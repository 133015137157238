import BRIDGE_TYPES from './types';
import WEBRTC_TYPES from '@/store/webrtc/types';
import LOG_TYPES from '@/store/log/types'
import CORE_TYPES from '@/store/core/types';
import { STOP_CALL_CAUSE } from './index';
import router from '@/router';
import { KONTOVERWALTUNG_PATH } from '@/store/multiBanking/actions';
import { removeSessionItem, getSessionObject } from '@/helpers/local-storage-helper';
import { handlePushSocket } from '../webrtc/sockets/push-socket-handler';
import { setColorScheme } from '@/configs/color-config';
import { isLoginRoute, findLoginNextUrl, findUserId } from '@/router/features-routes/login';
import { isPathAllowed } from '@/router/guards';

const DEEPLINK_BASE_PATH = '/applink';
const DEEPLINK_IGNORED_PATHS = [
  '/confirmed-2fa',
];

function isDeeplinkPathIgnored(path) {
  const buildRegex = p => new RegExp(`^${p}(\\?.*|#.*)?$`);
  return DEEPLINK_IGNORED_PATHS
    .findIndex(ignoredPath => buildRegex(ignoredPath).test(path)) >= 0;
}

/**
 * MSC-25335 Temporarily disabling bridge messages on iOS
 * @param {*} dispatch 
 * @param {*} getters 
 * @param {*} action 
 * @returns 
 */
function shouldIgnoreBridgeActionsIOS(dispatch, getters, action) {
  const shouldIgnore = getters[BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT_IOS];
  if (shouldIgnore) {
    dispatch(LOG_TYPES.ACTIONS.INFO, `WebRTC: ${action} ignored. Temporarily disabling bridge messages on iOS`);
  }

  return shouldIgnore;
}

export default {
  async [BRIDGE_TYPES.HANDLERS.STOP_CALL]({ dispatch }, data) {
    switch (data?.cause) {
      case STOP_CALL_CAUSE.REJECTED:
        await dispatch(WEBRTC_TYPES.ACTIONS.REJECT_CALL, { triggeredByNativeStack: true});
        break;
      default:
        await dispatch(WEBRTC_TYPES.ACTIONS.HANG_UP, { triggeredByNativeStack: true});
        break;
    }

  },
  async [BRIDGE_TYPES.HANDLERS.APP_RETURNED_FROM_BACKGROUND]({ dispatch }) {
    dispatch(LOG_TYPES.ACTIONS.LOG, `"${BRIDGE_TYPES.HANDLERS.APP_RETURNED_FROM_BACKGROUND}" received from mobileNativeStack`);
    document.dispatchEvent(new CustomEvent(BRIDGE_TYPES.HANDLERS.APP_RETURNED_FROM_BACKGROUND));
  },

  async [BRIDGE_TYPES.HANDLERS.PUSH_NOTIFICATION_RECEIVED]({ dispatch, commit, getters, state }, data) {
    if (shouldIgnoreBridgeActionsIOS(dispatch, getters, 'PUSH_NOTIFICATION_RECEIVED')) {
      return;
    }

    await handlePushSocket({
      msg: data?.['push-payload'],
      dispatch, 
      commit, 
      getters, 
      state
    });
  },

  async [BRIDGE_TYPES.HANDLERS.PREPARE_INCOMING_CALL]({ dispatch, commit, getters, state }, data) {
    if (shouldIgnoreBridgeActionsIOS(dispatch, getters, 'PREPARE_INCOMING_CALL')) {
      return;
    }

    commit(BRIDGE_TYPES.MUTATIONS.SAVE_CLIENT_CALL_ID, data?.['client-call-id']);
    commit(BRIDGE_TYPES.MUTATIONS.SAVE_RECEIVED_DB_PREFIX, data?.dbPrefix);
    commit(BRIDGE_TYPES.MUTATIONS.SAVE_SHOW_BUTTON_SWITCH_AUDIO_OUTPUT, data?.['show-button-switch-audio-output']);
    await handlePushSocket({
      msg: data?.['push-payload'],
      dispatch, 
      commit, 
      getters, 
      state
    });
  },

  async [BRIDGE_TYPES.HANDLERS.ACCEPT_INCOMING_CALL]({ dispatch, getters }) {
    if (shouldIgnoreBridgeActionsIOS(dispatch, getters, 'ACCEPT_INCOMING_CALL')) {
      return;
    }
    await dispatch(WEBRTC_TYPES.ACTIONS.ACCEPT_CALL);
  },

  async [BRIDGE_TYPES.HANDLERS.BACK_BUTTON_PRESSED]({ dispatch }) {
    dispatch(LOG_TYPES.ACTIONS.LOG, 'Back button received from mobileNativeStack');
    document.dispatchEvent(new CustomEvent('back-button-pressed'))
  },

  async [BRIDGE_TYPES.HANDLERS.MULTIBANKING_DISMISSED]({ dispatch }, data) {
    const accountId = getSessionObject('multibanking-accountId');
    removeSessionItem('multibanking-accountId');

    router.push({
      path: KONTOVERWALTUNG_PATH, 
      query: { 
        baReentry: data?.['baReentry'],
        accountId
      },
    });

    dispatch(LOG_TYPES.ACTIONS.LOG, 'Multibanking dismissed received from mobileNativeStack');
  },

  async [BRIDGE_TYPES.HANDLERS.OPEN_DEEPLINK]({ dispatch, getters }, data) {
    dispatch(LOG_TYPES.ACTIONS.LOG, '[open-deeplink] received from mobileNativeStack');

    // get url
    const { url:rawURL } = data || {};
    const url = rawURL?.trim();
    if (!url) {
      dispatch(LOG_TYPES.ACTIONS.ERROR, '[open-deeplink] empty url');
      return;
    }

    // check url base path
    const basePathIndex = url.indexOf(DEEPLINK_BASE_PATH);
    if (basePathIndex < 0) {
      dispatch(LOG_TYPES.ACTIONS.ERROR, {message:'[open-deeplink] invalid deep link:', url});
      return;
    }

    // sanitize path
    const basePathPattern = new RegExp(`^${DEEPLINK_BASE_PATH}`);
    let path = url.substring(basePathIndex).replace(basePathPattern, '');
    if (!path) {
      dispatch(LOG_TYPES.ACTIONS.ERROR, '[open-deeplink] empty path');
      return;
    }

    // is ignored?
    if (isDeeplinkPathIgnored(path)) {
      dispatch(LOG_TYPES.ACTIONS.INFO, `[open-deeplink] path (${path}) is ignored`);
      return;
    }

    const confirmUserLogout = async () => {
      await dispatch(CORE_TYPES.ACTIONS.CONFIRM_MODAL, {
        title: 'Link öffnen',
        message: 'Sie sollten sich abmelden, bevor Sie fortfahren',
        labelButtonConfirm: 'Abmelden',
      });

      if (getters[BRIDGE_TYPES.GETTERS.SHOW_CLOSE_EXTRA_WEB_INSTANCE]) {
        await dispatch(BRIDGE_TYPES.ACTIONS.CLOSE_EXTRA_WEB_INSTANCE_WITH_CONFIRM);
      }

      await dispatch(CORE_TYPES.ACTIONS.LOGOUT);
    };

    const findLoginValidNextUrl = (loginRelatedPath)  => {
      const { route:loginRelatedRoute } = router.resolve(loginRelatedPath);
      const userId = findUserId(loginRelatedRoute);
      const nextUrl = findLoginNextUrl(loginRelatedRoute);
      const isSameUserLoggedInFn = getters[CORE_TYPES.GETTERS.IS_SAME_USER_LOGGED_IN];
      return nextUrl && (!userId || isSameUserLoggedInFn(userId)) ? nextUrl : null;
    };

    // is login related page?
    const isLoginRelatedPath = isLoginRoute(path);
    const isLoggedIn = getters[CORE_TYPES.GETTERS.IS_LOGGED_IN];
    if (isLoginRelatedPath && isLoggedIn) {
      const validNextUrl = findLoginValidNextUrl(path);
      if (validNextUrl && isPathAllowed(validNextUrl)) {
        path = validNextUrl;
      } else {
        await confirmUserLogout();
      }
    }

    // navigate to path
    requestAnimationFrame(() => {
      router.push('/noop')
        .catch(() => {})
        .finally(() => router.push(path));
    });
  },

  async [BRIDGE_TYPES.HANDLERS.SWITCH_COLOR_THEME]({ dispatch }, data) {
    dispatch(BRIDGE_TYPES.ACTIONS.COLOR_THEME, data?.theme);
    setColorScheme();

    dispatch(LOG_TYPES.ACTIONS.LOG, '[switch-color-theme] received from mobileNativeStack');
  },
}