import CUSTOMERDATA_TYPES from './types';
import { getInitialState } from './index';
import Vue from 'vue'

export default {
  [CUSTOMERDATA_TYPES.MUTATIONS.GET_CUSTOMER_DATA_SUCCESS](state, payload) {
    if (payload.customerData) {
      Vue.set(state, 'customerData', {...payload.customerData});
      Vue.set(state, 'legitimationsurkundeChanged', false);
    }
  },
  [CUSTOMERDATA_TYPES.MUTATIONS.GET_CUSTOMER_DATA_CONFIG_SUCCESS](state, payload) {
    if (payload.customerDataConfig) {
      Vue.set(state, 'customerDataConfig', {...payload.customerDataConfig, isLoaded: true});
    }
  },
  [CUSTOMERDATA_TYPES.MUTATIONS.SAVE_CUSTOMER_DATA_SUCCESS](state, payload) {
    if (payload.customerData) {
      Vue.set(state, 'customerData', {...payload.customerData});
    }
  },
  [CUSTOMERDATA_TYPES.MUTATIONS.SAVE_PERSON_DATA_SUCCESS](state, payload) {
    if (payload.personData) {
      Vue.set(state, 'personData', {...payload.personData});
      Vue.set(state, 'selectedPersonForEditingExtraData', null);
    }
  },
  [CUSTOMERDATA_TYPES.MUTATIONS.SAVE_EINKOMMEN_SOZIALVERSICHERUNGEN_SUCCESS](state, {type = 'incomeSocialSecurity', data}) {
    if (data?.personId && state.customerData) {
      state.customerData = Object.assign({}, state.customerData);
      state.customerData[type] = data;
      Vue.set(state, 'customerDataEdited', {});
    }
  },
  [CUSTOMERDATA_TYPES.MUTATIONS.DELETE_CUSTOMER_DATA_SUCCESS](state, payload) {
    if (payload.customerData) {
      Vue.set(state, 'customerData', {...payload.customerData});
    }
  },
  [CUSTOMERDATA_TYPES.MUTATIONS.DELETE_PERSON_DATA_SUCCESS](state, payload) {
    if (payload.personData) {
      Vue.set(state, 'personData', {...payload.personData});
    }
  },
  [CUSTOMERDATA_TYPES.MUTATIONS.GET_CUSTOMER_DATA_ADDITIONAL_PERSONS_SUCCESS](state, payload) {
    if (payload && Array.isArray(payload.persons)) {
      Vue.set(state, 'persons', [...payload.persons])
    }
  },
  [CUSTOMERDATA_TYPES.MUTATIONS.UPDATE_SELECTED_CUSTOMER_DATA](state, payload) {
    Vue.set(state, 'selectedPersonForEditing', payload || 'person1')
  },
 
  [CUSTOMERDATA_TYPES.MUTATIONS.LAST_SAVED_NEW_PERSON](state, personId) {
    Vue.set(state, 'lastSavedNewPerson', personId)
  },
  
  [CUSTOMERDATA_TYPES.MUTATIONS.SET_SELECTED_EXTRA_DATA](state, payload) {
    if (payload && Object.keys(payload).length) {
      Vue.set(state, 'selectedPersonForEditingExtraData', payload)
    }
  },

  [CUSTOMERDATA_TYPES.MUTATIONS.ADD_CUSTOMER_DATA_EDITED](state, payload) {
    if (payload.personId) {
      Vue.set(state, 'customerDataEdited', Object.assign({}, state.customerDataEdited, payload))
    }
    Vue.set(state, 'dataChanged', true);
    Vue.set(state, 'legitimationsurkundeChanged', true);
  },

  [CUSTOMERDATA_TYPES.MUTATIONS.RESET_CUSTOMER_DATA_EDITED](state){
    Vue.set(state, 'customerDataEdited', {})
  },

  [CUSTOMERDATA_TYPES.MUTATIONS.SET_BACK_BUTTON](state, payload) {
    Vue.set(state, 'backButton', payload)
  },

  [CUSTOMERDATA_TYPES.MUTATIONS.SET_ADDITIONAL_VALIDATORS](state, payload) {
    Vue.set(state, 'additionalValidators', payload)
  },
  
  [CUSTOMERDATA_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, { ...getInitialState(), lastSavedNewPerson: state.lastSavedNewPerson})
  },

  [CUSTOMERDATA_TYPES.MUTATIONS.SET_SAVED_CUSTOMER_ID](state, payload)  {
    if(payload?.customerID) {
      Vue.set(state, 'savedCustomerID', payload.customerID);
    }
  },

  [CUSTOMERDATA_TYPES.MUTATIONS.SET_PIN_DATA_SUCCESS](state, payload)  {
    if(payload?.pinData) {
      Vue.set(state, 'pinData', {...payload.pinData});
    }
  },

  [CUSTOMERDATA_TYPES.MUTATIONS.GET_PERSON_STATES_SUCCESS](state, payload)  {
    if(payload?.personId) {
      Vue.set(state.personStates, payload.personId, payload.states);
    }
  },

  [CUSTOMERDATA_TYPES.MUTATIONS.CLEAR_PERSON_STATES](state, payload)  {
    if(payload?.personId) {
      Vue.set(state.personStates, payload.personId, []);
    }
  },

  [CUSTOMERDATA_TYPES.MUTATIONS.GET_SELECTED_PERSON_DATA_SUCCESS](state, payload) {
    Vue.set(state, 'personData', { ...payload });
  },

  [CUSTOMERDATA_TYPES.MUTATIONS.COPY_ADDRESS_SUCCESS](state, payload) {
    Vue.set(state, 'addressToCopy', { ...payload });
  },

  [CUSTOMERDATA_TYPES.MUTATIONS.FIND_PEOPLE_SUCCESS](state, payload) {
    Vue.set(state, 'peopleFound', [...payload]);
  },

  [CUSTOMERDATA_TYPES.MUTATIONS.FIND_PEOPLE_RELATIONSHIP_SUCCESS](state, payload) {
    Vue.set(state, 'peopleRelationship', [...payload]);
  },

  [CUSTOMERDATA_TYPES.MUTATIONS.ADD_HINT](state, payload) {
    Vue.set(state, 'hints', [...state.hints, payload]);
  },

  [CUSTOMERDATA_TYPES.MUTATIONS.REMOVE_HINTS](state, { group, }) {
    if(group) {
      Vue.set(state, 'hints', [...state.hints.filter(hint => hint.group !== group)]);
    }
  },

  [CUSTOMERDATA_TYPES.MUTATIONS.RESET_HINTS](state) {
    Vue.set(state, 'hints', []);
  },

  [CUSTOMERDATA_TYPES.MUTATIONS.FIND_COURTAGE_SUCCESS](state, payload) {
    Vue.set(state, 'courtage', [...payload]);
  },

  [CUSTOMERDATA_TYPES.MUTATIONS.FIND_BEMERKUNGEN_SUCCESS](state, payload) {
    Vue.set(state, 'bemerkungen', [...payload]);
  },

  [CUSTOMERDATA_TYPES.MUTATIONS.FIND_SUB_CUSTOMERS_SUCCESS](state, payload) {
    Vue.set(state, 'subCustomers', [...payload]);
  },

  [CUSTOMERDATA_TYPES.MUTATIONS.GET_INTERNE_BEMERKUNG_SUCCESS](state, payload) {
    Vue.set(state, 'interneBemerkung', payload);
  },

  [CUSTOMERDATA_TYPES.MUTATIONS.FIND_BIRTHDAYS_SUCCESS](state, payload) {
    Vue.set(state, 'birthdays', [...payload || []]);
  },

  [CUSTOMERDATA_TYPES.MUTATIONS.LEGITIMATIONSURKUNDE_CHANGED](state, payload) {
    Vue.set(state, 'legitimationsurkundeChanged', payload?.legitimationsurkundeChanged);
  },  

  [CUSTOMERDATA_TYPES.MUTATIONS.BEMERKUNG_KUNDE](state, payload) {
    Vue.set(state, 'bemerkungKunde', payload);
  },

  [CUSTOMERDATA_TYPES.MUTATIONS.BEMERKUNG_KUNDE_SICHTBAR](state, payload) {
    Vue.set(state, 'bemerkungSichtbar', payload);
  },

  [CUSTOMERDATA_TYPES.MUTATIONS.GET_CUSTOMER_CONTACT_PERSON_DATA_SUCCESS](state, payload) {
    console.log('GET_CUSTOMER_CONTACT_PERSON_DATA_SUCCESS', payload)
    Vue.set(state, 'contactPerson', payload)
  }, 

  [CUSTOMERDATA_TYPES.MUTATIONS.SELECTED_BEMERKUNG](state, payload) {
    Vue.set(state, 'selectedBemerkung', payload)
    // state.selectedBemerkung = payload;
  },   

  
  [CUSTOMERDATA_TYPES.MUTATIONS.RESET_STATE_STAMMDATEN](state) {
    Vue.set(state, 'editedState', false);
  },
 
  [CUSTOMERDATA_TYPES.MUTATIONS.SET_STATE_STAMMDATEN](state) {
    Vue.set(state, 'editedState', true);
  },

  
  [CUSTOMERDATA_TYPES.MUTATIONS.UPDATE_LEGITIMATION_INFO](state, payload) {
    Vue.set(state, 'legitimation', payload);
    Vue.set(state, 'customerData', { ...state.customerData, legitimation: payload.legitimation });
  },


  [CUSTOMERDATA_TYPES.MUTATIONS.UPLOAD_LEGITIMATION_IMAGES_SUCCESS](state, payload) {
    if (!payload.personId || !payload.data || !Object.keys(payload.data).length) {
      return;
    }
    if (payload?.personId === 'person1') {
      if(!state.customerData.legitimation) {
        state.customerData.legitimation = {};
      }
      state.customerData = {
        ...state.customerData,
        legitimation: Object.assign(state.customerData.legitimation, payload.data)
      };
    } else {
      // legitimation of a additional person
      const index = state.persons?.findIndex(person => person.personId == payload.personId)
      if (index >= 0) {
        state.persons[index].legitimation = Object.assign(state.persons[index].legitimation || {}, payload.data);
      }
    } 
  },

  [CUSTOMERDATA_TYPES.MUTATIONS.SET_FA_CHECK_EMAILS](state, payload) {
    Vue.set(state, 'checkEmailsFA', payload);
  },

}