<template>
  <div class="depotpositions--navigation__container">
    <PageHeaderTitleNavigation 
      :defaultMenu="$appNavigation.currentTabMenu" 
      :title="title"
      :subtitle="subtitle" 
      :noPrimaryAction="!headerActions.length"
      :actions="actions" 
      @action-ADD-ACTIVITY="handleAddActivity()"
      @action-ADD-TICKET="navigateToNewTicket()"
      @action-OPEN-PDF="navigateToSendVermogensubersichtPdf()"
      @action-OPEN-SEND-PDF="navigateToSendVermogensubersichtPdfByEmail()"
      @action-ANTEILE-NULL="$emit('ANTEILE-NULL')"
      @action-OPEN-ERW_COURTAGE="openErwarteteCourtage()"
      v-on="$listeners"
    >
      <template v-if="$slots.title" #title><slot name="title" /></template>
      <template v-if="$slots.subtitle" #subtitle><slot name="subtitle" /></template>
    </PageHeaderTitleNavigation>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CALENDAR_TYPES from '@/store/calendar/types';
import CORE_TYPES from '@/store/core/types';
import DEPOTPOSITIONS_TYPES from "@/store/depotpositions/types";

import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';

import BaseModal from '@/components/core/BaseModal.vue';
import AppointmentEditCreate from '@/components/calendar/appointmentFiles/AppointmentEditCreate.vue';

import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

export default {
  props: {
    title: {
      type: String,
      default: 'Depotpositionen',
    },
    subtitle: {
      type: String,
    },
    headerActions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      noInitAppoiment: false,
    };
  },
  computed: {
    ...mapGetters({
      loginData: CORE_TYPES.GETTERS.GET_LOGIN_DATA,
      isBrokerOrBypass: CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS,
      showBestandscourtage: DEPOTPOSITIONS_TYPES.GETTERS.GET_SHOW_COURTAGE,
    }),
    actions() {
      return [
        ...this.headerActions,
        PageHeaderSimpleAction('ADD-ACTIVITY', 'Aktivität hinzufügen').withVisible(() => this.isBrokerOrBypass),
        PageHeaderSimpleAction('ADD-TICKET', 'Neue Nachricht'),
        PageHeaderSimpleAction('OPEN-PDF', 'Vermögensübersicht als PDF'),
        PageHeaderSimpleAction('OPEN-SEND-PDF', 'Vermögensübersicht zusenden').withVisible(() => this.isBrokerOrBypass),
        PageHeaderSimpleAction('OPEN-ERW_COURTAGE', 'Die erwartete Bestandscourtage').withVisible(() => this.showBestandscourtage > 0),
      ];
    },
  },
  mounted() {
    if (this.isBrokerOrBypass && this.showBestandscourtage == 0) {
      this.$store.dispatch(DEPOTPOSITIONS_TYPES.ACTIONS.LOAD_SHOW_COURTAGE);
    }
  },
  methods: {
    handleAddActivity() {
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_NEW_ACTIVITY);
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_BETEILIGTE_FOR_UPDATE, []);
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_SELECTED_APPOINTMENT_DATA, {
        appointment: {
          appointment: {
            label: '',
            activity: true,
            bereich: 'Kunde',
            bereichDisplay: 'Kunde ' + this.loginData.fullname + ' (' + this.loginData.userid + ')',
            bereichId: this.loginData.userid,
          }
        }
      });
      this.noInitAppoiment = true;
      this.openAppointment();
    },
    openAppointment() {
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_CONFIG, {
        title: 'Aufgabe bearbeiten',
        noInitialise: this.noInitAppoiment,
        attachCustomer: false,
        discardChanges: this.resetAppointment,
  
        back: this.$router.currentRoute.fullPath,
      })
      this.$router.push({ path: `/communication/appointment` });
    },
    resetAppointment() {
      this.noInitAppoiment = false;
    },
    navigateToNewTicket() {
      this.$router.push({name: 'new-ticket'});
    },
    navigateToSendVermogensubersichtPdf() {
      this.$router.push('/home/vermogensubersicht/vermogensubersicht_pdf');
    },
    navigateToSendVermogensubersichtPdfByEmail() {
      this.$router.push('/home/vermogensubersicht/send-vermogensubersicht-pdf-by-email');
    },
    openErwarteteCourtage() {
      if (!this.loginData.roles.find(r => r == "ZUGRIFF_COURTAGE_TABELLE_FONDS")) {
        this.loginData.roles.push("ZUGRIFF_COURTAGE_TABELLE_FONDS");
      }
      this.$router.push('/home/vermogensubersicht/erwartete_courtage');
    },
  },
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    BaseModal,
    AppointmentEditCreate,
  },
}
</script>
