var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.rows.length
        ? _c(
            "div",
            [
              _vm.config && _vm.config.comment
                ? _c("div", { staticClass: "small" }, [
                    _vm._v(_vm._s(_vm.config.comment))
                  ])
                : _vm._e(),
              _c("Table", {
                attrs: {
                  headers: _vm.headers,
                  rows: _vm.rows,
                  rowId: "fondsname",
                  hidePagination: ""
                },
                on: { "action-DELETE": _vm.openModalDeleteFond }
              })
            ],
            1
          )
        : _vm._e(),
      _c("DeletePositionModal", {
        ref: "deleteModal",
        attrs: { position: _vm.positionToDelete },
        on: {
          delete: function($event) {
            return _vm.doDeletePosition()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }