import dayjs from 'dayjs';
import {getInsurancePeriodLabel } from './mapping'
import { parse } from '@/helpers/number-formatter.js'
import store from '@/store'
import CORE_TYPES from '@/store/core/types.js'
import { ROLES } from '@/router/roles';

// Array.flat polyfill
Object.defineProperty(Array.prototype, 'flat', {
  value: function(depth = 1) {
    return this.reduce(function(flat, toFlatten) {
      return flat.concat(
        Array.isArray(toFlatten) && depth - 1 ? (toFlatten).flat(depth - 1) : toFlatten
      );
    }, []);
  }
});
export const isNotNumber = function(n) {
  return !(!isNaN(parseFloat(n)) && isFinite(n));
};

export function indexOfRow(items, name) {
  return items.findIndex(item => item.key === name);
}

export const WERTPAPIER_VERSICHERUNG = 'WERTPAPIER_VERSICHERUNG';

/**
 * Profits and losts global service. For assets, liabilities, balance sheet usage.
 *
 * @export
 * @class BalanceService
 */
export class BalanceService {
  balance = [];
  profits;
  loss;
  authStatus$;
  balance$;
  subRoute;
  portfolios$;
  credit$;
  subPortfolios;
  subBalance;
  portfolios;
  // Profits tables
  left = [];
  // Losts tables
  right = [];
  loggedIn = false;
  tableData;
  totalAssets = 0;
  wealth = 0;
  liability = 0;
  assertData;
  liabilityData;

  masterObservable;
  state$;
  stateSub;
  balanceCache = {};
  user;
  emptyTableObj = {
    resultSums: {},
    tableHeaders: [],
    records: [],
    headerLabels: [],
    footerLabels: [],
    isLoaded: false,
    isError: true,
  };
  emptyTableObjNotLoaded = {
    resultSums: {},
    tableHeaders: [],
    records: [],
    headerLabels: [],
    footerLabels: [],
    isLoaded: false,
    isError: false,
  };

  static initialBalanceSum = {
    profits: [
      {
        header: 'Wertpapiere',
        summary: []
      },
      {
        header: 'Bankkonten',
        summary: []
      },
      {
        header: 'Versicherungen',
        summary: []
      },
      {
        header: 'Beteiligungen',
        summary: []
      },
      {
        header: 'Immobilien',
        summary: []
      },
      {
        header: 'Weitere',
        summary: []
      }
    ],
    loss: [
      {
        header: 'Kredite',
        summary: []
      },
      {
        header: 'Bankkonten',
        summary: []
      },
      {
        header: 'Weitere',
        summary: []
      },
    ],
    assetData: [],
    liabilityData: [],
    wealth: null,
    totalAssets: null,
    liability: null,
    portfolios: [],
    isLoaded: false,
    isError: false,
  };
  depotid;
  state;
  meta;
  balanceMeta;
  portfolioMeta;
  bankTransactionsMeta;
  prevId;

  /**
   * Calculate profits and losts data from all required data sources to one single result object
   */
  calculateBalance(
    {...meta},
    {...balanceMeta},
    {...portfolioMeta},
    {...balanceData},
    {...portfolios},
    {...credit},
    {...bankAccount},
    {...closedFunds},
    {...insuranceTable},
    id,
    user,
    {...bankAccounts},
    {...multibankingPortfolios},
    {...bankTransactionsMeta},
    {...balanceDataIdData},
    isNotCustomer,
    {...otherProductsProfits},
    {...otherProductsLoss},
    {...immobilienData},
  ) {
    // use cached calculated data if it's not expired
    // if (this.balanceCache && this.balanceCache[id] && (Date.now() < balance.meta.updatedAt + environment.lifeTime)
    //     && this.balanceCache[id].isLoaded) {
    //   return this.balanceCache[id];
    // }
    meta = this.getBalanceRelatedMeta(meta);
    if (this.balanceCache[id]
      && (this.balanceCache[id].isLoaded || this.balanceCache[id].isError)
      && JSON.stringify(this.meta) === JSON.stringify(meta)
      && JSON.stringify(this.balanceMeta) === JSON.stringify(balanceMeta)
      && JSON.stringify(this.portfolioMeta) === JSON.stringify(portfolioMeta)
      && JSON.stringify(this.bankTransactionsMeta) === JSON.stringify(bankTransactionsMeta)
      && Object.keys(this.balanceCache[id].profits).filter(k => this.balanceCache[id].profits[k].isLoaded).length
      && Object.keys(this.balanceCache[id].loss).filter(k => this.balanceCache[id].loss[k].isLoaded).length
      ) {
      return this.balanceCache[id];
    }
    this.meta = meta;
    this.balanceMeta = balanceMeta;
    this.portfolioMeta = portfolioMeta;
    this.bankTransactionsMeta = bankTransactionsMeta;
    this.prevId = id;
    this.left = [];
    this.right = [];

    // Prepare empty data in case of error
    portfolios = portfolios.isError
      ? {...this.emptyTableObj}
      : (portfolios.isLoaded
        ? {...this.emptyTableObjNotLoaded, ...portfolios}
        : {...this.emptyTableObjNotLoaded});

    credit = credit.isError
      ? {...this.emptyTableObj}
      : (credit.isLoaded
        ? {...this.emptyTableObjNotLoaded, ...credit}
        : {...this.emptyTableObjNotLoaded});

    bankAccount = bankAccount.isError
      ? {...this.emptyTableObj}
      : (bankAccount.isLoaded
        ? {...this.emptyTableObjNotLoaded, ...bankAccount}
        : {...this.emptyTableObjNotLoaded});

    closedFunds = closedFunds.isError
      ? {...this.emptyTableObj}
      : (closedFunds.isLoaded
        ? {...this.emptyTableObjNotLoaded, ...closedFunds}
        : {...this.emptyTableObjNotLoaded});

    insuranceTable = insuranceTable.isError
      ? {...this.emptyTableObj}
      : (insuranceTable.isLoaded
        ? {...this.emptyTableObjNotLoaded, ...insuranceTable}
        : {...this.emptyTableObjNotLoaded});

    if (id && id !== 'ALLE_DEPOTS_ANZEIGEN') {
      if (balanceDataIdData
        && !!balanceDataIdData[id]
        && Object.keys(balanceDataIdData) && balanceDataIdData[id]) {
          balanceData = balanceDataIdData[id];
      } else {
        balanceData = {
          ...balanceData,
          tableData: [{...this.emptyTableObjNotLoaded}],
        };
      }
    }

    balanceData = balanceData.isError
    || !balanceData.tableData
        ? {
          tableData: [{...this.emptyTableObj}],
          isError: true,
        } : (balanceData.isLoaded
          ? {...this.emptyTableObjNotLoaded, ...balanceData}
          : {
            tableData: [{...this.emptyTableObjNotLoaded}],
            isError: false,
            isLoaded: false,
          });

    if (bankAccounts && Object.keys(bankAccounts).length) {
      bankAccounts = bankAccounts && bankAccounts.isError ? {
        isError: true,
        isLoaded: true,
        accounts: []
      } : bankAccounts && bankAccounts.isLoaded ?
        {
          isError: false,
          isLoaded: true,
          accounts: [],
          ...bankAccounts
        } : {
          accounts: [],
          isError: false,
          isLoaded: false,
        };
    } else {
      bankAccounts = {
        accounts: [],
        isError: false,
        isLoaded: true,
      };
    }
    this.tableData = balanceData.tableData;
    let assetsCurrencyData = [];
    let liabilityCurrencyData = [];
    
    otherProductsProfits = otherProductsProfits ? (
      Array.isArray(otherProductsProfits) ? [...otherProductsProfits] : Object.values(otherProductsProfits)
    ) : []
    otherProductsLoss = otherProductsLoss ? (
      Array.isArray(otherProductsLoss) ? [...otherProductsLoss] : Object.values(otherProductsLoss)
    ) : []
    immobilienData = immobilienData ? (
      Array.isArray(immobilienData) ? [...immobilienData] : Object.values(immobilienData)
    ) : []

    const depotIdIndex = portfolios.tableHeaders.findIndex(item => item.key === 'depotid');
    portfolios.records = portfolios && portfolios.records.filter(v =>
        v[depotIdIndex] !== 'WERTPAPIER_VERSICHERUNG'
        && (id === 'ALLE_DEPOTS_ANZEIGEN' || !id || id === v[depotIdIndex])
    );
    this.portfolios = portfolios && this.prepareDepotpositionSum(portfolios, id, multibankingPortfolios);

    if (balanceData.tableData) {
      this.fillVermogenSide(insuranceTable, bankAccount, bankAccounts, closedFunds, balanceData, otherProductsProfits, immobilienData);

      this.fillVerbindlichkeitenSide(credit, insuranceTable, balanceData, bankAccount, bankAccounts, otherProductsLoss);

      // get total profits sum
      this.wealth = this.left.reduce((accumulator, currentValue) => {
        if (currentValue && currentValue.summary) {
          accumulator =
            accumulator +
            currentValue.summary.reduce((acc, cur) => acc + (cur.sum ? parseFloat(cur.sum) : 0), 0);
        }
        return accumulator;
      }, 0);
      // get total losts sum
      this.liability = this.right.reduce((accumulator, currentValue) => {
        if (currentValue && currentValue.summary) {
          accumulator =
            accumulator +
            currentValue.summary.reduce((acc, cur) => acc + (cur.sum ? parseFloat(cur.sum) : 0), 0);
        }
        return accumulator;
      }, 0);

      this.wealth += this.getSumWertpapiere() + this.getSumWertpapiereVorsorge();
      //   // ngx chart formated data for profits
      this.assertData = this.left.map(item => {
        return {
          string: item.header,
          number: this.wealth ? Math.abs(Math.round((this.getSum(item) / this.wealth) * 100)) : 0
        };
      });
      //   // ngx chart formated data for losts
      this.liabilityData = this.right.map(item => {
        return {
          string: item.header,
          number: this.liability ? Math.abs(Math.round((this.getSum(item) / this.liability) * 100)) : 0
        };
      });
      
      assetsCurrencyData = this.left.map(item => {
        return {
          string: item.header,
          number: this.wealth ? this.getSum(item) : 0
        };
      });
      //   // ngx chart formated data for losts
      liabilityCurrencyData = this.right.map(item => {
        return {
          string: item.header,
          number: this.liability ? this.getSum(item) : 0
        };
      });
    }

    const hasRoles = store.getters[CORE_TYPES.GETTERS.HAS_ROLES]
    const assetChartData = [
      ...(this.getSumWertpapiere() ? [{
        string: 'Wertpapiere',
        number: this.wealth ? Math.abs(Math.round((this.getSumWertpapiere() / this.wealth) * 100)) : 0
      }] : []),
      ...(hasRoles(ROLES.SHOW_WERTPAPIERE_VORSORGE) && this.getSumWertpapiereVorsorge() ? [{
        string: 'Wertpapiere Vorsorge',
        number: this.wealth ? Math.abs(Math.round((this.getSumWertpapiereVorsorge() / (this.wealth)) * 100)) : 0
      }] : []),
      ...this.assertData
    ];
    // add portfolio positions sum data to profits
    this.assertData = [
      ...(this.getSumWertpapiere() ? [{
        string: 'Wertpapiere',
        number: this.wealth ? Math.abs(Math.round((this.getSumWertpapiere() / (this.wealth)) * 100)) : 0
      }]: []),
      ...(this.getSumWertpapiereVorsorge() && [{
        string: 'Wertpapiere Vorsorge',
        number: this.wealth ? Math.abs(Math.round((this.getSumWertpapiereVorsorge() / (this.wealth)) * 100)) : 0
      }] || []),
      ...this.assertData
    ];
    assetsCurrencyData = [
      ...(this.getSumWertpapiere() ? [{
        string: 'Wertpapiere',
        number: this.wealth ? this.getSumWertpapiere() : 0
      }]: []),
      ...(this.getSumWertpapiereVorsorge() && [{
        string: 'Wertpapiere Vorsorge',
        number: this.wealth ? this.getSumWertpapiereVorsorge() : 0
      }] || []),
      ...assetsCurrencyData
    ]

    // update sums with the data added above
    this.totalAssets = parseFloat(`${this.liability}`) + parseFloat(`${this.wealth}`);

    this.balanceCache[id] = {
      ...BalanceService.initialBalanceSum,
      profits: this.left,
      loss: this.right,
      assetData: this.assertData,
      liabilityData: this.liabilityData,
      assetChartData,
      liabilityChartData: this.liabilityData,
      wealth: this.wealth,
      totalAssets: this.totalAssets,
      liability: this.liability,
      portfolios: !portfolios.isError
        ? this.portfolios && this.portfolios.sort((a, b) => a.depotName.localeCompare(b.depotName))
        : {isError: true},
      portfoliosError: portfolios.isError,
      isLoaded: (portfolios.isLoaded || portfolios.isError),
      liabilityCurrencyData,
      assetsCurrencyData,
      // && (credit.isError || credit.isLoaded)
      // && (bankAccount.isError || bankAccount.isLoaded)
      // && (isNotCustomer || insuranceTable.isError || insuranceTable.isLoaded)
      // && (balanceData.isError || balanceData.isLoaded)
      // && (
      //   (!this.isShowMultibanking || multibankingPortfolios.data && multibankingPortfolios.data.isLoaded || (multibankingPortfolios.data && multibankingPortfolios.data.isLoaded) === undefined)),
      isError: false,
    };

    // ALLE_DEPOTS_ANZEIGEN
    return {...this.balanceCache[id]};
  }

  fillVermogenSide(insuranceTable, bankAccount, bankAccounts, closedFunds, balanceData, otherProductsProfits, immobilienData) {
    const indexKategorie = indexOfRow(insuranceTable.tableHeaders, 'kategorie');

    const bankIndex1 = indexOfRow(bankAccount.tableHeaders, 'Gesellschaft');
    const bankIndex2 = indexOfRow(bankAccount.tableHeaders, 'Kontonummer');
    const bankIndexSum = indexOfRow(bankAccount.tableHeaders, 'Kontostand');
    const bankInsuranceIndex1 = indexOfRow(insuranceTable.tableHeaders, 'gesellschaft');
    const bankInsuranceIndex2 = indexOfRow(insuranceTable.tableHeaders, 'nummer');
    const bankInsuranceSum = indexOfRow(insuranceTable.tableHeaders, 'rueckkaufswert');
    const bankInsurancePeriodName = indexOfRow(insuranceTable.tableHeaders, 'zw');
    const bankInsuranceSumPeriod = indexOfRow(insuranceTable.tableHeaders, 'praemie');
    const bankInsuranceBezeichnung = indexOfRow(insuranceTable.tableHeaders, 'bezeichnung');

    const Bankkonten = {
      header: 'Bankkonten',
      headers: ['Bank', 'Bezeichnung', 'Kontonummer', 'Kontostand'],
      resultSums: bankAccount.resultSums,
      isError: bankAccount.isError,
      isLoaded: bankAccount.isLoaded,
      isRestricted: bankAccount.isRestricted,
      summary: [
        ...bankAccount.records
          .filter(v => parseFloat(v[bankIndexSum] || '0') >= 0)
          .map((v, index) => ({
          title1: v[bankIndex1] || '',
          title2: '',
          title3: v[bankIndex2] || '',
          sum: v[bankIndexSum] || 0,
          id: v[bankIndex2],
          data: {
            tableHeaders: bankAccount.tableHeaders.filter(
              (item, index) => index !== bankIndex1 && index !== bankIndex1 && index !== bankIndexSum
            ),
            record: v.filter((item, index) => index !== bankIndex1 && index !== bankIndex1 && index !== bankIndexSum)
          }
        })),
        ...insuranceTable.records
          .filter(v => v[indexKategorie] === 'Bank' && parseFloat(v[bankInsuranceSum] || '0') >= 0)
          .map(v => ({
            title1: v[bankInsuranceIndex1].shortName || '',
            title2: v[bankInsuranceIndex2] || '',
            title3: '',
            title4: v[bankInsuranceBezeichnung] || 'k.a.',
            id: v[bankInsuranceIndex2],
            sum: v[bankInsuranceSum] || 0,
            sumPeriod: v[bankInsuranceSumPeriod],
            period: this.getPeriodLabel(v[bankInsurancePeriodName]) || '',
            insurance: true,
            data: {
              tableHeaders: insuranceTable.tableHeaders.filter(
                (item, index) => index !== bankInsuranceIndex1 && index !== bankInsuranceIndex2 && index !== bankInsuranceSum
              ),
              record: v.filter(
                (item, index) => index !== bankInsuranceIndex1 && index !== bankInsuranceIndex2 && index !== bankInsuranceSum
              )
            }
          }))
      ]
    };
    Bankkonten.summary = Bankkonten.summary.sort((a, b) => a.title1.localeCompare(b.title1));

    const Multibanking = {
      header: 'Multibanking',
      headers: ['Bank', 'Bezeichnung', 'Kontonummer', 'Kontostand'],
      resultSums: bankAccount.resultSums,
      isError: bankAccount.isError,
      isLoaded: bankAccount.isLoaded,
      isRestricted: bankAccount.isRestricted,
      summary: [
          ...bankAccounts.accounts
            .filter(v => v.totalAmount >= 0)
            .map(v => ({
            title1: v.displayName,
            title2: '',
            title3: v.iban || v.creditcardNo,
            sum: v.totalAmount,
            id: v.iban || v.creditcardNo,
            multibanking: true,
            authNeeded: v.authNeeded,
            providerId: v.providerId,
            data: {
              tableHeaders: [
                { label: 'Display Name', key: 'Display Name', dataType: 'String' },
                { label: 'Kontonummer', key: 'Kontonummer', dataType: 'String' },
                { label: 'letzte Aktualisierung', key: 'letzte Aktualisierung', dataType: 'String' }
              ],
              record: [
                v.displayName,
                (v.iban || v.creditcardNo),
                (v.lastExternalUpdate ? dayjs(v.lastExternalUpdate).format('DD.MM.YYYY') : '')
              ]
            }
          }))
      ]
    };
    Multibanking.summary = Multibanking.summary.sort((a, b) => a.title1.localeCompare(b.title1));

    const bankSum = Bankkonten.summary.reduce((accumulator, currentValue) => {
      accumulator = parseFloat(accumulator) + (currentValue.sum && !isNotNumber(parseFloat(currentValue.sum))
      ? parseFloat(currentValue.sum)
      : 0);
      return accumulator;
    }, 0.0);

    const insuranceIndex1 = indexOfRow(insuranceTable.tableHeaders, 'bezeichnung');
    const insuranceIndex2 = indexOfRow(insuranceTable.tableHeaders, 'nummer');
    const insuranceIndex3 = indexOfRow(insuranceTable.tableHeaders, 'gesellschaft');
    const insuranceIndex4 = indexOfRow(insuranceTable.tableHeaders, 'zw');
    const insuranceSum = indexOfRow(insuranceTable.tableHeaders, 'praemie');
    const insuranceStatus = indexOfRow(insuranceTable.tableHeaders, 'status');

    // Add portfolio like insurance to proper insurance sum
    const insuranceDepoPositionsIndex = indexOfRow(insuranceTable.tableHeaders, 'depotAuszug');
    const insuranceIdIndex = indexOfRow(insuranceTable.tableHeaders, 'id');
    const insuranceLogoIndex = indexOfRow(insuranceTable.tableHeaders, 'gesellschaft');
    let insurancePortfolioWertIndex;
    const insurancePortfolioSums = insuranceTable.records
      && insuranceTable.records
      .filter(v => !!v[insuranceDepoPositionsIndex])
      .map(v => {
        insurancePortfolioWertIndex = indexOfRow(v[insuranceDepoPositionsIndex].headers, 'Euro');
      });

    const Versicherungen = {
      header: 'Versicherungen',
      headers: ['Gesellschaft', 'Nummer', 'Beitrag', 'Zahlweise', 'Rückkaufswerte'],
      isError: insuranceTable.isError,
      isLoaded: insuranceTable.isLoaded,
      isRestricted: insuranceTable.isRestricted,
      summary: insuranceTable.records
        .filter(v => v[indexKategorie] === 'VORSORGE' && (this.prevId=='ALLE_DEPOTS_ANZEIGEN'))
        .map(v => ({
          title1: v[insuranceIndex3] && v[insuranceIndex3].shortName || '',
          title2: v[insuranceIndex2] || '',
          title3: v[insuranceSum] || '',
          title4: v[insuranceIndex4] && this.getPeriodLabel(v[insuranceIndex4]) || '',
          id: v[insuranceIndex2],
          sum: parse(v[bankInsuranceSum]),
          sumPeriod: v[insuranceSum] || '',
          period: this.getPeriodLabel(v[bankInsurancePeriodName]) || '',
          periodOriginal: v[bankInsurancePeriodName] || 1,
          insurance: true,
          logo: v[insuranceLogoIndex],
          data: {
            tableHeaders: insuranceTable.tableHeaders.filter(
              (item, index) =>
                index !== insuranceIndex1
                && index !== insuranceIndex2
                && index !== insuranceIndex3
                && index !== insuranceIndex4
                && index !== insuranceLogoIndex
                && index !== bankInsuranceSum
            ),
            record: v.filter(
              (item, index) =>
                index !== insuranceIndex1
                && index !== insuranceIndex2
                && index !== insuranceIndex3
                && index !== insuranceIndex4
                && index !== insuranceLogoIndex
                && index !== bankInsuranceSum
            ),
          }
        }))
    };

    const closedFundsIndex1 = indexOfRow(closedFunds.tableHeaders, 'gesellschaftsname');
    const closedFundsIndex2 = indexOfRow(closedFunds.tableHeaders, 'fondsname');
    const closedFundsIndexSum = indexOfRow(closedFunds.tableHeaders, 'anlagesumme');
    const closedFundsIndexId = indexOfRow(closedFunds.tableHeaders, 'isin');

    const Beteiligungen = {
      header: 'Beteiligungen',
      headers: ['Gesellschaft', 'Name', 'Betrag'],
      resultSums: closedFunds.resultSums,
      isError: closedFunds.isError,
      isLoaded: closedFunds.isLoaded,
      isRestricted: closedFunds.isRestricted,
      summary: closedFunds.records.map(v => ({
        title1: v[closedFundsIndex1] || '',
        title2: v[closedFundsIndex2] || '',
        title3: '',
        sum: v[closedFundsIndexSum] || 0,
        id: v[closedFundsIndexId],
        data: {
          tableHeaders: closedFunds.tableHeaders.filter(
            (item, index) => index !== closedFundsIndex1 && index !== closedFundsIndex2 && index !== closedFundsIndexSum
          ),
          record: v.filter(
            (item, index) => index !== closedFundsIndex1 && index !== closedFundsIndex2 && index !== closedFundsIndexSum
          )
        }
      }))
    };

    const Immobilien = {
      header: 'Immobilien',
      headers: ['Anlageart', 'Beschreibung/Bemerkung', 'Wert'],
      isError: balanceData.isError,
      isLoaded: balanceData.isLoaded,
      isRestricted: balanceData.isRestricted,
      summary: [
        ...((immobilienData && immobilienData.length) ?
        immobilienData.map(v => ({
            title1: v?.produktart || '',
            title2: v?.produktbez || '',
            title3: '',
            id: v?.id || '',
            sum: v?.aktWert || 0,
          }))
        : [])
      ]
    };

    const Sonstige = {
      header: 'Weitere',
      headers: ['Anlageart', 'Beschreibung/Bemerkung', 'Wert'],
      isError: balanceData.isError,
      isLoaded: balanceData.isLoaded,
      isRestricted: balanceData.isRestricted,
      summary: [
        ...((otherProductsProfits && otherProductsProfits.length) ?
          otherProductsProfits.map(v => ({
            title1: v?.produktart || '',
            title2: v?.produktbez || '',
            title3: '',
            id: v?.id || '',
            sum: v?.aktWert || 0,
          }))
        : [])
      ]
    };
    Sonstige.summary = Sonstige.summary.sort((a, b) => a.title1.localeCompare(b.title1));

    this.getSum(Bankkonten) && this.left.push(Bankkonten);
    this.getSum(Multibanking) && this.left.push(Multibanking);
    this.getSum(Versicherungen) && this.left.push(Versicherungen);
    this.getSum(Beteiligungen) && this.left.push(Beteiligungen);
    this.getSum(Immobilien) && this.left.push(Immobilien);
    this.getSum(Sonstige) && this.left.push(Sonstige);
  }

  fillVerbindlichkeitenSide(credit, insuranceTable, balanceData, bankAccount, bankAccounts, otherProductsLoss) {
    // Loss (Right)
    const Dispo = {
      header: 'Dispo',
      summary: [],
      empty: true
    };

    const bankIndex1 = indexOfRow(bankAccount.tableHeaders, 'Gesellschaft');
    const bankIndex2 = indexOfRow(bankAccount.tableHeaders, 'Kontonummer');
    const bankInsuranceIndex1 = indexOfRow(insuranceTable.tableHeaders, 'gesellschaft');
    const bankInsuranceIndex2 = indexOfRow(insuranceTable.tableHeaders, 'nummer');
    const bankInsuranceSum = indexOfRow(insuranceTable.tableHeaders, 'rueckkaufswert');
    const bankInsurancePeriodName = indexOfRow(insuranceTable.tableHeaders, 'zw');
    const bankInsuranceSumPeriod = indexOfRow(insuranceTable.tableHeaders, 'praemie');
    const bankInsuranceBezeichnung = indexOfRow(insuranceTable.tableHeaders, 'bezeichnung');
    const insuranceSum = indexOfRow(insuranceTable.tableHeaders, 'praemie');

    const bankIndexSum = indexOfRow(bankAccount.tableHeaders, 'Kontostand');
    
    const indexKategorie = indexOfRow(insuranceTable.tableHeaders, 'kategorie');
    const insurancecreditIndex1 = indexOfRow(insuranceTable.tableHeaders, 'nummer');
    const insurancecreditIndex2 = indexOfRow(insuranceTable.tableHeaders, 'bezeichnung');
    const insurancecreditIndex3 = indexOfRow(insuranceTable.tableHeaders, 'praemie');
    const insurancecreditIndex4 = indexOfRow(insuranceTable.tableHeaders, 'zw');
    const insurancecreditIndexSum = indexOfRow(insuranceTable.tableHeaders, 'rueckkaufswert');

    const getRate = function(value, period) {
      switch (period) {
        case 'monatlich':
          return value / 12;
        case 'vierteljährlich':
          return value / 4;
        case 'halbjährlich':
          return value / 2;
      }
      return value;
    };

    const ImmobilienFinanzierung = {
      header: 'Kredite',
      headers: ['Kreditart', 'Beschreibung/Bemerkung', 'Rate', 'Wert'],
      isError: credit.isError,
      isLoaded: credit.isLoaded,
      isRestricted: credit.isRestricted,
      summary: [
        ...credit?.list?.map((v, i) => ({
          title1: v.form || '',
          title2: v.bezeichnung || '',
          title3: getRate(v.rate, v.zahlungsrythmus) || '0',
          sum: v.summe || 0,
          id: v.nummer || i,
          data: {
            tableHeaders: credit.tableHeaders,
            record: v
          }
        })) || [],
        ...insuranceTable.records
          .filter(v => v[indexKategorie] === 'Immobilienfinanzierung')
          .map(v => ({
            title1: v[insurancecreditIndex1] || '',
            title2: v[insurancecreditIndex2] || '',
            title3: getRate(v[insurancecreditIndex3], v[insurancecreditIndex4]) || '',
            id: v[insurancecreditIndex1],
            sum: v[bankInsuranceSum] || 0,
            sumPeriod: v[insuranceSum] || '',
            period: this.getPeriodLabel(v[bankInsurancePeriodName]) || '',
            insurance: true,
            data: {
              tableHeaders: insuranceTable.tableHeaders,
              record: v
            }
          })),
        ...balanceData.tableData
          .filter(v => v.headerLabels[0] === 'Immobilienfinanzierung')
          .map(table => {
              const balanceIndex1Ver1 = indexOfRow(table.tableHeaders, 'Anlageart');
              const balanceIndex2Ver1 = indexOfRow(table.tableHeaders, 'BEMERKUNG');
              const balanceIndexSumVer1 = indexOfRow(table.tableHeaders, 'AKT_WERT');
              const balanceIndexSumVer2 = indexOfRow(table.tableHeaders, 'ANFANGSWERT');
              return table.records.map(v => ({
                title1: v[balanceIndex1Ver1] || '',
                title2: v[balanceIndex2Ver1] || '',
                title3: '',
                id: v[balanceIndex2Ver1] || '',
                sum: v[balanceIndexSumVer1] || v[balanceIndexSumVer2] || 0,
                data: {
                  tableHeaders: table.tableHeaders,
                  record: v
                }
              }));
            }
          )
          .flat()
      ]
    };
    const sonstigeVerbindlichkeiten = {
      header: 'Weitere',
      headers: ['Anlageart', 'Beschreibung/Bemerkung', 'Wert'],
      isError: balanceData.isError,
      isLoaded: balanceData.isLoaded,
      isRestricted: balanceData.isRestricted,
      summary: [
        ...((otherProductsLoss && otherProductsLoss.length) ?
          otherProductsLoss.map(v => ({
            title1: v?.produktart || '',
            title2: v?.produktbez || '',
            title3: '',
            id: v?.id || '',
            sum: v?.aktWert || 0,
          })) : []),
      ]
    };

    const BankRight = {
      header: 'Bankkonten',
      headers: ['Konto', 'Kontonummer', '', 'Wert'],
      isError: bankAccounts.isError,
      isLoaded: bankAccounts.isLoaded,
      summary: [
        ...bankAccount.records
          .filter(v => parseFloat(v[bankIndexSum] || '0') < 0)
          .map((v, index) => ({
            title1: v[bankIndex1] || '',
            title2: v[bankIndex2] || '',
            title3: '',
            sum: v[bankIndexSum] || 0,
            id: v[bankIndex2],
            data: {
              tableHeaders: bankAccount.tableHeaders.filter(
                (item, index) => index !== bankIndex1 && index !== bankIndex1 && index !== bankIndexSum
              ),
              record: v.filter((item, index) => index !== bankIndex1 && index !== bankIndex1 && index !== bankIndexSum)
            }
          })),
      ]
    }

    const MultibankingRight = {
      header: 'Multibanking',
      headers: ['Konto', 'Kontonummer', '', 'Wert'],
      isError: bankAccounts.isError,
      isLoaded: bankAccounts.isLoaded,
      summary: [
        ...insuranceTable.records
          .filter(v => v[indexKategorie] === 'Bank' && parseFloat(v[bankInsuranceSum] || '0') < 0)
          .map(v => ({
            title1: v[bankInsuranceIndex1].shortName || '',
            title2: v[bankInsuranceIndex2] || '',
            title3: '',
            title4: v[bankInsuranceBezeichnung] || 'k.a.',
            id: v[bankInsuranceIndex2],
            sum: v[bankInsuranceSum] || 0,
            sumPeriod: v[bankInsuranceSumPeriod],
            period: this.getPeriodLabel(v[bankInsurancePeriodName]) || '',
            insurance: true,
            data: {
              tableHeaders: insuranceTable.tableHeaders.filter(
                (item, index) => index !== bankInsuranceIndex1 && index !== bankInsuranceIndex2 && index !== bankInsuranceSum
              ),
              record: v.filter(
                (item, index) => index !== bankInsuranceIndex1 && index !== bankInsuranceIndex2 && index !== bankInsuranceSum
              )
            }
          })),
        ...bankAccounts.accounts
          .filter(v => v.totalAmount < 0)
          .map(v => ({
          title1: v.displayName,
          title2: '',
          title3: v.iban || v.creditcardNo,
          sum: v.totalAmount || 0,
          id: v.iban || v.creditcardNo,
          multibanking: true,
          authNeeded: v.authNeeded,
          providerId: v.providerId,
          data: {
            tableHeaders: [
              { label: 'Display Name', key: 'Display Name', dataType: 'String' },
              { label: 'Kontonummer', key: 'Kontonummer', dataType: 'String' },
              { label: 'letzte Aktualisierung', key: 'letzte Aktualisierung', dataType: 'String' }
            ],
            record: [
              v.displayName,
              (v.iban || v.creditcardNo),
              (v.lastExternalUpdate ? dayjs(v.lastExternalUpdate).format('DD.MM.YYYY') : '')
            ]
          }
          }))
      ],
    };

    const multibankingRightSum = MultibankingRight.summary.reduce((acc, curr) => {
      acc = parseFloat(acc) + parseFloat(curr.sum);
      return acc;
    }, 0.0);

    // this.right.push(Bankkonten);
    this.getSum(ImmobilienFinanzierung) && this.right.push(ImmobilienFinanzierung);
    this.getSum(sonstigeVerbindlichkeiten) && this.right.push(sonstigeVerbindlichkeiten);
    this.getSum(BankRight) && this.right.push(BankRight);
    this.getSum(MultibankingRight) && this.right.push(MultibankingRight);
  }

  getPeriodLabel(period) {
    return getInsurancePeriodLabel(period);
  }

  getBalanceRelatedMeta(meta) {
    return Object.keys(meta).filter(k =>
      k.indexOf('mrsotherassets') >= 0 ||
      k.indexOf('mrsbankaccount') >= 0 ||
      k.indexOf('mrsclosedfunds') >= 0 ||
      k.indexOf('mrscredit') >= 0 ||
      k.indexOf('mrsdepotpositionsum') >= 0
    ).map(v => ({[v]: meta[v]}));
  }

  /**
   * Calculate sum of all items in the table by wertKey
   *
   * @param {*} table TableData type
   * @returns
   * @memberof BalanceService
   */
  getSum(table) {
    if (!table || !table.summary) {
      return 0;
    }
    return table.summary.reduce((accumulator, currentValue) => {
      if (currentValue) {
        accumulator = accumulator + (currentValue.sum ? parseFloat(currentValue.sum) : 0);
      }
      return accumulator;
    }, 0.0);
  }

  /**
   * Detect is it Losts (negative)
   *
   * @param {*} item
   * @returns
   * @memberof BalanceService
   */
  isRight(item) {
    return this.getSum(item) < 0;
  }

  getSumWertpapiere() {
    if (!this.portfolios?.length) {
      return 0
    }

    const hasRoles = store.getters[CORE_TYPES.GETTERS.HAS_ROLES]
    let portfoliosTemp = this.portfolios;
    if (hasRoles(ROLES.SHOW_WERTPAPIERE_VORSORGE)) {
      portfoliosTemp = this.portfolios.filter(item => item.depotid !== 'Vorsorge')
    }
    return portfoliosTemp
      .map(item => item.depotSum)
      .reduce((acc, cur) => parseFloat(acc) + parseFloat(cur), 0)
  }

  getSumWertpapiereVorsorge() {
    const hasRoles = store.getters[CORE_TYPES.GETTERS.HAS_ROLES]
    if (!this.portfolios?.length || !hasRoles(ROLES.SHOW_WERTPAPIERE_VORSORGE)) {
      return 0
    }

    let portfoliosTemp = this.portfolios;
    if (hasRoles(ROLES.SHOW_WERTPAPIERE_VORSORGE)) {
      portfoliosTemp = this.portfolios.filter(item => item.depotid === 'Vorsorge')
    }
    return portfoliosTemp
      .map(item => item.depotSum)
      .reduce((acc, cur) => parseFloat(acc) + parseFloat(cur), 0)
  }

  /**
   * Convert portfolio positions sum to a proper format for next components usage
   *
   * @param {*} items
   * @returns
   * @memberof BalanceService
   */
  prepareDepotpositionSum(items, id, multibankingPortfolios) {
    const depotIdIndex = items.tableHeaders.findIndex(item => item.key === 'depotid');
    const depotNameIndex = items.tableHeaders.findIndex(item => item.key === 'depotName');
    const depotSumIndex = items.tableHeaders.findIndex(item => item.key === 'Euro');
    const performanceIndex = items.tableHeaders.findIndex(item => item.key === 'GuV');

    let additional = [];
    if (this.isShowMultibanking && multibankingPortfolios.data.depotList) {
      const accounts = {};
      multibankingPortfolios.data.depotList.map(p => {
          accounts[p.account.externalId] = {
          depotid: p.account.externalId,
          depotName: p.account.displayName,
          depotSum: p.account.totalAmount,
          guv: null,
          guvPA: null,
          multibanking: true,
        };
      });
      additional = Object.keys(accounts).map(k => (accounts[k]));
    }

    return items.records
      .filter(v => v[depotIdIndex] !== 'WERTPAPIER_VERSICHERUNG'
        && (id === 'ALLE_DEPOTS_ANZEIGEN' || !id || id === v[depotIdIndex]))
      .map(v => ({
        depotid: v[depotIdIndex],
        depotName: v[depotNameIndex],
        depotSum: v[depotSumIndex],
        guv: v[performanceIndex] && v[performanceIndex].procent,
        guvPA: v[performanceIndex] && (v[performanceIndex].procentPA || v[performanceIndex].procent)
      })).concat(additional);
  }

  getSumCredit(credit) {
    const index = credit.tableHeaders.findIndex(item => item.key === 'Summe');
    return credit.list.reduce((accumulator, currentValue) => {
      accumulator = accumulator + (currentValue[index] ? parseFloat(currentValue[index]) : 0);
      return accumulator;
    }, 0.0);
  }

  // @todo: remove temporary fix, fix it on backend
  fixYearDataType(table) {
    const index = table.data.tableHeaders.findIndex(item => item.key === 'Jahr');
    table.data.tableHeaders[index].dataType = 'String';
    return table;
  }

  clearCache() {
    this.balanceCache = {};
  }

  get isShowMultibanking() {
    return false; // AuthenticationService.getRight('isMultibankingAccountUser') && AuthenticationService.getRight('isUserAllowedToGetMultibankingData');
  }
}

export const balanceCalc = new BalanceService();