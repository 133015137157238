import Vue from 'vue';
import MENU_CONFIG_TYPES from './types';
import { getInitialState } from './index';

export default {

  [MENU_CONFIG_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState());
  },

  // Makler context when editing a Mitarbeiter data
  [MENU_CONFIG_TYPES.MUTATIONS.SET_SELECTED_UNTERNR](state, { configContext, unternr, }) {
    if (!configContext || !unternr && unternr !== 0) return;
    Vue.set(state.selectedUnternr, configContext, unternr);
  },
  [MENU_CONFIG_TYPES.MUTATIONS.RESET_SELECTED_UNTERNR](state, { configContext, }) {
    if (!configContext) return;
    Vue.delete(state.selectedUnternr, configContext);
  },

  // Common
  [MENU_CONFIG_TYPES.MUTATIONS.SET_FLAT_MENU_CONFIG](state, { configContext, flatMenuConfig, }) {
    if(!configContext) return;
    Vue.set(state.flatMenuConfig, configContext, flatMenuConfig || []);

    const flatByPath = {};

    for (let index = 0; index < flatMenuConfig.length; index++) {
      const menu = flatMenuConfig[index];
      flatByPath[menu.path] = menu;
    }

    Vue.set(state.flatMenuConfigByPath, configContext, flatByPath);
  },
  [MENU_CONFIG_TYPES.MUTATIONS.SET_USER_LEVELS](state, { configContext, userLevels }) {
    if (!configContext) return;
    Vue.set(state.userLevels, configContext, userLevels || []);
  },
  [MENU_CONFIG_TYPES.MUTATIONS.RESET_COMMON_DATA](state, { configContext }) {
    if(!configContext) return;
    Vue.delete(state.flatMenuConfig, configContext);
    Vue.delete(state.flatMenuConfigByPath, configContext);
    Vue.delete(state.userLevels, configContext);
  },

  // Options Menu Config
  [MENU_CONFIG_TYPES.MUTATIONS.SET_OPTIONS_MENU_CONFIG](state, { configContext, userLevel, configId, config, }) {
    if (!configContext || !userLevel || !configId) return;

    if(!(configContext in state.optionsMenuConfig)) {
      Vue.set(state.optionsMenuConfig, configContext, {});
    }
    if(!(configId in state.optionsMenuConfig[configContext])) {
      Vue.set(state.optionsMenuConfig[configContext], configId, {});
    }
    Vue.set(state.optionsMenuConfig[configContext][configId], userLevel, {
      ...state.optionsMenuConfig[configContext]?.[configId]?.[userLevel] || {},
      ...config || {},
    });
  },
  [MENU_CONFIG_TYPES.MUTATIONS.RESET_OPTIONS_MENU_CONFIG](state, { configContext }) {
    if (!configContext) return;
    Vue.set(state.optionsMenuConfig, configContext, {});
  },
  [MENU_CONFIG_TYPES.MUTATIONS.RESET_OPTIONS_MENU_CONFIG_BY_USER_LEVEL](state, { configContext, configId, userLevel, }) {
    if(!configContext || !configId || !userLevel) return;
    if(configContext in state.optionsMenuConfig && configId in state.optionsMenuConfig[configContext]) {
      Vue.set(state.optionsMenuConfig[configContext][configId], userLevel);
    }
  },
  [MENU_CONFIG_TYPES.MUTATIONS.SET_OPTIONS_MENU_CONFIG_EDITED](state, { configContext, userLevel, configId, changedMenu, }) {
    if (!configContext || !userLevel || !configId) return;

    if(!(configContext in state.optionsMenuConfigEdited)) {
      Vue.set(state.optionsMenuConfigEdited, configContext, {});
    }
    if(!(configId in state.optionsMenuConfigEdited[configContext])) {
      Vue.set(state.optionsMenuConfigEdited[configContext], configId, {});
    }
    Vue.set(state.optionsMenuConfigEdited[configContext][configId], userLevel, [ ...changedMenu, ]);
  },
  [MENU_CONFIG_TYPES.MUTATIONS.RESET_OPTIONS_MENU_CONFIG_EDITED](state, { configContext }) {
    if (!configContext) return;
    Vue.set(state.optionsMenuConfigEdited, configContext, {});
  },
  [MENU_CONFIG_TYPES.MUTATIONS.RESET_OPTIONS_MENU_CONFIG_EDITED_BY_USER_LEVEL](state, { configContext, configId, userLevel, }) {
    if(!configContext || !configId || !userLevel) return;
    if(configContext in state.optionsMenuConfigEdited && configId in state.optionsMenuConfigEdited[configContext]) {
      Vue.delete(state.optionsMenuConfigEdited[configContext][configId], userLevel);
    }
  },

  // Options Menu Permission Config
  [MENU_CONFIG_TYPES.MUTATIONS.SET_OPTIONS_MENU_PERMISSION_CONFIG](state, { configContext, userLevel, permissionConfig, }) {
    if(!(configContext in state.optionsMenuPermissionConfig)) {
      Vue.set(state.optionsMenuPermissionConfig, configContext, {});
    }
    Vue.set(state.optionsMenuPermissionConfig[configContext], userLevel, { ...permissionConfig || {}, });
  },
  [MENU_CONFIG_TYPES.MUTATIONS.SET_OPTIONS_MENU_PERMISSION_CONFIG_EDITED](state, { configContext, userLevel, path, visible, }) {
    if(!(configContext in state.optionsMenuPermissionConfigEdited)) {
      Vue.set(state.optionsMenuPermissionConfigEdited, configContext, {});
    }

    const { optionsMenuPermissionConfig, optionsMenuPermissionConfigEdited, } = state;
    Vue.set(state.optionsMenuPermissionConfigEdited[configContext], userLevel, {
      ...optionsMenuPermissionConfig?.[configContext]?.[userLevel] || {}, // add existent config saved
      ...optionsMenuPermissionConfigEdited?.[configContext]?.[userLevel] || {}, // add existent config edited
      [path]: { visible, },
    });
  },
  [MENU_CONFIG_TYPES.MUTATIONS.RESET_OPTIONS_MENU_PERMISSION_CONFIG_EDITED](state) {
    Vue.set(state, 'optionsMenuPermissionConfigEdited', {});
  },
  [MENU_CONFIG_TYPES.MUTATIONS.FIND_ALL_OPTIONS_MENU_PERMISSION_CONFIG_ALL_MITARBEITER_SUCCESS](state, payload) {
    Vue.set(state, 'allOptionsMenuPermissionConfigAllMitarbeiter', payload);
  },
  [MENU_CONFIG_TYPES.MUTATIONS.RESET_OPTIONS_MENU_PERMISSION_CONFIG_EDITED_BY_USER_LEVEL](state, { configContext, userLevel, }) {
    if(!configContext || !userLevel) return;
    if(configContext in state.optionsMenuPermissionConfigEdited) {
      Vue.delete(state.optionsMenuPermissionConfigEdited[configContext], userLevel);
    }
  },

}
