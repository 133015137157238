import MR_MONEY_TYPES from './types';
import CORE_TYPES from '../core/types';
import axios from 'axios';
import { buildMessage, } from '../../helpers/log-message-helper';
import * as utils from '../../helpers/commonfunctions';

export default {
  [MR_MONEY_TYPES.ACTIONS.GET_LISTING]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {
      let serviceUrl = '/mrMoney/getListing';
     
  
      axios.post(rootState.core.apiAddress + serviceUrl, payload, config).then(response => {
        if (response && response.data) {
  
          commit(MR_MONEY_TYPES.MUTATIONS.GET_LISTING_SUCCESS, response.data);
          resolve(response?.data);
        }      
  
      }).catch((error) => {
        reject(error);
      });
    });



  },

  [MR_MONEY_TYPES.ACTIONS.RESCANN_ALL]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true,
      cancelableRequest: {
        title: 'Erneut analysieren und autom. zuordnen',
      },      
    };

    return new Promise((resolve, reject) => {
      let serviceUrl = '/mrMoney/rescanAll';
     
  
      axios.post(rootState.core.apiAddress + serviceUrl, payload, config).then(response => {
        if (response && response.data) {
  
          resolve(response?.data);
        }      
  
      }).catch((error) => {
        reject(error);
      });
    });



  },
  

  [MR_MONEY_TYPES.ACTIONS.UPLOAD_AND_ANALYSE_PDF]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {
      let serviceUrl = '/mrMoney/uploadAndAnalysePdf';

      if (payload?.fileId) {
        serviceUrl = `${serviceUrl}?fileId=${payload?.fileId}`;
      }
     
  
      axios.get(rootState.core.apiAddress + serviceUrl, config).then(response => {
        if (response && response.data) {
  
          resolve(response?.data);
        }      
  
      }).catch((error) => {
        reject(error);
      });
    });



  },

  [MR_MONEY_TYPES.ACTIONS.TRASH_PDF]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {
      let serviceUrl = '/mrMoney/trashPDF';

      if (payload?.mrMoneyId) {
        serviceUrl = `${serviceUrl}?mrMoneyId=${payload?.mrMoneyId}`;
      }
     
  
      axios.delete(rootState.core.apiAddress + serviceUrl, config).then(response => {
        if (response && response.data) {
  
          resolve(response?.data);
        }      
  
      }).catch((error) => {
        reject(error);
      });
    });



  },
  

  

}