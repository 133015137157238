<template>
  <EntitySelector
    :label="label"
    :value="value"
    :isComponentHalfSize="isComponentHalfSize"
    :values="values"
    :disabled="disabled"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
    @new="newBankverbindung()"
    @edit="editBankverbindung()"
  />
</template>

<script>
import CUSTOMERDATA_TYPES from '@/store/customerData/types';

import EntitySelector from '@/components/core/forms/EntitySelector.vue';


export default {
  props: {
    label: {
      type: String,
      default: 'Bankverbindung',
    },
    value: {
    },
    isComponentHalfSize: {
      type: Boolean,
      default: false
    },
    values: {
      type: [Array, String],
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    backButtonPayload() {
      return {
        backButton: true,
      }
    },
  },
  methods: {
    newBankverbindung() {
      const personId = 'person1';
      this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.UPDATE_SELECTED_CUSTOMER_DATA, personId);
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GOTO_BANKVERBINDUNG, this.backButtonPayload);
    },
    editBankverbindung() {
      const personId = 'person1';
      this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.UPDATE_SELECTED_CUSTOMER_DATA, personId);
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GOTO_BANKVERBINDUNG, this.backButtonPayload);
    },
  },
  components: {
    EntitySelector,
  },
}
</script>
