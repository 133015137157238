var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", { attrs: { title: _vm.title } }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("InputField", {
            attrs: { label: "Login-Name", isComponentHalfSize: "" },
            model: {
              value: _vm.ssoName,
              callback: function($$v) {
                _vm.ssoName = $$v
              },
              expression: "ssoName"
            }
          }),
          _c("ComboBox", {
            attrs: {
              label: "Berechtigung",
              values: _vm.comboBerechtigung,
              isComponentHalfSize: ""
            },
            model: {
              value: _vm.permission,
              callback: function($$v) {
                _vm.permission = $$v
              },
              expression: "permission"
            }
          }),
          _c("InputField", {
            attrs: {
              label: "temporäres Passwort (Mindestens 6 Zeichen)",
              type: "password",
              isComponentHalfSize: ""
            },
            model: {
              value: _vm.password,
              callback: function($$v) {
                _vm.password = $$v
              },
              expression: "password"
            }
          }),
          _c("div", { staticClass: "mb-4" }, [
            _vm._v(
              "Verwenden Sie ein Passwort, dass nicht Ihrem Loginkennwort entspricht und teilen Sie dieses im Nachgang dem zu registrierenden Nutzer mit."
            )
          ]),
          _c("div", { staticClass: "mb-0" }, [
            _vm._v(
              "Wenn Sie einen SSO-Login angeben, wird die Einladung an das entsprechenden SSO-Konto gesendet und nur dessen Besitzer kann sich damit anmelden."
            )
          ]),
          _c("div", { staticClass: "my-0" }, [
            _vm._v(
              "Die Einladung sollte also sofort mit einem SSO-Konto verknüpft werden, um die Zugangsberechtigung dauerhaft zu behalten."
            )
          ]),
          _c("div", [
            _vm._v(
              "Verwenden Sie nicht das Passwort, das Sie für ihre Anmeldung verwenden!"
            )
          ]),
          _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c("router-link", { attrs: { to: { name: "sso" } } }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function($event) {
                        return _vm.updateBreadcrumb()
                      }
                    }
                  },
                  [_vm._v("zurück")]
                )
              ]),
              _c(
                "BaseButton",
                {
                  staticClass: "ml-3",
                  attrs: {
                    disabled: !_vm.ssoName || _vm.password.length < 6,
                    isPrimary: ""
                  },
                  on: { click: _vm.createInvite }
                },
                [_vm._v("Einladen")]
              )
            ],
            1
          ),
          _c(
            "BaseModal",
            {
              ref: "notExist",
              attrs: { labelButtonCancel: "Ok", showConfirmButton: false },
              on: { onCancelButton: _vm.closeErrorModal },
              scopedSlots: _vm._u([
                {
                  key: "modalTitle",
                  fn: function() {
                    return [
                      _c("PhInfo", { staticStyle: { color: "orange" } }),
                      _vm._v(" Fehler ")
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [_c("div", [_vm._v(" " + _vm._s(_vm.errorStr) + " ")])]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }