<template>
  <div class="intern-view">
    <router-view></router-view>
    <!-- <span v-if="isTestBroker">Only for testing:</span> -->
  </div>
</template>

<script>
import CORE_TYPES from "@/store/core/types";
import { mapGetters, dispatch } from "vuex";

export default {
  components: {
  },
  computed: {
    ...mapGetters({
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
      roles: CORE_TYPES.GETTERS.GET_USER_ROLES,
    }),
    isTestBroker() {
      return this.roles.includes('FK_TEST_BROKER')
    },
  },
  mounted() {
  } 
}
</script>