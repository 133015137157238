var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("InsuranceOptionMenu", {
        attrs: {
          title: "E-Mails & Aktivitäten",
          insuranceId: _vm.versicherungId,
          headerActions: _vm.headerActions
        },
        on: {
          "action-NEW-ACTIVITY": function($event) {
            return _vm.newAppointment()
          },
          "action-OLD-POLICE-NUMBERS": function($event) {
            return _vm.showOldPoliceNumbers()
          },
          "action-EMAIL-KUNDE": function($event) {
            return _vm.emailStart(false)
          },
          "action-EMAIL-GESELLSCHAFT": function($event) {
            return _vm.emailStart(true)
          },
          "action-SCHADEN-MELDEN": function($event) {
            return _vm.createSchaden()
          },
          "action-BRIEF-ERSTELLEN": function($event) {
            return _vm.briefStart()
          },
          "action-KUENDIGUNG-VERSICHERUNGEN": function($event) {
            return _vm.emailCancel()
          },
          "action-AUFGABE-HINZUFUEGEN": function($event) {
            return _vm.handleAddActivity()
          },
          "action-VERSICHERUNG-KOPIEREN": function($event) {
            return _vm.versicherungKopierenBestaetigung()
          },
          "action-DOKUMENTE-HOCHLADEN": function($event) {
            return _vm.dateiHochladen()
          },
          "action-EXTERNEN-ZUGANG-AUFRUFEN": function($event) {
            return _vm.externenZugangOeffnen()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [_vm._v(" E-Mails & Aktivitäten ")]
            },
            proxy: true
          },
          {
            key: "subtitle",
            fn: function() {
              return [
                _vm.gesellschaftName
                  ? [
                      _c("span", { staticClass: "subtitle-part" }, [
                        _c(
                          "a",
                          {
                            on: {
                              click: function($event) {
                                return _vm.navigateToGesellschaft()
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.gesellschaftName))]
                        )
                      ]),
                      _c("span", [_vm._v(" | ")])
                    ]
                  : _vm._e(),
                _vm.bezeichnung
                  ? [
                      _c("span", { staticClass: "subtitle-part" }, [
                        _vm._v(_vm._s(_vm.bezeichnung))
                      ]),
                      _vm.nummer || _vm.statusAktuell || _vm.showKennzeichen
                        ? _c("span", [_vm._v(" | ")])
                        : _vm._e()
                    ]
                  : _vm._e(),
                _vm.nummer
                  ? [
                      _c(
                        "router-link",
                        {
                          staticClass: "subtitle-part",
                          attrs: { to: _vm.overviewLink(_vm.versicherungId) }
                        },
                        [_vm._v(_vm._s(_vm.nummer))]
                      ),
                      _vm.statusAktuell || _vm.showKennzeichen
                        ? _c("span", [_vm._v(" | ")])
                        : _vm._e()
                    ]
                  : _vm._e(),
                _vm.statusAktuell
                  ? [
                      _c(
                        "span",
                        { staticClass: "subtitle-part" },
                        [
                          _c("Pill", {
                            attrs: {
                              label: _vm.statusAktuell,
                              type: _vm.statusToPillType[_vm.statusAktuell]
                            }
                          })
                        ],
                        1
                      ),
                      _vm.showKennzeichen
                        ? _c("span", [_vm._v(" | ")])
                        : _vm._e()
                    ]
                  : _vm._e(),
                _vm.showKennzeichen
                  ? [
                      _c("span", { staticClass: "subtitle-part" }, [
                        _vm._v(_vm._s(_vm.kennzeichen))
                      ])
                    ]
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("PostfachOverviewTable", {
        attrs: {
          title: "E-Mails & Aktivitäten",
          isInsurance: "",
          records: _vm.records,
          loading: _vm.loadingActivities,
          parentId: _vm.versicherungId,
          disabledColumns: ["name", "contact"]
        }
      }),
      _c("EmailVersand"),
      _c("CopyInsuranceConfirmationModal", {
        ref: "copyInsuranceConfirmationModal",
        on: { "open-item": _vm.openItemSelectedDetail }
      }),
      _c("ShowOldPoliceNumbersModal", {
        ref: "showOldPoliceNumbersModal",
        on: { "open-item": _vm.openItemSelectedDetail }
      }),
      _c("AddDocument", {
        ref: "addDocumentModal",
        attrs: { nodeId: _vm.nodeId, versicherungId: _vm.versicherungId },
        on: {
          close: function($event) {
            return _vm.goToDownloadArea(_vm.versicherungId, true)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }