<template>
  <DragnDropArea
    class="depotnummer-anfordern-button-component mb-4"
    hoverText="Hochladen"
    @files="onFileChange"
  >
    <BaseFileSelect @files="onFileChange"> Dokumente hochladen </BaseFileSelect>
    <BaseButton
      v-if="config.dokumentenarchiv"
      isPrimary
      @click="handleAddDocuments"
    >
      Dokumentenarchiv
    </BaseButton>
    <div v-if="antragData && antragData.antragsdatenId === ''">
      Dokumente werden beim ersten Speichern des Antrags hochgeladen
    </div>
    <br /><br />
    <div>
      <Table
        v-if="!loading && rows.length"
        :headers="headers"
        :rows="rows"
        :rowsPerPage="10"
        @action-DELETE="doDelete"
      >
        <template #fileName="row">
          <DownloadLink
            v-if="row.fileId"
            :title="row.fileName || 'Dokument'"
            :disabled="loading"
            :href="simpleFileLinkmaker(row.fileName, row.fileId)"
          />
          <span v-else>{{ row.fileName }}</span>
        </template>
      </Table>
      <div v-else-if="loading" class="text-centered"><AnimatedSpinner /></div>
      <div v-else>Keine Daten</div>
    </div>
  </DragnDropArea>
</template>

<script>
import { mapGetters } from "vuex";
import BaseFileSelect from "@/components/fileUpload/BaseFileSelect.vue";
import ANTRAG_TYPES from "@/store/antrag/types";
import DragnDropArea from "@/components/fileUpload/DragnDropArea.vue";
import Table from "@/components/table2/Table.vue";
import DownloadLink from "@/components/core/download/DownloadLink.vue";
import DOCUMENT_TYPES from "@/store/documents/types";
import {
  SlotColumn,
  ActionColumn,
  SimpleAction,
} from "@/components/table2/table_util.js";
import AnimatedSpinner from "@/components/core/AnimatedSpinner.vue";
import axios from "axios";
import { PhTrash } from "phosphor-vue";
import BaseButton from "@/components/core/BaseButton.vue";

export default {
  props: {
    antragId: {
      type: String,
    },
    label: {
      type: String,
    },
    antragData: {},
    config: {},
  },
  data() {
    return {
      filename: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      simpleFileLinkmaker: DOCUMENT_TYPES.GETTERS.GET_SIMPLE_FILE_LINK_MAKER,
      attachmentsGeldwaesche: ANTRAG_TYPES.GETTERS.GET_ATTACHMENTS_GELDWAESCHE,
    }),
    headers() {
      return {
        lockedLeft: [SlotColumn("fileName", "Bezeichnung")],
        lockedRight: [ActionColumn("actions", "")],
      };
    },
    rows() {
      if (!this.config?.tableRecords) return [];
      const actions = [SimpleAction("DELETE", PhTrash, "Löschen")];
      return this.config.tableRecords.map((row) => ({
        ...row,
        actions,
      }));
    },
  },
  components: {
    BaseFileSelect,
    DragnDropArea,
    Table,
    AnimatedSpinner,
    BaseButton,
    DownloadLink,
  },
  mounted() {
    this.getAttachmentsFromDocArchive();
  },

  methods: {
    commitUpdateAntragDataFromEvent(payload) {
      this.$emit("updateStore", payload);
    },
    getAttachmentsFromDocArchive() {
      let idsArray = [];

      if (this.attachmentsGeldwaesche?.length) {
        this.attachmentsGeldwaesche.forEach(attachment => {
          idsArray.push(attachment.id);
          this.config.tableRecords.push({
            fileName: attachment.name,
            fileId: attachment.id,
          });
        })

        const payload = {
          componentType: this.type,
          id: this.antragId,
          tempIds: idsArray.join() || "",
        };
        
        this.commitUpdateAntragDataFromEvent(payload);
        setTimeout(() => {
          this.handleSave();
        }, 1000);

        this.$store.commit(ANTRAG_TYPES.MUTATIONS.RESET_ATTACHMENTS_GELDWAESCHE);
      }
    },
    onFileChange(files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        let base64string = reader.result.split(",").pop();
        this.config.tableRecords.push({
          fileName: files[0].name,
          fileData: base64string,
          fileId: files[0].id,
        });
        this.$emit("input", {
          fileName: files[0].name,
          fileData: base64string,
          fileId: files[0].id,
        });
        this.handleSave();
      };
      reader.readAsDataURL(files[0]);
    },
    doDelete(row) {
      this.loading = true;
      axios
        .get(
          `${process.env.VUE_APP_API}/geldwaesche/deleteFile?fileId=${row.fileId}`
        )
        .then(() => {
          this.handleSave();
        })
        .then(() => (this.loading = false));
    },
    async handleSave() {
      this.loading = true;
      this.saveAntrag(true)
        .then(() => {
          setTimeout(() => {
            this.reloadAntrag();
          }, 1000);
        })
        .then(() => (this.loading = false));
    },
    handleAddDocuments() {
      this.navigateTo("antrag-dokumentenarchiv");
    },

    navigateTo(event) {
      this.$router.push({
        path: `/beratung/formulare/antrag/${this.$route.params.lagerstelle}/${this.$route.params.antragsName}/${this.$route.params.step}/${event}/`,
        query: {
          componentId: this.componentId,
          antragId: this.antragId,
          updateDataId: this.updateDataId,
        },
      });
    },

    saveAntrag(forceSave) {
      if (forceSave && this.antragId && this.$route.params.lagerstelle) {
        this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_ANTRAG_DATA, {
          id: this.antragId,
          data: {},
        });
      }
      return this.$store.dispatch(ANTRAG_TYPES.ACTIONS.SAVE_ANTRAG, {
        id: this.antragId,
        lagerstelle: this.$route.params.lagerstelle,
      });
    },
    reloadAntrag() {
      let payload = {
        id: this.antragId,
        beratungsMappeId: this.antragData.beratungsMappeId,
        antragsdatenId: this.antragData.antragsdatenId,
        lagerstelle: this.$route.params.lagerstelle,
        antragsName: this.$route.params.antragsName,
      };
      this.$store.commit(
        ANTRAG_TYPES.MUTATIONS.SET_ANTRAG_RELOAD_PAYLOAD,
        payload
      );
      return this.$store.dispatch(ANTRAG_TYPES.ACTIONS.RELOAD_ANTRAG, {
        id: this.antragId,
        antragsdatenId: this.antragData.antragsdatenId,
      });
    },
  },
  beforeDestroy() {
    if (this.$route.params.step == "aktionen") {
      this.handleSave();
    }
  },
};
</script>
