<template>
  <div>
    <div v-if="this.dat">
      <div class="naija-flag">
        <InputToggleSwitch
          label="format"
          :value="formatText"
          @input="changeFormat($event)"
          inLineLabel
          suppressValidationMessage
        >
        </InputToggleSwitch>
        <InputToggleSwitch
          v-if="this.haveSql"
          label="full sql"
          :value="fullSql"
          @input="changeFullSQL($event)"
          inLineLabel
          suppressValidationMessage
        >
        </InputToggleSwitch>
      </div>
      <Table
        tableId="d85dc90c-0816-11ed-861d-0242ac120002"
        :title="title"
        :headers="headers"
        :rows="rows"
        hidePagination
        @click-SID="clickSID"
        @click-SERIAL="clickSID"
        @click-STATUS="clickSID"
        @click-CLIENT_IDENTIFIER="clickSID"
        @click-TABLESPACE_NAME="clickTbs"
      >
        <template #SESSION_RECID="row">
          <DownloadLink
            target="_blank"
            rel="noopener noreferer"
            :title="row.SESSION_RECID"
            downloadServicePath="/getRmanOutput"
            :queryParams="{
              recId: row.SESSION_RECID,
              stam: row.SESSION_STAMP
            }"
          />
        </template>
      </Table>

      <DbmSessionDialog ref="DbmSessionDialog" />
    </div>
  </div>
</template>
  
  <script>
import DB_TYPES from "@/store/dbm/types";

import { mapGetters } from "vuex";
import Table from "@/components/table2/Table.vue";
import DbmSessionDialog from "@/views/dbm/DbmSessionDialog.vue";
import BRIDGE_TYPES from "@/store/bridge/types";
import DownloadLink from "@/components/core/download/DownloadLink.vue";
import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue";
import {
  HtmlColumn,
  DateTimeColumn,
  PillColumn,
  TextColumn,
  ActionColumn,
  SimpleAction,
  NumberColumn,
  SlotColumn,
} from "@/components/table2/table_util.js";

export default {
  props: {
    dat: {
      type: Object,
    },
  },
  components: {
    Table,

    DownloadLink,

    DbmSessionDialog,

    InputToggleSwitch,
  },

  computed: {
    ...mapGetters({
      formatText: DB_TYPES.GETTERS.FORMAT_SWITCH,
      fullSql: DB_TYPES.GETTERS.FULL_SQL,
    }),

    title() {
      if (this.dat != null) {
        return this.dat.label;
      } else {
        return null;
      }
    },
    disabledColumns() {
      if (this.setup && this.setup.modus != "VERWALTUNG") {
        return ["actions", "maklerVerwalter", "dateUpdated", "bemerkung"];
      } else {
        return [];
      }
    },

    records() {
      if (!this.dat) {
        return null;
      }

      return this.dat.data;
    },
    headers() {
      let result = {
        lockedLeft: [],
        lockedRight: [],
        center: [],
      };
      this.haveSql = false;

      if (this.dat && this.dat.columns) {
        let res = result.center;
        this.dat.columns.forEach((sub) => {
          if (sub.key == "SQL_TEXT") {
            this.haveSql = true;
          }
          if (
            result.lockedLeft.length == 0 &&
            (sub.key == "SID" ||
              sub.key == "SERIAL" ||
              sub.key == "SEGMENT_NAME")
          ) {
            res = result.lockedLeft;
          } else {
            res = result.center;
          }
          if (sub.columnType) {
            if (sub.columnType == "NumberColumn") {
              res.push(NumberColumn(sub.key, sub.label, sub.scale));
            } else if (sub.columnType == "TextColumn") {
              if (sub.key == "SESSION_RECID") {
                res.push(SlotColumn(sub.key, sub.label));
              } else {
                res.push(
                  this.formatText
                    ? HtmlColumn(sub.key, sub.label)
                    : TextColumn(
                        sub.key,
                        sub.label,
                        sub.key == "SEGMENT_NAME" ? 4000 : 2000
                      )
                );
              }
            } else if (sub.columnType == "DateTimeColumn") {
              res.push(DateTimeColumn(sub.key, sub.label, 1000));
            }
          }
        });

        result.center
          .filter(
            (column) =>
              column.key == "TABLESPACE_NAME" ||
              column.key == "SID" ||
              column.key == "CLIENT_IDENTIFIER" ||
              column.key == "SERIAL" ||
              column.key == "STATUS"
          )
          .forEach((c) => c.makeLink());
        result.lockedLeft
          .filter(
            (column) =>
              column.key == "TABLESPACE_NAME" ||
              column.key == "SID" ||
              column.key == "CLIENT_IDENTIFIER" ||
              column.key == "SERIAL" ||
              column.key == "STATUS"
          )
          .forEach((c) => c.makeLink());
      }
      return result;
    },
    rows() {
      if (!this.records) return [];
      return this.records.map((record) => {
        const row = {
          ...record,
        };

        if (row.SQL_TEXT && !this.fullSql && row.SQL_TEXT.length > 1000) {
          row.SQL_TEXT = row.SQL_TEXT.substring(0, 999) + "...................";
        }

        return row;
      });
    },
  },
  data() {
    return {
      apiAddress: process.env.VUE_APP_API,
      haveSql: true,

      timer: null,
    };
  },
  methods: {
    clickSID(row) {
      this.$refs.DbmSessionDialog?.open(row.SID, row.SERIAL);
      this.$store.commit(DB_TYPES.MUTATIONS.START_TIME, null);
    },
    clickTbs(row) {
      this.$emit("onClickTbs", row);
      // this.$store.commit(DB_TYPES.MUTATIONS.START_TIME,null);
      //console.log('ref', this.$refs);
      //this.$refs.DbmSegmentsDialog?.open(row.TABLESPACE_NAME);
    },
    changeFormat(event) {
      this.$store.commit(DB_TYPES.MUTATIONS.FORMAT_SWITCH, event);
    },
    changeFullSQL(event) {
      this.$store.commit(DB_TYPES.MUTATIONS.FULL_SQL, event);
    },
  },
};
</script>
  
  
  <style scoped>
.subject {
  display: inline-block;
  max-width: 700px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.horizint {
  display: flex;
  flex-direction: row;
}
</style>