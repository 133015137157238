<template>
  <BaseToolbarColorPicker @change="action" :value="value" :disabled="disabled"/>
</template>

<script>
import { PhTextBolder } from 'phosphor-vue';
import BaseToolbarColorPicker from '../base-components/BaseToolbarColorPicker.vue';
import FC_CONFIG_TYPES from '@/store/fcConfig/types';
import { mapGetters } from 'vuex';

const ADD_PARAMETER = 'HTML_EDITOR_DEFAULT_FONT_COLOR';

export default {
  components: {
    BaseToolbarColorPicker,
  },

  props: {
    editor: {
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    ...mapGetters({
      addParameters: FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_ADD_PARAMETER
    }),
    icon() {
      return PhTextBolder;
    },
    applySavedSettings() {
      return this.editor?.options?.editorProps?.attributes?.applySavedSettings;
    },
    savedOption() {
      return this.applySavedSettings ? this.addParameters?.[ADD_PARAMETER]?.content?.trim() : '';
    },
    value() {
      const current = this.editor?.getAttributes('textStyle').color;

      return current || this.savedOption || '';
    }
  },

  methods: {
    action(color) {
      this.editor?.chain().focus().setColor(color).run()
    },
  }

}
</script>
