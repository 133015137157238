<template>
  <div>
    <div class="box__container" v-if="hasAllImagesAdded && isEditable && isFA">
      <div class="row mb-2">
          <InputToggleSwitch v-model="legitimation.istBestaetigt" @input="$emit('addCustomerDataEdited')" label="Ich bestätige, dass die Kopie mit dem Original überein stimmt" 
            inLineLabel suppressValidationMessage :disabled="isLoading || !isEditable || !hasLegitimType || disabled"/>
      </div>
    </div>

    <div class="scans-container">
      <div class="box__container">
        <ImageUpload
          class="scan-container"
          title="Vorderseite"
          hoverText="Vorderseite hier ablegen"
          edgeCorrection
          :image="vorderseiteImage"
          :loading="isLoading && !!fileToUpload.vorderseite"
          :disabled="isLoading || !isEditable || !hasLegitimType || disabled"
          :config="imageUploadConfig"
          photoCamera="back"
          photoHighQuality
          @file="convertFileIfNeeded([$event], true)"
          @change="updateImage($event, true)"
        />
      </div>
      <div class="box__container">
        <ImageUpload
          class="scan-container"
          title="Rückseite"
          hoverText="Rückseite hier ablegen"
          edgeCorrection
          :image="rueckseiteImage"
          :loading="isLoading && !!fileToUpload.rueckseite"
          :disabled="isLoading || !isEditable || isRueckseiteUploadDisabled || disabled"
          :config="imageUploadConfig"
          photoCamera="back"
          photoHighQuality
          @file="convertFileIfNeeded([$event], false)"
          @change="updateImage($event, false)"
        />
      </div>
      <div v-if="isMeldebescheinigungUploadVisible" class="box__container">
        <ImageUpload
          class="scan-container"
          title="Meldebescheinigung"
          hoverText="Meldebescheinigung hier ablegen"
          edgeCorrection
          :image="meldebescheinigungImage"
          :loading="isLoading && !!fileToUpload.meldebescheinigung"
          :disabled="isLoading || !isEditable || disabled"
          :config="imageUploadConfig"
          photoCamera="back"
          photoHighQuality
          @file="convertFileIfNeeded([$event], false, true)"
          @change="updateImage($event, false, true)"
        />
      </div>
    </div>

    <div class="box__container" v-if="hasImageAdded && isEditable && !isSmallScreen">
      <div class="row mb-0">
        <div class="col-auto">
          <BaseButton isSecondary @click="$refs.deleteImages.open()" :disabled="isLoading || disabled">Gespeicherte Ausweisfotos löschen</BaseButton>
          
          <template v-if="hasAllImagesAdded || (legitimation && hasVorderseiteImage && (legitimation.legitimType=='Reisepass' || isRueckseiteUploadDisabled))">
            <div  v-if="mitarbeiterId"></div>
            <BaseButton 
              @click="saveLegitimation" 
              :disabled="isLoading || disabled" 
              v-else-if="isFA && (legitimationsurkundeChanged || (legitimation.istBestaetigt && (!legitimation.signatureInfo || (legitimation.signatureInfo && !legitimation.signatureInfo.fileId))))"
            >
              Generiere ein PDF Dokument
            </BaseButton>

            <SignoViewerLink
              v-else-if="isFA && legitimation.signatureInfo && legitimation.signatureInfo.fileId && !legitimation.signatureInfo.isSigned && legitimation.signatureInfo.signoURL"
              asButton
              title="Signieren"
              requestURL
              extraParams="noRedirect=true"
              :href="getSignoviewerLink" 
              :disabled="disabled"
            />

            <DownloadLink 
              asButton
              title="Herunterladen"
              :disabled="isLoading || disabled" 
              v-else
              :href="getDownloadLink"
            />  
          </template>
        </div>
      </div>
    </div>

    <BaseModal
      ref="confirmReadImageModal"
      modalTitle="Bestätigung"
      labelButtonConfirm="Ja"
      labelButtonCancel="Nein"
      @onConfirmButton="readImage(tempFiles, tempFilesFront)">
      Möchten Sie die Daten aus dem Ausweis auslesen lassen?
    </BaseModal>

    <BaseModal 
      ref="deleteImages"
      modalTitle="Löschen Legitimationsurkunde"
      labelButtonConfirm="Ja"
      labelButtonCancel="Nein"
      @onConfirmButton="deleteImages()">
      Möchten Sie die gespeicherte Legitimationsurkunde löschen?
    </BaseModal>
  </div>
</template>

<script>
import BaseButton from '@/components/core/BaseButton.vue'
import BaseModal from '@/components/core/BaseModal.vue'
import { mapGetters } from 'vuex'
import CUSTOMERDATA_TYPES from '@/store/customerData/types'
import { uploadFileChunked, pdfToImage } from '@/helpers/upload-helper'
import ImageUpload from '@/components/fileUpload/ImageUpload.vue'
import CORE_TYPES from '@/store/core/types';
import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue";
import DownloadLink from '@/components/core/download/DownloadLink.vue'
import { makeQueryParam } from '@/helpers/utils-helper';
import SignoViewerLink from '@/components/core/download/SignoViewerLink.vue'
import { base64ToArrayBuffer } from "@/helpers/download-helper";

const isPdf = file => file?.name?.endsWith?.(".pdf");
const fileNameNoExtension = file => file?.name && file.name.substr(0, file.name.lastIndexOf('.')) || 'untitled';
import EVENT_BUS, { GESPEICHERTE_AUSWEISFOTOS_LOESCHEN, SAVE_LEGITIMATION} from '@/event-bus';

export default {
  props: {
    legitimation: {
    },
    mitarbeiterId: {
      type: String,
      default: '',
    },
    isMitarbeiterEditable: {
      type: Boolean,
      default: true,
    },
    isMitarbeiter: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['read-image-data'],
  computed: {
    ...mapGetters({
      isFA: CORE_TYPES.GETTERS.IS_FA,
      isFK: CORE_TYPES.GETTERS.IS_FK,
      personId: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_ID,
      customerData: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_DATA,
      legitimationsurkundeChanged: CUSTOMERDATA_TYPES.GETTERS.LEGITIMATIONSURKUNDE_CHANGED,
      apiAddress: CORE_TYPES.GETTERS.API_ADDRESS,
      token: CORE_TYPES.GETTERS.GET_TOKEN,
      isSmallScreen: CORE_TYPES.GETTERS.IS_SMALL_SCREEN,
    }), 
    hasVorderseiteImage() {
      return !!this.vorderseiteUploadPreview || !!this.legitimation?.bildVorderseite;
    },
    hasRueckseiteImage() {
      return !!this.rueckseiteUploadPreview || !!this.legitimation?.bildRueckseite;
    },
    hasImageAdded() {
      return !!this.legitimation?.bildVorderseite || !!this.legitimation?.bildRueckseite || this.isMeldebescheinigungUploadVisible && !!this.legitimation?.bildMeldebescheinigung;
    },
    hasAllImagesAdded() {
      return !!this.legitimation?.bildVorderseite && (this.isFA || !!this.legitimation?.bildRueckseite);
    },
    isLoading() {
      return this.uploading || this.reading || this.updatingProfile || this.savingLegitimation || this.convertingFile;
    },
    vorderseiteImage() {
      if(this.vorderseiteUploadPreview) {
        return this.vorderseiteUploadPreview;
      } else {
        return this.getImgSrc(this.legitimation?.bildVorderseite);
      }
    },
    rueckseiteImage() {
      if(this.rueckseiteUploadPreview) {
        return this.rueckseiteUploadPreview;
      } else {
        return this.getImgSrc(this.legitimation?.bildRueckseite);
      }
    },
    meldebescheinigungImage() {
      if(this.meldebescheinigungUploadPreview) {
        return this.meldebescheinigungUploadPreview;
      } else {
        return this.getImgSrc(this.legitimation?.bildMeldebescheinigung);
      }
    },
    isEditable() {
      return this.customerData?.isLegitimationEditable || this.isMitarbeiterEditable;
    },
    getDownloadLink() {
      const token = this.token

      if (this.isFA && this.legitimation.signatureInfo && this.legitimation.signatureInfo.fileId) {
        const id = this.legitimation?.signatureInfo?.fileId;        
        const params = makeQueryParam({ id, token })      
        const fileName = this.legitimation?.signatureInfo?.fileName;

        return `${this.apiAddress}/download_service/downloadFileById/${fileName}?${params}`;
      } else {
        let personId = this.customerData.personId;      
        if(this.mitarbeiterId){
          personId = this.mitarbeiterId
        }  
        const params = makeQueryParam({ personId, token })  

        return `${this.apiAddress}/download_service/generateLegitimationPDF?${params}`;
      }

    },
    getSignoviewerLink() {
      return this.legitimation?.signatureInfo?.signoURL;
    },
    hasLegitimType() {
      return !!this.legitimation?.legitimType;
    },
    isRueckseiteUploadDisabled() {
      return !this.hasLegitimType || this.legitimation?.legitimType === 'Geburtsurkunde';
    },
    isMeldebescheinigungUploadVisible() {
      return this.isFK && !this.isMitarbeiter && this.hasLegitimType && this.nationality !== 'deutsch';
    },
  },
  data() {
    return {
      tempFiles: null,
      tempFilesFront: false,
      fileToUpload: {},
      vorderseiteUploadPreview: null,
      rueckseiteUploadPreview: null,
      meldebescheinigungUploadPreview: null,
      cameraAvailable: false,
      convertingFile: false,
      uploading: false,
      reading: false,
      updatingProfile: false,
      savingLegitimation: false,
      imageUploadConfig: {
        fit: 'fill',
      },
      nationality: '',
    }
  },
  methods: {
    registerEventBus() {
        // Delete Action
        EVENT_BUS.$on(GESPEICHERTE_AUSWEISFOTOS_LOESCHEN, () => {
          this.$refs.deleteImages.open()
        });

        // Save Action
        EVENT_BUS.$on(SAVE_LEGITIMATION, () => {
          this.saveLegitimation();
        });        
    },   
    unregisterEventBus() {
        EVENT_BUS.$off(GESPEICHERTE_AUSWEISFOTOS_LOESCHEN);
        EVENT_BUS.$off(SAVE_LEGITIMATION);
      }, 
    convertFileIfNeeded(files, isFront = false, isMeldebescheinigung = false) {
      if (isPdf(files[0])) { // convert pdf to image before upload it
        this.convertingFile = true;
        uploadFileChunked(files[0]).then((tempFileId) => {
          return pdfToImage(tempFileId, fileNameNoExtension(files[0]) + ".png")
            .then(file => this.uploadImage([file], isFront, isMeldebescheinigung));
        }).finally(() => this.convertingFile = false);
      } else {
        this.uploadImage(files, isFront, isMeldebescheinigung);
      }
    },
    uploadImage(files, isFront = false, isMeldebescheinigung = false) {
      if (!files?.length) {
        return ;
      }

      this.fileToUpload = {};
      this.tempFiles = files;
      this.tempFilesFront = isFront;

      if(isFront) {
        this.onUploadVorderseite(files[0]);
        this.openReadImageModalIfNeeded(true);
      } else if(isMeldebescheinigung) {
        this.onUploadMeldebescheinigung(files[0]);
      } else {
        this.onUploadRueckseite(files[0]);
        this.openReadImageModalIfNeeded();
      }

      this.upload();

      if(this.mitarbeiterId){
        this.$emit('addAnsprechpartnerDataEdited');
      }
    },
    updateImage(file, isFront = false, isMeldebescheinigung = false) {
      if (!file)
        return;

      this.fileToUpload = {};

      if(isFront) {
        this.onUploadVorderseite(file);
      } else if(isMeldebescheinigung) {
        this.onUploadMeldebescheinigung(file);
      } else {
        this.onUploadRueckseite(file);
      }

      this.upload();
    },
    closeReadImageModal() {
      this.$refs.confirmReadImageModal?.close?.();
    },
    openReadImageModalIfNeeded(isFront = false) {
      if (isFront && !this.vorderseiteImage || !isFront && !this.rueckseiteImage){
        this.$refs.confirmReadImageModal.open();
      } else{
        this.readImage(this.tempFiles, this.tempFilesFront);
      }
    },
    readImage(files, isFront) {
      if(!files?.length) {
        return ;
      }

      this.reading = true;
      uploadFileChunked(files[0]).then((tempFileId) => {
        return this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.RECOGNIZE_UPLOADED_FILE, { tempFileId, front: isFront })
          .then((response) => {
            this.$emit('read-image-data', response);
            this.closeReadImageModal();
            if(response.personalausweis) {
              this.nationality = response.personalausweis.nationality;
            }
          });
      }).finally(() => this.reading = false);
    },
    onUploadVorderseite(file) {
      this.vorderseiteUploadPreview = URL.createObjectURL(file);
      this.fileToUpload.vorderseite = file;
    },
    onUploadRueckseite(file) {
      this.rueckseiteUploadPreview = URL.createObjectURL(file);
      this.fileToUpload.rueckseite = file;
      this.updateLegitimationOnStore();

    },
    updateLegitimationOnStore() {
      this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.UPDATE_LEGITIMATION_INFO, {legitimation: this.legitimation, rueckseiteUploadPreview: this.rueckseiteUploadPreview, vorderseiteUploadPreview: this.vorderseiteUploadPreview });
    },
    onUploadMeldebescheinigung(file) {
      this.meldebescheinigungUploadPreview = URL.createObjectURL(file);
      this.fileToUpload.meldebescheinigung = file;
    },
    upload() {
      let personId = this.personId

      if(this.mitarbeiterId){
        personId = this.mitarbeiterId
      }

      this.uploading = true;
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.UPLOAD_LEGITIMATION_IMAGES, {
        personId: personId,
        ...this.fileToUpload
      })
      .then(v => {
        this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.LEGITIMATIONSURKUNDE_CHANGED, {legitimationsurkundeChanged : true});
        this.updateLegitimationOnStore();
      })
      .finally(() => this.uploading = false);
    },
    deleteImages() {
      this.vorderseiteUploadPreview = null
      this.rueckseiteUploadPreview = null
      this.meldebescheinigungUploadPreview = null

      if(this.legitimation?.bildVorderseite) {
        this.legitimation.bildVorderseite = null;
      }
      if(this.legitimation?.bildRueckseite) {
        this.legitimation.bildRueckseite = null;
      }
      if(this.legitimation?.bildMeldebescheinigung) {
        this.legitimation.bildMeldebescheinigung = null;
      }

      this.legitimation.istBestaetigt = null

      let personId = this.personId;
      if(this.mitarbeiterId){
        personId = this.mitarbeiterId;
      }
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.DELETE_LEGITIMATION_IMAGES, {
        personId: personId,
      })
    },
    getImgSrc(image) {
      if (image) {
        let byteArray = image.bytes;
        if(this.mitarbeiterId){
          byteArray = base64ToArrayBuffer(image.bytes);
        }
        const typedArray = new Uint8Array(byteArray);
        const blob = new Blob([typedArray], { type: image.contentType });
        return URL.createObjectURL(blob);
      }

      return '';
    },
    async updateProfile() {
      try {
        this.updatingProfile = true;
        await Promise.all([
          this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_CUSTOMER_DATA),
          this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_CUSTOMER_DATA_CONFIG),
          this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_CUSTOMER_DATA_ADDITIONAL_PERSONS, { list: true }),
        ]);
      } finally {
        this.updatingProfile = false;
      }
    },
    async saveLegitimation() {
      const payload = {
        personId: this.personId,
      };
      this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.ADD_CUSTOMER_DATA_EDITED, payload);
  
      this.savingLegitimation = true;
      await this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.SAVE_CUSTOMER_DATA)
      .then(() => {
        this.updateProfile();
      })
      .finally(() => {
        this.savingLegitimation = false;
        this.vorlegen();
      })
    },
    removeReentryParameters() {
      let query = { 
        substep: this.$route.query.substep,
        isDeepLink: true
       }
      this.$router.replace({ query }).catch(() => {});
    },
    vorlegen() {
      // MSC-24696
      if (this.isFA && this.legitimation.istBestaetigt && this.legitimation?.signatureInfo?.fileId && !this.legitimation?.signatureInfo?.isSigned 
        && this.legitimation?.signatureInfo?.signoURL && !this.legitimation?.signatureInfo?.isVorgelegt) {
          
          this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.TOGGLE_E_SIGNATUR_LEGITIMATION, { fileId: this.legitimation?.signatureInfo?.fileId});
      }
    }
  },
  mounted() {
    const isDeepLink = this.$route.query.isDeepLink
    if (isDeepLink) {
      this.removeReentryParameters()
    }
    if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
      navigator.mediaDevices.enumerateDevices()
      .then(devices => {
        this.cameraAvailable = devices.some(device => device.kind == 'videoinput');
      });
    }

    this.registerEventBus();
    // this.updateLegitimationOnStore();
  },
  destroyed() {
      this.unregisterEventBus();
    },
  components: {
    BaseButton,
    BaseModal,
    ImageUpload,
    InputToggleSwitch,
    DownloadLink,
    SignoViewerLink,
  }
}
</script>

<style scoped>
  .scans-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: center;
    gap: 10px;
  }
  .scans-container > div {
    flex: 1 1 344px;
    max-width: 100%;
  }
  .scan-container {
    margin: 0;
    display: flex;
    flex-direction: column;
    max-width: 100%;
  }

  .scan-container.no-image {
    max-width: 344px;
  }
</style>
