export const MUTATION_PREFIX = 'versandProtokolle_';
export const ACTIONS_PREFIX = 'versandProtokolle_';
export const GETTERS_PREFIX = 'versandProtokolle_';

export default {
  MUTATIONS: {
    SETUP: MUTATION_PREFIX + 'SETUP',
    FILTER: MUTATION_PREFIX + 'FILTER',
    COMMUNICATIONS:MUTATION_PREFIX + 'COMMUNICATIONS',
    VP_BEMERKUNG:MUTATION_PREFIX + 'VP_BEMERKUNG',
    TRACE:MUTATION_PREFIX + 'TRACE',
    QUELLE:MUTATION_PREFIX + 'QUELLE',
    LABEL:MUTATION_PREFIX + 'LABEL',
    RELOAD:MUTATION_PREFIX+"RELOAD",
    EINGANG:MUTATION_PREFIX+"EINGANG",
    BARCODES_COMBO:MUTATION_PREFIX+"BARCODES_COMBO",
    ERROR_EDIT:MUTATION_PREFIX+"ERROR_EDIT",
    EDIT_GESEL_PAGE:MUTATION_PREFIX+"EDIT_GESEL_PAGE",
    EDIT_GESEL_PAGE_CONFIG:MUTATION_PREFIX+"EDIT_GESEL_PAGE_CONFIG",
    EDIT_KUNDEN_PAGE_CONFIG:MUTATION_PREFIX+"EDIT_KUNDEN_PAGE_CONFIG",
    GESELL_KOMM_COMBO:MUTATION_PREFIX+"GESELL_KOMM_COMBO",
    PERSONEN_COMBO:MUTATION_PREFIX+"PERSONEN_COMBO",
    PAGES_ACTIVE:MUTATION_PREFIX+"PAGES_ACTIVE",
    MAPPE_COMBO:MUTATION_PREFIX+"MAPPE_COMBO",
    MAPPE_TABLE:MUTATION_PREFIX+"MAPPE_TABLE",

    },
  ACTIONS: {
    //FIND_CORNERS: ACTIONS_PREFIX + 'FIND_CORNERS',
    SET_BARCODE_PAGES: ACTIONS_PREFIX +"SET_BARCODE_PAGES",
    SETUP: ACTIONS_PREFIX + 'SETUP',
    FILTER: ACTIONS_PREFIX + 'FILTER',
    COMMUNICATIONS:ACTIONS_PREFIX + 'COMMUNICATIONS',
    LOAD_WEB_SERVICE:ACTIONS_PREFIX + 'LOAD_WEB_SERVICE',
    LOAD_PDF:ACTIONS_PREFIX + 'LOAD_PDF',
    LOAD_WEB_SERVICE_REPORT:ACTIONS_PREFIX + 'LOAD_WEB_SERVICE_REPORT',
    LOAD_FAX_REPORT:ACTIONS_PREFIX + 'LOAD_FAX_REPORT',
    LOAD_MAIL_REPORT:ACTIONS_PREFIX + 'LOAD_MAIL_REPORT',
    LOAD_EINGANG:ACTIONS_PREFIX + 'LOAD_EINGANG',
    GET_VP_BEMERKUNG:ACTIONS_PREFIX + 'GET_VP_BEMERKUNG',
    SET_VP_BEMERKUNG:ACTIONS_PREFIX + 'SET_VP_BEMERKUNG',
    TRACE:ACTIONS_PREFIX + 'TRACE',
    QUELLE:ACTIONS_PREFIX + 'QUELLE',
    LABEL:ACTIONS_PREFIX + 'LABEL',
    CONFIRM:ACTIONS_PREFIX + 'CONFIRM',
    TOERROR:ACTIONS_PREFIX + 'TOERROR',
    EINGANG:ACTIONS_PREFIX+"EINGANG",
    BARCODES_COMBO:ACTIONS_PREFIX+"BARCODES_COMBO",
    GET_PAGE:ACTIONS_PREFIX+"GET_PAGE",
    GET_KUNDEN_NAME:ACTIONS_PREFIX+"GET_KUNDEN_NAME",
    GESELL_KOMM_COMBO:ACTIONS_PREFIX+"GESELL_KOMM_COMBO",
    GET_GESELL_KOMM:ACTIONS_PREFIX+"GET_GESELL_KOMM",
    SET_KUNDEN_GESEL_PAGES:ACTIONS_PREFIX+"SET_KUNDEN_GESEL_PAGES",
    SET_KUNDEN_DECKBLATT_PAGES:ACTIONS_PREFIX+"SET_KUNDEN_DECKBLATT_PAGES",
    EDIT_KUNDEN_PAGE_CONFIG:ACTIONS_PREFIX+"EDIT_KUNDEN_PAGE_CONFIG",
    PERSONEN_COMBO:ACTIONS_PREFIX+"PERSONEN_COMBO",
    DEACT:ACTIONS_PREFIX+"DEACT",
    SEND:ACTIONS_PREFIX+"SEND",
    MAPPE_COMBO:ACTIONS_PREFIX+"MAPPE_COMBO",
    MAPPE_TABLE:+ACTIONS_PREFIX+"MAPPE_TABLE",
  },
  GETTERS: {
    SETUP: GETTERS_PREFIX + 'SETUP',
    FILTER: GETTERS_PREFIX + 'FILTER',
    ROW_COUNT: GETTERS_PREFIX + 'ROW_COUNT',
    COMMUNICATIONS:GETTERS_PREFIX + 'COMMUNICATIONS',
    VP_BEMERKUNG:GETTERS_PREFIX + 'VP_BEMERKUNG',
    TRACE:GETTERS_PREFIX  + 'TRACE',
    QUELLE:GETTERS_PREFIX + 'QUELLE',
    LABEL:GETTERS_PREFIX + 'LABEL',
    RELOAD:GETTERS_PREFIX+"RELOAD",
    EINGANG:GETTERS_PREFIX+"EINGANG",
    BARCODES_COMBO:GETTERS_PREFIX+"BARCODES_COMBO",
    ERROR_EDIT:GETTERS_PREFIX+"ERROR_EDIT",
    EDIT_GESEL_PAGE:GETTERS_PREFIX+"EDIT_GESEL_PAGE",
    EDIT_GESEL_PAGE_CONFIG:GETTERS_PREFIX+"EDIT_GESEL_PAGE_CONFIG",
    GESELL_KOMM_COMBO:GETTERS_PREFIX+"GESELL_KOMM_COMBO",
    EDIT_KUNDEN_PAGE_CONFIG:GETTERS_PREFIX+"EDIT_KUNDEN_PAGE_CONFIG",
    PERSONEN_COMBO:GETTERS_PREFIX+"PERSONEN_COMBO",
    PAGES_ACTIVE:GETTERS_PREFIX+"PAGES_ACTIVE",
    MAPPE_COMBO:GETTERS_PREFIX+"MAPPE_COMBO",
    MAPPE_TABLE:GETTERS_PREFIX+"MAPPE_TABLE",
  }
}