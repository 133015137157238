import decode from 'jwt-decode';

const EMPLOYEE_ROLE_PREFIX = 'EMPLOYEE_'
const BROKER_STRUCTURE_ROLE_PREFIX = 'BROKER_STRUCTURE_'
const BROKER_LEVEL_ROLE_PREFIX = 'BROKER_LEVEL_'

export const ROLES = {

  IS_USER_LOGGED: 'IS_USER_LOGGED',

  SUPER_CUSTOMER: 'SUPER_CUSTOMER',
  PRODUCTION: 'PRODUCTION',
  BROKER_MASTER: 'BROKER_MASTER',

  IS_MAKLER_KPI: 'IS_MAKLER_KPI',
  IS_FPP_PLUS: 'IS_FPP_PLUS',
  IS_FPP_PLUS_MASTER: 'IS_FPP_PLUS_MASTER',
  IS_FPP_PLUS_NOT_MASTER: 'IS_FPP_PLUS_NOT_MASTER',
  IS_FINANCIAL_ARCHITECTS: 'IS_FINANCIAL_ARCHITECTS',
  IS_FINANCIAL_ARCHITECTS_MASTER: 'IS_FINANCIAL_ARCHITECTS_MASTER',
  IS_VVCUSTOMER: 'IS_VVCUSTOMER',
  IS_AVL: 'IS_AVL',
  IS_DIALOG: 'IS_DIALOG',
  IS_MEHRFACHAGENT: 'IS_MEHRFACHAGENT',
  CUSTOMERS_USE_KSC2: 'CUSTOMERS_USE_KSC2',

  RETIREMENT_SCENARIO: 'RETIREMENT_SCENARIO',

  IS_DGFRP_MASTER: 'IS_DGFRP_MASTER',
  IS_10000_FA: 'IS_10000_FA',

  CMS_FONDSSHOP_OHNE_HOME_SEITE: 'CMS_FONDSSHOP_OHNE_HOME_SEITE',

  /**
   * @return false in production tomcat or true on all non-production tomcat instances
   * 
   * @see msc.session.Status#isInTestMode()
   */
  IS_IN_TEST_MODE: 'IS_IN_TEST_MODE',
  TEST_USER: 'TEST_USER',
  SPECIAL_TEST_USER: 'SPECIAL_TEST_USER',
  IS_BYPASS_SLASH: 'IS_BYPASS_SLASH',
  IS_MAKLER_EARLY_ACCESS: 'IS_MAKLER_EARLY_ACCESS',
  IS_USER_UNTERNR: 'IS_USER_UNTERNR',
  IS_EXTERNAL_BROKER: 'IS_EXTERNAL_BROKER',
  IS_BIPRO_STRUKTUR: 'IS_BIPRO_STRUKTUR',
  IS_REALLY_USER: 'IS_REALLY_USER',
  USER_CAN_DOWNLOAD: 'USER_CAN_DOWNLOAD',
  HAT_VERTRAG: 'HAT_VERTRAG',
  IS_TICKET_ERLAUBT: 'IS_TICKET_ERLAUBT',

  SHOW_PIN_LIST: 'SHOW_PIN_LIST',

  MULTIBANKING: 'MULTIBANKING',
  MULTIBANKING_DATA_ALLOWED: 'MULTIBANKING_DATA_ALLOWED',
  MULTIBANKING_ACCOUNT_USER: 'MULTIBANKING_ACCOUNT_USER',

  FK: 'FK',
  FK_TEST_USER: 'FK_TEST_USER',

  FA: 'FA',
  FA_TEST_USER: 'FA_TEST_USER',
  FA_WK_TEST_USER: 'FA_WK_TEST_USER',

  INSURANCE_COMPANY: 'INSURANCE_COMPANY',

  DATA_PRIVACY_CONFIRMED: 'DATA_PRIVACY_CONFIRMED',
  DATA_PRIVACY_EMAIL: 'DATA_PRIVACY_EMAIL',
  DATA_PRIVACY_PHONE: 'DATA_PRIVACY_PHONE',
  DATA_PRIVACY_FAX: 'DATA_PRIVACY_FAX',
  DATA_PRIVACY_POST: 'DATA_PRIVACY_POST',
  DATA_PRIVACY_APP: 'DATA_PRIVACY_APP',

  HAS_HOMEPAGE: 'HAS_HOMEPAGE',
  SHOW_GRUNDLAGENWISSEN: 'SHOW_GRUNDLAGENWISSEN',
  SHOW_ZUSATZINFORMATION_INTERESSENKONFLIKT: 'SHOW_ZUSATZINFORMATION_INTERESSENKONFLIKT',

  SHOW_ANSPRECHPARTNER: 'SHOW_ANSPRECHPARTNER',

  KUNDE_APP_ALLOW_VERS_UPLOAD: 'KUNDE_APP_ALLOW_VERS_UPLOAD',
  ALLOW_VERS_SCHADEN: 'ALLOW_VERS_SCHADEN',
  ALLOW_VERS_SAVE: 'ALLOW_VERS_SAVE',

  KUNDE_APP_ALLOW_ORDER: 'KUNDE_APP_ALLOW_ORDER',
  SHOW_BUTTON_DOKUMENT_ABSCHLIESSEN: 'SHOW_BUTTON_DOKUMENT_ABSCHLIESSEN',

  ALLOW_WEBRTC_CALL_KUNDEN_FREIGABE: 'ALLOW_WEBRTC_CALL_KUNDEN_FREIGABE',
  SHOW_WEBRTC_CALL_RECORDING_BUTTON: 'SHOW_WEBRTC_CALL_RECORDING_BUTTON',
  IS_WEBRTC_KUNDENZUGANG: 'IS_WEBRTC_KUNDENZUGANG',
  IS_WEBRTC_MAKLERZUGANG: 'IS_WEBRTC_MAKLERZUGANG',

  IS_COMDIRECT_KWG32: 'IS_COMDIRECT_KWG32',
  SHOW_BROKER_NAME_ON_TITLE_PAGE: 'SHOW_BROKER_NAME_ON_TITLE_PAGE',

  IS_ANLAGE_BEISPIEL: 'IS_ANLAGE_BEISPIEL',
  SHOW_WERTPAPIERE_VERSICHERUNGEN: 'SHOW_WERTPAPIERE_VERSICHERUNGEN',
  SHOW_WERTPAPIERE_VORSORGE: 'SHOW_WERTPAPIERE_VORSORGE',

  SHOW_HISTORICAL_PERFORMANCE: 'SHOW_HISTORICAL_PERFORMANCE',
  MAKLER_EXKLUSIVER_MAKLER: 'MAKLER_EXKLUSIVER_MAKLER',

  ALLOW_SEND_PIN_VIA_SMS: 'ALLOW_SEND_PIN_VIA_SMS',

  IS_BERATUNGSKUNDE: 'IS_BERATUNGSKUNDE',
  IS_VERMITTLUNGSKNUDE: 'IS_VERMITTLUNGSKNUDE',
  IS_EXECUTION_ONLY: 'IS_EXECUTION_ONLY',

  EDIT_LAYOUT: 'EDIT_LAYOUT',

  HIDE_LOGOUT_BUTTON: 'HIDE_LOGOUT_BUTTON',

  IS_KUNDENZUGANG: 'IS_KUNDENZUGANG',
  IS_MAKLERZUGANG: 'IS_MAKLERZUGANG',

  ALLOW_CONFIG_FC_CONFIG_GLOBAL: 'ALLOW_CONFIG_FC_CONFIG_GLOBAL',

  IS_AT: 'IS_AT',
  VERSADMIN_A: 'VERSADMIN_A',
  VERSADMIN_M: 'VERSADMIN_M',

  ALLOW_EDIT_CREDIT: 'ALLOW_EDIT_CREDIT',
  IS_BAVARIA: 'IS_BAVARIA',
  IS_INVERSE: 'IS_INVERSE',
  IS_SVM: 'IS_SVM',

  IS_HAUPTSTRUKTURLEITENR: 'IS_HAUPTSTRUKTURLEITENR',

  SHOW_FEE: 'SHOW_FEE',
  DEPOTAUSZUG_LISTE_REALISIERT: 'DEPOTAUSZUG_LISTE_REALISIERT',
  BEMERKUNG_VISIBLE_KUNDE: 'BEMERKUNG_VISIBLE_KUNDE',

  IS_OPEN_SIGNATURES_READ_ONLY: 'IS_OPEN_SIGNATURES_READ_ONLY',
  ZEIGE_GELDWAESCHE: 'ZEIGE_GELDWAESCHE',
  ZEIGE_DATENSCHUTZSCHULUNG: 'ZEIGE_DATENSCHUTZSCHULUNG',

  HAS_AKTUELLER_QUARTALSBERICHT: 'HAS_AKTUELLER_QUARTALSBERICHT',

  SHOW_EVENTS: 'SHOW_EVENTS',

  BRAUCHT_HAFTUNGSERKLAERUNG_NEW_BROKER: 'BRAUCHT_HAFTUNGSERKLAERUNG_NEW_BROKER',
  DARF_VERMITTLER_ANLEGEN: 'DARF_VERMITTLER_ANLEGEN',

  SHOW_MARKTBERICHT: 'SHOW_MARKTBERICHT',
  SHOW_VV_MARKTBERICHT: 'SHOW_VV_MARKTBERICHT',

  EDIT_CONFIGS_DEFINITION: 'EDIT_CONFIGS_DEFINITION',
  EDIT_VERSICHERUNGEN_FIELDS: 'EDIT_VERSICHERUNGEN_FIELDS',
  SONDERRECHT_KUNDENNR: 'SONDERRECHT_KUNDENNR',
  BLOCKED_ABRECHNUNGEN_STATISTIKEN: 'BLOCKED_ABRECHNUNGEN_STATISTIKEN',
  HAT_SYNC_USER: 'HAT_SYNC_USER',
  TERMINKALENDER_SYNC_AKTIV: 'TERMINKALENDER_SYNC_AKTIV',
  DEPOTAUSZUG_GUV_FARBE: 'DEPOTAUSZUG_GUV_FARBE',
  HAT_BETEILIGUNGEN: 'HAT_BETEILIGUNGEN',
  HAT_VERSICHERUNGSVERMOEGEN: 'HAT_VERSICHERUNGSVERMOEGEN',
  HAT_RUECKKAUFSWERT: 'HAT_RUECKKAUFSWERT',

  IS_CONFIGURATION_READ_ONLY: 'IS_CONFIGURATION_READ_ONLY',
  SHOW_SAVINGS_AND_WITHDRAWAL_PLANS_SEPARATELY: 'SHOW_SAVINGS_AND_WITHDRAWAL_PLANS_SEPARATELY',

  IS_FA_VERMITTLER_DATEN_ALLOWED: 'IS_FA_VERMITTLER_DATEN_ALLOWED',

  VERSAND_PROTOKOLLE_VERWALTUNG: 'VERSAND_PROTOKOLLE_VERWALTUNG',
  VERSAND_PROTOKOLLE_PROTOKOLL: 'VERSAND_PROTOKOLLE_PROTOKOLL',
  VIEW_ANTRAG_CHECK: 'VIEW_ANTRAG_CHECK',
  VIEW_SIPGATE: 'VIEW_SIPGATE',
  VIEW_COURTAGETABELLE_VERSICHERUNG: 'VIEW_COURTAGETABELLE_VERSICHERUNG',

  DBM: 'DBM',
  INS_ZIP_FILE: 'INS_ZIP_FILE',
  CHAT_TEST_SESSION_QUERY:'CHAT_TEST_SESSION_QUERY',
  VIEW_PERSONLICHES_GESCHAEFT: 'VIEW_PERSONLICHES_GESCHAEFT',

  IS_RESTRICTION_TO_BROKER_CONFIG_ENABLED: 'IS_RESTRICTION_TO_BROKER_CONFIG_ENABLED',

  CAN_ADD_NEW_SPARTEN: 'CAN_ADD_NEW_SPARTEN',
  DARF_VERSICHERUNG_MAKLERAUFTRAG_SEHEN: 'DARF_VERSICHERUNG_MAKLERAUFTRAG_SEHEN',

  VIEW_VV_STRATEGIEN: 'VIEW_VV_STRATEGIEN',
  VIEW_VV_PRODUKTE: 'VIEW_VV_PRODUKTE',
  VIEW_VV_KUNDEN: 'VIEW_VV_KUNDEN',
  VIEW_VV_SUITABILITY: 'VIEW_VV_SUITABILITY',
  VIEW_VV_ADVISOR_FRAGEBOGEN: 'VIEW_VV_ADVISOR_FRAGEBOGEN',

  HAS_EX_POST_BERECHNUNG: 'HAS_EX_POST_BERECHNUNG',

  SHOW_BROKER_STATISTICS_MITARBEITER: 'SHOW_BROKER_STATISTICS_MITARBEITER',

  ZUGRIFF_COURTAGE_TABELLE_FONDS: 'ZUGRIFF_COURTAGE_TABELLE_FONDS',

  HAS_FINANZPORTAL24: 'HAS_FINANZPORTAL24',
  HAS_FINANZPORTAL24_ONLINE_BESTELLANLEITUNG: 'HAS_FINANZPORTAL24_ONLINE_BESTELLANLEITUNG',
  ALLOW_SCHADEN_MERGE: 'ALLOW_SCHADEN_MERGE'
}

/*
  View Roles are intended to be used to show/hide resources according to how a user is opened.
  The user (broker or customer) can be opened in two ways: 
    1 - Directly with credentials 
    2 - It can be bypassed by another broker or a intern user

  WARN: Since they can be exclusionary, it's better to avoid bringing other 
  items non related to "userType" and "userTypeGetToken" into it

  Some examples and use cases:
    1 - Layout Editor can only be visible in broker perspective, but when an intern bypass it
        This way the correct option should be:
        allowed: [VIEW_ROLES.VIEW_BROKER_AS_BYPASS]

    2 - Beratungsmappe can only be visible in customer view, but when a broker bypass it
        This way the correct option should be:
        allowed: [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS]

    3 - Datenschutz page can only be visible if the customer makes login directly, because the broker is not allowed to change it.
        But it should also blocked if the broker does the Kundensicht.
        This way the correct option should be:
        allowed: [VIEW_ROLES.VIEW_CUSTOMER_ONLY]
        denied: [VIEW_ROLES.VIEW_KUNDENSICHT]
    
    4 - Kundensuche page should be visible to brokers, but it doesn't matter if the broker is logged in directly or bypassed by intern/another broker
        This way the correct option should be:
        allowed: [VIEW_ROLES.VIEW_BROKER]
*/
export const VIEW_ROLES = {
  VIEW_INTERN: 'VIEW_INTERN', // a intern in any situation. No matter if it's bypassed or not
  VIEW_INTERN_ONLY: 'VIEW_INTERN_ONLY', // when the intern makes login directly with credentials

  VIEW_CUSTOMER: 'VIEW_CUSTOMER', // a customer in any situation. No matter if it's bypassed or not
  VIEW_CUSTOMER_ONLY: 'VIEW_CUSTOMER_ONLY', // when the customer makes login directly with credentials 
  VIEW_CUSTOMER_AS_BYPASS: 'VIEW_CUSTOMER_AS_BYPASS', // when the customer is bypassed by broker or intern
  VIEW_CUSTOMER_AS_INTERN: 'VIEW_CUSTOMER_AS_INTERN', // when the customer is bypassed by intern
  VIEW_CUSTOMER_AS_BROKER: 'VIEW_CUSTOMER_AS_BROKER', // when the customer is bypassed by broker

  VIEW_BROKER: 'VIEW_BROKER', // a broker in any situation. No matter if it's bypassed or not
  VIEW_BROKER_ONLY: 'VIEW_BROKER_ONLY', // when the broker makes login directly with credentials 
  VIEW_BROKER_AS_BYPASS: 'VIEW_BROKER_AS_BYPASS', // when the broker is bypassed by another broker or intern
  VIEW_BROKER_AS_INTERN: 'VIEW_BROKER_AS_INTERN', // when the broker is bypassed by intern
  VIEW_BROKER_AS_BROKER: 'VIEW_BROKER_AS_BROKER', // when the broker is bypassed by another broker

  VIEW_KUNDENSICHT: 'VIEW_KUNDENSICHT', // when the broker activates Kundensicht. 

  VIEW_GESELLSCHAFT: 'VIEW_GESELLSCHAFT', // when the gesellschaft person does the login
}

export const EMPLOYEE_ROLES = {
  ZUGRIFF_COURTAGEHISTORIE: employeeRolePrefix('ZUGRIFF_COURTAGEHISTORIE'),
  ZUGRIFF_MATERIAL: employeeRolePrefix('ZUGRIFF_MATERIAL'),
  ZUGRIFF_KONTAKTE: employeeRolePrefix('ZUGRIFF_KONTAKTE'),
  ZUGRIFF_RATENKREDITMODUL: employeeRolePrefix('ZUGRIFF_RATENKREDITMODUL'),
  ZUGRIFF_STATISTIK_INVESTMENT: employeeRolePrefix('ZUGRIFF_STATISTIK_INVESTMENT'),
  ZUGRIFF_MITARBEITER_NEU: employeeRolePrefix('ZUGRIFF_MITARBEITER_NEU'),
  CSV_DOWNLOAD_KUNDENDATEN: employeeRolePrefix('CSV_DOWNLOAD_KUNDENDATEN'),
  ZUGRIFF_EFONDS24: employeeRolePrefix('ZUGRIFF_EFONDS24'),
  ZUGRIFF_COURTAGE: employeeRolePrefix('ZUGRIFF_COURTAGE'),
  ZUGRIFF_STATISTIK: employeeRolePrefix('ZUGRIFF_STATISTIK'),
  ZUGRIFF_COURTLIST_INVEST: employeeRolePrefix('ZUGRIFF_COURTLIST_INVEST'),
  ZUGRIFF_TRANSAKTIONEN_VERLAUF: employeeRolePrefix('ZUGRIFF_TRANSAKTIONEN_VERLAUF'),
  ZUGRIFF_STATISTIK_BETEILIGUNG: employeeRolePrefix('ZUGRIFF_STATISTIK_BETEILIGUNG'),
  STORNOS_WARNUNGEN_BESTAETIGEN: employeeRolePrefix('STORNOS_WARNUNGEN_BESTAETIGEN'),
  ZUGRIFF_ZERTIFIKATE: employeeRolePrefix('ZUGRIFF_ZERTIFIKATE'),
  ZUGRIFF_TERMINE: employeeRolePrefix('ZUGRIFF_TERMINE'),
  ZUGRIFF_AKTIVITAETEN: employeeRolePrefix('ZUGRIFF_AKTIVITAETEN'),
  ZUGRIFF_COURTLIST_GESCHLOSS: employeeRolePrefix('ZUGRIFF_COURTLIST_GESCHLOSS'),
  ZUGRIFF_KUNDEN: employeeRolePrefix('ZUGRIFF_KUNDEN'),
  ZUGRIFF_FINANZIERUNG: employeeRolePrefix('ZUGRIFF_FINANZIERUNG'),
  ZUGRIFF_PERSDATEN: employeeRolePrefix('ZUGRIFF_PERSDATEN'),
  ZUGRIFF_MUNIO: employeeRolePrefix('ZUGRIFF_MUNIO'),
  ZUGRIFF_STATISTIK_VERSICHERUNG: employeeRolePrefix('ZUGRIFF_STATISTIK_VERSICHERUNG'),
  ZUGRIFF_UNTERMAKLER: employeeRolePrefix('ZUGRIFF_UNTERMAKLER'),
  ZUGRIFF_BIPRO_ABRECHNUNG: employeeRolePrefix('ZUGRIFF_BIPRO_ABRECHNUNG'),
  ZUGRIFF_FAVORITENLISTE: employeeRolePrefix('ZUGRIFF_FAVORITENLISTE'),
}

export const BROKER_STRUCTURE_ROLES = {
  ZUGRIFF_INVESTMENT_FONDS: brokerStructureRolePrefix('ZUGRIFF_INVESTMENT_FONDS'),
  ZUGRIFF_GESCHLOSSENE_FONDS: brokerStructureRolePrefix('ZUGRIFF_GESCHLOSSENE_FONDS'),
  ZUGRIFF_VERSICHERUNGEN: brokerStructureRolePrefix('ZUGRIFF_VERSICHERUNGEN'),
  ZUGRIFF_KREDITEDARELEHEN: brokerStructureRolePrefix('ZUGRIFF_KREDITEDARELEHEN'),
  PASSWORT_AENDERBAR: brokerStructureRolePrefix('PASSWORT_AENDERBAR'),
  LOESCHEN_GESPEICHERTE_DOKUMENTE: brokerStructureRolePrefix('LOESCHEN_GESPEICHERTE_DOKUMENTE'),
}

export const BROKER_LEVEL_ROLES = {
  ZUGRIFF_COURTAGE: brokerLevelRolePrefix('ZUGRIFF_COURTAGE'),
  ZUGRIFF_BIPRO_ABRECHNUNG: brokerLevelRolePrefix('ZUGRIFF_BIPRO_ABRECHNUNG'),
  ZUGRIFF_KUNDEN: brokerLevelRolePrefix('ZUGRIFF_KUNDEN'),
  ZUGRIFF_UNTERMAKLER: brokerLevelRolePrefix('ZUGRIFF_UNTERMAKLER'),
  ZUGRIFF_MAKLERNUMMERN: brokerLevelRolePrefix('ZUGRIFF_MAKLERNUMMERN'),
  ZUGRIFF_INFORMATIONSVERSAND: brokerLevelRolePrefix('ZUGRIFF_INFORMATIONSVERSAND'),
  ZUGRIFF_TRANSAKTIONEN_VERLAUF: brokerLevelRolePrefix('ZUGRIFF_TRANSAKTIONEN_VERLAUF'),
  ZUGRIFF_STATISTIK: brokerLevelRolePrefix('ZUGRIFF_STATISTIK'),
  ZUGRIFF_STATISTIK_INVESTMENT: brokerLevelRolePrefix('ZUGRIFF_STATISTIK_INVESTMENT'),
  ZUGRIFF_STATISTIK_BETEILIGUNG: brokerLevelRolePrefix('ZUGRIFF_STATISTIK_BETEILIGUNG'),
  ZUGRIFF_STATISTIK_VERSICHERUNG: brokerLevelRolePrefix('ZUGRIFF_STATISTIK_VERSICHERUNG'),
  ZUGRIFF_COURTLIST_INVEST: brokerLevelRolePrefix('ZUGRIFF_COURTLIST_INVEST'),
  ZUGRIFF_VOLLVERSION_MAKLERSOFTWARE: brokerLevelRolePrefix('ZUGRIFF_VOLLVERSION_MAKLERSOFTWARE'),
  ZUGRIFF_ZERTIFIKATE: brokerLevelRolePrefix('ZUGRIFF_ZERTIFIKATE'),
  ZUGRIFF_MUNIO: brokerLevelRolePrefix('ZUGRIFF_MUNIO'),
  AAB_STRUKTUR: brokerLevelRolePrefix('AAB_STRUKTUR'),
  MAKLER_AAB_LOGIN_ZUGANG: brokerLevelRolePrefix('MAKLER_AAB_LOGIN_ZUGANG'),
  ZUGRIFF_COURTLIST_GESCHLOSS: brokerLevelRolePrefix('ZUGRIFF_COURTLIST_GESCHLOSS'),
  ZUGRIFF_PERSDATEN: brokerLevelRolePrefix('ZUGRIFF_PERSDATEN'),
  ZUGRIFF_MATERIAL: brokerLevelRolePrefix('ZUGRIFF_MATERIAL'),
  ZUGRIFF_EFONDS24: brokerLevelRolePrefix('ZUGRIFF_EFONDS24'),
  ZUGRIFF_FINANZIERUNG: brokerLevelRolePrefix('ZUGRIFF_FINANZIERUNG'),
  ZUGRIFF_RATENKREDITMODUL: brokerLevelRolePrefix('ZUGRIFF_RATENKREDITMODUL'),
  MAKLER_VV_IS_ADVISOR: brokerLevelRolePrefix('MAKLER_VV_IS_ADVISOR'),
  MAKLER_VV_DARF_VERKAUFEN: brokerLevelRolePrefix('MAKLER_VV_DARF_VERKAUFEN'),
  ZUGRIFF_MITARBEITER_NEU: brokerLevelRolePrefix('ZUGRIFF_MITARBEITER_NEU'),
  ZUGRIFF_MAKLER_EINSTELLUNGEN: brokerLevelRolePrefix('ZUGRIFF_MAKLER_EINSTELLUNGEN'),
  MAKLER_BETEILIGUNGS_FAVORITEN_FREIGESCHALTET: brokerLevelRolePrefix('MAKLER_BETEILIGUNGS_FAVORITEN_FREIGESCHALTET'),
  ZUGRIFF_FMA_BESCHWERDE: brokerLevelRolePrefix('ZUGRIFF_FMA_BESCHWERDE'),
  ZUGRIFF_EBASEONLINE: brokerLevelRolePrefix('ZUGRIFF_EBASEONLINE'),
  PIN_ABFRAGE_BEI_ANSPRECHPARTNER: brokerLevelRolePrefix('PIN_ABFRAGE_BEI_ANSPRECHPARTNER'),
  USER_IS_REALLY_USER: brokerLevelRolePrefix('USER_IS_REALLY_USER'),
  ZUGRIFF_FAVORITENLISTE: brokerLevelRolePrefix('ZUGRIFF_FAVORITENLISTE'),
  RIGHT_DATENIMPORT: brokerLevelRolePrefix('RIGHT_DATENIMPORT'),
  RIGHT_ABRECHNUNGIMPORT: brokerLevelRolePrefix('RIGHT_ABRECHNUNGIMPORT'),
  RIGHT_SET_MAKLER_INAKTIV: brokerLevelRolePrefix('RIGHT_SET_MAKLER_INAKTIV'),
  SHOW_NEGATIVERKLAERUNG_MENU: brokerLevelRolePrefix('SHOW_NEGATIVERKLAERUNG_MENU'),
  ZUGRIFF_DYNAMIC_FORMS: brokerLevelRolePrefix('ZUGRIFF_DYNAMIC_FORMS'),
  UNTERVERMITTLER_NEU: brokerLevelRolePrefix('UNTERVERMITTLER_NEU'),
  COMDIRECT_WS: brokerLevelRolePrefix('COMDIRECT_WS'),
  ZUGRIFF_JAHRESCHECKLISTE_FA: brokerLevelRolePrefix('ZUGRIFF_JAHRESCHECKLISTE_FA'),
  ZUGRIFF_GESELLSCHAFTDATEN_VERS: brokerLevelRolePrefix('ZUGRIFF_GESELLSCHAFTDATEN_VERS'),
}

export const ALL_ROLES = {
  ROLES,
  VIEW_ROLES,
  BROKER_LEVEL_ROLES,
  BROKER_STRUCTURE_ROLES,
  EMPLOYEE_ROLES,
}

Object.freeze(ROLES);
Object.freeze(VIEW_ROLES);
Object.freeze(BROKER_LEVEL_ROLES);
Object.freeze(BROKER_STRUCTURE_ROLES);
Object.freeze(EMPLOYEE_ROLES);
Object.freeze(ALL_ROLES);

function brokerLevelRolePrefix(roleName) {
  return `${BROKER_LEVEL_ROLE_PREFIX}${roleName}`
}

function employeeRolePrefix(roleName) {
  return `${EMPLOYEE_ROLE_PREFIX}${roleName}`
}

function brokerStructureRolePrefix(roleName) {
  return `${BROKER_STRUCTURE_ROLE_PREFIX}${roleName}`
}

export function getTokenData(token) {
  const decodedToken = decode(token);

  return {
    fullname: decodedToken.fullname,
    userid: decodedToken.userid,
    unternr: decodedToken.unternr || decodedToken.unternr === 0 ? `${decodedToken.unternr}` : null,
  }
}
/**
 * View roles are a special set of roles generated based on the combination of
 * two variables: "userType" and "userTypeGetToken"
 * 
 * WARN: Since they can be exclusionary, it's better to avoid bringing other 
 * items non related to "userType" and "userTypeGetToken" into it
 */
export function getViewRoles(rights) {
  const userViewRoles = []
  const userType = rights.userType
  const userTypeGetToken = rights.userTypeGetToken

  if (!userType && !userTypeGetToken) {
    return userViewRoles;
  }

  if (userType === 'INTERN' && userTypeGetToken === 'KUNDE') {
    userViewRoles.push(VIEW_ROLES.VIEW_CUSTOMER_AS_INTERN)
    userViewRoles.push(VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS)
    userViewRoles.push(VIEW_ROLES.VIEW_CUSTOMER)
  } else if (userType === 'INTERN' && userTypeGetToken === 'MAKLER') {
    userViewRoles.push(VIEW_ROLES.VIEW_BROKER_AS_INTERN)
    userViewRoles.push(VIEW_ROLES.VIEW_BROKER_AS_BYPASS)
    userViewRoles.push(VIEW_ROLES.VIEW_BROKER)
  } else if (userType === 'MAKLER' && userTypeGetToken === 'KUNDE') {
    userViewRoles.push(VIEW_ROLES.VIEW_CUSTOMER_AS_BROKER)
    userViewRoles.push(VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS)
    userViewRoles.push(VIEW_ROLES.VIEW_CUSTOMER)
  } else if (userType === 'MAKLER' && userTypeGetToken === 'MAKLER') {
    userViewRoles.push(VIEW_ROLES.VIEW_BROKER_AS_BROKER)
    userViewRoles.push(VIEW_ROLES.VIEW_BROKER_AS_BYPASS)
    userViewRoles.push(VIEW_ROLES.VIEW_BROKER)
  } else if (userType === 'INTERN' && !userTypeGetToken) {
    userViewRoles.push(VIEW_ROLES.VIEW_INTERN)
    userViewRoles.push(VIEW_ROLES.VIEW_INTERN_ONLY)
  } else if (userType === 'KUNDE' && !userTypeGetToken) {
    userViewRoles.push(VIEW_ROLES.VIEW_CUSTOMER)
    userViewRoles.push(VIEW_ROLES.VIEW_CUSTOMER_ONLY)
  } else if (userType === 'MAKLER' && !userTypeGetToken) {
    userViewRoles.push(VIEW_ROLES.VIEW_BROKER)
    userViewRoles.push(VIEW_ROLES.VIEW_BROKER_ONLY)
  } else if (userType === 'PERSON' && rights.isGesellschaft) {
    userViewRoles.push(VIEW_ROLES.VIEW_GESELLSCHAFT);
  }

  return userViewRoles
}

export function addPrivacyRoles(data, roles) {
  if (data.privacy?.isConfirmed) {
    roles.push(ROLES.DATA_PRIVACY_CONFIRMED)
  }

  if (data.privacy?.contacts) {
    const mapping = {
      email: ROLES.DATA_PRIVACY_EMAIL,
      phone: ROLES.DATA_PRIVACY_PHONE,
      fax: ROLES.DATA_PRIVACY_FAX,
      post: ROLES.DATA_PRIVACY_POST,
      app: ROLES.DATA_PRIVACY_APP,
    }

    for (const contact of data.privacy.contacts) {
      if (contact.hasPermission) {
        roles.push(mapping[contact.type])
      }
    }
  }
}

export function generateUserRoles(data) {
  const roles = [];

  if (data.rights) {
    roles.push(ROLES.IS_USER_LOGGED);

    if (data.rights.isTestUser) {
      roles.push(ROLES.TEST_USER)
    }

    if (data.rights.isInTestMode) {
      roles.push(ROLES.IS_IN_TEST_MODE);
    }

    if (data.rights.isSpecialTester) {
      roles.push(ROLES.SPECIAL_TEST_USER)
    }

    if (data.rights.isBypassSlash) {
      roles.push(ROLES.IS_BYPASS_SLASH)
    }
    if (data.rights.isSuperCustomer) {
      roles.push(ROLES.SUPER_CUSTOMER)
    }

    if (data.rights.isBrokerMaster) {
      roles.push(ROLES.BROKER_MASTER)
    }

    if (data.rights.isMaklerKpi) {
      roles.push(ROLES.IS_MAKLER_KPI)
    }

    if (data.rights.isMaklerEarlyAccess) {
      roles.push(ROLES.IS_MAKLER_EARLY_ACCESS)
    }

    if (data.rights.showWertpapierVersicherungen) {
      roles.push(ROLES.SHOW_WERTPAPIERE_VERSICHERUNGEN);
    }

    if (data.rights.showWertpapiereVorsorge) {
      roles.push(ROLES.SHOW_WERTPAPIERE_VORSORGE);
    }

    if (data.rights.showHistoricalPerformance) {
      roles.push(ROLES.SHOW_HISTORICAL_PERFORMANCE)
    }

    if (data.rights.showButtonDokumentAbschliessen) {
      roles.push(ROLES.SHOW_BUTTON_DOKUMENT_ABSCHLIESSEN);
    }

    if (data.rights.isCmsFondsshopOhneHomeSeite) {
      roles.push(ROLES.CMS_FONDSSHOP_OHNE_HOME_SEITE)
    }

    if (data.rights.showPinList) {
      roles.push(ROLES.SHOW_PIN_LIST)
    }

    if (data.rights.isMultibankingActivated) {
      roles.push(ROLES.MULTIBANKING)
    }
    if (data.rights.isUserAllowedToGetMultibankingData) {
      roles.push(ROLES.MULTIBANKING_DATA_ALLOWED)
    }
    if (data.rights.isMultibankingAccountUser) {
      roles.push(ROLES.MULTIBANKING_ACCOUNT_USER)
    }

    if (data.rights.isComdirectKWG32) {
      roles.push(ROLES.IS_COMDIRECT_KWG32)
    }

    if (data.rights.hideLogoutButton) {
      roles.push(ROLES.HIDE_LOGOUT_BUTTON)
    }

    if (data.rights.zeigeGeldwaesche) {
      roles.push(ROLES.ZEIGE_GELDWAESCHE)
    }

    if (data.rights.zeigeDatenschutzschulung) {
      roles.push(ROLES.ZEIGE_DATENSCHUTZSCHULUNG)
    }
    if (data.rights.mergeSchaden) {
      roles.push(ROLES.ALLOW_SCHADEN_MERGE)
    }

    if (data.rights.allowOrder) {
      roles.push(ROLES.KUNDE_APP_ALLOW_ORDER)
    }

    if (data.rights.isFA) {
      roles.push(ROLES.FA)
      if (data.rights.isTestUser) {
        roles.push(ROLES.FA_TEST_USER)
      }
      if (data.rights.isTestUserWk) {
        roles.push(ROLES.FA_WK_TEST_USER)
      }
    }

    if (data.rights.isFK) {
      roles.push(ROLES.FK)
      if (data.rights.isTestUser) {
        roles.push(ROLES.FK_TEST_USER)
      }
    }

    if (data.rights.hasHomepage) {
      roles.push(ROLES.HAS_HOMEPAGE)
    }
    
    if (data.rights.showSavingsAndWithdrawalPlansSeparately) {
      roles.push(ROLES.SHOW_SAVINGS_AND_WITHDRAWAL_PLANS_SEPARATELY)
    }

    if (data.rights.isMaklerExklusiverMakler) {
      roles.push(ROLES.MAKLER_EXKLUSIVER_MAKLER)
    }

    if (data.rights.isFPPlusMaster) {
      roles.push(ROLES.IS_FPP_PLUS_MASTER)
    }
    if (data.rights.isFPPlus) {
      roles.push(ROLES.IS_FPP_PLUS)
    }
    if (data.rights.isFPPlus && !data.rights.isFPPlusMaster) {
      roles.push(ROLES.IS_FPP_PLUS_NOT_MASTER)
    }
    if (data.rights.isFinancialArchitectsMaster) {
      roles.push(ROLES.IS_FINANCIAL_ARCHITECTS_MASTER)
    }
    if (data.rights.isFinancialArchitects) {
      roles.push(ROLES.IS_FINANCIAL_ARCHITECTS)
    }
    if (data.rights.isVVCustomer) {
      roles.push(ROLES.IS_VVCUSTOMER)
    }
    if (data.rights.isAVL) {
      roles.push(ROLES.IS_AVL)
    }
    if (data.rights.isMehrfachagent) {
      roles.push(ROLES.IS_MEHRFACHAGENT)
    }

    if (data.rights.showAnsprechpartner) {
      roles.push(ROLES.SHOW_ANSPRECHPARTNER)
    }

    if (data.rights.allowVersUpload) {
      roles.push(ROLES.KUNDE_APP_ALLOW_VERS_UPLOAD)
    }    

    if (data.rights.allowVersSchaden) {
      roles.push(ROLES.ALLOW_VERS_SCHADEN)
    }

    if (data.rights.allowVersSave) {
      roles.push(ROLES.ALLOW_VERS_SAVE)
    }

    if (data.rights.customersUseKSC2) {
      roles.push(ROLES.CUSTOMERS_USE_KSC2)
    }

    if (data.rights.isDialog) {
      roles.push(ROLES.IS_DIALOG)
    }

    if (data.rights.istDeutscheRuhestandsplanungMaster) {
      roles.push(ROLES.IS_DGFRP_MASTER)
    }

    if (data.rights.istFAWertpapierdiensleistungen) {
      roles.push(ROLES.IS_10000_FA)
    }
    if (data.rights.istInverse) {
      roles.push(ROLES.IS_INVERSE)
    }
    if (data.rights.isBavaria) {
      roles.push(ROLES.IS_BAVARIA)
    }
    if (data.rights.isSVM) {
      roles.push(ROLES.IS_SVM)
    }
    if (data.rights.showGrundlagenwissen) {
      roles.push(ROLES.SHOW_GRUNDLAGENWISSEN)
    }
    if (data.rights.showZusatzinformationInteressenkonflikt) {
      roles.push(ROLES.SHOW_ZUSATZINFORMATION_INTERESSENKONFLIKT)
    }
    if (data.rights.showBrokerNameOnTitlePage) {
      roles.push(ROLES.SHOW_BROKER_NAME_ON_TITLE_PAGE)
    }

    if (data.rights.allowWebrtcCallKundenFreigabe) {
      roles.push(ROLES.ALLOW_WEBRTC_CALL_KUNDEN_FREIGABE)
    }
    if (data.rights.showWebrtcCallRecordingButton) {
      roles.push(ROLES.SHOW_WEBRTC_CALL_RECORDING_BUTTON)
    }
    if (data.rights.isWebrtcKundenzugang)
      roles.push(ROLES.IS_WEBRTC_KUNDENZUGANG);
    if (data.rights.isWebrtcMaklerzugang)
      roles.push(ROLES.IS_WEBRTC_MAKLERZUGANG);

    if (data.rights.isAnlageBeispiel) {
      roles.push(ROLES.IS_ANLAGE_BEISPIEL);
    }

    if (data.rights.allowSendPinViaSMS) {
      roles.push(ROLES.ALLOW_SEND_PIN_VIA_SMS);
    }

    if (data.rights.isBeratungskunde) {
      roles.push(ROLES.IS_BERATUNGSKUNDE);
    }
    if (data.rights.isVermittlungskunde) {
      roles.push(ROLES.IS_VERMITTLUNGSKNUDE);
    }
    if (data.rights.isExecutionOnly) {
      roles.push(ROLES.IS_EXECUTION_ONLY);
    }

    if (data.rights.isInsuranceCompany) {
      roles.push(ROLES.INSURANCE_COMPANY)
    }

    if (data.rights.isUserUnternr) {
      roles.push(ROLES.IS_USER_UNTERNR)
    }

    if (data.rights.isExternalBroker) {
      roles.push(ROLES.IS_EXTERNAL_BROKER)
    }
    if (data.rights.istBiProStruktur) {
      roles.push(ROLES.IS_BIPRO_STRUKTUR)
    }

    if (data.rights.IsReallyUser) {
      roles.push(ROLES.IS_REALLY_USER)
    }
    if (data.rights.canLoad) {
      roles.push(ROLES.USER_CAN_DOWNLOAD)
    }
    if (data.rights.hatVertrag) {
      roles.push(ROLES.HAT_VERTRAG)
    }

    if (data.rights.depotauszugListeRealisiert) {
      roles.push(ROLES.DEPOTAUSZUG_LISTE_REALISIERT)
    }

    if (data.rights.showBemerkungToKunde) {
      roles.push(ROLES.BEMERKUNG_VISIBLE_KUNDE)
    }

    roles.push(...getViewRoles(data.rights))

    // temporary role for retirement scenario to include the broker 35220. 
    // Remove the role when this situation is over
    if (roles.includes(VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS) && data.rights.showRetirementScenario) {
      roles.push(ROLES.RETIREMENT_SCENARIO)
    }

    if (data.rights.isIntern && data.rights.isBypSession) {
      // const tokenData = getTokenData(data.token)
      // commented out due to spcial brokers in Austria MSC-19949
      //if (data.rights.isFK || (data.rights.isFA && tokenData?.userid === '10000')) {
      roles.push(ROLES.EDIT_LAYOUT)
      //}
    }

    if (data.rights.employeeRights) {
      for (const empKey in data.rights.employeeRights) {
        if (Object.hasOwnProperty.call(data.rights.employeeRights, empKey)) {
          if (data.rights.employeeRights[empKey] && EMPLOYEE_ROLES[empKey]) {
            roles.push(employeeRolePrefix(empKey))
          }
        }
      }
    }

    if (data.rights.brokerStructureRights) {
      for (const empKey in data.rights.brokerStructureRights) {
        if (Object.hasOwnProperty.call(data.rights.brokerStructureRights, empKey)) {
          if (data.rights.brokerStructureRights[empKey] && BROKER_STRUCTURE_ROLES[empKey]) {
            roles.push(brokerStructureRolePrefix(empKey))
          }
        }
      }
    }

    if (data.rights.brokerLevelRights) {
      for (const empKey in data.rights.brokerLevelRights) {
        if (Object.hasOwnProperty.call(data.rights.brokerLevelRights, empKey)) {
          if (data.rights.brokerLevelRights[empKey] && BROKER_LEVEL_ROLES[empKey]) {
            roles.push(brokerLevelRolePrefix(empKey))
          }
        }
      }
    }

    addPrivacyRoles(data, roles)

    if (data.rights.isTicketErlaubt) {
      roles.push(ROLES.IS_TICKET_ERLAUBT)
    }

    if (data.rights.isKundenzugang) {
      roles.push(ROLES.IS_KUNDENZUGANG)
    }

    if (data.rights.isMaklerzugang) {
      roles.push(ROLES.IS_MAKLERZUGANG)
    }

    if (data.rights.isAt) {
      roles.push(ROLES.IS_AT);
    }

    if (data.rights.versAdminA) {
      roles.push(ROLES.VERSADMIN_A);
    }

    if (data.rights.versAdminM) {
      roles.push(ROLES.VERSADMIN_M);
    }

    if (data.rights.allowEditCredit) {
      roles.push(ROLES.ALLOW_EDIT_CREDIT);
    }

    if (data.rights.isHauptstrukturleitenr) {
      roles.push(ROLES.IS_HAUPTSTRUKTURLEITENR)
    }

    if (data.rights.showFee) {
      roles.push(ROLES.SHOW_FEE)
    }

    if (data.rights.isOpenSignaturesReadOnly) {
      roles.push(ROLES.IS_OPEN_SIGNATURES_READ_ONLY);
    }

    if (data.rights.showEvents) {
      roles.push(ROLES.SHOW_EVENTS);
    }

    if (data.rights.brauchtHaftungserklaerungNewBroker) {
      roles.push(ROLES.BRAUCHT_HAFTUNGSERKLAERUNG_NEW_BROKER);
    }

    if (data.rights.darfVermittlerAnlegen) {
      roles.push(ROLES.DARF_VERMITTLER_ANLEGEN);
    }

    if (data.rights.showMarktbericht) {
      roles.push(ROLES.SHOW_MARKTBERICHT);
    }

    if (data.rights.showVVMarktbericht) {
      roles.push(ROLES.SHOW_VV_MARKTBERICHT);
    }

    if (data.rights.editConfigsDefinition) {
      roles.push(ROLES.EDIT_CONFIGS_DEFINITION);
    }

    if (data.rights.editVersicherungenFields) {
      roles.push(ROLES.EDIT_VERSICHERUNGEN_FIELDS);
    }

    if (data.rights.hatBeteiligungen) {
      roles.push(ROLES.HAT_BETEILIGUNGEN);
    }

    if (data.rights.hatVersicherungsvermoegen) {
      roles.push(ROLES.HAT_VERSICHERUNGSVERMOEGEN);
    }

    if (data.rights.hatRueckKaufsWert) {
      roles.push(ROLES.HAT_RUECKKAUFSWERT);
    }
  }

  if (data?.allowConfigFCConfigGlobal) {
    roles.push(ROLES.ALLOW_CONFIG_FC_CONFIG_GLOBAL);
  }

  if (data.rights.VERSAND_PROTOKOLLE_PROTOKOLL) {
    roles.push(ROLES.VERSAND_PROTOKOLLE_PROTOKOLL);
  } else if (data.rights.VERSAND_PROTOKOLLE_VERWALTUNG) {
    roles.push(ROLES.VERSAND_PROTOKOLLE_VERWALTUNG);
  }

  if (data.rights.VIEW_ANTRAG_CHECK) {
    roles.push(ROLES.VIEW_ANTRAG_CHECK)
  }
  if (data.rights.VIEW_SIPGATE) {
    roles.push(ROLES.VIEW_SIPGATE)
  }
  if (data.rights.DBM) {
    roles.push(ROLES.DBM)
  }
  if (data.rights.INS_ZIP_FILE) {
    roles.push(ROLES.INS_ZIP_FILE)
  }
  if (data.rights.CHAT_TEST_SESSION_QUERY) {
    roles.push(ROLES.CHAT_TEST_SESSION_QUERY)
  }
  if (data.rights.VIEW_PERSONLICHES_GESCHAEFT) {
    roles.push(ROLES.VIEW_PERSONLICHES_GESCHAEFT)
  }

  if (data.rights.VIEW_COURTAGETABELLE_VERSICHERUNG) {
    roles.push(ROLES.VIEW_COURTAGETABELLE_VERSICHERUNG)
  }

  if (data.rights.VIEW_VV_STRATEGIEN) {
    roles.push(ROLES.VIEW_VV_STRATEGIEN);
  }
  if (data.rights.VIEW_VV_PRODUKTE) {
    roles.push(ROLES.VIEW_VV_PRODUKTE);
  }
  if (data.rights.VIEW_VV_KUNDEN) {
    roles.push(ROLES.VIEW_VV_KUNDEN);
  }
  if (data.rights.VIEW_VV_SUITABILITY) {
    roles.push(ROLES.VIEW_VV_SUITABILITY);
  }
  if (data.rights.VIEW_VV_ADVISOR_FRAGEBOGEN) {
    roles.push(ROLES.VIEW_VV_ADVISOR_FRAGEBOGEN);
  }

  if (data.rights.blockedAbrechnungenStatistiken) {
    roles.push(ROLES.BLOCKED_ABRECHNUNGEN_STATISTIKEN);
  }

  if (data.hasAktuellerQuartalsbericht) {
    roles.push(ROLES.HAS_AKTUELLER_QUARTALSBERICHT);
  }

  if (data.rights.isBiproTester) {
    roles.push("BIPRO_TESTER");
  }

  if (data.rights.sonderrechtKundennr) {
    roles.push(ROLES.SONDERRECHT_KUNDENNR)
  }

  if (data.rights.hatSyncUser) {
    roles.push(ROLES.HAT_SYNC_USER);
  }

  if (data.rights.terminkalenderSyncAktiv) {
    roles.push(ROLES.TERMINKALENDER_SYNC_AKTIV);
  }

  if (data.rights.isConfigurationReadOnly) {
    roles.push(ROLES.IS_CONFIGURATION_READ_ONLY);
  }

  if (data.rights.isRestrictionToBrokerConfigEnabled) {
    roles.push(ROLES.IS_RESTRICTION_TO_BROKER_CONFIG_ENABLED);
  }

  if (data.rights.isFAVermittlerDatenAllowed) {
    roles.push(ROLES.IS_FA_VERMITTLER_DATEN_ALLOWED);
  }

  if (data.rights.depotauszugGuvFarbe) {
    roles.push(ROLES.DEPOTAUSZUG_GUV_FARBE)
  }

  if (data.rights.canAddNewSparten) {
    roles.push(ROLES.CAN_ADD_NEW_SPARTEN)
  }

  if (data.rights.darfVersicherungMaklerauftragSehen) {
    roles.push(ROLES.DARF_VERSICHERUNG_MAKLERAUFTRAG_SEHEN);
  }

  if (data.rights.hasExPostBerechnung) {
    roles.push(ROLES.HAS_EX_POST_BERECHNUNG)
  }

  if (data.rights?.showBrokerStatisticsMitarbeiter) {
    roles.push(ROLES.SHOW_BROKER_STATISTICS_MITARBEITER)
  }

  if (data.rights?.zugriffCourtagetabelleFonds) {
    roles.push(ROLES.ZUGRIFF_COURTAGE_TABELLE_FONDS)
  }

  if (data.rights?.hasFinanzportal24) {
    roles.push(ROLES.HAS_FINANZPORTAL24)
  }

  if (data.rights?.hasFinanzportal24OnlineBestellanleitung) {
    roles.push(ROLES.HAS_FINANZPORTAL24_ONLINE_BESTELLANLEITUNG)
  }




  return {
    roles
  }
}

export default ROLES
