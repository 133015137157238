<template>
  <div class="customer-picture__container" @click="$emit('click')">

    <template v-if="userPictureUrl || image">
        <div 
          :class="{
          'image-cropper--small': size === 'small',
          'image-cropper--large': size === 'large'
          }" >

          <div :class="{
          'rounded--small': size === 'small',
          'rounded--large': size === 'large'
          }" :style="style">
            
          </div>
        
        </div>
    </template>
    <template v-else>
      <div class="customer-picture__image-placeholder customer-picture__container-img"
        :class="{
          'customer-picture__container-img--small': size === 'small',
          'customer-picture__container-img--large': size === 'large'
        }">
        <ph-user :size="24" />
      </div>
    </template>

  </div>
</template>

<script>
import CORE_TYPES from '@/store/core/types';
import { mapGetters } from 'vuex';
import { PhUser } from 'phosphor-vue'

export default {
  props: {
    size: {
      type: String,
      default: 'small'
    },
    image: {
      type: String,
      default: ''
    },
  },
  computed: {
    ...mapGetters({
      userPictureUrl: CORE_TYPES.GETTERS.GET_USER_PICTURE_URL,
    }),
    style() {
      return `background-image: url("${this.image || this.userPictureUrl}");`;
    }
  },
  components: {
    PhUser
  }
}
</script>

<style scoped>

  .customer-picture__image-placeholder {
    color: var(--color-workspaces-nav-background);
    background-color: var(--color-workspaces-nav-text);
  }

  .customer-picture__container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex: 0 0 auto;
  }

  .customer-picture__container-div {
    border-radius: 100%;
    background-size: cover;
    background: #eee no-repeat center;
  }

  .customer-picture__container-img {
    border-radius: 50%;
  }

  .image-cropper--small {
    width: 24px;
    height: auto;
    position: relative;
    overflow: hidden;
  }
   .customer-picture__container-img--small {
    width: 24px;
    height: 24px;
   }

   .customer-picture__container-img--large {
    width: 100px;
    height: 100px;
   }

  .image-cropper--large {
    width: 100px;
    height: auto;
    position: relative;
    overflow: hidden;
  }

  .rounded--small {
    display: block; 
    margin: 0 auto; 
    height:24px; 
    width: 24px; 
    background-size: cover;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .rounded--large {
    height:100px; 
    width: 100px; 
    display: block; 
    margin: 0 auto; 
    background-size: cover;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }  

  .customer-picture__container-div--small {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }

  .customer-picture__container-div--large {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }

</style>