import ABRECHNUNG_TYPES from './types';
import axios from 'axios';

export default {
  async [ABRECHNUNG_TYPES.ACTIONS.INIT]({ commit, rootState }) {
    const response = await axios.get(rootState.core.apiAddress + '/abrechnung/init', {});
    if (response.data) {
      commit(ABRECHNUNG_TYPES.MUTATIONS.FORM, response.data);
    }
  },
  [ABRECHNUNG_TYPES.ACTIONS.GETDATA]({ rootState }, payload) {
    return axios.post(rootState.core.apiAddress + '/abrechnung/get_data?'+payload.action, payload, {defaultSpinner: true});
  },
  [ABRECHNUNG_TYPES.ACTIONS.PDF_LISTE]({ rootState }, payload) {
    return axios.post(rootState.core.apiAddress + '/abrechnung/pdf_liste?'+payload.action, payload, {defaultSpinner: true});
  },
  [ABRECHNUNG_TYPES.ACTIONS.BUCHUNGEN]({ rootState }, payload) {
    return axios.post(rootState.core.apiAddress + '/abrechnung/buchungen?'+payload.action, payload, {defaultSpinner: true});
  },
}