var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "modal",
      attrs: { showDefaultButtons: false, actions: _vm.modalActions },
      on: {
        close: _vm.close,
        "action-SAVE": function($event) {
          return _vm.confirmAccept()
        },
        "action-CLOSE": function($event) {
          return _vm.close()
        },
        "action-TOGGLE-FREE-STYLE": function($event) {
          _vm.freeStyle = !_vm.freeStyle
        }
      },
      scopedSlots: _vm._u([
        {
          key: "modalTitle",
          fn: function() {
            return [_vm._v(" Bild zuschneiden ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("div", [
        _vm.simpleDialog
          ? _c("div", [
              _c(
                "div",
                { staticClass: "main" },
                [
                  _c("EdgeSelectionDialog", {
                    attrs: { imageData: _vm.src, corners: null },
                    on: { change: _vm.onChange }
                  })
                ],
                1
              )
            ])
          : _c("div", [
              !_vm.freeStyle
                ? _c("div", { staticClass: "simple-main__wrapper" }, [
                    _c(
                      "div",
                      { staticClass: "main" },
                      [
                        _c(
                          "div",
                          {
                            style: {
                              "max-width": "100%",
                              position: "relative",
                              height: "100%"
                            }
                          },
                          [
                            _c("img", {
                              ref: "img",
                              attrs: { src: _vm.src, crossorigin: "anonymous" }
                            })
                          ]
                        ),
                        _c("div", {
                          staticClass: "overlay",
                          style: {
                            left: 0,
                            top: 0,
                            bottom: (1 - _vm.bottom) * 100 + "%",
                            right: (1 - _vm.left) * 100 + "%"
                          }
                        }),
                        _c("div", {
                          staticClass: "overlay",
                          style: {
                            left: 0,
                            top: _vm.bottom * 100 + "%",
                            bottom: 0,
                            right: (1 - _vm.right) * 100 + "%"
                          }
                        }),
                        _c("div", {
                          staticClass: "overlay",
                          style: {
                            left: _vm.right * 100 + "%",
                            top: _vm.top * 100 + "%",
                            bottom: 0,
                            right: 0
                          }
                        }),
                        _c("div", {
                          staticClass: "overlay",
                          style: {
                            left: _vm.left * 100 + "%",
                            top: 0,
                            bottom: (1 - _vm.top) * 100 + "%",
                            right: 0
                          }
                        }),
                        _c("div", {
                          staticClass: "overlay",
                          style: {
                            left: _vm.left * 100 + "%",
                            top: _vm.top * 100 + "%",
                            bottom: (1 - _vm.bottom) * 100 + "%",
                            right: (1 - _vm.right) * 100 + "%",
                            backgroundColor: "unset",
                            outline: "1px solid black"
                          }
                        }),
                        _vm._l(_vm.corners, function(corner, index) {
                          return _c("div", {
                            key: index,
                            staticClass: "corner",
                            style: {
                              left: corner.x * 100 + "%",
                              top: corner.y * 100 + "%"
                            },
                            on: {
                              mousedown: function(e) {
                                return _vm.dragStart(corner, e)
                              },
                              touchstart: function(e) {
                                return _vm.dragStart(corner, e)
                              }
                            }
                          })
                        })
                      ],
                      2
                    )
                  ])
                : _c(
                    "div",
                    { staticClass: "main" },
                    [
                      _c("EdgeSelectionDialog", {
                        attrs: {
                          imageData: _vm.src,
                          corners: _vm.detectedCorners
                        },
                        on: { change: _vm.onChange }
                      })
                    ],
                    1
                  )
            ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }