import BIPRO_TYPES from './types';

export default {
    [BIPRO_TYPES.GETTERS.GESELLSCHAFT_OPTIONS](state) {
        return state.gesellschaftOptions;
    },
    [BIPRO_TYPES.GETTERS.GESELLSCHAFT_OPTIONS_WITH_ALL](state) {
        return state.gesellschaftOptions
            ? [{label: "Alle", value: "PARAMETER_ALLE"}, ...state.gesellschaftOptions]
            : state.gesellschaftOptions;
    },
    [BIPRO_TYPES.GETTERS.GESELLSCHAFT_OPTIONS_INTERFACE](state) {
        return state.gesellschaftOptionsInterface;
    },
    [BIPRO_TYPES.GETTERS.SAVED_FILTERS](state) {
        return state.savedFilters;
    },
    [BIPRO_TYPES.GETTERS.SAVED_SORTING](state) {
        return state.savedSorting;
    }, 
    [BIPRO_TYPES.GETTERS.PARAM_RETURN_VERS_VERTRAG](state) {
        return state.paramReturnVersVertrag;
    }, 
}