var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseModal", {
    ref: "modal",
    attrs: { showDefaultButtons: false },
    on: { onCloseButton: _vm.close },
    scopedSlots: _vm._u([
      {
        key: "modalTitle",
        fn: function() {
          return [
            _c("InputField", {
              attrs: { label: "Neue Telefonnummer für Sicherheitscodes" },
              on: {
                change: function($event) {
                  return _vm.clearErrorStr()
                }
              },
              model: {
                value: _vm.phoneNumber,
                callback: function($$v) {
                  _vm.phoneNumber = $$v
                },
                expression: "phoneNumber"
              }
            }),
            _c(
              "BaseButton",
              {
                attrs: {
                  disabled:
                    !_vm.validatePhoneNumber(_vm.phoneNumber) ||
                    _vm.tokenButtonsDisabled,
                  isSecondary: ""
                },
                on: { click: _vm.requestCodes }
              },
              [
                _vm._v(
                  "Sicherheitscode " +
                    _vm._s(_vm.codesRequested ? "erneut " : "") +
                    "senden"
                )
              ]
            ),
            _vm.codesRequested
              ? _c("InputField", {
                  staticClass: "mt-4",
                  attrs: { label: "SMS Code" },
                  model: {
                    value: _vm.smsCode,
                    callback: function($$v) {
                      _vm.smsCode = $$v
                    },
                    expression: "smsCode"
                  }
                })
              : _vm._e(),
            _vm.errorStr
              ? _c("div", { staticClass: "color-danger mt-2" }, [
                  _vm._v(_vm._s(_vm.errorStr))
                ])
              : _vm._e()
          ]
        },
        proxy: true
      },
      {
        key: "footer",
        fn: function() {
          return [
            _c(
              "BaseButton",
              {
                attrs: {
                  disabled:
                    !_vm.validatePhoneNumber(_vm.phoneNumber) ||
                    _vm.smsCode.length <= 0,
                  isPrimary: ""
                },
                on: { click: _vm.changePhone }
              },
              [_vm._v("Telefonnummer ändern")]
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }