<template>
  <div v-if="buttonLabel && rowId">
    <BaseButton 
      class="m-0"
      isSecondary 
      :disabled="disabled || isLoading"
      :animated="isLoading"
      @click="applyConfig"
    >{{ buttonLabel }}</BaseButton>

    <OptionsMenuApplyConfigModal 
      ref="applyConfigModal" 
      :title="applyConfirmModalTitle" 
      :rowId="rowId"
      :configContext="configContext"
      :configId="configId" 
      :userLevel="userLevel"
      :showFilter="isPartial"
      @onConfirm="confirmApply" 
    />
  </div>
</template>

<script>
import MENU_CONFIG_TYPES from '@/store/menuConfig/types';
import { FC_CONFIG_USER_LEVEL } from '@/configs/fcConfig';

import BaseButton from '@/components/core/BaseButton.vue';
import OptionsMenuApplyConfigModal from '@/components/core/option-menu/optionMenuConfig/OptionsMenuApplyConfigModal.vue';

import { OptionsMenuDiffHelper } from '@/menu/menu-config-utils';

const ApplyRowID = Object.freeze({
  [FC_CONFIG_USER_LEVEL.MAKLER_STRUKTUR]: 'maklernr',
  [FC_CONFIG_USER_LEVEL.MAKLER_PERSONEN]: 'unternr',
});

const ApplyToAllTitles = Object.freeze({
  [FC_CONFIG_USER_LEVEL.MAKLER_STRUKTUR]: {
    BUTTON_LABEL: 'In Alle Unterstruktur übernehmen',
    CONFIRM_APPLY_TO_ALL_MESSAGE: 'Möchten Sie es wirklich in "Alle Unterstruktur" übernehmen?',
  },
  [FC_CONFIG_USER_LEVEL.MAKLER_PERSONEN]: {
    BUTTON_LABEL: 'In Alle Mitarbeiter übernehmen',
    CONFIRM_APPLY_TO_ALL_MESSAGE: 'Möchten Sie es wirklich in "Alle Mitarbeiter" übernehmen?',
  },
});

const ApplyToPartialTitles = Object.freeze({
  [FC_CONFIG_USER_LEVEL.MAKLER_STRUKTUR]: {
    BUTTON_LABEL: 'In teilweise Unterstruktur übernehmen',
  },
  [FC_CONFIG_USER_LEVEL.MAKLER_PERSONEN]: {
    BUTTON_LABEL: 'In teilweise Mitarbeiter übernehmen',
  },
});

const logsUserLevelNotImplementedYet = userLevel => console.log(`FC_CONFIG_USER_LEVEL = "${userLevel}" not implemented yet!`);

export default {
  components: {
    BaseButton,
    OptionsMenuApplyConfigModal,
  },
  props: {
    configContext: {
      type: String,
      required: true,
    },
    configId: {
      type: String,
      required: true,
    },
    userLevel: {
      type: String,
      required: true,
    },
    isPartial: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      applying: false,
      loading: false,
    };
  },
  computed: {
    isLoading() {
      return this.applying || this.loading;
    },
    buttonLabel() {
      if (this.isPartial) {
        return ApplyToPartialTitles?.[this.userLevel]?.BUTTON_LABEL;
      }

      return ApplyToAllTitles?.[this.userLevel]?.BUTTON_LABEL;
    },
    applyConfirmModalTitle() {
      if (this.isPartial) {
        return 'Konfiguration übernehmen';
      }

      return 'Daten-Konflikt';
    },
    rowId() {
      return ApplyRowID?.[this.userLevel];
    },
  },
  methods: {
    applyConfig() {
      if (this.isPartial) {
        this.applyToPartial();
      } else {
        this.applyToAll();
      }
    },
    async applyToPartial() {
      const optionsMenuConfigs = await this.findAllOptionsMenuConfig();
      this.$refs.applyConfigModal.open(optionsMenuConfigs);
    },
    async applyToAll() {
      try {
        await this.$confirmModal({ 
          message: ApplyToAllTitles?.[this.userLevel]?.CONFIRM_APPLY_TO_ALL_MESSAGE,
        });
  
        this.applying = true;
        const optionsMenuConfigs = await this.findAllOptionsMenuConfig();
        const configsWithConflict = optionsMenuConfigs
          .filter(config => OptionsMenuDiffHelper.hasConflict(this.configContext, this.configId, this.userLevel, config.content));
  
        if(configsWithConflict.length > 0) { // shows conflicts on modal when there is at least one
          this.$refs.applyConfigModal.open(configsWithConflict);
        } else { // applies direct when there is no conflict
          await this.confirmApplyToAll();
        }
      } catch(e) {
        // empty block
      } finally {
        this.applying = false;
      }
    },
    async findAllOptionsMenuConfig() {
      try {
        this.loading = true;

        const payload = { 
          configId: this.configId,
          onlyWithConfig: !this.isPartial,
        };
  
        switch (this.userLevel) {
          case FC_CONFIG_USER_LEVEL.MAKLER_STRUKTUR: // Unterstruktur
            return await this.$store.dispatch(MENU_CONFIG_TYPES.ACTIONS.FIND_ALL_OPTIONS_MENU_CONFIG_UNTERSTRUKTUR, payload)
          case FC_CONFIG_USER_LEVEL.MAKLER_PERSONEN: // Mitarbeiter
            return await this.$store.dispatch(MENU_CONFIG_TYPES.ACTIONS.FIND_ALL_OPTIONS_MENU_CONFIG_MITARBEITER, payload);
          default: 
            logsUserLevelNotImplementedYet(this.userLevel);
        }
      } finally {
        this.loading = false;
      }

      return [];
    },
    async confirmApply(args) {
      try {
        this.applying = true;

        if (this.isPartial) {
          await this.confirmApplyToPartial(args);
        } else {
          await this.confirmApplyToAll(args);
        }
      } finally {
        this.applying = false;
      }
    },
    async confirmApplyToPartial({ selected = [] } = {}) {
      const payload = { 
        configContext: this.configContext,
        configId: this.configId,
        selected,
      };

      switch (this.userLevel) {
        case FC_CONFIG_USER_LEVEL.MAKLER_STRUKTUR: // Unterstruktur
          await this.$store.dispatch(MENU_CONFIG_TYPES.ACTIONS.APPLY_OPTIONS_MENU_CONFIG_TO_UNTERSTRUKTUR, payload);
          break;
        case FC_CONFIG_USER_LEVEL.MAKLER_PERSONEN: // Mitarbeiter
          await this.$store.dispatch(MENU_CONFIG_TYPES.ACTIONS.APPLY_OPTIONS_MENU_CONFIG_TO_MITARBEITER, payload);
          await this.$store.dispatch(MENU_CONFIG_TYPES.ACTIONS.APPLY_OPTIONS_MENU_PERMISSION_CONFIG_TO_MITARBEITER, payload);
          break;
        default:
          logsUserLevelNotImplementedYet(this.userLevel);
      }
    },
    async confirmApplyToAll({ ignored = [] } = {}) {
      const payload = {
        configContext: this.configContext,
        configId: this.configId,
        ignored,
      };

      switch (this.userLevel) {
        case FC_CONFIG_USER_LEVEL.MAKLER_STRUKTUR: // Unterstruktur
          await this.$store.dispatch(MENU_CONFIG_TYPES.ACTIONS.APPLY_OPTIONS_MENU_CONFIG_TO_ALL_UNTERSTRUKTUR, payload);
          break;
        case FC_CONFIG_USER_LEVEL.MAKLER_PERSONEN: // Mitarbeiter
          await this.$store.dispatch(MENU_CONFIG_TYPES.ACTIONS.APPLY_OPTIONS_MENU_CONFIG_TO_ALL_MITARBEITER, payload);
          break;
        default:
          logsUserLevelNotImplementedYet(this.userLevel);
      }
    },
  },
  created() {
    if (!(this.userLevel in ApplyRowID)) {
      logsUserLevelNotImplementedYet(this.userLevel);
    }
  },
}
</script>
