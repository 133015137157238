var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: _vm.title,
          subtitle: _vm.subtitle,
          actions: _vm.headerActions
        },
        on: {
          "action-DISCARD": function($event) {
            return _vm.discardChanges()
          },
          "action-BACK": function($event) {
            return _vm.navigateToBackPath()
          },
          "action-COPY-ADDRESS": _vm.copyAddress,
          "action-CONVERT": function($event) {
            return _vm.convertPersonToCustomer()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }