<template>
  <BaseModal 
    ref="modal"
    modalTitle="Daten-Konflikt"
    size="md"
    :confirmDisabled="!selectedRows.length"
    @onConfirmButton="confirm()"
  >
    <p class="mb-8px">
      Es sind bereits Felder befüllt, die bei dem automatischen Auslesen zu einem Konflikt führen.
      Bitte wählen sie die Felder, die überschrieben werden sollen:
    </p>
    <Table
        tableId="8575cd74-cd3c-4182-b699-7f2ed45a6743"
        :headers="headers"
        :rows="rows"
        :rowsPerPage="10"
        rowId="key"
        v-model="selectedRows"
    >
      <template #newValue="{newValue,invalid}">
        <div :class="{ 'color-danger': !!invalid, }">{{ newValue }}</div>
      </template>
    </Table>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/core/BaseModal.vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn,SlotColumn} from "@/components/table2/table_util.js";

const MAP_KEY_TO_FIELD_TITLE = {
  title: 'Anrede',
  lastName: 'Name',
  firstName: 'Vorname',
  street: 'Straße',
  zip: 'Postleitzahl',
  city: 'Ort',
  country: 'Land',

  dayOfBirth: 'Geburtsdatum',
  nationality: 'Staatsangehörigkeit',
  nameAtBirth: 'Geburtsname',
  placeOfBirth: 'Geburtsort',

  legitimNumber: 'Ausweisnummer',
  authority: 'Ausstellungsbehörde',
  dateOfIssue: 'Ausstellungsdatum',
  validTo: 'Gültig bis',
};


export default {
  data() {
    return {
        data: [],
        selectedRows: [],
        headers: {
            lockedLeft: [
                TextColumn("label", "Feld"),
                TextColumn("currentValue", "aktueller Wert"),
                SlotColumn("newValue", "neuer Wert").makeSortable(),
            ],
        },
    };
  },
  computed: {
      rows() {
          return (this.data || [])
              .filter(row => row.key in MAP_KEY_TO_FIELD_TITLE)
              .map(row => ({
                  ...row,
                  label: MAP_KEY_TO_FIELD_TITLE[row.key],
              }))
              .sort((item1, item2) => item1.label.toLowerCase().localeCompare(item2.label.toLowerCase()));
      },
  },
  methods: {
    open(data) {
      const checkedData = data?.length ? [...data] : [];
      if(checkedData?.length > 0) {
        this.selectedRows = [];
        this.data = checkedData;
        this.$refs.modal.open();
      }
    },
    close() {
      this.$refs.modal.close();
    },
    confirm() {
      this.$emit('confirm', [...this.selectedRows]);
    },
  },
  components: {
    BaseModal,
    Table,
  },
}
</script>
