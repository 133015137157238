<template>
  <div>
    <label class="bold">{{ label }}</label>
    <div class="row pl-2">
      <div class="col-12 col-md-8 col-lg-10 basisfonds" :class="{'invalid': isInvalid}">
        {{ getFondStr }}
      </div>
      <div class="col-12 col-md-4 col-lg-2 pt-2">
        <div class="d-flex">
          <a :class="{'disabled': disabled || disableBtn}" @click="openFondsfinder" title="Position hinzufügen">
            <ph-pencil :size="26" class="pl-2"/>
          </a>
          <a :class="{'disabled': disabled || disableBtn}" @click="doDeletePosition()" title="Position löschen">
            <ph-trash :size="26" class="pl-2"/>
          </a>
        </div>
      </div>
      
    </div>
    <div class="mt-2" v-if="config && config.betragId">
      <InputField v-if="position"
        :isComponentHalfSize="true"
        v-model="betrag" label="Betrag" type="currency"
        @change="updateBetrag($event)"/>
    </div>

    <div v-if="position && config && config.positionFields">
        <WertpapierAuswahlPositionFields 
          :config="config"
          :categoryId="id"
          :antragId="antragId" 
        />
    </div>

    <BaseModal 
      ref="removeModal"
      labelButtonConfirm="Position entfernen"
      labelButtonCancel="Abbrechen"
      @onConfirmButton="doDeletePosition()"
      @onCancelButton="$refs.hatFehler.close()">
      <template v-slot:modalTitle>
        Position entfernen?
      </template>
      <div>
        Soll die Position <b>{{ wertpapiername }} (ISIN {{ position && position.isin }}) </b>
        wirklich entfernt werden?
      </div>
    </BaseModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CORE_TYPES from '@/store/core/types.js';
import FONDSFINDER_TYPES from '@/store/fondsfinder/types';
import ANTRAG_TYPES from '@/store/antrag/types';
import InputField from '@/components/core/forms/InputField.vue';
import BaseModal from '@/components/core/BaseModal.vue';
import antragNavigationMixin from '@/mixins/antrag/antrag-navigation-mixin.js';
import WertpapierAuswahlPositionFields from '@/components/core/forms/antraege/wertpapierAuswahl/WertpapierAuswahlPositionFields.vue';
import validator from "@/mixins/validator";
import { PhPencilLine, PhTrash, PhPencil } from 'phosphor-vue';
import antragMixin from "@/mixins/antrag/antrag-mixin.js";

export default {
  mixins: [antragNavigationMixin, antragMixin, validator],
  validators: {},
  props: {
    id: {
      type: String,
    },
    label: {
      type: String,
      required: true,
    },
    antragWarnings: {
      type: Array
    },
    data: {
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    antragId: {
    },
    config: {
    }
  },
  emits: ['change'],
  data() {
    return {
      ignoreWarning: false,
      disableBtn: false,
      betrag: '',
    }
  },
  mounted() {
    this.betrag = this.position && this.config && this.config.betragId && this.position[this.config.betragId];
  },
  computed: {
    ...mapGetters({
      loginData: CORE_TYPES.GETTERS.GET_LOGIN_DATA,
      positionInfo: FONDSFINDER_TYPES.GETTERS.POSITION_INFO,
      positionsAll: ANTRAG_TYPES.GETTERS.POSITIONS,
    }),
    position() {
       if (this.positionsAll && this.antragId && this.id 
          && this.positionsAll[this.antragId] 
          && this.positionsAll[this.antragId][this.id]) {
        return this.positionsAll[this.antragId][this.id].length ? this.positionsAll[this.antragId][this.id][0] : null;
      }
    },
    isInvalid() {
      const warn = this.getBetragWarningMessage;
      if (warn) {
        this.$pushErrors('betrag', warn);
      }
      return !this.ignoreWarning && this.antragWarnings && this.antragWarnings.some(warn => warn.id === this.id);
    },
    getBetragWarningMessage() {
      return !this.ignoreWarning && this.config && this.config.betragId && this.antragWarnings 
        && (this.antragWarnings.find(warn => warn.id && warn.id.includes(this.config.betragId) 
        && warn.id.includes(this.id)) || {}).message;
    },
    wertpapiername() {
      return this.position && this.positionInfo && this.positionInfo[this.position.isin] && this.positionInfo[this.position.isin].wertpapiername || ''; 
    },
    getFondStr() {
      return this.position ? (`${this.wertpapiername} (ISIN: ${this.position.isin})`) : 'Wertpapierauswahl ist leer.';
    },
  },
  methods: {
    updateBetrag(value) {
      if (this.position && this.config.betragId) {
        const positionChange = { [this.config.betragId]: value }
        const payload = {
          id: this.antragId,
          categoryId: this.id,
          fonds: [
            { 
              ...positionChange,
              isin: this.position.isin,
            }
          ]
        }
        this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_POSITIONS, payload);
      }
    },
    doDeletePosition() {
      this.deletePosition(this.antragId, this.id, this.position);
      this.positionToDelete = null;
    },

    openFondsfinder() {
      this.ignoreWarning = true;
      if (!this.disableBtn) {
        this.goToFormsFinder(this.id, this.config, this.suppressFilterLagerstelle);
      }
    },
  },
  components: {
    InputField,
    BaseModal,
    WertpapierAuswahlPositionFields,
    PhPencilLine,
    PhTrash,
    PhPencil
  },
}
</script>
<style scoped>
  .basisfonds {
      background-color: #ebebe4;
      padding: 0.65rem 1.25rem 0.4rem 1.25rem;
      /* margin-left: 1rem; */
      border-width: 1px;
      border-radius: 2px;
      border-color: #c8c8c2;
      padding-left: 1rem;
  }

  a.disabled {
      color:  #c8c8c2;
      pointer-events: none;
      cursor: default;
  }

  .invalid {
    border: 1px solid var(--color-danger);
  }

  .bold {
    font-weight: bold;
  }

  .las {
    font-size: 1.5rem;
  }
</style>
