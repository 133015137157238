var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isCollapsable
        ? _c("BaseCollapsable", {
            scopedSlots: _vm._u(
              [
                {
                  key: "title",
                  fn: function() {
                    return [_c("strong", [_vm._v("Passwortrichtlinien")])]
                  },
                  proxy: true
                },
                {
                  key: "content",
                  fn: function() {
                    return [
                      _c("p", [
                        _vm._v(
                          "Das Passwort muss mindestens eine Länge von 6 Zeichen haben."
                        )
                      ]),
                      _c("p", [
                        _vm._v(
                          "Es muss zumindest ein Großbuchstabe, ein Kleinbuchstabe und eine Zahl enthalten sein."
                        )
                      ]),
                      _c("p", [
                        _vm._v(
                          "Die Nutzerkennung darf nicht Teil des Passworts sein."
                        )
                      ])
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              2475037190
            )
          })
        : [
            _c("strong", { staticStyle: { "text-align": "center" } }, [
              _vm._v(" Passwortrichtlinien ")
            ]),
            _c("br"),
            _c("p", [
              _vm._v(
                "Das Passwort muss mindestens eine Länge von 6 Zeichen haben."
              )
            ]),
            _c("p", [
              _vm._v(
                "Es muss zumindest ein Großbuchstabe, ein Kleinbuchstabe und eine Zahl enthalten sein."
              )
            ]),
            _c("p", [
              _vm._v("Die Nutzerkennung darf nicht Teil des Passworts sein.")
            ])
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }