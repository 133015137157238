import dayjs from 'dayjs';
import {preformancePerAnno} from "@/helpers/commonfunctions";
import { formatNumber } from '@/helpers/number-formatter.js';

dayjs.locale('de');

const BARLABEL = function (params) {
	if (params?.value == null || isNaN(params?.value)) {
		return '';
	}
	return formatNumber(params.value, 2) + '%';
};
const BARSERIE = function (name, data, colorIndex, isSmallScreen) { 
  const color = COLOR_STANDARD[colorIndex % (COLOR_STANDARD.length - 1)];
  const position = colorIndex % 2 ? 'bottom' : 'top';
	return {
		name: name, 
		type: 'bar',
    barGap: isSmallScreen ? '50%' : '30%',
		color,
		label: { show: true, position, formatter: BARLABEL, distance: 10, fontSize: 10},
		labelLayout: {
			hideOverlap: true,
		},
		data: data, 
		showBackground: false,
		itemStyle: {
			shadowColor: 'rgba(0, 0, 0, 0.3)',
			shadowBlur: 6,
			shadowOffsetX: 2,
			shadowOffsetY: 0,
		}
	};
};
const BAROPTION = function (xAxis, series)  {
	return {
		legend: {
			show: true,
			bottom: 0,
		},
		yAxis: {
			show: false,
			type: 'value',
		},
		xAxis: {
			type: 'category',
			data: xAxis,
			axisTick: { show: false },
			axisLabel: { 
				interval: 'auto', // to avoid labels overlapping
				align: 'center', 
				fontSize: 12, 
				rotate: 0 
			}
		},
		series: series,
	};
};

export const COLOR_STANDARD = [ // MSCTimeSeriesChartData
  'rgba(7, 137, 7, 1)', //	grün
  'rgba(0, 0, 255, 1)', //	blau
  'rgba(218, 4, 4, 1)', //  rot
  'rgba(255, 185, 0, 1)', //	orange
  'rgba(0, 153, 153, 1)', //  türkis
  'rgba(176, 4, 200, 1)', //	lila
  'rgba(1, 80, 251, 1)', //	blau 1
  'rgba(217, 138, 102, 1)', //  braun hell
  'rgba(115, 115, 255, 1)', //  blau hell
  'rgba(122, 196, 159, 1)', //	blassgrün
  'rgba(172, 74, 35, 1)', //  braun
  'rgba(255, 84, 102, 1)', //	rosa
  'rgba(7, 97, 7, 1)', //	grün
  'rgba(0, 0, 180, 1)', //	blau
  'rgba(179, 28, 21, 1)', //	rot
  'rgba(255, 120, 0, 1)', //	orange
  'rgba(238, 155, 105, 1)', //	ocker
  'rgba(50, 128, 197, 1)', //	blau 1
  'rgba(255, 97, 168, 1)', //	pink
  'rgba(176, 4, 249, 1)', //	lila
  'rgba(7, 167, 7, 1)', //	grün
  'rgba(0, 0, 255, 0.7)', //	blau
  'rgba(190, 0, 0, 1)', //	rot	
  'rgba(255, 150, 105, 1)', //	orange
  'rgba(108, 190, 251, 1)', //	blau 1
  'rgba(184, 104, 105, 1)', //	rotbraun
  'rgba(176, 97, 255, 1)', //	lila
  'rgba(70, 185, 77, 1)', //	grün
  'rgba(53, 117, 208, 1)', //	blau
  'rgba(194, 78, 5, 1)', //	blau
  'rgba(255, 140, 0, 1)', //	orange
  'rgba(0, 93, 0, 1)', //	grün
];


export const utils = {

  getNextDeadline(nextTest, day) {
    if (day < 29) {
      return new Date(nextTest.getFullYear(), 1 + nextTest.getMonth(), day );
    }
    const lastDay = new Date(new Date(nextTest.getFullYear(), 2 + nextTest.getMonth(), 1 ).getTime() - 86400000);
    if (day <= lastDay.getDate() ) {
      return new Date(nextTest.getFullYear(), 1 + nextTest.getMonth(), day );
    }
    return lastDay;
  },  
  getAnlage(dTime, einmalInv, monatInv, quartalInv, jahrInv, ixVon, ixBis) {
    ixVon = ixVon == null ? 0 : ixVon;
    ixBis = ixBis == null ? dTime.length : ixBis;
    const anlageData = [];
    let nextTest = new Date(dTime[ixVon]);
    const day = nextTest.getDate();
    
    nextTest = this.getNextDeadline(nextTest, day);
    let monatCount = 1;
    let investment = einmalInv + monatInv + quartalInv + jahrInv;
    dTime.forEach( (dat, ixS) => {
      if (ixS < ixVon || ixS >= ixBis) {
        anlageData.push(null);
      } else {
        if ( dat >= nextTest.getTime() ) {
          investment = investment + monatInv;
          if (monatCount && (monatCount % 3) === 0) {
            investment = investment + quartalInv;
          }
          if (monatCount && (monatCount % 12) === 0) {
            investment = investment + jahrInv;
          }
          
          nextTest = this.getNextDeadline(nextTest, day);
          monatCount++;
        }
        anlageData.push(investment);
      }
    });
    return anlageData;
  },
  calcIRR( beginnWert,  endWert, gewichtungsfaktor, cashFlows) {
    // if (process.env.VUE_APP_USER === 'hannes') console.log('calcIRR', beginnWert,  endWert,  JSON.parse(JSON.stringify(gewichtungsfaktor)), 'cashFlows', JSON.parse(JSON.stringify(cashFlows)) );
    let resultR1 = 0;
		let resultR2 = 0;
		let resultRi = 0;
		let endWertTmp1 = 0;
		let endWertTmp2 = 0;
		let counter = 0;
		let repeat = true;
		do {
			let einbezahlteKapital = 0;
			let abgezinsteCashflows = 0;
			let gewichtung = 0;
			for (let i = 0; i < gewichtungsfaktor.length; i++) {
				abgezinsteCashflows += cashFlows[i] * Math.pow((1 + resultRi), gewichtungsfaktor[i]);
				einbezahlteKapital += cashFlows[i];
				gewichtung += cashFlows[i] * gewichtungsfaktor[i];
			}
			endWertTmp2 = beginnWert * (1 + resultRi) + abgezinsteCashflows;

			repeat = Math.abs(endWert - endWertTmp2) / endWert * 100 > 0.001 && counter < 50;

			if (resultR2 === 0) {
				resultR2 = (endWert - beginnWert - einbezahlteKapital) / (beginnWert + gewichtung);
				if (resultR2 < -1) {
					resultR2 = -1;
				}
				resultRi = resultR2;
				endWertTmp1 = endWertTmp2;
			} else if (repeat) {
        if (((endWert - endWertTmp2) - (endWert - endWertTmp1)) == 0) {
          break;
        }
				resultRi = resultR1 -
						((endWert - endWertTmp1)
								/ ((endWert - endWertTmp2) - (endWert - endWertTmp1)))
								* (resultR2 - resultR1);
				
				resultR1 = resultR2;
				resultR2 = resultRi;
				endWertTmp1 = endWertTmp2;
			}
			counter++;
		} while (repeat);

		return resultRi * 100;
	},
  getKapitalgewichteteWertentwicklung(serieData, anlageData, anlagesummeAlsBeginnwert, irr, ixVon, ixBis, years ) {
    ixVon = ixVon == undefined ? 0 : ixVon ;
    ixBis = ixBis == undefined ? serieData.length : ixBis ;
    let von = null;
    let bis = null;
    let bisY = null;
    let i = 0;
    for (i = ixVon; i < ixBis; i++) {
      if (serieData[i][1] !== null && serieData[i][1] >= 0) {
        von = i;
        break;
      }
    }
    for (i = ixBis - 1; i >= 0; i--) {
      if (serieData[i][1] !== null && serieData[i][1] >= 0) {
        if (bisY == null) {
          bisY = i;
        }
        if (serieData[i][1] > 0) {
          bis = i;
          break;
        }
      }
    }
    if (von === null || bis === null) {
      return null;
    }

    if (!years) {
      years = !serieData?.length ? 1 : (serieData[bisY][0] - serieData[von][0] - 86400000) / (86400000 * 365) ;
    }
    if (!anlageData ) {
      const beginnWert = serieData[von][1];
      const endWert = serieData[bis][1];
      const we = (100 * (endWert - beginnWert) / beginnWert);
      return [we, preformancePerAnno(years, we), endWert - beginnWert, years];
		}
    let gewichtungsfaktor = [];
    let cashFlows =  [];
    let cashFlow = null;
    let gewichtungsfaktorElem = 0;
    let cashFlowsElem =  0;
    let gewichtung = 0;
		let einbezahlteKapital = 0;
    for (let i = von; i <= bis; i++) {
      let cashFlowTmp = anlageData[i];

      if (cashFlow === null || cashFlow != cashFlowTmp) {
        if (cashFlow != null) {
          cashFlowsElem = cashFlowTmp - (cashFlow || 0);
          gewichtungsfaktorElem = ( serieData[bis][0] -  serieData[i][0]) / (serieData[bis][0]  - serieData[von][0]  );
          cashFlows.push(cashFlowsElem);
          gewichtungsfaktor.push(gewichtungsfaktorElem);
          gewichtung += cashFlowsElem * gewichtungsfaktorElem;
          einbezahlteKapital += cashFlowsElem;
        }
        cashFlow = cashFlowTmp;
      }
    }
    anlagesummeAlsBeginnwert = anlageData[von] != null && anlagesummeAlsBeginnwert;
    const beginnWert = !anlagesummeAlsBeginnwert ? serieData[von][1] : anlageData[von];
    const endWert = serieData[bis][1];
    if ( irr ){
      const we = this.calcIRR(beginnWert, endWert, gewichtungsfaktor, cashFlows);
      return [we, preformancePerAnno(years, we), endWert-beginnWert - einbezahlteKapital, years];
    } else { // Dietz
      const we = beginnWert + gewichtung == 0 ? 0 : ((endWert - beginnWert - einbezahlteKapital) * 100 / (beginnWert + gewichtung)) ;
      return [we, preformancePerAnno(years, we), endWert-beginnWert, years];
    }
  },
  calcVola(serieData, anlageData, len, ixVon, ixBis) {
    const result = [];
    if ( serieData.length ) {
      ixVon = ixVon == null ? 0 : ixVon;
      ixBis = ixBis == null ? serieData.length : ixBis;
      let lastValue = null, value = 0, investment = (anlageData ? anlageData[0] : 0), sum = 0, cr = 0;
      len = len ? len : serieData.length ;
      for (let ixX=0; ixX < len; ixX++) {
        if (ixX >= ixVon || ixX < ixBis) {
          value = serieData[ixX][1];
          if ( value != null && value != 0) {
            if ( lastValue === null) {
              lastValue = value;
              investment = (anlageData ? anlageData[ixX] : 0)
            } else {
              if ( anlageData && investment !== anlageData[ixX] ) {
                cr = 100 * Math.log((value - (anlageData[ixX] - investment)) / lastValue);
                investment = anlageData[ixX];
              } else {
                cr = 100 * Math.log(value / lastValue);
              }
              if (isNaN(cr)) {
                cr = 0;
              }
              result.push(cr);
              lastValue = value;
              sum += cr;
            }
          }
        }
      }
      const d = sum / result.length;
      sum = 0;
      result.forEach( r => {
        sum = sum + (r-d)*(r-d);
      })
      if (result.length > 1) {
        sum = sum / (result.length - 1);
        const v = Math.sqrt(sum * 257 / result.length) * Math.sqrt(result.length);
        return v;
      }
    }
    return 0;
  },
  sliceArray(data, von, bis) {
    von = von < 0 ? 0 : von;
    bis = bis > data.length ? data.length : bis;
    if (von === 0 && bis === data.length) {
      return data;
    } else if (bis === data.length) {
      return data.slice(von);
    }
    return data.slice(von, bis);
  },
  setColor(series) {
    series.forEach( (serie, ixS) => {
      serie.color = COLOR_STANDARD[ixS % (COLOR_STANDARD.length - 1)];
    })
  },
  convertSerie(serie, dTime) {
    const data = [];
    let temp, dt, value;
    serie.forEach( point => {
      if (point.x && point.y !== undefined) {
        dt = point.x;
        value = point.y;
      } else if (Array.isArray(point)) {
        dt = point[0];
        value = point[1];
      }
      if (typeof dt === 'string') {
        temp = dt.split(/[./]/).map( v => parseInt(v, 10));
        if ( temp[2] > 100) {
          dt = (new Date(temp[2], temp[1] - 1, temp[0])).getTime();
        } else if (temp[0] > 0) {
          dt = (new Date(temp[0], temp[1] - 1, temp[2])).getTime();
        }
      } else if (dt.getTime) {
        dt = dt.getTime();
      }
      if (value != null && typeof value === 'string') {
        value = value.replace(/[ ,]/g, '');
        if ( isNaN(value)) {
          value = null;
        } else {
          value = parseFloat(value);
        }
      }
      if (dTime){
        dTime.push(dt);
        data.push([dt, value]);
      } else {
        data.push(value);
      }
    })
    return data;
  },
  convSerieData(series) {
    let dTime;
    series.forEach( (serie, ixS) => {
      if (!serie.isConvertedData) {
        serie.color = serie.color || COLOR_STANDARD[ixS % (COLOR_STANDARD.length - 1)];
        dTime = [];
        serie.origName = serie.name;
        serie.origData = serie.data;
        serie.data = this.convertSerie(serie.data, dTime);
        if (serie.anlage) {
          serie.origAnlage = serie.anlage;
          const anlage = this.convertSerie(serie.anlage, null);
          serie.anlage = (anlage.length === serie.data.length) ? anlage : null;
        }
        serie.dTime = dTime;
        serie.isConvertedData = true;
      }
    })
    return series;
  },
  getFaktor(serie, anlageData) {
    let factor = [], value, lastValue = null, lastInvest = 0;
    serie.data.forEach( (point, ix) => {
      if (point[1] == null) {
        factor.push(lastValue === null ? null : 1)
      } else {
        if (lastValue == null) {
          lastValue = point[1];
          lastInvest = anlageData != null ? anlageData[ix] : lastValue;
          value = lastValue / lastInvest;
        } else {
          if ( anlageData != null && anlageData[ix] !== lastInvest ) {
            value = (point[1] - (anlageData[ix] - lastInvest))/ (lastValue );
            lastInvest = anlageData[ix];
          } else {
            value = point[1] / lastValue;
          }
          lastValue = point[1];
        }
        factor.push(value);
      }
    })
    return factor;
  },
  addKurs(series) {
    series.forEach( (serie) => {
      serie.factor = this.getFaktor(serie, serie.anlageData);
    })

  },
  setDataFromFactor(serie, factor, anlageData) {
    let value, lastValue = null;
    serie.data.forEach( (point, k) => {
      if (k === 0 || lastValue === null) {
        value = factor[k] * anlageData[k];
        lastValue = value;
      } else {
        if ( anlageData[k] !== anlageData[k-1] ) {
          value = factor[k] * (lastValue + (anlageData[k] - anlageData[k-1])) 
        } else {
          value = factor[k] * lastValue;
        }
        value = Math.round(value * 100) / 100;
        lastValue = value;
      }
      serie.data[k][1] = value;
    })
  },
  recalcFromFactor(serie, ixVon, ixBis, steuersatz) {
    if (!serie.dTime || !serie.invest) {
      return;
    }
    const anlageData = serie.anlageData ? serie.anlageData : this.getAnlage(serie.dTime, serie.invest[0], serie.invest[1], serie.invest[2], serie.invest[3], ixVon, ixBis);
    if (serie.anlageData == null ) {
      serie.data = anlageData.map( (a, ix) => ([serie.dTime[ix], a]));
      return;
    }
    if (!serie.factor) {
      return;
    }
    serie.data = [];
    if (serie.relativ){
      serie.relativ = [];
    }
    serie.zAnlageData = anlageData;
    let value, lastValue = null, firstValue = 1;
    for (let k = 0; k < serie.dTime.length; k++) { 
      if (k < ixVon || k >= ixBis || serie.factor[k] == null) {
        serie.data.push([serie.dTime[k], null]);
        if (serie.relativ){
          serie.relativ.push([serie.dTime[k], null]);
        }
      } else {
        if (k === ixVon || lastValue === null) {
          if (serie.invest[0] === 100 && serie.invest[1] === 0 && serie.invest[2] === 0 && serie.invest[3] === 0) {
            value = anlageData[k];
          } else {
            value = serie.factor[k] * anlageData[k];
          }
          lastValue = value;
          firstValue = value;
        } else {
          if ( anlageData[k] !== anlageData[k-1] ) {
            value = serie.factor[k] * (lastValue + (anlageData[k] - anlageData[k-1])) 
          } else {
            value = serie.factor[k] * lastValue;
          }
          lastValue = value;
          if ( serie.agio ) {
            value = value / (1 + serie.agio / 100);
          }
          if ( steuersatz != null && value > anlageData[k]) {
            value = value - (value - anlageData[k]) * (steuersatz / 100);
          }
        }
        serie.data.push([serie.dTime[k], value] );
        if (serie.relativ){
          serie.relativ.push([serie.dTime[k], value / anlageData[k]] );
        }
      }
    }
  },
  addGD200Series(serie, obenProzent = 5, untenProzent = 5) {
    const result = [];
    const obenFactor = 1 + obenProzent / 100;
    const untenFactor = 1 - untenProzent / 100;
   
    const gd200_linie = [], gd200_oben = [], gd200_unten = [];
    let sumAverage = 0, gdValue = 0, idx = 0;
    serie.data.forEach((point, ix) => {
      if (point[1] != null || idx ){
        sumAverage += (point[1]||0);
        if ( idx >= 200) {
          sumAverage -= (serie.data[ix - 200][1] || 0);
          gdValue = sumAverage / 200;
          gd200_linie.push([point[0], gdValue]);
          gd200_oben.push( [point[0], gdValue * obenFactor]);
          gd200_unten.push([point[0], gdValue * untenFactor]);
        } else {
          gd200_linie.push([point[0], null]);
          gd200_oben.push( [point[0], null]);
          gd200_unten.push([point[0], null]);
        }
        idx++
      }
    })
    result.push(JSON.parse(JSON.stringify(serie)));
    result.push({name: '200-Tage-Linie', data: gd200_linie, color: COLOR_STANDARD[1] });
    result.push({name: 'Obere Schranke', data: gd200_oben, color: COLOR_STANDARD[2] });
    result.push({name: 'Untere Schranke', data: gd200_unten, color: COLOR_STANDARD[3] });
    return result;
  },
  jaehrlichePerformance(serie, series) {
    if (serie.data) {
      let ixBis = serie.data.length;
      let jahr = new Date(serie.data[ixBis - 1][0]).getFullYear();
      let limit = new Date(jahr, 0, 1).getTime();
      let cnt = 0;
      const colorIndex = series?.series ? series.series.length : 0;
      const rendite = [];
      const renditePA = [];
      const jahren = []
      let wes ;
      for (let k = ixBis - 1; k >= 0 && cnt < 10; k--){
        if (serie.data[k][0] < limit) {
          wes = this.getKapitalgewichteteWertentwicklung(serie.data, null, false, true, k, ixBis );
          jahren.unshift(jahr);
          rendite.unshift(Math.round(wes[0] * 100) / 100);
          renditePA.unshift(Math.round(wes[1] * 100) / 100);
          cnt++;

          ixBis = k + 1;
          jahr--;
          limit = new Date(jahr, 0, 1).getTime();
        }
      }
      if (series?.series) {
        series.series.push(BARSERIE(serie.name, rendite, colorIndex));
      }
      return BAROPTION(jahren, [BARSERIE(serie.name, rendite, colorIndex)]);
    }
  },
  jaehrlicheTendite(rendite, isSmallScreen) {
    if (!rendite?.jahren || !rendite?.wertFond) {
      return {}
    }
    let min = rendite.wertFond.reduce((m, v) => Math.min(m, v), 0 );
    min = Math.min(min, rendite.wertKategorie.reduce((m, v) => Math.min(m, v), 0 ))
    
    let gap = rendite.wertFond.reduce((m, v) => Math.max(m, v), 0 );
    gap = Math.max(gap, rendite.wertKategorie.reduce((m, v) => Math.max(m, v), 0 ))
    if (min < 0) {
      gap = gap - min;
    }
    gap = Math.round(gap / 10);

    const optionBar = BAROPTION(rendite.jahren, [
      BARSERIE('Wertentwicklung', rendite.wertFond, 0, isSmallScreen), 
      BARSERIE(rendite.kategorie, rendite.wertKategorie, 1, isSmallScreen)
    ]);
    optionBar.yAxis.min = min >= 0 ? -gap : min-gap;
    return optionBar;
  },
  initFondsvergleich(strData) {
    const data = JSON.parse(strData);
    const series = this.convSerieData(data.series);
    const dTime = series[0].dTime;
    const anlageData = this.getAnlage(dTime, data.invest[0], data.invest[1], data.invest[2], data.invest[3]);
    this.addKurs(series);
    const addOnRows = {};
    series.forEach((serie) => {
      serie.hidden = false;
      serie.highlight = false;
      serie.anlageData = anlageData;
      serie.invest = data.invest;
      this.recalcFromFactor(serie, 0, serie.data.length, data.steuersatz);
      serie.key = serie.isin;
      serie.irr = false;
      serie.wes = this.getKapitalgewichteteWertentwicklung(serie.data, serie.anlageData, false, serie.irr ) 
      serie.vola = this.calcVola(serie.data, serie.anlageData);
      if (serie.wes){
        serie.perf = serie.wes[0];
        const legend = {
          key: serie.key,
          we: Math.round(serie.wes[0] * 100) / 100,
          wepa: Math.round(serie.wes[1] * 100) / 100,
          vola: Math.round(serie.vola * 100) / 100,
        }
        if (data.format === 'currency') {
          legend.saldo = Math.round(serie.wes[2] * 100) / 100;
        }
        addOnRows[serie.name] = legend;
      }
    });
    return {series, addOnRows, anlageData, dTime};
  },

  initAnlageempfehlung(type, chartAnlage, series, format) {
    let einmal = 0;
    let monat = 0;
    let quartal = 0;
    let jahr = 0;
    let fondsAnlage = null;
    if (type === 'Anlageempf-advice') {
      einmal = chartAnlage.einmal || 0;
      monat = chartAnlage.monat || 0;
      quartal = chartAnlage.quartal || 0;
      jahr = chartAnlage.jahr || 0;
    } else {
      fondsAnlage = chartAnlage.fonds;
    }
    if (process.env.VUE_APP_USER === 'hannes') console.log('chartAnlage', JSON.parse(JSON.stringify(chartAnlage)), 'series', JSON.parse(JSON.stringify(series)) );
    series.map((serie, ix) => {
      serie.sort = serie.name == 'Depotvorschlag' ? 1000
      : (serie.name == 'Aktuelles Depot' ? 1001 
      : (serie.name == 'Marker' ? 9999 : 1002+ix ))
      return serie
    } ).sort( (a,b) => a.sort - b.sort);

    let anlageData = null;
    series = series.map(serie => ({ ...serie, color: '' }));
    this.convSerieData(series);
    let dTime = series[0]?.dTime || [];
    let ixS = type === 'Anlageempf-advice' ? 2 : 0;
    series.forEach(serie => {
      if ( dTime.length < (serie.dTime?.length || 0)) [
        dTime = serie.dTime
      ]
      if (serie.name == 'Aktuelles Depot') {
        serie.color = COLOR_STANDARD[1]
      } else if (serie.name == 'Depotvorschlag') {
        serie.color = COLOR_STANDARD[0]
      } else {
        serie.color = COLOR_STANDARD[(ixS) % (COLOR_STANDARD.length - 1)];
        ixS++;
      }
    });

    if (type === 'Anlageempf-advice') {
      anlageData = this.getAnlage(dTime, einmal, monat, quartal, jahr);
      const marker = series.find(serie => serie.name=='Marker');
      if (marker && (einmal + monat + quartal + jahr) == 0) {
        anlageData = marker.data.map(value => value[1]);
        if (process.env.VUE_APP_USER === 'hannes') console.log('marker', JSON.parse(JSON.stringify(marker)), JSON.parse(JSON.stringify(anlageData)) );
      }
    } else if (fondsAnlage) {
      const marker = series.find(serie => serie.name=='Marker');
      if (marker) {
        format = 'currency';
        series = series.filter(serie => serie.name !== 'Marker' );
        let lastMarker = null, anlage, lastIdx = 0;
        series.forEach(serie => {
          serie.name = serie.name.substring(0, (serie.name + '\n').indexOf('\n'));
          if (fondsAnlage && fondsAnlage[serie.name]) {
            serie.invest = [
              fondsAnlage[serie.name]?.einmal || 0, 
              fondsAnlage[serie.name]?.monat || 0,
              fondsAnlage[serie.name]?.quartal || 0,
              fondsAnlage[serie.name]?.jahr || 0
            ];
            serie.anlageData = serie.anlage ? serie.anlage : this.getAnlage(dTime, serie.invest[0], serie.invest[1], serie.invest[2], serie.invest[3]);
          }
        });
      }
    }

    const years = type !== 'Anlageempf-advice' ? null : (!dTime?.length ? 1 : (dTime[dTime.length - 1] - dTime[0] - 86400000) / (86400000 * 365)) ;
    const addOnRows = {};
    series.forEach((serie) => {
      if ('Marker' === serie.name) {
        serie.name = 'Anlagesumme'
        serie.color = '#aaa';
        serie.hidden = true;
        serie.highlight = false;
        serie.relativ = serie.data.map( (a, ix) => ([dTime[ix], 1]));
        serie.anlageData = null;
        serie.invest = [einmal, monat, quartal, jahr];
        const legend = {
            we: null,
          wepa: null,
          vola: null,
        }
        if (format === 'currency') {
          legend.saldo = null;
        }
        addOnRows[serie.name] = legend;
      } else {
        if (serie.name.indexOf('\n') !== -1) {
          serie.name = serie.name.substring(0, serie.name.indexOf('\n'));
        }
        serie.hidden = false;
        serie.highlight = false;
        if (type === 'Anlageempf-advice') {
          serie.anlageData = serie.anlage ? serie.anlage : anlageData;
          // const value0 = serie.data[0][1];
          serie.relativ = serie.data.map((point, idx) => ([point[0], point[1]/serie.anlageData[idx]]))
        } else {
          if (fondsAnlage && fondsAnlage[serie.name] && format === 'currency') {
            einmal = fondsAnlage[serie.name]?.einmal || 0;
            monat = fondsAnlage[serie.name]?.monat || 0;
            quartal = fondsAnlage[serie.name]?.quartal || 0;
            jahr = fondsAnlage[serie.name]?.jahr || 0;
            serie.anlageData = serie.anlage ? serie.anlage : this.getAnlage(dTime, einmal, monat, quartal, jahr);
            serie.invest = [einmal, monat, quartal, jahr];
            serie.relativ = serie.data.map((point, idx) => ([point[0], point[1]/serie.anlageData[idx]]))
          } else {
            serie.anlageData = serie.anlage ? serie.anlage : this.getAnlage(dTime, 100, 0, 0, 0);
            serie.invest = [100, 0, 0, 0];
          }
        }
        serie.irr = true;
        serie.wes = this.getKapitalgewichteteWertentwicklung(serie.data, serie.anlageData, false, serie.irr, 0, serie.data.length, years ) 
        serie.vola = this.calcVola(serie.data, serie.anlageData);
        if (type === 'Anlageempf-advice') {
          serie.invest = [einmal, monat, quartal, jahr];
        }
        const legend = {
          we: Math.round(serie.wes[0] * 100) / 100,
          wepa: Math.round(serie.wes[1] * 100) / 100,
          vola: Math.round(serie.vola * 100) / 100,
        }
        if (format === 'currency') {
          legend.saldo = Math.round(serie.wes[2] * 100) / 100;
        }
        addOnRows[serie.name] = legend;
      }
    });
    return {series, addOnRows, format}
  },
}