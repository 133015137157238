import BRIDGE_TYPES from './types';
import Vue from 'vue';
import { getInitialState } from './index';

export default {
  [BRIDGE_TYPES.MUTATIONS.LOAD_LOGIN_DATA_SUCCESS](state, payload) {
    Vue.set(state, 'loadedLoginData', payload)
  },
  [BRIDGE_TYPES.MUTATIONS.RESET_LOADED_LOGIN_DATA](state) {
    Vue.set(state, 'loadedLoginData', {})
    Vue.set(state, 'hasLoadedLoginData', false)
  },
  [BRIDGE_TYPES.MUTATIONS.HAS_LOADED_LOGIN_DATA](state) {
    Vue.set(state, 'hasLoadedLoginData', true)
  },
  [BRIDGE_TYPES.MUTATIONS.HAS_PASSWORD_PREVIOUSLY_SAVED](state) {
    Vue.set(state, 'hasPasswordPreviouslySaved', true)
  },
  [BRIDGE_TYPES.MUTATIONS.RESET_HAS_PASSWORD_PREVIOUSLY_SAVED](state) {
    Vue.set(state, 'hasPasswordPreviouslySaved', false)
  },
  [BRIDGE_TYPES.MUTATIONS.MOBILE_NATIVE_CONTEXT](state, payload) {
    Vue.set(state, 'mobileNativeContext', payload)
  },
  [BRIDGE_TYPES.MUTATIONS.SAVE_CLIENT_CALL_ID](state, clientCallId){
    Vue.set(state, 'clientCallId', clientCallId)
  },
  [BRIDGE_TYPES.MUTATIONS.MOBILE_NATIVE_SPEC](state, payload) {
    Vue.set(state, 'mobileNativeSpec', payload)
  },
  [BRIDGE_TYPES.MUTATIONS.RESET_STATE](state) {
    const mobileNativeContext = state.mobileNativeContext
    const mobileNativeSpec = state.mobileNativeSpec
    Object.assign(state, getInitialState())
    Vue.set(state, 'mobileNativeContext', mobileNativeContext)
    Vue.set(state, 'mobileNativeSpec', mobileNativeSpec)
  },
  [BRIDGE_TYPES.MUTATIONS.VIRTUAL_KEYBOARD_DETECTED](state, payload) {
    Vue.set(state, 'virtualKeyboardDetected', payload);
  },
  [BRIDGE_TYPES.MUTATIONS.SAVE_RECEIVED_DB_PREFIX](state, payload) {
    Vue.set(state, 'receivedDbPrefix', payload);
  },
  [BRIDGE_TYPES.MUTATIONS.SAVE_SHOW_BUTTON_SWITCH_AUDIO_OUTPUT](state, payload) {
    Vue.set(state, 'showButtonSwitchAudioOutput', payload);
  },
  [BRIDGE_TYPES.MUTATIONS.SET_BIOMETRIC_LOGIN_SETTINGS_RESPONSE](state, payload) {
    Vue.set(state, 'biometricLoginSettings', Object.assign({}, state.biometricLoginSettings, payload));
  },
  [BRIDGE_TYPES.MUTATIONS.SET_AUDIO_OUTPUT](state, payload) {
    Vue.set(state, 'audioOutput', payload);
  },
}
