export const MUTATION_PREFIX = 'DOKUMENTENARCHIV_MUTATIONS_';
export const ACTIONS_PREFIX = 'DOKUMENTENARCHIV_ACTIONS_';
export const GETTERS_PREFIX = 'DOKUMENTENARCHIV_GETTERS_';

export default {
  MUTATIONS: {
    RETRIEVE_DOCUMENT_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_DOCUMENT_SUCCESS',
    RETRIEVE_DOCUMENT_FAILURE: MUTATION_PREFIX + 'RETRIEVE_DOCUMENT_FAILURE',
    RETRIEVE_DOCUMENT_HISTORY_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_DOCUMENT_HISTORY_SUCCESS',
    ADD_DOCUMENT_CHANGELOG: MUTATION_PREFIX + 'ADD_DOCUMENT_CHANGELOG',
    UPDATE_COLLAPSED: MUTATION_PREFIX + 'UPDATE_COLLAPSED',
    SET_COLLAPSED: MUTATION_PREFIX + 'SET_COLLAPSED',
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
  },
  ACTIONS: {
    RETRIEVE_DOCUMENT: ACTIONS_PREFIX + 'RETRIEVE_DOCUMENT',
    RETRIEVE_DOCUMENT_HISTORY: ACTIONS_PREFIX + 'RETRIEVE_DOCUMENT_HISTORY',
  },
  GETTERS: {
    DOCUMENT_DATA: GETTERS_PREFIX + 'DOCUMENT_DATA',
    GET_ATTACHMENT_LINK_MAKER: GETTERS_PREFIX + 'GET_ATTACHMENT_LINK_MAKER',
    GET_SIMPLE_FILE_LINK_MAKER: GETTERS_PREFIX + 'GET_SIMPLE_FILE_LINK_MAKER',
    GET_ANTRAG_SCANS_LINK_MAKER: GETTERS_PREFIX + 'GET_ANTRAG_SCANS_LINK_MAKER',
    GET_AENDERUNGS_HISTORIE_LINK_MAKER: GETTERS_PREFIX + 'GET_AENDERUNGS_HISTORIE_LINK_MAKER',
    DOCUMENT_HISTORY: GETTERS_PREFIX + 'DOCUMENT_HISTORY',
    COLLAPSED: GETTERS_PREFIX + 'COLLAPSED',
    GET_DOWNLOAD_LINK_MAKER: GETTERS_PREFIX + 'GET_DOWNLOAD_LINK_MAKER',
  }
}