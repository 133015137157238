var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.$isSmallScreen
        ? _c(
            "div",
            [
              !_vm.embedded
                ? _c("OptionMenu", {
                    attrs: {
                      id: _vm.$appNavigation.currentOptionMenuId,
                      defaultMenu: _vm.$appNavigation.currentOptionMenu
                    }
                  })
                : _vm._e(),
              !_vm.embedded
                ? _c("PageHeaderTitleNavigation", {
                    attrs: {
                      title: "Nachricht",
                      actions: _vm.headerActions,
                      noPrimaryAction: _vm.closed || !_vm.chatInputEnabled
                    },
                    on: {
                      "action-SEND": _vm.onSendChat,
                      "action-FILE_SELECT": _vm.onUploadChatFile,
                      "action-CLOSE": function($event) {
                        return _vm.closeTicket()
                      },
                      "action-EDIT-BEARBEITER": function($event) {
                        return _vm.openBearbeiterMenu()
                      },
                      "action-CHANGE-STATUS": function($event) {
                        return _vm.openChangeStatusModal()
                      },
                      "action-AUFGABE-HINZUFUEGEN": function($event) {
                        return _vm.handleAddActivity()
                      },
                      "action-RESET-MESSAGE": function($event) {
                        return _vm.resetMessage()
                      },
                      "action-DOCUMENT_ARCHIVE": _vm.handleAddDocuments
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _vm.ticket
                                ? _c(
                                    "div",
                                    [
                                      _vm.isNavigationValuesEmpty
                                        ? _c(
                                            "BaseButton",
                                            {
                                              attrs: { isClear: "" },
                                              on: {
                                                click: _vm.goToMessagesList
                                              }
                                            },
                                            [
                                              _c("PhCaretLeft", {
                                                attrs: { size: 18 }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.canOpenCustomerNewTab()
                                        ? _c("span", [
                                            _c(
                                              "a",
                                              {
                                                on: {
                                                  click: function($event) {
                                                    return _vm.openCustomerNewTab()
                                                  }
                                                }
                                              },
                                              [_vm._v(_vm._s(_vm.customerName))]
                                            ),
                                            _vm._v(
                                              " | " +
                                                _vm._s(
                                                  _vm.ticketStatusDescriptionForTitle
                                                )
                                            )
                                          ])
                                        : _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.customerName) +
                                                " | " +
                                                _vm._s(
                                                  _vm.ticketStatusDescriptionForTitle
                                                )
                                            )
                                          ])
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    [
                                      _vm.isNavigationValuesEmpty
                                        ? _c(
                                            "BaseButton",
                                            {
                                              attrs: { isClear: "" },
                                              on: {
                                                click: _vm.goToMessagesList
                                              }
                                            },
                                            [
                                              _c("PhCaretLeft", {
                                                attrs: { size: 18 }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" Nachricht ")
                                    ],
                                    1
                                  )
                            ]
                          },
                          proxy: true
                        },
                        _vm.ticket
                          ? {
                              key: "subtitle",
                              fn: function() {
                                return [_vm._v(_vm._s(_vm.ticket.thema))]
                              },
                              proxy: true
                            }
                          : null,
                        {
                          key: "action-BEWERTUNG",
                          fn: function() {
                            return [
                              _c("hr", { staticClass: "my-3" }),
                              _c("TicketBewertung", {
                                staticClass: "m-0",
                                attrs: {
                                  centered: "",
                                  value: _vm.bewertung,
                                  disabled:
                                    _vm.bewertungReadonly || _vm.savingBewertung
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.setBewertung($event)
                                  }
                                }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      true
                    )
                  })
                : _vm._e(),
              _c("TicketContent", {
                attrs: {
                  embedded: _vm.embedded,
                  loading: _vm.loading,
                  textInput: _vm.textInput,
                  richText: _vm.richText,
                  themaProps: _vm.thema,
                  thema: _vm.themaDefault
                },
                on: {
                  isFormInvalid: _vm.setFormInvalid,
                  isUploadDisabled: function($event) {
                    _vm.isUploadDisabled = $event
                  },
                  loading: function($event) {
                    _vm.loading = $event
                  },
                  savingBewertung: function($event) {
                    _vm.savingBewertung = $event
                  },
                  changeChannel: _vm.changeChannel,
                  loadTicketData: _vm.loadTicketData
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "BaseModal",
        {
          ref: "chatModal",
          attrs: {
            modalTitle: _vm.ticket ? _vm.customerName : "Nachricht",
            showDefaultButtons: false,
            actions: _vm.chatModalActions
          },
          on: {
            "action-SEND": _vm.onSendChat,
            "action-FILE_SELECT": _vm.onUploadChatFile,
            "action-DOCUMENT_ARCHIVE": _vm.handleAddDocuments,
            "action-CLOSE": function($event) {
              return _vm.closeTicket()
            },
            "action-EDIT-BEARBEITER": function($event) {
              return _vm.openBearbeiterMenu()
            },
            "action-CHANGE-STATUS": function($event) {
              return _vm.openChangeStatusModal()
            },
            "action-RESET-MESSAGE": function($event) {
              return _vm.resetMessage()
            },
            onCloseButton: _vm.goToLastAction
          },
          scopedSlots: _vm._u([
            {
              key: "action-BEWERTUNG",
              fn: function() {
                return [
                  _c("hr", { staticClass: "my-3" }),
                  _c("TicketBewertung", {
                    staticClass: "m-0",
                    attrs: {
                      centered: "",
                      value: _vm.bewertung,
                      disabled: _vm.bewertungReadonly || _vm.savingBewertung
                    },
                    on: {
                      input: function($event) {
                        return _vm.setBewertung($event)
                      }
                    }
                  })
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("TicketContent", {
            attrs: {
              embedded: _vm.embedded,
              loading: _vm.loading,
              thema: _vm.themaDefault
            },
            on: {
              isUploadDisabled: function($event) {
                _vm.isUploadDisabled = $event
              },
              isFormInvalid: _vm.setFormInvalid,
              loading: function($event) {
                _vm.loading = $event
              },
              savingBewertung: function($event) {
                _vm.savingBewertung = $event
              },
              changeChannel: _vm.changeChannel,
              loadTicketData: _vm.loadTicketData
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }