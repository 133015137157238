var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container" },
    [
      _vm.isLoading && !_vm.scrollLoading
        ? _c("GhostLoading", {
            attrs: { type: "table", title: _vm.TABLE_TITLE }
          })
        : _c("PaginatedTable", {
            attrs: {
              tableId: "0a133a25-36f5-492d-a293-62ba5ddfd584",
              title: _vm.TABLE_TITLE,
              headers: _vm.headers,
              pages: _vm.pages,
              pageCount: _vm.pageCount,
              rowCount: _vm.totalRows,
              page: _vm.pageIndex,
              selected: _vm.selectedIntern,
              selectionAsBlacklist: _vm.invertSelection,
              sorted: _vm.sorted,
              mobileConfig: {
                title: "beschreibung",
                headers: ["kundenLinkName", "status"]
              },
              noDataContent: "Keine Informationen",
              pageSize: _vm.ROWS_PER_PAGE_DEFAULT,
              menuRowsPerPage: _vm.menuRowsPerPage
            },
            on: {
              selectionAsBlacklist: function($event) {
                _vm.invertSelection = $event
              },
              page: _vm.setPageIndex,
              requestPage: _vm.loadPage,
              selected: _vm.onSelect,
              "headerAction-CONFIRM": _vm.onConfirm,
              "click-kundenLinkName": _vm.openCustomerNewTab,
              "click-kontoNr": _vm.clickKontoNr,
              "click-gesellschaft": _vm.openGesellschaftNewTab,
              "action-dokument": _vm.openDocument,
              "action-chat": function($event) {
                return _vm.createChat($event.chatData)
              },
              "action-emailBearbeitender": function($event) {
                return _vm.createEmail($event.emailDataBearbeitender)
              },
              "action-emailKunde": function($event) {
                return _vm.createEmail(
                  $event.emailDataKunde,
                  $event.kundennr,
                  "",
                  $event
                )
              },
              "action-vertrag": _vm.openLink,
              "action-vermoegen": _vm.openVermoegenNewTab,
              "action-freigabe": function($event) {
                return _vm.dokumentFreigeben($event)
              },
              "action-sign": function($event) {
                return _vm.signPdf($event)
              },
              "action-tan": function($event) {
                return _vm.comdirectTan($event)
              },
              "action-mailsettings": function($event) {
                return _vm.openMailSettings($event)
              },
              sort: _vm.sortByColumn,
              "action-confirm": _vm.confirmSelection,
              rowsPerPage: _vm.onPageSizeChange,
              onScroll: _vm.onScroll,
              scrollLoading: function($event) {
                _vm.scrollLoading = $event
              }
            },
            scopedSlots: _vm._u([
              {
                key: "document",
                fn: function(row) {
                  return [
                    row.docType
                      ? _c(
                          "DownloadLink",
                          {
                            attrs: {
                              downloadServicePath: _vm.downloadServicePath(row),
                              filename: row.fileName,
                              queryParams: {
                                docId: row.dokId,
                                fileId: row.fileId,
                                stornoId: row.stornoId
                              }
                            }
                          },
                          [
                            _c(_vm.getDocTypeIcon(row.docType), {
                              tag: "component",
                              attrs: { size: 22 }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
      _c("BaseModal", {
        ref: "hatFehler",
        attrs: { showCancelButton: false, labelButtonConfirm: "Ok" },
        on: {
          onConfirmButton: function($event) {
            _vm.errorStr = null
          }
        },
        scopedSlots: _vm._u([
          {
            key: "modalTitle",
            fn: function() {
              return [
                _c("ph-warning", {
                  staticClass: "mr-2",
                  staticStyle: { color: "orangered" },
                  attrs: { size: 16 }
                }),
                _vm._v(" " + _vm._s(_vm.errorStr) + " ")
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "BaseModal",
        {
          ref: "info",
          attrs: { showCancelButton: false, labelButtonConfirm: "Ok" },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [_vm._v("Informationen")]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", {
            staticClass: "col mb-2",
            domProps: { innerHTML: _vm._s(_vm.sanitize(_vm.infoText)) }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }