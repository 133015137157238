<template>
  <div>
    <BaseCollapsable v-if="isCollapsable">
      <template #title>
        <strong>Passwortrichtlinien</strong>
      </template>
      <template #content>
        <p>Das Passwort muss mindestens eine Länge von 6 Zeichen haben.</p>
        <p>Es muss zumindest ein Großbuchstabe, ein Kleinbuchstabe und eine Zahl enthalten sein.</p>
        <p>Die Nutzerkennung darf nicht Teil des Passworts sein.</p>
      </template>
    </BaseCollapsable>
    <template v-else>
      <strong style="text-align: center">
        Passwortrichtlinien
      </strong><br>
      <p>Das Passwort muss mindestens eine Länge von 6 Zeichen haben.</p>
      <p>Es muss zumindest ein Großbuchstabe, ein Kleinbuchstabe und eine Zahl enthalten sein.</p>
      <p>Die Nutzerkennung darf nicht Teil des Passworts sein.</p>
    </template>
  </div>
</template>

<script>
import BaseCollapsable from '@/components/core/BaseCollapsable.vue';

export default {
  props: {
    isCollapsable: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BaseCollapsable,
  },
}
</script>
