<template>
  <div class="box__container">
    <div class="box__title">Stammdaten</div>
    <div class="row">
      <div class="col-12">
        <template v-if="!isMainPerson">
          <PersonBeziehungFields 
            :relationship="relationship" 
            isComponentHalfSize 
            @change="addRelationshipDataChanged($event)" 
          />
          <hr/>
        </template>
        <InputToggleSwitch label="Synchronisation" 
            :inLineLabel="true"
            v-model="personalDataAddress.synchronisation"
            v-if="hatSyncUser" 
            @input="addCustomerDataEditedCombo('personalDataAddress');" />        

        <InputToggleSwitch v-if="isMainPerson && isByPass"
          label="Kundendaten werden durch automatische Updates angepasst / überschrieben" 
          :inLineLabel="true"
          v-model="personalDataAddress.automaticUpdated"
          :disabled="!isEditable"
          @input="addCustomerDataEditedCombo('personalDataAddress');" />
        
        <InputToggleSwitch v-if="isIntern && showRisikolevel"
          label="Risikoeinstufung: manuelle Zuweisung" 
          :inLineLabel="true"
          v-model="personalDataAddress.isRisikolevelManuel"
          @input="addCustomerDataEditedCombo('personalDataAddress')" />
        <InputRadioBoxGroup v-if="showRisikolevel"
          title="Risikoeinstufung (GWG)"
          v-model="personalDataAddress.risikolevel" 
          isComponentHalfSize
          :disabled="!isRisikolevelEditable"
          :vertical="false"
          :values="[
              {value: '1', label: '1'},
              {value: '2', label: '2'},
              {value: '3', label: '3'}
          ]"
          @input="addCustomerDataEdited('personalDataAddress')" />

        <ComboBox v-if="isByPass"
          label="Anredeform" 
          :isComponentHalfSize="true"
          :values="anredePronomen" 
          v-model="personalDataAddress.anredePronomen"
          :disabled="!isEditable"
          @change="addCustomerDataEditedCombo('personalDataAddress', 'anredePronomen', $event)"/>
        <ComboBox label="Anrede" 
          :labelClass="depoteroeffnungenLabelClass" 
          :isComponentHalfSize="true"
          :values="customerDataConfig.genders" 
          v-model="personalDataAddress.title"
          :disabled="!isEditable"
          @change="addCustomerDataEditedCombo('personalDataAddress', 'title', $event)"/>
        <template v-if="hasTitles"><!-- Only shown if has titles. It is for existing records. -->
          <InputField 
            label="Titel" 
            :isComponentHalfSize="true"
            v-model="personalDataAddress.titles"
            :disabled="!isEditable"
            @change="addCustomerDataEdited('personalDataAddress')"/>
        </template>
        <template v-if="hasFirstName"><!-- Only shown if has a first name. It is for existing records. -->
          <InputField 
            label="Ansprechpartner Vorname" 
            :isComponentHalfSize="true"
            v-model="personalDataAddress.firstName"
            :disabled="!isEditable"
            @change="addCustomerDataEdited('personalDataAddress')"/>
        </template>
        <InputField data-field-target="lastNameField"
          label="Firmenname" 
          :labelClass="depoteroeffnungenLabelClass" 
          :isComponentHalfSize="true"
          v-model="personalDataAddress.lastName"
          :disabled="!isEditable"
          validateUntouched
          @change="addCustomerDataEdited('personalDataAddress')"/>
         <InputField 
          label="Firmenzusatz" 
          :isComponentHalfSize="true"
          v-model="personalDataAddress.firmenzusatz"
          :disabled="!isEditable"
          validateUntouched
          @change="addCustomerDataEdited('personalDataAddress')"/>
          <ComboBox
          label="Rechtsform" 
          :labelClass="depoteroeffnungenLabelClass" 
          :isComponentHalfSize="true"
          :values="customerDataConfig.rechtsform" 
          v-model="personalDataAddress.rechtsform"
          :disabled="!isEditable"
          @change="addCustomerDataEditedCombo('personalDataAddress', 'rechtsform', $event)"/>
            
          <InputField label="Kundenreferenznr."
            :isComponentHalfSize="true" 
            v-model="kundenReferenzNummer"
            :disabled="!isEditable"
            @change="addKundenReferenzNummerDataChanged($event)" />


        <template v-if="isByPass">
          <InputField v-if="isMainPerson" 
            label="Briefanrede Depot"
            :isComponentHalfSize="true" 
            v-model="personalDataAddress.briefanredeDepot"
            :disabled="!isEditable"
            @change="addCustomerDataEditedCombo('personalDataAddress')" />

          <InputField label="Briefanrede Person"
            :isComponentHalfSize="true" 
            v-model="personalDataAddress.briefanredePerson"
            :disabled="!isEditable"
            @change="addCustomerDataEditedCombo('personalDataAddress')" />

          <template v-if="isMainPerson">
            <ComboBox 
              label="Kundenkategorie"
              :isComponentHalfSize="true"
              :values="customerDataConfig.customerCategories" 
              v-model="personalDataAddress.category"
              :disabled="!isEditable"
              @change="addCustomerDataEditedCombo('personalDataAddress', 'category', $event)"/>

            <DatePickerField
               v-if="personalDataAddress.dateCreated"
              label="Anlagedatum des Kunden"
              isValueAsString
              :isComponentHalfSize="true"
              v-model="personalDataAddress.dateCreated"
              disabled/>  

            <InputToggleSwitch 
              label="Interessent" 
              :inLineLabel="true"
              v-model="personalDataAddress.interested"
              :disabled="!isEditable"
              @input="addCustomerDataEditedCombo('personalDataAddress');" />

            <InputToggleSwitch 
              label="automatische Anpassung sperren" 
              :inLineLabel="true"
              v-model="personalDataAddress.categoryAutoLock"
              :disabled="!isEditable"
              @input="addCustomerDataEditedCombo('personalDataAddress');" />
              
            <ComboBox 
            label="Betreuer"
            :isComponentHalfSize="true"
            :values="customerDataConfig.customerBetreuer" 
            v-model="personalDataAddress.betreuer"
            :disabled="!isEditable"
            @change="addCustomerDataEditedCombo('personalDataAddress', 'betreuer', $event)"/>

            <InputTextArea 
              label="Bemerkung (für Kunden nicht sichtbar)"
              :isComponentHalfSize="true"
              v-model="personalDataAddress.interneBemerkung"
              :disabled="!isEditable"
              :rows="5"
              :maxlength="4000"
              @change="addCustomerDataEdited('personalDataAddress')"/>
          </template>
        </template>

        <UserPicture v-if="isMainPerson" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CUSTOMERDATA_TYPES from '@/store/customerData/types';
import CORE_TYPES from '@/store/core/types';
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue';
import InputField from '@/components/core/forms/InputField.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import UserPicture from './UserPicture.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import PersonBeziehungFields from '../zusaetzliche-personen/PersonBeziehungFields.vue';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';

import persoenlicheDatenMixin from '../persoenliche-daten-mixin';

const HINT_GROUP = 'FIRMA_STAMMDATEN';

export default {
  mixins: [persoenlicheDatenMixin],
  data() {
    return {
      ...this.initialState(),
    };
  },
  computed: {
    ...mapGetters({
      personId: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_ID,
    }),
    hasTitles() {
      const personalDataAddress = this.customerDataEdited?.personalDataAddress ?? this.customerData?.personalDataAddress;
      return !!personalDataAddress?.titles;
    },
    hasFirstName() {
      const personalDataAddress = this.customerDataEdited?.personalDataAddress ?? this.customerData?.personalDataAddress;
      return !!personalDataAddress?.firstName;
    },
  },
  methods: {
    initialState() {
      return {
        personalDataAddress: {
          anredePronomen: '',
          title: '',
          titles: '', // for legacy customer/person data
          firstName: '', // for legacy customer/person data
          lastName: '',
          street: '',
          zip: '',
          city: '',
          country: '',
          personalStatus: '',
          co: '',
          state: '',
          firmenzusatz: '',
          rechtsform: '',
          briefanredeDepot: '',
          betreuer: '',
          briefanredePerson: '',
          personDeceased: false,
          dateOfDeath: null,
          interneBemerkung: '',
          category: '',
          interested: '',
          categoryAutoLock: '',
          automaticUpdated: true,
          dateCreated: null,
          synchronisation: false,
          risikolevel: '',
          isRisikolevelManuel: false,
          hintRisikolevel: '',
        },
        relationship: {
          relation: null,
          isLegalAgent: false,
          isExtraDepotOwner: false,
          isMandatory: false,
        },
         kundenReferenzNummer: '',
      };
    },
    updateGroupsIntoData() {
      this.updateGroupIntoData('personalDataAddress');
      this.updateGroupIntoData('relationship');
       this.updateFieldIntoData('kundenReferenzNummer');
    },
    addRelationshipDataChanged(relationship) {
      this.relationship = { personId: this.personId, ...relationship, };
      this.addCustomerDataEdited('relationship');
    },
    addKundenReferenzNummerDataChanged(value) {
      this.addCustomerDataEdited('kundenReferenzNummer', value);
    },
    addHints() {
      if (this.isMainPerson && this.isByPass) {
        this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.ADD_HINT, {
          group: HINT_GROUP,
          title: 'Kundendaten werden durch automatische Updates angepasst / überschrieben',
          message: ''
                  + "Diese Option muss <b>abgewählt</b> sein, falls Sie die persönlichen Daten Ihrer Kunden ändern wollen, da diese sonst mit der täglichen Datenlieferung der Lagerstellen wieder überschrieben werden."
        });
      }
      if (this.showRisikolevel) {
        if (this.isIntern) {
          this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.ADD_HINT, {
            group: HINT_GROUP,
            title: 'Manuelle Zuweisung',
            message: 'Die automatische Einstufung wird deaktiviert, der Kunde muss manuell verwaltet werden.'
          });
        }
        this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.ADD_HINT, {
          group: HINT_GROUP,
          title: 'Risikoeinstufung (GWG)',
          message: ''
                    + "Laut Geldwäschegesetz (GwG)  muss jeder Verpflichtete seine Kunden gemäß dem Risiko der Geldwäsche einstufen."
                    + " Folgende Einstufungen werden automatisch gesetzt:<br>"
                    + "<b>Risiko 1</b> für alle Kunden mit Wohnsitz EWR / EU</b>;<br>"
                    + "<b>Risiko 2</b> für alle Kunden die nicht unter Risiko 3 fallen und einen Depotinhaber haben, der in einem Land wohnt das nicht EWR / EU ist;<br>"
                    + "<b>Risiko 3</b> für alle Kunden die einen PEP-Status (siehe Kundendaten/Sonstiges 2) haben und alle Kunden mit einem Depotinhaber oder Bankkonto in den Ländern: "
                    + this.hintRisikolevel
        });
      }
      if (this.isByPass && this.isMainPerson) {
        this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.ADD_HINT, {
          group: HINT_GROUP,
          title: 'Briefanrede Depot',
          message: ''
            + 'Dieses Feld ersetzt die automatisch generierte Anrede incl. Herr/Frau und dem Titel. ' 
            + '<b>Daher müssen Sie hier auch den Namen einfügen, falls Sie dieses Feld verändern möchten.</b>'
        });
      }
      if (this.isByPass) {
        this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.ADD_HINT, {
          group: HINT_GROUP,
          title: 'Briefanrede Person',
          message: ''
            + 'Dieses Feld wird verwendet, wenn die Hauptperson des Kunden angesprochen wird (z.B. bei Geburtstagsglückwünschen). ' 
            + 'Es ersetzt die automatisch generierte Anrede incl. Herr/Frau und dem Titel. '
            + '<b>Daher müssen Sie hier auch den Namen einfügen, falls Sie dieses Feld verändern möchten.</b>'
        });
      }

      if(this.isMainPerson && this.isByPass) {
        this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.ADD_HINT, {
          group: HINT_GROUP,
          title: 'Kundenkategorie',
          message: ''
            + "Hier können Sie Ihre Kunden in die Gruppen A-D und I einteilen und später in der Kundensuche danach sortieren. Die Gruppe I steht für Interessent.<br>"
            + "Bitte beachten Sie dazu auch die Einstellungen unter <b><i>Daten/Einstellungen/Kundenkategorie</i></b> im Hauptmenu (MSC).<br>"
            + "Sollte die dynamische Anpassung aktiviert sein und 'automatische Anpassung sperren' nicht angewählt sein, so wird die Kategorie täglich überprüft und ggf. aktualisiert."
        });
      }
    },
    resetUnrelatedFieldsIfNeeded() {
      this.$nextTick(() => {
        const hasEditedTitle = !!this.customerDataEdited?.personalDataAddress?.title;
        if (!hasEditedTitle) return;
  
        this.$set(this.personalDataAddress, 'titles', '');
        this.$set(this.personalDataAddress, 'firstName', '');
        this.addCustomerDataEdited('personalDataAddress');
      });
    },
  },
  mounted() {
    this.updateGroupsIntoData();
    this.addHints();
    this.resetUnrelatedFieldsIfNeeded();
  },
  beforeDestroy() {
    this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.REMOVE_HINTS, { group: HINT_GROUP, });
  },
  components: {
    InputField,
    ComboBox,
    UserPicture,
    InputTextArea,
    InputToggleSwitch,
    PersonBeziehungFields,
    InputRadioBoxGroup,
    DatePickerField,
  },
}
</script>
