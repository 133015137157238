<template>
<div>
    <template v-if="showOptionen">
        <PageHeaderTitleNavigation title="2FA Intervall ändern" />
    </template>

    <div class="box__container">
        <div class="box__title">Intervall für Zwei-Faktor-Authentifizierung festlegen</div>
        <p>Momentan können Sie sich nach einem Login mit Zwei-Faktor-Authentifizierung {{currentInterval}} Tage anmelden, bis Sie wieder Ihren zweiten Faktor verwenden müssen.</p>
        <p v-if="error" class="color-danger">{{error}}</p>
        <ComboBox
            isComponentHalfSize
            v-model="currentInterval"
            :values="options"
            label="Tage zwischen Zwei-Faktor-Authentifizierung"
            @change="changeIntervals"
            :disabled="loading"/>
        <ComboBox
            isComponentHalfSize
            v-if="maxCustomerLimit > 0"
            v-model="customerLimit"
            :values="customerLimitOptions"
            label="Obergrenze für Kunden"
            @change="changeIntervals"
            :disabled="loading"/>
        <ComboBox
            isComponentHalfSize
            v-if="maxBrokerLimit > 0"
            v-model="brokerLimit"
            :values="brokerLimitOptions"
            label="Obergrenze für Makler"
            @change="changeIntervals"
            :disabled="loading"/>
    </div>
</div>
</template>

<script>
import LOG_TYPES from '@/store/log/types';

import axios from 'axios';
import { buildMessage } from '@/helpers/log-message-helper';
import { mapGetters } from 'vuex';

import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';

const config = {
    defaultSpinner: true,
};

export default {
    components: {
        OptionMenu,
        PageHeaderTitleNavigation,
        ComboBox,
    },
    props: {
        showOptionen: {
            type: Boolean,
            default: true,
        }
    },
    data() {
        return {
            currentInterval: -1,
            customerLimit: -1,
            brokerLimit: -1,
            maxInterval: 90,
            maxCustomerLimit: 0,
            maxBrokerLimit: 0,
            loading: false,
            error: null,
        }
    },
    computed: {
        ...mapGetters({
        }),
        options() {
            const options = [{label: "Immer", value: 0}];
            for (let i = 1; i <= this.maxInterval; i++)
                options.push({label: i, value: i});
            return options;
        },
        customerLimitOptions() {
            const options = [];
            options.push({label: this.maxCustomerLimit + " (Standard)", value: null});
            options.push({label: "Immer", value: 0});
            for (let i = 1; i <= this.maxCustomerLimit; i++)
                options.push({label: i, value: i});
            return options;
        },
        brokerLimitOptions() {
            const options = [];
            options.push({label: this.maxBrokerLimit + " (Standard)", value: null});
            options.push({label: "Immer", value: 0});
            for (let i = 1; i <= this.maxBrokerLimit; i++)
                options.push({label: i, value: i});
            return options;
        },
    },
    async mounted() {
        await this.loadInterval();
    },
    methods: {
        async loadInterval() {
            this.loading = true;
            let response = await axios.get(`${process.env.VUE_APP_API}/login/interval_2fa`, config);
            this.loading = false;
            if (response.status == 200) {
                this.currentInterval = response.data.interval;
                this.maxInterval = response.data.maxInterval;
                this.customerLimit = response.data.customerLimit;
                this.maxCustomerLimit = response.data.maxCustomerLimit;
                this.brokerLimit = response.data.brokerLimit;
                this.maxBrokerLimit = response.data.maxBrokerLimit;
                this.error = response.data.error;
            }
        },
        async changeIntervals() {
            this.$nextTick(() => {
                const data = {
                    newValue: this.currentInterval,
                    customerLimit: this.customerLimit,
                    brokerLimit: this.brokerLimit,
                };
                this.loading = true;
                axios.post(`${process.env.VUE_APP_API}/login/interval_2fa`, data, config)
                .then(response => {
                    this.loading = false;
                    if (response.status != 200) {
                        this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage("Der Intervall konnte nicht geändert werden.", 'danger'));
                        this.loadInterval();
                    }
                })
            });
        },
    },
}
</script>

<style scoped>

</style>
