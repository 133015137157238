var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("GesellschaftTitleNavigation", {
        attrs: { headerActions: _vm.headerActions },
        on: {
          "action-SET_FREMDNUMMERN": _vm.setFremdnummern,
          "action-DELETE_FREMDNUMMERN": _vm.deleteFremdnummern
        }
      }),
      _vm.loading
        ? _c("GhostLoading", {
            attrs: { title: "Nummern", useBoxContainer: "", type: "table" }
          })
        : [
            _c(
              "div",
              { staticClass: "box__container" },
              [
                _c("div", { staticClass: "box__title" }, [_vm._v("Nummern")]),
                _c("div", [
                  _vm._v("Generierungsvorschrift für Fremdnummer: "),
                  _c("b", [_vm._v(_vm._s(_vm.nummern.generierungFremdnr))])
                ]),
                _c("InputField", {
                  attrs: { label: "Vornummer", isComponentHalfSize: "" },
                  model: {
                    value: _vm.nummern.vornummer,
                    callback: function($$v) {
                      _vm.$set(_vm.nummern, "vornummer", $$v)
                    },
                    expression: "nummern.vornummer"
                  }
                }),
                _c("Table", {
                  attrs: {
                    title: _vm.TABLE_TITLE,
                    rows: _vm.rows,
                    headers: _vm.headers,
                    headerActions: _vm.tableHeaderActions,
                    rowsPerPage: 20
                  },
                  on: {
                    "click-maklernr": _vm.openBroker,
                    "headerAction-NEW": _vm.openCreateVornummerModal,
                    "action-DELETE": _vm.deleteVornummer
                  }
                }),
                _c("InputRadioBoxGroup", {
                  attrs: { values: _vm.artGenerierungValues },
                  model: {
                    value: _vm.nummern.artGenerierung,
                    callback: function($$v) {
                      _vm.$set(_vm.nummern, "artGenerierung", $$v)
                    },
                    expression: "nummern.artGenerierung"
                  }
                }),
                _c("InputField", {
                  attrs: {
                    label: "akt. laufende Nummer",
                    isComponentHalfSize: ""
                  },
                  model: {
                    value: _vm.nummern.laufendeNummer,
                    callback: function($$v) {
                      _vm.$set(_vm.nummern, "laufendeNummer", $$v)
                    },
                    expression: "nummern.laufendeNummer"
                  }
                }),
                _c("InputField", {
                  attrs: {
                    label: "Stelligkeit der laufenden Nummer",
                    isComponentHalfSize: ""
                  },
                  model: {
                    value: _vm.nummern.stellenLfdnr,
                    callback: function($$v) {
                      _vm.$set(_vm.nummern, "stellenLfdnr", $$v)
                    },
                    expression: "nummern.stellenLfdnr"
                  }
                }),
                _c("InputToggleSwitch", {
                  attrs: { label: "Antrag direkt bei Gesellschaft einreichen" },
                  model: {
                    value: _vm.nummern.antragDirekt,
                    callback: function($$v) {
                      _vm.$set(_vm.nummern, "antragDirekt", $$v)
                    },
                    expression: "nummern.antragDirekt"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "box__container" },
              [
                _c("div", { staticClass: "box__title" }, [
                  _vm._v("Kunden-Kontonummern bei Gesellschaft")
                ]),
                _c("InputField", {
                  attrs: {
                    label: "Anzahl der Stellen",
                    type: "number",
                    isComponentHalfSize: ""
                  },
                  model: {
                    value: _vm.nummern.stellenKontonr,
                    callback: function($$v) {
                      _vm.$set(_vm.nummern, "stellenKontonr", $$v)
                    },
                    expression: "nummern.stellenKontonr"
                  }
                }),
                _c("InputField", {
                  class: { "mb-0": !!_vm.beispielKontonrError },
                  attrs: {
                    label: "Beispiel für Kontonummer",
                    isComponentHalfSize: "",
                    renderDanger: !!_vm.beispielKontonrError
                  },
                  model: {
                    value: _vm.nummern.beispielKontonr,
                    callback: function($$v) {
                      _vm.$set(_vm.nummern, "beispielKontonr", $$v)
                    },
                    expression: "nummern.beispielKontonr"
                  }
                }),
                !!_vm.beispielKontonrError
                  ? _c("div", { staticClass: "fc-form-danger" }, [
                      _vm._v(_vm._s(_vm.beispielKontonrError))
                    ])
                  : _vm._e(),
                _c("InputField", {
                  class: { "mb-0": !!_vm.formatKontonrError },
                  attrs: {
                    label: "Format der Kontonummer",
                    isComponentHalfSize: "",
                    renderDanger: !!_vm.formatKontonrError
                  },
                  model: {
                    value: _vm.nummern.formatKontonrEinfach,
                    callback: function($$v) {
                      _vm.$set(_vm.nummern, "formatKontonrEinfach", $$v)
                    },
                    expression: "nummern.formatKontonrEinfach"
                  }
                }),
                !!_vm.formatKontonrError
                  ? _c("div", { staticClass: "fc-form-danger" }, [
                      _vm._v(_vm._s(_vm.formatKontonrError))
                    ])
                  : _vm._e(),
                _c("div", [
                  _c("b", [_vm._v("Legende Format")]),
                  _c("br"),
                  _c("small", [
                    _vm._v(" z eine Ziffer 0 bis 9"),
                    _c("br"),
                    _vm._v(" b ein Großbuchstabe A bis Z"),
                    _c("br"),
                    _vm._v(
                      " (ABC) genau eines der eingeklammerten Zeichen, hier A oder B oder C"
                    ),
                    _c("br"),
                    _vm._v(
                      " sind mehrere Formate möglich, können einzelne Vorgaben durch Strichpunkt (; ohne Leerzeichen) getrennt werden"
                    ),
                    _c("br"),
                    _vm._v(
                      " Beispiel: 991X12345678 oder 991Y12345678 wird durch 991(XY)zzzzzzzz erkannt."
                    ),
                    _c("br"),
                    _vm._v(
                      " Beispiel für mehrfache Vorgaben: zzz;bbb erkennt 123 und ABC, nicht aber A23"
                    ),
                    _c("br")
                  ])
                ])
              ],
              1
            )
          ],
      _c(
        "BaseModal",
        {
          ref: "createVornummerModal",
          attrs: {
            modalTitle: "Vornummer hinzufügen",
            labelButtonConfirm: "Speichern",
            confirmDisabled:
              !_vm.editVornummer.vornummer ||
              !_vm.editVornummer.maklernr ||
              (_vm.editVornummer.maklernr &&
                _vm.editVornummer.maklernr.length !== 5)
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.addVornummer()
            }
          }
        },
        [
          _c("InputField", {
            attrs: {
              label: "Vermittlernr",
              renderDanger:
                !_vm.editVornummer.maklernr ||
                (_vm.editVornummer.maklernr &&
                  _vm.editVornummer.maklernr.length !== 5)
            },
            model: {
              value: _vm.editVornummer.maklernr,
              callback: function($$v) {
                _vm.$set(_vm.editVornummer, "maklernr", $$v)
              },
              expression: "editVornummer.maklernr"
            }
          }),
          _c("InputField", {
            attrs: {
              label: "Vornummer",
              renderDanger: !_vm.editVornummer.vornummer
            },
            model: {
              value: _vm.editVornummer.vornummer,
              callback: function($$v) {
                _vm.$set(_vm.editVornummer, "vornummer", $$v)
              },
              expression: "editVornummer.vornummer"
            }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }