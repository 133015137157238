import actions from './actions';
import mutations from './mutations';
import getters from './getters';

import { AppDefaultLabel } from '@/helpers/app-helper';

/**
 * mutations: synchronous operation responsible for change the state
 * actions: asynchronous operation responsible for call external resources and commit mutations 
 */

export const ResetStateSource = {
  Logout: 'Logout',
  CloseExtraWebInstance: 'CloseExtraWebInstance',
};

export const LOGIN_WELCOME_TITLE_DEFAULT = AppDefaultLabel.WELCOME_TITLE;
export const APP_NAME_DEFAULT = AppDefaultLabel.APP_NAME;

export function getInitialState(isInitialAppState = false) {
  return {
    internetActive: true,
    loggingIn: false,
    loggingOut: false,
    appName: process.env.VUE_APP_NAME,
    publicPath: process.env.VUE_APP_PUBLIC_PATH,
    apiAddress: process.env.VUE_APP_API,
    isProduction: process.env.VUE_APP_PRODUCTION === 'true',
    storeSessionInformation: process.env.NODE_ENV === 'development' && process.env.VUE_APP_STORE_SESSION_INFORMATION === 'true', 
    messageCloseableTimeSeconds: parseInt(process.env.VUE_APP_MESSAGE_CLOSEABLE_TIME_SECONDS),
    screenWidth: '',
    baseGlobalState: {
      loading: 0,
      pendingActions: {},
      logs: [],
      recordingTutorial: false,
      playingTutorial: false,
      tutorialRecordedItems: [],
      secondaryMenu: {},
      colorSchema: {},
      configuredColorScheme: '',
      preferredColorSchema: '',
      tutorialTitle: '',
      tutorialRoute: '',
      recordedTutorials: [],
      recordingFeature: false,
      tutorialLastId: null,
      tutorialUserType: null,
      nextCheckTimeoutTime: null,
      supposedTimeoutTime: null,
      timeoutSchedule: null,
      timeoutFlagVisibilityChange: false,
      isLogoutRunning: false,
    },
    loginData: {
    },
    originalUserStack: [],
    brokerData: {
      isLoaded: false
    },
    maklerLogo: null,
    imageBackgroundLoginUrl: null,
    loginWelcomeTitle: LOGIN_WELCOME_TITLE_DEFAULT,
    appNameDefault: APP_NAME_DEFAULT,
    systemData: {},
    appLinks: {},
    addBreadcrumbLocked: false,
    backToPreviousData: [],
    backToPreviousDataTrack: {},
    fcConfig: {},
    privacyInfo: {},
    registrationData: {},
    privacyRegistrationData: {},
    defaultLayoutFc: {},
    layoutFc: {},
    cmsMetaTags: [],
    loadingToken: false,
    managingLoginResponse: false,
    mfaChallengeType: {},
    captchaData: {},
    pendingConfirmModal: {}, // pending confirm modal
    askPIN: {},
    resetEventBaseFilter: 1,
    mscBuildInfo: {},
    isInitialAppState,
    genericSelection: {}, // index by uuid
    CHAT_EMPFAENGER_KEY:null,
    exceptionLog: {}
  }
}

export default {
  state: {
    ...getInitialState(true)
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  },
  getters: {
    ...getters
  }
}