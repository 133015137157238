<template>
  <div>
    <PageHeaderTitleNavigation title="Änderungshistorie" />

    <div class="box__container">
      <!-- <BaseButton @click="retrieveDocumentHistory">Aktualisieren</BaseButton> -->
      <Table
        v-if="rows.length"
        :title="historyTitle"
        :rows="rows"
        :headers="headers"
        @click-bezeichnung="openPDF"
        @action="executeAction($event)"
      />
      <GhostLoading v-else-if="loading" type="table" :config="{ table: { rows: 10 } }" />
      <NoData v-else/>
    </div>

    <div class="box__container" v-if="versionRows.length">  
      <Table
        :title="versionTitle"
        :headers="versionHeaders"
        :rows="versionRows"
        v-model="selectedVersionDocs"
        @click-version="openPDF"
        @action="executeAction($event)">
      </Table>
    </div>

    <div class="box__container" v-if="versionRows.length && isIntern">
      <BaseButton @click="executeVereinigung()" :disabled="versionRows && versionRows.length < 2 || loading">Versionsvereinigung</BaseButton>
    </div>

  <BaseModal
    ref="actionConfirmationModal"
    :modalTitle="currentAction && currentAction.confirmationTitle || 'Bestätigung'"
    labelButtonConfirm="Ok"
    :showCancelButton="true"
    @onConfirmButton="onConfirmActionModal()">
    <label>{{ currentAction && currentAction.confirmationMessage }}</label>
  </BaseModal> 
  </div>
</template>

<script>
import axios from 'axios';
import CORE_TYPES from '@/store/core/types';
import DOCUMENT_TYPES from "@/store/documents/types";
import BaseButton from '@/components/core/BaseButton.vue';
import Table from "@/components/table2/Table.vue";
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import BaseModal from "@/components/core/BaseModal.vue";

import { mapGetters } from "vuex";
import {TextColumn, ActionColumn, SimpleAction} from "@/components/table2/table_util.js";
import { viewDocument } from '@/components/core/download/DownloadLink.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';

const config = {
  defaultSpinner: true
};

export default {
  mixins: [],
  components: {
    BaseButton,
    Table,
    OptionMenu,
    PageHeaderTitleNavigation,
    BaseModal,
    GhostLoading,
    NoData,
  },
  data() {
    return {
      tableRows: [],
      versionTableRows: [],
      currentAction: null,
      selectedDoc: null,
      selectedVersionDocs: null,
      loading: false,
      label: '',
    };
  },
  computed: {
    ...mapGetters({
      attachmentLinkMaker: DOCUMENT_TYPES.GETTERS.GET_AENDERUNGS_HISTORIE_LINK_MAKER,
      isIntern: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
    }),    
    headers() {
      let headers = {
          lockedLeft: [
            TextColumn("bezeichnung", "Bezeichnung").makeConditionalLink('hasAccess'),
          ],
          center: [
            TextColumn("datum", "Datum"),
          ],
          lockedRight: [
            ActionColumn("actions", "Aktionen")
          ],
      }
      return headers
    },
    rows() {
      return this.tableRows.map(row => {
        return {...row, data: row, bezeichnung: row.bezeichnung || 'Unbenannt', actions: this.makeActions(row)};
      });
    },
    versionHeaders() {
      return {
          lockedLeft: [
            TextColumn("version", "Version").makeConditionalLink('hasAccess'),
          ],
          center: [
            TextColumn("datum", "Datum"),
            TextColumn("benutzer", "Benutzer"),
          ],
          lockedRight: [],
      }
    },
    versionRows() {
      return this.versionTableRows.map(row => {
        return {...row, data: row};
      });
    },
    historyTitle() {
      return 'Änderungshistorie für ' + this.label;
    },
    versionTitle() {
      return 'Versionen für ' + this.selectedDoc?.bezeichnung || ''
    }
  }, 
  mounted() {
    this.loading = true
    axios.get(`${process.env.VUE_APP_API}/dokumentHistory/getDocumentHistory?docInfo=${encodeURIComponent(this.$route.query.docInfo)}`, config)
    .then(response => {
      this.$store.commit(DOCUMENT_TYPES.MUTATIONS.RETRIEVE_DOCUMENT_HISTORY_SUCCESS, response)
      this.label = response.data.label
      this.tableRows = response.data.tableRows  
    }).finally(() => this.loading = false)
      
  },
  methods: {
    openPDF(row) {
      viewDocument({
        href: this.makeLink(row),
        filename: row.filename || 'unbenannt',
      });
    },
    makeActions(child) {
      return (child.actions || []).filter(action => action.legend.key !== "DOKUMENT_ANZEIGEN")
        .map(action => SimpleAction(action.legend.key, action.legend.icon, action.legend.label))
    },
    selectDocument(row) {
      this.selectedVersionDocs = []
      this.selectedDoc = row;
      this.versionTableRows = row.subtablerecords
    },
    executeAction(actionData) {
      this.currentAction = actionData.row.data.actions.find(r=> r.legend.key === actionData.key)
      if (this.currentAction.confirmationMessage) {
        this.$refs.actionConfirmationModal.open();
        return
      }
      switch(actionData.key) {
        case "AENDERUNGSLOG":
          this.loading = true
          axios.post(`${process.env.VUE_APP_API}/dokumentHistory/getDocumentChangeHistory`, this.currentAction, config).then(response => {
            if (response && response.data) {
              const responseData = response.data
              this.$store.commit(
                DOCUMENT_TYPES.MUTATIONS.ADD_DOCUMENT_CHANGELOG,
                {
                  title: actionData.row.bezeichnung,
                  tableRecords: responseData.tableRows,
                }
              );
              this.$router.push({ path: `/communication/documents/aenderungshistorie/aenderungslog` })
            }
          }).finally(() => this.loading = false)
          break;
        case "VERSIONLOG":
          this.selectDocument(actionData.row)
          break;
        case "SIGNATUR_ZURUECKSETZEN ":
          this.onConfirmActionModal()
          break;
        default:
          console.log("action not yet implemented: " + actionData.key);                  
      }
    },
    onConfirmActionModal() {
      switch (this.currentAction.legend.key) {
        case "DOKUMENTE_VEREINIGEN":
        case "SIGNATUR_ZURUECKSETZEN":
          this.loading = true
          axios.post(`${process.env.VUE_APP_API}/dokumentHistory/doDocumentHistoryActions`, this.currentAction, config)
            .finally(() => this.loading = false)
          break;
        default:                
          break;
      }
    },
    makeLink(row) {
      let action = row.data.actions.find(t => t.legend.key === "DOKUMENT_ANZEIGEN")
      const filename = row.filename || row.name
      const sendData = action?.sendData

      let sendList = []
      if (sendData && sendData.length) {
        sendData.forEach(x => {
          sendList.push(x.key)
          sendList.push(x.value)
        })
        return this.attachmentLinkMaker(filename, sendList)
      }
    },
    executeVereinigung() {
        this.currentAction = {
          legend: {
            key: 'DOKUMENTE_VEREINIGEN'
          },
          sendData: [
            {
              key: 'ACTION_COMMAND',
              value: 'BUTTON_SPLIT'
            },
            {
              key: 'SELECTED_DOCUMENTS',
              value: this.selectedVersionDocs.map(x => x.version)
            },
            {
              key: 'DOK_ID_SELECTED',
              value: this.selectedDoc.dokId
            },
            {
              key: 'DOC_FILE_ID_SELECTED',
              value: this.selectedDoc.fileId
            },
          ],
          confirmationTitle: 'Versionsvereinigung',
          confirmationMessage: 'Sollen die ausgewählten Dokumente vereinigt werden?',
        }
      this.$refs.actionConfirmationModal.open();
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zur Änderungshistorie', 
      to,
      from,
      breadcrumb: 'Änderungshistorie',
    });

    next();
  },
}
</script>