<template>
  <div class="main-container">
    <div class="main__wrapper" :style="hideSelection ? {display: 'none'} : {}">
      <div class="main">
        <div :style="{'max-width': overlayWidth, position: 'relative', 'height': '100%'}">
          <img ref="img" :src="imageData" @load="updateOverlay"/>
          <canvas id="overlay" ref="overlay" 
            @mousemove="hoverOverlay($event)" 
            @mousedown="dragOverlay($event)" 
            @touchstart="dragOverlay($event)" 
          />
          <div class="center" style="position:absolute">
            <EdgeDetection v-if="$refs.overlay"
              @change="setCorners" 
              :imageInput="imageDataAsHTMLImage"
              debugStep="2"/>
          </div>
        </div>
        <div class="corners-edges__container">
          <template v-for="(corner, i) in corner_data">
            <div class="corner" :key="corner.id" 
              @mousedown="(e)=>dragCorner(corner.id, e)" 
              @touchstart="(e)=>dragCorner(corner.id, e)" 
              :style="{left: corner.x * 100 + '%', top: corner.y * 100 + '%'}"
            />
            <div class="edge" :key="'edge_' + corner.id" 
              @mousedown="(e)=>dragEdge(corner.id, e)" 
              @touchstart="(e)=>dragEdge(corner.id, e)" 
              :style="getEdgeStyle(corner, corner_data[(i + 1) % corner_data.length])" 
            />
          </template>
        </div>
      </div>
    </div>
    <div class="controls mt-2" v-if="!hideControls">
      <div v-if="!$isSmallScreen && !$isMobileNativeContext" class="text-small controls-msg">
        <span class="shortcut-text">Shift</span> gedrückt halten = Seitenverhältnis beibehalten
      </div>

      <div class="rotate-btns">
        <BaseButton isClear @click="rotateLeft"><PhArrowArcLeft :size="24"/></BaseButton>
        <BaseButton isClear @click="rotateRight"><PhArrowArcRight :size="24"/></BaseButton>
      </div>
    </div>
    <div class="main__wrapper mt-2" style="flex-shrink:1">
      <div class="main" ref="preview" v-if="!hideControls"></div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import BrowserSupport from '@/browser-support';

import BaseButton from '@/components/core/BaseButton.vue';
import { PhArrowArcLeft, PhArrowArcRight } from 'phosphor-vue';
import {vsSource, fsSource, loadShader} from '@/helpers/image-operation-helper';
import EdgeDetection from './EdgeDetection.vue';
import { eventCoord } from '@/helpers/utils-helper';

const PASSIVE_EVENT_OPTIONS = BrowserSupport.supportsPassive ? { passive: true, capture: false, } : false;
const NO_PASSIVE_EVENT_OPTIONS = BrowserSupport.supportsPassive ? { passive: false, capture: false, } : false;

const AXIS_MIN_VALUE = 0;
const AXIS_MAX_VALUE = 1;
const CORNERS_MAX = 4;

const FORMAT_OPTIMIZED = 'r';
const FORMAT_FOTO_PORTRAIT = 'p';
const FORMAT_FOTO_LANDSCAPE = 'l';

function dotProduct(vec1, vec2) {
    return vec1.x * vec2.x + vec1.y * vec2.y;
}
function pointDistance(p1, p2) {
  return Math.sqrt((p1.x - p2.x) * (p1.x - p2.x) + (p1.y - p2.y) * (p1.y - p2.y));
}

function hasValidCornersValue(corners) {
  return corners && !corners.every(c => c.x === 0 && c.y === 0);
}

function findPoints(corners) {
  const pa = corners.find(c => c.id === 0);
  const pb = corners.find(c => c.id === 1);
  const pc = corners.find(c => c.id === 2);
  const pd = corners.find(c => c.id === 3);

  return [pa, pb, pc, pd];
}

function isBetweenSelectedArea(corners, width, height, x, y) {
  const cornerDataInPx = corners
    .map(c => ({
      ...c,
      x: c.x * width,
      y: c.y * height,
    }));

  const [pa, pb, pc, pd] = findPoints(cornerDataInPx);

  return x >= pa.x && x <= pb.x && x <= pc.x && x >= pd.x
    && y >= pa.y && y >= pb.y && y <= pc.y && y <= pd.y;
}

function axisLimit(axis) {
  return Math.max(AXIS_MIN_VALUE, Math.min(AXIS_MAX_VALUE, axis));
}

function cornerPosition(value) {
  let rValue = value;

  if (value < 0) {
    rValue = (Math.ceil(Math.abs(value) / CORNERS_MAX) * CORNERS_MAX) + value;
  }

  return rValue % CORNERS_MAX;
}

function calcCornerXSize(rect, clientX) {
  return axisLimit((clientX - rect.left) / rect.width);
}

function calcCornerYSize(rect, clientY) {
  return axisLimit((clientY - rect.top) / rect.height);
}

function calcAspectRatio(fromCornerId, currentCorner, x, y) {
  if (currentCorner.id === fromCornerId) {
    return [axisLimit(currentCorner.x + x), axisLimit(currentCorner.y + y)];
  } else if (fromCornerId % 2 === 0) {
    if (currentCorner.id === cornerPosition(fromCornerId - 1)) {
      return [axisLimit(currentCorner.x + x), axisLimit(currentCorner.y - y)];
    } else if (currentCorner.id === cornerPosition(fromCornerId + 1)) {
      return [axisLimit(currentCorner.x - x), axisLimit(currentCorner.y + y)];
    } else if (currentCorner.id === cornerPosition(fromCornerId + 2)) {
      return [axisLimit(currentCorner.x - x), axisLimit(currentCorner.y - y)];
    }
  } else {
    if (currentCorner.id === cornerPosition(fromCornerId - 1)) {
      return [axisLimit(currentCorner.x - x), axisLimit(currentCorner.y + y)];
    } else if (currentCorner.id === cornerPosition(fromCornerId + 1)) {
      return [axisLimit(currentCorner.x + x), axisLimit(currentCorner.y - y)];
    } else if (currentCorner.id === cornerPosition(fromCornerId + 2)) {
      return [axisLimit(currentCorner.x - x), axisLimit(currentCorner.y - y)];
    }
  }
}

function calcPreviewSize(format, imgEl, corners) {
  const { naturalWidth, naturalHeight } = imgEl;

  switch(format) {
    case FORMAT_OPTIMIZED:
      const [pa, pb, pc, pd] = corners;
      const w = Math.max(8, naturalWidth * (pointDistance(pb, pa) + pointDistance(pc, pd)) / 2);
      const h = Math.max(8, naturalHeight * (pointDistance(pc, pb) + pointDistance(pd, pa)) / 2);
      if (pa.id % 2 == 0) {
        return [w, h];
      } else {
        return [h, w];
      }
    case FORMAT_FOTO_PORTRAIT:
      return [Math.min(naturalWidth, naturalHeight), Math.max(naturalWidth, naturalHeight)];
    case FORMAT_FOTO_LANDSCAPE:
      return [Math.max(naturalWidth, naturalHeight), Math.min(naturalWidth, naturalHeight)];
    default:
      const relativeWidth = parseFloat(format.split("x")[0]);
      const realtiveHeight = parseFloat(format.split("x")[1]);
      const scale = naturalWidth < naturalHeight 
        ? naturalWidth / relativeWidth 
        : naturalHeight / realtiveHeight;
      return [scale * relativeWidth, scale * realtiveHeight];
  }
}

export default Vue.extend({
  components: {
    BaseButton,
    PhArrowArcLeft,
    PhArrowArcRight,
    EdgeDetection,
  },

  props: {
    imageData: {
      type: String,
    },
    corners: {
      type: Array,
      default: null,
    },
    hideSelection: {
      type: Boolean,
      default: false,
    },
    hideControls: {
      type: Boolean,
      default: false,
    },
  },

  data: () => {
    return {
      corner_data: null,
      preview: null,
      glData: null,
      rateLimitTimer: null,
      format: FORMAT_OPTIMIZED,
      overlayWidth: "100%",
    }
  },
  computed: {
    isValid () {
      // check if the shape is convex
      let n = this.corner_data.length;
      for (let i = 0; i < n; i+=1) {
          let base = this.corner_data[(i + 1) % n];
          let relative_position = {x: this.corner_data[i].x - base.x, y: this.corner_data[i].y - base.y};
          let opposite_corner = {x: this.corner_data[(i + 2) % n].x - base.x, y: this.corner_data[(i + 2) % n].y - base.y};
          // the normal of the line between the corners (i+1) and (i-1)
          let normal = {x: this.corner_data[(i + 3) % n].y - base.y, y: base.x - this.corner_data[(i + 3) % n].x};
          // check whether corner i and corner (i+2) are on different sides of the line spanned by the other 2 corners
          if (dotProduct(relative_position, normal) * dotProduct(opposite_corner, normal) > 0)
            return false;
      }
      return true;
    },
    imageDataAsHTMLImage() {
      return this.$refs['img'];
    },
  },

  watch: {
    hideControls () {
      if (!this.hideControls) {
        setTimeout(() => this.preparePreview(this.$refs.img), 0);
      }
    }
  },

  methods: {
    getEdgeStyle(p1,p2) {
      let style = {width: `${this.distanceInPixel(p1,p2)}px`, transform: `rotate(${this.angleBetweenPoints(p1,p2)}deg)`, left: `${p1.x * 100}%`, top:  `${p1.y * 100}%`};
      if(p1.id % 2 === 0) { //top and bottom edges
        style['cursor'] = 'ns-resize';
        //padding for top and bottom have to be done seperately for better line alignment
        if(p1.id === 0) { //top edge
          style['padding'] = '0 0 6px 0';
        } else {//bottom edge      
          style['padding'] = '6px 0 0 0';
        }
      } else { //left and right edges
        style['cursor'] = 'ew-resize';
        style['padding'] = '3px 0';
      }
      return style;
    },
    distanceInPixel(p1, p2) {
      const canvasWidth = this.$refs['overlay'].width;
      const canvasHeight = this.$refs['overlay'].height;
      return Math.sqrt( Math.pow(canvasWidth * (p1.x - p2.x), 2) + Math.pow(canvasHeight * (p1.y - p2.y), 2))
    },
    angleBetweenPoints(p1, p2) {
      const canvasWidth = this.$refs['overlay'].width;
      const canvasHeight = this.$refs['overlay'].height;
      return (Math.atan2(canvasHeight * (p2.y - p1.y), canvasWidth * (p2.x - p1.x)) * 180) / Math.PI;
    },
    setupCorners () { //TODO: maybe set to slightly higher than 0 and slightly lower than 1 so corners are more easily grabbable
      this.corner_data = hasValidCornersValue(this.corners) ? [...this.corners] : [
        {id: 0, x: 0.01, y: 0.01},
        {id: 1, x: 0.99, y: 0.01},
        {id: 2, x: 0.99, y: 0.99},
        {id: 3, x: 0.01, y: 0.99},
      ];
      for (let i = 0; i < this.corner_data.length; i++) {
        this.corner_data[i].x = axisLimit(this.corner_data[i].x);
        this.corner_data[i].y = axisLimit(this.corner_data[i].y);
      }
    },
    setCorners(corners) {
      if(corners != null) {
        this.corner_data = corners;
        this.updateOverlay();
      } else {
        this.setupCorners();
      }
    },
    dragCorner (cornerId) {
      const dragMove = (event) => {
        event = event || window.event;
        event.preventDefault();

        const clientX = eventCoord(event, 'clientX');
        const clientY = eventCoord(event, 'clientY');

        const rect = this.$refs.img.getBoundingClientRect();
        const x = calcCornerXSize(rect, clientX);
        const y = calcCornerYSize(rect, clientY);

        const corner = this.corner_data.find(corner => corner.id == cornerId);

        if (this.isToKeepAspectRatio(event)) {
          this.applyAspectRatioSize(corner, x, y);
        } else {
          corner.x = x;
          corner.y = y;
        }

        this.updateOverlay();
      };

      const dragEnd = () => {
        document.removeEventListener('mousemove', dragMove, NO_PASSIVE_EVENT_OPTIONS);
        document.removeEventListener('touchmove', dragMove, NO_PASSIVE_EVENT_OPTIONS);
        document.removeEventListener('mouseup', dragEnd, PASSIVE_EVENT_OPTIONS);
        document.removeEventListener('touchend', dragEnd, PASSIVE_EVENT_OPTIONS);
        document.removeEventListener('touchcancel', dragEnd, PASSIVE_EVENT_OPTIONS);
      };

      document.addEventListener('mousemove', dragMove, NO_PASSIVE_EVENT_OPTIONS);
      document.addEventListener('touchmove', dragMove, NO_PASSIVE_EVENT_OPTIONS);
      document.addEventListener('mouseup', dragEnd, PASSIVE_EVENT_OPTIONS);
      document.addEventListener('touchend', dragEnd, PASSIVE_EVENT_OPTIONS);
      document.addEventListener('touchcancel', dragEnd, PASSIVE_EVENT_OPTIONS);
    },    
    dragEdge (cornerId) {
      const dragMove = (event) => {
        event = event || window.event;
        event.preventDefault();
  
        const clientX = eventCoord(event, 'clientX');
        const clientY = eventCoord(event, 'clientY');
  
        const rect = this.$refs.img.getBoundingClientRect();
        const x = calcCornerXSize(rect, clientX);
        const y = calcCornerYSize(rect, clientY);

        const p1 = this.corner_data.find(corner => corner.id == cornerId);
        const p2 = this.corner_data.find(corner => corner.id == ((cornerId + 1) % 4));

        if (this.isToKeepAspectRatio(event)) {
          this.applyAspectRatioSize(p1, x, y);
        } else {
          if(p1.id % 2 === 0) { //top or bottom edge, so only handle y-coord updates
            p1.y = y;
            p2.y = y;
          } else { //left or right edge, so only handle x-coord updates
            p1.x = x;
            p2.x = x;
          }
        }

        this.updateOverlay();
      };

      const dragEnd = () => {
        document.removeEventListener('mousemove', dragMove, NO_PASSIVE_EVENT_OPTIONS);
        document.removeEventListener('touchmove', dragMove, NO_PASSIVE_EVENT_OPTIONS);
        document.removeEventListener('mouseup', dragEnd, PASSIVE_EVENT_OPTIONS);
        document.removeEventListener('touchend', dragEnd, PASSIVE_EVENT_OPTIONS);
        document.removeEventListener('touchcancel', dragEnd, PASSIVE_EVENT_OPTIONS);
      };

      document.addEventListener('mousemove', dragMove, NO_PASSIVE_EVENT_OPTIONS);
      document.addEventListener('touchmove', dragMove, NO_PASSIVE_EVENT_OPTIONS);
      document.addEventListener('mouseup', dragEnd, PASSIVE_EVENT_OPTIONS);
      document.addEventListener('touchend', dragEnd, PASSIVE_EVENT_OPTIONS);
      document.addEventListener('touchcancel', dragEnd, PASSIVE_EVENT_OPTIONS);
    },
    isToKeepAspectRatio(event) {
      return event.shiftKey;
    },
    applyAspectRatioSize(fromCorner, x, y) {
      const fromCornerId = fromCorner.id;
      const isTopOrBottom = fromCornerId % 2 === 0;
      const diff = isTopOrBottom ? y - fromCorner.y : x - fromCorner.x;
      const [diffX, diffY] = (() => {
        if(isTopOrBottom) {
          return [diff, diff];
        } else {
          return diff < 0 ? [diff, Math.abs(diff)] : [diff, -diff];
        }
      })();

      const isNotAtLimit = corner => corner.x !== 0 && corner.x !== 1 && corner.y !== 0 && corner.y !== 1;

      const cornerData = this.corner_data.map(corner => {
        const [ x, y ] = calcAspectRatio(fromCornerId, corner, diffX, diffY);
        return { ...corner, x, y };
      });

      if (this.corner_data.every(isNotAtLimit) || cornerData.every(isNotAtLimit)) {
        cornerData.forEach((cornerItem2, index) => {
          this.corner_data[index].x = cornerItem2.x;
          this.corner_data[index].y = cornerItem2.y;
        });
      }
    },
    rotateLeft() {
      this.corner_data.push(this.corner_data.shift());
      this.drawPreview();
    },
    rotateRight() {
      this.corner_data.unshift(this.corner_data.pop());
      this.drawPreview();
    },
    updateOverlay () {
      let canvas = this.$refs['overlay'];
      let img = this.$refs.img;
      this.overlayWidth = img.width + "px";
      canvas.width = img.width;
      canvas.height = img.height;
      let ctx = canvas.getContext('2d');
      if (this.isValid)
        ctx.fillStyle = "#0f0";
      else
        ctx.fillStyle = "#f00";
      ctx.beginPath();
      ctx.moveTo(this.corner_data[0].x * canvas.width, this.corner_data[0].y * canvas.height);
      for (let i = 1; i < this.corner_data.length; i+=1)
        ctx.lineTo(this.corner_data[i].x * canvas.width, this.corner_data[i].y * canvas.height);
      ctx.closePath();
      ctx.fill();
      if (this.glData == null)
        this.preparePreview(img);
      if (this.isValid && this.rateLimitTimer == null) {
        this.rateLimitTimer = setTimeout(() => {
          this.rateLimitTimer = null;
          this.drawPreview();
        }, 100);
      }
    },
    hoverOverlay(event) {
      const rect = event.target.getBoundingClientRect();
      const moveX = eventCoord(event, 'clientX') - rect.left;
      const moveY = eventCoord(event, 'clientY') - rect.top;

      const { width, height } = this.$refs['overlay'] || {};
      if (isBetweenSelectedArea(this.corner_data, width, height, moveX, moveY)) {
        event.target.style.cursor = 'move';
      } else {
        event.target.style.cursor = '';
      }
    },
    dragOverlay(event) {
      const rect = event.target.getBoundingClientRect();
      const moveX = eventCoord(event, 'clientX') - rect.left;
      const moveY = eventCoord(event, 'clientY') - rect.top;

      const { width, height } = this.$refs['overlay'] || {};
      if (!isBetweenSelectedArea(this.corner_data, width, height, moveX, moveY)) {
        return;
      }

      let lastX = eventCoord(event, 'clientX');
      let lastY = eventCoord(event, 'clientY');
      const dragMove = event => {
        event = event || window.event;
        event.preventDefault();

        const diffX = eventCoord(event, 'clientX') - lastX;
        const diffY = eventCoord(event, 'clientY') - lastY;
        lastX = eventCoord(event, 'clientX');
        lastY = eventCoord(event, 'clientY');

        const rect = event.target.getBoundingClientRect();
        const cornerData = this.corner_data.map((corner) => ({
          ...corner,
          x: axisLimit(corner.x + diffX / rect.width),
          y: axisLimit(corner.y + diffY / rect.height),
        }));

        const canMoveX = cornerData.every(corner => {
          switch(corner.id) {
            case 0:
            case 3:
              return corner.x !== 0;
            case 1:
            case 2:
              return corner.x !== 1;
          }
        });

        const canMoveY = cornerData.every(corner => {
          switch(corner.id) {
            case 0:
            case 1:
              return corner.y !== 0;
            case 2:
            case 3:
              return corner.y !== 1;
          }
        });

        if (canMoveX || canMoveY) {
          cornerData.forEach((corner, index) => {
            if (canMoveX) {
              this.corner_data[index].x = corner.x;
            }

            if (canMoveY) {
              this.corner_data[index].y = corner.y;
            }
          });
          this.updateOverlay();
        }
      };

      const dragEnd = () => {
        document.removeEventListener('mousemove', dragMove, NO_PASSIVE_EVENT_OPTIONS);
        document.removeEventListener('touchmove', dragMove, NO_PASSIVE_EVENT_OPTIONS);
        document.removeEventListener('mouseup', dragEnd, PASSIVE_EVENT_OPTIONS);
        document.removeEventListener('touchend', dragEnd, PASSIVE_EVENT_OPTIONS);
        document.removeEventListener('touchcancel', dragEnd, PASSIVE_EVENT_OPTIONS);
      };

      document.addEventListener('mousemove', dragMove, NO_PASSIVE_EVENT_OPTIONS);
      document.addEventListener('touchmove', dragMove, NO_PASSIVE_EVENT_OPTIONS);
      document.addEventListener('mouseup', dragEnd, PASSIVE_EVENT_OPTIONS);
      document.addEventListener('touchend', dragEnd, PASSIVE_EVENT_OPTIONS);
      document.addEventListener('touchcancel', dragEnd, PASSIVE_EVENT_OPTIONS);
    },
    updatePreviewSize() {
      if (!this.format) {
        this.format = FORMAT_OPTIMIZED;
      }

      const [width, height] = calcPreviewSize(this.format, this.$refs.img, this.corner_data);
      this.preview.width = width;
      this.preview.height = height;
    },
    // creates all the gl stuff necessary to render the input to a canvas as texture
    preparePreview (img) {
      this.glData = null;
      if (!this.$refs.preview)
        return;
      if (this.preview == null) {
        this.preview = document.createElement('canvas');
        this.preview.style.maxWidth = "100%";
        this.preview.style.maxHeight = "100%";
        this.preview.style.border = "1px solid black";
      }
      this.updatePreviewSize();
      this.glData = {};
      this.$refs.preview.appendChild(this.preview);
      this.glData.gl = this.preview.getContext('webgl', {antialias: false, depth: false, preserveDrawingBuffer: true});
      if (!this.glData.gl) {
        // TODO: WebGL not supported by the browser
        alert("WebGL is not supported by the browser");
        return;
      }
      this.glData.gl.viewport(0, 0, this.preview.width, this.preview.height);

      // create Shader Program
      const vertexShader = loadShader(this.glData.gl, this.glData.gl.VERTEX_SHADER, vsSource);
      const fragmentShader = loadShader(this.glData.gl, this.glData.gl.FRAGMENT_SHADER, fsSource);
      this.glData.shaderProgram = this.glData.gl.createProgram();
      this.glData.gl.attachShader(this.glData.shaderProgram, vertexShader);
      this.glData.gl.attachShader(this.glData.shaderProgram, fragmentShader);
      this.glData.gl.linkProgram(this.glData.shaderProgram);
      if (!this.glData.gl.getProgramParameter(this.glData.shaderProgram, this.glData.gl.LINK_STATUS)) {
        alert('Unable to initialize the shader program: ' + this.glData.gl.getProgramInfoLog(this.glData.shaderProgram));
        return null;
      }
      this.glData.vertexPosition = this.glData.gl.getAttribLocation(this.glData.shaderProgram, 'aVertexPosition')
      this.glData.uSampler = this.glData.gl.getUniformLocation(this.glData.shaderProgram, 'uSampler');
      this.glData.uTopLeft = this.glData.gl.getUniformLocation(this.glData.shaderProgram, 'uTopLeft');
      this.glData.uTopRight = this.glData.gl.getUniformLocation(this.glData.shaderProgram, 'uTopRight');
      this.glData.uBottomLeft = this.glData.gl.getUniformLocation(this.glData.shaderProgram, 'uBottomLeft');
      this.glData.uBottomRight = this.glData.gl.getUniformLocation(this.glData.shaderProgram, 'uBottomRight');

      // Create position buffer
      this.glData.positionBuffer = this.glData.gl.createBuffer();

      // create Texture
      this.glData.texture = this.glData.gl.createTexture();
      this.glData.gl.bindTexture(this.glData.gl.TEXTURE_2D, this.glData.texture);
      this.glData.gl.texImage2D(this.glData.gl.TEXTURE_2D, 0, this.glData.gl.RGBA, this.glData.gl.RGBA, this.glData.gl.UNSIGNED_BYTE, img);
      this.glData.gl.texParameteri(this.glData.gl.TEXTURE_2D, this.glData.gl.TEXTURE_WRAP_S, this.glData.gl.CLAMP_TO_EDGE);
      this.glData.gl.texParameteri(this.glData.gl.TEXTURE_2D, this.glData.gl.TEXTURE_WRAP_T, this.glData.gl.CLAMP_TO_EDGE);
      this.glData.gl.texParameteri(this.glData.gl.TEXTURE_2D, this.glData.gl.TEXTURE_MIN_FILTER, this.glData.gl.LINEAR);

      // draw Scene
      this.drawPreview();
    },
    drawPreview () {
      if (!this.glData)
        return;

      if (this.format == FORMAT_OPTIMIZED) {
        this.updatePreviewSize();
        this.glData.gl.viewport(0, 0, this.preview.width, this.preview.height);
      }

      this.glData.gl.bindBuffer(this.glData.gl.ARRAY_BUFFER, this.glData.positionBuffer);
      const positions = [
        1.0,  1.0,
        -1.0,  1.0,
        1.0, -1.0,
        -1.0, -1.0,
      ];
      this.glData.gl.bufferData(this.glData.gl.ARRAY_BUFFER, new Float32Array(positions), this.glData.gl.STATIC_DRAW);
      
      this.glData.gl.clearColor(0.0, 0.0, 0.0, 1.0);
      this.glData.gl.clearDepth(1.0);
      this.glData.gl.clear(this.glData.gl.COLOR_BUFFER_BIT | this.glData.gl.DEPTH_BUFFER_BIT);
      this.glData.gl.bindBuffer(this.glData.gl.ARRAY_BUFFER, this.glData.positionBuffer);
      this.glData.gl.vertexAttribPointer(this.glData.vertexPosition, 2, this.glData.gl.FLOAT, false, 0, 0);
      this.glData.gl.enableVertexAttribArray(this.glData.vertexPosition);
      this.glData.gl.useProgram(this.glData.shaderProgram);
      this.glData.gl.activeTexture(this.glData.gl.TEXTURE0);
      this.glData.gl.bindTexture(this.glData.gl.TEXTURE_2D, this.glData.texture);
      this.glData.gl.uniform1i(this.glData.uSampler, 0);
      this.glData.gl.uniform2f(this.glData.uTopLeft, this.corner_data[0].x, this.corner_data[0].y);
      this.glData.gl.uniform2f(this.glData.uTopRight, this.corner_data[1].x, this.corner_data[1].y);
      this.glData.gl.uniform2f(this.glData.uBottomLeft, this.corner_data[3].x, this.corner_data[3].y);
      this.glData.gl.uniform2f(this.glData.uBottomRight, this.corner_data[2].x, this.corner_data[2].y);
      this.glData.gl.drawArrays(this.glData.gl.TRIANGLE_STRIP, 0, 4);
      this.$emit('change', this.preview.toDataURL('image/jpeg'));
    },
  },

  mounted: function () {
    if (!this.corner_data) {
      this.setupCorners();
    }
  },
  beforeDestroy () {
    this.glData = null;
  },
})
</script>

<style scoped>
.main-container {
  display:flex;
  flex-direction: column;
  align-items: center;
  overflow-y: hidden;
  max-height: 100%;
}
hr {
  width: 100%;
}
.main__wrapper {
  overflow: hidden;
  padding: 8px;
}
.main {
  position: relative;
  display: inline-block;
  max-width: 100%;
  max-height: 100%;
  flex-shrink: 1;
  user-select: none;
}
canvas {
  max-width: 100%;
  max-height: 100%;
}
img {
  max-width: 100%;
  max-height: 100%;
}
.corner {
  position: absolute;
  width: 12px;
  height: 12px;
  margin-left: -5px;
  margin-top: -4px;
  border: 4px solid black;
  border-radius: 10em;
  z-index: 2;
  background-color: black;
  cursor: all-scroll;
}
.edge {
  color: black;
  height: 9px;
  background-color: black;
  position: absolute;
  z-index: 1;
  transform-origin: center left;
  background-clip: content-box;
}
#overlay {
  position: absolute;
  left: 0;
  max-width: 100%;
  opacity: 0.4;
}
.controls {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  --esd-controlsBtnWidth: 64px;
}
.controls-msg {
  flex: 1 1 auto;
  margin-left: calc(var(--esd-controlsBtnWidth) + .5rem);
  margin-right: .5rem;
  text-align: center;
}
.rotate-btns {
  display: flex;
  justify-content: flex-end;
  flex: 1 1 auto;
}
.controls-msg + .rotate-btns {
  flex: 0 0 var(--esd-controlsBtnWidth);
}
</style>
