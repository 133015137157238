<template>
  <BaseModal 
    ref="modal" 
    :modalTitle="title" 
    size="lg"
    labelButtonConfirm="Anwenden"
    @close="reset"
    @onConfirmButton="onConfirm()"
  >
    <template v-if="showFilter" #modalHeaderBottom>
      <div class="row my-0">
        <div class="col-6 col-sm-3">
          <InputField label="Nummer" v-model="filterForm[rowId]" class="pb-1" />
        </div>
        <div class="col-6 col-sm-3">
          <ComboBox 
            label="Status" 
            :value="filterForm.status" 
            :values="statusValues" 
            @change="$set(filterForm, 'status', $event)"
          />
        </div>
      </div>
    </template>
    <template #default>
      <div v-if="hasConflicts" class="fc-alert fc-alert-warning">
        <div>Es gibt einige Datenkonflikte.</div>
        <div>Unabhängig davon, ob diese Konfiguration auf eine Konfiguration mit Datenkonflikt angewendet wird, verlieren diese Benutzer ihre Konfiguration.</div>
      </div>

      <Table
        :key="rows.length"
        :headers="headers"
        :rows="rows"
        :rowId="rowId"
        :rowsPerPage="TABLE_ROWS_PER_PAGE"
        ignoreTablePageSize
        @action-VIEW_DIFF="openViewDiffModal"
      >
        <template #header_select>
          <InputCheckBoxItem 
            class="checkbox--select-all"
            :value="isAllSelected" 
            :indeterminate="hasSelected" 
            @input="onSelectAll($event)" 
          />
        </template>
        <template #select="row">
          <InputCheckBoxItem 
            :value="isRowSelected[row[rowId]]" 
            @input="onSelect(row[rowId], $event)" 
          />
        </template>
      </Table>

      <OptionsMenuConfigViewDiffModal ref="viewDiffModal" />
    </template>
  </BaseModal>
</template>

<script>
import { mapGetters } from 'vuex';
import MENU_CONFIG_TYPES from '@/store/menuConfig/types';

import BaseModal from '@/components/core/BaseModal.vue';
import InputField from '@/components/core/forms/InputField.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import Table from '@/components/table2/Table.vue';
import { SlotColumn, TextColumn, PillColumn, ActionColumn, SimpleAction } from '@/components/table2/table_util';
import InputCheckBoxItem from '@/components/core/forms/checkbox/InputCheckBoxItem.vue';
import OptionsMenuConfigViewDiffModal from '@/components/core/option-menu/optionMenuConfig/OptionsMenuConfigViewDiffModal.vue';

import { findConfiguredMenuActives, normalizeMenu } from '@/components/core/option-menu/option-menu-utils';
import { OptionsMenuDiffHelper, ApplyConfigStatus } from '@/menu/menu-config-utils';
import { FC_CONFIG_USER_LEVEL } from '@/configs/fcConfig';

const TABLE_ROWS_PER_PAGE = 10;

export default {
  components: {
    BaseModal,
    InputField,
    ComboBox,
    Table,
    OptionsMenuConfigViewDiffModal,
    InputCheckBoxItem,
  },
  props: {
    title: {
      type: String,
      default: 'Konfiguration übernehmen',
    },
    rowId: {
      type: String,
    },
    configContext: {
      type: String,
    },
    configId: {
      type: String,
    },
    userLevel: {
      type: String,
    },
    showFilter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      TABLE_ROWS_PER_PAGE,
      filterForm: {
        [this.rowId]: '',
        status: '',
      },
      optionsMenuConfigs: [],
      isRowSelected: {},
    };
  },
  computed: {
    ...mapGetters({
      appNavigationConfigByPathFn: MENU_CONFIG_TYPES.GETTERS.APP_NAVIGATION_CONFIG_BY_PATH,
      isMenuPathVisibleFn: MENU_CONFIG_TYPES.GETTERS.IS_MENU_PATH_VISIBLE,
      allOptionsMenuPermissionConfigAllMitarbeiter: MENU_CONFIG_TYPES.GETTERS.ALL_OPTIONS_MENU_PERMISSION_CONFIG_ALL_MITARBEITER,
      optionsMenuPermissionConfigFn: MENU_CONFIG_TYPES.GETTERS.OPTIONS_MENU_PERMISSION_CONFIG
    }),
    allOptionsMenuPermissionConfigAllMitarbeiterByUnternr() {
      if (!this.allOptionsMenuPermissionConfigAllMitarbeiter) {
        return {}
      }

      const byUnternr = this.allOptionsMenuPermissionConfigAllMitarbeiter.reduce((acc, cur) => {
        if (!acc[cur.unternr]) {
          acc[cur.unternr] = cur;
        }
        return acc;
      }, {});

      return byUnternr;
    },
    statusValues() {
      return [
        { value: '', label: 'Alle' }, 
        { value: ApplyConfigStatus.HAS_CONFLICT, label: ApplyConfigStatus.HAS_CONFLICT },
        { value: ApplyConfigStatus.NO_CONFLICT, label: ApplyConfigStatus.NO_CONFLICT },
        { value: ApplyConfigStatus.NO_CONFIG, label: ApplyConfigStatus.NO_CONFIG },
      ];
    },
    headers() {
      return {
        lockedLeft: [
          SlotColumn('select').makeAlwaysVisible(),
          TextColumn(this.rowId, `Nummer`).makeAlwaysVisible(),
        ],
        center: [
          TextColumn('fullname', 'Name'),
        ],
        lockedRight: [
          PillColumn('statusPill', 'Status').makeAlwaysVisible(),
          ActionColumn('actions'),
        ],
      };
    },
    optionsMenuConfigsIntern() {
      return this.optionsMenuConfigs.map(config => {
        return {
          ...config,
          status: this.findApplyBothStatus(config),
        };
      });
      
    },
    hasConflicts() {
      return this.optionsMenuConfigsIntern.some(config => config.status === ApplyConfigStatus.HAS_CONFLICT);
    },
    rows() {
      const { filterForm } = this;
      return [ ...this.optionsMenuConfigsIntern || [] ]
        .filter(row => row[this.rowId].startsWith(filterForm[this.rowId] || '') 
            && (!filterForm.status || filterForm.status === row.status))
        .map(row => ({
          ...row,
          statusPill: {
            ...this.findStatusPill(row),
            bordered: true,
          },
          actions: [
            SimpleAction('VIEW_DIFF', 'PhEye', 'Konfigurationen vergleichen'),
          ],
        }));
    },
    selected() {
      return this.optionsMenuConfigsIntern.filter(config => this.isRowSelected[config[this.rowId]]);
    },
    isAllSelected() {
      return this.rows.every(config => this.isRowSelected[config[this.rowId]]);
    },
    hasSelected() {
      return this.selected?.length > 0;
    },
  },
  methods: {
    open(optionsMenuConfigs) {
      this.optionsMenuConfigs = [ ...optionsMenuConfigs || [], ];

      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    reset() {
      this.filterForm = {
        [this.rowId]: '',
        status: '',
      };
      this.optionsMenuConfigs = [];
      this.isRowSelected = {};
    },
    findApplyBothStatus(config) {
      const options = this.findApplyConfigStatus(config);
      const permission = this.findApplyConfigPermissionStatus(config);

      const weight = {
        [ApplyConfigStatus.NO_CONFIG]: 1,
        [ApplyConfigStatus.NO_CONFLICT]: 2,
        [ApplyConfigStatus.HAS_CONFLICT]: 3,
      }

      if (weight[options] > weight[permission]) {
        return options;
      } else {
        return permission;
      }

    },
    findApplyConfigStatus(config) {
      if (config?.content) {
        const appNavigation = this.appNavigationConfigByPathFn(this.configContext, this.configId);

        const currentConfig = this.$store.getters[MENU_CONFIG_TYPES.GETTERS._CURRENT_OPTIONS_MENU_CONFIG](this.configContext, this.userLevel, this.configId);
        const sourceConfig = findConfiguredMenuActives(normalizeMenu(appNavigation.optionMenu, appNavigation.currentOptionMenu, currentConfig));

        const destConfig = findConfiguredMenuActives(normalizeMenu(appNavigation.optionMenu, appNavigation.currentOptionMenu, config.content));
        return OptionsMenuDiffHelper.isIdentical(sourceConfig, destConfig) ? ApplyConfigStatus.NO_CONFLICT : ApplyConfigStatus.HAS_CONFLICT;
      }

      return ApplyConfigStatus.NO_CONFIG;
    },
    findApplyConfigPermissionStatus(config) {
      const permissionContent = this.allOptionsMenuPermissionConfigAllMitarbeiterByUnternr?.[config?.unternr]?.content

      if (permissionContent) {
        return ApplyConfigStatus.NO_CONFLICT;
      }

      return ApplyConfigStatus.NO_CONFIG;
    },
    findStatusPill(row) {
      let type = '';

      switch(row.status) {
        case ApplyConfigStatus.HAS_CONFLICT:
          type = 'warning';
          break;
        case ApplyConfigStatus.NO_CONFLICT:
          type = 'info';
          break;
        default:
          type = 'default';
      }

      return {
        label: row.status,
        type,
      };
    },
    onSelectAll(value) {
      const allCurrentRowsSelected = this.rows.reduce((acc, row) => ({
        ...acc,
        [row[this.rowId]]: value || undefined,
      }), {});

      this.isRowSelected = {
        ...this.isRowSelected,
        ...allCurrentRowsSelected,
      };
    },
    onSelect(rowId, value) {
      this.$set(this.isRowSelected, rowId, value || undefined);
    },
    openViewDiffModal(row) {
      const selectedConfig = {
        nummer: row[this.rowId],
        fullname: row.fullname,
      };

      const appNavigation = this.appNavigationConfigByPathFn(this.configContext, this.configId);

      const currentConfig = this.$store.getters[MENU_CONFIG_TYPES.GETTERS._CURRENT_OPTIONS_MENU_CONFIG](this.configContext, this.userLevel, this.configId);
      const sourceConfig = this.setAllowedFlag(normalizeMenu(appNavigation.optionMenu, appNavigation.currentOptionMenu, currentConfig));

      const hasConfigSaved = !!row.content;
      const destConfig = hasConfigSaved ? this.setAllowedFlag(normalizeMenu(appNavigation.optionMenu, appNavigation.currentOptionMenu, row.content)) : [];
      this.$refs.viewDiffModal.open(selectedConfig, sourceConfig, destConfig);
    },
    setAllowedFlag(menus) {
      return menus.map(menu => ({
        ...menu,
        allowed: this.isMenuPathVisibleFn(this.configContext, this.userLevel, menu.path),
      }));
    },
    onConfirm() {
      const selected = [ ...this.selected || [] ].map(config => config[this.rowId]);
      const ignored = this.optionsMenuConfigsIntern
        .filter(config => selected.indexOf(config[this.rowId]) < 0)
        .map(config => config[this.rowId]);

      this.$emit('onConfirm', {
        selected,
        ignored: [ ...ignored || [] ],
      });
    },
  },
}
</script>

<style lang="scss" scoped>
.checkbox--select-all {
  margin: 0 3px;
}
</style>
