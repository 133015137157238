var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-container" }, [
    _c(
      "div",
      {
        staticClass: "main__wrapper",
        style: _vm.hideSelection ? { display: "none" } : {}
      },
      [
        _c("div", { staticClass: "main" }, [
          _c(
            "div",
            {
              style: {
                "max-width": _vm.overlayWidth,
                position: "relative",
                height: "100%"
              }
            },
            [
              _c("img", {
                ref: "img",
                attrs: { src: _vm.imageData },
                on: { load: _vm.updateOverlay }
              }),
              _c("canvas", {
                ref: "overlay",
                attrs: { id: "overlay" },
                on: {
                  mousemove: function($event) {
                    return _vm.hoverOverlay($event)
                  },
                  mousedown: function($event) {
                    return _vm.dragOverlay($event)
                  },
                  touchstart: function($event) {
                    return _vm.dragOverlay($event)
                  }
                }
              }),
              _c(
                "div",
                {
                  staticClass: "center",
                  staticStyle: { position: "absolute" }
                },
                [
                  _vm.$refs.overlay
                    ? _c("EdgeDetection", {
                        attrs: {
                          imageInput: _vm.imageDataAsHTMLImage,
                          debugStep: "2"
                        },
                        on: { change: _vm.setCorners }
                      })
                    : _vm._e()
                ],
                1
              )
            ]
          ),
          _c(
            "div",
            { staticClass: "corners-edges__container" },
            [
              _vm._l(_vm.corner_data, function(corner, i) {
                return [
                  _c("div", {
                    key: corner.id,
                    staticClass: "corner",
                    style: {
                      left: corner.x * 100 + "%",
                      top: corner.y * 100 + "%"
                    },
                    on: {
                      mousedown: function(e) {
                        return _vm.dragCorner(corner.id, e)
                      },
                      touchstart: function(e) {
                        return _vm.dragCorner(corner.id, e)
                      }
                    }
                  }),
                  _c("div", {
                    key: "edge_" + corner.id,
                    staticClass: "edge",
                    style: _vm.getEdgeStyle(
                      corner,
                      _vm.corner_data[(i + 1) % _vm.corner_data.length]
                    ),
                    on: {
                      mousedown: function(e) {
                        return _vm.dragEdge(corner.id, e)
                      },
                      touchstart: function(e) {
                        return _vm.dragEdge(corner.id, e)
                      }
                    }
                  })
                ]
              })
            ],
            2
          )
        ])
      ]
    ),
    !_vm.hideControls
      ? _c("div", { staticClass: "controls mt-2" }, [
          !_vm.$isSmallScreen && !_vm.$isMobileNativeContext
            ? _c("div", { staticClass: "text-small controls-msg" }, [
                _c("span", { staticClass: "shortcut-text" }, [_vm._v("Shift")]),
                _vm._v(" gedrückt halten = Seitenverhältnis beibehalten ")
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "rotate-btns" },
            [
              _c(
                "BaseButton",
                { attrs: { isClear: "" }, on: { click: _vm.rotateLeft } },
                [_c("PhArrowArcLeft", { attrs: { size: 24 } })],
                1
              ),
              _c(
                "BaseButton",
                { attrs: { isClear: "" }, on: { click: _vm.rotateRight } },
                [_c("PhArrowArcRight", { attrs: { size: 24 } })],
                1
              )
            ],
            1
          )
        ])
      : _vm._e(),
    _c(
      "div",
      {
        staticClass: "main__wrapper mt-2",
        staticStyle: { "flex-shrink": "1" }
      },
      [
        !_vm.hideControls
          ? _c("div", { ref: "preview", staticClass: "main" })
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }