<template>
  <div>
    <PageHeaderTitleNavigation
      title="Kundenzugang und Kunden-App versenden"
      :actions="actions"
      @action-CONFIRM="confirm()"
      @action-SEE_LATER="spaterAnzeigen()"
      @action-DECLINE="decline()">
    </PageHeaderTitleNavigation>

    <div class="box__container">
      <p>
        Erleichtern Sie sich den Arbeitsaufwand beim Verschicken der Kundenzugänge an Ihre Kunden. 
				Mit dieser Aktion ermittelt das System <strong>jeden</strong> Abend diejenigen Ihrer Kunden (mit eingetragener Mailadresse und Handynummer), 
				die sich noch nie in den Kundenzugang bzw. Kunden-App eingeloggt haben und 
				schickt diesen dann einmalig eine E-Mail mit einem Link zur Homepage <strong>{{appNameDefault}}</strong> und zum <strong>KundenLogin</strong>.
				Legen Sie einen Neukunden an oder ergänzen Sie die persönlichen Daten des Kunden um die Mailadresse sowie die Handynummer, 
				und Sie brauchen nicht mehr Ihren Kunden einzeln den Kundenzugang zuzuschicken. Dies geschieht dann automatisch über das System. 
				
				<strong>Achtung:</strong> Wenn Sie über Ihre eigene Homepage den Kundenlogin bzw. auch die eigene Kundenapp in Ihrem Look-and-feel Ihren Kunden anbieten, 
				bitten wir Sie den Text der E-Mail unter dem Reiter <strong>Daten/Einstellungen/Einstellungen E-Mailtexte</strong> 
				und der Vorlage <strong>Mobiler Zugang</strong> bezüglich Ihre Homepage und Kundenzugang-URL anzupassen. Die App wird automatisch angepasst. 
      </p>

    </div>
  </div>
</template>

<script>
import CORE_TYPES from "@/store/core/types";
import { mapGetters } from "vuex";
import PENDING_ACTIONS_TYPES from "@/store/pendingActions/types";
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import { PageHeaderSimpleAction, PageHeaderDownloadLinkAction } from '@/components/core/header-title-navigation/page-header-utils'

export default {
  components: {
    PageHeaderTitleNavigation,
  },
  computed: {
    ...mapGetters({
      pendingActions: CORE_TYPES.GETTERS.PENDING_ACTIONS,
      actionId: PENDING_ACTIONS_TYPES.GETTERS.ACTION_ID,
      appNameDefault: CORE_TYPES.GETTERS.APP_NAME_DEFAULT,
    }),
    calculatedActionId() {
      const simpleActionId = this.$route.query.simpleActionId;
      return simpleActionId || this.actionId?.value;
    },
    actions() {
      return [
        PageHeaderSimpleAction('CONFIRM', 'Ja'),
        PageHeaderSimpleAction('SEE_LATER', 'Später anzeigen'),
        PageHeaderSimpleAction('DECLINE', 'Nein'),
        PageHeaderDownloadLinkAction('EMAIL_PREVIEW', 'E-mail Vorschau anzeigen', 
          'ActionKundenzugang.pdf', '/simpleActionKundenzugangPdf', { 
            simpleActionId: this.calculatedActionId
          })
      ]
    },
  },
  mounted() {
    this.$store.dispatch(PENDING_ACTIONS_TYPES.ACTIONS.GET_ACTION_ID, {
      actionStatus: 'PENDING',
      actionType: 'SimpleActionKundenzugang',
      }).then(response => {
          this.$store.commit(PENDING_ACTIONS_TYPES.MUTATIONS.GET_ACTION_ID_SUCCESS, response);
      });  
  },
  methods: {
    confirm() {
      // SimpleActionFrameworkServlet.java -> line 280 -> updateData(...)

      const simpleActionId = this.$route.query.simpleActionId || this.actionId?.value;

      this.$store.dispatch(PENDING_ACTIONS_TYPES.ACTIONS.SIMPLE_ACTION_KUNDENZUGANG, {
        simpleActionId,
        actionCommand: 'BUTTON_YES',
        }).then(response => {
            this.$store.commit(PENDING_ACTIONS_TYPES.MUTATIONS.SIMPLE_ACTION_KUNDENZUGANG_SUCCESS, response);

            if (this.$route.query.simpleActionId) {
              this.$router.go(-1);
            } else {
              this.$router.push('/home');
            }
            
        });  
    },
    spaterAnzeigen() {
      this.$router.push('/home');
    },
    decline() {
      // SimpleActionFrameworkServlet.java -> line 280 -> updateData(...)

      const simpleActionId = this.$route.query.simpleActionId || this.actionId?.value;

      this.$store.dispatch(PENDING_ACTIONS_TYPES.ACTIONS.SIMPLE_ACTION_KUNDENZUGANG, {
        simpleActionId,
        actionCommand: 'BUTTON_NO',
        }).then(response => {
            this.$store.commit(PENDING_ACTIONS_TYPES.MUTATIONS.SIMPLE_ACTION_KUNDENZUGANG_SUCCESS, response);
            
            if (this.$route.query.simpleActionId) {
              this.$router.go(-1);
            } else {
              this.$router.push('/home');
            }
        });        
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch(PENDING_ACTIONS_TYPES.ACTIONS.GET_SIMPLE_ACTION_LISTE);  

    this.$addBreadcrumb({
      label: 'zurück zu Kundenzugang und Kunden-App versenden', 
      to,
      from,
      breadcrumb: "Kundenzugang und Kunden-App versenden"
    });

    next();
  },
}
</script>

<style scoped>

</style>