<template>
  <div>
      <div class="row">
        <div class="col-12">
          <BoxContainer title="">
            <div class="d-flex">
              <div class="box__title" style="flex-grow: 1;">Wertentwicklung</div>
              <ComboBox v-if="zoom" :value="years" :values="zoom" @change="onChangeYear" />
            </div>
            <ChartFunds type="Fondsinfo" :chartData="chartPerf"/>
            <!-- <ChartPerformance :chartData="perfData" :zoom="perfZoom" :isin="isin"></ChartPerformance> -->
          </BoxContainer>
        </div>
        <div class="col-12">
          <BoxContainer title="Jährliche Rendite">
            <BarChart format="percent" height="400px" :chartOptions="chartRendite" gridLeft="0%"/>
          </BoxContainer>
        </div>
        <div class="col-12">
          <BoxContainer title="Rendite / Risiko">
            <ChartYieldRisk :chartParam="riskChartParams"></ChartYieldRisk>
          </BoxContainer>
        </div>
      </div>
  </div>
</template>

<script>
import FONDSINFO_TYPES from "@/store/fondsinfo/types";
import CORE_TYPES from "@/store/core/types";
import BoxContainer from "@/components/core/BoxContainer.vue";
import { mapGetters } from "vuex";
import ChartPerformance from "@/components/charts/ChartPerformance.vue";
import ChartFunds from '@/components/charts/ChartFunds.vue';
import {utils} from '@/components/charts/chartFondsUtil.js';
import ChartYieldRisk from "@/components/charts/ChartYieldRisk.vue";
import BarChart from "@/components/charts/echarts/BarChart.vue";
import ComboBox from '@/components/core/forms/ComboBox.vue';

export default {
  components: {
    BoxContainer,
    ChartPerformance, ChartFunds,
    ChartYieldRisk,
    BarChart,
    ComboBox,
  },
  name: "TabCharts",
  props: {
    selectedTabLabel: {
      type: String,
    },
  },
  data(){
    return {
      riskChartParams: {
        isin: this.$route.params?.isin,
        year: 5
      },
      chartPerf: {},
      perfData: [],
      perfZoom: [],
      isin: this.$route.params?.isin,
      years: 5,
      zoom: null,
    }
  },
  computed: {
    ...mapGetters({
      performanceChart: FONDSINFO_TYPES.GETTERS.FONDSINFO_PERFORMANCE_CHART,
      rendite: FONDSINFO_TYPES.GETTERS.FONDSINFO_RENDITE_CHART,
      isSmallScreen: CORE_TYPES.GETTERS.IS_SMALL_SCREEN
    }),
    chartRendite() {
      if (this.rendite) {
        const chartOptions = utils.jaehrlicheTendite(this.rendite, this.isSmallScreen);
        return chartOptions;
      }
      return {};
    }
  },
  mounted() {
    this.loadPerformance();
  },
  watch: {
    performanceChart: 'receiveData',
  },
  methods: {
    loadPerformance() {
      this.$store.dispatch(
        FONDSINFO_TYPES.ACTIONS.GET_FONDSINFO_PERFORMANCE_CHART,
        {
          isin: this.isin,
          years: this.years
        }
      );
      this.chartPerf = {};
      this.$store.dispatch(FONDSINFO_TYPES.ACTIONS.GET_FONDSINFO_RENDITE_CHART, {isin: this.isin, years: this.years} );
    },
    receiveData() {
      if(this.performanceChart){
        this.perfData = this.performanceChart.records;
        this.chartPerf = utils.convSerieData([{'name': 'Wertentwicklung', 'data': this.performanceChart.records}])[0];
     
        const series = utils.addGD200Series(this.chartPerf);
        if (this.performanceChart.rows.length) {
          this.perfZoom = this.performanceChart.rows[0].zoom;
        }
        //
        if (this.zoom == null && this.chartPerf?.data?.length){
          let test = new Date();
          test = new Date(test.getFullYear() - 5, test.getMonth(), test.getDate() + 3);
          if (test.getTime() > this.chartPerf.data[0][0]) {
            this.zoom = [{label: '5Jahre', value: 5}, {label: 'Seit Beginn', value: 100}]
          }
        }
      }
    },
    onChangeYear(years) {
      this.years = years;
      this.loadPerformance();
    },
  }
};
</script>

<style>
</style>