export const MUTATION_PREFIX = 'MENU_CONFIG: ';
export const ACTIONS_PREFIX = 'MENU_CONFIG_ACTIONS_';
export const GETTERS_PREFIX = 'MENU_CONFIG_GETTERS_';

export const CONFIGURE_MENU_CONFIG_LOADING_ID = 'configureMenuConfig-loading';

export default {
  MUTATIONS: {
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',

    // Makler context when editing a Mitarbeiter data
    SET_SELECTED_UNTERNR: MUTATION_PREFIX + 'SET_SELECTED_UNTERNR',
    RESET_SELECTED_UNTERNR: MUTATION_PREFIX + 'RESET_SELECTED_UNTERNR',

    // Common
    SET_FLAT_MENU_CONFIG: MUTATION_PREFIX + 'SET_FLAT_MENU_CONFIG',
    SET_USER_LEVELS: MUTATION_PREFIX + 'SET_USER_LEVELS',
    RESET_COMMON_DATA: MUTATION_PREFIX + 'RESET_COMMON_DATA',

    // Options Menu Config
    SET_OPTIONS_MENU_CONFIG: MUTATION_PREFIX + 'SET_OPTIONS_MENU_CONFIG',
    RESET_OPTIONS_MENU_CONFIG: MUTATION_PREFIX + 'RESET_OPTIONS_MENU_CONFIG',
    RESET_OPTIONS_MENU_CONFIG_BY_USER_LEVEL: MUTATION_PREFIX + 'RESET_OPTIONS_MENU_CONFIG_BY_USER_LEVEL',
    SET_OPTIONS_MENU_CONFIG_EDITED: MUTATION_PREFIX + 'SET_OPTIONS_MENU_CONFIG_EDITED',
    RESET_OPTIONS_MENU_CONFIG_EDITED: MUTATION_PREFIX + 'RESET_OPTIONS_MENU_CONFIG_EDITED',
    RESET_OPTIONS_MENU_CONFIG_EDITED_BY_USER_LEVEL: MUTATION_PREFIX + 'RESET_OPTIONS_MENU_CONFIG_EDITED_BY_USER_LEVEL',

    // Options Menu Permission Config
    SET_OPTIONS_MENU_PERMISSION_CONFIG: MUTATION_PREFIX + 'SET_OPTIONS_MENU_PERMISSION_CONFIG',
    SET_OPTIONS_MENU_PERMISSION_CONFIG_EDITED: MUTATION_PREFIX + 'SET_OPTIONS_MENU_PERMISSION_CONFIG_EDITED',
    RESET_OPTIONS_MENU_PERMISSION_CONFIG_EDITED: MUTATION_PREFIX + 'RESET_OPTIONS_MENU_PERMISSION_CONFIG_EDITED',
    RESET_OPTIONS_MENU_PERMISSION_CONFIG_EDITED_BY_USER_LEVEL: MUTATION_PREFIX + 'RESET_OPTIONS_MENU_PERMISSION_CONFIG_EDITED_BY_USER_LEVEL',

    FIND_ALL_OPTIONS_MENU_PERMISSION_CONFIG_ALL_MITARBEITER_SUCCESS: MUTATION_PREFIX + 'FIND_ALL_OPTIONS_MENU_PERMISSION_CONFIG_ALL_MITARBEITER_SUCCESS',
  },
  ACTIONS: {
    // Common
    CONFIGURE_MENU_CONFIG: ACTIONS_PREFIX + 'CONFIGURE_MENU_CONFIG',
    CONFIGURE_USER_LEVEL: ACTIONS_PREFIX + 'CONFIGURE_USER_LEVEL',
    RESET_ALL: ACTIONS_PREFIX + 'RESET_ALL',

    // Options Menu Config
    FIND_OPTIONS_MENU_CONFIG: ACTIONS_PREFIX + 'FIND_OPTIONS_MENU_CONFIG',
    SAVE_ALL_OPTIONS_MENU_CONFIG: ACTIONS_PREFIX + 'SAVE_ALL_OPTIONS_MENU_CONFIG',
    DELETE_OPTIONS_MENU_CONFIG: ACTIONS_PREFIX + 'DELETE_OPTIONS_MENU_CONFIG',

    COPY_OPTIONS_MENU_CONFIG_TO_USER_LEVEL: ACTIONS_PREFIX + 'COPY_OPTIONS_MENU_CONFIG_TO_USER_LEVEL',

    // Apply Options Menu Config - Unterstruktur
    FIND_ALL_OPTIONS_MENU_CONFIG_UNTERSTRUKTUR: ACTIONS_PREFIX + 'FIND_ALL_OPTIONS_MENU_CONFIG_UNTERSTRUKTUR',
    APPLY_OPTIONS_MENU_CONFIG_TO_ALL_UNTERSTRUKTUR: ACTIONS_PREFIX + 'APPLY_OPTIONS_MENU_CONFIG_TO_ALL_UNTERSTRUKTUR',
    APPLY_OPTIONS_MENU_CONFIG_TO_UNTERSTRUKTUR: ACTIONS_PREFIX + 'APPLY_OPTIONS_MENU_CONFIG_TO_UNTERSTRUKTUR',

    // Apply Options Menu Config - Mitarbeiter
    FIND_ALL_OPTIONS_MENU_CONFIG_MITARBEITER: ACTIONS_PREFIX + 'FIND_ALL_OPTIONS_MENU_CONFIG_MITARBEITER',
    APPLY_OPTIONS_MENU_CONFIG_TO_ALL_MITARBEITER: ACTIONS_PREFIX + 'APPLY_OPTIONS_MENU_CONFIG_TO_ALL_MITARBEITER',
    APPLY_OPTIONS_MENU_CONFIG_TO_MITARBEITER: ACTIONS_PREFIX + 'APPLY_OPTIONS_MENU_CONFIG_TO_MITARBEITER',

    // Options Menu Config - Mitarbeiter
    FIND_OPTIONS_MENU_CONFIG_MITARBEITER: ACTIONS_PREFIX + 'FIND_OPTIONS_MENU_CONFIG_MITARBEITER',
    SAVE_ALL_OPTIONS_MENU_CONFIG_MITARBEITER: ACTIONS_PREFIX + 'SAVE_ALL_OPTIONS_MENU_CONFIG_MITARBEITER',
    DELETE_OPTIONS_MENU_CONFIG_MITARBEITER: ACTIONS_PREFIX + 'DELETE_OPTIONS_MENU_CONFIG_MITARBEITER',

    // Options Menu Config - Global
    FIND_OPTIONS_MENU_CONFIG_GLOBAL: ACTIONS_PREFIX + 'FIND_OPTIONS_MENU_CONFIG_GLOBAL',
    SAVE_ALL_OPTIONS_MENU_CONFIG_GLOBAL: ACTIONS_PREFIX + 'SAVE_ALL_OPTIONS_MENU_CONFIG_GLOBAL',
    DELETE_OPTIONS_MENU_CONFIG_GLOBAL: ACTIONS_PREFIX + 'DELETE_OPTIONS_MENU_CONFIG_GLOBAL',

    // Options Menu Permission Config
    ADD_OPTIONS_MENU_PERMISSION_CONFIG_EDITED: ACTIONS_PREFIX + 'ADD_OPTIONS_MENU_PERMISSION_CONFIG_EDITED',
    FIND_ALL_OPTIONS_MENU_PERMISSION_CONFIG: ACTIONS_PREFIX + 'FIND_ALL_OPTIONS_MENU_PERMISSION_CONFIG',
    SAVE_ALL_OPTIONS_MENU_PERMISSION_CONFIG: ACTIONS_PREFIX + 'SAVE_ALL_OPTIONS_MENU_PERMISSION_CONFIG',
    DELETE_OPTIONS_MENU_PERMISSION_CONFIG: ACTIONS_PREFIX + 'DELETE_OPTIONS_MENU_PERMISSION_CONFIG',

    // Options Menu Permission Config - Mitarbeiter
    APPLY_OPTIONS_MENU_PERMISSION_CONFIG_TO_MITARBEITER: ACTIONS_PREFIX + 'APPLY_OPTIONS_MENU_PERMISSION_CONFIG_TO_MITARBEITER',
    FIND_ALL_OPTIONS_MENU_PERMISSION_CONFIG_MITARBEITER: ACTIONS_PREFIX + 'FIND_ALL_OPTIONS_MENU_PERMISSION_CONFIG_MITARBEITER',
    SAVE_ALL_OPTIONS_MENU_PERMISSION_CONFIG_MITARBEITER: ACTIONS_PREFIX + 'SAVE_ALL_OPTIONS_MENU_PERMISSION_CONFIG_MITARBEITER',
    DELETE_OPTIONS_MENU_PERMISSION_CONFIG_MITARBEITER: ACTIONS_PREFIX + 'DELETE_OPTIONS_MENU_PERMISSION_CONFIG_MITARBEITER',
    FIND_ALL_OPTIONS_MENU_PERMISSION_CONFIG_ALL_MITARBEITER: ACTIONS_PREFIX + 'FIND_ALL_OPTIONS_MENU_PERMISSION_CONFIG_ALL_MITARBEITER',

    // Options Menu Permission Config - Global
    FIND_ALL_OPTIONS_MENU_PERMISSION_CONFIG_GLOBAL: ACTIONS_PREFIX + 'FIND_ALL_OPTIONS_MENU_PERMISSION_CONFIG_GLOBAL',
    SAVE_ALL_OPTIONS_MENU_PERMISSION_CONFIG_GLOBAL: ACTIONS_PREFIX + 'SAVE_ALL_OPTIONS_MENU_PERMISSION_CONFIG_GLOBAL',
    DELETE_OPTIONS_MENU_PERMISSION_CONFIG_GLOBAL: ACTIONS_PREFIX + 'DELETE_OPTIONS_MENU_PERMISSION_CONFIG_GLOBAL',
  },
  GETTERS: {
    // Makler context when editing a Mitarbeiter data
    SELECTED_UNTERNR: GETTERS_PREFIX + 'SELECTED_UNTERNR',
    HAS_SELECTED_UNTERNR: GETTERS_PREFIX + 'HAS_SELECTED_UNTERNR',

    // Common
    IS_INTERN_GLOBAL_CONFIG_ALLOWED: GETTERS_PREFIX + 'IS_INTERN_GLOBAL_CONFIG_ALLOWED',

    FLAT_MENU_CONFIG: GETTERS_PREFIX + 'FLAT_MENU_CONFIG',
    FLAT_MENU_CONFIG_BY_PATH: GETTERS_PREFIX + 'FLAT_MENU_CONFIG_BY_PATH',
    IS_MENU_CONFIGURED: GETTERS_PREFIX + 'IS_MENU_CONFIGURED',
    OPTION_MENU: GETTERS_PREFIX + 'OPTION_MENU',
    PARENTS_MENU_CONFIG: GETTERS_PREFIX + 'PARENTS_MENU_CONFIG',
    APP_NAVIGATION_CONFIG_BY_PATH: GETTERS_PREFIX + 'APP_NAVIGATION_CONFIG_BY_PATH',

    OPTIONS_MENU_CONFIG_USER_LEVELS: GETTERS_PREFIX + 'OPTIONS_MENU_CONFIG_USER_LEVELS',

    OPTIONS_MENU_CONFIG_CONTEXTS: GETTERS_PREFIX + 'OPTIONS_MENU_CONFIG_CONTEXTS',
    OPTIONS_MENU_CONFIG_ROLES: GETTERS_PREFIX + 'OPTIONS_MENU_CONFIG_ROLES',
    OPTIONS_MENU_CONFIG_TABS: GETTERS_PREFIX + 'OPTIONS_MENU_CONFIG_TABS',
    OPTIONS_MENU_CONFIG_USER_LEVEL_DEFAULT: GETTERS_PREFIX + 'OPTIONS_MENU_CONFIG_USER_LEVEL_DEFAULT',

    RESOLVE_MENU_PATH: GETTERS_PREFIX + 'RESOLVE_MENU_PATH',
    IS_MENU_PATH_VISIBLE: GETTERS_PREFIX + 'IS_MENU_PATH_VISIBLE',
    IS_MENU_PATH_DISABLED: GETTERS_PREFIX + 'IS_MENU_PATH_DISABLED',

    // Options Menu Config
    _CURRENT_OPTIONS_MENU_CONFIG_EDITED_AND_SAVED_MERGED: GETTERS_PREFIX + '_CURRENT_OPTIONS_MENU_CONFIG_EDITED_AND_SAVED_MERGED', // should be used only in inner getters
    _CURRENT_OPTIONS_MENU_CONFIG: GETTERS_PREFIX + '_CURRENT_OPTIONS_MENU_CONFIG',
    IS_CONFIGURED_MENU_REMOVEABLE: GETTERS_PREFIX + 'IS_CONFIGURED_MENU_REMOVEABLE',
    IS_CONFIGURED_MENU_REMOVEABLE_CHANGEABLE: GETTERS_PREFIX + 'IS_CONFIGURED_MENU_REMOVEABLE_CHANGEABLE',
    IS_CONFIGURED_MENU_LOCKED: GETTERS_PREFIX + 'IS_CONFIGURED_MENU_LOCKED',
    IS_CONFIGURED_MENU_CHANGEABLE: GETTERS_PREFIX + 'IS_CONFIGURED_MENU_CHANGEABLE',
    CONFIGURED_MENU_FROM_STRUKTUR: GETTERS_PREFIX + 'CONFIGURED_MENU_FROM_STRUKTUR',
    OPTIONS_MENU_CONFIG: GETTERS_PREFIX + 'OPTIONS_MENU_CONFIG',
    HAS_OPTIONS_MENU_CONFIG_SAVED: GETTERS_PREFIX + 'HAS_OPTIONS_MENU_CONFIG_SAVED',
    OPTIONS_MENU_CONFIG_EDITED: GETTERS_PREFIX + 'OPTIONS_MENU_CONFIG_EDITED',
    HAS_MENU_CONFIG_EDITED: GETTERS_PREFIX + 'HAS_MENU_CONFIG_EDITED',

    // Options Menu Permission Config
    IS_OPTIONS_MENU_PERMISSION_CONFIGURABLE: GETTERS_PREFIX + 'IS_OPTIONS_MENU_PERMISSION_CONFIGURABLE',
    OPTIONS_MENU_PERMISSION_CONFIG: GETTERS_PREFIX + 'OPTIONS_MENU_PERMISSION_CONFIG',
    OPTIONS_MENU_PERMISSION_CONFIG_EDITED: GETTERS_PREFIX + 'OPTIONS_MENU_PERMISSION_CONFIG_EDITED',
    HAS_MENU_PERMISSION_CONFIG_EDITED: GETTERS_PREFIX + 'HAS_MENU_PERMISSION_CONFIG_EDITED',
    IS_OPTIONS_MENU_PERMISSION_CONFIG_VISIBLE: GETTERS_PREFIX + 'IS_OPTIONS_MENU_PERMISSION_CONFIG_VISIBLE',

    ALL_OPTIONS_MENU_PERMISSION_CONFIG_ALL_MITARBEITER: GETTERS_PREFIX + 'ALL_OPTIONS_MENU_PERMISSION_CONFIG_ALL_MITARBEITER',
  },
}
