import STECKBRIEF_TYPES from './types';
import CORE_TYPES from '@/store/core/types';
import axios from 'axios';
import { VIEW_ROLES } from '@/router/roles.js'
import { buildMessage } from '@/helpers/log-message-helper';
import LOG_TYPES from '@/store/log/types';

const config = {
  defaultSpinner: true
};

export default {
  async [STECKBRIEF_TYPES.ACTIONS.GET_TODO_VIDEO_INDENT]({ commit, rootState }, payload) {
    await axios.post(rootState.core.apiAddress + '/../mrsvideoident', {}, config).then(response => {
      commit(STECKBRIEF_TYPES.MUTATIONS.GET_TODO_VIDEO_INDENT, response.data);
    }).catch(error => {
      commit(STECKBRIEF_TYPES.MUTATIONS.GET_TODO_VIDEO_INDENT, error.response && error.response.data);
    })
  },

  async [STECKBRIEF_TYPES.ACTIONS.GET_TODO_VIDEO_INDENT_COUNT]({ getters, commit, }) {
    const hasRoles = getters[CORE_TYPES.GETTERS.HAS_ROLES]
    if (!hasRoles([VIEW_ROLES.VIEW_CUSTOMER])) {
      return 
    }

    await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/signandvideoident/countVideoIdent`)
      .then(response => {
        if('data' in response) {
          commit(STECKBRIEF_TYPES.MUTATIONS.SET_TODO_VIDEO_INDENT_COUNT, response.data || 0)
        }
      });
  },

  async [STECKBRIEF_TYPES.ACTIONS.GET_STECKBRIEF_CUSTOMER_INFO]({ commit, rootState }) {
    await axios.get(rootState.core.apiAddress + '/SteckBrief/get', config).then(response => {
      commit(STECKBRIEF_TYPES.MUTATIONS.GET_STECKBRIEF_CUSTOMER_INFO, response.data);
    }).catch(error => {
      commit(STECKBRIEF_TYPES.MUTATIONS.GET_STECKBRIEF_CUSTOMER_INFO, error.response && error.response.data);
    })
  },
  async [STECKBRIEF_TYPES.ACTIONS.GET_DEPOTPOSITIONS]({ commit, rootState }, payload) {
    const depotid = payload || 'ALLE_DEPOTS_ANZEIGEN';
    await axios.get(rootState.core.apiAddress + `/../mrsdepotpositions?depotid=${depotid}&IgnoreVorsorgeParam=true`, config).then(response => {
      commit(STECKBRIEF_TYPES.MUTATIONS.GET_DEPOTPOSITIONS, response.data);
    }).catch(error => {
      commit(STECKBRIEF_TYPES.MUTATIONS.GET_DEPOTPOSITIONS, error.response && error.response.data);
    })
  },
  async [STECKBRIEF_TYPES.ACTIONS.GET_INVESTMENT_BESTAND]({ commit, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };
    const depotid = payload && payload !== 'ALLE_DEPOTS_ANZEIGEN' ? payload : '-1';
    await axios.get(rootState.core.apiAddress + `/../mrsprogressdetailsdata?id=1&depotId=${depotid}`, config).then(response => {
      commit(STECKBRIEF_TYPES.MUTATIONS.GET_INVESTMENT_BESTAND, response.data);
    }).catch(error => {
      commit(STECKBRIEF_TYPES.MUTATIONS.GET_INVESTMENT_BESTAND, error.response && error.response.data);
    })
  },
  [STECKBRIEF_TYPES.ACTIONS.GET_MULTIBANKING_DATA]({ commit, rootState }, payload) {
    axios.get(rootState.core.apiAddress + '/../mrsbanktrasactionsavingspot', config).then(response => {
      commit(STECKBRIEF_TYPES.MUTATIONS.GET_MULTIBANKING_DATA, response.data);
    }).catch(error => {
      commit(STECKBRIEF_TYPES.MUTATIONS.GET_MULTIBANKING_DATA, error.response && error.response.data);
    })
  },
  async [STECKBRIEF_TYPES.ACTIONS.SIMPLY_CHART_GV]({state, commit, rootState }, payload) {
    //if (!state.simplyChartGV || !(state.simplyChartGV.status === 'loading' || state.simplyChartGV.status === payload.user)) {
      commit(STECKBRIEF_TYPES.MUTATIONS.SIMPLY_CHART_GV, {status: 'loading'});
      await axios.get(rootState.core.apiAddress + '/graphicalcourse/get_graf_verlauf?depotId='+payload.id, config).then(response => {
        commit(STECKBRIEF_TYPES.MUTATIONS.SIMPLY_CHART_GV, {...response.data, status: payload.user});
      }).catch(error => {
        commit(STECKBRIEF_TYPES.MUTATIONS.SIMPLY_CHART_GV, {status: 'error'});
      })
    //}
  },

}