<template>
    <div class="box__container-wrap" v-if="hasStornoRoles">
        <div v-if="showTitle" class="box__title">Wichtige Warnungen, Stornos, Informationen </div>
        
        <div class="box__container-rows">
            <Table
                v-if="rows.length"
                :headers="headers"
                :rows="rows"
                :rowsPerPage="MAX_OVERVIEW_COUNT"
                hidePagination
                :mobileConfig="mobileConfig"
                @click-kundenLinkName="openCustomerNewTab"
                @click-beschreibungShort="openLink"
                @action-dokument="openDocument"
                @action-chat="createChat($event.chatData)"
                @action-emailBearbeitender="createEmail($event.emailDataBearbeitender)"
                @action-emailKunde="createEmail($event.emailDataKunde, $event.kundennr, $event.beschreibung)"
                @action-vertrag="openLink"
                @action-vermoegen="openVermoegenNewTab"
                @action-confirm="confirmSelection"
                @action-freigabe="dokumentFreigeben($event, true)"
                @action-tan="comdirectTan($event, true)"
                @action-mailsettings="openMailSettings($event)"
                @action-sign="signPdf($event)">
            
                <template #document="row">
                    <DownloadLink
                        v-if="row.docType"
                        :downloadServicePath="downloadServicePath(row)"
                        :filename="row.fileName"
                        :queryParams="{
                            docId: row.dokId,
                            fileId: row.fileId,
                            stornoId: row.stornoId
                        }"
                    >
                        <component
                            :is="getDocTypeIcon(row.docType)"
                            :size="22"
                        />
                    </DownloadLink>
                </template>
            </Table>
            <GhostLoading v-else-if="loading" type="table" :config="{ table: { rows: MAX_OVERVIEW_COUNT, }, }" />
            <NoData v-else noIcon />
        </div>

        <div v-if="!loading && !$isSmallScreen" class="bottom-row text-right mt-3">
            <router-link :to="{name: 'stornos'}">
                {{ rows.length ? 'weitere...' : 'Übersicht anzeigen' }}
            </router-link>
        </div>

        <BaseModal ref="hatFehler" :showCancelButton="false" labelButtonConfirm="Ok" @onConfirmButton="errorStr=null">
            <template v-slot:modalTitle>
                <ph-warning :size="16" class="mr-2" style="color: orangered"/>
                {{errorStr}}
            </template>
        </BaseModal>
        <BaseModal ref="info" :showCancelButton="false" labelButtonConfirm="Ok">
            <template v-slot:modalTitle>Informationen</template>
            <div class="col mb-2" v-html="sanitize(infoText)"></div>
        </BaseModal>
    </div>
</template>

<script>
import Table from "@/components/table2/Table.vue";
import {TextColumn, SlotColumn, PillColumn, ActionColumn} from "@/components/table2/table_util.js";
import STORNO_WARNUNG_TYPES from '@/store/stornoWarnung/types';
import CORE_TYPES from '@/store/core/types';
import { mapGetters } from 'vuex';
import mixin from '@/mixins/stornos/stornos-mixin.js';
import { BROKER_LEVEL_ROLES, EMPLOYEE_ROLES } from '@/router/roles';
import BaseModal from '@/components/core/BaseModal.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import { PhWarning, PhFilePdf, PhFileXls, PhFileArrowDown } from 'phosphor-vue';
import { MAX_OVERVIEW_COUNT } from '@/store/stornoWarnung/actions.js'
import Pill from "@/components/core/Pill.vue";
import DownloadLink from "../core/download/DownloadLink.vue";
import NoData from '@/components/core/NoData.vue';

export default {
    mixins: [mixin],
    props: {
        showTitle: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        Table,
        BaseModal,
        GhostLoading,
        PhWarning,
        Pill,
        DownloadLink,
        NoData,
    },
    mounted: function () {
        this.getStornoWarnungOverview();
    },
    computed: {
        ...mapGetters({
            stornoWarnung: STORNO_WARNUNG_TYPES.GETTERS.STORNO_WARNUNG_OVERVIEW,
            totalStornoCount: STORNO_WARNUNG_TYPES.GETTERS.TOTAL_COUNT,
            hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
        }),
        hasStornoRoles() {
            return this.hasRoles(EMPLOYEE_ROLES.STORNOS_WARNUNGEN_BESTAETIGEN)
        },
        zugriffKunden() {
            return this.hasRoles([[EMPLOYEE_ROLES.ZUGRIFF_KUNDEN, BROKER_LEVEL_ROLES.ZUGRIFF_KUNDEN]])
        },
        downloadServicePath() {
            return (row) => {
                if (row.docType && row.dokId) {
                    return '/getStornoDocument';
                } else if (row.docType && row.fileId) {
                    return '/get_simple_file';
                }
            }
        },
        headers() {
            const kundenLinkName = TextColumn("kundenLinkName", "Name");
            const beschreibungShort = TextColumn("beschreibungShort", "Beschreibung");
            const status = PillColumn("status", "Status", 80);
            
            if (this.zugriffKunden) {
                kundenLinkName.makeLink();
                beschreibungShort.makeConditionalLink("url");
            }
            
            return {
                lockedLeft: [
                    SlotColumn("document", ""),
                    kundenLinkName.makeAlwaysVisible(),
                ],
                center: [
                    beschreibungShort,
                ],
                lockedRight: [
                    status.makeAlwaysVisible(),
                    ActionColumn("actions", "Aktionen"),
                ],
            };
        },
        rows() {
            if (!this.stornoWarnung)
                return [];
            return this.buildPage(this.stornoWarnung, true);
        },
        mobileConfig() {
            return {
                title: '',
                headers: ['document', 'kundenLinkName', 'beschreibungShort', 'status'],
            }
        }
    },
    data() {
        return {
            loading: false,
            MAX_OVERVIEW_COUNT: MAX_OVERVIEW_COUNT,
        }
    },
    methods: {
        shortend(text) {
            const l = 50;
            return (text?.length > l) ? text?.substring(0, l).concat('...') : text;
        },
        async getStornoWarnungOverview() {
            try {
                this.loading = true;
                await this.$store.dispatch(STORNO_WARNUNG_TYPES.ACTIONS.GET_STORNO_WARNUNG_OVERVIEW);
            } finally {
                this.loading = false;
            }
        },
        confirmSelection(selection) {
            if (selection?.stornoId) {
                this.$store.dispatch(STORNO_WARNUNG_TYPES.ACTIONS.CONFIRM_STORNO_WARNUNG, { stornosToConfirm: [selection?.stornoId], isOverview: true, limit: this.MAX_OVERVIEW_COUNT });
            }
        },
        getDocTypeIcon(docType) {
            switch (docType) {
                case 'pdf':
                    return PhFilePdf
                case 'xls':
                    return PhFileXls
                default:
                    return PhFileArrowDown
            }
        },
    },
}
</script>

<style scoped>


</style>