export const MUTATION_PREFIX = 'GDV_MUTATIONS_';
export const ACTIONS_PREFIX = 'GDV_ACTIONS_';
export const GETTERS_PREFIX = 'GDV_GETTERS_';

export default {
    MUTATIONS: {
        RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
        SAVE_FILTERS: MUTATION_PREFIX + 'SAVE_FILTERS',
    },
    ACTIONS: {
    },
    GETTERS: {
        SAVED_FILTERS: GETTERS_PREFIX + 'SAVED_FILTERS',
    }
}
