<template>
    <div class="mb-4">
      <PersonTopNavigation 
      title="Person"
      :subtitle="fullName"
    />


      <DashboardPanel 
        id="60883d97-2510-42ee-a963-40e2c5a18e15"
        :data="dashboardData"
        :canEdit="isEditable"
        ignoreUserLevelConfig
        :defaultOptionsMenu="customOptionsMenu"
        is3Columns
        :headerActions="headerActions"
        :noPrimaryAction="false"
        @executeAction="handleExecuteAction($event)"
        @action-PDF="navigateTo('/persoenlichedaten/pdf-kundendaten')"
        @action-VCARD="navigateTo('/persoenlichedaten/vcard-kundendaten')"
        @action-COPY="openCopyCustomerModal()"
        @action-COPY-GROUP="openCopyCustomerGroupModal()"
        @action-BRIEF="navigateTo('/communication/mailcomposer-brief')"
        @action-EMAIL="navigateTo('/communication/mailcomposer')"
        @action-TICKET="navigateTo('/communication/tickets/new-ticket')"
        @action-ACTIVITY="navigateTo('/communication/postfach/aktivitaeten')"
        @action-HOBBYS-VORLIEBEN="openStep('hobbys-vorlieben')"
        @action-COURTAGE="openStep('courtage')"
      >
      
          <template #title>
            {{fullname}}
          </template>
          <template #subtitle>
            <template v-if="userIdUnterNr">
              <span class="subtitle-part">{{userIdUnterNr}}</span>
            </template>
          </template>    
  
      </DashboardPanel>
  

      <BaseModal ref="copyCustomerModal" :showDefaultButtons="false">
      <template #modalTitle>Kunde kopieren</template>
      <template #default>
        Soll der Kunde unter einer weiteren Kundennummer angelegt werden?
      </template>
      <template #footer>
        <BaseButton class="mr-16px" isSecondary @click="closeCopyCustomerModal()" :disabled="loading">Nein</BaseButton>
        <BaseButton @click="copyCustomer()" :disabled="loading">
          Ja <AnimatedSpinner v-if="loading" />
        </BaseButton>
      </template>
    </BaseModal>

    <BaseModal ref="copyCustomerGroupModal" :showDefaultButtons="false">
      <template #modalTitle>Gruppenoberhaupt erzeugen</template>
      <template #default>
        Soll der Kunde unter einer weiteren Kundennummer als Kundengruppe nochmal angelegt werden?
      </template>
      <template #footer>
        <BaseButton class="mr-16px" isSecondary @click="closeCopyCustomerGroupModal()" :disabled="loading">Nein</BaseButton>
        <BaseButton @click="copyCustomerGroup()" :disabled="loading">
          Ja <AnimatedSpinner v-if="loading" />
        </BaseButton>
      </template>
    </BaseModal>  
    
    
      <BaseModal
        ref="editModal"
        :modalTitle="modalTitle"
        size="lg"
        :showCancelButton="false"
        @onCloseButton="saveChanges()"
        :showConfirmButton="false">
        

        <Stammdaten v-if="editComponent=='stammdaten'" :substepProperty="substepProperty"></Stammdaten>
        <Kommunikationsdaten  v-if="editComponent=='kommunikationsdaten'"></Kommunikationsdaten>
        <Arbeitgeber v-if="editComponent=='arbeitgeber'"></Arbeitgeber>
        <SchulbildungUndQualifikationen v-if="editComponent=='schulbildung-und-qualifikationen'"></SchulbildungUndQualifikationen>
        <PolitischExponiertePerson v-if="editComponent=='pep'"></PolitischExponiertePerson>
      </BaseModal>  
    </div>
  </template>
  
  <script>
  import CORE_TYPES from '@/store/core/types';
  import { mapGetters } from 'vuex'
  import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
  import DashboardPanel from '@/components/dashboard/DashboardPanel.vue';
  import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
  import { VIEW_ROLES } from '@/router/roles.js'
  import MailContactsModal from '@/views/versicherungen/MailContactsModal.vue'
  import AufgabeBearbeitenModal from '@/views/versicherungen/AufgabeBearbeitenModal.vue'
  import CopyInsuranceConfirmationModal from '@/views/versicherungen/CopyInsuranceConfirmationModal.vue'
  import AddDocument from '@/views/versicherungen//AddDocument.vue';
  import CUSTOMERDATA_TYPES from '@/store/customerData/types';
  import persoenlicheDatenMixin from '../persoenliche-daten-mixin'
  import BaseModal from '@/components/core/BaseModal.vue';
  import BaseButton from '@/components/core/BaseButton.vue';
  import Stammdaten from '@/components/persoenliche-daten/Stammdaten.vue';
  import Kommunikationsdaten from '@/components/persoenliche-daten/Kommunikationsdaten.vue'
  import Arbeitgeber from '@/components/persoenliche-daten/Arbeitgeber.vue';
  import SchulbildungUndQualifikationen from '@/components/persoenliche-daten/SchulbildungUndQualifikationen.vue';
  import PolitischExponiertePerson from '@/components/persoenliche-daten/PolitischExponiertePerson.vue';
  import { RELATION_BEKANNT } from '@/components/persoenliche-daten/zusaetzliche-personen/PersonBeziehungFields.vue';
  import PersonTopNavigation from '@/components/persoenliche-daten/components/PersonTopNavigation.vue';


  export default {
    props: {
    },
    
    mixins: [persoenlicheDatenMixin, InteractiveHelpCommonsMixin],
    validators: {},
  
    data: function () {
  
      return {
        showAdditional: false,
        tableData: {
          headers: {},
          records: []
        },
        selectedRowsValues: [],
        editComponent: null,
        substepProperty: null,
        modalTitle: null,
      }
    },
    watch: {
      personIdParam: {
        handler() {
          this.init();
        },
        immediate: true,
      },
    },    
    computed: {
      ...mapGetters({
        isFA: CORE_TYPES.GETTERS.IS_FA,
        hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
        isCustomer: CORE_TYPES.GETTERS.IS_CUSTOMER,
        isCustomerOnly: CORE_TYPES.GETTERS.IS_CUSTOMER_ONLY,
        customerData: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_DATA,
        customerDataConfig: CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA_CONFIG,
        fullname: CORE_TYPES.GETTERS.GET_USER_FULL_NAME,
        unternr: CORE_TYPES.GETTERS.GET_USER_UNTER_NR,
        userid: CORE_TYPES.GETTERS.GET_USER_ID,
        isFirma: CUSTOMERDATA_TYPES.GETTERS.IS_FIRMA,
      }),
      userIdUnterNr() {
        return this.unternr ? `${this.userid}-${this.unternr}` : this.userid
      },
      isViewCustomerAsBypass() {
        return this.hasRoles([VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS]);
      },
      steps() {
        const steps = this.getStepsBegin.concat(this.additionalSteps).concat(this.dynamicSteps).concat(this.getStepsEnd);
        return this.isDynamicStepsAvailable ? steps : this.getSteps;
      },
      isDynamicStepsAvailable() {
        return this.isOriginalUserBroker || this.isOriginalUserIntern;
      },
      dynamicSteps() {
        return (this.versicherungenDetails?.steps || []).map(step => Object.assign(step, { hidden: !this.hasSparte(this.getSpartenData, step) }));
      },
      additionalSteps() {
        return this.versicherungenDetails?.additionalSteps || [];
      },
      hasInfo() {
        return !!this.info?.customerID;
      },
      personIdParam() {
        return this.$route?.params?.personId;
      },
      dashboardData() {
        const data = {
          widgets: [
            {
              name: 'stammdaten',
              title: 'Stammdaten',
              component: () => import('@/components/persoenliche-daten/cards/Stammdaten.vue'),
              loading: () => false,
              actions: [
                {
                  tableContainer: 'stammdaten',
                  legend: {
                    icon: 'PhList',
                    key: 'EDIT_COLUMNS',
                    label: 'Zeilen anpassen',
                  },
                  visible: () => true,
                },
              ],
              overrideDefaultAction: false,
              isNavigable: this.isEditable,
            },
            {
              name: 'adresse',
              title: 'Adresse',
              component: () => import('@/components/persoenliche-daten/cards/Adresse.vue'),
              loading: () => false,
              mainStep: 'stammdaten',
              subStep: 'adresse',
              actions: [
                {
                  legend: {
                    icon: 'PhList',
                    key: 'EDIT_COLUMNS',
                    label: 'Zeilen anpassen',
                  },
                  visible: () => true,
                },
              ],
              overrideDefaultAction: false,
              isNavigable: this.isEditable,              
            },         
            {
              name: 'kommunikationsdaten',
              title: 'Kommunikationsdaten',
              component: () => import('@/components/persoenliche-daten/cards/Kommunikationsdaten.vue'),
              loading: () => false,
              overrideDefaultAction: false,
              isNavigable: this.isEditable,               
            }, 
  
          ],
        };

        if (this.hasRoles([VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS])) {
          data.widgets.push(
            {
              name: 'arbeitgeber',
              title: 'Arbeitgeber',
              component: () => import('@/components/persoenliche-daten/cards/Arbeitgeber.vue'),
              loading: () => false,
              actions: [
                {
                  legend: {
                    icon: 'PhList',
                    key: 'EDIT_COLUMNS',
                    label: 'Zeilen anpassen',
                  },
                  visible: () => true,
                },
              ],
              overrideDefaultAction: false,
              isNavigable: this.isEditable, 
            }, 

          );
          
        }
  
        if (!this.isFirma) {

          if (this.isByPass) {

            data.widgets.push(
              {
                name: 'versandadresse',
                title: 'Versandadresse',
                component: () => import('@/components/persoenliche-daten/cards/Versandadresse.vue'),
                loading: () => false,
                mainStep: 'stammdaten',
                subStep: 'versandadresse',
                actions: [
                  {
                    legend: {
                      icon: 'PhList',
                      key: 'EDIT_COLUMNS',
                      label: 'Zeilen anpassen',
                    },
                    visible: () => true,
                  },
                ],
                overrideDefaultAction: false,
                isNavigable: this.isEditable,                 
              },          

            );            

            data.widgets.push(
                {
                  name: 'anschriftfeld',
                  title: 'Anschriftfeld',
                  component: () => import('@/components/persoenliche-daten/cards/Anschriftfeld.vue'),
                  loading: () => false,
                  mainStep: 'stammdaten',
                  subStep: 'anschriftfeld',
                  actions: [
                    {
                      legend: {
                        icon: 'PhList',
                        key: 'EDIT_COLUMNS',
                        label: 'Zeilen anpassen',
                      },
                      visible: () => true,
                    },
                  ],
                  overrideDefaultAction: false,
                  isNavigable: this.isEditable,                   
                },          
  
            );   
	        }  

          data.widgets.push(

              {
                name: 'geburtstag',
                title: 'Geburtstag',
                component: () => import('@/components/persoenliche-daten/cards/Geburtstag.vue'),
                loading: () => false,
                mainStep: 'stammdaten',
                subStep: 'geburtstag',
                actions: [
                  {
                    legend: {
                      icon: 'PhList',
                      key: 'EDIT_COLUMNS',
                      label: 'Zeilen anpassen',
                    },
                    visible: () => true,
                  },
                ],
                overrideDefaultAction: false,
                isNavigable: this.isEditable,                 
              },          

          );        
          
          data.widgets.push(

              {
                name: 'beruf',
                title: 'Beruf',
                component: () => import('@/components/persoenliche-daten/cards/PrivatBeruf.vue'),
                loading: () => false,
                mainStep: 'stammdaten',
                subStep: 'beruf',
                actions: [
                  {
                    legend: {
                      icon: 'PhList',
                      key: 'EDIT_COLUMNS',
                      label: 'Zeilen anpassen',
                    },
                    visible: () => true,
                  },
                ],
                overrideDefaultAction: false,
                isNavigable: this.isEditable,                 
              },          

          );          
          
          data.widgets.push(

              {
                name: 'steuerdaten',
                title: 'Steuerdaten',
                component: () => import('@/components/persoenliche-daten/cards/PrivatSteuerdaten.vue'),
                loading: () => false,
                mainStep: 'stammdaten',
                subStep: 'steuerdaten',
                actions: [
                  {
                    legend: {
                      icon: 'PhList',
                      key: 'EDIT_COLUMNS',
                      label: 'Zeilen anpassen',
                    },
                    visible: () => true,
                  },
                ],
                overrideDefaultAction: false,
                isNavigable: this.isEditable,                 
              },          

          );               
          
          
        } 

        if (this.isFirma) {

          data.widgets.push(

              {
                name: 'geburtstag',
                title: 'Geburtstag',
                component: () => import('@/components/persoenliche-daten/cards/GeburtstagFirma.vue'),
                loading: () => false,
                mainStep: 'stammdaten',
                subStep: 'geburtstag',
                actions: [
                  {
                    legend: {
                      icon: 'PhList',
                      key: 'EDIT_COLUMNS',
                      label: 'Zeilen anpassen',
                    },
                    visible: () => true,
                  },
                ],
                overrideDefaultAction: false,
                isNavigable: this.isEditable,                 
              },          

          );    
          
          data.widgets.push(

              {
                name: 'steuerdaten',
                title: 'Steuerdaten',
                component: () => import('@/components/persoenliche-daten/cards/FirmaSteuerdaten.vue'),
                loading: () => false,
                mainStep: 'stammdaten',
                subStep: 'steuerdaten',
                actions: [
                  {
                    legend: {
                      icon: 'PhList',
                      key: 'EDIT_COLUMNS',
                      label: 'Zeilen anpassen',
                    },
                    visible: () => true,
                  },
                ],
                overrideDefaultAction: false,
                isNavigable: this.isEditable,                 
              },          

          );             
          
          
        }        

        data.widgets.push(

            {
              name: 'legitimationsurkunde',
              title: 'Legitimationsurkunde',
              component: () => import('@/components/persoenliche-daten/cards/Legitimationsurkunde.vue'),
              loading: () => false,
              mainStep: 'stammdaten',
              subStep: 'legitimationsurkunde',
              actions: [
                {
                  legend: {
                    icon: 'PhList',
                    key: 'EDIT_COLUMNS',
                    label: 'Zeilen anpassen',
                  },
                  visible: () => true,
                },
              ],
            },          

        );       
        
        if (this.isViewCustomerAsBypass) {
          data.widgets.push(

            {
              name: 'schulbildung-und-qualifikationen',
              title: 'Schulbildung und Qualifikationen',
              component: () => import('@/components/persoenliche-daten/cards/SchulbildungUndQualifikationen.vue'),
              loading: () => false,
              actions: [
                {
                  legend: {
                    icon: 'PhList',
                    key: 'EDIT_COLUMNS',
                    label: 'Zeilen anpassen',
                  },
                  visible: () => true,
                },
              ],
            },          

          );     
          
          data.widgets.push(

              {
                name: 'pep',
                title: 'PEP / FATCA',
                component: () => import('@/components/persoenliche-daten/cards/PolitischExponiertePerson.vue'),
                loading: () => false,
                actions: [
                  {
                    legend: {
                      icon: 'PhList',
                      key: 'EDIT_COLUMNS',
                      label: 'Zeilen anpassen',
                    },
                    visible: () => true,
                  },
                ],
              },          

            );  
        }
          
          
  
        return data;
      },
      customOptionsMenu() {
        return [];
      },
   
    },
    mounted: async function () {
      if (this.$route.query?.isBrokerContact) {
        const payload = {
            personId: this.personIdParam,
            personType: 'Kontakt',
          }

        this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.ADD_CUSTOMER_DATA_EDITED, payload);
      }      
    },
  
    async beforeRouteLeave (to, from, next) {
      await this.saveChanges();

      this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.RESET_HINTS);
      if(to?.path?.indexOf('customer-data') < 0) {
        this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.RESET_STATE);
      }

      next();
    },
  
    methods: {
      navigateTo(event) {
        this.$router.push({ path: `${event}` });
      },
      async retrieveAllData() {
        const promiseToWait = this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_CUSTOMER_DATA, {newCustomer: this.$route.query.newCustomer});
        this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_CUSTOMER_DATA_CONFIG),
        this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_CUSTOMER_DATA_ADDITIONAL_PERSONS, { list: true });
        await promiseToWait;
      },
      handleExecuteAction(actionData) {
          if (actionData.action?.legend?.key === "EDIT_COLUMNS") {
            if (actionData?.component?.$refs.table) {
              actionData?.component?.$refs.table.openColumnsConfig();
            } else if (actionData.action?.tableContainer) {
              actionData.component.$refs[actionData.action?.tableContainer].$refs.table.openColumnsConfig();
            }
          } else {

            if (actionData.action?.action === "BEARBEITEN_APP") {
              this.editComponent = actionData?.widget?.mainStep || actionData?.widget?.name;

              if (actionData?.widget?.mainStep) {
                this.substepProperty = actionData?.widget?.name;
              } else {
                this.substepProperty = null;
              }

              if (this.isEditable) {
                this.modalTitle = actionData?.widget?.title;
                this.$refs.editModal.open();
              }

            } else {
              if (actionData.widget?.mainStep) {
                let url = `/persoenlichedaten/customer-data/steps/${actionData?.widget?.mainStep}`;
  
                if (actionData?.widget?.subStep) {
                  url = `${url}?substep=${actionData?.widget?.subStep}`;
                }
  
                this.$router.push(url);
              } else {
                this.$router.push(`/persoenlichedaten/customer-data/steps/${actionData?.widget?.name}`);
              }

            }
              
          }
      },  
      confirmContactsToSend(selectedRecipients) {
        this.selectedRowsValues = selectedRecipients;
        this.direktInDieMailSpringen();
      },    
      async init() {
        this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.UPDATE_SELECTED_CUSTOMER_DATA, this.personIdParam);
        this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.RESET_CUSTOMER_DATA_EDITED);
        this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_CUSTOMER_DATA_CONFIG)
        if(this.personIdParam === 'person1') {
          await this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_CUSTOMER_DATA);
        } else if(this.personIdParam !== 'neue_person') {
          await this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_SELECTED_PERSON_DATA);
        } else if(this.personIdParam === 'neue_person') {
          this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_CUSTOMER_DATA);
          const payload = {
            personId: this.personIdParam,
            relationship: {
              personId: this.personIdParam,
              relation: { 
                value: this.$route.query?.relation || RELATION_BEKANNT,
              },
            },
          };
          this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.ADD_CUSTOMER_DATA_EDITED, payload);
        }
        if(this.isMainPerson) {
          this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_CUSTOMER_DATA_ADDITIONAL_PERSONS, { list: true });
        }
      },      
    },
  
    components: {
      OptionMenu,
      DashboardPanel,
      MailContactsModal,
      AufgabeBearbeitenModal,
      CopyInsuranceConfirmationModal,
      AddDocument,
      BaseModal,
      BaseButton,
      Stammdaten,
      Kommunikationsdaten,
      Arbeitgeber,
      SchulbildungUndQualifikationen,
      PolitischExponiertePerson,
      PersonTopNavigation,
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .subtitle-part {
    word-break: break-word;
  }
  </style>
  
  <!-- <style src='./InsuranceOverview.scss' lang='scss' scoped></style> -->
  