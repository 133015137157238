var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ticket__container", class: { "ticket--new": !_vm.chatId } },
    [
      _vm.emailId && !_vm.isSomeActionLoading
        ? _c("div", { staticClass: "box__container chat-email-header" }, [
            _c("div", { staticClass: "box__title" }, [
              _c(
                "span",
                { staticClass: "text-right", staticStyle: { width: "5rem" } },
                [_vm._v("Betreff")]
              ),
              _vm._v(" " + _vm._s(_vm.chatInfo ? _vm.chatInfo.thema : "") + " ")
            ]),
            _vm.chatInfo
              ? _c("div", [
                  _c("div", { staticClass: "flex gap-2" }, [
                    _c("div", { staticClass: "text-right w-4" }, [
                      _vm._v("Von")
                    ]),
                    _vm._v(" " + _vm._s(_vm.chatInfo.senderName) + " ")
                  ]),
                  _vm.chatInfo.receiverNames.length > 1 &&
                  _vm.chatInfo.receiverIds
                    ? _c(
                        "div",
                        { staticClass: "flex gap-2" },
                        [
                          _c("div", { staticClass: "text-right w-4" }, [
                            _vm._v("An")
                          ]),
                          _vm._l(_vm.chatInfo.receiverNames, function(
                            receiver,
                            index
                          ) {
                            return _c("div", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.receiverEnabled[
                                        _vm.chatInfo.receiverIds[index]
                                      ],
                                    expression:
                                      "receiverEnabled[chatInfo.receiverIds[index]]"
                                  }
                                ],
                                attrs: { type: "checkbox" },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.receiverEnabled[
                                      _vm.chatInfo.receiverIds[index]
                                    ]
                                  )
                                    ? _vm._i(
                                        _vm.receiverEnabled[
                                          _vm.chatInfo.receiverIds[index]
                                        ],
                                        null
                                      ) > -1
                                    : _vm.receiverEnabled[
                                        _vm.chatInfo.receiverIds[index]
                                      ]
                                },
                                on: {
                                  change: [
                                    function($event) {
                                      var $$a =
                                          _vm.receiverEnabled[
                                            _vm.chatInfo.receiverIds[index]
                                          ],
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.receiverEnabled,
                                              _vm.chatInfo.receiverIds[index],
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.receiverEnabled,
                                              _vm.chatInfo.receiverIds[index],
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.receiverEnabled,
                                          _vm.chatInfo.receiverIds[index],
                                          $$c
                                        )
                                      }
                                    },
                                    function($event) {
                                      return _vm.isFormInvalid()
                                    }
                                  ]
                                }
                              }),
                              _vm._v(" " + _vm._s(receiver) + " ")
                            ])
                          })
                        ],
                        2
                      )
                    : _c("div", { staticClass: "flex gap-2" }, [
                        _c("div", { staticClass: "text-right w-4" }, [
                          _vm._v("An")
                        ]),
                        _vm._v(" " + _vm._s(_vm.chatInfo.receiverName) + " ")
                      ]),
                  (_vm.chatInfo.receiverNames &&
                    _vm.chatInfo.receiverNames.length) > 1 &&
                  (!_vm.chatInfo.receiverEnabled ||
                    _vm.chatInfo.receiverEnabled.length == 0)
                    ? _c("div", [
                        _vm._v(
                          " Es muss mindestens ein Empfänger ausgewählt werden. Werden mehrere Empfänger gewählt, wird ein Chat pro Empfänger geöffnet. "
                        )
                      ])
                    : _vm._e()
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "ticket__content" },
        [
          _vm.showBewertung && _vm.isIntern
            ? _c("TicketBewertung", {
                attrs: {
                  value: _vm.bewertung,
                  centered: "",
                  disabled: _vm.bewertungReadonly || _vm.isBewertungSaving
                },
                on: {
                  input: function($event) {
                    return _vm.setBewertung($event)
                  }
                }
              })
            : _vm._e(),
          _vm.isSomeActionLoading
            ? _c(
                "div",
                [
                  !_vm.chatId
                    ? _c("div", { staticClass: "box__container" }, [
                        _c("span", { staticClass: "box__title" }, [
                          _vm._v(_vm._s(_vm.title))
                        ]),
                        _c(
                          "div",
                          { staticClass: "mt-1" },
                          [
                            _c("GhostLoading", { attrs: { type: "input" } }),
                            _c("GhostLoading", { attrs: { type: "input" } })
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _c(
                    "GhostLoading",
                    {
                      staticClass: "ticket__main-content",
                      attrs: { type: "custom", useBoxContainer: "" }
                    },
                    [_c("Block", { attrs: { width: "100%", height: "100%" } })],
                    1
                  )
                ],
                1
              )
            : _vm.chatInfo
            ? _c(
                "DragnDropArea",
                {
                  staticClass: "ticket__main-content",
                  attrs: {
                    tid: _vm._generateTidFromString("ticket__main-content"),
                    disabled:
                      (_vm.chatInfo && _vm.chatInfo.readonly) ||
                      _vm.beteiligterId == null ||
                      _vm.chatId == 0 ||
                      !_vm.chatInputEnabled,
                    hoverText: "Datei senden"
                  },
                  on: { files: _vm.onUploadChat }
                },
                [
                  !_vm.chatId && !_vm.embedded
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "ticket--chat-options__container box__container",
                          attrs: {
                            tid: _vm._generateTidFromString(
                              "box__container_chat"
                            )
                          }
                        },
                        [
                          _c("span", { staticClass: "box__title" }, [
                            _vm._v(_vm._s(_vm.title))
                          ]),
                          _c(
                            "div",
                            { staticClass: "ticket--chat-options" },
                            [
                              _c("ShortcutInputField", {
                                attrs: {
                                  label: "Thema",
                                  type: "text",
                                  maxLength: 128
                                },
                                model: {
                                  value: _vm.themaComputedWithDefault,
                                  callback: function($$v) {
                                    _vm.themaComputedWithDefault = $$v
                                  },
                                  expression: "themaComputedWithDefault"
                                }
                              }),
                              !_vm.showSimplifiedChatpartner
                                ? _c("ComboBox", {
                                    attrs: {
                                      label: "Chatpartner",
                                      value: _vm.chatPartner,
                                      values: _vm.chatPartnerOptions
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.changeChatPartner($event)
                                      }
                                    }
                                  })
                                : _vm._e(),
                              _vm.bearbeiterOptions &&
                              _vm.bearbeiterOptions.length &&
                              !_vm.isChatpartnerReceiverKunde
                                ? _c("ComboBox", {
                                    attrs: {
                                      label: _vm.showSimplifiedChatpartner
                                        ? "Chatpartner"
                                        : "Bearbeiter",
                                      values: _vm.bearbeiterOptions
                                    },
                                    model: {
                                      value: _vm.bearbeiter,
                                      callback: function($$v) {
                                        _vm.bearbeiter = $$v
                                      },
                                      expression: "bearbeiter"
                                    }
                                  })
                                : _vm._e(),
                              _vm.isFA &&
                              _vm.chatPartner &&
                              _vm.chatPartner.includes("LEVEL+INTERN-10000")
                                ? _c("ComboBox", {
                                    attrs: {
                                      label: "FMA-Beschwerde",
                                      values: _vm.fmaBeschwerdeOptions
                                    },
                                    model: {
                                      value: _vm.fmaBeschwerde,
                                      callback: function($$v) {
                                        _vm.fmaBeschwerde = $$v
                                      },
                                      expression: "fmaBeschwerde"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      )
                    : _c(
                        "div",
                        {
                          staticClass:
                            "ticket--chat-messages__container box__container text-selectable"
                        },
                        [
                          _c("ChatMessages", {
                            staticClass: "ticket--chat-messages",
                            attrs: {
                              chatId: _vm.chatInfo.chatId,
                              channelId: _vm.chatInfo.channelId,
                              emailId: +_vm.emailId,
                              beteiligterId: _vm.beteiligterId
                            }
                          })
                        ],
                        1
                      ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "ticket--chat-footer__container box__container m-0"
                    },
                    [
                      !_vm.closed && _vm.chatInputEnabled
                        ? _c("ChatFooter", {
                            ref: "chatFooter",
                            staticClass: "ticket--chat-footer",
                            attrs: {
                              chatId: _vm.chatInfo.chatId,
                              channelId: _vm.chatInfo.channelId,
                              emailId: +_vm.emailId,
                              beteiligterId: _vm.beteiligterId,
                              disabled: _vm.formInvalid,
                              thema: _vm.themaComputedWithDefault,
                              bezugId: _vm.bezugId,
                              initialText: _vm.textInput,
                              richTextInit: _vm.richText,
                              embedded: _vm.embedded,
                              fmaBeschwerde: _vm.fmaBeschwerde
                            },
                            on: {
                              changeChannel: _vm.changeChannel,
                              typing: function($event) {
                                return _vm.calculateChatFooterSize(
                                  !_vm.$isVirtualKeyboardDetected
                                )
                              },
                              isEditorValid: _vm.setEditorValid
                            }
                          })
                        : _vm._e(),
                      !_vm.chatInputEnabled
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "ticket--chat-footer__container fc-alert fc-alert-danger text-centered text-small m-0"
                            },
                            [
                              _vm._v(
                                "Um zu Chatten, muss Kontakt per App erlaubt werden."
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "bearbeiterModal",
          attrs: {
            modalTitle: "Bearbeiter anpassen",
            showDefaultButtons: false,
            actions: _vm.bearbeiterModalActions
          },
          on: {
            close: _vm.closeBearbeiterMenu,
            "action-SAVE": _vm.saveBearbeiterMenu,
            "action-BECOME_BEARBEITER": _vm.becomeBearbeiter
          }
        },
        [
          _vm.bearbeiterModalData
            ? [
                _c("ComboBox", {
                  attrs: { values: _vm.bearbeiterChangeOptions },
                  on: {
                    change: function($event) {
                      return _vm.loadPhoneOptions($event)
                    }
                  },
                  model: {
                    value: _vm.bearbeiterModalData.bearbeiter,
                    callback: function($$v) {
                      _vm.$set(_vm.bearbeiterModalData, "bearbeiter", $$v)
                    },
                    expression: "bearbeiterModalData.bearbeiter"
                  }
                }),
                _vm.bearbeiterPhoneOptions
                  ? _c("ComboBox", {
                      attrs: { values: _vm.bearbeiterPhoneOptions },
                      model: {
                        value: _vm.bearbeiterModalData.phone,
                        callback: function($$v) {
                          _vm.$set(_vm.bearbeiterModalData, "phone", $$v)
                        },
                        expression: "bearbeiterModalData.phone"
                      }
                    })
                  : _vm._e(),
                _vm.bearbeiterPhoneOptions &&
                _vm.bearbeiterModalData.phone == _vm.PHONE_EMPTY
                  ? _c("InputField", {
                      attrs: { type: "text" },
                      model: {
                        value: _vm.bearbeiterModalData.phoneManual,
                        callback: function($$v) {
                          _vm.$set(_vm.bearbeiterModalData, "phoneManual", $$v)
                        },
                        expression: "bearbeiterModalData.phoneManual"
                      }
                    })
                  : _vm._e()
              ]
            : _vm._e()
        ],
        2
      ),
      _c(
        "BaseModal",
        {
          ref: "changeStatusModal",
          attrs: {
            modalTitle: "Status anpassen",
            showDefaultButtons: false,
            actions: _vm.changeStatusModalActions
          },
          on: {
            close: _vm.closeChangeStatusModal,
            "action-SAVE": _vm.saveChangeStatusModal
          }
        },
        [
          _vm.changeStatusModalData
            ? [
                _c("ComboBox", {
                  attrs: { values: _vm.changeStatusOptions },
                  model: {
                    value: _vm.changeStatusModalData.newTicketStatus,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.changeStatusModalData,
                        "newTicketStatus",
                        $$v
                      )
                    },
                    expression: "changeStatusModalData.newTicketStatus"
                  }
                })
              ]
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }