<template>
  <div>
    <div v-for="(field, fieldId, index) in filteredForm" :key="index" >
      <div class="col-12 font-bold" v-if="field.type === 'LABEL'">
        <form-label :label="field.label" />
      </div>

      <InputField v-if="field.type === 'TEXT_CURRENCY'" class="mt-2"
        :label="field.label"
        v-model="field.value"
        type="currency"
        :precision="2"
        isComponentHalfSize
        :disabled="disabled"
        @change="onChange({value: $event, field})"
      />

      <InputField v-if="field.type === 'TEXT_PROZENT'" class="mt-2"
        :label="field.label"
        v-model="field.value"
        type="percent"
        :precision="4"
        isComponentHalfSize
        :disabled="disabled"
        @change="onChange({value: $event, field})"
      />

      <InputField v-if="field.type === 'TEXT_NUMBER'" class="mt-2"
        :label="field.label"
        v-model="field.value"
        type="number"
        :precision="2"
        isComponentHalfSize
        :disabled="disabled"
        @change="onChange({value: $event, field})"
      />

      <InputRadioBoxGroup v-if="field.type === 'SINGLE_SELECTION'" class="mt-2"
        :label="field.label"
        v-model="field.value"
        :values="field.values"
        isComponentHalfSize
        :disabled="disabled"
        :vertical="false"
        @input="onChange({value: $event, field})"
      />                
    </div>

    <div class="cards__container">
      <div v-if="rows && rows.length" class="cards__items">
        <div class="box__container cards__item" v-for="(pos, index) in rows" :key="pos.isin + index">
          <div class="cards__item--header">
            <div class="box__title cards__item--header-title">
              <span><a @click="navigateToFondsInfo(pos)">{{pos && pos.wertpapier}}</a></span>
              <span class="text-divider">|</span>
                <span :class="getColorClass(pos)">{{getOrderTyp(pos)}}</span>
              <span class="text-divider">|</span>
              <span>{{pos && pos.betrag}}</span>
            </div>

            <div class="cards__item--header-actions">
              <a @click="deletePositionConfirmation(pos)" title="Position löschen">
                <span class="cards__item--header-action--item-icon"><PhTrash :size="16" /></span>
                <span class="cards__item--header-action--item-text">Position löschen</span>
              </a>
            </div>
          </div>

          <div class="row" v-if="pos">
            <div class="font-bold col-lg-4 col-12">ISIN: {{pos.isin}}</div>
            <div class="font-bold col-lg-4 col-12">Antrag: {{pos.antrag}}</div>
            <div class="font-bold col-lg-4 col-12">Perf. Fee: {{pos.perffee}}%</div>
          </div>
          <div class="row" v-if="pos">
            <div class="font-bold col-lg-4 col-12">lauf. Kosten: {{pos.laufk}}% p.a.</div>
            <div class="font-bold col-lg-4 col-12">davon Anteil WPD {{pos.anteilwpd}}%</div>
            <div class="font-bold col-lg-4 col-12">Bepro: {{pos.bepro}}%</div>
          </div>

          <InputField v-if="form['input_wpa_entry_aa'+pos.id]"
            type="percent" 
            v-model="form['input_wpa_entry_aa'+pos.id].value"
            label="Ausgabeaufschlag"
            :id="'input_wpa_entry_aa'+pos.id" 
            :disabled="disabled || form['input_wpa_entry_aa'+pos.id].disabled"
            isComponentHalfSize
            @input="onChange"
          />
          <div class="row">
            <InputField v-if="form['input_wpa_entry_transaktion_kauf_wert'+pos.id]"
              class="col-12 col-lg-auto forms__input--half-size"
              type="number" 
              :label="transaktionskostenLabel(pos)"
              v-model="form['input_wpa_entry_transaktion_kauf_wert'+pos.id].value" 
              :id="'input_wpa_entry_transaktion_kauf_wert'+pos.id" 
              :disabled="disabled || form['input_wpa_entry_transaktion_kauf_wert'+pos.id].disabled"
              @input="onChange"
            />

            <InputRadioBoxGroup v-if="form['input_wpa_entry_transaktion_kauf_rg_proz_eur'+pos.id]"
              class="col-auto radio-row"
              v-model="form['input_wpa_entry_transaktion_kauf_rg_proz_eur'+pos.id].value"
              :values="form['input_wpa_entry_transaktion_kauf_rg_proz_eur'+pos.id].values"
              :disabled="disabled || form['input_wpa_entry_transaktion_kauf_rg_proz_eur'+pos.id].disabled"
              :vertical="false"
              @input="onChange"
            /> 
          </div>
          <div class="row">            
            <InputField v-if="form['input_wpa_entry_transaktion_verkauf_wert'+pos.id]"
              class="col-12 col-lg-auto forms__input--half-size"
              type="number" 
              label="Transaktionskosten Verkauf"
              v-model="form['input_wpa_entry_transaktion_verkauf_wert'+pos.id].value"
              :id="'input_wpa_entry_transaktion_verkauf_wert'+pos.id" 
              :disabled="disabled || form['input_wpa_entry_transaktion_verkauf_wert'+pos.id].disabled"
              @input="onChange"
            />                  

            <InputRadioBoxGroup v-if="form['input_wpa_entry_transaktion_verkauf_rg_proz_eur'+pos.id]"
              class="col-auto radio-row"
              v-model="form['input_wpa_entry_transaktion_verkauf_rg_proz_eur'+pos.id].value"
              :values="form['input_wpa_entry_transaktion_verkauf_rg_proz_eur'+pos.id].values"
              :disabled="disabled || form['input_wpa_entry_transaktion_verkauf_rg_proz_eur'+pos.id].disabled"
              :vertical="false"
              @input="onChange"
            />
          </div>
          <InputField v-if="form['input_wpa_entry_sparplan_raten'+pos.id]"
              class="col-12 col-lg-auto forms__input--half-size"
              type="number" 
              :precision="0"
              label="Anzahl Raten"
              v-model="form['input_wpa_entry_sparplan_raten'+pos.id].value"
              :id="'input_wpa_entry_sparplan_raten'+pos.id" 
              :disabled="disabled || form['input_wpa_entry_sparplan_raten'+pos.id].disabled"
              @change="onChange"
            />   
        </div>
      </div>
    </div>

    <BaseModal @onConfirmButton="deletePosition()" ref="deletePositionConfirmation" modalTitle="Position löschen" labelButtonConfirm="Löschen">
      {{ deletePositionMessage }}
    </BaseModal>    
</div>

</template>

<script>
import InputField from "@/components/core/forms/InputField.vue";
import Table from "@/components/table2/Table.vue";
import { ActionColumn, CurrencyColumn, PercentageColumn, SlotColumn, TextColumn } from '@/components/table2/table_util';
import { PhTrash, PhPencilLine } from 'phosphor-vue';
import BaseModal from '@/components/core/BaseModal.vue';
import BERATUNGSMAPPE_TYPES from "@/store/beratungsmappe/types";
import { mapGetters } from "vuex";
import FormLabel from "@/components/core/forms/FormLabel";
import InputRadioBoxGroup from "@/components/core/forms/radiobox/InputRadioBoxGroup.vue";
import mixin from '@/mixins/wertpapierorder/mixin.js';

export default {
  mixins: [mixin],
  props: {
    id: {
      type: String,
    },
    label: {
      type: String,
    },
    config: {
      type: Object,
    },
    antragData: {
      type: Object,
    },
    beratungsmappeId: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    InputField,
    Table,
    PhPencilLine,
    BaseModal,
    FormLabel,
    InputRadioBoxGroup,
    PhTrash,
  },
  data() {
    return {
        form: {},
        headers: {
            lockedLeft: [
                TextColumn("wertpapier", "Wertpapier"),
            ],
            center: [
                SlotColumn("INPUT_WPA_ENTRY_AA_PREFIX", "AA %"),
                SlotColumn("INPUT_WPA_ENTRY_TRANSAKTION_WERT_KAUF_PREFIX", "Transaktionskosten Kauf"),
                SlotColumn("INPUT_WPA_ENTRY_TRANSAKTION_WERT_VERKAUF_PREFIX", "Transaktionskosten Verkauf"),
                SlotColumn("INPUT_WPA_ENTRY_SPARPLAN_RATEN_PREFIX", "Anzahl Raten"),
                TextColumn("art", "Art"),
                CurrencyColumn("betrag", "Betrag"),
                TextColumn(this.config.bezKurz, this.config.bezKurz),
                TextColumn("antrag", "Antrag"),
                TextColumn("isin", "ISIN"),
                TextColumn("zusatz", "Zusatz"),
                PercentageColumn("laufk", "lauf. Kosten % p.a."),
                PercentageColumn("anteilwpd", "davon Anteil WPD"),
                PercentageColumn("bepro", "Bepro"),
                PercentageColumn("perffee", "Perf. Fee"),
            ],
            lockedRight: [
                ActionColumn("actions", "Aktionen"),
            ],
        },
        positionToDelete: null, 
        deletedPositions: [],
    };
  },
  watch: {
    lastDeletedAntragsPosition: function (newValue, oldValue) {
      this.deletedPositions.push(newValue.positionId + '');
    },
  },
  computed: {
    ...mapGetters({
      lastDeletedAntragsPosition: BERATUNGSMAPPE_TYPES.GETTERS.LAST_DELETED_ANTRAGS_POSITION,
    }),   
    rows() {
      const rows = this.config.tableData.rows
        .filter(row => this.deletedPositions.indexOf(row.id) < 0)
        .sort((a,b) => a?.wertpapier?.localeCompare(b?.wertpapier));
      return rows;
    },
    deletePositionMessage() {
      const positionName = this.positionToDelete && this.positionToDelete.wertpapier;

      return positionName && `Soll die folgende Position wirklich gelöscht werden? "${positionName}"`;
    },   
    filteredForm() {
      const filtered = Object.keys(this.form)
        .filter(key => this.form[key].config.showInHeader!==false)
        .reduce((obj, key) => {
          obj[key] = this.form[key];
          return obj;
        }, {});

      return filtered;
    }
  },
  mounted() {
    this.createForm();
  },
  methods: {
    createForm() {
      this.form = this.config.positionFields
        .map((field) => {
           field.disabled = typeof field.disabled == "boolean" ? field.disabled : field.hasOwnProperty('disabled') && field.disabled['IMMER'] && field.disabled['IMMER'] == 'IMMER' || false;
          field.hidden = field.type === "TEXT_CURRENCY" && !field.values;
          field = { ...field, value: null };
          return field;
        })
        .reduce((acc, field) => ({ ...acc, [field.id]: field }), {});
      const test = this.config.positionFields.filter(pos => pos.id.includes('input_wpa_entry_sparplan_raten'));
      this.patchForm();
    },
    patchForm() {
      if (this.antragData) {
        for (const [key, field] of Object.entries(this.form)) {
          field.value = this.antragData[key] == null ? "" : this.antragData[key];
        }
      }
    },
    onChange() {
        const form = Object.values(this.form).reduce((acc, field) => ({...acc, [field.id]: this.convertValueToForm(field.value + '', field.type)}),{});
        this.$emit('change', form);
    },
    convertValueToForm(value, type) {
      if (['TEXT_NUMBER', 'TEXT_PROZENT', 'TEXT_CURRENCY'].includes(type) ) {
        let newValue = value.split('.').join(',');

        value = newValue;
      }
      return value
    },
    deletePositionConfirmation(row) {
      if (!this.disabled) {
        this.positionToDelete = row;
        this.$refs.deletePositionConfirmation.open();
      }
    },
    deletePosition() {
      if (!this.disabled) {
        const params = {
          positionId: this.positionToDelete.id,
          beratungsmappeId: this.beratungsmappeId
        };

        this.$store.dispatch(BERATUNGSMAPPE_TYPES.ACTIONS.DELETE_POSITION, params);

        this.positionToDelete = null;
      }
    },
    getOrderTyp(pos) {
      return pos?.art || 'LALALA';
    },
    transaktionskostenLabel(pos) {
      return `Transaktionskosten ${(pos.art.includes('Ansparplan') || pos.art?.includes('Auszahlplan') ? 'Sparplan' : 'Kauf')}`;
    },
    navigateToFondsInfo(fond) {
      if (!fond?.isin) {
          return
      }
      const path = `/shared/vermogensubersicht/fondsinfo/${fond.isin}/${fond.depotNr}`;
      this.$router.push({ path })
    },
  },
};
</script>
<style lang="scss" scoped>
.text-divider {
    font-weight: normal;
    margin: 0 0.25rem;
}
.cards__container {
    .cards__items {
        .cards__item {
            &.has-warnings {
                border: 1px solid var(--color-danger);
            }

            &.box__container > * {
                margin-bottom: 0.25rem;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .cards__item--header {
            display: flex;
            justify-content: space-between;

            &.cards__item--header-wrap {
                flex-wrap: wrap;
            }
        }

        .cards__item--header-title {
            display: flex;
            flex-wrap: wrap;
            margin: 0 0.75rem 0 0;
        }

        .cards__item--header-actions {
            display: flex;
            justify-content: flex-end;
            flex: 1 1 auto;
            text-align: right;

            .cards__item--header-action--item-text {
                display: none;
            }
        }

        .cards__item--inputs {
            display: flex;
            margin-left: -0.5rem;
            margin-right: -0.5rem;
            flex-wrap: wrap;

            > * {
                flex: 0 0 140px;
                margin: 0 0.5rem 0.25rem;
                padding: 0;
                width: auto;

                &.cards__item--input-240 {
                    flex-basis: 240px;
                }
            }

            .cards__item--input-no-label {
                margin-top: 1.5rem;
            }
        }
    }
}

@media (max-width: 375px) {
    .cards__container {
        .cards__items {
            .cards__item--inputs {
                > * {
                    width: calc(50% - 1rem);
                    flex-basis: auto;
                }
            }

            .cards__item--header-actions {
                flex-flow: column;

                .text-divider {
                    display: none;
                }

                .cards__item--header-action--item-icon {
                    display: none;
                }

                .cards__item--header-action--item-text {
                    display: initial;
                }
            }
        }
    }
}

@media (max-width: 335px) {
    .cards__container {
        .cards__items {
            .cards__item--inputs {
                > * {
                    width: calc(100% - 1rem);
                }

                .cards__item--input-no-label {
                    margin-top: 0;
                }
            }
        }
    }
}

@media (min-width: 992px) {
  .radio-row {
    margin-top: 31px;
  }
}
</style>