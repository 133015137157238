<template>
  <div :class="needsToChangePassword ? 'login-container': ''">
    <HeaderLogin v-if="needsToChangePassword" class="change-password-header" @backButtonPressed="logout()" />

    <template v-if="isLoggedIn && !needsToChangePassword">
      <PageHeaderTitleNavigation 
        :actions="headerActions()" 
        @action-ZUGANGSPASSWORT_ANDERN="changePassword('ZUGANG')"
        :title="pageTitle" />
    </template>

    <div data-app-content class="login-content" :style="{ backgroundImage: loginContentBackground }" v-if="needsToChangePassword">
      <div class="login-box">
        <div class="login-logo-box">
          <img :src="logoLoginBoxBackground"/>
        </div>
        <hr class="login-logo__bottom--spacer"/>
        <div class="rules-container">
            <PasswordPolicy class="change-password--policy is-collapsable" isCollapsable />
            <div class="change-password--form">
                <div class="login-logo__box-title">
                    Passwort ändern
                </div>
                <InputField 
                    id="password"
                    label="Aktuelles Passwort / Startpasswort" 
                    v-model="form.password"
                    type="password"
                    allowPlainPassword
                    validateUntouched
                    autocomplete="current-password"
                />
                <InputField 
                    id="new-password"
                    label="Neues Passwort" 
                    v-model="form.newPassword"
                    type="password"
                    allowPlainPassword
                    allowPasswordRules
                    validateUntouched
                    autocomplete="new-password"
                />
                <InputField 
                    id="new-password-again"
                    label="Neues Passwort bestätigen" 
                    v-model="form.newPasswordRetype"
                    type="password"
                    allowPlainPassword
                    allowPasswordRules
                    autocomplete="new-password"
                /> 
                <InputField 
                    id="communication-password"
                    label="Neues Kommunikations- und Versandpasswort" 
                    v-model="versandForm.newPasswordVersand"
                    v-if="!isCustomer"
                    type="password"
                    allowPlainPassword
                    allowPasswordRules
                    validateUntouched
                    autocomplete="new-password"
                />
                <InputField 
                    id="communication-password-again"
                    label="Neues Kommunikations- und Versandpasswort bestätigen" 
                    v-model="versandForm.newPasswordVersandRetype"
                    v-if="!isCustomer"
                    type="password"
                    allowPlainPassword
                    allowPasswordRules
                    autocomplete="new-password"
                />
            </div>
            <PasswordPolicy class="change-password--policy no-collapsable" />
        </div>
        <div class="text-small base-button" v-if="!isCustomer">Das Kommunikations- und Versandpasswort dient zur Verschlüsselung aller per Mail verschickten PDFs.</div>
        <BaseButton
          class="base-button"
          isSecondary
          @click="logout()">
            Zurück zum Login
        </BaseButton>      
        <BaseButton 
          class="base-button"
          isSecondary
          @click="skipChangePassword">
            Überspringen
        </BaseButton>
        <BaseButton 
          class="base-button"
          :disabled="isFormInvalid || zugangInvalid || (isVersandFormInvalid || versandInvalid && !isCustomer)" 
          @click="changePassword">
            Kennwort aktualisieren
        </BaseButton>

        <div class="row" v-if="errorMessage">
          <div class="col-12">
            <span class="color-danger">{{errorMessage}}</span>
          </div>
        </div>
        <div class="row" v-if="message">
          <div class="col-12">
            <span>{{message}}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="box__container"  v-if="!needsToChangePassword">
      <div class="box__title">Passwort ändern</div>
      <InputField 
        id="password"
        label="Aktuelles Zugangspasswort" 
        v-model="form.password"
        type="password"
        allowPlainPassword
        isComponentHalfSize
        validateUntouched
        autocomplete="current-password"
      />
    </div>
    <div class="box__container rules-container"  v-if="!needsToChangePassword">
        <div class="change-password--form">
            <div class="box__title">Zugangspasswort ändern</div>
            <InputField 
                id="new-password"
                label="Neues Zugangspasswort" 
                v-model="form.newPassword"
                type="password"
                allowPlainPassword
                allowPasswordRules
                autocomplete="new-password"
            />
            <InputField 
                id="new-password-again"
                label="Neues Zugangspasswort bestätigen" 
                v-model="form.newPasswordRetype"
                type="password"
                allowPlainPassword
                allowPasswordRules
                autocomplete="new-password"
            />
            <BaseButton 
                v-if="!isMobileNativeContext"
                :disabled="isFormInvalid || zugangInvalid" 
                @click="changePassword('ZUGANG')">
                Zugangspasswort ändern
            </BaseButton>

            <div class="row" v-if="errorMessage">
                <div class="col-12">
                    <span class="color-danger">{{errorMessage}}</span>
                </div>
            </div>
            <div class="row" v-if="message">
                <div class="col-12">
                    <span>{{message}}</span>
                </div>
            </div>
        </div>
        <PasswordPolicy class="change-password--policy" />
    </div>
    <div class="box__container"  v-if="!needsToChangePassword && !isCustomer">
      <div class="box__title">Kommunikations- und Versandpasswort ändern</div>
      <div class="text-small">Das Kommunikations- und Versandpasswort dient zur Verschlüsselung aller per Mail verschickten PDFs.</div>
      <InputField 
        id="communication-password"
        label="Neues Kommunikations- und Versandpasswort" 
        v-model="versandForm.newPasswordVersand"
        type="password"
        allowPlainPassword
        allowPasswordRules
        isComponentHalfSize
        autocomplete="new-password"
      />
      <InputField 
        id="communication-password-again"
        label="Neues Kommunikations- und Versandpasswort bestätigen" 
        v-model="versandForm.newPasswordVersandRetype"
        type="password"
        allowPlainPassword
        allowPasswordRules
        isComponentHalfSize
        autocomplete="new-password"
      />
      <BaseButton 
        :disabled="isVersandFormInvalid || versandInvalid" 
        @click="changePassword('VERSAND')">
          Kommunikations- und Versandpasswort ändern
      </BaseButton>

      <div class="row" v-if="errorMessageVersand">
        <div class="col-12">
          <span class="color-danger">{{errorMessageVersand}}</span>
        </div>
      </div>
      <div class="row" v-if="messageVersand">
        <div class="col-12">
          <span>{{messageVersand}}</span>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import CORE_TYPES from '../../store/core/types';
import { mapGetters } from 'vuex';
import InputField from '@/components/core/forms/InputField.vue'
import BaseButton from '@/components/core/BaseButton.vue'
import validator from "@/mixins/validator";
import { minLength, required, createRule } from '@/mixins/validator/rules';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import HeaderLogin from '@/components/core/login/HeaderLogin.vue'

import PasswordPolicy from './change-password/PasswordPolicy.vue';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import BRIDGE_TYPES from '@/store/bridge/types';

export const MIN_PASSWORD_LENGTH = 6;
const DEFAULT_TITLE = 'Passwort ändern';

export const PREVENT_START_END_SPACES_RULE = createRule((value) => !/^\s|\s$/.test(value))
  .withMessage('Leerzeichen am Anfang und/oder Ende des Passworts sind nicht zulässig');

export default {
  mixins: [validator],
  computed: {
    ...mapGetters({
      apiAddressLegacy: CORE_TYPES.GETTERS.API_ADDRESS_LEGACY,
      fullname: CORE_TYPES.GETTERS.GET_USER_FULL_NAME,
      isPendingActionActiveFn: CORE_TYPES.GETTERS.IS_PENDING_ACTION_ACTIVE,
      imageBackgroundLoginUrl: CORE_TYPES.GETTERS.GET_BROKER_IMAGE_BACKGROUND_LOGIN_URL,
      isCustomer: CORE_TYPES.GETTERS.IS_CUSTOMER,
      isLoggedIn: CORE_TYPES.GETTERS.IS_LOGGED_IN,
      isMobileNativeContext: BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT,
    }),
    isFormInvalid() {
      return this.validation.updated && this.validation.isInvalid('form');
    },
    isVersandFormInvalid() {
      return this.validation.updated && this.validation.isInvalid('versandForm');
    },
    needsToChangePassword() {
      return this.isPendingActionActiveFn('changePassword');
    },
    loginContentBackground() {
      return this.imageBackgroundLoginUrl ? `url(${this.imageBackgroundLoginUrl})` : `url(${this.apiAddressLegacy}/../images/etc/layout/msc_wolken4.png)`
    },
    logoLoginBoxBackground() {
      return this.maklerLogo
    },
    zugangInvalid() {
      return this.form && !this.form.newPassword || !this.form.newPasswordRetype
    },
    versandInvalid() {
      return this.versandForm && !this.versandForm.newPasswordVersand || !this.versandForm.newPasswordVersandRetype
    },
    pageTitle() {
      return this.$appNavigation.currentMenu?.label || DEFAULT_TITLE;
    }
  },
  watch: {
    'form.newPassword'() {
        const currentValidator = this.validation.getValidator('form.newPasswordRetype')
        if (currentValidator?.watchCallback) {
          currentValidator.watchCallback(this.form.newPasswordRetype, false)
        }
    },
    'versandForm.newPasswordVersand'() {
        const currentValidator = this.validation.getValidator('versandForm.newPasswordVersandRetype')
        if (currentValidator?.watchCallback) {
          currentValidator.watchCallback(this.versandForm.newPasswordVersandRetype, false)
        }
    },
  },
  data() {
    return {
      form: {},
      versandForm: {},
      errorMessage: '',
      errorMessageVersand: '',
      message: '',
      messageVersand: '',
    }
  },
  validators: {
    form: {
      password: [required()],
      newPassword: [
        required(),
        minLength(MIN_PASSWORD_LENGTH, `Neues Passwort muss mindestens ${MIN_PASSWORD_LENGTH} Zeichen lang sein.`, {emptyAllowed: true}),
        PREVENT_START_END_SPACES_RULE,
        createRule((value, self) => value || !self.needsToChangePassword),
      ],
      newPasswordRetype: [
        required(),
        createRule((value, self) => !value || value === self.form?.newPassword)
          .withMessage('Fehler. Passwörter stimmen nicht überein!'),
      ]
    },
    versandForm: {
      password: [required()],
      newPasswordVersand: [
        required(),
        minLength(MIN_PASSWORD_LENGTH, `Neues Passwort muss mindestens ${MIN_PASSWORD_LENGTH} Zeichen lang sein.`, {emptyAllowed: true}),
        PREVENT_START_END_SPACES_RULE,
        createRule((value, self) => value || !self.needsToChangePassword),
      ],
      newPasswordVersandRetype: [
        required(),
        createRule((value, self) => !value || value === self.versandForm?.newPasswordVersand)
          .withMessage('Fehler. Passwörter stimmen nicht überein!'),
      ]
    }
  },
  components: {
    InputField,
    BaseButton,
    OptionMenu,
    PageHeaderTitleNavigation,
    HeaderLogin,
    PasswordPolicy,
  },
  methods: {
    headerActions() {
      const actions = [];
      actions.push(PageHeaderSimpleAction('ZUGANGSPASSWORT_ANDERN', 'Zugangspasswort ändern').withDisabled(() => this.validation.isInvalid('form') || this.zugangInvalid));
      return this.isMobileNativeContext ? actions : [];
    },          
    changePassword(action) {
        if (action != "VERSAND") {
            const wasForced = this.needsToChangePassword;
            this.$store.dispatch(CORE_TYPES.ACTIONS.CHANGE_PASSWORD, {
                oldPassword: this.form.password,
                newPassword: this.form.newPassword,
            }).then(success => {
                if (success && wasForced)
                    this.$router.push({ path: this.$route.query?.nextUrl || '/home' });
            });
        }
        if (action != 'ZUGANG' && !this.isCustomer) {
            this.$store.dispatch(CORE_TYPES.ACTIONS.CHANGE_VERSAND_PASSWORD, {
                oldPassword: this.form.password,
                newPasswordVersand: this.versandForm.newPasswordVersand,
                newPasswordVersandRetype: this.versandForm.newPasswordVersandRetype,
                isVersandOnly: true,
            }).then((result) => {
                this.form = {}
                this.versandForm = {}
                if (action === 'VERSAND') {
                    this.messageVersand = result
                    this.errorMessageVersand = ''
                } else {
                    this.message = result
                    this.errorMessage = ''
                }
            }).catch(error => {
                if (action === 'VERSAND') {
                    this.messageVersand = ''
                    this.errorMessageVersand = error?.message
                } else {
                    this.message = ''
                    this.errorMessage = error?.message
                }
            });
        }
    },
    skipChangePassword() {
      if (this.needsToChangePassword) {
        this.$store.commit(CORE_TYPES.MUTATIONS.MARK_PENDING_ACTION_AS_SKIPPED, 'changePassword');
        this.$router.push({ path: this.$route.query?.nextUrl || '/home' }).catch(() => {});
      }
    },
    logout() {
      this.$store.dispatch(CORE_TYPES.ACTIONS.LOGOUT);
    },
  }
}
</script>

<style scoped>

  .change-password-header {
    flex: 0 0 auto;
  }

  .login-container {
    display: flex;
    align-items: center;
    min-height: -webkit-fill-available;
    min-height: 100vh;
    min-height: 100svh;
    min-height: 100dvh;

    flex-direction: column;
    justify-content: flex-start;
  }

  .login__form {
    display: flex;
    flex-direction: column;
    padding-top: 16px;
  }

  .login-box {
    width: 520px;
    background-color: var(--color-box);
    border-radius: 8px;
    margin: 16px 0;
    padding: 32px;
  }

  .login-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 0 auto;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center center;
  }

  .login-logo-box, .login-logo-box img {
    max-height: 80px;
  }

.rules-container {
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 32px;
}
  /* .login-logo-box { 
    
  } */

  .login-logo__bottom--spacer {
    padding: 8px;
    border-top: solid 1px #C4C4C4;
  }

  .base-button {
    margin-bottom: 8px;
  }

  .login-logo__box-title {
    font-size: 1.75rem;
    font-weight: 500;
    color: var(--color-primary)
  }

  .change-password--form,
  .change-password--policy {
    flex: 1 1 240px;
  }

  .change-password--policy {
    overflow: hidden;
  }

  .change-password--policy.no-collapsable {
    display: block;
  }

  .change-password--policy.is-collapsable {
    display: none;
  }

  @media (max-width: 576px) { 
    .change-password--form,
    .change-password--policy {
      flex-basis: 250px;
    }

    .change-password--policy.no-collapsable {
      display: none;
    }

    .change-password--policy.is-collapsable {
      display: block;
    }

    .change-password--form {
      margin-bottom: 16px;
    }

    .login-content {
      background-image: none !important;
    }
  }
</style>
