<template>
  <div>
    <PageHeaderTitleNavigation title="Änderungslog" />

    <div class="box__container">
      <h3>Änderungslog für {{ title }}</h3>
        <InputField v-if="rows.length"
            label="Suchfeld"
            v-model="descriptionSearch">
        </InputField>
        <Table v-if="rows.length"
            :headers="headers"
            :rows="rows"
            rowId="aenderung"
            hidePagination
            @click-aenderungTxt="openChangelogModal"
        />
        <div v-else>Keine Daten vorhanden</div>
    </div>

  <BaseModal
    ref="changelogModal"
    :modalTitle="'Änderungslogeintrag'"
    labelButtonCancel="Zurück"
    :showConfirmButton="false">
    <div v-for="(aenderung, index) of changelog" :key="index">
      <span>{{ aenderung }}</span>
    </div>
  </BaseModal>

  </div>
</template>

<script>
import DOCUMENT_TYPES from "@/store/documents/types";
import BaseButton from '@/components/core/BaseButton.vue';
import InputField from '@/components/core/forms/InputField.vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn, DateTimeColumn} from "@/components/table2/table_util.js";
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import BaseModal from "@/components/core/BaseModal.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    BaseButton,
    InputField,
    Table,
    OptionMenu,
    PageHeaderTitleNavigation,
    BaseModal,
  },
  data() {
    return {
        descriptionSearch: '',
        changelog: null,
        headers: {
            center: [
                DateTimeColumn("datum", "Datum"),
                TextColumn("benutzer", "Benutzer"),
                TextColumn("eintrag", "Eintrag"),
            ],
            lockedRight: [
                TextColumn("aenderungTxt", "Änderung").makeLink(),
            ],
        },
    };
  },
  computed: {
    ...mapGetters({
        documentHistory: DOCUMENT_TYPES.GETTERS.DOCUMENT_HISTORY,
    }),
    title() {
        return this.documentHistory?.aenderungsLog?.title || '';
    },
    rows() {
        let result = this.documentHistory?.aenderungsLog?.tableRecords || [];
        if(this.descriptionSearch.length) {
            const regex = new RegExp(this.descriptionSearch, "i");
            result = result.filter(r => regex.test(r.aenderung) || regex.test(r.datum) || regex.test(r.eintrag) || regex.test(r.benutzer));
        }
        return result.map(row => ({
            ...row,
            aenderungTxt: "Bitte anklicken",
        }));
    },
  }, 
  methods: {
    getStringAenderung(aenderung) {
      let temp = aenderung.replace(/\<\#\>/g, ', ')
      return temp
    },
    openChangelogModal(row) {
      this.changelog = row.aenderung.split('<#>')
      this.$refs.changelogModal.open();
    },
  },
}
</script>

<style scoped>
</style>
