<template>
  <div  :class="{'empty-legend': (refresh || !isLoaded)}">
    <template v-if="!isLoading">
      <div id="chart-main" >

        <div v-if="!isLoaded">
          <div class="chart-meldung">
              <PhArrowClockwise  :size="16" class="spin-animation"/>
              Grafikdaten laden ...
          </div>
        </div>

        <LineChart
          ref="lineChart"
          xAxisType="category"
          height="500px"
          :chartSeries="refresh ? [{name: ' ', data:[]}] : series"
          :customColors="customColors"
          :format="chartFormat"
          :xLabels="xLabelsEcharts"
          :isArea="sparkline"
          :showDataZoom="!sparkline && series.length > 0 && !refresh"
          :gridLeft="50"
          :chartOptions="chartOptions"
          :changeOption="changeOption" />
      </div>
      <div class="mt-2" v-if="showTypeTrigger">
        <InputToggleSwitch class="m-2" v-model="triggerAxis" inLineLabel  label="Grafik Detailansicht Wertpapier (Mouseover)"/>
      </div>
      <div class="mt-2" v-if="showBtnAuswahl">
        <InputToggleSwitch class="m-2" inLineLabel label="mit Bestand" v-model="cbMitBestand"/>
      </div>
      <ChartLegend class="ml-2" v-if="lineChart"
        :showAsTable="showBtnAuswahl"
        :chartSeries="series"
        :lineChart="lineChart"
        :addOnHeaders="addOnHeaders"
        :addOnRows="addOnRows"
        @changeVisible="onChangeVisible"
      />
    </template>
    <GhostLoading v-else type="block" :config="{ block: { height: 500, }, }" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import dayjs from 'dayjs';
import GRAPHICAL_COURSE_TYPES from '@/store/graphicalCourse/types';
import CORE_TYPES from '@/store/core/types';
import {utils} from '@/store/graphicalCourse/helper';
import {preformancePerAnno} from "@/helpers/commonfunctions"
import BaseButton from '@/components/core/BaseButton.vue';
import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue";
import LineChart from '@/components/charts/echarts/LineChart.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import { PhArrowClockwise } from 'phosphor-vue';
import ChartLegend from "./ChartLegend.vue";
import {PercentageColumn} from "@/components/table2/table_util.js";
import {formatNumber} from '@/helpers/number-formatter.js';

const isMobile = !!((navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) 
        || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) 
        || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) 
        || navigator.userAgent.match(/Windows Phone/i)));
export default {
  props: {
    chartHeight: {
      type: String,
      default: '100%',
    },
    depotid: {
      type: String
    },
    isLoaded: {
      type: Boolean,
      default: true,
    },
    sparkline: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      zoomMoving: '',
      colors: [], // getColorsMixedContrastDecreasing(),
      refresh: false,
      cbMitBestand: true,
      eChart: null,
      chartOptions: {},
      lineChart: null,
      addOnRows: {},
      addAnlagesumme: false,
      hiddenRows: {},
      newSeries: true,
      triggerAxis: false,
      perfomZoomed: null,
    }
  },
  computed: {
    site() {
      return 'page';
    },
    ...mapGetters({
      sessionData: GRAPHICAL_COURSE_TYPES.GETTERS.SITE,
      isDepotLoading: GRAPHICAL_COURSE_TYPES.GETTERS.IS_DEPOT_LOADING,
      screenWidth: CORE_TYPES.GETTERS.SCREEN_WIDTH,
    }),
    isLoading() {
      return this.isDepotLoading(this.depotid);
    },
    session() {
      return this.isLoaded ? {...this.sessionData(this.site)} : {...this.sessionData('loading')};
    },
    xLabels() {
      return this.session.xLabels;
    },
    xLabelsEcharts() {
      return (this.xLabels || []).map( (v) => {
        const dt = dayjs(v);
        if (this.xType === 1 ) {
          return 'Wo ' + dt.isoWeek() + ' (' + dt.format('DD.MM.YY') +')';
        } else if (this.xType === 2 ) {
          return dt.format('MMM YYYY');
        }
        return dt.format('DD MMM YYYY');
      });
    },
    xType() {
      return this.session.xType;
    },
    series() {
      if (this.session.series?.length && this.session.series[0].data?.length) {
        const series = this.session.series.filter(serie => {
          serie.config.show = !this.cbMitBestand || this.visibleSerie(serie);
          return serie.config.show && serie.config.visible;
        })
        .map( serie => {
          if (serie.name2.indexOf('(gesamt)') !== -1 && this.screenWidth === 'xs') {
            serie.name2 = serie.name2.replace(/\(gesamt\)/, '(ges.)');
          } else if (serie.name2.indexOf('(ges.)') !== -1 && this.screenWidth !== 'xs') {
            serie.name2 = serie.name2.replace(/\(ges.\)/, '(gesamt)');
          }
          serie.color = serie.config.color;
          serie.visible = !this.hiddenRows[serie.name];
          serie.newSeries = this.newSeries;

          const data = serie.data;
          const test = (this.kind === 'funds_course' || this.kind === 'performance' ) ? serie.value : serie.data;
          if (test && !test[test.length - 1] && test[0]) {
            let k = test.length - 1;
            while (k >= 0 && !test[k]) {
              data[k--] = null;
            }
          }
          if(serie.config?.id) {
            serie.id = serie.config.id + this.kind;
          }
          return {...serie, data}
        });
        if (this.addAnlagesumme) { // series.length === 1 && series[0].anlageData
          series.push({
            name: 'Anlagesumme',
            name2: '',
            visible: true,
            color: "rgb(112,112,112)",
            config: {
              visible: true,
              color: "rgb(112,112,112)",
            },
            data: this.addAnlagesumme
          });
        }
        return series;
      }
      return [];
    },
    showSeries() {
      if (this.session.series?.length && this.session.series[0].data?.length) {
        return this.session.series.filter(serie => {
            serie.config.show = !this.cbMitBestand || this.visibleSerie(serie);
            return serie.config.show;
          });
      }
      return [];
    },
    charts() {
      if (this.session.charts?.length > 1) {
        return this.session.charts;
      }
      return null;
    },
    kind: {
      get() {
        return this.session.kind;
      },
      set(kind) {
        this.refresh = true;
        setTimeout(() => {
          this.$store.commit(GRAPHICAL_COURSE_TYPES.MUTATIONS.KIND, {site: this.site, kind: kind});
          this.refresh = false;
        }, 0);
        
      }
    },
    chartFormat() {
      if ( this.kind === 'performance' || this.series?.[0]?.config?.currency === '%' ) {
        return 'percent';
      }
      return 'currency';
    },
    customColors() {
      return (this.series || []).map(s => s.config.visible ? s?.config?.color : null).filter(c => c !== null);
    },
    showBtnAuswahl() {
      return ' pos_value funds_course performance '.indexOf(this.kind) !== -1;
    },
    showTypeTrigger() {
      return ' performance '.indexOf(this.kind) !== -1 && !isMobile;
    },
    addOnHeaders(){
      if (this.session.performancePA) {
        return [
          PercentageColumn("we", "Wertentw. pro Jahr"),
          PercentageColumn("vola", "Volatilität"),
        ];
      }
      return [
        PercentageColumn("we", "Wertentwicklung"),
        PercentageColumn("vola", "Volatilität"),
      ];
    },
  },
  methods: {
    changeDepot() {
      this.$store.commit(GRAPHICAL_COURSE_TYPES.MUTATIONS.DEPOTID, {site: this.site, depotid: this.depotid});
    },
    visibleSerie(serie) {
      if ( this.cbMitBestand && this.showBtnAuswahl ) {
        const test = (this.kind === 'funds_course' || this.kind === 'performance' ) ? serie.value : serie.data;
        if (test) {
          // return test.some( t => (t||0) > 0);
          return test[test.length - 1];
        }
      }
      return true;
    },
    onDataZoom() {
      const opt = this.eChart.getOption();
      if (opt.dataZoom?.length ) {
        setTimeout(() => {
          this.calcAfterZoom(opt.dataZoom[0].startValue, opt.dataZoom[0].endValue);
        }, 100);
      }
    },
    calcAfterZoom(startValue, endValue) {
      const opt = this.eChart.getOption();
      if (opt.dataZoom?.length && startValue === opt.dataZoom[0].startValue && endValue === opt.dataZoom[0].endValue) {
        endValue++; // from last index to length
        if (startValue === 0 && endValue === this.session.series[0]?.data?.length) {
          this.updateLegend()
          return ;
        }
        if (this.kind === 'perf_chart') {
          const series = []
          this.session.series.forEach(ser => { // grafNachTransakt  aktWert: aktWert.data2, anlage: anlage.data2, xLabel
            if (ser.config.startIndex !== startValue && ser.config?.aktWert && ser.config?.anlage && ser.config?.xLabel) {
              ser.config.startIndex = startValue;
              const perf_chart = utils.calcPerformanceChart(ser.config, startValue===0 && ser.config.abAnfang);
              series.push({
                data: perf_chart,
                id: ser.id,
              });
            }
          });
          this.chartOptions = { dataZoom : [{type: 'inside'},{type: 'slider'}], series}
          this.addOnRows = {}
        } else if (this.kind === 'performance') {
          const series = []
          this.perfomZoomed = {};
          const addOnRows = {};
          let data, anlage, perf, vola ;
          this.session.series.forEach(ser => {
            if (ser.config?.pos_value && ser.anlageData) {
              data = utils.arSlice(ser.config.pos_value, startValue, endValue);
              anlage = utils.arSlice(ser.anlageData, startValue, endValue);
              perf = utils.getKapitalgewichteteWertentwicklung(data, anlage, this.xLabels, this.kind, ser.config.abAnfang && !startValue );
              vola = utils.calcVola(data, anlage, this.kind);
              addOnRows[ser.name] = {vola: Math.round(vola * 100) / 100};
              let we = perf?.length ? perf[0] : 0;
              if (we === Infinity || we === -Infinity || isNaN(we)) {
                addOnRows[ser.name].we = null;
              } else if (this.session.performancePA && perf?.length ) {
                we = preformancePerAnno(perf[1], we);
                addOnRows[ser.name].we = Math.round(we * 100) / 100;
              } else {
                addOnRows[ser.name].we = Math.round(we * 100) / 100;
              }
              const funds_course = utils.arSlice(ser.config.funds_course, startValue, endValue);
              data = utils.getPercentData(ser.config.funds_course, startValue, endValue);
              series.push({
                data,
                id: ser.id,
              });
              this.perfomZoomed[ser.id] = data;
            }
          });
          console.log('series', startValue, endValue, JSON.parse(JSON.stringify(series)) );
          this.chartOptions = { dataZoom : [{type: 'inside'},{type: 'slider'}], series}
          this.addOnRows = addOnRows;
        } else {
          const addOnRows = {};
          let data, anlage, perf, vola ;
          this.session.series.forEach(ser => {
            if (this.kind === 'funds_course') {
              data = utils.arSlice(ser.config.pos_value, startValue, endValue);
              anlage = utils.arSlice(ser.anlageData, startValue, endValue);
            }
            if (ser.config?.data && ser.config?.anlage) {
              data = utils.arSlice(ser.config.data, startValue, endValue);
              anlage = utils.arSlice(ser.config.anlage, startValue, endValue);
            }
            if (data && anlage) {
              perf = utils.getKapitalgewichteteWertentwicklung(data, anlage, this.xLabels, this.kind, ser.config.abAnfang && !startValue );
              vola = utils.calcVola(data, anlage, this.kind);
              addOnRows[ser.name] = {vola: Math.round(vola * 100) / 100};
              let we = perf?.length ? perf[0] : 0;
              if (we === Infinity || we === -Infinity || isNaN(we)) {
                addOnRows[ser.name].we = null;
              } else if (this.session.performancePA && perf?.length ) {
                we = preformancePerAnno(perf[1], we);
                addOnRows[ser.name].we = Math.round(we * 100) / 100;
              } else {
                addOnRows[ser.name].we = Math.round(we * 100) / 100;
              }
            }
          });
          this.addOnRows = addOnRows;
        }
      }
    },
    tooltipSerie(params) {
      console.log('tooltipSerie', JSON.parse(JSON.stringify(params)) );
      const { marker, seriesName, name, dataIndex, seriesIndex, seriesId } = params;
      const config = this.series[seriesIndex].config;
      const pos_value = formatNumber(config.pos_value[dataIndex], 2);
      const funds_course = formatNumber(config.funds_course[dataIndex], 2);
      let performance = formatNumber(config.performance[dataIndex] - 100, 2);
      if (seriesId && this.perfomZoomed && this.perfomZoomed[seriesId]) {
        performance = formatNumber(this.perfomZoomed[seriesId][dataIndex] - 100, 2);
      }
      return `
        <div class="tooltip-series">
          <div>${name}</div>
          <div><span>${marker}</span> <span>${seriesName}:</span></div>
          <div class="tooltip-series-table">
            <div><span>Wert</span> <span>${pos_value} €</span></div>
            <div><span>Wertentw.</span> <span>${performance} %</span></div>
            <div><span>Kurs</span> <span>${funds_course} €</span></div>
          </div>
        </div>`;
    },
    changeOption(option) {
      if ( this.showTypeTrigger && this.triggerAxis) {
        option.tooltip = {
          show: true,
          trigger: 'item',
          position: null
        }
        option.series.forEach(serie => {
          serie.symbol = 'circle';
          serie.symbolSize = 3;
          serie.showAllSymbol = true;
          serie.tooltip = {
            formatter: this.tooltipSerie,
          }
        })
      }
      return option;
    },
    updateLegend() { 
      this.chartOptions = {};
      const addOnRows = {};
      this.series.forEach(serie => {
        if (serie.config.we != undefined) {
          if (serie.config.we === Infinity || serie.config.we === -Infinity || isNaN(serie.config.we)) {
            addOnRows[serie.name] = {
              we: null,
              vola: Math.round(serie.config.vola * 100) / 100
            };
          } else {
            addOnRows[serie.name] = {
              we: Math.round(serie.config.we * 100) / 100,
              vola: Math.round(serie.config.vola * 100) / 100
            };
          }
        }
      })
      this.addOnRows = addOnRows;
    },
    onChangeVisible(name, status, rows) {      
      let cnt = 0;
      let anlageData = {};
      let serie;
      const hiddenRows = {};
      rows.forEach(row => {
        if (row.name !== 'Anlagesumme') {
          serie = this.series.find(s => s.name === row.name);
          if (serie){
            if (row.status === 0) {
              hiddenRows[row.name] = true;
            } else if (serie.anlageData){
              anlageData = serie.anlageData;
              cnt++;
            }}
        }
      })
      // this.hiddenRows = hiddenRows
      this.addAnlagesumme = this.showBtnAuswahl && cnt === 1 ? anlageData : false;
    },
  },
  watch: {
    depotid: 'changeDepot',
    updateData: 'changeDepot',
    series: 'updateLegend',
    kind() {
      this.hiddenRows = {};
      this.addAnlagesumme = false;
      this.newSeries = true;
      this.$nextTick( () => {this.newSeries = false;})
      this.perfomZoomed = null;
    },
  },
  created() {
    dayjs.locale('de');
    this.$store.dispatch(GRAPHICAL_COURSE_TYPES.ACTIONS.CONFIG, {site: this.site});
    this.$store.dispatch(GRAPHICAL_COURSE_TYPES.ACTIONS.DEPOT, {site: this.site, depotid: this.depotid});
  },
  mounted() {
    if ( this.$refs?.lineChart?.$refs?.chart?.chart) {
      this.lineChart = this.$refs.lineChart;
      this.eChart = this.lineChart.$refs.chart;
      this.eChart.chart.on('datazoom', this.onDataZoom);
    }
  },
  updated() {
    if (!this.lineChart && this.$refs?.lineChart?.$refs?.chart?.chart) {
      this.lineChart = this.$refs.lineChart;
      this.eChart = this.lineChart.$refs.chart;
      this.eChart.chart.on('datazoom', this.onDataZoom);
    }
  },
  components: {
    BaseButton, 
    InputToggleSwitch,
    LineChart,
    GhostLoading,
    PhArrowClockwise,
    ChartLegend
  }
}
</script>

<style scoped>
.chart-meldung {
  position: absolute;
  left: 45%;
  top: 30%;
  color: var(--color-info);
  font-size: 1.5rem;
}
.bar {
  padding: 0.5rem 0 0.5rem 2rem;
  display: flex;
  border-bottom: 2px solid var(--color-background);
}
.charts-legend {
  display: grid;
  gap: 5px;
  grid-template-columns: repeat(auto-fit, minmax(15rem,auto));
  padding: 0.5rem 2rem 0 2rem;
  font-size: 13px;
  cursor: pointer;
}
.charts-legend .charts-legend-marker {
  margin-right: 5px;
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 50%;
}
</style>



