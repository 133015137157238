<template>
  <div v-if="selectedUserLevel" class="option-menu-edit-page__container">
    <Tabs 
      :tabList="tabList" 
      :selected="selectedUserLevel" 
      :disabled="isLoading" 
      class="mt-1 mb-2" 
      @tabSelected="selectUserLevel($event.key)" 
    />

    <div>
      <OptionMenuGhostLoading v-if="isLoading" />
      <OptionMenuConfig v-else 
        :configId="configId"
        :key="selectedUserLevel"
        :defaultMenu="defaultMenu"
        :optionMenu="optionMenu"
        :userLevel="selectedUserLevel"
        :configContext="configContext"
        :currentPath="currentPath"
        :isPermissionConfigurable="isPermissionConfigurable"
        isExpandedLayout
      />

      <div class="option-menu-edit-page__buttons d-flex mt-3 layout__negative-margin--8">
        <BaseButton isSecondary @click="openResetConfigModal();" :disabled="isLoading">Standard wiederherstellen</BaseButton>

        <template v-if="selectedUserLevel === FC_CONFIG_USER_LEVEL.MAKLER">
          <BaseButton 
            v-if="hasUserLevel(FC_CONFIG_USER_LEVEL.KUNDE)" 
            isSecondary 
            :disabled="isLoading"
            @click="copyUserLevelTo(selectedUserLevel, FC_CONFIG_USER_LEVEL.KUNDE)"
          >In aktueller Kunde übernehmen</BaseButton>

          <BaseButton 
            v-if="hasUserLevel(FC_CONFIG_USER_LEVEL.MAKLER_STRUKTUR)" 
            isSecondary 
            :disabled="isLoading"
            @click="copyUserLevelTo(selectedUserLevel, FC_CONFIG_USER_LEVEL.MAKLER_STRUKTUR)"
          >In Unterstruktur übernehmen</BaseButton>

          <BaseButton 
            v-if="hasUserLevel(FC_CONFIG_USER_LEVEL.MAKLER_PERSONEN)" 
            isSecondary 
            :disabled="isLoading"
            @click="copyUserLevelTo(selectedUserLevel, FC_CONFIG_USER_LEVEL.MAKLER_PERSONEN)"
          >In Mitarbeiter übernehmen</BaseButton>
        </template>
        <template v-else-if="selectedUserLevel === FC_CONFIG_USER_LEVEL.MAKLER_STRUKTUR || selectedUserLevel === FC_CONFIG_USER_LEVEL.MAKLER_PERSONEN">
          <OptionsMenuApplyButton
            :configContext="configContext"
            :configId="configId" 
            :userLevel="selectedUserLevel"
            :disabled="isLoading" 
          />
          <OptionsMenuApplyButton
            :configContext="configContext"
            :configId="configId" 
            :userLevel="selectedUserLevel"
            :disabled="isLoading" 
            isPartial
          />
        </template>
      </div>
    </div>

    <OptionMenuResetConfigModal 
      ref="resetConfigModal" 
      :configContext="configContext" 
      :configContextLabel="configContextLabel" 
      :currentMenu="currentMenu" 
      :userLevel="selectedUserLevel" 
      :enablePermissionConfig="enablePermissionConfig" 
      @onResetDefault="onResetConfigPage" 
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MENU_CONFIG_TYPES from '@/store/menuConfig/types';
import LOG_TYPES from '@/store/log/types';

import Tabs from '@/components/tabs/Tabs.vue';
import OptionMenuGhostLoading from './OptionMenuGhostLoading.vue';
import OptionMenuConfig from '@/components/core/option-menu/OptionMenuConfig.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import OptionMenuResetConfigModal from '@/components/core/option-menu/optionMenuConfig/OptionMenuResetConfigModal.vue';
import OptionsMenuApplyButton from '@/components/core/option-menu/optionMenuConfig/OptionsMenuApplyButton.vue';

import { FC_CONFIG_USER_LEVEL } from '@/configs/fcConfig';
import optionMenuEditMixin from '@/components/core/option-menu/option-menu-edit-mixin';

import { buildMessageWith } from '@/helpers/log-message-helper';
import EVENT_BUS, { RECALCULATE_MENU_USER_LEVEL, } from '@/event-bus';

export default {
  mixins: [optionMenuEditMixin],
  components: {
    Tabs,
    OptionMenuGhostLoading,
    OptionMenuConfig,
    BaseButton,
    OptionMenuResetConfigModal,
    OptionsMenuApplyButton,
  },
  props: {
    currentPath: {
      type: String,
      default: null,
    },
    configContextLabel: {
      type: String,
      default: null,
    },
    configContext: {
      type: String,
      default: null,
    },
    enablePermissionConfig: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      FC_CONFIG_USER_LEVEL,
    };
  },
  computed: {
    ...mapGetters({
      flatMenuConfigByPathFn: MENU_CONFIG_TYPES.GETTERS.FLAT_MENU_CONFIG_BY_PATH,
      optionsMenuConfigUserLevelDefaultFn: MENU_CONFIG_TYPES.GETTERS.OPTIONS_MENU_CONFIG_USER_LEVEL_DEFAULT,
      tabListFn: MENU_CONFIG_TYPES.GETTERS.OPTIONS_MENU_CONFIG_TABS,
      configUserLevelsFn: MENU_CONFIG_TYPES.GETTERS.OPTIONS_MENU_CONFIG_USER_LEVELS,
      isOptionsMenuPermissionConfigurableFn: MENU_CONFIG_TYPES.GETTERS.IS_OPTIONS_MENU_PERMISSION_CONFIGURABLE,
      isInternGlobalConfigAllowed: MENU_CONFIG_TYPES.GETTERS.IS_INTERN_GLOBAL_CONFIG_ALLOWED,
      hasSelectedUnternFn: MENU_CONFIG_TYPES.GETTERS.HAS_SELECTED_UNTERNR,
    }),
    configUserLevels() {
      return this.configUserLevelsFn(this.configContext);
    },
    tabList() {
      return this.tabListFn(this.configContext);
    },
    hasSelectedUntern() {
      return this.hasSelectedUnternFn(this.configContext);
    },
    currentMenu() {
      const { currentPath, } = this;
      const flatMenuConfig = this.flatMenuConfigByPathFn(this.configContext);
      const currentMenu = flatMenuConfig[currentPath];
      return currentMenu;
    },
    userLevelDefault() {
      return this.optionsMenuConfigUserLevelDefaultFn(true);
    },
    isPermissionConfigurable() {
      return this.enablePermissionConfig && this.isOptionsMenuPermissionConfigurableFn(this.configContext, this.selectedUserLevel);
    },
  },
  methods: {
    async findFCConfigByUserLevel(userLevel, forceReload = false) {
      try {
        this.loading = true;

        if(this.isInternGlobalConfigAllowed) { // global
          await this.$store.dispatch(MENU_CONFIG_TYPES.ACTIONS.FIND_OPTIONS_MENU_CONFIG_GLOBAL, {
            configContext: this.configContext,
            userLevel,
            configId: this.configId,
            forceReload,
          });
        } else if(this.hasSelectedUntern) {
          await this.$store.dispatch(MENU_CONFIG_TYPES.ACTIONS.FIND_OPTIONS_MENU_CONFIG_MITARBEITER, { 
            configContext: this.configContext,
            userLevel: FC_CONFIG_USER_LEVEL.MAKLER,
            configId: this.currentPath,
          });
        } else {
          await this.$store.dispatch(MENU_CONFIG_TYPES.ACTIONS.FIND_OPTIONS_MENU_CONFIG, {
            configContext: this.configContext,
            userLevel,
            configId: this.configId,
            forceReload,
          });
        }
      } finally {
        this.loading = false;
        EVENT_BUS.$emit(RECALCULATE_MENU_USER_LEVEL, { userLevel, configContext: this.configContext });
      }
    },
    resetOptionMenuConfigData() {
      // reset loaded
      this.$store.commit(MENU_CONFIG_TYPES.MUTATIONS.RESET_OPTIONS_MENU_CONFIG, { configContext: this.configContext, });

      // reset edited
      this.$store.commit(MENU_CONFIG_TYPES.MUTATIONS.RESET_OPTIONS_MENU_CONFIG_EDITED, { configContext: this.configContext, });
    },
    async onSaveConfigPage(configContext) {
      if(!configContext) return;

      try {
        this.loading = true;

        if(this.isInternGlobalConfigAllowed) {
          await Promise.all([
            this.$store.dispatch(MENU_CONFIG_TYPES.ACTIONS.SAVE_ALL_OPTIONS_MENU_CONFIG_GLOBAL, { configContext, }),
            this.$store.dispatch(MENU_CONFIG_TYPES.ACTIONS.SAVE_ALL_OPTIONS_MENU_PERMISSION_CONFIG_GLOBAL, { configContext, }),
          ]);
        } else if(this.hasSelectedUntern) {
          await Promise.all([
            this.$store.dispatch(MENU_CONFIG_TYPES.ACTIONS.SAVE_ALL_OPTIONS_MENU_CONFIG_MITARBEITER, { configContext, }),
            this.$store.dispatch(MENU_CONFIG_TYPES.ACTIONS.SAVE_ALL_OPTIONS_MENU_PERMISSION_CONFIG_MITARBEITER, { configContext, }),
          ]);
        } else {
          await Promise.all([
            this.$store.dispatch(MENU_CONFIG_TYPES.ACTIONS.SAVE_ALL_OPTIONS_MENU_CONFIG, { configContext }),
            this.$store.dispatch(MENU_CONFIG_TYPES.ACTIONS.SAVE_ALL_OPTIONS_MENU_PERMISSION_CONFIG, { configContext }),
          ]);
        }
      } finally {
        this.resetOptionMenuConfigData();
        this.loading = false;
      }
    },
    async openResetConfigModal() {
      this.$refs.resetConfigModal?.open();
    },
    async onResetConfigPage(options) {
      this.deleting = true;

      try {
        const promises = [];

        // restore options menu config
        if(options?.configUserLevels?.length) {
          promises.push(this.deleteOptionsMenuConfig(this.configId, options.configUserLevels, this.configContext));
        }

        // restore options menu permission config
        if(options?.permissionConfigUserLevels?.length) {
          promises.push(this.deleteOptionsMenuPermissionConfig(options.permissionConfigUserLevels, this.configContext));
        }
        await Promise.all(promises);

        // init component
        this.init();
      } finally {
        this.deleting = false;
      }
    },
    async deleteOptionsMenuConfig(configId, userLevels, configContext) {
      if(this.isInternGlobalConfigAllowed) { // global
        await this.$store.dispatch(MENU_CONFIG_TYPES.ACTIONS.DELETE_OPTIONS_MENU_CONFIG_GLOBAL, { 
          configId, 
          userLevels: [ ...userLevels, ],
          configContext,
        });
      } else if(this.hasSelectedUntern) {
        await this.$store.dispatch(MENU_CONFIG_TYPES.ACTIONS.DELETE_OPTIONS_MENU_CONFIG_MITARBEITER, { 
          configContext,
          configId: this.currentPath,
        });
      } else {
        await this.$store.dispatch(MENU_CONFIG_TYPES.ACTIONS.DELETE_OPTIONS_MENU_CONFIG, { 
          configContext,
          configId, 
          userLevels: [ ...userLevels, ],
        });
      }
    },
    async deleteOptionsMenuPermissionConfig(userLevels, configContext) {
      if(this.isInternGlobalConfigAllowed) { // global
        await this.$store.dispatch(MENU_CONFIG_TYPES.ACTIONS.DELETE_OPTIONS_MENU_PERMISSION_CONFIG_GLOBAL, { 
          userLevels: [ ...userLevels, ],
          configContext,
        });
      } else if(this.hasSelectedUntern) {
        await this.$store.dispatch(MENU_CONFIG_TYPES.ACTIONS.DELETE_OPTIONS_MENU_PERMISSION_CONFIG_MITARBEITER, { configContext, });
      } else {
        await this.$store.dispatch(MENU_CONFIG_TYPES.ACTIONS.DELETE_OPTIONS_MENU_PERMISSION_CONFIG, { 
          userLevels: [ ...userLevels, ],
          configContext,
        });
      }
    },
    hasUserLevel(userLevel) {
      return this.configUserLevels.indexOf(userLevel) >= 0;
    },
    async copyUserLevelTo(userLevelSource, userLevelDest) {
      try {
        const destUserLevelTitle = this.tabList.find(tab => tab.key === userLevelDest)?.label || '';

        await this.$confirmModal({
          message: `Möchten Sie es wirklich in "${destUserLevelTitle}" übernehmen?`,
        });

        this.$store.dispatch(MENU_CONFIG_TYPES.ACTIONS.COPY_OPTIONS_MENU_CONFIG_TO_USER_LEVEL, {
          configContext: this.configContext,
          configId: this.configId, 
          userLevelSource, 
          userLevelDest, 
        });

        this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessageWith({
          message: `in "${destUserLevelTitle}" übernommen`,
          type: 'success',
          timeout: 2500,
        }));
      } catch(e) {
        // empty block
      }
    },
  },
  mounted() {
    this.$nextTick(this.init);
  },
}
</script>

<style lang="scss" scoped>
.option-menu-edit-page__buttons {
  flex-wrap: wrap;
}
</style>
