import CONFIG_DEFINITION_TYPES from '@/store/configDefinition/types';

import { ROLES, } from '@/router/roles';

import { PrimaryMenu, MenuItem, GroupMenu, TabMenu, ConditionalLabelsBuild, AsyncMenu, } from './../menu-utils';

import { PhUser, } from 'phosphor-vue';

import UndrawProfile from '@/components/icons/undraw/UndrawProfile.vue'
import UndrawSecureLogin from '@/components/icons/undraw/UndrawSecureLogin.vue'
import UndrawGDPR from '@/components/icons/undraw/UndrawGDPR.vue'
import UndrawPreferences from '@/components/icons/undraw/UndrawPreferences.vue'
import UndrawEducation from '@/components/icons/undraw/UndrawEducation.vue'
import UndrawAgreement from '@/components/icons/undraw/UndrawAgreement.vue'
import UndrawOnlineCalendar from '@/components/icons/undraw/UndrawOnlineCalendar.vue'
import UndrawDataPoints from '@/components/icons/undraw/UndrawDataPoints.vue'
import UndrawSecurity from '@/components/icons/undraw/UndrawSecurity.vue'

export default PrimaryMenu('/persoenlichedaten', 'Profil', PhUser, [
  MenuItem('/persoenlichedaten/customer-data', 'Kundendaten', UndrawProfile, [
    TabMenu('/persoenlichedaten/customer-data/steps/stammdaten', 'Stammdaten'),
    TabMenu('/persoenlichedaten/customer-data/steps/kommunikationsdaten', 'Kommunikations&shy;daten'),
    TabMenu('/persoenlichedaten/customer-data/steps/zusaetzliche-personen', 'Zusätzliche Personen'),
    TabMenu('/persoenlichedaten/customer-data/steps/sonstiges', 'Maklervertrag'),
    TabMenu('/persoenlichedaten/customer-data/steps/gruppenmitglieder', 'Gruppenmitglieder'),
    TabMenu('/persoenlichedaten/customer-data/steps/bankverbindung', 'Bankverbindung'),
    TabMenu('/persoenlichedaten/customer-data/steps/arbeitgeber', 'Arbeitgeber'),
    TabMenu('/persoenlichedaten/customer-data/steps/aenderungslog', 'Änderungslog'),
    TabMenu('/persoenlichedaten/customer-data/steps/hobbys-vorlieben', 'Hobbys und Vorlieben'),
    TabMenu('/persoenlichedaten/customer-data/steps/schulbildung-und-qualifikationen', 'Schulbildung und Qualifikationen'),
    TabMenu('/persoenlichedaten/customer-data/steps/pep', 'PEP / FATCA'),
    TabMenu('/persoenlichedaten/customer-data/steps/finanzielle-verhaltnisse', 'Finanzielle Verhältnisse'),
    TabMenu('/persoenlichedaten/customer-data/steps/einkommen-sozialversicherungen', 'Einkommen und Sozialversicherungen'),
    TabMenu('/persoenlichedaten/customer-data/steps/einkommen-sozialversicherungen-partner', 'Einkommen und Sozialversicherungen (Partner)'),
    TabMenu('/persoenlichedaten/customer-data/steps/courtage', 'Zubringercourtage'),
  ]),
  MenuItem('/persoenlichedaten/change-password', 'Passwort ändern', UndrawSecureLogin),
  MenuItem('/persoenlichedaten/change-2fa-interval', '2FA Intervall ändern', UndrawSecureLogin),
  MenuItem('/persoenlichedaten/privacy', 'Datenschutz&shy;erklärung', UndrawGDPR),
  MenuItem('/persoenlichedaten/grundlagenwissen-herunterladen', 'Grundlagenwissen herunterladen', UndrawEducation),
  GroupMenu('/persoenlichedaten/configs', 'Einstellungen', null, [
    AsyncMenu(CONFIG_DEFINITION_TYPES.ACTIONS.FIND_CONFIG_DEFINITION_OPTIONS_MENU, 'Einstellungen Asynchrone Menüs'),
    MenuItem('/persoenlichedaten/configs/fee-properties', ConditionalLabelsBuild().add('Dienstleistungsvertrag', [ROLES.FA]).add('Honorar / Serviceleistung Einstellungen').build()),
    MenuItem('/persoenlichedaten/configs/ex-post-manuelle', 'ExPost manuelle Kosten'),
    MenuItem('/persoenlichedaten/configs/configuration', 'Konfiguration'),
    MenuItem('/persoenlichedaten/configs/change-2fa-interval', '2FA Intervall ändern', UndrawSecureLogin),
  ]),
  MenuItem('/persoenlichedaten/settings', 'Zugangsverwaltung', UndrawPreferences, [
    TabMenu('/persoenlichedaten/settings/access-management', 'Zugangsverwaltung'),
    TabMenu('/persoenlichedaten/settings/change-2fa-interval', '2FA Intervall ändern', UndrawSecureLogin),
    TabMenu('/persoenlichedaten/settings/two-factor-sms-option', 'Zwei-Faktor-Authentifizierung per SMS (kostenpflichtig)'),
  ]),
  
  MenuItem('/persoenlichedaten/privacy-infos', 'Hinweise zum Datenschutz', UndrawSecurity),
  MenuItem('/persoenlichedaten/conflict-of-interests-principles', 'Zusatzinfo Interessenskonflikt', UndrawAgreement),
  MenuItem('/persoenlichedaten/bemerkungen', 'Bemerkungen'),
  MenuItem('/persoenlichedaten/pdf-kundendaten', 'Kundendaten <nobr>als PDF anzeigen</nobr>'), // TODO check this page, it is not following the design system for the base filter
  MenuItem('/persoenlichedaten/vermittlerwechselintern', 'Interner Vermittlerwechsel', UndrawDataPoints),
  MenuItem('/persoenlichedaten/kundennummern-gesellschaft', 'Kundennummern Gesellschaft'),
]);
