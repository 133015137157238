var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DragnDropArea",
    {
      staticClass: "depotnummer-anfordern-button-component mb-4",
      attrs: { hoverText: "Hochladen" },
      on: { files: _vm.onFileChange }
    },
    [
      _c("BaseFileSelect", { on: { files: _vm.onFileChange } }, [
        _vm._v(" Dokumente hochladen ")
      ]),
      _vm.config.dokumentenarchiv
        ? _c(
            "BaseButton",
            { attrs: { isPrimary: "" }, on: { click: _vm.handleAddDocuments } },
            [_vm._v(" Dokumentenarchiv ")]
          )
        : _vm._e(),
      _vm.antragData && _vm.antragData.antragsdatenId === ""
        ? _c("div", [
            _vm._v(
              " Dokumente werden beim ersten Speichern des Antrags hochgeladen "
            )
          ])
        : _vm._e(),
      _c("br"),
      _c("br"),
      _c(
        "div",
        [
          !_vm.loading && _vm.rows.length
            ? _c("Table", {
                attrs: {
                  headers: _vm.headers,
                  rows: _vm.rows,
                  rowsPerPage: 10
                },
                on: { "action-DELETE": _vm.doDelete },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "fileName",
                      fn: function(row) {
                        return [
                          row.fileId
                            ? _c("DownloadLink", {
                                attrs: {
                                  title: row.fileName || "Dokument",
                                  disabled: _vm.loading,
                                  href: _vm.simpleFileLinkmaker(
                                    row.fileName,
                                    row.fileId
                                  )
                                }
                              })
                            : _c("span", [_vm._v(_vm._s(row.fileName))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1198799061
                )
              })
            : _vm.loading
            ? _c(
                "div",
                { staticClass: "text-centered" },
                [_c("AnimatedSpinner")],
                1
              )
            : _c("div", [_vm._v("Keine Daten")])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }