import actions from './actions';
import mutations from './mutations';
import getters from './getters';

/**
 * mutations: synchronous operation responsible for change the state
 * actions: asynchronous operation responsible for call external resources and commit mutations 
 */

export function getInitialState() {
  return {
    selectedUnternr: {}, // index by configContext - it is used on Makler context when editing a Mitarbeiter data

    flatMenuConfig: {}, // index by configContext
    flatMenuConfigByPath: {}, // index by configContext and path
    userLevels: {}, // index by configContext

    // Options Menu Config
    optionsMenuConfig: {}, // index by configContext -> configId -> userLevel
    optionsMenuConfigEdited: {}, // index by configContext -> configId -> userLevel

    // Options Menu Permission Config
    optionsMenuPermissionConfig: {}, // index by configContext -> userLevel
    optionsMenuPermissionConfigEdited: {}, // index by configContext -> userLevel

    allOptionsMenuPermissionConfigAllMitarbeiter: [],
  };
}

export default {
  state: {
    ...getInitialState()
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  },
  getters: {
    ...getters
  },
}
