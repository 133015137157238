var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.hasAllImagesAdded && _vm.isEditable && _vm.isFA
        ? _c("div", { staticClass: "box__container" }, [
            _c(
              "div",
              { staticClass: "row mb-2" },
              [
                _c("InputToggleSwitch", {
                  attrs: {
                    label:
                      "Ich bestätige, dass die Kopie mit dem Original überein stimmt",
                    inLineLabel: "",
                    suppressValidationMessage: "",
                    disabled:
                      _vm.isLoading ||
                      !_vm.isEditable ||
                      !_vm.hasLegitimType ||
                      _vm.disabled
                  },
                  on: {
                    input: function($event) {
                      return _vm.$emit("addCustomerDataEdited")
                    }
                  },
                  model: {
                    value: _vm.legitimation.istBestaetigt,
                    callback: function($$v) {
                      _vm.$set(_vm.legitimation, "istBestaetigt", $$v)
                    },
                    expression: "legitimation.istBestaetigt"
                  }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _c("div", { staticClass: "scans-container" }, [
        _c(
          "div",
          { staticClass: "box__container" },
          [
            _c("ImageUpload", {
              staticClass: "scan-container",
              attrs: {
                title: "Vorderseite",
                hoverText: "Vorderseite hier ablegen",
                edgeCorrection: "",
                image: _vm.vorderseiteImage,
                loading: _vm.isLoading && !!_vm.fileToUpload.vorderseite,
                disabled:
                  _vm.isLoading ||
                  !_vm.isEditable ||
                  !_vm.hasLegitimType ||
                  _vm.disabled,
                config: _vm.imageUploadConfig,
                photoCamera: "back",
                photoHighQuality: ""
              },
              on: {
                file: function($event) {
                  return _vm.convertFileIfNeeded([$event], true)
                },
                change: function($event) {
                  return _vm.updateImage($event, true)
                }
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "box__container" },
          [
            _c("ImageUpload", {
              staticClass: "scan-container",
              attrs: {
                title: "Rückseite",
                hoverText: "Rückseite hier ablegen",
                edgeCorrection: "",
                image: _vm.rueckseiteImage,
                loading: _vm.isLoading && !!_vm.fileToUpload.rueckseite,
                disabled:
                  _vm.isLoading ||
                  !_vm.isEditable ||
                  _vm.isRueckseiteUploadDisabled ||
                  _vm.disabled,
                config: _vm.imageUploadConfig,
                photoCamera: "back",
                photoHighQuality: ""
              },
              on: {
                file: function($event) {
                  return _vm.convertFileIfNeeded([$event], false)
                },
                change: function($event) {
                  return _vm.updateImage($event, false)
                }
              }
            })
          ],
          1
        ),
        _vm.isMeldebescheinigungUploadVisible
          ? _c(
              "div",
              { staticClass: "box__container" },
              [
                _c("ImageUpload", {
                  staticClass: "scan-container",
                  attrs: {
                    title: "Meldebescheinigung",
                    hoverText: "Meldebescheinigung hier ablegen",
                    edgeCorrection: "",
                    image: _vm.meldebescheinigungImage,
                    loading:
                      _vm.isLoading && !!_vm.fileToUpload.meldebescheinigung,
                    disabled: _vm.isLoading || !_vm.isEditable || _vm.disabled,
                    config: _vm.imageUploadConfig,
                    photoCamera: "back",
                    photoHighQuality: ""
                  },
                  on: {
                    file: function($event) {
                      return _vm.convertFileIfNeeded([$event], false, true)
                    },
                    change: function($event) {
                      return _vm.updateImage($event, false, true)
                    }
                  }
                })
              ],
              1
            )
          : _vm._e()
      ]),
      _vm.hasImageAdded && _vm.isEditable && !_vm.isSmallScreen
        ? _c("div", { staticClass: "box__container" }, [
            _c("div", { staticClass: "row mb-0" }, [
              _c(
                "div",
                { staticClass: "col-auto" },
                [
                  _c(
                    "BaseButton",
                    {
                      attrs: {
                        isSecondary: "",
                        disabled: _vm.isLoading || _vm.disabled
                      },
                      on: {
                        click: function($event) {
                          return _vm.$refs.deleteImages.open()
                        }
                      }
                    },
                    [_vm._v("Gespeicherte Ausweisfotos löschen")]
                  ),
                  _vm.hasAllImagesAdded ||
                  (_vm.legitimation &&
                    _vm.hasVorderseiteImage &&
                    (_vm.legitimation.legitimType == "Reisepass" ||
                      _vm.isRueckseiteUploadDisabled))
                    ? [
                        _vm.mitarbeiterId
                          ? _c("div")
                          : _vm.isFA &&
                            (_vm.legitimationsurkundeChanged ||
                              (_vm.legitimation.istBestaetigt &&
                                (!_vm.legitimation.signatureInfo ||
                                  (_vm.legitimation.signatureInfo &&
                                    !_vm.legitimation.signatureInfo.fileId))))
                          ? _c(
                              "BaseButton",
                              {
                                attrs: {
                                  disabled: _vm.isLoading || _vm.disabled
                                },
                                on: { click: _vm.saveLegitimation }
                              },
                              [_vm._v(" Generiere ein PDF Dokument ")]
                            )
                          : _vm.isFA &&
                            _vm.legitimation.signatureInfo &&
                            _vm.legitimation.signatureInfo.fileId &&
                            !_vm.legitimation.signatureInfo.isSigned &&
                            _vm.legitimation.signatureInfo.signoURL
                          ? _c("SignoViewerLink", {
                              attrs: {
                                asButton: "",
                                title: "Signieren",
                                requestURL: "",
                                extraParams: "noRedirect=true",
                                href: _vm.getSignoviewerLink,
                                disabled: _vm.disabled
                              }
                            })
                          : _c("DownloadLink", {
                              attrs: {
                                asButton: "",
                                title: "Herunterladen",
                                disabled: _vm.isLoading || _vm.disabled,
                                href: _vm.getDownloadLink
                              }
                            })
                      ]
                    : _vm._e()
                ],
                2
              )
            ])
          ])
        : _vm._e(),
      _c(
        "BaseModal",
        {
          ref: "confirmReadImageModal",
          attrs: {
            modalTitle: "Bestätigung",
            labelButtonConfirm: "Ja",
            labelButtonCancel: "Nein"
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.readImage(_vm.tempFiles, _vm.tempFilesFront)
            }
          }
        },
        [_vm._v(" Möchten Sie die Daten aus dem Ausweis auslesen lassen? ")]
      ),
      _c(
        "BaseModal",
        {
          ref: "deleteImages",
          attrs: {
            modalTitle: "Löschen Legitimationsurkunde",
            labelButtonConfirm: "Ja",
            labelButtonCancel: "Nein"
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.deleteImages()
            }
          }
        },
        [_vm._v(" Möchten Sie die gespeicherte Legitimationsurkunde löschen? ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }