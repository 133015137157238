<template>
    <BaseModal ref="modal"
        @onCloseButton="close"
        :showDefaultButtons="false">
        <template v-slot:modalTitle>
            <InputField label="Bisheriges Passwort" v-model="oldPassword" type="password"/>
            <InputField label="Neues Passwort (Mindestens 6 Zeichen)" v-model="newPassword" type="password"/>
            <InputField label="Neues Passwort bestätigen" v-model="newPasswordRetype" type="password"/>
        </template>
        <template v-slot:footer>
            <BaseButton @click="changeLoginName" :disabled="!isValid" isPrimary>Passwort ändern</BaseButton>
        </template>
    </BaseModal>
</template>
<script>
import SSO_TYPES from '@/store/sso/types';
import { mapGetters } from 'vuex';

import BaseModal from '@/components/core/BaseModal.vue';
import InputField from '@/components/core/forms/InputField.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import validator from '@/mixins/validator/index.js';
import { minLength } from "@/mixins/validator/rules";

export default {
    mixins: [validator],
    validators: {
        newPassword: minLength(6, 'Neues Passwort muss mindestens 6 Zeichen lang sein.'),
        newPasswordRetype: [
            {
            isValid: (value, self) => {
                return !value || value === self.newPassword
            },
            getMessage: () => 'Fehler. Passwörter stimmen nicht überein!',
            }
        ]
    },
    components: {
        BaseModal,
        InputField,
        BaseButton,
    },
    emits: ['close'],
    computed: {
        ...mapGetters({
        }),
        isValid() {
            return this.oldPassword !== this.newPassword && this.newPassword === this.newPasswordRetype && this.validation?.newPassword?.valid && this.validation?.newPasswordRetype?.valid;
        }
    },
    data() {
        return {
            oldPassword: "",
            newPassword: "",
            newPasswordRetype: "",
            errorStr: "",
        };
    },
    mounted() {
        this.$refs.modal.open();
    },
    methods: {
        changeLoginName() {
            this.$store.dispatch(SSO_TYPES.ACTIONS.CHANGE_PASSWORD, {
                newPassword: this.newPassword,
                newPasswordRetype: this.newPasswordRetype,
                password: this.oldPassword,
            }).then(response => {
                 if (response?.data?.errorStr) {
                    if (response.data.loginName) {
                         this.$pushErrors('newPassword', response.data.errorStr);
                    } else if (response.data.oldPassword) {
                         this.$pushErrors('oldPassword', response.data.errorStr);
                    } else {
                         this.errorStr = response.data.errorStr;
                    }
                } else {
                    this.$store.dispatch(SSO_TYPES.ACTIONS.GET_SSO_ACCOUNT_INFO);
                    this.close();
                }
            }).catch(error => {
                this.errorStr = "Das Passwort konnte nicht geändert werden. Bitte überprüfen Sie ihre Eingaben.";
            });
        },
        close() {
            this.$refs.modal.close();
            this.$emit('close');
        },
    },
}
</script>
<style scoped>

</style>