<template>
  <div>
    <div v-show="!$isSmallScreen">
      <PageHeaderTitleNavigation
        title="Postfach"
        subtitle="E-Mail"
        :actions="headerActions"
        @action-REPLY="onReplyChat"
        @action-FORWARD="onReply"
        @action-WEITERLEITEN_USER="handleActionWeiterleitenUser"
        @action-WEITERLEITEN_COMPANY="handleActionWeiterleitenCompany"
      />
    </div>

    <BasePageContent
      modalTitle="Postfach - Email"
      defaultLastAction="/communication/postfach/emails"
      :modalActions="modalHeaderActions"
      @action-REPLY="onReplyChat"
      @action-FORWARD="onReply"
      @action-WEITERLEITEN_USER="handleActionWeiterleitenUser"
      @action-WEITERLEITEN_COMPANY="handleActionWeiterleitenCompany"
    >
      <div class="box__container" v-if="email">
        <div class="email-title--data">
          <div class="box__title">{{ email.subject }}</div>
          <div>{{ email.dateSend }}</div>
        </div>
        <div class="email-header--data" v-if="email.mailInfo  && email.mailInfo.trim().length>0">
          <span>{{ email.mailInfo}}</span>
        </div>
        <div class="email-header--data">
          <span>Von</span>
          <span>{{ email.fromAddress }}</span>
          <span>An</span>
          <span>{{ email.toAddress }}</span>
          <template v-if="email.ccAddress">
            <span>CC</span>
            <span>{{ email.ccAddress }}</span>
          </template>
          <template v-if="isBrokerOrBypass">
            <span>Status</span>
            <span
              :class="`color-${email.status}`"
              v-html="sanitize(email.statusText)"
            ></span>
          </template>
        </div>
      </div>

      <div
        class="box__container"
        v-if="email && email.attachments && email.attachments.length"
      >
        Anhang
        <hr />
        <div class="d-flex attachments-lines">
          <div
            v-for="attachment in email.attachments"
            :key="attachment.id"
            class="d-flex flex-row"
          >
            <div>
              <PhImage
                v-if="
                  attachment.contentType == 'image/png' ||
                    attachment.contentType == 'image/jpeg'
                "
                :size="16"
              />
              <PhMusicNotes
                v-else-if="attachment.contentType == 'audio/mpeg'"
                :size="16"
              />
              <PhMonitorPlay
                v-else-if="attachment.contentType == 'video/mp4'"
                :size="16"
              />
              <PhFileText v-else :size="16" />
            </div>
            <DownloadLink
              :title="attachment.fileName"
              :filename="attachment.fileName"
              downloadServicePath="/get_email_attachment"
              :queryParams="{
                attId: attachment.id,
                tempFileId: attachment.tempFileId,
              }"
            />
          </div>
        </div>
      </div>
      <GhostLoading v-else-if="loading" type="block" useBoxContainer />

      <div class="box__container" v-if="email">
        <div v-html="sanitizedText" class="email-body"></div>
      </div>
      <GhostLoading
        v-else-if="loading"
        type="block"
        :config="{ block: { height: 500 } }"
        useBoxContainer
      />
    </BasePageContent>

    <BaseModal
      ref="mailContacts"
      modalTitle="Kontakte"
      size="lg"
      :showCancelButton="false"
      labelButtonConfirm="Auswahl übernehmen"
      @onConfirmButton="confirmContactsToSend"
    >
      <InsuranceCompanyMailContacts
        :records="mailUsers"
        @selectedRows="selectRowsContacts"
      />
    </BaseModal>
  </div>
</template>

<script>
import COMMUNICATION_TYPES from "@/store/communication/types";
import MAILCOMPOSER_TYPES from "@/store/mailcomposer/types";
import CORE_TYPES from "@/store/core/types";
import { mapGetters } from "vuex";
import sanitizeHtml from "sanitize-html";
import { sanitize } from "@/helpers/string-helper.js";
import {
  PhMagnifyingGlass,
  PhImage,
  PhMusicNotes,
  PhMonitorPlay,
  PhFileText,
  PhPaperPlaneRight,
  PhShare,
} from "phosphor-vue";
import OptionMenu from "@/components/core/option-menu/OptionMenu.vue";
import BasePageContent from "@/components/core/BasePageContent.vue";
import PageHeaderTitleNavigation from "@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue";
import DownloadLink from "@/components/core/download/DownloadLink.vue";
import GhostLoading from "@/components/core/loading/GhostLoading.vue";
import { ROLES } from "@/router/roles";
import {
  PageHeaderSimpleAction,
  PageHeaderDownloadLinkAction,
} from "@/components/core/header-title-navigation/page-header-utils";
import BaseModal from "@/components/core/BaseModal.vue";
import InsuranceCompanyMailContacts from "@/views/versicherungen/InsuranceCompanyMailContacts.vue";
import {
  BaseModalSimpleAction,
  BaseModalDownloadLinkAction,
} from "@/components/core/base-modal-actions/base-modal-actions-utils";

export default {
  components: {
    PhMagnifyingGlass,
    PhImage,
    PhMusicNotes,
    PhMonitorPlay,
    PhFileText,
    OptionMenu,
    PageHeaderTitleNavigation,
    DownloadLink,
    GhostLoading,
    BaseModal,
    InsuranceCompanyMailContacts,
    BasePageContent,
  },
  props: {},
  computed: {
    ...mapGetters({
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      emails: COMMUNICATION_TYPES.GETTERS.EMAILS,
      sanitizeHtmlConfig: COMMUNICATION_TYPES.GETTERS.SANITIZE_HTML_CONFIG,
      isBrokerOrBypass: CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS,
      isCustomer: CORE_TYPES.GETTERS.IS_CUSTOMER,
      initMailData: MAILCOMPOSER_TYPES.GETTERS.GET_INIT,
    }),
    appContactEnabled() {
      return (
        this.hasRoles(ROLES.DATA_PRIVACY_APP) &&
        !(this.email && this.email.notAllowRetry)
      );
    },
    email() {
      return this.emails[this.commId];
    },
    sanitizedText() {
      return sanitizeHtml(this.email.text, this.sanitizeHtmlConfig);
    },
    headerActions() {
      const { email, commId, appContactEnabled, isBrokerOrBypass } = this;
      const actions = [];
      if (this.$route.meta.forward && this.$route.params.insuranceId) {
        actions.push(
          PageHeaderSimpleAction(
            "WEITERLEITEN_COMPANY",
            "Weiterleiten an Gesellschaft"
          )
        );
      } else if (appContactEnabled) {
        const replayAction = PageHeaderSimpleAction("REPLY", "Antworten");
        actions.push(replayAction.withIcon(PhPaperPlaneRight));
      }

      actions.push(
        PageHeaderDownloadLinkAction(
          "DOWNLOAD",
          "PDF",
          `${email?.subject || "Postfach_Email"}.pdf`,
          "/email_as_pdf",
          { emailId: commId }
        )
      );
      if (this.email?.insurance && isBrokerOrBypass) {
        if (
          this.email.subject.toLowerCase().includes("pol.nr.") ||
          this.email.subject.toLowerCase().includes("pol. nr.") ||
          this.email.subject.toLowerCase().includes("[[fkv") ||
          this.email.subject.toLowerCase().includes("{{fkv")
        ) {
          actions.push(
            PageHeaderSimpleAction(
              "WEITERLEITEN_COMPANY",
              "Weiterleiten an Gesellschaft"
            ).withIcon(PhShare)
          );
          actions.push(
            PageHeaderSimpleAction(
              "WEITERLEITEN_USER",
              "Weiterleiten an Kunde"
            ).withIcon(PhShare)
          );
        }
      } else if (isBrokerOrBypass) {
        actions.push(
          PageHeaderSimpleAction("FORWARD", "Weiterleiten").withIcon(PhShare)
        );
      }
      return actions;
    },
    modalHeaderActions() {
      const { email, commId, appContactEnabled, isBrokerOrBypass } = this;

      if (!email) {
        return [];
      }

      const actions = [];
      if (this.$route.meta.forward && this.$route.params.insuranceId) {
        actions.push(
          BaseModalSimpleAction(
            "WEITERLEITEN_COMPANY",
            "Weiterleiten an Gesellschaft"
          )
        );
      } else if (appContactEnabled) {
        if (!this.email.insurance) {
          actions.push(BaseModalSimpleAction("REPLY", "Antworten"));
        }
      }
      actions.push(
        BaseModalDownloadLinkAction(
          "DOWNLOAD",
          "PDF",
          `${email?.subject || "Postfach_Email"}.pdf`,
          "/email_as_pdf",
          { emailId: commId }
        )
      );
      if (this.email.insurance && isBrokerOrBypass) {
        if (
          this.email.subject.toLowerCase().includes("pol.nr.") ||
          this.email.subject.toLowerCase().includes("pol. nr.") ||
          this.email.subject.toLowerCase().includes("[[fkv") ||
          this.email.subject.toLowerCase().includes("{{fkv")
        ) {
          actions.push(
            BaseModalSimpleAction(
              "WEITERLEITEN_COMPANY",
              "Weiterleiten an Gesellschaft"
            )
          );
          actions.push(
            BaseModalSimpleAction("WEITERLEITEN_USER", "Weiterleiten an Kunde")
          );
        }
      } else if (isBrokerOrBypass) {
        actions.push(BaseModalSimpleAction("FORWARD", "Weiterleiten"));
      }
      return actions;
    },
  },
  data() {
    return {
      commId: this.$route.params.commId,
      loading: false,
      selectedRowsContacts: [],
      rowSelected: {},
      mailUsers: [],
    };
  },
  mounted() {
    this.loadEmail();
  },
  watch: {
    initMailData() {
      this.mailUsers = this.initMailData?.participants;
    },
  },
  methods: {
    sanitize(htmlString) {
      return sanitize(htmlString);
    },
    async loadEmail() {
      try {
        this.loading = true;
        await this.$store.dispatch(
          COMMUNICATION_TYPES.ACTIONS.LOAD_EMAIL,
          this.commId
        );
      } finally {
        this.loading = false;
      }
    },
    hasPreview(contentType) {
      return (
        contentType &&
        (contentType.indexOf("image") >= 0 || contentType.indexOf("pdf") >= 0)
      );
    },
    onReplyChat() {
      this.$router.push({
        name: "chat",
        params: { chatId: "0", channelId: "0", emailId: this.commId },
      });
    },
    onReply() {
      this.$router.push({
        name: "mailcomposer-postfach",
        params: {
          subject: "Fwd: " + this.email.subject,
          copiedFiles: this.email.attachments,
          content:
            "<br><br>-------- Weitergeleitete Nachricht --------<br><i>" +
            "<u>Betreff:</u> " +
            this.email.subject +
            "<br>" +
            "<u>Datum:</u> " +
            this.email.dateSend +
            "<br>" +
            "<u>Von:</u> " +
            this.email.fromAddress +
            "<br>" +
            "<u>An:</u> " +
            this.email.toAddress +
            "</i><br>" +
            this.email.text,
        },
      });
    },
    handleActionWeiterleitenUser(row) {
      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAILCOMPOSER_CONFIG, {
        type: "mailcomposer-insurance",
        action: "clear",
      });
      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, {
        type: "mailcomposer-insurance",
        action: "clear",
      });
      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAIL_LINKED_OBJECT, {
        data: [this.email?.insurance],
        toCompany: false,
        participants: undefined,
      });
      this.$router.push({
        name: "mailcomposer-insurance",
        params: {
          subject: "Fwd: " + this.email.subject,
          copiedFiles: this.email.attachments,
          content:
            "<br><br>-------- Weitergeleitete Nachricht --------<br><i>" +
            "<u>Betreff:</u> " +
            this.email.subject +
            "<br>" +
            "<u>Datum:</u> " +
            this.email.dateSend +
            "<br>" +
            "<u>Von:</u> " +
            this.email.fromAddress +
            "<br>" +
            "<u>An:</u> " +
            this.email.toAddress +
            "</i><br>" +
            this.email.text,
        },
      });
    },
    handleActionWeiterleitenCompany(row) {
      this.rowSelected = row;
      let insuranceId = this.email?.insurance;
      if (this.$route.meta.forward && this.$route.params.insuranceId) {
        insuranceId = this.$route.params.insuranceId;
      }
      this.startCompanySelector(insuranceId);
    },
    startCompanySelector(insurance) {
      this.$store
        .dispatch(MAILCOMPOSER_TYPES.ACTIONS.GET_INIT, {
          insurance,
          toCompany: true,
        })
        .then((response) => {
          if (
            response?.data?.participants &&
            response?.data?.participants?.length
          ) {
            this.$refs.mailContacts.open();
          } else {
            this.sendMailWeiterleitenCompany();
          }
        });
    },
    confirmContactsToSend() {
      this.$refs.mailContacts.close();
      this.sendMailWeiterleitenCompany();
    },
    selectRowsContacts(rows) {
      this.selectedRowsContacts = rows;
    },
    sendMailWeiterleitenCompany() {
      let insuranceId = this.email?.insurance;
      if (this.$route.meta.forward && this.$route.params.insuranceId) {
        insuranceId = this.$route.params.insuranceId;
      }
      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, {
        type: "mailcomposer-insurance",
        action: "clear",
      });
      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAILCOMPOSER_CONFIG, {
        type: "mailcomposer-insurance",
        action: "clear",
      });
      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAIL_LINKED_OBJECT, {
        data: [insuranceId],
        toCompany: true,
        participants: this.selectedRowsContacts || [],
      });
      this.$router.push({
        name: "mailcomposer-insurance",
        params: {
          subject: "Fwd: " + this.email.subject,
          copiedFiles: this.email.attachments,
          content:
            "<br><br>-------- Weitergeleitete Nachricht --------<br><i>" +
            "<u>Betreff:</u> " +
            this.email.subject +
            "<br>" +
            "<u>Datum:</u> " +
            this.email.dateSend +
            "<br>" +
            "<u>Von:</u> " +
            this.email.fromAddress +
            "<br>" +
            "<u>An:</u> " +
            this.email.toAddress +
            "</i><br>" +
            this.email.text,
        },
        query: {
          ...(this.$route.meta.forward && this.$route.params.userId
            ? { userId: this.$route.params.userId }
            : {}),
        },
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({ to, from });
    next();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .email-body p {
  min-height: 0.1px;
  margin: 0;
}

::v-deep .email-body table {
  max-width: 100%;
}

.email-title--data {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 4px 10px;
}

.email-header--data {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 4px 10px;
}

.flex-row {
  margin-right: 12px;
}

.flex-row > * {
  box-sizing: border-box;
  padding: 0 5px;
}

.attachments-lines {
  flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
  .flex-row > * {
    padding: 5px;
  }
}
</style>
