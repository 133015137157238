var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "box__container" }, [
        _c("div", { staticClass: "box__title" }, [
          _vm._v("PEP - Politisch exponierte Person")
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c("InputToggleSwitch", {
                attrs: {
                  label: "Politisch exponierte Person / VIP",
                  inLineLabel: true
                },
                on: {
                  input: function($event) {
                    return _vm.addCustomerDataEdited("pepActive", $event)
                  }
                },
                model: {
                  value: _vm.pepActive,
                  callback: function($$v) {
                    _vm.pepActive = $$v
                  },
                  expression: "pepActive"
                }
              })
            ],
            1
          )
        ]),
        _vm.pepActive
          ? _c("div", { staticClass: "forms__input--half-size" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-lg-7 col-md-8 col-12" },
                  [
                    _c("InputField", {
                      attrs: { label: "Funktion", disabled: true },
                      model: {
                        value: _vm.pepText,
                        callback: function($$v) {
                          _vm.pepText = $$v
                        },
                        expression: "pepText"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-lg-5 col-md-4 col-12 mt-lg-24px mt-md-24px mt-sm-16px"
                  },
                  [
                    _c(
                      "BaseButton",
                      {
                        staticClass: "mt-0",
                        attrs: { isSecondary: "", disabled: true }
                      },
                      [_vm._v("PEP-WIS Abgleich")]
                    )
                  ],
                  1
                )
              ])
            ])
          : _vm._e()
      ]),
      _c("Fatca"),
      _vm.pepActive ? [_vm.isFirma ? _c("Firma") : _c("Privat")] : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }