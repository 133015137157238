var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: _vm.$appNavigation.currentMenu.label,
          defaultMenu: _vm.customOptionMenu
        }
      }),
      _c(
        "KeepAliveOnBackNavigation",
        [
          _c("WertpapierSearch", {
            attrs: {
              name: _vm.searchKey,
              type: _vm.wi_type,
              canAddToFavoritenliste: _vm.isBrokerOrBypass
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }