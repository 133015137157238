export const MUTATION_PREFIX = 'CORE: ';
export const ACTIONS_PREFIX = 'CORE_ACTIONS_';
export const GETTERS_PREFIX = 'CORE_GETTERS_';

export default {
  MUTATIONS: {
    GLOBAL_LOADING_STATE_START: MUTATION_PREFIX + 'GLOBAL_LOADING_STATE_START ⏳',
    GLOBAL_LOADING_STATE_STOP: MUTATION_PREFIX + 'GLOBAL_LOADING_STATE_STOP ⌛',

    SET_INTERNET_ACTIVE: MUTATION_PREFIX + 'SET_INTERNET_ACTIVE',

    SET_LOGGING_IN: MUTATION_PREFIX + 'SET_LOGGING_IN',
    SET_LOGGING_OUT: MUTATION_PREFIX + 'SET_LOGGING_OUT',

    LOGIN_SUCCESS: MUTATION_PREFIX + 'LOGIN_SUCCESS',
    LOGIN_FAIL: MUTATION_PREFIX + 'LOGIN_FAIL',

    SET_LOADING_TOKEN: MUTATION_PREFIX + 'SET_LOADING_TOKEN',
    SET_MANAGING_LOGIN_RESPONSE: MUTATION_PREFIX + 'SET_MANAGING_LOGIN_RESPONSE',
    GET_BROKER_INFORMATION_SUCCESS: MUTATION_PREFIX + 'GET_BROKER_INFORMATION_SUCCESS',
    GET_BROKER_LAYOUT_SUCCESS: MUTATION_PREFIX + 'GET_BROKER_LAYOUT_SUCCESS',
    SET_CONFIGURED_COLOR_SCHEME: MUTATION_PREFIX + 'SET_CONFIGURED_COLOR_SCHEME',
    GET_USER_PICTURE_SUCCESS: MUTATION_PREFIX + 'GET_USER_PICTURE_SUCCESS',

    MERGE_PENDING_ACTIONS: MUTATION_PREFIX + 'MERGE_PENDING_ACTIONS',
    REMOVE_PENDING_ACTION: MUTATION_PREFIX + 'REMOVE_PENDING_ACTION',
    MARK_PENDING_ACTION_AS_DONE: MUTATION_PREFIX + 'MARK_PENDING_ACTION_AS_DONE',
    MARK_PENDING_ACTION_AS_SKIPPED: MUTATION_PREFIX + 'MARK_PENDING_ACTION_AS_SKIPPED',
    UPDATE_TIMEOUT_SCHEDULE: MUTATION_PREFIX + 'UPDATE_TIMEOUT_SCHEDULE',
    CHANGE_FLAG_TIMEOUT_VISIBILITY_CHANGE: MUTATION_PREFIX + 'CHANGE_FLAG_TIMEOUT_VISIBILITY_CHANGE',
    SET_LOGOUT_RUNNING: MUTATION_PREFIX + 'SET_LOGOUT_RUNNING',

    ADD_COLOR_SCHEMA: MUTATION_PREFIX + 'ADD_COLOR_SCHEMA 🎨',
    SET_PREFERRED_COLOR_SCHEMA: MUTATION_PREFIX + 'SET_PREFERRED_COLOR_SCHEMA',

    RECORDING_TUTORIAL_START: MUTATION_PREFIX + 'RECORDING_TUTORIAL_START',
    RECORDING_TUTORIAL_STOP: MUTATION_PREFIX + 'RECORDING_TUTORIAL_STOP',

    PLAYING_TUTORIAL_START: MUTATION_PREFIX + 'PLAYING_TUTORIAL_START',
    PLAYING_TUTORIAL_STOP: MUTATION_PREFIX + 'PLAYING_TUTORIAL_STOP',

    ADD_RECORDED_ITEM: MUTATION_PREFIX + 'ADD_RECORDED_ITEM',
    REMOVE_RECORDED_ITEM: MUTATION_PREFIX + 'REMOVE_RECORDED_ITEM',
    UPDATE_RECORDED_ITEM: MUTATION_PREFIX + 'UPDATE_RECORDED_ITEM',
    SET_SECONDARY_MENU: MUTATION_PREFIX + 'SET_SECONDARY_MENU',

    RECORDING_TUTORIAL_TITLE: MUTATION_PREFIX + 'RECORDING_TUTORIAL_TITLE',

    SEND_TUTORIAL_SUCCESS: MUTATION_PREFIX + 'SEND_TUTORIAL_SUCCESS',
    SEND_TUTORIAL_FAIL: MUTATION_PREFIX + 'SEND_TUTORIAL_FAIL',

    RETRIEVE_TUTORIALS_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_TUTORIALS_SUCCESS',

    RECORDING_TUTORIAL_ROUTE: MUTATION_PREFIX + 'RECORDING_TUTORIAL_ROUTE',
    RECORDING_TUTORIAL_USER_TYPE: MUTATION_PREFIX + 'RECORDING_TUTORIAL_USER_TYPE',

    RECORDED_TUTORIALS: MUTATION_PREFIX + 'RECORDED_TUTORIALS',

    RECORDING_FEATURE_ENABLE: MUTATION_PREFIX + 'RECORDING_FEATURE_ENABLE',
    RECORDING_FEATURE_DISABLE: MUTATION_PREFIX + 'RECORDING_FEATURE_DISABLE',

    REMOVE_ALL_RECORDED_ITEMS: MUTATION_PREFIX + 'REMOVE_ALL_RECORDED_ITEMS',
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',

    REPLACE_VIEW_ROLES: MUTATION_PREFIX + 'REPLACE_VIEW_ROLES',
    ADD_BELATED_ROLE: MUTATION_PREFIX + 'ADD_BELATED_ROLE',

    RECORDING_TUTORIAL_LAST_ID: MUTATION_PREFIX + 'RECORDING_TUTORIAL_LAST_ID',
    
    SET_SOCIAL_MEDIA_SRC: MUTATION_PREFIX + 'SET_SOCIAL_MEDIA_SRC',
    SET_HAS_HOMEPAGE_ROLE: MUTATION_PREFIX + 'SET_HAS_HOMEPAGE_ROLE',

    GET_ALL_CMS_META_TAG_SUCCESS: MUTATION_PREFIX + 'GET_ALL_CMS_META_TAG_SUCCESS',
    
    UPDATE_BROKER_LOGO: MUTATION_PREFIX + 'UPDATE_BROKER_LOGO',
    UPDATE_BROKER_IMAGE_BACKGROUND_LOGIN_URL: MUTATION_PREFIX + 'UPDATE_BROKER_IMAGE_BACKGROUND_LOGIN_URL',

    UPDATE_LOGIN_WELCOME_TITLE: MUTATION_PREFIX + 'UPDATE_LOGIN_WELCOME_TITLE',

    PUSH_ORIGINAL_USER: MUTATION_PREFIX + 'PUSH_ORIGINAL_USER',
    POP_ORIGINAL_USER: MUTATION_PREFIX + 'POP_ORIGINAL_USER',

    GET_SYSTEM_DATA_SUCCESS: MUTATION_PREFIX + 'GET_SYSTEM_DATA_SUCCESS',

    GET_MFA_CHALLENGE_TYPE_SUCCESS: MUTATION_PREFIX + 'GET_MFA_CHALLENGE_TYPE_SUCCESS',

    GET_APP_LINKS_SUCCESS: MUTATION_PREFIX + 'GET_APP_LINKS_SUCCESS',
    LOCK_ADD_BREADCRUMB: MUTATION_PREFIX + 'LOCK_ADD_BREADCRUMB',
    PUSH_SAVE_BACK_TO_PREVIOUS_PAGE: MUTATION_PREFIX + 'PUSH_SAVE_BACK_TO_PREVIOUS_PAGE',
    POP_SAVE_BACK_TO_PREVIOUS_PAGE: MUTATION_PREFIX + 'POP_SAVE_BACK_TO_PREVIOUS_PAGE',
    REPLACE_BACK_TO_PREVIOUS_PAGE: MUTATION_PREFIX + 'REPLACE_BACK_TO_PREVIOUS_PAGE',
    SET_BACK_TO_PREVIOUS_PAGE_TRACK: MUTATION_PREFIX + 'SET_BACK_TO_PREVIOUS_PAGE_TRACK',
    UPDATE_CURRENT_BACK_TO_PREVIOUS_PAGE: MUTATION_PREFIX + 'UPDATE_CURRENT_BACK_TO_PREVIOUS_PAGE',
    SAVE_BACK_TO_PREVIOUS_PAGE_SHIFT: MUTATION_PREFIX + 'SAVE_BACK_TO_PREVIOUS_PAGE_SHIFT',

    GET_PRIVACY_DATA_SUCCESS: MUTATION_PREFIX + 'GET_PRIVACY_DATA_SUCCESS',
    UPDATE_PRIVACY: MUTATION_PREFIX + 'UPDATE_PRIVACY',

    UPDATE_USER_FULL_NAME: MUTATION_PREFIX + 'UPDATE_USER_FULL_NAME',

    SET_REGISTRATION_DATA: MUTATION_PREFIX + 'SET_REGISTRATION_DATA',
    GET_PRIVACY_REGISTRATION_DATA_SUCCESS: MUTATION_PREFIX + 'GET_PRIVACY_REGISTRATION_DATA_SUCCESS',
    RESET_PRIVACY_REGISTRATION_DATA: MUTATION_PREFIX + 'RESET_PRIVACY_REGISTRATION_DATA',
    CLEAR_ALL_REGISTRATION_DATA: MUTATION_PREFIX + 'CLEAR_ALL_REGISTRATION_DATA',

    GET_CAPTCHA_RESULT: MUTATION_PREFIX + 'GET_CAPTCHA_RESULT',

    GET_DEFAULT_LAYOUT_SUCCESS: MUTATION_PREFIX + 'GET_DEFAULT_LAYOUT_SUCCESS',
    GET_LAYOUT_SUCCESS: MUTATION_PREFIX + 'GET_LAYOUT_SUCCESS',

    ADD_CONFIRM_MODAL: MUTATION_PREFIX + 'ADD_CONFIRM_MODAL',
    REPLY_CONFIRM_MODAL: MUTATION_PREFIX + 'REPLY_CONFIRM_MODAL',
    
    SCREEN_WIDTH: MUTATION_PREFIX + 'SCREEN_WIDTH',
    CHECK_PIN: MUTATION_PREFIX + 'CHECK_PIN',
    RESET_EVENT_BASE_FILTER: MUTATION_PREFIX + 'RESET_EVENT_BASE_FILTER',
    MSC_BUILD_INFO: MUTATION_PREFIX + 'MSC_BUILD_INFO',
    MARK_INITIAL_APP_LOADED: MUTATION_PREFIX + 'MARK_INITIAL_APP_LOADED',
    UPDATE_IS_INAKTIV_SUCCESS: MUTATION_PREFIX + 'UPDATE_IS_INAKTIV_SUCCESS',

    ADD_GENERIC_SELECTION: MUTATION_PREFIX + 'ADD_GENERIC_SELECTION',
    REMOVE_GENERIC_SELECTION: MUTATION_PREFIX + 'REMOVE_GENERIC_SELECTION',

    CHAT_EMPFAENGER_KEY:MUTATION_PREFIX+'CHAT_EMPFAENGER_KEY',
    EXCEPTION_LOG: MUTATION_PREFIX + 'EXCEPTION_LOG',
  },
  ACTIONS: {
    LOGIN: ACTIONS_PREFIX + 'LOGIN',
    TOKEN_LOGIN: ACTIONS_PREFIX + 'TOKEN_LOGIN',
    MANAGE_LOGIN_RESPONSE: ACTIONS_PREFIX + 'MANAGE_LOGIN_RESPONSE',
    GET_BROKER_INFORMATION: ACTIONS_PREFIX + 'GET_BROKER_INFORMATION',
    GET_USER_PICTURE: ACTIONS_PREFIX + 'GET_USER_PICTURE',
    LOGOUT: ACTIONS_PREFIX + 'LOGOUT',
    
    CHECK_BACKEND_TIMEOUT: ACTIONS_PREFIX + 'CHECK_BACKEND_TIMEOUT',
    CAN_DOWNLOAD_DATA: ACTIONS_PREFIX + 'CAN_DOWNLOAD_DATA',
    SAVE_PIN_DATA: ACTIONS_PREFIX + 'SAVE_PIN_DATA',
    GET_PIN_DATA: ACTIONS_PREFIX + 'GET_PIN_DATA',
    CREATE_XLS_OR_PDF: ACTIONS_PREFIX + 'CREATE_XLS_OR_PDF',

    GET_MFA_CHALLENGE_TYPE: ACTIONS_PREFIX + 'GET_MFA_CHALLENGE_TYPE',
    SAVE_MFA_CHALLENGE_TYPE: ACTIONS_PREFIX + 'SAVE_MFA_CHALLENGE_TYPE',

    LOAD_COLOR_SCHEMA: ACTIONS_PREFIX + 'LOAD_COLOR_SCHEMA',
    APPLY_CONFIGURED_COLOR_SCHEME: ACTIONS_PREFIX + 'APPLY_CONFIGURED_COLOR_SCHEME',
    GLOBAL_LOADING_STATE_STOP: ACTIONS_PREFIX + 'GLOBAL_LOADING_STATE_STOP',
    GLOBAL_LOADING_STATE_START: ACTIONS_PREFIX + 'GLOBAL_LOADING_STATE_START',

    ADD_RECORDED_ITEM: ACTIONS_PREFIX + 'ADD_RECORDED_ITEM',
    REMOVE_RECORDED_ITEM: ACTIONS_PREFIX + 'REMOVE_RECORDED_ITEM',
    UPDATE_RECORDED_ITEM: ACTIONS_PREFIX + 'UPDATE_RECORDED_ITEM',
    REMOVE_TUTORIAL: ACTIONS_PREFIX + 'REMOVE_TUTORIAL',

    SEND_TUTORIAL: ACTIONS_PREFIX + 'SEND_TUTORIAL',
    RETRIEVE_TUTORIALS: ACTIONS_PREFIX + 'RETRIEVE_TUTORIALS',

    REMOVE_ALL_RECORDED_ITEMS: ACTIONS_PREFIX + 'REMOVE_ALL_RECORDED_ITEMS',

    RECORDING_TUTORIAL_TITLE: ACTIONS_PREFIX + 'RECORDING_TUTORIAL_TITLE',
    RESET_STATE: ACTIONS_PREFIX + 'RESET_STATE',

    GET_SYSTEM_DATA: ACTIONS_PREFIX + 'GET_SYSTEM_DATA',
    OPEN_CUSTOMER_STECKBRIEF: ACTIONS_PREFIX + 'OPEN_CUSTOMER_STECKBRIEF',
    OPEN_BROKER: ACTIONS_PREFIX + 'OPEN_BROKER',
    OPEN_INTERN: ACTIONS_PREFIX + 'OPEN_INTERN',

    GET_APP_LINKS: ACTIONS_PREFIX + 'GET_APP_LINKS',

    RESTORE_ROLE_VIEW_LOGGED_USER: ACTIONS_PREFIX + 'RESTORE_ROLE_VIEW_LOGGED_USER',
    SWITCH_TO_ROLE_VIEW_CUSTOMER_ONLY: ACTIONS_PREFIX + 'SWITCH_TO_ROLE_VIEW_CUSTOMER_ONLY',

    OPEN_CUSTOMER_NEW_TAB: ACTIONS_PREFIX + 'OPEN_CUSTOMER_NEW_TAB',
    OPEN_CUSTOMER_SAME_TAB_FOR_TESTING: ACTIONS_PREFIX + 'OPEN_CUSTOMER_SAME_TAB_FOR_TESTING',
    OPEN_BROKER_NEW_TAB: ACTIONS_PREFIX + 'OPEN_BROKER_NEW_TAB',
    OPEN_INTERN_NEW_TAB: ACTIONS_PREFIX + 'OPEN_INTERN_NEW_TAB',
    SAVE_BACK_TO_PREVIOUS_PAGE: ACTIONS_PREFIX + 'SAVE_BACK_TO_PREVIOUS_PAGE',
    SAVE_BACK_TO_PREVIOUS_PAGE_TRACK: ACTIONS_PREFIX + 'SAVE_BACK_TO_PREVIOUS_PAGE_TRACK',
    GO_TO_LAST_BACK_TO_PREVIOUS_PAGE: ACTIONS_PREFIX + 'GO_TO_LAST_BACK_TO_PREVIOUS_PAGE',

    CHANGE_PASSWORD: ACTIONS_PREFIX + 'CHANGE_PASSWORD',
    CHANGE_VERSAND_PASSWORD: ACTIONS_PREFIX + 'CHANGE_VERSAND_PASSWORD',

    GET_ALL_CMS_META_TAG: ACTIONS_PREFIX + 'GET_ALL_CMS_META_TAG',
    SAVE_CMS_META_TAG: ACTIONS_PREFIX + 'SAVE_CMS_META_TAG',
    DELETE_CMS_META_TAG: ACTIONS_PREFIX + 'DELETE_CMS_META_TAG',

    GET_PRIVACY_DATA: ACTIONS_PREFIX + 'GET_PRIVACY_DATA',
    GET_PRIVACY_CONDITIONS: ACTIONS_PREFIX + 'GET_PRIVACY_CONDITIONS',
    CONFIRM_PRIVACY: ACTIONS_PREFIX + 'CONFIRM_PRIVACY',

    GET_PDF_FILES: ACTIONS_PREFIX + 'GET_PDF_FILES',
    SUBMIT_KUNDEINTERESSENKONFLIKT: ACTIONS_PREFIX + 'SUBMIT_KUNDEINTERESSENKONFLIKT',

    CHECK_REGISTRATION_CODE: ACTIONS_PREFIX + 'CHECK_REGISTRATION_CODE',
    GET_CAPTCHA: ACTIONS_PREFIX + 'GET_CAPTCHA',
    GET_CAPTCHA_IMG: ACTIONS_PREFIX + 'GET_CAPTCHA_IMG',
    CHECK_CAPTCHA_AND_REGISTRATION_CODE: ACTIONS_PREFIX + 'CHECK_CAPTCHA_AND_REGISTRATION_CODE',
    GET_PRIVACY_REGISTRATION_DATA: ACTIONS_PREFIX + 'GET_PRIVACY_REGISTRATION_DATA',
    REGISTRATION: ACTIONS_PREFIX + 'REGISTRATION',
    REGISTER_ACTIVATION: ACTIONS_PREFIX + 'REGISTER_ACTIVATION',

    OPEN_CUSTOMER_INSURANCES: ACTIONS_PREFIX + 'OPEN_CUSTOMER_INSURANCES',

    GET_BROKER_LAYOUT: ACTIONS_PREFIX + 'GET_BROKER_LAYOUT',
    
    GET_DEFAULT_LAYOUT: ACTIONS_PREFIX + 'GET_DEFAULT_LAYOUT',
    GET_LAYOUT: ACTIONS_PREFIX + 'GET_LAYOUT',
    SAVE_LAYOUT: ACTIONS_PREFIX + 'SAVE_LAYOUT',

    CONFIRM_MODAL: ACTIONS_PREFIX + 'CONFIRM_MODAL',

    REQUEST_PASSWORD_RESET_CODES: ACTIONS_PREFIX + 'REQUEST_PASSWORD_RESET_CODES',
    PASSWORT_WIEDERHERSTELLEN_WITH_CODE: ACTIONS_PREFIX + 'PASSWORT_WIEDERHERSTELLEN_WITH_CODE',

    OPEN_KUNDENZUGANG: ACTIONS_PREFIX + 'OPEN_KUNDENZUGANG',
    OPEN_MAKLERZUGANG: ACTIONS_PREFIX + 'OPEN_MAKLERZUGANG',
    OPEN_MITARBEITERZUGANG: ACTIONS_PREFIX + 'OPEN_MITARBEITERZUGANG',

    OPEN_CURRENT_BYPASS_USER: ACTIONS_PREFIX + 'OPEN_CURRENT_BYPASS_USER',
    OPEN_CURRENT_LOGGED_BROKER: ACTIONS_PREFIX + 'OPEN_CURRENT_LOGGED_BROKER',
    OPEN_CUSTOMER_BERATUNGSMAPPE: ACTIONS_PREFIX + 'OPEN_CUSTOMER_BERATUNGSMAPPE',
    OPEN_CURRENT_LOGGED_INTERN: ACTIONS_PREFIX + 'OPEN_CURRENT_LOGGED_INTERN',
    
    UNLOCK_PIN: ACTIONS_PREFIX + 'UNLOCK_PIN',
    CHECK_PIN: ACTIONS_PREFIX + 'CHECK_PIN',
    MSC_BUILD_INFO: ACTIONS_PREFIX + 'MSC_BUILD_INFO',
    UPDATE_IS_INAKTIV: ACTIONS_PREFIX + 'UPDATE_IS_INAKTIV',

    ADD_GENERIC_SELECTION: ACTIONS_PREFIX + 'ADD_GENERIC_SELECTION', 
    EXCEPTION_LOG: ACTIONS_PREFIX + 'EXCEPTION_LOG',
  },
  GETTERS: {
    IS_INTERNET_ACTIVE: GETTERS_PREFIX + 'IS_INTERNET_ACTIVE',
    IS_LOGGING_IN: GETTERS_PREFIX + 'IS_LOGGING_IN',
    IS_LOGGING_OUT: GETTERS_PREFIX + 'IS_LOGGING_OUT',
    READ_ONLY: GETTERS_PREFIX + 'READ_ONLY',
    API_ADDRESS: GETTERS_PREFIX + 'API_ADDRESS',
    API_ADDRESS_LEGACY: GETTERS_PREFIX + 'API_ADDRESS_LEGACY',
    PROXY_CONTENT: GETTERS_PREFIX + 'PROXY_CONTENT',
    PENDING_ACTIONS: GETTERS_PREFIX + 'PENDING_ACTIONS',
    IS_PENDING_ACTION_ACTIVE: GETTERS_PREFIX + 'IS_PENDING_ACTION_ACTIVE',
    GET_USER_ROLES: GETTERS_PREFIX + 'GET_USER_ROLES',
    HAS_ROLES: GETTERS_PREFIX + 'HAS_ROLES',
    HAS_ACCESS: GETTERS_PREFIX + 'HAS_ACCESS',
    IS_LOGGED_IN: GETTERS_PREFIX + 'IS_LOGGED_IN',
    IS_LOADING_TOKEN: GETTERS_PREFIX + 'IS_LOADING_TOKEN',
    IS_MANAGING_LOGIN_RESPONSE: GETTERS_PREFIX + 'IS_MANAGING_LOGIN_RESPONSE',
    GET_TOKEN: GETTERS_PREFIX + 'GET_TOKEN',
    STORE_SESSION_INFORMATION: GETTERS_PREFIX + 'STORE_SESSION_INFORMATION',
    IS_CUSTOMER: GETTERS_PREFIX + 'IS_CUSTOMER',
    IS_CUSTOMER_ONLY: GETTERS_PREFIX + 'IS_CUSTOMER_ONLY',
    IS_SUPER_CUSTOMER: GETTERS_PREFIX + 'IS_SUPER_CUSTOMER',
    IS_INTERN: GETTERS_PREFIX + 'IS_INTERN',
    IS_BROKER: GETTERS_PREFIX + 'IS_BROKER',
    IS_GESELLSCHAFT: GETTERS_PREFIX + 'IS_GESELLSCHAFT',
    IS_MEHRFACHAGENT: GETTERS_PREFIX + 'IS_MEHRFACHAGENT',
    IS_FK: GETTERS_PREFIX + 'IS_FK',
    IS_FA: GETTERS_PREFIX + 'IS_FA',
    GET_DB_PREFIX: GETTERS_PREFIX + 'GET_DB_PREFIX',
    IS_FPP_PLUS_MASTER: GETTERS_PREFIX + 'IS_FPP_PLUS_MASTER',
    IS_DGFRP_MASTER: GETTERS_PREFIX + 'IS_DGFRP_MASTER',
    IS_10000_FA: GETTERS_PREFIX + 'IS_10000_FA',
    IS_FPP_PLUS: GETTERS_PREFIX + 'IS_FPP_PLUS',
    IS_AVL: GETTERS_PREFIX + 'IS_AVL',
    CUSTOMERS_USE_KSC2: GETTERS_PREFIX + 'CUSTOMERS_USE_KSC2',
    IS_DIALOG: GETTERS_PREFIX + 'IS_DIALOG',
    ORIGINAL_USER_IS_BROKER: GETTERS_PREFIX + 'ORIGINAL_USER_IS_BROKER',
    ORIGINAL_USER_IS_INTERN: GETTERS_PREFIX + 'ORIGINAL_USER_IS_INTERN',
    ORIGINAL_TOKEN: GETTERS_PREFIX + 'ORIGINAL_TOKEN',
    ORIGINAL_VIEW_ROLES: GETTERS_PREFIX + 'ORIGINAL_VIEW_ROLES',
    ORIGINAL_USER: GETTERS_PREFIX + 'ORIGINAL_USER',
    IS_TEST_BROKER: GETTERS_PREFIX + 'IS_TEST_BROKER',
    IS_TEST_CUSTOMER: GETTERS_PREFIX  + 'IS_TEST_CUSTOMER',
    IS_BROKER_OR_BYPASS: GETTERS_PREFIX + 'IS_BROKER_OR_BYPASS',
    IS_BYPASS: GETTERS_PREFIX + 'IS_BYPASS',
    IS_TEST: GETTERS_PREFIX + 'IS_TEST',
    IS_MAKLER_EARLY_ACCESS: GETTERS_PREFIX + 'IS_MAKLER_EARLY_ACCESS',
    IS_MAKLER_KPI: GETTERS_PREFIX + 'IS_MAKLER_KPI',
    ALLOW_WEBRTC_CALL_KUNDEN_FREIGABE: GETTERS_PREFIX + 'ALLOW_WEBRTC_CALL_KUNDEN_FREIGABE',
    SHOW_WEBRTC_CALL_RECORDING_BUTTON: GETTERS_PREFIX + 'SHOW_WEBRTC_CALL_RECORDING_BUTTON',
    IS_WEBRTC_KUNDENZUGANG: GETTERS_PREFIX + 'IS_WEBRTC_KUNDENZUGANG',
    IS_WEBRTC_MAKLERZUGANG: GETTERS_PREFIX + 'IS_WEBRTC_MAKLERZUGANG',
    ALLOW_CMS_FONDSSHOP_OHNE_HOME_SEITE: GETTERS_PREFIX + 'ALLOW_CMS_FONDSSHOP_OHNE_HOME_SEITE',
    SHOW_BROKER_NAME_ON_TITLE_PAGE: GETTERS_PREFIX + 'SHOW_BROKER_NAME_ON_TITLE_PAGE',
    SUPPOSED_TIMEOUT_TIME: GETTERS_PREFIX + 'SUPPOSED_TIMEOUT_TIME',
    TIMEOUT_SECONDS: GETTERS_PREFIX + 'TIMEOUT_SECONDS',

    MFA_CHALLENGE_TYPE: GETTERS_PREFIX + 'MFA_CHALLENGE_TYPE',

    IS_MULTIBANKING_ACCOUNT_USER: GETTERS_PREFIX + 'IS_MULTIBANKING_ACCOUNT_USER',
    IS_ALLOWED_TO_GET_MULTIBANKING_DATA: GETTERS_PREFIX + 'IS_ALLOWED_TO_GET_MULTIBANKING_DATA',

    GET_LOGIN_WELCOME_TITLE: GETTERS_PREFIX + 'GET_LOGIN_WELCOME_TITLE',
    GET_USER_FULL_NAME: GETTERS_PREFIX + 'GET_USER_FULL_NAME',
    GET_USER_DASHBOARD: GETTERS_PREFIX + 'GET_USER_DASHBOARD',
    GET_LOGIN_USER_ID: GETTERS_PREFIX + 'GET_LOGIN_USER_ID',
    GET_USER_ID: GETTERS_PREFIX + 'GET_USER_ID',
    GET_USER_UNTER_NR: GETTERS_PREFIX + 'GET_USER_UNTER_NR',
    IS_SAME_USER_LOGGED_IN: GETTERS_PREFIX + 'IS_SAME_USER_LOGGED_IN',
    GET_LOGIN_DATA: GETTERS_PREFIX + 'GET_LOGIN_DATA',
    GET_USER_PICTURE_URL: GETTERS_PREFIX + 'GET_USER_PICTURE_URL',
    GET_USER_PICTURE_DATA: GETTERS_PREFIX + 'GET_USER_PICTURE_DATA',
    GET_USER_LOGO_URL: GETTERS_PREFIX + 'GET_USER_LOGO_URL',
    GET_BROKER_INFORMATION: GETTERS_PREFIX + 'GET_BROKER_INFORMATION',
    GET_BROKER_LOGO: GETTERS_PREFIX + 'GET_BROKER_LOGO',
    GET_BROKER_IMAGE_BACKGROUND_LOGIN_URL: GETTERS_PREFIX + 'GET_BROKER_IMAGE_BACKGROUND_LOGIN_URL',
    GET_COLOR_SCHEMA: GETTERS_PREFIX + 'GET_COLOR_SCHEMA',
    CONFIGURED_COLOR_SCHEME: GETTERS_PREFIX + 'CONFIGURED_COLOR_SCHEME',
    PREFERRED_COLOR_SCHEMA: GETTERS_PREFIX + 'PREFERRED_COLOR_SCHEMA',
    GET_USER_PRIVACY_CONTACTS: GETTERS_PREFIX + 'GET_USER_PRIVACY_CONTACTS',
    GET_DEFAULT_BROKER_ID: GETTERS_PREFIX + 'GET_DEFAULT_BROKER_ID',
    IS_DEFAULT_BROKER_ID: GETTERS_PREFIX + 'IS_DEFAULT_BROKER_ID',
    GET_ANMELDEN_URL: GETTERS_PREFIX + 'GET_ANMELDEN_URL',

    GLOBAL_LOADING_STATE_STATUS: GETTERS_PREFIX + 'GLOBAL_LOADING_STATE_STATUS',

    IS_RIGHT_SET_MAKLER_INAKTIV: GETTERS_PREFIX + 'IS_RIGHT_SET_MAKLER_INAKTIV',

    RECORDING_TUTORIAL: GETTERS_PREFIX + 'RECORDING_TUTORIAL',
    TUTORIAL_RECORDED_ITEMS: GETTERS_PREFIX + 'TUTORIAL_RECORDED_ITEMS',
    RECORDING_TUTORIAL_TITLE: GETTERS_PREFIX + 'RECORDING_TUTORIAL_TITLE',
    RECORDING_TUTORIAL_ROUTE: GETTERS_PREFIX + 'RECORDING_TUTORIAL_ROUTE',
    RECORDED_TUTORIALS: GETTERS_PREFIX + 'RECORDED_TUTORIALS',
    RECORDING_FEATURE: GETTERS_PREFIX + 'RECORDING_FEATURE',
    RECORDING_TUTORIAL_LAST_ID: GETTERS_PREFIX + 'RECORDING_TUTORIAL_LAST_ID',
    PAGE_TITLE: GETTERS_PREFIX + 'PAGE_TITLE',
    APP_NAME_DEFAULT: GETTERS_PREFIX + 'APP_NAME_DEFAULT',
    PLAYING_TUTORIAL: GETTERS_PREFIX + 'PLAYING_TUTORIAL',
    RECORDING_TUTORIAL_USER_TYPE: GETTERS_PREFIX + 'GETTERS_PREFIX',

    SYSTEM_DATA: GETTERS_PREFIX + 'SYSTEM_DATA',

    GET_APP_LINKS: GETTERS_PREFIX + 'GET_APP_LINKS',
    IS_ADD_BREADCRUMB_LOCKED: GETTERS_PREFIX + 'IS_ADD_BREADCRUMB_LOCKED',
    GET_SAVE_BACK_TO_PREVIOUS_PAGE: GETTERS_PREFIX + 'GET_SAVE_BACK_TO_PREVIOUS_PAGE',
    GET_BACK_TO_PREVIOUS_PAGE_TRACK: GETTERS_PREFIX + 'GET_BACK_TO_PREVIOUS_PAGE_TRACK',
    CURRENT_BACK_TO_PREVIOUS_PAGE: GETTERS_PREFIX + 'CURRENT_BACK_TO_PREVIOUS_PAGE',
    HAS_COLOR_SCHEMA: GETTERS_PREFIX + 'HAS_COLOR_SCHEMA',
    GET_PRIVACY_DATA: GETTERS_PREFIX + 'GET_PRIVACY_DATA',
    GET_CMS_META_TAGS: GETTERS_PREFIX + 'GET_CMS_META_TAGS',

    REGISTRATION_DATA: GETTERS_PREFIX + 'REGISTRATION_DATA',
    PRIVACY_REGISTRATION_DATA: GETTERS_PREFIX + 'PRIVACY_REGISTRATION_DATA',

    CAPTCHA_DATA: GETTERS_PREFIX + 'CAPTCHA_DATA',

    IS_ANLAGE_BEISPIEL: GETTERS_PREFIX + 'IS_ANLAGE_BEISPIEL',

    IS_BROKER_MASTER: GETTERS_PREFIX + 'IS_BROKER_MASTER',

    GET_DEFAULT_LAYOUT: GETTERS_PREFIX + 'GET_DEFAULT_LAYOUT',
    GET_LAYOUT: GETTERS_PREFIX + 'GET_LAYOUT',

    IS_KUNDENZUGANG: GETTERS_PREFIX + 'IS_KUNDENZUGANG',
    IS_MAKLERZUGANG: GETTERS_PREFIX + 'IS_MAKLERZUGANG',
    IS_MITARBEITERZUGANG: GETTERS_PREFIX + 'IS_MITARBEITERZUGANG',

    IS_AT: GETTERS_PREFIX + 'IS_AT',
    VERSADMIN_A: GETTERS_PREFIX + 'VERSADMIN_A',
    VERSADMIN_M: GETTERS_PREFIX + 'VERSADMIN_M',
    
    APP_VERSION: GETTERS_PREFIX + 'APP_VERSION',
    
    IS_BAVARIA: GETTERS_PREFIX + 'IS_BAVARIA',
    IS_INVERSE: GETTERS_PREFIX + 'IS_INVERSE',
    IS_SVM: GETTERS_PREFIX + 'IS_SVM',

    PENDING_CONFIRM_MODAL: GETTERS_PREFIX + 'PENDING_CONFIRM_MODAL',
    WAIT_PENDING_CONFIRM_MODAL_RESPONSE: GETTERS_PREFIX + 'WAIT_PENDING_CONFIRM_MODAL_RESPONSE',

    IS_HAUPTSTRUKTURLEITENR: GETTERS_PREFIX + 'IS_HAUPTSTRUKTURLEITENR',

    IS_OPEN_SIGNATURES_READ_ONLY: GETTERS_PREFIX + 'IS_OPEN_SIGNATURES_READ_ONLY',
    HAT_VERTRAG: GETTERS_PREFIX + 'HAT_VERTRAG',

    SCREEN_WIDTH: GETTERS_PREFIX + 'SCREEN_WIDTH',
    IS_SMALL_SCREEN: MUTATION_PREFIX + 'IS_SMALL_SCREEN',
    ASK_FOR_PIN: GETTERS_PREFIX + 'ASK_FOR_PIN',
    RESET_EVENT_BASE_FILTER: GETTERS_PREFIX + 'RESET_EVENT_BASE_FILTER',
    MSC_BUILD_INFO: GETTERS_PREFIX + 'MSC_BUILD_INFO',
    IS_INITIAL_APP_STATE: GETTERS_PREFIX + 'IS_INITIAL_APP_STATE',
    
    IS_INAKTIV: GETTERS_PREFIX + 'IS_INAKTIV',
    IS_SSO_VISIBLE: GETTERS_PREFIX + 'IS_SSO_VISIBLE',
    IS_ALLOWED_MERGE_SCHADEN: GETTERS_PREFIX + 'IS_ALLOWED_MERGE_SCHADEN',

    GENERIC_SELECTION_FN: GETTERS_PREFIX + 'GENERIC_SELECTION_FN',

    CHAT_EMPFAENGER_KEY:GETTERS_PREFIX+'CHAT_EMPFAENGER_KEY',
    EXCEPTION_LOG: GETTERS_PREFIX + 'EXCEPTION_LOG',
  }
}