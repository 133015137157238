import axios from 'axios';

import MENU_TYPES from './types';
import FC_CONFIG_TYPES from '@/store/fcConfig/types';
import CORE_TYPES from '@/store/core/types';

import FC_CONFIG from '@/configs/fcConfig.js';

import router from '@/router';
import { findConfiguredMenuActives, normalizeMenu, } from '@/components/core/option-menu/option-menu-utils';
import badge from './badge';
import { createAppMenu } from '@/menu';
import { VIEW_ROLES } from '@/router/roles';
import menuTrack from '@/menu/menu-track';

const config = { defaultSpinner: true, };

export default {

  async [MENU_TYPES.ACTIONS.CONFIGURE_MENU]({ getters, commit, dispatch, }, { reconfiguration = false } = {}) {
    const isMenuConfigured = getters[MENU_TYPES.GETTERS.IS_MENU_CONFIGURED];
    const isConfiguringMenu = getters[MENU_TYPES.GETTERS.IS_CONFIGURING_MENU];
    if(!reconfiguration && isMenuConfigured || isConfiguringMenu) return; // check if menu is already configured

    commit(MENU_TYPES.MUTATIONS.SET_CONFIGURING_MENU, true);
    commit(MENU_TYPES.MUTATIONS.SET_MENU_CONFIGURED, true);

    menuTrack.close();

    // Load menu permission - it must be loaded before process menu
    await dispatch(MENU_TYPES.ACTIONS.LOAD_OPTIONS_MENU_PERMISSION, { forceReload: reconfiguration, });

    // default flat menu
    const menuStructure = await dispatch(MENU_TYPES.ACTIONS.FIND_MENU_STRUCTURE);
    const appMenu = await createAppMenu(router, {
      menuStructure,
    });
    const { flatMenu } = appMenu;

    // find primary and group menus configs
    const parentsMenu = flatMenu.filter(menu => !menu.parent || menu.group);
    const configIds = parentsMenu.map(parent => parent.id);
    await dispatch(MENU_TYPES.ACTIONS.LOAD_OPTIONS_MENU_CONFIGS, { configIds, });

    commit(MENU_TYPES.MUTATIONS.SET_FLAT_MENU, [ ...flatMenu, ]);

    requestAnimationFrame(() => commit(MENU_TYPES.MUTATIONS.SET_CONFIGURING_MENU, false));
  },

  async [MENU_TYPES.ACTIONS.CONFIG_PRIMARY_MENU_BADGE](ctx) {
    const { dispatch, getters, commit, } = ctx;

    const primaryMenu = getters[MENU_TYPES.GETTERS.PRIMARY_MENU];

    // Load options menu config from backend
    const configList = primaryMenu.map(menu => ({
      configId: menu?.id,
      configType: FC_CONFIG.OPTION_MENU_CONFIG,
    }))

    await dispatch(FC_CONFIG_TYPES.ACTIONS.LOAD_FC_CONFIG, configList);
    
    const optionsMenuConfig = getters[FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_OPTION_MENU];
    const appNavigationByMenuPathFn = getters[MENU_TYPES.GETTERS.APP_NAVIGATION_BY_MENU_PATH];
    const optionMenu = getters[MENU_TYPES.GETTERS.OPTION_MENU];

    // Configure the badges to the primary menu
    const primaryMenuBadge = primaryMenu.reduce((acc, menu) => {
      const optionsMenuConfigRoute = optionsMenuConfig[menu?.id];
      const configuredOptionsMenu = optionsMenuConfigRoute?.content && JSON.parse(optionsMenuConfigRoute?.content);
      const appNavigation = appNavigationByMenuPathFn(menu.path);
      const normalizedMenu = normalizeMenu(optionMenu, appNavigation?.currentOptionMenu, configuredOptionsMenu);
      const optionsMenu = [...findConfiguredMenuActives(normalizedMenu)];

      return {
        ...acc,
        [menu.path]: optionsMenu
          .map(om => ({ ...om, realPath: om.redirect || om.path, }))
          .filter(om => badge?.primaryMenu?.[om.realPath])
          .reduce((acc, om) => ({ ...acc, [om.realPath]: badge?.primaryMenu?.[om.realPath], }), {}),
      };
    }, {});

    commit(MENU_TYPES.MUTATIONS.SET_PRIMARY_MENU_BADGE, primaryMenuBadge);
  },

  async [MENU_TYPES.ACTIONS.LOAD_PRIMARY_MENU_BADGE](ctx, { badgeName } = {}) {
    const { state } = ctx;
    const { primaryMenuBadge, } = state;

    const badgesByPath = Object.keys(primaryMenuBadge)
      .reduce((acc, primaryMenuPath) => ({ 
        ...acc, 
        ...primaryMenuBadge[primaryMenuPath] || {},
      }), {});

    const badges = Object.keys(badgesByPath)
      .map(key => badgesByPath[key])
      .filter(badge => !badgeName || badge.name === badgeName);

    await Promise.all(badges.map(badge => 'load' in badge && badge.load(ctx)));
  },

  async [MENU_TYPES.ACTIONS.CONFIG_OPTIONS_MENU_BADGE](ctx, { optionsMenu = [] }) {
    const { commit, } = ctx;

    const filteredOptionsMenu = optionsMenu
      .filter(om => badge?.optionsMenu?.[om.path])
      .reduce((acc, om) => ({ ...acc, [om.path]: badge?.optionsMenu?.[om.path], }), {})

    const redirectOptionsMenu = optionsMenu
      .filter(om => om.redirect && badge?.optionsMenu?.[om.redirect])
      .reduce((acc, om) => ({ ...acc, [om.path]: badge?.optionsMenu?.[om.redirect], }), {});

    commit(MENU_TYPES.MUTATIONS.SET_OPTIONS_MENU_BADGE, { 
      ...filteredOptionsMenu || {},
      ...redirectOptionsMenu || {},
    });
  },

  async [MENU_TYPES.ACTIONS.LOAD_OPTIONS_MENU_BADGE](ctx, { badgeName } = {}) {
    const { state } = ctx;
    const { optionsMenuBadge, } = state;

    const badges = Object.keys(optionsMenuBadge)
      .map(key => optionsMenuBadge[key])
      .filter(badge => !badgeName || badge.name === badgeName);

    await Promise.all(badges.map(badge => 'load' in badge && badge.load(ctx)));
  },

  async [MENU_TYPES.ACTIONS.LOAD_OPTIONS_MENU_CONFIGS]({ dispatch, }, { configIds, forceReload = false } = {}) {
    if(!configIds?.length) return;

    const payload = configIds.map(configId => ({
      configId,
      configType: FC_CONFIG.OPTION_MENU_CONFIG,
      forceReload,
    }));
    await dispatch(FC_CONFIG_TYPES.ACTIONS.LOAD_FC_CONFIG, payload);

    dispatch(MENU_TYPES.ACTIONS.CONFIG_PRIMARY_MENU_BADGE);
  },

  async [MENU_TYPES.ACTIONS.LOAD_OPTIONS_MENU_PERMISSION]({ state, getters, commit, }, { forceReload = false } = {}) {
    const hasRoles = getters[CORE_TYPES.GETTERS.HAS_ROLES];
    if (!hasRoles([VIEW_ROLES.VIEW_BROKER, VIEW_ROLES.VIEW_CUSTOMER, VIEW_ROLES.VIEW_INTERN])) return;

    if(state.optionsMenuPermission && !forceReload) return;

    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/fcconfig/options_menu_permission_config`, config);

    // reduce found configs by user level
    const optionMenuPermissionByUserLevel = response?.data || {};
    const userLevelResponse = Object.keys(optionMenuPermissionByUserLevel)?.[0]; // there is only a default user level by user session
    const permissionConfig = optionMenuPermissionByUserLevel?.[userLevelResponse];
    const optionsMenuPermission = JSON.parse(permissionConfig?.content || '{}');

    // set options menu permission config
    commit(MENU_TYPES.MUTATIONS.SET_OPTIONS_MENU_PERMISSION, { ...optionsMenuPermission, });
  },

  async [MENU_TYPES.ACTIONS.TRACK_RECENT_MENU_OPENED]({ commit, getters, }, { path, }) {
    if(!path) return;

    const { currentMenu } = getters[MENU_TYPES.GETTERS.APP_NAVIGATION_BY_MENU_PATH](path) || {};
    const menu = currentMenu?.$isTabMenu ? currentMenu.parent : currentMenu;

    // ignore workspace (menu without parent) and group menu
    if(!menu || !menu?.parent || menu?.group) return;

    const userId = getters[CORE_TYPES.GETTERS.GET_USER_ID];

    commit(MENU_TYPES.MUTATIONS.PREPEND_RECENT_MENU_OPENED, { 
      userId,
      menu, 
    });
  },

  async [MENU_TYPES.ACTIONS.FIND_MENU_STRUCTURE]({ getters, }) {
    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/menu/menu_structure`, config);
    return response?.data;
  },

}
