import actions from './actions';
import mutations from './mutations';
import getters from './getters';

/**
 * mutations: synchronous operation responsible for change the state
 * actions: asynchronous operation responsible for call external resources and commit mutations 
 */
export function getInitialState() {
  return {
    customerData: {},
    customerDataConfig: {},
    checkFormatCommunication: {},
    persons: [],
    selectedPersonForEditing: 'person1',
    selectedPersonForEditingExtraData: null,
    customerDataEdited: {},
    backButton: null,
    additionalValidators: null,
    savedCustomerID: null,
    pinData: {},
    personStates: {}, // indexed by personId
    personData: {},
    addressToCopy: {},
    peopleFound: [],
    peopleRelationship: [],
    hints: [],
    courtage: [],
    bemerkungen: [],
    subCustomers: [],
    interneBemerkung: null,
    bemerkungKunde: null,
    bemerkungSichtbar: null,
    lastSavedNewPerson: null,
    birthdays: [],
    dataChanged: false,
    legitimationsurkundeChanged: false,
    contactPerson: null,
    selectedBemerkung: {},
    editedState: false,
    legitimation: {},
    checkEmailsFA: {status: 0},
  }
}

export default {
  state: {
    ...getInitialState()
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  },
  getters: {
    ...getters
  }
}