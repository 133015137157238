import dayjs from 'dayjs';
import { mapListToComboBoxValues, mapListToKeyValue } from '@/helpers/mapping.js';
import { isDate } from '@/helpers/commonfunctions.js';
import { isRolesIncludes } from '@/router/guards';
import { ROLES, VIEW_ROLES } from '@/router/roles';
import {CurrencyColumn, TextColumn, DateColumn, SlotColumn, NumberColumn, PillColumn, ActionColumn} from "@/components/table2/table_util.js";

export const BESTAND_FREMDBESTAND = 'Fremdbestand';
export const BESTAND_EIGENBESTAND = 'Eigenbestand';

export const MAP_STATUS_TO_PILL_TYPE = {
  aktiv: 'success',
  Angebot: 'info',
  beantragt: 'info',
  beitragsfrei: 'info',
  Mahnung: 'danger',
  Nachbearbeitung: 'danger',
  provisionsfrei: 'info',
  Switch: 'info',
};

export const MAP_BESTAND_TO_PILL_TYPE = {
  [BESTAND_FREMDBESTAND]: 'info',
  [BESTAND_EIGENBESTAND]: 'success',
};

const FILTERS_IS_ARRAY = ['vertragStatus', 'versSparten', 'vertragGesellschaft', 'fremdGesellschaft'];
const FILTERS_RANGE = ['vBegin', 'vEnde', 'vJahresBetrag', 'vFaeligkeit', 'datumAbrechnung', 'paramVertragCreated', 'gdvAktuell', 'gdvNichtAktuell'];
const FILTERS_DATEPICKER = ['vBeginAb', 'vBeginBis', 'vEndeAb', 'vEndeBis'];

const DEFAULT_SEARCH_INPUT_KEY = 'defaultSearchInput';
const QUERY_KEY = 'insuranceQuery';

export function isArrayFilter(key) {
  return FILTERS_IS_ARRAY.indexOf(key) >= 0;
}

export function isRangeFilter(key) {
  return FILTERS_RANGE.indexOf(key) >= 0;
}

export function prepareFilters(filters) {
  if(!filters) {
    return {};
  }

  return filters.reduce((result, f) => {
    const category = f.key.split('@@');
    const key = category[0];
    if(!key) {
      return result;
    }

    if(key === DEFAULT_SEARCH_INPUT_KEY) {
      result[QUERY_KEY] = f.value;
    } else if(isArrayFilter(key)) {
      if(!result[key]) {
        result[key] = [];
      }
      if(f.value) {
        if (isArrayFilter(f.key))
            result[key].push(f.value);
        else
            result[key].push({...f, key: category[1]});
      }
    } else if(isRangeFilter(key)) {
      const start = f.value[0];
      const end = f.value[1];
      result[`${key}Ab`] = isDate(start?.value) && dayjs(start?.value).format('DD.MM.YYYY') || start?.value;
      result[`${key}Bis`] = isDate(end?.value) && dayjs(end?.value).format('DD.MM.YYYY') || end?.value;
    } else if(FILTERS_DATEPICKER.indexOf(key) >= 0) {
      result[key] = isDate(f.value) && dayjs(f.value).format('DD.MM.YYYY') || f.value;
    } else {
      result[key] = f.value;
    }

    return result;
  }, {})
}

export function searchMenu(data, isFPPlus, isFA = false, isIntern = false, isTestUser = false) {
  const mapCategoryIdLabel = mapListToKeyValue(data?.kategorie, 'id', 'label');

  let groupAllgemein = {
    type: 'group',
    key: 'Allgemein',
    label: 'Allgemein',
    allowMultipleSearch: true,
    menuItems: [
      {
        type: 'integer',
        label: 'Interne Nummer',
        key: 'interneNummer',
        emptyDenied: true,
      },
      {
        type: 'text',
        label: 'Kundennummer',
        key: 'customerId',
        inputmode: 'numeric',
      },
      {
        type: 'text',
        label: 'Name',
        key: 'lastName'
      },
      {
        type: 'text',
        label: 'Vorname',
        key: 'firstName'
      },
      {
        type: 'default',
        label: 'inkl. Verträge der Struktur',
        key: 'structure',
      },
      {
        type: 'text',
        label: 'Vermittlernummer',
        key: 'brokerId',
        emptyDenied: true,
      },
      {
        type: 'combobox',
        label: 'Aktueller Status',
        key: 'vertragStatus',
        comboOptions: [...mapListToComboBoxValues(data?.vertragStatus, 'key', 'value')],
        emptyDenied: true,
      },
      {
        type: 'text',
        label: 'Schadensnr.',
        key: 'schadensNummer',
        emptyDenied: true,
      },
      {
        type: 'combobox',
        label: 'Schadensstatus',
        key: 'schadenStatus',
        comboOptions: [...mapListToComboBoxValues(data?.schadenStatus, 'key', 'value')],
        emptyDenied: true,
      },
      {
        type: 'text',
        dataType: 'Number',
        label: 'Vers. Nr.',
        key: 'versNummer',
        emptyDenied: true,
      },
      {
        type: 'combobox',
        label: 'Gesellschaft',
        key: 'vertragGesellschaft',
        emptyDenied: true,
        comboOptions: [...mapListToComboBoxValues(data?.vertragGesellschaft, 'key', 'value')],
      },
      {
        type: 'combobox',
        label: 'Fremdgesellschaft',
        key: 'fremdGesellschaft',
        emptyDenied: true,
        comboOptions: [...mapListToComboBoxValues(data?.fremdGesellschaft, 'key', 'value')],
      },
      {
        type: 'default',
        label: 'inkl. inaktive Verträge',
        key: 'inklInaktive',
        emptyDenied: true,
      },
      {
        type: 'default',
        label: 'Korrespondenz',
        key: 'korrespondenz',
        emptyDenied: true,
      },
      {
        type: 'default',
        label: 'nur Eigenbestand',
        key: 'nurEigenbestand',
        emptyDenied: true,
      },
      {
        type: 'default',
        label: 'mit Schadensmeldung',
        key: 'nurMitSchadensmeldung',
        emptyDenied: true,
      },
      {
        type: 'default',
        label: 'Bestandsübertragung',
        key: 'bestandsuebertragung',
        emptyDenied: true,
      },
      {
        type: 'default',
        label: 'kostenpflichtige Produkte',
        key: 'kostenpflichtig',
        emptyDenied: true,
      },
      {
        type: 'default',
        label: 'nur Fremdbestand',
        key: 'nurFremdbestand',
        emptyDenied: true,
      },
      {
        type: 'default',
        label: 'mit Courtageabrechnung',
        key: 'checkNurAbrechnung',
        emptyDenied: true,
      },
      {
        type: 'default',
        label: 'ohne Courtageabrechnung',
        key: 'checkNurOhneAbrechnung',
        emptyDenied: true,
      },
      {
        type: 'default',
        label: 'mit Courtageabrechnung AP',
        key: 'checkNurAbrechnungAP',
        emptyDenied: true,
      },    
      {
        type: 'default',
        label: 'mit Courtageabrechnung BP',
        key: 'checkNurAbrechnungBP',
        emptyDenied: true,
      },         
      {
        type: 'dateRange',
        label: 'Courtageabrechnung in Zeitraum',
        key: 'datumAbrechnung',
        emptyDenied: true,
      }, 
      {
        type: 'default',
        label: 'mit Zubringercourtage',
        key: 'paramZubringercourtage',
        invertedWithParameter: true,
      },
      {
        type: 'default',
        label: 'nur kündbare Versicherungen',
        key: 'onlyCancelableInsurances',
        emptyDenied: false,
      },
      {
        type: 'text',
        label: 'Risiko',
        key: 'versObjekt',
        emptyDenied: true,
      },
      
    ],
  };

  if (isIntern) {

    groupAllgemein?.menuItems?.push(
      {
        type: 'dateRange',
        label: 'Anlagedatum Vertrag',
        key: 'paramVertragCreated',
        emptyDenied: true,
      }, 
    );    

    groupAllgemein?.menuItems?.push(
      {
        type: 'text',
        dataType: 'String',
        label: 'Benutzertyp',
        key: 'createUserType',
        emptyDenied: true,
      }, 
    );      
    
    groupAllgemein?.menuItems?.push(
      {
        type: 'default',
        label: 'nur Fremdanlagen',
        key: 'nurFremdvertrag',
        emptyDenied: true,
      }
    );

    groupAllgemein?.menuItems?.push(
      {
        type: 'default',
        label: 'ohne Fremdanlagen',
        key: 'ohneFremdvertrag',
        emptyDenied: true,
      }
    );   

    
  }

  if (isFA) {
    groupAllgemein?.menuItems?.push(
      {
        type: 'default',
        label: 'Konvertierung',
        key: 'konvertierung',
        emptyDenied: true,
      },
    );
  }

  const filters =  [
    ...[groupAllgemein],
    {
      type: 'group',
      key: 'Vertragsdaten',
      label: 'Vertragsdaten',
      menuItems: [
        {
          type: 'dateRange',
          label: 'Vertragsbeginn (Zeitraum)',
          key: 'vBegin',
          emptyDenied: true,
        },
        {
          type: 'datepicker',
          label: 'Vertragsbeginn ab',
          key: 'vBeginAb',
          emptyDenied: true,
        },
        {
          type: 'datepicker',
          label: 'Vertragsbeginn bis',
          key: 'vBeginBis',
          emptyDenied: true,
        },
        {
          type: 'dateRange',
          label: `Vertragsende (Zeitraum)`,
          key: 'vEnde',
          emptyDenied: true,
        },
        {
          type: 'datepicker',
          label: 'Vertragsende ab',
          key: 'vEndeAb',
          emptyDenied: true,
        },
        {
          type: 'datepicker',
          label: 'Vertragsende bis',
          key: 'vEndeBis',
          emptyDenied: true,
        },
        {
          type: 'numberRange',
          label: 'Jahresbeitrag',
          key: 'vJahresBetrag',
          emptyDenied: true,
        },
        {
          type: 'dateRange',
          label: isFA ? 'Hauptfälligkeit' : 'Kündbar zum',
          key: 'vFaeligkeit',
          emptyDenied: true,
        },
        {
          type: 'text',
          label: 'KFZ-Kennzeichen',
          key: 'kfz',
          emptyDenied: true,
        },
        {
          type: 'text',
          label: 'VP-Vorname',
          key: 'versPersVorname',
          emptyDenied: true,
        },
        {
          type: 'text',
          label: 'VP-Name',
          key: 'versPersNachname',
          emptyDenied: true,
        },
        {
          type: 'text',
          label: 'Ext. Kennung',
          key: 'extKennung',
          emptyDenied: true,
        },
        {
          type: 'text',
          label: 'Produktbezeichnung/Info',
          key: 'produktbezeichnung',
          emptyDenied: true,
        },
        {
          type: 'text',
          label: 'Bemerkung',
          key: 'bemerkung',
          emptyDenied: true,
        },
        {
          type: 'text',
          label: 'Vermittlernummer Gesellschaft',
          key: 'vermittlernr',
          emptyDenied: true,
        },
        {
          type: 'dateRange',
          label: isFA ? 'OMDS aktualisiert' : 'GDV aktualisiert',
          key: 'gdvAktuell',
          emptyDenied: true,
        },
        {
          type: 'dateRange',
          label: isFA ? 'OMDS nicht aktualisiert' : 'GDV nicht aktualisiert',
          key: 'gdvNichtAktuell',
          emptyDenied: true,
        }        
      ],
    },
    {
      type: 'group',
      key: 'versSparten',
      label: 'Vertragssparte',
      allowMultipleSearch: true,
      menuItems: Object.keys(data?.versSparten || {}).map((key) => {
        const filters = [{id: "ALLE", label: "Alle"}, ...data?.versSparten[key]];

        return {
          type: 'combobox',
          label: mapCategoryIdLabel[key],
          key: `versSparten@@${key}`,
          emptyDenied: true,
          comboOptions: [...mapListToComboBoxValues(filters, 'id', 'label')],
        };
      }).filter((v) => v !== null),
    },
  ];

  filters.forEach(group => {
    if(group.key === 'Vertragsdaten' && isFPPlus){
      group.menuItems.push({
        type: 'combobox',
        label: 'Anbindung',
        key: 'anbindungTyp',
        comboOptions: [...mapListToComboBoxValues(data?.vertragAnbindung, 'key', 'value')],
        emptyDenied: true,
      },
      {
        type: 'combobox',
        label: 'Betreuungsstufe',
        key: 'betreuungsstufe',
        comboOptions: [...mapListToComboBoxValues(data?.vertragBetreuungsstufe, 'key', 'value')],
        emptyDenied: true,
      })
    }
  })

  return filters;
}

export function headers(isFPPlus, isFA = false, isIntern, isSmallScreen, mitZuordnenAktion) {

    const lockedLeft = [
      TextColumn("bezeichnung", "Vertragsart").makeLink().makeAlwaysVisible().addCellProps({ lineClamp: 2, }),
    ];

    if (mitZuordnenAktion) {
      lockedLeft.push(SlotColumn("zurdnenAktion", "")); 
    }

    lockedLeft.push(PillColumn("status", "Status", 130).makeSortable());

    const result = {
        lockedLeft,
        center: [
          ...(isSmallScreen ? [TextColumn("gesellschaftText", "Gesellschaft")] : [SlotColumn("gesellschaft", "Gesellschaft", 200, 1)
            .makeSortable(gesell => gesell ? gesell.zweigstelleName || gesell.shortName : "")
            .withExporter((gesell, row, header) => gesell ? gesell.zweigstelleName || gesell.shortName : "")]),
            TextColumn("id", "Int.Nr"),
            PillColumn("bestand", "Bestand").makeSortable(),
            NumberColumn("weitereSparten", "Weitere Sparten"),
            TextColumn("produktbez", "Produktbezeichnung/Info").addCellProps({ lineClamp: 2, }),
            TextColumn("nummer", "Vers. Nr."),
            TextColumn("kategorieName", "Hauptkategorie").addCellProps({ lineClamp: 2, }),
            TextColumn("versObject", "Risiko"),
            TextColumn("tarif", "Tarif"),
            DateColumn("beginn", "Vertragsbeginn"),
            DateColumn("ende", "Vertragsende"),
            TextColumn("externeKennung", "Ext. Kennung"),
            DateColumn("dateUpdatetdGDV", `${isFA ? 'OMDS' : 'GDV'} aktualisiert`),
            NumberColumn("rueckkaufswert", "Rückkaufswert "),
            DateColumn("hauptfaelligkeit", isFA ? "Hauptfälligkeit" : "Kündbar zum"),
            TextColumn("maklernr", "Maklernr").makeLink(),
            TextColumn("maklername", "Maklername"),
            TextColumn("vertragsinhaber", "Vertragsinhaber "),
            TextColumn("zahlrhythm", "Zahlweise (ZW)").makeAlwaysVisible(),
            CurrencyColumn("praemiento_zahlweise", "Prämie Netto").makeAlwaysVisible(),
        ],
        lockedRight: [
            CurrencyColumn("praemiebto_zahlweise", "Prämie Brutto"),
            ActionColumn("actions", "Aktionen"),
        ],
    };
    if(isRolesIncludes([VIEW_ROLES.VIEW_BROKER]) ||  isRolesIncludes([ROLES.SUPER_CUSTOMER]) || isRolesIncludes([VIEW_ROLES.VIEW_INTERN]) ){
        result.center.unshift(TextColumn("kundennr", "KundenNr."));
        result.center.unshift(TextColumn("kundeName", "Nachname"));
        result.center.unshift(TextColumn("kundeVorname", "Vorname"));
    }
    if (isFPPlus)
        result.center.push(TextColumn("anbindungTyp", "Anbindung"));
    return result;
}
