var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "TeleportToBody",
    [
      _c(
        "ResizableModal",
        {
          ref: "resizablePopup",
          attrs: {
            modalTitle: _vm.modalTitle,
            initialWidth: _vm.initialWidth,
            initialHeight: _vm.initialHeight,
            sizeIsPercentage: _vm.sizeIsPercentage,
            startPosition: _vm.startPosition,
            id: _vm.id,
            fullClientHeight: _vm.fullClientHeight
          },
          on: {
            onMinimize: _vm.resizeObjectContainer,
            onMaximize: _vm.resizeObjectContainer,
            onResize: _vm.resizeObjectContainer
          }
        },
        [
          _vm.loading
            ? _c(
                "GhostLoading",
                [
                  _c("Block", { attrs: { type: "paragraph" } }),
                  _c("Block", { attrs: { type: "paragraph" } }),
                  _c("Block", { attrs: { type: "paragraph" } }),
                  _c("Block", { attrs: { type: "paragraph" } }),
                  _c("Block", { attrs: { type: "paragraph" } }),
                  _c("Block", { attrs: { type: "paragraph" } }),
                  _c("Block", { attrs: { type: "paragraph" } }),
                  _c("Block", { attrs: { type: "paragraph" } }),
                  _c("Block", { attrs: { type: "paragraph" } }),
                  _c("Block", { attrs: { type: "paragraph" } }),
                  _c("Block", { attrs: { type: "paragraph" } })
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { ref: "objectContainer", staticClass: "object-container" },
            [
              _c(
                "iframe",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.objectUrl && !_vm.loading,
                      expression: "objectUrl && !loading"
                    }
                  ],
                  attrs: { src: _vm.objectUrl, width: "100%", height: "100%" },
                  on: { load: _vm.onFileLoaded }
                },
                [
                  _vm._v(
                    " Der Browser unterstützt das Format nicht. Bitte laden Sie die Datei herunter: "
                  ),
                  _c("a", { attrs: { href: _vm.objectUrl } }, [
                    _vm._v("Herunterladen")
                  ]),
                  _vm._v(". ")
                ]
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }