<template>
  <div class="box__container">
    <div class="box__title">{{ title }}</div>

    <div>
      <p v-if="isBiPRO" class="mt-0 mb-2">
        Über die <b>BiPRO Zugänge</b> werden täglich die Dokumente von den Versicherungsgesellschaften geladen und bei den 
        jeweiligen Versicherungen Ihrer Kunden  im Servicecenter abgelegt. Gleichzeitig bekommen Sie eine Meldung unter 
        <b>Wichtige Warnungen/Stornos</b> angezeigt.
      </p>
      <p class="mt-0 mb-2"><b>Achtung</b> bei Gesellschaften die unter Zertifikat ein <b>'Ja'</b> stehen haben benötigen Sie ein Zertifikat. Dieses erhalten Sie von Ihrem Betreuer.</p>
      <p v-if="!isBiPRO" class="mt-0 mb-2">Für die Gesellschaften die unter Neustart ein <b>'Ja'</b> stehen haben ist für die Benutzung der Webseite unter 'Externe Zugänge' ein Neustart des Servicecenter notwendig.</p>
    </div>

    <GhostLoading v-if="isLoading" type="table" />
    <NoData v-else-if="!rows.length" />
    <Table
      v-else
      rowId="rowId"
      :headers="headers"
      :rows="rows"
      @action-NEW="openAddExterneZugangConfig($event, true)"
      @action-EDIT="openAddExterneZugangConfig($event)"
      @action-DELETE="deleteExterneZugangConfig($event && $event.id)"
    />

  </div>
</template>

<script>
import { mapGetters, } from 'vuex';
import EXTERNE_ZUGANG_CONFIG_TYPES from '@/store/externeZugangConfig/types';

import Table from '@/components/table2/Table.vue';
import { TextColumn, PillColumn, ActionColumn, SimpleAction, ConfirmedAction, } from '@/components/table2/table_util';
import { PhPencilLine, PhTrash, PhPlus, } from 'phosphor-vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import AddExterneZugangConfigModal from './../components/AddExterneZugangConfigModal.vue';
import mixin from '@/mixins/externe-zugaenge/externe-zugaenge-mixin.js';

const ZUGANGSART_BIPRO = 'BiPro';

export default {
  mixins: [mixin],
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapGetters({
      configData: EXTERNE_ZUGANG_CONFIG_TYPES.GETTERS.CONFIG_DATA,
      selectedZugangsArt: EXTERNE_ZUGANG_CONFIG_TYPES.GETTERS.SELECTED_ZUGANGSART,
      list: EXTERNE_ZUGANG_CONFIG_TYPES.GETTERS.LIST,
    }),
    title() {
      return this.selectedZugangsArt?.title || '';
    },
    isBiPRO() {
      return this.selectedZugangsArt?.id === ZUGANGSART_BIPRO;
    },
    biproMehrereZugaengeErlaubt() {
      return !!this.configData?.biproMehrereZugaengeErlaubt;
    },
    biproZugangsfehlerColumnVisible() {
      return !!this.configData?.biproZugangsfehlerColumnVisible;
    },
    filteredList() {
      const { selectedZugangsArt, } = this;
      if(!selectedZugangsArt) {
        return [];
      }

      const { list, } = this;
      const filteredList = list.filter(item => item.zugangsArt === selectedZugangsArt.id);
      return [ ...filteredList || [], ].map(item => ({
        ...item,
        rowId: `${item.bezeichnung}-${item.id}`,
        validPill: item?.valid ? { label: 'eingerichtet', type: 'success', } : { label: 'nicht eingerichtet', type: 'warning', },
        loginText: item?.vpnr || item?.login,
        zertifikatValidUntil: item?.validUntil ? new Date(item.validUntil).toLocaleDateString('de') : '',
        neustartText: item?.neustart ? 'Ja' : 'Nein',
        autoUpdateGdvDataText: item?.autoUpdateGdvData ? 'Ja' : 'Nein',
        zuordnungsfehlerFreigebenText: item?.zuordnungsfehlerFreigeben ? 'Ja' : 'Nein',
        useGdvMultiinterfaceText: item?.useGdvMultiinterface ? 'Ja' : 'Nein',
        actions: [ ...this.findActions(item), ],
       // test 123
      }));
    },
    headers() {
      const { isBiPRO, biproZugangsfehlerColumnVisible, } = this;

      const headers = {
        lockedLeft: [
          TextColumn('title', 'Name').addCellProps({ lineClamp: 2, }),
          PillColumn('validPill', 'Status'),
        ],
        center: [
          TextColumn('loginText', 'Login'),
          TextColumn('zertifikatValidUntil', 'Zertifikat gültig bis'),
        ],
        lockedRight: [
          ActionColumn('actions', 'Aktionen'),
        ],
      };

      if(isBiPRO) {
        headers.center.push(TextColumn('autoUpdateGdvDataText', 'GDV Daten automatisch einlesen'));
        if(biproZugangsfehlerColumnVisible) {
          headers.center.push(TextColumn('zuordnungsfehlerFreigebenText', 'Zuordnungsfehler freigeben'));
        }
        headers.center.push(TextColumn('id', 'Id'));
        headers.center.push(TextColumn('useGdvMultiinterfaceText', 'Fremdanlage Sonderbehandlung'));
      }else{
        headers.center.push(TextColumn('neustartText', 'Neustart'));
        headers.center.push(TextColumn('textZusatz', 'Zusatz'));
      }

      return headers;
    },
    rows() {
      return [ ...this.filteredList || [], ];
    },
  },
  methods: {
    findActions(item) {
      const actions = [];

      if(!item.available) return [];

      const { isBiPRO, biproMehrereZugaengeErlaubt, } = this;
      if(item.valid) {
        if(isBiPRO && biproMehrereZugaengeErlaubt) {
          actions.push(SimpleAction('NEW', PhPlus, 'Externen Zugang anlegen'));
        }
        actions.push(SimpleAction('EDIT', PhPencilLine, 'Bearbeiten'));
        actions.push(ConfirmedAction('DELETE', PhTrash, 'Löschen', 'Soll dieser externe Zugang wirklich gelöscht werden?'));
      } else {
        actions.push(SimpleAction('NEW', PhPlus, isBiPRO ? 'Externen Zugang anlegen' : 'Logindaten für externen Zugang eintragen'));
      }

      return actions;
    },
    openAddExterneZugangConfig(externeZugang, isNew = false) {
      this.$addBreadcrumb({
        label: 'zurück zu den externen Zugängen', 
        fullPath: this.$route.path,
        breadcrumb: externeZugang?.bezeichnung,
      });
      this.$router.push(`/intern/configs/externe-zugaenge/configs-extern-zugang-edit/${externeZugang?.bezeichnung}/${isNew ? 0 : externeZugang?.id}`)
    },
  },
  components: {
    Table,
    GhostLoading,
    NoData,
    AddExterneZugangConfigModal,
  },
}
</script>
