<template>
  <div v-if="isPlaceholderVisible && editor" class="margin-0" style="background-color: aqua;">
    <!--<BaseToolbarComboBox
      :value="placeholderCategory"
      :values="categories"
      :disabled="disabled"
      @change="changeCategory($event)"
    >
    </BaseToolbarComboBox>

    <PhCaretRight size="20" v-if="placeholderCategory" />

    <BaseToolbarComboBox
      v-if="placeholderCategory"
      :value="placeholder"
      :values="data"
      :disabled="disabled"
      @change="insertPlaceholder($event, editor)"
    >
    </BaseToolbarComboBox>


  
  -->

    <bubble-menu ref="menu" :editor="editor" :tippy-options="{ theme: 'smartmsc', duration: 100, maxWidth: 1000 }" v-if="editor"
      v-show="!bubbleSectionHidden" :shouldShow="({ editor, view, state, oldState, from, to }) => view.hasFocus()
        " >
      <BaseButton isClear @click="menuClose" class="bubble_close">
        <PhXCircle size="16" />
      </BaseButton>
      <div v-if="!placeholderCategory">
        <BaseButton class="bubble_button info" isClear>
          Einfügen:
        </BaseButton>
        <BaseButton class="bubble_button menu" isClear v-for="categorie in categories"
        @click="changeCategory(categorie.name)" :key="categorie.name">
          {{ categorie.name }}
        </BaseButton>
      </div>
      <div v-else>
        <BaseButton isClear @click="menuBack" class="bubble_button">
          <PhCaretLeft v-if="bubbleSectionIndex > 0" size="16" />
          <PhRewind v-else size="16" />
        </BaseButton>
        <BaseButton class="bubble_button menu" isClear v-for="placeholder in bubbleSection" :key="placeholder.label"
          @click="insertPlaceholder(placeholder.value, editor)">
          {{ placeholder.label }}
        </BaseButton>
        <BaseButton v-if="bubbleSectionIndex + 1 < data.length" class="bubble_button" isClear @click="menuForward">
          <PhCaretRight size="16" />
        </BaseButton>
      </div>
    </bubble-menu>
  </div>
</template>

<script>
import { PhCaretRight, PhCaretLeft, PhXCircle, PhRewind } from "phosphor-vue";
import { BubbleMenu } from "@tiptap/vue-2";
import BaseButton from "@/components/core/BaseButton.vue";
import 'tippy.js/dist/tippy.css';

export default {
  components: {
    PhCaretLeft,
    PhCaretRight,
    PhXCircle,
    PhRewind,
    BubbleMenu,
    BaseButton,
  },

  props: {
    editor: {},

    textPlaceholderOptions: {
      type: Array,
      default: null,
    },

    isPlaceholderVisible: {
      type: Boolean,
      default: false,
    },

    disabled: {
      default: false,
    },
  },

  data() {
    return {
      placeholderCategory: "",
      placeholder: "",
      bubbleSectionIndex: 0,
      bubbleSectionHidden: false,
    };
  },

  computed: {
    categories() {
      return this.textPlaceholderOptions;
    },
    data() {
      if (
        !this.textPlaceholderOptions.some(
          (el) => el.name == this.placeholderCategory
        )
      ) {
        return [];
      }

      return this.textPlaceholderOptions.find(
        (el) => el.name == this.placeholderCategory
      ).data;
    },
    bubbleSection() {
      return this.data.slice(
        this.bubbleSectionIndex,
        this.bubbleSectionIndex + 3
      );
    },
    hasFocus() {
      return this.editor && this.editor.view && this.editor.view.hasFocus()
    }
  },

  methods: {
    menuBack() {
      this.bubbleSectionIndex--;

      if (this.bubbleSectionIndex < 0) {
        this.placeholderCategory = "";
      }
    },
    menuForward() {
      this.bubbleSectionIndex++;

      if (this.bubbleSectionIndex + 2 >= this.data.length) {
        this.bubbleSectionIndex = this.data.length - 3;
      }
    },
    //Set isPlaceholderVisible to false
    menuClose() {
      //console.log(this.$refs['menu'].hide())
      //this.bubbleSectionHidden = true
      this.$emit('hide')
    },
    changeCategory(newValue) {
      this.placeholderCategory = newValue;
      this.bubbleSectionIndex = 0;
    },
    insertPlaceholder(value, editor) {
      editor?.commands.insertContent(value);
      this.$nextTick(() => {
        this.placeholderCategory = "";
        this.placeholder = "";
        this.$emit("inserted");
      });
    },
  },
  watch: {
    
    
  },
  mounted() {
  }
};
</script>
<style>
.tippy-box[data-theme~='smartmsc'] {
  background-color: var(--color-background);
  color: var(--color-text);
}

.tippy-content[data-theme~='smartmsc'] {
  margin: 0px;
  padding: 0px;
}
.tippy-box[data-theme~='smartmsc'][data-placement^='top'] > .tippy-arrow::before {
  border-top-color: var(--color-text);
}
.tippy-box[data-theme~='smartmsc'][data-placement^='bottom'] > .tippy-arrow::before {
  border-bottom-color: var(--color-text);
}
</style>
<style scoped>
.margin-0 {
  margin: 0px;
}

.placeholderSection {
  display: flex;
  flex: 0 0 auto;
  column-gap: 1rem;
  line-height: 1em;
  padding: 0;
  border: none;
}

.bubble_button:first-of-type {
  border-radius: 4px 0px 0px 4px;
}

.bubble_button {
  color: var(--color-text);
  border-color: var(--color-text);
  padding: 10px;
  border: 1px solid;
  overflow: hidden;
  font-size: 0.8rem;
  margin: 0px;
  border-radius: 0px;
}

.bubble_button:last-of-type {
  border-radius: 0px 4px 4px 0px;
}

.bubble_button.info {
  text-overflow: ellipsis;
  font-size: 0.8rem;
  text-decoration: underline;
}

.bubble_button.menu {
  text-overflow: ellipsis;
  font-size: 0.8rem;
}

.bubble_close {
  background-color: var(--color-primary);
  color: var(--color-primary-text);
  position: absolute;
  right: -16px;
  top: -8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 1rem;
  padding: 2px;
}

</style>
