var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? _c("GhostLoading", { attrs: { type: "table" } })
        : !_vm.hasLinks
        ? _c("NoData", { attrs: { noIcon: "" } })
        : _c(
            "ul",
            { staticClass: "m-0" },
            _vm._l(_vm.links, function(name, url) {
              return _c("li", { key: name }, [
                _c("a", { attrs: { href: url, target: "_blank" } }, [
                  _vm._v(_vm._s(name))
                ])
              ])
            }),
            0
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }