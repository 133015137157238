var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-panel" },
    [
      !_vm.hidePageHeader
        ? _c(
            "PageHeaderTitleNavigation",
            _vm._g(
              {
                attrs: {
                  id: _vm.id,
                  title: _vm.title,
                  subtitle: _vm.subtitle,
                  defaultMenu: _vm.defaultOptionsMenu,
                  actions: _vm.headerActionsInternal,
                  noPrimaryAction: _vm.noPrimaryAction
                },
                on: {
                  "action-EDIT": function($event) {
                    return _vm.openDashboardPanelConfigModal()
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "title",
                      fn: function() {
                        return [
                          _vm._t("title", function() {
                            return [_vm._v(_vm._s(_vm.title))]
                          })
                        ]
                      },
                      proxy: true
                    },
                    _vm.$slots.subtitle
                      ? {
                          key: "subtitle",
                          fn: function() {
                            return [_vm._t("subtitle")]
                          },
                          proxy: true
                        }
                      : null
                  ],
                  null,
                  true
                )
              },
              _vm.$listeners
            )
          )
        : _vm._e(),
      _vm.userLevelSaveConfig
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("Pill", [
                _vm._v("Einstellungen werden gespeichert für: "),
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        return _vm.openUserLevelConfigSelectModal()
                      }
                    }
                  },
                  [
                    _c("UserLevelText", {
                      attrs: { value: _vm.userLevelSaveConfig }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.$slots.topBox
        ? _c("div", { staticClass: "box__container" }, [_vm._t("topBox")], 2)
        : _vm._e(),
      !_vm.loading && !_vm.isExternalLoading && _vm.widgetsActive.length
        ? [
            _c(
              "SortableList",
              {
                attrs: {
                  items: _vm.widgetsActive,
                  disabled: !_vm.isEditAvailable || !_vm.config.sortable
                },
                on: {
                  orderChanged: function($event) {
                    return _vm.orderChanged($event)
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    class: _vm.isMasonry
                      ? "dashboard-masonry-panel__widgets"
                      : _vm.is3Columns
                      ? "dashboard-three-columns-panel__widgets"
                      : "dashboard-panel__widgets",
                    attrs: { "data-sortable-container": "" }
                  },
                  [
                    _vm._l(_vm.widgetsActive, function(widget, i) {
                      return [
                        _c(
                          "DashboardPanelWidget",
                          {
                            key: widget.name,
                            attrs: {
                              "data-sortable-item": "",
                              isMasonry: _vm.isMasonry,
                              is3Columns: _vm.is3Columns,
                              canEdit: _vm.canEdit,
                              widget: widget,
                              tid: _vm._generateTidFromString(
                                _vm.id + widget.name
                              ),
                              showToolbar: _vm.isEditAvailable,
                              actions: _vm.actions
                            },
                            on: {
                              executeAction: function($event) {
                                return _vm.$emit("executeAction", {
                                  action: $event,
                                  widget: widget,
                                  component: _vm.$refs["comp" + i]
                                    ? _vm.$refs["comp" + i][0]
                                    : widget.name
                                })
                              },
                              changeSize: function($event) {
                                return _vm.changeSize(widget, $event)
                              },
                              remove: function($event) {
                                return _vm.removeWidget(widget)
                              }
                            }
                          },
                          [
                            _vm.isLoading(widget)
                              ? [
                                  _c(
                                    "GhostLoading",
                                    {
                                      style: {
                                        height: "100%",
                                        minHeight: "175px"
                                      },
                                      attrs: { height: "100%" }
                                    },
                                    [
                                      _c("Block", { attrs: { height: "100%" } })
                                    ],
                                    1
                                  )
                                ]
                              : [
                                  widget.component
                                    ? _c(
                                        widget.component,
                                        _vm._b(
                                          {
                                            ref: "comp" + i,
                                            refInFor: true,
                                            tag: "component"
                                          },
                                          "component",
                                          widget.props,
                                          false
                                        )
                                      )
                                    : widget.name
                                    ? _vm._t(widget.name)
                                    : _vm._e()
                                ]
                          ],
                          2
                        )
                      ]
                    })
                  ],
                  2
                )
              ]
            )
          ]
        : _vm.loading || _vm.isExternalLoading
        ? _c(
            "div",
            { staticClass: "dashboard-panel__widgets" },
            _vm._l(_vm.widgetsActive.length, function(n) {
              return _c(
                "div",
                {
                  key: n,
                  staticClass: "dashboard-panel-widget box__container"
                },
                [
                  _c(
                    "GhostLoading",
                    [
                      _c("Block", { attrs: { type: "title" } }),
                      _c("Block", { attrs: { height: 175 } })
                    ],
                    1
                  )
                ],
                1
              )
            }),
            0
          )
        : _c("div", { staticClass: "box__container text-centered" }, [
            _c("div", [_vm._v("Keine Daten")])
          ]),
      _c("DashboardPanelConfigModal", {
        ref: "dashboardPanelConfigModal",
        attrs: {
          id: _vm.id,
          widgets: _vm.widgets,
          externalConfig: _vm.externalConfig,
          hiddenCards: _vm.hiddenCards,
          ignoreUserLevelConfig: _vm.ignoreUserLevelConfig
        },
        on: {
          saved: _vm.onSaved,
          onRestoreDefault: function($event) {
            return _vm.restoreDefaultConfig($event)
          }
        }
      }),
      _c("DashboardPanelUserLevelSelectModal", {
        ref: "dashboardPanelUserLevelSelectModal",
        attrs: { id: _vm.id, userLevel: _vm.userLevelSaveConfig },
        on: {
          userLevelChanged: function($event) {
            _vm.userLevelSaveConfig = $event
          },
          saveConfig: function($event) {
            return _vm.savePendingConfiguration($event)
          },
          onRestoreDefault: function($event) {
            return _vm.restoreDefaultConfig($event)
          }
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }