<template>
  <div class="box__container">
    <GhostLoading v-if="loading" type="table" title="Sonstiges" />
    <template v-else>
      <InputToggleSwitch v-model="eigeneApp" label="Eigene App" inLineLabel />
      <InputField label="App Name" v-model="appName" :disabled="!sonstiges.eigeneApp" />
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BROKERDATA_TYPES from "@/store/brokerData/types";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import InputField from '@/components/core/forms/InputField.vue';
import { required } from "@/mixins/validator/rules";
import validator from '@/mixins/validator'

export default {
  mixins: [validator],
  components: {
    GhostLoading, InputToggleSwitch, InputField
  },
  data() {
    return {
      loading: false,
    }
  },
  validators: {
    appName: [required('Bitte App Name eingeben', {forceTouch: true})],
  },
  computed: {
    ...mapGetters({
      sonstiges: BROKERDATA_TYPES.GETTERS.GET_SONSTIGES,
    }),
    eigeneApp: {
      get() { return this.sonstiges.eigeneApp },
      set(value) { this.commit({eigeneApp: value}) }
    },
    appName: {
      get() {return this.sonstiges.eigeneApp ? this.sonstiges.appName : 'FinanceApp'},
      set(value) { this.commit({appName: value}) }
    }
  },
  beforeRouteLeave(to, from, next) {
    if (from.path.indexOf('vermittlerdaten/sonstiges') == -1 || this.save()) {
      next();
    }
  },
  mounted() {
    this.loadSonstiges();
  },
  methods: {
    async loadSonstiges() {
      this.loading = true;
      await this.$store.dispatch(BROKERDATA_TYPES.ACTIONS.LOAD_SONSTIGES);
      this.loading = false;
    },
    commit(value) {
      this.$store.commit(BROKERDATA_TYPES.MUTATIONS.SET_SONSTIGES, {...this.sonstiges, ...value, edited: true })
    },
    save() {
      if (this.sonstiges.eigeneApp && !this.sonstiges.appName) {
        return false;
      }
      if (this.sonstiges.edited) {
        this.$store.dispatch(BROKERDATA_TYPES.ACTIONS.SAVE_SONSTIGES);
      }
      return true;
    },
  },
};
</script>
