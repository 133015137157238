import OPEN_SIGNS_TYPES from '@/store/openSigns/types';
import COMMUNICATION_TYPES from '@/store/communication/types';
import MSC_NEWS_TYPES from '@/store/mscnews/types';
import STECKBRIEF_TYPES from '@/components/steckbrief/store/types';

/**
 * Any menu url can be configured as badge.
 * 
 * Configuration example:
 * '<menu url>': {
 *   count: <store getter key | [store getter key 1, store getter key 2]>,
 *   load: <must be a function where the count can be loaded>
 * }
 */
export default {
  primaryMenu: {
    '/communication/postfach': {
      name: 'POSTFACH',
      count: COMMUNICATION_TYPES.GETTERS.COMMUNICATIONS_UNREAD,
        load: ({ dispatch }) => dispatch(COMMUNICATION_TYPES.ACTIONS.UNREAD_COMMUNICATIONS),
      //load: ({ dispatch }) => {},dispatch(COMMUNICATION_TYPES.ACTIONS.UNREAD_COMMUNICATIONS),
      //, // TODO disabled for performance tests on Nachrichten
    },
    '/communication/todo-list': {
      name: 'TODO_LIST',
      count: [
        OPEN_SIGNS_TYPES.GETTERS.OPEN_SIGNS_COUNT,
        STECKBRIEF_TYPES.GETTERS.TODO_VIDEO_INDENT_COUNT,
      ],
      load: ({ dispatch }) => {
        dispatch(OPEN_SIGNS_TYPES.ACTIONS.GET_OPEN_SIGNS_COUNT);
        dispatch(STECKBRIEF_TYPES.ACTIONS.GET_TODO_VIDEO_INDENT_COUNT);
      },
    },
  },
  optionsMenu: {
    '/communication/todo-list/offene-unterschriften': {
      name: 'TODO_LIST_OPEN_SIGNS',
      count: OPEN_SIGNS_TYPES.GETTERS.OPEN_SIGNS_COUNT,
    },
    '/communication/todo-list/video-ident-vorhanden': {
      name: 'TODO_LIST_VIDEO_INDENT',
      count: STECKBRIEF_TYPES.GETTERS.TODO_VIDEO_INDENT_COUNT,
    },
    '/intern/news-msc': {
      name: 'NEWS-MSC-COUNT-TEST',
      count: MSC_NEWS_TYPES.GETTERS.MSC_NEWS_COUNT,
      load: ({ dispatch }) => dispatch(MSC_NEWS_TYPES.ACTIONS.GET_MSC_NEWS_COUNT),
    },
    '/communication/postfach/chats': {
      name: 'POSTFACH_CHATS',
      count: COMMUNICATION_TYPES.GETTERS.COMMUNICATIONS_UNREAD_CHATS,
      load: ({ dispatch }) => dispatch(COMMUNICATION_TYPES.ACTIONS.UNREAD_COMMUNICATIONS),
      //load: ({ dispatch }) => {}, // dispatch(COMMUNICATION_TYPES.ACTIONS.UNREAD_COMMUNICATIONS), // TODO disabled for performance tests on Nachrichten
    },
  },
}
