<template>
  <div class="box__container-wrap">
    <div class="box__container-rows">
      <template v-if="rows.length">
        <OverviewTable v-if="!$isSmallScreen" :records="rows" @title="openCustomerNewTab" @subject="clickBetreff">

          <template #status="row">
            <span>{{ row.status }}</span>
          </template>
        </OverviewTable>
        <Table v-else :headers="headers" :rows="rows" rowId="rowId"
          :mobileConfig="{ title: 'title', headers: ['subject', 'date'] }" @clickRow="clickBetreff" />
      </template>
      <GhostLoading v-else-if="loading" type="table" :config="{ table: { rows: 5 } }" />
      <NoData :noIcon="$isSmallScreen" v-else />
    </div>

    <div class="bottom-row text-right mt-3">
      <router-link :to="`/communication/postfach/${routeStep}`">
        weitere...
      </router-link>
    </div>
  </div>
</template>

<script>
import CALENDAR_TYPES from "@/store/calendar/types";
import postfachMixin from '@/views/communication/postfach/widgets/postfach-mixin.js';
import Table from "@/components/table2/Table.vue";
import { TextColumn, DateColumn } from "@/components/table2/table_util.js";

export default {
  mixins: [postfachMixin],
  components: {
    Table,
  },
  data() {
    return {
      routeStep: 'aktivitaeten',
    }
  },
  computed: {
    headers() {
      return {
        lockedLeft: [
          TextColumn("title", "Name"),
          DateColumn("date", "Datum"),
          TextColumn("subject", "Betreff"),
        ],
        center: [],
        lockedRight: []
      }
    },
    rows() {
      return this.records.map(record => ({
        ...record,
        title: record.name || "[Unbekannt]",
        subject: record.subject || "[kein Betreff]",
        isTitleLink: this.canOpenCustomer(record),
        isSubjectLink: !!record.commId,
        rowId: ('' + record.commId + record.date),
        status: record.status
      }))
    },
  },
  methods: {
    clickBetreff(row) {
      if (row.commId) {
        this.$store.dispatch(CALENDAR_TYPES.ACTIONS.RETRIEVE_SELECTED_APPOINTMENT, row.commId);
        this.openAppointment();
      }
    },
    openAppointment() {
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_CONFIG, {
        isActivity: true,
        selectedDate: null,

        back: this.$router.currentRoute.fullPath,
      })
      this.$router.push({ path: `/communication/appointment` });
    },
  },
}
</script>