<template>
  <div>
      <AntragHeaderTitleNavigation 
        :title="lagerstelleName"
        :subtitle="antragLabel"
        :antragId="antragId" 
        :antrag="antrag" 
        :antragData="antragData" 
        :warnings="warnings"
        :currentStepAdditionalButtons="additionalButtonsForCurrentStep"
        :disabled="disabled"
      />
      <div class="row my-0">
        <div class="d-lg-none col-12" v-if="isSmallOrMediumScreen">
          <AntragStep 
            v-if="steps"
            :antragStep="steps"
            :antragData="antragData"
            @setStep="setStepByKey($event)"
            @setSubstep="setSubstepByKey($event)"
            >
          </AntragStep>
        </div>
      </div>

      <div class="row my-0 hide-on-small-screen" v-if="!isSmallOrMediumScreen">
        <div class="col-12" v-if="routeStep !== 'aktionen'">
          <AntragSidetext 
            :warnings="warnings"
            :steps="antrag && antrag.steps"
            :showExpanded="antragId == 'FK-aenderungsformular'"
            :highestStepVisited="antrag && antrag.highestStepVisited"
            @setStep="setStepByKey($event)"
            @setSubstep="setSubstepByKey($event)">
          </AntragSidetext>
        </div>
      </div>

      <ContentWithStepper :stepperCollapsed="stepperCollapsed" v-if="!isSmallOrMediumScreen">
        <template #stepper v-if="!hideStepper">
          <AntragVerticalStep
            v-if="steps"
            :antragStep="steps"
            :antragData="antragData"
            @setStep="setStepByKey($event)"
            @setSubstep="setSubstepByKey($event)"
            @collapsed="stepperCollapsed = $event"
            @zusammenfassung="saveAntrag($event)">
          </AntragVerticalStep>
        </template>
        <template #content>
          <div class="row my-0">
            <div class="col-12">
              <AntragComponentList 
                v-if="components"
                :antragComponents="components"
                :comboboxSelections="comboboxSelections"
                :multiSelectionsTabledata="multiSelectionsTabledata"
                :antragId="antragId"
                :warnings="warnings"
                :antragData="antragData"
                @change="changeEvent"
                showTwoColumns>
              </AntragComponentList>
            </div>
          </div>
          <div class="row my-0" v-if="routeStep === 'aktionen'">
            <div class="col-12">
              <AntragSidetext 
                :warnings="warnings"
                :steps="antrag && antrag.steps"
                :highestStepVisited="antrag && antrag.highestStepVisited"
                showMessageErrorMustBeFixed
                showExpanded
                @setStep="setStepByKey($event)"
                @setSubstep="setSubstepByKey($event)">
              </AntragSidetext>
            </div>
          </div>
          <div class="row my-0" v-if="routeStep === 'zusdokumente'">
            <div class="col-12">
              <AntragAttachments
                :steps="antrag && antrag.steps"
                :antragData="antragData"
                :antragId="antragId"
              />
            </div>
          </div>
          <div class="row my-0" v-if="antrag && !antrag.hideBottomButtons">
            <div class="col-12">
              <AntragBottomButtons 
                :antragStep="steps"
                :routeStep="routeStep"
                :antragData="antragData" 
                :antragId="antragId">
              </AntragBottomButtons>
            </div>
          </div>
        </template>
      </ContentWithStepper>

      <BaseModal ref="infoModal" :showDefaultButtons="false">
        <template v-slot:modalTitle>
              <PhInfo style="color: var(--color-success)"/>  {{infoTitle}}
        </template>
        
          <template #default>
            <div class="info-modal--content" v-html="antragData.aenderungenHinweise"></div>
        </template>   
      </BaseModal>

      <BaseModal
        ref="editModal"
        :modalTitle="currentAntragStepTitle"
        size="lg"
        :showCancelButton="false"
        @onCloseButton="onCloseEditModal()"
        :actions="baseModalActions"
        :showConfirmButton="false"
        :autoCloseOnRouteNavigation="false"
        @action-WEITER="navigateNextStep()"
        @action-ZURUCK="navigatePreviousStep()"
        @action-SCHLIESSEN="onSchliessen()"
        @action-PREVIOUS_FORM="navigateToPreviousAntrag()">

        <div  class="modal__container" v-if="!loadingStep">
          <div class="row my-0">
            <div class="col-12">
              <AntragComponentList 
                v-if="components"
                :antragComponents="components"
                :comboboxSelections="comboboxSelections"
                :multiSelectionsTabledata="multiSelectionsTabledata"
                :antragId="antragId"
                :warnings="warnings"
                :antragData="antragData"
                @change="changeEvent"
                showTwoColumns>
              </AntragComponentList>
            </div>
          </div>
          <div class="row my-0" v-if="routeStep === 'aktionen'">
            <div class="col-12">
              <AntragSidetext 
                :warnings="warnings"
                :steps="antrag && antrag.steps"
                :highestStepVisited="antrag && antrag.highestStepVisited"
                showMessageErrorMustBeFixed
                showExpanded
                @setStep="setStepByKey($event)"
                @setSubstep="setSubstepByKey($event)">
              </AntragSidetext>
            </div>
          </div>
          <div class="row my-0" v-if="routeStep === 'zusdokumente'">
            <div class="col-12">
              <AntragAttachments
                :steps="antrag && antrag.steps"
                :antragData="antragData"
                :antragId="antragId"
              />
            </div>
          </div>
<!--           <div class="row my-0" v-if="antrag && !antrag.hideBottomButtons">
            <div class="col-12">
              <AntragBottomButtons 
                :antragStep="steps"
                :routeStep="routeStep"
                :antragData="antragData" 
                :antragId="antragId">
              </AntragBottomButtons>
            </div>
          </div> -->
        </div>
        <GhostLoading v-else type="table" />

     

      </BaseModal>       
        
  </div>
     
</template>

<script>
import AntragStep from '@/components/antrag/AntragStep.vue'; 
import AntragAttachments from '@/components/antrag/AntragAttachments.vue'; 
import AntragSidetext from '@/components/antrag/AntragSidetext.vue'; 
import AntragComponentList from '@/components/antrag/AntragComponentList.vue'; 
import AntragBottomButtons from '@/components/antrag/AntragBottomButtons.vue'; 
import antragNavigationMixin from '@/mixins/antrag/antrag-navigation-mixin.js';
import AntragVerticalStep from '@/components/antrag/AntragVerticalStep.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import AntragHeaderTitleNavigation from '@/components/antrag/AntragHeaderTitleNavigation.vue';
import ContentWithStepper from '@/components/core/ContentWithStepper.vue';
import ANTRAG_TYPES from '@/store/antrag/types';
import BaseModal from "@/components/core/BaseModal.vue";
import {PhInfo} from 'phosphor-vue';
import { mapGetters } from 'vuex';
import BRIDGE_TYPES from '@/store/bridge/types';
import CORE_TYPES from "@/store/core/types";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import { BaseModalSimpleAction } from '@/components/core/base-modal-actions/base-modal-actions-utils';



export default {
  mixins: [antragNavigationMixin],
  inject: {
    antragCustomNavigation: {
      from: 'antragCustomNavigation',
      default: () => ({}),
    },
  },
  props: {
    antrag: {       
    },
    antragData: {
    },
    routeStep: {
    }
  },
  data() {
    return {
      stepperCollapsed: false,
      hideSingleStepEndorsedByMichael: false,
      showingEditModal: false,
      loadingStep: false,
      infoTitle: '',
    };
  },
  computed: {
    ...mapGetters({
      isMobileNativeContext: BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT,
      isSmallScreen: CORE_TYPES.GETTERS.IS_SMALL_SCREEN,
      screenSize: CORE_TYPES.GETTERS.SCREEN_WIDTH,
    }),
    baseModalActions() {
      const actions =  [
        BaseModalSimpleAction('WEITER', 'Weiter')
          .withPrimary(() => true)
          .withLoading(() => this.isLoadingNextAntragStep)
          .withVisible(() => this.hasNextAntragStep),
        BaseModalSimpleAction('SCHLIESSEN', 'Schließen')
          .withPrimary(() => true)
          .withVisible(() => this.routeStep === 'aktionen' || !this.hasNextAntragStep),
        BaseModalSimpleAction('ZURUCK', 'Zurück')
          .withLoading(() => this.isLoadingPreviousAntragStep)
          .withVisible(() => this.hasPreviousAntragStep),
      ];

      if (this.hasBachToPreviousFormLink) {
        actions.push(new BaseModalSimpleAction('PREVIOUS_FORM', this.backToPreviousFormLabel));

      }

      return actions;
    },
    isMediumScreen() {
      return this.screenSize === 'md';
    },   
    isSmallOrMediumScreen() {
      return this.isSmallScreen || this.isMediumScreen;
    },   
    lagerstelleName() {
      const name = this.$route.query.lagerstelleLabel || this.$route.params.lagerstelle;
      switch (name) {
        case 'HELLOBANK':
          return 'EASYBANK';
        case 'CAPITALBANK':
          return 'SCHELHAMMER CAPITALBANK';
        case 'DWS FONDSPLATTFORM FFM':
          return 'MorgenFund Deutschland';
        case 'DWS FONDSPLATTFORM':
          return 'MorgenFund Zweigeniederlassung Luxemburg';
        case 'EBASE':
          return 'FNZ Bank';
        case 'vv':
          return 'Vermögensverwaltung';
      }
      return name;
    },
    hideStepper() {
      let antragSteps = this.steps;
      if (antragSteps && Array.isArray(antragSteps) && antragSteps.length > 1) {
        return false;
      }
      return true && this.hideSingleStepEndorsedByMichael;
    },
    steps() {
      const result = this.antrag && this.antrag.steps && this.antrag.steps.map(step => {
        return {
          ...step,
          warning: step.stepKey === 'aktionen' && this.warnings && (this.warnings.antragWarnings?.filter(warn => warn?.status !== 'HINWEIS')?.length 
            || this.warnings.positionWarnings?.filter(warn => warn?.status !== 'HINWEIS')?.length),
        }
      });
      if (result) {
        result.forEach(step => {
          if(step.substeps) {
            step.substeps.forEach(substep => {
            substep.warning = false
            })
          }
        })
      }

      if (this.warnings) {
        let warnings = this.warnings.antragWarnings.concat(this.warnings.positionWarnings);
        warnings.forEach(warning => {
          const stepIndex = result.findIndex(step => step.stepKey == warning.stepKey);
          const currentStep = result[stepIndex]

          // set warnings for substeps
          if (currentStep && currentStep.substeps && currentStep.substeps.length && warning.substepKey) {
            const substepIndex = currentStep.substeps.findIndex(substep => substep.substepKey === warning.substepKey);

            if (substepIndex >= 0) {
              result[stepIndex].substeps[substepIndex].warning = true
            }
          } else if (stepIndex >= 0) {
            result[stepIndex].warning = true;
          }
            
        })
      }
      return result;
    },
    components() {
      return this.antrag && this.antrag.components
    },
    additionalButtonsForCurrentStep() {
      const routeStep = this.$route.params.step;
      if (routeStep && this.components && this.components[routeStep]) {
        return this.components[routeStep].filter(ct => ct.type && ct.type === 'BUTTON_DECKBLATT');
      }
      const routeSubstep = this.$route.params.substep;
      if (routeSubstep && this.components && this.components[routeSubstep]) {
        return this.components[routeSubstep].filter(ct => ct.type && ct.type === 'BUTTON_DECKBLATT')
      }
      return []
    },
    comboboxSelections() {
      return this.antrag && this.antrag.comboboxSelections
    },
    multiSelectionsTabledata() {
      return this.antrag && this.antrag.multiSelectionsTabledata
    },
    antragId() {
      return this.antrag && this.antrag.id
    },
    warnings() {
      if (this.antrag && this.antrag.warnings)
        return {
          antragWarnings: !this.antrag.warnings.antragWarnings ? [] : this.antrag.warnings.antragWarnings.filter(warning => {
            const stepIndex = this.antrag.steps.findIndex(step => step.stepKey == warning.stepKey);
            return stepIndex < this.antrag.highestStepVisited;
          }),
          positionWarnings: !this.antrag.warnings.positionWarnings ? [] : this.antrag.warnings.positionWarnings.filter(warning => {
            const stepIndex = this.antrag.steps.findIndex(step => step.stepKey == warning.stepKey);
            return stepIndex < this.antrag.highestStepVisited;
          }),
        }
      return null;
    },
    antragLabel() {
      return this.antrag?.label || ''
    },
    disabled() {
      return !this.antragData || (!this.antragData.antragsdatenId && !this.antragData.PARAMETER_PROTOKOLL_ID && !this.antragData.PARAM_ID) || (this.antrag && this.antrag.dataHasChanged)
    },
  
    isReadOnly() {
      return this.antragData?.READ_ONLY || false;
    }
  },
  watch: {
    steps(newVal) {
      if (newVal) {
        if (this.$route.query.selectStep) {
          const selectStep = newVal.find(step => step.stepKey?.includes(this.$route.query.selectStep));
          selectStep ? this.navigateToStep(selectStep.stepKey) : this.navigateToFirstStep(); 
        } else {
          this.navigateToFirstStep();
        }
      }
    }
  },
  methods: {
    onCloseEditModal() {
      this.showingEditModal = false;
    },
    openEditModal() {
      if (this.isSmallOrMediumScreen) {
        this.$refs.editModal.open();
      }
    },
    async setStepByKey(stepKey) {
      await this.navigateToStep(stepKey);
      this.openEditModal();
    },
    async setSubstepByKey(event) {
      await this.navigateToSubstep(event);
      this.openEditModal();
    },
    isAntragSaved() {
      return this.antragData && (this.antragData['antragsdatenId'] || this.antragData['antragsnrIntern'])
        && !this.antrag?.dataHasChanged;
    },
    async saveAntrag(selectStep){
      if(selectStep == 'aktionen'  && !this.isAntragSaved() && !this.isReadOnly) {

        if (this.antrag.id && this.$route.params.lagerstelle) {
        
          const payload = {
            id: this.antrag.id,
            data: {}
          }
          this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_ANTRAG_DATA, payload)
        }

        await this.$store.dispatch(ANTRAG_TYPES.ACTIONS.SAVE_ANTRAG, {
            id: this.antrag.id,
            lagerstelle: this.$route.params.lagerstelle,
          });
      }
    },
    changeEvent(event) {
      if (event?.button === 'BUTTON_ACTION_EMAIL' && this.antrag?.id === 'FK-aenderungsformular') {
         this.infoTitle = 'E-Mails werden an die Gesellschaften geschickt.\nDie Bestätigung der Adressänderungen finden Sie in den jeweiligen Verträgen, im Bereich “E-Mails & Aktivitäten”.'
         this.$refs.infoModal.open(); //MSC-21847
        //this.$router.push(`/communication/mailcomposer-antrag/${this.antragData?.antragsdatenId}`) 
      }
    },
    onSchliessen() {
      const payload = {
        id: this.antragId,
        data: {}
      }

      if(!this.antragData?.isWebserviceAktiv){
        this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_ANTRAG_DATA, payload)
      }

      this.$refs.editModal?.close?.();
      this.$goToLastPage();
    }
  },
  mounted() {
    if (this.$route.query?.backAction) {
      this.openEditModal();
    }
  },
  components: {
    AntragStep,
    AntragComponentList,
    AntragBottomButtons,
    AntragAttachments,
    AntragSidetext,
    AntragVerticalStep,
    OptionMenu,
    AntragHeaderTitleNavigation,
    ContentWithStepper,
    BaseModal,
    PhInfo,
    GhostLoading,
  }
}
</script>
<style scoped>

</style>