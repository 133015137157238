var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.rows.length
        ? _c("Table", {
            attrs: {
              tableId: "dbbdcfe4-b5c1-4386-86d8-efac26962b2c",
              headers: _vm.headers,
              rows: _vm.rows,
              rowsPerPage: 10,
              headerActions: _vm.tableHeaderActions,
              mobileConfig: {
                title: "meldedatum",
                headers: [
                  "schadennr",
                  "sparte",
                  "schadensursacheText",
                  "bearbstandLabel"
                ]
              },
              rowId: "schadenId"
            },
            on: {
              "click-meldedatum": _vm.openSchaden,
              "headerAction-Schaden-VEREINIGEN": function($event) {
                return _vm.openVertragVereinigenModal()
              },
              "action-DELETE": _vm.deleteSchadensmeldung
            },
            model: {
              value: _vm.selectedRows,
              callback: function($$v) {
                _vm.selectedRows = $$v
              },
              expression: "selectedRows"
            }
          })
        : _c("div", [_vm._v("Keine Daten")]),
      _c("SchadenVereinigenModal", {
        ref: "SchadenVereinigenModal",
        attrs: { vertragList: _vm.selectedRows },
        on: {
          save: function($event) {
            return _vm.mergeSchaden($event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }