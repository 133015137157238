<template>
  <div>
    <div class="row">
      <div class="col-12" v-if="canViewCourtageStep">
        <InputField
          id="courtageBemerkung"
          :label="isIntern && !isMaklerzugang ? 'Bemerkung zur Courtage (Sichtbar für Vermittler)' : 'Bemerkung zur Courtage'"
          :disabled="cannotEditAPBemerkung"
          v-model="versicherungenDetails.insurance.courtageBemerkung"
          @input="dataChanged('courtageBemerkung', $event)"
        />
      </div>
    </div>    
    <div class="row">
      <div class="col-12">
        <InputField
          id="produktbezeichnung"
          label="Produktbezeichnung"
          v-model="versicherungenDetails.produktbez"
          disabled
        />
      </div>
    </div>
    <div class="row" v-if="isIntern">
      <div class="col-6">
        <InputCheckBoxItem
          label="ThinkSurance Vergleichsrechner"
          v-model="form.thinksuranceVergleich"
        />
      </div>
    </div>
    <div class="row" v-if="isIntern">
      <div class="col-6">
        <InputCheckBoxItem
          label="ThinkSurance Angebotserstellung"
          v-model="form.thinksuranceAngebot"
        />
      </div>
    </div>
    <div class="row" v-if="editable">
      <div class="col 12">
        <BaseButton @click="openNewBuchung()">Neue</BaseButton>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <Table v-if="!loading && rows.length"
          title="Abrechnungen"
          :headers="headers"
          :rows="rows"
          :rowsPerPage="10"
          rowId="buchungsnr"
          v-model="selectedRowsAbrechnungTable"
          :exportConfig="{roottext: 'Abrechnungen'}"
          @click-buchungsnr="openEditBuchung"
          @action-DELETE="deleteAbrechnung($event)"
          @action-STRUKTUR="openVerteilungAufDieStruktur($event)"
          @action-COPY="copyVersicherungBuchung($event)"
          @action-STORNO="stornierenVersicherungBuchung($event)"
        />
        <GhostLoading v-else-if="loading" type="table" />
        <NoData v-else content="Es wurden keine Einträge gefunden" />
      </div>
    </div>

    <BaseModal
      ref="buchungDeleteModal"
      size="sm"
      modalTitle="Buchung löschen"
      labelButtonConfirm="Löschen"
      @onConfirmButton="deleteBuchungItem()"
    >
      <template #default>
        Soll die Buchung {{ abrechnungItemToDelete.buchungsnr }} wirklich gelöscht werden?
      </template>
    </BaseModal>

    <BaseModal
      ref="buchungCopyModal"
      size="sm"
      modalTitle="Buchung kopieren"
      labelButtonConfirm="kopieren"
      @onConfirmButton="copyBuchungItem()"
    >
      <template #default>
        <h3 class="color-danger">ACHTUNG!</h3>
        <p>Die Courtagesätze werden gemäß der Verteilung des Originals ebenfalls kopiert!</p>
        <p>Dies gilt allerdings nur solange Sie die Verteilung nicht neu berechnen lassen!</p>
        <p>Möchten Sie mit dem Kopieren fortfahren?</p>
      </template>
    </BaseModal>

    <BaseModal
      ref="buchungStornierenModal"
      size="sm"
      modalTitle="Buchung Stornieren"
      labelButtonConfirm="Stornieren"
      @onConfirmButton="stornierenBuchungItem()"
    >
      <template #default>
        <h3 class="color-danger">ACHTUNG!</h3>
        <p>Die Courtagesätze werden gemäß der Verteilung des Originals ebenfalls kopiert und ins Minus gesetzt!</p>
        <p>Dies gilt allerdings nur solange Sie die Verteilung nicht neu berechnen lassen!</p>
        <p>Möchten Sie mit dem Stornieren fortfahren?</p>
      </template>
    </BaseModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VERSICHERUNG_TYPES from "@/store/versicherungen/types";
import INSURANCE_BUCHUNG_TYPES from "@/store/insuranceBuchung/types";
import CORE_TYPES from '@/store/core/types';
import BaseModal from '@/components/core/BaseModal.vue';
import BaseButton from "@/components/core/BaseButton.vue";
import Table from '@/components/table2/Table.vue';
import {TextColumn, PercentageColumn, ActionColumn, LoadingAction, SimpleAction, NumberColumn } from "@/components/table2/table_util.js";
import {PhTrash, PhArrowsLeftRight, PhCopy, PhArrowBendDoubleUpRight } from 'phosphor-vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import InputCheckBoxItem from '@/components/core/forms/checkbox/InputCheckBoxItem.vue';
import InputField from "@/components/core/forms/InputField.vue";
import { VIEW_ROLES, ROLES } from "@/router/roles";
import InsuranceDetailMixin from '@/components/versicherungen/InsuranceDetail-mixin.js';
import NoData from '@/components/core/NoData.vue';

const PRECISION = 2;

export default {
  mixins: [InsuranceDetailMixin],
  components: {
    BaseModal,
    BaseButton,
    Table,
    PhTrash,
    PhArrowsLeftRight,
    PhCopy,
    PhArrowBendDoubleUpRight,
    GhostLoading,
    InputCheckBoxItem,
    InputField,
    NoData,
  },
  props:{
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      abrechnungItemToDelete: {},
      abrechnungItemToCopy: {},
      deleteLoading: false,
      deleteId: null,
      form: {},
      selectedRowsAbrechnungTable: null,
      copyLoading: null,
      copyBuchungID: null,
      newAPBemerkung: null,
    }
  },
  watch: {
    versicherungenDetails(value) {
      this.newAPBemerkung = !value?.insurance?.courtageBemerkung?.length;
    }
  },
  computed: {
    ...mapGetters({
      abrechnungen: VERSICHERUNG_TYPES.GETTERS.INSURANCE_ABRECHNUNGEN,
      versicherungenDetails: VERSICHERUNG_TYPES.GETTERS.INSURANCE_DETAILS,
      isIntern: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
      isMaklerzugang: CORE_TYPES.GETTERS.IS_MAKLERZUGANG,
      isFpPlus: CORE_TYPES.GETTERS.IS_FPP_PLUS,
      isHauptstrukturleitenr: CORE_TYPES.GETTERS.IS_HAUPTSTRUKTURLEITENR,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      isFA: CORE_TYPES.GETTERS.IS_FA,
      isFK: CORE_TYPES.GETTERS.IS_FK,
    }),
    hasSpecialPermission() {
      const customerOrCustomerAsIntern = this.hasRoles([[VIEW_ROLES.VIEW_CUSTOMER, VIEW_ROLES.VIEW_CUSTOMER_AS_INTERN]]);
      const notBroker = !this.hasRoles([[VIEW_ROLES.VIEW_CUSTOMER_AS_BROKER]]);
      return customerOrCustomerAsIntern || notBroker;
    },
    headers() {

      const internColumns = [
        TextColumn("zeichnungsdatum", "Z.-Datum"),
        TextColumn("courtageeingDatum", "Prov. Eingang"),
        TextColumn("courtageart", "Prov. Art"),
        PercentageColumn("provisionsSatzProzent", "Prov. Satz %"),
        NumberColumn("bewertungssumme", "Bew.Summe", PRECISION),
        NumberColumn("courtagesumme", "Courtagesumme", PRECISION),
        TextColumn("status", "Status"),
        TextColumn("automatisch", "Auto"),
        TextColumn("rechDatum", "Abr. Datum"),
        TextColumn("istOverhead", "Overhead"),
        TextColumn("userId", "Bearbeiter"),
        TextColumn("bemerkung", "Bemerkung"),
      ];
      
      const editableCourtageColumns = [
        TextColumn("zeichnungsdatum", "Z.-Datum"),
        TextColumn("courtageeingDatum", "Prov. Eingang"),
        TextColumn("courtageart", "Prov. Art"),
        NumberColumn("bewertungssumme", "Bew.Summe",PRECISION),
        NumberColumn("provBetrag", "Prov. Betrag",PRECISION),
        TextColumn("status", "Status"),
        TextColumn("automatisch", "Auto"),
        TextColumn("rechDatum", "Abr. Datum"),
        TextColumn("userId", "Bearbeiter"),
        TextColumn("bemerkung", "Bemerkung"),
      ];

      const columns = {
        center: [
          TextColumn("zeichnungsdatum", "Z.-Datum"),
          // TextColumn("courtageeingDatum", "Prov. Eingang"),
          TextColumn("courtageart", "Prov. Art"),
          NumberColumn("bewertungssumme", "Bew.Summe", PRECISION),
          // NumberColumn("provBetrag", "Prov. Betrag",PRECISION),
          // TextColumn("status", "Status"),
          TextColumn("rechDatum", "Abr. Datum"),
          TextColumn("bemerkung", "Bemerkung"),
        ],
      }

      let lockedLeft = [];
      let lockedRight = [];

      if (this.hasSpecialPermission) {
        lockedLeft = [
          TextColumn("buchungsnr", "Buchungsnr").makeLink().makeAlwaysVisible(),
        ];
        columns.center.splice(1, 0, TextColumn("courtageeingDatum", "Prov. Eingang"))
        columns.center.splice(5, 0, TextColumn("status", "Status"))
      }
      lockedRight = [
        ActionColumn("actions", "Aktionen"),
      ];

      columns['lockedLeft'] = lockedLeft;
      columns['lockedRight'] = lockedRight;

      if(this.isIntern) {
        columns.center = internColumns;
      } else
      if(this.editable){
        columns.center = editableCourtageColumns;
      }
      return columns;
    },
    rows() {
      if (!this.abrechnungen) {
          return [];
      }
      const disabled = (row) => row.status < 3 && row.allowNewBuchung && !this.isIntern;

      const getActions = (row) => {
        const actions = [
          SimpleAction("STRUKTUR", PhArrowsLeftRight , "Verteilung auf die Struktur"),
        ];
        if(this.isIntern) {
          actions.unshift(LoadingAction("DELETE", PhTrash, "Löschen", disabled(row), (row) => this.deleteLoading && this.deleteId == row.id));
        }
        if(this.editable) {
          actions.push(
            LoadingAction("COPY", PhCopy , "Copy", () => row.allowNewBuchung),
            LoadingAction("STORNO", PhArrowBendDoubleUpRight , "Stornieren", () => row.allowNewBuchung)
          )
        }
        return actions;
      }

      return [...this.abrechnungen].map(item => {
        const record = {
          ...item,
          actions: getActions(item),
        };
        record.bewertungssumme = (item.bewertungssumme || 0).toFixed(PRECISION);
        record.courtagesumme = (item.courtagesumme || 0).toFixed(PRECISION);
        record.istOverhead = item.istOverhead === true ? 'Ja' : 'Nein';
        return record;
      });
    },
    canViewCourtageStep(){
      return this.hasRoles([
        [VIEW_ROLES.VIEW_BROKER],
        [VIEW_ROLES.VIEW_CUSTOMER_AS_BROKER],
        [VIEW_ROLES.VIEW_CUSTOMER_AS_INTERN],
      ]);
    },
    cannotEditAPBemerkung() {
      // Das Feld darf initial von allen Usern befüllt werden, gelöscht oder geändert aber nur von internen Usern oder Hauptstrukturleitenr (z.B. 72103 ind Deutschland).
      return !this.newAPBemerkung && (!this.isIntern && !this.isHauptstrukturleitenr);
    }
  },
  methods: {
    openNewBuchung(){
      this.$router.push(`/home/versicherungen/insurance-group/versicherung-buchungs/${this.$route.params.id}/neuesBuchung`);
    },
    openEditBuchung(item) {
      this.$router.push(`/home/versicherungen/insurance-group/versicherung-buchungs/${this.$route.params.id}/${item.buchungsnr}`);
    },
    openVerteilungAufDieStruktur(item){
      this.$store.commit(INSURANCE_BUCHUNG_TYPES.MUTATIONS.SELECTED_INSURANCE_BUCHUNG, item);
      this.$router.push(`/home/versicherungen/insurance-group/versicherung-verteilung-struktur/${this.$route.params.id}/${item.buchungsnr}`);
    },
    deleteAbrechnung(event){
      if(event){
        this.openModal('delete', event);
      }
    },
    deleteBuchungItem() {
      if(this.abrechnungItemToDelete?.buchungsnr) {
        this.deleteLoading = true;
        this.deleteId = this.abrechnungItemToDelete?.buchungsnr;
        this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.INSURANCE_BUCHUNG_DELETE, {
          buchungsnr: this.abrechnungItemToDelete?.buchungsnr,
        }).then(() => {
          this.deleteLoading = false;
          this.deleteId = null;
          this.abrechnungItemToDelete = null;
        });
      }
    },
    openModal(modalName, item) {
      
      if('delete' === modalName) {
        this.abrechnungItemToDelete = {
          ...item,
        };
        this.$refs.buchungDeleteModal.open();
      }
      if('copy' === modalName) {
        this.abrechnungItemToCopy = {
          ...item,
        };
        this.$refs.buchungCopyModal.open();
      }
      if('storno' === modalName) {
        this.abrechnungItemToCopy = {
          ...item,
        };
        this.$refs.buchungStornierenModal.open();
      }
    },
    copyVersicherungBuchung(event) {
      if(event){
        this.openModal('copy', event);
      }
    },
    stornierenVersicherungBuchung(event) {
      if(event){
        this.openModal('storno', event);
      }
    },
    copyBuchungItem() {
      if(this.abrechnungItemToCopy?.buchungsnr) {
        this.copyLoading = true;
        this.copyBuchungID = this.abrechnungItemToCopy?.buchungsnr;
        this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.INSURANCE_BUCHUNG_COPY, {
          vertragId: this.$route.params.id,
          buchungsnr: this.abrechnungItemToCopy?.buchungsnr,
        }).then(() => {
          this.copyLoading = false;
          this.copyBuchungID = null;
          this.abrechnungItemToCopy = null;
        });
      }
    },
    stornierenBuchungItem() {
      if(this.abrechnungItemToCopy?.buchungsnr) {
        this.copyLoading = true;
        this.copyBuchungID = this.abrechnungItemToCopy?.buchungsnr;
        this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.INSURANCE_BUCHUNG_STORNO, {
          vertragId: this.$route.params.id,
          buchungsnr: this.abrechnungItemToCopy?.buchungsnr,
        }).then(() => {
          this.copyLoading = false;
          this.copyBuchungID = null;
          this.abrechnungItemToCopy = null;
        });
      }
    },
  },
  mounted() {
    this.loading = true;
    this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.FIND_ALL_INSURANCE_ABRECHNUNGEN, {vertragId: this.$route.params.id})
    .then(response => {
      this.loading = false;
    })
  }


}
</script>
