var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.stepperMediator
    ? _c(
        "div",
        { staticClass: "container" },
        [
          _vm.isSmallOrMediumScreen
            ? _c(
                "div",
                { class: ["template-top", _vm.stepperClass, _vm.stepType] },
                [
                  _vm.$slots.headerTemplate
                    ? _c(
                        "div",
                        {
                          class: [
                            "template-top",
                            _vm.stepperClass,
                            _vm.stepType
                          ]
                        },
                        [_vm._t("headerTemplate")],
                        2
                      )
                    : _vm._e(),
                  _c("Stepper3", {
                    ref: "stepper3",
                    attrs: {
                      steps: _vm.stepperMediator.getCurrentStepList(),
                      stepType: "step",
                      disabledUpdate: _vm.stepType === "fixed",
                      visible: _vm.showStepper,
                      stepperName: _vm.stepperName,
                      selectedStepKey: _vm.currentStepKey,
                      selectedSubstepKey: _vm.currentSubstepKey,
                      stepperService: _vm.stepperMediator
                    },
                    on: {
                      setStep: _vm.setStepOnMobile,
                      setSubstep: _vm.setSubstepOnMobile
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.$slots.headerTemplate && !_vm.isSmallOrMediumScreen
            ? _c(
                "div",
                { class: ["template-top", _vm.stepperClass, _vm.stepType] },
                [_vm._t("headerTemplate")],
                2
              )
            : _vm._e(),
          !_vm.isSmallOrMediumScreen
            ? _c("ContentWithStepper", {
                attrs: { stepperCollapsed: _vm.stepperCollapsed },
                scopedSlots: _vm._u(
                  [
                    _vm.showVerticalStepper
                      ? {
                          key: "stepper",
                          fn: function() {
                            return [
                              _c(
                                "div",
                                { staticClass: "box__container" },
                                [
                                  _vm.isDynamicDocument
                                    ? _c("DynamicVerticalStepper", {
                                        ref: "stepper",
                                        staticClass: "responsive-stepper",
                                        attrs: {
                                          stepType: _vm.stepType,
                                          stepperName: _vm.stepperName,
                                          selectedStepKey: _vm.currentStepKey,
                                          selectedSubstepKey:
                                            _vm.currentSubstepKey,
                                          stepperService: _vm.stepperMediator,
                                          showCollapse: true,
                                          showSaveSpinner: _vm.showSaveSpinner
                                        },
                                        on: {
                                          setStep: _vm.setStep,
                                          setSubstep: _vm.setSubstep,
                                          collapsed: function($event) {
                                            _vm.stepperCollapsed = $event
                                          },
                                          addStep: function($event) {
                                            return _vm.$emit("addStep")
                                          },
                                          removeStep: function($event) {
                                            return _vm.$emit(
                                              "removeStep",
                                              $event
                                            )
                                          },
                                          copyStep: function($event) {
                                            return _vm.$emit("copyStep", $event)
                                          },
                                          orderChanged: function($event) {
                                            return _vm.$emit(
                                              "orderChanged",
                                              $event
                                            )
                                          }
                                        }
                                      })
                                    : _c("VerticalStepper", {
                                        ref: "stepper",
                                        staticClass: "responsive-stepper",
                                        attrs: {
                                          stepType: _vm.stepType,
                                          stepperName: _vm.stepperName,
                                          selectedStepKey: _vm.currentStepKey,
                                          selectedSubstepKey:
                                            _vm.currentSubstepKey,
                                          stepperService: _vm.stepperMediator,
                                          showCollapse: true,
                                          showSaveSpinner: _vm.showSaveSpinner
                                        },
                                        on: {
                                          setStep: _vm.setStep,
                                          setSubstep: _vm.setSubstep,
                                          collapsed: function($event) {
                                            _vm.stepperCollapsed = $event
                                          }
                                        }
                                      })
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        }
                      : null,
                    {
                      key: "content",
                      fn: function() {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "row my-0 template-middle",
                              class: [_vm.stepperClass, _vm.stepType]
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "col-12 template-content",
                                  class: [
                                    _vm.stepperClass,
                                    _vm.getTemplateContentClass()
                                  ]
                                },
                                [
                                  _vm._t("contentTemplate", null, {
                                    data: _vm.currentStep,
                                    currentSubstep: _vm.currentSubstep
                                  }),
                                  _vm.$slots.footerTemplate
                                    ? _c(
                                        "div",
                                        { staticClass: "template-bottom" },
                                        [_vm._t("footerTemplate")],
                                        2
                                      )
                                    : _vm._e()
                                ],
                                2
                              ),
                              _vm.hasSidetextTemplate
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "col-md-3 col-12",
                                      class: [_vm.getSideTextClass()]
                                    },
                                    [_vm._t("sidetextTemplate")],
                                    2
                                  )
                                : _vm._e()
                            ]
                          ),
                          !_vm.hideNavigationButtons
                            ? _c(
                                "div",
                                { staticClass: "template-navigation-buttons" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "box__container" },
                                    [
                                      !!_vm.$slots.customBottomButtons
                                        ? _c(
                                            "div",
                                            { staticClass: "m-0 mr-3" },
                                            [_vm._t("customBottomButtons")],
                                            2
                                          )
                                        : _vm._e(),
                                      _vm._t("buttonsTemplate", function() {
                                        return [
                                          _vm.zuruckEnabled ||
                                          _vm.zuruckEnabledSubsteps
                                            ? _c(
                                                "BaseButton",
                                                {
                                                  staticClass: "m-0 mr-3",
                                                  attrs: {
                                                    disabled:
                                                      _vm.showSaveSpinner,
                                                    isSecondary: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.backStep()
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(" Zurück "),
                                                  _vm.showSaveSpinner &&
                                                  _vm.lastBottomButtonAction ===
                                                    _vm.ACTION_PREV
                                                    ? _c("AnimatedSpinner")
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.weiterEnabled ||
                                          _vm.weiterEnabledSubsteps
                                            ? _c(
                                                "BaseButton",
                                                {
                                                  staticClass: "m-0 mr-3",
                                                  attrs: {
                                                    disabled:
                                                      _vm.showSaveSpinner,
                                                    "is-primary": ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.nextStep()
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(" Weiter "),
                                                  _vm.showSaveSpinner &&
                                                  _vm.lastBottomButtonAction ===
                                                    _vm.ACTION_NEXT
                                                    ? _c("AnimatedSpinner")
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.hasFertig && _vm.isLastStep
                                            ? _c(
                                                "BaseButton",
                                                {
                                                  staticClass: "m-0",
                                                  attrs: {
                                                    disabled:
                                                      _vm.showSaveSpinner,
                                                    "is-primary": ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.fertig()
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(" Schließen "),
                                                  _vm.showSaveSpinner &&
                                                  _vm.lastBottomButtonAction ===
                                                    _vm.ACTION_FINISH
                                                    ? _c("AnimatedSpinner")
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ]
                                      })
                                    ],
                                    2
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  true
                )
              })
            : _vm._e(),
          _c(
            "BaseModal",
            {
              ref: "editModal",
              attrs: {
                modalTitle: _vm.editModalTitle,
                size: "lg",
                showCancelButton: false,
                actions: _vm.baseModalActions,
                showConfirmButton: false,
                autoCloseOnRouteNavigation: false,
                backNavigationOnClose: _vm.backNavigationOnClose
              },
              on: {
                onCloseButton: function($event) {
                  return _vm.onCloseEditModal()
                },
                "action-WEITER": function($event) {
                  return _vm.nextStep()
                },
                "action-ZURUCK": function($event) {
                  return _vm.backStep()
                },
                action: _vm.emitAction
              }
            },
            [
              !_vm.loadingStep
                ? _c("div", { staticClass: "modal__container" }, [
                    _c(
                      "div",
                      {
                        staticClass: "row my-0 template-middle",
                        class: [_vm.stepperClass, _vm.stepType]
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "col-12 template-content",
                            class: [
                              _vm.stepperClass,
                              _vm.getTemplateContentClass()
                            ]
                          },
                          [
                            _vm._t("contentTemplate", null, {
                              data: _vm.currentStep,
                              currentSubstep: _vm.currentSubstep
                            }),
                            _vm.$slots.footerTemplate
                              ? _c(
                                  "div",
                                  { staticClass: "template-bottom" },
                                  [_vm._t("footerTemplate")],
                                  2
                                )
                              : _vm._e()
                          ],
                          2
                        ),
                        _vm.hasSidetextTemplate
                          ? _c(
                              "div",
                              {
                                staticClass: "col-md-3 col-12",
                                class: [_vm.getSideTextClass()]
                              },
                              [_vm._t("sidetextTemplate")],
                              2
                            )
                          : _vm._e()
                      ]
                    )
                  ])
                : _vm._e()
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }