var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "ToDo-Liste",
          subtitle:
            _vm.page === "offene-unterschriften"
              ? "Offene Unterschriften"
              : "Online Legitimation",
          defaultMenu: _vm.optionsMenu
        }
      }),
      _c("div", { attrs: { tid: "b61ff807-c971-492e-a260-257bfaa8f36c" } }, [
        _vm.page === "offene-unterschriften"
          ? _c(
              "div",
              [
                _vm.readOnly
                  ? _c("div", { staticClass: "color-danger box__container" }, [
                      _vm._v(
                        " Die aktuellen Einstellungen erlauben nur einen lesenden Zugriff. Deshalb ist es nicht möglich das Dokument digital zu signieren! "
                      )
                    ])
                  : _c("OffeneUnterschriften")
              ],
              1
            )
          : _vm._e(),
        _vm.page === "video-ident-vorhanden"
          ? _c(
              "div",
              { staticClass: "box__container" },
              [
                _c("h5", [
                  _vm._v("Bitte schliessen Sie folgende Legitimationen ab:")
                ]),
                [
                  _vm.rows.length
                    ? _c("Table", {
                        attrs: {
                          headers: _vm.headers,
                          rows: _vm.rows,
                          rowId: "vorgangsnummer",
                          rowsPerPage: 20,
                          hidePagination: _vm.rows.length < 21
                        },
                        on: {
                          "click-bezeichnung": function($event) {
                            return _vm.navigateTo($event.navigateTo)
                          }
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "depotnr",
                              fn: function(row) {
                                return [
                                  _c("DownloadLink", {
                                    attrs: {
                                      title: row.depotnr,
                                      href: _vm.showPDF(row)
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2122619079
                        )
                      })
                    : _c("div", [_vm._v("Keine Daten")])
                ]
              ],
              2
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }