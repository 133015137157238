import CORE_TYPES from './types';
import CMS_TYPES from '../cms/types';
import COMMUNICATION_TYPES from '../communication/types';
import CUSTOMER_TYPES from '../customer/types';
import CUSTOMER_SEARCH_TYPES from '../customerSearch/types';
import DOCUMENTS_TYPES from '../documents/types';
import INTERN_TYPES from '../intern/types';
import CALENDAR_TYPES from '../calendar/types';
import CALENDAR_SETTINGS_TYPES from '../calendarSettings/types';
import CUSTOMER_FILTERS_TYPES from '../customerFilters/types';
import DEPOTPOSITIONS_TYPES from '../depotpositions/types';
import DOKUMENTENARCHIV_TYPES from '../dokumentenarchiv/types';
import SCHADENSMELDUNG_TYPES from '../schadensmeldung/types';
import MULTIBANKING_TYPES from '../multiBanking/types';
import VERSICHERUNG_TYPES from '../versicherungen/types';
import DASHBOARD_TYPES from '../../components/dashboard/store/types';
import STECKBRIEF_TYPES from '@/components/steckbrief/store/types';
import ANTRAG_TYPES from '../antrag/types';
import DOCUMENT_TYPES from '../documents/types';
import DYNAMIC_DOCUMENT_TYPES from '../dynamicDocument/types';
import ANLEGERPROFIL_TYPES from '../anlegerprofil/types';
import ANLAGEZIELE_TYPES from '../anlegerprofil/types';
import ANSPRECHPARTNER_TYPES from '../ansprechpartner/types';
import GESELLSCHAFT_TYPES from '../gesellschaft/types';
import ANLAGEEMPFEHLUNG_TYPES from '../investmentAdvice/types';
import FONDSFINDER_TYPES from '../fondsfinder/types';
import WEBRTC_TYPES from '../webrtc/types';
import INSURANCE_TYPES from '../insurance/types'
import CUSTOMERDATA_TYPES from '../customerData/types'
import MAILCOMPOSER_TYPES from '../mailcomposer/types'
import MSC_NEWS_TYPES from '@/store/mscnews/types'
import FONDSINFO_TYPES from '../fondsinfo/types'
import STORNO_WARNUNG_TYPES from '../stornoWarnung/types'
import SEARCH_CONFIGS_TYPES from '../searchConfigs/types'
import HTTP_REQ_TYPES from '../http-requests/types';
import LOG_TYPES from '../log/types';
import SSO_TYPES from '@/store/sso/types';
import BALANCE_SHEET_TYPES from '@/store/balance-sheet/types';
import BIPRO_TYPES from '@/store/bipro/types';
import BRIDGE_TYPES from '@/store/bridge/types';
import TAGS_TYPES from '@/store/tags/types'
import EMAIL_NOT_RECOGNIZED_TYPES from '@/store/emailNotRecognized/types'
import FC_CONFIG_TYPES from '@/store/fcConfig/types'
import DOMAIN_FC_TYPES from '@/store/domainFC/types'
import CONFIG_DEFINITION_TYPES from '@/store/configDefinition/types'
import MERGE_PEOPLE_TYPES from '@/store/merge-people/types'
import VERTRAG_WP_TYPES from '@/store/vertragWP/types'
import VV_TYPES from '@/store/vermoegensverwaltung/types'
import SESSION_TIME_TYPES from '@/store/session-time/types'
import MENU_TYPES from '@/store/menu/types'
import NAVIGATION_LOG_TYPES from '@/store/navigation-log/types'
import MENU_CONFIG_TYPES from '@/store/menuConfig/types'
import LOADING_TYPES from '@/store/loading/types'
import WINDOW_CONTROL_TYPES from '@/store/windowControl/types'
import OPEN_SIGNS_TYPES from '@/store/openSigns/types'
import TEMPLATES_EDIT_TYPES from '@/store/templatesEdit/types'
import VUBLOCK_TYPES from '@/store/vuBlock/types'
import MSC_MARKETING_TYPES from '@/store/mscmarketing/types'
import BROKER_PERMISSIONS_TYPES from '@/store/brokerPermissions/types'
import EXTERNE_ZUGANG_TYPES from '@/store/externeZugang/types'

import axios from 'axios';
import router from '../../router';
import { loadColorScheme, setColorScheme } from '../../configs/color-config';
import { ROLES, generateUserRoles, getTokenData, getViewRoles, VIEW_ROLES } from '@/router/roles';
import { scheduleLogoutTimeout } from '../../helpers/timeout-helper';
import { buildMessage } from '../../helpers/log-message-helper';
import { 
  setObject,
  setSessionObject, 
  removeLoginDataSessionStorage, 
  saveLoginDataSessionStorage,
} from '@/helpers/local-storage-helper'
import { generateOrUpdateDefaultHtml5MetaTag, generateOrUpdateOpenGraphMetaTag } from '../../configs/meta-tag-config';
import { isLoginRoute } from '@/router/features-routes/login';
import { v4 as uuidv4 } from 'uuid';
import { getRedirectionWindow, openFile } from '@/components/table/export/utils';
import { makeQueryParam, parseParamsIntoObject, encodeURIComponentIfNeeded } from '@/helpers/utils-helper';
import { MESSAGE_CANCEL_REPEATED_REQUESTS, MESSAGE_CANCEL_REQUEST_DUE_TO_LOGOUT } from '../http-requests/actions';
import TAPI from '../tapi/types';
import { looksLikeKundennr } from '@/views/customer/customer-utils';
import dayjs from 'dayjs';
import { isPathAllowed, } from '@/router/guards';
import { lockAddBreadcrumb, unlockAddBreadcrumb, resetBreadcrumb, isSamePath } from '@/router/breadcrumb';
import BrowserSupport from '@/browser-support';
import { isOptionMenuPermissionVisibleAsync, } from '@/router/guards';
import { ResetStateSource } from '@/store/core';
import {getCookie, setCookie} from '@/tools/CookieHandler';
import { isObject } from '@/helpers/commonfunctions';
import BROKER_SEARCH_TYPES from '../brokerSearch/types';

const config = {
  defaultSpinner: true
};

const loginConfig = {
  ...config,
  disableDefaultErrorMessage: true,
  disableDefaultLog: true,
}

const requestTimeLeftTimeoutConfig = {
  timeout: 60000,
  disableDefaultErrorMessage: true,
  transitional: {
    clarifyTimeoutError: true
  }
}

/**
 * Login request checks whether the 2FA is required.
 * 
 * When yes, this request should be spit into three steps:
 * 1 - (loginRequestFirstStep) Get mfaChallenge or get the regular login token if no 2FA is required.
 * 2 - (loginRequestSecondStep) Call a waiting endpoint until the 2FA is confirmed in the APP
 * 3 - (loginRequestThirdStep) Request the actual login data to get token and anything else
 * 
 * When no, request a single step
 * 1 - Request the actual login data to get token and anything else
 * 
 * Aside from that, when the request has sitzungID, it should load the session right away.
 * 
 * @param {*} getters 
 * @param {*} payload 
 */
async function loginRequest(getters, payload) {
  const sitzungID = payload.sitzungID || payload.SitzungID;

  payload.clientIdToken = getCookie("clientIdToken");

  if (sitzungID) {
    return await loginRequestUsingSitzungID(getters, sitzungID);
  }

  if (!payload.mfaChallengeId && !payload.confirm2FA) {
    return await loginRequestFirstStep(getters, payload);
  }

  if (payload.confirm2FA && payload.mfaType === 'APP') {
    await loginRequestSecondStep(getters, payload);
  }

  return await loginRequestThirdStep(getters, payload);
}

async function loginRequestFirstStep(getters, payload) {
  const mobileNativeSpec = getters[BRIDGE_TYPES.GETTERS.MOBILE_NATIVE_SPEC];

  const credentialsPayload = {
    mobileNativeContext: getters[BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT],
    userid: payload.userid,
    password: payload.password,
    appLoginToken: payload.appLoginToken,
    factor2: payload.factor2,
    mfaChallengeId: payload.mfaChallengeId,
    mfaSessionSecurityToken: payload.mfaSessionSecurityToken,
    mfaCode: payload.mfaCode,
    ...(mobileNativeSpec ? mobileNativeSpec : {})
  }

  const firstStepResponse = await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/login/credentials`, credentialsPayload, loginConfig);
  return firstStepResponse;
}

async function loginRequestSecondStep(getters, payload, networkErrorAttempt = 0) {
  const { confirm2FA, mfaSessionSecurityToken, mfaChallengeId } = payload;

  if (confirm2FA && mfaSessionSecurityToken && mfaChallengeId) {
    const waitPayload = {
      mfaSessionSecurityToken,
      mfaChallengeId,
    }

    try {
      await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/login2fawait/waitFor2ndFactor`, waitPayload, loginConfig);
    } catch (error) {
      if (networkErrorAttempt < 2) {
        // In case of network error, try again up to two times
        await loginRequestSecondStep(payload, getters, networkErrorAttempt + 1);
      } else {
        throw error;
      }
    }
  }
}

async function loginRequestThirdStep(getters, payload) {
  const { mfaSessionSecurityToken, mfaChallengeId } = payload;

  const mobileNativeSpec = getters[BRIDGE_TYPES.GETTERS.MOBILE_NATIVE_SPEC];

  const thirdStepPayload = {
    mfaSessionSecurityToken,
    mfaChallengeId,
    userid: payload.userid,
    mfaCode: payload.mfaCode,
    appLoginToken: payload.appLoginToken,
    factor2: payload.factor2,
    mobileNativeContext: getters[BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT],
    ...(mobileNativeSpec ? mobileNativeSpec : {})
  }

  const response =  await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/login/credentials`, thirdStepPayload, loginConfig);
  return response;
}

async function loginRequestUsingSitzungID(getters, sitzungID) {
  return await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/login/session`, { sitzungID }, loginConfig);
}

function extractTimeLeftOutOfError(responseOrError, state) {
  /**
   * means the get_time_left_until_timeout could not be finished
   * It's gonna scheduled to test again using the previously known timeout
   */
  if (responseOrError?.code === 'ETIMEDOUT' || responseOrError?.message === MESSAGE_CANCEL_REPEATED_REQUESTS) {
    const finalTime = dayjs(state.baseGlobalState.supposedTimeoutTime);
    return finalTime.diff(dayjs(), 'seconds');
  } else {
    return -1;
  }
}

function navigateToReferrer(referrer, getters) {
  // do not navigate on app context
  if (getters[BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT]) {
    return;
  }

  const urlReferrer = new URL(referrer);
  const urlCurrent = new URL(window.location.href);

  // do not navigate if we are in the same host
  if (urlReferrer.host !== urlCurrent.host) {
    window.location.href = referrer;
  }
}

export default {
  async [CORE_TYPES.ACTIONS.LOGIN]({ commit, dispatch, getters }, payload) {
    const referrer = document.referrer;

    try {
      commit(CORE_TYPES.MUTATIONS.SET_LOGGING_IN, true);

      const response = await loginRequest(getters, payload);

      setCookie("clientIdToken", response.data.clientIdToken, 365);

      let responseData = null;

      if (response?.data?.validUntil != null) {
          commit(SSO_TYPES.MUTATIONS.LOGIN, {
              sessionId: response.headers.sso,
              loginName: payload.userid,
              referrer,
              ...response.data,
          });
          if (response.data.loginResponse) {
              responseData = { ...response.data.loginResponse, currentDate: new Date().getTime()};
          } else {
              router.push({name: 'sso-login'});
          }
      }
      else if (response && response.data.token) {
          responseData = { ...response.data, currentDate: new Date().getTime()}
      } else {
          // login is incomplete or failed, let the caller handle it
          return response.data;
      }
      
      const loginName = payload.userid;
      
      if (response.data?.token && payload.userid && response.data?.appLoginToken) {
        dispatch(BRIDGE_TYPES.ACTIONS.SAVE_HASHED_PASSWORD, { 
          loginName, 
          password: response.data?.appLoginToken
        });
      }
      if (responseData) {
        saveLoginDataSessionStorage(getters, payload, responseData)

        if (!payload.isKundenzugang && !payload.isMaklerzugang) {
          dispatch(BRIDGE_TYPES.ACTIONS.UPDATE_SETTINGS, {
            allowPushNotification: !!response?.data?.rights?.isAppAktiv,
            layoutParamsFC: response?.data?.layoutParamsFC
          });
        }

        dispatch(CORE_TYPES.ACTIONS.MANAGE_LOGIN_RESPONSE, { 
          data: {
            ...responseData,
            rights: {
              ...responseData?.rights,
              isKundenzugang: payload.isKundenzugang,
              isMaklerzugang: payload.isMaklerzugang,
            }
          }, 
          nextUrl: payload.nextUrl, 
          isIntern: payload.isIntern, 
          tabsBroadcastId: payload.tabsBroadcastId,
          referrer,
          maklerzugangTabsBroadcastId: payload.maklerzugangTabsBroadcastId,
        })
      } else {
        removeLoginDataSessionStorage();
      }
      return null;
    } catch (error) {
      removeLoginDataSessionStorage();

      let message = error?.response?.data?.message || ''
      let timeout = message.includes('Zugang ist gesperrt') ? 0 : 5000;

      if (error?.response?.status === 401) {
        dispatch(BRIDGE_TYPES.ACTIONS.DELETE_HASHED_PASSWORD, error)
        commit(BRIDGE_TYPES.MUTATIONS.RESET_LOADED_LOGIN_DATA)
        message = message || 'Zugangsnummer und/oder Passwort sind nicht korrekt.';

      } else {
        message = message || 'Es ist ein Fehler aufgetreten.';
      }

      dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(message, 'danger', true, timeout));
      commit(CORE_TYPES.MUTATIONS.LOGIN_FAIL);
      throw error;
    } finally {
      commit(CORE_TYPES.MUTATIONS.SET_LOGGING_IN, false);
    }
  },

  async [CORE_TYPES.ACTIONS.TOKEN_LOGIN]({ dispatch, commit, getters }, payload) {
    const referrer = document.referrer;
    try {
      commit(CORE_TYPES.MUTATIONS.SET_LOADING_TOKEN, true);
      const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrslogin`, {
      ...config,
      headers: {'token': payload.token}
      });

      if (!response?.data?.token) {
        removeLoginDataSessionStorage();
        return;
      }

        const responseData = { ...response.data, currentDate: new Date().getTime(), token: payload.token}
        if (getters[CORE_TYPES.GETTERS.STORE_SESSION_INFORMATION] && !payload.isMaklerzugang && !payload.isSuperCustomer) {
          setObject('loginData', responseData)
        }

      await dispatch(CORE_TYPES.ACTIONS.MANAGE_LOGIN_RESPONSE, { 
        data: {
          ...responseData,
          rights: {
            ...responseData?.rights,
            isMaklerzugang: payload.isMaklerzugang,
          },
        },
        nextUrl: 'waiting', 
        isIntern: payload.isIntern, 
        tabsBroadcastId: payload.tabsBroadcastId,
        referrer,
        maklerzugangTabsBroadcastId: payload.maklerzugangTabsBroadcastId,
        isWaitingForNextToken: true,
      })
      
      if (payload.kundennrSteckbrief) {
        await dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_STECKBRIEF, {
          customerId: payload.kundennrSteckbrief,
          nextUrl: payload.nextUrl,
          tabsBroadcastId: payload.tabsBroadcastId,
          isMaklerzugang: payload.isMaklerzugang,
          maklerzugangTabsBroadcastId: payload.maklerzugangTabsBroadcastId,
        })
      }

      if (payload.kundennrVersicherungen) {
        await dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_INSURANCES, {
          customerId: payload.kundennrVersicherungen,
          nextUrl: payload.nextUrl,
          tabsBroadcastId: payload.tabsBroadcastId,
          isMaklerzugang: payload.isMaklerzugang,
          maklerzugangTabsBroadcastId: payload.maklerzugangTabsBroadcastId,
        })
      }    
      
      if (payload.kundennrBeratungsmappe) {
        dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_BERATUNGSMAPPE, {
          customerId: payload.kundennrBeratungsmappe,
          nextUrl: payload.nextUrl,
          tabsBroadcastId: payload.tabsBroadcastId,
          isMaklerzugang: payload.isMaklerzugang,
          maklerzugangTabsBroadcastId: payload.maklerzugangTabsBroadcastId,
        })
      }         
      
      
      if (payload.userId) {
        await dispatch(CORE_TYPES.ACTIONS.OPEN_INTERN, {
          userId: payload.userId,
          nextUrl: payload.nextUrl,
          tabsBroadcastId: payload.tabsBroadcastId,
          isIntern: payload.isIntern,
          maklerzugangTabsBroadcastId: payload.maklerzugangTabsBroadcastId,
        })
      }

      if (payload.maklernr) {
        await dispatch(CORE_TYPES.ACTIONS.OPEN_BROKER, {
          maklernr: payload.maklernr,
          nextUrl: payload.nextUrl,
          tabsBroadcastId: payload.tabsBroadcastId,
          isMaklerzugang: payload.isMaklerzugang,
          maklerzugangTabsBroadcastId: payload.maklerzugangTabsBroadcastId,
          fillUnterNr: payload.fillUnterNr,
        })
      }

      if(payload?.isOpenCurrentLoggedUser) {
        router.push('/home');
      }
    } catch (error) {
      console.error(error)
      removeLoginDataSessionStorage();
      const message = error && error.response && error.response.data && error.response.data.message || 'Zugangsnummer und/oder Passwort sind nicht korrekt.';
      dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(message, 'danger'));
      commit(CORE_TYPES.MUTATIONS.LOGIN_FAIL);
    } finally {
      commit(CORE_TYPES.MUTATIONS.SET_LOADING_TOKEN, false);
    }
  },

  async [CORE_TYPES.ACTIONS.MANAGE_LOGIN_RESPONSE]({ commit, getters, dispatch, state }, payload) {
      // set managing login response as true
      commit(CORE_TYPES.MUTATIONS.SET_MANAGING_LOGIN_RESPONSE, true);
      state.baseGlobalState?.timeoutSchedule?.stop();
      scheduleLogoutTimeout(payload.data.timeoutSeconds, dispatch, commit);
      const tabsBroadcastId = payload.tabsBroadcastId || uuidv4()
      const tabsBroadcast = new BroadcastChannel(tabsBroadcastId); 

      tabsBroadcast.onmessage = (event) => {
        if (event?.data === 'logout') {
          dispatch(CORE_TYPES.ACTIONS.LOGOUT) 
          tabsBroadcast.close()
        }
      }

      const { maklerzugangTabsBroadcastId } = payload;
      let maklerzugangTabsBroadcast = null;
      if (maklerzugangTabsBroadcastId) {
        maklerzugangTabsBroadcast = new BroadcastChannel(maklerzugangTabsBroadcastId); 

        maklerzugangTabsBroadcast.onmessage = (event) => {
          if (event?.data === 'logout') {
            dispatch(CORE_TYPES.ACTIONS.LOGOUT) 
            maklerzugangTabsBroadcast.close()
          }
        }
      }

      const successPayload = {
        ...payload.data,
        ...getTokenData(payload.data.token),
        ...generateUserRoles(payload.data),
        tabsBroadcast,
        tabsBroadcastId,
        referrer: payload.referrer,
        nextUrl: payload.nextUrl,
        maklerzugangTabsBroadcast,
        maklerzugangTabsBroadcastId,
      }

      for (const message of getters[LOG_TYPES.GETTERS.MESSAGES]) {
        dispatch(LOG_TYPES.ACTIONS.REMOVE_MESSAGE, message);
      }

      commit(CORE_TYPES.MUTATIONS.LOGIN_SUCCESS, successPayload);

      if (payload.data.token) {
        dispatch(BRIDGE_TYPES.ACTIONS.UPDATE_TOKEN, payload.data.token)
      }

      if (successPayload && successPayload.layoutParamsFC) {
        dispatch(CORE_TYPES.ACTIONS.LOAD_COLOR_SCHEMA, successPayload.layoutParamsFC);
        commit(CORE_TYPES.MUTATIONS.SET_CONFIGURED_COLOR_SCHEME, successPayload.colorScheme);
      } else {
        const isIntern = getters[CORE_TYPES.GETTERS.IS_INTERN];
        const isBypass = getters[CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS];
        const defaultBrokerId = getters[CORE_TYPES.GETTERS.GET_DEFAULT_BROKER_ID]

        const brokerLayoutPayload = {};
        if (isBypass){
          brokerLayoutPayload.broker = payload.layoutFCMaklernr;
        } else if (isIntern){
          brokerLayoutPayload.broker = defaultBrokerId;
        }

        dispatch(CORE_TYPES.ACTIONS.GET_BROKER_LAYOUT, brokerLayoutPayload)
      }

      if (getters[CORE_TYPES.GETTERS.HAS_ROLES]([VIEW_ROLES.VIEW_GESELLSCHAFT]) ) {
        await dispatch(GESELLSCHAFT_TYPES.ACTIONS.GET_GESELLSCHAFT_ID_LOGGED_USER);
      }

      if (!payload.isWaitingForNextToken) {
        dispatch(MENU_TYPES.ACTIONS.CONFIGURE_MENU);
      }

      const afterNavigate = () => {
        // unlock breadcrumb
        unlockAddBreadcrumb();

        if (!payload.isWaitingForNextToken) {
          // set managing login response as false
          commit(CORE_TYPES.MUTATIONS.SET_MANAGING_LOGIN_RESPONSE, false)
        }
      };

      const navigateToNextUrl = async function(payload, router) {
        const hasRoles = getters[CORE_TYPES.GETTERS.HAS_ROLES]
        const homePath = '/home';
        let path = homePath;

        if (payload.nextUrl && isPathAllowed(payload.nextUrl)) {
          path = payload.nextUrl;
        } else if (hasRoles([ROLES.INSURANCE_COMPANY])) {
          path = '/home/versicherungen';
        } else if (hasRoles([VIEW_ROLES.VIEW_CUSTOMER_ONLY]) && BrowserSupport.supportsTouch) {
          const to = router.match('/communication/todo-list');
          let todoListRedirected = '';

          await isOptionMenuPermissionVisibleAsync(to, null, (redirect) => {
            todoListRedirected = redirect;
          });

          if (!todoListRedirected) {
            await Promise.all([
              dispatch(OPEN_SIGNS_TYPES.ACTIONS.GET_HAS_OPEN_SIGNS),
              dispatch(STECKBRIEF_TYPES.ACTIONS.GET_TODO_VIDEO_INDENT_COUNT),
            ]);
    
            const hasOpenSigns = getters[OPEN_SIGNS_TYPES.GETTERS.HAS_OPEN_SIGNS];
            const hasVideoIndent = getters[STECKBRIEF_TYPES.GETTERS.HAS_VIDEO_INDENT];
            const hasToDoListeItems = hasOpenSigns || hasVideoIndent;
            path = hasToDoListeItems ? '/communication/todo-list' : path;
          }
        }

        // reset and lock breadcrumb before navigation
        resetBreadcrumb();
        lockAddBreadcrumb();

        router.push('/noop') // prevents re-mount current route component before navigate to next path
          .catch(() => {})
          .finally(() => {
            router.push({ path, })
              .catch(() => {})
              .finally(afterNavigate);
          });
      }
    
      dispatch(TAPI.ACTIONS.CAN_TAPI);

      // might need to reset all pending actions - when bypassing another user this gets called multiple times and pending actions gets called unneccessary
      commit(CORE_TYPES.MUTATIONS.MERGE_PENDING_ACTIONS, { changePassword: false });
      if (!payload.isWaitingForNextToken) {
        axios.get(state.apiAddress + `/login/get_pending_actions`, {
          defaultSpinner: true,
          params: {
            isMaklerzugang: payload.data.rights.isMaklerzugang,
          }
        }).then(response => {
          if (response && response.data) {
            commit(CORE_TYPES.MUTATIONS.MERGE_PENDING_ACTIONS, response.data);
          }
        }).finally(() => {
          navigateToNextUrl(successPayload, router);
        });
      } else {
        navigateToNextUrl(successPayload, router);
      }

      setTimeout(() => {
        dispatch(CORE_TYPES.ACTIONS.GET_USER_PICTURE);  

        if(!payload.isWaitingForNextToken) {
          dispatch(CUSTOMER_SEARCH_TYPES.ACTIONS.GET_LAST_CUSTOMERS_OPENED);
          dispatch(BROKER_SEARCH_TYPES.ACTIONS.GET_LAST_BROKERS_OPENED);
        }
      });

      if (!getters[CORE_TYPES.GETTERS.IS_INTERN] && (!getters[CORE_TYPES.GETTERS.IS_BYPASS] || getters[CORE_TYPES.GETTERS.ORIGINAL_USER_IS_BROKER])) {
        if (!payload.isWaitingForNextToken) {
          dispatch(WEBRTC_TYPES.ACTIONS.START_PUSH_SOCKET);
        }

        dispatch(WEBRTC_TYPES.ACTIONS.UPDATE_AVAILABILITY, {calleeChatBeteiligterId: successPayload.calleeChatBeteiligterId, available: successPayload.calleeChatBeteiligterAvailable});
        if (getters[CORE_TYPES.GETTERS.IS_CUSTOMER] && getters[CORE_TYPES.GETTERS.ORIGINAL_USER_IS_BROKER])
          commit(WEBRTC_TYPES.MUTATIONS.SET_CALLEE_CHAT_BETEILIGTER_ID, successPayload.calleeChatBeteiligterId);
      }
      commit(CORE_TYPES.MUTATIONS.CHAT_EMPFAENGER_KEY, successPayload.chatEmpfaengerKey);

      if(getters[BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT] || payload.loginSource === 'SSO') {
        commit(FC_CONFIG_TYPES.MUTATIONS.RESET_STATE); // reset all fc config loaded - this is to avoid context overflow
        dispatch(MENU_TYPES.ACTIONS.CONFIGURE_MENU, { reconfiguration: true, }); // reconfigure menu when is opening a new login session // TODO menu: check this code on the new menu structure
      }

      return Promise.resolve();
  },

  async [CORE_TYPES.ACTIONS.GET_BROKER_LAYOUT]({ state, commit, dispatch, getters }, { broker = null, host = null, isSSOContext = false }) {
    const mobileNativeContext = getters[BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT]

    let layoutUrl = `${state.apiAddress}/cms/cmsGetBrokerLayout?${makeQueryParam({ broker, mobileNativeContext, isSSOContext})}`;
    const response = await axios.get(layoutUrl, { noCheckToken: true });

    if (response.data && response.data.layoutParamsFC) {
      commit(CORE_TYPES.MUTATIONS.GET_BROKER_LAYOUT_SUCCESS, response.data.layoutParamsFC);
      commit(CORE_TYPES.MUTATIONS.SET_CONFIGURED_COLOR_SCHEME, response.data.colorScheme);
      dispatch(CORE_TYPES.ACTIONS.LOAD_COLOR_SCHEMA, response.data.layoutParamsFC);
    }
  },

  async [CORE_TYPES.ACTIONS.GET_MFA_CHALLENGE_TYPE]({ commit, getters }, payload) {
    const mfaChallengeUrl = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/login/mfa_challenge_type?${makeQueryParam(payload)}`;
    const response = await axios.get(mfaChallengeUrl, config);

    if (response.data) {
      commit(CORE_TYPES.MUTATIONS.GET_MFA_CHALLENGE_TYPE_SUCCESS, response.data);
    }
  },

  async [CORE_TYPES.ACTIONS.SAVE_MFA_CHALLENGE_TYPE]({ commit, getters }, payload) {
    const queryParam = { 
      unternr: payload.unternr
    }
    const mfaChallengeUrl = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/login/mfa_challenge_type?${makeQueryParam(queryParam)}`;
    const response = await axios.put(mfaChallengeUrl, payload.mfaChallenge, config);

    if (response.data) {
      commit(CORE_TYPES.MUTATIONS.GET_MFA_CHALLENGE_TYPE_SUCCESS, response.data);
    }
  },

  [CORE_TYPES.ACTIONS.CAN_DOWNLOAD_DATA]({state}, payload) {
    const unique = new Date().getTime();
    const url = `${state.apiAddress}/tempFile/can_load?count=${payload.rowsCount}&name=${payload.filename}&t=${unique}`;
    return axios.get(url)
  },

  [CORE_TYPES.ACTIONS.SAVE_PIN_DATA]({state}, payload) {
    const url = `${state.apiAddress}/tempFile/save_blob?name=${payload.filename}`;
    return axios.post(url, payload )
  },
  
  [CORE_TYPES.ACTIONS.CREATE_XLS_OR_PDF]({state}, payload) {
    const url = `${state.apiAddress}/exports/create_xls_pdf?name=${encodeURIComponent(payload.filename)}`;
    return axios.post(url, payload )
  },
  
  async [CORE_TYPES.ACTIONS.GET_PIN_DATA]({state, dispatch}, payload) {
    if (payload.id) {
      const response = await axios.get(`${state.apiAddress}/tempFile/get_pin`, {
          defaultSpinner: true,
          responseType: 'arraybuffer',
          params: {
            tempFileId: payload.id,
            pin: payload.pin,
          }
        });
        if (response.headers.error ) {
          dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.headers.error, 'danger'));
          return Promise.resolve(response.headers.error);
        } else if (response.headers.filename) {
          let fileName = response.headers.filename;
          openFile(response.data, payload.contenttype, fileName );
        }
    }
    return Promise.resolve('')
  },

  [CORE_TYPES.ACTIONS.GET_BROKER_INFORMATION]({ commit, state, dispatch, getters }, payload) {
    const isIntern = getters[CORE_TYPES.GETTERS.IS_INTERN];
    const isBypass = getters[CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS];
    const getUserID = getters[CORE_TYPES.GETTERS.GET_USER_ID];
    const defaultBrokerId = getters[CORE_TYPES.GETTERS.GET_DEFAULT_BROKER_ID]

    let queryParams = '';

    if (isIntern){
      queryParams = `?broker=${defaultBrokerId}`;
    }
    else if (isBypass){
      queryParams = `?broker=${getUserID}`;
    }
    else {
      if (payload?.regCode) {
        queryParams = `?broker=${payload.regCode}`;
      } else if (payload?.broker) {
        queryParams = `?broker=${payload.broker}`;
      } else if (payload?.host) {
        queryParams = `?host=${payload.host}`;
      }
    }

    let brokerInfoUrl = `${state.apiAddress}/cms/cmsGetBrokerInfo${queryParams}`;

    let brokerInfo = getters[CORE_TYPES.GETTERS.GET_BROKER_INFORMATION];
    const getConfig = { 
      disableDefaultLog: true, 
      disableDefaultErrorMessage: true,
      noCheckToken: true,
    }

    brokerInfo = brokerInfo.maklernr === payload.broker ? brokerInfo : null;
    if (!isIntern && (!brokerInfo || !brokerInfo.isLoaded)) {
      axios.get(brokerInfoUrl, getConfig).then(response => {
        if (response.statusText === '204' || !response?.data?.brokerInfo) {
          return Promise.reject()
        }
        const brokerInfoResult = response.data.brokerInfo;
        dispatch(CORE_TYPES.ACTIONS.GET_APP_LINKS, brokerInfoResult?.maklernr)

        if (brokerInfoResult.hasHomepage){
          commit(CORE_TYPES.MUTATIONS.SET_HAS_HOMEPAGE_ROLE);
        }

        commit(CORE_TYPES.MUTATIONS.GET_BROKER_INFORMATION_SUCCESS, response.data);
        if (brokerInfoResult) {
          generateOrUpdateOpenGraphMetaTag('og:locale', 'de_DE');
          generateOrUpdateOpenGraphMetaTag('og:type', 'website');
          generateOrUpdateOpenGraphMetaTag('og:title', brokerInfoResult.officialName);
          generateOrUpdateOpenGraphMetaTag('og:site_name', brokerInfoResult.officialName);
          generateOrUpdateOpenGraphMetaTag('og:image', brokerInfoResult.maklerLogo);
          generateOrUpdateOpenGraphMetaTag('og:description', brokerInfoResult.cleanMaklername)
          generateOrUpdateOpenGraphMetaTag('og:url', brokerInfoResult.maklerImageUrl);
        }
        if (brokerInfoResult && brokerInfoResult.htmlMetaTags) {
          for (const [key, value] of Object.entries(brokerInfoResult.htmlMetaTags)) {
            generateOrUpdateDefaultHtml5MetaTag(key, value);
          }
        }
      }).catch(() => {
        if (getters[CORE_TYPES.GETTERS.IS_LOGGED_IN]) {
          router.push('/').catch(err => err);
        } else if(!isLoginRoute(window.location?.pathname)) {
          router.push({path: '/login', query: payload.to?.query}).catch(err => err);
        }
      });
    }
  },

  [CORE_TYPES.ACTIONS.GET_USER_PICTURE]({ commit, state }, payload) {
    axios.get(`${state.apiAddress}/PictureUrl/getPictureUrl`).then(response => {
    // axios.get(state.apiAddress + '/../mrsuserpicture').then(response => {
      if (response.data) {
        // commit(CORE_TYPES.MUTATIONS.GET_USER_PICTURE_SUCCESS, response.data);
        commit(CORE_TYPES.MUTATIONS.GET_USER_PICTURE_SUCCESS, {url: response.data});
      }
    })
  },

  async [CORE_TYPES.ACTIONS.CHECK_BACKEND_TIMEOUT]({state, dispatch, getters, commit}) {
    if (!getters[CORE_TYPES.GETTERS.IS_LOGGED_IN]) {
      return;
    }

    let secondsUntilNextTimeout = 0

    try {
      state.baseGlobalState.timeoutSchedule?.stop();

      const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/login/get_time_left_until_timeout`, requestTimeLeftTimeoutConfig)
      secondsUntilNextTimeout = response?.data?.secondsUntilNextTimeout || 0
      if (response.message) {
        secondsUntilNextTimeout = extractTimeLeftOutOfError(response, state)
      }
    } catch (error) {
      secondsUntilNextTimeout = extractTimeLeftOutOfError(error, state)
    }

    if (secondsUntilNextTimeout > 0) {
      scheduleLogoutTimeout(secondsUntilNextTimeout, dispatch, commit);
    } else {
      await dispatch(CORE_TYPES.ACTIONS.LOGOUT, { message: buildMessage('Die Sitzung ist abgelaufen.', 'danger'), timeout: true, });
    }
  },

  async [CORE_TYPES.ACTIONS.LOGOUT]({ state, dispatch, getters, commit, }, { message=null, timeout=false, } = {}) {
    const isLoggedIn = getters[CORE_TYPES.GETTERS.IS_LOGGED_IN];
    if (!isLoggedIn || state.baseGlobalState.isLogoutRunning) {
      return;
    }
    commit(CORE_TYPES.MUTATIONS.SET_LOGOUT_RUNNING, true);
    const config = {
      timeout: 20000 // if the server is offline or slow, give up waiting and reset app state
    };
    state.baseGlobalState.timeoutSchedule?.stop();
    const loginData = state.loginData;
    const ssoReferrer = getters[SSO_TYPES.GETTERS.REFERRER];
    if (loginData.referrer || ssoReferrer) {
      const referrer = loginData.referrer || ssoReferrer;
      navigateToReferrer(referrer, getters);
    }
      //if (getters[CORE_TYPES.GETTERS.STORE_SESSION_INFORMATION])
    dispatch(TAPI.ACTIONS.CLOSE);
    const isKundenzugang = getters[CORE_TYPES.GETTERS.IS_KUNDENZUGANG];
    const isMaklerzugang = getters[CORE_TYPES.GETTERS.IS_MAKLERZUGANG];
    const isMobileNativeContext = getters[BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT];

    const tabsBroadcast = state.loginData.tabsBroadcast
    const maklerzugangTabsBroadcast = state.loginData.maklerzugangTabsBroadcast
    
    removeLoginDataSessionStorage()

    const brokerInfo = getters[CORE_TYPES.GETTERS.GET_BROKER_INFORMATION]
    const isBypass = getters[CORE_TYPES.GETTERS.IS_BYPASS]
    const defaultBrokerId = getters[CORE_TYPES.GETTERS.GET_DEFAULT_BROKER_ID]
    const mobileNativeContext = getters[BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT]

    const mobileNativeSpec = getters[BRIDGE_TYPES.GETTERS.MOBILE_NATIVE_SPEC];
    const query = {
      logout: true,
      ...(timeout === true ? { logoutTimeout: timeout, } : {}),
      ...brokerInfo?.maklernr && brokerInfo.maklernr !== defaultBrokerId && { maklernr: brokerInfo?.maklernr },
      ...(mobileNativeContext ? { mobileNativeContext } : {}),
      ...(mobileNativeSpec ? mobileNativeSpec : {}),
    }

    const sendNextSaveClickBatch = async () => await dispatch(SESSION_TIME_TYPES.ACTIONS.SEND_NEXT_SAVE_CLICK_BATCH)
    sendNextSaveClickBatch()

    const loggedOffBridge = async () => {
      await dispatch(BRIDGE_TYPES.ACTIONS.LOGGED_OFF)
    }
    loggedOffBridge()

    dispatch(WEBRTC_TYPES.ACTIONS.STOP_CALL_ON_LOGOUT);

    const user = loginData.unternr ? `${loginData.userid}-${loginData.unternr}` : loginData.userid
    dispatch(WEBRTC_TYPES.ACTIONS.STOP_PUSH_SOCKET, { message: `The user ${user} has logged out` });

    const afterLogout = () => {
      dispatch(SSO_TYPES.ACTIONS.LOGOUT, false);
      if(isMaklerzugang) {
        maklerzugangTabsBroadcast?.postMessage('logout');
        maklerzugangTabsBroadcast?.close();
      } else if(!isKundenzugang) {
        tabsBroadcast?.postMessage('logout');
        tabsBroadcast?.close();
      }
    }

    if (!isBypass) {
      axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrslogout`, {}, {
        ...config,
        disableDefaultErrorMessage: true,
      })
      .finally(() => {
        // in order to stop the push socket, we need to wait until the mrslogout is finished.
        afterLogout();
      });
    } else {
      // or call the stop push socket if mrslogout is not called
      afterLogout();
    }

    return new Promise(resolve => {
      const redirectAfterLogout = () => {
        commit(CORE_TYPES.MUTATIONS.SET_LOGGING_OUT, true);
  
        let pageAfterLogout = '/login'
  
        if (isKundenzugang) {
          pageAfterLogout = '/logged-out'
        }
  
        resetBreadcrumb();
        lockAddBreadcrumb();
        router.push({ path: pageAfterLogout, query })
          .catch(() => {})
          .finally(() => requestAnimationFrame(() => {
            unlockAddBreadcrumb();
            commit(CORE_TYPES.MUTATIONS.SET_LOGGING_OUT, false);
            resolve();
          }));
      };

      setTimeout(async () => {
        await dispatch(HTTP_REQ_TYPES.ACTIONS.CANCEL_ALL_CURRENT_FRONTEND_REQUESTS, MESSAGE_CANCEL_REQUEST_DUE_TO_LOGOUT)
        document.dispatchEvent(new CustomEvent('onLogout'))
  
        // when logging out from a Kundenzugang / Maklerzugang session on Mobile Native Context do not redirect
        if(isMobileNativeContext && (isMaklerzugang || isKundenzugang)) {
          await dispatch(BRIDGE_TYPES.ACTIONS.CLOSE_EXTRA_WEB_INSTANCE); // global reset state is called into the close extra web instance action
          resolve();
        } else {
          dispatch(CORE_TYPES.ACTIONS.RESET_STATE, ResetStateSource.Logout);
          redirectAfterLogout();
        }
  
        if (message) {
          dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, message);
        }
      }, 1);
    });
  },

  [CORE_TYPES.ACTIONS.RESET_STATE]({ commit, getters }, source) {
    commit(CORE_TYPES.MUTATIONS.RESET_STATE, source);
    commit(CMS_TYPES.MUTATIONS.RESET_STATE);
    commit(COMMUNICATION_TYPES.MUTATIONS.RESET_STATE);
    commit(CUSTOMER_TYPES.MUTATIONS.RESET_STATE);
    commit(CUSTOMER_SEARCH_TYPES.MUTATIONS.RESET_STATE);
    commit(DOCUMENTS_TYPES.MUTATIONS.RESET_STATE);
    commit(INTERN_TYPES.MUTATIONS.RESET_STATE);
    commit(CALENDAR_TYPES.MUTATIONS.RESET_STATE);
    commit(CALENDAR_SETTINGS_TYPES.MUTATIONS.RESET_STATE);
    commit(CUSTOMER_FILTERS_TYPES.MUTATIONS.RESET_STATE);
    commit(DEPOTPOSITIONS_TYPES.MUTATIONS.RESET_STATE);
    commit(DOKUMENTENARCHIV_TYPES.MUTATIONS.RESET_DATA);
    commit(SCHADENSMELDUNG_TYPES.MUTATIONS.RESET_STATE);
    commit(MULTIBANKING_TYPES.MUTATIONS.RESET_STATE);
    commit(VERSICHERUNG_TYPES.MUTATIONS.RESET_STATE);
    commit(DASHBOARD_TYPES.MUTATIONS.RESET_STATE);
    commit(STECKBRIEF_TYPES.MUTATIONS.RESET_STATE);
    commit(INSURANCE_TYPES.MUTATIONS.RESET_STATE);
    commit(ANTRAG_TYPES.MUTATIONS.RESET_STATE);
    commit(DOCUMENT_TYPES.MUTATIONS.RESET_STATE);
    commit(DYNAMIC_DOCUMENT_TYPES.MUTATIONS.RESET_STATE);
    commit(ANLEGERPROFIL_TYPES.MUTATIONS.RESET_STATE);
    commit(ANLAGEZIELE_TYPES.MUTATIONS.RESET_STATE);
    commit(ANSPRECHPARTNER_TYPES.MUTATIONS.RESET_STATE);
    commit(GESELLSCHAFT_TYPES.MUTATIONS.RESET_STATE);
    commit(ANLAGEEMPFEHLUNG_TYPES.MUTATIONS.RESET_STATE);
    commit(FONDSFINDER_TYPES.MUTATIONS.RESET_STATE);
    commit(CUSTOMERDATA_TYPES.MUTATIONS.RESET_STATE);
    commit(MAILCOMPOSER_TYPES.MUTATIONS.RESET_STATE);
    commit(MSC_NEWS_TYPES.MUTATIONS.RESET_STATE);
    commit(FONDSINFO_TYPES.MUTATIONS.RESET_STATE);
    commit(STORNO_WARNUNG_TYPES.MUTATIONS.RESET_STATE);
    commit(SEARCH_CONFIGS_TYPES.MUTATIONS.RESET_STATE);
    commit(SESSION_TIME_TYPES.MUTATIONS.RESET_STATE);
    commit(WEBRTC_TYPES.MUTATIONS.RESET_STATE);
    commit(LOG_TYPES.MUTATIONS.RESET_STATE, true);
    commit(BALANCE_SHEET_TYPES.MUTATIONS.RESET_STATE);
    commit(BIPRO_TYPES.MUTATIONS.RESET_STATE);
    commit(BRIDGE_TYPES.MUTATIONS.RESET_STATE);
    commit(TAGS_TYPES.MUTATIONS.RESET_STATE);
    commit(FC_CONFIG_TYPES.MUTATIONS.RESET_STATE);
    commit(DOMAIN_FC_TYPES.MUTATIONS.RESET_STATE)
    commit(CONFIG_DEFINITION_TYPES.MUTATIONS.RESET_STATE);
    commit(MERGE_PEOPLE_TYPES.MUTATIONS.RESET_STATE);
    commit(TEMPLATES_EDIT_TYPES.MUTATIONS.RESET_STATE);
    commit(NAVIGATION_LOG_TYPES.MUTATIONS.RESET_STATE);
    commit(VERTRAG_WP_TYPES.MUTATIONS.RESET_STATE);
    commit(EMAIL_NOT_RECOGNIZED_TYPES.MUTATIONS.RESET_STATE)
    commit(VV_TYPES.MUTATIONS.RESET_STATE)
    commit(WINDOW_CONTROL_TYPES.MUTATIONS.RESET_STATE);
    commit(OPEN_SIGNS_TYPES.MUTATIONS.RESET_STATE);
    commit(MENU_TYPES.MUTATIONS.RESET_STATE);
    commit(MENU_CONFIG_TYPES.MUTATIONS.RESET_STATE);
    commit(LOADING_TYPES.MUTATIONS.RESET_STATE);
    commit(VUBLOCK_TYPES.MUTATIONS.RESET_STATE);
    commit(MSC_MARKETING_TYPES.MUTATIONS.RESET_STATE);
    commit(BROKER_PERMISSIONS_TYPES.MUTATIONS.RESET_STATE);
    commit(EXTERNE_ZUGANG_TYPES.MUTATIONS.RESET_STATE);
    commit(BROKER_SEARCH_TYPES.MUTATIONS.RESET_STATE);
  },

  [CORE_TYPES.ACTIONS.ADD_RECORDED_ITEM]({ commit, state }, payload) {

    commit(CORE_TYPES.MUTATIONS.ADD_RECORDED_ITEM, { ...payload });
  },

  [CORE_TYPES.ACTIONS.REMOVE_RECORDED_ITEM]({ commit }, payload) {
    commit(CORE_TYPES.MUTATIONS.REMOVE_RECORDED_ITEM, payload);
  },

  [CORE_TYPES.ACTIONS.UPDATE_RECORDED_ITEM]({ commit, state }, payload) {

    commit(CORE_TYPES.MUTATIONS.UPDATE_RECORDED_ITEM, { ...payload });
  },

  [CORE_TYPES.ACTIONS.LOAD_COLOR_SCHEMA]({ commit, getters }, payload) {

    const colorSchema = [];
    const allMatchSchema = getters[CORE_TYPES.GETTERS.GET_COLOR_SCHEMA];
    for (let [key, layout] of Object.entries(payload)) {

      if (allMatchSchema[key] && allMatchSchema[key].eventListenerRemover) {
        allMatchSchema[key].eventListenerRemover();
      }

      const mediaQuery = window.matchMedia(`(prefers-color-scheme: ${key})`);
      const eventListener = (m) => {
        if (m.matches) {
          if (key === 'cms' && document.querySelector('.base-cms-view')) {
            loadColorScheme(layout, '.base-cms-view');
          } else {
            setColorScheme();
          }
        }
      }
      mediaQuery.addListener(eventListener);

      const eventListenerRemover = () => {
        mediaQuery.removeListener(eventListener);
      }

      colorSchema.push({ schema: key, layout, mediaQuery, eventListenerRemover })
    }
    commit(CORE_TYPES.MUTATIONS.ADD_COLOR_SCHEMA, colorSchema);

    setColorScheme();

    const cmsLayout = Object.values(allMatchSchema)
        .filter(mm => mm.schema === 'cms')
        .map(mm => mm.layout)
        .find(l => l);

    if (document.querySelector('.base-cms-view')) {
      loadColorScheme(cmsLayout, '.base-cms-view');
    }
  },

  [CORE_TYPES.ACTIONS.APPLY_CONFIGURED_COLOR_SCHEME]({ commit }, scheme) {
    commit(CORE_TYPES.MUTATIONS.SET_CONFIGURED_COLOR_SCHEME, scheme);
    setColorScheme();

    if (process.env.NODE_ENV === 'development') { // when it is on development mode keeps the color scheme in the localStorage
      localStorage.setItem('app:colorScheme', scheme); // it overrides the "colorScheme" in "loginData" in localStorage
    }
  },

  [CORE_TYPES.ACTIONS.SEND_TUTORIAL]({ commit, state, dispatch, getters }, payload) {
    const config = {
      defaultSpinner: true
    };

    axios.post(state.apiAddress + '/interactiveHelp/sendTutorial', payload, config).then(response => {
      if (response && response.data) {

        commit(CORE_TYPES.MUTATIONS.SEND_TUTORIAL_SUCCESS, response.data);

      }
    }).catch(error => {
      commit(CORE_TYPES.MUTATIONS.SEND_TUTORIAL_FAIL);
    })
  },

  [CORE_TYPES.ACTIONS.REMOVE_TUTORIAL]({ commit, state, dispatch, getters }, payload) {
    const config = {
      defaultSpinner: true
    };


    return new Promise((resolve, reject) => {
      axios.delete(state.apiAddress + `/interactiveHelp/removeTutorial?tutorialId=${payload.tutorialId}`, payload, config).then(response => {
        if (response && response.data) {
          resolve(response.data);
        }
      }).catch(error => {
        reject();
      })
      
    });


  },  

  [CORE_TYPES.ACTIONS.RETRIEVE_TUTORIALS]({ commit, state, dispatch, getters }, payload) {
    const config = {
      defaultSpinner: true
    };

    axios.get(state.apiAddress + `/interactiveHelp/getList?routerPath=${payload.routerPath}`, config).then(response => {
      if (response && response.data) {

        commit(CORE_TYPES.MUTATIONS.RETRIEVE_TUTORIALS_SUCCESS, response.data.availableTutorials);

      }
    }).catch(error => {
      commit(CORE_TYPES.MUTATIONS.SEND_TUTORIAL_FAIL);
    })
  },

  [CORE_TYPES.ACTIONS.GLOBAL_LOADING_STATE_STOP]({ commit, getters }) {
    if (getters[CORE_TYPES.GETTERS.GLOBAL_LOADING_STATE_STATUS]) {
      commit(CORE_TYPES.MUTATIONS.GLOBAL_LOADING_STATE_STOP)
    }
  },

  [CORE_TYPES.ACTIONS.GLOBAL_LOADING_STATE_START]({ commit, getters }) {
      commit(CORE_TYPES.MUTATIONS.GLOBAL_LOADING_STATE_START)
  },

  [CORE_TYPES.ACTIONS.REMOVE_ALL_RECORDED_ITEMS]({ commit }, payload) {
    commit(CORE_TYPES.MUTATIONS.REMOVE_ALL_RECORDED_ITEMS);
  },


  [CORE_TYPES.ACTIONS.RECORDING_TUTORIAL_TITLE]({ commit }, payload) {
    commit(CORE_TYPES.MUTATIONS.RECORDING_TUTORIAL_TITLE, payload);
  },

  [CORE_TYPES.ACTIONS.GET_SYSTEM_DATA]({ commit, getters }, payload) {
    let endpoint = '/system_data/get_system_data'
    if (payload?.key) {
      endpoint = `/system_data/get_system_data_by_key?key=${payload?.key}`
    } 

    axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${endpoint}`).then(response => {
      if (response.data) {
        commit(CORE_TYPES.MUTATIONS.GET_SYSTEM_DATA_SUCCESS, response.data);
      }
    })
  },
  
  async [CORE_TYPES.ACTIONS.OPEN_INTERN]({ getters, dispatch, commit, state }, { userId, nextUrl, tabsBroadcastId, maklerzugangTabsBroadcastId }) {
    const queryParam = {
      userid: userId,
      fillUnterNr: false,
      userType: 'INTERN',
    };
    await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/login/getToken?${makeQueryParam(queryParam)}`).then(response => {
      const originalUser = {
        ...state.loginData,
        originalUserRouteFullPath:  router.history?.current?.fullPath
      };
      const loginData = {...originalUser, token: response.data.token};

      commit(CORE_TYPES.MUTATIONS.PUSH_ORIGINAL_USER, originalUser);
      commit(CORE_TYPES.MUTATIONS.LOGIN_SUCCESS, loginData);
      commit(CORE_TYPES.MUTATIONS.LOGIN_FAIL);

      dispatch(CORE_TYPES.ACTIONS.MANAGE_LOGIN_RESPONSE, {
          ...originalUser,
          data: {
            ...originalUser,
            ...response.data,
            rights: {
              ...originalUser.rights,
              ...response.data.rights,
              isIntern: true,
              hideLogoutButton: true,
            },
          },
          rights: {
            ...originalUser.rights,
            ...response.data.rights
          },
          ...response.data,
          nextUrl,
          tabsBroadcastId,
          maklerzugangTabsBroadcastId: '',
      });
    })
  },
  
  async [CORE_TYPES.ACTIONS.OPEN_BROKER]({ getters, dispatch, commit, state }, { maklernr, nextUrl, tabsBroadcastId, isMaklerzugang, maklerzugangTabsBroadcastId, fillUnterNr }) {
    
    const queryParam = {
      userid: maklernr,
      fillUnterNr: fillUnterNr || false,
      userType: 'MAKLER',
    };

    await dispatch(BROKER_SEARCH_TYPES.ACTIONS.TRACK_OPEN_BROKER, { brokerId: maklernr});

    await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/login/getToken?${makeQueryParam(queryParam)}`).then(response => {
      const originalUser = {
        ...state.loginData,
        originalUserRouteFullPath:  router.history?.current?.fullPath
      };

      const loginData = {...originalUser, token: response.data.token};
      commit(CORE_TYPES.MUTATIONS.PUSH_ORIGINAL_USER, originalUser);
      commit(CORE_TYPES.MUTATIONS.LOGIN_SUCCESS, loginData);
      commit(CORE_TYPES.MUTATIONS.LOGIN_FAIL);
      commit(DASHBOARD_TYPES.MUTATIONS.RESET_STATE);

      if (getters[CORE_TYPES.GETTERS.STORE_SESSION_INFORMATION]) {
        setSessionObject('userNumberPerspective', maklernr)
      }

      const broker = maklernr;
      const host = window.location.host;
      if (broker || host) {
        dispatch(CORE_TYPES.ACTIONS.GET_BROKER_INFORMATION, { broker, host });
      }

      dispatch(CORE_TYPES.ACTIONS.MANAGE_LOGIN_RESPONSE, {
          ...originalUser,
          data: {
            ...originalUser,
            ...response.data,
            rights: {
              ...originalUser.rights,
              ...response.data.rights,
              isMaklerzugang,
              isBroker: true,
              hideLogoutButton: true,
            },
          },
          rights: {
            ...originalUser.rights,
            ...response.data.rights
          },
          ...response.data,
          nextUrl,
          tabsBroadcastId,
          maklerzugangTabsBroadcastId,
      });
    });
  },

  async [CORE_TYPES.ACTIONS.OPEN_CUSTOMER_STECKBRIEF]({ dispatch, commit, state, getters }, payload) {
    await axios.get(`${process.env.VUE_APP_API}/login/getToken?userType=KUNDE&userid=${payload.customerId}`, config).then(response => {
      const originalUser = {
        ...state.loginData,
        originalUserRouteFullPath:  router.history?.current?.fullPath
      };

      const loginData = {...originalUser, token: response.data.token}
      commit(CORE_TYPES.MUTATIONS.PUSH_ORIGINAL_USER, originalUser);
      commit(CORE_TYPES.MUTATIONS.LOGIN_SUCCESS, loginData);
      commit(CORE_TYPES.MUTATIONS.LOGIN_FAIL);

      if (getters[CORE_TYPES.GETTERS.STORE_SESSION_INFORMATION]) {
        setSessionObject('userNumberPerspective', payload.customerId)
      }

      dispatch(CORE_TYPES.ACTIONS.MANAGE_LOGIN_RESPONSE, {
          ...originalUser,
          ...response.data,
          data: {
            ...originalUser,
            ...response.data,
            rights: {
              ...originalUser.rights,
              ...response.data.rights,
              isTestBroker: false,
              isBroker: false,
              isCustomer: true,
              hideLogoutButton: true,
            },
            maklernr: response.data.maklernr,
            maklerName: response.data.maklerName
          },
          rights: {
            ...originalUser.rights,
            ...response.data.rights
          },
          nextUrl: payload.nextUrl && payload.nextUrl != 'undefined' ? payload.nextUrl : '/home/steckbrief-view',
          tabsBroadcastId: payload.tabsBroadcastId
      });
    });
  },

  [CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB]({ state, getters, dispatch }, payload) {
    const { customerId, path, originalToken } = payload;
    if(!looksLikeKundennr(customerId) && !payload.force) {
      return;
    }

    // go to FC in another tab
    const isMaklerzugang = getters[CORE_TYPES.GETTERS.IS_MAKLERZUGANG];
    const isSuperCustomer = getters[CORE_TYPES.GETTERS.IS_SUPER_CUSTOMER];

    const token = originalToken || state.loginData.token
    const tabsBroadcastId = state.loginData.tabsBroadcastId
    const maklerzugangTabsBroadcastId = state.loginData.maklerzugangTabsBroadcastId || ''

    const queryParam = {
      isMaklerzugang,
      isSuperCustomer,
      token,
      tabsBroadcastId,
      maklerzugangTabsBroadcastId,
      path: encodeURIComponentIfNeeded(path), // keeps query params
    }

    if (payload.skipNewTab) {
      queryParam.skipNewTab = true
    }

    if (payload.insurances) {
      queryParam.kundennrVersicherungen = customerId
    } else if (payload.kundennrBeratungsmappe) {
      queryParam.kundennrBeratungsmappe = customerId
    } else {
      queryParam.kundennrSteckbrief = customerId
    }

    const url = `/token-login?${makeQueryParam(queryParam)}`
    
    dispatch(CUSTOMER_SEARCH_TYPES.ACTIONS.TRACK_OPEN_CUSTOMER, { customerId });
    dispatch(BRIDGE_TYPES.ACTIONS.OPEN_EXTRA_WEB_INSTANCE, { url, queryParam });
  },

  [CORE_TYPES.ACTIONS.OPEN_CUSTOMER_SAME_TAB_FOR_TESTING]({ state, getters, dispatch }, payload) {
    const { customerId, path, originalToken } = payload;
    if(!looksLikeKundennr(customerId) && !payload.force) {
      return;
    }

    // go to FC in another tab
    const isMaklerzugang = getters[CORE_TYPES.GETTERS.IS_MAKLERZUGANG];
    const isSuperCustomer = getters[CORE_TYPES.GETTERS.IS_SUPER_CUSTOMER];

    const token = originalToken || state.loginData.token
    const tabsBroadcastId = state.loginData.tabsBroadcastId
    const maklerzugangTabsBroadcastId = state.loginData.maklerzugangTabsBroadcastId || ''

    const queryParam = {
      isMaklerzugang,
      isSuperCustomer,
      token,
      tabsBroadcastId,
      maklerzugangTabsBroadcastId,
      path: encodeURIComponentIfNeeded(path), // keeps query params
    }

    if (payload.skipNewTab) {
      queryParam.skipNewTab = true
    }

    if (payload.insurances) {
      queryParam.kundennrVersicherungen = customerId
    } else if (payload.kundennrBeratungsmappe) {
      queryParam.kundennrBeratungsmappe = customerId
    } else {
      queryParam.kundennrSteckbrief = customerId
    }

    const url = `http://localhost:10880/token-login?${makeQueryParam(queryParam)}`
    document.location.href = url;
  },

  async [CORE_TYPES.ACTIONS.OPEN_CUSTOMER_INSURANCES]({ dispatch, commit, state, getters }, payload) {
    await axios.get(`${process.env.VUE_APP_API}/login/getToken?userType=KUNDE&userid=${payload.customerId}`, config).then(response => {
      const originalUser = {
        ...state.loginData,
        originalUserRouteFullPath:  router.history?.current?.fullPath
      };

      const loginData = {...originalUser, token: response.data.token}
      commit(CORE_TYPES.MUTATIONS.PUSH_ORIGINAL_USER, originalUser);
      commit(CORE_TYPES.MUTATIONS.LOGIN_SUCCESS, loginData);
      commit(CORE_TYPES.MUTATIONS.LOGIN_FAIL);

      if (getters[CORE_TYPES.GETTERS.STORE_SESSION_INFORMATION]) {
        setSessionObject('userNumberPerspective', payload.customerId);
      }

      dispatch(CORE_TYPES.ACTIONS.MANAGE_LOGIN_RESPONSE, {
          ...originalUser,
          ...response.data,
          data: {
            ...originalUser,
            ...response.data,
            rights: {
              ...originalUser.rights,
              ...response.data.rights,
              isTestBroker: false,
              isBroker: false,
              isCustomer: true,
              hideLogoutButton: true,
            },
          },
          rights: {
            ...originalUser.rights,
            ...response.data.rights
          },
          nextUrl: payload.nextUrl && payload.nextUrl != 'undefined' ? payload.nextUrl : '/home/versicherungen',
          tabsBroadcastId: payload.tabsBroadcastId
      });
    });
  },  
  

  [CORE_TYPES.ACTIONS.OPEN_CUSTOMER_BERATUNGSMAPPE]({ dispatch, commit, state, getters }, payload) {
    axios.get(`${process.env.VUE_APP_API}/login/getToken?userType=KUNDE&userid=${payload.customerId}`, config).then(response => {
      const originalUser = {
        ...state.loginData,
        originalUserRouteFullPath:  router.history?.current?.fullPath
      };

      const loginData = {...originalUser, token: response.data.token}
      commit(CORE_TYPES.MUTATIONS.PUSH_ORIGINAL_USER, originalUser);
      commit(CORE_TYPES.MUTATIONS.LOGIN_SUCCESS, loginData);
      commit(CORE_TYPES.MUTATIONS.LOGIN_FAIL);

      if (getters[CORE_TYPES.GETTERS.STORE_SESSION_INFORMATION]) {
        setSessionObject('userNumberPerspective', payload.customerId)
      }

      dispatch(CORE_TYPES.ACTIONS.MANAGE_LOGIN_RESPONSE, {
          ...originalUser,
          ...response.data,
          data: {
            ...originalUser,
            ...response.data,
            rights: {
              ...originalUser.rights,
              ...response.data.rights,
              isTestBroker: false,
              isBroker: false,
              isCustomer: true,
              hideLogoutButton: true,
            },
          },
          rights: {
            ...originalUser.rights,
            ...response.data.rights
          },
          nextUrl: payload.nextUrl && payload.nextUrl != 'undefined' ? payload.nextUrl : '/beratung/beratung/bearbeiten',
          tabsBroadcastId: payload.tabsBroadcastId
      });
    });
  }, 
  
  [CORE_TYPES.ACTIONS.OPEN_BROKER_NEW_TAB]({ state, getters, dispatch }, { originalToken, brokerId, path, windowRef, fillUnterNr }) {
    const isMaklerzugang = getters[CORE_TYPES.GETTERS.IS_MAKLERZUGANG];
    const token = originalToken || state.loginData.token
    const tabsBroadcastId = state.loginData.tabsBroadcastId
    const maklerzugangTabsBroadcastId = state.loginData.maklerzugangTabsBroadcastId || ''
    const maklernr = brokerId

    const queryParam = {
      token,
      tabsBroadcastId,
      maklernr,
      isMaklerzugang,
      maklerzugangTabsBroadcastId,
      path,
      fillUnterNr,
    }

    const url = `/token-login?${makeQueryParam(queryParam)}`

    dispatch(BRIDGE_TYPES.ACTIONS.OPEN_EXTRA_WEB_INSTANCE, { url, queryParam, windowRef, })
  },
  
  [CORE_TYPES.ACTIONS.OPEN_INTERN_NEW_TAB]({ state, getters, dispatch }, { originalToken, userId, path, windowRef }) {
    const isIntern = getters[CORE_TYPES.GETTERS.IS_INTERN];
    const token = originalToken || state.loginData.token
    const tabsBroadcastId = state.loginData.tabsBroadcastId
    const maklerzugangTabsBroadcastId = '';

    const queryParam = {
      token,
      tabsBroadcastId,
      userId,
      isIntern,
      maklerzugangTabsBroadcastId,
      path,
    }

    const url = `/token-login?${makeQueryParam(queryParam)}`

    dispatch(BRIDGE_TYPES.ACTIONS.OPEN_EXTRA_WEB_INSTANCE, { url, queryParam, windowRef, })
  },
  
  [CORE_TYPES.ACTIONS.GET_APP_LINKS]({ state, getters, commit }, brokerid) {
    
    const store = ['IOS', 'google']
    let brokerIdParam = makeQueryParam({ brokerid })
    brokerIdParam = brokerIdParam ? '&' + brokerIdParam : ''

    for (const storeName of store) {
      if (!state.appLinks[storeName]) {
        axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/applink?APP_TYPE=${storeName}${brokerIdParam}`).then(response => {
          if (response && response.data) {
            commit(CORE_TYPES.MUTATIONS.GET_APP_LINKS_SUCCESS, { [storeName]: response.data})
          }
        })
      }
    }
  },

  async [CORE_TYPES.ACTIONS.GET_PRIVACY_DATA]({ commit, getters }) {
    try {
      const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrsprivacy`)
      commit(CORE_TYPES.MUTATIONS.GET_PRIVACY_DATA_SUCCESS, response.data)
    } catch (error) {
      // empty block
    }
  },

  async [CORE_TYPES.ACTIONS.SUBMIT_KUNDEINTERESSENKONFLIKT]({ getters, commit }) {
    const response = await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrskundeinteressenkonflikt`, {}, config)
    commit(CORE_TYPES.MUTATIONS.MERGE_PENDING_ACTIONS, { kundeInteressenkonflikt: false });
    return response?.data || ''
  },

  async [CORE_TYPES.ACTIONS.GET_PRIVACY_CONDITIONS]({ getters }, payload) {
    const params = Object.entries(payload)
      .map(([key, val]) => `${key}=${val}`)
      .join('&')

    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrsconditions?${params}`, config)
    return response?.data || ''
  },

  async [CORE_TYPES.ACTIONS.CONFIRM_PRIVACY]({ getters, commit, dispatch }, payload) {
    const response = await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrsprivacy`, payload.terms)

    if (response?.data?.isConfirmed) {
      commit(CORE_TYPES.MUTATIONS.UPDATE_PRIVACY, response.data)

      const mustCheckPrivacy = getters[CORE_TYPES.GETTERS.PENDING_ACTIONS] && getters[CORE_TYPES.GETTERS.PENDING_ACTIONS].checkPrivacy
      if (mustCheckPrivacy) {
        commit(CORE_TYPES.MUTATIONS.MERGE_PENDING_ACTIONS, { checkPrivacy: false });
      }
      
      router.push({ path: payload.nextUrl || '/home' }).catch(() => {});
    } else {
      dispatch(CORE_TYPES.ACTIONS.LOGOUT)
    }
  },

  async [CORE_TYPES.ACTIONS.GET_PDF_FILES]({ getters }, payload) {
    try {
      let params = `?${payload.paramName}=true&`;
      params += Object.entries(payload).filter(([key, val]) => key !== 'paramName')
        .map(([key, val]) => `${key}=${val}`).join('&');
      const customConfig = {
        ...config,
        responseType: 'blob'
      }

      const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrspdfprint${params}`, customConfig)
      if (!response?.data) {
        throw Error('pdf not found')
      }
      return response?.data
    } catch (error) {
      return { errorMessage: 'Unable to download pdf' }
    }
  },

  [CORE_TYPES.ACTIONS.SAVE_BACK_TO_PREVIOUS_PAGE]({ commit, getters, }, payload) {

    const currentPreviosPage = getters[CORE_TYPES.GETTERS.CURRENT_BACK_TO_PREVIOUS_PAGE];

    const fullPath = payload.replaceLastPreviousData ? currentPreviosPage?.fullPath : payload.route?.fullPath;
    const label = payload.replaceLastPreviousData ? currentPreviosPage?.label : null;

    const backActionParam = payload?.to?.query?.backAction

    const pushPayload = {
      label: label || payload.label,
      fullPath: payload.fullPath || fullPath,
      breadcrumb: payload.breadcrumb,
      resetPreviousState: payload.resetPreviousState,
      replaceLastPreviousData: payload.replaceLastPreviousData,
      popLastPreviousData: payload.popLastPreviousData,
      dokumentenarchivLastFolder: payload.dokumentenarchivLastFolder
    }
    
    if (!backActionParam && !isSamePath(pushPayload?.fullPath, currentPreviosPage?.fullPath)) {
      commit(CORE_TYPES.MUTATIONS.PUSH_SAVE_BACK_TO_PREVIOUS_PAGE, pushPayload)
    }
  },

  [CORE_TYPES.ACTIONS.SAVE_BACK_TO_PREVIOUS_PAGE_TRACK]({ commit, getters }, payload) {
    if(!payload?.currentRouteLink) {
      return ;
    }

    const backActionParam = payload?.to?.query?.backAction;
    if(backActionParam) {
      return ;
    }

    const commitPayload = {
      [payload.currentRouteLink]: [...getters[CORE_TYPES.GETTERS.GET_SAVE_BACK_TO_PREVIOUS_PAGE]],
    };

    commit(CORE_TYPES.MUTATIONS.SET_BACK_TO_PREVIOUS_PAGE_TRACK, commitPayload);
  },

  [CORE_TYPES.ACTIONS.GO_TO_LAST_BACK_TO_PREVIOUS_PAGE]({ getters }) {
    const backToPreviousData = getters[CORE_TYPES.GETTERS.GET_SAVE_BACK_TO_PREVIOUS_PAGE];

    const lastBackToPreviousData = backToPreviousData?.[backToPreviousData.length - 1];
    if(!lastBackToPreviousData) return;

    const fullPath = lastBackToPreviousData.fullPath;
    const query = { backAction: true, };
    router.push({
      path: fullPath,
      query: query,
    })
  },

  async [CORE_TYPES.ACTIONS.CHANGE_PASSWORD]({ commit, getters, dispatch}, {oldPassword, newPassword}) {

    return axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/login/change_password`, {oldPassword, newPassword}, config)
    .then(response => {
        commit(CORE_TYPES.MUTATIONS.MARK_PENDING_ACTION_AS_DONE, "changePassword");
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage("Das Passwort wurde erfolgreich geändert.", 'info'));
        return true;
    }).catch(error => {
        if (error?.response?.status == 403)
            dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage("Das aktuelle Zugangspasswort ist nicht korrekt. Bitte überprüfen Sie Ihr aktuelles Zugangspasswort.", 'danger'));
        else
            dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage("Das Passwort konnte nicht geändert werden. Bitte überprüfen Sie Ihre Angaben.", 'danger'));
        return false;
    });
  },
  async [CORE_TYPES.ACTIONS.CHANGE_VERSAND_PASSWORD]({ commit, getters, dispatch}, payload) {
    let result;
    try {
      result = await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrschangepassword`, {...payload, nextUrl: undefined}, config);
    } catch (error) {
      throw Error('Something went wrong')
    }
    if (result?.data?.error) {
      throw Error(result.data.error)
    }
    return result?.data?.message
  },
  [CORE_TYPES.ACTIONS.CHECK_REGISTRATION_CODE]({ getters }, { code }) {
    return new Promise((resolve, reject) => {
      if(!code) {
        reject('CORE_TYPES.ACTIONS.CHECK_REGISTRATION_CODE -> You must pass the "code" to be checked!');
        return ;
      }
      axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrscheckregistrationcode?regCode=${code}`)
        .then((response) => {
          resolve(!!response?.data?.valid);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  async [CORE_TYPES.ACTIONS.GET_CAPTCHA]({ getters, dispatch }) {
    const tokenUrl = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/captcha/captcha_token`;
    const responseToken = await axios.get(tokenUrl);
    const captchaToken = responseToken.data?.token;

    if (!captchaToken) {
      dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Captcha konnte nicht generiert werden', 'danger'));
      return;
    }

    await dispatch(CORE_TYPES.ACTIONS.GET_CAPTCHA_IMG, { captchaToken })
  },
  async [CORE_TYPES.ACTIONS.GET_CAPTCHA_IMG]({ getters, commit }, { captchaToken }) {
    const imageUrl = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/captcha/captcha_image`;

    const imageConfig = {
      ...config,
      params: { captchaToken }
    };

    const responseImage = await axios.get(imageUrl, imageConfig);

    const captchaData = {
      captchaToken,
      captchaImage: 'data:image/png;base64,' + encodeURI(responseImage.data?.captchaImg),
    };

    commit(CORE_TYPES.MUTATIONS.GET_CAPTCHA_RESULT, captchaData);
  },
  async [CORE_TYPES.ACTIONS.CHECK_CAPTCHA_AND_REGISTRATION_CODE]({ getters, dispatch }, { code, captcha }) {
    try {
      const captchaData = getters[CORE_TYPES.GETTERS.CAPTCHA_DATA];
      const data = {
        regCode: code,
        captcha,
        captchaToken: captchaData?.captchaToken,
      };
      const response = await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/captcha/captcha_check`, data, config)

      if (!response.data?.captcha) {
        await dispatch(CORE_TYPES.ACTIONS.GET_CAPTCHA_IMG, { captchaToken: captchaData?.captchaToken });
      }

      return response?.data;
    } catch (error) {
      dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Captcha konnte nicht generiert werden', 'danger'));
      dispatch(LOG_TYPES.ACTIONS.LOG, { error, message: 'Check captcha could not be validated'});
      return false;
    }
  },
  [CORE_TYPES.ACTIONS.GET_PRIVACY_REGISTRATION_DATA]({ getters, commit }, { code }) {
    axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrsprivacyregistration?regCode=${code}`, config)
      .then((response) => {
        commit(CORE_TYPES.MUTATIONS.GET_PRIVACY_REGISTRATION_DATA_SUCCESS, response?.data || {});
      })
      .catch((error) => {
        console.log(error);
      });
  },
  [CORE_TYPES.ACTIONS.REGISTRATION]({ getters, commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      if(!payload?.registerCode) { //  Task MSC-18808: removed !payload?.privacy?.isConfirmed
        reject('CORE_TYPES.ACTIONS.REGISTRATION -> invalid registration object');
        return ;
      }
      axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/register/customer`, payload, config)
        .then((response) => {
          resolve(response?.data || undefined);
        })
        .catch((error) => {
          console.log(error);
          const message = error?.response?.data?.message;
          if(message) {
            dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(message, 'danger'));
          }
          reject(error);
        });
    });
  },
  [CORE_TYPES.ACTIONS.REGISTER_ACTIVATION]({ getters, dispatch }, { regid }) {
    return new Promise((resolve, reject) => {
      if(!regid) {
        reject('CORE_TYPES.ACTIONS.REGISTER_ACTIVATION -> you must pass "regid"!');
        return ;
      }
      const payload = {
        regid,
      };
  
      axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrsactivate`, payload, config)
        .then((response) => {
          const data = response?.data || {};
          router.push(`/login?userid=${data.userid}`);
          resolve(data);
        })
        .catch((error) => {
          console.log(error);
          const message = error?.response?.data?.message;
          if(message) {
            dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(message, 'danger'));
          }
          reject(error);
        });
    });
  },

  async [CORE_TYPES.ACTIONS.GET_DEFAULT_LAYOUT]({ commit, getters}) {
    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/layoutfc/get_default_layout`, config)
    commit(CORE_TYPES.MUTATIONS.GET_DEFAULT_LAYOUT_SUCCESS, response.data);  
  },

  async [CORE_TYPES.ACTIONS.GET_LAYOUT]({ commit, getters}) {
    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/layoutfc/get_layout`, config)
    if (response.data) {
      commit(CORE_TYPES.MUTATIONS.GET_LAYOUT_SUCCESS, response.data);
    }
  },

  async [CORE_TYPES.ACTIONS.SAVE_LAYOUT]({ getters, dispatch, commit }, payload) {
    try {
      await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/layoutfc/save_layout`, payload, config);

      const getUserID = getters[CORE_TYPES.GETTERS.GET_USER_ID];
      let layoutUrl = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/cms/cmsGetBrokerLayout?broker=${getUserID}`;
      const layoutResponse = await axios.get(layoutUrl, { noCheckToken: true })

      if (layoutResponse?.data?.layoutParamsFC) {
        commit(CORE_TYPES.MUTATIONS.GET_BROKER_LAYOUT_SUCCESS, layoutResponse.data.layoutParamsFC);
        await dispatch(CORE_TYPES.ACTIONS.LOAD_COLOR_SCHEMA, layoutResponse.data.layoutParamsFC);
      }
    } catch(error) {
      console.log(error);
    }
  },

  async [CORE_TYPES.ACTIONS.CONFIRM_MODAL]({ getters, commit }, payload) {
    const options = isObject(payload) ? Object.assign({}, payload) : { message: payload || '', };
    const _uuid = uuidv4();

    commit(CORE_TYPES.MUTATIONS.ADD_CONFIRM_MODAL, {
      _uuid,
      options,
    });

    return getters[CORE_TYPES.GETTERS.WAIT_PENDING_CONFIRM_MODAL_RESPONSE](_uuid);
  },

  [CORE_TYPES.ACTIONS.RESTORE_ROLE_VIEW_LOGGED_USER]({ commit, state }) {
    if (state.loginData?.rights) {
      const userViewRoles = getViewRoles(state.loginData?.rights)
      commit(CORE_TYPES.MUTATIONS.REPLACE_VIEW_ROLES, { roles: userViewRoles })
      router.push({ path: '/home' }).catch(() => {});
    }
  },

  [CORE_TYPES.ACTIONS.SWITCH_TO_ROLE_VIEW_CUSTOMER_ONLY]({ commit }) {
    const roles = [
      VIEW_ROLES.VIEW_CUSTOMER_ONLY, 
      VIEW_ROLES.VIEW_CUSTOMER,
      VIEW_ROLES.VIEW_KUNDENSICHT,
    ]

    commit(CORE_TYPES.MUTATIONS.REPLACE_VIEW_ROLES, {roles})
    router.push({ path: '/home' }).catch(() => {});
  },

  async [CORE_TYPES.ACTIONS.OPEN_KUNDENZUGANG]({ getters, state, dispatch, }) {
    const userid = getters[CORE_TYPES.GETTERS.GET_USER_ID];

    const params = {
      userType: 'KUNDE',
      userid,
      useBypass: true,
    };

    const windowRef = getRedirectionWindow(true);

    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrskundenzugang`, {
      ...config,
      params,
    })
    .catch(() => windowRef?.close());

    const { link } = response?.data || {};
    if (link) {
      const params = link.substring(link.indexOf('?')).replace(/^\?/, '');
      const host = window.location.origin;
      
      const queryParam = {
        ...parseParamsIntoObject(params),
        tabsBroadcastId: state.loginData.tabsBroadcastId,
        maklerzugangTabsBroadcastId: state.loginData.maklerzugangTabsBroadcastId || '',
        host,
        isKundenzugang: true
      }

      const url = `${host}/kundenzugang-login?${makeQueryParam(queryParam)}`;

      dispatch(BRIDGE_TYPES.ACTIONS.OPEN_EXTRA_WEB_INSTANCE, {
        url,
        windowRef,
        queryParam
      });
    } else {
      windowRef?.close();
    }
  },

  async [CORE_TYPES.ACTIONS.OPEN_MAKLERZUGANG]({ getters, state, dispatch, }) {
    const windowRef = getRedirectionWindow(true);

    const response = await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/login/maklerzugang`, {}, config)
      .catch(() => windowRef?.close());

    const { link } = response?.data || {};
    if (link) {
      const params = link.substring(link.indexOf('?')).replace(/^\?/, '');
      const host = window.location.origin;

      const queryParam = {
        ...parseParamsIntoObject(params),
        tabsBroadcastId: state.loginData.tabsBroadcastId,
        maklerzugangTabsBroadcastId: uuidv4(),
        host,
        isMaklerzugang: true
      }

      const url = `${host}/maklerzugang-login?${makeQueryParam(queryParam)}`;
      
      dispatch(BRIDGE_TYPES.ACTIONS.OPEN_EXTRA_WEB_INSTANCE, {
        url,
        windowRef,
        queryParam,
      });
    } else {
      windowRef?.close();
    }
  },

  async [CORE_TYPES.ACTIONS.OPEN_MITARBEITERZUGANG]({ getters, state, dispatch, }, { unternr, }) {
    if(!unternr) return;

    const windowRef = getRedirectionWindow(true);

    const response = await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/login/mitarbeiterzugang?unternr=${unternr}`, {}, config)
      .catch(() => windowRef?.close());

    const { link } = response?.data || {};
    if (link) {
      const params = link.substring(link.indexOf('?')).replace(/^\?/, '');
      const host = window.location.origin;

      const nextUrl = '/intern/ansprechpartner-daten';

      const queryParam = {
        ...parseParamsIntoObject(params),
        tabsBroadcastId: state.loginData.tabsBroadcastId,
        maklerzugangTabsBroadcastId: uuidv4(),
        host,
        isMaklerzugang: true,
        nextUrl,
      }

      const url = `${host}/maklerzugang-login?${makeQueryParam(queryParam)}`;
      
      dispatch(BRIDGE_TYPES.ACTIONS.OPEN_EXTRA_WEB_INSTANCE, {
        url,
        windowRef,
        queryParam,
      });
    } else {
      windowRef?.close();
    }
  },

  async [CORE_TYPES.ACTIONS.SAVE_CMS_META_TAG]({ getters, commit }, metaTag) {
    const response = await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/cms_meta_tags/save_meta_tag`, metaTag, config);

    if (response?.data) {
      commit(CORE_TYPES.MUTATIONS.GET_ALL_CMS_META_TAG_SUCCESS, response?.data)
    }
  },

  async [CORE_TYPES.ACTIONS.GET_ALL_CMS_META_TAG]({ getters, commit }) {
    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/cms_meta_tags/get_all_metatags`, config);

    if (response?.data) {
      commit(CORE_TYPES.MUTATIONS.GET_ALL_CMS_META_TAG_SUCCESS, response?.data)
    }
  },

  async [CORE_TYPES.ACTIONS.DELETE_CMS_META_TAG]({ getters, commit }, id) {
    const response = await axios.delete(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/cms_meta_tags/delete_meta_tag/${id}`, config);

    if (response?.data) {
      commit(CORE_TYPES.MUTATIONS.GET_ALL_CMS_META_TAG_SUCCESS, response?.data)
    }
  },
  
  [CORE_TYPES.ACTIONS.REQUEST_PASSWORD_RESET_CODES]({ getters }, payload) {
      return axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/forgot_password/request_password_reset`, payload, config);
  },

  [CORE_TYPES.ACTIONS.PASSWORT_WIEDERHERSTELLEN_WITH_CODE]({ getters }, payload) {
      return axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/forgot_password/reset_password_with_code`, payload, config);
  },

  [CORE_TYPES.ACTIONS.OPEN_CURRENT_BYPASS_USER]({ getters, commit, dispatch }) {
    const hasRoles = getters[CORE_TYPES.GETTERS.HAS_ROLES];

    if(!hasRoles([VIEW_ROLES.VIEW_BROKER_AS_BYPASS, VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS])) return;

    const loginData = getters[CORE_TYPES.GETTERS.GET_LOGIN_DATA];
    const originalToken = getters[CORE_TYPES.GETTERS.ORIGINAL_TOKEN];

    const { userid } = loginData || {};
    if(!userid) return;

    if(hasRoles([VIEW_ROLES.VIEW_BROKER])) {
      dispatch(CORE_TYPES.ACTIONS.OPEN_BROKER_NEW_TAB, { 
        brokerId: userid, 
        originalToken, 
      });
    } else if(hasRoles([VIEW_ROLES.VIEW_CUSTOMER])) {
      dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, { 
        customerId: userid, 
        originalToken, 
      });
    }
  },

  [CORE_TYPES.ACTIONS.OPEN_CURRENT_LOGGED_BROKER]({ getters, dispatch }) {
    const hasRoles = getters[CORE_TYPES.GETTERS.HAS_ROLES];

    if(!hasRoles([
      VIEW_ROLES.VIEW_BROKER_AS_BYPASS, 
      VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS,
      VIEW_ROLES.VIEW_BROKER, ])) {
      return;
    }

    let brokerId = getters[CORE_TYPES.GETTERS.GET_USER_ID];

    if (hasRoles([VIEW_ROLES.VIEW_BROKER_AS_BROKER, VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS ])) {
      brokerId = getters[CORE_TYPES.GETTERS.GET_LOGIN_USER_ID];
    }

    if (hasRoles([VIEW_ROLES.VIEW_CUSTOMER_AS_INTERN])) {
      brokerId = getters[CORE_TYPES.GETTERS.GET_LOGIN_DATA]?.maklernr
    }

    const fillUnterNr = true;
    dispatch(CORE_TYPES.ACTIONS.OPEN_BROKER_NEW_TAB, {
      brokerId,
      fillUnterNr,
      originalToken: getters[CORE_TYPES.GETTERS.ORIGINAL_TOKEN],
    });
  },

  [CORE_TYPES.ACTIONS.OPEN_CURRENT_LOGGED_INTERN]({ getters, dispatch }, payload) {
    const isIntern = getters[CORE_TYPES.GETTERS.IS_INTERN];
    if(!isIntern) {
      return;
    }

    let userId = getters[CORE_TYPES.GETTERS.GET_USER_ID];

    dispatch(CORE_TYPES.ACTIONS.OPEN_INTERN_NEW_TAB, {
      userId,
      originalToken: getters[CORE_TYPES.GETTERS.ORIGINAL_TOKEN],
      path: payload.path
    });
  },

  [CORE_TYPES.ACTIONS.UNLOCK_PIN]({ getters }, payload) {
    return axios.put(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/tempFile/unlock_pin?`+payload.id, payload, config);
  },

  async [CORE_TYPES.ACTIONS.CHECK_PIN]({ getters, commit }, payload) {
    const response = await axios.put(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/tempFile/check_pin`, payload, config);
    if (response.data.key && typeof response.data.value === 'number' ) {
      commit(CORE_TYPES.MUTATIONS.CHECK_PIN, response.data);
    }
  },
  [CORE_TYPES.ACTIONS.MSC_BUILD_INFO]({ getters, commit }) {
    return axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/fcconfig/getBuildInfo`, config).then(response => {
      if (response && response.data) {
        commit(CORE_TYPES.MUTATIONS.MSC_BUILD_INFO, response?.data);
      }
    });
  },

  [CORE_TYPES.ACTIONS.UPDATE_IS_INAKTIV]({ commit, rootState }, isInaktiv) {
    return new Promise((resolve, reject) => {
      axios.post(`${rootState.core.apiAddress}/brokerData/update-inaktiv?isInaktiv=${isInaktiv}`, {}, config).then(response => {
        commit(CORE_TYPES.MUTATIONS.UPDATE_IS_INAKTIV_SUCCESS, response.data ? true : false);
        resolve(response.data !== isInaktiv ? false : true);
      })
      .catch(error => {
        resolve(false);
      });
    });
  },
  [CORE_TYPES.ACTIONS.EXCEPTION_LOG]({ getters, commit }, id) {
    return axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/exceptionlog?id=${id}`, config).then(response => {
      if (response && response.data) {
        commit(CORE_TYPES.MUTATIONS.EXCEPTION_LOG, response?.data);
      }
    });
  },

  [CORE_TYPES.ACTIONS.ADD_GENERIC_SELECTION]({ commit }, list) {
    const uuid = uuidv4();
    commit(CORE_TYPES.MUTATIONS.ADD_GENERIC_SELECTION, {
      uuid, 
      list: [ ...list ], 
    });
    return uuid;
  },

}