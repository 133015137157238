var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", { attrs: { title: "Änderungslog" } }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("h3", [_vm._v("Änderungslog für " + _vm._s(_vm.title))]),
          _vm.rows.length
            ? _c("InputField", {
                attrs: { label: "Suchfeld" },
                model: {
                  value: _vm.descriptionSearch,
                  callback: function($$v) {
                    _vm.descriptionSearch = $$v
                  },
                  expression: "descriptionSearch"
                }
              })
            : _vm._e(),
          _vm.rows.length
            ? _c("Table", {
                attrs: {
                  headers: _vm.headers,
                  rows: _vm.rows,
                  rowId: "aenderung",
                  hidePagination: ""
                },
                on: { "click-aenderungTxt": _vm.openChangelogModal }
              })
            : _c("div", [_vm._v("Keine Daten vorhanden")])
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "changelogModal",
          attrs: {
            modalTitle: "Änderungslogeintrag",
            labelButtonCancel: "Zurück",
            showConfirmButton: false
          }
        },
        _vm._l(_vm.changelog, function(aenderung, index) {
          return _c("div", { key: index }, [
            _c("span", [_vm._v(_vm._s(aenderung))])
          ])
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }