import Vue from 'vue'
import MENU_TYPES from './types';
import { getInitialState } from './index';

export default {

  [MENU_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, { ...getInitialState(), });
  },

  [MENU_TYPES.MUTATIONS.SET_CONFIGURING_MENU](state, payload) {
    Vue.set(state, 'configuringMenu', payload);
  },

  [MENU_TYPES.MUTATIONS.SET_MENU_CONFIGURED](state, payload) {
    Vue.set(state, 'menuConfigured', payload);
  },

  [MENU_TYPES.MUTATIONS.SET_FLAT_MENU](state, payload) {
    Vue.set(state, 'flatMenu', [ ...payload, ]);
  },

  [MENU_TYPES.MUTATIONS.UPDATE_CURRENT_APP_NAVIGATION](state) {
    Vue.set(state, 'updateCurrentAppNavigation', new Date().getTime());
  },

  [MENU_TYPES.MUTATIONS.SET_PRIMARY_MENU_BADGE](state, payload) {
    Vue.set(state, 'primaryMenuBadge', { ...payload, });
  },

  [MENU_TYPES.MUTATIONS.SET_OPTIONS_MENU_BADGE](state, payload) {
    Vue.set(state, 'optionsMenuBadge', { ...payload, });
  },

  [MENU_TYPES.MUTATIONS.PREPEND_RECENT_MENU_OPENED](state, { userId, menu, }) {
    if(!userId) return;

    const recentMenuOpened = [ ...state.recentMenuOpened?.[userId] || [], ];

    const index = recentMenuOpened.findIndex(rm => rm.path === menu.path);
    // remove if exists to avoid duplication and keep it at the top
    if(index >= 0) {
      recentMenuOpened.splice(index, 1);
    }

    Vue.set(state.recentMenuOpened, userId, [ { ...menu, }, ...recentMenuOpened, ]);
  },

  [MENU_TYPES.MUTATIONS.SET_OPTIONS_MENU_PERMISSION](state, payload) {
    Vue.set(state, 'optionsMenuPermission', { ...payload || {}, });
  },

}
