var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "Freistellungsaufträge Übersicht",
          actions: _vm.headerActions
        },
        on: {
          "action-ADD": function($event) {
            return _vm.addItem()
          }
        }
      }),
      _c(
        "div",
        {
          staticClass: "box__container",
          attrs: { tid: "85cd7ff5-9e47-4216-95f3-567a2ba95b26" }
        },
        [
          !_vm.loading && _vm.tableRows.length
            ? _c("Table", {
                staticClass: "table-container",
                attrs: {
                  tableId: "949def7b-09b0-4bef-8283-9725f4ae1de2",
                  title: _vm.TABLE_TITLE,
                  headers: _vm.tableHeaders,
                  rows: _vm.tableRows,
                  rowsPerPage: 20,
                  count: _vm.resultCount,
                  mobileConfig: _vm.mobileConfig
                },
                on: {
                  "click-gesellschaft": _vm.bearbeiten,
                  action: function($event) {
                    return _vm.executeAction($event)
                  }
                }
              })
            : _vm.loading
            ? _c("GhostLoading", {
                attrs: { type: "table", title: _vm.TABLE_TITLE }
              })
            : _c("NoData", { attrs: { title: _vm.TABLE_TITLE } })
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "removeItemConfirmation",
          attrs: {
            modalTitle: "Freistellungsauftrag löschen",
            labelButtonConfirm: "Löschen"
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.removeItem()
            }
          }
        },
        [
          _vm._v(
            " Soll der Freistellungsauftrag der Gesellschaft " +
              _vm._s(_vm.selectedItem.gesellschaft) +
              " " +
              _vm._s(_vm.selectedItem.gueltigAb) +
              " in Höhe von " +
              _vm._s(Number(_vm.selectedItem.betrag).toLocaleString()) +
              " wirklich gelöscht werden. "
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }