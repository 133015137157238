import dayjs from "dayjs";
import store from '@/store';
import CORE_TYPES from '@/store/core/types';
import { BROKER_STRUCTURE_ROLES, } from '@/router/roles';

export function searchMetadata(isFA, showIsinPanels, showAelterAls) {
  return ([
    {
      type: 'group',
      key: 'allgemein',
      label: 'Allgemein',
      menuItems: [
        {
          type: 'text',
          label: 'Name',
          key: 'lastName',
          dataType: 'string',
        },
        {
          type: 'text',
          label: 'Kundennummer',
          key: 'customerId',
          dataType: 'number',
          inputmode: 'numeric',
        },
        {
          type: 'text',
          label: 'Vorname',
          key: 'firstName',
          dataType: 'string',
        },
        {
          type: 'text',
          label: 'Vermittlernummer',
          key: 'brokerId',
          inputmode: 'numeric',
        },
        {
          type: 'combobox',
          label: 'Beratung / Vermittlung',
          key: 'beratungVermittler',
          comboOptions: []
        },
        {
          type: 'combobox',
          label: 'Betreuer',
          key: 'kundenBetreuer',
          comboOptions: [],
          emptyDenied: true,
        },
        {
          type: 'default',
          label: 'inkl. Kunden der Struktur',
          key: 'structure',
        },
        {
          type: 'default',
          label: 'inkl. Kunden ohne aktiven Vertrag',
          key: 'inklOhneVertrag',
        },
        {
          type: 'default',
          label: 'nur Kunden ohne aktiven Vertrag (Neukunden)',
          key: 'nurOhneVertrag',
        },
        {
          type: 'default',
          label: 'bekannte /verwandte Personen durchsuchen',
          key: 'includeRelatedPerson',
        },
        {
          type: 'default',
          label: 'Kunde ist aktiv',
          key: 'activeOnly',
        },
        {
          type: 'default',
          label: 'Kunde ist inaktiv',
          key: 'inactiveOnly',
        },
        {
          type: 'default',
          label: 'mit Servicegebühr',
          key: 'paramServicegebuehr',
          deniable: true,
          invertedWithParameter: true,
        },
        {
          type: 'default',
          label: 'mit Zubringercourtage',
          key: 'paramZubringercourtage',
          deniable: true,
          invertedWithParameter: true,
        },
        {
          type: 'default',
          label: 'mit aktuellem Quartalsbericht',
          key: 'quartalBericht',
          deniable: true,
          invertedWithParameter: true,
        },
        {
          type: 'default',
          label: 'mit Rahmenvereinbarung',
          key: 'mitRahmen',
          deniable: true,
          invertedWithParameter: true,
        },
        {
          type: 'default',
          label: 'Gruppenmitglied',
          key: 'gruppenMitglid',
          deniable: true,
          invertedWithParameter: true,
        },
        {
          type: 'default',
          label: 'Kundengruppe',
          key: 'gruppe',
          deniable: true,
          invertedWithParameter: true,
        },
        {
          type: 'default',
          label: 'mit Anlegerprofil',
          key: 'paramMitAP',
          deniable: true,
          invertedWithParameter: true,
        },
        {
          type: 'default',
          label: 'mit Anlegerprofil signiert',
          key: 'paramErlaubeAPSigniert',
          deniable: true,
          invertedWithParameter: true,
        },
        {
          type: 'default',
          label: 'aktuelles Anlegerprofil ist unsigniert',
          key: 'paramErlaubeAPUnsigniert',
          invertedWithParameter: true,
        },
        {
          type: 'default',
          label: 'mit Versand Vermögensübersicht',
          key: 'paramMitVersandVU',
          deniable: true,
          invertedWithParameter: true,
        },
        ...(showAelterAls ? [
          {
            type: 'text',
            label: 'mit Anlegerprofil älter als (Monate)',
            key: 'paramAPAlterMonate',
            dataType: 'string',
            deniable: true,
            invertedWithParameter: true,
          }
        ] : []),
        {
          type: 'dateRange',
          label: 'Anlagedatum Kunde',
          key: 'dateCreated',
          emptyDenied: true,
        },
        {
          type: 'combobox',
          label: 'Informationsblatt',
          key: 'informationsblatt',
          emptyDenied: true,
          comboOptions: [
            {
              label: 'egal',
              key: 'VALUE_EGAL'
            },
            {
              label: 'mit',
              key: 'VALUE_MIT_IB'
            },
            {
              label: 'ohne',
              key: 'VALUE_OHNE_IB'
            },
          ]
        },
        {
          type: 'combobox',
          label: 'Typ',
          key: 'kundentyp',
          emptyDenied: true,
          comboOptions: [
            {
              label: 'Einzelkunde',
              value: 'EIZELKUNDE'
            },
            {
              label: 'Gemeinschaftskunde',
              value: 'GEMEINSCHAFTSKUNDE'
            },
            {
              label: 'Firma',
              value: 'FIRMA'
            },
            {
              label: 'Kundengruppe',
              value: 'KUNDENGRUPPE'
            },
          ]
        },
      ]
    },
    {
      type: 'group',
      key: 'pers_daten',
      label: 'Pers. Daten',
      menuItems: [
        {
          type: 'combobox',
          label: 'Kategorie',
          key: 'kundenKategorie',
          comboOptions: []
        },
        {
          type: 'combobox',
          label: 'Anrede',
          key: 'anrede',
          comboOptions: []
        },
        {
          type: 'combobox',
          label: 'Anredeform',
          key: 'anredePronomen',
          comboOptions: []
        },
        {
          type: 'combobox',
          label: 'Kind',
          key: 'kind',
          comboOptions: []
        },
        {
          type: 'combobox',
          label: 'Sepa',
          key: 'sepa',
          comboOptions: []
        },
        {
          type: 'combobox',
          label: 'Tin',
          key: 'tin',
          comboOptions: []
        },
        {
          type: 'text',
          label: 'Titel',
          key: 'titel',
        },
        {
          type: 'combobox',
          label: 'Arbeitsverhältnis',
          key: 'arbeitsverhaeltnis',
          comboOptions: []
        },
        {
          type: 'text',
          label: 'Beruf',
          key: 'beruf',
          emptyDenied: true
        },
        {
          type: 'text',
          label: 'Branche',
          key: 'branche',
          emptyDenied: true
        },
        {
          type: 'default',
          label: 'Legitimation fehlt',
          key: 'legiFehlt'
        },
        {
          type: 'default',
          label: 'Legitimation abgelaufen',
          key: 'legiAbgelaufen'
        },
        {
          type: 'default',
          label: 'Legitimation spätestens in drei Monaten abgelaufen',
          key: 'legiBaldAbgelaufen',
          emptyDenied: true
        },
        {
          type: 'default',
          label: 'Legitimation ohne Ausweiskopie',
          key: 'legiOhneAusweiskopie'
        },
        {
          type: 'text',
          label: 'Firmenzusatz',
          key: 'firmenzusatz',
          emptyDenied: true
        },
        {
          type: 'combobox',
          label: 'Familienstand',
          key: 'familienstand',
          comboOptions: [],
          emptyDenied: true
        },
        {
          type: 'combobox',
          label: 'Güterstand',
          key: 'gueterstand',
          comboOptions: [],
          emptyDenied: true
        },
        {
          type: 'numberRange',
          label: 'Alter',
          key: 'alter',
          emptyDenied: true,
          comboOptions: [{label: 'precision', value: 0 }],
          /*            comboOptions: [
                      {
                        type: 'text',
                        label: 'min.',
                        key: 'alterMin',
                      },
                      {
                        type: 'text',
                        label: 'max.',
                        key: 'alterMax',
                      },
                    ]  */
        },
        {
          type: 'numberRange',
          label: 'mtl. Bruttogehalt',
          key: 'gehalt',
          emptyDenied: true,
          /*           comboOptions: [
                      {
                        type: 'text',
                        label: 'von',
                        key: 'gehaltMin',
                      },
                      {
                        type: 'text',
                        label: 'bis',
                        key: 'gehaltMax',
                      },
                    ] */
        },
        // {
        //   type: 'text',
        //   label: 'Alter min.',
        //   key: 'alterMin',
        //   emptyDenied: true
        // },
        // {
        //   type: 'text',
        //   label: 'Alter max.',
        //   key: 'alterMax',
        //   emptyDenied: true
        // },
        // {
        //   type: 'text',
        //   label: 'mtl. Bruttogehalt von',
        //   key: 'gehaltMin',
        //   emptyDenied: true
        // },
        // {
        //   type: 'text',
        //   label: 'mtl. Bruttogehalt bis',
        //   key: 'gehaltMax',
        //   emptyDenied: true
        // }
      ]
    },
    {
      type: 'group',
      key: 'Adresse_Geburtsdatum',
      label: 'Adresse u. Geburtsdatum',
      menuItems: [
        {
          type: 'text',
          label: 'Straße',
          key: 'strasse',
          emptyDenied: true,
        },
        {
          type: 'text',
          label: 'PLZ',
          key: 'plz',
          emptyDenied: true,
        },
        {
          type: 'textRange',
          label: 'PLZ Bereich',
          key: 'plzVonBis',
          emptyDenied: true,
          /*           comboOptions: [
                      {
                        type: 'text',
                        label: 'PLZ von',
                        key: 'plzVon',
                      },
                      {
                        type: 'text',
                        label: 'PLZ bis',
                        key: 'plzBis',
                      },
                    ] */
        },
        {
          type: 'text',
          label: 'Ort',
          key: 'ort',
          emptyDenied: true,
        },
        {
          type: 'splitedDatePicker',
          label: 'Geburtsdatum',
          key: 'dayOfBirth',
          emptyDenied: true,
        },
        {
          type: 'dateRange',
          label: 'Geburtstage zwischen',
          key: 'dayOfBirthVonBis',
          emptyDenied: true,
        },
      ],
    },
    {
    type: 'group',
    key: 'Kundenkontakt',
    label: 'Kundenkontakt',
    menuItems:[
      {
        type: 'text',
        label: 'E-Mail-Adresse',
        key: 'email',
        emptyDenied: true,
      },
      {
        type: 'combobox',
        label: 'E-Mails filtern nach',
        key: 'withEmail',
        emptyDenied: true,
        comboOptions: [
          {
            label: 'egal',
            key: 'egal'
          },
          {
            label: 'mit E-Mail-Adresse',
            key: 'withEmail'
          },
          {
            label: 'ohne E-Mail-Adresse',
            key: 'ohneEmail'
          },
        ]
      },
      {
        type: 'tel',
        label: 'Tel./Mobil.',
        key: 'phone',
        emptyDenied: true,
      },
      {
        type: 'combobox',
        label: 'mit Tel./Mobil.',
        key: 'withPhone',
        emptyDenied: true,
        comboOptions: [
          {
            label: 'egal',
            key: 'egal'
          },
          {
            label: 'mit E-Mail-Adresse',
            key: 'withEmail'
          },
          {
            label: 'ohne E-Mail-Adresse',
            key: 'ohneEmail'
          },
        ]
      },
      {
        type: 'combobox',
        label: 'mit Mobil',
        key: 'withMobilePhone',
        emptyDenied: true,
        comboOptions: [
          {
            label: 'egal',
            key: 'egal'
          },
          {
            label: 'nur Kunden mit Mobil',
            key: 'valueMitMobil'
          },
          {
            label: 'nur Kunde ohne Moible',
            key: 'valueOhneMobil'
          },
        ]
      },      
      {
        type: 'combobox',
        label: 'E-Mail',
        key: 'kontaktErlaubtMail',
        emptyDenied: true,
        comboOptions: [
          {
            label: 'egal',
            key: 'egal'
          },
          {
            label: 'mit E-Mail-Adresse',
            key: 'withEmail'
          },
          {
            label: 'ohne E-Mail-Adresse',
            key: 'ohneEmail'
          },
        ]
      },
      {
        type: 'combobox',
        label: 'Telefon',
        key: 'kontaktErlaubtTel',
        emptyDenied: true,
        comboOptions: [
          {
            label: 'egal',
            key: 'egal'
          },
          {
            label: 'mit E-Mail-Adresse',
            key: 'withEmail'
          },
          {
            label: 'ohne E-Mail-Adresse',
            key: 'ohneEmail'
          },
        ]
      },
      {
        type: 'combobox',
        label: 'Post',
        key: 'kontaktErlaubtPost',
        emptyDenied: true,
        comboOptions: [
          {
            label: 'egal',
            key: 'egal'
          },
          {
            label: 'mit E-Mail-Adresse',
            key: 'withEmail'
          },
          {
            label: 'ohne E-Mail-Adresse',
            key: 'ohneEmail'
          },
        ]
      },
      {
        type: 'combobox',
        label: 'Fax',
        key: 'kontaktErlaubtFax',
        emptyDenied: true,
        comboOptions: [
          {
            label: 'egal',
            key: 'egal'
          },
          {
            label: 'mit E-Mail-Adresse',
            key: 'withEmail'
          },
          {
            label: 'ohne E-Mail-Adresse',
            key: 'ohneEmail'
          },
        ]
      },
    ],
  },
    searchMetadataDepot(isFA, showIsinPanels),
    searchMetadataVersicherungen(isFA),
    searchMetadataVertragssparte(),
    {
      type: 'group',
      key: 'Zusätzliche_Kriterien',
      label: 'Zusätzliche Kriterien',
      menuItems: [
        {
          type: 'text',
          label: 'Kundenreferenznr.',
          key: 'kundenreferenznr',
          emptyDenied: true,
        },
        {
          type: 'combobox',
          label: 'Hobbies',
          key: 'hobbies',
          emptyDenied: true,
          comboOptions: [],
          
        },
        {
          type: 'combobox',
          label: 'Vorlieben',
          key: 'vorlieben',
          emptyDenied: true,
          comboOptions: []
        },
        {
          type: 'combobox',
          label: 'Zus. Informationen',
          key: 'zusInfosCombo',
          deniable: true,
          emptyDenied: true,
        },
        {
          type: 'text',
          label: 'Zus. Informationen Freitext',
          key: 'zusInformationenFreitext',
          deniable: true,
          allowDuplicated: true,
          emptyDenied: true,
          maxOccurences: 2,
        },        
        {
          type: 'default',
          label: 'Vermögensverwaltungsdepot',
          key: 'vvdepot',
          deniable: true,
          emptyDenied: true,
        },
        {
          type: 'default',
          label: 'verpfändete Depots',
          key: 'verpfaendete',
          deniable: true,
          emptyDenied: true,
        },
        {
          type: 'default',
          label: 'Fondsshopkunden',
          key: 'fondsshopkunden',
          deniable: true,
          emptyDenied: true,
        },
        {
          type: 'default',
          label: 'Minderjährigendepots',
          key: 'minderjärigendepots',
          deniable: true,
          emptyDenied: true,
        },
        {
          type: 'default',
          label: 'Garantiedepots',
          key: 'garantiedepots',
          deniable: true,
          emptyDenied: true,
        },
        {
          type: 'text',
          label: 'Gerissene Limits für Fonds',
          key: 'limitsFonds',
          emptyDenied: true,
        },
        {
          type: 'datepicker',
          label: 'Gerissene Limits für Depotposition',
          key: 'depotpositionDatum',
          emptyDenied: true,
        },

        {
          type: 'datepicker',
          label: 'Gerissene Limits für Depot',
          key: 'depotDatum',
          emptyDenied: true,
        },
        {
          type: 'datepicker',
          label: 'Gerissene Limits für Risikoklassen',
          key: 'risikoklassenDatum',
          emptyDenied: true,
        },
        {
          type: 'combobox',
          label: 'Versand Infobrief "GUT ZU WISSEN"',
          key: 'versandInfobrief',
          emptyDenied: true,
          comboOptions: []
        },
        {
          type: 'default',
          label: 'PEP',
          key: 'pep',
          emptyDenied: true,
        },
        {
          type: 'default',
          label: 'FATCA',
          key: 'fatca',
          emptyDenied: true,
        },

      ],
    },
    {
      type: 'group',
      key: 'Zusätzliche_Kriterien_2',
      label: 'Zusätzliche Kriterien 2',
      menuItems: [
        {
          type: 'combobox',
          label: 'Anlageart',
          key: 'anlageart',
          emptyDenied: true,
          comboOptions: []
        },
        {
          type: 'combobox',
          label: 'Risikoeinstufung(GWG)',
          key: 'risikoeinstufung',
          emptyDenied: true,
          comboOptions: []
        },
        {
          type: 'default',
          label: 'nur mit Honorar-/Servicevertrag',
          key: 'mitHonorar',
          emptyDenied: true,
        },
        {
          type: 'default',
          label: 'DWS Riestervertrag ohne Zulage',
          key: 'dwsRiestervertragOhneZulage',
          emptyDenied: true,
        },
        {
          type: 'default',
          label: 'Quartalsbericht ohne Versand',
          key: 'quartalsberichtOhneVersand',
          emptyDenied: true,
        },
        {
          type: 'default',
          label: 'mit Riestervertrag',
          key: 'mitRiestervertrag',
          emptyDenied: true,
        },
        {
          type: 'default',
          label: 'ohne Riestervertrag',
          key: 'ohneRiestervertrag',
          emptyDenied: true,
        },
        {
          type: 'default',
          label: 'Kunde nutzt eine App',
          key: 'appUser',
          emptyDenied: true,
        },
        {
          type: 'default',
          label: 'Kunde nutzt das KSC',
          key: 'kscUser',
          emptyDenied: true,
        },
        {
          type: 'default',
          label: 'Kunde nutzt keine App',
          key: 'notAppUser',
          emptyDenied: true,
        },
        {
          type: 'default',
          label: 'Kunde nutzt das KSC nicht',
          key: 'notKscUser',
          emptyDenied: true,
        },
      ],
    },
    {
      type: 'group',
      key: 'Datenschutz',
      label: 'Datenschutz',
      menuItems: [
        {
          type: 'default',
          label: 'Datenschutz E-Mail erhalten',
          key: 'datenschutzEmail',
          emptyDenied: true,
        },
        {
          type: 'default',
          label: 'Datenschutz E-Mail nicht erhalten',
          key: 'noDatenschutzEmail',
          emptyDenied: true,
        },
        {
          type: 'default',
          label: 'Datenschutzerklärung aus Anlegerprofil / Depoteröffnung akzeptiert',
          key: 'datenschutzerklaerungAlt',
          emptyDenied: true,
        },
        {
          type: 'default',
          label: 'Datenschutzerklärung noch nicht entschieden',
          key: 'datenschutzerklaerungNichtEntschieden',
          emptyDenied: true,
        },
        {
          type: 'default',
          label: 'Datenschutzerklärung abgelehnt',
          key: 'datenschutzAbgelehnt',
          emptyDenied: true,
        },
        {
          type: 'default',
          label: 'Datenschutzerklärung akzeptiert',
          key: 'datenschutzAkzeptiert',
          emptyDenied: true,
        },
      ],
    },
  ]).filter(group => group != null);
}

function searchMetadataDepot(isFA, showIsinPanels) {
  const hasRoles = store.getters[CORE_TYPES.GETTERS.HAS_ROLES];
  if(!hasRoles([BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS])) {
    return null;
  }

  return {
    type: 'group',
    key: 'Depot',
    label: 'Depot',
    menuItems: [
      {
        type: 'combobox',
        label: 'Gesellschaft',
        key: 'gesellschaftDepot',
        emptyDenied: true,
        comboOptions: []
      },
      {
        type: 'combobox',
        label: 'Lagerstelle',
        key: 'lagerstelleDepot',
        emptyDenied: true,
        comboOptions: []
      },
      {
        type: 'combobox',
        label: 'Wertpapier',
        key: 'wertpapier',
        emptyDenied: true,
        comboOptions: []
      },
      {
        type: 'text',
        label: 'Kundennr Gesellschaft',
        key: 'kundnnrGesellNr',
        emptyDenied: true,
      },
      {
        type: 'combobox',
        label: 'Freistellungsaufträge',
        key: 'freistellungAuftrag',
        emptyDenied: true,
        comboOptions: [
          {
            label: 'egal',
            key: 'VALUE_FREISTELLUNG_AUFTRAG_EGAL'
          },
          {
            label: 'mit',
            key: 'VALUE_FREISTELLUNG_AUFTRAG_MIT'
          },
          {
            label: 'ohne',
            key: 'VALUE_FREISTELLUNG_AUFTRAG_OHNE'
          },
          {
            label: 'verfügbarer Freibetrag im laufenden Jahr',
            key: 'VALUE_FREISTELLUNG_AUFTRAG_VERFUEGBAR_AKT_J'
          },
  
          
        ]
      },
      {
        type: 'combobox',
        label: 'Kunden anzeigen',
        key: 'mitOhneWertPapier',
        emptyDenied: true,
        comboOptions: []
      },
      {
        type: 'text',
        label: 'ISIN/WKN',
        key: 'isin',
        emptyDenied: true,
      },
      {
        type: 'combobox',
        label: 'Sparpläne',
        key: 'sparplan',
        comboOptions: [],
        emptyDenied: true,
      },
      {
        type: 'combobox',
        label: 'Entnahmepläne',
        key: 'entnahmeplan',
        comboOptions: [],
        emptyDenied: true,
      },
      {
        type: 'default',
        label: 'mit Eigenanlagen',
        key: 'mitEigenanlagen',
        comboOptions: [],
        emptyDenied: true,
      },
      {
        type: 'default',
        label: 'mit Fremdanlagen',
        key: 'mitFremdanlagen',
        emptyDenied: true,
      },
      {
        type: 'default',
        label: 'ETFs',
        key: 'etf',
        emptyDenied: true,
      },
      {
        type: 'default',
        label: 'inkl. Positionen mit 0 Anteilen',
        key: 'ohneAnteile',
        emptyDenied: true,
      },
      {
        type: 'combobox',
        label: 'Schwerpunkt',
        key: 'schwerpunkt',
        comboOptions: [],
        emptyDenied: true,
      },
      {
        type: 'text',
        label: 'Depotnummer',
        key: 'depotNummer',
        emptyDenied: true,
      },
      {
        type: 'text',
        label: 'Portfolionr.',
        key: 'portfolionr',
        emptyDenied: true,
      },
      {
        type: 'combobox',
        label: 'Region',
        key: 'region',
        comboOptions: [],
        emptyDenied: true,
      },
      {
        type: 'numberRange',
        label: 'Gesamt Depotwert',
        key: 'gesamt',
        emptyDenied: true,
        /*           comboOptions: [
                    {
                      type: 'text',
                      label: 'min.',
                      key: 'depotMin0',
                    },
                    {
                      type: 'text',
                      label: 'max.',
                      key: 'depotMax0',
                    },
                  ] */
      },
      {
        type: 'numberRange',
        label: 'Depotwert',
        key: 'depotwert',
        emptyDenied: true,
        /*           comboOptions: [
                    {
                      type: 'text',
                      label: 'min.',
                      key: 'depotMin1',
                    },
                    {
                      type: 'text',
                      label: 'max.',
                      key: 'depotMax1',
                    },
                  ] */
      },
      {
        type: 'numberRange',
        label: 'Beteiligungen',
        key: 'Beteiligungen',
        emptyDenied: true,
        /*           comboOptions: [
                    {
                      type: 'text',
                      label: 'min.',
                      key: 'depotMin2',
                    },
                    {
                      type: 'text',
                      label: 'max.',
                      key: 'depotMax2',
                    },
                  ] */
      },
      {
        type: 'numberRange',
        label: 'Bankkonto',
        key: 'Bankkonto',
        emptyDenied: true,
        /*           comboOptions: [
                    {
                      type: 'text',
                      label: 'min.',
                      key: 'depotMin3',
                    },
                    {
                      type: 'text',
                      label: 'max.',
                      key: 'depotMax3',
                    },
                  ] */
      },
      {
        type: 'default',
        label: 'Standarddepot',
        key: 'standarddepot',
      },
      {
        type: 'default',
        label: 'Fondsshop',
        key: 'fondsshop',
      },
      {
        type: 'default',
        label: 'DWS Riester',
        key: 'dwsRiester',
      },
      {
        type: 'combobox',
        label: 'VV WK',
        key: 'vvWK',
        allowDuplicated: true,
        comboOptions: []
      },
      ...(showIsinPanels ? [
        {
          type: 'combobox',
          label: 'Basisfilter für ISIN/WKN',
          key: 'basisfllter',
          comboOptions: [
            {
              label: 'alle Bedingungen erfüllt',
              value: 'alleBedingungenErfullt',
            },
            {
              label: 'nur eine Bedingung muss erfüllt sein',
              value: 'nurEineBedingung',
            },
          ]
        },
        {
          type: 'text',
          label: '(Basisfilter für ISIN/WKN) Nicht (1)',
          key: 'basisfllter_nicht1',
        },
        {
          type: 'text',
          label: '(Basisfilter für ISIN/WKN) Nicht (2)',
          key: 'basisfllter_nicht2',
        },
        {
          type: 'combobox',
          label: 'Einschränkungsfilter für ISIN/WKN',
          key: 'einschrankungsfilter',
          comboOptions: [
            {
              label: 'alle Bedingungen erfüllt',
              value: 'alleBedingungenErfullt',
            },
            {
              label: 'nur eine Bedingung muss erfüllt sein',
              value: 'nurEineBedingung',
            },
          ]
        },
        {
          type: 'text',
          label: '(Einschränkungsfilter für ISIN/WKN) Nicht (1)',
          key: 'einschrankungsfilter_nicht1',
        },
        {
          type: 'text',
          label: '(Einschränkungsfilter für ISIN/WKN) Nicht (2)',
          key: 'einschrankungsfilter_nicht2',
        },
      ] : []),
      ...(!isFA ? [
        {
          type: 'doubleCombo',
          label: 'Zustimmung',
          key: 'zustimmung',
          comboOptions1: [],
          comboOptions2: [],
        }
      ] : []),
      {type: 'default', key: 'mitGesVertreter', deniable: true, label: 'mit ges. Vertreter'},
      {type: 'default', key: 'mitVollmacht',    deniable: true, label: 'mit ' + (isFA ? 'Zeichnungsberechtigten' : 'Vollmacht')},
      {type: 'default', key: 'mitZusatzDi',     deniable: true, label: 'mit zus. Depotinhabern'},
    ],
  };
}

function searchMetadataVersicherungen(isFA) {
  const hasRoles = store.getters[CORE_TYPES.GETTERS.HAS_ROLES];
  if(!hasRoles([BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN])) {
    return null;
  }

  return {
    type: 'group',
    key: 'Versicherungen',
    label: 'Versicherungen',
    menuItems: [
      {
        type: 'text',
        label: 'Versicherungsnummer',
        key: 'versNummer',
        emptyDenied: true,
      },
      {
        type: 'text',
        label: 'KFZ-Kennzeichen',
        key: 'kfz',
        emptyDenied: true,
      },
      {
        type: 'dateRange',
        label: 'Vertragsbeginn',
        key: 'Vertragsbegin',
        emptyDenied: true,
        /*           comboOptions: [
                    {
                      type: 'date',
                      label: 'ab',
                      key: 'vbeginAb',
                    },
                    {
                      type: 'date',
                      label: 'bis',
                      key: 'vBeginBis',
                    },
                  ] */
      },

      {
        type: 'dateRange',
        label: 'Vertragsende',
        key: 'Vertragsende',
        emptyDenied: true,
        /*           comboOptions: [
                    {
                      type: 'date',
                      label: 'ab',
                      key: 'vEndeAb',
                    },
                    {
                      type: 'date',
                      label: 'bis',
                      key: 'vEndeBis',
                    },
                  ] */
      },
      {
        type: 'numberRange',
        label: 'Jahresbeitrag',
        key: 'Jahresbeitrag',
        emptyDenied: true,
        /*           comboOptions: [
                    {
                      type: 'text',
                      label: 'ab',
                      key: 'vJahresBetragAb',
                    },
                    {
                      type: 'text',
                      label: 'bis',
                      key: 'vJahresBetragBis',
                    },
                  ] */
      },
      {
        type: 'dateRange',
        label: 'Kündbar zum',
        key: 'Kundbarzum',
        emptyDenied: true,
        /*           comboOptions: [
                    {
                      type: 'text',
                      label: 'ab',
                      key: 'vFaeligkeitAb',
                    },
                    {
                      type: 'text',
                      label: 'bis',
                      key: 'vFaeligkeitBis',
                    },
                  ] */
      },
      {
        type: 'combobox',
        label: isFA ? 'Maklervollmacht/-vertrag' : 'Maklerauftrag',
        key: 'maklerAuftrag',
        emptyDenied: true,
        comboOptions: [
          {
            label: 'mit',
            key: 'mit'
          },
          {
            label: 'ohne',
            key: 'ohne'
          },
        ]
      },
      {
        type: 'combobox',
        label: 'Vertragsstatus',
        key: 'vertragStatus',
        emptyDenied: true,
        comboOptions: []
      },
      {
        type: 'combobox',
        label: 'Schadensstatus',
        key: 'schadenStatus',
        emptyDenied: true,
        comboOptions: []
      },
      {
        type: 'combobox',
        label: 'RV-Status',
        key: 'rvStatus',
        emptyDenied: true,
        comboOptions: []
      },
      {
        type: 'combobox',
        label: 'KV-Status',
        key: 'kvStatus',
        emptyDenied: true,
        comboOptions: []
      },
      {
        type: 'combobox',
        label: 'ohne Sparte',
        key: 'ohneSparte',
        emptyDenied: true,
        comboOptions: []
      },
      {
        type: 'combobox',
        label: 'ohne Gesellschaft',
        key: 'ohneGesell',
        emptyDenied: true,
        comboOptions: []
      },
      {
        type: 'combobox',
        label: 'Gesellschaft',
        key: 'vertragGesellschaft',
        emptyDenied: true,
        allowMultipleSearch:true,
        comboOptions: [],
      },
      {
        type: 'combobox',
        label: 'Fremdgesellschaft',
        key: 'fremdGesellschaft',
        emptyDenied: true,
        allowMultipleSearch:true,
        comboOptions: [],
      },
      {
        type: 'default',
        label: 'nur Fremdbestand',
        key: 'nurFremdbestand',
        emptyDenied: true,
      },
      {
        type: 'default',
        label: 'nur Eigenbestand',
        key: 'nurEigenbestand',
        emptyDenied: true,
      },        
    ],
  };
}

function searchMetadataVertragssparte() {
  const hasRoles = store.getters[CORE_TYPES.GETTERS.HAS_ROLES];
  if(!hasRoles([BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN])) {
    return null;
  }

  return {
    type: 'group',
    key: 'versSparten',
    label: 'Vertragssparte',
    allowMultipleSearch:true,
    menuItems: [
      // {
      //   type: 'combobox',
      //   label: 'Vorsorge',
      //   key: 'VORSORGE',
      //   comboOptions: []
      // },
      // {
      //   type: 'combobox',
      //   label: 'Gesundheit',
      //   key: 'GESUNDHEIT',
      //   comboOptions: []
      // },
      // {
      //   type: 'combobox',
      //   label: 'Betrieblich',
      //   key: 'BETRIEBLICH',
      //   comboOptions: []
      // },
      // {
      //   type: 'combobox',
      //   label: 'Sachversicherungen',
      //   key: 'SACHEN',
      //   comboOptions: []
      // },
      // {
      //   type: 'combobox',
      //   label: 'Sonstige',
      //   key: 'SONSTIG',
      //   comboOptions: []
      // },
    ]
  };
}

export function personenListe(customerRows, isFA) {
  const vertragStatusLabel = function(status) {
    if (isFA) {
      switch(status){
        case 'success':
          return 'vorhanden';
        case 'danger':
        case 'warning':
          return 'nicht hinterlegt';
        default: 
          return null;
      }              
    } else {
      switch(status){
        case 'success':
          return 'hinterlegt, gescannt';
        case 'warning':
          return 'hinterlegt, nicht gescannt';
        case 'danger':
          return 'nicht hinterlegt';
        default: 
          return null;
      }            
    }
  }
  const rows = [];
  customerRows.forEach(item => {
    const address = item.person && item.person.personalDataAddress
    const birth = item.person && item.person.personalDataBirth

    const tel = item.person && item.person.contacts && item.person.contacts.find(c => !!c.typeId.match(new RegExp(/^(Telefon tagsüber|Telefon){1}$/)) && c.default == true)
    const telPrivat = item.person && item.person.contacts && item.person.contacts.find(c => !!c.typeId.match(new RegExp(/Privat$/)) && c.default == true)
    const fax = item.person && item.person.contacts && item.person.contacts.find(c => c.typeId.includes('Fax') && c.default == true)
    const mobile = item.person && item.person.contacts && item.person.contacts.find(c => c.typeId.includes('Mobil') && c.default == true)
    const email = item.person && item.person.contacts && item.person.contacts.find(c => c.typeId.includes('E-Mail') && c.default == true)

    const customerName = address && (address.lastName || address.firstName) ? `${address.lastName || ''}, ${address.firstName || ''}` : '';
    
    let customerType = null;
    if (item.beratungVermittler) {
        customerType = {
            label: '',
            type: item.beratungVermittler?.value == 'EXECUTION_ONLY' ? 'danger' : 'info',
        };
        switch (item.beratungVermittler.value) {
            case 'VERMITTLUNG':
                customerType.label = 'Vermittlung';
                break;
            case 'BERATUNG':
                customerType.label = 'Beratung';
                break;
            case 'EXECUTION_ONLY':
                customerType.label = 'Execution Only';
                break;
            default:
                customerType = null;
        }
    }
    rows.push({
        id: item.customerID,
        name: customerName,
        active: {
            label: item.active ? 'aktiv' : 'inaktiv',
            type: item.active ? 'info' : 'danger',
        },
        customerStatus: {
            label: item.haveAP ? 'vorhanden' : (item.beratungVermittler?.value == 'EXECUTION_ONLY' ? 'nicht notwendig' : 'nicht vorhanden'),
            type: item.haveAP ? 'info' : 'danger',
        },
        customerID: item.customerID,
        Telefon: tel && tel.value || '',
        TelefonPrivat: telPrivat?.value || '',
        Fax: fax?.value || '',
        Mobil: mobile && mobile.value || '',
        email: email && email.value || '',
        title: address && address.title,
        adresse: address && (address.street || address.zip || address.city) ? `${address.street || ''}, ${address.zip || ''} ${address.city || ''}` : '',
        openFunds: item.openFunds,
        dayOfBirth: birth &&birth.dayOfBirth  != null ?(typeof(birth.dayOfBirth)=='number' ?  dayjs(birth.dayOfBirth).format("DD.MM.YYYY") :birth.dayOfBirth)  :birth.dayOfBirth,
        customerType,
        superKunde: address && address.title === 'Firma' ? 'Firma' : item.superKundeString,
        category: item.category,
        maklernr: item.maklernr,
        maklerName: item.maklerName,
        insurances: item.insurances,
        vertragStatus: {
            label: vertragStatusLabel(item.versicherungMaklervertragStatus),
            type: item.versicherungMaklervertragStatus,
        },                    
        closedFunds: item.closedFunds,
        bankAccounts: item.bankAccounts,
        street: address && address.street,
        zip: address && address.zip,
        city: address && address.city,
        briefAnrede: item.briefAnrede,
        kredite: item.credits,
        tin: item.tin,

        data: item,
    })

    item.additionalPersons.forEach((addItem, index) => {
        const address = addItem.personalDataAddress
        const birth = addItem.personalDataBirth

        const tel = addItem.contacts && addItem.contacts.find(c => !!c.typeId.match(new RegExp(/^(Telefon tagsüber|Telefon){1}$/)) && c.default == true)
        const telPrivat = addItem.contacts && addItem.contacts.find(c => !!c.typeId.match(new RegExp(/Privat$/)) && c.default == true)
        const fax = addItem.contacts && addItem.contacts.find(c => c.typeId.includes('Fax') && c.default == true)
        const mobile = addItem.contacts && addItem.contacts.find(c => c.typeId.includes('Mobil') && c.default == true)
        const email = addItem.contacts && addItem.contacts.find(c => c.typeId.includes('E-Mail') && c.default == true)

        rows.push({
            id: item.customerID + " - " + index,
            name: address && (address.lastName || address.firstName) ? `${address.lastName || ''}, ${address.firstName || ''}` : '',
            Telefon: tel && tel.value || '',
            TelefonPrivat: telPrivat?.value || '',
            Fax: fax?.value || '',
            Mobil: mobile && mobile.value || '',
            email: email && email.value || '',
            title: address && address.title,
            adresse: address && (address.street || address.zip || address.city) ? `${address.street || ''}, ${address.zip || ''} ${address.city || ''}` : '',
            dayOfBirth: birth &&birth.dayOfBirth  != null ?(typeof(birth.dayOfBirth)=='number' ?  dayjs(birth.dayOfBirth).format("DD.MM.YYYY") :birth.dayOfBirth)  :birth.dayOfBirth,
            street: address && address.street,
            zip: address && address.zip,
            city: address && address.city,

            customerRelationship: {
                customerID: item.customerID,
                name: customerName,
            },
            data: {},
        });
    });
  })
  return rows;
}
