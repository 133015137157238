<template>
    <div>
        <PageHeaderTitleNavigation 
            :id="$appNavigation.currentOptionMenuId" 
            title="Persönliches Geschäft" 
            :actions="headerActions"
            @action-SAVE="save()"
        />
        <ComboBox
            label="Jahr"
            v-model="form.jahr" 
            :values="availableYears"
            :disabled="disabled"
            @change="setChanged"
            isComponentHalfSize
        />
        <div class="box__container">
            <InputRadioBoxGroup 
                title="Ich bestätige,"
                v-model="form.depotStatus" 
                :values="depotStatusValues"
                :disabled="disabled"
                @input="setChanged"
            />
            <InputField 
                v-model="form.depotnr"
                label="Depotnummer"
                :disabled="!hasDepot || disabled"
                isComponentHalfSize 
                @change="setChanged" />
            <InputField 
                v-model="form.lagerstelle"
                label="Lagerstelle"
                :disabled="!hasDepot || disabled"                
                isComponentHalfSize 
                @change="setChanged"/>
            

            <DragnDropArea class="form-select-file-button__container mb-3" 
                hoverText="Hochladen" 
                @files="upload($event, DEPOTAUSZUG)"
                :disabled="isUploadDisabled(DEPOTAUSZUG)"
            >
                <div class="my-3">
                    <BaseFileSelect @files="upload($event, DEPOTAUSZUG)" :disabled="isUploadDisabled(DEPOTAUSZUG)">
                        Depotauszug hochladen
                    </BaseFileSelect>
                    <div class="mt-3" v-if="depotauszugFileName">
                        <div class="pill__container" :key="form.depotauszugFileObjectId">
                            <DownloadLink target="_blank" rel="noopener noreferer" v-if="form.depotauszugFileId"
                                :title="form.depotauszugFileName"
                                :filename="form.depotauszugFileName"
                                downloadServicePath="/get_simple_file"
                                :queryParams="{
                                    fileId: form.depotauszugFileId,
                                    tempFileId: form.depotauszugFileId,
                                }"
                            >
                                <Pill :label="depotauszugFileName" type="default"></Pill>
                            </DownloadLink>
                            <BaseButton isLink ><PhTrash class="ml-2" :size="16" @click="onRemoveFile(DEPOTAUSZUG)"/></BaseButton>
                        </div>
                    </div>
                </div>
            </DragnDropArea>
    
            <DragnDropArea
                hoverText="Neues Dokument hochladen"
                @files="upload($event, TRANSLISTE)" 
                class="simple-additional-documents-table__dropzone" 
                :disabled="isUploadDisabled(TRANSLISTE)"
            >
                <div class="my-3">
                    <BaseFileSelect @files="upload($event, TRANSLISTE)" :disabled="isUploadDisabled(TRANSLISTE)">
                        Transaktionsliste hochladen
                    </BaseFileSelect>
                </div>
                <div class="mt-3" v-if="translisteFileName">
                    <div class="pill__container" :key="form.translisteFileObjectId">
                        <DownloadLink target="_blank" rel="noopener noreferer" v-if="form.translisteFileId"
                            :title="form.translisteFileName"
                            :filename="form.translisteFileName"
                            downloadServicePath="/get_simple_file"
                            :queryParams="{
                                fileId: form.translisteFileId,
                                tempFileId: form.translisteFileId,
                            }"
                        >
                            <Pill :label="translisteFileName" type="default"></Pill>
                        </DownloadLink>
                        <BaseButton isLink ><PhTrash class="ml-2" :size="16" @click="onRemoveFile(TRANSLISTE)"/></BaseButton>
                    </div>
                </div>
            </DragnDropArea>
            <div>
                <p> <span class="font-bold">Hinweis: </span> Bitte fügen Sie alle Depotauszüge/Transaktionslisten zu jeweils einem PDF zusammen, sofern mehrere Dokumente vorliegen.</p>
            </div>
            <InputTextArea
                label="Anmerkung"
                v-model="form.bemerkung"
                isComponentHalfSize
                :disabled="!form.depotStatus || disabled"
                @change="setChanged" />

            <InputToggleSwitch v-model="form.confirmed" :disabled="!form.depotStatus || disabled" 
                label="Ich bestätige hiermit, die Angaben wahrheitsgetreu getätigt zu haben." inLineLabel validateUntouched
                @input="setChanged" />
        </div>
    </div>
</template>

<script>
import InputField from '@/components/core/forms/InputField.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import DragnDropArea from '@/components/fileUpload/DragnDropArea.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import DocumentsUploadModal from '@/components/fileUpload/DocumentsUploadModal.vue';
import validator from "@/mixins/validator";
import presoenlichesGeschaeftMixin from "@/mixins/persoenliches-geschaeft-mixin";
import { required } from "@/mixins/validator/rules";
import PERS_GESCHAEFT_TYPES from '@/store/persoenlichesGeschaeft/types';
import { mapGetters } from 'vuex';
import BaseFileSelect from '@/components/fileUpload/BaseFileSelect.vue';
import Pill from '@/components/core/Pill.vue';
import {uploadFileChunked} from '@/helpers/upload-helper'
import DOKUMENTENARCHIV_TYPES from "@/store/dokumentenarchiv/types";
import CORE_TYPES from "@/store/core/types";
import DownloadLink from "@/components/core/download/DownloadLink.vue";
import { PhTrash } from "phosphor-vue";
import ComboBox from '@/components/core/forms/ComboBox.vue';

import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

export const DEPOT_STATUS_VALUES = [ 
                {value: 'KEIN_DEPOT', status: 'kein Wertpapierdepot', label: 'dass ich über kein Wertpapierdepot verfüge und nicht Mit-Depotinhaber, Zeichnungsberechtigter oder Verfügungsberechtigter auf anderen Depots bin'},
                {value: 'ALLES_GEMELDET', status: 'alle meldepflichtigen Transaktionen gemeldet', label: 'dass ich im oben genannten Prüfungszeitraum alle meldepflichtigen Transaktionen gemeldet habe'},
                {value: 'KEINE_PFLICHT_TRANSAKTIONEN', status: 'keine meldepflichtigen Transaktionen getätigt', label: 'meine Wertpapierdepots gemeldet zu haben, ich aber im oben genannten Prüfungszeitraum keine meldepflichtigen Transaktionen getätigt habe'},
                {value: 'TRANSAKTIONEN_MELDUNG', status: 'Transaktionen jetzt gemeldet', label: 'dass ich im oben genannten Prüfungszeitraum nicht alle meldepflichtigen Transaktionen gemeldet habe und lege diese bei'},
                {value: 'MELDEPFLICHTIGE_TRANSAKTION', status: 'Persönliches, meldepflichtiges Geschäft', label: 'hiermit eine persönliche meldepflichtige Transaktion getätigt zu haben'},
            ];

export default {
    mixins: [validator, presoenlichesGeschaeftMixin],
    validators: {
        form: {
            depotnr: [required('Depotnummer ist erforderlich!')],
            lagerstelle: [required('Lagerstelle ist erforderlich!')],
            depotStatus: [required('Depotstatus ist erforderlich!')],
            confirmed: [required('Bitte bestätigen Sie Ihre Eingaben!', {forcetouch: true})]
        },
    },
    components: {
        InputField,
        InputTextArea,
        InputToggleSwitch,
        DragnDropArea,
        OptionMenu,
        PageHeaderTitleNavigation,
        InputRadioBoxGroup,
        BaseButton,
        DocumentsUploadModal,
        BaseFileSelect,
        Pill,
        DownloadLink,
        PhTrash,
        ComboBox
    },
    data() {
        return {
            form: {
                depotnr: '',
                lagerstelle: '',
                bemerkung: '',
                depotStatus: '',
                confirmed: false,
            },
            files: null,
            uploadFiles: [],
            depotStatusValues: DEPOT_STATUS_VALUES,
            isChanged: false,
            TRANSLISTE: 'TRANSLISTE',
            DEPOTAUSZUG: 'DEPOTAUSZUG',
            depotauszugFileName: '',
            translisteFileName: '',
        }
    },
    watch: {
        fragebogen(value) {
            this.initForm();
        },
    },
    mounted() {
        this.$store.dispatch(PERS_GESCHAEFT_TYPES.ACTIONS.GET_FRAGEBOGEN, this.id);
    },
    computed: {
        ...mapGetters({
            fragebogen: PERS_GESCHAEFT_TYPES.GETTERS.FRAGEBOGEN,
            isIntern: CORE_TYPES.GETTERS.IS_INTERN,
            userId: CORE_TYPES.GETTERS.GET_USER_ID,
        }),
        headerActions() {
            return [
                PageHeaderSimpleAction('SAVE', 'Abschließen').withDisabled(() => !this.form?.depotStatus || this.disabled),
            ];
        },
        hasDepot() {
            return !!this.form.depotStatus && this.form.depotStatus !== 'KEIN_DEPOT';
        },
        id() {
            return this.$route.params.id;
        },
        isValid() {
            return this.validation.valid;
        },
        disabled() {
            return this.form.geprueft || (this.isIntern && this.form.id && this.form.internId !== this.userId);
        }
    },
    methods: {
        openDocumentUploadModal(files) {
            this.$refs.uploadForm.open();
            this.files = files;
        },
        upload(files, type) {
            if (files?.length && type) {
                if (!this.form.id || this.isChanged) {
                    this.$store.dispatch(PERS_GESCHAEFT_TYPES.ACTIONS.SAVE_FRAGEBOGEN, this.form).then(response => {
                        if (response?.id) {
                            this.isChanged = true;
                            this.uploadFile(files[0], type);
                            this.$router.push(`/intern/persoenliches-geschaeft-fragegoben/${response.id}`)
                        }
                    });
                } else {
                    this.uploadFile(files[0], type);
                }
            }
        },
        uploadFile(file, type) {
            uploadFileChunked(file).then(async (id) => {
                this.$store.dispatch(DOKUMENTENARCHIV_TYPES.ACTIONS.UPLOAD_DOCUMENT, {
                    fileId: id,
                    parentId: this.form.parentId || '',
                    formData: { PERSOENLICHES_GESCHAEFT: this.form.id,
                                FILE_KATEGORIE: type}
                    })
                    .then(() => this.$store.dispatch(PERS_GESCHAEFT_TYPES.ACTIONS.GET_FRAGEBOGEN, this.id));
            });
        },
        save() {
            if (this.isChanged) {
                this.$store.dispatch(PERS_GESCHAEFT_TYPES.ACTIONS.SAVE_FRAGEBOGEN, this.form);
            }
            this.$store.commit(CORE_TYPES.MUTATIONS.POP_SAVE_BACK_TO_PREVIOUS_PAGE);
            this.$router.push('/intern/persoenliches-geschaeft');
        },
        initForm() {
            if (this.fragebogen) {
                this.form = Object.assign({}, this.form, this.fragebogen)
                this.depotauszugFileName = this.fragebogen?.depotauszugFileName;
                this.translisteFileName = this.fragebogen?.translisteFileName;
            }
        },
        onRemoveFile(fileType) {
            if (this.disabled) {
                return;
            }
            this.$confirmModal({
                title: 'Dokument löschen', 
                message: `Wollen Sie ${fileType === this.TRANSLISTE ? 'die Transaktionsliste' : 'den Depotauszug'} wirklich löschen?`, 
                labelButtonConfirm: 'Löschen',
            }).then(() => {
                const documentId = fileType === this.TRANSLISTE ? this.form.translisteFileObjectId : this.form.depotauszugFileObjectId;
                this.$store.dispatch(
                    DOKUMENTENARCHIV_TYPES.ACTIONS.EXECUTE_ACTION, {
                        action: {
                            legend: { key: "DOKUMENT_LOSCHEN" },
                            sendData: [{ key: 'nodeId', value: this.form.parentId}, { key: 'PARAM_DOK_NODE_ITEM_TYPE', value: 'OWNER_FILE'}, 
                                { key: 'DOKFILEID', value: documentId }, {key: 'FILE_TYPE', value: fileType }, 
                                { key: 'DOKANTRAGID', value: this.id }],
                        },
                        nodeId: this.form.parentId,
                        documentId: documentId,
                    }).then(() =>  {
                        this.$store.dispatch(PERS_GESCHAEFT_TYPES.ACTIONS.GET_FRAGEBOGEN, this.id)
            });
            });
        },
        isUploadDisabled(fileType) {
            return !this.hasDepot || this.disabled // || !this.form.depotnr || !this.form.lagerstelle
                || (fileType === this.TRANSLISTE ? !!this.form.translisteFileObjectId: !! this.form.depotauszugFileObjectId);
        },
        setChanged() {
            if (!this.isChanged) {
                this.isChanged = true;
            }
        }
    }
}

</script>

<style>

</style>