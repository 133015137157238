var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("fk-fonds-shop", {
        staticClass: "fk-fs-element",
        attrs: {
          broker: _vm.getBoker,
          start: _vm.zeige,
          regcode: _vm.regCode,
          offsetscroll: _vm.scroll
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }