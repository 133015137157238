<template>
  <div class="input-forms__container">
    <label class="input-forms__label-container">
      <div class="input-forms__label-content" v-if="label">{{label}}</div>
      <div class="layout__negative-margin--8 d-flex" :class="{'forms__input--half-size': this.isComponentHalfSize }">
        <div class="entity-selector__combobox-container">
          <ComboBox :renderDanger="isValidationConfigured() && validation.isInvalid(this.validationPath) && validation.isDirty(this.validationPath)"
            isEntitySelector
            @input="onChange"
            :values="values"
            :value="internalValue" 
            :disabled="disabled"
            :firstEmpty="firstEmpty"
            :sortComboboxValues="sortComboboxValues"
            isEmbedded/>
        </div>
        <div class="entity-selector__button input-forms__buttons--line-height d-flex clickable" v-if="!hideNewButton">
          <div class="d-flex" @click.prevent.stop="doClick('new')">
            <ph-file-plus v-if="iconNew === 'default'" :size="24"/>
            <ph-user-plus v-if="iconNew === 'person'" :size="24"/>
          </div>
        </div>
        <div class="entity-selector__button input-forms__buttons--line-height d-flex clickable" v-if="isSomethingSelected && hasEditButton">
          <ph-pencil-line :size="24" @click.prevent.stop="$emit('edit', internalValue)"/>
        </div>
      </div>
      <div class="row" v-if="isValidationConfigured() && !suppressValidationMessage && validation.isInvalid(this.validationPath, this.validateUntouched)">
        <div class="col-12">
          <div :key="validation.updated">
            <div class="fc-form-danger" 
              v-for="error in validation.getErrors(this.validationPath, this.validateUntouched)" 
              :key="error">
                {{ error }}
            </div>
          </div>
        </div>
      </div>
    </label>
  </div>
</template>

<script>
import ComboBox from '@/components/core/forms/ComboBox.vue'
import { PhFilePlus, PhPencilLine, PhUserPlus } from 'phosphor-vue'
import validatorComponentUtils from '@/mixins/validator/validator-component-utils'

export default {
  mixins: [validatorComponentUtils],
  components: {
    ComboBox,
    PhFilePlus, 
    PhPencilLine,
    PhUserPlus,
  },
  props: {
    label: {
    },
    value: {
    },
    values: {
      type: [Array, String],
      default: () => [],
    },
    textNewButton: {
      type: String,
      default: '',
    },
    textEditButton: {
      type: String,
      default: '',
    },
    hasEditButton: {
      type: Boolean,
      default: true,
    },
    hideNewButton: {
      type: Boolean,
      default: false,
    },
    validateUntouched: {
      type: Boolean,
      default: false
    },
    suppressValidationMessage: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isComponentHalfSize: {
      type: Boolean,
      default: false
    },
    isPersonPicker: {
      type: Boolean,
      default: false,
    },
    firstEmpty: {
      type: Boolean,
      default: false,
    },
    sortComboboxValues: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    value: function(newValue) {
      this.internalValue = newValue;
      this.$runValidator(this.internalValue)
    },
    disabled() {
      if (this.disabled) {
        this.$reset()
      } else { 
        this.$runValidator(this.internalValue);
      }
    }
  },
  data() {
    return {
      internalValue: null
    }
  },
  mounted() {
    this.internalValue = this.value
  },
  computed: {
    iconNew() {
      return this.isPersonPicker ? 'person' : 'default'
    },
    isSomethingSelected() {
      return this.internalValue  && this.internalValue !== 'NICHT_VERWENDEN';
    }
  },
  methods: {
    onChange($event) {
      this.$emit('change', $event)
      this.$emit('input', $event)
      this.$runValidator($event)
      this.$setDirty()
    },
    doClick(buttonId) {
      if (!this.disabled) {
        this.$emit(buttonId, this.internalValue);
      }
    }
  },
}
</script>

<style scoped>
  .entity-selector__combobox-container {
    flex: 1 1 auto;
  }
  .entity-selector__button {
    flex: 0 0 auto;
  }
</style>