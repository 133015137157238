import BIPRO_TYPES from './types';
import CORE_TYPES from '@/store/core/types'
import axios from 'axios';

const config = {
    defaultSpinner: true
}

export default {
    [BIPRO_TYPES.ACTIONS.LOAD_GESELLSCHAFT_OPTIONS]({ commit, getters}) {
        const url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/bipro/gesellschaft_options`;
        axios.get(url).then(response => {
            commit(BIPRO_TYPES.MUTATIONS.GESELLSCHAFT_OPTIONS, response.data);
        });
    },
    [BIPRO_TYPES.ACTIONS.LOAD_GESELLSCHAFT_OPTIONS_INTERFACE]({ commit, getters}) {
        const url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/gdv_interface/gesellschaft_options`;
        axios.get(url).then(response => {
            commit(BIPRO_TYPES.MUTATIONS.GESELLSCHAFT_OPTIONS_INTERFACE, response.data);
        });
    },
    async [BIPRO_TYPES.ACTIONS.FIND_INSURANCE_DEEPLINKS]({ commit, getters}, { vertragId }) {
        if (!vertragId) return;
        try {
            const url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/bipro/deeplinks/insurance?id=${vertragId}`;
            const response = await axios.get(url, config);
            return response?.data || {};
        } catch (e) {
            // empty block
        }
        return {};
    },
}