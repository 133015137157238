var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "customer-overview__container" }, [
    _c("div", { staticClass: "customer-overview--image" }, [
      _c("div", { staticClass: "img-wrap" }, [
        _c("img", { attrs: { src: _vm.info.pictureUrl } })
      ])
    ]),
    _c("div", { staticClass: "customer-overview--content" }, [
      _c(
        "div",
        [
          _c("PersonOverview", {
            attrs: {
              person: _vm.person,
              category: _vm.info.category,
              isMainPerson: ""
            }
          }),
          _c(
            "div",
            { staticClass: "channel-status-container" },
            _vm._l(_vm.info.activeCommunicationChannels, function(
              channel,
              index
            ) {
              return _c("div", { key: index }, [
                channel.value
                  ? _c(
                      "span",
                      { staticClass: "color-success" },
                      [
                        _c("PhCheck", {
                          staticClass: "m-1",
                          attrs: { size: 24 }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                !channel.value
                  ? _c(
                      "span",
                      { staticClass: "color-danger" },
                      [
                        _c("PhWarning", {
                          staticClass: "m-1",
                          attrs: { size: 24 }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c("span", [_vm._v(_vm._s(channel.key))])
              ])
            }),
            0
          ),
          _vm.additionalPersons.length
            ? _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _vm._m(0),
                  _vm._l(_vm.additionalPersons, function(person, index) {
                    return _c("PersonOverview", {
                      key: index,
                      attrs: { person: person, isCollapsable: "" }
                    })
                  })
                ],
                2
              )
            : _vm._e(),
          _vm.hasRoles([_vm.ROLES.SUPER_CUSTOMER]) && _vm.subCustomers.length
            ? _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _vm._m(1),
                  _c(
                    "ul",
                    { staticClass: "gruppenmitglieder__list mb-2" },
                    _vm._l(_vm.subCustomers, function(subCustomer, index) {
                      return _c("li", { key: index }, [
                        _c(
                          "a",
                          {
                            on: {
                              click: function($event) {
                                return _vm.openCustomerNewTab(subCustomer)
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(subCustomer.fullName) +
                                " (" +
                                _vm._s(subCustomer.customerID) +
                                ")"
                            )
                          ]
                        )
                      ])
                    }),
                    0
                  ),
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to:
                          "/persoenlichedaten/customer-data/steps/gruppenmitglieder"
                      }
                    },
                    [_vm._v("weitere Gruppenmitglieder")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("b", [_vm._v("zus. Depotinhaber")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("b", [_vm._v("Gruppenmitglieder")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }