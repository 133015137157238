<template>
  <div>
    <DragnDropArea hoverText="Datei hier ablegen" @files="dropFile">
      <div class="schadensmeldung">
        <PageHeaderTitleNavigation 
          :actions="headerActions" 
          :defaultMenu="customOptionsMenu"
          v-on="$listeners"
          @action-EMAIL="handleEmail()"
          @action-GESELLSCHAFT-HOME-PAGE="handleGesellschaftHomePage()"
          @action-ADD-ACTIVITY="handleAddActivity()"
        >
          <template #title>{{ gesellschaftName }}</template>
            <template #subtitle>
              <template v-if="bezeichnung">
                <span>{{ bezeichnung }}</span>
                <span> | </span>
              </template>
              <template>
                <span>Schadensmeldung</span>
              </template>
          </template>
        </PageHeaderTitleNavigation>

        <template v-if="hasHinweis">
          <div class="box__container">
            <div class="font-bold">Hinweise:</div>
            <div class="d-flex justify-content-between">
              <div class="row" v-for="(hinweis, index) of hinweise" :key="index">
                <div>{{ hinweis.text }}</div>
              </div>
            </div>
          </div>
        </template>

        <form v-on:submit.prevent="send">
          <div v-if="bearbeitenMode">
            <div class="row">
              <div class="col-12">
                <StepperForm v-if="bearbeitenMode==true" stepType="step" :stepperMediator="getStepperMediator"
                  :selectedStepKey="selectedStepKey" :showSaveSpinner="loading" @next-step="nextStep"
                  @previous-step="previousStep" @on-fertig="fertig" @set-step="setStepByKey">
                  <template #contentTemplate>
                    <div v-if="selectedStepKey === 'allgemeine'">
                      <AllgemeineAngaben :vertragId="vertragId" :schadenId="schadenId" v-bind:value="ortDatum" v-on:sendInfo="getInfo($event.target.ortDatum)" />
                    </div>
                    <div v-else-if="selectedStepKey === 'dokumenteUndFotos'">
                      <DragnDropArea hoverText="Datei hier ablegen" @files="dropFile">
                        <div class="box__container">
                            <DocumentsTable
                              ref="documentsTableEl"
                              title="Dokumente"
                              :nodeId="nodeId"
                              :versicherungId="vertragId"
                              :schadenId="schadenId"
                              :rowsPerPage="rowsPerPage"
                              :unregisterEmailEventsBeforeDestroy="false"
                              showOnlySchadenDocs
                              @sendEmail="handleEmail"
                            />
                        </div>
                      </DragnDropArea>
                    </div>
                    <div class="box__container" v-else-if="selectedStepKey === 'beteiligte'">
                      <BeteiligtePersonen :schadenId="schadenId" />
                    </div>
                    <div class="box__container" v-else-if="selectedStepKey === 'geschaedigte'">
                      <GeschaedigteObjekte :schadenId="schadenId" />
                    </div>
                    <div v-else-if="selectedStepKey === 'zusammenfassung' && schadensmeldung">
                      <Zusammenfassung :summary="schadensmeldung" />
                    </div>
                    <div class="box__container" v-else-if="selectedStepKey === 'maklernotizen' && schadensmeldung && isByPass">
                      <Maklernotizen :schadenId="schadenId" />
                    </div>
                  </template>
                </StepperForm>
              </div>
            </div>
          </div>
          <div v-else-if="schadensmeldung">
            <div>
              <div class="box__title">Zusammenfassung</div>

              <Zusammenfassung :summary="schadensmeldung" :bearbeitenMode="bearbeitenMode"
                @on-zuruck="zuruckAusZusammenfassung" @on-bearbeiten="bearbeiten" />
            </div>

            <div class="box__container">
              <BaseButton isSecondary @click="zuruckAusZusammenfassung" class="m-0 mr-16px">Zurück</BaseButton>
              <BaseButton @click="bearbeiten" class="m-0">Bearbeiten</BaseButton>
            </div>
          </div>
        </form>
      </div>
    </DragnDropArea>
    <EmailVersand/>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";

  import { StepperMediator } from "@/components/stepper/utils";
  import SCHADENSMELDUNG_TYPES from "@/store/schadensmeldung/types";
  import VERSICHERUNG_TYPES from "@/store/versicherungen/types";
  import CORE_TYPES from "@/store/core/types";
  import MAILCOMPOSER_TYPES from "@/store/mailcomposer/types";
  import CALENDAR_TYPES from "@/store/calendar/types";
  import BaseModal from '@/components/core/BaseModal.vue';
  import BaseCollapsable from '@/components/core/BaseCollapsable.vue'

  import StepperForm from "@/components/stepper/StepperForm.vue";
  import VerticalStepper from '@/components/vertical-stepper/VerticalStepper.vue';
  import BaseButton from "@/components/core/BaseButton.vue";

  import Zusammenfassung from "@/views/schadensmeldung/Zusammenfassung.vue";
  import AllgemeineAngaben from './steps/AllgemeineAngaben.vue';
  import BeteiligtePersonen from './steps/BeteiligtePersonen.vue';
  import GeschaedigteObjekte from './steps/GeschaedigteObjekte.vue';
  import FotosAnhaenge from './steps/FotosAnhaenge.vue';
  import Maklernotizen from './steps/Maklernotizen.vue';
  import DocumentsTable from '@/components/documents/DocumentsTable.vue';
  import DownloadLink from '@/components/core/download/DownloadLink.vue'
  import AppointmentEditCreate from '@/components/calendar/appointmentFiles/AppointmentEditCreate.vue';
  import EmailVersand from "@/components/core/EmailVersand.vue";

  import { trackBreadcrumbToCurrentRouteIfNeeded } from '@/router/breadcrumb/index';
  import DragnDropArea from '@/components/fileUpload/DragnDropArea.vue';

  import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
  import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
  import { PageHeaderSimpleAction, PageHeaderDownloadLinkAction, } from '@/components/core/header-title-navigation/page-header-utils';

  import { addParamToURL } from '@/helpers/utils-helper';
  import { TabMenu, } from '@/menu/menu-utils';
  import { buildMessage } from '../../helpers/log-message-helper';
  import LOG_TYPES from '@/store/log/types';
  import FC_CONFIG_TYPES from '@/store/fcConfig/types';
  import FC_CONFIG from '@/configs/fcConfig';
  import EVENT_BUS, { SEND_EMAIL, SEND_BRIEF, } from '@/event-bus';

  const STEPS = [
    {
      stepKey: "allgemeine",
      label: "Allgemeine Angaben",
      totalProgress: 1
    },

    {
      stepKey: "beteiligte",
      label: "Beteiligte Personen",
      totalProgress: 1
    },
    {
      stepKey: "geschaedigte",
      label: "Beschädigte Objekte",
      totalProgress: 1
    },
    {
      stepKey: "dokumenteUndFotos",
      label: "Dokumente / Fotos",
      totalProgress: 1
    },
    {
      stepKey: "zusammenfassung",
      label: "Zusammenfassung",
      totalProgress: 1
    },
    {
      stepKey: "maklernotizen",
      label: "Schadennotizen Vermittler",
      totalProgress: 1
    },    
  ];

const ROWS_PER_PAGE_DEFAULT = 20;


  export default {

    data() {
      return {
        loading: false,
        selectedStepKey: 'allgemeine',
        steps: STEPS,
        hinweise: [],
        form: {},
        ortDatum: "",
        bearbeitenMode: false,
        rowsPerPage: ROWS_PER_PAGE_DEFAULT,
      };
    },
    components: {
      VerticalStepper,
      StepperForm,
      BaseButton,
      BaseModal,
      Zusammenfassung,
      AllgemeineAngaben,
      BeteiligtePersonen,
      GeschaedigteObjekte,
      FotosAnhaenge,
      BaseCollapsable,
      DocumentsTable,
      DragnDropArea,
      AppointmentEditCreate,
      DownloadLink,
      OptionMenu,
      PageHeaderTitleNavigation,
      Maklernotizen,
      EmailVersand,
    },
    watch: {
      beteiligte() {
        this.retrieveSchadensDetail();
      },
      beschaedigt() {
        this.retrieveSchadensDetail();
      },
      files() {
        this.retrieveSchadensDetail();
      },
      deletedBeteiligteId() {
        this.retrieveSchadensDetail();
      },
      lastSentAnhang() {
        this.retrieveSchadensDetail();
      },
      deletedAnhangId() {
        this.retrieveSchadensDetail();
      },
      lastBeschaedigtId() {
        this.retrieveSchadensDetail();
      },
      deletedBeschaedigtId() {
        this.retrieveSchadensDetail();
      },
      deletedZahlungId() {
        this.retrieveSchadensDetail();
      },
      schadensmeldung(value) {
        if (value && Object.keys(value).length && !this.backToPreviousData?.length) {
          this.$addBreadcrumb({
            label: 'zurück zu Versicherungen',
            // fullPath: `/home/versicherungen/details/${this.$route.params.vertragId}`,
            fullPath: '/home/versicherungen/insurance-group',
            breadcrumb: value?.kategorie || 'Details',
          });
          trackBreadcrumbToCurrentRouteIfNeeded({ path: `/home/versicherungen/insurance-group/details/${this.$route.params.vertragId}` });
          this.$addBreadcrumb({
            label: 'zurück zu Details',
            // fullPath: this.$route.fullPath,
            fullPath: `/home/versicherungen/insurance-group/details/${this.$route.params.vertragId}`,
            breadcrumb: 'Schaden melden',
          });

        }
      },
      isByPass: {
        handler() {
          this.getStepperMediator.setStepHidden('zahlungen', !this.isByPass)
        },
        immediate: true 
      },
    },
    computed: {
      ...mapGetters({
        schadensmeldung: SCHADENSMELDUNG_TYPES.GETTERS.SCHADENSMELDUNG,
        schadensmeldungEdited: SCHADENSMELDUNG_TYPES.GETTERS.SCHADENSMELDUNG_EDITED,
        beteiligte: SCHADENSMELDUNG_TYPES.GETTERS.LAST_INSERTED_BETEILIGTE,
        deletedBeteiligteId: SCHADENSMELDUNG_TYPES.GETTERS.LAST_DELETED_BETEILIGTE,
        lastSentAnhang: SCHADENSMELDUNG_TYPES.GETTERS.LAST_SENT_ANHANG,
        deletedAnhangId: SCHADENSMELDUNG_TYPES.GETTERS.LAST_DELETED_ANHANG,
        lastBeschaedigtId: SCHADENSMELDUNG_TYPES.GETTERS.LAST_SENT_BESCHAEDIGT,
        deletedZahlungId: SCHADENSMELDUNG_TYPES.GETTERS.LAST_DELETED_ZAHLUNG,
        deletedBeschaedigtId: SCHADENSMELDUNG_TYPES.GETTERS.LAST_DELETED_BESCHAEDIGT,
        isByPass: CORE_TYPES.GETTERS.IS_BYPASS,
        configData: SCHADENSMELDUNG_TYPES.GETTERS.CONFIG_DATA,
        pdfData: SCHADENSMELDUNG_TYPES.GETTERS.PDF_DATA,
        backToPreviousData: CORE_TYPES.GETTERS.GET_SAVE_BACK_TO_PREVIOUS_PAGE,
        isCustomer: CORE_TYPES.GETTERS.IS_CUSTOMER,
        attachments: MAILCOMPOSER_TYPES.GETTERS.SET_ATTACHMENTS,
        configParameters: FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_ADD_PARAMETER,
      }),
      vertragId() {
        return this.$route.params.vertragId;
      },
      schadenId() {
        return this.$route.params.schadenId;
      },
      sendEmailToCompanyAutomaticallyConfigParameter() {
        const isPositiveRegex = /(1|j[a]?)/i
        let content = isPositiveRegex.test(this.configParameters['VERTRAG_SCHADENSMELDUNG_DIREKT']?.content);
        return content;
      },
      headerActions() {
        const { 
          isSchadenValid, vertragId, schadenId, getLabelSchadenEmailButton, 
          gesellschaftHomePage, isCustomer, istNeuesSchaden,
        } = this;

        const actions = [];

        if(this.isByPass || (this.isCustomer && this.sendEmailToCompanyAutomaticallyConfigParameter)) {
          actions.push(PageHeaderSimpleAction('EMAIL', getLabelSchadenEmailButton).withDisabled(() => !isSchadenValid));
        }

        actions.push(PageHeaderDownloadLinkAction('DOWNLOAD', 'PDF', 
          `Schadensmeldung zu Vertrag${(vertragId || '')}.pdf`, '/schadensPdf', { schadenId, })
          .withDisabled(() => istNeuesSchaden || !isSchadenValid));

        if(gesellschaftHomePage && !isCustomer) {
          actions.push(PageHeaderSimpleAction('GESELLSCHAFT-HOME-PAGE', 'Link zum Versicherer'));
        }

        actions.push(PageHeaderSimpleAction('ADD-ACTIVITY', 'Aktivität hinzufügen'));

        return actions;
      },
      detailsData() {
        return this.configData || null;
      },
      gesellschaftName() {
        return this.detailsData?.gesellschaft || '';
      },
      bezeichnung() {
        return this.detailsData?.sparte || '';
      },
      istNeuesSchaden() {
        return this.schadenId === 'neuesSchaden' || this.schadensmeldung?.schadennr === 'neuesSchaden';
      },
      getStepperMediator() {
        return new StepperMediator(JSON.parse(JSON.stringify(this.steps)));
      },
      gesellschaftHomePage() {
        return this.configData.gesellschaftHomePage || null;
      },
      nodeId() {
        return this.schadensmeldung?.nodeId || null;
      },
      schadennr() {
        return this.schadensmeldung?.schadennr || this.$route.params.schadennr || null;
      },
      schadenid() {
        return this.schadensmeldung?.schadenId || null;
      },
      isSchadenValid() {
        return !this.schadensmeldungEdited || this.schadensmeldungEdited?.validation?.form?.sachbearbeiterEmail?.valid;
      },
      bereichText() {
        let text = "Schadensmeldung"

        if(this.schadensmeldung?.vsnr) text += ` zu VSNR: ${this.schadensmeldung?.vsnr}`
        if(this.schadensmeldung?.vsnr && this.schadennr && !this.istNeuesSchaden) text += ','
        if(this.schadennr && !this.istNeuesSchaden) text += ` Schaden-Nr. ${this.schadennr}`

        return text;
      },
      getLabelSchadenEmailButton() {
        return this.schadensmeldung?.isEmailAlreadySent ? 'Folgemeldung versenden' : 'Erstmeldung versenden';
      },
      customOptionsMenu() {
        let result = [ 
          TabMenu(`/home/versicherungen/insurance-group/schadensmeldung/${this.vertragId}/${this.schadenId}`, 'Schadensmeldung'),
        ]
        if(this.schadensmeldung && this.isByPass) {
          result.push(TabMenu(`/home/versicherungen/insurance-group/schadensmeldung/zahlungen-geschaedigte/${this.vertragId}/${this.schadenId}`, 'Schadenzahlung'))
        }
        return result;
      },
    },
    mounted() {
      this.bearbeitenMode = this.istNeuesSchaden || this.isByPass;

      if (this.istNeuesSchaden) {
        this.$store.commit(SCHADENSMELDUNG_TYPES.MUTATIONS.RESET_STATE);
      } else {
        this.retrieveSchadensDetail();
      }

      this.$store.dispatch(SCHADENSMELDUNG_TYPES.ACTIONS.GET_CONFIG_DATA, {
        vertragId: this.vertragId,
      });
      // come hier directly from broker Stornos -> no breadcrums

      if (this.$route.query?.stepKey) {
        this.selectedStepKey=this.$route.query?.stepKey;
        // important when commes back from schadensmeldung-documents 
        this.bearbeitenMode = true;
      }
      const payload = {
        configId: 'VERTRAG_SCHADENSMELDUNG_DIREKT',
        configType: FC_CONFIG.ADD_PARAMETER
      }
      this.$store.dispatch(FC_CONFIG_TYPES.ACTIONS.LOAD_FC_CONFIG, payload);
    },
    beforeRouteLeave(to, from, next) {
      this.$store.commit(VERSICHERUNG_TYPES.MUTATIONS.FILTER_CATEGORY_STOP);

      let url = from.fullPath;
      if(from.fullPath.includes('stepKey')){
        url = from.fullPath.replaceAll(/stepKey\=.*(\&)/gi, `stepKey=${this.selectedStepKey}$1`)
      } else {
        url = addParamToURL(from.fullPath, `stepKey=${this.selectedStepKey}`)
      }
      this.$addBreadcrumb({
         to,
        from,
        label: 'zurück zu Schaden melden',
        fullPath: url,
        breadcrumb: 'E-Mail an Gesellschaft ',
      }); 

      if (this.schadensmeldungEdited && this.schadensmeldungEdited?.validation?.valid) {
        this.saveAndNext(next);
      } else {
        next();
      }
    },
    methods: {
      async retrieveSchadensDetail() {
        await this.$store.dispatch(SCHADENSMELDUNG_TYPES.ACTIONS.RETRIEVE_SCHADENSMELDUNG, {
          vertragId: this.vertragId,
          ...(this.schadenId ? {schadenId: this.schadenId} : {schadennr: this.$route.params.schadennr}),
        });
      },
      getInfo(data) {
        this.ortDatum = data;
      },
      setSchadenIdInRoute(schadenId) {
        this.$router.push(`/home/versicherungen/insurance-group/schadensmeldung/${this.$route.params.vertragId}/${schadenId}`);
      },
      async handleEmail(event) {
        let schadenId = null;
        if(this.istNeuesSchaden || !this.schadenId) {
          schadenId = await this.save();
        }

        const isCommingFromDocumentHeaderComponent = !!event;
        let toCompany = isCommingFromDocumentHeaderComponent ? event.toCompany : true;

        EVENT_BUS.$emit(SEND_EMAIL, {schadenId: schadenId || this.schadenId, toCompany, attachments: event?.attachments});
      },
      hasHinweis() {
        return this.hinweise.length > 0;
      },
      save(next) {
        if (!this.schadensmeldungEdited) {
          return;
        }

        let payload = {
          vertragId: this.vertragId,
          schadenId: (this.istNeuesSchaden ? null : this.schadenId),
          ...this.schadensmeldung,
          ...this.schadensmeldungEdited,
        };

        //important to clean data if displaySchadenort is false on allgemeine angaben (Broker.vue)
        if(!this.schadensmeldungEdited?.displaySchadenort) {
          const schadenort = {
            adresseStrasse: '',
            adressePlz: '',
            adresseOrt: '',
            adresseLand: '',
          };
          payload = {...payload, ...schadenort}
        }

        this.loading = true;
        return this.$store.dispatch(SCHADENSMELDUNG_TYPES.ACTIONS.SEND_SCHADENSMELDUNG, payload)
          .then(response => {
            if(!next){
              if (this.istNeuesSchaden) {
                this.setSchadenIdInRoute(this.schadensmeldung?.id || response.id);
              }
              this.retrieveSchadensDetail();
              return response.id;
            }
          })
          .catch((error) => console.log(error))
          .finally(() => this.loading = false);
      },
      saveAndNext(next) {
        if (!next) {
          return;
        }

        const promise = this.save(next);
        if (promise) {
          promise.then(() => next());
        } else {
          next();
        }
      },
      setStepByKey(stepKey) {
        if (this.selectedStepKey === stepKey || this.loading) {
          return;
        }

        this.selectedStepKey = stepKey;
        this.save();
      },
      nextStep(params) {
        this.setStepByKey(params.stepKey);
      },
      previousStep(params) {
        this.setStepByKey(params.stepKey);
      },
      zuruckAusZusammenfassung() {
        this.$router.push(`/home/versicherungen/insurance-group/details/${this.vertragId}?backAction=true`);
      },
      bearbeiten() {
        this.bearbeitenMode = true;
      },
      fertig() {
        this.$router.push(`/home/versicherungen/insurance-group/details/${this.vertragId}?backAction=true`);
      },
      handleGesellschaftHomePage() {
        if(this.gesellschaftHomePage){
           window.open(this.gesellschaftHomePage, "_blank");
        }
      },
      async dropFile(files) {
        this.selectedStepKey = 'dokumenteUndFotos';

        if(this.schadensmeldungEdited && this.schadensmeldungEdited?.validation?.invalid) {
          this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, 
          buildMessage('Das Formular ist ungültig. Bitte korrigieren Sie die im Formular markierten Fehler, bevor Sie die Dokumente hochladen.', 'warning'))
          return;
        }
        const promise = this.istNeuesSchaden ? await this.save() : this.schadenId;
        //Interval will run/tick every second until  "this.$refs.documentsTableEl" is defined
        var interval = setInterval(() => {
          if(promise && this.$refs?.documentsTableEl?.isLoadingComplete) {
            this.$refs.documentsTableEl.dateiHochladen(files);
            clearInterval(interval);
          }
        }, 1000);
      },
      handleAddActivity() {
        this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT, {activity: false});
        this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_NEW_AUFGABE);
        this.openAppointment();
      },
      openAppointment() {
        this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_CONFIG, {
          title: 'Aufgabe bearbeiten',
          subject: this.bereichText,
          status: 'OFFEN',
          isActivity: true,
          bereich: 'VersVertrag',
          bereichId: this.$route.params.vertragId,
          bereichText: this.bereichText,
          attachCustomer: false,
    
          back: this.$router.currentRoute.fullPath,
        })
        this.$router.push({ path: `/communication/appointment` });
      },
    }
  };
</script>