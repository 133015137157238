export const MUTATION_PREFIX = 'BERICHTE: ';
export const ACTIONS_PREFIX = 'BERICHTE_ACTIONS_';
export const GETTERS_PREFIX = 'BERICHTE_GETTERS_';

export default {
  MUTATIONS: {
    GET_RISIKOPRUEFUNG_LIST_SUCCESS: MUTATION_PREFIX + 'GET_RISIKOPRUEFUNG_LIST_SUCCESS',
    SAVE_RISIKOPRUEFUNG_BEMERKUNG_SUCCESS: MUTATION_PREFIX + 'SAVE_RISIKOPRUEFUNG_BEMERKUNG_SUCCESS',
    GET_STATUS_FFB_DEPOTS_SUCCESS: MUTATION_PREFIX + 'GET_STATUS_FFB_DEPOTS_SUCCESS',
    SET_FILTERS_FFB_STATUS_DEPOT: MUTATION_PREFIX + 'SET_FILTERS_FFB_STATUS_DEPOT',
    SET_FILTERS_STEUERLICHE_DATEN: MUTATION_PREFIX + 'SET_FILTERS_STEUERLICHE_DATEN',
    GET_STEUERLICHE_DATEN_SUCCESS: MUTATION_PREFIX + 'GET_STEUERLICHE_DATEN_SUCCESS',
    GET_STEUER_VERLUST_LIST_SUCCESS: MUTATION_PREFIX + 'GET_STEUER_VERLUST_LIST_SUCCESS',
    GET_GESELLSCHAFTEN_AUSWAHL_SUCCESS: MUTATION_PREFIX + 'GET_GESELLSCHAFTEN_AUSWAHL_SUCCESS',
    SET_COURTAGE_INVEST_KUNDE: MUTATION_PREFIX + 'SET_COURTAGE_INVEST_KUNDE',
    SET_COURTAGE_VERS_KUNDE: MUTATION_PREFIX + 'SET_COURTAGE_VERS_KUNDE',
  },
  ACTIONS: {
    GET_RISIKOPRUEFUNG_LIST: ACTIONS_PREFIX + 'GET_RISIKOPRUEFUNG_LIST',
    SAVE_RISIKOPRUEFUNG_BEMERKUNG: ACTIONS_PREFIX + 'SAVE_RISIKOPRUEFUNG_BEMERKUNG',
    OPEN_RISIKOPRUEFUNG_XLS_FILE: ACTIONS_PREFIX + 'OPEN_RISIKOPRUEFUNG_XLS_FILE',
    GET_STATUS_FFB_DEPOTS: ACTIONS_PREFIX + 'GET_STATUS_FFB_DEPOTS',
    CONFIRM_SELECTED_FFB_STATUS_ROWS: ACTIONS_PREFIX + 'CONFIRM_SELECTED_FFB_STATUS_ROWS',
    GET_STEUER_VERLUST_LIST: ACTIONS_PREFIX + 'GET_STEUER_VERLUST_LIST',
    GET_GESELLSCHAFTEN_AUSWAHL: ACTIONS_PREFIX + 'GET_GESELLSCHAFTEN_AUSWAHL',
    GET_STEUERLICHE_DATEN: ACTIONS_PREFIX + 'GET_STEUERLICHE_DATEN',
    LOAD_COURTAGE_INVEST_KUNDE: ACTIONS_PREFIX + 'LOAD_COURTAGE_INVEST_KUNDE',
    LOAD_COURTAGE_VERS_KUNDE: ACTIONS_PREFIX + 'LOAD_COURTAGE_VERS_KUNDE',
  },
  GETTERS: {
    RISIKOPRUEFUNG_LIST: GETTERS_PREFIX + 'RISIKOPRUEFUNG_LIST',
    STATUS_FFB_DEPOTS: GETTERS_PREFIX + 'STATUS_FFB_DEPOTS',
    FILTERS_FFB_STATUS_DEPOT: GETTERS_PREFIX + 'FILTERS_FFB_STATUS_DEPOT',
    STEUERLICHE_DATEN: GETTERS_PREFIX + 'STEUERLICHE_DATEN',
    FILTERS_STEUERLICHE_DATEN: GETTERS_PREFIX + 'FILTERS_STEUERLICHE_DATEN',
    STEUER_VERLUST_LIST: GETTERS_PREFIX + 'STEUER_VERLUST_LIST',
    GESELLSCHAFTEN_AUSWAHL: GETTERS_PREFIX + 'GESELLSCHAFTEN_AUSWAHL',
    GET_COURTAGE_INVEST_KUNDE: GETTERS_PREFIX + 'GET_COURTAGE_INVEST_KUNDE',
    GET_COURTAGE_VERS_KUNDE: GETTERS_PREFIX + 'GET_COURTAGE_VERS_KUNDE',
  }
}
