import Action from './actionTypes/Action.vue';
import DownloadLinkAction from './actionTypes/DownloadLinkAction.vue';
import SignoViewerLinkAction from './actionTypes/SignoViewerLinkAction.vue';

/**
 * A simple Action modal.
 * When clicked, the modal will emit an "action-[key]" event.
 * for example, if the given key is "DELETE", the event can be listened to with "@action-DELETE"
 * @param {*} key 
 * @param {*} label 
 * @returns 
 */
export function BaseModalSimpleAction(key, label) {
  return action(key, Action, label);
}

/**
* This action won't emit an event.
* It handle the download link according to the platform
* @param {*} key 
* @param {*} label 
* @param {*} filename
* @param {*} downloadServicePath - the path into /download_service. e.g.: /get_simple_file
* @param {*} queryParams - an Object with all parameters of the link. e.g.: { fileId: 0123, dokId: 0123 }.
*/
export function BaseModalDownloadLinkAction(key, label, filename, downloadServicePath, queryParams) {
  return {
      ...action(key, DownloadLinkAction, label),
      filename,
      downloadServicePath,
      queryParams,
  };
}

/**
* This action won't emit an event.
* It opens the Signoviewer service link according to the platform
* @param {*} key 
* @param {*} label - default is "Signieren"
* @param {*} servicePath - the path to the Signoviewer service (this is usually generated in the backend)
*/
export function BaseModalSignoViewerLinkAction(key, label = 'Signieren', servicePath) {
  return {
      ...action(key, SignoViewerLinkAction, label),
      servicePath,
  };
}

/**
* The internal method for action creation
* Don't use this method directly!
* Instead use one of the ...Action functions above.
* If no fitting Action type exists yet, you can create a new one!
* @param {*} actionKey 
* @param {*} component 
* @param {*} label 
* @returns 
*/
function action(actionKey, component, label) {
  return {
    actionKey,
    component,
    label,
    withLabel(label) {
      this.label = label;
      return this;
    },
    withValue(value) {
      this.value = value;
      return this;
    },
    withDisabled(disabled) {
      this.disabled = disabled
      return this;
    },
    withLoading(loading) {
      this.loading = loading
      return this;
    },
    withIcon(icon, size) {
      this.icon = icon
      this.size = size
      return this;
    },
    withVisible(visible) {
      this.visible = visible
      return this;
    },
    withPrimary(primary) {
      this.primary = primary
      return this;
    },
    withReplacementActionForCloseOnSmallScreen(replacementActionForCloseOnSmallScreen) {
      this.replacementActionForCloseOnSmallScreen = replacementActionForCloseOnSmallScreen;
      return this;
    },
    withVisibleOnSmallScreen(visibleOnSmallScreen) {
      this.visibleOnSmallScreen = visibleOnSmallScreen
      return this;
    },
    withVisibleOnLargeScreen(visibleOnLargeScreen) {
      this.visibleOnLargeScreen = visibleOnLargeScreen
      return this;
    },
  };
}