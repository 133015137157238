var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.loading
        ? _c("DashboardPanel", {
            attrs: {
              id: "4d5f8286-e61f-4110-a06e-c8f2752042e1",
              headerActions: _vm.headerActions,
              data: _vm.dashboardData,
              noPrimaryAction: false,
              ignoreUserLevelConfig: "",
              title:
                _vm.title +
                " | Festlegung der Versicherung für das " +
                _vm.prfx +
                " Dokument"
            },
            on: {
              "action-RELOAD-DATA": function($event) {
                return _vm.reloadData()
              },
              "action-TOGGLE-GESELLSCHAFT-PANEL": function($event) {
                return _vm.toggleGesellschaftPanel()
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "left-card",
                  fn: function() {
                    return [
                      _vm.leftTableRows.length
                        ? _c("Table", {
                            ref: "tableLeft",
                            attrs: {
                              tableId: "0964b72a-6a60-4cc2-adb4-f42cb877de9c",
                              cardView: "",
                              headers: _vm.headersLeftTable,
                              rows: _vm.leftTableRows,
                              rowId: "dataiName",
                              hidePagination: ""
                            },
                            on: { "click-fileName": _vm.fetchPDF },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "insuranceId",
                                  fn: function(row) {
                                    return [
                                      _c("InputField", {
                                        ref: "insuranceIdInputField",
                                        staticClass: "full__width__input",
                                        attrs: { validateUntouched: "" },
                                        model: {
                                          value: _vm.insuranceId,
                                          callback: function($$v) {
                                            _vm.insuranceId = $$v
                                          },
                                          expression: "insuranceId"
                                        }
                                      })
                                    ]
                                  }
                                },
                                {
                                  key: "damageNumber",
                                  fn: function(row) {
                                    return [
                                      _c("InputField", {
                                        staticClass: "full__width__input",
                                        model: {
                                          value: _vm.damageNumber,
                                          callback: function($$v) {
                                            _vm.damageNumber = $$v
                                          },
                                          expression: "damageNumber"
                                        }
                                      })
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              466079678
                            )
                          })
                        : _vm._e()
                    ]
                  },
                  proxy: true
                },
                {
                  key: "right-card",
                  fn: function() {
                    return [
                      _c("ComboBox", {
                        attrs: { label: "Kategorie", values: _vm.categories },
                        model: {
                          value: _vm.comboCategory,
                          callback: function($$v) {
                            _vm.comboCategory = $$v
                          },
                          expression: "comboCategory"
                        }
                      }),
                      _c("InputField", {
                        ref: "mscInternalIdInputField",
                        attrs: {
                          id: "mscInternalId",
                          label: "Interne Nummer",
                          validateUntouched: ""
                        },
                        model: {
                          value: _vm.mscInternalId,
                          callback: function($$v) {
                            _vm.mscInternalId = $$v
                          },
                          expression: "mscInternalId"
                        }
                      }),
                      _c("InputToggleSwitch", {
                        attrs: {
                          label: _vm.prfx + "Versicherungsnummer übernehmen",
                          inLineLabel: ""
                        },
                        model: {
                          value: _vm.transferInsuranceNumber,
                          callback: function($$v) {
                            _vm.transferInsuranceNumber = $$v
                          },
                          expression: "transferInsuranceNumber"
                        }
                      }),
                      _c(
                        "BaseButton",
                        {
                          attrs: { isSecondary: "", isSmall: "" },
                          on: { click: _vm.searchInsurances }
                        },
                        [_vm._v(" Versicherungssuche ")]
                      ),
                      _c(
                        "BaseButton",
                        {
                          attrs: {
                            isPrimary: "",
                            disabled: !_vm.mscInternalId,
                            isSmall: ""
                          },
                          on: { click: _vm.buttonVersicherungsdokumentZuordnen }
                        },
                        [_vm._v(" Versicherungsdokument zuordnen ")]
                      ),
                      _c(
                        "BaseButton",
                        {
                          attrs: { isPrimary: "", isSmall: "" },
                          on: {
                            click: function($event) {
                              return _vm.buttonNewCustomer(false)
                            }
                          }
                        },
                        [_vm._v(" Neuer Kunde ")]
                      )
                    ]
                  },
                  proxy: true
                },
                {
                  key: "bipro-card",
                  fn: function() {
                    return [
                      _vm.biproFileName
                        ? _c("InputField", {
                            attrs: { label: "BiPRO Dateiname", disabled: "" },
                            model: {
                              value: _vm.biproFileName,
                              callback: function($$v) {
                                _vm.biproFileName = $$v
                              },
                              expression: "biproFileName"
                            }
                          })
                        : _vm._e(),
                      _vm.biproForeignName
                        ? _c("InputField", {
                            attrs: { label: "BiPRO Kundenname", disabled: "" },
                            model: {
                              value: _vm.biproForeignName,
                              callback: function($$v) {
                                _vm.biproForeignName = $$v
                              },
                              expression: "biproForeignName"
                            }
                          })
                        : _vm._e(),
                      _vm.biproForeingVorname
                        ? _c("InputField", {
                            attrs: {
                              label: "BiPRO Kundenvorname",
                              disabled: ""
                            },
                            model: {
                              value: _vm.biproForeingVorname,
                              callback: function($$v) {
                                _vm.biproForeingVorname = $$v
                              },
                              expression: "biproForeingVorname"
                            }
                          })
                        : _vm._e(),
                      _vm.biproForeignGeburtsname
                        ? _c("InputField", {
                            attrs: {
                              label: "BiPRO Kundengeburtsname",
                              disabled: ""
                            },
                            model: {
                              value: _vm.biproForeignGeburtsname,
                              callback: function($$v) {
                                _vm.biproForeignGeburtsname = $$v
                              },
                              expression: "biproForeignGeburtsname"
                            }
                          })
                        : _vm._e(),
                      _vm.biproForeignAnshrift
                        ? _c("InputField", {
                            attrs: {
                              label: "BiPRO Kundenanschrift",
                              disabled: ""
                            },
                            model: {
                              value: _vm.biproForeignAnshrift,
                              callback: function($$v) {
                                _vm.biproForeignAnshrift = $$v
                              },
                              expression: "biproForeignAnshrift"
                            }
                          })
                        : _vm._e(),
                      _vm.biproForeignGeburtsdatum
                        ? _c("InputField", {
                            attrs: {
                              label: "BiPRO Kundengeburtsdatum",
                              disabled: ""
                            },
                            model: {
                              value: _vm.biproForeignGeburtsdatum,
                              callback: function($$v) {
                                _vm.biproForeignGeburtsdatum = $$v
                              },
                              expression: "biproForeignGeburtsdatum"
                            }
                          })
                        : _vm._e(),
                      _vm.biproMSCCategory
                        ? _c("InputField", {
                            attrs: {
                              label: "BiPRO MSC Kategorie",
                              disabled: ""
                            },
                            model: {
                              value: _vm.biproMSCCategory,
                              callback: function($$v) {
                                _vm.biproMSCCategory = $$v
                              },
                              expression: "biproMSCCategory"
                            }
                          })
                        : _vm._e(),
                      _vm.biproVersicherungsnummer
                        ? _c("InputField", {
                            attrs: {
                              label: "BiPRO Versicherungsnummer",
                              disabled: ""
                            },
                            model: {
                              value: _vm.biproVersicherungsnummer,
                              callback: function($$v) {
                                _vm.biproVersicherungsnummer = $$v
                              },
                              expression: "biproVersicherungsnummer"
                            }
                          })
                        : _vm._e()
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              2497912166
            )
          })
        : _vm._e(),
      _vm.showGesellschaftPanel
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _vm.isBroker
                ? _c(
                    "BaseButton",
                    {
                      attrs: { isSecondary: "" },
                      on: {
                        click: function($event) {
                          return _vm.buttonNewCustomer(true)
                        }
                      }
                    },
                    [
                      _vm._v(
                        " Neuer Kunde für den Vermittler " +
                          _vm._s(_vm.userId) +
                          " "
                      )
                    ]
                  )
                : _vm._e(),
              _c("ComboBox", {
                attrs: {
                  values: _vm.gesellschaftOptions,
                  label: "MSC Gesellschaft",
                  isComponentHalfSize: ""
                },
                model: {
                  value: _vm.comboGesellschaft,
                  callback: function($$v) {
                    _vm.comboGesellschaft = $$v
                  },
                  expression: "comboGesellschaft"
                }
              }),
              _c(
                "BaseButton",
                { attrs: { isPrimary: "" }, on: { click: _vm.advancedSearch } },
                [_vm._v(" Erweiterte Suche ")]
              )
            ],
            1
          )
        : _vm._e(),
      !_vm.loading
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _vm.rows.length
                ? _c("Table", {
                    attrs: {
                      title: "SmartMSC Vorschläge",
                      tableId: "7020d022-7380-4209-9afd-2c0ac46e0ce2",
                      headers: _vm.insuranceHeaders,
                      rows: _vm.rows,
                      rowsPerPage: 25,
                      selected: _vm.selectedRows,
                      headerActions: _vm.tableActions
                    },
                    on: {
                      selected: _vm.processSelection,
                      "click-kundennr": _vm.openCustomerNewTab,
                      "action-CONTRACT": _vm.actionContract,
                      "action-NEW_CONTRACT": _vm.openCustomerNewInsurance,
                      "action-TICKET_NEED_VERTRAG": _vm.sendMaklerTicket,
                      "click-ICON": _vm.actionAssign,
                      "headerAction-UNITE": _vm.actionUnite
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "insuranceId",
                          fn: function(row) {
                            return [
                              row.insuranceId
                                ? _c(
                                    "div",
                                    { class: { green: row.insuranceId.green } },
                                    [
                                      _vm._v(
                                        " " + _vm._s(row.insuranceId.text) + " "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          }
                        },
                        {
                          key: "name",
                          fn: function(row) {
                            return [
                              _c("div", { class: { green: row.name.green } }, [
                                _vm._v(" " + _vm._s(row.name.text) + " ")
                              ])
                            ]
                          }
                        },
                        {
                          key: "vorname",
                          fn: function(row) {
                            return [
                              _c(
                                "div",
                                { class: { green: row.vorname.green } },
                                [_vm._v(" " + _vm._s(row.vorname.text) + " ")]
                              )
                            ]
                          }
                        },
                        {
                          key: "street",
                          fn: function(row) {
                            return [
                              _c(
                                "div",
                                { class: { green: row.street.green } },
                                [_vm._v(" " + _vm._s(row.street.text) + " ")]
                              )
                            ]
                          }
                        },
                        {
                          key: "plz",
                          fn: function(row) {
                            return [
                              _c("div", { class: { green: row.plz.green } }, [
                                _vm._v(" " + _vm._s(row.plz.text) + " ")
                              ])
                            ]
                          }
                        },
                        {
                          key: "ort",
                          fn: function(row) {
                            return [
                              _c("div", { class: { green: row.ort.green } }, [
                                _vm._v(" " + _vm._s(row.ort.text) + " ")
                              ])
                            ]
                          }
                        },
                        {
                          key: "birthdate",
                          fn: function(row) {
                            return [
                              _c(
                                "div",
                                { class: { green: row.birthdate.green } },
                                [_vm._v(" " + _vm._s(row.birthdate.text) + " ")]
                              )
                            ]
                          }
                        },
                        {
                          key: "gesellnr",
                          fn: function(row) {
                            return [
                              _c(
                                "div",
                                { class: { green: row.gesellnr.green } },
                                [_vm._v(" " + _vm._s(row.gesellnr.text) + " ")]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      230661966
                    )
                  })
                : _c("NoData", {
                    attrs: { title: "SmartMSC Vorschläge", noIcon: "" }
                  })
            ],
            1
          )
        : _vm._e(),
      _vm.loading
        ? _c(
            "div",
            { staticClass: "box__container" },
            [_c("GhostLoading", { attrs: { type: "table" } })],
            1
          )
        : _vm._e(),
      _vm.uniteInsurance1 && _vm.uniteInsurance2
        ? _c("UniteContracts", {
            attrs: {
              insurance1: _vm.uniteInsurance1,
              insurance2: _vm.uniteInsurance2
            },
            on: {
              close: function($event) {
                _vm.uniteInsurance1 = null
                _vm.uniteInsurance2 = null
              }
            }
          })
        : _vm._e(),
      _c("PreviewPDF", {
        ref: "resizablePopup",
        attrs: {
          pdfUrl: _vm.pdfUrl,
          id: "c5ad9400-c09d-4258-ac12-6eaa8af8ae27"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }