var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      key: _vm.messageListReverse.length,
      ref: "container",
      staticClass: "container"
    },
    _vm._l(_vm.messageListReverse, function(message) {
      return _c("div", { key: message.messageId }, [
        _c(
          "div",
          {
            staticClass: "message",
            class: [
              message.direction == _vm.sending ? "chat-sender" : "chat-receiver"
            ]
          },
          [
            _vm.showMessageBody(message)
              ? _c(
                  "div",
                  { staticClass: "message-body" },
                  [
                    _c("span", {
                      staticClass: "message-text",
                      domProps: { innerHTML: _vm._s(message.messageText) }
                    }),
                    _c(
                      "div",
                      {
                        staticClass:
                          "message-real-download-link__container d-none"
                      },
                      _vm._l(message.imgAttachments, function(imgAttachment) {
                        return _c(
                          "DownloadLink",
                          {
                            key: imgAttachment.id,
                            attrs: {
                              asButton: "",
                              title: "Anhang",
                              downloadServicePath: "/getChatAtt",
                              filename: imgAttachment.name,
                              queryParams: {
                                id: imgAttachment.id
                              },
                              "data-img-attachment-id": imgAttachment.id
                            }
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: _vm.setImgSrc(
                                  imgAttachment,
                                  message.direction
                                )
                              }
                            })
                          ]
                        )
                      }),
                      1
                    ),
                    _vm._l(message.externalAttachments, function(
                      externalAttachment
                    ) {
                      return _c(
                        "div",
                        { key: externalAttachment.id },
                        [
                          _c(
                            "DownloadLink",
                            {
                              staticClass: "d-flex px-0",
                              attrs: {
                                asButton: "",
                                title: "Anhang",
                                downloadServicePath: "/getChatAtt",
                                filename: externalAttachment.name,
                                queryParams: {
                                  id: externalAttachment.id
                                }
                              }
                            },
                            [
                              _c("ph-paperclip", {
                                staticClass: "mr-8px cursor-pointer",
                                attrs: { size: 18 }
                              }),
                              _vm._v(
                                " " + _vm._s(externalAttachment.name) + " "
                              )
                            ],
                            1
                          ),
                          _vm.spreadsheetContent[externalAttachment.id]
                            ? _c(
                                "div",
                                { staticClass: "spreadsheet__container" },
                                [
                                  _c("Table", {
                                    attrs: {
                                      tableId:
                                        "f5efcb61-1f0a-4317-90a8-841b2f2b7f5e",
                                      scrollHorizontally: true,
                                      headers:
                                        _vm.spreadsheetContent[
                                          externalAttachment.id
                                        ].headers,
                                      rows:
                                        _vm.spreadsheetContent[
                                          externalAttachment.id
                                        ].rows
                                    }
                                  })
                                ],
                                1
                              )
                            : externalAttachment.renderSpreadsheet
                            ? _c("div", [_c("AnimatedSpinner")], 1)
                            : _vm._e()
                        ],
                        1
                      )
                    })
                  ],
                  2
                )
              : _vm._e(),
            message.isDeletionAllowed
              ? _c(
                  "div",
                  { staticClass: "chat-delete cursor-pointer" },
                  [
                    _c("ph-trash", {
                      attrs: { size: 18 },
                      on: {
                        click: function($event) {
                          return _vm.openDeleteMessageModal(message.messageId)
                        }
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]
        ),
        _c(
          "div",
          {
            staticClass: "sent-by",
            style: {
              justifyContent:
                message.direction == _vm.sending ? "flex-end" : "start"
            }
          },
          [
            message.direction == _vm.sending
              ? _c(
                  "div",
                  { staticClass: "chat-info" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(message.date) +
                        " | " +
                        _vm._s(message.fromName) +
                        " | "
                    ),
                    !message.isGelesen
                      ? _c("ph-check", { attrs: { size: 18 } })
                      : _c("ph-checks", { attrs: { size: 18 } })
                  ],
                  1
                )
              : _c("div", { staticClass: "chat-info" }, [
                  _vm._v(
                    " " +
                      _vm._s(message.date) +
                      " | " +
                      _vm._s(message.fromName) +
                      " "
                  )
                ])
          ]
        )
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }