export const MUTATION_PREFIX = 'ABRECHNUNG_MUTATION_';
export const ACTIONS_PREFIX = 'ABRECHNUNG_ACTIONS_';
export const GETTERS_PREFIX = 'ABRECHNUNG_GETTERS_';

export default {
  ACTIONS: {
    INIT: ACTIONS_PREFIX + 'INIT',
    GETDATA: ACTIONS_PREFIX + 'GETDATA',
    PDF_LISTE: ACTIONS_PREFIX + 'PDF_LISTE',
    BUCHUNGEN: ACTIONS_PREFIX + 'BUCHUNGEN'
  },
  MUTATIONS: {
    FORM: MUTATION_PREFIX + 'FORM',
  },
  GETTERS: {
    FORM: GETTERS_PREFIX + 'FORM',
  }
}