<template>
  <div>
    <div class="box__container">
      <div class="text-bold">Gebüren:</div>
      <InputField
        isComponentHalfSize
        v-model="form.strategieGebuehr"
        type="percent"
        :label="getLabelStrategiegebuehr"
        validateUntouched
        @change="changeStrategieGebuehr($event)"
      />
      <InputField
        isComponentHalfSize
        v-model="form.verwaltungsGebuehr"
        type="percent"
        :label="getLabelVermoegensverwaltungsgebuehr"
        validateUntouched
        @change="changeVerwaltungsGebuehr($event)"
      />
      <ComboBox
        isComponentHalfSize
        v-model="form.gebuehrenModell"
        :values="valuesGebuehrenModell"
        label="Gebührenmodell"
        :disabled="form.disabled"
        @change="updateGebuehrenModell($event)"
      />

      <InputField
        isComponentHalfSize
        v-model="form.kostenTransaktion"
        v-if="form.kostenTransaktion"
        type="currency"
        label="Kosten pro Transaktion"
        :disabled="true"
      />
      <InputField
        isComponentHalfSize
        v-model="form.kostenTransaktionProz"
        v-if="form.kostenTransaktionProz"
        type="percent"
        label="Transaktionskostenpauschale"
        :disabled="true"
      />
      <InputField
        isComponentHalfSize
        v-model="form.verwahrGebuehrMitAfp"
        v-if="form.verwahrGebuehrMitAfp"
        type="percent"
        label="Verwahrgebühr p.a. inkl.ges. MwSt."
        :disabled="true"
      />
      <InputField
        isComponentHalfSize
        v-model="form.verwahrGebuehrBetrag"
        v-if="form.verwahrGebuehrBetrag"
        type="currency"
        label="Verwahrgebühr (maximal p.a.)"
        :disabled="true"
      />
      <InputField
        isComponentHalfSize
        v-model="form.depotGebuehrFst"
        v-if="form.depotGebuehrFst"
        type="currency"
        label="Depotgebühr p.a. inkl. ges. MwSt."
        :disabled="true"
      />
      <InputField
        isComponentHalfSize
        v-model="form.depotGebuehrWeitere"
        v-if="form.depotGebuehrWeitere"
        type="currency"
        label="Depotgebühr p.a. inkl. ges. MwSt. weiteres Depot"
        :disabled="true"
      />
      <InputField
        isComponentHalfSize
        v-model="form.depotGebuehrFstProz"
        v-if="form.depotGebuehrFstProz"
        type="percent"
        label="Depotgebühr p.a. inkl. ges. MwSt."
        :disabled="true"
      />

      <InputField
        isComponentHalfSize
        v-model="form.bestandsrueckverguetung"
        type="percent"
        label="Bestandsrückvergütung der Fonds"
        :disabled="!schemaData.isInternVV"
        validateUntouched
        @change="
          updateStore(
            { bestandsrueckverguetung: formatNumber($event) },
            'settings'
          )
        "
      />
      <InputField
        isComponentHalfSize
        v-model="form.kostenProduktPa"
        type="percent"
        label="Produktkosten p.a."
        :disabled="!schemaData.isInternVV"
        validateUntouched
        @change="
          updateStore({ kostenProduktPa: formatNumber($event) }, 'settings')
        "
      />
      <InputField
        isComponentHalfSize
        v-model="form.jaehrlicheEntwicklung"
        type="percent"
        label="geschätzte jährliche Wertentwicklung"
        validateUntouched
        @change="
          updateStore(
            { jaehrlicheEntwicklung: formatNumber($event) },
            'settings'
          )
        "
      />
    </div>
  </div>
</template>

<script>
import VV_TYPES from "@/store/vermoegensverwaltung/types";
import CORE_TYPES from "@/store/core/types";

import { mapGetters } from "vuex";
import { parse, formatNumber } from "@/helpers/number-formatter.js";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import InputField from "@/components/core/forms/InputField.vue";
import mixin from "@/mixins/vermoegensverwaltung/vv-mixin.js";
import validator from "@/mixins/validator";
import { minValue, maxValue } from "@/mixins/validator/rules";

export default {
  mixins: [mixin, validator],
  validators: {
    form: {
      bestandsrueckverguetung: [
        minValue(0, "Die eingegebene Zahl muss positiv sein.", {
          forceTouch: true,
        }),
        maxValue(101, "Der Maximalwert beträgt 100 Euro.", {
          forceTouch: true,
        }),
      ],
      kostenProduktPa: [
        minValue(0, "Die eingegebene Zahl muss positiv sein.", {
          forceTouch: true,
        }),
        maxValue(101, "Der Maximalwert beträgt 100 Euro.", {
          forceTouch: true,
        }),
      ],
      jaehrlicheEntwicklung: [
        minValue(0, "Die eingegebene Zahl muss positiv sein.", {
          forceTouch: true,
        }),
        maxValue(101, "Der Maximalwert beträgt 100%.", { forceTouch: true }),
      ],
    },
  },
  components: {
    ComboBox,
    InputField,
  },
  data() {
    return {
      form: {
        strategieGebuehr: "",
        verwaltungsGebuehr: "",
        gebuehrenModell: "",
        kostenTransaktion: "",
        verwahrGebuehrMitAfp: "",
        verwahrGebuehrBetrag: "",
        depotGebuehrFst: "",
        depotGebuehrFstProz: "",
        depotGebuehrWeitere: "",
        bestandsrueckverguetung: "",
        kostenProduktPa: "",
        jaehrlicheEntwicklung: "",
      },
    };
  },
  mounted() {
    this.initValuesSettings();
    this.updateWarnings(true);
  },
  watch: {
    schemaData() {
      this.initValuesSettings();
    },
  },
  methods: {
    changeStrategieGebuehr(value) {
      let valueNumber = parse(value, 3);

      if (valueNumber < 0)
        this.$pushErrors(
          "form.strategieGebuehr",
          "Die Strategiegebühr muss positiv sein."
        );
      else if (this.isFa && valueNumber < 1.2)
        this.$pushErrors(
          "form.strategieGebuehr",
          "Die Strategiegebühr muss mindestens 1,2% betragen."
        );
      else if (this.isFa && valueNumber > 3.6)
        this.$pushErrors(
          "form.strategieGebuehr",
          "Die Strategiegebühr darf höchstens 3,6% betragen."
        );
      else if (valueNumber > 100)
        this.$pushErrors(
          "form.strategieGebuehr",
          "Die Strategiegebühr darf höchstens 100% betragen."
        );
      else
        this.updateStore({ strategieGebuehr: formatNumber(value) }, "settings");
    },
    changeVerwaltungsGebuehr(value) {
      let valueNumber = parse(value, 3);

      if (valueNumber < 0)
        this.$pushErrors(
          "form.verwaltungsGebuehr",
          "Die Vermögensverwaltungsgebühr muss positiv sein."
        );
      else if (this.isFa && valueNumber < 1.19)
        this.$pushErrors(
          "form.verwaltungsGebuehr",
          "Die Vermögensverwaltungsgebühr muss mindestens 1,19% betragen."
        );
      else if (this.isFa && valueNumber > 2.38)
        this.$pushErrors(
          "form.verwaltungsGebuehr",
          "Die Vermögensverwaltungsgebühr darf höchstens 2,38% betragen."
        );
      else if (valueNumber > 100)
        this.$pushErrors(
          "form.verwaltungsGebuehr",
          "Die Vermögensverwaltungsgebühr darf höchstens 100% betragen."
        );
      else
        this.updateStore(
          { verwaltungsGebuehr: formatNumber(value) },
          "settings"
        );
    },
    updateGebuehrenModell(value) {
      this.updateStore({ gebuehrenModell: value }, "settings");
      this.$store
        .dispatch(VV_TYPES.ACTIONS.GET_GEBUEHREN_MODELL_DATA, {
          gebuehrenModell: value,
        })
        .then((response) => {
          Object.assign(this.form, response.data);
        });
    },
  },
  computed: {
    ...mapGetters({
      isFa: CORE_TYPES.GETTERS.IS_FA,
    }),
    valuesGebuehrenModell() {
      return this.dataSettings?.valuesGebuehrenModell || [];
    },
    getLabelStrategiegebuehr() {
      return this.isFa
        ? "Strategiegebühr inkl. 20% ges. MwSt."
        : "Strategiegebühr inkl. ges. MwSt. (Einstiegsentgelt, einmalig)";
    },
    getLabelVermoegensverwaltungsgebuehr() {
      return this.isFa
        ? "Vermögensverwaltungsgebühr inkl. 19% ges MwSt. (jährlich)"
        : "Vermögensverwaltungsgebühr inkl. ges MwSt. (jährlich)";
    },
  },
};
</script>

<style scoped>
</style>