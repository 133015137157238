<template>
  <div>
    <PageHeaderTitleNavigation
      :title="appointmentConfig.title"
      :actions="headerActions"
      @action-BACK="back()"
    />

    <div class="box__container">
            <AppointmentEditCreate
        :subject="appointmentConfig.subject"
        :status="appointmentConfig.status"
        :isActivity="appointmentConfig.isActivity"
        :bereich="appointmentConfig.bereich"
        :vsnr="appointmentConfig.vsnr"
        :bereichId="appointmentConfig.bereichId"
        :bereichText="appointmentConfig.bereichText"
        :mode="appointmentConfig.currentMode"
        :selectedDate="appointmentConfig.selectedDate"
        :discardChanges="discardChanges"
        :additionalBeteiligte="appointmentConfig.additionalBeteiligte"
        />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CORE_TYPES from '@/store/core/types';
import CALENDAR_TYPES from '@/store/calendar/types';
import AppointmentEditCreate from '@/components/calendar/appointmentFiles/AppointmentEditCreate.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import {PageHeaderSimpleAction} from '@/components/core/header-title-navigation/page-header-utils';

export default {
  name: 'Appointment',
  components: {
    AppointmentEditCreate,
    OptionMenu,
    PageHeaderTitleNavigation,
  },

  props: {},

  data() {
    return {
    };
  },

  computed: {
    ...mapGetters({
      appointmentConfig: CALENDAR_TYPES.GETTERS.GET_APPOINTMENT_CONFIG,
    }),
    headerActions() {
      return this.appointmentConfig.back ? [
        PageHeaderSimpleAction('BACK', 'Abbrechen')
      ] : [];
    },
  },

  watch: {
  },

  async mounted() {
  },
  beforeDestroy(){
    
  },
  

  methods: {
    discardChanges() {
      // this.$router.back()
      if (this.appointmentConfig.discardChanges) {
        this.appointmentConfig.discardChanges()
      }
      this.$router.push({ path: this.appointmentConfig.back, query: { backAction: true, } });
    },
    back() {
      this.$router.push({ path: this.appointmentConfig.back, query: { backAction: true, } });
    },
  },

  validators: {},

  beforeRouteLeave(to, from, next) {
    next()
  }
};
</script>

<style scoped>
</style>
