var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mb-4" },
    [
      _c("PersonTopNavigation", {
        attrs: { title: "Person", subtitle: _vm.fullName }
      }),
      _c("DashboardPanel", {
        attrs: {
          id: "60883d97-2510-42ee-a963-40e2c5a18e15",
          data: _vm.dashboardData,
          canEdit: _vm.isEditable,
          ignoreUserLevelConfig: "",
          defaultOptionsMenu: _vm.customOptionsMenu,
          is3Columns: "",
          headerActions: _vm.headerActions,
          noPrimaryAction: false
        },
        on: {
          executeAction: function($event) {
            return _vm.handleExecuteAction($event)
          },
          "action-PDF": function($event) {
            return _vm.navigateTo("/persoenlichedaten/pdf-kundendaten")
          },
          "action-VCARD": function($event) {
            return _vm.navigateTo("/persoenlichedaten/vcard-kundendaten")
          },
          "action-COPY": function($event) {
            return _vm.openCopyCustomerModal()
          },
          "action-COPY-GROUP": function($event) {
            return _vm.openCopyCustomerGroupModal()
          },
          "action-BRIEF": function($event) {
            return _vm.navigateTo("/communication/mailcomposer-brief")
          },
          "action-EMAIL": function($event) {
            return _vm.navigateTo("/communication/mailcomposer")
          },
          "action-TICKET": function($event) {
            return _vm.navigateTo("/communication/tickets/new-ticket")
          },
          "action-ACTIVITY": function($event) {
            return _vm.navigateTo("/communication/postfach/aktivitaeten")
          },
          "action-HOBBYS-VORLIEBEN": function($event) {
            return _vm.openStep("hobbys-vorlieben")
          },
          "action-COURTAGE": function($event) {
            return _vm.openStep("courtage")
          }
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [_vm._v(" " + _vm._s(_vm.fullname) + " ")]
            },
            proxy: true
          },
          {
            key: "subtitle",
            fn: function() {
              return [
                _vm.userIdUnterNr
                  ? [
                      _c("span", { staticClass: "subtitle-part" }, [
                        _vm._v(_vm._s(_vm.userIdUnterNr))
                      ])
                    ]
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("BaseModal", {
        ref: "copyCustomerModal",
        attrs: { showDefaultButtons: false },
        scopedSlots: _vm._u([
          {
            key: "modalTitle",
            fn: function() {
              return [_vm._v("Kunde kopieren")]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return [
                _vm._v(
                  " Soll der Kunde unter einer weiteren Kundennummer angelegt werden? "
                )
              ]
            },
            proxy: true
          },
          {
            key: "footer",
            fn: function() {
              return [
                _c(
                  "BaseButton",
                  {
                    staticClass: "mr-16px",
                    attrs: { isSecondary: "", disabled: _vm.loading },
                    on: {
                      click: function($event) {
                        return _vm.closeCopyCustomerModal()
                      }
                    }
                  },
                  [_vm._v("Nein")]
                ),
                _c(
                  "BaseButton",
                  {
                    attrs: { disabled: _vm.loading },
                    on: {
                      click: function($event) {
                        return _vm.copyCustomer()
                      }
                    }
                  },
                  [
                    _vm._v(" Ja "),
                    _vm.loading ? _c("AnimatedSpinner") : _vm._e()
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("BaseModal", {
        ref: "copyCustomerGroupModal",
        attrs: { showDefaultButtons: false },
        scopedSlots: _vm._u([
          {
            key: "modalTitle",
            fn: function() {
              return [_vm._v("Gruppenoberhaupt erzeugen")]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return [
                _vm._v(
                  " Soll der Kunde unter einer weiteren Kundennummer als Kundengruppe nochmal angelegt werden? "
                )
              ]
            },
            proxy: true
          },
          {
            key: "footer",
            fn: function() {
              return [
                _c(
                  "BaseButton",
                  {
                    staticClass: "mr-16px",
                    attrs: { isSecondary: "", disabled: _vm.loading },
                    on: {
                      click: function($event) {
                        return _vm.closeCopyCustomerGroupModal()
                      }
                    }
                  },
                  [_vm._v("Nein")]
                ),
                _c(
                  "BaseButton",
                  {
                    attrs: { disabled: _vm.loading },
                    on: {
                      click: function($event) {
                        return _vm.copyCustomerGroup()
                      }
                    }
                  },
                  [
                    _vm._v(" Ja "),
                    _vm.loading ? _c("AnimatedSpinner") : _vm._e()
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "BaseModal",
        {
          ref: "editModal",
          attrs: {
            modalTitle: _vm.modalTitle,
            size: "lg",
            showCancelButton: false,
            showConfirmButton: false
          },
          on: {
            onCloseButton: function($event) {
              return _vm.saveChanges()
            }
          }
        },
        [
          _vm.editComponent == "stammdaten"
            ? _c("Stammdaten", {
                attrs: { substepProperty: _vm.substepProperty }
              })
            : _vm._e(),
          _vm.editComponent == "kommunikationsdaten"
            ? _c("Kommunikationsdaten")
            : _vm._e(),
          _vm.editComponent == "arbeitgeber" ? _c("Arbeitgeber") : _vm._e(),
          _vm.editComponent == "schulbildung-und-qualifikationen"
            ? _c("SchulbildungUndQualifikationen")
            : _vm._e(),
          _vm.editComponent == "pep"
            ? _c("PolitischExponiertePerson")
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }