<template>
  <div>
    <PageHeaderTitleNavigation :title="title"  />

    <BaseFilter
        :title="title2+'-Filter'"
        filterId="Abrechnungen"
        :configFilter="filterConfig"
        :metadata="filterOptionen"
        :defaultOptions="defaultOptions"
        showSaveButton
        :immidiateSearch="false"
        @onFilter="onFilter"
    />

    <div class="box__container" >
      <Table v-if="rows.length" 
        :title=" 'Ausgewählte '+title2+' exportieren'"
        :headers="headers"
        :rows="rows"
        :rowsPerPage="10"
        :headerActions="headerActions"
        :enableToggleHorizontalScrollingConfig="item=='buchungspositionen'"
        v-model="selected"
        :exportConfig="exportConfig"
        @headerAction-SEND="doAction('email')"
        @headerAction-DOWNLOAD="doAction('download')"
      >
      </Table>
      <NoData v-else/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ABRECHNUNG_TYPES from '@/store/abrechnungen/types';
import CORE_TYPES from "@/store/core/types";
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import BaseFilter from "@/components/core/BaseFilter.vue";
import Table from "@/components/table2/Table.vue";
import {TextColumn, DateColumn, CurrencyColumn, NumberColumn} from "@/components/table2/table_util.js";
import dayjs from 'dayjs';
import NoData from '@/components/core/NoData.vue';
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';
import { viewDocument } from '@/components/core/download/DownloadLink.vue';
import { base64ToArrayBuffer } from '@/helpers/download-helper.js';
import {formatNumber} from '@/helpers/number-formatter.js';

export default {
  components: {
    BaseFilter, Table, OptionMenu, PageHeaderTitleNavigation, NoData,
  },
  props: {
  },
  data: function() {
    return {
      filterConfig: {
        checkDefaultSearchInput: false,
        cbDefSearchInput: null,
        hideFirstColumn: true,
        filterZurucksetzen: null,
        noResetOnDefaultSearchInputExit: true,
      },
      item: ',',
      rows: [],
      selected: [],
      headers: {
        lockedLeft: [TextColumn("rech", "Abrechnungen")],
        center: [TextColumn("typ", "Abrechnungstyp")],
        lockedRight: [],
      },
      buchungsheader: [],
      buchungstitel: '',
    }
  },
  mounted() {
    this.item = this.$route.path.replace(/^.*\//, '');
  },
  computed: {
    ...mapGetters({
      form: ABRECHNUNG_TYPES.GETTERS.FORM,
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
      userID: CORE_TYPES.GETTERS.GET_USER_ID,
    }),
    filterOptionen() {
      if (this.item == 'abrechnungsliste' ) {
        return [
          {
            type: 'group',
            key: 'allgemein',
            label: 'Allgemein',
            menuItems: [
              {
                type: 'dateRange',
                label: 'Zeitraum',
                key: 'zeitraum',
                emptyDenied: true,
              },
              {
                type: 'combobox',
                label: 'Rechnungstyp',
                key: 'rechtyp',
                comboOptions: [{label: 'Fonds', value: 'F'}, {label: 'Versicherung', value: 'V'}],
                emptyDenied: true,
              },
            ],
          },
        ]
      }
      const comboOptions = [{label: 'Wertpapiere', value: 1}, {label: 'Versicherungen', value: 2}, {label: 'Kredite', value: 3}
      , {label: 'Sonstige Produkte', value: 4}, {label: 'zus. Abrechnung', value: 5}]
      return [
        {
          type: 'group',
          key: 'allgemein',
          label: 'Allgemein',
          menuItems: [
            {
              type: 'dateRange',
              label: 'Zeitraum',
              key: 'zeitraum',
              emptyDenied: true,
            },
            {
              type: 'combobox',
              label: 'Buchungstyp',
              key: 'rechtyp',
              comboOptions,
              emptyDenied: true,
            },
            {
              type: 'text',
              label: 'Kundenname',
              key: 'kname',
            },
            {
              type: 'text',
              label: 'Kundenvorname',
              key: 'kvorname',
            },
            {
              type: 'text',
              label: 'Pollizzennr',
              key: 'polnr',
            },
          ],
        },
      ]
    },
    defaultOptions() {
      const end = new Date();
      let start = new Date(end.getFullYear(), 0, 1);
      switch (end.getMonth()) {
        case 0: 
          start = '01.10.' + (end.getFullYear() - 1);
          break;
        case 10: case 11:
          start = '01.10.' + end.getFullYear();
          break;
        case 1: case 2: case 3:
          start = '01.01.' + end.getFullYear();
          break;
        case 4: case 5: case 6:
          start = '01.04.' + end.getFullYear();
          break;
        case 7: case 8: case 9:
          start = '01.07.' + end.getFullYear();
          break;
        default:
          break;
      }
      return {
        zeitraum: {
          value: [{
            key: "min",
            value: start,
          },
          {
            key: "max",
            value: dayjs(end).format('DD.MM.YYYY'),
          }],
        },
        rechtyp: {value: (this.item == 'abrechnungsliste' ? 'F' : 1)}
      }
    },
    title() {
      return this.item == 'abrechnungsliste' ? 'Mehrere Abrechnungen versenden oder herunterladen' 
          : 'Buchungspositionen exportieren';
    },
    title2() {
      return this.item == 'abrechnungsliste' ? 'Abrechnungen' : 'Buchungspositionen';
    },
    rechnungen() {
      let result = [];
      const prefix = 'Abrechnung_' + this.userID + '_';
      if (this.form?.combos?.abrechnungsdatum) {
        result = this.form.combos.abrechnungsdatum.map( rd => ({
          rech: prefix + rd.value.slice(6) + '_' +rd.value.slice(3,5) + '_' +rd.value.slice(0,2),
          typ: rd.label.slice(rd.label.length - 1) == 'F' ? 'Fonds' : 'Versicherung',
          time: rd.time,
          datum: rd.value
        }))
      } // Fonds Versicherung
      return result;
    },
    headerActions() {
      if (this.item == 'abrechnungsliste') {
        return [
          PageHeaderSimpleAction('DOWNLOAD', 'Abrechnungen herunterladen').withDisabled(() =>this.selected.length == 0),
          PageHeaderSimpleAction('SEND', 'E-Mail versenden').withDisabled(() =>this.selected.length == 0)
        ];
      } else {
        // return [
        //   PageHeaderSimpleAction('DOWNLOAD', 'Buchungspositionen herunterladen').withDisabled(() =>this.selected.length == 0),
        // ];
        return [];
      }
    },
    exportConfig() { 
      if (this.item == 'abrechnungsliste' || !this.selected.length) {
        return null;
      }
      return {
        pdf: true, 
        xls: true, 
        name: 'Buchungen_' + this.userID,
        title: this.buchungstitel,
        dispatch: this.exportAsFile,
      }
    },
  },
  watch: {
    
  },
  methods: {
    onFilter(filter) {
      let ix = filter[0].key == 'zeitraum' ? 0 : 1;
      let von = filter[ix].value[0].value, parts;
      let bis = filter[ix].value[1].value;
      let typ = null;
      if (filter.length > 1) {
        ix = filter[1].key == 'rechtyp' ? 1 : 0;
        typ = filter[ix].value;
      }
      if (this.item == 'abrechnungsliste') {
        if (typeof von == 'string') {
          parts = von.split('.').map(p => parseInt(p, 10));
          von = new Date(parts[2], parts[1] - 1, parts[0]);
        }
        if (typeof bis == 'string') {
          parts = bis.split('.').map(p => parseInt(p, 10));
          bis = new Date(parts[2], parts[1] - 1, parts[0]);
        }
        this.rows = this.rechnungen.filter (item => item.time >= von.getTime() && item.time <= bis.getTime() && (!typ || typ==item.typ.charAt(0)))
        this.selected= this.rows;
      } else {
        const params = {
          useStruktur: false,
          typ,
          datumListe: [von, bis],
          action: 'buchungspositionen'
        }
        filter.forEach(f => {
          if (f.value && (f.key=='kname' || f.key=='kvorname' || f.key=='polnr')) {
            params[f.key] = f.value;
          }
        })
        this.$store.dispatch(ABRECHNUNG_TYPES.ACTIONS.BUCHUNGEN, params)
        .then((resp) => {
          if ( resp.data.headers ){
            const headers = {
              lockedLeft: [DateColumn("abrechnungsdatum", "Abrechnungsdatum")],
              center: [],
              lockedRight: [],
            }
            let col;
            this.buchungstitel = 'Buchungspositionen ' + this.userID + ' von '+von+' bis '+bis,
            this.buchungsheader = [resp.data.headers[0]];
            for (let k = 1; k < resp.data.headers.length; k++) {
              col = resp.data.headers[k];
              if (col[0].indexOf('datum') !== -1) {
                headers.center.push(DateColumn(col[0], col[1]));
              } else if (col[2] == 'n') {
                if (col[0].indexOf('summe') !== -1 || col[0].indexOf('preis') !== -1 || col[0].indexOf('mwst') !== -1) {
                  headers.center.push(CurrencyColumn(col[0], col[1]));
                  col[2] = 'e';
                } else {
                  headers.center.push(NumberColumn(col[0], col[1]));
                }
              } else {
                headers.center.push(TextColumn(col[0], col[1]));
              }
              this.buchungsheader.push(col);
            }
            this.headers = headers;
            this.rows = resp.data.rows;
            this.selected= this.rows;
          }
        });
      }
    },
    async doAction(arg) {
      if (this.item == 'abrechnungsliste') {
        const params = {
          [arg]: true,
          details: true,
          includeSummarypage: true,
          datumListe: this.selected.map(s => s.datum),
          action: arg
        }
        const response = await this.$store.dispatch(ABRECHNUNG_TYPES.ACTIONS.PDF_LISTE, params);
        if (response.data?.pdf) {
          const file = base64ToArrayBuffer(response.data.pdf);
          viewDocument({
            data: file,
            filename: 'Abrechnungen_' + this.userID + '.pdf',
            contentType: 'application/pdf',
          }, true);
        }
        if (response.data?.versendet) {
        }
      } else {
        // const params = {
        //   useStruktur: false,
        //   datumListe: this.selected.map(s => s.datum),
        //   action: arg
        // }
        // const response = await this.$store.dispatch(ABRECHNUNG_TYPES.ACTIONS.BUCHUNGEN, params);
        // if (response.data?.xlsx) {
        //   const file = base64ToArrayBuffer(response.data.xlsx);
        //   viewDocument({
        //     data: file,
        //     filename: 'Buchungen_' + this.userID + '.xlsx',
        //     contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        //     fileExtension: 'xlsx',
        //   }, true);
        // }
      }
    },
    exportAsFile(fileEnding) {
      const expRows = this.selected.map(row => {
        return this.buchungsheader.map(h => {
          let result = '';
          switch (h[2]) {
            case 'e':
              result = formatNumber(row[h[0]], 2) + '€';
              break;
            case 'n':
              result = formatNumber(row[h[0]], 2);
              break;
            default:
              result = row[h[0]] || '';
              break;
          }
          return result;
        })
      })
      const params = {
          create: fileEnding,
          filename: 'Buchungen_' + this.userID + '.' + fileEnding,
          title: this.buchungstitel,
          columns: this.buchungsheader.map(header => header[1]),
          rows: expRows,
          printMaklerLogo: '0',
      };
      return this.$store.dispatch(CORE_TYPES.ACTIONS.CREATE_XLS_OR_PDF, params);
    },
  },
}
</script>

<style scoped>

</style>