var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "zusaetzliche-person-daten-view" },
    [
      _c("PersonTopNavigation", {
        attrs: { title: "Person", subtitle: _vm.fullName }
      }),
      _c("StepperForm", {
        attrs: {
          stepType: "tab",
          stepperName: "person-data",
          stepperMediator: _vm.tabsStepperMediator,
          hasFertig: false,
          selectedStepKey: _vm.step,
          selectedSubstepKey: _vm.substep
        },
        on: {
          "set-step": function($event) {
            return _vm.navigateToPath($event)
          },
          "set-substep": function($event) {
            return _vm.navigateToSubstepPath($event)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "headerTemplate",
            fn: function() {
              return [
                _c("HinweiseUndFehler", {
                  attrs: { errors: _vm.warnings, hints: _vm.hints },
                  on: {
                    "set-step": function($event) {
                      return _vm.navigateToPath($event)
                    },
                    "set-substep": function($event) {
                      return _vm.navigateToSubstepPath($event)
                    }
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "contentTemplate",
            fn: function() {
              return [_c("router-view")]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }