<template>
  <div v-if="showAddTable">
    <BaseToolbarButton 
      title="Neue Tabelle hinzufügen"
      :icon="icon"
      :disabled="disabled"
      :showLabel="showLabel"
      class="mr-0"
      @click="action"/>

    <BaseToolbarTableEditor 
      v-if="showTableModal" 
      :tableForm="tableForm"
      @close="showTableModal = false"
      @onConfirmButton="setTable"/>
  </div>
</template>

<script>
import { PhTable } from 'phosphor-vue';
import BaseToolbarTableEditor from '../../base-components/BaseToolbarTableEditor.vue';
import BaseToolbarButton from '../../base-components/BaseToolbarButton.vue';

export default {
  components: {
    BaseToolbarTableEditor,
    BaseToolbarButton,
  },

  props: {
    editor: {
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
    forceShowAddTable: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      showTableModal: false,
      tableForm: {},
    }
  },

  computed: {
    icon() {
      return PhTable;
    },
    showAddTable() {
      return this.forceShowAddTable || !this.editor?.isActive('table')
    }
  },

  methods: {
    action() {
      this.showTableModal = true;
    },
    setTable(tableForm) {
      const table = {
        rows: tableForm.rows,
        cols: tableForm.cols,
        withHeaderRow: tableForm.withHeaderRow,
      }

      this.editor?.chain().focus().insertTable(table).run();
      
      if (tableForm.border) {
        this.editor?.chain().focus().setTableBorder(tableForm.border).run();
      }
    }
  }

}
</script>
