var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "input-forms__container combo-box__container",
      class: { "input-forms__container--embedded": _vm.isEmbedded }
    },
    [
      _c(
        "div",
        {
          staticClass: "input-forms__label-container",
          class: { "inline-container": _vm.inlineContainer }
        },
        [
          _vm.label
            ? _c("div", {
                staticClass: "input-forms__label-content",
                class: _vm.labelClass,
                domProps: { innerHTML: _vm._s(_vm.sanitize(_vm.label)) }
              })
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "input-forms__input-container",
              class: [_vm.inputContainerClasses]
            },
            [
              _c(
                "div",
                {
                  staticClass: "combo-box--input-container",
                  class: { "has-no-option-selected": !_vm.selectedOption }
                },
                [
                  _c("input", {
                    ref: "inputEl",
                    staticClass:
                      "combo-box--input-field fc-select input-forms__input-field unselectable",
                    class: _vm.inputClasses,
                    attrs: {
                      type: "text",
                      placeholder: _vm.placeholder,
                      disabled: _vm.disabled,
                      inputmode: "none"
                    },
                    domProps: { value: _vm.selectedLabel },
                    on: {
                      click: function($event) {
                        return _vm.toggle($event)
                      },
                      focus: _vm.onFocus,
                      blur: _vm.onBlur,
                      keypress: function($event) {
                        return _vm.onKeypress($event)
                      },
                      keydown: [
                        function($event) {
                          return _vm.onArrowVerticalMove($event)
                        },
                        function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.onEnter.apply(null, arguments)
                        },
                        function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k($event.keyCode, "space", 32, $event.key, [
                              " ",
                              "Spacebar"
                            ])
                          ) {
                            return null
                          }
                          return _vm.onSpace.apply(null, arguments)
                        },
                        function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
                          ) {
                            return null
                          }
                          return _vm.onTab.apply(null, arguments)
                        }
                      ]
                    }
                  }),
                  _c(
                    "div",
                    {
                      staticClass:
                        "combo-box--input-label fc-select input-forms__input-field unselectable",
                      class: _vm.inputClasses
                    },
                    [
                      _vm.$slots[_vm.selectedValue]
                        ? _vm._t(_vm.selectedValue)
                        : _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.sanitize(
                                  _vm.selectedOption
                                    ? _vm.selectedLabel
                                    : _vm.placeholder
                                )
                              )
                            }
                          })
                    ],
                    2
                  )
                ]
              ),
              _vm.isShowList
                ? _c(
                    "div",
                    {
                      ref: "dropListEl",
                      staticClass: "combo-box--list__container unselectable",
                      attrs: { "data-dropdown-not-close": "" }
                    },
                    [
                      _c(
                        "ul",
                        {
                          staticClass: "combo-box--list__scroll",
                          on: {
                            mousemove: _vm.onHover,
                            mouseleave: _vm.resetHoverValue
                          }
                        },
                        _vm._l(_vm.allValues, function(item, index) {
                          return _c(
                            "li",
                            {
                              key: index,
                              staticClass: "combo-box--list-option",
                              class: {
                                hover: item.value === _vm.hoverValue,
                                selected: _vm.isSelected(item.value),
                                disabled: item.disabled,
                                bold: _vm.isBold(item.value)
                              },
                              style: item.style,
                              attrs: {
                                tabindex: index + 1,
                                "data-value": item.value
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.selectAndRefocusOnInput(item)
                                }
                              }
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "option-icon" },
                                [
                                  _vm.isSelected(item.value)
                                    ? _c("PhCheck", { attrs: { size: 16 } })
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm.$slots[item.value]
                                ? _vm._t(item.value)
                                : _c("span", {
                                    staticClass: "option-label",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.sanitize(
                                          item.label ? item.label : item.value
                                        )
                                      )
                                    }
                                  })
                            ],
                            2
                          )
                        }),
                        0
                      )
                    ]
                  )
                : _vm._e()
            ]
          ),
          _vm.isValidationConfigured()
            ? [
                !_vm.suppressValidationMessage && _vm.validation
                  ? _c(
                      "div",
                      { key: _vm.validation.updated },
                      _vm._l(
                        _vm.validation.getErrors(
                          _vm.validationPath,
                          _vm.validateUntouched
                        ),
                        function(error) {
                          return _c(
                            "div",
                            { key: error, staticClass: "fc-form-danger" },
                            [_vm._v(" " + _vm._s(error) + " ")]
                          )
                        }
                      ),
                      0
                    )
                  : _vm._e()
              ]
            : _vm._e(),
          _vm.hint
            ? _c("div", { staticClass: "input-forms__hint-content" }, [
                _vm._v(_vm._s(_vm.hint))
              ])
            : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }