<template #BalanceSheetView>
  <div v-if="balanceSum">
    <div v-if="!isMobileNativeContext">
      <div class="row align-items-center justify-content-center">
        <div class="col-4 hide-on-small-screen" v-if="!isSelectableView">
          <PieChart 
            v-if="balanceSum.isLoaded && balanceSum.assetChartData" 
            :chartData="getEChartData(balanceSum.assetChartData)"
            @clickchart="handleChartClick($event, 'profits')" 
            format="percent"
            height="250px"
          />
          <GhostLoading v-else-if="!balanceSum.isLoaded" type="block" :config="{ block: { height: 250, }, }" />
        </div>

        <div class="col-12 col-md-4">
          <div v-if="balanceSum.isLoaded" class="box__container-wrap justify-items-center align-items-center">
            <span class="text-truncate">Gesamtvermögen:</span>
            <template v-if="!isSelectableView">
              <span v-if="balanceSum.isLoaded || balanceSum.totalAssets !== null">
                <CurrencyLabel :value="balanceSum.totalAssets"/>
              </span>
            </template>
            <template v-else>
              <CurrencyLabel :value="allBalanceSum"/>
            </template>
          </div>
          <GhostLoading v-else-if="!balanceSum.isLoaded">
            <Block type="paragraph" />
            <Block type="paragraph" />
          </GhostLoading>
        </div>

        <div class="col-4 hide-on-small-screen" v-if="!isSelectableView">
          <PieChart 
            v-if="balanceSum.isLoaded && balanceSum.liabilityChartData" 
            :chartData="getEChartData(balanceSum.liabilityChartData)" 
            @clickchart="handleChartClick($event, 'loss')" 
            format="percent"
            height="250px"
          />
          <GhostLoading v-else-if="!balanceSum.isLoaded" type="block" :config="{ block: { height: 250, }, }" />
        </div>
      </div>

      <div class="row mb-0">
        <!--start: vermogen side -->
        <div class="col-12 hide-on-large-screen" v-if="!isSelectableView">
          <PieChart 
            v-if="balanceSum.isLoaded && balanceSum.assetChartData"
            :chartData="getEChartData(balanceSum.assetChartData)"
            @clickchart="handleChartClick($event, 'profits')" 
            format="percent"
            height="250px"
          />
          <GhostLoading v-else-if="!balanceSum.isLoaded" type="block" :config="{ block: { height: 250, }, }" />
        </div>

        <div class="col-12 col-md-6">
          <template v-if="balanceSum.isLoaded">
          <div class="row">
            <div class="col-12 d-flex justify-content-between" v-if="balanceSum.isLoaded && balanceSum.portfolios.length && getSumWertpapiere(balanceSum.portfolios)">
              <div :class="{'clickable': isClickable('Wertpapiere')}" @click="navigateToPage('Wertpapiere')">
                <PhCircle :size="16" weight="fill" :color="getColor(getLeftIndex('Wertpapiere'))" v-if="!isSelectableView" />
                <InputCheckBoxItem v-model="selectedBalanceValue[0]" class="col-left" @input="onSelectedBalance($event,0, 1)" v-if="isSelectableView" />

                <span> Wertpapiere</span>
                <span v-if="balanceSum.isLoaded || balanceSum.portfolios.length"> ({{getPercentage('Wertpapiere', balanceSum, 1)}}%)</span>:
              </div>
              <div class="text-right" v-if="balanceSum.isLoaded || balanceSum.portfolios.length">
                <span v-if="balanceSum.isLoaded && !balanceSum.portfoliosError && balanceSum.portfolios && balanceSum.portfolios.length">
                  <CurrencyLabel :value="getSumWertpapiere(balanceSum.portfolios)"/>
                </span>
                <CurrencyLabel v-else :value="0"/>
                <app-data-loading-failed v-if="balanceSum.portfolios.isError"></app-data-loading-failed>
                <span><div :isLoading="!balanceSum.isLoaded && !balanceSum.portfolios.length" class="ghost sm"></div></span>
              </div>
            </div>
          </div>

          <div class="row" v-if="showVertpapiereVorsorgeSeparated && balanceSum.isLoaded && balanceSum.portfolios.length && getSumWertpapiereVorsorge(balanceSum.portfolios)">
            <div class="col-12 d-flex justify-content-between">
              <div :class="{'clickable': isClickable('Wertpapiere Vorsorge')}" @click="navigateToPage('Wertpapiere Vorsorge')">
                <PhCircle :size="16" weight="fill" :color="getColor(getLeftIndex('Wertpapiere Vorsorge'))" />
                <span> Wertpapiere Vorsorge</span>
                <span v-if="balanceSum.isLoaded || balanceSum.portfolios.length"> ({{getPercentage('Wertpapiere Vorsorge', balanceSum, 1)}}%)</span>:
              </div>
              <div class="text-right" v-if="balanceSum.isLoaded || balanceSum.portfolios.length">
                <span v-if="!balanceSum.portfolios.isError && (balanceSum.isLoaded)">
                  <CurrencyLabel :value="getSumWertpapiereVorsorge(balanceSum.portfolios)"/>
                </span>
                <CurrencyLabel v-else :value="0"/>
                <app-data-loading-failed v-if="balanceSum.portfolios.isError"></app-data-loading-failed>
                <span><div :isLoading="!balanceSum.isLoaded && !balanceSum.portfolios.length" class="ghost sm"></div></span>
              </div>
            </div>
          </div>

          <template v-for="(l, li) of balanceSum.profits">
            <template v-if="showCategory(l)">
              <div class="row" :key="li">
                <div class="col-12 d-flex justify-content-between">
                  <div :class="{'clickable': isClickable(l.header)}" @click="navigateToPage(l.header, 'profits')">
                    <PhCircle :size="16" weight="fill" :color="getColor(getLeftIndex(l.header))" v-if="!isSelectableView" />
                    <InputCheckBoxItem v-model="selectedBalanceValue[li+(showVertpapiereVorsorgeSeparated ? 2 : 1)]" class="col-left" @input="onSelectedBalance($event,li+(showVertpapiereVorsorgeSeparated ? 2 : 1), 1)" v-if="isSelectableView" />
                    <span class="text-truncate">
                        {{l.header}}
                        <span v-if="balanceSum.isLoaded || l.isLoaded"> ({{getPercentage(l.header, balanceSum, 1)}}%)</span>:
                        <span><div :isLoading="!balanceSum.isLoaded && !l.isLoaded" class="ghost sm"></div></span>
                    </span>
                  </div>
                  <div class="text-right" v-if="balanceSum.isLoaded || l.isLoaded">
                    <span v-if="!l.isError && (balanceSum.isLoaded || l.isLoaded)">
                      <CurrencyLabel :value="getSum(l)"/>
                    </span>
                    <CurrencyLabel v-else :value="0"/>
                    <span><div :isLoading="!balanceSum.isLoaded && !l.isLoaded" class="ghost sm"></div></span>
                  </div>
                </div>
              </div>
              </template>
          </template>
          </template>
          <GhostLoading v-else-if="!balanceSum.isLoaded">
            <Block type="title" width="100%" />
            <Block type="title" width="100%" />
            <Block type="title" width="100%" />
            <Block type="title" width="100%" />
            <Block type="title" width="100%" />
            <Block type="title" width="100%" />
            <Block type="title" width="100%" />
          </GhostLoading>

          <div class="row mt-3 sum-row hide-on-large-screen">
            <hr class="col-12">
            <div class="col-7">
              <span>Vermögen:</span>
            </div>
            <div class="col-5 text-right ">
              <span v-if="!balanceSum.portfolios.isError && (balanceSum.isLoaded ||  balanceSum.wealth)">
                <CurrencyLabel :value="balanceSum.wealth"/>
              </span>
              <GhostLoading v-else-if="!balanceSum.isLoaded">
                <Block type="title" width="100%" />
              </GhostLoading>
              <app-data-loading-failed v-if="balanceSum.portfolios.isError"></app-data-loading-failed>
            </div>
          </div>

        </div>
        <!--end:  vermogen side -->

        <!--start: verbindlichkeiten side -->
        <div class="col-12 hide-on-large-screen" v-if="!isSelectableView">
          <PieChart 
            v-if="balanceSum.isLoaded && balanceSum.liabilityChartData"
            :chartData="getEChartData(balanceSum.liabilityChartData)"
            @clickchart="handleChartClick($event, 'loss')"
            format="percent"
            height="250px"
          />
          <GhostLoading v-else-if="!balanceSum.isLoaded" type="block" :config="{ block: { height: 250, }, }" />
        </div>

        <div class="col-12 col-md-6">
          <template v-if="balanceSum.isLoaded">
          <template v-for="(r, ri) of balanceSum.loss">
            <div :key="'ri'+ri">
              <div class="row">
                <div class="col-12 d-flex justify-content-between">
                  <div class="m-0" @click="navigateToPage(r.header, 'loss')" :class="{'clickable': isClickable(r.header)}">
                    <span class="text-truncate">
                      <PhCircle :size="16" weight="fill" :color="getColor(ri)" v-if="!isSelectableView" />
                      <InputCheckBoxItem v-model="selectedBalanceLossValue[ri]" class="col-left" @input="onSelectedBalance($event,ri, -1)" v-if="isSelectableView" />
                        {{r.header}}
                      <span v-if="balanceSum.isLoaded || r.isLoaded"> 
                        ({{getPercentage(r.header, balanceSum, -1)}}%)
                      </span>:
                      <template v-if="r.empty">{{r.header}}:</template>
                    </span>
                  </div>
                  <div class=" text-right" v-if="balanceSum.isLoaded || r.isLoaded">
                    <span v-if="!r.isError && (balanceSum.isLoaded || r.isLoaded)">
                      <CurrencyLabel :value="getSum(r)"/>
                    </span>
                    <CurrencyLabel v-else :value="0"/>
                    <span><div :isLoading="!balanceSum.isLoaded && !r.isLoaded" class="ghost sm"></div></span>
                  </div>
                </div>
              </div>
            </div>

          </template>
          </template>
          <GhostLoading v-else-if="!balanceSum.isLoaded">
            <Block type="title" width="100%" />
            <Block type="title" width="100%" />
            <Block type="title" width="100%" />
          </GhostLoading>

          <div class="row mt-3 mb-0 sum-row hide-on-large-screen">
            <hr class="col-12">
            <div class="col-7">
              <span>Verbindlichkeiten:</span>
            </div>
            <div class="col-5 text-right ">
              <span v-if="balanceSum.isLoaded || balanceSum.liability !== null">
                <CurrencyLabel :value="balanceSum.liability"/>
              </span>
              <GhostLoading v-else-if="!balanceSum.isLoaded">
                <Block type="title" width="100%" />
              </GhostLoading>
              <app-data-loading-failed v-if="balanceSum.portfolios.isError"></app-data-loading-failed>
            </div>
          </div>
        </div>
        <!--start: verbindlichkeiten side -->

      </div>

      <div class="row mt-3 hide-on-small-screen">
        <hr class="col-12">
        <div class="col-6">
          <div class="row sum-row">
            <div class="col-7">
              <span>Vermögen:</span>
            </div>
            <div class="col-5 text-right ">
              <span v-if="!balanceSum.portfolios.isError && (balanceSum.isLoaded ||  balanceSum.wealth)">
                <CurrencyLabel :value="balanceSum.wealth"/>
              </span>
              <GhostLoading v-else-if="!balanceSum.isLoaded">
                <Block type="title" width="100%" />
              </GhostLoading>
              <app-data-loading-failed v-if="balanceSum.portfolios.isError"></app-data-loading-failed>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="row sum-row">
            <div class="col-7">
              <span>Verbindlichkeiten:</span>
            </div>
            <div class="col-5 text-right ">
              <span v-if="balanceSum.isLoaded || balanceSum.liability !== null">
                <CurrencyLabel :value="balanceSum.liability"/>
              </span>
              <GhostLoading v-else-if="!balanceSum.isLoaded">
                <Block type="title" width="100%" />
              </GhostLoading>
              <app-data-loading-failed v-if="balanceSum.portfolios.isError"></app-data-loading-failed>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <div class="box__container">
        <Table
          ref="vermoegenTable"
          tableId="dac58861-7e21-4f32-a469-f55f487e86a4"
          rowId="id"
          cardView
          hidePagination
          :headers="vermoegenSumaryMobileTable.headers"
          :rows="vermoegenSumaryMobileTable.rows"
        />
      </div>

      <!-- profits -->
      <div class="box__container" v-if="profitsMobileTable && profitsMobileTable.rows.length > 0">
        <FlexibleList :rows="profitsMobileTable.rows" linkInFirstRow @onRowClick="navigateToPage($event.title)">
          <template #title="row">
            {{ row.title }}
          </template>

          <template #value="row">
            <CurrencyLabel :value="row.value"/>
          </template>
        </FlexibleList>
      </div>

      <!-- losses -->
      <div class="box__container" v-if="lossesMobileTable && lossesMobileTable.rows > 0">
        <FlexibleList :rows="lossesMobileTable.rows" linkInFirstRow @onRowClick="navigateToPage($event.title)">
          <template #title="row">
            {{ row.title }}
          </template>

          <template #value="row">
            <CurrencyLabel :value="row.value"/>
          </template>
        </FlexibleList>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DEPOTPOSITIONS_TYPES from '@/store/depotpositions/types';
import BALANCE_SHEET_TYPES from '@/store/balance-sheet/types';
import {balanceCalc, BalanceService, isNotNumber} from './BalanceCalculations';
import { PhCircle } from 'phosphor-vue';
import CurrencyLabel from '@/components/core/CurrencyLabel.vue';
import { getColorsMixedContrastDecreasing } from "@/helpers/colors-helper.js";
import CORE_TYPES from "@/store/core/types";
import { ROLES, BROKER_STRUCTURE_ROLES } from '@/router/roles.js'
import InputCheckBoxItem from '@/components/core/forms/checkbox/InputCheckBoxItem.vue';
import OTHER_PRODUCTS_TYPES from '@/store/otherProducts/types';
import PieChart from '@/components/charts/echarts/PieChart.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';
import BRIDGE_TYPES from '@/store/bridge/types';
import Table from "@/components/table2/Table.vue";
import {TextColumn, CurrencyColumn} from "@/components/table2/table_util.js";
import FlexibleList from "@/components/flexibleList/FlexibleList.vue";

const colors = getColorsMixedContrastDecreasing();

export default {
  components: {
    PhCircle,
    CurrencyLabel,
    InputCheckBoxItem,
    PieChart,
    GhostLoading,
    Block,
    Table,
    FlexibleList,
  },
  props: {
    isSelectableView: {
      type: Boolean,
      default: false,
    },
    selectedItems: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {
      showModal: {},
      expanded: false,
      expandedLeft: [],
      expandedRight: [],
      expandedWert: false,
      expandedCredit: false,
      internalCardsClasses: {
        main: 'h-100',
        body: '',
        title: ''
      },
      initExpand: false,
      // path: API_ADDRESS,
      id: 'ALLE_DEPOTS_ANZEIGEN',

      balanceSum: BalanceService.initialBalanceSum,
      selectedBalanceValue: {},
      selectedBalanceLossValue: {},
      selectedBalance: {},
      selectedBalanceLoss: {},
      allBalanceSum: 0,
    }
  },
  computed: {
    ...mapGetters({
      limits: DEPOTPOSITIONS_TYPES.GETTERS.LIMITS,
      otherAssets: BALANCE_SHEET_TYPES.GETTERS.OTHER_ASSETS,
      credit: BALANCE_SHEET_TYPES.GETTERS.CREDIT,
      bankAccount: BALANCE_SHEET_TYPES.GETTERS.BANK_ACCOUNT,
      closedFunds: BALANCE_SHEET_TYPES.GETTERS.CLOSED_FUNDS,
      depositSum: BALANCE_SHEET_TYPES.GETTERS.DEPOSIT_SUM,
      accounts: BALANCE_SHEET_TYPES.GETTERS.ACCOUNTS,
      insuranceTable: BALANCE_SHEET_TYPES.GETTERS.INSURANCE_TABLE,
      depotid: DEPOTPOSITIONS_TYPES.GETTERS.DEPOT_ID,
      isOriginalUserIntern: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
      isOriginalUserBroker: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_BROKER,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      otherProducts: OTHER_PRODUCTS_TYPES.GETTERS.OTHER_PRODUCTS_ALL,
      isMobileNativeContext: BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT,
    }),
    showVertpapiereVorsorgeSeparated() {
      return this.hasRoles(ROLES.SHOW_WERTPAPIERE_VORSORGE)
    },
    canNavigateToMultibanking() {
      return false; // AuthenticationService.getRight('!isBypSession');
    },
    chartColors() {
      return [...colors]
    },
    isNotCustomer() {
      return this.isOriginalUserIntern || this.isOriginalUserBroker
    },
    getSumWertpapiere() {
      return function(portfolios) {
        if (portfolios?.length) {

          if (this.showVertpapiereVorsorgeSeparated) {
            portfolios = portfolios.filter(item => item.depotid !== 'Vorsorge')
          }
          const sum = portfolios
            .map(item => item.depotSum).reduce((acc, cur) => parseFloat(acc) + parseFloat(cur), 0)
            
          return isNaN(sum) ? 0.0 : sum
        }
        return 0;
      }
    },
    getSumWertpapiereVorsorge() {
      return function(portfolios) {
        if (portfolios?.length) {
          if (this.showVertpapiereVorsorgeSeparated) {
            portfolios = portfolios.filter(item => item.depotid === 'Vorsorge')
          }
          return portfolios
            .map(item => item.depotSum).reduce((acc, cur) => parseFloat(acc) + parseFloat(cur), 0)
        }
        return 0;
      }
    },
    weitereVermoegen() {
      const result = this.otherProducts?.WeitereVermoegen||[];
      if (this.id === 'ALLE_DEPOTS_ANZEIGEN' ){
        return result;
      } else if (this.id === 'STANDARD_DEPOT_ANZEIGEN' ){
        return result.filter(x => x.depotId == null)
      }
      return result.filter(x => x.depotId == this.id)
    },
    weitereVerbindlichkeiten() {
      const result = this.otherProducts?.WeitereVerbindlichkeiten||[];
      if (this.id === 'ALLE_DEPOTS_ANZEIGEN' ){
        return result;
      } else if (this.id === 'STANDARD_DEPOT_ANZEIGEN' ){
        return result.filter(x => x.depotId == null)
      }
      return result.filter(x => x.depotId == this.id)
    },
    immobilien() {
      const result = this.otherProducts?.Immobilie||[];
      if (this.id === 'ALLE_DEPOTS_ANZEIGEN' ){
        return result;
      } else if (this.id === 'STANDARD_DEPOT_ANZEIGEN' ){
        return result.filter(x => x.depotId == null)
      }
      return result.filter(x => x.depotId == this.id)
    },
    vermoegenSumaryMobileTable() {
      const headers = {
        center: [],
      };

      headers.center.push(CurrencyColumn("wealth", "Vermögen"))
      headers.center.push(CurrencyColumn("liability", "Verbindlichkeiten"))
      headers.center.push(CurrencyColumn("totalAssets", "Gesamtvermögen"))

      const rows = [];
      const column = {};
      column.wealth = this.balanceSum.wealth
      column.liability = this.balanceSum.liability
      column.totalAssets = this.isSelectableView ? this.allBalanceSum : this.balanceSum.totalAssets

      rows.push(column)

      return {headers, rows}
    },
    profitsMobileTable() {
      const rows = [];

      if(this.balanceSum.isLoaded || this.balanceSum?.portfolios?.length) {
        
        const wertpapiere = this.balanceSum.isLoaded && !this.balanceSum?.portfoliosError ? this.getSumWertpapiere(this.balanceSum.portfolios) : 0;
        rows.push({title: `Wertpapiere (${this.getPercentage('Wertpapiere', this.balanceSum, 1)}%)`, value: wertpapiere});


        const wertpapiereVorsorgeValue = this.getPercentage('Wertpapiere Vorsorge', this.balanceSum, 1);
        if(this.showVertpapiereVorsorgeSeparated && wertpapiereVorsorgeValue) {
          const wertpapiereVorsorge = this.balanceSum.isLoaded && !this.balanceSum?.portfoliosError ? this.getSumWertpapiereVorsorge(this.balanceSum.portfolios) : 0;
          rows.push({title: `Wertpapiere Vorsorge (${wertpapiereVorsorgeValue}%)`, value: wertpapiereVorsorge});
        }

        this.balanceSum.profits.forEach(profit => {
          const profitValue = this.balanceSum.isLoaded && !profit.isError ? this.getSum(profit) : 0;
          rows.push({title: `${profit.header} (${this.getPercentage(profit.header, this.balanceSum, 1)}%)`, value: profitValue});
        })

      }
      return {rows}
    },
    lossesMobileTable() {
      const rows = [];

      if(this.balanceSum.isLoaded || this.balanceSum?.portfolios?.length) {
        this.balanceSum.loss.forEach(loss => {
          const lossValue = this.balanceSum.isLoaded && !loss.isError ? this.getSum(loss) : 0;
          rows.push({title: `${loss.header} (${this.getPercentage(loss.header, this.balanceSum, -1)}%)`, value: lossValue});
        })

      }
      return {rows}
    },
  },
  watch: {
    otherProducts(v) {
      this.getBalance();
    },
    limits(result, oldValue) {
      this.getBalance();
    },
    otherAssets(result, oldValue) {
      this.getBalance();
    },
    credit(result, oldValue) {
      this.getBalance();
    },
    bankAccount(result, oldValue) {
      this.getBalance();
    },
    closedFunds(result, oldValue) {
      this.getBalance();
    },
    depositSum(result, oldValue) {
      this.getBalance();
    },
    accounts() {
      this.getBalance()
    },
    insuranceTable(result, oldValue) {
      this.getBalance();
    },
    depotpositions(result, oldValue) {
      this.getBalance();
    },
    selectedItems(v) {
      this.setInitialSelected(false);
    },
    '$store.state.balance': function() {
      this.getBalance();
    },
    'depotid': function (id) {
      this.id = id;
      this.retrieveAllData();
    },
  },
  mounted: function() {
    this.id = this.depotid;
    if (this.balanceSum && this.balanceSum.isLoaded && this.isSelectableView) {
      return;
    }
    this.retrieveAllData();
  },
  methods: {
    retrieveAllData() {
      if (this.hasRoles(ROLES.MULTIBANKING_DATA_ALLOWED)) {
        this.$store.dispatch(BALANCE_SHEET_TYPES.ACTIONS.GET_ACCOUNTS, {id: this.id});
      }
      if(this.hasRoles([BROKER_STRUCTURE_ROLES.ZUGRIFF_GESCHLOSSENE_FONDS])) {
        this.$store.dispatch(BALANCE_SHEET_TYPES.ACTIONS.GET_CLOSED_FUNDS, {id: this.id});
      }
      this.$store.dispatch(BALANCE_SHEET_TYPES.ACTIONS.GET_CREDIT, {id: this.id});
      this.$store.dispatch(BALANCE_SHEET_TYPES.ACTIONS.GET_BANK_ACCOUNT, {id: this.id});
      this.$store.dispatch(BALANCE_SHEET_TYPES.ACTIONS.GET_DEPOSIT_SUM, {id: this.id});
      if(this.hasRoles([BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN])) {
        this.$store.dispatch(BALANCE_SHEET_TYPES.ACTIONS.GET_INSURANCE_TABLE, {id: this.id});
      }
      this.$store.dispatch(OTHER_PRODUCTS_TYPES.ACTIONS.FIND_OTHER_PRODUCTS, { type: 'WeitereVerbindlichkeiten' });
      this.$store.dispatch(OTHER_PRODUCTS_TYPES.ACTIONS.FIND_OTHER_PRODUCTS, { type: 'WeitereVermoegen' });
      this.$store.dispatch(OTHER_PRODUCTS_TYPES.ACTIONS.FIND_OTHER_PRODUCTS, { type: 'Immobilie' });
    },
    getBalance() {
      const balanceSum = balanceCalc.calculateBalance(
        {}, // state.meta.cache,
        {}, // this.$store.state.balance.meta,
        {}, // state.customer.portfolio.meta,
        this.$store.state.balance.data||{},
        this.depositSum||{},
        this.credit||{},
        this.bankAccount||{},
        this.closedFunds||{},
        this.insuranceTable.data||{},
        this.id,
        {}, //state.auth.user,
        this.accounts||{},
        this.depotPositions||{},
        this.$store.state.balance.meta||{},
        this.$store.state.balance.idData||{},
        !this.isNotCustomer,
        this.weitereVermoegen,
        this.weitereVerbindlichkeiten,
        this.immobilien,
      )
      this.balanceSum = balanceSum;
      this.$emit('balanceSum', this.balanceSum)
      this.setInitialSelected(true);

      if (balanceSum && balanceSum.isLoaded) {
        let increment = 0;
        this.selectedBalance[0] = this.getSumWertpapiere(this.balanceSum.portfolios);
        if (this.selectedBalance[0]) {
          increment = 1
        }
        if (this.showVertpapiereVorsorgeSeparated) {
          this.selectedBalance[1] = this.getSumWertpapiereVorsorge(this.balanceSum.portfolios);
          if (this.selectedBalance[1]) {
            increment = 2
          }
        }
        this.balanceSum.profits.map((v, index) => {
          this.selectedBalance[index + increment] = +this.getSum(this.balanceSum.profits[index])
        })
        this.balanceSum.loss.map((v, index) => {
          this.selectedBalanceLoss[index] = +this.getSum(this.balanceSum.loss[index]);
        })
        this.$emit('selectedItems', {assets: this.selectedBalanceValue, loss: this.selectedBalanceLossValue});
        this.allBalanceSum = this.getBalanceSum()
        this.$emit('setBalance', this.getBalanceSum())
        this.$emit('setBalanceItems', this.getBalanceSumArray())
      }
    },
    toggleWert() {
      this.expandedWert = !this.expandedWert;
    },
    toggleLeft(i) {
      this.expandedLeft[i] = !this.expandedLeft[i];
      this.expandedLeft = [...this.expandedLeft];
    },
    toggleRight(i) {
      this.expandedRight[i] = !this.expandedRight[i];
      this.expandedRight = [...this.expandedRight];
    },
    getChartData(data) {
      return {
        name: '',
        data: data.map(v => ({
          value: v.number,
          label: v.string,
        }))
      }
    },
    getEChartData(data) {
      return data.map((v, i) => ({
        value: Math.abs(v.number),
        name: v.string,
      }));
    },
    handleChartClick(chartName, type = 'profits') {
      this.navigateToPage(chartName, type)
    },
    setPortfolioSelector() {
      if (!this.subRoute) {
        this.subRoute = this.route.params.subscribe(params => {
          this.id = params['id'] || 'ALLE_DEPOTS_ANZEIGEN';
          this.breadcrumbsService.portfolioSelector = {
            id: this.id,
            link: 'balance-sheet/'
          };
          this.balanceService.updateBalance(this.id);
        });
        this.breadcrumbsService.portfolioSelector = {
          id: this.id,
          link: 'balance-sheet/'
        };
      }
    },
    /**
     * Calculate sum of all items in the table
     *
     * @param {*} table
     * @returns
     * @memberof BalanceSheetComponent
     */
    getSum(table) {
      if (!table || !table.summary) {
        return 0;
      }
      const sum = table.summary.reduce((accumulator, currentValue) => {
        accumulator = parseFloat(accumulator) + (currentValue.sum && !isNotNumber(parseFloat(currentValue.sum)) ? parseFloat(currentValue.sum) : 0);
        return accumulator;
      }, 0.0);
      return isNaN(sum) ? 0.0 : sum
    },
    getLeftIndex(name) {
      if (this.balanceSum && this.balanceSum.assetChartData) {
        const result = this.balanceSum.assetChartData.findIndex(data => data.string === name);
        return result;
      }
      return 0;
    },
    getSumCalculated(name, chartData) {
      const result = chartData.filter(data => data.string === name);
      return result && result[0] && result[0].number;
    },
    getPercentage(name, balanceSum, direction = 1) {
      let val = 0;
      if (direction > 0) {
        val = this.getSumCalculated(name, balanceSum.assetChartData);
      }
      if (direction < 0) {
        val = this.getSumCalculated(name, balanceSum.liabilityChartData);
      }
      return val || 0;
    },
    sortSummary(summary, coef = 1) {
      return summary.sort((a, b) => coef * (a.sum - b.sum));
    },
    sortSummaryPortfolios(summary) {
      return summary.sort((a, b) => a.depotSum - b.depotSum);
    },
    /**
     * Find index of the header by provided key
     *
     * @param {*} items
     * @param {*} name
     * @returns
     * @memberof BalanceSheetComponent
     */
    indexOfRow(items, name) {
      return items.findIndex(item => item.key === name);
    },
    indexOfRowById(table, index) {
      const headerKeys = ['Anlageart', 'BEMERKUNG', 'AKT_WERT'];
      let name = headerKeys[index];
      if (table.meta) {
        name = table.meta.headers[index].key;
      }
      return this.indexOfRow(table.tableHeaders, name);
    },
    getPersent(str, index) {
      let res = '00,00 %';
      if (str && str.length > 4) {
        res = str.match(/\d+,\d+/g)[index] + '0 %';
      }
      return res;
    },
    /**
     * Detect is it Losts (negative)
     *
     * @param {*} item
     * @returns
     * @memberof BalanceSheetComponent
     */
    isRight(item) {
      return this.getSum(item) < 0;
    },
    parseFloat(val) {
      return parseFloat(val);
    },
    /**
     * Scroll screen to specific table after pie chart element clicked
     *
     * @param {*} event
     * @memberof BalanceSheetComponent
     */
    onSelectFragment(event) {
      const fragment = Math.round(event.value);
      const el = document.querySelector('#fragment-' + fragment);
      if (el) {
        el.scrollIntoView();
      }
    },
    navigateToPage(header, type = 'profits') {
      if (this.isSelectableView) {
        return
      }

      if(header.includes('Wertpapiere') && this.isClickable('Wertpapiere')) {
        this.navigateTo('/home/vermogensubersicht/depot')
      } else if(header.includes('Wertpapiere Vorsorge') && this.isClickable('Wertpapiere Vorsorge')) {
        this.navigateTo('/home/vermogensubersicht/wertpapiere-vorsorge')
      } else if(header.includes('Bankkonten') && this.isClickable('Bankkonten')) {
        this.navigateTo(`/home/account`)
      } else if(header.includes('Versicherungen') && this.isClickable('Versicherungen')) {
        this.navigateTo('/home/vermogensubersicht/versicherungsvermoegen')
      } else if(header.includes('Beteiligungen') && this.isClickable('Beteiligungen')) {
        this.navigateTo('/home/vermogensubersicht/beteiligungen')
      } else if(header.includes('Immobilien') && this.isClickable('Immobilien')) {
        this.navigateTo('/home/immobilien/search')
      } else if(header.includes('Weitere') && this.isClickable('Weitere')) {
        if (type === 'profits') {
          this.navigateTo('/home/vermogensubersicht/weitere-vermoegen/search')
        } else {
          this.navigateTo('/home/verbindlichkeiten/weitere-verbindlichkeiten/search')
        }
      } else if(header.includes('Multibanking') && this.isClickable('Multibanking')) {
        this.navigateTo('/home/multibanking-account')
      } else if(header.includes('Kredite') && this.isClickable('Kredite')) {
        if (this.hasRoles(BROKER_STRUCTURE_ROLES.ZUGRIFF_KREDITEDARELEHEN)) {
          this.navigateTo('/home/verbindlichkeiten/credit')
        }
      }
    },
    isClickable(header) {
      if (this.isSelectableView) {
        return false;
      }
      switch (header) {
        case 'Wertpapiere':
        case 'Wertpapiere Vorsorge':
        case 'Bankkonten':
        case 'Beteiligungen':
        case 'Immobilien':
        case 'Weitere':
        case 'Multibanking':
          return true;
        case 'Versicherungen':
          return (this.hasRoles(ROLES.HAT_VERSICHERUNGSVERMOEGEN) || this.hasRoles(ROLES.HAT_RUECKKAUFSWERT)) && this.hasRoles(BROKER_STRUCTURE_ROLES.ZUGRIFF_KREDITEDARELEHEN);
        case 'Kredite':
          if (!this.hasRoles(BROKER_STRUCTURE_ROLES.ZUGRIFF_KREDITEDARELEHEN)) {
            return false;
          }
          return true;
        default:
          return false;
      }
    },
    parseInt(val) {
      return Math.round(val);
    },
    getRate(value, period) {
      switch (period) {
        case 'monatlich':
          return value / 12;
        case 'vierteljährlich':
          return value / 4;
        case 'halbjährlich':
          return value / 2;
      }
      return value;
    },
    getHeaderName(index, table) {
      if (table.meta) {
        return table.meta.headers[index].label;
      }
      const titles = ['Anlageart', 'Beschreibung/Bemerkung', 'Wert'];
      return titles[index];
    },
    isLinkToTransactions(headers, col) {
      const indexDetails = this.indexOfRow(headers, 'Details');
      const indexTransactions = this.indexOfRow(headers, 'Transaktionen');
      const index = indexDetails >= 0 ? indexDetails : indexTransactions;
      return index >= 0 && col[index] && col[index].url && col[index].url === 'mrstransactionslist';
    },
    getLinkToTransactions(headers, col) {
      const indexDetails = this.indexOfRow(headers, 'Details');
      const indexTransactions = this.indexOfRow(headers, 'Transaktionen');
      const index = indexDetails >= 0 ? indexDetails : indexTransactions;
      const bodyParams = JSON.stringify(
        JSON.parse(col[index].bodyParams.replace(new RegExp("'", 'g'), '"'))
      );
      const params = encodeURIComponent(bodyParams);
      return params;
    },
    navigateToTransactions(url, title) {
      this.router.navigateByUrl(`/transaktionen?params=${url}&title=${encodeURIComponent(title)}`);
    },
    closedFundsDetails(val, headers) {
      const isinIndex = this.indexOfRow(headers, 'Fondsinfo');
      const isinJson = val[isinIndex] && val[isinIndex].bodyParams.replace('{isin:', '{"isin":');
      const isinObj = JSON.parse(isinJson);
      this.router.navigateByUrl(`/closedfunds/transactions/${isinObj && isinObj.isin}`);
    },
    toggleExpand(event) {
      const value = this.expanded;
      this.expandedRight[this.balanceSum.right && this.balanceSum.right.length - 1] = !value;
      this.expandedLeft[this.balanceSum.left && this.balanceSum.left.length - 1] = !value;
      this.expandedRight.fill(!value);
      this.expandedLeft.fill(!value);
      this.expanded = !value;
      this.expandedWert = !value;
      this.expandedWert = !value;
      this.expandedCredit = !value;
    },
    expandAll(balanceSum) {
      if (this.initExpand) {
        return;
      }
      this.initExpand = true;
      const value = false;
      this.expandedRight[balanceSum.loss.length - 1] = !value;
      this.expandedLeft[balanceSum.profits.length - 1] = !value;
      this.expandedRight.fill(!value);
      this.expandedLeft.fill(!value);
      this.expanded = !value;
      this.expandedWert = !value;
      this.expandedWert = !value;
      this.expandedCredit = !value;
    },
    getColor(index) {
      if(index || index == 0) {
        const length = colors.length;
        if(index < length) {
          return colors[index];
        }
        return colors[index % length];
      }
    },
    getWidth(index, num) {
      if (parseInt(index) === parseInt(num)) {
        return 100;
      }
      return (index === 0 || !!index && !isNotNumber(index)) ? Math.abs(+index / +num * 100) : 0;
    },
    retrieveBgnr(row)  {
      let JSONBgsnr = JSON.parse(row.data.record[0].bodyParams);
      return JSONBgsnr.bgsNr;
    },
    currentIdLink() {
      return (!this.id || this.id === 'ALLE_DEPOTS_ANZEIGEN') ? '' : '/' + this.id;
    },
    /** Here it is possible to show/hide Categories in the Vermoegensuebersicht */
    showCategory(l){
      return true; // AssetsLiabilitiesCardComponent.showCategoryStatic(l);
    },
    isMultibankingLink(portfolio) {
      return !!portfolio.multibanking ? '/multibanking' : '';
    },
    navigateTo(event) {
      this.$router.push({ path: `${event}` });
    },
    hideModal() {
      this.showModal = {};
    },
    onSelectedBalance(value, index, direction) {
      this.allBalanceSum = this.getBalanceSum();
      this.$emit('setBalance', this.getBalanceSum())
      this.$emit('setBalanceItems', this.getBalanceSumArray())
      this.$emit('selectedItems', {assets: this.selectedBalanceValue, loss: this.selectedBalanceLossValue});
    },
    getBalanceSum() {
      return Object.keys(this.selectedBalance).filter(k => this.selectedBalanceValue[k]).reduce((acc, k) => acc+this.selectedBalance[k], 0) + Object.keys(this.selectedBalanceLoss).filter(k => this.selectedBalanceLossValue[k]).reduce((acc, k) => acc+this.selectedBalanceLoss[k], 0);
    },
    getBalanceSumArray() {
      return {
        assets: Object.keys(this.selectedBalance).filter(k => this.selectedBalanceValue[k])
          .filter(k => this.balanceSum.assetChartData && this.balanceSum.assetChartData[k])
          .map(k => ({key: this.balanceSum.assetChartData[k].string, value: this.selectedBalance[k]})),
        loss: Object.keys(this.selectedBalanceLoss)
          .filter(k => this.liabilityChartData && this.liabilityChartData[k])
          .map(k => ({key: this.balanceSum.liabilityChartData[k].string, value: this.selectedBalanceLoss[k]}))
      }
    },
    setInitialSelected(setCheckboxes = true) {
      if (this.selectedItems && (this.selectedItems['assets'] || this.selectedItems['loss'])) {
        this.selectedBalanceValue = this.selectedItems['assets'];
        this.selectedBalanceLossValue = this.selectedItems['loss'];
      } else {
        if (setCheckboxes) {
          for (let i = 0; i < this.balanceSum.profits.length + 3; i++) {
            this.selectedBalanceValue[i] = true;
          }
          for (let i = 0; i <= this.balanceSum.loss.length; i++) {
            this.selectedBalanceLossValue[i] = true;
          }
        }
      }

      if (this.balanceSum && this.balanceSum.isLoaded) {
        let increment = 0;
        this.selectedBalance[0] = this.getSumWertpapiere(this.balanceSum.portfolios);
        if (this.selectedBalance[0]) {
          increment = 1
        }
        if (this.showVertpapiereVorsorgeSeparated) {
          this.selectedBalance[1] = this.getSumWertpapiereVorsorge(this.balanceSum.portfolios);
          if (this.selectedBalance[1]) {
            increment = 2
          }
        }
        this.balanceSum.profits.map((v, index) => {
          this.selectedBalance[index + increment] = +this.getSum(this.balanceSum.profits[index])
        })
        this.balanceSum.loss.map((v, index) => {
          this.selectedBalanceLoss[index] = +this.getSum(this.balanceSum.loss[index]);
        })
        this.allBalanceSum = this.getBalanceSum()
        this.$emit('setBalance', this.getBalanceSum())
        this.$emit('setBalanceItems', this.getBalanceSumArray())
      }
    }
  },
  filters: {
    currency(value) {
      if (isNaN(value)) {
        return value;
      }
      let val = (value/1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €';
    },
    number(value) {
      if (isNaN(value)) {
        return value;
      }
      let val = (value/1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    percent(value) {
      if (isNaN(value)) {
        return value;
      }
        let val = (value/1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' %';
    },
  },
};
</script>

<style scoped>
.btn-group button {
  margin-right: 10px;
}
.modal-footer {
    min-width: 150px;
}
.text-right {
  text-align: right;
}
.table-condensed {
  width: 100%;
}

.sum-row {
  align-self: flex-end;
  font-weight: bold;
}

.legend_icon {
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.col-left {
  float: left;
}

</style>