<template>
    <div>
        <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />
        <PageHeaderTitleNavigation v-if="!embedded" :id="$appNavigation.currentOptionMenuId" :title="title"
            :actions="headerActions" @action-OPEN-CUSTOMER="openCustomerNewTab()" @action-OPEN-BROKER="openBrokerNewTab()" @action-OPEN-INTERN="openInternNewTab()" />

        <div class="box__container" v-if="exceptionLog">
            <p class="m-0 font-bold">Message:</p>
            {{ exceptionLog.message }}

            <p class="m-0 font-bold">Stacktrace:</p>
            <div class="m-0">
                <span v-for="trace in stacktrace" class="break-line" :class="{ 'class': trace.type == 'class' }">
                    <br v-if="trace.type == 'break'">{{ trace.content }}
                </span>
            </div>

            <p class="m-0 font-bold">Date:</p>
            {{ date }}
        </div>
    </div>
</template>

<script>
import CORE_TYPES from '@/store/core/types';
import { mapGetters } from 'vuex';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import { BROKER_LEVEL_ROLES, EMPLOYEE_ROLES, VIEW_ROLES } from '@/router/roles';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';

export default {
    props: {
        embedded: {
            type: Boolean,
            default: false,
        }
    },
    components: {
        OptionMenu, PageHeaderTitleNavigation
    },
    methods: {
        openCustomerNewTab() {
            if (this.exceptionLog && this.exceptionLog.userID) {
                this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, { customerId: this.exceptionLog.userID });
            }
        },
        openBrokerNewTab() {
            if (this.exceptionLog && this.exceptionLog.userID) {
                this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_BROKER_NEW_TAB, { brokerId: this.exceptionLog.userID });
            }
        },
        openInternNewTab() {
            if (this.exceptionLog && this.exceptionLog.userID) {
                this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_INTERN, { userId: this.exceptionLog.userID });
            }
        },
    },
    computed: {
        ...mapGetters({
            exceptionLog: CORE_TYPES.GETTERS.EXCEPTION_LOG,
            hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
        }),
        isUserBroker() {
            return this.exceptionLog && this.exceptionLog.userID && (this.exceptionLog.userID.match(/^[0-9]{5}$/) || this.exceptionLog.userID.match(/^[0-9]{5}-[0-9]*$/))
        },
        isUserIntern() {
            return this.exceptionLog && this.exceptionLog.userID && !this.exceptionLog.userID.match(/^[0-9]+$/)
        },
        headerActions() {
            const actions = [];
            if(this.isUserBroker) {
                actions.push(
                    PageHeaderSimpleAction(
                        "OPEN-BROKER",
                        "Vermittlersicht"
                    ).withDisabled(() => !this.exceptionLog || !this.hasRoles([[EMPLOYEE_ROLES.ZUGRIFF_KUNDEN,  BROKER_LEVEL_ROLES.ZUGRIFF_KUNDEN]]) )
                )
            } else if(this.isUserIntern) {
                actions.push(
                    PageHeaderSimpleAction(
                        "OPEN-INTERN",
                        "Internsicht"
                    ).withDisabled(() => true )
                )
            } else {
                actions.push(
                PageHeaderSimpleAction(
                    "OPEN-CUSTOMER",
                    "Kundensicht"
                ).withDisabled(() => !this.exceptionLog || !this.hasRoles([[EMPLOYEE_ROLES.ZUGRIFF_KUNDEN,  BROKER_LEVEL_ROLES.ZUGRIFF_KUNDEN]]) )
            )
            }

            return actions
        },
        title() {
            return `Exception ${this.id}` + (this.exceptionLog.userID ? ` | ${this.exceptionLog.userID}` : '')
        },
        id() {
            return this.$route.params.id
        },
        stacktrace() {
            if (!this.exceptionLog || !this.exceptionLog.stacktrace) return []

            const string = this.exceptionLog.stacktrace;
            const classRegexp = /([a-zA-Z0-9]*.java:[0-9]*)/g;
            const atRegexp = /\s*at\s+/g;
            const parts = string.split(atRegexp);

            let parsed = [];

            for (let i = 0; i < parts.length; i++) {
                const part = parts[i];

                // Füge ein 'break' Element hinzu, außer beim ersten Teil
                if (i > 0) {
                    parsed.push({ content: 'at', type: 'break' });
                }

                const classMatches = part.match(classRegexp);
                if (classMatches) {
                    const subParts = part.split(classRegexp);
                    for (const subPart of subParts) {
                        if (subPart.match(classRegexp)) {
                            parsed.push({ content: subPart, type: 'class' });
                        } else {
                            parsed.push({ content: subPart, type: 'code' });
                        }
                    }
                } else {
                    parsed.push({ content: part, type: 'code' });
                }
            }

            return parsed;
        },
        date() {
            return new Date(this.exceptionLog.dateCreated).toLocaleString()
        }
    },
    mounted() {
        this.$store.dispatch(CORE_TYPES.ACTIONS.EXCEPTION_LOG, this.id);
    },

}
</script>
<style>
.font-bold {
    font-weight: bold;
}

.m-0 {
    margin: 0;
}

.class {
    color: var(--color-link);
}

.break-line {
    line-break: anywhere;
}
</style>