var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "top-navbar__container-bg" }, [
    _c(
      "div",
      {
        staticClass: "fc-container top-navbar__container",
        class: { loading: _vm.isLoading }
      },
      [
        _vm.isLoading
          ? _c(
              "div",
              { staticClass: "top-navbar__loading" },
              [
                !_vm.$isSmallScreen
                  ? [
                      _c("GhostLoading", {
                        attrs: {
                          type: "block",
                          config: {
                            block: { width: 16, height: 16, opacity: 0.6 }
                          }
                        }
                      }),
                      _c("GhostLoading", {
                        attrs: {
                          type: "block",
                          config: {
                            block: { width: 16, height: 16, opacity: 0.6 }
                          }
                        }
                      })
                    ]
                  : _vm._e(),
                _c("GhostLoading", {
                  staticClass: "top-navbar__loading--user-text",
                  attrs: {
                    type: "block",
                    config: { block: { width: 200, height: 16, opacity: 0.6 } }
                  }
                }),
                _c("GhostLoading", {
                  staticClass: "top-navbar__loading--user-photo",
                  attrs: {
                    type: "block",
                    config: { block: { width: 24, height: 24, opacity: 0.6 } }
                  }
                })
              ],
              2
            )
          : [
              _c(
                "div",
                {
                  staticClass:
                    "top-nav__navigation-controls d-flex header-navbar__container-icons--spaced"
                },
                [
                  _c("BaseBackButtonBreadcrumb", {
                    staticClass: "top-nav__navigation-controls--breadcrumb",
                    attrs: { size: _vm.buttonsSize }
                  })
                ],
                1
              ),
              !_vm.isMobileNativeContext && !_vm.isSmallScreen
                ? _c(
                    "div",
                    {
                      staticClass:
                        "header-navbar__controls-container header-navbar__container-icons--spaced"
                    },
                    [
                      _c("LogoutTimer"),
                      _vm.isNavItemsAllowed
                        ? [
                            _c("Maklerzugang", {
                              attrs: { size: _vm.buttonsSize }
                            }),
                            _c("Kundenzugang", {
                              attrs: { size: _vm.buttonsSize }
                            }),
                            _c("PhoneButton", {
                              attrs: { size: _vm.buttonsSize }
                            }),
                            _c("TapiDisconnectButton", {
                              attrs: {
                                size: _vm.buttonsSize,
                                color: "var(--color-danger)"
                              }
                            }),
                            _c("TapiButton", {
                              attrs: { size: _vm.buttonsSize }
                            }),
                            _c("GlobalSearchModal", {
                              attrs: { size: _vm.buttonsSize }
                            }),
                            _vm.showInteractiveRecorder
                              ? _c("RecordTutorial", {
                                  attrs: { size: _vm.buttonsSize }
                                })
                              : _vm._e(),
                            _vm.showSso
                              ? _c("SsoButton", {
                                  attrs: { size: _vm.buttonsSize }
                                })
                              : _vm._e(),
                            _c("Logout", { attrs: { size: _vm.buttonsSize } }),
                            _c("CloseExtraWebInstance", {
                              attrs: { size: _vm.buttonsSize }
                            })
                          ]
                        : [_c("Logout", { attrs: { size: _vm.buttonsSize } })],
                      _c("LoggedUserInformation"),
                      _c("CustomerPicture")
                    ],
                    2
                  )
                : _c(
                    "div",
                    {
                      staticClass:
                        "header-navbar__controls-container header-navbar__container-icons--spaced"
                    },
                    [
                      _vm.isNavItemsAllowed
                        ? [
                            _c("CloseExtraWebInstance", {
                              attrs: { size: _vm.buttonsSize }
                            })
                          ]
                        : _vm._e(),
                      _c("ProfileMenu")
                    ],
                    2
                  )
            ]
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }