<template>
  <div>
    <div v-if="!rechtsform" class="box__container">
      <p><span class="font-bold">Schritt Stammdaten: </span>Bitte setzen Sie zuerst die Rechtsform.</p>
    </div>
    <div v-else>
    <div class="scans-container" v-for="requiredFile in requiredFiles">
      <div class="box__container">
        <ImageUpload class="scan-container" :title="requiredFile.title" edgeCorrection
          :image="previewImages[requiredFile.shorthand]" :loading="isLoading"
          :disabled="isLoading || !isEditable || disabled" :config="imageUploadConfig" photoCamera="back"
          hasDeleteButton
          @delete="deleteImage(requiredFile.shorthand)"
          @file="convertFileIfNeeded([$event], requiredFile.shorthand)"
          @change="uploadImage([$event], requiredFile.shorthand)" />
        <DatePickerField
          v-if="isFA && (requiredFile.shorthand === 'HRBSEITE1' || requiredFile.shorthand === 'TRANSPARENZREGISTERAUSZUG')"
          label="Austellungsdatum" isValueAsString :isComponentHalfSize="true"
          v-model="unterlagenFirmaDates[requiredFile.shorthand]"
          :disabled="isLoading || !isEditable || disabled || previewImages[requiredFile.shorthand] === undefined"
          @change="reupload(requiredFile.shorthand)"/>
      </div>
    </div>

    <div class="box__container" v-if="hasImageAdded && isEditable && !isSmallScreen">
      <div class="row mb-0">
        <div class="col-auto">
          <BaseButton isSecondary @click="$refs.deleteImages.open()" :disabled="isLoading || disabled">Gespeicherte Bilder
            löschen</BaseButton>
        </div>
        <div class="col-auto">
          <DownloadLink 
            downloadServicePath="/generateLegitimationPDF"
            title="PDF herunterladen"
            :queryParams="{ personId: customerData.personId }"
            asButton
              />
        </div>
      </div>
    </div>
  </div>
    <BaseModal ref="deleteImages" modalTitle="Löschen Legitimationsurkunde" labelButtonConfirm="Löschen"
      labelButtonCancel="Abbrechen" @onConfirmButton="deleteImages()">
      Möchten Sie die gespeicherten Dokumente wirklich löschen?
    </BaseModal>
  </div>
</template>

<script>
import BaseButton from '@/components/core/BaseButton.vue'
import BaseModal from '@/components/core/BaseModal.vue'
import { mapGetters } from 'vuex'
import CUSTOMERDATA_TYPES from '@/store/customerData/types'
import { uploadFileChunked, pdfToImage } from '@/helpers/upload-helper'
import ImageUpload from '@/components/fileUpload/ImageUpload.vue'
import CORE_TYPES from '@/store/core/types';
import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue";
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue';
import DownloadLink from '@/components/core/download/DownloadLink.vue'

const isPdf = file => file?.name?.endsWith?.(".pdf");
const fileNameNoExtension = file => file?.name && file.name.substr(0, file.name.lastIndexOf('.')) || 'untitled';

export default {
  props: {
    rechtsform: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['read-image-data'],
  computed: {
    ...mapGetters({
      isFA: CORE_TYPES.GETTERS.IS_FA,
      isFK: CORE_TYPES.GETTERS.IS_FK,
      personId: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_ID,
      customerData: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_DATA,
      legitimationsurkundeChanged: CUSTOMERDATA_TYPES.GETTERS.LEGITIMATIONSURKUNDE_CHANGED,
      apiAddress: CORE_TYPES.GETTERS.API_ADDRESS,
      token: CORE_TYPES.GETTERS.GET_TOKEN,
      isSmallScreen: CORE_TYPES.GETTERS.IS_SMALL_SCREEN,
    }),
    previewImages() {
      let images = {};
      this.requiredFiles.forEach(file => {
        if (this.legitimation?.unterlagenFirma && this.legitimation?.unterlagenFirma[file.shorthand]) {
          images[file.shorthand] = this.getImgSrc(this.legitimation?.unterlagenFirma[file.shorthand]);
        }
      });
      return images;
    },
    legitimation() {
      return this.customerData?.legitimation;
    },
    hasImageAdded() {
      return !!this.legitimation?.unterlagenFirma;
    },
    hasAllImagesAdded() {
      for (let requiredFile of this.requiredFiles) {
        if (!this.legitimation || !this.legitimation.unterlagenFirma || !this.legitimation.unterlagenFirma[requiredFile.shorthand]) {
          return false;
        }
      }
      return true;
    },
    isLoading() {
      return this.uploading || this.reading || this.updatingProfile || this.savingLegitimation || this.convertingFile || this.deleting;
    },
    isEditable() {
      return this.customerData?.isLegitimationEditable || this.isMitarbeiterEditable;
    },
    requiredFiles() {
      return this.isFA ? this.requiredFilesFA : this.requiredFilesFK;
    },
    requiredFilesFK() {
      const files = [{ 'shorthand': 'TRANSPARENZREGISTERAUSZUG', 'title': 'Auszug Transparenzregister' },
      { 'shorthand': 'FATCAAUSKUNFT', 'title': 'Selbstauskunft für juristische Personen (CRS/FATCA)' }];
      switch (this.rechtsform) {
        case 'EINZELFIRMA':
          files.push({ 'shorthand': 'GEWERBEANMELDUNG', 'title': 'Gewerbeanmeldung' });
          break;
        case 'PERSONENGESELLSCHAFT':
          files.push({ 'shorthand': 'HRBSEITE1', 'title': 'HRB Seite 1' }, { 'shorthand': 'HRBSEITE2', 'title': 'HRB Seite 2' }, { 'shorthand': 'UNTERSCHRIFTSVERZEICHNIS', 'title': 'Unterschriftsverzeichnis' });
          break;
        case 'GBR':
          files.push({ 'shorthand': 'VEREINSREGISTERAUSZUG', 'title': 'Bestätigte Kopie des Vereinsregisterauszuges' });
          break;
        case 'STIFTUNG':
          files.push({ 'shorthand': 'ANERKENNTNISURKUNDE', 'title': 'Bestätigte Kopie der Anerkenntnisurkunde' }, { 'shorthand': 'VERTRETUNGSBESCHEINIGUNG', 'title': 'Bestätigte Kopie der Vertretungsbescheinigung' },
            { 'shorthand': 'SATZUNGSBESTAETIGUNG', 'title': 'Bestätigte Kopie der Satzung' });
          break;
        case 'VEREIN':
          files.push({ 'shorthand': 'VEREINSREGISTERAUSZUG', 'title': 'Bestätigte Kopie des Vereinsregisterauszuges' });
      }
      return files;
    },
    requiredFilesFA() {
      const files = []
      switch (this.rechtsform) {
        case 'EINZELFIRMA':
          files.push({ 'shorthand': 'TRANSPARENZREGISTERAUSZUG', 'title': 'Erweiterten WiEReG gemäß §9' }, { 'shorthand': 'GEWERBEANMELDUNG', 'title': 'Gewerbeschein' }, );
          break;
        case 'PERSONENGESELLSCHAFT':
          files.push({ 'shorthand': 'TRANSPARENZREGISTERAUSZUG', 'title': 'Erweiterten WiEReG gemäß §9' }, { 'shorthand': 'HRBSEITE1', 'title': 'Firmenbuchauszug 1' }, { 'shorthand': 'HRBSEITE2', 'title': 'Firmenbuchauszug 2' }, { 'shorthand': 'UNTERSCHRIFTSVERZEICHNIS', 'title': 'Unterschriftsverzeichnis' });
          break;
        case 'GBR':
          files.push({ 'shorthand': 'VEREINSREGISTERAUSZUG', 'title': 'Gesellschaftsvertrag' });
          break;
        case 'STIFTUNG':
          files.push({ 'shorthand': 'TRANSPARENZREGISTERAUSZUG', 'title': 'Erweiterten WiEReG gemäß §9' }, { 'shorthand': 'VERTRETUNGSBESCHEINIGUNG', 'title': 'Bestätigte Kopie der Vertretungsbescheinigung' },
            { 'shorthand': 'SATZUNGSBESTAETIGUNG', 'title': 'Bestätigte Kopie der Satzung' });
          break;
        case 'VEREIN':
          files.push({ 'shorthand': 'TRANSPARENZREGISTERAUSZUG', 'title': 'Erweiterten WiEReG gemäß §9' }, { 'shorthand': 'VEREINSREGISTERAUSZUG', 'title': 'Bestätigte Kopie des Vereinsregisterauszuges' });
      }
      return files;
    }
  },
  data() {
    return {
      tempFiles: null,
      tempFilesFront: false,
      fileToUpload: {},
      cameraAvailable: false,
      convertingFile: false,
      uploading: false,
      reading: false,
      deleting: false,
      updatingProfile: false,
      savingLegitimation: false,
      previews: {},
      imageUploadConfig: {
        fit: 'fill',
      },
      unterlagenFirmaDates: {}
    }
  },
  methods: {
    convertFileIfNeeded(files, shorthand) {
      if (isPdf(files[0])) { // convert pdf to image before upload it
        this.convertingFile = true;
        uploadFileChunked(files[0]).then((tempFileId) => {
          return pdfToImage(tempFileId, fileNameNoExtension(files[0]) + ".png")
            .then(file => this.uploadImage([file], shorthand));
        }).finally(() => this.convertingFile = false);
      } else {
        this.uploadImage(files, shorthand);
      }
    },
    //This function also calls upload() which reuploads the corresponding image, since the ausstellungsdatum is added to the File directly;
    //FIXME INVESTIGATE IF THIS IS STILL NEEDED AFTER UNTERLAGENFIRMA REFACTOR
    //FIXME remove mitarbeiterId, where appropriate
    reupload(filename) {
      const image = this.legitimation?.unterlagenFirma[filename];
      this.fileToUpload[filename] = new Blob([new Uint8Array(image.bytes)], { type: image.contentType });
      //No need to set smth else as that logic is already handled in upload()
      this.upload();
    },
    deleteImage(legidocType) {
      this.deleting = true;
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.DELETE_LEGITIMATION_IMAGE_FIRMA, {
        personId: this.personId,
        legiTypFirma: legidocType,
      })
      .then(() => {
        this.legitimation.unterlagenFirma[legidocType] = null;
        this.unterlagenFirmaDates[legidocType] = '';
      })
      .finally(() => this.deleting = false);
    },
    uploadImage(files, filename) {
      if (!files?.length) {
        return;
      }
      this.fileToUpload[filename] = files[0];
      this.upload();

      if (this.mitarbeiterId) {
        this.$emit('addAnsprechpartnerDataEdited');
      }
    },
    readImage(files) {
      if (!files?.length) {
        return;
      }

      this.reading = true;
      uploadFileChunked(files[0]).then((tempFileId) => {
        return this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.RECOGNIZE_UPLOADED_FILE, { tempFileId })
          .then((response) => {
            this.$emit('read-image-data', response);
            this.closeReadImageModal();
          });
      }).finally(() => this.reading = false);
    },
    upload() {
      this.uploading = true;
      let payload = {
        rechtsform: this.rechtsform,
        ...this.fileToUpload,
        personId: this.personId
      }
      //Add ausstellungsdatum info
      Object.keys(this.unterlagenFirmaDates).forEach(key => {
        if(this.unterlagenFirmaDates[key]) {
          payload['AUSSTELLUNGSDATUM_' + key] = this.unterlagenFirmaDates[key];
        }
      })
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.UPLOAD_FIRMA_LEGITIMATION_IMAGES, payload)
        .then(v => {
          this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.LEGITIMATIONSURKUNDE_CHANGED, { legitimationsurkundeChanged: true });
        })
        .finally(() => this.uploading = false);
    },
    deleteImages() {
      this.legitimation.unterlagenFirma = {};
      this.unterlagenFirmaDates = {};
      this.deleting = true;

      let personId = this.personId;
      if (this.mitarbeiterId) {
        personId = this.mitarbeiterId;
      }
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.DELETE_LEGITIMATION_IMAGES, {
        personId: personId,
      }).finally(() => this.deleting = false);
    },
    getImgSrc(image) {
      if (image) {
        let byteArray = image.bytes;
        const typedArray = new Uint8Array(byteArray);
        const blob = new Blob([typedArray], { type: image.contentType });
        return URL.createObjectURL(blob);
      }

      return '';
    },
    async updateProfile() {
      try {
        this.updatingProfile = true;
        await Promise.all([
          this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_CUSTOMER_DATA),
          this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_CUSTOMER_DATA_CONFIG),
          this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_CUSTOMER_DATA_ADDITIONAL_PERSONS, { list: true }),
        ]);
      } finally {
        this.updatingProfile = false;
      }
    },
    async saveLegitimation() {
      const payload = {
        personId: this.personId,
      };
      this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.ADD_CUSTOMER_DATA_EDITED, payload);

      this.savingLegitimation = true;
      await this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.SAVE_CUSTOMER_DATA)
        .then(() => {
          this.updateProfile();
        })
        .finally(() => {
          this.savingLegitimation = false;
        })
    }
  },
  mounted() {
    //Update dates for legi if exists
    if(this.customerData?.legitimation?.unterlagenFirma) {
      Object.keys(this.customerData?.legitimation?.unterlagenFirma).forEach(key => {
        this.$set(this.unterlagenFirmaDates, key, this.customerData?.legitimation?.unterlagenFirma[key].paramString);
      });
  }
    if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
      navigator.mediaDevices.enumerateDevices()
        .then(devices => {
          this.cameraAvailable = devices.some(device => device.kind == 'videoinput');
        });
    }
  },
  components: {
    BaseButton,
    BaseModal,
    ImageUpload,
    InputToggleSwitch,
    DownloadLink,
    DatePickerField

  }
}
</script>

<style scoped>
.scans-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: center;
  gap: 10px;
}

.scans-container>div {
  flex: 1 1 344px;
  max-width: 100%;
}

.scan-container {
  margin: 0;
  display: flex;
  flex-direction: column;
  max-width: 100%;
}

.scan-container.no-image {
  max-width: 344px;
}
</style>
