import ABRECHNUNG_TYPES from './types';

export default {
  [ABRECHNUNG_TYPES.MUTATIONS.FORM](state, payload) {
    if (payload.combos?.abrechnungsdatum) {
      let parts;
      payload.combos?.abrechnungsdatum.forEach(rd => {
        parts = rd.value.split('.').map(p => parseInt(p, 10));
        rd.time = new Date(parts[2], parts[1] - 1, parts[0]).getTime();
      })
    }
    state.form = payload;
  },
}