import { VIEW_ROLES } from '@/router/roles';

export default [
  // {
  //   path: 'wertpapierorder',
  //   component: () => import(/* webpackChunkName: "wertpapierorder" */ '@/views/BaseRouterView.vue'),
  //   meta: {
  //     roles: {
  //       allowed: [VIEW_ROLES.VIEW_CUSTOMER]
  //     },
  //   },
  //   children: [
      {
        path: '',
        redirect: {
          path: 'main',
          query: { initData: true }
        }
      },
      {
        path: 'fondsfinder',
        component: () => import(/* webpackChunkName: "wertpapierorder-fondsfinder" */ '@/views/wertpapierorder/WertpapierorderFondsfinder.vue'),
      },
      {
        path: 'tausch',
        component: () => import(/* webpackChunkName: "wertpapierorder-tausch" */ '@/views/wertpapierorder/WertpapierorderTausch.vue'),
      },
      {
        path: ':step',
        component: () => import(/* webpackChunkName: "wertpapierorder" */ '@/views/wertpapierorder/WertpapierorderStepperTemplate.vue'),
      },
  //   ],
  // },
]
