var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "Vermögensverwaltung",
          subtitle: _vm.title,
          defaultMenu: _vm.schemaMenuVarianten,
          actions: _vm.schemaHeaderActions
        },
        on: { action: _vm.handleAction }
      }),
      _c("AntragSidetext", {
        attrs: {
          warnings: _vm.warnings,
          hinweiseProps: _vm.hinweise,
          showMessageErrorMustBeFixed: "",
          showExpanded: false,
          hinweisText: "Die Strategie ist fehlerhaft"
        }
      }),
      _vm.schemaData && _vm.schemaData.titleEditable
        ? _c("div", { staticClass: "box__container" }, [
            _c(
              "div",
              { staticClass: "d-flex" },
              [
                _c("span", { staticClass: "mr-2" }, [
                  _vm._v("Name der Vermögensverwaltung")
                ]),
                _c("InputField", {
                  on: {
                    change: function($event) {
                      return _vm.updateStore({ title: $event })
                    }
                  },
                  model: {
                    value: _vm.title,
                    callback: function($$v) {
                      _vm.title = $$v
                    },
                    expression: "title"
                  }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          actions: _vm.varianteHeaderActions,
          title: _vm.varianteTitle,
          showBackButton: false
        },
        on: { action: _vm.handleAction },
        scopedSlots: _vm._u([
          {
            key: "subtitle",
            fn: function() {
              return [
                _vm._l(_vm.variante.stati || [], function(status, index) {
                  return [
                    index > 0
                      ? _c("span", { key: "SEP" + index }, [_vm._v(" | ")])
                      : _vm._e(),
                    _c(
                      "span",
                      {
                        key: "LABEL" + index,
                        staticClass: "pl-2",
                        class: status.status
                      },
                      [_vm._v(_vm._s(status.label))]
                    )
                  ]
                })
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.isSchemaDataLoading
            ? _c("GhostLoading", { attrs: { type: "table" } })
            : _vm.variante
            ? _c("VVWertpapiereList", {
                attrs: {
                  positions: (_vm.variante && _vm.variante.positions) || [],
                  extraHeaders: _vm.schemaData.extraHeaders,
                  isEditor: true,
                  disabled: _vm.disabled,
                  versionId: _vm.varianteId,
                  showKennzahlen: _vm.wertpapiereShowKennzahlen
                },
                on: {
                  updateVarianteStore: function($event) {
                    return _vm.updateVariantePositionStore(
                      _vm.varianteId,
                      $event
                    )
                  }
                }
              })
            : _vm._e(),
          _c("InputTextArea", {
            attrs: {
              disabled: !_vm.schemaData.isAdvisor || _vm.disabled,
              label: "Anmerkung des Erstellers"
            },
            on: {
              change: function($event) {
                return _vm.updateVarianteStore(_vm.varianteId, {
                  commentZulassung: $event
                })
              }
            },
            model: {
              value: _vm.commentZulassung,
              callback: function($$v) {
                _vm.commentZulassung = $$v
              },
              expression: "commentZulassung"
            }
          }),
          _c("InputTextArea", {
            attrs: {
              disabled: !_vm.schemaData.isInternVV || _vm.disabled,
              label: "Kommentar Vermögensverwalter"
            },
            on: {
              change: function($event) {
                return _vm.updateVarianteStore(_vm.varianteId, {
                  commentVerwalter: $event
                })
              }
            },
            model: {
              value: _vm.commentVerwalter,
              callback: function($$v) {
                _vm.commentVerwalter = $$v
              },
              expression: "commentVerwalter"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("StrategienZielmarktTable", {
            attrs: {
              zielmarktdaten: _vm.variante.zielmarktdaten,
              disabled: _vm.disabled
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("StrategienRichtbandbreiteTable", {
            attrs: {
              tableRows: _vm.variante.richtbandbreiten || [],
              isAdvisor: _vm.variante.isAdvisor
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "box__container" }, [
        _c(
          "div",
          { staticClass: "button_right" },
          [
            this.variante && this.variante.defaultAction
              ? _c("BaseButton", {
                  attrs: { label: this.variante.defaultAction.label },
                  on: { click: _vm.handleDefaultAction }
                })
              : _vm._e()
          ],
          1
        )
      ]),
      _c(
        "BaseModal",
        {
          ref: "pdfAenderungenModal",
          attrs: {
            labelButtonConfirm: "PDF erzeugen",
            labelButtonCancel: "Abbrechen"
          },
          on: { onConfirmButton: _vm.convertPdf },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [_vm._v(" Änderungs-Dokument erstellen ")]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "div",
            [
              _c("InputRadioBoxGroup", {
                attrs: {
                  label: "Änderungsdokument erstellen",
                  values: _vm.typValues
                },
                model: {
                  value: _vm.typ,
                  callback: function($$v) {
                    _vm.typ = $$v
                  },
                  expression: "typ"
                }
              }),
              _c("ComboBox", {
                attrs: {
                  label: "Vergleiche Variante",
                  values: _vm.comboVersionenAktuell,
                  disabled: _vm.typ !== "VALUE_TYP_VERGLEICH"
                },
                on: { change: _vm.updateComboboxVersionenPrevious },
                model: {
                  value: _vm.versionAktuell,
                  callback: function($$v) {
                    _vm.versionAktuell = $$v
                  },
                  expression: "versionAktuell"
                }
              }),
              _c("ComboBox", {
                attrs: {
                  label: "Vergleiche Variante",
                  values: _vm.comboVersionenPrevious,
                  disabled: _vm.typ !== "VALUE_TYP_VERGLEICH"
                },
                model: {
                  value: _vm.versionPrevious,
                  callback: function($$v) {
                    _vm.versionPrevious = $$v
                  },
                  expression: "versionPrevious"
                }
              })
            ],
            1
          )
        ]
      ),
      _c(
        "BaseModal",
        {
          ref: "archivierungModal",
          attrs: {
            labelButtonConfirm: "Fortfahren",
            labelButtonCancel: "Abbrechen"
          },
          on: { onConfirmButton: _vm.moveVarianteIntoArchive },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [_vm._v(" Archivierung der Variante")]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", [
            _vm._v(
              " Diese Variante wird in das Archiv verschoben und ist nicht mehr in dieser Maske sichtbar. "
            )
          ]),
          _c("div", [
            _vm._v(
              " Dadurch steht sie auch nicht mehr zur Genehmigung zur Verfügung. "
            )
          ]),
          _c("div", [
            _vm._v("Sie können Kopien dieser Variante im Archiv erstellen.")
          ]),
          _c("div", { staticClass: "mt-2" }, [_vm._v("Möchen Sie fortfahren?")])
        ]
      ),
      _c(
        "BaseModal",
        {
          ref: "statusChangeFehlerModal",
          attrs: {
            showConfirmButton: false,
            labelButtonCancel: "Ok",
            modalTitle: "Fehlerliste",
            size: "lg"
          }
        },
        _vm._l(_vm.scmErrors, function(row) {
          return _c("div", { key: row.title }, [
            _c("div", { staticStyle: { "padding-bottom": "1em" } }, [
              _c("span", { staticStyle: { "font-weight": "bold" } }, [
                _vm._v(_vm._s(row.title))
              ]),
              _vm._v(": " + _vm._s(row.message) + " ")
            ])
          ])
        }),
        0
      ),
      _c(
        "BaseModal",
        {
          ref: "statusChangeModal",
          attrs: {
            showConfirmButton: true,
            labelButtonConfirm: this.scmButtonLabel,
            labelButtonCancel: "Abbrechen",
            modalTitle: "Status ändern"
          },
          on: { onConfirmButton: _vm.doChangeStatusScm }
        },
        [_c("div", { domProps: { innerHTML: _vm._s(this.scmMessage) } })]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }