<template>
  <div class="box__container">
    <div>
      <InputField
        v-if="sprod.id && isIntern"
        label="Interne Nummer"
        :disabled="true"
        :isComponentHalfSize="true"
        v-model="sprod.id"
      />
      <template v-if="!isImmobilie">
        <InputToggleSwitch
          label="Fremdanlage"
          :inLineLabel="true"
          v-model="sprod.fremdanlage"
          :disabled="!isIntern"
          @input="dataChanged()"
        />
        <InputToggleSwitch
          label="Automatisch aktualisieren"
          :inLineLabel="true"
          v-model="sprod.autoupdate"
          :disabled="!isIntern"
          @input="dataChanged()"
        />
      </template>
      <template v-if="isFA && isByPass">
        <InputToggleSwitch
          label="Betreuerwechsel"
          :inLineLabel="true"
          :disabled="!isEditable"
          v-model="sprod.betreuerwechsel"
          @input="dataChanged()"
        />
      </template>
      <ComboBox
        label="Produktart"
        :isComponentHalfSize="true"
        :firstEmpty="!sprod.produktart"
        :values="combos.prokuktArt"
        v-model="sprod.produktart"
        :validateUntouched="true"
        :disabled="!isEditable"
        @change="dataChangedCombo('produktart', $event); clearFreieEingabeProduktartIfNeeded($event)"
      />
      <template v-if="isProduktartFreieEingabe">
        <InputField
          :isComponentHalfSize="true"
          :disabled="!isEditable"
          v-model="sprod.freieEingabeProduktart"
          @change="dataChanged()"
        />
      </template>

      <div v-if="isImmobilie" class="mb-3">
        <ComboBox
          label="Nutzung"
          :isComponentHalfSize="true"
          :firstEmpty="true"
          :values="combos.nutzung"
          v-model="sprod.nutzung"
          :disabled="!isEditable"
          @change="dataChangedCombo('nutzung', $event)"
        />
        <InputField
          label="QM Wohnfläche gesamt"
          type="number"
          :isComponentHalfSize="true"
          v-model="sprod.wohnflaeche"
          :disabled="!isEditable"
          @change="dataChanged()"
        />

        <template v-if="isNutzungMischnutzung">
          <InputField
            label="QM Wohnfläche Eigennutzung"
            type="number"
            :isComponentHalfSize="true"
            v-model="sprod.wohnflaecheEigennutzung"
            :disabled="!isEditable"
            @change="dataChanged()"
          />
          <InputField
            label="QM Wohnfläche Fremdnutzung"
            type="number"
            :isComponentHalfSize="true"
            v-model="sprod.wohnflaecheFremdnutzung"
            :disabled="!isEditable"
            @change="dataChanged()"
          />
        </template>

        <ComboBox
          label="Gebäudetyp"
          :isComponentHalfSize="true"
          :values="combos.gebaudeTyp"
          v-model="sprod.gebaeudetyp"
          :disabled="!isEditable"
          @change="dataChangedCombo('gebaeudetyp', $event)"
        />
        <DatePickerField
          isValueAsString
          label="Anschaffungsdatum"
          :isComponentHalfSize="true"
          v-model="sprod.begin"
          :disabled="!isEditable"
          @input="dataChanged()"
        />
        <InputField
          label="Kaufpreis"
          type="currency"
          :isComponentHalfSize="true"
          v-model="sprod.bezugssumme"
          :disabled="!isEditable"
          @change="dataChanged()"
        />
        <InputField
          label="akt. Verkehrswert"
          type="currency"
          :isComponentHalfSize="true"
          :disabled="true"
          v-model="sprod.aktWert"
          @change="dataChanged()"
        />
        <InputField
          label="Mietkosten pro Monat"
          type="currency"
          :isComponentHalfSize="true"
          v-model="sprod.miete"
          :disabled="!isEditable"
          @change="dataChanged()"
        />
        <InputField
          label="Mieteinnahmen pro Monat"
          type="currency"
          :isComponentHalfSize="true"
          v-model="sprod.einnahmen"
          :disabled="!isEditable"
          @change="dataChanged()"
        />        
        <InputField
          label="Adresse des Objektes"
          :isComponentHalfSize="true"
          v-model="sprod.adresse1"
          :disabled="!isEditable"
          @change="dataChanged()"
        />
        <InputField
          label=""
          :isComponentHalfSize="true"
          v-model="sprod.adresse2"
          :disabled="!isEditable"
          @change="dataChanged()"
        />
      </div>
      <div v-else-if="sprod.produktart" class="mb-3">
        <InputField
          label="Produktbezeichnung"
          :isComponentHalfSize="true"
          v-model="sprod.produktbez"
          :disabled="!isEditable"
          @change="dataChanged()"
        />
        <InputField
          label="Gesellschaft"
          :isComponentHalfSize="true"
          v-model="sprod.company"
          :disabled="!isEditable"
          @change="dataChanged()"
        />
        <InputField
          label="Tarif"
          :isComponentHalfSize="true"
          v-model="sprod.tarif"
          :disabled="!isEditable"
          @change="dataChanged()"
        />
        <InputField
          label="Produktnummer"
          :isComponentHalfSize="true"
          v-model="sprod.sprodNr"
          :disabled="!isEditable"
          @change="dataChanged()"
        />
        <DatePickerField
          isValueAsString
          label="Beginn"
          :isComponentHalfSize="true"
          v-model="sprod.begin"
          :disabled="!isEditable"
          @input="dataChanged()"
        />
        <DatePickerField
          isValueAsString
          label="Ende"
          :isComponentHalfSize="true"
          v-model="sprod.ende"
          :disabled="!isEditable"
          @input="dataChanged()"
        />
        <InputField
          type="currency"
          label="aktueller Wert"
          :isComponentHalfSize="true"
          :disabled="true"
          v-model="sprod.aktWert"
          @change="dataChanged()"
        />
        <ComboBox
          label="Zahlrhythmus"
          :isComponentHalfSize="true"
          :firstEmpty="true"
          :values="combos.zahlrhythmus"
          v-model="sprod.zahlrhythm"
          :disabled="!isEditable"
          @change="dataChangedCombo('zahlrhythm', $event)"
        />
        <InputField
          type="currency"
          label="Sparrate pro Periode"
          :isComponentHalfSize="true"
          v-model="sprod.beitrag"
          :disabled="!isEditable"
          @change="dataChanged()"
        />
        <InputField
          type="currency"
          label="Anfangswert/Bezugssumme"
          :isComponentHalfSize="true"
          v-model="sprod.bezugssumme"
          :disabled="!isEditable"
          @change="dataChanged()"
        />
      </div>

      <div v-if="sprodRights.allowReadSollprovision" class="mb-3">
        <ComboBox
          label="Provision"
          :isComponentHalfSize="true"
          :firstEmpty="true"
          :values="provisionValues"
          :disabled="!sprodRights.allowModifySollprovision || !isEditable"
          v-model="sprod.provVorgabe"
          @change="dataChangedCombo('provVorgabe', $event)"
        />
        <InputField
          type="currency"
          label="Sollprovision"
          :isComponentHalfSize="true"
          :disabled="sprod.provVorgabe !== PROVISION_MANUELL || !sprodRights.allowModifySollprovision || !isEditable"
          v-model="sprod.sollprovision"
          @change="dataChanged()"
        />
      </div>

      <div v-if="sprodRights.allowReadBearbStatus" class="mb-3">
        <ComboBox
          label="Bearbeitungsstatus"
          :isComponentHalfSize="true"
          :values="combos.bearbeitungsstatus"
          v-model="sprod.bearbeitungsstatus"
          :disabled="!isEditable"
          @change="dataChangedCombo('bearbeitungsstatus', $event)"
        />
        <DatePickerField
          v-if="!isImmobilie"
          isValueAsString
          label="Datum Status"
          :isComponentHalfSize="true"
          v-model="sprod.bearbStatusDate"
          :disabled="!isEditable"
          @input="dataChanged()"
        />
        <InputField
          v-if="!isImmobilie"
          label="Bemerkung Status"
          :isComponentHalfSize="true"
          v-model="sprod.bearbStatusBem"
          :disabled="!isEditable"
          @change="dataChanged()"
        />
        <BaseButton v-if="isIntern" :disabled="creatingAppointment" @click="createAppointment()">Neue Aktivität aus Status</BaseButton>
      </div>

      <EntitySelector
        label="Bankverbindung"
        :isComponentHalfSize="true"
        :values="combos.bankingAccounts"
        v-model="sprod.bankingAccount"
        :disabled="!isEditable"
        :validateUntouched="true"
        @change="dataChangedCombo('bankingAccount', $event)"
        @new="newBankverbindung()"
        @edit="editBankverbindung()"
      />

      <InputTextArea
        label="Beschreibung / Bemerkung"
        :isComponentHalfSize="true"
        :disabled="!isEditable"
        v-model="sprod.bemerkung"
        @change="dataChanged()"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CORE_TYPES from '@/store/core/types';
import OTHER_PRODUCTS_TYPES from '@/store/otherProducts/types';
import CUSTOMERDATA_TYPES from '@/store/customerData/types';

import InputField from '@/components/core/forms/InputField.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import EntitySelector from '@/components/core/forms/EntitySelector.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';

import validator from '@/mixins/validator';
import { required } from '@/mixins/validator/rules';

const PRODUKTART_FREIE_EINGABE = 'Freie Eingabe';
const PRODUKTART_IMMOBILIE = 'Immobilie';
const NUTZUNG_MISCHNUTZUNG = 'Mischnutzung';

const PROVISION_VORGABE = 0;
const PROVISION_MANUELL = 3;

export default {
  mixins: [validator],
  data() {
    return {
      PROVISION_MANUELL,
      sprod: {
        id: null,
        company: null,
        sprodNr: null,
        produktTyp: this.currentType,
        produktart: null,
        freieEingabeProduktart: null,
        tarif: null,
        eingangZahlungBetreuung: true,
        zahlrhythm: null,
        begin: null,
        ende: null,
        beitrag: null,
        bezugssumme: null,
        produktbez: null,
        autoupdate: true,
        fremdanlage: true,
        aktWert: null,
        bearbeitungsstatus: null,
        bearbStatusBem: null,
        bearbStatusDate: null,
        bankingAccount: null,
        antragsNrIntern: null,
        nutzung: null,
        wohnflaeche: null,
        wohnflaecheEigennutzung: null,
        wohnflaecheFremdnutzung: null,
        anlageArtenImmobilie: null,
        gebaeudetyp: null,
        miete: null,
        adresse1: null,
        adresse2: null,
        betreuerwechsel: null,
        provVorgabe: null,
        sollprovision: null,
        bemerkung: null,
        einnahmen: null,
      },
      creatingAppointment: false,
    };
  },
  computed: {
    ...mapGetters({
      currentType: OTHER_PRODUCTS_TYPES.GETTERS.CURRENT_TYPE,
      sprodId: OTHER_PRODUCTS_TYPES.GETTERS.SELECTED_SPROD_ID,
      isNew: OTHER_PRODUCTS_TYPES.GETTERS.IS_NEW,
      combos: OTHER_PRODUCTS_TYPES.GETTERS.COMBOS,
      sprodRights: OTHER_PRODUCTS_TYPES.GETTERS.RIGHTS,
      otherProduct: OTHER_PRODUCTS_TYPES.GETTERS.OTHER_PRODUCT,
      otherProductEdited: OTHER_PRODUCTS_TYPES.GETTERS.OTHER_PRODUCT_EDITED,
      isFA: CORE_TYPES.GETTERS.IS_FA,
      isByPass: CORE_TYPES.GETTERS.IS_BYPASS,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
    }),
    isIntern() {
      return this.hasRoles([this.VIEW_ROLES.VIEW_CUSTOMER_AS_INTERN]);
    },
    isImmobilie() {
      return this.sprod?.produktart === PRODUKTART_IMMOBILIE;
    },
    isNutzungMischnutzung() {
      return this.sprod?.nutzung === NUTZUNG_MISCHNUTZUNG;
    },
    isProduktartFreieEingabe() {
      return this.checkIfProduktartIsFreieEingabe(this.sprod?.produktart);
    },
    provisionValues() {
      return [ 
        { label: 'Vorgabe', value: PROVISION_VORGABE, }, 
        { label: 'Manuell', value: PROVISION_MANUELL, }, 
      ];
    },
    isEditable() {
      return this.otherProduct?.allowModify || this.isNew;
    },
  },
  watch: {
    otherProduct() {
      this.updateData();
    },
    currentType: {
      handler(value) {
        if(!this.sprod?.produktTyp) {
          this.sprod.produktTyp = value;
        }
      },
      immediate: true,
    },
  },
  methods: {
    checkData() {
      if(!this.sprod?.produktart?.trim()) {
        return false;
      }
      return true;
    },
    async save() {
      await this.$store.dispatch(OTHER_PRODUCTS_TYPES.ACTIONS.SAVE_OTHER_PRODUCT);
    },
    updateData() {
      Object.keys(this.sprod).forEach(key => {
        if(key in this.otherProductEdited) {
          this.sprod[key] = this.otherProductEdited[key];
        } else if(key in this.otherProduct) {
          this.sprod[key] = this.otherProduct[key];
        }
      });

      if(this.sprod?.bankingAccount) {
        this.sprod.bankingAccount = `${this.sprod.bankingAccount}`;
      }
    },
    isChanged(group) {
      return Object.keys(group)
        .filter(fieldName => this.otherProduct[fieldName] !== group[fieldName])
        .length > 0;
    },
    dataChanged() {
      const groupData = this.isChanged(this.sprod) ? this.sprod : null;

      if (groupData !== null || groupData !== undefined) {
        const payload = {
          ...this.otherProduct, 
          ...groupData,
          id: this.sprodId,
        };

        this.$store.commit(OTHER_PRODUCTS_TYPES.MUTATIONS.APPEND_OTHER_PRODUCT_EDITED, payload);
      }
    },
    dataChangedCombo(field, value) {
      this.sprod[field] = value;
      this.dataChanged();
    },
    checkIfProduktartIsFreieEingabe(value) {
      return value?.startsWith(PRODUKTART_FREIE_EINGABE);
    },
    clearFreieEingabeProduktartIfNeeded(value) {
      if(!this.checkIfProduktartIsFreieEingabe(value)) {
        this.sprod.freieEingabeProduktart = '';
        this.dataChanged();
      }
    },
    newBankverbindung() {
      const personId = 'person1';
      this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.UPDATE_SELECTED_CUSTOMER_DATA, personId);
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GOTO_BANKVERBINDUNG, this.backButtonPayload);
    },
    editBankverbindung() {
      const personId = 'person1';
      this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.UPDATE_SELECTED_CUSTOMER_DATA, personId);
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GOTO_BANKVERBINDUNG, this.backButtonPayload);
    },
    async createAppointment() {
      this.creatingAppointment = true;
      await this.$store.dispatch(OTHER_PRODUCTS_TYPES.ACTIONS.CREATE_APPOINTMENT);
      this.creatingAppointment = false;
    },
    setProduktartIfNeeded() {
      if(this.currentType === PRODUKTART_IMMOBILIE) {
        this.sprod.produktart = PRODUKTART_IMMOBILIE;
      }
    },
  },
  mounted() {
    this.updateData();
    this.setProduktartIfNeeded();
  },
  async beforeDestroy() {
    if(this.checkData()) {
      await this.save();
    }
  },
  validators: {
    sprod: {
      produktart: [required()],
      bankingAccount: [  {
        ruleName: 'bankingAccount',
        getMessage: (field) => `Die Bankverbindung ist nicht gültig`,
        isValid: (value, modul) => {
          const bankingAccounts =  modul?.combos?.bankingAccounts;
          if (!value || !bankingAccounts) {
            return true
          }
          return -1 != bankingAccounts.findIndex(b => b.value == value)
        },
      }],
    }
  },
  components: {
    InputField,
    InputToggleSwitch,
    ComboBox,
    DatePickerField,
    BaseButton,
    EntitySelector,
    InputTextArea,
  },
}
</script>
