import CALENDAR_TYPES from "./types";
import { getInitialState } from "./index";
import { isActivityTypeBereich } from "./actions";
import dayjs from "dayjs";
import Vue from "vue";

export default {
  [CALENDAR_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState());
  },

  [CALENDAR_TYPES.MUTATIONS.SET_SHOWN_DAYS](state, payload) {
    state.shownDays = payload;
  },
  [CALENDAR_TYPES.MUTATIONS.SET_SHOWN_HOURS](state, payload) {
    state.shownHours = payload;
  },

  [CALENDAR_TYPES.MUTATIONS.SET_CALENDAR_DATA](state, payload) {
    state.refinedCalendarData = [];
    state.refinedCalendarData = payload;
    state.calendarDataReady = true;
  },
  [CALENDAR_TYPES.MUTATIONS.SET_WEEK_CALENDAR_DATA](state, payload) {
    state.weekCalendarData = [];
    state.weekCalendarData = payload;
    state.calendarDataReady = true;
  },

  [CALENDAR_TYPES.MUTATIONS.RENEW_CALENDAR_DATA](state) {
    // state.weekCalendarData = [];
    // state.refinedCalendarData = [];
    state.calendarDataReady = false;
  },

  [CALENDAR_TYPES.MUTATIONS.SET_DAY_ELEMENTS](state, payload) {
    state.dayElements = payload;
  },

  [CALENDAR_TYPES.MUTATIONS.SET_HOURS_START](state, payload) {
    state.hoursStart = payload;
  },
  [CALENDAR_TYPES.MUTATIONS.SET_HOURS_STOP](state, payload) {
    state.hoursStop = payload;
  },
  [CALENDAR_TYPES.MUTATIONS.PUSH_COLUMN_EVENT_ARRAY](state, payload) {
    state.columnEventArray.push(payload);
  },
  [CALENDAR_TYPES.MUTATIONS.RENEW_COLUMN_EVENT_ARRAY](state) {
    state.columnEventArray = [];
  },
  [CALENDAR_TYPES.MUTATIONS.SET_HOURS_CONTENT](state, payload) {
    state.hoursContent = payload;
  },
  [CALENDAR_TYPES.MUTATIONS.RESET_HOURS_CONTENT](state) {
    state.hoursContent = [];
  },
  [CALENDAR_TYPES.MUTATIONS.SET_WEEKS_CONTENT](state, payload) {
    state.weeksContent = payload;
  },
  [CALENDAR_TYPES.MUTATIONS.SET_SELECTED_APPOINTMENT_DATA](state, payload) {
    state.selectedAppointment = {
      ...payload.appointment,
      appointment: {
        ...(payload.appointment?.appointment || {}),
        activity:
          payload.appointment?.appointment &&
          (isActivityTypeBereich(payload.appointment.appointment.bereich) ||
            !!payload.appointment?.appointment.art),
      },
    };
    if (payload.day === null || payload.day === undefined) {
      let modified = false;
      if (payload.appointment?.appointment.begin) {
        state.appointmentBeginDate = dayjs(
          payload.appointment.appointment.begin.split(" ")[0],
          "DD.MM.YYYY"
        ).format("DD.MM.YYYY");
        state.appointmentBeginTime = payload.appointment.appointment.begin
          .split(" ")[1]
          .substring(0, 5);
        modified = true;
      }
      if (payload.appointment?.appointment.fullDay) {
        if (payload.appointment.appointment.begin) {
          state.appointmentEndDate = dayjs(
            payload.appointment.appointment.begin.split(" ")[0],
            "DD.MM.YYYY"
          ).format("DD.MM.YYYY");
        }
        state.appointmentEndTime = "23:59:00";
        modified = true;
      }
      if (payload.appointment?.appointment.end) {
        state.appointmentEndDate = dayjs(
          payload.appointment.appointment.end.split(" ")[0],
          "DD.MM.YYYY"
        ).format("DD.MM.YYYY");
        state.appointmentEndTime = payload.appointment.appointment.end
          .split(" ")[1]
          .substring(0, 5);
        modified = true;
      }
      if (payload.appointment?.appointment.retryMaxDate !== null) {
        state.selectedAppointment.appointment.retryMaxDate = dayjs(
          payload.appointment.appointment?.retryMaxDate?.substring(
            0,
            payload.appointment.appointment.retryMaxDate.length - 11
          ),
          "YYYY-MM-DD"
        ).format("DD.MM.YYYY");
        modified = true;
      }
      if (!modified) {
        state.appointmentBeginTime = dayjs(payload.day).format("HH") + ":00";
        state.appointmentEndTime =
          dayjs(payload.day)
            .add(1, "hour")
            .format("HH") + ":00";
        state.appointmentBeginDate = dayjs(payload.day).format("DD.MM.YYYY");
        state.appointmentEndDate = dayjs(payload.day).format("DD.MM.YYYY");
      }
    } else {
      state.appointmentBeginTime = dayjs(payload.day).format("HH") + ":00";
      state.appointmentEndTime =
        dayjs(payload.day)
          .add(1, "hour")
          .format("HH") + ":00";
      state.appointmentBeginDate = dayjs(payload.day).format("DD.MM.YYYY");
      state.appointmentEndDate = dayjs(payload.day).format("DD.MM.YYYY");
    }
  },

  [CALENDAR_TYPES.MUTATIONS.SET_CLICKED_ON_EVENT](state, payload) {
    state.clickedOnEvent = payload;
  },
  [CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_COMBOS](state, payload) {
    state.appointmentCombos = payload;
  },
  [CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_LABEL](state, payload) {
    state.selectedAppointment.appointment = {
      ...state.selectedAppointment.appointment,
      label: payload,
    };
  },
  [CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_PLACE](state, payload) {
    state.selectedAppointment.appointment.place = payload;
  },
  [CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_TEXT](state, payload) {
    state.selectedAppointment.appointment.text = payload;
  },
  [CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_FULL_DAY](state, payload) {
    state.selectedAppointment.appointment.fullDay = payload;
  },
  [CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_RETRY](state, payload) {
    state.selectedAppointment.appointment.retry = payload;
  },
  [CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_RETRY_COUNT](state, payload) {
    state.selectedAppointment.appointment.retryCount = payload;
  },
  [CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_RETRY_MAX_DATE](state, payload) {
    state.selectedAppointment.appointment.retryMaxDate = payload;
  },
  [CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_EMAIL](state, payload) {
    state.selectedAppointment.appointment.alarmEmail =
      payload === "" ? null : +payload;
    if (payload) {
      // state.selectedAppointment.appointment.alarmModusEmail = 'MINUTEN'
    }
  },
  [CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_POPUP](state, payload) {
    state.selectedAppointment.appointment.alarmMsc =
      payload === "" ? null : +payload;
    if (payload) {
      // state.selectedAppointment.appointment.alarmModusMSC = 'MINUTEN'
    }
  },

  [CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_END_DATE](state, payload) {
    state.appointmentEndDate = payload;
  },
  [CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_END_TIME](state, payload) {
    state.appointmentEndTime = payload;
  },
  [CALENDAR_TYPES.MUTATIONS.SET_DATES](state, payload) {
    state.datesToSend = payload;
  },
  [CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_BEGIN_DATE](state, payload) {
    state.appointmentBeginDate = payload;
  },
  [CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_BEGIN_TIME](state, payload) {
    state.appointmentBeginTime = payload;
  },
  [CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_BEGIN](state, payload) {
    state.selectedAppointment.appointment.begin = payload;
  },
  [CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_END](state, payload) {
    state.selectedAppointment.appointment.end = payload;
  },
  [CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_DURATION](state, payload) {
    state.appointmentDuration = payload;
  },
  [CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT](state, payload) {
    state.selectedAppointment.appointment = {
      ...state.selectedAppointment.appointment,
      ...payload,
    };
  },
  [CALENDAR_TYPES.MUTATIONS.SET_NEW_ACTIVITY_AUFGABE](state) {
    state.selectedAppointment.appointment = {
      ...state.emptyAppointment,
      activity: false,
      bereich: "Kunde",
      art: "Aufgabe",
    };
    state.beteiligteForUpdate = [];
    state.presaveAppointment = null;
  },
  [CALENDAR_TYPES.MUTATIONS.SET_NEW_ACTIVITY](state) {
    state.selectedAppointment.appointment = {
      ...state.emptyAppointment,
      activity: true,
      bereich: "Kunde",
    };
    state.beteiligteForUpdate = [];
    state.presaveAppointment = null;
  },
  [CALENDAR_TYPES.MUTATIONS.SET_NEW_AUFGABE](state) {
    state.selectedAppointment.appointment = {
      ...state.emptyAppointment,
      activity: false,
      bereich: "Aufgabe",
    };
    state.beteiligteForUpdate = [];
  },
  [CALENDAR_TYPES.MUTATIONS.SET_NEW_TERMIN](state) {
    state.selectedAppointment.appointment = {
      ...state.emptyAppointment,
      activity: false,
      bereich: "Termin",
    };
    state.beteiligteForUpdate = [];
    state.presaveAppointment = null;
  },
  [CALENDAR_TYPES.MUTATIONS.UPDATE_BETEILIGTE_ROLLE](state, payload) {
    state.selectedAppointment.beteiligteOutput[payload.index].beteiligte.rolle =
      payload.rolle;
  },
  [CALENDAR_TYPES.MUTATIONS.DELETE_BETEILIGTE_SUCCESS](state, payload) {
    state.selectedAppointment = {
      ...state.selectedAppointment,
      beteiligteOutput: payload.beteiligteOutput,
    };
  },
  [CALENDAR_TYPES.MUTATIONS.DELETE_BETEILIGTE_BY_VALUE](state, value) {
    state.beteiligteForUpdate = state.beteiligteForUpdate.filter(
      (x) => x.name !== value
    );
  },
  [CALENDAR_TYPES.MUTATIONS.SET_BETEILIGTE_FOR_UPDATE](state, payload) {
    if (payload && Array.isArray(payload)) {
      state.beteiligteForUpdate = payload;
    }
  },
  [CALENDAR_TYPES.MUTATIONS.ADD_BETEILIGTE_FOR_UPDATE](state, payload) {
    state.beteiligteForUpdate.forEach((bet, index) => {
      if (
        (bet.id && bet?.id === payload?.id) ||
        (bet.customerId && bet.customerId === payload?.customerId)
      ) {
        if (index > -1) {
          state.beteiligteForUpdate.splice(index, 1);
        }
      }
    });
    state.beteiligteForUpdate = [...state.beteiligteForUpdate];
    state.beteiligteForUpdate.push(payload);
  },
  [CALENDAR_TYPES.MUTATIONS.UPDATE_BETEILIGTE](state, payload) {
    state.beteiligteForUpdate.forEach((element, i) => {
      if (
        (payload.id &&
          (element.id === payload.id ||
            element?.beteiligte?.id === payload.id)) ||
        (payload.name && element.name === payload.name) ||
        payload.index === i
      ) {
        const { index, data, beteiligte, ...props } = payload;

        Vue.set(element, "beteiligte", props);
      }
    });
    state.beteiligteForUpdate = [...state.beteiligteForUpdate];
  },
  [CALENDAR_TYPES.MUTATIONS.GET_APPOINTMENT_SETUP](state, payload) {
    state.appointmentSetup = payload;
  },
  [CALENDAR_TYPES.MUTATIONS.GET_CUSTOMER_APPOINTMENTS](state, payload) {
    state.customerAppointments = payload;
  },
  [CALENDAR_TYPES.MUTATIONS.PRESAVE_APPOINTMET](state, payload) {
    state.presaveAppointment = payload;
  },
  [CALENDAR_TYPES.MUTATIONS.SET_ATTACHMENT](state, payload) {
    state.attachments = payload;
  },
  [CALENDAR_TYPES.MUTATIONS.LOAD_APPOINTMENT](state, payload) {
    state.loadAppointment = payload;
  },
  [CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_CONFIG](state, payload) {
    state.appointmentConfig = payload;
  },
};
