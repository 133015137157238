<template>
  <div>
    <div class="mb-3">
      <BaseButton @click="newAppointment()">Neue Aktivität anlegen</BaseButton>
    </div>

    <Table v-if="!loading && rows.length"
        tableId="7cc3927e-1cfd-4027-863a-808f524ffb31"
        :headers="headers"
        :rows="rows"
        @click-label="openEditModal"
        @action-DELETE="openDeleteModal"
    />
    <div v-else-if="loading" class="text-centered"><AnimatedSpinner /></div>
    <div v-else>Keine Daten</div>

    <BaseModal
      ref="modalDelete"
      modalTitle="Eintrag löschen"
      size="sm"
      @onConfirmButton="deleteAppointment()"
    >
      <template #default>
        Soll der Eintrag "{{ appointmentToDelete && appointmentToDelete.label || '' }}" wirklich gelöscht werden?
      </template>
    </BaseModal>
  </div>
</template>

<script>
import CALENDAR_TYPES from '@/store/calendar/types';

import {
    PhCheck,
    PhTrash,
} from 'phosphor-vue';

import BaseButton from '@/components/core/BaseButton.vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn, DateColumn, IconColumn, PillColumn, Icon, ActionColumn, SimpleAction, CurrencyColumn} from "@/components/table2/table_util.js";
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue';
import BaseModal from '@/components/core/BaseModal.vue';
import AppointmentEditCreate from '@/components/calendar/appointmentFiles/AppointmentEditCreate.vue';
import Pill from '@/components/core/Pill.vue';

const MAP_WV_STATUS_TO_PILL_TYPE = {
  UNBESTAETIGTE_VERGANGENHEIT: 'warning',
  UNBESTAETIGTE_ZUKUNFTIG: 'info',
  BESTAETIGTE: 'success',
};


export default {
  props: {
    bereich: {
      type: String,
      default: null,
    },
    bereichId: {
      type: [String, Number],
      default: null,
    },
    bereichText: {
      type: Function,
    },
    appointments: {
      type: Array,
      default: () => [],
    },
    appointmentCombos: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      appointmentToDelete: null,
      MAP_WV_STATUS_TO_PILL_TYPE,
    };
  },
  computed: {
    headers() {
        return {
            lockedLeft: [
                TextColumn("label", "Betreff").makeLink(),
                DateColumn("begin", "Zeitpunkt"),
                PillColumn("wvStatusMessage", "Status"),
            ],
            center: [
                IconColumn("retry", "Wiederholung"),
                TextColumn("adressatText", "Adressat"),
                TextColumn("text", "Text"),
                TextColumn("artText", "Art"),
                TextColumn("bereichText", "Bereich"),
                TextColumn("customerNames", "Beteiligte Kunden"),
                TextColumn("ownerName", "Besitzer"),
                DateColumn("dateCreated", "Erstellungsdatum"),
                CurrencyColumn("geschaeftwert", "Geschätzter Geschäftswert"),
                TextColumn("produkt", "Produkt"),
                TextColumn("sellingPhaseText", "Verkaufphase"),
                TextColumn("statusText", "Status"),
            ],
            lockedRight: [
                ActionColumn("actions", "Aktionen"),
            ],
        };
    },
    rows() {
        if (!this.appointments)
            return [];
        const actions = [
            SimpleAction("DELETE", PhTrash, "Löschen"),
        ];
        return this.appointments.map(({appointment, beteiligteOutput}) => {
            const icons = [];
            if (appointment.retry && appointment.retry !== 'Keine')
                icons.push(Icon(PhCheck, "Ja", 24));
            return {
                ...appointment,
                wvStatusMessage: appointment.wvStatus ? {
                    label: this.appointmentCombos?.wvStatusMessage?.[appointment.wvStatus],
                    type: MAP_WV_STATUS_TO_PILL_TYPE[appointment.wvStatus],
                } : null,
                retry: icons,
                artText: appointment.art ? this.appointmentCombos?.artComboWV?.[appointment.art] : '',
                adressatText: this.getAdressatText(appointment, beteiligteOutput),
                bereichText: this.getBereichText(appointment),
                customerNames: beteiligteOutput.filter(o => !!o?.customerName).map(o => o?.customerName).join(', '),
                sellingPhaseText: this.getSellingPhaseText(appointment),
                statusText: this.getStatusText(appointment),
                actions,
            };
        });
    },
  },
  methods: {
    newAppointment() {
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT, {});
      this.openAppointment();
    },
    openEditModal(appointment) {
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_CLICKED_ON_EVENT, true);
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT, {});
      this.$store.dispatch(CALENDAR_TYPES.ACTIONS.RETRIEVE_SELECTED_APPOINTMENT, appointment.sid);
      this.openAppointment();
    },
    openAppointment() {
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_CONFIG, {
        title: 'Aktivität bearbeiten',
        bereich: this.bereich,
        bereichId: this.bereichId,
        discardChanges: this.resetAppointment,
        isActivity: false,
        selectedDate: null,
  
        back: this.$router.currentRoute.fullPath,
      })
      this.$router.push({ path: `/communication/appointment` });
    },
    openDeleteModal(appointment) {
      this.appointmentToDelete = { ...appointment };
      this.$refs.modalDelete.open();
    },
    async deleteAppointment() {
      if(this.appointmentToDelete) {
        this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT, this.appointmentToDelete);
        await this.$store.dispatch(CALENDAR_TYPES.ACTIONS.DELETE_APPOINTMENT);
        this.$emit('renew');
      }
    },
    resetAppointment() {
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.RENEW_CALENDAR_DATA);
    },
    renewAppointmentView() {
      this.$emit('renew');
    },
    getAdressatText(appointment, beteiligteOutput) {
      const adressatText = [];
      if(appointment?.ownerName) {
        adressatText.push(appointment.ownerName);
      }
      beteiligteOutput.forEach(o => adressatText.push(o.name));
      return adressatText.join(', ');
    },
    getBereichText(appointment) {
      if(this.bereichText) {
        return this.bereichText(appointment);
      }
      return '';
    },
    getSellingPhaseText(appointment) {
      if(!appointment.sellingPhase) return '';
      return appointment.activity ? this.appointmentCombos?.verkaufsphaseWVCombo?.[appointment.sellingPhase] : '';
    },
    getStatusText(appointment) {
      if(!appointment.status) return '';
      return appointment.activity ? this.appointmentCombos?.statusComboWV?.[appointment.status] : this.appointmentCombos?.statusComboAufgabe?.[appointment.status];
    },
  },
  mounted() {
  },
  components: {
    BaseButton,
    Table,
    AnimatedSpinner,
    BaseModal,
    AppointmentEditCreate,
    Pill,
  },
}
</script>
