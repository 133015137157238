<template>
  <div class="registration-page">
    <HeaderLogin @backButtonPressed="goToLogin()"/>

    <div data-app-content class="registration-page__container">
      <div class="box__container">
        <div class="box__title">kostenfrei registrieren</div>

        <p>* Pflichtfelder</p>

        <form autocomplete="off" @submit.prevent="checkDataAndGoToPrivacyPage()">
          <ComboBox
            id="gender"
            label="Anrede*"
            :values="genderValues"
            v-model="form.gender"
          />
          <InputField
            id="firstName"
            label="Vorname*"
            v-model="form.firstName"
          />
          <InputField
            id="lastName"
            label="Nachname*"
            v-model="form.lastName"
          />
          <InputField
            id="email"
            label="E-Mail*"
            v-model="form.email"
            autocomplete="off"
          />
          <InputField
            id="phone"
            label="Mobilnummer*"
            v-model="form.phone"
            autocomplete="off"
            @change="checkPhoneFormat($event)"
            inputmode="tel"
          />
          <InputField
            id="new-password"
            label="Passwort*"
            type="password"
            allowPlainPassword
            allowPasswordRules
            v-model="form.password"
            autocomplete="new-password"
          />
          <InputField
            id="new-password-again"
            label="Passwort Bestätigung*"
            type="password"
            allowPlainPassword
            allowPasswordRules
            v-model="form.passwordConfirm"
            autocomplete="new-password"
          />
          <PasswordPolicy class="change-password--policy is-collapsable" isCollapsable />
          <InputField v-if="!hasValidMaklernr"
            id="registerCode"
            label="Registrierungscode*"
            v-model="form.registerCode"
            autocomplete="one-time-code"
            @input="checkCodeDebounce"
          />

          <p>
            Wir senden Ihnen regelmäßig E-Mails mit Angeboten zu unseren Dienstleistungen. 
            Sie können dieser Nutzung zu Werbezwecken jederzeit über den Link in den E-Mails kostenlos widersprechen.
          </p>

          <div class="mb-16px" v-if="captchaData && captchaData.captchaImage">
            <img :src="captchaData.captchaImage" />
            <InputField
              placeholder="Geben Sie bitte den Text vom Bild*"
              v-model="form.captchaStr"
            />
          </div>

          <div v-if="datenschutzText" class="mb-4">
            <InputCheckBoxItem 
              :label="datenschutzText"
              v-model="datenschutzConfirmed"
              @click="openLink"
            />
          </div>

          <BaseButton isSecondary class="mr-16px" @click="goToLogin()">
            Zurück zur Anmeldung
          </BaseButton>

          <BaseButton type="submit" :disabled="isFormInvalid || loading || !datenschutzText || !datenschutzConfirmed">
            Registrieren <AnimatedSpinner v-if="loading" />
          </BaseButton>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import CORE_TYPES from '@/store/core/types';
import CUSTOMERDATA_TYPES from '@/store/customerData/types';
import { mapGetters } from 'vuex';

import validator from '@/mixins/validator';
import { required, email, telefon, minLength } from '@/mixins/validator/rules';
import { getRegistrationQuery } from '@/router/features-routes/login.js';

import HeaderLogin from '@/components/core/login/HeaderLogin.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputField from '@/components/core/forms/InputField.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue';

import { MIN_PASSWORD_LENGTH } from '@/views/core/ChangePassword.vue';
import InputCheckBoxItem from '@/components/core/forms/checkbox/InputCheckBoxItem.vue';

import { debounce, } from '@/helpers/commonfunctions.js';

import PasswordPolicy from '@/views/core/change-password/PasswordPolicy.vue';

const GENDER_VALUES =  [
  { value: 'herr', label: 'Herr' },
  { value: 'frau', label: 'Frau' },
  { value: 'firma', label: 'Firma/Verein' }
];
const PHONE_CHECK_TYPE = 'Mobiltelefon';


export default {
  mixins: [validator],
  data() {
    return {
      loading: false,
      genderValues: GENDER_VALUES,
      maklernrIsValid: false,
      form: {
        gender: GENDER_VALUES[0].value,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        password: '',
        passwordConfirm: '',
        registerCode: '',
        captchaStr: '',
      },
      datenschutzText: '',
      datenschutzVersion: '',
      datenschutzConfirmed: false,
    };
  },
  computed: {
    ...mapGetters({
      privacyRegistrationData: CORE_TYPES.GETTERS.PRIVACY_REGISTRATION_DATA,
      registrationData: CORE_TYPES.GETTERS.REGISTRATION_DATA,
      maklerInfo: CORE_TYPES.GETTERS.GET_BROKER_INFORMATION,
      isDefaultBrokerID: CORE_TYPES.GETTERS.IS_DEFAULT_BROKER_ID,
      captchaData: CORE_TYPES.GETTERS.CAPTCHA_DATA,
    }),
    maklernrFromBrokerInfo(){
      return this.maklerInfo?.maklernr;
    },
    maklernr() {
      return this.$route.query.maklernr || this.maklernrFromBrokerInfo;
    },
    registrationCode() {
      return this.maklernr || this.form?.registerCode || '';
    },
    hasValidMaklernr() {
      return !!this.maklernr && this.maklernrIsValid;
    },
    hasDefaultMaklernr() {
      return this.isDefaultBrokerID(this.maklernr)
    },
    isFormInvalid() {
      return this.validation.updated && this.validation.isInvalid('form')
    }
  },
  watch: {
    maklernr: {
      handler(newMaklernr) {
        if (!this.isDefaultBrokerID(this.maklernr)) {
          this.form.registerCode = newMaklernr;
          this.checkMaklernrIfExists();
        }
      },
      immediate: true,
    },
    'form.password'(newPassword) {
      if (this.form.passwordConfirm && newPassword !== this.form.passwordConfirm) {
        this.$pushErrors('form.passwordConfirm', 'Fehler. Passwörter stimmen nicht überein!')
      }
    },
    'form.passwordConfirm'(passwordConfirm) {
      if (this.form.password !== passwordConfirm) {
        this.$pushErrors('form.passwordConfirm', 'Fehler. Passwörter stimmen nicht überein!')
      }
    },
    privacyRegistrationData: {
      handler(value) {
        this.datenschutzText = value?.config?.acceptLabelHtmlText;
        this.datenschutzVersion = value?.config?.metaDataConfig?.version;
      },
      immediate: true,
    }
  },
  methods: {
    // Check code can still send maklernr when this number comes from makernr query
    // But the customer is not allowed to type Maklernr as a valid Registrierungscode
    checkCode(code) {
      return this.$store.dispatch(CORE_TYPES.ACTIONS.CHECK_REGISTRATION_CODE, { code }).then((valid) => {
          this.maklernrIsValid = valid;
          if (valid) {
            this.getDatenschutzText(code);
          } else {
            this.$store.commit(CORE_TYPES.MUTATIONS.RESET_PRIVACY_REGISTRATION_DATA);
          }
        })
        .catch(() => this.maklernrIsValid = false);
    },
    checkCodeDebounce: debounce(async function checkCodeDebounce(code) {
      if (code?.length === 10) { // Only proper Registrierungscode are allowed to be typed
        this.checkCode(code);
      }
    }),
    checkMaklernrIfExists() {
      if(!this.maklernr) {
        return ;
      }
      this.checkCode(this.maklernr)
    },
    async checkCaptchaAndCode() {
      const response = await this.$store.dispatch(CORE_TYPES.ACTIONS.CHECK_CAPTCHA_AND_REGISTRATION_CODE, {
        code: this.registrationCode,
        captcha: this.form.captchaStr,
      });
      return response;
    },
    checkPhoneFormat(value) {
      if(!value) {
        return ;
      }

      const payload = {
        type: PHONE_CHECK_TYPE,
        value: value,
      };

      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.CHECK_FORMAT_COMMUNICATION, payload)
        .catch((error) => {
          if(error?.text) {
            this.$pushErrors('form.phone', error?.text)
          }
        });
    },
    checkData() {
      return new Promise((resolve, reject) => {
        this.checkCaptchaAndCode()
          .then((response) => {
            if (!response) {
              reject();
            } else if (!response?.regCode || !response?.captcha) {
              if(!response?.regCode) {
                this.$pushErrors('form.registerCode', 'Fehler: Registrierungscode ist ungültig');
              }
              if (!response?.captcha) {
                this.$pushErrors('form.captchaStr', 'Fehler: Captcha ist ungültig');
              }
              reject();
            } else if (response?.regCode && response?.captcha) {
              resolve();
            }
          });
      });
    },
    goToPrivacyPage() {
      this.$router.push({
        path: '/login/register/privacy',
        query: getRegistrationQuery(this.$route),
      });
    },
    checkDataAndGoToPrivacyPage() {
      this.loading = true;
      this.checkData()
        .then(() => {
          const payload = { 
            ...this.form,
            captchaToken: this.captchaData?.captchaToken,
          };
          this.$store.commit(CORE_TYPES.MUTATIONS.SET_REGISTRATION_DATA, payload);
          this.goToPrivacyPage();
        })
        .finally(() => this.loading = false);
    },
    goToLogin() {
      this.$store.commit(CORE_TYPES.MUTATIONS.GET_PRIVACY_REGISTRATION_DATA_SUCCESS, null);
      CORE_TYPES.MUTATIONS.GET_PRIVACY_REGISTRATION_DATA_SUCCESS
      this.$router.push({
        path: '/login',
        query: getRegistrationQuery(this.$route),
      });
    },
    getDatenschutzText(code) {
      if (code?.length >= 5) {
        this.$store.dispatch(CORE_TYPES.ACTIONS.GET_PRIVACY_REGISTRATION_DATA, {code: code});
      }
    },
    openLink(event) {
      if (event?.target?.id) {
        this.$store.commit(CORE_TYPES.MUTATIONS.SET_REGISTRATION_DATA, this.form);
        
        const type = event.target.id.toLowerCase()?.includes('agb') ? 'AGB_HTML' : 'DATENSCHUTZERKLAERUNG_HTML';
        this.$addBreadcrumb({
          fullPath: this.$route.fullPath,
        });
        this.$router.push(`/login/register/privacy/terms-content?version=${this.datenschutzVersion}&type=${type}`);
      }
    },
  },
  mounted() {
    if (this.$route.query?.backAction && this.registrationData) {
      this.form = this.registrationData;
    }

    this.$store.dispatch(CORE_TYPES.ACTIONS.GET_CAPTCHA);
  },
  validators: {
    form: {
      gender: [required('Anrede ist erforderlich!')],
      firstName: [required('Vorname ist erforderlich!')],
      lastName: [required('Nachname ist erforderlich!')],
      email: [required('E-Mail ist erforderlich!'), email()],
      phone: [required('Mobilnummer ist erforderlich!'), telefon()],
      password: [required('Passwort ist erforderlich!'), minLength(MIN_PASSWORD_LENGTH, "Das Passwort muss mindestens " + MIN_PASSWORD_LENGTH + " Zeichen haben!")],
      passwordConfirm: [required('Passwort Bestätigung ist erforderlich!')],
      registerCode: [required('Registrierungscode ist erforderlich!')],
      captchaStr: [required('Captcha ist erforderlich!')],
    },
  },
  components: {
    HeaderLogin,
    ComboBox,
    InputField,
    BaseButton,
    AnimatedSpinner,
    InputCheckBoxItem,
    PasswordPolicy,
  },
}
</script>

<style scoped>
.registration-page {
  padding-bottom: 1px;
}
.registration-page__container {
  margin-top: 24px;
  margin-bottom: 24px;
}

.registration-page__container > .box__container {
  max-width: 400px;
}
</style>
