function loadFondsshop() {
  if ( !document.getElementById('fk-fonds-shop-web-component') ) {
    const wcFs = document.createElement('script');
    wcFs.src = 'https://finance-cloud.de/fondsshop/fondsshop.js?cache=' + Math.ceil((new Date()).getTime() / 1000 / 3600 / 24 );
    wcFs.id = 'fk-fonds-shop-web-component';
    document.head.appendChild(wcFs);
  }
}

export default
    [
        {
            path: "home",
            component: () => {
                return import(
                    /* webpackChunkName: "home" */ "@/views/cms/template5/Home.vue"
                )
            }
        },
        {
            path: "home7",
            component: () => {
                return import(
                  /* webpackChunkName: "home" */ "@/views/cms/template7/Home.vue"
                )
            }
        },
        {
            path: "home8",
            component: () => {
                return import(
                  /* webpackChunkName: "home" */ "@/views/cms/template8/Home.vue"
                )
            }
        },
        {
            path: "fondsshop",
            component: () => {
                loadFondsshop();
                return import(
                    /* webpackChunkName: "fondsshop" */ "@/views/cms/template5/Fondsshop.vue"
                )
            }
        },
        {
            path: "anlageassistent",
            component: () => {
                loadFondsshop();
                return import(
                    /* webpackChunkName: "fondsshop" */ "@/views/cms/template8/Anlageassistent.vue"
                )
            }
        },
        {
            path: "aa_sofunktionierts",
            component: () =>
                import(
                /* webpackChunkName: "aa_sofunktionierts" */ "@/views/cms/template8/Sofunktionierts.vue"
                )
        },
        {
            path: "sofunktionierts",
            component: () =>
                import(
                /* webpackChunkName: "sofunktionierts" */ "@/views/cms/template5/Sofunktionierts.vue"
                )
        },
        {
            path: "modellportfolios",
            component: () =>
                import(
                /* webpackChunkName: "modellportfolios" */ "@/views/cms/template5/Modellportfolios.vue"
                )
        },
        {
            path: "fonds",
            component: () => {
                loadFondsshop();
                return import(
                    /* webpackChunkName: "fonds" */ "@/views/cms/template5/Fonds.vue"
                )
            }
        },
        {
            path: "assistent",
            component: () => {
                loadFondsshop();
                return import(
                    /* webpackChunkName: "fonds" */ "@/views/cms/template8/Assistent.vue"
                )
            }
        },
        {
            path: "einzelfonds",
            component: () => {
                loadFondsshop();
                return import(
                    /* webpackChunkName: "einzelfonds" */ "@/views/cms/template5/Einzelnfonds.vue"
                )
            }
        },
        {
            path: "details",
            component: () =>
                import(
                /* webpackChunkName: "details" */ "@/views/cms/template5/Details.vue"
                )
        },
        {
            path: "impressum",
            component: () =>
                import(
                /* webpackChunkName: "impressum" */ "@/components/cms/parts/ImpressumSelbst.vue"
                )
        },
        {
            path: "disclaimer",
            component: () =>
                import(
                /* webpackChunkName: "disclaimer" */ "@/components/cms/parts/Disclaimer.vue"
                )
        },
        {
            path: "datenschutz",
            component: () =>
                import(
                /* webpackChunkName: "datenschutz" */ "@/components/cms/parts/Datenschutz.vue"
                )
        },
        {
            path: "agb",
            component: () =>
                import(
                /* webpackChunkName: "agb" */ "@/components/cms/parts/AGB.vue"
                )
        },
        {
            path: "kontakt",
            component: () =>
                import(
                /* webpackChunkName: "kontakt" */ "@/views/cms/template5/Kontakt.vue"
                )
        },
        {
            path: "beschwerdemanagement",
            component: () =>
                import(
                /* webpackChunkName: "beschwerde" */ "@/views/cms/template5/Beschwerde.vue"
                )
        },
        {
            path: "esg",
            component: () =>
                import(
               /* webpackChunkName: "agb" */ "@/components/cms/parts/ESG.vue"
                )
        }
    ]