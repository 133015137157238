<template>
<div>
        <DashboardPanel 
        v-if="!loading"
      id="4d5f8286-e61f-4110-a06e-c8f2752042e1"
      :headerActions="headerActions"
      :data="dashboardData"
      :noPrimaryAction="false"
      ignoreUserLevelConfig
      @action-RELOAD-DATA="reloadData()"
      @action-TOGGLE-GESELLSCHAFT-PANEL="toggleGesellschaftPanel()"
      :title="title + ' | Festlegung der Versicherung für das '  + prfx + ' Dokument'">

        <template #left-card>
            <Table v-if="leftTableRows.length"
                ref="tableLeft"
                tableId="0964b72a-6a60-4cc2-adb4-f42cb877de9c"
                cardView
                :headers="headersLeftTable"
                @click-fileName="fetchPDF"
                :rows="leftTableRows"
                rowId="dataiName"
                hidePagination
            >

            <template #insuranceId="row">
                <InputField  class="full__width__input" v-model="insuranceId" ref="insuranceIdInputField" validateUntouched/>
            </template>

            <template #damageNumber="row">
                <InputField class="full__width__input" v-model="damageNumber"/>
            </template>            


            </Table>
        </template>

        <template #right-card>
 
            <ComboBox 
                v-model="comboCategory"
                label="Kategorie"
                :values="categories"
            />

            <InputField id="mscInternalId" label="Interne Nummer" v-model="mscInternalId" ref="mscInternalIdInputField" validateUntouched/>    
            

            <InputToggleSwitch
            :label="prfx + 'Versicherungsnummer übernehmen'"
            v-model="transferInsuranceNumber"
            inLineLabel
            />   
            
            <BaseButton @click="searchInsurances" isSecondary isSmall>
                Versicherungssuche
            </BaseButton>

            <BaseButton @click="buttonVersicherungsdokumentZuordnen" isPrimary :disabled="!mscInternalId"  isSmall>
                Versicherungsdokument zuordnen
            </BaseButton>

            <BaseButton @click="buttonNewCustomer(false)" isPrimary  isSmall>
                Neuer Kunde
            </BaseButton>            
      


        </template>

        <template #bipro-card>
            <InputField v-if="biproFileName" label="BiPRO Dateiname" v-model="biproFileName" disabled/>    
            <InputField v-if="biproForeignName" label="BiPRO Kundenname" v-model="biproForeignName" disabled/>
            <InputField v-if="biproForeingVorname" label="BiPRO Kundenvorname" v-model="biproForeingVorname" disabled/>
            <InputField v-if="biproForeignGeburtsname" label="BiPRO Kundengeburtsname" v-model="biproForeignGeburtsname" disabled/>
            <InputField v-if="biproForeignAnshrift" label="BiPRO Kundenanschrift" v-model="biproForeignAnshrift" disabled/>
            <InputField v-if="biproForeignGeburtsdatum" label="BiPRO Kundengeburtsdatum" v-model="biproForeignGeburtsdatum" disabled/>
            <InputField v-if="biproMSCCategory" label="BiPRO MSC Kategorie" v-model="biproMSCCategory" disabled/>
            <InputField v-if="biproVersicherungsnummer" label="BiPRO Versicherungsnummer" v-model="biproVersicherungsnummer" disabled/>

        </template>        

    </DashboardPanel>

    <div class="box__container" v-if="showGesellschaftPanel">


        <BaseButton @click="buttonNewCustomer(true)" isSecondary v-if="isBroker">
            Neuer Kunde für den Vermittler {{userId}}
        </BaseButton>

        <ComboBox
            v-model="comboGesellschaft"
            :values="gesellschaftOptions"
            label="MSC Gesellschaft"
            isComponentHalfSize
        />
        <BaseButton @click="advancedSearch" isPrimary>
            Erweiterte Suche
        </BaseButton>
    </div>

    <div v-if="!loading" class="box__container">
        <Table v-if="rows.length"
            title="SmartMSC Vorschläge"
            tableId="7020d022-7380-4209-9afd-2c0ac46e0ce2"
            :headers="insuranceHeaders"
            :rows="rows"
            :rowsPerPage="25"
            :selected="selectedRows"
             @selected="processSelection"
            @click-kundennr="openCustomerNewTab"
            @action-CONTRACT="actionContract"
            @action-NEW_CONTRACT="openCustomerNewInsurance"
             @action-TICKET_NEED_VERTRAG="sendMaklerTicket"
            @click-ICON="actionAssign"
            :headerActions="tableActions"
            @headerAction-UNITE="actionUnite" 
        >
            <template v-slot:insuranceId="row">
                <!-- doesn't exist for customer rows -->
                <div v-if="row.insuranceId" :class="{'green': row.insuranceId.green}">
                    {{row.insuranceId.text}}
                </div>
            </template>
            <template v-slot:name="row">
                <div :class="{'green': row.name.green}">
                    {{row.name.text}}
                </div>
            </template>
            <template v-slot:vorname="row">
                <div :class="{'green': row.vorname.green}">
                    {{row.vorname.text}}
                </div>
            </template>
            <template v-slot:street="row">
                <div :class="{'green': row.street.green}">
                    {{row.street.text}}
                </div>
            </template>
            <template v-slot:plz="row">
                <div :class="{'green': row.plz.green}">
                    {{row.plz.text}}
                </div>
            </template>
            <template v-slot:ort="row">
                <div :class="{'green': row.ort.green}">
                    {{row.ort.text}}
                </div>
            </template>
            <template v-slot:birthdate="row">
                <div :class="{'green': row.birthdate.green}">
                    {{row.birthdate.text}}
                </div>
            </template>
            <template v-slot:gesellnr="row">
                <div :class="{'green': row.gesellnr.green}">
                    {{row.gesellnr.text}}
                </div>
            </template>
        </Table>
        <NoData v-else
            title="SmartMSC Vorschläge"
            noIcon
        />

    </div>

    <div class="box__container" v-if="loading">
        <GhostLoading type="table" />
    </div>
    

    <UniteContracts v-if="uniteInsurance1 && uniteInsurance2" :insurance1="uniteInsurance1" :insurance2="uniteInsurance2" @close="uniteInsurance1=null; uniteInsurance2=null"/>

    <PreviewPDF ref="resizablePopup" :pdfUrl="pdfUrl" id="c5ad9400-c09d-4258-ac12-6eaa8af8ae27"></PreviewPDF>

</div>
</template>

<script>
import CORE_TYPES from '@/store/core/types'
import BIPRO_TYPES from '@/store/bipro/types'
import LOG_TYPES from '@/store/log/types';

import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import BaseModal from '@/components/core/BaseModal.vue';
import Table from "@/components/table2/Table.vue";
import BaseButton from '@/components/core/BaseButton.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import InputField from '@/components/core/forms/InputField.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import UniteContracts from '@/components/bipro/UniteContracts.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';

import {TextColumn, DateColumn, CurrencyColumn, SlotColumn, ActionColumn, SimpleAction, IconColumn, Icon, dateToSortable} from "@/components/table2/table_util.js";
import { mapGetters } from 'vuex'
import axios from 'axios';
import {PhPencilLine, PhArrowFatRight, PhFilePlus} from 'phosphor-vue';
import { buildMessage } from "@/helpers/log-message-helper";
import {GreenColumn} from "@/views/bipro/FileImportZuordnen.vue";
import DashboardPanel from '@/components/dashboard/DashboardPanel.vue';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import { required, regex, maxLength, kundennr } from '@/mixins/validator/rules';
import validator from '@/mixins/validator';
import PreviewPDF from '@/components/core/PreviewPDF.vue';
import { downloadLinkMaker } from '@/helpers/utils-helper';


const config = {
    defaultSpinner: true,
};

const ROOT_DOWNLOAD_SERVICE_PATH = '/download_service'

export default {
    mixins: [validator],
    components: {
        OptionMenu,
        PageHeaderTitleNavigation,
        BaseModal,
        Table,
        BaseButton,
        InputToggleSwitch,
        InputField,
        ComboBox,
        UniteContracts,
        GhostLoading,
        NoData,
        DashboardPanel,
        PreviewPDF,
    },
    props: {
        type: {
            type: String,
        },
        versicherungId: {
            type: String,
        },
        id: {
            type: String,
        },
        isMrMoney: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loading: false,
            customerHeaders: {
                lockedLeft: [
                    GreenColumn("name", "Kundenname"),
                    GreenColumn("vorname", "Kundenvorname"),
                    TextColumn("kundennr", "Kundennr").makeLink(),
                ],
                center: [
                    GreenColumn("street", "Straße u. Hausnr."),
                    GreenColumn("plz", "PLZ"),
                    GreenColumn("ort", "Ort"),
                    GreenColumn("birthdate", "Geburtsdatum").makeSortable((obj) => dateToSortable(obj.text)),
                    TextColumn("maklernr", "Maklernr"),
                    TextColumn("makler", "Maklername"),
                    GreenColumn("gesellnr", "Kunden GesellNr."),
                ],
                lockedRight: [
                    ActionColumn("actions"),
                ]
            },
            insurances: null,
            customers: null,

            title: null,

            fileName: null,
            foreignName: null,
            foreignVorname: null,
            foreignBirthName: null,
            foreignAddress: null,
            foreignStrasse: null,
            foreignOrt: null,
            foreignPLZ: null,
            foreignBirthDate: null,
            foreignPartnernummer: null,
            cause: null,
            category: null,
            insuranceId: null,
            gesellschaftId: null,

            transferInsuranceNumber: false,
            customerNameSearch: "",
            comboCategory: null,
            categories: [],
            mscInternalId: null,
            comboGesellschaft: null,

            uniteInsurance1: null,
            uniteInsurance2: null,
            showGesellschaftPanel: false,
            validationMetadata: null,
            isValid: true,
            licensePlateNumber: null,
            referenceNumber: null,

            biproFileName: null,
            biproForeignName: null,
            biproForeingVorname: null,
            biproForeignGeburtsname: null,
            biproForeignAnshrift: null,
            biproForeignGeburtsdatum: null,
            biproMSCCategory: null,
            biproVersicherungsnummer: null,

            damageNumber: null,
            pdfUrl: '',
            selectedRows: [],

        }
    },
    watch: {
        paramReturnVersVertrag: {
            handler(newValue) {
                this.mscInternalId = newValue;
            },
            immediate: true,
        },
    },
    computed: {
        ...mapGetters({
            gesellschaftOptions: BIPRO_TYPES.GETTERS.GESELLSCHAFT_OPTIONS,
            isInternOriginally: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
            isBroker: CORE_TYPES.GETTERS.IS_BROKER,
            userId: CORE_TYPES.GETTERS.GET_USER_ID,
            isFA: CORE_TYPES.GETTERS.IS_FA,
            paramReturnVersVertrag: BIPRO_TYPES.GETTERS.PARAM_RETURN_VERS_VERTRAG,
        }),
        tableActions() {
            return [
                PageHeaderSimpleAction('UNITE', 'Vertrag vereinigen')
                .withDisabled(() => this.numberSelected !== 2),
            ];
        },
        numberSelected() {
            return this.selectedRows.length;
        },        
        headerActions() {
            const actions = [];

            actions.push(PageHeaderSimpleAction('RELOAD-DATA', 'Aktualisieren'));
            actions.push(PageHeaderSimpleAction('TOGGLE-GESELLSCHAFT-PANEL', this.showGesellschaftPanel ? 'Gesellschaft-Panel ausblenden' : 'Gesellschaft-Panel anzeigen'));


            return actions;
        },          
        insuranceHeaders() {
            return {
                lockedLeft: [
                    IconColumn("ICON", "").makeLink(),
                    TextColumn("id", "Interne Nr"),
                    TextColumn("gesellschaft", "Gesellschaft"),
                ],
                center: [
                    GreenColumn("insuranceId", "Vers. Nr."),
                    TextColumn("description", "Prod. Bezeichnung"),
                    TextColumn("sparte", "Sparte"),
                    DateColumn("begin", "Beginn"),
                    CurrencyColumn("beitrag", "Beitrag"),
                    TextColumn("status", "Status"),
                    GreenColumn("name", "Kundenname"),
                    GreenColumn("vorname", "Kundenvorname"),
                    TextColumn("kundennr", "Kundennr").makeLink(),
                    GreenColumn("street", "Straße u. Hausnr."),
                    GreenColumn("plz", "PLZ"),
                    GreenColumn("ort", "Ort"),
                    GreenColumn("birthdate", "Geburtsdatum").makeSortable((obj) => dateToSortable(obj.text)),
                    TextColumn("maklernr", "Maklernr"),
                    TextColumn("makler", "Maklername"),
                    TextColumn("weitere", "Weitere Personen/Gegenstand"),
                    GreenColumn("gesellnr", "Kunden GesellNr."),
                    DateColumn("current", this.isFA ? "OMDS aktualisiert" : "GDV aktualisiert"),
                ],
                lockedRight: [
                    ActionColumn("actions"),
                ]
            };
        },
        insuranceRows() {
            return (this.insurances || []).map(row => {
                const actions = [
                    SimpleAction("CONTRACT", PhPencilLine, "Versicherungsvertrag öffnen"),
                ];
                return {
                    ...row,
                    ICON: Icon("PhArrowFatRight", "Interne Nr. übernehmen und Versicherungsdokument direkt zuordnen"),
                    actions,
                }
            });
        },
        customerRows() {
            return (this.customers || []).map(row => {
                const actions = [
                    SimpleAction("NEW_CONTRACT", PhFilePlus, "Neuen Vers. Vertrag anlegen"),
                    SimpleAction("TICKET_NEED_VERTRAG", PhFilePlus, "Ticket an Makler schicken"),
                ];
                return {
                    ...row,
                    actions,
                }
            });
        },
        rows() {
            return [...this.insuranceRows, ...this.customerRows];
        },
        prfx() {
            return this.type == "pdfBox" ? "PDF-Box" : "BiPRO";
        },

        dashboardData() {
            const data = {
                widgets: [

                    {
                        name: 'left-card',
                        title: this.prfx,
                    },

                    {
                        name: 'right-card',
                        title: 'MSC Daten',
                    },     
                    
                     

                ],
            };


            if (this.biproFileName) {
                data.widgets.push(
                    {
                        name: 'bipro-card',
                        title: 'Bipro Daten',
                    }, 
                );

            }


            return data;
        },        
        headersLeftTable() {
            const result = {
            center: [],
            };

            if (this.category) {
                result.center.push(TextColumn("category", "Kategory"));
            }

            if (this.fileName) {
                result.center.push(TextColumn("fileName", "Dateiname").makeLink());
            }

            if (this.cause) {
                result.center.push(TextColumn("cause", "Fehlerursache"));
            }       
            
            if (this.foreignName) {
                result.center.push(TextColumn("foreignName", "Kundenname"));
            }     
            
            if (this.foreignVorname) {
                result.center.push(TextColumn("foreignVorname", "Kundenvorname"));
            }  
            
            if (this.foreignBirthName) {
                result.center.push(TextColumn("foreignBirthName", "Kundengeburtsname"));
            }

            if (this.foreignAddress) {
                result.center.push(TextColumn("foreignAddress", "Kundenanschrift"));
            }

            if (this.foreignBirthDate) {
                result.center.push(TextColumn("foreignBirthDate", "Kundengeburtsdatum"));
            }

            if (this.foreignPartnernummer) {
                result.center.push(TextColumn("foreignPartnernummer", "Partnernummer"));
            }

            if (this.insuranceId || this.insuranceId?.length == 0) {
                result.center.push(SlotColumn("insuranceId", "Versicherungsnummer (nach MSC Formatierung)"));
            }

            if (this.damageNumber) {
                result.center.push(SlotColumn("damageNumber", "Schaden-Nr."));
            }  
            
            if (this.licensePlateNumber) {
                result.center.push(TextColumn("licensePlateNumber", "KFZ"));
            }

            if (this.referenceNumber) {
                result.center.push(TextColumn("referenceNumber", "Referenz"));
            }            


            return result;
        },
        leftTableRows() {
            const rows = [];

            const row = {};

            if (this.category) {
                row.category = this.category;
            }
            
            if (this.fileName) {
                row.fileName = this.fileName;
            }

            if (this.cause) {
                row.cause = this.cause;
            }            

            if (this.foreignName) {
                row.foreignName = this.foreignName;
            }            

            if (this.foreignVorname) {
                row.foreignVorname = this.foreignVorname;
            }        
            
            if (this.foreignBirthName) {
                row.foreignBirthName = this.foreignBirthName;
            }   

            if (this.foreignAddress) {
                row.foreignAddress = this.foreignAddress;
            }   

            if (this.foreignBirthDate) {
                row.foreignBirthDate = this.foreignBirthDate;
            }  
            
            if (this.foreignPartnernummer) {
                row.foreignPartnernummer = this.foreignPartnernummer;
            }

            if (this.licensePlateNumber) {
                row.licensePlateNumber = this.licensePlateNumber;
            }  

            if (this.referenceNumber) {
                row.referenceNumber = this.referenceNumber;
            }

            rows.push(row);
            return rows;
        },        
        fieldsValidators() {

            let validators = {
                mscInternalId : [required('Es muss ein Text eingegeben werden')],
            };

            if (this.validationMetadata?.insuranceId?.regex !== undefined) {
                const insuranceRegex = "^(?:" + this.validationMetadata.insuranceId.regex + ")$";
                const insuranceExample =  this.validationMetadata.insuranceId.beispielKontonrEffektiv;
                
                validators.insuranceId = [
                    regex(new RegExp(insuranceRegex), `Die Pol.Nr. muss im Format ${insuranceExample} eingegeben werden.`, {emptyAllowed: true})
                ];
            }

            return validators;
        },
    },
    async mounted() {
        if (this.versicherungId)
            this.mscInternalId = this.versicherungId || null;
        
        if (!this.gesellschaftOptions)
            this.$store.dispatch(BIPRO_TYPES.ACTIONS.LOAD_GESELLSCHAFT_OPTIONS);
        
        await this.loadData(null, false);

        if (this.isMrMoney) {
            this.showGesellschaftPanel = true;
        }
    },
    methods: {
        async reloadData() {
            await this.loadData(null, false);
        },
        processSelection(selection) {
            this.selectedRows = selection;
            console.log(this.selectedRows.map(sr => {return {id: sr.id}}));
        },
        fetchPDF() {
            const {fileName} = this;

            if (this.isMrMoney) {
                const queryParams = {
                    mrMoneyId: this.id,
                };

                const downloadServicePath="/mrmoneypdf";

                const url = downloadLinkMaker(this.$store.getters, `${ROOT_DOWNLOAD_SERVICE_PATH}${downloadServicePath}`, fileName, queryParams);
                this.pdfUrl = url;
            } else {
                const { automatischZugeordnet, } = this;
    
                const queryParams = {
                        id: this.id,
                    };
    
                let downloadServicePath="/bipro_dokument_nicht_zugeordnet";
    
                if (automatischZugeordnet) {
                    downloadServicePath =  "/bipro_dokument_zugeordnet";
                }
    
                const url = downloadLinkMaker(this.$store.getters, `${ROOT_DOWNLOAD_SERVICE_PATH}${downloadServicePath}`, fileName, queryParams);
                this.pdfUrl = url;
            }
            
            this.$refs.resizablePopup.showPopup();

        },    
        toggleGesellschaftPanel() {
            this.showGesellschaftPanel = !this.showGesellschaftPanel;
        },
        async loadData(name, advancedSearch) {
            this.loading = true;
            let nameParam = "";
            if (name !== null)
                nameParam = `&name=${name}`;
            let advancedSearchParam = "";
            if (advancedSearch)
                advancedSearchParam = `&advancedSearch=true`;

            let serviceUrl = process.env.VUE_APP_API;

            // switch (this.$route.query.prfx) {
            switch (this.type) {
                case "pdfBox":
                    serviceUrl = `${serviceUrl}/mrMoney/zuweisen`
                    break;
            
                default:
                    serviceUrl = `${serviceUrl}/bipro_documents/zuweisen`
                    break;
            }
            axios.get(`${serviceUrl}?id=${this.id}` + nameParam + advancedSearchParam + `&changedPolNr=${this.insuranceId}`)
            .then(response => {

                this.validationMetadata = response?.data?.validationMetadata;

                this.$configureValidators(this.fieldsValidators);
                
                this.title = `${response.data.title}, Id: ${this.id}`;

                // table rows
                this.insurances = response.data.insurances;
                this.customers = response.data.customers;

                // information displayed above the tables
                this.fileName = response.data.fileName;
                this.foreignName = response.data.foreignName;
                this.foreignVorname = response.data.foreignVorname;
                this.foreignBirthName = response.data.foreignBirthName;
                this.foreignAddress = response.data.foreignAddress;
                this.foreignPLZ = response.data.foreignPLZ;
                this.foreignOrt = response.data.foreignOrt;
                this.foreignStrasse = response.data.foreignStrasse;
                this.foreignBirthDate = response.data.foreignBirthDate;
                this.foreignPartnernummer = response.data.foreignPartnernummer;
                this.cause = response.data.cause;
                this.category = response.data.category;
                this.insuranceId = this.insuranceId || response.data.insuranceId;
                this.gesellschaftId = response.data.gesellschaftId;
                this.damageNumber = response?.data?.damageNumber;
                this.licensePlateNumber = response?.data?.licensePlateNumber;
                this.referenceNumber = response?.data?.referenceNumber;

                this.comboCategory = response.data.comboCategory;
                this.categories = response.data.categories;
                this.categories.map(option => {
                    if (option.label.startsWith("[c=blue]")) {
                        option.label = option.label.substring(8, option.label.length - 4);
                        option.style = "color: blue";
                    }
                });

                //FixMe: for some reason, the validation in the "Interne Nummer" only happens when the focus is focused
                this.$nextTick(() => {
                    this.$refs.mscInternalIdInputField && this.$refs.mscInternalIdInputField.focus();
                    this.$refs.insuranceIdInputField && this.$refs.insuranceIdInputField.focus();
                    
                })

                if (response?.data?.biproFileName) {
                    this.biproFileName = response?.data?.biproFileName;
                    this.biproForeignName = response?.data?.biproForeignName;
                    this.biproForeingVorname = response?.data?.biproForeingVorname;
                    this.biproForeignGeburtsname = response?.data?.biproForeignGeburtsname;
                    this.biproForeignAnshrift = response?.data?.biproForeignAnshrift;
                    this.biproForeignGeburtsdatum = response?.data?.biproForeignGeburtsdatum;
                    this.biproMSCCategory = response?.data?.biproMSCCategory;
                    this.biproVersicherungsnummer = response?.data?.biproVersicherungsnummer;
                }

                this.fetchPDF();


                this.loading = false;

            });
        },
        actionContract(row) {
            this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, {
                customerId: row.kundennr,
                insurances: true,
                path: `/home/versicherungen/insurance-group/details/${row.id}`
            });
        },
        actionUnite() {
            this.uniteInsurance1 = this.selectedRows[0]?.id;
            this.uniteInsurance2 = this.selectedRows[1]?.id;
        },
        async actionAssign(row) {
            // const isMrMoney = !!this.$route?.query?.isMrMoney;

            let params = {
                mscInternalId: row.id,
                gesellName: row.gesellschaft,
                transferInsuranceNumber: this.transferInsuranceNumber,
                insuranceId: this.insuranceId,
                mscCategory: this.comboCategory,
            }

            if (this.isMrMoney) {
                // params.mrMoneyId = this.$route?.query?.id;
                params.mrMoneyId = this.id;
            } else {
                // params.docId = this.$route?.query?.id;
                params.docId = this.id;
            }

            const url = this.isMrMoney ? "/mrMoney/accept_insurance_proposal" : "/bipro_documents/accept_insurance_proposal";

            let response = await axios.post(`${process.env.VUE_APP_API}${url}`, params, config);

            if (response.data.error) {
                this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.error, 'danger'));
            } else {
                this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.message, 'success', true, 5000));

                if (this.isMrMoney) {
                    this.$router.push({path: "/intern/mrmoney/list"});
                } else {
                    this.$router.push({path: "/intern/bipro/documents"});
                }
                
            }
        },
        async searchInsurances() {
            
            // let allPossibleGesellschaftIds = await axios.get(`${process.env.VUE_APP_API}/bipro_documents/selected_gesellschaften?id=${this.$route.query.id}`);
            let allPossibleGesellschaftIds = await axios.get(`${process.env.VUE_APP_API}/bipro_documents/selected_gesellschaften?id=${this.id}`);

            const defaultOptions = {
                lastName: {
                    value: this.foreignName,
                },
                firstName: {
                    value: this.foreignVorname,
                },
            };

            if (allPossibleGesellschaftIds?.data?.length) {
                defaultOptions["vertragGesellschaft"] = allPossibleGesellschaftIds.data[0];

            }

            if (this.insuranceId != null) {
                defaultOptions.versNummer = {
                    value: this.insuranceId,
                };
            }
            if (this.isInternOriginally && this.isBroker) {
                defaultOptions.maklernr = {
                    value: this.userId,
                };
            }

            this.$router.push({
                path: "/home/versicherungsfilter/insurance-group",
                query: {
                    // default options for the filter
                    defaultOptions,
                    mitZuordnenAktion: true,
                }
            });
        },
        buttonVersicherungsdokumentZuordnen() {
            const params = {
                // docId: this.$route.query.id,
                docId: this.id,
                mscInternalId: this.mscInternalId,
                insuranceId: this.insuranceId,
                setPoliceNr: this.transferInsuranceNumber,
                // mrMoney: this.$route.query.isMrMoney,
                mrMoney: this.isMrMoney,
            };

            if (this.isMrMoney) {
                axios.post(`${process.env.VUE_APP_API}/mrMoney/assign_insurance`, params, config)
                .then(response => {
                    if (response?.data)
                        this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data, 'success'));
                    this.$router.push({path: "/intern/mrmoney/list"});
                }).catch(error => {
                    let msg = error?.data || 'Es ist ein unerwarteter Fehler aufgetreten.';
                    if(error?.response?.status == 400 || error?.response?.status == 403 ){
                        msg = error?.response?.data
                    }                
                    this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(msg, 'danger'));
                })
            } else {
                axios.post(`${process.env.VUE_APP_API}/bipro_documents/assign_insurance`, params, config)
                .then(response => {
                    if (response?.data)
                        this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data, 'success'));
                    this.$router.push({path: "/intern/bipro/documents"});
                }).catch(error => {
                    let msg = error?.data || 'Es ist ein unerwarteter Fehler aufgetreten.';
                    if(error?.response?.status == 400 || error?.response?.status == 403 ){
                        msg = error?.response?.data
                    }                
                    this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(msg, 'danger'));
                })

            }
        },
        buttonNewCustomer(sameBroker) {
            this.$addBreadcrumb({
              label: `zurück`,
              fullPath: this.$route.fullPath,
              breadcrumb: 'Datensatz zuordnen',
            });
            let query = '';
            if (this.foreignName) {
                query += '&lastName=' + this.foreignName;
            }
            if (this.foreignVorname) {
                query += '&firstName=' + this.foreignVorname;
            }
            if (this.foreignBirthDate) {
                query += '&dayOfBirth=' + this.foreignBirthDate;
            }
            if (this.foreignPLZ) {
                query += '&zip=' + this.foreignPLZ;
            }
            if (this.foreignOrt) {
                query += '&city=' + this.foreignOrt;
            }
            if (this.foreignStrasse) {
                query += '&street=' + this.foreignStrasse;
            }
            if (query) {
                query = '?' + query.substring(1);
            }
            if (sameBroker) {
                this.$router.push({
                    path: "/customer/kunde-anlegen" + query
                });
                // in neuer tab
                // this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_BROKER_NEW_TAB, {
                //     brokerId: this.userId,
                //     path: `customer/kunde-anlegen${encodeURIComponent(query)}`
                // });
            } else {
                this.$router.push({
                    path: "/customer/maklersuche",
                    query: {
                        bipro: true,
                        nextUrl: `customer/kunde-anlegen${query}`
                    }
                });
            }
        },
        advancedSearch() {
            this.loadData(this.comboGesellschaft, true);
        },
        openCustomerNewTab(row) {
            this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, { customerId: row.kundennr })
        },
        openCustomerNewInsurance(row) {
            let path = '/home/versicherungen/auswahl-vertragsart';
            if (this.gesellschaftId || this.insuranceId) {
                let query = '';
                if (this.gesellschaftId) {
                    query='?gesellid=' + this.gesellschaftId;
                }
                if (this.insuranceId) {
                    query += (query ? '&' : '?') + 'vesichnr=' + this.insuranceId;
                }
                path += encodeURIComponent(query);
            }
            this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, { customerId: row.kundennr, path })
        },
       sendMaklerTicket(row){
           console.log(`maklernr: ${row.maklernr} kundennr: ${row.kundennr} id: ${this.id} user: ${this.userId} type: ${this.type}`);

           const url = this.isMrMoney ? "/mrMoney/send_ticket_new_vertrag" : "/bipro_documents/send_ticket_new_vertrag";
           const nextUrl = this.isMrMoney ? "/intern/mrMoney/list" : "/intern/bipro/documents";
           let params = {
                //maklernr: row.maklernr,
                kundennr: row.kundennr,
                id: this.id
                //user: this.userId
            }

          axios.post(`${process.env.VUE_APP_API}${url}`, params, config)
          .then(response => {
                    if (response?.data)
                        this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data, 'success'));
                    this.$router.push({path: nextUrl});
                })  
          .catch(error => {
                    let msg = error?.data || 'Es ist ein unerwarteter Fehler aufgetreten.';
                    if(error?.response?.status == 400 || error?.response?.status == 403 ){
                        msg = error?.response?.data
                    }                
                    this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(msg, 'danger'));
            });
        },         
    },
    validators: {},
}
</script>


<style scoped>

.flexible__layout__row {
    display: flex;
}

.full__width__input {
    flex: 1 1 auto;
    text-align: right;
    max-width: 366px;
    margin-left: auto;
}
.green {
    background-color: var(--color-success)
}

</style>
