var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseToolbarButton", {
    attrs: {
      title: "Ordered list",
      icon: _vm.icon,
      active: _vm.active,
      disabled: _vm.disabled
    },
    on: { click: _vm.action }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }