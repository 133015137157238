var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { actions: _vm.pageHeaderActions, title: _vm.dynamicTitle },
        on: { "action-SHOW_BROKER_BLACKLIST": _vm.toggleBrokerList }
      }),
      _c("BaseFilter", {
        attrs: {
          title: "BiPRO Dokumente",
          filterId: "BiPRO Dokumente",
          configFilter: _vm.filterConfig,
          metadata: _vm.metadata,
          defaultOptions: _vm.defaultOptions,
          showSaveButton: "",
          immidiateSearch: true
        },
        on: { onFilter: _vm.onFilter, onFilterZurucksetzen: _vm.onResetFilter }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.showbrokerBlacklist
            ? _c("InputField", {
                attrs: {
                  label:
                    "Makler Sperrliste (Komma separierte Strukturleiter Maklernummern Liste. Die entsprechenden Makler Strukturen werden von der Vorschlagssuche ausgeschlossen)",
                  type: "text"
                },
                on: { change: _vm.updateBrokerBlacklist },
                model: {
                  value: _vm.brokerBlacklist,
                  callback: function($$v) {
                    _vm.brokerBlacklist = $$v
                  },
                  expression: "brokerBlacklist"
                }
              })
            : _vm._e(),
          _c("div", { staticStyle: { clear: "both" } }),
          _vm.loading || !_vm.rows.length
            ? _c("div", [
                _c("span", { staticClass: "box__title" }, [
                  _vm._v(" BiPRO Dokumente ")
                ]),
                _c("br")
              ])
            : _vm._e(),
          _vm.loading
            ? _c("GhostLoading", { attrs: { type: "table" } })
            : _vm.rows.length
            ? _c("big-table", {
                attrs: {
                  selectable: "",
                  headers: _vm.tableHeadersForBackend,
                  headerActions: _vm.headerActions,
                  title: "BiPRO Dokumente",
                  tableId: _vm.tableId,
                  rows: _vm.rows,
                  initialPageSize: _vm.minNumRows,
                  page: _vm.page,
                  sortingState: _vm.sortingState
                },
                on: {
                  onClickHeader: _vm.onClickHeader,
                  selectedRow: _vm.onRowSelection,
                  selectedRows: _vm.onAllRowsSelection,
                  "click-icon-iconColumn": _vm.iconColumnClicked,
                  action: _vm.executeAction,
                  "headerAction-MARKIERTE_POSITIONEN_LOESCHEN": function(
                    $event
                  ) {
                    return _vm.deleteRows(_vm.selected)
                  },
                  "headerAction-REASSIGN_UNASSIGNED_ROWS": function($event) {
                    return _vm.reassignUnassignedRows(_vm.selected)
                  },
                  "click-icon-SHOW_AND_ASSIGN": _vm.actionAssign
                },
                scopedSlots: _vm._u([
                  {
                    key: "fileName",
                    fn: function(row) {
                      return [
                        _c(
                          "DownloadLink",
                          {
                            attrs: {
                              downloadServicePath: _vm.automatischZugeordnet
                                ? "/bipro_dokument_zugeordnet"
                                : "/bipro_dokument_nicht_zugeordnet",
                              filename: row.fileName,
                              queryParams: {
                                id: row.id
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(row.fileName) + " ")]
                        )
                      ]
                    }
                  },
                  {
                    key: "comment",
                    fn: function(row) {
                      return [
                        _c("InputField", {
                          attrs: { value: row.comment },
                          on: {
                            change: function($event) {
                              return _vm.saveComment(row, $event)
                            }
                          }
                        })
                      ]
                    }
                  }
                ])
              })
            : _vm._e(),
          !_vm.rows.length ? _c("NoData") : _vm._e()
        ],
        1
      ),
      _c("PreviewPDF", {
        ref: "resizablePopup",
        attrs: {
          pdfUrl: _vm.pdfUrl,
          id: "a973ea87-9ce7-4201-a113-5f4c2ca5ddbf"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }