import axios from 'axios';

import FC_CONFIG_TYPES from './types';
import CORE_TYPES from '@/store/core/types';
import LOG_TYPES from '@/store/log/types';
import { VIEW_ROLES } from '@/router/roles';

const SERVICE_PATH = '/fcconfig';
const EMPTY_USER_LEVEL = '__empty__'
const MAX_CONFIGS_PER_REQUEST = 100;

const axiosConfig = {
  defaultSpinner: true
};

function configToList(config) {
  let configList = config
  if (config?.configId && config?.configType) {
    configList = [config]
  }
  return configList
}

function groupConfigByType(configList) {
  return configList.reduce((acc, cur) => {
    acc[cur.configType] ??= []
    acc[cur.configType].push(cur)
    return acc
  }, {})
}

function cleanCurrentConfig(configList, commit) {
  const resultPayload = configList.map(config => ({
    configId: config.configId, 
    configType: config.configType, 
    config: {}})
  )
  commit(FC_CONFIG_TYPES.MUTATIONS.LOAD_CONFIG_SUCCESS, resultPayload)
}

function groupConfigByUserLevel(configList) {
  return configList.reduce((acc, cur) => {
    const userLevel  = cur.userLevel ?? EMPTY_USER_LEVEL 
    acc[userLevel] ??= []
    acc[userLevel].push(cur)
    return acc
  }, {})
}

function setConfigLoading(commit, configList, value) {
  if(value) {
    configList.forEach(config => commit(FC_CONFIG_TYPES.MUTATIONS.ADD_FC_CONFIG_LOADING, config));
  } else {
    configList.forEach(config => commit(FC_CONFIG_TYPES.MUTATIONS.REMOVE_FC_CONFIG_LOADING, config))
  }
}

function chunkArray(arr, size) {
  if(!arr?.length) {
    return arr;
  }

  return arr.reduce((acc, item) => {
    if(!acc[acc.length - 1] || acc[acc.length - 1].length >= size) {
      acc.push([]);
    }

    acc[acc.length - 1].push(item);

    return acc;
}, [])
}

export default {
  async [FC_CONFIG_TYPES.ACTIONS.SAVE_ONLY]({ getters, dispatch }, payload) {
    const hasRoles = getters[CORE_TYPES.GETTERS.HAS_ROLES];

    if (!hasRoles([VIEW_ROLES.VIEW_BROKER, VIEW_ROLES.VIEW_CUSTOMER, VIEW_ROLES.VIEW_INTERN])) {
      return;
    }
    
    const configList = configToList(payload)

    if (!Array.isArray(configList) || configList.filter(config => !config?.configId || !config?.configType).length) {
      dispatch(LOG_TYPES.ACTIONS.WARN, 'A list with configs is required to save. Every item need to have "configId" and "configType"')
      return
    }

    return await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/save_config`, configList, axiosConfig);
  },
  async [FC_CONFIG_TYPES.ACTIONS.SAVE_FC_CONFIG]({ dispatch, commit, }, payload) {
    try {
      const configList = configToList(payload)

      const result = await dispatch(FC_CONFIG_TYPES.ACTIONS.SAVE_ONLY, configList);
      if (Array.isArray(result?.data)) {

        const resultConfigList = result.data.map(config => ({
          configId: config.configId, 
          configType: config.configType, 
          config})
        )

        commit(FC_CONFIG_TYPES.MUTATIONS.LOAD_CONFIG_SUCCESS, resultConfigList);
      }
    } catch (error) {
      // empty block
    }
  },
  async [FC_CONFIG_TYPES.ACTIONS.LOAD_FC_CONFIG]({ commit, getters }, payload) {
    const hasRoles = getters[CORE_TYPES.GETTERS.HAS_ROLES];

    if (!hasRoles([VIEW_ROLES.VIEW_BROKER, VIEW_ROLES.VIEW_CUSTOMER, VIEW_ROLES.VIEW_INTERN])) {
      return;
    }

    const fcConfig = getters[FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG]
    const allConfigList = configToList(payload)
    const configList = allConfigList.filter(config => !(config.configId in (fcConfig?.[config.configType] || {})) || config?.forceReload)

    setConfigLoading(commit, configList, true);
    cleanCurrentConfig(configList, commit)

    const configsByType = groupConfigByType(configList)
    const configPromises = []

    for (const [configType, listOfConfigs] of Object.entries(configsByType)) {
      configPromises.push(...chunkArray(listOfConfigs, MAX_CONFIGS_PER_REQUEST).map(configs => { // added to avoid 413 (Request Entity Too Large) exception
        let params = `configType=${configType}`
        params += configs.reduce((acc, cur) => (acc + `&configId=${encodeURI(cur.configId)}`), '');
        return axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/get_config?${params}`, axiosConfig);
      }));
    }

    try {
      const results = await Promise.all(configPromises)

      for (const result of results) {
        const resultConfigList = result?.data?.filter(config => config?.configId && config?.configType)
          .map(config => ({
            configId: config.configId, 
            configType: config.configType, 
            config})
          )
        commit(FC_CONFIG_TYPES.MUTATIONS.LOAD_CONFIG_SUCCESS, resultConfigList)
      }
    } catch (error) {
      //empty block
    } finally {
      setConfigLoading(commit, configList, false);
    }
  },
  async [FC_CONFIG_TYPES.ACTIONS.LOAD_FC_CONFIG_BY_USER_LEVEL]({ commit, getters, dispatch }, payload) {
    const hasRoles = getters[CORE_TYPES.GETTERS.HAS_ROLES];

    if (!hasRoles([VIEW_ROLES.VIEW_BROKER, VIEW_ROLES.VIEW_CUSTOMER, VIEW_ROLES.VIEW_INTERN])) {
      return;
    }

    const allConfigList = configToList(payload)

    if (!Array.isArray(allConfigList) || allConfigList.filter(config => !config?.configId || !config?.configType || !config?.userLevel).length) {
      dispatch(LOG_TYPES.ACTIONS.WARN, 'Every item need to have "configId", "configType" and "userLevel"')
      return
    }

    const isConfigByUserLevelLoaded = getters[FC_CONFIG_TYPES.GETTERS.IS_CONFIG_BY_USER_LEVEL_LOADED];
    const configList = allConfigList.filter(config => !isConfigByUserLevelLoaded(config) || config?.forceReload)

    const resultConfigList = configList.map(config => ({
      configId: config.configId,
      configType: config.configType,
      userLevel: config.userLevel,
      config: {},
    })) 
    commit(FC_CONFIG_TYPES.MUTATIONS.LOAD_CONFIG_BY_USER_LEVEL_SUCCESS, resultConfigList)
      
    try {
      const configByUserLevel = groupConfigByUserLevel(configList)
      const configPromises = []

      for (const [userLevel, byEndpointList] of Object.entries(configByUserLevel)) {
        const configsByType = groupConfigByType(byEndpointList)
        
        for (const [configType, listOfConfigs] of Object.entries(configsByType)) {
          let endpointAndParams = `userLevel=${userLevel}&configType=${configType}`
          endpointAndParams += listOfConfigs.reduce((acc, cur) => (acc + `&configId=${cur.configId}`), '')
          configPromises.push(axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/get_config_by_user_level?${endpointAndParams}`, axiosConfig))
        }
      }

      const results = await Promise.all(configPromises)

      for (const result of results) {
        const resultConfigList = result?.data?.filter(config => config?.configId && config?.configType)
          .map(config => {
            const configFound = allConfigList.find(allConfig => allConfig.configId === config.configId && allConfig.configType === config.configType)

            return {
              configId: config.configId, 
              configType: config.configType, 
              userLevel: configFound?.userLevel,
              config
            }
          })
        commit(FC_CONFIG_TYPES.MUTATIONS.LOAD_CONFIG_BY_USER_LEVEL_SUCCESS, resultConfigList)
      }
    } catch (error) {
      //empty block
    }
  },
  async [FC_CONFIG_TYPES.ACTIONS.DELETE_ONLY]({ getters }, payload) {
    const hasRoles = getters[CORE_TYPES.GETTERS.HAS_ROLES];

    if (!hasRoles([VIEW_ROLES.VIEW_BROKER, VIEW_ROLES.VIEW_CUSTOMER, VIEW_ROLES.VIEW_INTERN])) {
      return;
    }

    const configList = configToList(payload)

    const configByUserLevel = groupConfigByUserLevel(configList)

    try {
      const configPromises = []

      for (const [userLevel, byEndpointList] of Object.entries(configByUserLevel)) {
        const configsByType = groupConfigByType(byEndpointList)
        
        for (const [configType, listOfConfigs] of Object.entries(configsByType)) {
          let endpointAndParams = userLevel !== EMPTY_USER_LEVEL ? `delete_config_by_user_level?userLevel=${userLevel}&` : 'delete_config?'
          endpointAndParams += `configType=${configType}`
          endpointAndParams += listOfConfigs.reduce((acc, cur) => (acc + `&configId=${cur.configId}&`), '')
          configPromises.push(axios.delete(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/${endpointAndParams}`, axiosConfig))
        }
      }
      
      return await Promise.all(configPromises)
    } catch (error) {
      // empty block
    }
  },
  async [FC_CONFIG_TYPES.ACTIONS.DELETE_FC_CONFIG]({ dispatch }, payload) {
    try {
      const configList = configToList(payload)
      const result = await dispatch(FC_CONFIG_TYPES.ACTIONS.DELETE_ONLY, configList);
      if (result) {
        const resultPayload = configList.map(config => ({
          configId: config.configId,
          configType: config.configType,
          forceReload: true,
        }))
        await dispatch(FC_CONFIG_TYPES.ACTIONS.LOAD_FC_CONFIG, resultPayload)
      }
    } catch (error) {
      // empty block
    }
  },

  async [FC_CONFIG_TYPES.ACTIONS.SAVE_AS_INTERN]({ getters, }, payload) {
    if(!payload?.fcConfigs?.length) return;

    return await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/save_config_as_intern`, payload, axiosConfig);
  },
  async [FC_CONFIG_TYPES.ACTIONS.DELETE_AS_INTERN]({ getters, }, { configId, configType, configContext, userLevel, kundennr, maklernr, intern, }) {
    if(!configId || !configType || !configContext || !userLevel) return;

    const params = [];
    params.push(`configId=${configId}`);
    params.push(`configType=${configType}`);
    params.push(`configContext=${configContext}`);
    params.push(`userLevel=${userLevel}`);
    if(kundennr) params.push(`kundennr=${kundennr}`);
    if(maklernr) params.push(`maklernr=${maklernr}`);
    if(intern) params.push(`intern=${intern}`);

    await axios.delete(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/delete_config_as_intern?${params.join('&')}`, axiosConfig);
  },
}
