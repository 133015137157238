<template>
     <div class="box__container">
         <div class="box__title">Wirtschaftsprüfung 2023 – Klagen, Beschwerden</div>
         <FormDivider />
          <FormLabel
        label="Anforderung: Bitte teilen Sie uns Ihre Klagen und Beschwerden im WAG 2018 Bereich bis zum 15.02.2024 mit. Sollten diese Punkte auf Sie zutreffen, 
        legen Sie bitte entsprechende Unterlagen/Sachverhaltsdarstellungen bei, auch bei bereits erfolgter Dokumentation."
      />
       <InputRadioBoxGroup 
      :value="form.INPUT_WIRTSCHAFTSPRUEFUNG_BEST1"
      :values="wirtschaftspruefungValues"
      labelClass="font-bold"
      :disabled="disabledStatusOK"
      @input="fieldChanged($event, 'INPUT_WIRTSCHAFTSPRUEFUNG_BEST1')"
      /> 
      <ChecklisteDocsUpload :paramId="this.form.PARAM_ID" :topic="'Beschwerden'" :form="this.form"/>
    <FormDivider />
       
   <InputToggleSwitch
      label="Ich bestätige hiermit, die Angaben wahrheitsgetreu getätigt zu haben."
      :inLineLabel="true"
      :config="{ bold: true }"
      isComponentHalfSize
      :disabled="disabledStatusOK"
      :value="true"
    />
     </div>
</template>
<script>
import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue";
import FormLabel from "@/components/core/forms/FormLabel.vue";
import FormDivider from "@/components/core/forms/FormDivider.vue";
import ChecklisteDocsUpload from './ChecklisteDocsUpload.vue';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';

export default {
  components: {
  InputToggleSwitch,
  FormLabel,
  FormDivider,
  ChecklisteDocsUpload,
  InputRadioBoxGroup
  },
  props: {
    form: {},
  },
  computed:{
     disabledStatusOK(){
     return  this.form.INPUT_STATUS_OK == '1' || this.form.INPUT_STATUS_OK == 'true'
   }
  },
  data() {
    return {
      wirtschaftspruefungValues:[
        {label:"Es gab/gibt keine Klagen oder Beschwerden im WAG 2018 Bereich im Jahr 2023; sollten unterjährig Klagen/Beschwerden im WAG 2018 Bereich auftreten verpflichte ich mich umgehend FinanzAdmin davon in Kenntnis zu setzen.",value:true},
        {label:"Es gab/gibt Klagen oder Beschwerden im WAG 2018 Bereich im Jahr 2023 und die entsprechenden Sachverhalte/Unterlagen sind beigelegt.",value:false}
        ]
    };
  },
  methods:{
    fieldChanged(newValue, propName){
      const payload = { name: propName, value: newValue}
      this.$emit('fieldChanged',payload)
    }
  }
};
</script>