<template>
  <div v-if="vertragsdatenFieldsDefinition">

    <div style="display:flex; flex-direction:column">
      <div class="row" v-if="vertragsdatenFieldsDefinition.pramieBruttoJahr.visible" :style="styleOrder('pramieBruttoJahr')">
        <div class="col-12 col-sm-6">
          <InputField
            type="currency"
            :precision="2"
            v-model="pramieBruttoJahr"
            label="Prämie Brutto (Jahr)"
            :disabled="vertragsdatenFieldsDefinition.pramieBruttoJahr.disabled"
          />
        </div>
      </div>
      <div class="row" v-if="vertragsdatenFieldsDefinition.vertragssumme.visible" :style="styleOrder('vertragssumme')">
        <div class="col-12 col-sm-6">
          <InputField
            type="currency"
            :precision="2"
            v-model="versicherung.vertragssumme"
            label="Vertragssumme"
            :disabled="vertragsdatenFieldsDefinition.vertragssumme.disabled"
            @input="dataChanged()"
          />
        </div>
      </div>
      
      <div class="row" v-if="vertragsdatenFieldsDefinition.pramieNetto.visible" :style="styleOrder('pramieNetto')">
        <div class="col-12 col-sm-6">
          <InputField
            type="currency"
            :precision="2"
            v-model="versicherung.praemiento_zahlweise"
            label="Prämie Netto"
            :disabled="vertragsdatenFieldsDefinition.pramieNetto.disabled"
            @input="dataChanged()"
          />
        </div>
      </div>
  
      <div class="row" v-if="vertragsdatenFieldsDefinition.pramieNettoJahr.visible" :style="styleOrder('pramieNettoJahr')">
        <div class="col-12 col-sm-6">
          <InputField
            type="currency"
            :precision="2"
            v-model="pramieNettoJahr"
            label="Prämie Netto (Jahr)"
            :disabled="vertragsdatenFieldsDefinition.pramieNettoJahr.disabled"
          />
        </div>
      </div>
      
      <div class="row" v-if="vertragsdatenFieldsDefinition.zahlungsart.visible" :style="styleOrder('zahlungsart')">
        <div class="col-12 col-sm-6">
          <ComboBox
            label="Zahlungsart"
            v-model="versicherung.zahlungsart"
            :firstEmpty="true"
            :values="vertragsdatenFieldsDefinition.zahlungsart.value"
            :disabled="vertragsdatenFieldsDefinition.zahlungsart.disabled"
            @change="dataChanged('zahlungsart', $event)"
          />
        </div>
      </div>
      <div class="row" :style="styleOrder('bankverbindung')">
        <div class="col-12 col-sm-6">
          <EntitySelector
            label="Bankverbindung"
            v-model="versicherung.bankverbindung"
            :values="vertragsdatenFieldsDefinition.bankverbindung.value"
            @change="dataChanged('bankverbindung', $event)"
            @new="newBankverbindung()"
            @edit="editBankverbindung()"
          />
        </div>
      </div>
  
      <div class="row" v-if="vertragsdatenFieldsDefinition.letzteAnderung.visible" :style="styleOrder('letzteAnderung')">
        <div class="col-12 col-sm-6">
          <InputField
            v-model="versicherung.letzteAnderung"
            label="Letzte Änderung"
            :disabled="vertragsdatenFieldsDefinition.letzteAnderung.disabled"
            @input="dataChanged()"
          />
        </div>
      </div>
      <div class="row" v-if="vertragsdatenFieldsDefinition.letzteGDVAnderung.visible" :style="styleOrder('letzteGDVAnderung')">
        <div class="col-12 col-sm-6">
          <InputField
            v-model="versicherung.dateUpdatetdGDV"
            :label="`Letzte ${isFA ? 'OMDS' : 'GDV'} Änderung`"
            :disabled="vertragsdatenFieldsDefinition.letzteGDVAnderung.disabled"
            @input="dataChanged()"
          />
        </div>
      </div>
      <div class="row" v-if="vertragsdatenFieldsDefinition.autoUpdate.visible" :style="styleOrder('autoUpdate')">
        <div class="col-12 col-sm-6">
          <InputToggleSwitch
            :label="vertragsdatenFieldsDefinition.autoUpdate.value"
            v-model="versicherung.einlesen_updaten"
            :disabled="vertragsdatenFieldsDefinition.autoUpdate.disabled"
            @input="dataChanged()"
          />
        </div>
      </div>
      <div class="row" v-if="vertragsdatenFieldsDefinition.externeKennung.visible" :style="styleOrder('externeKennung')">
        <div class="col-12 col-sm-6">
          <InputField
            v-model="versicherung.externeKennung"
            label="Ext. Kennung"
            :disabled="vertragsdatenFieldsDefinition.externeKennung.disabled"
            @input="dataChanged()"
          />
        </div>
      </div>
      <div class="row" v-if="vertragsdatenFieldsDefinition.erfassungsdatum.visible" :style="styleOrder('erfassungsdatum')">
        <div class="col-12 col-sm-6">
          <DatePickerField
            v-model="versicherung.erfassungsdatum"
            label="Erfassungsdatum"
            :disabled="!isNewVertrag || vertragsdatenFieldsDefinition.erfassungsdatum.disabled"
            @input="dataChanged()"
          />
        </div>
      </div>
    </div>
    

  </div>
</template>


<script>
import CUSTOMERDATA_TYPES from '@/store/customerData/types';
import BoxContainer from "@/components/core/BoxContainer.vue";
import InputCheckBoxItem from "@/components/core/forms/checkbox/InputCheckBoxItem.vue";
import InputRadioBoxItem from "@/components/core/forms/radiobox/InputRadioBoxItem.vue";
import InputRadioBoxGroup from "@/components/core/forms/radiobox/InputRadioBoxGroup.vue";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import InputField from "@/components/core/forms/InputField.vue";
import DatePickerField from "@/components/core/forms/date-picker2/DatePickerField.vue";
import { PhNotePencil, PhCopy, PhInfo, PhNoteBlank } from "phosphor-vue";
import EntitySelector from '@/components/core/forms/EntitySelector.vue';
import InsuranceDetailMixin from './InsuranceDetail-mixin.js';
import CORE_TYPES from '@/store/core/types';
import { mapGetters } from 'vuex';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue'
import validator from '@/mixins/validator';
import VERSICHERUNG_TYPES from "@/store/versicherungen/types";

export default {
  mixins: [InsuranceDetailMixin, validator],
  components: {
    BoxContainer,
    InputCheckBoxItem,
    InputRadioBoxItem,
    InputRadioBoxGroup,
    ComboBox,
    InputField,
    DatePickerField,
    PhNotePencil,
    PhCopy,
    PhInfo,
    PhNoteBlank,
    EntitySelector,
    InputToggleSwitch,
  },
  props: {
    details: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
      isFA: CORE_TYPES.GETTERS.IS_FA, 
    }),
    tableId() {
            const uniqueUUID = '87a21185-5360-4f71-b7f8-583d23be53df';
            const vertragsparte = this.vertragsdatenFieldsDefinition?.vertragssparte?.value?.find(vd => vd.value == this.versicherungenDetails?.insurance?.description?.id)
            return `${uniqueUUID}-${vertragsparte?.label}`;
    },    
   
  },
  methods: {
    newBankverbindung() {
      const personId = 'person1';
      this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.UPDATE_SELECTED_CUSTOMER_DATA, personId);
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GOTO_BANKVERBINDUNG, this.backButtonPayload);
    },
    editBankverbindung() {
      const personId = 'person1';
      this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.UPDATE_SELECTED_CUSTOMER_DATA, personId);
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GOTO_BANKVERBINDUNG, this.backButtonPayload);
    },
    async validateNummer(serverError) {
      const nummerPath = 'versicherung.nummer';

      this.validation.reset(nummerPath);
      if(!this.versicherung.gesellschaft.id) {
        return;
      }
      const validator = this.fieldsValidators.versicherung.nummer;

      if(serverError) {
        this.$pushErrors(nummerPath, serverError);
        return;
      }

      for(const rule of validator) {
        const isValid = await this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.INSURANCE_VERTRAGSDATEN_POLNR_RUN_VALIDATION, 
          { gesellschaftId: this.versicherung.gesellschaft.id, polNummer: this.versicherung.nummer });
        if(this.versicherung.nummer && !isValid){
          this.$pushErrors(nummerPath, this.vertragsdatenFieldsDefinition.polNr.validationMessage);
        } else {
          this.validation.reset(nummerPath);
        }
      }

      if(this?.versicherungFormValidation?.versicherung?.nummer.valid && this.versicherung?.fehler?.length){
        const index = this.versicherung?.fehler.findIndex(errorMsg => errorMsg.includes('Nummer'));
        if(index > -1) {
          this.versicherung.fehler.splice(index, 1);
        }
      }
    },      
  },
  mounted() {
    this.$configureValidators(this.fieldsValidators);
    if(this.versicherung?.gesellschaft?.id && this.vertragsdatenFieldsDefinition?.polNr){
      this.validateNummer();
    }
  },  
  validators: {},
};
</script>

<style scoped>
.align-end {
  flex: 1 1 auto;
  align-self: flex-end;
  padding: 0px 0px 16px 5px;
}
.form-control {
  display: flex;
  align-content: stretch;
  align-items: center;
}
</style>