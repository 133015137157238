<template>
  <div>
    <PageHeaderTitleNavigation
      :title="isAnlageBeispiel ? 'Anlagebeispiel' : 'Anlageempfehlung'"
      :actions="actions"
      @action-NEW_INVESTMENT_ADVICE="createNew()">
    </PageHeaderTitleNavigation>

    <InvestmentAdviceSidetext :warnings="warnings" :hinweise="hinweise" />

    <BaseFilter
      title="Filter"
      filterId="AnlageempfehlungSuche"
      :configFilter="filterConfig"
      :metadata="filterMetadata"
      :defaultOptions="filterDefaultOptions"
      @onFilter="onFilter"
    ></BaseFilter>

    <div class="box__container" tid="15a4d7fd-737e-4c4b-a953-b9ccc0da2166">
      <Table
        v-if="!listLoading && rows.length"
        tableId="Anlageempfehlungliste-777"
        :title="tableTitle"
        :headers="headers"
        :rows="rows"
        :rowsPerPage="10"
        v-model="selectedRows"
        :headerActions="tableActions"
        :exportConfig="{ roottext: 'Anlageempfehlungsliste' }"
        @click-bezeichnung="open"
        @action-INFO="info"
        @action-COPY="copy"
        @action-DELETE="deleteInvestmentAdvice"
        @headerAction-DELETE="deleteSelection"
      />
      <GhostLoading v-else-if="listLoading" type="table" :title="tableTitle" />
      <NoData v-else :title="tableTitle" />
    </div>
    <BaseModal ref="infoModal" :showDefaultButtons="false" size="lg">
      <template v-slot:modalTitle>Anlageempfehlung</template>
      <template #default>
        <div class="info-modal--content" v-html="infoHTMLText"></div>
      </template>
    </BaseModal>
    <BaseModal ref="copyModal"
      :labelButtonConfirm="!message?'Kopieren':'Schließen'"
      :showCancelButton="!message"
      @onConfirmButton="doCopy"
    >
      <template v-slot:modalTitle>
        Anlageempfehlung kopieren
      </template>
      <form v-if="!message">
        <InputField v-model="bezeichnung" label="Bezeichnung" />
        <InputRadioBoxGroup v-if="anwendungen.length"
          title="Anwendung"
          v-model="changeZweck" 
          :values="anwendungen" />
      </form>
      <div v-else>{{message}}</div>
    </BaseModal>
  </div>
</template>
<script>
import Table from "@/components/table2/Table.vue";
import {
  TextColumn,
  CurrencyColumn,
  DateColumn,
  ActionColumn,
  SimpleAction,
  LoadingAction,
} from "@/components/table2/table_util.js";
import BaseModal from "@/components/core/BaseModal.vue";
import INVESTMENT_ADVICE from "@/store/investmentAdvice/types";
import { mapGetters } from "vuex";
import GhostLoading from "@/components/core/loading/GhostLoading.vue";
import NoData from "@/components/core/NoData.vue";
import PageHeaderActionCard from "@/components/core/PageHeaderActionCard.vue";
import InvestmentAdviceSidetext from "@/components/investmentAdvice/InvestmentAdviceSidetext.vue";
import CORE_TYPES from "@/store/core/types";
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import BaseFilter from "@/components/core/BaseFilter.vue";
import { DatePickerUtils } from "@/components/core/forms/DatePicker/date-picker-utils";
import {
  filterMetadata,
  prepareFilters,
} from "./investmentAdviceSearchConstants";
import { PhInfo, PhTrash, PhCopy } from "phosphor-vue";
import { sanitize } from "@/helpers/string-helper.js";
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils'
import InputField from "@/components/core/forms/InputField.vue";
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';

export default {
  mixins: [InteractiveHelpCommonsMixin],
  components: {
    Table,
    BaseModal,
    GhostLoading,
    NoData,
    PageHeaderActionCard,
    InvestmentAdviceSidetext,
    BaseFilter,
    PageHeaderTitleNavigation,
    OptionMenu,
    InputField,
    InputRadioBoxGroup,
  },
  computed: {
    ...mapGetters({
      list: INVESTMENT_ADVICE.GETTERS.LIST,
      id: INVESTMENT_ADVICE.GETTERS.ID,
      listFilter: INVESTMENT_ADVICE.GETTERS.LIST_FILTERS,
      infoHTML: INVESTMENT_ADVICE.GETTERS.INFO,
      isAnlageBeispiel: CORE_TYPES.GETTERS.IS_ANLAGE_BEISPIEL,
      isCustomer: CORE_TYPES.GETTERS.IS_CUSTOMER,
      fullName: CORE_TYPES.GETTERS.GET_USER_FULL_NAME,
      userNr: CORE_TYPES.GETTERS.GET_USER_ID,
    }),
    tableTitle() {
      return this.isAnlageBeispiel
            ? 'Bestehende Anlagebeispiele'
            : 'Bestehende Anlageempfehlungen';
    },
    infoHTMLText() {
      if (
        this.currentId > 0 &&
        this.infoHTML.some((item) => item.key === this.currentId)
      )
        return sanitize(
          this.infoHTML.find((item) => item.key === this.currentId).info
        );
      else {
        return "";
      }
    },
    warnings() {
      return this.list?.data?.fehlerData?.data || [];
    },
    hinweise() {
      return this.list?.data?.hinweise || [];
    },
    hasNurVorlagenFilter() {
      return !this.isCustomer;
    },
    filterMetadata() {
      return filterMetadata(this.hasNurVorlagenFilter);
    },
    filterDefaultOptions() {
      if (Object.keys(this.listFilter || {}).length) {
        return Object.fromEntries(
          Object.entries(this.listFilter).map(([key, value]) => [
            [key],
            { value },
          ])
        );
      }
      return {
        erzeugungsdatum: {
          value: DatePickerUtils.subtract(new Date(), 'month', 3), //  "day", 7),
        },
        ...(this.hasNurVorlagenFilter ? { inclKunden: { value: true } } : {}),
      };
    },
    rows() {
      if (!this.list?.data?.rows) {
        return [];
      } else {
        const actions = [
          SimpleAction("INFO", PhInfo, "Info"),
          SimpleAction("COPY", PhCopy, "Kopieren"),
          LoadingAction(
            "DELETE",
            PhTrash,
            "Löschen",
            () => this.deleteLoading,
            (row) => this.deleteLoading && this.deleteId == row.id
          ),
        ];
        return this.list.data.rows
          .filter(row => !this.isCustomer || this.inclVorlagen || row.zweck !== 'Vorlage')
          .map((row) => {
            if (this.isCustomer && row.zweck == "Vorlage") {
              row.bezeichnung = row.bezeichnung + " (Vorlage)";
            }
            return {
              ...row,
              actions,
            };
          });
      }
    },
    headers() {
      const headers = {
        lockedLeft: [TextColumn("bezeichnung", "Bezeichnung").makeLink()],
        center: [
          CurrencyColumn("anlagesumme", "Anlagesumme"),
          CurrencyColumn("zielsumme", "Zielsumme"),
          CurrencyColumn("sparplan", "Sparplan"),
          CurrencyColumn("entnahmeplan", "Entnahmeplan"),
          TextColumn("zeitraum", "Zeitraum").makeSortable(false),
          DateColumn("datum", "Datum"),
          TextColumn("depot", "Depot"),
        ],
        lockedRight: [ActionColumn("actions", "Aktionen")],
      };
      if (!this.isCustomer) {
        headers.center.push(TextColumn("zweck", "Anwendung"));
      }
      return headers;
    },
    actions() {
      return [
        PageHeaderSimpleAction('NEW_INVESTMENT_ADVICE', 'Neu'),
      ]
    },
    tableActions() {
      return [
        PageHeaderSimpleAction('DELETE', 'Die markierten Zeilen löschen').withDisabled(() => !this.selectedRows.length),
      ]
    },
    anwendungen() {
      if (this.currentRow.zweck) {
        if (this.currentRow.zweck != 'Vorlage') {
          return [
            {label: this.currentRow.zweck, value: false},
            {label: 'Vorlage', value: true},
          ];
        } else if (this.currentRow.zweck == 'Vorlage' && this.isCustomer) {
          return [
            {label: 'Vorlage', value: false},
            {label: this.fullName, value: true},
          ]
        }
      }
      return [];
    }
  },
  data() {
    return {
      selectedRows: [],
      currentId: -1,
      deleteLoading: false,
      deleteId: null,
      listLoading: false,
      filterConfig: {
        checkDefaultSearchInput: false,
        cbDefSearchInput: null,
        hideFirstColumn: true,
        filterZurucksetzen: null,
      },
      currentRow: {},
      bezeichnung: '',
      changeZweck: false,
      message: '',
      filters: null,
    };
  },
  watch: {
    infoHTML: {
      handler: function(val, oldVal) {
        if (this.infoHTMLText.length > 0) {
          this.$refs.infoModal.open();
          if (this.$refs.infoSpan)
            this.$refs.infoSpan.innerHTML = this.infoHTMLText;
        }
      },
      deep: true,
    },
  },
  methods: {
    deleteSelection() {
      this.$store.dispatch(
        INVESTMENT_ADVICE.ACTIONS.DELETE_INVESTMENT_ADVICE_LIST,
        this.selectedRows.map((row) => row.id)
      );
    },
    createNew() {
      this.$store.dispatch(INVESTMENT_ADVICE.ACTIONS.SET_INVESTMENT_ADVICE, {
        adviceId: "-1",
      });
      this.toDetails();
    },
    findAdviceList(filters) {
      if (filters) {
        this.filters = filters;
      } else {
        filters = this.filters;
      }
      this.listLoading = true;
      this.$store
        .dispatch(INVESTMENT_ADVICE.ACTIONS.FETCH_LIST, { filters })
        .finally(() => (this.listLoading = false));
    },
    toDetails() {
      this.$router.push({
        path: 'details', query: { addwp: this.$route.query?.addwp }
      });
    },
    open(row) {
      this.$store.dispatch(INVESTMENT_ADVICE.ACTIONS.SET_INVESTMENT_ADVICE, {
        adviceId: row.id,
      });
      this.toDetails();
    },
    deleteInvestmentAdvice(row) {
      const deleteId = row.id;
      this.deleteLoading = true;
      this.deleteId = deleteId;
      this.$store
        .dispatch(INVESTMENT_ADVICE.ACTIONS.DELETE_INVESTMENT_ADVICE, {
          adviceId: deleteId,
        })
        .finally(() => {
          this.deleteLoading = false;
          this.deleteId = null;
        });
    },
    info(row) {
      this.currentId = row.id;
      this.$store.dispatch(INVESTMENT_ADVICE.ACTIONS.FETCH_INFO, {
        adviceId: row.id,
      });
      if (this.$refs.infoSpan) {
        this.$refs.infoSpan.innerHTML = this.infoHTMLText;
      }
      this.$refs.infoModal.open();
    },
    copy(row){
      this.currentRow = row;
      this.bezeichnung = row.bezeichnung;
      if (this.isCustomer) {
        this.bezeichnung = this.bezeichnung.replace(' (Vorlage)', '');
      }
      this.changeZweck = false;
      this.message = '';
      this.$refs.copyModal.open();
    },
    async doCopy() {
      if ( this.currentRow.id) {
        const resp = await this.$store.dispatch(INVESTMENT_ADVICE.ACTIONS.COPY_INVESTMENT_ADVICE, 
          {id: this.currentRow.id, changeZweck: this.changeZweck, name: this.bezeichnung});
        if (resp && resp.data) {
          this.message = resp.data;
          this.$refs.copyModal.open();
        }
        this.currentRow = {};
      } else if (this.message && this.message.indexOf('erfolg') != -1) {
        this.findAdviceList();
        this.message = '';
      }
    },
    onFilter(filtersArr) {
      const filters = prepareFilters(filtersArr);
      this.inclVorlagen = !!filters.inclVorlagen;
      this.findAdviceList(filters);
    },
  },
  created() {
    const initialFilters = Object.keys(
      this.filterDefaultOptions
    ).map((key) => ({ key, ...this.filterDefaultOptions[key] }));
    this.onFilter(initialFilters);
  },
};
</script>

<style scoped>
.info-modal--content >>> table {
  width: 100%;
}
</style>