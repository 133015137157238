import INVESTMENT_ADVICE from './types';
import Vue from 'vue'

export default {
  [INVESTMENT_ADVICE.GETTERS.ID](state) {
    return state.investmentAdvice;
  },
  [INVESTMENT_ADVICE.GETTERS.INFO](state) {
    return state.info;
  },
  [INVESTMENT_ADVICE.GETTERS.LIST](state) {
    return state.investmentAdviceList;
  },
  [INVESTMENT_ADVICE.GETTERS.EXPIRED](state) {
    return state.expiredParameters;
  },
  [INVESTMENT_ADVICE.GETTERS.PARAMETERS]: state=>step=>{
    return state.parameters[step];
  },  
  [INVESTMENT_ADVICE.GETTERS.POSITIONS]: state=>category=>{
    return state.positions[category];
  },
  [INVESTMENT_ADVICE.GETTERS.BACKTEST_ADVICE](state) {    
    return state.ergebnisBacktestAdvice;
  },
  [INVESTMENT_ADVICE.GETTERS.BACKTEST_DETAILS](state) {
    return state.ergebnisBacktestDetails;
  },
  [INVESTMENT_ADVICE.GETTERS.BACKTEST_ACTUAL](state) {
      return state.ergebnisBacktestActual;
  },
  [INVESTMENT_ADVICE.GETTERS.DIAGRAMME](state) {
      return state.ergebnisDiagramme;
  },
  [INVESTMENT_ADVICE.GETTERS.OPTIMIZATION](state) {
      return state.ergebnisDepotoptimierung;
  },
  [INVESTMENT_ADVICE.GETTERS.ORDER](state) {
    return state.order;
  },
  [INVESTMENT_ADVICE.GETTERS.INVESTMENT_ADVICE_ORDER](state) {
    return state.investmentAdviceOrder;
  },
  [INVESTMENT_ADVICE.GETTERS.INVESTMENT_ADVICE_DEPOTEROEFFNUNG](state) {
    return state.investmentAdviceDepoteroeffnung;
  },
  [INVESTMENT_ADVICE.GETTERS.LIST_FILTERS](state) {
    return { ...state.listFilters };
  },
  [INVESTMENT_ADVICE.GETTERS.GET_MESSAGE](state) {
    return state.message;
  },
  [INVESTMENT_ADVICE.GETTERS.LAST_VISIT_STEP](state) {
    return state.lastVisitStep;
  },
}