import INVESTMENT_ADVICE from './types';
import { getInitialState } from './index';
import store from '@/components/dashboard/store/index';
import Vue from 'vue';
import {setRow} from './types';


export default {  
  [INVESTMENT_ADVICE.MUTATIONS.TO_ORDER_SUCCESS](state, payload) {
    state.order = {
      data: payload.data,
      status: 'success',
      loaded: true,
    };
  },
  [INVESTMENT_ADVICE.MUTATIONS.TO_ORDER_FAILURE](state, payload) {
    state.order = {
      data: payload.data,
      status: 'error',
      loaded: false,
    };
  },
  [INVESTMENT_ADVICE.MUTATIONS.SET_INVESTMENT_ADVICE_ORDER_SUCCESS](state, payload) {
    Vue.set(state, 'investmentAdviceOrder', payload);
  },
  [INVESTMENT_ADVICE.MUTATIONS.SET_INVESTMENT_ADVICE_DEPOTEROEFFNUNG_SUCCESS](state, payload) {
    Vue.set(state, 'investmentAdviceDepoteroeffnung', payload);
  },
  [INVESTMENT_ADVICE.MUTATIONS.UPDATE_INVESTMENT_ADVICE_ORDER_SUCCESS](state, payload) {
    if (state.investmentAdviceOrder.orderList) {
      Vue.set(state.investmentAdviceOrder, 'orderList', state.investmentAdviceOrder.orderList.filter(item=>item.value !== payload));
    } else if (state.investmentAdviceOrder.lagerstelle === payload) {
      Vue.set(state, 'investmentAdviceOrder', {});
    }
  },
  [INVESTMENT_ADVICE.MUTATIONS.SET_INVESTMENT_ADVICE_SUCCESS](state, payload) {
    Vue.set(state, 'investmentAdvice', payload);
    Vue.set(state, 'lastVisitStep', {});
  },
  [INVESTMENT_ADVICE.MUTATIONS.FETCH_LIST_SUCCESS](state, payload) {
    state.investmentAdviceList = {
      data: payload.data,
      status: 'success',
      loaded: true,
    };
  },
  [INVESTMENT_ADVICE.MUTATIONS.FETCH_LIST_FAILURE](state, payload) { 
    let storeValue = state.investmentAdviceList;
    
    state.investmentAdviceList = {
        data: storeValue === undefined || payload.delete ? undefined : storeValue.data,
        status: 'error',
        loaded: false,
    };    
  },
  [INVESTMENT_ADVICE.MUTATIONS.FETCH_PARAMETERS_SUCCESS](state, payload) {
    if (payload?.data?.rows?.length) {
      const bestandsdepot = payload?.data?.parameterData?.bestandsdepot;
      let nichtHandelbar = '';
      payload.data.rows.forEach(row => {
        setRow(row, payload.key, bestandsdepot);
        if (row.isNichtHandelbar && !row.isDeleted) {
          nichtHandelbar += (nichtHandelbar ? ', ' : '') + row.isin;
        }
      })
      if (nichtHandelbar) {
        if (!payload.data.hinweise) {
          payload.data.hinweise = [];
        }
        const label = `nicht handelbare Fonds (<span class='color-danger'>x</span>-Markierung)`;
        const nH = payload.data.hinweise.find(h => h.label === label);
        if (nH) {
          nH.text = nichtHandelbar;
        } else {
          payload.data.hinweise.push({label, text: nichtHandelbar});
        }
      }
    }
    if (process.env.VUE_APP_USER === 'hannes') console.log(payload.key, JSON.parse(JSON.stringify(payload.data)) );
    state.parameters[payload.key] = {...state.parameters[payload.key],
      data: payload.data,
      status: 'success',
      loaded: true,
    };
    //state.expiredParameters = payload.key;
    if (payload.key ===  INVESTMENT_ADVICE.STEPS.MAIN && state.investmentAdvice === '-1') {
      state.investmentAdvice = payload.data.anlageempfehlungId;
    }
  },  
  [INVESTMENT_ADVICE.MUTATIONS.FETCH_PARAMETERS_FAILURE](state, payload) {
    let storeValue = state.parameters[payload.key];
    {
      state.parameters[payload.key] = {...state.parameters[payload.key],
        data: storeValue === undefined ? undefined : storeValue.data,
        status: 'error',
        loaded: false,
      };
      state.expiredParameters = payload.key;
    }
  },

  [INVESTMENT_ADVICE.MUTATIONS.FETCH_POSITIONS_SUCCESS](state, payload) { //key - positions QUELLE / PURPOSE
    if (payload.key == 'OUTCOME' && payload.data?.rows) {
      payload.data.rows.forEach(row => {
        setRow(row, payload.key, '');
      })
      if (process.env.VUE_APP_USER === 'hannes') console.log('POSITIONS',  JSON.parse(JSON.stringify(payload)) );
      Vue.set(state.parameters.OUTCOME.data, 'rows', payload.data.rows);
    } else if (payload.data?.rows?.length) {
      if (process.env.VUE_APP_USER === 'hannes') console.log(payload.key,  JSON.parse(JSON.stringify(payload.data.rows)) );
    }
  },
  [INVESTMENT_ADVICE.MUTATIONS.CHANGE_ROWS](state, payload) {
    if (process.env.VUE_APP_USER === 'hannes') console.log('MUTATIONS.CHANGE_ROWS', JSON.parse(JSON.stringify(payload)) );
    const step = payload.step;
    if (typeof payload.rowIdx === 'number') {
      Vue.set(state.parameters[step].data.rows, payload.rowIdx, payload.changed);
    } else {
      Vue.set(state.parameters[step].data, 'rows', payload.changed);
    }
  },
  [INVESTMENT_ADVICE.MUTATIONS.RESET_POSITIONS](state, payload) {
    if (process.env.VUE_APP_USER === 'hannes') console.log('MUTATIONS.RESET_POSITIONS', 
    JSON.parse(JSON.stringify(payload)), JSON.parse(JSON.stringify(state.parameters))  );
    
    ['MAIN','MYDEPOT','MYDEPOT_EINMALANLAGE','MYDEPOT_SPARPLAN','WK','FAVORITES',
    'FONDSFINDER','OPTIMIZATION','BENCHMARK','OUTCOME','PDF','COURTAGE','ORDER']
    .forEach( key => {
      if (payload.key != key && state.parameters[key].loaded && state.parameters[key].data?.rows) {
        state.parameters[key].loaded = false;
      }
    })
    state.ergebnisBacktestActual.loaded = false;
    state.ergebnisBacktestAdvice.loaded = false;
    state.ergebnisBacktestDetails.loaded = false;
    state.ergebnisDiagramme = {};
  },
  [INVESTMENT_ADVICE.MUTATIONS.RESET_POSITIONS_OUTPUT](state, payload) {
    if (process.env.VUE_APP_USER === 'hannes') console.log('MUTATIONS.RESET_POSITIONS_OUTPUT', payload && JSON.parse(JSON.stringify(payload)) );
    
    this.commit(INVESTMENT_ADVICE.MUTATIONS.RESET_OUTCOME);
  },
  [INVESTMENT_ADVICE.MUTATIONS.RESET_POSITIONS_COURTAGE](state, payload) {
  },
  [INVESTMENT_ADVICE.MUTATIONS.RESET_ORDER](state, payload) {
    state.order = getInitialState().order;
  },

  [INVESTMENT_ADVICE.MUTATIONS.FETCH_INFO_SUCCESS](state, payload) { 
    state.info.push(payload);  
  },
  
  [INVESTMENT_ADVICE.MUTATIONS.FETCH_POSITIONS_FAILURE](state, payload) {
  },

  [INVESTMENT_ADVICE.MUTATIONS.FETCH_OUTCOME_SUCCESS](state, payload) { //key - outcome type
    if (process.env.VUE_APP_USER === 'hannes') console.log('MUTATIONS.FETCH_OUTCOME_SUCCESS', JSON.parse(JSON.stringify(payload)) );
    switch (payload.key) {
      case INVESTMENT_ADVICE.OUTCOME.BACKTEST_ACTUAL:
        state.ergebnisBacktestActual = {data: payload.data, status: 'success', loaded: true,};
        break;
      case INVESTMENT_ADVICE.OUTCOME.BACKTEST_ADVICE: {
        const measures = payload.data?.chartData?.measures;
        if (measures && measures['Aktuelles Depot'] && measures['Aktuelles Depot'].jahresPerformance ) {
          payload.data.performance = payload.data.performance || {};
          payload.data.performance.Aktuelles_Depot = measures['Aktuelles Depot'];
        }
        if (measures?.Depotvorschlag?.jahresPerformance ) {
          payload.data.performance = payload.data.performance || {};
          payload.data.performance.Depotvorschlag = measures.Depotvorschlag;
        }
        state.ergebnisBacktestAdvice = {data: payload.data, status: 'success', loaded: true,};
      }
        break;
      case INVESTMENT_ADVICE.OUTCOME.BACKTEST_DETAILS:
        state.ergebnisBacktestDetails = {data: payload.data, status: 'success', loaded: true,};
        break;
      case INVESTMENT_ADVICE.OUTCOME.OPTIMIZATION:
        state.ergebnisDepotoptimierung = {data: payload.data, status: 'success', loaded: true,};
        this.commit(INVESTMENT_ADVICE.MUTATIONS.RESET_OUTCOME);
    }    
  },  

  [INVESTMENT_ADVICE.MUTATIONS.FETCH_OUTCOME_FAILURE](state, payload) {
    if (process.env.NODE_ENV === 'development')
      console.log('FETCH_OUTCOME_FAILURE ' + payload.key);
    switch (payload.key) {
      case INVESTMENT_ADVICE.OUTCOME.BACKTEST_ACTUAL:
        state.ergebnisBacktestActual.loaded = false;
        state.ergebnisBacktestActual.status = 'error';
        break;
      case INVESTMENT_ADVICE.OUTCOME.BACKTEST_ADVICE:
        state.ergebnisBacktestAdvice.loaded = false;
        state.ergebnisBacktestAdvice.status = 'error';
        break;
      case INVESTMENT_ADVICE.OUTCOME.BACKTEST_DETAILS:
        state.ergebnisBacktestDetails.loaded = false;
        state.ergebnisBacktestDetails.status = 'error';
        break;
      case INVESTMENT_ADVICE.OUTCOME.OPTIMIZATION:
        state.ergebnisDepotoptimierung.loaded = false;
        state.ergebnisDepotoptimierung.status = 'error';
    }
  },  
  [INVESTMENT_ADVICE.MUTATIONS.FETCH_DIAGRAMME](state, payload) {
    state.ergebnisDiagramme = payload;
  },  
  [INVESTMENT_ADVICE.MUTATIONS.RESET_OUTCOME](state, payload) {
    if (process.env.VUE_APP_USER === 'hannes') console.error('MUTATIONS.RESET_OUTCOME' );
    state.parameters.OUTCOME.loaded = false;
    state.ergebnisBacktestActual.loaded = false;
    state.ergebnisBacktestAdvice.loaded = false;
    state.ergebnisBacktestDetails.loaded = false;
    state.ergebnisDiagramme = {};
  },
  
  [INVESTMENT_ADVICE.MUTATIONS.RESET_STATE](state) {
    if (process.env.VUE_APP_USER === 'hannes') console.log('MUTATIONS.RESET_STATE' );
    const iState = getInitialState();
    Object.assign(state, {...iState, listFilters: state.listFilters, investmentAdviceList: state.investmentAdviceList})
  },

  [INVESTMENT_ADVICE.MUTATIONS.RESET_PARAMETERS_LOADED](state, payload) {
    if (process.env.VUE_APP_USER === 'hannes') console.log('MUTATIONS.RESET_PARAMETERS_LOADED', payload.key);
    // Object.keys(INVESTMENT_ADVICE.STEPS).forEach(stepKey => {
    //   if (payload == null || !payload.step || payload.step === stepKey) {
    //     const stepParameters = state.parameters[stepKey];
    //     if(stepParameters) {
    //       stepParameters.loaded = false;
    //     }
    //   }
    // })
    if (payload.key && state[payload.key] != undefined){
      state.parameters[payload.key] = {data: {}, status: 'reseted', loaded: false};
    }
    this.commit(INVESTMENT_ADVICE.MUTATIONS.RESET_OUTCOME, {data: []});
  },

  [INVESTMENT_ADVICE.MUTATIONS.SET_LIST_FILTERS](state, payload) {
    Vue.set(state, 'listFilters', { ...(payload || {}) })
  },
  
  [INVESTMENT_ADVICE.MUTATIONS.SET_MESSAGE](state, payload) {
    Vue.set(state, 'message', {
      title: payload?.title || '',
      text: payload?.text || '',
      show: (payload?.show != null ? payload?.show : !!payload?.text)
    })
  },
  [INVESTMENT_ADVICE.MUTATIONS.LAST_VISIT_STEP](state, payload) {
    state.lastVisitStep = payload;
  },
}