export function getTicketStatusDescription(isKunde, ticket) {
    let statusDescription;
    if (isKunde) {
        statusDescription = ticket.status === "BEARBEITET" ? "bearbeitet" : (ticket.status === "GESCHLOSSEN" ? "geschlossen" : "offen");
    } else {
        switch (ticket.status) {
            case "ANFRAGE":
                statusDescription = "Anfrage";
                break;
            case "IN_BEARBEITUNG":
            case "BITTE_UM_BEARBEITUNG":
            case "BITTE_UM_KENNTNISNAHME":
            case "IN_BEARBEITUNG_GESELLSCHAFT":
            case "IN_BEARBEITUNG_IT":
                statusDescription = "In Bearbeitung";
                break;
            case "BEARBEITET":
                statusDescription = "Bearbeitet";
                break;
            case "GESCHLOSSEN":
                statusDescription = "Geschlossen";
                break;
            default:
                statusDescription = "Unbekannter Status";
                console.log("unbekannter status: ", ticket.status);
        }
    };
    return statusDescription;
};