var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { key: _vm.__DYNAMIC_APP_ID__, class: _vm.appClass, attrs: { id: "app" } },
    [
      _vm.$route.matched.some(function(ref) {
        var meta = ref.meta

        return meta && meta.docs
      })
        ? _c(
            "div",
            { key: "docs" },
            [_c("router-view", { key: _vm.completeTitle }, [_vm._v("docs")])],
            1
          )
        : _vm._e(),
      _vm.$route.matched.some(function(ref) {
        var meta = ref.meta

        return meta && meta.simplePage
      })
        ? _c("div", [_c("router-view")], 1)
        : _c(
            "div",
            [
              _c("UpdaterModal"),
              _c("BaseNotificationMessage"),
              _c("BaseKillSession"),
              _c("BaseHeaderBar"),
              _c("BaseFooterBar"),
              _vm.$route.matched.some(function(ref) {
                var meta = ref.meta

                return meta && meta.bypassContainerSize
              })
                ? _c(
                    "div",
                    { attrs: { "data-app-content": "" } },
                    [
                      _c(
                        "PageNotAvailableInSmallScreen",
                        [_c("router-view")],
                        1
                      )
                    ],
                    1
                  )
                : [
                    _c(
                      "div",
                      {
                        staticClass: "fc-container",
                        attrs: { "data-app-content": "" }
                      },
                      [
                        _c("OptionMenu", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.showOptionsMenu,
                              expression: "showOptionsMenu"
                            }
                          ],
                          attrs: {
                            isLoading: _vm.isLoadingRequiredData,
                            id: _vm.currentAppNavigation.currentOptionMenuId,
                            defaultMenu:
                              _vm.currentAppNavigation.defaultOptionMenu,
                            ignoreRender: false
                          }
                        }),
                        !_vm.isLoadingRequiredData
                          ? _c(
                              "PageNotAvailableInSmallScreen",
                              [_c("router-view")],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
              _c("HeatmapCanvas"),
              _vm._l(_vm.pendingConfirmModal, function(pendingConfirm) {
                return _c("BaseConfirmModal", {
                  key: pendingConfirm._uuid,
                  attrs: { config: pendingConfirm }
                })
              })
            ],
            2
          ),
      _vm.isMobileNativeContext
        ? _c("VStyle", [
            _vm._v(
              " html, body { width: 100vw; overflow-x: hidden; overflow-y: scroll; overscroll-behavior: none; } "
            )
          ])
        : _vm._e(),
      _c("Offline")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }