<template>
  <div>
    <PageHeaderTitleNavigation 
      title="Kundennummern Gesellschaft" 
      :actions="headerActions"
      @action-ADD="addKundenGesellNr()"
    />

    <div class="box__container">
      <Table v-if="!loading && rows.length" 
        tableId="14dd3103-0cdf-4fa7-82a4-5325d84c0d2f" 
        :title="TABLE_TITLE" 
        :headers="headers" 
        :rows="rows" 
        :rowsPerPage="20" 
        @click-fremdnr="openKundenGesellNr($event)" 
        @action-DELETE="deleteKundenGesellNr($event)"
      />
      <GhostLoading v-else-if="loading" type="table" :title="TABLE_TITLE"  />
      <NoData v-else :title="TABLE_TITLE"  />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import KUNDEN_GESELL_NR_TYPES from '@/store/kundenGesellNr/types';
import CORE_TYPES from '@/store/core/types';

import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import Table from '@/components/table2/Table.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';

import {TextColumn, ActionColumn, ConfirmedAction, } from "@/components/table2/table_util";
import { VIEW_ROLES, } from '@/router/roles';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import { buildMessage } from "@/helpers/log-message-helper";
import LOG_TYPES from '@/store/log/types';

const TABLE_TITLE = 'Kundennummernliste Gesellschaften';

export default {
  name: 'KundenGesellNr',
  data() {
    return {
      TABLE_TITLE,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      list: KUNDEN_GESELL_NR_TYPES.GETTERS.LIST,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
    }),
    isIntern() {
      return this.hasRoles([VIEW_ROLES.VIEW_CUSTOMER_AS_INTERN]);
    },
    headerActions() {
      return [
        PageHeaderSimpleAction('ADD', 'Neu'),
      ];
    },
    headers() {
      const headers = {
        lockedLeft: [
          TextColumn("gesellName", "Gesellschaft"),
          TextColumn("fremdnr", "Nummer").makeLink().makeAlwaysVisible(),
        ],
        center: [
          TextColumn("bezeichnung", "Bezeichnung"),
          TextColumn("nummerntyp", "Typ"),
          TextColumn("depotmodell", "Depotmodell"),
          TextColumn("depotGeschlossenText", "geschlossenes Depot/Konto"),
          TextColumn("ausInterfaceText", "von der Gesellschaft bestätigt"),
          TextColumn("portfolioFremdId", "Portfolio Kennung"),
          TextColumn("bestaetigtDatum", "bestätigt seit"),
        ],
        lockedRight: [
          ActionColumn("actions", "Aktionen"),
        ],
      };

      if(this.isIntern) {
        headers.center.push(TextColumn("validText", "Valid"),);
      }

      return headers;
    },
    rows() {
      return (this.list || []).map(item => {
        const actions = [];
        if (!item?.ausInterface) {
          actions.push(ConfirmedAction('DELETE', 'PhTrash', 'Löschen', 
              `Soll die Kundennummer "${item?.fremdnr || ''}" bei der Gesellschaft "${item?.gesellName || ''}" wirklich gelöscht werden?`, 'Kontonummer löschen'));
        }
        return {
          ...item,
          depotGeschlossenText: item?.depotGeschlossen ? 'Ja' : 'Nein',
          ausInterfaceText: item?.ausInterface ? 'Ja' : 'Nein',
          ...(this.isIntern ? { validText: item?.valid ? 'Ja' : 'Nein', } : {}),
          actions: actions,
        }
      });
    },
  },
  methods: {
    async find() {
      try {
        this.loading = true;
        await this.$store.dispatch(KUNDEN_GESELL_NR_TYPES.ACTIONS.FIND);
      } finally {
        this.loading = false;
      }
    },
    navigateToEdit(fremdnr, gesellId) {
      this.$router.push(`/persoenlichedaten/kundennummern-gesellschaft/edit/${fremdnr}/${gesellId}`);
    },
    addKundenGesellNr() {
      this.navigateToEdit('new', null);
    },
    openKundenGesellNr(kundenGesellNr) {
      if(kundenGesellNr?.nummerntyp == 'Partnernummer'){
        this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Fremdnummer vom Typ `Partnernummer` ist nicht editierbar.', 'primary'));
      }else{
        this.navigateToEdit(kundenGesellNr?.fremdnr, kundenGesellNr?.gesellId);
      }
    },
    async deleteKundenGesellNr(kundenGesellNr) {
      await this.$store.dispatch(KUNDEN_GESELL_NR_TYPES.ACTIONS.DELETE, kundenGesellNr);
      this.find();
    },
  },
  mounted() {
    this.find();
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({ to, from, });
    next();
  },
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    BaseButton,
    Table,
    GhostLoading,
    NoData,
  },
}
</script>
