<template>
  <div class="big-table">

    <PageHeaderTitleNavigation 
            isSecondary
            asPrimaryOnly
            :actions="headerActionsLocal"
            @action="handleHeaderAction"
            :title=title
        >

    </PageHeaderTitleNavigation>

    <div class="scroll-wrapper">
      <div class="table-container"  @scroll="checkScroll" ref="tableContainer" >
        <table :style="{ width: tableWidth + 'px' }"  >
          <colgroup>
            <col v-if="selectable" :style="{ width: getCheckboxColumnWidth + 'px' }" /> <!-- Adjust width for selection column -->
            <col v-for="(header, index) in visibleColumns" :key="index" :style="{ width: header.width + 'px' }" />
          </colgroup>
          <thead class="sticky__header">
            <tr>
              <th v-if="selectable" class="fixed-left-col"> <!-- Add checkbox column if selectable -->
                <div class="header-content"><input type="checkbox" @change="toggleRowsSelection($event)" /></div>
              </th>
              <th
                :class="['text-' + getAlignment(header.type), header.type==ACTION_COLUMN ? 'fixed-right-col' : header.position || '' ]"
                v-for="(header, index) in visibleColumns"
                :key="index"
                :style="getPositionStyle(header)"
              >
                <div class="header-content">
                  <div  :class="header.type != ICON_COLUMN && header.type != STATIC_ICON_COLUMN && header.type != SLOT_COLUMN && header.type != ACTION_COLUMN  ? 'header-title' : ''" @click="onClickHeaderTitle(header)">
                    {{ header.label }}
                    <ph-caret-up :size="16" v-show="sortingState && sortingState.key == header.key && sortingState.direction == 'desc'"/>
                    <ph-caret-down :size="16" v-show="sortingState && sortingState.key == header.key && sortingState.direction == 'asc'"/>
                  </div>
                  <div
                    class="resize-handle"
                    @mousedown="startResizing(index, $event)"
                  ></div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, rowIndex) in visibleRows" :key="rowIndex">
              <td  v-if="selectable" class="fixed-left-col"> <!-- Add checkbox cell if selectable -->
                <input type="checkbox" v-model="row.selected" @change="handleRowSelection($event, row)" />
              </td>
              <td
                v-for="(header, colIndex) in visibleColumns"
                :class="['text-' + getAlignment(header.type), header.key=='actions' ? 'fixed-right-col' : header.position || '' ]"
                :key="colIndex"
                :style="getMergedStyles(header, row, rowIndex)"
              >
  
                <slot v-if="header.type == SLOT_COLUMN" :name="header.key" v-bind="row"/>
  
  
                <span v-else-if="header.type == ICON_COLUMN" class="icon__cell">
                  <IconCell :column="header.key" :row="row" @onClick="iconCellClicked(header, row)"/>
                </span>

                <span v-else-if="header.type == STATIC_ICON_COLUMN">
                  <IconCell :column="header.key" :row="row"/>
                </span>                
  
                <span v-else-if="header.type == LINK_COLUMN">
                  <a @click="linkCellClicked(header, row)">{{ row[header.key] }}</a>
                </span>              
  
                <span v-else-if="header.key!=='actions'">
                  {{ formatValue(row[header.key], header.type) }}
                </span> 
  
                <span v-else>
                  <ActionCell 
                       v-bind:key="rowIndex + colIndex"
                       :tid="tid + rowIndex"
                       :column="header.key"
                       :row="row"
                       @action="emitAction($event)"></ActionCell>
  
                </span>
  
              </td>
  
              
            </tr>
          </tbody>
        </table>
      </div>

    </div>


    <ModalColumnConfig 
            ref="columnsConfig"
            :title="title ? (title + ' - Einstellungen') : 'Einstellungen'"
            :tableId="tableId"
            :tableHeaders="modifiedHeaders"
            :lockedColumns="fakeLockedColumns"
            @close="onCloseColumnsConfig"
            @onFinishConfig="onFinishColumnsConfig"
            @onRestoreDefault="onFinishColumnsConfig"
        />    

  </div>
</template>

<script>
import ActionCell from '@/components/table2/cellTypes/ActionCell.vue';
import IconCell from '@/components/table2/cellTypes/IconCell.vue';
import { PhCaretUp, PhCaretDown } from 'phosphor-vue'
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import ModalColumnConfig from '@/components/table/ModalColumnConfig.vue'
import FC_CONFIG_TYPES from '@/store/fcConfig/types';
import { mapGetters } from 'vuex';
import FC_CONFIG from '@/configs/fcConfig.js'
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';

// Column types
export const STRING_COLUMN = "STRING";  
export const DATE_COLUMN = "DATE";
export const CURRENCY_COLUMN = "CURRENCY";
export const FLOAT_COLUMN = "FLOAT";
export const ACTION_COLUMN = "ACTION";
export const SLOT_COLUMN = "SLOT";
export const ICON_COLUMN = "ICON";
export const STATIC_ICON_COLUMN = "STATIC_ICON";
export const LINK_COLUMN = "LINK";
export const DATETIME_COLUMN = "DATETIME";
export const NUMBER_COLUMN = "NUMBER";
export const INTEGER_COLUMN = "INTEGER";


// Column positions
export const FIXED_LEFT = "fixed-left-col";  
export const FIXED_RIGHT = "fixed-right-col"; 

const TABLE_ACTIONS_PREFIX = 'TABLE__';

export function Icon(icon, title, size = 20, weight, classString) {
    return {
        icon,
        title,
        size,
        weight,
        class: classString,
    }
}

export default {
  created() {
        this.ACTION_COLUMN = ACTION_COLUMN;
        this.SLOT_COLUMN = SLOT_COLUMN;
        this.ICON_COLUMN = ICON_COLUMN;
        this.STATIC_ICON_COLUMN = STATIC_ICON_COLUMN;
        this.LINK_COLUMN = LINK_COLUMN;
  },  
  components: {
    ActionCell,
    IconCell,
    PhCaretUp, 
    PhCaretDown,
    PageHeaderTitleNavigation,
    ModalColumnConfig,
  },
  props: {
    headers: {
      type: Array,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    initialPageSize: {
      type: Number,
      default: 0,
    },    
    page: {
      type: Number,
      default: 0,
    },     
    selectionColumnHeader: {
      type: String,
      default: "", // Default label for the selection column header
    },
    headerActions: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },      
    sortingState: {
      type: Object,
      default: () => ({direction: 'desc'}),
    },    
    tableId: {
      type: String,
    },  
  },
  data() {
    return {
      resizing: false,
      resizeIndex: -1,
      startX: 0,
      startWidth: 0,
      modifiedHeaders: [...this.headers.filter(mh => !!mh.hidden == false || !!mh.visible == false)],
      tableWidth: 0, // Track the table width dynamically
      tid: '60350f89-ac6c-4aa1-89a9-333898128629',
      lastScrollTop: 0, // Store the last scroll position
      pageSize: 100,
    };
  },
  mounted() {
      if (this.tableId) {
          // console.log("BigTable mounted called");
          this.$store.dispatch(FC_CONFIG_TYPES.ACTIONS.LOAD_FC_CONFIG, {
              configId: this.tableId,
              configType: FC_CONFIG.TABLE_COLUMN_CONFIG,
          });
      }
      this.restoreColumnsConfiguration(this.storedColumnsConfig);
  }, 
  computed: {
    ...mapGetters({
      storedColumnsConfig: FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_TABLE_COLUMN,
    }),
    showOpenColumnsConfig() {
      const hasTableId = !!this.tableId;
      return hasTableId;
    },    
    headerActionsLocal() {
      const actions = [...this.headerActions.slice()];

      actions.push(PageHeaderSimpleAction(`${TABLE_ACTIONS_PREFIX}CONFIG-EDIT`, 'Spalten anpassen')
                    .withVisible(() => this.showOpenColumnsConfig));

      return actions;
    },
    fakeLockedColumns() {
            const mapLockedColumn = header => ({
                key: header.key,
                neverHide: header.neverHide,
            });
            return {
                toLeft: this.modifiedHeaders.filter(mh => mh.position == FIXED_LEFT)?.map(mapLockedColumn),
                toRight: this.modifiedHeaders.filter(mh => mh.position == FIXED_RIGHT || mh.type=='ACTION')?.map(mapLockedColumn),
            }
    },        
    getCheckboxColumnWidth() {
      // Calculate the width based on the length of the selectionColumnHeader
      return (this.selectionColumnHeader.length || 3) * 10; 
    },
    sortedRows() {
        if (this.sortingState?.key) {
            const columnType = this.modifiedHeaders.find(header => header.key === this.sortingState.key)?.type || 'STRING';  // Default to STRING if no type is found

            return this.rows.slice().sort((a, b) => {
                const valueA = a[this.sortingState.key];
                const valueB = b[this.sortingState.key];

                return this.compareValues(valueA, valueB, columnType);
            });
        }
        return this.rows;
    },
    visibleRows() {
        let visibleRows = [];


        if (this.pageSize > 0) {
            visibleRows = this.sortedRows.slice(this.page * this.pageSize, (this.page + 1) * this.pageSize);
        } else {
            visibleRows = this.sortedRows.slice();
        }

        return visibleRows;
    },
    visibleColumns() {
      return this.modifiedHeaders.filter(mh => mh.visible == true || mh.position == FIXED_LEFT || mh.position == FIXED_RIGHT || mh.type=='ACTION');
    },
  },
  methods: {
      openColumnsConfig() {
            this.$emit('isColumnsConfigOpen', true)
            this.$refs.columnsConfig.open()
      },    
      onFinishColumnsConfig() {
        if (this.tableId) {
            const savedConfig = this.storedColumnsConfig[this.tableId];
            const savePayload = {
                configId: this.tableId,
                configType: FC_CONFIG.TABLE_COLUMN_CONFIG,
                content: savedConfig?.content,
                userLevel: savedConfig?.userLevel || null,
            }
            this.$emit('configColumn', savePayload);
        }
      },    
      onCloseColumnsConfig() {
        this.$emit('isColumnsConfigOpen', false)
      },    
      checkScroll() {
        // let wrapper = this.$el;
        let wrapper =this.$refs.tableContainer;

        // const tableContainer = this.$refs.tableContainer;

        let currentScrollTop = wrapper.scrollTop;
        // console.log("current page size", this.pageSize);

        if (currentScrollTop > this.lastScrollTop && wrapper.scrollTop + wrapper.clientHeight >= wrapper.scrollHeight - 10) {
          this.pageSize = Math.min(this.pageSize + 100, this.rows.length);
          // console.log("should show more items", this.pageSize)
        }
        this.lastScrollTop = currentScrollTop; // Update last scroll position
      },    
      handleHeaderAction({ key, value, }) {
        if (key == `${TABLE_ACTIONS_PREFIX}CONFIG-EDIT`) {
          this.openColumnsConfig();
        }

        this.$emit('headerAction', { key, value, });
        this.$emit(`headerAction-${key}`, value);
      },    
      compareStrings(valueA, valueB) {
          return this.sortingState?.direction === 'desc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
      },
      compareValues(valueA, valueB, type) {
        // Handle null values
        if (valueA === null && valueB === null) return 0;
        if (valueA === null) return this.sortingState.direction === 'desc' ? 1 : -1;
        if (valueB === null) return this.sortingState.direction === 'desc' ? -1 : 1;

        switch (type) {
            case DATETIME_COLUMN: {
              // Parse German-formatted dates and times (dd.mm.yyyy hh:mm:ss)
              const parseDateTime = (dateTimeStr) => {
                const [datePart, timePart] = dateTimeStr.split(' ');
                const [day, month, year] = datePart.split('.');
                const [hours, minutes, seconds] = timePart.split(':');
                return new Date(year, month - 1, day, hours, minutes, seconds);
              };

              const dateA = parseDateTime(valueA);
              const dateB = parseDateTime(valueB);
              valueA = dateA.getTime();
              valueB = dateB.getTime();
              break;
            }
            case DATE_COLUMN: {
                // Parse German-formatted dates (dd.mm.yyyy)
                const parseDate = dateStr => {
                    const parts = dateStr.split('.');
                    return new Date(parts[2], parts[1] - 1, parts[0]); // Convert to Date object
                };
                const dateA = parseDate(valueA);
                const dateB = parseDate(valueB);
                valueA = dateA.getTime();
                valueB = dateB.getTime();
                break;
            }
            case STRING_COLUMN: {
                valueA = String(valueA);
                valueB = String(valueB);
                return this.sortingState.direction === 'desc' ? valueB.localeCompare(valueA) : valueA.localeCompare(valueB);
            }
            case NUMBER_COLUMN:
            case FLOAT_COLUMN:
            case CURRENCY_COLUMN:
            case INTEGER_COLUMN:  {
                valueA = Number(valueA);
                valueB = Number(valueB);
                break;
            }
            default:
                return 0;
        }

        return this.sortingState.direction === 'desc' ? valueA - valueB : valueB - valueA;
      },
      onClickHeaderTitle(header) {
      if (header?.type != ICON_COLUMN && header?.type != STATIC_ICON_COLUMN && header?.type != SLOT_COLUMN && header?.type != ACTION_COLUMN) {

        const newSortingState = {};
        if (this.sortingState?.key !== header.key) {
          newSortingState.key = header.key;
        }

         newSortingState.direction = this.sortingState?.direction =="asc" ? "desc" : "asc";

        this.$emit("onClickHeader", {header, sortingState:  newSortingState });
      }
    },
    iconCellClicked(header, row) {
      this.$emit("click-icon-"+header.key, row);
    },
    linkCellClicked(header, row) {
      this.$emit("click-link-"+header.key, row);
    },
    emitAction(eventData) {
      this.$emit("action", eventData);
      this.$emit(`action-${eventData.key}`, eventData.row);
    },
    getMergedStyles(header, row, rowIndex) {
      return {
        ...this.getPositionStyle(header),
        ...row?.customRowStyle,
        ...this.calculatedRowBackgroundColor(row, rowIndex)
      };
    },
    calculatedRowBackgroundColor(whatRow, index) {
			if (index % 2== 0 && !whatRow.clicked) {
				return ( { backgroundColor: 'var(--color-box-neutral)', } );
			}
			return {};
		},      
    getPositionStyle(header) {
      if (header.position == FIXED_LEFT) {
        return this.getLeftPositionStyle(header.key);
      } else if (header.position == FIXED_RIGHT) {
        return this.getRightPositionStyle(header.key);
      }
      return "";
    },
    getLeftPositionStyle(key) {
      let precedingWidth = this.getWidthOfPrecedingColumn(key);

      let checkboxColumnWidth = 0;
      if (this.selectable) {
        checkboxColumnWidth = this.getCheckboxColumnWidth;
      }
      return {left: `${precedingWidth + checkboxColumnWidth}px`}
    },
    getRightPositionStyle(key) {
      let nextWidth = this.getWidthOfFollowingColumn(key);

      return {right: `${nextWidth}px`}
    },    
    getWidthOfPrecedingColumn(key) {
      let precedingWidth = 0;
      for (let i = 0; i < this.modifiedHeaders.length; i++) {
        if (this.modifiedHeaders[i].key === key) {
          break;
        }
        precedingWidth += this.modifiedHeaders[i].width;
      }

      return precedingWidth;
    },
    getWidthOfFollowingColumn(key) {
      let found = false;
      let followingWidth = 0;
      for (let i = 0; i < this.modifiedHeaders.length; i++) {
        if (found) {
          followingWidth += this.modifiedHeaders[i].width;
          break;
        }
        if (this.modifiedHeaders[i].key === key) {
          found = true;
        }
      }
      return followingWidth;
    },    
    startResizing(index, event) {
      this.resizing = true;
      this.resizeIndex = index;
      this.startX = event.clientX;
      this.startWidth = this.modifiedHeaders[index].width;

      document.addEventListener("mousemove", this.handleResize);
      document.addEventListener("mouseup", this.stopResizing);
    },
    getAlignment(type) {
      switch (type) {
        case CURRENCY_COLUMN:
        case FLOAT_COLUMN:
        case NUMBER_COLUMN:  
          return 'right';
        default:
          return 'left';
      }

    },
    formatValue(value, columnType) {
      if (columnType === "NUMBER") {
        return this.formatNumber(value);
      } else if (columnType === "FLOAT") {
        return this.formatFloat(value);
      } else if (columnType === "CURRENCY") {
        return this.formatCurrency(value);
      }
      return value; // Return the value as is if no formatting is needed
    },
    formatNumber(value) {
      return new Intl.NumberFormat('de-DE').format(value);
    },
    formatFloat(value) {
      return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
    },
    formatCurrency(value) {
      return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value);
    },    
    handleResize(event) {
      if (this.resizing) {
        const newWidth = this.startWidth + event.clientX - this.startX;
        if (newWidth > 20) { // Minimum column width
          this.$set(this.modifiedHeaders, this.resizeIndex, {
            ...this.modifiedHeaders[this.resizeIndex],
            width: newWidth,
          });
        }
      }
    },
    stopResizing() {
      this.resizing = false;
      this.resizeIndex = -1;
      this.startX = 0;
      this.startWidth = 0;

      document.removeEventListener("mousemove", this.handleResize);
      document.removeEventListener("mouseup", this.stopResizing);
    },
    handleRowSelection(event, row) {
      const isChecked = event.target.checked;
      const action = isChecked ? "added" : "removed";
      const payload = { action, row };
      row.selected = isChecked;
      this.$emit("selectedRow", payload);
    },
    toggleRowsSelection(event) {
      const isChecked = event.target.checked;
      this.rows.forEach(row => {
       this.$set(row, 'selected', isChecked);
      });
      this.$emit("selectedRows", this.rows);
    },
    restoreColumnsConfiguration(whatConfiguration) {
      const config = whatConfiguration[this.tableId];
      if (config?.content) {
        let columnsConfig = JSON.parse(config?.content);

        const fixedLeftColumns = this.modifiedHeaders.filter(mh => mh.position == FIXED_LEFT);
        const fixedRightColums = this.modifiedHeaders.filter(mh => mh.position == FIXED_RIGHT);
        const actionColumn =  this.modifiedHeaders.filter(mh => mh.type == ACTION_COLUMN);

        this.modifiedHeaders = [...fixedLeftColumns, ...columnsConfig, ...fixedRightColums, ...actionColumn];

        // console.log("Big table columns configuration called")
      }
    },
  },
  watch: {
    modifiedHeaders: {
      handler() {
        // Recalculate the table width when headers change
        this.tableWidth = this.modifiedHeaders.reduce((acc, header) => acc + header.width, 0);
      },
      deep: true,
    },
    initialPageSize: {
      handler(newValue) {
        this.pageSize = newValue;
      },
      deep: true,
    },
    storedColumnsConfig: {
      handler(newValue) {

        // console.log("Big table storedColumnsConfig watch called")
        this.restoreColumnsConfiguration(newValue);

      },
      deep: true,
    }    
  },

};
</script>

<style scoped>


.fixed-left-col {
  position: -webkit-sticky;
  position: sticky;
  background-color: var(--color-box);
  left: 0;
  z-index: 1;
}

.fixed-right-col {
  position: -webkit-sticky;
  position: sticky;
  background-color: var(--color-box);
  right: 0;
  z-index: 1;
}

.table-container::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 15px;
}

.table-container::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 4px solid var(--color-box); 
  background-color: var(--color-secondary-text);
}

.scroll-wrapper {
  overflow-x: auto;
  max-height: 500px; 
}


.table-container {
  overflow-x: visible;
  height: 500px;
  overflow-y: auto;
  position: relative;
  white-space: nowrap;
}

td, th {
  z-index: 0;
}

table {
  border-collapse: collapse;
  table-layout: fixed;
  border-spacing: 0px;
}

th,
td {
  border: 1px solid var(--color-box);
  padding: 8px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

th {
  position: relative;
}


.header-content {
  display: flex;
  align-items: center;
}

.header-title {
  cursor: pointer;
}

.resize-handle {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 10px;
  cursor: col-resize;
  background-color: var(--color-box-neutral);
}

/* Disable text selection while resizing */
.big-table .resizing {
  user-select: none;
}


.sticky__header {
  position: sticky;
  top: 0;
  background-color: var(--color-box);
  z-index: 2;
  margin-top: 0px;
  /* border-style: top right bottom left; */
  /* border-style: solid solid solid solid; */
}

.icon__cell {
  cursor: pointer;
}

</style>
