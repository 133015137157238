<template>
    <BaseModal ref="modal"
        @onCloseButton="close"
        :showDefaultButtons="true"
        :showConfirmButton="false"
       >
        <template v-slot:modalTitle>
            {{oldLoginName}} ändern (muss sich vom bisherigen LoginName unterscheiden)
        </template>
        <InputField label="Neuer Login Name" v-model="loginName"/>
        <template v-slot:footer>
            <BaseButton @click="changeLoginName" :disabled="!isValid" isPrimary>Login Name ändern</BaseButton>
        </template>
    </BaseModal>
</template>
<script>
import SSO_TYPES from '@/store/sso/types';
import { mapGetters } from 'vuex';

import BaseModal from '@/components/core/BaseModal.vue';
import InputField from '@/components/core/forms/InputField.vue';
import BaseButton from '@/components/core/BaseButton.vue'
import validator from '@/mixins/validator/index.js';
import { required, regex } from "@/mixins/validator/rules";

export default 
    {mixins: [validator],
    validators: {
        loginName: [required('Login Name ist erforderlich!'), regex(/.*[a-zA-Z].*/, "Der Name muss mindestens einen Buchstaben beinhalten")],
        // password: [required('Zum Ändern des Login Namen ist das Passwort erforderlich')]
    },
    components: {
        BaseModal,
        InputField,
        BaseButton,
    },
    emits: ['close'],
    computed: {
        ...mapGetters({
            oldLoginName: SSO_TYPES.GETTERS.LOGIN_NAME,
        }),
        isValid() {
            return this.oldLoginName !== this.loginName && !this.validation.isInvalid("loginName") && !this.validation.isInvalid("password");
        },
    },
    data() {
        return {
            loginName: this.oldLoginName || "",
            password: "",
            errorStr: "",
        };
    },
    mounted() {
        this.$refs.modal.open();
    },
    methods: {
        changeLoginName() {
            this.$store.dispatch(SSO_TYPES.ACTIONS.CHANGE_LOGIN_NAME, {
                loginName: this.loginName,
                password: this.password,
            }).then(response => {
                if (response?.data?.errorStr) {
                    if (response.data.loginName) {
                         this.$pushErrors('loginName', response.data.errorStr);
                    } else if (response.data.password) {
                         this.$pushErrors('password', response.data.errorStr);
                    } else {
                         this.errorStr = response.data.errorStr;
                    }
                } else {
                    this.$store.dispatch(SSO_TYPES.ACTIONS.GET_SSO_ACCOUNT_INFO);
                    this.close();
                }
            }).catch(error => {
                this.errorStr = "Der Login Name konnte nicht geändert werden. Vielleicht existiert der neue Login Name bereits.";
            });
        },
        close() {
            this.errorStr = null;
            this.$refs.modal.close();
            this.$emit('close');
        },
    },
}
</script>
<style scoped>

</style>