<template>
    <div class="login-container">
        <HeaderLogin/>
        <div data-app-content class="login-content" :style="{ backgroundImage: loginContentBackground }">
            <div class="login-box">
                <div class="login-logo-box">
                    <img :src="logoLoginBoxBackground"/>
                </div>
                <hr class="login-logo__bottom--spacer"/>
                <div class="login-logo__box-title">
                    {{loginWelcomeTitle}}
                </div>
                <form v-on:submit.prevent="register" class="login__form">
                    <InputField label="Loginkennung (Bitte wählen Sie hier Ihre persönliche Nutzerkennung)" v-model="form.loginName"/>
                    <InputField label="E-Mail-Adresse" v-model="form.email"/>
                    <InputField label="E-Mail-Adresse (Bestätigung)" v-model="form.email2"/>
                    <InputField label="Telefonnummer für Zwei-Faktor-Authentifizierung" v-model="form.phoneNumber" inputmode="tel"/>
                    <InputField label="Passwort (Mindestens 6 Zeichen)" type="password" v-model="form.password"/>
                    <InputField label="Passwort (Bestätigung)" type="password" v-model="form.password2"/>
                    <div class="fc-form-danger" v-if="form.password != form.password2" style="margin-top: -16px; padding-bottom: 16px">
                        Passwörter müssen gleich sein!
                    </div>
                    Verwenden Sie die Telefonnummer eines Geräts und eine E-Mail-Adresse, der Sie vertrauen. Sie können beide später ändern.<br>
                    Wenn Ihr gewähltes Telefon Zugriff auf das gewählte E-Mail-Postfach hat, reduziert das die Sicherheit des zweiten Faktors.<br>
                    Nachdem sie auf "Registrieren" geklickt haben, wird Ihnen zur Bestätigung je ein Sicherheitscode an die Telefonnummer und E-Mail-Adresse gesendet, die sie zur Aktivierung des Zugangs benötigen.<br>
                    <BaseButton type="submit" isPrimary class="mr-16px">Registrieren</BaseButton>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import CORE_TYPES from '@/store/core/types';
import SSO_TYPES from '@/store/sso/types';

import InputField from '@/components/core/forms/InputField.vue';
import BaseButton from '@/components/core/BaseButton.vue'
import HeaderLogin from '@/components/core/login/HeaderLogin.vue'

import validator from "@/mixins/validator";
import { mapGetters } from 'vuex';
import { required, email, telefon, minLength, regex } from '@/mixins/validator/rules';

import { SmartMSCAppLabel } from '@/helpers/app-helper';

export default {
    mixins: [validator],
    components: {
        InputField,
        BaseButton,
        HeaderLogin,
    },
    validators: {
        form: {
            loginName: [required(), regex(/.*[a-zA-Z].*/, "Der Name muss mindestens einen Buchstaben beinhalten")],
            email: [required(), email()],
            email2: [required(), email()],
            phoneNumber: [required(), telefon()],
            password: [required(), minLength(6, "Das Passwort muss mindestens eine Länge von 6 Zeichen haben.")],
            password2: [required(), minLength(6, "Das Passwort muss mindestens eine Länge von 6 Zeichen haben.")],
        }
    },
    data() {
        const email = this.$route.query.email || "";
        return {
            loginWelcomeTitle: SmartMSCAppLabel.WELCOME_TITLE,
            form: {
                loginName: "",
                email: email,
                email2: email,
                phoneNumber: "",
                password: "",
                password2: "",
            },
        };
    }, 
    computed: {
        ...mapGetters({
            apiAddressLegacy: CORE_TYPES.GETTERS.API_ADDRESS_LEGACY,
            imageBackgroundLoginUrl: CORE_TYPES.GETTERS.GET_BROKER_IMAGE_BACKGROUND_LOGIN_URL,
            maklerLogo: CORE_TYPES.GETTERS.GET_BROKER_LOGO,
            hasColorSchema: CORE_TYPES.GETTERS.HAS_COLOR_SCHEMA,
            isFA: CORE_TYPES.GETTERS.IS_FA,
            isFK: CORE_TYPES.GETTERS.IS_FK,
        }),
        loginContentBackground() {
            return this.imageBackgroundLoginUrl ? `url(${this.imageBackgroundLoginUrl})` : `url(${this.apiAddressLegacy}/../images/etc/layout/msc_wolken4.png)`
        },
        logoLoginBoxBackground() {
            return this.maklerLogo ? this.maklerLogo : `${this.apiAddressLegacy}/../images/etc/layout/FK20-FinanceCloud.png`;
        },
    },
    methods: {
        isValid() {
            return !this.validation.isInvalid("form") && this.form.email == this.form.email2 && this.form.password == this.form.password2;
        },
        register() {
            if (!this.isValid())
                return;
            this.$store.dispatch(SSO_TYPES.ACTIONS.REGISTER, this.form);
        },
    },
}
</script>

<style scoped>
.login-container {
    display: flex;
    align-items: center;
    min-height: 100vh;
    min-height: 100svh;
    min-height: 100dvh;

    flex-direction: column;
    justify-content: flex-start;
}

.login__form {
    display: flex;
    flex-direction: column;
    padding-top: 16px;
}

.login-box {
    max-width: 480px;
    background-color: var(--color-box);
    border-radius: 8px;
    margin: 16px 0;
    padding: 32px;
    max-height: 100%;
    overflow-y: auto;
}

.login-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 100;
    width: 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center center;
}

.login-logo-box, .login-logo-box img {
    max-height: 80px;
}

.login-logo__bottom--spacer {
    padding: 8px;
    border-top: solid 1px #C4C4C4;
}

.login-logo__box-title {
    font-size: 1.75rem;
    font-weight: 500;
    color: var(--color-primary)
}

@media (max-width: 576px) { 
    .login-content {
        background-image: none !important;
    }
}
</style>