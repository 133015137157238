<template>
  <div>
    <div :style="style">
      <v-chart ref="chart" class="chart" :option="option" :theme="themeKey" autoresize @legendselectchanged="legendSelectChanged($event)" />
    </div>

    <ul class="chart-series" v-if="isLegend && hasChartSeries">
      <li v-for="(serie, index) in chartLegend" :key="index"
        :class="{ 'hidden': mapHiddenSeries[serie.name], }"
      >
        <button type="button" class="btn-clear clickable"
          @mouseover="highlightSerie(serie, index)" 
          @mouseleave="downplaySerie(serie, index)" 
          @click="handleLegendClick(serie, index)">
          <span class="chart-serie-circle" :style="{ backgroundColor: getColorBySeriesIndex(index) }"></span>
          <span class="chart-serie-label">
            <strong v-if="toggleSerieState[serie.name] === 1">
              {{ serie.name }}
            </strong>
            <span v-else>
              {{ serie.name }}
            </span>
          </span>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart } from "echarts/charts";
import {
  TooltipComponent,
  LegendComponent,
  DataZoomComponent,
  ToolboxComponent,
  GridComponent,
} from "echarts/components";
import VChart, { INIT_OPTIONS_KEY } from "vue-echarts";
import echartsMixin from './echarts-mixin';
import dayjs from 'dayjs';

use([
  CanvasRenderer,
  LineChart,
  TooltipComponent,
  LegendComponent,
  DataZoomComponent,
  ToolboxComponent,
  GridComponent,
]);

export default {
  name: 'LineChart',
  mixins: [echartsMixin],
  components: {
    VChart
  },
  provide: {
    [INIT_OPTIONS_KEY]: { locale: 'DE' }
  },
  props: {
    chartData: {
      type: Array,
      default: () => []
    },
    chartSeries: {
      type: Array,
      default: () => [],
    },
    xLabels: {
      type: Array,
      default: () => [],
    },
    isArea: {
      type: Boolean,
      default: true,
    },
    gridHeight: {
      type: Number,
      default: null,
    },
    gridLeft: {
      type: Number,
      default: null
    },
    showDataZoom: {
      type: Boolean,
      default: false,
    },
    xAxisType: {
      type: String,
      default: 'time',
    },
    isSmallChart: {
      type: Boolean,
      default: false,
    },
    customTooltipFormatter: {
      type: Function
    },
    hiddenSeries: {
      type: Object
    },
    markAreaData: {
      type: Array,
      default: () => [],
    },
    markPointData: {
      type: Array,
      default: () => [],
    },
    chartOptions: {
      type: Object,
      default: () => {}
    },
    changeOption: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      mapHiddenSeries: {},
      externLegend: null,
      toggleSerieState: {},
    };
  },
  computed: {
    colors() {
      return this.customColors || this.themeColors;
    },
    chartLegend() {
      if (this.externLegend) {
        return this.externLegend;
      }
      return this.chartSeries;
    },
    option() {
      const option = {
        type: 'line',
        smooth: false,
        animation: false,
        symbol: 'none',
        ...(this.isArea ? { areaStyle: {} } : {}),
        lineStyle: {
          width: 1,
        },
        emphasis: {
          lineStyle: {
            width: 3
          }
        },
        markArea: {
          itemStyle: {
            borderWidth: 1,
          },
          label: {
            rotate: 90,
            padding: [4, 8, 2],
            borderRadius: 4,
          },
          data: this.markAreaData?.length > 0 ? [ ...this.markAreaData ] : [],
        },
        markPoint: {
          symbol: 'circle',
          symbolSize: 12,
          data: this.markPointData?.length > 0 ? [ ...this.markPointData ] : [],
        },
      };
      const series = (this.chartSeries?.length ? this.chartSeries.map(cs => ({
          ...cs,
          ...option,
        })) : [
          {
            data: this.chartData,
            ...option,
          }
        ]);

      const chartOptions = this.chartOptions || {};
      const result = {
        color: this.colors,
        backgroundColor: 'transparent',
        grid: chartOptions.grid ? chartOptions.grid :  {
          ...(this.isSmallChart ? { top:  0, } : {}),
          height: this.gridHeight || 'auto',
          ...(this.showDataZoom ? { bottom: '100px' } : { bottom: '30px'}),
          right: '16px',
          ...(this.gridLeft && { left: this.gridLeft })
        },
        animation: {
          duration: 0
        },
        xAxis: chartOptions.xAxis ? chartOptions.xAxis : {
          show: this.showXaxis,
          type: this.xAxisType,
          boundaryGap: false,
          data: this.xLabels,
          max: this.xAxisType === 'time' ? undefined : Math.max.apply(null, series.map(s => s.data.length - 1)),
          axisLabel: {
            fontSize: 10,
          },
        },
        yAxis: chartOptions.yAxis ? chartOptions.yAxis : {
          show: this.showYaxis,
          type: 'value',
          boundaryGap: false,
          axisLabel: {
            formatter: this.axisLabelFormatter(this.axisLabelFormatterShowOption),
            fontSize: 10,
          },
          min: function (value) {
            return value.min < 0 ? null : (value.min < 10 ? value.min * 0.9 : Math.floor(value.min - (value.min * .1)));
          },
        },
        ...( { legend: {
          show: false,
          bottom: this.showDataZoom ? '10%' : 0,
          ...(this.hiddenSeries && { selected: this.hiddenSeries}),
        }}),
        tooltip: chartOptions.tooltip ? chartOptions.tooltip : {
          show: this.isTooltip,
          trigger: 'axis',
          formatter: this.customTooltipFormatter || this.tooltipFormatter,
          position: { left: 'center', top: 'auto'},
        },
        ...(this.showDataZoom && {dataZoom: chartOptions.dataZoom ? chartOptions.dataZoom : [
          {
            type: 'inside',
            start: 0,
            end: 100
          },
          {
            start: 0,
            end: 100,
            labelFormatter: (value, valStr) => {
              if (this.xAxisType === 'time' && typeof value === 'number') {
                return dayjs(new Date(value)).format('DD.MM.YYYY')
              }
              return valStr;
            }
          }
        ]}),
        series: chartOptions.series ? chartOptions.series : series,
      }
      const finalOptions = this.changeOption ? this.changeOption(result) : result;
      return {
          ...finalOptions || {},
          backgroundColor: 'transparent',
      };
    },
    hasChartSeries() {
      return this.chartSeries?.length > 0;
    },
  },
  watch: {
    chartSeries() {
      this.$refs.chart.clear();
      this.$refs.chart.setOption(this.option, true);
    },
    hiddenSeries() {
      // Change flag
      const hiddenSeries = Object.keys(this.hiddenSeries)
        .reduce((acc, key) => ({
          ...acc,
          [key]: !this.hiddenSeries[key],
        }), {});

      this.mapHiddenSeries = {
        ...this.mapHiddenSeries,
        ...hiddenSeries,
      };
    },
  },
  methods: {
    getColorBySeriesIndex(seriesIndex) {
      const totalColors = this.colors.length;
      if(seriesIndex >= totalColors) {
        const multiplier = Math.floor(seriesIndex / totalColors);
        const colorIndex = seriesIndex - totalColors * multiplier;
        return this.colors[colorIndex];
      }
      return this.colors[seriesIndex];
    },
    handleToggle(serie) {
      this.$refs.chart.dispatchAction({
        type: 'legendToggleSelect',
        name: serie.name,
      });
    },
    highlightSerie(serie, seriesIndex) {
      this.$refs.chart.dispatchAction({
        type: 'highlight',
        name: serie.name,
        seriesIndex,
      });
    },
    handleLegendClick(serie, index) {
      this.toggleSerie(serie, index);
      this.highlightSerie(serie, index);
    },
    downplaySerie(serie, seriesIndex) {
      if (this.toggleSerieState[serie.name] !== 1) {
        this.$refs.chart.dispatchAction({
          type: 'downplay',
          name: serie.name,
          seriesIndex,
        });
      }
    },
    toggleSerie(serie, index) {
      switch (this.toggleSerieState[serie.name]) {
        case 1:
          this.toggleSerieState[serie.name] = 2;
          break;
        case 2:
          this.toggleSerieState[serie.name] = undefined;
          break;
        default:
          this.toggleSerieState[serie.name] = 1;
          break;
      }
      if (this.toggleSerieState[serie.name] === 1) {
        this.highlightSerie(serie, index);
      }
      if (this.toggleSerieState[serie.name] === 2) {
        this.handleToggle(serie);
      }
      if (!this.toggleSerieState[serie.name]) {
        this.handleToggle(serie);
      }
      this.toggleSerieState = {...this.toggleSerieState};
    },
    legendSelectChanged(event) {
      const { name, selected } = event;
      this.$set(this.mapHiddenSeries, name, !selected[name]);
    },
  },
};
</script>

<style scoped lang="scss">
.chart-series {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 12px 0 0;
  padding: 0;

  li {
    margin: 0 8px 8px;
    text-align: left;
    width: calc(50% - 32px);

    .btn-clear {
      display: flex;
      transition: color .5s ease;

      .chart-serie-circle {
        align-self: center;
        border-radius: 10px;
        margin: 0 6px 0 0;
        width: 12px;
        height: 12px;
        transition: opacity .5s ease;
        flex: 0 0 12px;
      }
    }

    &.hidden {
      .btn-clear {
        color: #ccc;

        .chart-serie-circle {
          opacity: 0.5;
        }
      }
    }
  }
}
</style>