<template>
  <div class="box__container-wrap">
    <template v-if="contactPerson">
      <div class="box__container-rows">
        <div class="row" v-if="contactPerson">
          <div class="col-8">
            <div>{{contactPerson.name}}</div>
            <div><br/></div>
            <div>{{contactPerson.street}}</div>
            <div>{{contactPerson.zip}} {{contactPerson.city}}</div>
            <div><br/></div>
            <div v-if="contactPerson.phone">Telefon: {{contactPerson.phone}}</div>
            <div v-if="contactPerson.mobile && contactPerson.isMobilePublic">Mobil: {{contactPerson.mobile}}</div>
            <div v-if="contactPerson.email && getEmail(contactPerson.email)">E-Mail: {{getEmail(contactPerson.email)}}</div>
            <div v-if="contactPerson.webPage"><a :href="contactPerson.webPage">{{ contactPerson.webPage }}</a></div>
            <div><br/></div>
          </div>
          <div class="col-4">
            <CustomerPicture class="profile-menu-burger__customer--picture" size="large" :image="customerImageUrl" />
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <BaseButton isLink @click="newTicket(); $emit('click')">Nachricht senden</BaseButton>
        <PhoneButton :showAsButton="false" @click="$emit('click')"/>
      </div>
    </template>
    <GhostLoading v-else-if="!contactPerson" type="table" />
    <NoData v-else noIcon />
  </div>
</template>

<script>
import CUSTOMERDATA_TYPES from '@/store/customerData/types'
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import CustomerPicture from '@/components/core/header/CustomerPicture.vue'
import { mapGetters } from 'vuex';
import BRIDGE_TYPES from '@/store/bridge/types';
import PhoneButton from '@/components/webrtc/PhoneButton.vue';

export default {
  components: {
    GhostLoading,
    NoData,
    BaseButton,
    CustomerPicture,
    PhoneButton,
  },
  computed: {
    ...mapGetters({
      contactPerson: CUSTOMERDATA_TYPES.GETTERS.GET_CUSTOMER_CONTACT_PERSON_DATA,
      isMobileNativeContext: BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT,
    }),
    customerImageUrl() {
      if (!this.contactPerson?.pictureURL) {
        return '';
      }
      var url = new URL(this.contactPerson.pictureURL);
      url.searchParams.append('maklerbild', 'true');
      return url.toString()
    },
  },
  mounted(){
    this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_CUSTOMER_CONTACT_PERSON_DATA);
  },
  methods: {
    newTicket() {
      this.$router.push({ name: 'new-ticket', params: { textInput: '' }})
    },
    getEmail(value) {
      const arr = value.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi)
      return arr && arr[0] || ''
    },
  }

}
</script>

<style lang="scss" scoped>
.col-none {
  display: none;
}
.customer-bankkonten__bottom {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  .customer-bankkonten__links {
    flex: 1 0 auto;
  }
}
@media (min-width: 992px) {
  .col-none {
    display: block;
  }
}

</style>