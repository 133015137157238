
import { ROLES, VIEW_ROLES } from '@/router/roles'

export default [
  {
    path: '',
    redirect: 'documentsoverview'
  },
  {
    path: 'documentsoverview/:navigationParams?',//(.*)*
    component: () => import(/* webpackChunkName: "documents" */ '@/components/core/BaseExplorer.vue'),
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_BROKER],
          [VIEW_ROLES.VIEW_CUSTOMER],
        ]
      },
      breadcrumb: {
        label: 'Dokumente',
      },
    },    
  },
  {
    path: 'zusaetzlichedokumente',
    component: () => import(/* webpackChunkName: "additional-documents" */ '@/views/documents/AdditionalDocuments.vue'),
    meta: {
      breadcrumb: {
        label: 'Zusätzliche Dokumente',
      },
    }
  },  
  {
    path: 'aenderungshistorie',
    component: () => import(/* webpackChunkName: "aenderungs-historie" */ '@/views/documents/Aenderungshistorie.vue'),
    meta: {
      breadcrumb: {
        label: 'Änderungshistorie',
      },
    },
  }, 
  {
    path: 'aenderungshistorie/aenderungslog',
    component: () => import(/* webpackChunkName: "aenderungs-log" */ '@/views/documents/Aenderungslog.vue'),
    meta: {
      breadcrumb: {
        label: 'Änderungslog',
      },
    },
  }, 
  {
    path: 'openSignView/:stornoId',
    component: () => import(/* webpackChunkName: "open-sign-view" */ '@/views/documents/OpenSignView.vue'),
  }, 
]