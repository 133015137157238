<template>
<div>
    <PageHeaderTitleNavigation 
        :defaultMenu="$appNavigation.currentTabMenu"
        title="GDV Datenimport"
                subtitle="Dateien in Bearbeitung" 
    />
    
    <BaseFilter
        title="Dateien in Bearbeitung"
        filterId="Dateien in Bearbeitung"
        :configFilter="filterConfig"
        :metadata="metadata"
        :defaultOptions="defaultOptions"
        showSaveButton
        :immidiateSearch="true"
        @onFilter="onFilter"
    >
        <BaseButton v-if="isIntern" @click="goToSettings" isSecondary >
            Einstellungen
        </BaseButton>
    </BaseFilter>

    <div class="box__container">
        <GhostLoading v-if="loading" type="table"/>
        <Table v-else-if="rows.length"
            tableId="fea3ca15-41a8-430f-85d2-d7dde32bg360"
            :headers="headers"
            :rows="rows"
            :rowsPerPage="30"
            :showColumnsConfigEvenNoTitle="true"
            rowId="index"
            @action-CONFIRM_ERRORS="confirmErrors"
            @action-CONFIRM="confirmInterface"
            @action-UNLOCK="unlock"
            @click-numRowsError="goToDatenimport"
            @click-status="goToDatenimport"
            @action-INFO="info"
        >
            <template v-slot:statusColumn="row">
                <PhCheck :size="16" :class="row.statusColor" v-if="row.statusIcon==='PhCheck'" /> 
                <PhX :size="16" :class="row.statusColor" v-if="row.statusIcon==='PhX'" /> 
                <PhArrowRight :size="16" :class="row.statusColor" v-if="row.statusIcon==='PhArrowRight'" /> 
            </template>
        </Table>
        <NoData v-else/>
    </div>
</div>
</template>

<script>
import CORE_TYPES from '@/store/core/types'
import LOG_TYPES from '@/store/log/types';

import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue'
import BaseFilter from "@/components/core/BaseFilter.vue";
import Table from "@/components/table2/Table.vue";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import BaseButton from '@/components/core/BaseButton.vue';

import {TextColumn, SlotColumn, Icon, DateTimeColumn, NumberColumn, ActionColumn, ConfirmedAction, SimpleAction, } from "@/components/table2/table_util.js";
import { mapGetters } from 'vuex';
import axios from 'axios';
import { buildMessage } from "@/helpers/log-message-helper";
import { DatePickerUtils } from '@/components/core/forms/DatePicker/date-picker-utils';
import {PhInfo, PhX, PhCheck, PhArrowRight} from 'phosphor-vue';

const CONFIG = {
    defaultSpinner: true,
};

export default {
    mixins: [],
    components: {
        PageHeaderTitleNavigation,
        OptionMenu,
        BaseFilter,
        Table,
        GhostLoading,
        NoData,
        BaseButton,
        PhX,
        PhCheck,
        PhArrowRight,
    },
    data() {
        return {
            loading: false,
            lines: [],
            filterConfig: {
                placeholderForDefSearchInput: 'Dateiname',
                checkDefaultSearchInput: false,
                cbDefSearchInput: null,
                hideFirstColumn: true,
                filterZurucksetzen: null,
                noResetOnDefaultSearchInputExit: true,
            },
            metadata: [
                {
                    type: 'group',
                    key: 'allgemein',
                    label: 'Allgemein',
                    menuItems: [
                        {
                            type: 'dateRange',
                            label: 'Zeitraum',
                            key: 'zeitraum',
                            emptyDenied: true,
                        },
                        {
                            type: 'text',
                            label: 'Gesellschaft',
                            key: 'gesellschaft',
                            emptyDenied: true,
                        },
                        {
                            type: 'text',
                            label: 'Interface',
                            key: 'interfaceName',
                            emptyDenied: true,
                        },
                        {
                            type: 'text',
                            label: 'Dateiname',
                            key: 'fileName',
                            emptyDenied: true,
                        },
                        {
                            type: 'text',
                            label: 'Dateinummer',
                            key: 'fileNumber',
                            emptyDenied: true,
                        },
                        {
                            type: 'default',
                            label: 'nur Dateien in aktueller Verarbeitung',
                            key: 'onlyCurrentlyProcessing',
                        },
                        {
                            type: 'default',
                            label: 'Dateien der Struktur anzeigen',
                            key: 'mitStruktur',
                        },                        
                    ],
                },
            ],
            defaultOptions: {
                zeitraum: {
                    value: [{
                        key: "min",
                        value: new Date(),
                    },
                    {
                        key: "max",
                        value: new Date(),
                    }],
                },
            },
            filterParams: {},
            headers: {
                lockedLeft: [
                    SlotColumn("statusColumn", ""),
                    TextColumn("status", "Status").makeLink().addCellProps({lineClamp: 4}),
                    TextColumn("fileName", "Dateiname", 300).addCellProps({lineClamp: 4}),
                    TextColumn("gesellschaft", "Gesellschaft").addCellProps({lineClamp: 4}),
                    TextColumn("interfaceName", "Interface"),
                ],
                center: [
                    DateTimeColumn("dateFrom", "Start"),
                    DateTimeColumn("dateTo", "Ende"),
                    NumberColumn("numRows", "Anzahl Datensätze", 0, 200),
                    NumberColumn("numRowsError", "Fehlerhafte Datensätze", 0, 200).makeLink(),
                    NumberColumn("numRowsWithoutError", "Fehlerfreie Datensätze", 0, 200),
                    NumberColumn("fileId", "Dateinr").addCellProps({separator: ''}),
                    TextColumn("userId", "UserId"),
                ],
                lockedRight: [
                    ActionColumn("actions"),
                ],
            },
        };
    },
    computed: {
        ...mapGetters({
            isIntern: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
        }),
        isImportMonitorEinstellungVisible() {
            return this.isIntern;
        },
        rows() {
            return this.lines.map((row, index) => {
                const actions = [];
                // normal actions
                if (row.hasUnlockAction)
                    actions.push(ConfirmedAction("UNLOCK", "PhLockSimpleOpen", "Datei entsperren", "Wollen Sie die Datei wirklich entsperren?\nDamit kann ein Einlesevorgang fehlerhaft werden!"));
                if (row.hasConfirmErrorsAction)
                    actions.push(ConfirmedAction("CONFIRM_ERRORS", "PhCheck", "Fehlerhafte Datensätze bestätigen",
                        row.numRowsError == 1 ? "Wollen Sie den einen fehlerhaften Datensatz wirklich bestätigen?"
                        : `Wollen Sie die ${row.numRowsError} fehlerhaften Datensätze wirklich bestätigen?`));
                // interface actions
                if (row.hasConfirmAction)
                    actions.push(ConfirmedAction("CONFIRM", "PhCheck", "Interface bestätigen", "Wollen Sie das geplante Interface wirklich bestätigen?"));

                if (row.hasImportResult) {
					actions.push(SimpleAction("INFO", PhInfo, "Datei Einlesen Resultat anzeigen"));					
				}          
                
                return {
                    index,
                    ...row,
                    status: row.status || row.statusErrors,
                    statusIcon: row?.iconMetadata?.icon,
                    statusColor: row?.iconMetadata?.color,
                    actions,
                };
            });
        },
    },
    methods: {
        onFilter(filterConfig) {
            this.filterParams = {};

            filterConfig.forEach(fc => {
                let value = fc.value;
                if (fc.key == 'onlyCurrentlyProcessing')
                    value = true; // the filters with above keys have no input field and should only be considered as boolean (true if added to the filter)
                if (fc.key == "zeitraum") {
                    this.filterParams.dateFrom = DatePickerUtils.toDateStr(value.find(v => v.key == 'min').value);
                    this.filterParams.dateTo = DatePickerUtils.toDateStr(value.find(v => v.key == 'max').value);
                } else {
                    this.filterParams[fc.key] = value;
                }
            });
            this.loadRows();
        },
        async loadRows() {
            this.loading = true;
            try {
                const response = await axios.post(`${process.env.VUE_APP_API}/gdv_import_monitor/filter`, this.filterParams, CONFIG)
                this.lines = response.data || [];
            } catch (_) {
                this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Bei der Suche ist ein Fehler aufgetreten.', 'danger'));
            } finally {
                this.loading = false;
            }
        },
        async confirmErrors(row) {
            let success = false;
            try {
                const params = {
                    fileName: row.fileName,
                    dateFrom: row.dateFrom,
                    fileId: row.fileId,
                };
                const response = await axios.post(`${process.env.VUE_APP_API}/gdv_import_monitor/confirm_read_errors`, params, CONFIG)
                success = response.status == 200;
            } finally {
                if (success) {
                    this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage("Einlesefehler für die Datei '" + row.fileName + "' vom " + row.dateFrom + " wurden bestätigt.", 'success'));
                } else {
                    this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage("Fehler: Einlesefehler für die Datei '" + row.fileName + "' vom " + row.dateFrom + " wurden nicht bestätigt.", 'danger'));
                }
                this.loadRows();
            }
        },
        async confirmInterface(row) {
            let success = false;
            try {
                const params = {
                    interfaceId: row.interfaceId,
                    dateFrom: row.dateFrom,
                };
                const response = await axios.post(`${process.env.VUE_APP_API}/gdv_import_monitor/confirm_interface`, params, CONFIG)
                success = response.status == 200;
            } finally {
                if (success) {
                    this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage("Geplantes nicht gelaufenes Interface " + row.interfaceName + " wurde bestätigt.", 'success'));
                } else {
                    this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage("Fehler: Geplantes nicht gelaufenes Interface " + row.interfaceName + " wurde nicht bestätigt!", 'danger'));
                }
                this.loadRows();
            }
        },
        async unlock(row) {
            try {
                const params = {
                    fileId: row.fileId,
                };
                const response = await axios.post(`${process.env.VUE_APP_API}/gdv_import_monitor/unlock_file`, params, CONFIG)
            } finally {
                this.loadRows();
            }
        },
        goToSettings() {
            this.$router.push({path: `/intern/gdv/gdv-import-monitor/settings`});
        },
        goToDatenimport(row) {
            this.$router.push({path: `/intern/gdv/import`, query: {filename: row?.fileName, showFile: true}});
            
        },
        info(row) {
            // navigate to "Datenimport" with the row's data prefilled
            this.$router.push({path: "/intern/gdv/import", query: {filename: row.filename}});
        },	        
    },
    beforeRouteLeave(to, from, next) {
        this.$addBreadcrumb({ to, from, });
        next();
    },
}
</script>
