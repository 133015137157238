<template>
    <div class="container" ref="container" :key="messageListReverse.length">
      <div v-for="message in messageListReverse" :key="message.messageId">
        <div class="message" :class="[
      message.direction == sending ? 'chat-sender' : 'chat-receiver',
    ]">
          <div class="message-body" v-if="showMessageBody(message)">
            <span class="message-text" v-html="message.messageText"></span>

            <div class="message-real-download-link__container d-none">
              <!-- This DownloadLink is linked to the message text img click -->
              <DownloadLink v-for="imgAttachment in message.imgAttachments" :key="imgAttachment.id" asButton
                title="Anhang" downloadServicePath="/getChatAtt" :filename="imgAttachment.name" :queryParams="{
      id: imgAttachment.id,
    }" :data-img-attachment-id="imgAttachment.id">
                <img :src="setImgSrc(imgAttachment, message.direction)" />
              </DownloadLink>
            </div>

            <div v-for="externalAttachment in message.externalAttachments" :key="externalAttachment.id">
              <DownloadLink asButton class="d-flex px-0" title="Anhang" downloadServicePath="/getChatAtt"
                :filename="externalAttachment.name" :queryParams="{
      id: externalAttachment.id,
    }">
                <ph-paperclip :size="18" class="mr-8px cursor-pointer" />
                {{ externalAttachment.name }}
              </DownloadLink>

              <div class="spreadsheet__container" v-if="spreadsheetContent[externalAttachment.id]">
                <Table tableId="f5efcb61-1f0a-4317-90a8-841b2f2b7f5e" :scrollHorizontally="true"
                  :headers="spreadsheetContent[externalAttachment.id].headers"
                  :rows="spreadsheetContent[externalAttachment.id].rows" />
              </div>
              <div v-else-if="externalAttachment.renderSpreadsheet">
                <AnimatedSpinner />
              </div>
            </div>
          </div>

          <div class="chat-delete cursor-pointer" v-if="message.isDeletionAllowed">
            <ph-trash :size="18" @click="openDeleteMessageModal(message.messageId)" />
          </div>
        </div>

        <div class="sent-by" :style="{
      justifyContent: message.direction == sending ? 'flex-end' : 'start',
    }">
          <div class="chat-info" v-if="message.direction == sending">
            {{ message.date }} | {{ message.fromName }} |
            <ph-check :size="18" v-if="!message.isGelesen" />
            <ph-checks :size="18" v-else />
          </div>
          <div class="chat-info" v-else>
            {{ message.date }} | {{ message.fromName }}
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import WEBRTC_TYPES from "@/store/webrtc/types";
import COMMUNICATION_TYPES from "@/store/communication/types";
import LOG_TYPES from "@/store/log/types";
import { PhTrash, PhCheck, PhChecks, PhPaperclip } from "phosphor-vue";
import sanitizeHtml from "sanitize-html";
import DownloadLink from "@/components/core/download/DownloadLink.vue";
import CORE_TYPES from "@/store/core/types";
import axios from "axios";
import { TextColumn, NumberColumn } from "@/components/table2/table_util.js";
import AnimatedSpinner from "@/components/core/AnimatedSpinner.vue";
import { getColorLimitedListBasedOnLightingDecreasing } from "@/helpers/colors-helper";

export default {
  components: {
    PhTrash,
    PhPaperclip,
    PhCheck,
    PhChecks,
    DownloadLink,
    AnimatedSpinner,
  },
  props: {
    chatId: {
      type: [Number, String],
    },
    channelId: {
      type: [Number, String],
    },
    emailId: {
      type: [Number, String],
    },
  },
  data() {
    return {
      sending: "SENDING",
      receiving: "RECEIVING",
      spreadsheetContent: {},
    };
  },
  computed: {
    ...mapGetters({
      chatInfos: WEBRTC_TYPES.GETTERS.CHAT_INFO,
      sanitizeHtmlConfig: COMMUNICATION_TYPES.GETTERS.SANITIZE_HTML_CONFIG,
      apiAddress: CORE_TYPES.GETTERS.API_ADDRESS,
    }),
    chatInfo() {
      return (
        this.chatInfos &&
        this.chatInfos.find(
          (chatInfo) =>
            chatInfo.chatId == this.chatId &&
            chatInfo.channelId == this.channelId &&
            (!this.emailId || chatInfo.emailId == this.emailId)
        )
      );
    },
    messageList() {
      return this.chatInfo?.messageList
        ?.map(this.mapUrlImagesIntoAttachments)
        ?.map(this.mapExternalUrlImagesIntoProxy);
    },
    /**
     * Message list reversed to take some advantage on flex container column-reverse
     * This way the browser keeps scroll to bottom on every message list update.
     */
    messageListReverse() {
      if (!this.messageList) {
        return [];
      }
      return [...this.messageList].reverse();
    },
  },
  watch: {
    messageList: {
      handler(newValue) {
        this.checkAndLoadSpreadsheetAttachments(newValue);
        this.$nextTick(() => { 
          this.addOpenImgAttachmentEvent()
          this.setRgbSenderBackgroundColor()
        });
      },
      immediate: true,
    },
  },
  mounted() {
    this.$nextTick(() => this.setRgbSenderBackgroundColor());
  },
  methods: {
    setRgbSenderBackgroundColor() {
      const hexColor = document.documentElement.style.getPropertyValue(
        "--color-primary"
      );
      const colors = getColorLimitedListBasedOnLightingDecreasing(hexColor, {
        quantity: 1,
        fromStepsHigher: 4,
        useChartColorPreset: false,
      });

      if (this.$refs.container) this.$refs.container.style.setProperty('--color-background-chat-message', colors?.[0]);
    },
    mapExternalUrlImagesIntoProxy(message) {
      let messageText = message?.messageText;

      if (messageText) {
        try {
          const pattern = /\src\=(?:\"|\')(.+?)(?:\"|\')(?:.+?)/gi;
          messageText = messageText.replaceAll(
            pattern,
            `src="${this.apiAddress}/proxy/content?url=$1"`
          );
        } catch (e) {
          this.$store.dispatch(LOG_TYPES.ACTIONS.LOG, e);
        }
      }

      return {
        ...message,
        messageText,
      };
    },
    mapUrlImagesIntoAttachments(message) {
      let messageText = message?.messageText;
      try {
        if (message?.imgAttachments?.length > 0) {
          message?.imgAttachments?.forEach((imgAttachment) => {
            if (imgAttachment.imageData) {
              const pattern = new RegExp(
                `soscomp://chat-attachment/${imgAttachment.id}`,
                "gi"
              );
              messageText = messageText.replaceAll(
                pattern,
                imgAttachment.imageData
              );
            } else {
              messageText = messageText.replaceAll(
                /soscomp:\/\/chat-attachment\/\d+/gi,
                ""
              );
            }
          });
        }
      } catch (e) {
        this.$store.dispatch(LOG_TYPES.ACTIONS.LOG, e);
      }

      return {
        ...message,
        messageText,
      };
    },
    checkAndLoadSpreadsheetAttachments(messageList) {
      if (!messageList?.length) {
        return;
      }

      const attIdsToLoad = messageList
        .map((message) => message.externalAttachments)
        .flatMap((att) => att)
        .filter(
          (att) => att.renderSpreadsheet && !this.spreadsheetContent[att.id]
        )
        .map((att) => att.id);

      attIdsToLoad.forEach(this.loadSpreadsheetContent);
    },
    addOpenImgAttachmentEvent() {
      const imgEls = this.$el?.querySelectorAll?.(".message-text img");

      if (!imgEls?.length) return;

      imgEls.forEach((imgEl) => {
        imgEl.setAttribute("title", "Anhang");
        imgEl.removeEventListener("click", this.openImgAttachment);
        imgEl.addEventListener("click", this.openImgAttachment);
      });
    },
    openImgAttachment(event) {
      const imgEl = event.target;
      if (!imgEl?.src) return;

      const { messageList } = this;
      const message = messageList?.find((message) =>
        message.imgAttachments?.some(
          (imgAttachment) => imgAttachment.imageData === imgEl.src
        )
      );
      const imgAttachment = message?.imgAttachments?.find(
        (imgAttachment) => imgAttachment.imageData === imgEl.src
      );
      if (imgAttachment) {
        document
          .querySelector(`[data-img-attachment-id="${imgAttachment.id}"]`)
          .click();
      }
    },
    loadSpreadsheetContent(chatMessage) {
      const columns = {
        NumberColumn: (h) => NumberColumn(h.key, h.label, 2),
        TextColumn: (h) => TextColumn(h.key, h.label),
      };

      axios
        .get(
          `${this.apiAddress}/spreadsheet_service/chat_attachment_spreadsheet_as_table?chatMessage=${chatMessage}`
        )
        .then((response) => {
          if (response.data) {
            const data = {
              headers: {
                center: response.data.headers.map((h) => columns[h.type]?.(h)),
              },
              rows: response.data.rows.slice(0, 20),
            };

            this.$set(this.spreadsheetContent, chatMessage, data);
          }
        });
    },
    showMessageBody(message) {
      return !!(
        this.getMessageWithoutBody(message.messageText) ||
        message.imgAttachments?.length ||
        message.externalAttachments?.length
      );
    },
    setImgSrc(attachment, direction) {
      if (attachment.imageData != null)
        // WEBRTC_TYPES.ACTIONS.LOAD_ATTACHMENT will set this to an empty string while the loading hasn't completed to prevent duplicate requests
        return attachment.imageData;
      this.$store.dispatch(LOG_TYPES.ACTIONS.INFO, {
        message: "setImgSrc" + direction,
        attachment,
      });
      this.$store.dispatch(WEBRTC_TYPES.ACTIONS.LOAD_ATTACHMENT, attachment.id);
      return "";
    },
    getMessageWithoutBody(message) {
      if (!message) return "";
      return sanitizeHtml(message, this.sanitizeHtmlConfig);
    },
    onOpenFile(id, name) {
      this.$store.dispatch(WEBRTC_TYPES.ACTIONS.OPEN_FILE, {
        attId: id,
        filename: name,
      });
    },
    openDeleteMessageModal(deleteMessageId) {
      this.$confirmModal({
        title: "Nachricht Löschen",
        message: "Möchten Sie diese Nachricht wirklich löschen?",
        labelButtonConfirm: "Löschen",
        showCloseButton: false,
      }).then(() => {
        this.deleteMessage(deleteMessageId);
      });
    },
    deleteMessage(deleteMessageId) {
      this.$store.dispatch(WEBRTC_TYPES.ACTIONS.DELETE_MESSAGE, {
        chatId: this.chatId,
        channelId: this.channelId,
        messageId: deleteMessageId,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  max-height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
}

@media (max-width: 767px) {
  .container {
    padding-right: 8px; //padding for scrollbar on small devices
  }
}

.chat-sender .message-body {
  background-color: var(--color-background-chat-message);
}





.message {
  display: flex;
  align-items: center;
}

.message-body {
  margin: 0.5rem;
  padding: 0.25rem;
  max-width: 70%;
  border-radius: 4px;
  border: solid;
  border-color: transparent transparent var(--color-box) transparent;
}

.message-body img {
  max-width: 100%;
}

.message-body p {
  min-height: 0.1px;
}

.chat-info {
  font-size: small;
}

.chat-sender {
  justify-content: flex-end;
}

.chat-sender {
  .message-body {
    border-width: 0 8px 8px 0;
    padding-left: 8px;
    color: var(--color-text-neutral);
  }

  a.button {
    background-color: unset;
    border: unset;
    color: var(--color-text);
  }
}

.chat-receiver {
  .message-body {
    border-width: 0 0 8px 8px;
    background-color: var(--color-box-neutral);
    padding-right: 8px;
    color: var(--color-text);
  }

  a.button {
    border-color: var(--color-background);
    background-color: var(--color-background);
    color: var(--color-text);
  }
}

@media (prefers-color-scheme: dark) {
  .chat-receiver .message-body {
    background-color: var(--color-box-neutral);
  }

  .chat-sender .message-body {
    color: var(--color-text-neutral);
  }
}

.sent-by {
  display: flex;
}

.spreadsheet__container {
  color: var(--color-text);
  background: var(--color-background);
  overflow: hidden;
  padding: 1rem;
}

.cursor-pointer {
  cursor: pointer;
}

// @media (min-width: 1767px) {
//   .chat-sender {
//     justify-content: flex-end;
//   }
//   .chat-receiver {
//     justify-content: flex-end;
//   }
// }</style>

<!-- GLOBAL STYLE -->
<style>
.message-text img {
  cursor: pointer;
}

.message-text p {
  padding: 0;
  margin: 0;
}
</style>
