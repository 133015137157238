var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.vertragsdatenFieldsDefinition
    ? _c("div", [
        _c(
          "div",
          { staticStyle: { display: "flex", "flex-direction": "column" } },
          [
            _vm.vertragsdatenFieldsDefinition.pramieBruttoJahr.visible
              ? _c(
                  "div",
                  {
                    staticClass: "row",
                    style: _vm.styleOrder("pramieBruttoJahr")
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-6" },
                      [
                        _c("InputField", {
                          attrs: {
                            type: "currency",
                            precision: 2,
                            label: "Prämie Brutto (Jahr)",
                            disabled:
                              _vm.vertragsdatenFieldsDefinition.pramieBruttoJahr
                                .disabled
                          },
                          model: {
                            value: _vm.pramieBruttoJahr,
                            callback: function($$v) {
                              _vm.pramieBruttoJahr = $$v
                            },
                            expression: "pramieBruttoJahr"
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              : _vm._e(),
            _vm.vertragsdatenFieldsDefinition.vertragssumme.visible
              ? _c(
                  "div",
                  {
                    staticClass: "row",
                    style: _vm.styleOrder("vertragssumme")
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-6" },
                      [
                        _c("InputField", {
                          attrs: {
                            type: "currency",
                            precision: 2,
                            label: "Vertragssumme",
                            disabled:
                              _vm.vertragsdatenFieldsDefinition.vertragssumme
                                .disabled
                          },
                          on: {
                            input: function($event) {
                              return _vm.dataChanged()
                            }
                          },
                          model: {
                            value: _vm.versicherung.vertragssumme,
                            callback: function($$v) {
                              _vm.$set(_vm.versicherung, "vertragssumme", $$v)
                            },
                            expression: "versicherung.vertragssumme"
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              : _vm._e(),
            _vm.vertragsdatenFieldsDefinition.pramieNetto.visible
              ? _c(
                  "div",
                  { staticClass: "row", style: _vm.styleOrder("pramieNetto") },
                  [
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-6" },
                      [
                        _c("InputField", {
                          attrs: {
                            type: "currency",
                            precision: 2,
                            label: "Prämie Netto",
                            disabled:
                              _vm.vertragsdatenFieldsDefinition.pramieNetto
                                .disabled
                          },
                          on: {
                            input: function($event) {
                              return _vm.dataChanged()
                            }
                          },
                          model: {
                            value: _vm.versicherung.praemiento_zahlweise,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.versicherung,
                                "praemiento_zahlweise",
                                $$v
                              )
                            },
                            expression: "versicherung.praemiento_zahlweise"
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              : _vm._e(),
            _vm.vertragsdatenFieldsDefinition.pramieNettoJahr.visible
              ? _c(
                  "div",
                  {
                    staticClass: "row",
                    style: _vm.styleOrder("pramieNettoJahr")
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-6" },
                      [
                        _c("InputField", {
                          attrs: {
                            type: "currency",
                            precision: 2,
                            label: "Prämie Netto (Jahr)",
                            disabled:
                              _vm.vertragsdatenFieldsDefinition.pramieNettoJahr
                                .disabled
                          },
                          model: {
                            value: _vm.pramieNettoJahr,
                            callback: function($$v) {
                              _vm.pramieNettoJahr = $$v
                            },
                            expression: "pramieNettoJahr"
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              : _vm._e(),
            _vm.vertragsdatenFieldsDefinition.zahlungsart.visible
              ? _c(
                  "div",
                  { staticClass: "row", style: _vm.styleOrder("zahlungsart") },
                  [
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-6" },
                      [
                        _c("ComboBox", {
                          attrs: {
                            label: "Zahlungsart",
                            firstEmpty: true,
                            values:
                              _vm.vertragsdatenFieldsDefinition.zahlungsart
                                .value,
                            disabled:
                              _vm.vertragsdatenFieldsDefinition.zahlungsart
                                .disabled
                          },
                          on: {
                            change: function($event) {
                              return _vm.dataChanged("zahlungsart", $event)
                            }
                          },
                          model: {
                            value: _vm.versicherung.zahlungsart,
                            callback: function($$v) {
                              _vm.$set(_vm.versicherung, "zahlungsart", $$v)
                            },
                            expression: "versicherung.zahlungsart"
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "row", style: _vm.styleOrder("bankverbindung") },
              [
                _c(
                  "div",
                  { staticClass: "col-12 col-sm-6" },
                  [
                    _c("EntitySelector", {
                      attrs: {
                        label: "Bankverbindung",
                        values:
                          _vm.vertragsdatenFieldsDefinition.bankverbindung.value
                      },
                      on: {
                        change: function($event) {
                          return _vm.dataChanged("bankverbindung", $event)
                        },
                        new: function($event) {
                          return _vm.newBankverbindung()
                        },
                        edit: function($event) {
                          return _vm.editBankverbindung()
                        }
                      },
                      model: {
                        value: _vm.versicherung.bankverbindung,
                        callback: function($$v) {
                          _vm.$set(_vm.versicherung, "bankverbindung", $$v)
                        },
                        expression: "versicherung.bankverbindung"
                      }
                    })
                  ],
                  1
                )
              ]
            ),
            _vm.vertragsdatenFieldsDefinition.letzteAnderung.visible
              ? _c(
                  "div",
                  {
                    staticClass: "row",
                    style: _vm.styleOrder("letzteAnderung")
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-6" },
                      [
                        _c("InputField", {
                          attrs: {
                            label: "Letzte Änderung",
                            disabled:
                              _vm.vertragsdatenFieldsDefinition.letzteAnderung
                                .disabled
                          },
                          on: {
                            input: function($event) {
                              return _vm.dataChanged()
                            }
                          },
                          model: {
                            value: _vm.versicherung.letzteAnderung,
                            callback: function($$v) {
                              _vm.$set(_vm.versicherung, "letzteAnderung", $$v)
                            },
                            expression: "versicherung.letzteAnderung"
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              : _vm._e(),
            _vm.vertragsdatenFieldsDefinition.letzteGDVAnderung.visible
              ? _c(
                  "div",
                  {
                    staticClass: "row",
                    style: _vm.styleOrder("letzteGDVAnderung")
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-6" },
                      [
                        _c("InputField", {
                          attrs: {
                            label:
                              "Letzte " +
                              (_vm.isFA ? "OMDS" : "GDV") +
                              " Änderung",
                            disabled:
                              _vm.vertragsdatenFieldsDefinition
                                .letzteGDVAnderung.disabled
                          },
                          on: {
                            input: function($event) {
                              return _vm.dataChanged()
                            }
                          },
                          model: {
                            value: _vm.versicherung.dateUpdatetdGDV,
                            callback: function($$v) {
                              _vm.$set(_vm.versicherung, "dateUpdatetdGDV", $$v)
                            },
                            expression: "versicherung.dateUpdatetdGDV"
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              : _vm._e(),
            _vm.vertragsdatenFieldsDefinition.autoUpdate.visible
              ? _c(
                  "div",
                  { staticClass: "row", style: _vm.styleOrder("autoUpdate") },
                  [
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-6" },
                      [
                        _c("InputToggleSwitch", {
                          attrs: {
                            label:
                              _vm.vertragsdatenFieldsDefinition.autoUpdate
                                .value,
                            disabled:
                              _vm.vertragsdatenFieldsDefinition.autoUpdate
                                .disabled
                          },
                          on: {
                            input: function($event) {
                              return _vm.dataChanged()
                            }
                          },
                          model: {
                            value: _vm.versicherung.einlesen_updaten,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.versicherung,
                                "einlesen_updaten",
                                $$v
                              )
                            },
                            expression: "versicherung.einlesen_updaten"
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              : _vm._e(),
            _vm.vertragsdatenFieldsDefinition.externeKennung.visible
              ? _c(
                  "div",
                  {
                    staticClass: "row",
                    style: _vm.styleOrder("externeKennung")
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-6" },
                      [
                        _c("InputField", {
                          attrs: {
                            label: "Ext. Kennung",
                            disabled:
                              _vm.vertragsdatenFieldsDefinition.externeKennung
                                .disabled
                          },
                          on: {
                            input: function($event) {
                              return _vm.dataChanged()
                            }
                          },
                          model: {
                            value: _vm.versicherung.externeKennung,
                            callback: function($$v) {
                              _vm.$set(_vm.versicherung, "externeKennung", $$v)
                            },
                            expression: "versicherung.externeKennung"
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              : _vm._e(),
            _vm.vertragsdatenFieldsDefinition.erfassungsdatum.visible
              ? _c(
                  "div",
                  {
                    staticClass: "row",
                    style: _vm.styleOrder("erfassungsdatum")
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-6" },
                      [
                        _c("DatePickerField", {
                          attrs: {
                            label: "Erfassungsdatum",
                            disabled:
                              !_vm.isNewVertrag ||
                              _vm.vertragsdatenFieldsDefinition.erfassungsdatum
                                .disabled
                          },
                          on: {
                            input: function($event) {
                              return _vm.dataChanged()
                            }
                          },
                          model: {
                            value: _vm.versicherung.erfassungsdatum,
                            callback: function($$v) {
                              _vm.$set(_vm.versicherung, "erfassungsdatum", $$v)
                            },
                            expression: "versicherung.erfassungsdatum"
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              : _vm._e()
          ]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }