import V_P from './types';
import Vue from 'vue'
import { getInitialState } from './index'

export default {
  [V_P.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState())
  },
  [V_P.MUTATIONS.SETUP](state, payload) {
    state.SETUP = payload
  },
  [V_P.MUTATIONS.FILTER](state, payload) {

    // when loading the first page we reset all other pages
    if (payload.page === 0) {
      state.FILTER = {[payload.page]: payload.records}
    } else {
      Vue.set(state.FILTER, payload.page, payload.records);
    }
    
    state.rowCount = payload.rowCount
  },
  [V_P.MUTATIONS.COMMUNICATIONS](state, payload) {
    state.COMMUNICATIONS = payload
  },
  [V_P.MUTATIONS.VP_BEMERKUNG](state, payload) {
    state.VP_BEMERKUNG = payload
  },
  [V_P.MUTATIONS.TRACE](state, payload) {
    state.TRACE = payload
  },
  [V_P.MUTATIONS.QUELLE](state, payload) {
    state.QUELLE = payload
  }, 
  [V_P.MUTATIONS.LABEL](state, payload) {
    state.LABEL = payload
  },
  [V_P.MUTATIONS.RELOAD](state, payload) {
    state.RELOAD = payload
  },
  [V_P.MUTATIONS.EINGANG](state, payload) {
    state.EINGANG = payload
  },
  [V_P.MUTATIONS.BARCODES_COMBO](state, payload) {
    state.BARCODES_COMBO = payload
  },
  [V_P.MUTATIONS.EDIT_GESEL_PAGE](state, payload) {
    state.EDIT_GESEL_PAGE = payload
  },
  [V_P.MUTATIONS.ERROR_EDIT](state, payload) {
    state.ERROR_EDIT = payload
  },
  [V_P.MUTATIONS.EDIT_GESEL_PAGE_CONFIG](state, payload) {
    state.EDIT_GESEL_PAGE_CONFIG = payload
  },
  [V_P.MUTATIONS.GESELL_KOMM_COMBO](state, payload) {
    state.GESELL_KOMM_COMBO = payload
  },
  [V_P.MUTATIONS.EDIT_KUNDEN_PAGE_CONFIG](state, payload) {
    state.EDIT_KUNDEN_PAGE_CONFIG = payload
  }, 
  [V_P.MUTATIONS.PERSONEN_COMBO](state, payload) {
    state.PERSONEN_COMBO = payload
  },
  [V_P.MUTATIONS.PAGES_ACTIVE](state, payload) {
    state.PAGES_ACTIVE = payload
  },
  [V_P.MUTATIONS.MAPPE_COMBO](state, payload) {
    state.MAPPE_COMBO = payload
  },
  [V_P.MUTATIONS.MAPPE_TABLE](state, payload) {
    state.MAPPE_TABLE = payload
  },
}