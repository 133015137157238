var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-container" },
    [
      _c("HeaderLogin"),
      _c(
        "div",
        {
          staticClass: "login-content",
          style: { backgroundImage: _vm.loginContentBackground },
          attrs: { "data-app-content": "" }
        },
        [
          _c("div", { staticClass: "login-box" }, [
            _c("div", { staticClass: "login-logo-box" }, [
              _c("img", { attrs: { src: _vm.logoLoginBoxBackground } })
            ]),
            _c("hr", { staticClass: "login-logo__bottom--spacer" }),
            _c("div", { staticClass: "login-logo__box-title" }, [
              _vm._v(" " + _vm._s(_vm.loginWelcomeTitle) + " ")
            ]),
            _c(
              "form",
              {
                staticClass: "login__form",
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.register.apply(null, arguments)
                  }
                }
              },
              [
                _c("InputField", {
                  attrs: {
                    label:
                      "Loginkennung (Bitte wählen Sie hier Ihre persönliche Nutzerkennung)"
                  },
                  model: {
                    value: _vm.form.loginName,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "loginName", $$v)
                    },
                    expression: "form.loginName"
                  }
                }),
                _c("InputField", {
                  attrs: { label: "E-Mail-Adresse" },
                  model: {
                    value: _vm.form.email,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "email", $$v)
                    },
                    expression: "form.email"
                  }
                }),
                _c("InputField", {
                  attrs: { label: "E-Mail-Adresse (Bestätigung)" },
                  model: {
                    value: _vm.form.email2,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "email2", $$v)
                    },
                    expression: "form.email2"
                  }
                }),
                _c("InputField", {
                  attrs: {
                    label: "Telefonnummer für Zwei-Faktor-Authentifizierung",
                    inputmode: "tel"
                  },
                  model: {
                    value: _vm.form.phoneNumber,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "phoneNumber", $$v)
                    },
                    expression: "form.phoneNumber"
                  }
                }),
                _c("InputField", {
                  attrs: {
                    label: "Passwort (Mindestens 6 Zeichen)",
                    type: "password"
                  },
                  model: {
                    value: _vm.form.password,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "password", $$v)
                    },
                    expression: "form.password"
                  }
                }),
                _c("InputField", {
                  attrs: { label: "Passwort (Bestätigung)", type: "password" },
                  model: {
                    value: _vm.form.password2,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "password2", $$v)
                    },
                    expression: "form.password2"
                  }
                }),
                _vm.form.password != _vm.form.password2
                  ? _c(
                      "div",
                      {
                        staticClass: "fc-form-danger",
                        staticStyle: {
                          "margin-top": "-16px",
                          "padding-bottom": "16px"
                        }
                      },
                      [_vm._v(" Passwörter müssen gleich sein! ")]
                    )
                  : _vm._e(),
                _vm._v(
                  " Verwenden Sie die Telefonnummer eines Geräts und eine E-Mail-Adresse, der Sie vertrauen. Sie können beide später ändern."
                ),
                _c("br"),
                _vm._v(
                  " Wenn Ihr gewähltes Telefon Zugriff auf das gewählte E-Mail-Postfach hat, reduziert das die Sicherheit des zweiten Faktors."
                ),
                _c("br"),
                _vm._v(
                  ' Nachdem sie auf "Registrieren" geklickt haben, wird Ihnen zur Bestätigung je ein Sicherheitscode an die Telefonnummer und E-Mail-Adresse gesendet, die sie zur Aktivierung des Zugangs benötigen.'
                ),
                _c("br"),
                _c(
                  "BaseButton",
                  {
                    staticClass: "mr-16px",
                    attrs: { type: "submit", isPrimary: "" }
                  },
                  [_vm._v("Registrieren")]
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }