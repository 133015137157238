var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("HinweiseUndFehler", { attrs: { hints: _vm.hints } }),
      _vm.substep === "stammdaten"
        ? _c("div", { key: "stammdaten" }, [
            _vm.istFPPlus
              ? _c(
                  "div",
                  { staticClass: "box__container" },
                  [
                    _c("div", { staticClass: "box__title" }, [
                      _vm._v("FinancePlan+")
                    ]),
                    _c("InputToggleSwitch", {
                      attrs: {
                        label:
                          "Keine Vermittlerdaten in Maklervertrag und -vollmacht"
                      },
                      on: {
                        change: function($event) {
                          return _vm.addBrokerDataEdited("brokerData")
                        }
                      },
                      model: {
                        value:
                          _vm.brokerData
                            .maklerVersVertragVollmachtEtcOhneMaklerdaten,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.brokerData,
                            "maklerVersVertragVollmachtEtcOhneMaklerdaten",
                            $$v
                          )
                        },
                        expression:
                          "brokerData.maklerVersVertragVollmachtEtcOhneMaklerdaten"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "box__container" },
              [
                _c("div", { staticClass: "box__title" }, [
                  _vm._v("Stammdaten")
                ]),
                _vm.isInternOriginal || _vm.inaktivAllowed
                  ? _c("InputToggleSwitch", {
                      attrs: { label: "Vermittler inaktiv" },
                      on: { change: _vm.updateInaktiv },
                      model: {
                        value: _vm.brokerData.inaktiv,
                        callback: function($$v) {
                          _vm.$set(_vm.brokerData, "inaktiv", $$v)
                        },
                        expression: "brokerData.inaktiv"
                      }
                    })
                  : _vm._e(),
                _c("InputField", {
                  attrs: {
                    label: "Telefonpasswort",
                    isComponentHalfSize: true,
                    disabled: true
                  },
                  on: {
                    change: function($event) {
                      return _vm.addBrokerDataEdited("brokerData")
                    }
                  },
                  model: {
                    value: _vm.brokerData.telefonpasswort,
                    callback: function($$v) {
                      _vm.$set(_vm.brokerData, "telefonpasswort", $$v)
                    },
                    expression: "brokerData.telefonpasswort"
                  }
                }),
                _c("InputField", {
                  attrs: {
                    label: "Registrierungscode",
                    isComponentHalfSize: true,
                    disabled: true
                  },
                  model: {
                    value: _vm.brokerData.registrierungscode,
                    callback: function($$v) {
                      _vm.$set(_vm.brokerData, "registrierungscode", $$v)
                    },
                    expression: "brokerData.registrierungscode"
                  }
                }),
                (_vm.istFPPlusMaster || _vm.istDGFRP || _vm.isIntern) &&
                !_vm.istFA
                  ? _c("InputField", {
                      attrs: {
                        label: "Kategorie",
                        isComponentHalfSize: true,
                        disabled: true
                      },
                      model: {
                        value: _vm.brokerData.kategorie,
                        callback: function($$v) {
                          _vm.$set(_vm.brokerData, "kategorie", $$v)
                        },
                        expression: "brokerData.kategorie"
                      }
                    })
                  : _vm._e(),
                (_vm.istFPPlusMaster || _vm.istDGFRP || _vm.isIntern) &&
                !_vm.istFA
                  ? _c("ComboBox", {
                      attrs: {
                        label: "Potential",
                        values: _vm.brokerDataConfig.potentialValues,
                        isComponentHalfSize: true,
                        disabled: !_vm.isIntern
                      },
                      on: {
                        change: function($event) {
                          return _vm.addBrokerDataEditedCombo(
                            "brokerData",
                            "potential",
                            $event
                          )
                        }
                      },
                      model: {
                        value: _vm.brokerData.potential,
                        callback: function($$v) {
                          _vm.$set(_vm.brokerData, "potential", $$v)
                        },
                        expression: "brokerData.potential"
                      }
                    })
                  : _vm._e(),
                (_vm.istFPPlusMaster || _vm.istDGFRP || _vm.isIntern) &&
                !_vm.istFA
                  ? _c("InputCheckboxItem", {
                      attrs: { label: "Interessent" },
                      on: {
                        input: function($event) {
                          return _vm.checkboxChanged()
                        },
                        change: function($event) {
                          return _vm.addBrokerDataEdited("brokerData")
                        }
                      },
                      model: {
                        value: _vm.brokerData.checkMaklerInteressent,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.brokerData,
                            "checkMaklerInteressent",
                            $$v
                          )
                        },
                        expression: "brokerData.checkMaklerInteressent"
                      }
                    })
                  : _vm._e(),
                _c("InputField", {
                  attrs: {
                    label: "Maklernummer",
                    isComponentHalfSize: true,
                    disabled: true
                  },
                  model: {
                    value: _vm.brokerData.maklernummer,
                    callback: function($$v) {
                      _vm.$set(_vm.brokerData, "maklernummer", $$v)
                    },
                    expression: "brokerData.maklernummer"
                  }
                }),
                _c("InputField", {
                  attrs: {
                    label:
                      "Firmenname</br> (Dieser Name wird in der Vermögensübersicht und Quartalsbericht angezeigt.)",
                    isComponentHalfSize: true
                  },
                  on: {
                    change: function($event) {
                      return _vm.addBrokerDataEdited("brokerData")
                    }
                  },
                  model: {
                    value: _vm.brokerData.firmenname,
                    callback: function($$v) {
                      _vm.$set(_vm.brokerData, "firmenname", $$v)
                    },
                    expression: "brokerData.firmenname"
                  }
                }),
                _c("InputField", {
                  attrs: {
                    label: _vm.isIntern ? "Anzeigename 2" : "",
                    isComponentHalfSize: true
                  },
                  on: {
                    change: function($event) {
                      return _vm.addBrokerDataEdited("brokerData")
                    }
                  },
                  model: {
                    value: _vm.brokerData.anzeigename2,
                    callback: function($$v) {
                      _vm.$set(_vm.brokerData, "anzeigename2", $$v)
                    },
                    expression: "brokerData.anzeigename2"
                  }
                }),
                _c("InputField", {
                  attrs: { label: "Firmenkürzel", isComponentHalfSize: true },
                  on: {
                    change: function($event) {
                      return _vm.addBrokerDataEdited("brokerData")
                    }
                  },
                  model: {
                    value: _vm.brokerData.firmenkuerzel,
                    callback: function($$v) {
                      _vm.$set(_vm.brokerData, "firmenkuerzel", $$v)
                    },
                    expression: "brokerData.firmenkuerzel"
                  }
                }),
                _vm.isIntern || _vm.isMaklerAsMakler
                  ? _c(
                      "div",
                      [
                        _c("InputField", {
                          attrs: {
                            label: "Briefanrede",
                            isComponentHalfSize: true
                          },
                          on: {
                            change: function($event) {
                              return _vm.addBrokerDataEdited("brokerData")
                            }
                          },
                          model: {
                            value: _vm.brokerData.briefanrede,
                            callback: function($$v) {
                              _vm.$set(_vm.brokerData, "briefanrede", $$v)
                            },
                            expression: "brokerData.briefanrede"
                          }
                        }),
                        !_vm.istFA
                          ? _c("InputToggleSwitch", {
                              attrs: { label: "Juristische Person" },
                              on: {
                                change: function($event) {
                                  return _vm.addBrokerDataEdited("brokerData")
                                }
                              },
                              model: {
                                value: _vm.brokerData.juristischePerson,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.brokerData,
                                    "juristischePerson",
                                    $$v
                                  )
                                },
                                expression: "brokerData.juristischePerson"
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.brokerData.telefon !== undefined
                  ? _c(
                      "div",
                      [
                        _c("InputField", {
                          attrs: {
                            label: "Telefon",
                            isComponentHalfSize: true,
                            inputmode: "tel",
                            isNotReactive: ""
                          },
                          on: {
                            change: function($event) {
                              return _vm.addBrokerDataEdited("brokerData")
                            }
                          },
                          model: {
                            value: _vm.brokerData.telefon,
                            callback: function($$v) {
                              _vm.$set(_vm.brokerData, "telefon", $$v)
                            },
                            expression: "brokerData.telefon"
                          }
                        }),
                        _c("InputField", {
                          attrs: {
                            label:
                              "Mobilfunknummer</br> z.B. zur Passwortrücksetzung oder Zwei-Faktor-Authentifizierung",
                            isComponentHalfSize: true,
                            inputmode: "tel",
                            isNotReactive: ""
                          },
                          on: {
                            change: function($event) {
                              return _vm.addBrokerDataEdited("brokerData")
                            }
                          },
                          model: {
                            value: _vm.brokerData.smsStandard,
                            callback: function($$v) {
                              _vm.$set(_vm.brokerData, "smsStandard", $$v)
                            },
                            expression: "brokerData.smsStandard"
                          }
                        }),
                        _c("InputField", {
                          attrs: {
                            label: "Telefax",
                            isComponentHalfSize: true,
                            inputmode: "tel",
                            isNotReactive: ""
                          },
                          on: {
                            change: function($event) {
                              return _vm.addBrokerDataEdited("brokerData")
                            }
                          },
                          model: {
                            value: _vm.brokerData.telefax,
                            callback: function($$v) {
                              _vm.$set(_vm.brokerData, "telefax", $$v)
                            },
                            expression: "brokerData.telefax"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c("InputField", {
                  attrs: { label: "USt-ID", isComponentHalfSize: true },
                  on: {
                    change: function($event) {
                      return _vm.addBrokerDataEdited("brokerData")
                    }
                  },
                  model: {
                    value: _vm.brokerData.ustId,
                    callback: function($$v) {
                      _vm.$set(_vm.brokerData, "ustId", $$v)
                    },
                    expression: "brokerData.ustId"
                  }
                }),
                _c("InputField", {
                  attrs: { label: "Steuernummer", isComponentHalfSize: true },
                  on: {
                    change: function($event) {
                      return _vm.addBrokerDataEdited("brokerData")
                    }
                  },
                  model: {
                    value: _vm.brokerData.steuernummer,
                    callback: function($$v) {
                      _vm.$set(_vm.brokerData, "steuernummer", $$v)
                    },
                    expression: "brokerData.steuernummer"
                  }
                }),
                _c("InputField", {
                  attrs: {
                    label: "Sozialversicherungsnummer",
                    isComponentHalfSize: true
                  },
                  on: {
                    change: function($event) {
                      return _vm.addBrokerDataEdited("brokerData")
                    }
                  },
                  model: {
                    value: _vm.brokerData.sozialversicherugsnummer,
                    callback: function($$v) {
                      _vm.$set(_vm.brokerData, "sozialversicherugsnummer", $$v)
                    },
                    expression: "brokerData.sozialversicherugsnummer"
                  }
                }),
                _c("ComboBox", {
                  attrs: {
                    label: "Staatsangehörigkeit",
                    values: _vm.brokerDataConfig.nationalities,
                    isComponentHalfSize: true
                  },
                  on: {
                    change: function($event) {
                      return _vm.addBrokerDataEditedCombo(
                        "brokerData",
                        "nationality",
                        $event
                      )
                    }
                  },
                  model: {
                    value: _vm.brokerData.nationality,
                    callback: function($$v) {
                      _vm.$set(_vm.brokerData, "nationality", $$v)
                    },
                    expression: "brokerData.nationality"
                  }
                }),
                _c("InputField", {
                  attrs: { label: "Referenznummer", isComponentHalfSize: true },
                  on: {
                    change: function($event) {
                      return _vm.addBrokerDataEdited("brokerData")
                    }
                  },
                  model: {
                    value: _vm.brokerData.referenznummer,
                    callback: function($$v) {
                      _vm.$set(_vm.brokerData, "referenznummer", $$v)
                    },
                    expression: "brokerData.referenznummer"
                  }
                }),
                _vm.primaerPersonen
                  ? [
                      _vm.brokerData.sectionPrimary
                        ? _c("ComboBox", {
                            attrs: {
                              label: "Primärer Ansprechpartner",
                              values: _vm.primaerPersonen.hauptPersonValues,
                              isComponentHalfSize: true
                            },
                            on: {
                              change: function($event) {
                                return _vm.addBrokerDataEditedCombo(
                                  "primaerPersonen",
                                  "hauptPerson",
                                  $event
                                )
                              }
                            },
                            model: {
                              value: _vm.primaerPersonen.hauptPerson,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.primaerPersonen,
                                  "hauptPerson",
                                  $$v
                                )
                              },
                              expression: "primaerPersonen.hauptPerson"
                            }
                          })
                        : _vm._e(),
                      _c("ComboBox", {
                        attrs: {
                          label: "Primärer Kundenberater",
                          values: _vm.primaerPersonen.kundenBeraterValues,
                          isComponentHalfSize: true
                        },
                        on: {
                          change: function($event) {
                            return _vm.addBrokerDataEditedCombo(
                              "primaerPersonen",
                              "kundenBerater",
                              $event
                            )
                          }
                        },
                        model: {
                          value: _vm.primaerPersonen.kundenBerater,
                          callback: function($$v) {
                            _vm.$set(_vm.primaerPersonen, "kundenBerater", $$v)
                          },
                          expression: "primaerPersonen.kundenBerater"
                        }
                      })
                    ]
                  : _vm._e(),
                _vm.istDGFRP
                  ? _c("ComboBox", {
                      attrs: {
                        label: "Betreuer",
                        values: _vm.brokerDataConfig.betreuerDGFRPValues,
                        isComponentHalfSize: true
                      },
                      on: {
                        change: function($event) {
                          return _vm.addBrokerDataEditedCombo(
                            "brokerData",
                            "betreuer",
                            $event
                          )
                        }
                      },
                      model: {
                        value: _vm.brokerData.betreuer,
                        callback: function($$v) {
                          _vm.$set(_vm.brokerData, "betreuer", $$v)
                        },
                        expression: "brokerData.betreuer"
                      }
                    })
                  : _vm._e(),
                _vm.isBrokerMaster
                  ? _c("DatePickerField", {
                      attrs: {
                        label: "Eintrittsdatum",
                        isComponentHalfSize: ""
                      },
                      on: {
                        input: function($event) {
                          return _vm.addBrokerDataEdited("brokerData")
                        }
                      },
                      model: {
                        value: _vm.brokerData.eintrittsdatum,
                        callback: function($$v) {
                          _vm.$set(_vm.brokerData, "eintrittsdatum", $$v)
                        },
                        expression: "brokerData.eintrittsdatum"
                      }
                    })
                  : _vm._e(),
                _c("MaklerPicture")
              ],
              2
            )
          ])
        : _vm.substep === "adresse"
        ? _c("div", { key: "adresse", staticClass: "box__container" }, [
            _c("div", { staticClass: "box__title" }, [_vm._v("Adresse")]),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c("InputField", {
                    attrs: { label: "Postleitzahl", isComponentHalfSize: true },
                    on: {
                      change: function($event) {
                        return _vm.addBrokerDataEdited("brokerData")
                      }
                    },
                    model: {
                      value: _vm.brokerData.plz,
                      callback: function($$v) {
                        _vm.$set(_vm.brokerData, "plz", $$v)
                      },
                      expression: "brokerData.plz"
                    }
                  }),
                  _c("InputField", {
                    attrs: { label: "Ort", isComponentHalfSize: true },
                    on: {
                      change: function($event) {
                        return _vm.addBrokerDataEdited("brokerData")
                      }
                    },
                    model: {
                      value: _vm.brokerData.ort,
                      callback: function($$v) {
                        _vm.$set(_vm.brokerData, "ort", $$v)
                      },
                      expression: "brokerData.ort"
                    }
                  }),
                  _c("InputField", {
                    attrs: { label: "Straße", isComponentHalfSize: true },
                    on: {
                      change: function($event) {
                        return _vm.addBrokerDataEdited("brokerData")
                      }
                    },
                    model: {
                      value: _vm.brokerData.strasse,
                      callback: function($$v) {
                        _vm.$set(_vm.brokerData, "strasse", $$v)
                      },
                      expression: "brokerData.strasse"
                    }
                  }),
                  _c("ComboBox", {
                    attrs: {
                      label: "Land",
                      isComponentHalfSize: true,
                      values: _vm.brokerDataConfig.countries
                    },
                    on: {
                      change: function($event) {
                        return _vm.addBrokerDataEditedCombo(
                          "brokerData",
                          "land",
                          $event
                        )
                      }
                    },
                    model: {
                      value: _vm.brokerData.land,
                      callback: function($$v) {
                        _vm.$set(_vm.brokerData, "land", $$v)
                      },
                      expression: "brokerData.land"
                    }
                  }),
                  _c("ComboBox", {
                    attrs: {
                      label: "Bundesland",
                      isComponentHalfSize: true,
                      values: _vm.brokerDataConfig.bundeslaender,
                      firstEmpty: !_vm.brokerData.bundesland
                    },
                    on: {
                      change: function($event) {
                        return _vm.addBrokerDataEditedCombo(
                          "brokerData.",
                          "bundesland",
                          $event
                        )
                      }
                    },
                    model: {
                      value: _vm.brokerData.bundesland,
                      callback: function($$v) {
                        _vm.$set(_vm.brokerData, "bundesland", $$v)
                      },
                      expression: "brokerData.bundesland"
                    }
                  })
                ],
                1
              )
            ])
          ])
        : _vm.substep === "versandadresse"
        ? _c(
            "div",
            { key: "versandadresse", staticClass: "box__container" },
            [
              _c("div", { staticClass: "box__title" }, [
                _vm._v("Versandadresse")
              ]),
              _c("InputField", {
                attrs: { label: "Straße", isComponentHalfSize: true },
                on: {
                  change: function($event) {
                    return _vm.addBrokerDataEdited("brokerData")
                  }
                },
                model: {
                  value: _vm.brokerData.versandAdresseStrasse,
                  callback: function($$v) {
                    _vm.$set(_vm.brokerData, "versandAdresseStrasse", $$v)
                  },
                  expression: "brokerData.versandAdresseStrasse"
                }
              }),
              _c("InputField", {
                attrs: { label: "Postleitzahl", isComponentHalfSize: true },
                on: {
                  change: function($event) {
                    return _vm.addBrokerDataEdited("brokerData")
                  }
                },
                model: {
                  value: _vm.brokerData.versandAdressePLZ,
                  callback: function($$v) {
                    _vm.$set(_vm.brokerData, "versandAdressePLZ", $$v)
                  },
                  expression: "brokerData.versandAdressePLZ"
                }
              }),
              _c("InputField", {
                attrs: { label: "Ort", isComponentHalfSize: true },
                on: {
                  change: function($event) {
                    return _vm.addBrokerDataEdited("brokerData")
                  }
                },
                model: {
                  value: _vm.brokerData.versandAdresseOrt,
                  callback: function($$v) {
                    _vm.$set(_vm.brokerData, "versandAdresseOrt", $$v)
                  },
                  expression: "brokerData.versandAdresseOrt"
                }
              }),
              _c("InputField", {
                attrs: { label: "Postfach", isComponentHalfSize: true },
                on: {
                  change: function($event) {
                    return _vm.addBrokerDataEdited("brokerData")
                  }
                },
                model: {
                  value: _vm.brokerData.versandAdressePostfach,
                  callback: function($$v) {
                    _vm.$set(_vm.brokerData, "versandAdressePostfach", $$v)
                  },
                  expression: "brokerData.versandAdressePostfach"
                }
              }),
              _c("InputField", {
                attrs: { label: "PLZ Postfach", isComponentHalfSize: true },
                on: {
                  change: function($event) {
                    return _vm.addBrokerDataEdited("brokerData")
                  }
                },
                model: {
                  value: _vm.brokerData.versandAdressePLZPostfach,
                  callback: function($$v) {
                    _vm.$set(_vm.brokerData, "versandAdressePLZPostfach", $$v)
                  },
                  expression: "brokerData.versandAdressePLZPostfach"
                }
              })
            ],
            1
          )
        : _vm.substep === "paketadresse"
        ? _c(
            "div",
            { key: "paketadresse", staticClass: "box__container" },
            [
              _c("div", { staticClass: "box__title" }, [
                _vm._v("Paketadresse")
              ]),
              _c("InputField", {
                attrs: { label: "Straße", isComponentHalfSize: true },
                on: {
                  change: function($event) {
                    return _vm.addBrokerDataEdited("brokerData")
                  }
                },
                model: {
                  value: _vm.brokerData.paketAdresseStrasse,
                  callback: function($$v) {
                    _vm.$set(_vm.brokerData, "paketAdresseStrasse", $$v)
                  },
                  expression: "brokerData.paketAdresseStrasse"
                }
              }),
              _c("InputField", {
                attrs: { label: "Postleitzahl", isComponentHalfSize: true },
                on: {
                  change: function($event) {
                    return _vm.addBrokerDataEdited("brokerData")
                  }
                },
                model: {
                  value: _vm.brokerData.paketAdressePLZ,
                  callback: function($$v) {
                    _vm.$set(_vm.brokerData, "paketAdressePLZ", $$v)
                  },
                  expression: "brokerData.paketAdressePLZ"
                }
              }),
              _c("InputField", {
                attrs: { label: "Ort", isComponentHalfSize: true },
                on: {
                  change: function($event) {
                    return _vm.addBrokerDataEdited("brokerData")
                  }
                },
                model: {
                  value: _vm.brokerData.paketAdresseOrt,
                  callback: function($$v) {
                    _vm.$set(_vm.brokerData, "paketAdresseOrt", $$v)
                  },
                  expression: "brokerData.paketAdresseOrt"
                }
              })
            ],
            1
          )
        : _vm.substep === "fp-plus-referenz"
        ? _c(
            "div",
            { key: "fp-plus-referenz", staticClass: "box__container" },
            [
              _c("div", { staticClass: "box__title" }, [
                _vm._v("Referenznummer FP+")
              ]),
              _c("InputField", {
                attrs: {
                  label: "Referenznummer FP+ GmbH",
                  isComponentHalfSize: ""
                },
                on: {
                  change: function($event) {
                    return _vm.addBrokerDataEdited("brokerData")
                  }
                },
                model: {
                  value: _vm.brokerData.referenznrPrivat,
                  callback: function($$v) {
                    _vm.$set(_vm.brokerData, "referenznrPrivat", $$v)
                  },
                  expression: "brokerData.referenznrPrivat"
                }
              }),
              _c("InputField", {
                attrs: {
                  label: "Referenznummer FP+ Gewerbe GmbH",
                  isComponentHalfSize: ""
                },
                on: {
                  change: function($event) {
                    return _vm.addBrokerDataEdited("brokerData")
                  }
                },
                model: {
                  value: _vm.brokerData.referenznrGewerbe,
                  callback: function($$v) {
                    _vm.$set(_vm.brokerData, "referenznrGewerbe", $$v)
                  },
                  expression: "brokerData.referenznrGewerbe"
                }
              }),
              _c("InputField", {
                attrs: {
                  label: "Referenznummer FP+ Service GmbH",
                  isComponentHalfSize: ""
                },
                on: {
                  change: function($event) {
                    return _vm.addBrokerDataEdited("brokerData")
                  }
                },
                model: {
                  value: _vm.brokerData.referenznrService,
                  callback: function($$v) {
                    _vm.$set(_vm.brokerData, "referenznrService", $$v)
                  },
                  expression: "brokerData.referenznrService"
                }
              }),
              _c("InputField", {
                attrs: {
                  label: "Referenznummer FP+ GBR",
                  isComponentHalfSize: ""
                },
                on: {
                  change: function($event) {
                    return _vm.addBrokerDataEdited("brokerData")
                  }
                },
                model: {
                  value: _vm.brokerData.referenznrGbr,
                  callback: function($$v) {
                    _vm.$set(_vm.brokerData, "referenznrGbr", $$v)
                  },
                  expression: "brokerData.referenznrGbr"
                }
              }),
              _c("InputField", {
                attrs: {
                  label: "Referenznummer FP+ GmbH DAC",
                  isComponentHalfSize: ""
                },
                on: {
                  change: function($event) {
                    return _vm.addBrokerDataEdited("brokerData")
                  }
                },
                model: {
                  value: _vm.brokerData.referenznrPrivatDac,
                  callback: function($$v) {
                    _vm.$set(_vm.brokerData, "referenznrPrivatDac", $$v)
                  },
                  expression: "brokerData.referenznrPrivatDac"
                }
              }),
              _c("InputField", {
                attrs: {
                  label: "Referenznummer FP+ Gewerbe GmbH DAC",
                  isComponentHalfSize: ""
                },
                on: {
                  change: function($event) {
                    return _vm.addBrokerDataEdited("brokerData")
                  }
                },
                model: {
                  value: _vm.brokerData.referenznrGewerbeDac,
                  callback: function($$v) {
                    _vm.$set(_vm.brokerData, "referenznrGewerbeDac", $$v)
                  },
                  expression: "brokerData.referenznrGewerbeDac"
                }
              }),
              _c("InputField", {
                attrs: {
                  label: "Referenznummer FP+ Service GmbH DAC",
                  isComponentHalfSize: ""
                },
                on: {
                  change: function($event) {
                    return _vm.addBrokerDataEdited("brokerData")
                  }
                },
                model: {
                  value: _vm.brokerData.referenznrServiceDac,
                  callback: function($$v) {
                    _vm.$set(_vm.brokerData, "referenznrServiceDac", $$v)
                  },
                  expression: "brokerData.referenznrServiceDac"
                }
              })
            ],
            1
          )
        : _vm.substep === "maklerkriterien"
        ? _c(
            "div",
            { key: "maklerkriterien", staticClass: "box__container" },
            [
              _c("div", { staticClass: "box__title" }, [
                _vm._v("Makler Kriterien")
              ]),
              _c("MaklerKriterien", {
                attrs: {
                  maklerKriterien: _vm.brokerDataConfig.maklerKriterien,
                  alleStrukturKriterien:
                    _vm.brokerDataConfig.alleStrukturKriterien
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }