var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", { attrs: { title: _vm.title } }),
      _c("BaseFilter", {
        attrs: {
          title: _vm.title,
          filterId: _vm.title,
          configFilter: _vm.filterConfig,
          metadata: _vm.metadata,
          defaultOptions: _vm.defaultOptions,
          showSaveButton: "",
          immidiateSearch: true
        },
        on: { onFilter: _vm.onFilter }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.loading
            ? _c("GhostLoading", { attrs: { type: "table" } })
            : _vm.rows.length
            ? _c("BigTable", {
                staticStyle: { clear: "both" },
                attrs: {
                  selectable: "",
                  tableId: "38b4386f-c591-4165-b3d9-1c1644592596",
                  headers: _vm.headers,
                  rows: _vm.rows,
                  page: _vm.page,
                  sortingState: _vm.sortingState,
                  headerActions: _vm.headerActions,
                  initialPageSize: _vm.minNumRows
                },
                on: {
                  onClickHeader: _vm.onClickHeader,
                  selectedRow: _vm.onRowSelection,
                  selectedRows: _vm.onAllRowsSelection,
                  "action-READ": _vm.actionRead,
                  "action-RESULT": _vm.actionResult,
                  "headerAction-MARKIERTE_POSITIONEN_LOESCHEN":
                    _vm.deleteSelected
                },
                scopedSlots: _vm._u([
                  {
                    key: "zusatz",
                    fn: function(row) {
                      return [
                        _c("InputField", {
                          attrs: { value: row.zusatz },
                          on: {
                            change: function($event) {
                              return _vm.saveZusatz(row, $event)
                            }
                          }
                        })
                      ]
                    }
                  }
                ])
              })
            : _c("NoData")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }