import BIPRO_TYPES from './types';
import { getInitialState } from './index';

export default {
    [BIPRO_TYPES.MUTATIONS.RESET_STATE](state) {
        Object.assign(state, getInitialState())
    },
    [BIPRO_TYPES.MUTATIONS.GESELLSCHAFT_OPTIONS](state, options) {
        state.gesellschaftOptions = options;
    },
    [BIPRO_TYPES.MUTATIONS.GESELLSCHAFT_OPTIONS_INTERFACE](state, options) {
        state.gesellschaftOptionsInterface = options;
    },
    [BIPRO_TYPES.MUTATIONS.SAVE_FILTERS](state, options) {
        state.savedFilters = options;
    },
    [BIPRO_TYPES.MUTATIONS.SAVE_SORTING](state, options) {
        state.savedSorting = options;
    }, 
    [BIPRO_TYPES.MUTATIONS.PARAM_RETURN_VERS_VERTRAG](state, payload) {
        state.paramReturnVersVertrag = payload;
    },    
}
