import {SimpleAction} from "@/components/table2/table_util.js";
import {PhFileArrowDown, PhPencilLine, PhTrash, PhWrench} from "phosphor-vue";

export const MUTATION_PREFIX =  'IA_MUTATION_';

export const MUTATION_PREFIX_FETCH_SUCCESS =  'IA_MUTATION_FETCH_SUCCESS_';
export const MUTATION_PREFIX_FETCH_FAILURE =  'IA_MUTATION_FETCH_FAILURE_';
export const MUTATION_PREFIX_SET_SUCCESS =    'IA_MUTATION_SET_SUCCESS_';
export const MUTATION_PREFIX_SAVE_SUCCESS =   'IA_MUTATION_SAVE_SUCCESS_';
export const MUTATION_PREFIX_ADD_SUCCESS =    'IA_MUTATION_ADD_SUCCESS_';
export const MUTATION_PREFIX_DELETE_SUCCESS = 'IA_MUTATION_DELETE_SUCCESS_';
export const MUTATION_PREFIX_APPLY_SUCCESS =  'IA_MUTATION_APPLY_SUCCESS_';

export const ACTIONS_PREFIX = 'IA_ACTIONS_';

export const ACTIONS_PREFIX_FETCH =  'IA_ACTIONS_FETCH_';
export const ACTIONS_PREFIX_SAVE =   'IA_ACTIONS_SAVE_';

export const ACTIONS_PREFIX_GET =  'IA_ACTIONS_GET_';
export const ACTIONS_PREFIX_SET =    'IA_ACTIONS_SET_';

export const ACTIONS_PREFIX_ADD =    'IA_ACTIONS_ADD_';

export const ACTIONS_PREFIX_DELETE = 'IA_ACTIONS_DELETE_';
export const ACTIONS_PREFIX_APPLY =  'IA_ACTIONS_APPLY_';

export const GETTERS_PREFIX = 'IA_GETTERS_';
export const OUTCOME_TYPE = {
  BACKTEST_ADVICE: 'ADVICE',
  BACKTEST_DETAILS: 'DETAILS',
  BACKTEST_ACTUAL: 'ACTUAL',
  OPTIMIZATION: 'OPTIMIZATION'
};
export function setLockPill(row) {
  if (!row.lockingMode && row.lockingModeView) {
    row.lockingMode = {
      'keine Einschränkungen': 'JA',
      'komplett gesperrt': 'FEST',
      'dauerhaft gesperrt': 'IMMER',
      'kein Kauf': 'KEINKAUF',
      'kein Verkauf': 'KEINVERKAUF'
    }[row.lockingModeView]
  }
  row.lockingModePill = { // primary, success, warning, info, danger
    JA: {label: 'keine Einschränkungen', type: 'success' },
    FEST: {label: 'komplett gesperrt', type: 'danger' },
    IMMER: {label: 'dauerhaft gesperrt', type: 'danger' },
    KEINKAUF: {label: 'kein Kauf', type: 'warning' },
    KEINVERKAUF: {label: 'kein Verkauf', type: 'danger' },
  }[row.lockingMode];
  
  if (row.active != null) {
    if ({FEST: 1, IMMER: 1, KEINVERKAUF: 1}[row.lockingMode] == 1) {
      row.active = true;
      row.activDisabled = true;
    } else {
      row.activDisabled = false;
    }
  }
}
export function setRow(row, step, bestandsdepot) {
  if (!row.actions){
    row.actions = [];
    if ( {OUTCOME: 1, OPTIMIZATION: 1, MAIN: (bestandsdepot == '-4' || bestandsdepot == '-6') }[step]) {
      row.actions.push(SimpleAction('EDIT', PhPencilLine, 'Bearbeiten'));
    }
    if ({OUTCOME: row.delete, MAIN: (bestandsdepot == '-4' || bestandsdepot == '-6') }[step] ) {
      row.actions.push(SimpleAction('DELETE', PhTrash, 'Löschen'));
    }
    if ({ MAIN: 1 }[step] && row.lockingMode != 'IMMER') {
      row.actions.push(
        SimpleAction('LOCKM$JA', PhWrench, 'keine Einschränkungen'),
        SimpleAction('LOCKM$FEST', PhWrench, 'komplett gesperrt'),
        SimpleAction('LOCKM$KEINKAUF', PhWrench, 'kein Kauf'),
        SimpleAction('LOCKM$KEINVERKAUF', PhWrench, 'kein Verkauf')
      );
    }
    if ( row.FACTSHEET_URL){
      row.actions.push(SimpleAction('FACTSHEET', PhFileArrowDown, 'Factsheet'));
    }
    if ( row.FACTSHEET_KAG_URL){
      row.actions.push(SimpleAction('FACTSHEET_KAG', PhFileArrowDown, 'Factsheet KAG'));
    }
    if ( row.PRIIP_BIB_URL){
      row.actions.push(SimpleAction('PRIIP_BIB', PhFileArrowDown, 'PRIIP-BIB'));
    }
  }
  if (row.lockingMode || row.lockingModeView) {
    setLockPill(row);
  }
  if (row.turnus !== undefined) {
    row.turnus = row.turnus || '';
    row.turnusDisp = '';
    if (row.turnus) {
      row.turnusDisp = {
        MONATLICH: 'monatlich',
        VIERTELJ: 'vierteljährlich',
        JAEHRLICH: 'jährlich',
      }[row.turnus];
    }
  }
  if (row.isNichtHandelbar !== undefined) {
    row.nichtHandelbarPill = row.isNichtHandelbar ?
    {label: 'x', type: 'danger' } : {label: '', type: 'primary' }
  }
  row.unique = row.isin + (row.id ? '_' + row.id : '')
}

export default {
  MUTATIONS: {
    FETCH_LIST_SUCCESS: MUTATION_PREFIX_FETCH_SUCCESS + 'LIST',
    FETCH_PARAMETERS_SUCCESS: MUTATION_PREFIX_FETCH_SUCCESS + 'PARAMS',
    FETCH_POSITIONS_SUCCESS: MUTATION_PREFIX_FETCH_SUCCESS + 'POSITIONS',
    FETCH_OUTCOME_SUCCESS: MUTATION_PREFIX_FETCH_SUCCESS + 'OUTCOME',
    FETCH_INFO_SUCCESS:  MUTATION_PREFIX_FETCH_SUCCESS + 'INFO',
    FETCH_DIAGRAMME: MUTATION_PREFIX_FETCH_SUCCESS + 'DIAGRAMME',

    FETCH_LIST_FAILURE: MUTATION_PREFIX_FETCH_FAILURE + 'LIST',
    FETCH_PARAMETERS_FAILURE: MUTATION_PREFIX_FETCH_FAILURE + 'PARAMS',
    FETCH_POSITIONS_FAILURE: MUTATION_PREFIX_FETCH_FAILURE + 'POSITIONS',
    FETCH_OUTCOME_FAILURE: MUTATION_PREFIX_FETCH_FAILURE + 'OUTCOME',

    SET_INVESTMENT_ADVICE_SUCCESS: MUTATION_PREFIX_SET_SUCCESS + 'ID',
    SAVE_PARAMETERS_SUCCESS: MUTATION_PREFIX_SAVE_SUCCESS + 'PARAMS',
    SAVE_POSITIONS_SUCCESS: MUTATION_PREFIX_SAVE_SUCCESS + 'POSITIONS',
    SAVE_OUTCOME_SUCCESS: MUTATION_PREFIX_SAVE_SUCCESS + 'OUTCOME',
    ADD_POSITIONS_SUCCESS: MUTATION_PREFIX_ADD_SUCCESS + 'POSITIONS',

    CHANGE_ROWS: MUTATION_PREFIX_SET_SUCCESS + 'CHANGE_ROWS',

    DELETE_INVESTMENT_ADVICE_SUCCESS: MUTATION_PREFIX_DELETE_SUCCESS + 'ID',
    DELETE_POSITIONS_SUCCESS: MUTATION_PREFIX_DELETE_SUCCESS + 'POSITIONS',

    APPLY_OPTIMIZATION_SUCCESS: MUTATION_PREFIX_APPLY_SUCCESS + 'OPTIMIZATION',
    TO_ORDER_SUCCESS: 'IA_MUTATION_SUCCESS_TO_ORDER',
    TO_ORDER_FAILURE: 'IA_MUTATION_FAILURE_TO_ORDER',

    SET_INVESTMENT_ADVICE_ORDER_SUCCESS: MUTATION_PREFIX_SET_SUCCESS + 'INVESTMENT_ADVICE_ORDER',
    SET_INVESTMENT_ADVICE_DEPOTEROEFFNUNG_SUCCESS: MUTATION_PREFIX_SET_SUCCESS + 'INVESTMENT_ADVICE_DEPOTEROEFFNUNG',
    UPDATE_INVESTMENT_ADVICE_ORDER_SUCCESS: MUTATION_PREFIX_SET_SUCCESS + 'UPDATE_INVESTMENT_ADVICE_ORDER',

    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
    RESET_POSITIONS: MUTATION_PREFIX + 'RESET_POSITIONS_OUTPUT',
    RESET_POSITIONS_OUTPUT: MUTATION_PREFIX + 'RESET_POSITIONS',
    RESET_POSITIONS_COURTAGE: MUTATION_PREFIX + 'RESET_POSITIONS_COURTAGE',
    RESET_OUTCOME: MUTATION_PREFIX + 'RESET_OUTCOME',
    RESET_ORDER: MUTATION_PREFIX + 'RESET_ORDER',

    RESET_PARAMETERS_LOADED: MUTATION_PREFIX + 'RESET_PARAMETERS_LOADED',

    SET_LIST_FILTERS: MUTATION_PREFIX + 'SET_LIST_FILTERS',

    SET_MESSAGE: MUTATION_PREFIX + 'SET_MESSAGE',
    LAST_VISIT_STEP: MUTATION_PREFIX + 'LAST_VISIT_STEP',
  },

  ACTIONS: {
    FETCH_LIST: ACTIONS_PREFIX_FETCH + 'LIST',
    REFRESH_LIST: ACTIONS_PREFIX_FETCH + 'REFRESH_LIST',
    FETCH_PARAMETERS: ACTIONS_PREFIX_FETCH + 'PARAMS',
    FETCH_POSITIONS:  ACTIONS_PREFIX_FETCH + 'POSITIONS',
    FETCH_OUTCOME:  ACTIONS_PREFIX_FETCH + 'OUTCOME',
    FETCH_DIAGRAMME:  ACTIONS_PREFIX_FETCH + 'DIAGRAMME',
    FETCH_INFO:  ACTIONS_PREFIX_FETCH + 'INFO',
    SET_INVESTMENT_ADVICE: ACTIONS_PREFIX_SET + 'ID',
    SAVE_PARAMETERS: ACTIONS_PREFIX_SAVE + 'PARAMS',
    SAVE_POSITIONS:  ACTIONS_PREFIX_SAVE + 'POSITIONS',
    SAVE_OUTCOME:  ACTIONS_PREFIX_SAVE + 'OUTCOME',
    ADD_POSITIONS:  ACTIONS_PREFIX_ADD + 'POSITIONS',
    TO_ORDER:  'IA_ACTIONS_TO_ORDER',

    SET_INVESTMENT_ADVICE_ORDER: ACTIONS_PREFIX_SET + 'INVESTMENT_ADVICE_ORDER',
    SET_INVESTMENT_ADVICE_DEPOTEROEFFNUNG: ACTIONS_PREFIX_SET + 'INVESTMENT_ADVICE_DEPOTEROEFFNUNG',

    SUBMIT_DIALOG_ANSWER:  'IA_ACTIONS_SUBMIT_DIALOG_ANSWER',

    DELETE_INVESTMENT_ADVICE: ACTIONS_PREFIX_DELETE + 'ID',
    DELETE_INVESTMENT_ADVICE_LIST: ACTIONS_PREFIX_DELETE + 'IDS',
    DELETE_POSITIONS:  ACTIONS_PREFIX_DELETE + 'POSITIONS',

    APPLY_OPTIMIZATION: ACTIONS_PREFIX_APPLY + 'OPTIMIZATION',
    UPLOAD_ALLOCATION_FILE: 'IA_ACTIONS_UPLOAD_FILE',

    CALC_ASSET_ALLOCATION: ACTIONS_PREFIX + 'CALC_ASSET_ALLOCATION',
    
    CREATE_PDF: ACTIONS_PREFIX + 'CREATE_PDF',
    LOAD_LAST_DOC_INFO: ACTIONS_PREFIX + 'LOAD_LAST_DOC_INFO',
    LOAD_ISIN_WKN: ACTIONS_PREFIX + 'LOAD_ISIN_WKN',
    COPY_INVESTMENT_ADVICE: ACTIONS_PREFIX + 'COPY_INVESTMENT_ADVICE',
    GET_WERTSTEIGERUNG: ACTIONS_PREFIX + 'GET_WERTSTEIGERUNG',
    MAPPE_INVESTMENT_ADVICE: ACTIONS_PREFIX + 'MAPPE_INVESTMENT_ADVICE',
    SAVE_PLATTFORMEN: ACTIONS_PREFIX + 'SAVE_PLATTFORMEN'
  },

  GETTERS: {
    LIST: GETTERS_PREFIX + 'LIST',
    ID: GETTERS_PREFIX + 'ID',
    INFO: GETTERS_PREFIX + 'INFO',
    PARAMETERS: GETTERS_PREFIX + 'PARAMS',
    POSITIONS: GETTERS_PREFIX + 'POSITIONS',
    BACKTEST_ADVICE: GETTERS_PREFIX + OUTCOME_TYPE.BACKTEST_ADVICE,
    BACKTEST_DETAILS: GETTERS_PREFIX + OUTCOME_TYPE.BACKTEST_DETAILS,
    BACKTEST_ACTUAL: GETTERS_PREFIX + OUTCOME_TYPE.BACKTEST_ACTUAL,
    DIAGRAMME: GETTERS_PREFIX + 'DIAGRAMME',
    OPTIMIZATION: GETTERS_PREFIX + OUTCOME_TYPE.OPTIMIZATION,
    EXPIRED: GETTERS_PREFIX + 'EXPIRED',
    TEMPLATE: GETTERS_PREFIX + 'TEMPLATE',
    ORDER: GETTERS_PREFIX + 'ORDER',
    INVESTMENT_ADVICE_ORDER: GETTERS_PREFIX + 'INVESTMENT_ADVICE_ORDER',
    INVESTMENT_ADVICE_DEPOTEROEFFNUNG: GETTERS_PREFIX + 'INVESTMENT_ADVICE_DEPOTEROEFFNUNG',
    LIST_FILTERS: GETTERS_PREFIX + 'LIST_FILTERS',
    GET_MESSAGE: GETTERS_PREFIX + 'GET_MESSAGE',
    LAST_VISIT_STEP: GETTERS_PREFIX + 'LAST_VISIT_STEP',
  },
  
  STEPS: {
    MAIN:'MAIN',
    MY_DEPOT:'MYDEPOT',
    MYDEPOT_EINMALANLAGE: 'MYDEPOT_EINMALANLAGE',
    MYDEPOT_SPARPLAN: 'MYDEPOT_SPARPLAN',
    WK:'WK',
    FAVORITES:'FAVORITES',
    FONDSFINDER:'FONDSFINDER',
    OPTIMIZATION:'OPTIMIZATION',
    BENCHMARK:'BENCHMARK',
    OUTCOME:'OUTCOME',
    PDF:'PDF',
    COURTAGE: 'COURTAGE',
    ORDER:'ORDER'
  },

  PURPOSE: {
    INPUT: 'INPUT',
    RESTRICTIONS: 'RESTRICTIONS',
    OUTPUT: 'OUTPUT', 
    COURTAGE: 'COURTAGE'
  },
  QUELLE: {
    KUNDENDEPOT:'KUNDENDEPOT',
    MYDEPOT_EINMALANLAGE: 'MYDEPOT_EINMALANLAGE',
    MYDEPOT_SPARPLAN: 'MYDEPOT_SPARPLAN',
    FONDSFAVORITEN: 'FONDSFAVORITEN',
    MANUELLESUCHE: 'MANUELLESUCHE',
    WEALTHKONZEPT: 'WEALTHKONZEPT'
  },
  OUTCOME: OUTCOME_TYPE,
}