<template>
<div>
    <div class="box__container">
        <div v-if="loading" class="text-centered">
            <AnimatedSpinner/>
        </div>
        <template v-else>
            <div v-if="selectedNav===CHART_BENCHMARKING" ref="advice">  
                <div class="tool-charts">
                    <BaseButton class="btn btn-primary mr-3 my-1" @click="aktualisieren" :disabled="loading"> 
                        Aktualisieren
                    </BaseButton>
                    <div class="tool-gap"></div>
                    <InputToggleSwitch label="Relativ zur Anlagesumme" inLineLabel v-model="chartRelativ" />
                </div>
                <ChartFunds type="Anlageempf-advice" :chartData="chartAdvice" :relativ="chartRelativ" />
            </div>        
            <div v-if="selectedNav===CHART_DETAILS" ref="details">
                <div class="tool-charts">
                    <div class="tool-gap"></div>
                    <InputToggleSwitch label="Relativ zur Anlagesumme" inLineLabel v-model="chartRelativ" />
                </div>
                <ChartFunds type="Anlageempf-details" :chartData="chartDetails" :relativ="chartRelativ" />
            </div>        
            <div v-if="selectedNav===CHART_BESTANDSDEPOT" ref="actual">
                <ChartFunds type="Anlageempf-actual" :chartData="chartActual" />
            </div>
        </template>
    </div>
   <!-- BACKTESTING {{adviceId}} -->
</div>
</template>
<script>
import INVESTMENT_ADVICE from "@/store/investmentAdvice/types";
import BaseButton from "@/components/core/BaseButton.vue";
import AnimatedSpinner from "@/components/core/AnimatedSpinner.vue";
import { mapGetters } from "vuex";
import ChartFunds from '@/components/charts/ChartFunds.vue';
import StepAllocation from "./StepAllocation";
import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue";

const CHART_BENCHMARKING = 'Benchmarking';
const CHART_DETAILS = 'Details';
const CHART_BESTANDSDEPOT = 'Bestandsdepot';

const CHARTS_NAV_VALUES = [
    {
        label: CHART_BENCHMARKING,
        value: CHART_BENCHMARKING,
    },
    {
        label: CHART_DETAILS,
        value: CHART_DETAILS,
    },
    {
        label: CHART_BESTANDSDEPOT,
        value: CHART_BESTANDSDEPOT,
    },
];


export default {
    props: {
        tab: {
            type: String,
            default: CHART_BENCHMARKING,
        },
        adviceId: '',
        stepData: {},
    },
    data() {
        return {
            selectedNav: CHART_BENCHMARKING,
            loading: false,
            chartsNavValues: CHARTS_NAV_VALUES,
            CHART_BENCHMARKING,
            CHART_DETAILS,
            CHART_BESTANDSDEPOT,
            stepAllocation: [
                new StepAllocation(undefined, null),
            ],
            anlage: false,
            chartRelativ: false,
        }
    },
    components:{
        BaseButton,        
        ChartFunds,
        AnimatedSpinner,
        InputToggleSwitch
    },
   
    computed: {
        ...mapGetters({
            backtestAdvice: INVESTMENT_ADVICE.GETTERS.BACKTEST_ADVICE,
            backtestDetails: INVESTMENT_ADVICE.GETTERS.BACKTEST_DETAILS,
            backtestActual: INVESTMENT_ADVICE.GETTERS.BACKTEST_ACTUAL,
            parameters: INVESTMENT_ADVICE.GETTERS.PARAMETERS,
        }),
        chartAdvice() {
            if (this.backtestAdvice?.data && this.anlage) {
                return {...this.backtestAdvice?.data, anlage: this.anlage };
            }
            return {}
        },
        chartDetails() {
            if (this.backtestDetails?.data && this.anlage) {
                return {...this.backtestDetails?.data, anlage: this.anlage };
            }
            return {}
        },
        allocationRows() {
            return this.parameters(INVESTMENT_ADVICE.STEPS.OUTCOME)?.data?.rows;
        },
        chartActual() {
            if (this.backtestActual?.data && this.anlage) {
                return {...this.backtestActual?.data, anlage: this.anlage };
            }
            return {}
        },
    },

    mounted() {
        this.setAnlage();
    },
    methods:{
        loadPositions() {
            this.$store.dispatch(INVESTMENT_ADVICE.ACTIONS.FETCH_PARAMETERS, {
                adviceId: this.adviceId,
                step: INVESTMENT_ADVICE.STEPS.OUTCOME,
            });
        },
        aktualisieren() {
            this.chartDataLaden(true, false);
        },
        chartDataLaden(refresh = false, ignoreHistory = true) {
            this.loading = true;
            switch(this.selectedNav) {
                case CHART_BENCHMARKING:
                    this.$store
                    .dispatch(INVESTMENT_ADVICE.ACTIONS.FETCH_OUTCOME, {
                        adviceId: this.adviceId,
                        type: INVESTMENT_ADVICE.OUTCOME.BACKTEST_ADVICE,
                        refresh: refresh,
                        sendDetails: true,
                        ignoreHistory,
                    });
                    break;
                case CHART_DETAILS:
                    this.$store
                    .dispatch(INVESTMENT_ADVICE.ACTIONS.FETCH_OUTCOME, {
                        adviceId: this.adviceId,
                        type: INVESTMENT_ADVICE.OUTCOME.BACKTEST_DETAILS,
                        refresh: refresh,
                        sendDetails: true,
                    });
                    break;
                case CHART_BESTANDSDEPOT:
                    this.$store
                    .dispatch(INVESTMENT_ADVICE.ACTIONS.FETCH_OUTCOME, {
                        adviceId: this.adviceId,
                        type: INVESTMENT_ADVICE.OUTCOME.BACKTEST_ACTUAL,
                        refresh: refresh
                    });    
                    break;
                default:
                    break;
            }
        },
        selectNav(selectedNav) {
            this.selectedNav = selectedNav;
            let refreshOutcome = false;
            let parent = this.$parent;
            while (parent) {
                if (parent.$data.refreshOutcome !== undefined) {
                    if (parent.$data.refreshOutcome) {
                        refreshOutcome = true;
                        parent.$data.refreshOutcome = false;
                    }
                    break;
                } else {
                    parent = parent.$parent;
                }
            }
            this.chartDataLaden(refreshOutcome);
        },
        setAnlage() {
            if (this.allocationRows) {
                let einmal = 0;
                let monat = 0;
                let quartal = 0;
                let jahr = 0;
                const fonds = {};
                this.allocationRows.forEach(row => {
                    if (row.summe != null && !isNaN(row.summe)) {
                        fonds[row.name] = {
                            einmal: row.zielAnfangsbetrag != null ? row.zielAnfangsbetrag : row.summe,
                            monat: (row.turnus == 'MONATLICH' ? row.rate : 0),
                            quartal: (row.turnus == 'VIERTELJ' ? row.rate : 0),
                            jahr: (row.turnus == 'JAEHRLICH' ? row.rate : 0),
                        }
                        einmal += fonds[row.name].einmal;
                        monat += fonds[row.name].monat;
                        quartal += fonds[row.name].quartal;
                        jahr += fonds[row.name].jahr;
                    }
                })
                this.anlage = {einmal, monat, quartal, jahr, fonds};
            } else {
                this.loadPositions();
                this.anlage = false;
            }
        },
        async saveMainParameters(mainParams) {
            await this.$store.dispatch(INVESTMENT_ADVICE.ACTIONS.SAVE_PARAMETERS, { 
                adviceId: this.adviceId,
                parameters: {
                step: 'MAIN',
                parameterData: mainParams,        
                updateOnlyVorgang: false,
                persist: true,
                tableData: null
                }
            });
            this.loadPositions();
            this.chartDataLaden(true);
        },
    },

    watch: {
        backtestAdvice(o, n) {
            if (this.backtestAdvice.data?.zusatz?._changetimeNeeded_changetimeStart) {
                const zusatz = this.backtestAdvice.data?.zusatz;
                let mainParams = this.parameters('MAIN');
                if(mainParams?.data?.parameterData?.backtestStart) {
                    mainParams = JSON.parse(JSON.stringify(mainParams.data.parameterData));
                    const dialogData = {
                    yesText: 'Ja',
                    noText: 'Nein',
                    yesCb: () => {
                        let part = zusatz._changetimeNeeded_changetimeStart.split('.').map(p => parseInt(p, 10));
                        mainParams.backtestStart = new Date(part[2], part[1]-1, part[0]);
                        part = zusatz._changetimeNeeded_changetimeEnd.split('.').map(p => parseInt(p, 10));
                        mainParams.backtestEnd = new Date(part[2], part[1]-1, part[0]);
                        mainParams.zeitIntervall = true;
                        this.saveMainParameters(mainParams);
                    },
                    noCb: () => {
                        this.chartDataLaden(true);
                        zusatz._changetimeNeeded_changetimeStart='';
                        zusatz._changetimeNeeded_changetimeEnd='';
                        zusatz._changetimeNeeded_mess='';
                        zusatz._changetimeNeeded='';
                    },
                    label: 'Analysephase',
                    text: (zusatz._changetimeNeeded_mess + '<br><br>' + zusatz._changetimeNeeded).replace(/\\n/, '<br>'),
                    }
                    this.$emit('display_server_question', dialogData);
                }
            }
            this.loading = false;
        },
        backtestDetails(o, n) {
            this.loading = false;
        },
        backtestActual(o, n) {
            this.loading = false;
        },
        tab: {
            handler(tab) {
                this.selectNav(tab);
            },
            immediate: true,
        },
        'allocationRows': 'setAnlage'
    },

}
</script>
<style>
.tool-charts {
    display: flex;
    align-items: center;
}
.tool-gap {
    flex-grow: 1;
}
</style>