<template>
  <div class="dashboard-panel">
    <PageHeaderTitleNavigation 
      v-if="!hidePageHeader"
      :id="id" :title="title" :subtitle="subtitle" :defaultMenu="defaultOptionsMenu" 
      :actions="headerActionsInternal" 
      :noPrimaryAction="noPrimaryAction"
      v-on="$listeners"
      @action-EDIT="openDashboardPanelConfigModal()">
      
      <template #title><slot name="title">{{ title }}</slot></template>
      <template v-if="$slots.subtitle" #subtitle><slot name="subtitle"></slot></template>
    </PageHeaderTitleNavigation>      

    <div v-if="userLevelSaveConfig" class="box__container">
      <Pill>Einstellungen werden gespeichert für: <a @click="openUserLevelConfigSelectModal()"><UserLevelText :value="userLevelSaveConfig" /></a></Pill>
    </div>

    <div v-if="$slots.topBox" class="box__container">
      <slot name="topBox"></slot>
    </div>

    <template v-if="!loading && !isExternalLoading && widgetsActive.length">
      <SortableList :items="widgetsActive" :disabled="!isEditAvailable || !config.sortable" @orderChanged="orderChanged($event)">
        <div :class="isMasonry ? 'dashboard-masonry-panel__widgets' : (is3Columns ? 'dashboard-three-columns-panel__widgets' :  'dashboard-panel__widgets')" data-sortable-container>
          <template v-for="(widget, i) in widgetsActive">
            <DashboardPanelWidget :key="widget.name" data-sortable-item
              :isMasonry="isMasonry"
              :is3Columns="is3Columns"
              :canEdit="canEdit"
              :widget="widget"
              :tid="_generateTidFromString(id + widget.name)"
              :showToolbar="isEditAvailable"
              :actions="actions"
              @executeAction="$emit('executeAction', { action: $event, widget, component: $refs['comp' + i] ? $refs['comp' + i][0] : widget.name})"
              @changeSize="changeSize(widget, $event)"
              @remove="removeWidget(widget)"
            >
              <template v-if="isLoading(widget)">
                <GhostLoading height="100%" :style="{ height: '100%', minHeight: '175px', }">
                  <Block height="100%" />
                </GhostLoading>
              </template>
              <template v-else>
                <component :ref="'comp' + i" v-if="widget.component" :is="widget.component" v-bind="widget.props"></component>
                <slot v-else-if="widget.name" :name="widget.name"></slot>
              </template>
            </DashboardPanelWidget>
          </template>
        </div>
      </SortableList>
    </template>
    <div v-else-if="loading || isExternalLoading" class="dashboard-panel__widgets">
      <div v-for="n in widgetsActive.length" :key="n" class="dashboard-panel-widget box__container">
        <GhostLoading>
          <Block type="title" />
          <Block :height="175" />
        </GhostLoading>
      </div>
    </div>
    <div v-else class="box__container text-centered">
      <div>Keine Daten</div>
    </div>

    <DashboardPanelConfigModal ref="dashboardPanelConfigModal" :id="id" :widgets="widgets" :externalConfig="externalConfig" :hiddenCards="hiddenCards" 
      :ignoreUserLevelConfig="ignoreUserLevelConfig" @saved="onSaved" @onRestoreDefault="restoreDefaultConfig($event)" />

    <DashboardPanelUserLevelSelectModal ref="dashboardPanelUserLevelSelectModal" :id="id" :userLevel="userLevelSaveConfig"
      @userLevelChanged="userLevelSaveConfig = $event" @saveConfig="savePendingConfiguration($event)" @onRestoreDefault="restoreDefaultConfig($event)" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DASHBOARD_TYPES from './store/types';
import FC_CONFIG_TYPES from '@/store/fcConfig/types';
import FC_CONFIG, { FC_CONFIG_USER_LEVEL, } from '@/configs/fcConfig.js';

import { PhPencilLine } from 'phosphor-vue';
import SortableList from '@/components/core/SortableList.vue';
import DashboardPanelWidget from './DashboardPanelWidget.vue';
import DashboardPanelConfigModal from './DashboardPanelConfigModal.vue';
import DashboardPanelUserLevelSelectModal from './DashboardPanelUserLevelSelectModal.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue';
import Pill from '@/components/core/Pill.vue';
import UserLevelText from '@/components/core/fcConfig/UserLevelText.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';

import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import { removeInvalidWidget, assignWidget, contentToSave, } from '@/components/dashboard/dashboard-panel-utils';

const DEFAULT_CONFIG = {
  sortable: true,
};

function usesInitialUserLevel(initialUserLevel) {
  if(initialUserLevel === FC_CONFIG_USER_LEVEL.GLOBAL 
    || initialUserLevel === FC_CONFIG_USER_LEVEL.MAKLER_STRUKTUR 
    || initialUserLevel === FC_CONFIG_USER_LEVEL.MAKLER_MASTER_STRUKTUR
    || initialUserLevel === FC_CONFIG_USER_LEVEL.MAKLER_PERSONEN) {
      return null;
  }

  return initialUserLevel;
}

export default {
  name: 'DashboardPanel',
  mixins: [InteractiveHelpCommonsMixin],
  props: {
    // DashboardPanel id must be different and unique. This "id" is being used to save the dashboard panel config into backend
    // a UUID randomly generated is enough. https://www.uuidgenerator.net/version4 or any other generator.
    id: {
      type: String,
      required: true,
      validator: (value) => {
        const notEmpty = !!value && !!value.trim();
        return notEmpty;
      },
    },
    title: {
      type: String,
      default: 'Übersicht',
    },
    subtitle: {
      type: String,
    },
    headerActions: {
      type: Array,
      default: () => [],
    },
    defaultOptionsMenu: {
      type: Array,
      default: () => [],
    },
    noPrimaryAction: {
      type: Boolean,
      default: true,
    },
    isMasonry: {
      type: Boolean,
      default: false,
    },
    is3Columns: {
      type: Boolean,
      default: false,
    },    
    /**
     * Example:
     * data: {
     *    widgets: [
     *      {
     *        name: 'Widget1', // it is required and must be unique for the configuration
     *        title: 'Widget 1',
     *        visible: () => <visible condition>,
     *      },
     *      {
     *        name: 'Widget2', // it is required and must be unique for the configuration
     *        title: 'Widget 2',
     *        component: () => import('<component path>'),
     *        visible: () => <visible condition>,
     *      },
     *      {
     *        name: 'Widget3', // it is required and must be unique for the configuration
     *        title: 'Widget 3',
     *      },
     *      {
     *        name: 'Widget4', // it is required and must be unique for the configuration
     *        title: 'Widget 4',
     *        component: () => import('<component path>'),
     *      },
     *    ],
     * }
     */
    data: {
      type: Object,
      default: () => ({}),
      validator: (data) => {
        if(!data?.widgets?.length) return true;

        const everyHasName = data?.widgets?.every(w => w.name);
        if(!everyHasName) {
          console.error('"data" all "widgets" must have a "name" property!')
        }
        return everyHasName;
      },
    },
    actions: {
      type: Array,
      default: () => [],
    },
    ignoreUserLevelConfig: {
      type: Boolean,
      default: false,
    },
    externalConfig: {
      type: Array,
      default: () => [],
    },
    isExternalLoading: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    editDisabled: {
      type: Boolean,
      default: false,
    },
    hidePageHeader: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['saved', 'restored', 'widgetsActived', 'executeAction'],
  data() {
    return {
      userLevelSaveConfig: null,
      pendingConfigurationWidgets: [],
      loading: false,
      widgetsIntern: [],
      widgetsRemoved: [],
    };
  },
  computed: {
    ...mapGetters({
      fcConfigDashboardPanelConfig: FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_DASHBOARD_PANEL,
      hiddenCards: DASHBOARD_TYPES.GETTERS.GET_USER_DASHBOARD_HIDE_CARDS,
      userLevelValues: FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_USER_LEVEL_VALUES,
      userLevelDefault: FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_USER_LEVEL_DEFAULT,
      isConfigurationReadOnly: FC_CONFIG_TYPES.GETTERS.IS_CONFIGURATION_READ_ONLY,
    }),
    dashboardPanelConfig() {
      return this.id in this.fcConfigDashboardPanelConfig && this.fcConfigDashboardPanelConfig[this.id] || null;
    },
    isEditAvailable() {
      return this.hasId && !this.isConfigurationReadOnly && !this.editDisabled;
    },
    headerActionsInternal() {
      const actions = [];
      if(this.isEditAvailable) {
        actions.push(PageHeaderSimpleAction('EDIT', 'Anpassen'));
      }
      return [
        ...this.headerActions,
        ...actions,
      ];
    },
    currentUserLevel() {
      return this.dashboardPanelConfig?.userLevel;
    },
    autoSaveUserLevel() {
      return this.userLevelValues?.length <= 1 ? this.userLevelDefault : null;
    },
    config() {
      return this.data?.config || DEFAULT_CONFIG;
    },
    hasId() {
      return !!this.id;
    },
    widgets() {
      const widgets = this.data?.widgets || [];
      return widgets
        .filter(w => w.name)
        .filter(widget => this.isVisible(widget));
    },
    savedWidgets() {
      const savedConfig = this.dashboardPanelConfig;

      if (!this.id || !savedConfig) {
        return null;
      }

      if (!savedConfig?.content) {
        return null;
      }

      return JSON.parse(savedConfig.content);
    },
    configuredWidgets() {
      const { widgets, savedWidgets, externalConfig, } = this;
      return removeInvalidWidget(assignWidget(widgets, savedWidgets, externalConfig));
    },
    widgetsActive() {
      const widgetsActive = this.widgetsIntern?.length 
        ? this.widgetsIntern.filter(cw => cw.removed !== true) 
        : [...this.widgets];
      const activedWidgets = widgetsActive?.filter(widget => this.isVisible(widget));
      this.$emit('widgetsActived', activedWidgets.map(widget => widget.name))
      return activedWidgets;
    },
    widgetsNeverConfigured() {
      return this.widgets.filter(w => {
        return !this.configuredWidgets || !this.configuredWidgets.some(cw => cw.name === w.name);
      });
    },
  },
  watch: {
    configuredWidgets: {
      handler() {
        this.setWidgetsIntern();
        this.setWidgetsRemoved();
      },
      immediate: true,
    },
    data() {
      this.setWidgetsIntern();
      this.setWidgetsRemoved();
    },
  },
  methods: {
    async loadConfig(forceReload = false) {
      if (!this.id || this.editDisabled) {
        return ;
      }

      const payload = {
        configId: this.id,
        configType: FC_CONFIG.DASHBOARD_PANEL_CONFIG,
        forceReload,
      };

      this.loading = true;
      await this.$store.dispatch(FC_CONFIG_TYPES.ACTIONS.LOAD_FC_CONFIG, payload);
      this.loading = false;
    },
    setWidgetsIntern() {
      const configuredWidgets = this.configuredWidgets || [];
      const widgetsIntern = configuredWidgets.concat(this.widgetsNeverConfigured);

      this.widgetsIntern = [...widgetsIntern];
    },
    setWidgetsRemoved() {
      this.widgetsRemoved = this.widgetsIntern.filter(w => w.removed === true);
    },
    openUserLevelConfigSelectModal() {
      if(!this.userLevelSaveConfig) {
        this.userLevelSaveConfig = this.currentUserLevel;
      }

      this.$refs.dashboardPanelUserLevelSelectModal?.open();
    },
    async savePendingConfiguration(userLevelSaveConfig) {
      if(this.pendingConfigurationWidgets?.length) {
        await this.saveConfig([...this.pendingConfigurationWidgets], userLevelSaveConfig);
        this.pendingConfigurationWidgets = [];
      } else {
        await this.saveConfig([...this.widgetsIntern], userLevelSaveConfig);
      }
    },
    async saveConfig(configuredWidgets, userLevel) {
      if(!this.id) {
        return;
      }

      if(this.ignoreUserLevelConfig !== true && !userLevel && !this.userLevelSaveConfig) {
        this.openUserLevelConfigSelectModal();
        this.pendingConfigurationWidgets = [...configuredWidgets];
        return;
      }

      const payload = {
        configId: this.id,
        configType: FC_CONFIG.DASHBOARD_PANEL_CONFIG,
        content: contentToSave(configuredWidgets),
        userLevel: userLevel || this.userLevelSaveConfig || this.currentUserLevel || null,
      };

      await this.$store.dispatch(FC_CONFIG_TYPES.ACTIONS.SAVE_FC_CONFIG, payload);

      this.$emit('saved', [ ...configuredWidgets ]);
    },
    async onSaved() {
      await this.loadConfig(true);

      this.$emit('saved', [ ...this.savedWidgets, ]);
    },
    async restoreDefaultConfig(userLevel) {
      if (!this.id) return ;

      const deletePayload = {
        configId: this.id,
        configType: FC_CONFIG.DASHBOARD_PANEL_CONFIG,
        userLevel,
      };

      await this.$store.dispatch(FC_CONFIG_TYPES.ACTIONS.DELETE_FC_CONFIG, deletePayload);

      const widgets = (() => {
        if (this.savedWidgets?.length) {
          return this.savedWidgets;
        } else {
          return [ ...this.widgetsIntern, ...this.widgetsRemoved, ].map(widget => ({ ...widget, removed: false, }));
        }
      })();

      this.$emit('restored', [ ...widgets, ]);
    },
    openDashboardPanelConfigModal() {
      const initialUserLevel = this.userLevelSaveConfig || usesInitialUserLevel(this.currentUserLevel) || this.userLevelDefault;
      this.$refs.dashboardPanelConfigModal.open(initialUserLevel);
    },
    isVisible(widget) {
      return 'visible' in widget ? widget.visible() : (this.hiddenCards?.length ? !this.hiddenCards.includes(widget.name) : true);
    },
    isLoading(widget) {
      return 'loading' in widget ? widget.loading() : false;
    },
    orderChanged(listOrdered) {
      this.saveConfig([...listOrdered, ...this.widgetsRemoved], this.autoSaveUserLevel);
    },
    async changeSize(widget, size) {
      const widgetIndex = this.widgetsIntern.findIndex(iw => iw.name === widget.name);
      if(widgetIndex < 0) return;

      const widgetsIntern = this.widgetsIntern;
      widgetsIntern[widgetIndex].size = size;

      await this.saveConfig([...widgetsIntern], this.autoSaveUserLevel);

      dispatchEvent(new Event('resize'));
    },
    removeWidget(widget) {
      const widgetIndex = this.widgetsIntern.findIndex(iw => iw.name === widget.name);
      if(widgetIndex < 0) return;

      const widgetsIntern = [...this.widgetsIntern];
      widgetsIntern.splice(widgetIndex, 1);

      const widgetRemoved = {
        ...widget,
        removed: true,
      };
      this.saveConfig([...widgetsIntern, widgetRemoved], this.autoSaveUserLevel);
    },
  },
  mounted() {
    if (!this.id || !this.dashboardPanelConfig) {
      this.loadConfig();
    }
    
  },
  components: {
    PhPencilLine,
    SortableList,
    DashboardPanelWidget,
    DashboardPanelConfigModal,
    DashboardPanelUserLevelSelectModal,
    BaseButton,
    AnimatedSpinner,
    Pill,
    UserLevelText,
    GhostLoading,
    Block,
    PageHeaderTitleNavigation,
  },
}
</script>

<style scoped>
.dashboard-panel__header {
  display: flex;
}

.dashboard-panel__title {
  flex-shrink: 0;
}

.dashboard-panel__widgets {
  margin: 0 -8px;
  display: flex;
  flex-wrap: wrap;
}


.dashboard-three-columns-panel__widgets {
  --gap: 8px;
  --columns: 3;
  display: flex;
  gap: var(--gap);
  flex-wrap: wrap;
}

.dashboard-three-columns-panel__widgets > div {
  width: calc((100% / var(--columns)) - var(--gap) + (var(--gap) / var(--columns)));
  margin-bottom: 0px;
}

.dashboard-masonry-panel__widgets {
  column-count: 3;
  column-gap: 1em;
}

@media screen and (max-width: 1200px) {
  .dashboard-masonry-panel__widgets {
    column-count: 2;
    column-gap: 1em;
  }

  .dashboard-three-columns-panel__widgets {
    --columns: 2;
  }

}

@media screen and (max-width: 767px) {
  .dashboard-panel__header {
    flex-flow: column;
  }

  .dashboard-masonry-panel__widgets {
    column-count: 1;
    column-gap: 1em;
  }

  .dashboard-three-columns-panel__widgets {
    --columns: 1;
  }


}
</style>
