<template>
  <div>
    <div class="box__container">
      <div class="box__title">PEP - Politisch exponierte Person</div>

      <div class="row">
        <div class="col-12">
          <InputToggleSwitch 
            label="Politisch exponierte Person / VIP" 
            :inLineLabel="true"
            v-model="pepActive" 
            @input="addCustomerDataEdited('pepActive', $event)" />
        </div>
      </div>

      <div v-if="pepActive" class="forms__input--half-size">
        <div class="row">
          <div class="col-lg-7 col-md-8 col-12">
            <InputField 
              label="Funktion" 
              v-model="pepText"
              :disabled="true" />
          </div>
          <div class="col-lg-5 col-md-4 col-12 mt-lg-24px mt-md-24px mt-sm-16px">
            <BaseButton isSecondary :disabled="true" class="mt-0">PEP-WIS Abgleich</BaseButton>
          </div>
        </div>
      </div>
    </div>

    <Fatca/>

    <template v-if="pepActive">
      <Firma v-if="isFirma" />
      <Privat v-else />
    </template>
  </div>
</template>

<script>
import CUSTOMERDATA_TYPES from '@/store/customerData/types';

import { isTrue } from '@/helpers/mapping.js';

import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import InputField from '@/components/core/forms/InputField.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import Privat from './politisch-exponierte-person/Privat.vue';
import Firma from './politisch-exponierte-person/Firma.vue';
import Fatca from './Fatca.vue';

import persoenlicheDatenMixin from './persoenliche-daten-mixin.js';


export default {
  mixins: [persoenlicheDatenMixin],
  data() {
    return {
      ...this.initialState(),
    };
  },
  computed: {
    pep() {
      return this.customerDataEdited?.pep || this.customerData?.pep;
    },
    pepText() {
      if(isTrue(this.pep?.pep_aktiv) || isTrue(this.pep?.vip_aktiv)) {
        return isTrue(this.pep?.pep_aktiv) ? this.pep?.pep_funktion : this.pep?.vip_funktion;
      } else if(isTrue(this.pep?.pep_ehe_aktiv) || isTrue(this.pep?.vip_ehe_aktiv)) {
        const text = (isTrue(this.pep?.pep_ehe_aktiv) ? this.pep?.pep_ehe_text : this.pep?.vip_ehe_text) || '';
        return `(Ehepartner): ${text}`;
      }
      return "";
    },
  },
  methods: {
    initialState() {
      return {
        pepActive: false,
      };
    },
    updateGroupsIntoData() {
      this.updateFieldIntoData('pepActive');
    },
  },
  mounted() {
    this.updateGroupsIntoData();
  },
  components: {
    InputToggleSwitch,
    InputField,
    BaseButton,
    Privat,
    Firma,
    Fatca,
  },
}
</script>
