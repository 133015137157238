import axios from 'axios';

import MENU_CONFIG_TYPES, { CONFIGURE_MENU_CONFIG_LOADING_ID } from './types';
import MENU_TYPES from '@/store/menu/types';
import CORE_TYPES from '@/store/core/types';
import LOG_TYPES from '@/store/log/types';
import FC_CONFIG_TYPES from '@/store/fcConfig/types';
import LOADING_TYPES from '@/store/loading/types';
import FC_CONFIG, { FC_CONFIG_USER_LEVEL, } from '@/configs/fcConfig';
import { VIEW_ROLES, ROLES, } from '@/router/roles';

import { _createRouter, } from '@/router';
import { createAppMenu, } from '@/menu';
import { contentToSave, permissionConfigToSave } from '@/components/core/option-menu/option-menu-utils';

import { makeQueryParam } from '@/helpers/utils-helper';
import { normalizeMenu } from '@/components/core/option-menu/option-menu-utils';

const USER_LEVEL_HIERARCHY = Object.freeze({
  [FC_CONFIG_USER_LEVEL.MAKLER_MASTER_STRUKTUR]: [
    FC_CONFIG_USER_LEVEL.MAKLER, 
    FC_CONFIG_USER_LEVEL.MAKLER_STRUKTUR, 
    FC_CONFIG_USER_LEVEL.MAKLER_PERSONEN, 
    FC_CONFIG_USER_LEVEL.KUNDE,
  ],
  [FC_CONFIG_USER_LEVEL.MAKLER]: [
    FC_CONFIG_USER_LEVEL.MAKLER_STRUKTUR, 
    FC_CONFIG_USER_LEVEL.MAKLER_PERSONEN, 
  ],
  [FC_CONFIG_USER_LEVEL.MAKLER_STRUKTUR]: [],
  [FC_CONFIG_USER_LEVEL.MAKLER_PERSONEN]: [],
  [FC_CONFIG_USER_LEVEL.KUNDE]: [],
});

const config = { defaultSpinner: true, };

export default {

  // Common
  async [MENU_CONFIG_TYPES.ACTIONS.CONFIGURE_MENU_CONFIG]({ getters, commit, dispatch, }, { configContext, unternr = null, isPermissionConfigurable = false, userLevel = null } = {}) {
    if(!configContext) return;

    dispatch(LOADING_TYPES.ACTIONS.START_LOADING, CONFIGURE_MENU_CONFIG_LOADING_ID);

    const hasRoles = getters[CORE_TYPES.GETTERS.HAS_ROLES];

    // set selectedUnternr - NOTE: it should only be set on Makler context when a Makler selects a Mitarbeiter to edit
    if(hasRoles([VIEW_ROLES.VIEW_BROKER])) {
      commit(MENU_CONFIG_TYPES.MUTATIONS.SET_SELECTED_UNTERNR, { 
        configContext, 
        unternr,
      });
    }

    // config user levels
    dispatch(MENU_CONFIG_TYPES.ACTIONS.CONFIGURE_USER_LEVEL, { configContext, isPermissionConfigurable, });

    // find menu permission config
    const isInternGlobalConfigAllowed = getters[MENU_CONFIG_TYPES.GETTERS.IS_INTERN_GLOBAL_CONFIG_ALLOWED];
    const hasSelectedUnternr = getters[MENU_CONFIG_TYPES.GETTERS.HAS_SELECTED_UNTERNR](configContext);

    if (isPermissionConfigurable) {
      if(isInternGlobalConfigAllowed) {
        await dispatch(MENU_CONFIG_TYPES.ACTIONS.FIND_ALL_OPTIONS_MENU_PERMISSION_CONFIG_GLOBAL, { configContext, });
      } else if(hasSelectedUnternr) {
        await dispatch(MENU_CONFIG_TYPES.ACTIONS.FIND_ALL_OPTIONS_MENU_PERMISSION_CONFIG_MITARBEITER, { configContext, });
      } else {
        await dispatch(MENU_CONFIG_TYPES.ACTIONS.FIND_ALL_OPTIONS_MENU_PERMISSION_CONFIG, { configContext, });
      }
    }

    // configure menu
    let menuStructure = null;
    if (!isInternGlobalConfigAllowed) {
      menuStructure = await dispatch(MENU_TYPES.ACTIONS.FIND_MENU_STRUCTURE);
    }

    const roles = getters[MENU_CONFIG_TYPES.GETTERS.OPTIONS_MENU_CONFIG_ROLES](configContext, userLevel);
    const router = _createRouter(roles);
    const appMenu = await createAppMenu(router, {
      menuStructure,
      configContext,
      disableResolve: isInternGlobalConfigAllowed, // resolve is disabled for global config because it depends on Makler/Kunde specific context
    });

    // Flat menu config
    commit(MENU_CONFIG_TYPES.MUTATIONS.SET_FLAT_MENU_CONFIG, { 
      configContext, 
      flatMenuConfig: [ ...appMenu.flatMenu, ], 
    });

    dispatch(LOADING_TYPES.ACTIONS.STOP_LOADING, CONFIGURE_MENU_CONFIG_LOADING_ID);
  },
  async [MENU_CONFIG_TYPES.ACTIONS.CONFIGURE_USER_LEVEL]({ dispatch, getters, commit, }, { configContext, isPermissionConfigurable = false } = {}) {
    dispatch(LOG_TYPES.ACTIONS.LOG, `MENU_CONFIG_TYPES.ACTIONS.CONFIGURE_USER_LEVEL >>> configContext = "${configContext}"`);
    dispatch(LOG_TYPES.ACTIONS.LOG, `MENU_CONFIG_TYPES.ACTIONS.CONFIGURE_USER_LEVEL >>> isPermissionConfigurable = ${isPermissionConfigurable}`);

    if (!configContext) return;

    const hasRoles = getters[CORE_TYPES.GETTERS.HAS_ROLES];

    const isInternGlobalConfigAllowed = getters[MENU_CONFIG_TYPES.GETTERS.IS_INTERN_GLOBAL_CONFIG_ALLOWED];
    const hasSelectedUnternr = getters[MENU_CONFIG_TYPES.GETTERS.HAS_SELECTED_UNTERNR](configContext);

    const isInternView = hasRoles([VIEW_ROLES.VIEW_INTERN]);
    const isBrokerView = hasRoles([VIEW_ROLES.VIEW_BROKER]);
    const istUserUnternr = hasRoles([ROLES.IS_USER_UNTERNR]);
    const isCustomerViewAsBypass = hasRoles([VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS]);
    const isCustomerViewOnlyBypassSlash = hasRoles([[VIEW_ROLES.VIEW_CUSTOMER_ONLY, ROLES.IS_BYPASS_SLASH]]);
    const isCustomerViewOnly = hasRoles([VIEW_ROLES.VIEW_CUSTOMER_ONLY]);

    const userLevels = [];

    if(isInternGlobalConfigAllowed && isPermissionConfigurable) { // Global config
      userLevels.push(FC_CONFIG_USER_LEVEL.MAKLER_MASTER_STRUKTUR);
    } else if(isInternView) { // Intern
      userLevels.push(FC_CONFIG_USER_LEVEL.INTERN);
    } else if(isBrokerView) {
      userLevels.push(FC_CONFIG_USER_LEVEL.MAKLER);

      if(!istUserUnternr && !hasSelectedUnternr) { // Broker
        userLevels.push(FC_CONFIG_USER_LEVEL.MAKLER_STRUKTUR);
        userLevels.push(FC_CONFIG_USER_LEVEL.MAKLER_PERSONEN);
      }
    } else if(isCustomerViewAsBypass || isCustomerViewOnlyBypassSlash) { // Customer bypass / bypass slash
      userLevels.push(FC_CONFIG_USER_LEVEL.MAKLER);

      if(isCustomerViewOnlyBypassSlash || !isPermissionConfigurable) {
        userLevels.push(FC_CONFIG_USER_LEVEL.KUNDE);
      }

      if(!istUserUnternr) {
        userLevels.push(FC_CONFIG_USER_LEVEL.MAKLER_STRUKTUR);
      }
    } else if(isCustomerViewOnly) { // Customer only
      userLevels.push(FC_CONFIG_USER_LEVEL.KUNDE); 
    }

    commit(MENU_CONFIG_TYPES.MUTATIONS.SET_USER_LEVELS, { configContext, userLevels });
  },
  [MENU_CONFIG_TYPES.ACTIONS.RESET_ALL]({ dispatch, commit }, { configContext }) {
    dispatch(LOG_TYPES.ACTIONS.LOG, `MENU_CONFIG_TYPES.ACTIONS.RESET_ALL >>> configContext = "${configContext}"`);

    if (!configContext) return;

    commit(MENU_CONFIG_TYPES.MUTATIONS.RESET_SELECTED_UNTERNR, { configContext });
    commit(MENU_CONFIG_TYPES.MUTATIONS.RESET_COMMON_DATA, { configContext });
    commit(MENU_CONFIG_TYPES.MUTATIONS.RESET_OPTIONS_MENU_CONFIG, { configContext });
    commit(MENU_CONFIG_TYPES.MUTATIONS.RESET_OPTIONS_MENU_CONFIG_EDITED, { configContext });
    commit(MENU_CONFIG_TYPES.MUTATIONS.RESET_OPTIONS_MENU_PERMISSION_CONFIG_EDITED, { configContext });
  },

  // Options Menu Config
  async [MENU_CONFIG_TYPES.ACTIONS.FIND_OPTIONS_MENU_CONFIG]({ getters, commit, }, { configContext, userLevel, configId, }) {
    if (!configContext || !userLevel || !configId) return;

    const configType = FC_CONFIG.OPTION_MENU_CONFIG;

    const paramsParts = [];
    paramsParts.push(`configId=${configId}`);
    paramsParts.push(`configType=${configType}`);
    paramsParts.push(`userLevel=${userLevel}`);

    const params = paramsParts.join('&');

    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/fcconfig/get_config_by_user_level?${params}`, config);
    const fcConfigs = response?.data || [];
    commit(MENU_CONFIG_TYPES.MUTATIONS.SET_OPTIONS_MENU_CONFIG, { configContext, userLevel, configId, config: fcConfigs?.[0], });
  },
  async [MENU_CONFIG_TYPES.ACTIONS.SAVE_ALL_OPTIONS_MENU_CONFIG]({ getters, state, dispatch, commit, }, { configContext }) {
    dispatch(LOG_TYPES.ACTIONS.LOG, `MENU_CONFIG_TYPES.ACTIONS.SAVE_ALL_OPTIONS_MENU_CONFIG >>> configContext = "${configContext}"`);

    const hasEdited = getters[MENU_CONFIG_TYPES.GETTERS.HAS_MENU_CONFIG_EDITED](configContext);
    if(!configContext || !hasEdited) return;

    const menuConfigEdited = { ...state.optionsMenuConfigEdited?.[configContext] || {}, };
    const configIds = Object.keys(menuConfigEdited);
    const promises = configIds.flatMap(configId => {
      return Object.keys(menuConfigEdited[configId]).map(async userLevel => {
        const content = menuConfigEdited[configId][userLevel];
        const payload = {
          configId,
          configType: FC_CONFIG.OPTION_MENU_CONFIG,
          content: contentToSave(content, userLevel),
          userLevel,
        };
        return await dispatch(FC_CONFIG_TYPES.ACTIONS.SAVE_ONLY, payload);
      });
    });

    await Promise.all(promises);

    // reset loaded
    commit(MENU_CONFIG_TYPES.MUTATIONS.RESET_OPTIONS_MENU_CONFIG, { configContext });

    // reset edited
    commit(MENU_CONFIG_TYPES.MUTATIONS.RESET_OPTIONS_MENU_CONFIG_EDITED, { configContext });

    // reload options menu
    dispatch(MENU_TYPES.ACTIONS.LOAD_OPTIONS_MENU_CONFIGS, { configIds, forceReload: true, });
  },
  async [MENU_CONFIG_TYPES.ACTIONS.DELETE_OPTIONS_MENU_CONFIG]({ commit, dispatch, }, { configContext, configId, userLevels, }) {
    if(!configContext || !configId || !userLevels?.length) return;

    const promises = userLevels.map(async userLevel => {
      // reset loaded config
      commit(MENU_CONFIG_TYPES.MUTATIONS.RESET_OPTIONS_MENU_CONFIG_BY_USER_LEVEL, { configContext, configId, userLevel, });

      // reset config edited
      commit(MENU_CONFIG_TYPES.MUTATIONS.RESET_OPTIONS_MENU_CONFIG_EDITED_BY_USER_LEVEL, { configContext, configId, userLevel, });

      // delete
      const deletePayload = { configId, configType: FC_CONFIG.OPTION_MENU_CONFIG, userLevel, };
      await dispatch(FC_CONFIG_TYPES.ACTIONS.DELETE_ONLY, deletePayload);
    });

    await Promise.all(promises);

    await dispatch(MENU_TYPES.ACTIONS.LOAD_OPTIONS_MENU_CONFIGS, { 
      configIds: [configId], 
      forceReload: true, 
    });
  },

  async [MENU_CONFIG_TYPES.ACTIONS.COPY_OPTIONS_MENU_CONFIG_TO_USER_LEVEL]({ getters, commit, }, { configContext, configId, userLevelSource, userLevelDest, }) {
    if(!configContext || !configId || !userLevelSource || !userLevelDest) return;

    const sourceOptionMenuConfig = getters[MENU_CONFIG_TYPES.GETTERS._CURRENT_OPTIONS_MENU_CONFIG](configContext, userLevelSource, configId);

    const appNavigationConfigByPathFn = getters[MENU_CONFIG_TYPES.GETTERS.APP_NAVIGATION_CONFIG_BY_PATH];
    const appNavigation = appNavigationConfigByPathFn(configContext, configId);

    const configuredMenu = normalizeMenu(appNavigation.optionMenu, appNavigation.currentOptionMenu, sourceOptionMenuConfig);
    commit(MENU_CONFIG_TYPES.MUTATIONS.SET_OPTIONS_MENU_CONFIG_EDITED, {
      configContext,
      userLevel: userLevelDest,
      configId,
      changedMenu: JSON.parse(JSON.stringify([ ...configuredMenu, ])),
    });
  },

  // Apply Options Menu Config - Unterstruktur
  async [MENU_CONFIG_TYPES.ACTIONS.FIND_ALL_OPTIONS_MENU_CONFIG_UNTERSTRUKTUR]({ getters, }, { configId, onlyWithConfig = false }) {
    if(!configId) return;

    const params = makeQueryParam({ 
      configId, 
      onlyWithConfig, 
    });
    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/menuConfig/find_all_options_menu_config_unterstruktur?${params}`, config);
    const optionsMenuConfigs = [ ...response?.data || [] ];
    return optionsMenuConfigs.map(config => {
      const parsedContent = JSON.parse(config.content || '[]');
      return {
        ...config,
        content: parsedContent.length > 0 ? parsedContent : null,
      };
    });
  },
  async [MENU_CONFIG_TYPES.ACTIONS.APPLY_OPTIONS_MENU_CONFIG_TO_ALL_UNTERSTRUKTUR]({ getters, }, { configContext, configId, ignored }) {
    if(!configContext || !configId) return;

    const content = getters[MENU_CONFIG_TYPES.GETTERS._CURRENT_OPTIONS_MENU_CONFIG](configContext, FC_CONFIG_USER_LEVEL.MAKLER_STRUKTUR, configId);
    const params = makeQueryParam({ configId });
    const payload = {
      ignoredMaklernrs: [ ...ignored || [] ],
      content: contentToSave(content, FC_CONFIG_USER_LEVEL.MAKLER),
    };

    const response = await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/menuConfig/apply_options_menu_config_to_all_unterstruktur?${params}`, payload, config);
    return [ ...response?.data || [] ];
  },
  async [MENU_CONFIG_TYPES.ACTIONS.APPLY_OPTIONS_MENU_CONFIG_TO_UNTERSTRUKTUR]({ getters, }, { configContext, configId, selected }) {
    if(!configContext || !configId || !selected.length) return;

    const content = getters[MENU_CONFIG_TYPES.GETTERS._CURRENT_OPTIONS_MENU_CONFIG](configContext, FC_CONFIG_USER_LEVEL.MAKLER_STRUKTUR, configId);
    const params = makeQueryParam({ configId });
    const payload = {
      selectedMaklernrs: [ ...selected ],
      content: contentToSave(content, FC_CONFIG_USER_LEVEL.MAKLER),
    };
    const response = await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/menuConfig/apply_options_menu_config_to_unterstruktur?${params}`, payload, config);
    return [ ...response?.data || [] ];
  },

  async [MENU_CONFIG_TYPES.ACTIONS.FIND_ALL_OPTIONS_MENU_PERMISSION_CONFIG_ALL_MITARBEITER]({ getters, commit }, { onlyWithConfig = false }) {
    const params = makeQueryParam({
      onlyWithConfig, 
    });
    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/menuConfig/find_all_options_menu_permission_config_all_mitarbeiter?${params}`, config);
    const optionsMenuConfigs = [ ...response?.data || [] ];
    const allOptionsMenuPermissionConfigAllMitarbeiter = optionsMenuConfigs.map(config => {
      const parsedContent = config.content ? JSON.parse(config.content) : null;
      return {
        ...config,
        content: parsedContent,
      };
    });

    commit(MENU_CONFIG_TYPES.MUTATIONS.FIND_ALL_OPTIONS_MENU_PERMISSION_CONFIG_ALL_MITARBEITER_SUCCESS, allOptionsMenuPermissionConfigAllMitarbeiter);
  },
  // Apply Options Menu Config - Mitarbeiter
  async [MENU_CONFIG_TYPES.ACTIONS.FIND_ALL_OPTIONS_MENU_CONFIG_MITARBEITER]({ getters }, { configId, onlyWithConfig = false }) {
    if(!configId) return;

    const params = makeQueryParam({ 
      configId, 
      onlyWithConfig, 
    });
    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/menuConfig/find_all_options_menu_config_mitarbeiter?${params}`, config);
    const optionsMenuConfigs = [ ...response?.data || [] ];
    return optionsMenuConfigs.map(config => {
      const parsedContent = JSON.parse(config.content || '[]');
      return {
        ...config,
        content: parsedContent.length > 0 ? parsedContent : null,
      };
    });
  },
  async [MENU_CONFIG_TYPES.ACTIONS.APPLY_OPTIONS_MENU_CONFIG_TO_ALL_MITARBEITER]({ getters, }, { configContext, configId, ignored }) {
    if(!configContext || !configId) return;

    const content = getters[MENU_CONFIG_TYPES.GETTERS._CURRENT_OPTIONS_MENU_CONFIG](configContext, FC_CONFIG_USER_LEVEL.MAKLER_PERSONEN, configId);
    const params = makeQueryParam({ configId });
    const payload = {
      ignoredUnternrs: [ ...ignored || [] ],
      content: contentToSave(content, FC_CONFIG_USER_LEVEL.MAKLER),
    };

    const response = await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/menuConfig/apply_options_menu_config_to_all_mitarbeiter?${params}`, payload, config);
    return [ ...response?.data || [] ];
  },
  async [MENU_CONFIG_TYPES.ACTIONS.APPLY_OPTIONS_MENU_CONFIG_TO_MITARBEITER]({ getters, }, { configContext, configId, selected }) {
    if(!configContext || !configId || !selected.length) return;

    const content = getters[MENU_CONFIG_TYPES.GETTERS._CURRENT_OPTIONS_MENU_CONFIG](configContext, FC_CONFIG_USER_LEVEL.MAKLER_PERSONEN, configId);
    const params = makeQueryParam({ configId });
    const payload = {
      selectedUnternrs: [ ...selected ],
      content: contentToSave(content, FC_CONFIG_USER_LEVEL.MAKLER),
    };
    const response = await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/menuConfig/apply_options_menu_config_to_mitarbeiter?${params}`, payload, config);
    return [ ...response?.data || [] ];
  },

  async [MENU_CONFIG_TYPES.ACTIONS.APPLY_OPTIONS_MENU_PERMISSION_CONFIG_TO_MITARBEITER]({ commit, dispatch }, payload) {
    await dispatch(MENU_CONFIG_TYPES.ACTIONS.SAVE_ALL_OPTIONS_MENU_PERMISSION_CONFIG_MITARBEITER, payload);

    commit(MENU_CONFIG_TYPES.MUTATIONS.RESET_OPTIONS_MENU_PERMISSION_CONFIG_EDITED);

    await dispatch(MENU_CONFIG_TYPES.ACTIONS.FIND_ALL_OPTIONS_MENU_PERMISSION_CONFIG_ALL_MITARBEITER, {
      onlyWithConfig: false,
    });
  },

  // Options Menu Config - Mitarbeiter
  async [MENU_CONFIG_TYPES.ACTIONS.FIND_OPTIONS_MENU_CONFIG_MITARBEITER]({ getters, commit, }, { configContext, configId, userLevel, }) {
    if(!getters[MENU_CONFIG_TYPES.GETTERS.HAS_SELECTED_UNTERNR](configContext)) return;

    const selectedUnternr = getters[MENU_CONFIG_TYPES.GETTERS.SELECTED_UNTERNR](configContext);

    // get config
    const configType = FC_CONFIG.OPTION_MENU_CONFIG;

    const paramsParts = [];
    paramsParts.push(`unternr=${selectedUnternr}`);
    paramsParts.push(`configId=${configId}`);
    paramsParts.push(`configType=${configType}`);
    paramsParts.push(`userLevel=${userLevel}`);

    const params = paramsParts.join('&');

    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/fcconfig/get_config_by_user_level_mitarbeiter?${params}`, config);
    const fcConfigs = response?.data || [];
    commit(MENU_CONFIG_TYPES.MUTATIONS.SET_OPTIONS_MENU_CONFIG, { configContext, userLevel, configId, config: fcConfigs?.[0], });
  },
  async [MENU_CONFIG_TYPES.ACTIONS.SAVE_ALL_OPTIONS_MENU_CONFIG_MITARBEITER]({ dispatch, state, getters, commit, }, { configContext }) {
    dispatch(LOG_TYPES.ACTIONS.LOG, `MENU_CONFIG_TYPES.ACTIONS.SAVE_ALL_OPTIONS_MENU_CONFIG_MITARBEITER >>> configContext = "${configContext}"`);

    const hasEdited = getters[MENU_CONFIG_TYPES.GETTERS.HAS_MENU_CONFIG_EDITED](configContext);
    const hasSelectedUnternr = getters[MENU_CONFIG_TYPES.GETTERS.HAS_SELECTED_UNTERNR](configContext);
    if(!hasEdited || !hasSelectedUnternr) return;

    const selectedUnternr = getters[MENU_CONFIG_TYPES.GETTERS.SELECTED_UNTERNR](configContext);

    // save config
    const menuConfigEdited = { ...state.optionsMenuConfigEdited?.[configContext] || {}, };
    const fcConfigs = Object.keys(menuConfigEdited).flatMap(configId => {
      return Object.keys(menuConfigEdited[configId]).map(userLevel => {
        const content = menuConfigEdited[configId][userLevel];
        return {
          configId,
          configType: FC_CONFIG.OPTION_MENU_CONFIG,
          content: contentToSave(content, userLevel),
          userLevel,
        };
      });
    });

    const paramsParts = [];
    paramsParts.push(`unternr=${selectedUnternr}`);

    const params = paramsParts.join('&');

    await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/fcconfig/save_config_mitarbeiter?${params}`, fcConfigs, config);

    // reset loaded config
    commit(MENU_CONFIG_TYPES.MUTATIONS.RESET_OPTIONS_MENU_CONFIG, { configContext });

    // reset config edited
    commit(MENU_CONFIG_TYPES.MUTATIONS.RESET_OPTIONS_MENU_CONFIG_EDITED, { configContext });
  },
  async [MENU_CONFIG_TYPES.ACTIONS.DELETE_OPTIONS_MENU_CONFIG_MITARBEITER]({ getters, commit, }, { configContext, configId, }) {
    const hasSelectedUnternr = getters[MENU_CONFIG_TYPES.GETTERS.HAS_SELECTED_UNTERNR](configContext);
    if(!hasSelectedUnternr) return;

    const selectedUnternr = getters[MENU_CONFIG_TYPES.GETTERS.SELECTED_UNTERNR](configContext);

    const userLevel = FC_CONFIG_USER_LEVEL.MAKLER;

    // reset loaded config
    commit(MENU_CONFIG_TYPES.MUTATIONS.RESET_OPTIONS_MENU_CONFIG_BY_USER_LEVEL, { configContext, configId, userLevel, });

    // reset config edited
    commit(MENU_CONFIG_TYPES.MUTATIONS.RESET_OPTIONS_MENU_CONFIG_EDITED_BY_USER_LEVEL, { configContext, configId, userLevel, });

    // delete config
    const configType = FC_CONFIG.OPTION_MENU_CONFIG;

    const paramsParts = [];
    paramsParts.push(`unternr=${selectedUnternr}`);
    paramsParts.push(`configId=${configId}`);
    paramsParts.push(`configType=${configType}`);
    paramsParts.push(`userLevel=${userLevel}`);

    const params = paramsParts.join('&');

    await axios.delete(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/fcconfig/delete_config_by_user_level_mitarbeiter?${params}`, config);
  },

  // Options Menu Config - Global
  async [MENU_CONFIG_TYPES.ACTIONS.FIND_OPTIONS_MENU_CONFIG_GLOBAL]({ getters, commit, }, { configContext, userLevel, configId, }) {
    const paramsParts = [];
    paramsParts.push(`configId=${configId}`);
    paramsParts.push(`configContext=${configContext}`);
    paramsParts.push(`userLevel=${userLevel}`);

    const params = paramsParts.join('&');

    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/fcconfig/options_menu_config_global?${params}`, config);
    const fcConfigs = response?.data || [];
    commit(MENU_CONFIG_TYPES.MUTATIONS.SET_OPTIONS_MENU_CONFIG, { configContext, userLevel, configId, config: fcConfigs?.[0], });
  },
  async [MENU_CONFIG_TYPES.ACTIONS.SAVE_ALL_OPTIONS_MENU_CONFIG_GLOBAL]({ getters, state, dispatch, commit, }, { configContext, }) {
    dispatch(LOG_TYPES.ACTIONS.LOG, `MENU_CONFIG_TYPES.ACTIONS.SAVE_ALL_OPTIONS_MENU_CONFIG_GLOBAL >>> configContext = "${configContext}"`);

    const hasEdited = getters[MENU_CONFIG_TYPES.GETTERS.HAS_MENU_CONFIG_EDITED](configContext);
    if(!configContext || !hasEdited) return;

    const menuConfigEdited = { ...state.optionsMenuConfigEdited?.[configContext] || {}, };
    const fcConfigs = Object.keys(menuConfigEdited).flatMap(configId => {
      return Object.keys(menuConfigEdited[configId]).map(userLevel => {
        const content = menuConfigEdited[configId][userLevel];
        return {
          configId,
          configType: FC_CONFIG.OPTION_MENU_CONFIG,
          content: contentToSave(content, userLevel),
          userLevel,
          configContext,
        };
      });
    });

    await dispatch(FC_CONFIG_TYPES.ACTIONS.SAVE_AS_INTERN, { fcConfigs, });

    // reset loaded config
    commit(MENU_CONFIG_TYPES.MUTATIONS.RESET_OPTIONS_MENU_CONFIG, { configContext });

    // reset config edited
    commit(MENU_CONFIG_TYPES.MUTATIONS.RESET_OPTIONS_MENU_CONFIG_EDITED, { configContext });
  },
  async [MENU_CONFIG_TYPES.ACTIONS.DELETE_OPTIONS_MENU_CONFIG_GLOBAL]({ commit, dispatch, }, { configId, userLevels, configContext, }) {
    if(!configId || !userLevels?.length) return;

    const promises = userLevels.map(async userLevel => {
      // reset loaded config
      commit(MENU_CONFIG_TYPES.MUTATIONS.RESET_OPTIONS_MENU_CONFIG_BY_USER_LEVEL, { configContext, configId, userLevel, });

      // reset config edited
      commit(MENU_CONFIG_TYPES.MUTATIONS.RESET_OPTIONS_MENU_CONFIG_EDITED_BY_USER_LEVEL, { configContext, configId, userLevel, });

      // delete
      const deletePayload = { configId, configType: FC_CONFIG.OPTION_MENU_CONFIG, userLevel, configContext, };
      await dispatch(FC_CONFIG_TYPES.ACTIONS.DELETE_AS_INTERN, deletePayload);
    });

    await Promise.all(promises);
  },

  // Options Menu Permission Config
  [MENU_CONFIG_TYPES.ACTIONS.ADD_OPTIONS_MENU_PERMISSION_CONFIG_EDITED]({ getters, commit, }, { configContext, userLevel, path, visible, }) {
    if(!configContext || !userLevel || !path) return ;

    const pathResolved = getters[MENU_CONFIG_TYPES.GETTERS.RESOLVE_MENU_PATH](configContext, path);

    commit(MENU_CONFIG_TYPES.MUTATIONS.SET_OPTIONS_MENU_PERMISSION_CONFIG_EDITED, {
      configContext,
      userLevel,
      path: pathResolved,
      visible,
    });

    // options menu permission config user levels
    const userLevels = getters[MENU_CONFIG_TYPES.GETTERS.OPTIONS_MENU_CONFIG_USER_LEVELS](configContext);

    // set visible as false to the sub user level
    USER_LEVEL_HIERARCHY?.[userLevel]
      .filter(userLevel => userLevels.includes(userLevel)) // only applies for allowed user level
      .forEach(subUserLevel => commit(MENU_CONFIG_TYPES.MUTATIONS.SET_OPTIONS_MENU_PERMISSION_CONFIG_EDITED, {
        configContext,
        userLevel: subUserLevel,
        path: pathResolved,
        visible: false,
      }));
  },
  async [MENU_CONFIG_TYPES.ACTIONS.FIND_ALL_OPTIONS_MENU_PERMISSION_CONFIG]({ dispatch, getters, commit, }, { configContext, }) {
    dispatch(LOG_TYPES.ACTIONS.LOG, `MENU_CONFIG_TYPES.ACTIONS.FIND_ALL_OPTIONS_MENU_PERMISSION_CONFIG >>> configContext = ${configContext}`);

    // user levels
    const userLevels = getters[MENU_CONFIG_TYPES.GETTERS.OPTIONS_MENU_CONFIG_USER_LEVELS](configContext);
    
    // params
    const paramsParts = [];
    paramsParts.push(...userLevels.map(userLevel => `userLevel=${userLevel}`));
    const params = paramsParts.join('&');

    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/fcconfig/options_menu_permission_config?${params}`, config);

    // reduce found configs by user level
    const optionsMenuPermissionConfigByUserLevel = response?.data || {};

    // set options menu permission config
    userLevels.forEach(userLevel => {
      const config = optionsMenuPermissionConfigByUserLevel?.[userLevel] || {};
      const permissionConfig = JSON.parse(config?.content || '{}');
      commit(MENU_CONFIG_TYPES.MUTATIONS.SET_OPTIONS_MENU_PERMISSION_CONFIG, { 
        configContext, 
        userLevel, 
        permissionConfig, 
      });
    });
  },
  async [MENU_CONFIG_TYPES.ACTIONS.SAVE_ALL_OPTIONS_MENU_PERMISSION_CONFIG]({ getters, state, dispatch, commit, }, { configContext }) {
    dispatch(LOG_TYPES.ACTIONS.LOG, `MENU_CONFIG_TYPES.ACTIONS.SAVE_ALL_OPTIONS_MENU_PERMISSION_CONFIG >>> configContext = "${configContext}"`);

    const hasEdited = getters[MENU_CONFIG_TYPES.GETTERS.HAS_MENU_PERMISSION_CONFIG_EDITED](configContext);
    if(!configContext || !hasEdited) return;

    const optionsMenuPermissionConfigEdited = { ...state.optionsMenuPermissionConfigEdited?.[configContext] || {}, };
    const promises = Object.keys(optionsMenuPermissionConfigEdited).flatMap(async userLevel => {
      const content = optionsMenuPermissionConfigEdited[userLevel];
      const payload = {
        configId: FC_CONFIG.OPTION_MENU_PERMISSION_CONFIG,
        configType: FC_CONFIG.OPTION_MENU_PERMISSION_CONFIG,
        content: permissionConfigToSave(content),
        userLevel,
      };
      await dispatch(FC_CONFIG_TYPES.ACTIONS.SAVE_ONLY, payload);
    });

    await Promise.all(promises);

    commit(MENU_CONFIG_TYPES.MUTATIONS.RESET_OPTIONS_MENU_PERMISSION_CONFIG_EDITED);

    const reloadPromises = [];
    reloadPromises.push(dispatch(MENU_TYPES.ACTIONS.CONFIGURE_MENU, { reconfiguration: true, }));
    reloadPromises.push(dispatch(MENU_CONFIG_TYPES.ACTIONS.FIND_ALL_OPTIONS_MENU_PERMISSION_CONFIG, { configContext }));

    await Promise.all(reloadPromises);
  },
  async [MENU_CONFIG_TYPES.ACTIONS.DELETE_OPTIONS_MENU_PERMISSION_CONFIG]({ commit, dispatch, }, { userLevels, configContext, }) {
    if(!userLevels?.length) return;

    // delete user levels informed
    const promises = userLevels.map(async userLevel => {
      // reset options menu permission config edited by user level
      commit(MENU_CONFIG_TYPES.MUTATIONS.RESET_OPTIONS_MENU_PERMISSION_CONFIG_EDITED, { userLevel, configContext, });

      const deletePayload = { 
        configId: FC_CONFIG.OPTION_MENU_PERMISSION_CONFIG, 
        configType: FC_CONFIG.OPTION_MENU_PERMISSION_CONFIG, 
        userLevel, 
      };
      await dispatch(FC_CONFIG_TYPES.ACTIONS.DELETE_ONLY, deletePayload);
    });

    await Promise.all(promises);

    Promise.all([
      // find all permission config
      dispatch(MENU_CONFIG_TYPES.ACTIONS.FIND_ALL_OPTIONS_MENU_PERMISSION_CONFIG, { configContext, }),

      // reconfigure menu
      dispatch(MENU_TYPES.ACTIONS.CONFIGURE_MENU, { reconfiguration: true, }),
    ]);
  },

  // Options Menu Permission Config - Mitarbeiter
  async [MENU_CONFIG_TYPES.ACTIONS.FIND_ALL_OPTIONS_MENU_PERMISSION_CONFIG_MITARBEITER]({ dispatch, getters, commit, }, { configContext }) {
    dispatch(LOG_TYPES.ACTIONS.LOG, `MENU_CONFIG_TYPES.ACTIONS.FIND_ALL_OPTIONS_MENU_PERMISSION_CONFIG_MITARBEITER >>> configContext = ${configContext}`);

    if(!getters[MENU_CONFIG_TYPES.GETTERS.HAS_SELECTED_UNTERNR](configContext)) return;

    const selectedUnternr = getters[MENU_CONFIG_TYPES.GETTERS.SELECTED_UNTERNR](configContext);

    // user levels
    const userLevels = [FC_CONFIG_USER_LEVEL.MAKLER];
    
    // params
    const paramsParts = [];
    paramsParts.push(`unternr=${selectedUnternr}`)
    paramsParts.push(...userLevels.map(userLevel => `userLevel=${userLevel}`));
    const params = paramsParts.join('&');

    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/fcconfig/options_menu_permission_config?${params}`, config);

    // reduce found configs by user level
    const optionsMenuPermissionConfigByUserLevel = response?.data || {};

    // set options menu permission config
    userLevels.forEach(userLevel => {
      const config = optionsMenuPermissionConfigByUserLevel?.[userLevel] || {};
      const permissionConfig = JSON.parse(config?.content || '{}');

      // when a Makler edits a Mitarbeiter override "changeable" if the permission do not belong to the Mitarbeiter
      // it prevents misbehavior when Makler edits a Mitarbeiter options menu from Makler context
      const permissionConfigChecked = Object.keys(permissionConfig).reduce((acc, path) => ({
        ...acc,
        [path]: {
          ...permissionConfig[path],
          changeable: (permissionConfig[path].unternr !== selectedUnternr ? false : permissionConfig[path].changeable),
        },
      }), {});

      commit(MENU_CONFIG_TYPES.MUTATIONS.SET_OPTIONS_MENU_PERMISSION_CONFIG, { 
        configContext, 
        userLevel, 
        permissionConfig: permissionConfigChecked,
      });
    });
  },
  async [MENU_CONFIG_TYPES.ACTIONS.SAVE_ALL_OPTIONS_MENU_PERMISSION_CONFIG_MITARBEITER]({ getters, state, commit, dispatch, }, { configContext, selected = [] }) {
    dispatch(LOG_TYPES.ACTIONS.LOG, `MENU_CONFIG_TYPES.ACTIONS.SAVE_ALL_OPTIONS_MENU_PERMISSION_CONFIG_MITARBEITER >>> configContext = "${configContext}"`);

    const unternrChange = [];
    const hasEdited = getters[MENU_CONFIG_TYPES.GETTERS.HAS_MENU_PERMISSION_CONFIG_EDITED](configContext);
    const hasSelectedUnternr = getters[MENU_CONFIG_TYPES.GETTERS.HAS_SELECTED_UNTERNR](configContext);

    if (hasSelectedUnternr) {
      unternrChange.push(getters[MENU_CONFIG_TYPES.GETTERS.SELECTED_UNTERNR](configContext))
    }
    if (selected?.length) {
      unternrChange.push(...selected);
    }

    if(!hasEdited || !unternrChange.length) return;

    const optionsMenuPermissionConfigEdited = { ...state.optionsMenuPermissionConfigEdited?.[configContext] || {}, };
    const content = optionsMenuPermissionConfigEdited[FC_CONFIG_USER_LEVEL.MAKLER_PERSONEN];
    const fcConfigs = [
      {
        configId: FC_CONFIG.OPTION_MENU_PERMISSION_CONFIG,
        configType: FC_CONFIG.OPTION_MENU_PERMISSION_CONFIG,
        content: permissionConfigToSave(content),
        userLevel: FC_CONFIG_USER_LEVEL.MAKLER,
      },
    ];

    const promiseUnternr = unternrChange.map(unternr => {
      return axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/fcconfig/save_config_mitarbeiter?unternr=${unternr}`, fcConfigs, config);
    })

    await Promise.all(promiseUnternr);

    commit(MENU_CONFIG_TYPES.MUTATIONS.RESET_OPTIONS_MENU_PERMISSION_CONFIG_EDITED);

    // find all permission config
    await dispatch(MENU_CONFIG_TYPES.ACTIONS.FIND_ALL_OPTIONS_MENU_PERMISSION_CONFIG_MITARBEITER, { configContext });
  },
  async [MENU_CONFIG_TYPES.ACTIONS.DELETE_OPTIONS_MENU_PERMISSION_CONFIG_MITARBEITER]({ getters, commit, dispatch, }, { configContext }) {
    const hasSelectedUnternr = getters[MENU_CONFIG_TYPES.GETTERS.HAS_SELECTED_UNTERNR](configContext);
    if(!hasSelectedUnternr) return;

    const selectedUnternr = getters[MENU_CONFIG_TYPES.GETTERS.SELECTED_UNTERNR](configContext);

    const userLevel = FC_CONFIG_USER_LEVEL.MAKLER;

    // reset options menu permission config edited by user level
    commit(MENU_CONFIG_TYPES.MUTATIONS.RESET_OPTIONS_MENU_PERMISSION_CONFIG_EDITED, { userLevel, configContext, });

    // delete permission config
    const deletePayload = { 
      configId: FC_CONFIG.OPTION_MENU_PERMISSION_CONFIG, 
      configType: FC_CONFIG.OPTION_MENU_PERMISSION_CONFIG, 
      userLevel, 
      unternr: selectedUnternr,
    };
    const params = makeQueryParam(deletePayload);
    await axios.delete(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/fcconfig/delete_config_by_user_level_mitarbeiter?${params}`, config);

    // find all permission config
    await dispatch(MENU_CONFIG_TYPES.ACTIONS.FIND_ALL_OPTIONS_MENU_PERMISSION_CONFIG_MITARBEITER, { configContext });
  },

  // Options Menu Permission Config - Global
  async [MENU_CONFIG_TYPES.ACTIONS.FIND_ALL_OPTIONS_MENU_PERMISSION_CONFIG_GLOBAL]({ dispatch, getters, commit, }, { configContext, } = {}) {
    dispatch(LOG_TYPES.ACTIONS.LOG, `MENU_CONFIG_TYPES.ACTIONS.FIND_ALL_OPTIONS_MENU_PERMISSION_CONFIG_GLOBAL >>> configContext = ${configContext}`);

    // user levels
    const userLevels = getters[MENU_CONFIG_TYPES.GETTERS.OPTIONS_MENU_CONFIG_USER_LEVELS](configContext);

    // params
    const paramsParts = [];
    paramsParts.push(...userLevels.map(userLevel => `userLevel=${userLevel}`));
    paramsParts.push(`configContext=${configContext}`);
    const params = paramsParts.join('&');

    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/fcconfig/options_menu_permission_config_global?${params}`, config);

    // reduce found configs by user level
    const optionsMenuPermissionConfigByUserLevel = response?.data || {};

    // set options menu permission config
    userLevels.forEach(userLevel => {
      const config = optionsMenuPermissionConfigByUserLevel?.[userLevel] || {};
      const permissionConfig = JSON.parse(config?.content || '{}');
      commit(MENU_CONFIG_TYPES.MUTATIONS.SET_OPTIONS_MENU_PERMISSION_CONFIG, { 
        configContext, 
        userLevel, 
        permissionConfig, 
      });
    });
  },
  async [MENU_CONFIG_TYPES.ACTIONS.SAVE_ALL_OPTIONS_MENU_PERMISSION_CONFIG_GLOBAL]({ getters, state, dispatch, commit, }, { configContext }) {
    dispatch(LOG_TYPES.ACTIONS.LOG, `MENU_CONFIG_TYPES.ACTIONS.SAVE_ALL_OPTIONS_MENU_PERMISSION_CONFIG_GLOBAL >>> configContext = "${configContext}"`);

    const hasEdited = getters[MENU_CONFIG_TYPES.GETTERS.HAS_MENU_PERMISSION_CONFIG_EDITED](configContext);
    if(!configContext || !hasEdited) return;

    const optionsMenuPermissionConfigEdited = { ...state.optionsMenuPermissionConfigEdited?.[configContext] || {}, };
    const fcConfigs = Object.keys(optionsMenuPermissionConfigEdited).map(userLevel => {
      const content = optionsMenuPermissionConfigEdited[userLevel];
      return {
        configId: FC_CONFIG.OPTION_MENU_PERMISSION_CONFIG,
        configType: FC_CONFIG.OPTION_MENU_PERMISSION_CONFIG,
        content: permissionConfigToSave(content),
        userLevel,
        configContext,
      };
    });
    await dispatch(FC_CONFIG_TYPES.ACTIONS.SAVE_AS_INTERN, { fcConfigs, });

    commit(MENU_CONFIG_TYPES.MUTATIONS.RESET_OPTIONS_MENU_PERMISSION_CONFIG_EDITED);
    await dispatch(MENU_CONFIG_TYPES.ACTIONS.CONFIGURE_MENU_CONFIG, { 
      configContext,
      isPermissionConfigurable: true,
    });
  },
  async [MENU_CONFIG_TYPES.ACTIONS.DELETE_OPTIONS_MENU_PERMISSION_CONFIG_GLOBAL]({ commit, dispatch, }, { userLevels, configContext, }) {
    if(!userLevels?.length || !configContext) return;

    // delete user levels informed
    const promises = userLevels.map(async userLevel => {
      // reset options menu permission config edited by user level
      commit(MENU_CONFIG_TYPES.MUTATIONS.RESET_OPTIONS_MENU_PERMISSION_CONFIG_EDITED, { userLevel, configContext, });

      const deletePayload = {
        configId: FC_CONFIG.OPTION_MENU_PERMISSION_CONFIG,
        configType: FC_CONFIG.OPTION_MENU_PERMISSION_CONFIG, 
        userLevel,
        configContext,
      };
      await dispatch(FC_CONFIG_TYPES.ACTIONS.DELETE_AS_INTERN, deletePayload);
    });

    await Promise.all(promises);

    // find all permission config by config context
    dispatch(MENU_CONFIG_TYPES.ACTIONS.FIND_ALL_OPTIONS_MENU_PERMISSION_CONFIG_GLOBAL, { configContext, });
  },

}
