export const MUTATION_PREFIX = 'WERTPAPIERORDER: ';
export const ACTIONS_PREFIX = 'WERTPAPIERORDER_ACTIONS_';
export const GETTERS_PREFIX = 'WERTPAPIERORDER_GETTERS_';

export default {
  MUTATIONS: {
    GET_WERTPAPIERORDER_SUCCESS: MUTATION_PREFIX + 'GET_WERTPAPIERORDER_SUCCESS',
    SAVE_WERTPAPIERORDER_SUCCESS: MUTATION_PREFIX + 'SAVE_WERTPAPIERORDER_SUCCESS',
    SAVE_WERTPAPIERORDER_FAILED: MUTATION_PREFIX + 'SAVE_WERTPAPIERORDER_FAILED',
    GET_POSITION_DATA_COMPONENTS_SUCCESS: MUTATION_PREFIX + 'GET_POSITION_DATA_COMPONENTS_SUCCESS',
    ADD_POSITIONS: MUTATION_PREFIX + 'ADD_POSITIONS',
    REMOVE_UNSAVED_POSITION: MUTATION_PREFIX + 'REMOVE_UNSAVED_POSITION',
    REMOVE_POSITION_SUCCESS: MUTATION_PREFIX + 'REMOVE_POSITION_SUCCESS',
    REMOVE_ALL_VERKAUF_AND_TAUSCH_POSITIONS_SUCCESS: MUTATION_PREFIX + 'REMOVE_ALL_VERKAUF_AND_TAUSCH_POSITIONS_SUCCESS',
    UPDATE_POSITION: MUTATION_PREFIX + 'UPDATE_POSITION',
    SAVE_POSITION_DATA_SUCCESS: MUTATION_PREFIX + 'SAVE_POSITION_DATA_SUCCESS',
    UPDATE_DATA: MUTATION_PREFIX + 'UPDATE_DATA',
    UPDATE_POSITION_DATA_STORE: MUTATION_PREFIX + 'UPDATE_POSITION_DATA_STORE',
    UPDATE_HIGHEST_STEP_VISITED: MUTATION_PREFIX + 'UPDATE_HIGHEST_STEP_VISITED',
    WERTPAPIERORDER_LOADING: MUTATION_PREFIX + 'WERTPAPIERORDER_LOADING',
    GET_PERSONEN_AUSWAHL_SUCCESS: MUTATION_PREFIX + 'GET_PERSONEN_AUSWAHL_SUCCESS',
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
    SET_MIT_GELDWAESCHEPRUEFUNG: MUTATION_PREFIX + 'SET_MIT_GELDWAESCHEPRUEFUNG',
    GET_DEPOTS_SUCCESS: MUTATION_PREFIX + 'GET_DEPOTS_SUCCESS',
    GET_VENUES: MUTATION_PREFIX + 'GET_VENUES',
    GET_LAGERSTELLEN_SUCCESS: MUTATION_PREFIX + 'GET_LAGERSTELLEN_SUCCESS',
    UPDATE_IS_VORGELEGT: MUTATION_PREFIX + 'UPDATE_IS_VORGELEGT',
  },
  ACTIONS: {
    GET_WERTPAPIERORDER: ACTIONS_PREFIX + 'GET_WERTPAPIERORDER',
    SAVE_WERTPAPIERORDER: ACTIONS_PREFIX + 'SAVE_WERTPAPIERORDER',
    GET_POSITION_DATA_COMPONENTS: ACTIONS_PREFIX + 'GET_POSITION_DATA_COMPONENTS',
    REMOVE_POSITION: ACTIONS_PREFIX + 'REMOVE_POSITION',
    REMOVE_ALL_VERKAUF_AND_TAUSCH_POSITIONS: ACTIONS_PREFIX + 'REMOVE_ALL_VERKAUF_AND_TAUSCH_POSITIONS',
    SAVE_POSITION_DATA: ACTIONS_PREFIX + 'SAVE_POSITION_DATA',
    CHECK_DEPOT_NUMMER: ACTIONS_PREFIX + 'CHECK_DEPOT_NUMMER',
    KAUF: ACTIONS_PREFIX + 'KAUF',
    ADD_POSITIONS: ACTIONS_PREFIX + 'ADD_POSITIONS',
    GET_PERSONEN_AUSWAHL: ACTIONS_PREFIX + 'GET_PERSONEN_AUSWAHL',
    GET_MELDUNG_EINREICHUNG: ACTIONS_PREFIX + 'GET_MELDUNG_EINREICHUNG',
    GET_DEPOTS: ACTIONS_PREFIX + 'GET_DEPOTS',
    GET_VENUES: ACTIONS_PREFIX + 'GET_VENUES',
    GET_LAGERSTELLEN: ACTIONS_PREFIX + 'GET_LAGERSTELLEN',
    TO_SIGNATURE_VORLEGEN: ACTIONS_PREFIX + 'TO_SIGNATURE_VORLEGEN',
    CHECK_COMDIRECT_LOGIN: ACTIONS_PREFIX + 'CHECK_COMDIRECT_LOGIN',
  },
  GETTERS: {
    DATA: GETTERS_PREFIX + 'DATA',
    POSITIONS: GETTERS_PREFIX + 'POSITIONS',
    LAGERSTELLEN: GETTERS_PREFIX + 'LAGERSTELLEN',
    LAGERSTELLEN_CONFIG: GETTERS_PREFIX + 'LAGERSTELLEN_CONFIG',
    KONTOS: GETTERS_PREFIX + 'KONTOS',
    DEPOTS: GETTERS_PREFIX + 'DEPOTS',
    ORDERBOOKS: GETTERS_PREFIX + 'ORDERBOOKS',
    VENUES: GETTERS_PREFIX + 'VENUES',
    DEPOT_NUMBERS: GETTERS_PREFIX + 'DEPOT_NUMBERS',
    COMBOBOX_VALUES: GETTERS_PREFIX + 'COMBOBOX_VALUES',
    POSITION_INFO: GETTERS_PREFIX + 'POSITION_INFO',
    WARNINGS: GETTERS_PREFIX + 'WARNINGS',
    // ERROR_MESSAGE: GETTERS_PREFIX + 'ERROR_MESSAGE',
    SELECTED_LAGERSTELLE: GETTERS_PREFIX + 'SELECTED_LAGERSTELLE',
    CAPITALBANK_COMPONENTS: GETTERS_PREFIX + 'CAPITALBANK_COMPONENTS',
    CAPITALBANK_KYC_COMPONENTS: GETTERS_PREFIX + 'CAPITALBANK_KYC_COMPONENTS',
    HELLOBANK_COMPONENTS: GETTERS_PREFIX + 'HELLOBANK_COMPONENTS',
    HELLOBANK_COMPONENTS_SELBST: GETTERS_PREFIX + 'HELLOBANK_COMPONENTS_SELBST',
    SEMPERCONSTANTIA_COMPONENTS: GETTERS_PREFIX + 'SEMPERCONSTANTIA_COMPONENTS',
    POSITION_DATA: GETTERS_PREFIX + 'POSITION_DATA',
    POSITION_DATA_COMPONENTS: GETTERS_PREFIX + 'POSITION_DATA_COMPONENTS',
    BERATUNGS_MAPPE_DATA: GETTERS_PREFIX + 'BERATUNGS_MAPPE_DATA',
    HIGHEST_STEP_VISITED: GETTERS_PREFIX + 'HIGHEST_STEP_VISITED',
    IS_WERTPAPIERORDER_LOADING: GETTERS_PREFIX + 'IS_WERTPAPIERORDER_LOADING',
    GET_MIT_GELDWAESCHEPRUEFUNG: GETTERS_PREFIX + 'GET_MIT_GELDWAESCHEPRUEFUNG',
    ANTRAG: GETTERS_PREFIX + 'ANTRAG',
  }
}
