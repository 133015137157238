export const MUTATION_PREFIX = 'MR_MONEY_MUTATIONS_';
export const ACTIONS_PREFIX = 'MR_MONEY_ACTIONS_';
export const GETTERS_PREFIX = 'MR_MONEY_GETTERS_';

export default {
  MUTATIONS: {
    GET_LISTING_SUCCESS: MUTATION_PREFIX + 'GET_LISTING_SUCCESS',
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
    SELECTED_ROW: MUTATION_PREFIX + 'SELECTED_ROW',
  },
  ACTIONS: {
    GET_LISTING: ACTIONS_PREFIX + 'GET_LISTING',
    UPLOAD_AND_ANALYSE_PDF: ACTIONS_PREFIX + 'UPLOAD_AND_ANALYSE_PDF',
    TRASH_PDF: ACTIONS_PREFIX + 'TRASH_PDF',
    RESCANN_ALL: ACTIONS_PREFIX + 'RESCANN_ALL',
  },
  GETTERS: {
    LISTING: GETTERS_PREFIX + 'LISTING',
    SELECTED_ROW: GETTERS_PREFIX + 'SELECTED_ROW',
  }
}