import HTTP_REQ_TYPES from './types';
import CORE_TYPES from '@/store/core/types'
import axios from 'axios';

const config = {
  defaultSpinner: true
};

// this endpoint should not be cancelled
const LOGOUT_ENDPOINT = '/mrslogout'

export const MESSAGE_CANCEL_REQUEST_DUE_TO_LOGOUT = 'Requests cancelled due to logout.'
export const MESSAGE_CANCEL_REPEATED_REQUESTS = 'Cancel repeated request.'
export const MESSAGE_TOKEN_DOES_NOT_MATCH = 'The request token and response token does not match.'
export const MESSAGE_CANCELLED_BY_USER = 'Request cancelled by the user.'
export const MESSAGE_CANCELLED = 'Request cancelled.'
export const MESSAGE_SESSION_TIMEOUT = 'Request cancelled. Session Timeout'
export const HEADER_PARAM_SESSION_EXPIRED = 'session-expired'
export const HEADER_PARAM_DUPLICATED_REQUEST = 'duplicated-request'

export default {
  async [HTTP_REQ_TYPES.ACTIONS.KILL_SESSION]({ getters, commit, dispatch }, payload) {
    const httpRequestUrl = getters[HTTP_REQ_TYPES.GETTERS.HTTP_REQUEST_URL_LIST]
    const found = httpRequestUrl.find(req => req.requestId === payload.requestId)
    
    if (found) {
      let message = payload.message ?? MESSAGE_CANCELLED
      message  += ` requestId: [${payload.requestId}]`
      found.config?.cancelTokenSource?.cancel?.(message);

      commit(HTTP_REQ_TYPES.MUTATIONS.REMOVE_HTTP_REQUEST_URL, found)

      if (found.config?.defaultSpinner) {
        dispatch(CORE_TYPES.ACTIONS.GLOBAL_LOADING_STATE_STOP);
      }
    }

    await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/kill_session/kill_session`, { requestId: payload.requestId }, { ...config, disableDefaultErrorMessage: true, });
  },

  async [HTTP_REQ_TYPES.ACTIONS.KILL_SESSION_BY_URL]({ getters, dispatch }, { url, }) {
    const httpRequestUrl = getters[HTTP_REQ_TYPES.GETTERS.HTTP_REQUEST_URL];
    const found = httpRequestUrl?.[url];
    
    if (found?.requestId) {
      await dispatch(HTTP_REQ_TYPES.ACTIONS.KILL_SESSION, { requestId: found.requestId, });
    }
  },

  async [HTTP_REQ_TYPES.ACTIONS.CANCEL_ALL_CURRENT_FRONTEND_REQUESTS]({getters, commit,}, message) {
    const httpRequestUrl = getters[HTTP_REQ_TYPES.GETTERS.HTTP_REQUEST_URL_LIST]

    for (const request of httpRequestUrl) {
      if (request?.config && !request?.url?.includes(LOGOUT_ENDPOINT)) {
        request.config.cancelTokenSource?.cancel?.(message);
        commit(HTTP_REQ_TYPES.MUTATIONS.REMOVE_HTTP_REQUEST_URL, request);
      }
    }
  } 

}