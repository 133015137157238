var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.filterLoading
        ? _c(
            "GhostLoading",
            { attrs: { useBoxContainer: "" } },
            [_c("Block", { attrs: { height: "200" } })],
            1
          )
        : _c(
            "div",
            [
              !_vm.filterLoading
                ? _c("GenericPersonFilter", {
                    ref: "filter",
                    attrs: {
                      filterId: "7b8ff9e5-10b1-4cf4-8f94-2d54d19d56fd",
                      saveKey: _vm.saveKeyFilter,
                      title: "Wertpapierfilter",
                      metadata: _vm.filter,
                      configFilter: _vm.configFilter,
                      predefinedFilter: _vm.predefinedFilter,
                      searchFromParam: _vm.searchFromParam,
                      extraButton: _vm.extraButton,
                      defaultOptions: _vm.defaultOptions,
                      showSearchButton: _vm.showSearchButton,
                      hasSmartSearch: !_vm.isFA || !_vm.isCourtage
                    },
                    on: {
                      search: _vm.handleSearch,
                      extraButtonClick: function($event) {
                        return _vm.$emit("extraButtonClick")
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
      !_vm.emitFilters
        ? [
            _vm.rows.length
              ? _c(
                  "div",
                  { staticClass: "box__container" },
                  [
                    _vm._t("beforeTable"),
                    _c("WertpapierTable", {
                      attrs: {
                        showMobileSelectionAlways: "",
                        tableId: _vm.tableId,
                        rows: _vm.rows,
                        headers: _vm.headers,
                        loading: _vm.loading,
                        exportConfig: _vm.exportConfig,
                        singleSelection: _vm.singleSelection,
                        selected: _vm.isRowSelectable
                          ? _vm.selectedRows
                          : undefined,
                        headerActions: _vm.tableHeaderActions,
                        canAddToFavoritenliste: _vm.canAddToFavoritenliste,
                        type: _vm.type
                      },
                      on: {
                        selected: _vm.onSelect,
                        action: _vm.handleTableAction
                      }
                    }),
                    _vm._t("afterTable"),
                    _vm.responseType === 1
                      ? _c(
                          "BaseButton",
                          {
                            staticClass: "mt-2",
                            attrs: { disabled: !_vm.selectedRows.length },
                            on: {
                              click: function($event) {
                                return _vm.emitSelected()
                              }
                            }
                          },
                          [_vm._v(" Markierung übernehmen ")]
                        )
                      : _vm._e()
                  ],
                  2
                )
              : _vm.loading
              ? _c("GhostLoading", {
                  attrs: {
                    type: "table",
                    title: _vm.TABLE_TITLE,
                    useBoxContainer: ""
                  }
                })
              : _vm.outcome
              ? _c("NoData", {
                  attrs: {
                    title: _vm.TABLE_TITLE,
                    content: "Es wurden keine Einträge gefunden",
                    useBoxContainer: ""
                  }
                })
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }