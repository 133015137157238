<template>

  <div class="top-navbar__container-bg">
    <div class="fc-container top-navbar__container" :class="{ 'loading': isLoading, }">
      <div v-if="isLoading" class="top-navbar__loading">
        <template v-if="!$isSmallScreen">
          <GhostLoading type="block" :config="{ block: { width: 16, height: 16, opacity: 0.6, }, }"/>
          <GhostLoading type="block" :config="{ block: { width: 16, height: 16, opacity: 0.6, }, }"/>
        </template>
        <GhostLoading class="top-navbar__loading--user-text" type="block" :config="{ block: { width: 200, height: 16, opacity: 0.6, }, }" />
        <GhostLoading class="top-navbar__loading--user-photo" type="block" :config="{ block: { width: 24, height: 24, opacity: 0.6, }, }" />
      </div>
      <template v-else>
        <div class="top-nav__navigation-controls d-flex header-navbar__container-icons--spaced">
          <BaseBackButtonBreadcrumb :size="buttonsSize" class="top-nav__navigation-controls--breadcrumb" />
        </div>
        <div class="header-navbar__controls-container header-navbar__container-icons--spaced" v-if="!isMobileNativeContext && !isSmallScreen">
          <LogoutTimer/>
          
          <template v-if="isNavItemsAllowed">
            <Maklerzugang  :size="buttonsSize" />

            <Kundenzugang :size="buttonsSize" />

            <PhoneButton :size="buttonsSize"/>
        
            <TapiDisconnectButton :size="buttonsSize"  color="var(--color-danger)" />
            <TapiButton   :size="buttonsSize"/>
      
            <GlobalSearchModal :size="buttonsSize"/>

            <!-- <InteractiveHelp :size="buttonsSize" v-if="hasRoles([VIEW_ROLES.VIEW_CUSTOMER, VIEW_ROLES.VIEW_BROKER, VIEW_ROLES.VIEW_INTERN])"/>  -->

            <RecordTutorial :size="buttonsSize" v-if="showInteractiveRecorder"/>

            <SsoButton :size="buttonsSize" v-if="showSso"/>

            <Logout :size="buttonsSize"/>

            <CloseExtraWebInstance :size="buttonsSize" />
          </template>
          <template v-else>
            <Logout :size="buttonsSize"/>
          </template>

          <LoggedUserInformation/>

          <CustomerPicture/>
        </div>
        <div v-else class="header-navbar__controls-container header-navbar__container-icons--spaced">
          <template v-if="isNavItemsAllowed">
            <CloseExtraWebInstance :size="buttonsSize" />
          </template>

          <ProfileMenu/>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import CORE_TYPES from '@/store/core/types';
import BRIDGE_TYPES from '@/store/bridge/types';
import MENU_TYPES from '@/store/menu/types'
import { mapGetters } from 'vuex';

import Logout from '@/components/core/header/buttons/Logout.vue'
import GlobalSearchModal from '@/components/core/header/buttons/GlobalSearchModal.vue';
import InteractiveHelp from '@/components/core/header/buttons/InteractiveHelp.vue'
import LoggedUserInformation from '@/components/core/header/LoggedUserInformation.vue';
import PhoneButton from '@/components/webrtc/PhoneButton.vue'
import BaseBackButtonBreadcrumb from '@/components/core/BaseBackButtonBreadcrumb.vue'
import CustomerPicture from '@/components/core/header/CustomerPicture.vue'
import RecordTutorial from '@/components/core/header/buttons/RecordTutorial.vue'
import SsoButton from '@/components/core/header/buttons/SsoButton.vue'
import Kundenzugang from '@/components/core/header/buttons/Kundenzugang.vue'
import Maklerzugang from '@/components/core/header/buttons/Maklerzugang.vue'
import CloseExtraWebInstance from '@/components/core/navbar-workspaces/CloseExtraWebInstance.vue'
import TapiButton from '@/components/core/header/buttons/TapiButton.vue'
import TapiDisconnectButton from '@/components/core/header/buttons/TapiDisconnectButton.vue'
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import LogoutTimer from '@/components/core/logout-timer/LogoutTimer.vue';
import ProfileMenu from '@/components/core/ProfileMenu.vue';

import { VIEW_ROLES, } from '@/router/roles'

const ICON_WEB_SIZE = 18;
const ICON_MOBILE_SIZE = 22;

export default {
  computed: {
    ...mapGetters({
      isLoggedIn: CORE_TYPES.GETTERS.IS_LOGGED_IN,
      logo: CORE_TYPES.GETTERS.GET_USER_LOGO_URL,
      userid: CORE_TYPES.GETTERS.GET_USER_ID,
      recordingFeature : CORE_TYPES.GETTERS.RECORDING_FEATURE,
      isTestBroker: CORE_TYPES.GETTERS.IS_TEST_BROKER,
      isTestCustomer: CORE_TYPES.GETTERS.IS_TEST_CUSTOMER,
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
      isByPass: CORE_TYPES.GETTERS.IS_BYPASS,
      earlyAccess: CORE_TYPES.GETTERS.IS_MAKLER_EARLY_ACCESS,
      isFA: CORE_TYPES.GETTERS.IS_FA,
      isBrokerOriginally: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_BROKER,
      isSsoVisible: CORE_TYPES.GETTERS.IS_SSO_VISIBLE,

      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      isMobileNativeContext: BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT,

      isLoadingToken: CORE_TYPES.GETTERS.IS_LOADING_TOKEN,
      isSmallScreen: CORE_TYPES.GETTERS.IS_SMALL_SCREEN,
      isConfiguringMenu: MENU_TYPES.GETTERS.IS_CONFIGURING_MENU,
      isLoggingIn: CORE_TYPES.GETTERS.IS_LOGGING_IN,
    }),

    isLoading() {
      return this.isLoadingToken || this.isConfiguringMenu || this.isLoggingIn;
    },

    isNavItemsAllowed() {
      return this.hasRoles([VIEW_ROLES.VIEW_INTERN, VIEW_ROLES.VIEW_BROKER, VIEW_ROLES.VIEW_CUSTOMER]);
    },

    showInteractiveRecorder() {
      return this.hasRoles([VIEW_ROLES.VIEW_CUSTOMER_AS_INTERN, VIEW_ROLES.VIEW_BROKER_AS_INTERN, VIEW_ROLES.VIEW_INTERN]);
    },

    showSso() {
      if (!this.isSsoVisible) {
        return false;
      } else {
        return !this.isFA || (!this.isIntern && !this.isBroker);
      }
    },

    buttonsSize() {
      return this.isMobileNativeContext || this.$isSmallScreen ? ICON_MOBILE_SIZE : ICON_WEB_SIZE;
    },

  },
  components: {
    GlobalSearchModal,
    Logout,
    InteractiveHelp,
    CloseExtraWebInstance,
    LoggedUserInformation,
    PhoneButton,
    BaseBackButtonBreadcrumb,
    CustomerPicture,
    RecordTutorial,
    SsoButton,
    Kundenzugang,
    Maklerzugang,
    TapiButton,
    TapiDisconnectButton,
    GhostLoading,
    LogoutTimer,
    ProfileMenu,
  },
}
</script>

<style scoped lang="scss">
.top-navbar__container-bg {
  color: var(--color-top-nav-text);
  background-color: var(--color-top-nav-background);
}

.top-navbar__loading {
  display: flex;
  align-items: center;
}

.top-navbar__loading > * {
  margin: 0 8px !important;
}

::v-deep .top-navbar__loading--user-photo .ghost-loading {
  border-radius: 100%;
}

.top-nav__navigation-controls--breadcrumb {
  display: none;
}

.header-navbar__controls-container > .button {
  flex: 0 0 auto;
}

@media (max-width: 767px) {
  .top-nav__navigation-controls--breadcrumb {
    display: flex;
    align-items: stretch !important;
    justify-content: stretch !important;
    align-self: stretch;
    margin-left: 0;
  }

}

@media (max-width: 360px) {
  .top-navbar__loading--user-text {
    display: none;
  }
}
</style>