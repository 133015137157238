<template>
  <div>
    <AdditionalPersonList :personList="additionalPersons"></AdditionalPersonList>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BROKER_STRUCTURE_ROLES } from '@/router/roles';
import CUSTOMERDATA_TYPES from "@/store/customerData/types";
import AdditionalPersonList from "@/components/steckbrief/cards/customerOverview/AdditionalPersonList.vue";

export default {
  computed: {
    ...mapGetters({
      additionalPersons: CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA_ADDITIONAL_PERSONS,
    }),
    zugriffVersicherungen() {
      return this.hasRoles([BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN])
    },
    steckBriefZusatz() {
      return this.info?.steckBreifZusatz || {}
    },
    maklervollmachtLabel() {
      return this.steckBriefZusatz.maklervollmachtLabel || 'Maklervollmacht'
    },
    maklervertragLabel() {
      return this.steckBriefZusatz.maklervertragLabel || 'Maklervertrag'
    },
  },
  components: {
    AdditionalPersonList,
  }
}
</script>

<style src='../steckbrief.scss' lang='scss' scoped>
</style>
