var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.getPositions && _vm.getPositions.length
        ? _c(
            "div",
            [
              _vm.config && _vm.config.comment
                ? _c("div", { staticClass: "small" }, [
                    _vm._v(_vm._s(_vm.config.comment))
                  ])
                : _vm._e(),
              _vm.isEditDisabled
                ? _c("FormLabel", {
                    staticClass: "mb-2",
                    attrs: {
                      label:
                        "Diese Daten sind bei der Löschung nicht notwendig und werden daher nicht angedruckt."
                    }
                  })
                : _vm._e(),
              _vm.tableData.records.length
                ? _c("Table", {
                    attrs: {
                      tableData: _vm.tableData,
                      cardViewEnabled: false,
                      filterEnabled: false,
                      exportEnabled: false,
                      paginationEnabled: true,
                      actions: _vm.columnActions,
                      rowsPerPage: _vm.tableData.records.length,
                      pageSize: _vm.tableData.records.length
                    },
                    on: {
                      "execute-action": function($event) {
                        return _vm.handleTableAction($event)
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "betrag",
                          fn: function(props) {
                            return [
                              _c("InputField", {
                                attrs: {
                                  type: _vm.betragType,
                                  value: props.data.row.betrag,
                                  disabled: _vm.isEditDisabled,
                                  placeholder:
                                    _vm.config &&
                                    _vm.config.betrag &&
                                    _vm.config.betrag.placeholder,
                                  id: "betrag" + props.data.row.index
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.onChangeInputField(
                                      $event,
                                      props.data.row,
                                      _vm.getBetragId
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "aa",
                          fn: function(props) {
                            return [
                              _c("InputField", {
                                attrs: {
                                  type: "percent",
                                  value: props.data.row.aa,
                                  id: "aa" + props.data.row.index,
                                  disabled: _vm.disabled,
                                  placeholder: props.data.row.defaultAA
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.onChangeInputField(
                                      $event,
                                      props.data.row,
                                      "aa"
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "modus",
                          fn: function(props) {
                            return [
                              _c("ComboBox", {
                                attrs: {
                                  value: props.data.row.modus,
                                  id: "modus" + props.data.row.index,
                                  values: _vm.values("modus", props),
                                  firstEmpty: true,
                                  disabled: _vm.isDisabled("modus", props)
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.onChangeInputField(
                                      $event,
                                      props.data.row,
                                      "modus"
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "lagerstelle",
                          fn: function(props) {
                            return [
                              _c("ComboBox", {
                                attrs: {
                                  value: props.data.row.lagerstelle,
                                  id: "lagerstelle" + props.data.row.index,
                                  values: _vm.values("lagerstelle", props),
                                  firstEmpty: true,
                                  disabled: _vm.isDisabled("lagerstelle", props)
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.onChangeInputField(
                                      $event,
                                      props.data.row,
                                      "lagerstelle"
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "depotnummer",
                          fn: function(props) {
                            return [
                              _c("InputField", {
                                attrs: {
                                  value: props.data.row.depotnummer,
                                  id: "depotnummer" + props.data.row.index,
                                  disabled: _vm.isDisabled("depotnummer", props)
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.onChangeInputField(
                                      $event,
                                      props.data.row,
                                      "depotnummer"
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "frequenz",
                          fn: function(props) {
                            return [
                              _c("ComboBox", {
                                attrs: {
                                  value: props.data.row.frequenz,
                                  id: "frequenz" + props.data.row.index,
                                  values: _vm.values("frequenz", props),
                                  disabled: _vm.isDisabled("frequenz", props),
                                  firstEmpty: true
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.onChangeInputField(
                                      $event,
                                      props.data.row,
                                      "frequenz"
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "dynamik",
                          fn: function(props) {
                            return [
                              _c("InputField", {
                                attrs: {
                                  type: "percent",
                                  value: props.data.row.dynamik,
                                  id: "dynamik" + props.data.row.index,
                                  disabled: _vm.isDisabled("dynamik", props)
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.onChangeInputField(
                                      $event,
                                      props.data.row,
                                      "dynamik"
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "zielsumme",
                          fn: function(props) {
                            return [
                              _c("InputField", {
                                attrs: {
                                  type: "currency",
                                  precision: 2,
                                  value: props.data.row.zielsumme,
                                  id: "zielsumme" + props.data.row.index,
                                  disabled: _vm.isDisabled("zielsumme", props)
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.onChangeInputField(
                                      $event,
                                      props.data.row,
                                      "zielsumme"
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "laufzeit",
                          fn: function(props) {
                            return [
                              _c("InputField", {
                                attrs: {
                                  type: "number",
                                  value: props.data.row.laufzeit,
                                  id: "laufzeit" + props.data.row.index,
                                  disabled: _vm.isDisabled("laufzeit", props)
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.onChangeInputField(
                                      $event,
                                      props.data.row,
                                      "laufzeit"
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "bemerkung",
                          fn: function(props) {
                            return [
                              _c("InputField", {
                                attrs: {
                                  value: props.data.row.bemerkung,
                                  id: "bemerkung" + props.data.row.index
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.onChangeInputField(
                                      $event,
                                      props.data.row,
                                      "bemerkung"
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "provfrei",
                          fn: function(props) {
                            return [
                              _c("input-toggle-switch", {
                                attrs: {
                                  value: props.data.row.provfrei,
                                  id: "provfrei" + props.data.row.index,
                                  disabled: _vm.isDisabled("provfrei", props)
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.onChangeInputField(
                                      $event,
                                      props.data.row,
                                      "provfrei"
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "depotuebertrag",
                          fn: function(props) {
                            return [
                              _c("input-toggle-switch", {
                                attrs: {
                                  value: props.data.row.depotuebertrag,
                                  id: "depotuebertrag" + props.data.row.index,
                                  disabled: _vm.isDisabled(
                                    "depotuebertrag",
                                    props
                                  )
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.onChangeInputField(
                                      $event,
                                      props.data.row,
                                      "depotuebertrag"
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "keineIndexierung",
                          fn: function(props) {
                            return [
                              _c("InputToggleSwitch", {
                                attrs: {
                                  value: props.data.row.keineIndexierung,
                                  id: "keineIndexierung" + props.data.row.index,
                                  disabled: _vm.isDisabled(
                                    "keineIndexierung",
                                    props
                                  )
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.onChangeInputField(
                                      !$event,
                                      props.data.row,
                                      "indexierung"
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2991041365
                    )
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.config.mitSummenbildung && this.positions && this.positions.length
        ? _c(
            "div",
            [
              _c("Summenbildung", {
                attrs: { antragId: _vm.antragId, summe: _vm.summe }
              })
            ],
            1
          )
        : _vm._e(),
      _c("DeletePositionModal", {
        ref: "deleteModal",
        attrs: { position: _vm.positionToDelete },
        on: {
          delete: function($event) {
            return _vm.doDeletePosition()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }