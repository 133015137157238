<template>
  <div>

    <div v-if="!$isSmallScreen">
      <OptionMenu v-if="!embedded" :id="$appNavigation.currentOptionMenuId"
        :defaultMenu="$appNavigation.currentOptionMenu" />
    
      <PageHeaderTitleNavigation 
        v-if="!embedded"
        title="Nachricht" 
        :actions="headerActions"
        :noPrimaryAction="closed || !chatInputEnabled"
        @action-SEND="onSendChat"
        @action-FILE_SELECT="onUploadChatFile"
        @action-CLOSE="closeTicket()"
        @action-EDIT-BEARBEITER="openBearbeiterMenu()"
        @action-CHANGE-STATUS="openChangeStatusModal()"
        @action-AUFGABE-HINZUFUEGEN="handleAddActivity()"
        @action-RESET-MESSAGE="resetMessage()"
        @action-DOCUMENT_ARCHIVE="handleAddDocuments"
      >
        <template #title>
          <div v-if="ticket">
            <BaseButton v-if="isNavigationValuesEmpty" isClear @click="goToMessagesList">
              <PhCaretLeft :size="18" />
            </BaseButton>
            <span v-if="canOpenCustomerNewTab()"><a @click="openCustomerNewTab()" >{{customerName}}</a> | {{ ticketStatusDescriptionForTitle }}</span>
            <span v-else>{{customerName}} | {{ ticketStatusDescriptionForTitle }}</span>
          </div>
          <div v-else>
            <BaseButton v-if="isNavigationValuesEmpty" isClear @click="goToMessagesList">
              <PhCaretLeft :size="18" />
            </BaseButton>
            Nachricht
          </div>
        </template>
        <template v-if="ticket" #subtitle>{{ ticket.thema }}</template>
        <template #action-BEWERTUNG>
          <hr class="my-3">
          <TicketBewertung centered class="m-0" :value="bewertung" :disabled="bewertungReadonly || savingBewertung"
            @input="setBewertung($event)" />
        </template>
        
        
        
      </PageHeaderTitleNavigation>

      <TicketContent
        :embedded="embedded"
        :loading="loading"
        :textInput="textInput"
        :richText="richText"
        :themaProps="thema"
        :thema="themaDefault"
        @isFormInvalid="setFormInvalid"
        @isUploadDisabled="isUploadDisabled = $event"
        @loading="loading = $event"
        @savingBewertung="savingBewertung = $event"
        @changeChannel="changeChannel"
        @loadTicketData="loadTicketData"
        
      />
      
    </div>


    <BaseModal
      ref="chatModal"
      :modalTitle="ticket ? customerName : 'Nachricht'"
      :showDefaultButtons="false"
      :actions="chatModalActions"
      @action-SEND="onSendChat"
      @action-FILE_SELECT="onUploadChatFile"
      @action-DOCUMENT_ARCHIVE="handleAddDocuments"
      @action-CLOSE="closeTicket()" 
      @action-EDIT-BEARBEITER="openBearbeiterMenu()"
      @action-CHANGE-STATUS="openChangeStatusModal()" 
      @action-RESET-MESSAGE="resetMessage()"
      @onCloseButton="goToLastAction"
    >
      <TicketContent
        :embedded="embedded"
        :loading="loading"
        :thema="themaDefault"
        @isUploadDisabled="isUploadDisabled = $event"
        @isFormInvalid="setFormInvalid"
        @loading="loading = $event"
        @savingBewertung="savingBewertung = $event"
        @changeChannel="changeChannel"
        @loadTicketData="loadTicketData"
      />
      <template #action-BEWERTUNG>
        <hr class="my-3">
        <TicketBewertung centered class="m-0" :value="bewertung" :disabled="bewertungReadonly || savingBewertung"
          @input="setBewertung($event)" />
      </template>
    </BaseModal>
  </div>
</template>

<script>
import CORE_TYPES from '@/store/core/types';
import LOADING_TYPES from '@/store/loading/types';
import COMMUNICATION_TYPES from '@/store/communication/types';
import WEBRTC_TYPES from '@/store/webrtc/types';
import CALENDAR_TYPES from '@/store/calendar/types';
import MAILCOMPOSER_TYPES from "@/store/mailcomposer/types";
import { mapGetters } from 'vuex'
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';

import BaseBackButtonBreadcrumb from '@/components/core/BaseBackButtonBreadcrumb.vue';
import { PhCaretLeft } from 'phosphor-vue'; // Assuming you are using this icon library
import BaseButton from "@/components/core/BaseButton.vue";

import BaseModal from '@/components/core/BaseModal.vue';
import TicketBewertung from '@/views/communication/TicketBewertung.vue';
import { BROKER_LEVEL_ROLES, EMPLOYEE_ROLES, VIEW_ROLES } from '@/router/roles';
import { ROLES } from '@/router/roles';
import TicketContent from '@/views/communication/TicketContent.vue';
import { BaseModalSimpleAction } from '@/components/core/base-modal-actions/base-modal-actions-utils';
import EVENT_BUS, { CHAT_SEND_MESSAGE, CHAT_ADD_DOCUMENT, CHAT_CLOSE, CHAT_EDIT_BEARBEITER, CHAT_CHANGE_STATUS, CHAT_SAVE_BEWERTUNG, } from '@/event-bus';

import { PageHeaderSimpleAction, PageHeaderSlotAction, PageHeaderFileSelectAction, } from '@/components/core/header-title-navigation/page-header-utils';

import { getTicketStatusDescription } from './utils.js';

export default {
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    BaseBackButtonBreadcrumb,
    PhCaretLeft,
    BaseButton,
    BaseModal,
    TicketBewertung,
    TicketContent,
  },

  props: {
    embedded: {
      type: Boolean,
      default: false,
    },
    chatId: {
      default: null,
    },
    channelId: {
      default: null,
    },
    emailId: {
      default: null,
    },
    thema: {
      default: null,
    },
    bezugId: {
      default: null,
    },
    textInput: {
      default: null,
    },
    richText: {
      default: null,
    }
  },

  data: function () {
    return {
      savingBewertung: false,
      isFormInvalid: true,
      isUploadDisabled: true,
      ticketLoading: false,
      chatCommunicationLoading: false,
      loadingTicketOptions: false,
      dontReset: false,
    }
  },

  computed: {
    ...mapGetters({
      defaultReceiver: COMMUNICATION_TYPES.GETTERS.DEFAULT_RECEIVER,
      bearbeiterOptions: COMMUNICATION_TYPES.GETTERS.TICKET_BEARBEITERS,
      ticketInfo: COMMUNICATION_TYPES.GETTERS.TICKET_INFO,
      bewertungInfo: COMMUNICATION_TYPES.GETTERS.BEWERTUNG_INFO,
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
      chatInfos: WEBRTC_TYPES.GETTERS.CHAT_INFO,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      userId: CORE_TYPES.GETTERS.GET_USER_ID,
      backToPreviousData: CORE_TYPES.GETTERS.GET_SAVE_BACK_TO_PREVIOUS_PAGE,
      isInitialLoadingFn: LOADING_TYPES.GETTERS.IS_INITIAL_LOADING,
      loginData: CORE_TYPES.GETTERS.GET_LOGIN_DATA,
      attachments: MAILCOMPOSER_TYPES.GETTERS.SET_ATTACHMENTS,
      isKunde: CORE_TYPES.GETTERS.IS_CUSTOMER_ONLY,
      attachments: MAILCOMPOSER_TYPES.GETTERS.SET_ATTACHMENTS,
    }),
    //Difference between pre defined thema from router (prop) and existing ticket's thema
    themaDefault() {
      if (this.thema)
        return this.thema

      if (this.ticket)
        return this.ticket.thema

      return null
    },
    ticket() {
      return this.ticketInfo.ticket
    },
    chatIdMultipleSource() {
      return this.ticketInfo.ticket?.chatId || this.chatId || 0 // chatId from props or $router
    },
    channelIdMultipleSource() {
      return this.ticketInfo.ticket?.channelId || this.channelId || 0 //channelId from props or $router 
    },
    emailIdMultipleSource() {
      return this.ticketInfo.ticket?.emailId || this.emailId || 0 // emailId from props or $router
    },
    bearbeiterChangeOptions() {
      return this.ticketInfo.bearbeiterOptions
    },
    showBewertung() {
      return this.bewertungInfo.showBewertung
    },
    bewertungReadonly() {
      return this.bewertungInfo.bewertungReadonly
    },
    bewertung() {
      return this.bewertungInfo.bewertung
    },
    canClose() {
      return this.bewertungInfo.canClose
    },
    isLoadingChatMessage() {
      return this.isInitialLoadingFn(`CHAT_MESSAGE-${this.chatIdMultipleSource}-${this.channelIdMultipleSource}`);
    },
    loading() {
      return {
        chatCommunicationLoading: this.chatCommunicationLoading,
        isLoadingChatMessage: this.isLoadingChatMessage,
        loadingTicketOptions: this.loadingTicketOptions,
        ticketLoading: this.ticketLoading,
      }
    },
    isSomeActionLoading() {
      return Object.values(this.loading).some(valueLoading => valueLoading)
    },
    backButtonPeek() {
      return this.backToPreviousData?.slice(-1)?.pop();
    },
    headerActions() {
      const aufgabeAction = PageHeaderSimpleAction('AUFGABE-HINZUFUEGEN', 'Aufgabe hinzufügen')
      
      let actions = [
        PageHeaderSimpleAction('SEND', 'Senden')
          .withVisible(() => !this.closed && this.chatInputEnabled)
          .withDisabled(() => (this.chatInfo && this.chatInfo.readonly) || this.isFormInvalid || this.isSomeActionLoading),
        PageHeaderFileSelectAction('FILE_SELECT', 'Dokumente hinzufügen')
          .withVisible(() => !this.closed && this.chatInputEnabled)
          .withDisabled(() => (this.chatInfo && this.chatInfo.readonly) || this.isUploadDisabled || this.chatIdMultipleSource == 0 || this.isSomeActionLoading),
        PageHeaderSimpleAction('DOCUMENT_ARCHIVE', 'Dokumentenarchiv')
          .withVisible(() => !this.closed && this.chatInputEnabled)
          .withDisabled(() => (this.chatInfo && this.chatInfo.readonly) || this.isUploadDisabled || this.chatIdMultipleSource == 0 || this.isSomeActionLoading),
        aufgabeAction,
        PageHeaderSimpleAction("RESET-MESSAGE",`Nachricht zurücksetzen`
        )
      ]

      if (!this.ticket) return actions;

      return [
        ...actions,
        PageHeaderSimpleAction('CLOSE', 'Abschließen').withVisible(() => this.isCloseVisible),
        PageHeaderSimpleAction('EDIT-BEARBEITER', 'Bearbeiter anpassen')
          .withVisible(() => this.bearbeiterChangeOptions)
          .withDisabled(() => !this.ticketInfo.isBearbeiterAenderbar),
        PageHeaderSimpleAction('CHANGE-STATUS', 'Status ändern').withVisible(() => !this.isKunde),
        PageHeaderSlotAction('BEWERTUNG').withVisible(() => this.showBewertung && !this.isIntern),
      ];
    },
    chatModalActions() {
      let actions = [
        BaseModalSimpleAction('SEND', 'Senden')
          .withVisible(() => !this.closed && this.chatInputEnabled)
          .withDisabled(() => (this.chatInfo && this.chatInfo.readonly) || this.isFormInvalid || this.isSomeActionLoading),
        PageHeaderFileSelectAction('FILE_SELECT', 'Dokumente hinzufügen')
          .withVisible(() => !this.closed && this.chatInputEnabled)
          .withDisabled(() => (this.chatInfo && this.chatInfo.readonly) || this.isUploadDisabled || this.chatIdMultipleSource == 0 || this.isSomeActionLoading),
        PageHeaderSimpleAction('DOCUMENT_ARCHIVE', 'Dokumentenarchiv')
          .withVisible(() => !this.closed && this.chatInputEnabled)
          .withDisabled(() => (this.chatInfo && this.chatInfo.readonly) || this.isUploadDisabled || this.chatIdMultipleSource == 0 || this.isSomeActionLoading),
      ]

      if (!this.ticket) return actions;

      return [
        ...actions,
        BaseModalSimpleAction('CLOSE', 'Abschließen').withVisible(() => this.isCloseVisible),
        BaseModalSimpleAction('EDIT-BEARBEITER', 'Bearbeiter anpassen').withVisible(() => this.bearbeiterChangeOptions),
        PageHeaderSlotAction('BEWERTUNG').withVisible(() => this.showBewertung && !this.isIntern),
      ];
    },
    isIntern() {
      return this.hasRoles([VIEW_ROLES.VIEW_INTERN]);
    },
    isCloseVisible() {
      return !this.closed && this.canClose;
    },
    chatInputEnabled() {
      // IS_TICKET_ERLAUBT checks data privacy as well as other permissions. Just like javaMSC
      // Brokers and Interns can send tickets even if the app contact is disabled.
      return this.hasRoles(ROLES.IS_TICKET_ERLAUBT) || this.hasRoles(VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS);
    },
    chatInfo() {
      return this.chatInfos.find(chatInfo => chatInfo.chatId == this.chatIdMultipleSource && chatInfo.channelId == this.channelIdMultipleSource && (!this.emailIdMultipleSource || chatInfo.emailId == this.emailIdMultipleSource))
    },
    title() {
      let result = "Nachricht";
      if (this.chatIdMultipleSource)
        result += " #" + this.chatIdMultipleSource;
      result += " an ";
      return result;
    },
    customerName() {
      let result = '';
      if (this.chatInfo && this.chatInfo.receiverName)
        result += this.chatInfo.receiverName;
      else if (this.chatIdMultipleSource == 0)
        result += this.defaultReceiver;
      return result;
    },
    ticketStatusDescriptionForTitle() {
      return getTicketStatusDescription(this.isKunde, this.ticket);
    },
    closed() {
      return this.ticket?.status === 'GESCHLOSSEN';
    },
    userToAppointment() {
      return [{
        value: this.loginData.userid,
        label: this.loginData.fullname,
      }]
    },
    isNavigationValuesEmpty() {
      return !(this.backToPreviousData && this.backToPreviousData.length > 0);
    },
  },
  watch: {
    $route() {
      this.reset();
      this.init()
    },
    $isSmallScreen(newVal) {
      if (newVal) {
        this.$refs.chatModal?.open()
      } else {
        this.$refs.chatModal?.close()
      }
    },
    chatId() {
      this.reset();
      this.init();
    },
    channelId() {
      this.reset();
      this.init();
    },
  },
  mounted() {
    if (this.$isSmallScreen) {
      this.$refs.chatModal.open()
    }

    this.init()
  },
  methods: {
    async init() {
      if (this.emailIdMultipleSource) {
        this.loadChatCommunication();
      } else {
        if (!this.chatIdMultipleSource) {
          await this.findTicketOptions();
        } else {
          this.getBewertung();
        }

        if (this.channelIdMultipleSource) {
          this.loadTicketData();
        }

        if (!this.chatInfo) {
          this.loadChatCommunication();
        } else if (this.channelIdMultipleSource && this.chatIdMultipleSource) {
          this.$store.dispatch(WEBRTC_TYPES.ACTIONS.UPDATE_CHAT_MESSAGES, { chatId: this.chatIdMultipleSource, channelId: this.channelIdMultipleSource, emailId: this.emailIdMultipleSource })
        }

        if (!this.defaultReceiver) {
          this.$store.dispatch(COMMUNICATION_TYPES.ACTIONS.GET_DEFAULT_RECEIVER);
        }
      }


      if (this.attachments && this.attachments && this.attachments.length) {
        let allAttachments = []
        const params = this.attachments
          .filter((v) => v?.node && !v.uploaded)
          .map((v) => ({ nodeId: v?.node, fileId: v?.id }));
        if (params.length) {
          axios
            .post(`${process.env.VUE_APP_API}/documents/getTempFiles`, params)
            .then((response) => {
              response?.data?.map((f) => {
                allAttachments.push({
                  id: f.id,
                  label: f.name,
                  fileName: f.name,
                });
              });
            })
            .catch((error) => {
              this.$store.dispatch(
                LOG_TYPES.ACTIONS.ADD_MESSAGE,
                buildMessage(
                  "Beim importieren des Filters ist ein Fehler aufgetreten.",
                  "danger"
                )
              );
            });
        } else {
          this.attachments
            .filter((v) => v?.preloaded)
            .map((f) => {
              allAttachments.push({
                id: f.id,
                label: f.name,
                fileName: f.name,
              });
            });
        }

        this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, null);

        this.onUploadChatFile(allAttachments)
      }
    },
    async findTicketOptions() {
      try {
        this.loadingTicketOptions = true;
        await Promise.all([
          this.$store.dispatch(COMMUNICATION_TYPES.ACTIONS.LOAD_TICKET_COMBOBOXES),
          this.$store.dispatch(COMMUNICATION_TYPES.ACTIONS.GET_CONFIGURED_CHATPARTNER)
        ])
      } finally {
        this.loadingTicketOptions = false;
      }
    },
    async loadChatCommunication(chatId = this.chatIdMultipleSource, channelId = this.channelIdMultipleSource, emailId = this.emailIdMultipleSource) {
      try {
        this.chatCommunicationLoading = true;
        await this.$store.dispatch(WEBRTC_TYPES.ACTIONS.LOAD_CHAT_COMMUNICATION, {
          chatId,
          channelId,
          ...(emailId ? { emailId } : {}),
        })
      } finally {
        this.chatCommunicationLoading = false;
      }
    },
    async loadTicketData(channelId = this.channelIdMultipleSource) {
      this.ticketLoading = true;
      await this.$store.dispatch(COMMUNICATION_TYPES.ACTIONS.LOAD_TICKET_INFO, channelId)
      this.ticketLoading = false;
    },
    getBewertung() {
      this.$store.dispatch(COMMUNICATION_TYPES.ACTIONS.LOAD_BEWERTUNG_INFO, this.chatIdMultipleSource)
    },
    goToLastAction() {
      const { backButtonPeek, } = this;
      const query = { backAction: true };
      const fullPath = backButtonPeek?.fullPath || '/communication/kommunikationsubersicht'

      this.$router.push({ path: fullPath, query: query, });
    },
    setBewertung(bewertung) {
      EVENT_BUS.$emit(CHAT_SAVE_BEWERTUNG, bewertung);
    },
    onSendChat() {
      EVENT_BUS.$emit(CHAT_SEND_MESSAGE);
    },
    onUploadChatFile(files) {
      EVENT_BUS.$emit(CHAT_ADD_DOCUMENT, files);
    },
    closeTicket() {
      EVENT_BUS.$emit(CHAT_CLOSE);
    },
    openBearbeiterMenu() {
      EVENT_BUS.$emit(CHAT_EDIT_BEARBEITER);
    },
    openChangeStatusModal() {
      EVENT_BUS.$emit(CHAT_CHANGE_STATUS);
    },
    canOpenCustomerNewTab() {
      const isTicketSameMakler = this.ticket?.desktopMaklerVonKundeId === this.userId;
      return this.ticket?.kundennr && this.hasRoles([[EMPLOYEE_ROLES.ZUGRIFF_KUNDEN, BROKER_LEVEL_ROLES.ZUGRIFF_KUNDEN]]) && isTicketSameMakler
    },
    openCustomerNewTab() {
      if (this.ticket?.kundennr) {
        this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, { customerId: this.ticket?.kundennr });
      }
    },
    changeChannel({ chatId, channelId }) {
      // a new chat channel has been created that should be switched to
      this.loadChatCommunication(chatId, channelId);
      this.loadTicketData(channelId);
    },
    reset() {
      this.$store.commit(COMMUNICATION_TYPES.MUTATIONS.LOAD_TICKET_INFO_SUCCESS, {});
      this.$store.commit(COMMUNICATION_TYPES.MUTATIONS.LOAD_BEWERTUNG_INFO_SUCCESS, {});
      this.$store.commit(COMMUNICATION_TYPES.MUTATIONS.LOAD_TICKET_COMBOBOXES, { bearbeiter: null });
    },
    handleAddActivity() {
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT, { activity: false });
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_NEW_AUFGABE);
      this.openAppointment();
    },
    openAppointment() {
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_CONFIG, {
        title: 'Aufgabe bearbeiten',
        user: this.userToAppointment,
        subject: '',
        isActivity: true,
        mode: 'Aufgabe',
        back: this.$router.currentRoute.fullPath,
      })
      this.$router.push({ path: `/communication/appointment` });
    },
    setFormInvalid($event) {
      this.isFormInvalid = $event;
    },
    navigateTo(event) {
      this.$router.push({ path: `${event}` });
    },
    getCurrentRoute() {
      return this.$router.currentRoute.path;
    },
    handleAddDocuments() {
      this.dontReset = true
      this.navigateTo(
        "/communication/mailcomposer-dokumentenarchiv/chat?back=" +
        this.getCurrentRoute()
      );
    },
    resetMessage(reset = true) {
      this.$store.commit(
        COMMUNICATION_TYPES.MUTATIONS.SAVE_UNSENT_NACHRICHT,"");
    },
    goToMessagesList() {
      this.$router.push('/communication/postfach/chats');
    },
  },
  beforeRouteLeave(from, to, next) {
    if (!this.dontReset) {
      this.reset();
      this.dontReset = false
    }
    next()
  }
}
</script>