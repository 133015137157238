<template>
    <div>
        <PageHeaderTitleNavigation title="Nachrichten" :actions="headerActions"
            @action-ADD="newTicket()" />

        <BaseFilter title="Nachrichtenfilter" filterId="TicketsNachrichten" :configFilter="filterConfig"
            :metadata="metadata" :defaultOptions="defaultOptions" showSaveButton hasSmartSearch :immidiateSearch="true"
            @onFilter="onFilter" />

        <div class="box__container">
            <GhostLoading v-if="loading" type="table" style="clear: both;" :title="TABLE_TITLE" />
            <NoData v-else-if="isLoaded && !hasTableData" :title="TABLE_TITLE" />
            <Table v-else-if="hasTableData" tableId="cf1a65f8-3933-4d68-b299-a23edeb56b2b" :title="TABLE_TITLE"
                :headers="headers" :headerActions="tableHeaderActions" :rows="rows" :rowsPerPage="30"
                :selected="selectedRows"
                :mobileConfig="{ title: 'nameBeteiligter', headers: ['thema', 'geaendert', 'status'] }"
                @clickRow="clickThema" @selectionAsBlacklist="selectionAsBlacklist" @selected="selected"
                @click-thema="clickThema" @click-nameBeteiligter="openCustomer" @click-kundennr="openCustomer"
                @action-PDF="getPDF" @headerAction-CLOSE="handleClosed" @headerAction-MARK_AS_READ="handleMarkAsRead"  @headerAction-MARK_AS_UNREAD= "handleMarkAsUnRead"/>
        </div>
    </div>
</template>

<script>
import CORE_TYPES from '@/store/core/types';
import LOG_TYPES from '@/store/log/types';
import COMMUNICATION_TYPES from '@/store/communication/types';
import { mapGetters } from 'vuex'
import Table from "@/components/table2/Table.vue";
import { TextColumn, DateColumn, PillColumn, Icon, numberToSortable, ActionColumn, SimpleAction } from "@/components/table2/table_util.js";
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import { DatePickerUtils } from '@/components/core/forms/DatePicker/date-picker-utils';
import { PhEnvelopeSimpleOpen, PhEnvelopeSimple, PhFilePdf } from 'phosphor-vue'
import BaseFilter from "@/components/core/BaseFilter.vue";
import BaseButton from '@/components/core/BaseButton.vue';
import { BROKER_LEVEL_ROLES, EMPLOYEE_ROLES, VIEW_ROLES } from '@/router/roles';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import { viewDocument } from '@/components/core/download/DownloadLink.vue';
import axios from 'axios';
import qs from 'qs';
import { ROLES } from '@/router/roles';

import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

import { getTicketStatusDescription } from './utils.js';

export const PILL_TYPES_STATUS = {
    offen: "primary",
    bearbeitet: "danger",
    geschlossen: "danger",
    ANFRAGE: "warning",
    BITTE_UM_BEARBEITUNG: "warning",
    BITTE_UM_KENNTNISNAHME: "warning",
    IN_BEARBEITUNG: "primary",
    IN_BEARBEITUNG_GESELLSCHAFT: "primary",
    IN_BEARBEITUNG_IT: "primary",
    BEARBEITET: "success",
    GESCHLOSSEN: "info",
};

const TABLE_TITLE = 'Nachrichten';

export default {
    mixins: [],
    components: {
        Table,
        OptionMenu,
        PageHeaderTitleNavigation,
        BaseFilter,
        BaseButton,
        GhostLoading,
        NoData,
    },

    data: function () {
        return {
            TABLE_TITLE,
            apiAddress: process.env.VUE_APP_API,
            filterConfig: {
                placeholderForDefSearchInput: 'Nummer, Thema',
                checkDefaultSearchInput: false,
                cbDefSearchInput: null,
                hideFirstColumn: true,
                filterZurucksetzen: null, // () => {},
                noResetOnDefaultSearchInputExit: true,
            },
            loading: false,
            isLoaded: false,

            selectedRows: [],
            filterParams: {},
            invertSelection: false,
        }
    },

    computed: {
        ...mapGetters({
            hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
            bearbeiterOptions: COMMUNICATION_TYPES.GETTERS.TICKETS_NACHRICHTEN_BEARBEITER,
            ticketsNachrichtenStatuses: COMMUNICATION_TYPES.GETTERS.TICKETS_NACHRICHTEN_STATUSES,
            tickets: COMMUNICATION_TYPES.GETTERS.TICKETS_NACHRICHTEN,
            isIntern: CORE_TYPES.GETTERS.IS_INTERN,
            isKunde: CORE_TYPES.GETTERS.IS_CUSTOMER_ONLY,
            userId: CORE_TYPES.GETTERS.GET_USER_ID,
            isBrokerOrByPass: CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS,
        }),
        headerActions() {
            return [
                PageHeaderSimpleAction('ADD', 'Neue Nachricht').withVisible(() => this.chatEnabled),
            ];
        },
        tableHeaderActions() {
            return [
                PageHeaderSimpleAction('CLOSE', 'Markierte Nachrichten schließen').withVisible(() => this.isBrokerOrByPass).withDisabled(() => !this.selectedRows.length),
                PageHeaderSimpleAction('MARK_AS_READ', 'Markierte Nachrichten als gelesen markieren').withDisabled(() => !this.selectedRows.filter(row => row.ungelesen).length),
                PageHeaderSimpleAction('MARK_AS_UNREAD', 'Markierte Nachrichten als ungelesen markieren').withDisabled(() => !this.selectedRows.filter(row => row.canUnread).length),            
            ];
        },
        chatEnabled() {
            return this.hasRoles(ROLES.DATA_PRIVACY_APP);
        },
        metadata() {
            const result = [
                {
                    type: 'group',
                    key: 'allgemein',
                    label: 'Allgemein',
                    menuItems: [
                        {
                            type: 'combobox',
                            label: 'Bearbeiter',
                            key: 'bearbeiter',
                            comboOptions: this.bearbeiterOptions,
                        },
                        {
                            type: 'text',
                            label: 'Beteiligter Nummer',
                            key: 'beteiligterId',
                            emptyDenied: true,
                        },
                        {
                            type: 'text',
                            label: 'Beteiligter',
                            key: 'beteiligter',
                            emptyDenied: true,
                        },
                        {
                            type: 'text',
                            label: 'Nummer',
                            key: 'ticketNr',
                            emptyDenied: true,
                        },
                        {
                            type: 'text',
                            label: 'Thema',
                            key: 'thema',
                            emptyDenied: true,
                        },
                        {
                            type: 'default',
                            label: 'nur Kundennachrichten',
                            key: 'onlyCustomers',
                            emptyDenied: true,
                        },
                        {
                            type: 'dateRange',
                            label: 'Zeitraum',
                            key: 'zeitraum',
                            emptyDenied: true,
                        },
                        {
                            type: 'default',
                            label: 'Systemerstelle Nachrichten ausblenden',
                            key: 'hideSystem',
                            emptyDenied: true,
                        },
                        {
                            type: 'default',
                            label: 'Ungelesene Nachrichten anzeigen',
                            key: 'showUnread',
                            emptyDenied: true,
                        },
                        {
                            type: 'default',
                            label: 'Auch außerhalb der Verantwortung des ausgewählten Bearbeiters',
                            key: 'ignoreResponsibility',
                            emptyDenied: true,
                        },
                        {
                            type: 'combobox',
                            label: 'Status',
                            key: 'listStatusFilter',
                            allowDuplicated: true,
                            comboOptions: this.ticketsNachrichtenStatuses
                            ,
                        },
                    ],
                },
            ];
            if (this.isIntern) {
                result[0].menuItems.push({
                    type: 'combobox',
                    label: 'Erledigte',
                    key: 'closedTickets',
                    comboOptions: [
                        {
                            label: 'ohne Erledigte (Standard)',
                            value: 0,
                        },
                        {
                            label: 'mit Erledigten',
                            value: 1,
                        },
                        {
                            label: 'nur Erledigte',
                            value: 2,
                        }
                    ],
                });
                result[0].menuItems.push({
                    type: 'combobox',
                    label: 'Note',
                    key: 'note',
                    comboOptions: [
                        {
                            label: 'sehr gut',
                            value: 1,
                        },
                        {
                            label: 'gut',
                            value: 3,
                        },
                        {
                            label: 'weniger gut',
                            value: 5,
                        }
                    ],
                });
            } else {
                result[0].menuItems.push({
                    type: 'combobox',
                    label: 'Geschlossene',
                    key: 'closedTickets',
                    comboOptions: [
                        {
                            label: 'mit Geschlossenen',
                            value: 1,
                        },
                        {
                            label: 'nur Geschlossene',
                            value: 2,
                        }
                    ],
                });
            }

            if(this.isKunde) {
                const hiddenFilters = ['ignoreResponsibility', 'bearbeiter', 'beteiligter', 'beteiligterId', 'onlyCustomers', 'hideSystem', 'listStatusFilter'];
                 result[0].menuItems = result[0].menuItems.filter(filter => !hiddenFilters.includes(filter.key));
            }
            return result;
        },
        headers() {
            return {
                lockedLeft: [
                    DateColumn("erstellt", "erstellt"),
                    DateColumn("geaendert", "geändert"),
                    TextColumn("nameBeteiligter", "Beteiligter").makeConditionalLink("canOpenCustomerNewTab"),
                    TextColumn("thema", "Thema", undefined, 2).makeAlwaysVisible().makeLink(),
                ],
                center: [
                    TextColumn("chatId", "Nummer").makeSortable(numberToSortable),
                    TextColumn("kundennr", "Kundennr.").makeConditionalLink("canOpenCustomerNewTab"),
                    TextColumn("nameBearbeiter", "Bearbeiter"),
                ],
                lockedRight: [
                    PillColumn("status", "Status", 80).makeSortable(pill => pill ? pill.label : ""),
                    ActionColumn("actions", "Aktionen"),
                ],
            };
        },
        rows() {
            const actions = [
                SimpleAction("PDF", PhFilePdf, "PDF herunterladen"),
            ];
            const tickets = (Array.isArray(this.tickets) && this.tickets) || [];
            return tickets/*.filter(t => t.status != "GESCHLOSSEN")*/
            .sort((a, b) => b.dateChangedChat - a.dateChangedChat)
            .map(ticket => {
                let status = getTicketStatusDescription(this.isKunde, ticket);
                const gelesen = [ticket.ungelesen
                    ? Icon(PhEnvelopeSimple, "Nein", 20, 'fill')
                    : Icon(PhEnvelopeSimpleOpen, "Ja", 20)
                ];
                gelesen.sortValue = ticket.ungelesen ? 1 : 0;
                return {
                    ...ticket,
                    gelesen,
                    erstellt: DatePickerUtils.toDateStr(new Date(ticket.dateCreated)),
                    geaendert: DatePickerUtils.toDateStr(new Date(ticket.dateChangedChat)),
                    id: ticket.chatId + " " + ticket.channelId,
                    status: {
                        label: status,
                        type: PILL_TYPES_STATUS[status],
                    },
                    canOpenCustomerNewTab: this.canOpenCustomerNewTab(ticket),
                    customRowStyle: {
                      ...(ticket.ungelesen ? { fontWeight: 'bold', ...(this.$isSmallScreen ? {} : {backgroundColor: 'var(--color-background)'}), } : {}),
                    },
                    actions
                };
            })
        },
        hasTableData() {
            return !!this.rows.length;
        },

        defaultOptions() {
            const startDate = new Date();
            startDate.setMonth(startDate.getMonth() - 1); // For performance reasons only 1 month back
            const emp=this.$store.getters[CORE_TYPES.GETTERS.CHAT_EMPFAENGER_KEY]
            if(emp== null){
                emp='';
            }
            let options = {
                zeitraum: {
                    value: [{
                        key: "min",
                        value: startDate,
                    },
                    {
                        key: "max",
                        value: new Date(),
                    }],
                    fixed: false
                },
                ignoreResponsibility: {
                    value: true, 
                    fixed: false
                },
                bearbeiter: {
                    value: emp, 
                    fixed: false
                }    
            }

            if (this.isKunde) options.closedTickets = { value: '1' }
            return options
        }
    },
    onBeforeMount: () => {

    },
    mounted: function () {
        this.$store.dispatch(COMMUNICATION_TYPES.ACTIONS.LOAD_TICKETS_NACHRICHTEN_COMBOBOXES);
    },

    methods: {
        canOpenCustomerNewTab(ticketNachrichten) {
            return ticketNachrichten?.kundennr && this.hasRoles([[EMPLOYEE_ROLES.ZUGRIFF_KUNDEN, BROKER_LEVEL_ROLES.ZUGRIFF_KUNDEN]])
                && this.hasRoles([VIEW_ROLES.VIEW_BROKER, VIEW_ROLES.VIEW_INTERN, VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS])
        },
        openCustomer(ticketNachrichten) {
            if (!this.canOpenCustomerNewTab(ticketNachrichten)) {
                return;
            }
            this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, { customerId: ticketNachrichten?.kundennr, })
        },
        clickThema(row) {
            this.$router.push({ name: 'ticket', params: { chatId: row.chatId, channelId: row.channelId } });
        },
        onFilter(filterConfig) {
            let params = {};

            filterConfig.forEach(fc => {
                let value = fc.value;
                if (fc.key == 'onlyCustomers' || fc.key == 'hideSystem' || fc.key == 'showUnread' || fc.key == 'ignoreResponsibility')
                    value = true; // the filters with above keys have no input field and should only be considered as boolean (true if added to the filter)
                if (fc.key == "zeitraum") {
                    params.datumVon = DatePickerUtils.toDateStr(value.find(v => v.key == 'min').value);
                    params.datumBis = DatePickerUtils.toDateStr(value.find(v => v.key == 'max').value);
                } else if (fc.key == "listStatusFilter") {
                    if (!params.listStatusFilter) {
                        params.listStatusFilter = [];
                    }
                    params.listStatusFilter.push(value);
                } else {
                  params[fc.key] = value;
                }
            });
            if (params.listStatusFilter) {
                params.listStatusFilter = params.listStatusFilter.join(',');
            }
            this.filterParams = params;
            this.doSearch()
        },
        async doSearch() {
            try {
                this.loading = true;
                await this.$store.dispatch(COMMUNICATION_TYPES.ACTIONS.LOAD_TICKETS_NACHRICHTEN, this.filterParams);
            } finally {
                this.loading = false;
                this.isLoaded = true;
            }
        },
        newTicket() {
            this.$router.push({ name: 'new-ticket' });
        },
        async getPDF(row) {
            const params = {
                chatId: '' + row.chatId,
                thema: row.thema,
                nameBearbeiter: row.nameBearbeiter,
                nameBeteiligter: row.nameBeteiligter,
                erstellt: row.erstellt,
                geaendert: row.geaendert,
                telefon: row.telefon
            }
            const response = await this.$store.dispatch(COMMUNICATION_TYPES.ACTIONS.LOAD_TICKET_PDF, params);

            if (response.data.pdf && response.data.filename) {
                viewDocument({
                    data: response.data.pdf,
                    filename: response.data.filename,
                    contentType: 'application/pdf',
                });
            }
        },
        selected(rows) {
            this.selectedRows = rows;
        },
        handleClosed() {
            const tickets = this.selectedRows.map(row =>
                axios.post(this.apiAddress + '/ticketsnachrichten/closeTicket', qs.stringify({ chatId: row.chatId }), { defaultSpinner: true })
                    .then(response => {
                        this.selectedRows = [];
                    })
                    .catch(error => {
                        this.$store.dispatch(LOG_TYPES.ACTIONS.ERROR, { message: 'error in Ticket.closeTicket', error });
                    })
            );
            Promise.all(tickets).then(value => {
                this.doSearch()
            })
        },
        handleMarkAsRead() {
            const chatIds = this.selectedRows.filter(row => row.ungelesen).map(row => `chatId=${row.chatId}`).join('&')

            axios.post(`${process.env.VUE_APP_API}/ticketsnachrichten/markAsRead?${chatIds}`, {}, { defaultSpinner: true })
                .then(() => {
                    this.selectedRows = [];
                    this.doSearch()
                })
        },
        handleMarkAsUnRead() {
            const chatIds = this.selectedRows.filter(row => row.canUnread).map(row => `chatId=${row.chatId}`).join('&')

            axios.post(`${process.env.VUE_APP_API}/ticketsnachrichten/markAsUnRead?${chatIds}`, {}, { defaultSpinner: true })
                .then(() => {
                    this.selectedRows = [];
                    this.doSearch()
                })
        },
        selectionAsBlacklist(value) {
            this.invertSelection = value;
        },
    },

    beforeRouteLeave(to, from, next) {
        this.$addBreadcrumb({
            label: 'zurück zu Nachrichten',
            to,
            from,
        });

        next()
    },
}
</script>

<style scoped></style>
