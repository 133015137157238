import { APP_NAME_DEFAULT } from "@/store/core/index";

const SimpleActionKundenzugang = { text: `<p>Erleichtern Sie sich den Arbeitsaufwand beim Verschicken der Kundenzugänge an Ihre Kunden. 
Mit dieser Aktion ermittelt das System <strong>jeden</strong> Abend diejenigen Ihrer Kunden (mit eingetragener Mailadresse und Handynummer), 
die sich noch nie in den Kundenzugang bzw. 
Kunden-App eingeloggt haben und schickt diesen dann einmalig eine E-Mail mit einem Link zur Homepage <strong>${APP_NAME_DEFAULT}</strong> und zum <strong>KundenLogin</strong>. 
Legen Sie einen Neukunden an oder ergänzen Sie die persönlichen Daten des Kunden um die Mailadresse sowie die Handynummer, 
und Sie brauchen nicht mehr Ihren Kunden einzeln den Kundenzugang zuzuschicken. Dies geschieht dann automatisch über das System.
</p><p><strong>Achtung:</strong> Wenn Sie über Ihre eigene Homepage den Kundenlogin bzw. auch die eigene Kundenapp in Ihrem Look-and-feel Ihren Kunden anbieten, 
bitten wir Sie den Text der E-Mail unter dem Reiter <strong>Daten/Einstellungen/Einstellungen E-Mailtexte</strong> und der 
Vorlage <strong>Mobiler Zugang</strong> bezüglich Ihre Homepage und Kundenzugang-URL anzupassen. Die App wird automatisch angepasst.</p>`};

const SimpleActionFondsshop = { text: '<p>Bei einigen Kunden wurden die Re-Balancing-Einstellungen nicht korrekt getroffen. Hier können Sie den Kunden eine E-Mail mit dem entsprechenden Antrag zuschicken.</p>'};
const SimpleActionFondsshopStatusMessage = { YES: '',  NO: '<p>Diese Aktion ist momentan <strong>deaktiviert</strong>. Zur Aktivierung müssen weitere Einstellungen getroffen werden</p>'};

const messages = {
    data() {
      return {

      };
    },
    methods: {
        getBeschreibung(key) {
            switch (key) {
                case 'SimpleActionKundenzugang': 
                    return SimpleActionKundenzugang.text;
                case 'SimpleActionFondsshop': 
                    return SimpleActionFondsshop.text;
                default: break;
            }
        },
        getStatusMessage(key, status) {
            switch (key) {
                case 'SimpleActionFondsshop': 
                    return SimpleActionFondsshopStatusMessage[status];
                default: break;
            }
        },        
    },
}

export default messages;
