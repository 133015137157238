<template>
  <div class="box__container">
    <div class="box__title">Unterlagen der FinanzAdmin</div>
    <FormDivider />
    <FormLabel
      label="Anforderung: Abwicklungsrichtlinien und Organisationshandbuch der FinanzAdmin im MSC, im geschützten Bereich der FinanzAdmin Homepage hinterlegte FAQ."
    />
    <InputRadioBoxGroup
      :value="form.INPUT_UNTERLAGEN_BEST1"
      :values="unterlagenBestValues"
      labelClass="font-bold"
      :disabled="disabledStatusOK"
      @input="fieldChanged($event, 'INPUT_UNTERLAGEN_BEST1')"
    />
    <FormDivider />

    <InputToggleSwitch
      label="Ich bestätige hiermit, die Angaben wahrheitsgetreu getätigt zu haben."
      :inLineLabel="true"
      :config="{ bold: true }"
      isComponentHalfSize
      :disabled="disabledStatusOK"
      :value="true"
    />
  </div>
</template>
<script>
import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue";
import FormLabel from "@/components/core/forms/FormLabel.vue";
import FormDivider from "@/components/core/forms/FormDivider.vue";
import InputRadioBoxGroup from "@/components/core/forms/radiobox/InputRadioBoxGroup.vue";
export default {
  components: {
    InputToggleSwitch,
    FormLabel,
    FormDivider,
    InputRadioBoxGroup,
  },
  props: {
    form: {},
  },
  data() {
    return {
      unterlagenBestValues: [
        {
          label:
            "Ich/wir habe/n die oben angeführten Unterlagen, insbesondere die Telekommunikationspolicy gelesen und verstanden und werde/n die darin geforderten Umsetzungen einhalte/n.",
          value: "verstanden",
        },
        {
          label:
            "Ich/wir habe/n die oben angeführten Unterlagen noch nicht vollständig gelesen und verstanden bzw. werde/n diese daher noch nicht einhalten können.",
          value: "nicht gelesen",
        },
        {
          label:
            "Ich/wir habe/n die oben angeführten Unterlagen nicht verstanden und ersuche/n um Kontaktaufnahme.",
          value: "nicht verstanden",
        },
      ],
    };
  },
  methods: {
    fieldChanged(newValue, propName) {
      const payload = { name: propName, value: newValue };
      this.$emit("fieldChanged", payload);
    },
  },
  computed: {
    disabledStatusOK() {
      return (
        this.form.INPUT_STATUS_OK == "1" || this.form.INPUT_STATUS_OK == "true"
      );
    },
  },
};
</script>