<template>
  <div>
    <InputField 
      type="currency" 
      v-model="praemieBtoZahlweise" 
      label="Prämie" 
      isComponentHalfSize
      @change="inputCurrencyChanged('praemieBtoZahlweise', $event, getSparteId)" 
    />
    <InputField
      type="currency"
      isComponentHalfSize
      :precision="2"
      v-model="praemieBtoPa"
      label="Prämie (Jahr)"
      :disabled="true"
    />
    <InputField
      type="currency"
      isComponentHalfSize
      :precision="2"
      v-model="praemieNtoZahlweise"
      label="Prämie Netto"
      :disabled="disabled"
      @change="inputCurrencyChanged('praemieNtoZahlweise', $event, getSparteId)"
    />
    <InputField
      type="currency"
      isComponentHalfSize
      :precision="2"
      v-model="praemieNtoPa"
      label="Prämie Netto (Jahr)"
      :disabled="true"
    />
    <DatePickerField 
      :value="data && data.begin"
      isComponentHalfSize
      label="Sparte Beginn"
      :disabled="true"
    />

    <EntitySelector
      label="Tarif"
      v-model="tarif"
      :values="getComboboxTarif(data)"
      :firstEmpty="true"
      :hasEditButton="false"
      isComponentHalfSize
      @input="inputHauptdataChanged({ componentId: 'tarif', value: $event }, getSparteId)"
      @new="newTarif()"
    />
    <InputToggleSwitch 
      v-model="onlyActivTarif"
      label="nur vertriebene Tarife" 
      inLineLabel suppressValidationMessage
      @input="inputHauptdataChanged({ componentId: 'onlyActivTarif', value: $event }, getSparteId)"/>

    <EntitySelectorPersonPicker
      label="Versicherte Person"
      v-model="versichertePerson"
      v-if="stepConfig && stepConfig.erlaubePerson && layoutModus && layoutModus == 'SPARTE_PERSON'"
      isComponentHalfSize
      :values="getComboboxVersichertePerson()"
      @change="inputHauptdataChanged({ componentId: 'versichertePerson', value: $event }, getSparteId)"
    />
    
    <BaseModal ref="tarif"  labelButtonCancel="Zurück"
       @onCancelButton="closeTarifModal()" @onConfirmButton="addTarif()">
      <template v-slot:modalTitle>
        Daten Versicherungstarif
      </template>
      <div>
        <InputField label="Bezeichnung" v-model="form.bezeichnung" />
        <ComboBox
          label="Gesellschaft"
          v-model="form.gesellschaft" 
          :values="comboboxValues ? comboboxValues.gesellschaft : []"
          :disabled="false"
          @change="inputCurrencyChanged($event, null, zeithorizontStr)" />
        <ComboBox
          label="Sparte"
          v-model="form.sparteTarif" 
          :values="comboboxValues ? comboboxValues.gesellschaft : []"
          :disabled="false"
          @change="inputCurrencyChanged($event, null, zeithorizontStr)" />
        <div v-if="isIntern || isBroker">
          <InputField label="Sichtbar für" v-model="form.visibility" :disabled="isBroker" />
          <InputToggleSwitch v-model="form.struktur" label="inclusive Struktur" inLineLabel suppressValidationMessage :disabled="isBroker" />
          <BaseButton @click="vorgabeTarifdaten()" isSecondary >
            <span>Vorgabe Tarifdaten</span>
          </BaseButton>
          <InputToggleSwitch v-model="form.inactive" label="Tarif wird nicht mehr vertrieben" inLineLabel suppressValidationMessage/>
        </div>
      </div>
    </BaseModal>

  </div>
</template>


<script>
import InputField from "@/components/core/forms/InputField.vue";
import DatePickerField from "@/components/core/forms/date-picker2/DatePickerField.vue";
import InsuranceDetailMixin from './InsuranceDetail-mixin.js';
import EntitySelector from '@/components/core/forms/EntitySelector.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import BaseModal from '@/components/core/BaseModal.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import { mapGetters } from 'vuex';
import CORE_TYPES from '@/store/core/types';
import BaseButton from '@/components/core/BaseButton.vue';
import VERSICHERUNGSTARIFE_TYPES from "@/store/versicherungstarife/types";
import EntitySelectorPersonPicker from '@/components/core/forms/EntitySelectorPersonPicker.vue';

export default {
  mixins: [InsuranceDetailMixin],
  components: {
    InputField,
    DatePickerField,
    EntitySelector,
    InputToggleSwitch,
    ComboBox,
    BaseModal,
    BaseButton,
    EntitySelectorPersonPicker,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {}
    },
    sparteId: {
      type: String,
      default: ''
    },
    comboboxValues: {
      type: Object,
      default: () => {}
    },
    stepConfig: {
      type: Object,
      default: () => {}
    },
    layoutModus: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      form: {},
      praemieBtoZahlweise: '',
      praemieNtoZahlweise: '',
      tarif: '',
      onlyActivTarif: false,
      versichertePerson: '',
    }
  },
  mounted() {
    this.initValues();
  },
  computed: {
    ...mapGetters({
      isIntern: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
      isBroker: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_BROKER,
      selectedTarif: VERSICHERUNGSTARIFE_TYPES.GETTERS.SELECTED_VERSICHERUNGSTARIF,
      originalUser: CORE_TYPES.GETTERS.ORIGINAL_USER,
      userId: CORE_TYPES.GETTERS.GET_USER_ID,
      loginData: CORE_TYPES.GETTERS.GET_LOGIN_DATA,

    }),
    getSparteId() {
      this.initValues();
      return this.sparteId;
    },
    praemieBtoPa() {
      return this.getPraemieJahr(this.praemieBtoZahlweise);
    },
    praemieNtoPa() {
      return this.getPraemieJahr(this.praemieNtoZahlweise);
    }
  },
  methods: {
    initValues() {
      this.praemieBtoZahlweise = this.data?.praemieBtoZahlweise;
      this.praemieNtoZahlweise = this.data?.praemieNtoZahlweise;
      this.tarif = this.data?.tarif;
      this.onlyActivTarif = this.data?.onlyActivTarif;
      this.versichertePerson = this.data?.versichertePerson;
    },
    praemieChanges(compId, value, compIdToUpdate) {
      this.inputCurrencyChanged(compId, value, this.getSparteId);
      // this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.GET_INSURANCE_PRAEMIE_PA, 
      //   { praemie: formatNumber(value), compIdToUpdate: compIdToUpdate, sparteId: this.getSparteId });
    },
    getComboboxTarif() {
      const values = this.getSparteId && this.comboboxValues[this.getSparteId] || {};
      return this.data?.onlyActivTarif ? (values?.tarifOnlyAktiv || []) : (values?.tarifAll || []);
    },
    getComboboxVersichertePerson() {
      if(this.getSparteId) {
        return this.comboboxValues[this.getSparteId].versichertePerson;
      }
      return [];
    },
    newTarif() {

      const newTarifPayload = {
          gesellschaft: {
            id: this.versicherung?.gesellschaft?.id 
          },
          sparte: {
            id: this.data?.sparteId,
            values: [
                {
                  type: 'default',
                  label: this.data?.bezeichnung, 
                  key: this.data?.sparteId,
                  value: this.data?.sparteId
                },
              ],
          },
          besitzer: this.isBroker ? this.originalUser?.userid : this.loginData?.maklernr,
        };

      const gesellschaften = [
        {
          label: this.versicherung?.gesellschaft?.zweigstelleName,
          value: this.versicherung?.gesellschaft?.id,
        }
      ]
      
      
      this.$store.commit(VERSICHERUNGSTARIFE_TYPES.MUTATIONS.RESET_TARIFE_DOKUMENTE);
      this.$store.commit(VERSICHERUNGSTARIFE_TYPES.MUTATIONS.SELECTED_VERSICHERUNGSTARIF, newTarifPayload);

      this.$store.commit(VERSICHERUNGSTARIFE_TYPES.MUTATIONS.VERSICHERUNGSTARIFE_GESELLSCHAFTEN, gesellschaften);

      this.$router.push({path: `/beratung/tarifBearbeiten`, query: {insuranceStep: this.data?.sparteId} });
    },
    closeTarifModal() {
      this.$refs.tarif.close();
    },
    addTarif() {
      this.$store.commit(VERSICHERUNGSTARIFE_TYPES.MUTATIONS.RESET_TARIFE_DOKUMENTE);
      this.$store.commit(VERSICHERUNGSTARIFE_TYPES.MUTATIONS.SELECTED_VERSICHERUNGSTARIF, {gesellschaft: { id: null}, sparte: {values: []}});
      this.$router.push({path: '/beratung/tarifBearbeiten', query: {insuranceStep: this.data?.sparteId} });
    },
    vorgabeTarifdaten() {
      

    },
  }
};
</script>

<style scoped>
</style>