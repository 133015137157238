var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "favoritenlisteModal",
      attrs: {
        modalTitle: "Favoritenlisten",
        labelButtonConfirm: "Hinzufügen",
        confirmDisabled: !_vm.selectedValidRows.length || !_vm.fondHasIsin,
        showCancelButton: !_vm.$isSmallScreen,
        closeButtonLabel: ""
      },
      on: {
        onConfirmButton: _vm.addToFavoritenliste,
        close: function($event) {
          _vm.selectedRows = []
        }
      }
    },
    [
      _vm.loading
        ? _c("GhostLoading", { attrs: { type: "table" } })
        : _c("Table", {
            attrs: {
              headers: _vm.headers,
              rows: _vm.rows,
              mobileConfig: {
                title: "label",
                headers: ["fondIncludedPill"],
                disableClickRow: true
              },
              rowId: "id",
              rowsPerPage: 15,
              tableRowsPerPage: []
            },
            model: {
              value: _vm.selectedRows,
              callback: function($$v) {
                _vm.selectedRows = $$v
              },
              expression: "selectedRows"
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }