import dayjs from 'dayjs';
import { isDate } from '@/helpers/commonfunctions.js';


export function prepareFilters(filters) {
  if(!filters) {
    return {};
  }

  return filters.reduce((result, f) => {
    const key = f.key;
    if(!key) {
      return result;
    }

    switch(key) {
      case 'defaultSearchInput':
        result['allSearch'] = f.value;
        break;
      case 'letztem_monat': {
          const end = new Date();
          const start = new Date(end.getFullYear(), end.getMonth() - 1);
          result[`dateBegin`] = dayjs(start).format('DD.MM.YYYY');
          result[`dateEnd`] = dayjs(end).format('DD.MM.YYYY');
        }
        break;
      case 'dieses_jahr': {
          const end = new Date();
          const start = new Date( end.getFullYear(), 0, 1);
          result[`dateBegin`] = dayjs(start).format('DD.MM.YYYY');
          result[`dateEnd`] = dayjs(end).format('DD.MM.YYYY');
        }
        break;
      case 'zeitraum': {
          const start = f.value[0];
          const end = f.value[1];
          result[`dateBegin`] = isDate(start?.value) && dayjs(start?.value).format('DD.MM.YYYY') || start?.value;
          result[`dateEnd`] = isDate(end?.value) && dayjs(end?.value).format('DD.MM.YYYY') || end?.value;
        }
        break;
      case 'statementIdent': 
        if (f.value === 'alle') {
          result[`isAllCustomers`] = true
        } else if (f.value === 'unterstruktur') {
          result[`isStructureCustomers`] = true;
        }
        break;
      case 'referenzIdent': 
        if (f.value === 'bezugsDatumEinlesen') {
          result[`isReadDate`] = true
        }
        break;
      default:
        result[key] = f.value;
        break;
    }

    return result;
  }, {})
}

export function searchMenu(isIntern, isBroker, isFA) {
  let result = [
    {
      type: 'group',
      key: 'Allgemein',
      label: 'Allgemein',
      menuItems: [
        {
          type: 'text',
          label: 'Umsatzart',
          key: 'transactionsType',
          emptyDenied: true,
        },
        {
          type: 'text',
          label: 'ISIN',
          key: 'isin',
          emptyDenied: true,
          pattern: /\b([A-Z]{2})((?![A-Z]{10}\b)[A-Z0-9]{10})\b/i
        },
        {
          type: 'text',
          label: 'Kontonummer',
          key: 'account',
          emptyDenied: true,
          dataType: 'number',
        },
        {
          type: 'text',
          label: 'Lagerstelle',
          key: 'depository',
          emptyDenied: true,
        },
        {
          type: 'default',
          label: 'ab letztem Monat',
          key: 'letztem_monat',
          emptyDenied: true,
        },
        {
          type: 'dateRange',
          label: 'Zeitraum (ab Datum - bis Datum)',
          key: 'zeitraum',
          emptyDenied: true,
        },
        {
          type: 'default',
          label: 'dieses Jahr',
          key: 'dieses_jahr',
          emptyDenied: true,
        },
        {
          type: 'default',
          label: 'nur Cashflow relevante Transaktionen',
          key: 'cashFlowTransactionOnly',
          emptyDenied: true,
        },
      ]
    }
  ];

  if (isIntern) {
    result[0].menuItems.push( 
      {
        type: 'text',
        label: 'Vermittlernr',
        key: 'vermittlernr',
        emptyDenied: true,
      });
  }

  if (isIntern || isBroker) {
    result[0].menuItems.push( 
      {
        type: 'text',
        label: 'Kundennr',
        key: 'kundennr',
        emptyDenied: true,
        inputmode: 'numeric',
      },      
    )
    result[0].menuItems.push( 
      {
        type: 'combobox',
        label: 'Listenauswahl',
        key: 'statementIdent',
        emptyDenied: true,
        comboOptions: [
          {
            label: 'eigene Kunden',
            value: 'eigene'
          },
          {
            label: 'Kunden der Struktur',
            value: 'unterstruktur'
          },
          {
            label: 'alle Kunden',
            value: 'alle'
          },
        ]
      },      
    )
  }  

  if ((isFA && isBroker) || isIntern) {
    result[0].menuItems.push( 
      {
        type: 'combobox',
        label: 'Referenzdatum',
        key: 'referenzIdent',
        emptyDenied: true,
        comboOptions: [
          {
            label: 'Transaktionsdatum',
            value: 'bezugsDatumTransaktion'
          },
          {
            label: 'Einlesedatum',
            value: 'bezugsDatumEinlesen'
          },
        ]
      },      
    )
  }  

  return result;
}
