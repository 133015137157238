var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 col-xl-11" }, [
        _vm.first
          ? _c(
              "div",
              {
                staticClass:
                  "row d-none d-xl-flex justify-content-between header"
              },
              [
                _c("label", { staticClass: "col-xl-2 px-1" }, [
                  _vm._v("Einmalig")
                ]),
                _c("label", { staticClass: "col-xl-2 px-1" }, [
                  _vm._v("Ratierlich")
                ]),
                _c("label", { staticClass: "col-xl-2 px-1" }, [
                  _vm._v("Zeithorizont")
                ]),
                _c("label", { staticClass: "col-xl-2 px-1" }, [
                  _vm._v("Welche Anlageziele haben Sie?")
                ]),
                !_vm.hideSpezielleZiele
                  ? _c("label", { staticClass: "col-xl-2 px-1" }, [
                      _vm._v("Welche speziellen Anlageziele haben Sie?")
                    ])
                  : _vm._e(),
                _c("label", { staticClass: "col-xl-2 px-1" }, [
                  _vm._v("Bereitschaft Verluste zu tragen")
                ])
              ]
            )
          : _vm._e()
      ])
    ]),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 col-xl-11" }, [
        _c("div", { staticClass: "row justify-content-between" }, [
          _c("label", { staticClass: "col-12" }, [
            _c("span", { staticClass: "header" }, [_vm._v(_vm._s(_vm.title))]),
            _c("span", { staticStyle: { "font-style": "italic" } }, [
              _vm._v(_vm._s(_vm.positionsStr))
            ])
          ]),
          _c(
            "div",
            { staticClass: "col-12 col-xl-2 px-1" },
            [
              _c("label", { staticClass: "d-xl-none" }, [_vm._v("Einmalig")]),
              _c("InputField", {
                attrs: { type: "currency", disabled: _vm.disabled },
                on: {
                  input: function($event) {
                    return _vm.emitChange($event, "einmalig", _vm.einmaligStr)
                  }
                },
                model: {
                  value: _vm.einmalig,
                  callback: function($$v) {
                    _vm.einmalig = $$v
                  },
                  expression: "einmalig"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-12 col-xl-2 px-1" },
            [
              _c("label", { staticClass: "d-xl-none" }, [_vm._v("Ratierlich")]),
              _c("InputField", {
                attrs: { type: "currency", disabled: _vm.disabled },
                on: {
                  input: function($event) {
                    return _vm.emitChange(
                      $event,
                      "ratierlich",
                      _vm.ratierlichStr
                    )
                  }
                },
                model: {
                  value: _vm.ratierlich,
                  callback: function($$v) {
                    _vm.ratierlich = $$v
                  },
                  expression: "ratierlich"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-12 col-xl-2 px-1" },
            [
              _c("label", { staticClass: "d-xl-none" }, [
                _vm._v("Zeithorizont")
              ]),
              _c("ComboBox", {
                attrs: {
                  values: _vm.comboboxValues ? _vm.comboboxValues.horizont : [],
                  disabled: _vm.disabled
                },
                on: {
                  change: function($event) {
                    return _vm.emitChange($event, null, _vm.zeithorizontStr)
                  }
                },
                model: {
                  value: _vm.zeithorizont,
                  callback: function($$v) {
                    _vm.zeithorizont = $$v
                  },
                  expression: "zeithorizont"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-12 col-xl-2 px-1" },
            [
              _c("label", { staticClass: "d-xl-none" }, [_vm._v("Anlageziel")]),
              _c("ComboBox", {
                attrs: {
                  values: _vm.comboboxValues
                    ? _vm.comboboxValues.anlageziel
                    : [],
                  firstEmpty: true,
                  disabled: _vm.disabled
                },
                on: {
                  change: function($event) {
                    return _vm.emitChange($event, null, _vm.anlagezielStr)
                  }
                },
                model: {
                  value: _vm.anlageziel,
                  callback: function($$v) {
                    _vm.anlageziel = $$v
                  },
                  expression: "anlageziel"
                }
              })
            ],
            1
          ),
          !_vm.hideSpezielleZiele
            ? _c(
                "div",
                { staticClass: "col-12 col-xl-2 px-1" },
                [
                  _c("label", { staticClass: "d-xl-none" }, [
                    _vm._v("Anlageziel speziell")
                  ]),
                  _c("ComboBox", {
                    attrs: {
                      values: _vm.comboboxValues
                        ? _vm.comboboxValues.anlagezielSpeziell
                        : [],
                      firstEmpty: true,
                      disabled: _vm.disabled
                    },
                    on: {
                      change: function($event) {
                        return _vm.emitChange(
                          $event,
                          null,
                          _vm.anlagezielSpeziellStr
                        )
                      }
                    },
                    model: {
                      value: _vm.anlagezielSpeziell,
                      callback: function($$v) {
                        _vm.anlagezielSpeziell = $$v
                      },
                      expression: "anlagezielSpeziell"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "col-12 col-xl-2 px-1" },
            [
              _c("label", { staticClass: "d-xl-none" }, [
                _vm._v("Verlusttragfähigkeit")
              ]),
              _c("ComboBox", {
                attrs: {
                  values: _vm.comboboxValues ? _vm.comboboxValues.verlust : [],
                  firstEmpty: true,
                  disabled: _vm.disabled
                },
                on: {
                  change: function($event) {
                    return _vm.emitChange($event, null, _vm.verlustStr)
                  }
                },
                model: {
                  value: _vm.verlust,
                  callback: function($$v) {
                    _vm.verlust = $$v
                  },
                  expression: "verlust"
                }
              })
            ],
            1
          )
        ])
      ]),
      _c(
        "div",
        {
          staticClass:
            "col-12 col-xl-1 mt-2 clickable d-flex align-items-center justify-content-end"
        },
        [
          _c(
            "span",
            { staticClass: "pr-1" },
            [
              _vm.count !== 1
                ? _c("PhTrash", {
                    attrs: { size: 16, weight: "bold" },
                    on: {
                      click: function($event) {
                        return _vm.removeZeile()
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "span",
            { staticClass: "pl-1" },
            [
              _vm.count < _vm.maxRows
                ? _c("PhPlus", {
                    attrs: { size: 16, weight: "bold" },
                    on: {
                      click: function($event) {
                        return _vm.addZeile()
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }