<template>
  <div>
    <FirmaStammdaten v-if="substep === 'stammdaten'" key="firma-stammdaten" />

    <div v-else-if="substep === 'adresse'" class="box__container" key="firma-adresse">
      <div class="box__title">Adresse</div>
      <div class="row">
        <div class="col-12">
          <InputZIP
            label="Postleitzahl"
            :labelClass="depoteroeffnungenLabelClass"
            :isComponentHalfSize="true"
            v-model="personalDataAddress.zip"
            :land="personalDataAddress.country"
            :disabled="!isEditable"
            @change="addCustomerDataEdited('personalDataAddress')"
            @city="setCity($event)"/>
          <InputField label="Ort" 
            :labelClass="depoteroeffnungenLabelClass"
            :isComponentHalfSize="true"
            v-model="personalDataAddress.city"
            :disabled="!isEditable"
            @change="addCustomerDataEdited('personalDataAddress')"/>
          <InputField label="Straße" 
            :labelClass="depoteroeffnungenLabelClass"
            :isComponentHalfSize="true"
            v-model="personalDataAddress.street"
            :disabled="!isEditable"
            @change="addCustomerDataEdited('personalDataAddress')"/>
          <ComboBox label="Land" 
            :isComponentHalfSize="true"
            :values="customerDataConfig.countries"
            v-model="personalDataAddress.country"
            :disabled="!isEditable"
            @change="countryChanged($event); addCustomerDataEditedCombo('personalDataAddress', 'country', $event)"/>
          <ComboBox label="Bundesland"
            :isComponentHalfSize="true" 
            :values="stateComboValues"
            v-model="personalDataAddress.state"
            :disabled="!isEditable"
            @change="addCustomerDataEditedCombo('personalDataAddress', 'state', $event)"/>
        </div>
      </div>
    </div>

    
    <div v-else-if="substep === 'legitimationsurkunde'" key="privat-legitimationsurkunde">
      <div class="box__container">
        <div class="box__title">Notwendige Unterlagen</div>
        </div>
      <LegitimationImagesFirma :rechtsform="customerData.personalDataAddress.rechtsform" :disabled="isSavingCustomerData || !customerData.personalDataAddress.rechtsform" @read-image-data="setReadImageData($event)" @addCustomerDataEdited="addCustomerDataEdited('legitimation')" />
      <div class="box__container" v-if="!HRBNotRequired">
        <div class="row">
          <div class="col-12" >

            <InputField
              :label="legitimNumberLabel"
              :labelClass="depoteroeffnungenLabelClass"
              :isComponentHalfSize="true"
              v-model="legitimation.legitimNumber"
              :disabled="!isLegitimationEditable || !customerData.personalDataAddress.rechtsform"
              @change="setLegiType(); addCustomerDataEdited('legitimation')"/>

            <DatePickerField label="Ausstellungsdatum"
              isValueAsString
              :isComponentHalfSize="true"
              v-model="legitimation.dateOfIssue"
              :disabled="! isLegitimationEditable || !customerData.personalDataAddress.rechtsform"
              validateUntouched
              @input="setLegiType(); addCustomerDataEdited('legitimation');"/>

            <DataConflictModal ref="dataConflictModal" @confirm="confirmDataConflict($event)" />
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="substep === 'geburtstag'" key="firma-geburtstag">
      <div class="box__container" key="firma-geburtstag">
        <div class="box__title">Gründungsdatum und Branche</div>
        <div class="row">
          <div class="col-12">
            <DatePickerField label="Gründungsdatum"
              :labelClass="depoteroeffnungenLabelClass"
              isValueAsString
              emptyValueAsNull
              :isComponentHalfSize="true"
              v-model="personalDataBirth.dayOfBirth"
              :disabled="!isEditable"
              @input="addCustomerDataEdited('personalDataBirth')"/>
            
            <ComboBox label="Gründungsland" 
              :labelClass="depoteroeffnungenLabelClass"
              :isComponentHalfSize="true"
              :values="customerDataConfig.countries"
              v-model="personalDataBirth.countryOfBirth"
              :disabled="!isEditable"
              :firstEmpty="!personalDataBirth.countryOfBirth"
              @change="addCustomerDataEditedCombo('personalDataBirth', 'countryOfBirth', $event)"/>
          </div>
        </div>
      </div>

      <div class="box__container">
        <div class="row">
          <div class="col-12">
            <InputField label="Branche" 
              :labelClass="depoteroeffnungenLabelClass"
              :isComponentHalfSize="true"
              v-model="employment.industry"
              :disabled="!isEditable"
              @change="addCustomerDataEdited('employment')"/>
          </div>
        </div>
      </div>

      <div class="box__container">
        <div class="row">
          <div class="col-12">
            <InputField label="Lohnsumme" 
              :isComponentHalfSize="true"
              type="currency"
              v-model="employment.lohnsumme"
              :disabled="!isEditable"
              @change="addCustomerDataEdited('employment')"/>

            <InputField label="Umsatz"
              :isComponentHalfSize="true"
              type="currency"
              v-model="employment.umsatz"
              :disabled="!isEditable"
              @change="addCustomerDataEdited('employment')"/>

            <InputField label="Anzahl Mitarbeiter Vollzeit"
              :isComponentHalfSize="true"
              type="number"
              v-model="employment.anzahlMitarbeiterVollzeit"
              :disabled="!isEditable"
              @change="addCustomerDataEdited('employment')"/>

            <InputField label="Anzahl Mitarbeiter Teilzeit"
              :isComponentHalfSize="true"
              type="number"
              v-model="employment.anzahlMitarbeiterTeilzeit"
              :disabled="!isEditable"
              @change="addCustomerDataEdited('employment')"/>

            <InputField label="Anzahl Mitarbeiter MiniJob"
              :isComponentHalfSize="true"
              type="number"
              v-model="employment.anzahlMitarbeiterMiniJob"
              :disabled="!isEditable"
              @change="addCustomerDataEdited('employment')"/>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="substep === 'steuerdaten'" class="box__container" key="firma-steuerdaten">
      <div class="box__title">Steuerdaten</div>
      <div class="row">
        <div class="col-12">
          <ComboBox label="Steuerlich in" 
            :labelClass="depoteroeffnungenLabelClass"
            :isComponentHalfSize="true"
            :values="steuerlichInCountries"
            v-model="personalDataTax.taxResidence"
            :disabled="!isEditable"
            :firstEmpty="!hasValidComboValue('personalDataTax', 'taxResidence', steuerlichInCountries)"
            @change="addCustomerDataEditedCombo('personalDataTax', 'taxResidence', $event)"/>

          <InputField label="SteuerIdentNummer TIN/LEI" 
            :isComponentHalfSize="true"
            v-model="personalDataTax.taxID"
            :disabled="!isEditable"
            @change="addCustomerDataEdited('personalDataTax')"/>
          
          <InputField label="Steuernummer" 
            :isComponentHalfSize="true"
            v-model="personalDataTax.taxNr"
            :disabled="!isEditable"
            @change="addCustomerDataEdited('personalDataTax')"/>

              <InputField label="Finanzamt" 
            :isComponentHalfSize="true"
            v-model="personalDataTax.taxOffice"
            :disabled="!isEditable"
            @change="addCustomerDataEdited('personalDataTax')"/>
          
          <InputToggleSwitch 
            label="FATCA - Foreign Account Tax Compliance Act" 
            :inLineLabel="true"
            v-model="fatca.aktiv" 
            :disabled="!isEditable" 
            @input="addCustomerDataEdited('fatca')" />

          <template v-if="fatca.aktiv">
            <InputField 
              label="Art des USA-Bezugs" 
              isComponentHalfSize
              v-model="fatca.text" 
              :disabled="!isEditable" 
              @input="addCustomerDataEdited('fatca')" />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import stammdatenMixin from './stammdaten-mixin'
import { initialState } from './stammdaten-mixin'
import { required } from '@/mixins/validator/rules'
import validator from '@/mixins/validator'
import persoenlicheDatenMixin from '../persoenliche-daten-mixin'

import ComboBox from '@/components/core/forms/ComboBox.vue'
import InputField from '@/components/core/forms/InputField.vue'
import InputZIP from '@/components/core/forms/InputZIP.vue'
import BaseButton from '@/components/core/BaseButton.vue'
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue'
import FirmaStammdaten from './FirmaStammdaten.vue'
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue'
import LegitimationImagesFirma from './LegitimationImagesFirma.vue'
import DataConflictModal from './DataConflictModal.vue'

import { LAST_NAME_REQUIRED_TEXT }  from '@/views/customer/customer-utils'


export default {
  props: {
    substepProperty: {
      type: String,
      default: null
    },
  },
  mixins: [validator, persoenlicheDatenMixin, stammdatenMixin],
  data() {
    return {
      ...initialState(),
    }
  },
  watch: {
    customerData() {
      Object.assign(this.$data, initialState());
      this.updateGroupsIntoData()
    },
    personId() {
      this.updateGroupsIntoData()
      this.findInitialStates()
    },
  },
  computed: {
     hasLegitimType() {
      return !!this.legitimation?.legitimType;
    },
    HRBNotRequired() {
      const noHRBRequired= ['FIRMA', 'STIFTUNG'];
      if(this.isFA) {
        noHRBRequired.push('GBR');
      }
      return noHRBRequired.includes(this.customerData.personalDataAddress.rechtsform);
    },
    legitimNumberLabel() {
      if(this.isFA) {
        if(this.customerData?.personalDataAddress?.rechtsform) {
        switch (this.customerData.personalDataAddress.rechtsform) {
          case 'VEREIN': 
            return 'ZVR-Zahl';
          case 'EINZELFIRMA':
            return 'GISA-Zahl';
          default:
            return 'Firmenbuchnummer';
        }
      } else {
        return 'Firmenbuchnummer';
      }

      } else {
        return 'HRB Nummer';
      }
    }
    // go to stammdaten-mixin.js. Shared computed attributes for Privat.vue and Firma.vue will be there
  },
  methods: {
    // go to stammdaten-mixin.js. Shared methnods for Privat.vue and Firma.vue will be there
    setCity(data) {
      this.personalDataAddress.city = data;
      this.addCustomerDataEdited('personalDataAddress', {
        ...this.personalDataAddress,
        city: data
      })
    },
    setLegiType() { 
      if(!this.hasLegitimType) {
        this.legitimation.legitimType = 'FIRMA'
        this.addCustomerDataEdited('legitimation', {
          ...this.legitimation,
          legitimType: this.legitimation.legitimType,
        });
      }
    }
  },
  created() {
    this.addDepoteroeffnungenHint()
  },
  mounted() {
    this.updateGroupsIntoData();
    this.findInitialStates();
  },
  validators: {
    personalDataAddress: {
      lastName: [required(LAST_NAME_REQUIRED_TEXT)],
    },
  },
  components: {
    ComboBox,
    InputField,
    InputZIP,
    BaseButton,
    DatePickerField,
    FirmaStammdaten,
    InputToggleSwitch,
    DataConflictModal,
    LegitimationImagesFirma
  }
}
</script>

<style scoped>
.box__container {
  flex: 1 1 auto;
}
</style>
