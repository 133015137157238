var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { title: "Persönliche Geschäfte" }
      }),
      _c("BaseFilter", {
        attrs: {
          filterId: "1ce70e20-d307-4e13-a23a-073e853db94c",
          title: "Persönliche Geschäfte",
          metadata: _vm.searchMenu,
          configFilter: _vm.configFilter,
          predefinedFilter: null,
          defaultOptions: _vm.initialFilter,
          extraButton: {
            buttonText: "Markierte Einträge bestätigen",
            isExtraButtonPrimary: true
          },
          immidiateSearch: ""
        },
        on: {
          onFilter: function($event) {
            return _vm.handleSearch($event)
          },
          onFilterZurucksetzen: function($event) {
            return _vm.$emit("onFilterZurucksetzen")
          },
          extraButtonClick: _vm.cofirmSelection
        }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("div", { staticClass: "box__title" }, [
            _vm._v("Liste Fragenbogen")
          ]),
          _vm.pages &&
          Object.keys(_vm.pages).length &&
          _vm.pages[_vm.pageIndex] &&
          _vm.pages[_vm.pageIndex].length
            ? _c(
                "div",
                [
                  !_vm.isLoading && _vm.pageCount > 0
                    ? _c("PaginatedTable", {
                        attrs: {
                          headers: _vm.headers,
                          pages: _vm.pages,
                          pageCount: _vm.pageCount,
                          rowCount: _vm.totalRows,
                          page: _vm.pageIndex
                        },
                        on: {
                          page: _vm.setPageIndex,
                          requestPage: _vm.loadPage,
                          "click-maklernrText": _vm.openMakler,
                          "click-depotnr": _vm.openFragebogen,
                          "action-OPEN": _vm.openFragebogen,
                          "action-TICKET": _vm.createTicket
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "geprueftBemerkung",
                              fn: function(row) {
                                return [
                                  row && row.id
                                    ? _c("InputField", {
                                        attrs: { value: row.geprueftBemerkung },
                                        on: {
                                          change: function($event) {
                                            return _vm.setChanges($event, row)
                                          }
                                        }
                                      })
                                    : _vm._e()
                                ]
                              }
                            },
                            {
                              key: "depotauszugFileName",
                              fn: function(row) {
                                return [
                                  row.depotauszugFileId
                                    ? _c(
                                        "DownloadLink",
                                        {
                                          attrs: {
                                            target: "_blank",
                                            rel: "noopener noreferer",
                                            title: row.depotauszugFileName,
                                            filename: row.depotauszugFileName,
                                            downloadServicePath:
                                              "/get_simple_file",
                                            queryParams: {
                                              fileId: row.depotauszugFileId,
                                              tempFileId: row.depotauszugFileId
                                            }
                                          }
                                        },
                                        [
                                          _c("PhFileText", {
                                            attrs: { size: 20 }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              }
                            },
                            {
                              key: "translisteFileName",
                              fn: function(row) {
                                return [
                                  row.translisteFileId
                                    ? _c(
                                        "DownloadLink",
                                        {
                                          attrs: {
                                            target: "_blank",
                                            rel: "noopener noreferer",
                                            title: row.translisteFileName,
                                            filename: row.translisteFileName,
                                            downloadServicePath:
                                              "/get_simple_file",
                                            queryParams: {
                                              fileId: row.translisteFileId,
                                              tempFileId: row.translisteFileId
                                            }
                                          }
                                        },
                                        [
                                          _c("PhFileText", {
                                            attrs: { size: 20 }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1840544439
                        ),
                        model: {
                          value: _vm.selectedRows,
                          callback: function($$v) {
                            _vm.selectedRows = $$v
                          },
                          expression: "selectedRows"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _c("NoData")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }