var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "persoenliche-daten-view" },
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { title: "Person", subtitle: _vm.ansprechpartner.name }
      }),
      _c("HinweiseUndFehler", {
        attrs: { errors: _vm.warnings, hints: _vm.hints }
      }),
      _c("StepperForm", {
        key: _vm.selectedStepKey,
        ref: "stepperForm",
        attrs: {
          stepType: "tab",
          stepperName: "customer-data",
          stepperMediator: _vm.getStepperMediator,
          selectedStepKey: _vm.selectedStepKey,
          selectedSubstepKey: _vm.selectedSubstepKey,
          hasFertig: false
        },
        on: {
          "set-step": _vm.setStepByKey,
          "set-substep": _vm.setSubstepByKey
        },
        scopedSlots: _vm._u([
          {
            key: "contentTemplate",
            fn: function() {
              return [
                _vm.selectedStepKey === "stammdaten"
                  ? _c(
                      "div",
                      { staticClass: "box__container" },
                      [
                        _c("div", { staticClass: "box__title" }, [
                          _vm._v("Persönliche Daten")
                        ]),
                        _vm.isContactsPersonView && _vm.hasSyncUser
                          ? _c("InputToggleSwitch", {
                              attrs: { label: "Synchronisation" },
                              on: {
                                change: function($event) {
                                  return _vm.addAnsprechpartnerDataEdited(
                                    "ansprechpartner"
                                  )
                                }
                              },
                              model: {
                                value: _vm.ansprechpartner.syncActive,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.ansprechpartner,
                                    "syncActive",
                                    $$v
                                  )
                                },
                                expression: "ansprechpartner.syncActive"
                              }
                            })
                          : _vm._e(),
                        !_vm.isContactsPersonView
                          ? _c("InputField", {
                              attrs: {
                                label: "Unternummer",
                                isComponentHalfSize: "",
                                disabled: true
                              },
                              on: {
                                change: function($event) {
                                  return _vm.addAnsprechpartnerDataEdited(
                                    "ansprechpartner"
                                  )
                                }
                              },
                              model: {
                                value: _vm.ansprechpartner.unternr,
                                callback: function($$v) {
                                  _vm.$set(_vm.ansprechpartner, "unternr", $$v)
                                },
                                expression: "ansprechpartner.unternr"
                              }
                            })
                          : _vm._e(),
                        !_vm.isContactsPersonView
                          ? _c("InputField", {
                              attrs: {
                                label:
                                  "Mitarbeiternummer (zum Login ins smartMSC / App)",
                                isComponentHalfSize: "",
                                disabled: true
                              },
                              on: {
                                change: function($event) {
                                  return _vm.addAnsprechpartnerDataEdited(
                                    "ansprechpartner"
                                  )
                                }
                              },
                              model: {
                                value: _vm.ansprechpartner.mitarbeiternr,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.ansprechpartner,
                                    "mitarbeiternr",
                                    $$v
                                  )
                                },
                                expression: "ansprechpartner.mitarbeiternr"
                              }
                            })
                          : _vm._e(),
                        !_vm.isContactsPersonView
                          ? _c("ComboBox", {
                              attrs: {
                                label: "Verwende als",
                                isComponentHalfSize: "",
                                renderDanger: !_vm.ansprechpartner.typ,
                                values: _vm.typs,
                                disabled:
                                  _vm.disabled || _vm.isVerwendeAlsDisabled
                              },
                              on: {
                                change: function($event) {
                                  return _vm.addAnsprechpartnerDataEditedCombo(
                                    "ansprechpartner",
                                    "typ",
                                    $event
                                  )
                                }
                              },
                              model: {
                                value: _vm.ansprechpartner.typ,
                                callback: function($$v) {
                                  _vm.$set(_vm.ansprechpartner, "typ", $$v)
                                },
                                expression: "ansprechpartner.typ"
                              }
                            })
                          : _vm._e(),
                        !_vm.isContactsPersonView
                          ? _c("InputCheckboxItem", {
                              attrs: {
                                label: "Geschäftsführung darf nicht beraten",
                                disabled:
                                  _vm.disabled || !_vm.isGeschaeftsfuehrung
                              },
                              on: {
                                input: function($event) {
                                  return _vm.addAnsprechpartnerDataEdited(
                                    "ansprechpartner"
                                  )
                                }
                              },
                              model: {
                                value: _vm.ansprechpartner.darfNichtBeraten,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.ansprechpartner,
                                    "darfNichtBeraten",
                                    $$v
                                  )
                                },
                                expression: "ansprechpartner.darfNichtBeraten"
                              }
                            })
                          : _vm._e(),
                        _vm.ansprechpartner.maklerVVAdvisor
                          ? _c("InputCheckboxItem", {
                              attrs: {
                                label: "Advisor (Vermögensverwaltung)",
                                disabled: _vm.disabled
                              },
                              on: {
                                input: function($event) {
                                  return _vm.addAnsprechpartnerDataEdited(
                                    "ansprechpartner"
                                  )
                                }
                              },
                              model: {
                                value: _vm.ansprechpartner.advisor,
                                callback: function($$v) {
                                  _vm.$set(_vm.ansprechpartner, "advisor", $$v)
                                },
                                expression: "ansprechpartner.advisor"
                              }
                            })
                          : _vm._e(),
                        !_vm.isContactsPersonView
                          ? _c("InputCheckboxItem", {
                              attrs: {
                                label: "Vertrauenswürdige Person",
                                disabled: _vm.disabled || _vm.internUser
                              },
                              on: {
                                input: function($event) {
                                  return _vm.addAnsprechpartnerDataEdited(
                                    "vertrauensvollerMitarbeiter"
                                  )
                                }
                              },
                              model: {
                                value:
                                  _vm.ansprechpartner
                                    .vertrauensvollerMitarbeiter,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.ansprechpartner,
                                    "vertrauensvollerMitarbeiter",
                                    $$v
                                  )
                                },
                                expression:
                                  "ansprechpartner.vertrauensvollerMitarbeiter"
                              }
                            })
                          : _vm._e(),
                        _vm.ansprechpartner.maklerVVVermoegensverwalter
                          ? _c("InputCheckboxItem", {
                              attrs: {
                                label: "Vermögensverwalter",
                                disabled: _vm.disabled
                              },
                              on: {
                                input: function($event) {
                                  return _vm.addAnsprechpartnerDataEdited(
                                    "ansprechpartner"
                                  )
                                }
                              },
                              model: {
                                value: _vm.ansprechpartner.vermoegensverwalter,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.ansprechpartner,
                                    "vermoegensverwalter",
                                    $$v
                                  )
                                },
                                expression:
                                  "ansprechpartner.vermoegensverwalter"
                              }
                            })
                          : _vm._e(),
                        _vm.ansprechpartner.maklerRiskManager
                          ? _c("InputCheckboxItem", {
                              attrs: {
                                label: "Risk-Manager",
                                disabled: _vm.disabled
                              },
                              on: {
                                input: function($event) {
                                  return _vm.addAnsprechpartnerDataEdited(
                                    "ansprechpartner"
                                  )
                                }
                              },
                              model: {
                                value: _vm.ansprechpartner.riskmanager,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.ansprechpartner,
                                    "riskmanager",
                                    $$v
                                  )
                                },
                                expression: "ansprechpartner.riskmanager"
                              }
                            })
                          : _vm._e(),
                        _c("InputField", {
                          attrs: {
                            label: "Position",
                            isComponentHalfSize: "",
                            disabled: _vm.disabled
                          },
                          on: {
                            change: function($event) {
                              return _vm.addAnsprechpartnerDataEdited(
                                "ansprechpartner"
                              )
                            }
                          },
                          model: {
                            value: _vm.ansprechpartner.beschreibung,
                            callback: function($$v) {
                              _vm.$set(_vm.ansprechpartner, "beschreibung", $$v)
                            },
                            expression: "ansprechpartner.beschreibung"
                          }
                        }),
                        _c("ComboBox", {
                          attrs: {
                            label: "Anrede",
                            isComponentHalfSize: "",
                            values: _vm.brokerDataConfig.anrede,
                            disabled:
                              _vm.disabled ||
                              _vm.isGeschaeftsfuehrungFieldsDisabled
                          },
                          on: {
                            change: function($event) {
                              return _vm.addAnsprechpartnerDataEditedCombo(
                                "ansprechpartner",
                                "anrede",
                                $event
                              )
                            }
                          },
                          model: {
                            value: _vm.ansprechpartner.anrede,
                            callback: function($$v) {
                              _vm.$set(_vm.ansprechpartner, "anrede", $$v)
                            },
                            expression: "ansprechpartner.anrede"
                          }
                        }),
                        _c("ComboBox", {
                          attrs: {
                            label: "Titel",
                            isComponentHalfSize: "",
                            values: _vm.brokerDataConfig.titles,
                            disabled:
                              _vm.disabled ||
                              _vm.isGeschaeftsfuehrungFieldsDisabled
                          },
                          on: {
                            change: function($event) {
                              return _vm.addAnsprechpartnerDataEditedCombo(
                                "ansprechpartner",
                                "titel",
                                $event
                              )
                            }
                          },
                          model: {
                            value: _vm.ansprechpartner.titel,
                            callback: function($$v) {
                              _vm.$set(_vm.ansprechpartner, "titel", $$v)
                            },
                            expression: "ansprechpartner.titel"
                          }
                        }),
                        _c("InputField", {
                          attrs: {
                            label: "Vorname",
                            isComponentHalfSize: "",
                            disabled:
                              _vm.disabled ||
                              _vm.isGeschaeftsfuehrungFieldsDisabled
                          },
                          on: {
                            change: function($event) {
                              return _vm.addAnsprechpartnerDataEdited(
                                "ansprechpartner"
                              )
                            }
                          },
                          model: {
                            value: _vm.ansprechpartner.vorname,
                            callback: function($$v) {
                              _vm.$set(_vm.ansprechpartner, "vorname", $$v)
                            },
                            expression: "ansprechpartner.vorname"
                          }
                        }),
                        _c("InputField", {
                          attrs: {
                            "data-field-target": "lastNameField",
                            label: "Name",
                            isComponentHalfSize: "",
                            renderDanger: !_vm.ansprechpartner.name,
                            disabled:
                              _vm.disabled ||
                              _vm.isGeschaeftsfuehrungFieldsDisabled
                          },
                          on: {
                            change: function($event) {
                              return _vm.addAnsprechpartnerDataEdited(
                                "ansprechpartner"
                              )
                            }
                          },
                          model: {
                            value: _vm.ansprechpartner.name,
                            callback: function($$v) {
                              _vm.$set(_vm.ansprechpartner, "name", $$v)
                            },
                            expression: "ansprechpartner.name"
                          }
                        }),
                        _c("DatePickerField", {
                          attrs: {
                            label: "Geburtsdatum",
                            isValueAsString: "",
                            isComponentHalfSize: "",
                            disabled: _vm.disabled && !_vm.isEditableWK
                          },
                          on: {
                            change: function($event) {
                              return _vm.addAnsprechpartnerDataEdited(
                                "ansprechpartner"
                              )
                            }
                          },
                          model: {
                            value: _vm.ansprechpartner.gebDatum,
                            callback: function($$v) {
                              _vm.$set(_vm.ansprechpartner, "gebDatum", $$v)
                            },
                            expression: "ansprechpartner.gebDatum"
                          }
                        }),
                        _c("InputField", {
                          attrs: {
                            label: "Geburtsname",
                            isComponentHalfSize: "",
                            disabled: _vm.disabled
                          },
                          on: {
                            change: function($event) {
                              return _vm.addAnsprechpartnerDataEdited(
                                "ansprechpartner"
                              )
                            }
                          },
                          model: {
                            value: _vm.ansprechpartner.gebName,
                            callback: function($$v) {
                              _vm.$set(_vm.ansprechpartner, "gebName", $$v)
                            },
                            expression: "ansprechpartner.gebName"
                          }
                        }),
                        !_vm.isContactsPersonView
                          ? _c("InputField", {
                              attrs: {
                                label: "Geburtsort",
                                isComponentHalfSize: "",
                                disabled: _vm.disabled && !_vm.isEditableWK
                              },
                              on: {
                                change: function($event) {
                                  return _vm.addAnsprechpartnerDataEdited(
                                    "ansprechpartner"
                                  )
                                }
                              },
                              model: {
                                value: _vm.ansprechpartner.gebOrt,
                                callback: function($$v) {
                                  _vm.$set(_vm.ansprechpartner, "gebOrt", $$v)
                                },
                                expression: "ansprechpartner.gebOrt"
                              }
                            })
                          : _vm._e(),
                        !_vm.isContactsPersonView
                          ? _c("ComboBox", {
                              attrs: {
                                label: "Geburtsland",
                                isComponentHalfSize: "",
                                values: _vm.brokerDataConfig.countries,
                                disabled: _vm.disabled
                              },
                              on: {
                                change: function($event) {
                                  return _vm.addAnsprechpartnerDataEditedCombo(
                                    "ansprechpartner",
                                    "gebLand",
                                    $event
                                  )
                                }
                              },
                              model: {
                                value: _vm.ansprechpartner.gebLand,
                                callback: function($$v) {
                                  _vm.$set(_vm.ansprechpartner, "gebLand", $$v)
                                },
                                expression: "ansprechpartner.gebLand"
                              }
                            })
                          : _vm._e(),
                        !_vm.isContactsPersonView
                          ? _c("InputField", {
                              attrs: {
                                label: "Finanzamt",
                                isComponentHalfSize: "",
                                disabled: _vm.disabled
                              },
                              on: {
                                change: function($event) {
                                  return _vm.addAnsprechpartnerDataEdited(
                                    "ansprechpartner"
                                  )
                                }
                              },
                              model: {
                                value: _vm.ansprechpartner.finanzamt,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.ansprechpartner,
                                    "finanzamt",
                                    $$v
                                  )
                                },
                                expression: "ansprechpartner.finanzamt"
                              }
                            })
                          : _vm._e(),
                        !_vm.isFA && !_vm.isContactsPersonView
                          ? _c("InputField", {
                              attrs: {
                                label: "Sozialversicherungsnummer",
                                isComponentHalfSize: "",
                                disabled: _vm.disabled
                              },
                              on: {
                                change: function($event) {
                                  return _vm.addAnsprechpartnerDataEdited(
                                    "ansprechpartner"
                                  )
                                }
                              },
                              model: {
                                value: _vm.ansprechpartner.sozialVersNummer,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.ansprechpartner,
                                    "sozialVersNummer",
                                    $$v
                                  )
                                },
                                expression: "ansprechpartner.sozialVersNummer"
                              }
                            })
                          : _vm._e(),
                        !_vm.isContactsPersonView
                          ? _c("InputField", {
                              attrs: {
                                label: "Gewerbenummer",
                                isComponentHalfSize: "",
                                disabled: _vm.disabled
                              },
                              on: {
                                change: function($event) {
                                  return _vm.addAnsprechpartnerDataEdited(
                                    "ansprechpartner"
                                  )
                                }
                              },
                              model: {
                                value: _vm.ansprechpartner.gewerbenummer,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.ansprechpartner,
                                    "gewerbenummer",
                                    $$v
                                  )
                                },
                                expression: "ansprechpartner.gewerbenummer"
                              }
                            })
                          : _vm._e(),
                        !_vm.isContactsPersonView
                          ? _c("InputField", {
                              attrs: {
                                label: "Gut beraten ID",
                                isComponentHalfSize: "",
                                disabled: _vm.disabled
                              },
                              on: {
                                change: function($event) {
                                  return _vm.addAnsprechpartnerDataEdited(
                                    "ansprechpartner"
                                  )
                                }
                              },
                              model: {
                                value: _vm.ansprechpartner.gutBeratenId,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.ansprechpartner,
                                    "gutBeratenId",
                                    $$v
                                  )
                                },
                                expression: "ansprechpartner.gutBeratenId"
                              }
                            })
                          : _vm._e(),
                        _c("InputCheckboxItem", {
                          attrs: {
                            label: "Person ist verstorben",
                            disabled: _vm.disabled
                          },
                          on: {
                            input: function($event) {
                              return _vm.addAnsprechpartnerDataEdited(
                                "ansprechpartner"
                              )
                            }
                          },
                          model: {
                            value: _vm.ansprechpartner.gestorben,
                            callback: function($$v) {
                              _vm.$set(_vm.ansprechpartner, "gestorben", $$v)
                            },
                            expression: "ansprechpartner.gestorben"
                          }
                        }),
                        _c("DatePickerField", {
                          attrs: {
                            label: "Sterbedatum",
                            isValueAsString: "",
                            isComponentHalfSize: "",
                            disabled: _vm.disabled
                          },
                          on: {
                            change: function($event) {
                              return _vm.addAnsprechpartnerDataEdited(
                                "ansprechpartner"
                              )
                            }
                          },
                          model: {
                            value: _vm.ansprechpartner.sterbeDatum,
                            callback: function($$v) {
                              _vm.$set(_vm.ansprechpartner, "sterbeDatum", $$v)
                            },
                            expression: "ansprechpartner.sterbeDatum"
                          }
                        }),
                        !_vm.isContactsPersonView
                          ? _c("InputCheckboxItem", {
                              attrs: {
                                label: "Zugang freigeschaltet",
                                disabled: _vm.disabled
                              },
                              on: {
                                input: function($event) {
                                  return _vm.addAnsprechpartnerDataEdited(
                                    "ansprechpartner"
                                  )
                                }
                              },
                              model: {
                                value: _vm.ansprechpartner.zugangFreigeschaltet,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.ansprechpartner,
                                    "zugangFreigeschaltet",
                                    $$v
                                  )
                                },
                                expression:
                                  "ansprechpartner.zugangFreigeschaltet"
                              }
                            })
                          : _vm._e(),
                        !_vm.isContactsPersonView
                          ? _c("InputCheckboxItem", {
                              attrs: {
                                label: "Ist aktiv",
                                disabled:
                                  !_vm.ansprechpartner.showAktiv || _vm.disabled
                              },
                              on: {
                                input: function($event) {
                                  return _vm.addAnsprechpartnerDataEdited(
                                    "ansprechpartner"
                                  )
                                }
                              },
                              model: {
                                value: _vm.ansprechpartner.istAktiv,
                                callback: function($$v) {
                                  _vm.$set(_vm.ansprechpartner, "istAktiv", $$v)
                                },
                                expression: "ansprechpartner.istAktiv"
                              }
                            })
                          : _vm._e(),
                        !_vm.isContactsPersonView &&
                        _vm.isRestrictionToBrokerConfigEnabled
                          ? _c("InputCheckboxItem", {
                              attrs: {
                                label:
                                  "Zugriff auf MSC aus Homeoffice / unterwegs erlaubt",
                                disabled: _vm.disabled
                              },
                              on: {
                                input: function($event) {
                                  return _vm.addAnsprechpartnerDataEdited(
                                    "ansprechpartner"
                                  )
                                }
                              },
                              model: {
                                value:
                                  _vm.ansprechpartner
                                    .checkNetworkRestrictionDisregard,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.ansprechpartner,
                                    "checkNetworkRestrictionDisregard",
                                    $$v
                                  )
                                },
                                expression:
                                  "ansprechpartner.checkNetworkRestrictionDisregard"
                              }
                            })
                          : _vm._e(),
                        !_vm.isContactsPersonView
                          ? _c("InputField", {
                              attrs: {
                                label: "Abw. Registernr 34 d/e",
                                isComponentHalfSize: "",
                                disabled: _vm.disabled
                              },
                              on: {
                                change: function($event) {
                                  return _vm.addAnsprechpartnerDataEdited(
                                    "ansprechpartner"
                                  )
                                }
                              },
                              model: {
                                value: _vm.ansprechpartner.registriernr34de,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.ansprechpartner,
                                    "registriernr34de",
                                    $$v
                                  )
                                },
                                expression: "ansprechpartner.registriernr34de"
                              }
                            })
                          : _vm._e(),
                        !_vm.isContactsPersonView
                          ? _c("InputField", {
                              attrs: {
                                label: "Abw. Registernr 34 f/h",
                                isComponentHalfSize: "",
                                disabled: _vm.disabled
                              },
                              on: {
                                change: function($event) {
                                  return _vm.addAnsprechpartnerDataEdited(
                                    "ansprechpartner"
                                  )
                                }
                              },
                              model: {
                                value: _vm.ansprechpartner.registriernr34fh,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.ansprechpartner,
                                    "registriernr34fh",
                                    $$v
                                  )
                                },
                                expression: "ansprechpartner.registriernr34fh"
                              }
                            })
                          : _vm._e(),
                        _c("AnsprechpartnerPicture", {
                          attrs: {
                            personId: _vm.personId,
                            disabled: _vm.disabled
                          }
                        })
                      ],
                      1
                    )
                  : _vm.selectedStepKey === "kontakt"
                  ? _c(
                      "div",
                      { staticClass: "box__container" },
                      [
                        _c("div", { staticClass: "box__title" }, [
                          _vm._v("Kontaktdaten")
                        ]),
                        _c("InputField", {
                          attrs: {
                            label: "E-Mail",
                            type: "email",
                            isComponentHalfSize: "",
                            disabled: _vm.disabled,
                            validateUntouched: ""
                          },
                          on: {
                            change: function($event) {
                              return _vm.addAnsprechpartnerDataEdited(
                                "ansprechpartner"
                              )
                            }
                          },
                          model: {
                            value: _vm.ansprechpartner.email,
                            callback: function($$v) {
                              _vm.$set(_vm.ansprechpartner, "email", $$v)
                            },
                            expression: "ansprechpartner.email"
                          }
                        }),
                        _c("InputField", {
                          attrs: {
                            label: "Telefon",
                            isComponentHalfSize: "",
                            disabled: _vm.disabled,
                            inputmode: "tel"
                          },
                          on: {
                            change: function($event) {
                              return _vm.addAnsprechpartnerDataEdited(
                                "ansprechpartner"
                              )
                            }
                          },
                          model: {
                            value: _vm.ansprechpartner.telefon,
                            callback: function($$v) {
                              _vm.$set(_vm.ansprechpartner, "telefon", $$v)
                            },
                            expression: "ansprechpartner.telefon"
                          }
                        }),
                        _c("InputField", {
                          attrs: {
                            label: "Telefon Privat",
                            isComponentHalfSize: "",
                            disabled: _vm.disabled,
                            inputmode: "tel"
                          },
                          on: {
                            change: function($event) {
                              return _vm.addAnsprechpartnerDataEdited(
                                "ansprechpartner"
                              )
                            }
                          },
                          model: {
                            value: _vm.ansprechpartner.telefonPrivat,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.ansprechpartner,
                                "telefonPrivat",
                                $$v
                              )
                            },
                            expression: "ansprechpartner.telefonPrivat"
                          }
                        }),
                        _c("div", [
                          _c(
                            "div",
                            [
                              _c("InputField", {
                                attrs: {
                                  label: "Mobil",
                                  isComponentHalfSize: "",
                                  disabled: _vm.disabled,
                                  inputmode: "tel"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.addAnsprechpartnerDataEdited(
                                      "ansprechpartner"
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.ansprechpartner.mobil,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ansprechpartner, "mobil", $$v)
                                  },
                                  expression: "ansprechpartner.mobil"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c("InputToggleSwitch", {
                                directives: [
                                  {
                                    name: "fc-tooltip",
                                    rawName: "v-fc-tooltip",
                                    value:
                                      "Dürfen meine Kunden diese Mobilnummer sehen?",
                                    expression:
                                      "'Dürfen meine Kunden diese Mobilnummer sehen?'"
                                  }
                                ],
                                attrs: {
                                  label: "für Kundenkontakt verwenden",
                                  disabled: _vm.disabled
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.saveMobileNumberPreference(
                                      $event
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.ansprechpartner.isMobilePublic,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ansprechpartner,
                                      "isMobilePublic",
                                      $$v
                                    )
                                  },
                                  expression: "ansprechpartner.isMobilePublic"
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _c("InputField", {
                          attrs: {
                            label: "Fax",
                            isComponentHalfSize: "",
                            disabled: _vm.disabled,
                            inputmode: "tel"
                          },
                          on: {
                            change: function($event) {
                              return _vm.addAnsprechpartnerDataEdited(
                                "ansprechpartner"
                              )
                            }
                          },
                          model: {
                            value: _vm.ansprechpartner.fax,
                            callback: function($$v) {
                              _vm.$set(_vm.ansprechpartner, "fax", $$v)
                            },
                            expression: "ansprechpartner.fax"
                          }
                        })
                      ],
                      1
                    )
                  : _vm.selectedStepKey === "postanschrift"
                  ? _c(
                      "div",
                      { staticClass: "box__container" },
                      [
                        _c("div", { staticClass: "box__title" }, [
                          _vm._v("Postanschrift")
                        ]),
                        _c("InputField", {
                          attrs: {
                            label: "Straße, HNr.",
                            isComponentHalfSize: "",
                            disabled: _vm.disabled
                          },
                          on: {
                            change: function($event) {
                              return _vm.addAnsprechpartnerDataEdited(
                                "ansprechpartner"
                              )
                            }
                          },
                          model: {
                            value: _vm.ansprechpartner.strasseHnrPost,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.ansprechpartner,
                                "strasseHnrPost",
                                $$v
                              )
                            },
                            expression: "ansprechpartner.strasseHnrPost"
                          }
                        }),
                        _c("InputField", {
                          attrs: {
                            label: "PLZ",
                            isComponentHalfSize: "",
                            disabled: _vm.disabled
                          },
                          on: {
                            change: function($event) {
                              return _vm.addAnsprechpartnerDataEdited(
                                "ansprechpartner"
                              )
                            }
                          },
                          model: {
                            value: _vm.ansprechpartner.plzPost,
                            callback: function($$v) {
                              _vm.$set(_vm.ansprechpartner, "plzPost", $$v)
                            },
                            expression: "ansprechpartner.plzPost"
                          }
                        }),
                        _c("InputField", {
                          attrs: {
                            label: "Ort",
                            isComponentHalfSize: "",
                            disabled: _vm.disabled
                          },
                          on: {
                            change: function($event) {
                              return _vm.addAnsprechpartnerDataEdited(
                                "ansprechpartner"
                              )
                            }
                          },
                          model: {
                            value: _vm.ansprechpartner.ortPost,
                            callback: function($$v) {
                              _vm.$set(_vm.ansprechpartner, "ortPost", $$v)
                            },
                            expression: "ansprechpartner.ortPost"
                          }
                        }),
                        _c("InputField", {
                          attrs: {
                            label: "Postfach",
                            isComponentHalfSize: "",
                            disabled: _vm.disabled
                          },
                          on: {
                            change: function($event) {
                              return _vm.addAnsprechpartnerDataEdited(
                                "ansprechpartner"
                              )
                            }
                          },
                          model: {
                            value: _vm.ansprechpartner.postfachPost,
                            callback: function($$v) {
                              _vm.$set(_vm.ansprechpartner, "postfachPost", $$v)
                            },
                            expression: "ansprechpartner.postfachPost"
                          }
                        }),
                        _c("InputField", {
                          attrs: {
                            label: "PLZ für Postfach",
                            isComponentHalfSize: "",
                            disabled: _vm.disabled
                          },
                          on: {
                            change: function($event) {
                              return _vm.addAnsprechpartnerDataEdited(
                                "ansprechpartner"
                              )
                            }
                          },
                          model: {
                            value: _vm.ansprechpartner.plzPostfachPost,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.ansprechpartner,
                                "plzPostfachPost",
                                $$v
                              )
                            },
                            expression: "ansprechpartner.plzPostfachPost"
                          }
                        }),
                        _c("ComboBox", {
                          attrs: {
                            label: "Land",
                            isComponentHalfSize: "",
                            values: _vm.brokerDataConfig.countries,
                            disabled: _vm.disabled
                          },
                          on: {
                            change: function($event) {
                              return _vm.addAnsprechpartnerDataEditedCombo(
                                "ansprechpartner",
                                "landPost",
                                $event
                              )
                            }
                          },
                          model: {
                            value: _vm.ansprechpartner.landPost,
                            callback: function($$v) {
                              _vm.$set(_vm.ansprechpartner, "landPost", $$v)
                            },
                            expression: "ansprechpartner.landPost"
                          }
                        }),
                        _vm.isEditable
                          ? [
                              _c("BaseButton", {
                                attrs: {
                                  isSecondary: "",
                                  disabled: _vm.loading,
                                  label:
                                    "Adresse als Privatanschrift übernehmen"
                                },
                                on: { click: _vm.copyToPrivate }
                              }),
                              _c("BaseButton", {
                                attrs: {
                                  isSecondary: "",
                                  disabled: _vm.loading,
                                  label:
                                    "Firmenadresse als Postadresse übernehmen"
                                },
                                on: { click: _vm.copyMaklerAdresse }
                              })
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  : _vm.selectedStepKey === "privatanschrift"
                  ? _c(
                      "div",
                      { staticClass: "box__container" },
                      [
                        _c("div", { staticClass: "box__title" }, [
                          _vm._v("Privatanschrift")
                        ]),
                        _c("InputField", {
                          attrs: {
                            label: "Straße, HNr.",
                            isComponentHalfSize: "",
                            disabled: _vm.disabled && !_vm.isEditableWK
                          },
                          on: {
                            change: function($event) {
                              return _vm.addAnsprechpartnerDataEdited(
                                "ansprechpartner"
                              )
                            }
                          },
                          model: {
                            value: _vm.ansprechpartner.strasseHnrPrivat,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.ansprechpartner,
                                "strasseHnrPrivat",
                                $$v
                              )
                            },
                            expression: "ansprechpartner.strasseHnrPrivat"
                          }
                        }),
                        _c("InputField", {
                          attrs: {
                            label: "PLZ",
                            isComponentHalfSize: "",
                            disabled: _vm.disabled && !_vm.isEditableWK
                          },
                          on: {
                            change: function($event) {
                              return _vm.addAnsprechpartnerDataEdited(
                                "ansprechpartner"
                              )
                            }
                          },
                          model: {
                            value: _vm.ansprechpartner.plzPrivat,
                            callback: function($$v) {
                              _vm.$set(_vm.ansprechpartner, "plzPrivat", $$v)
                            },
                            expression: "ansprechpartner.plzPrivat"
                          }
                        }),
                        _c("InputField", {
                          attrs: {
                            label: "Ort",
                            isComponentHalfSize: "",
                            disabled: _vm.disabled && !_vm.isEditableWK
                          },
                          on: {
                            change: function($event) {
                              return _vm.addAnsprechpartnerDataEdited(
                                "ansprechpartner"
                              )
                            }
                          },
                          model: {
                            value: _vm.ansprechpartner.ortPrivat,
                            callback: function($$v) {
                              _vm.$set(_vm.ansprechpartner, "ortPrivat", $$v)
                            },
                            expression: "ansprechpartner.ortPrivat"
                          }
                        }),
                        _c("InputField", {
                          attrs: {
                            label: "Postfach",
                            isComponentHalfSize: "",
                            disabled: _vm.disabled && !_vm.isEditableWK
                          },
                          on: {
                            change: function($event) {
                              return _vm.addAnsprechpartnerDataEdited(
                                "ansprechpartner"
                              )
                            }
                          },
                          model: {
                            value: _vm.ansprechpartner.postfachPrivat,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.ansprechpartner,
                                "postfachPrivat",
                                $$v
                              )
                            },
                            expression: "ansprechpartner.postfachPrivat"
                          }
                        }),
                        _c("InputField", {
                          attrs: {
                            label: "PLZ für Postfach",
                            isComponentHalfSize: "",
                            disabled: _vm.disabled && !_vm.isEditableWK
                          },
                          on: {
                            change: function($event) {
                              return _vm.addAnsprechpartnerDataEdited(
                                "ansprechpartner"
                              )
                            }
                          },
                          model: {
                            value: _vm.ansprechpartner.plzPostfachPrivat,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.ansprechpartner,
                                "plzPostfachPrivat",
                                $$v
                              )
                            },
                            expression: "ansprechpartner.plzPostfachPrivat"
                          }
                        }),
                        _c("ComboBox", {
                          attrs: {
                            label: "Land",
                            isComponentHalfSize: "",
                            values: _vm.brokerDataConfig.countries,
                            disabled: _vm.disabled && !_vm.isEditableWK
                          },
                          on: {
                            change: function($event) {
                              return _vm.addAnsprechpartnerDataEditedCombo(
                                "ansprechpartner",
                                "landPrivat",
                                $event
                              )
                            }
                          },
                          model: {
                            value: _vm.ansprechpartner.landPrivat,
                            callback: function($$v) {
                              _vm.$set(_vm.ansprechpartner, "landPrivat", $$v)
                            },
                            expression: "ansprechpartner.landPrivat"
                          }
                        })
                      ],
                      1
                    )
                  : _vm.selectedStepKey === "paketanschrift"
                  ? _c(
                      "div",
                      { staticClass: "box__container" },
                      [
                        _c("div", { staticClass: "box__title" }, [
                          _vm._v("Paketanschrift")
                        ]),
                        _c("InputField", {
                          attrs: {
                            label: "Straße, HNr.",
                            isComponentHalfSize: "",
                            disabled: _vm.disabled
                          },
                          on: {
                            change: function($event) {
                              return _vm.addAnsprechpartnerDataEdited(
                                "ansprechpartner"
                              )
                            }
                          },
                          model: {
                            value: _vm.ansprechpartner.strasseHnrPaket,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.ansprechpartner,
                                "strasseHnrPaket",
                                $$v
                              )
                            },
                            expression: "ansprechpartner.strasseHnrPaket"
                          }
                        }),
                        _c("InputField", {
                          attrs: {
                            label: "PLZ",
                            isComponentHalfSize: "",
                            disabled: _vm.disabled
                          },
                          on: {
                            change: function($event) {
                              return _vm.addAnsprechpartnerDataEdited(
                                "ansprechpartner"
                              )
                            }
                          },
                          model: {
                            value: _vm.ansprechpartner.plzPaket,
                            callback: function($$v) {
                              _vm.$set(_vm.ansprechpartner, "plzPaket", $$v)
                            },
                            expression: "ansprechpartner.plzPaket"
                          }
                        }),
                        _c("InputField", {
                          attrs: {
                            label: "Ort",
                            isComponentHalfSize: "",
                            disabled: _vm.disabled
                          },
                          on: {
                            change: function($event) {
                              return _vm.addAnsprechpartnerDataEdited(
                                "ansprechpartner"
                              )
                            }
                          },
                          model: {
                            value: _vm.ansprechpartner.ortPaket,
                            callback: function($$v) {
                              _vm.$set(_vm.ansprechpartner, "ortPaket", $$v)
                            },
                            expression: "ansprechpartner.ortPaket"
                          }
                        }),
                        _c("InputField", {
                          attrs: {
                            label: "Postfach",
                            isComponentHalfSize: "",
                            disabled: _vm.disabled
                          },
                          on: {
                            change: function($event) {
                              return _vm.addAnsprechpartnerDataEdited(
                                "ansprechpartner"
                              )
                            }
                          },
                          model: {
                            value: _vm.ansprechpartner.postfachPaket,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.ansprechpartner,
                                "postfachPaket",
                                $$v
                              )
                            },
                            expression: "ansprechpartner.postfachPaket"
                          }
                        }),
                        _c("InputField", {
                          attrs: {
                            label: "PLZ für Postfach",
                            isComponentHalfSize: "",
                            disabled: _vm.disabled
                          },
                          on: {
                            change: function($event) {
                              return _vm.addAnsprechpartnerDataEdited(
                                "ansprechpartner"
                              )
                            }
                          },
                          model: {
                            value: _vm.ansprechpartner.plzPostfachPaket,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.ansprechpartner,
                                "plzPostfachPaket",
                                $$v
                              )
                            },
                            expression: "ansprechpartner.plzPostfachPaket"
                          }
                        }),
                        _c("ComboBox", {
                          attrs: {
                            label: "Land",
                            isComponentHalfSize: "",
                            values: _vm.brokerDataConfig.countries,
                            disabled: _vm.disabled
                          },
                          on: {
                            change: function($event) {
                              return _vm.addAnsprechpartnerDataEditedCombo(
                                "ansprechpartner",
                                "landPaket",
                                $event
                              )
                            }
                          },
                          model: {
                            value: _vm.ansprechpartner.landPaket,
                            callback: function($$v) {
                              _vm.$set(_vm.ansprechpartner, "landPaket", $$v)
                            },
                            expression: "ansprechpartner.landPaket"
                          }
                        })
                      ],
                      1
                    )
                  : _vm.selectedStepKey === "legitimation"
                  ? _c(
                      "div",
                      [
                        _c("div", { staticClass: "box__container" }, [
                          _c("div", { staticClass: "box__title" }, [
                            _vm._v("Legitimationsurkunde")
                          ])
                        ]),
                        _c("LegitimationImages", {
                          attrs: {
                            legitimation:
                              _vm.ansprechpartner.legitimation || {},
                            mitarbeiterId: _vm.ansprechpartner.personId,
                            isMitarbeiterEditable:
                              _vm.ansprechpartner.editable || _vm.isEditableWK,
                            isMitarbeiter: ""
                          },
                          on: {
                            "read-image-data": function($event) {
                              return _vm.setReadImageDataAnsprechpartner($event)
                            },
                            addAnsprechpartnerDataEdited: function($event) {
                              return _vm.addAnsprechpartnerDataEdited(
                                "legitimation"
                              )
                            }
                          }
                        }),
                        _c(
                          "div",
                          { staticClass: "box__container" },
                          [
                            _c("ComboBox", {
                              attrs: {
                                labelClass: _vm.depoteroeffnungenLabelClass,
                                label: "Art der Urkunde",
                                isComponentHalfSize: "",
                                values: _vm.ansprechpartner.artUrkundeValues,
                                disabled: _vm.disabled && !_vm.isEditableWK
                              },
                              on: {
                                change: function($event) {
                                  return _vm.saveAnsprechpartner($event)
                                }
                              },
                              model: {
                                value:
                                  _vm.ansprechpartner.legitimation.legitimType,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.ansprechpartner.legitimation,
                                    "legitimType",
                                    $$v
                                  )
                                },
                                expression:
                                  "ansprechpartner.legitimation.legitimType"
                              }
                            }),
                            _c("InputField", {
                              attrs: {
                                label: "Ausweisnummer",
                                labelClass: _vm.depoteroeffnungenLabelClass,
                                isComponentHalfSize: "",
                                disabled: _vm.disabled && !_vm.isEditableWK
                              },
                              on: {
                                change: function($event) {
                                  return _vm.addAnsprechpartnerDataEdited(
                                    "ansprechpartner"
                                  )
                                }
                              },
                              model: {
                                value:
                                  _vm.ansprechpartner.legitimation
                                    .legitimNumber,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.ansprechpartner.legitimation,
                                    "legitimNumber",
                                    $$v
                                  )
                                },
                                expression:
                                  "ansprechpartner.legitimation.legitimNumber"
                              }
                            }),
                            _c("DatePickerField", {
                              attrs: {
                                label: "Gültig bis",
                                labelClass: _vm.depoteroeffnungenLabelClass,
                                isValueAsString: "",
                                isComponentHalfSize: "",
                                disabled: _vm.disabled && !_vm.isEditableWK
                              },
                              on: {
                                change: function($event) {
                                  return _vm.addAnsprechpartnerDataEdited(
                                    "ansprechpartner"
                                  )
                                }
                              },
                              model: {
                                value: _vm.ansprechpartner.legitimation.validTo,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.ansprechpartner.legitimation,
                                    "validTo",
                                    $$v
                                  )
                                },
                                expression:
                                  "ansprechpartner.legitimation.validTo"
                              }
                            }),
                            _c("InputField", {
                              attrs: {
                                label: "Ausstellungsbehörde",
                                labelClass: _vm.depoteroeffnungenLabelClass,
                                isComponentHalfSize: "",
                                disabled: _vm.disabled && !_vm.isEditableWK
                              },
                              on: {
                                change: function($event) {
                                  return _vm.addAnsprechpartnerDataEdited(
                                    "ansprechpartner"
                                  )
                                }
                              },
                              model: {
                                value:
                                  _vm.ansprechpartner.legitimation.authority,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.ansprechpartner.legitimation,
                                    "authority",
                                    $$v
                                  )
                                },
                                expression:
                                  "ansprechpartner.legitimation.authority"
                              }
                            }),
                            _c("DatePickerField", {
                              attrs: {
                                label: "Ausstellungsdatum",
                                labelClass: _vm.depoteroeffnungenLabelClass,
                                isValueAsString: "",
                                isComponentHalfSize: "",
                                disabled: _vm.disabled && !_vm.isEditableWK
                              },
                              on: {
                                change: function($event) {
                                  return _vm.addAnsprechpartnerDataEdited(
                                    "ansprechpartner"
                                  )
                                }
                              },
                              model: {
                                value:
                                  _vm.ansprechpartner.legitimation.dateOfIssue,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.ansprechpartner.legitimation,
                                    "dateOfIssue",
                                    $$v
                                  )
                                },
                                expression:
                                  "ansprechpartner.legitimation.dateOfIssue"
                              }
                            }),
                            _c("ComboBox", {
                              attrs: {
                                label: "Staatsangehörigkeit",
                                labelClass: _vm.depoteroeffnungenLabelClass,
                                isComponentHalfSize: "",
                                values: _vm.brokerDataConfig.nationalities,
                                disabled: _vm.disabled && !_vm.isEditableWK
                              },
                              on: {
                                change: function($event) {
                                  return _vm.addAnsprechpartnerDataEditedCombo(
                                    "ansprechpartner",
                                    "nationality",
                                    $event
                                  )
                                }
                              },
                              model: {
                                value: _vm.ansprechpartner.nationality,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.ansprechpartner,
                                    "nationality",
                                    $$v
                                  )
                                },
                                expression: "ansprechpartner.nationality"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm.selectedStepKey === "tickets"
                  ? _c(
                      "div",
                      { staticClass: "box__container" },
                      [
                        _c("div", { staticClass: "box__title" }, [
                          _vm._v("Tickets")
                        ]),
                        _c("InputCheckboxItem", {
                          attrs: {
                            label: "Ticketempfänger",
                            disabled: _vm.disabled
                          },
                          on: {
                            input: function($event) {
                              return _vm.addAnsprechpartnerDataEdited(
                                "ansprechpartner"
                              )
                            }
                          },
                          model: {
                            value: _vm.ansprechpartner.ticketErlaubt,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.ansprechpartner,
                                "ticketErlaubt",
                                $$v
                              )
                            },
                            expression: "ansprechpartner.ticketErlaubt"
                          }
                        }),
                        _c("InputCheckboxItem", {
                          attrs: {
                            label: "Bearbeiter ändern",
                            disabled: _vm.disabled
                          },
                          on: {
                            input: function($event) {
                              return _vm.addAnsprechpartnerDataEdited(
                                "ansprechpartner"
                              )
                            }
                          },
                          model: {
                            value: _vm.ansprechpartner.moveTicket,
                            callback: function($$v) {
                              _vm.$set(_vm.ansprechpartner, "moveTicket", $$v)
                            },
                            expression: "ansprechpartner.moveTicket"
                          }
                        })
                      ],
                      1
                    )
                  : _vm.selectedStepKey === "facsimile"
                  ? _c(
                      "div",
                      [
                        _c("Facsimile", {
                          attrs: {
                            personId: _vm.ansprechpartner.personId,
                            suffix: "STEMPEL"
                          }
                        }),
                        _c("Facsimile", {
                          attrs: { personId: _vm.ansprechpartner.personId }
                        })
                      ],
                      1
                    )
                  : _vm.selectedStepKey === "zusaetzlicheInfos"
                  ? _c("ZusaetzlicheInfos", {
                      attrs: {
                        isEditable: "",
                        zusaetzlicheInfosValues:
                          _vm.brokerDataConfig.zusaetzlicheInfosValues
                      },
                      on: {
                        change: function($event) {
                          return _vm.addAnsprechpartnerDataEdited(
                            "ansprechpartner"
                          )
                        }
                      },
                      model: {
                        value: _vm.ansprechpartner.zusaetzlicheInfos,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.ansprechpartner,
                            "zusaetzlicheInfos",
                            $$v
                          )
                        },
                        expression: "ansprechpartner.zusaetzlicheInfos"
                      }
                    })
                  : _vm.selectedStepKey === "zugriff"
                  ? _c("AnsprechpartnerZugriff", {
                      on: {
                        change: function($event) {
                          return _vm.addAnsprechpartnerDataEdited(
                            "ansprechpartner",
                            {
                              zugriffVertraege: $event
                            }
                          )
                        }
                      }
                    })
                  : _vm.selectedStepKey &&
                    _vm.selectedStepKey.startsWith("menu-config")
                  ? _c(
                      "div",
                      [
                        _c("MenuConfig", {
                          attrs: {
                            currentPath: _vm.menuCurrentPath,
                            configContext: _vm.MENU_CONFIG_CONTEXT
                          }
                        })
                      ],
                      1
                    )
                  : _vm.selectedStepKey === "weitere_zugriffsrechte"
                  ? _c(
                      "div",
                      [
                        _vm.ansprechpartner.unternr
                          ? _c("AdditionalPermissions", {
                              attrs: {
                                unternr: _vm.ansprechpartner.unternr,
                                userPermissionTarget: "MAKLER_PERSONEN"
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm.selectedStepKey === "access-management"
                  ? _c(
                      "div",
                      [
                        _vm.ansprechpartner.unternr
                          ? _c("TwoFactorSMSOption", {
                              attrs: { unternr: _vm.ansprechpartner.unternr }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("DataConflictModal", {
        ref: "dataConflictModal",
        on: {
          confirm: function($event) {
            return _vm.confirmDataConflictAnsprechpartner($event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }