var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "label-value--list" }, [
    !_vm.isMehrfachagent && !_vm.versAdminA && _vm.zugriffVersicherungen
      ? _c("div", { staticClass: "label-value--item" }, [
          _c(
            "div",
            { staticClass: "label-value--item-label" },
            [
              _vm.zugriffVersicherungen
                ? [
                    _vm.steckBriefZusatz.maklervollmacht &&
                    _vm.steckBriefZusatz.maklervollmachtFile &&
                    !_vm.isFPPlus
                      ? _c("DownloadLink", {
                          attrs: {
                            title: _vm.maklervollmachtLabel,
                            filename:
                              _vm.steckBriefZusatz.maklervollmachtFile.name,
                            downloadServicePath: "/docId",
                            queryParams: {
                              docId:
                                _vm.steckBriefZusatz.maklervollmachtFile.dokId
                            }
                          }
                        })
                      : _vm.steckBriefZusatz.maklervollmacht &&
                        _vm.isFA &&
                        !_vm.isFPPlus
                      ? _c("DownloadLink", {
                          attrs: {
                            title: _vm.maklervollmachtLabel,
                            filename: "Antrag.pdf",
                            downloadServicePath: "/getAktuelleMaklervollmacht"
                          }
                        })
                      : !_vm.isFPPlus ||
                        _vm.steckBriefZusatz.maklervollmachtDynamicFormId
                      ? _c(
                          "a",
                          {
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.redirectToMaklervollmacht()
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.maklervollmachtLabel))]
                        )
                      : _c("span", [_vm._v(_vm._s(_vm.maklervollmachtLabel))])
                  ]
                : [_c("span", [_vm._v(_vm._s(_vm.maklervollmachtLabel))])]
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "label-value--item-value" },
            [
              _vm.steckBriefZusatz.maklervollmacht
                ? [
                    _c(
                      "div",
                      { staticClass: "color-success" },
                      [_c("PhCheck")],
                      1
                    )
                  ]
                : [
                    _c(
                      "div",
                      { staticClass: "color-danger" },
                      [_c("PhWarning")],
                      1
                    )
                  ]
            ],
            2
          )
        ])
      : _vm._e(),
    _vm.isMehrfachagent &&
    _vm.steckBriefZusatz.maklervollmacht &&
    _vm.zugriffVersicherungen
      ? _c("div", { staticClass: "label-value--item" }, [
          _c(
            "div",
            { staticClass: "label-value--item-label" },
            [
              _vm.steckBriefZusatz.maklervollmacht &&
              _vm.steckBriefZusatz.maklervollmachtFile &&
              !_vm.isFPPlus
                ? _c("DownloadLink", {
                    attrs: {
                      title: "Mehrfachagentenvollmacht",
                      filename: _vm.steckBriefZusatz.maklervollmachtFile.name,
                      downloadServicePath: "/docId",
                      queryParams: {
                        docId: _vm.steckBriefZusatz.maklervollmachtFile.dokId
                      }
                    }
                  })
                : !_vm.isFPPlus ||
                  _vm.steckBriefZusatz.maklervertragDynamicFormId
                ? _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.redirectToMaklerVertrag()
                        }
                      }
                    },
                    [_vm._v("Mehrfachagentenvollmacht")]
                  )
                : _c("span", [_vm._v("Mehrfachagentenvollmacht")])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "label-value--item-value" },
            [[_c("div", { staticClass: "color-success" }, [_c("PhCheck")], 1)]],
            2
          )
        ])
      : _vm._e(),
    !_vm.isMehrfachagent && !_vm.versAdminA && _vm.zugriffVersicherungen
      ? _c("div", { staticClass: "label-value--item" }, [
          !_vm.isMehrfachagent
            ? _c(
                "div",
                { staticClass: "label-value--item-label" },
                [
                  _vm.zugriffVersicherungen
                    ? [
                        _vm.steckBriefZusatz.maklervertrag &&
                        _vm.steckBriefZusatz.maklervertragFile &&
                        !_vm.isFPPlus
                          ? _c("DownloadLink", {
                              attrs: {
                                title: _vm.maklervertragLabel,
                                filename:
                                  _vm.steckBriefZusatz.maklervertragFile.name,
                                downloadServicePath: "/docId",
                                queryParams: {
                                  docId:
                                    _vm.steckBriefZusatz.maklervertragFile.dokId
                                }
                              }
                            })
                          : _vm.steckBriefZusatz.maklervertrag &&
                            _vm.isFA &&
                            !_vm.isFPPlus
                          ? _c("DownloadLink", {
                              attrs: {
                                title: _vm.maklervertragLabel,
                                filename: "Antrag.pdf",
                                downloadServicePath:
                                  "/getAktuellerMaklervertrag"
                              }
                            })
                          : !_vm.isFPPlus ||
                            _vm.steckBriefZusatz.maklervertragDynamicFormId
                          ? _c(
                              "a",
                              {
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.redirectToMaklerVertrag()
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.maklervertragLabel))]
                            )
                          : _c("span", [_vm._v(_vm._s(_vm.maklervertragLabel))])
                      ]
                    : [_c("span", [_vm._v(_vm._s(_vm.maklervertragLabel))])]
                ],
                2
              )
            : _c("div", { staticClass: "label-value--item-label" }, [
                _vm._v("Mehrfachagent")
              ]),
          _c(
            "div",
            { staticClass: "label-value--item-value" },
            [
              _vm.steckBriefZusatz.maklervertrag
                ? [
                    _c(
                      "div",
                      { staticClass: "color-success" },
                      [_c("PhCheck")],
                      1
                    )
                  ]
                : [
                    _c(
                      "div",
                      { staticClass: "color-danger" },
                      [_c("PhWarning")],
                      1
                    )
                  ]
            ],
            2
          )
        ])
      : _vm._e(),
    _vm.isFK
      ? _c("div", { staticClass: "label-value--item" }, [
          _c("div", { staticClass: "label-value--item-label" }, [
            _vm._v("Datenschutz")
          ]),
          _c(
            "div",
            { staticClass: "label-value--item-value" },
            [
              _vm.steckBriefZusatz.datenSchutz
                ? [
                    _c(
                      "div",
                      { staticClass: "color-success" },
                      [_c("PhCheck")],
                      1
                    )
                  ]
                : [
                    _c(
                      "div",
                      { staticClass: "color-danger" },
                      [_c("PhWarning")],
                      1
                    )
                  ]
            ],
            2
          )
        ])
      : _vm.isFA
      ? _c("div", { staticClass: "label-value--item" }, [
          _c("div", { staticClass: "label-value--item-label" }, [
            _vm._v("Informationsblatt")
          ]),
          _c(
            "div",
            { staticClass: "label-value--item-value" },
            [
              _vm.steckBriefZusatz.informationsblatt
                ? [
                    _c(
                      "div",
                      { staticClass: "color-success" },
                      [_c("PhCheck")],
                      1
                    )
                  ]
                : [
                    _c(
                      "div",
                      { staticClass: "color-danger" },
                      [_c("PhWarning")],
                      1
                    )
                  ]
            ],
            2
          )
        ])
      : _vm._e(),
    _vm.zugriffInvestmentFonds
      ? _c("div", { staticClass: "label-value--item" }, [
          _c("div", { staticClass: "label-value--item-label" }, [
            _vm._v("Rahmenvereinbarung")
          ]),
          _c(
            "div",
            { staticClass: "label-value--item-value" },
            [
              _vm.steckBriefZusatz.rahmenvereinbarung
                ? [
                    _c(
                      "div",
                      { staticClass: "color-success" },
                      [_c("PhCheck")],
                      1
                    )
                  ]
                : [
                    _c(
                      "div",
                      { staticClass: "color-danger" },
                      [_c("PhWarning")],
                      1
                    )
                  ]
            ],
            2
          )
        ])
      : _vm._e(),
    _c("div", { staticClass: "label-value--item" }, [
      _c("div", { staticClass: "label-value--item-label" }, [
        _vm._v("Legitimationsdokumente")
      ]),
      _c(
        "div",
        { staticClass: "label-value--item-value" },
        [
          _vm.steckBriefZusatz.legitiation
            ? [_c("div", { staticClass: "color-success" }, [_c("PhCheck")], 1)]
            : [_c("div", { staticClass: "color-danger" }, [_c("PhWarning")], 1)]
        ],
        2
      )
    ]),
    _c("div", { staticClass: "label-value--item" }, [
      _c("div", { staticClass: "label-value--item-label" }, [
        _vm._v("Zugang " + _vm._s(_vm.appNameDefault)),
        _vm.steckBriefZusatz.lastKSCLogin
          ? _c("span", [
              _vm._v(" (" + _vm._s(_vm.steckBriefZusatz.lastKSCLogin) + ")")
            ])
          : _vm._e()
      ]),
      _c(
        "div",
        { staticClass: "label-value--item-value" },
        [
          _vm.steckBriefZusatz.lastKSCLogin
            ? [_c("div", { staticClass: "color-success" }, [_c("PhCheck")], 1)]
            : [_c("div", { staticClass: "color-danger" }, [_c("PhWarning")], 1)]
        ],
        2
      )
    ]),
    _c("div", { staticClass: "label-value--item" }, [
      _c("div", { staticClass: "label-value--item-label" }, [
        _vm._v("Zugang App"),
        _vm.steckBriefZusatz.lastAppLogin
          ? _c("span", [
              _vm._v(" (" + _vm._s(_vm.steckBriefZusatz.lastAppLogin) + ")")
            ])
          : _vm._e()
      ]),
      _c(
        "div",
        { staticClass: "label-value--item-value" },
        [
          _vm.steckBriefZusatz.lastAppLogin
            ? [_c("div", { staticClass: "color-success" }, [_c("PhCheck")], 1)]
            : [_c("div", { staticClass: "color-danger" }, [_c("PhWarning")], 1)]
        ],
        2
      )
    ]),
    _vm.zugriffInvestmentFonds
      ? _c("div", { staticClass: "label-value--item" }, [
          _c("div", { staticClass: "label-value--item-label" }, [
            _vm._v("Anlegerprofil")
          ]),
          _c(
            "div",
            { staticClass: "label-value--item-value" },
            [
              _vm.info.haveSignedAP
                ? [
                    _c(
                      "div",
                      { staticClass: "color-success" },
                      [_c("PhCheck")],
                      1
                    )
                  ]
                : [
                    _c(
                      "div",
                      { staticClass: "color-danger" },
                      [_c("PhWarning")],
                      1
                    )
                  ]
            ],
            2
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }