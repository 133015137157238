export const MUTATION_PREFIX = 'ANTRAG: ';
export const ACTIONS_PREFIX = 'ANTRAG_ACTIONS_';
export const GETTERS_PREFIX = 'ANTRAG_GETTERS_';

export default {
  MUTATIONS: {
    DELETE_POSITIONS: MUTATION_PREFIX + 'DELETE_POSITIONS',
    DELETE_ALL_POSITIONS_PRO_CATEGORY: MUTATION_PREFIX + 'DELETE_ALL_POSITIONS_PRO_CATEGORY',
    UPDATE_POSITIONS: MUTATION_PREFIX + 'UPDATE_POSITIONS',
    UPDATE_POSITION_DATA: MUTATION_PREFIX + 'UPDATE_POSITION_DATA',
    UPDATE_ANTRAG_DATA: MUTATION_PREFIX + 'UPDATE_ANTRAG_DATA',
    UPDATE_HIGHEST_STEP: MUTATION_PREFIX + 'UPDATE_HIGHEST_STEP',
    UPDATE_CURRENT_STEP: MUTATION_PREFIX + 'UPDATE_CURRENT_STEP',
    SET_ANTRAG_DATA: MUTATION_PREFIX + 'SET_ANTRAG_DATA',
    GET_ANTRAG_SUCCESS: MUTATION_PREFIX + 'GET_ANTRAG_SUCCESS',
    RELOAD_ANTRAG_SUCCESS: MUTATION_PREFIX + 'RELOAD_ANTRAG_SUCCESS',
    SAVE_ANTRAG_SUCCESS: MUTATION_PREFIX + 'SAVE_ANTRAG_SUCCESS',
    REMOVE_ANTRAG_WARNING: MUTATION_PREFIX + 'REMOVE_ANTRAG_WARNING',
    GET_EFONDS_PERSONS_SUCCESS: ACTIONS_PREFIX + 'GET_EFONDS_PERSONS_SUCCESS',
    UPDATE_IS_VORGELEGT: ACTIONS_PREFIX + 'UPDATE_IS_VORGELEGT',
    
    SET_ATTACHMENTS: MUTATION_PREFIX + 'SET_ATTACHMENTS',
    RESET_ATTACHMENTS:MUTATION_PREFIX + 'RESET_ATTACHMENTS',
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
    RESET_ANTRAG_ACTION_LOADING_ACTIVE: MUTATION_PREFIX + 'RESET_ANTRAG_ACTION_LOADING_ACTIVE',

    SET_REQUESTED_EBASE_DEPOTNUMMER: MUTATION_PREFIX + 'SET_REQUESTED_EBASE_DEPOTNUMMER',
    SET_REQUESTED_EBASE_AAB_ANLAGESTRATEGIEN: MUTATION_PREFIX + 'SET_REQUESTED_EBASE_AAB_ANLAGESTRATEGIEN',

    SET_ANTRAG_RELOAD_PAYLOAD: MUTATION_PREFIX + 'SET_ANTRAG_RELOAD_PAYLOAD',
    CAPITALBANK_DEPOTS_VORHANDEN_SUCCESS: MUTATION_PREFIX + 'CAPITALBANK_DEPOTS_VORHANDEN_SUCCESS',
    START_SAVE_ANTRAG_STATE: MUTATION_PREFIX + 'START_SAVE_ANTRAG_STATE',
    END_SAVE_ANTRAG_STATE: MUTATION_PREFIX + 'END_SAVE_ANTRAG_STATE',
    RETRIEVE_ANTRAG_LIST_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_ANTRAG_LIST_SUCCESS',
    SET_ANTRAG_LIST_FORMULARE: MUTATION_PREFIX + 'SET_ANTRAG_LIST_FORMULARE',
    PORTFOLIO_STRUKTUR_SUCCESS: MUTATION_PREFIX + 'PORTFOLIO_STRUKTUR_SUCCESS',
    RETRIEVE_GESELLSCHAFTS_FORMULARE_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_GESELLSCHAFTS_FORMULARE_SUCCESS',
    RETRIEVE_GESELLSCHAFTS_FORMULARE_LINKS_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_GESELLSCHAFTS_FORMULARE_LINKS_SUCCESS',
    RETRIEVE_GESELLSCHAFTS_MAINSTRUCTURE_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_GESELLSCHAFTS_MAINSTRUCTURE_SUCCESS',
    UPDATE_POSITIONS_FREMDWAEHRUNG: MUTATION_PREFIX + 'UPDATE_POSITIONS_FREMDWAEHRUNG',
    GET_FREMDWAEHRUNG_VALUES_SUCESS: MUTATION_PREFIX + 'GET_FREMDWAEHRUNG_VALUES_SUCESS',
    SET_ATTACHMENTS_GELDWAESCHE: MUTATION_PREFIX + 'SET_ATTACHMENTS_GELDWAESCHE',
    RESET_ATTACHMENTS_GELDWAESCHE:MUTATION_PREFIX + 'RESET_ATTACHMENTS_GELDWAESCHE',
    SET_TODO_VIDEO_INDENT: MUTATION_PREFIX + 'SET_TODO_VIDEO_INDENT',
    REMOVE_ATTACHMENT: MUTATION_PREFIX + 'REMOVE_ATTACHMENT',
    SAVE_ATTACHMENTS_SUCCESS: MUTATION_PREFIX + 'SAVE_ATTACHMENTS_SUCCESS',

    SET_ANTRAG_ACTION_LOADING: MUTATION_PREFIX + 'SET_ANTRAG_ACTION_LOADING',
    START_ANTRAG_ACTION_LOADING: MUTATION_PREFIX + 'START_ANTRAG_ACTION_LOADING',
    STOP_ANTRAG_ACTION_LOADING: MUTATION_PREFIX + 'STOP_ANTRAG_ACTION_LOADING',
  },
  ACTIONS: {
    GET_ANTRAG: ACTIONS_PREFIX + 'GET_ANTRAG',
    UPDATE_ANTRAG_DATA: ACTIONS_PREFIX + 'UPDATE_ANTRAG_DATA',
    RELOAD_ANTRAEGE_DATA: ACTIONS_PREFIX + 'RELOAD_ANTRAEGE_DATA',
    RELOAD_ANTRAG_PERSONEN_AUSWAHL_CHANGE: ACTIONS_PREFIX + 'RELOAD_ANTRAG_PERSONEN_AUSWAHL_CHANGE',
    RELOAD_ANTRAG_TRIGGER_CHANGE: ACTIONS_PREFIX + 'RELOAD_ANTRAG_TRIGGER_CHANGE',
    RELOAD_ANTRAG_SUBMIT: ACTIONS_PREFIX + 'RELOAD_ANTRAG_SUBMIT',
    SAVE_ANTRAG: ACTIONS_PREFIX + 'SAVE_ANTRAG',
    ADD_POSITIONS: ACTIONS_PREFIX + 'ADD_POSITIONS',
    UPDATE_SELECTION: ACTIONS_PREFIX + 'UPDATE_SELECTION',
    DELETE_POSITIONS: ACTIONS_PREFIX + 'DELETE_POSITIONS',
    REQUEST_EBASE_DEPOTNUMMER: ACTIONS_PREFIX + 'REQUEST_EBASE_DEPOTNUMMER',
    OPEN_PDF: ACTIONS_PREFIX + 'OPEN_PDF',
    OPEN_BERICHT: ACTIONS_PREFIX + 'OPEN_BERICHT',
    OPEN_BERICHT_PDF: ACTIONS_PREFIX + 'OPEN_BERICHT_PDF',
    SEND_BERICHT_PDF: ACTIONS_PREFIX + 'SEND_BERICHT_PDF',
    PATCH_ANTRAG: ACTIONS_PREFIX + 'PATCH_ANTRAG',
    RELOAD_ANTRAG: ACTIONS_PREFIX + 'RELOAD_ANTRAG',
    RETRIEVE_ANTRAG_LIST_ONLY: ACTIONS_PREFIX + 'RETRIEVE_ANTRAG_LIST_ONLY',
    RETRIEVE_ANTRAG_LIST: ACTIONS_PREFIX + 'RETRIEVE_ANTRAG_LIST',
    RETRIEVE_ANTRAG_LIST_FORMULARE: ACTIONS_PREFIX + 'RETRIEVE_ANTRAG_LIST_FORMULARE',
    RETRIEVE_ANTRAG_LIST_OPTION_MENU: ACTIONS_PREFIX + 'RETRIEVE_ANTRAG_LIST_OPTION_MENU',
    PORTFOLIO_STRUKTUR: ACTIONS_PREFIX + 'PORTFOLIO_STRUKTUR',
    RETRIEVE_GESELLSCHAFTS_FORMULARE: ACTIONS_PREFIX + 'RETRIEVE_GESELLSCHAFTS_FORMULARE',
    RETRIEVE_GESELLSCHAFTS_FORMULARE_LINKS: ACTIONS_PREFIX + 'RETRIEVE_GESELLSCHAFTS_FORMULARE_LINKS',
    RETRIEVE_GESELLSCHAFTS_MAINSTRUCTURE: ACTIONS_PREFIX + 'RETRIEVE_GESELLSCHAFTS_MAINSTRUCTURE',
    UPDATE_KONSORTE_EBASE_AAB: ACTIONS_PREFIX + 'UPDATE_KONSORTE_EBASE_AAB',
    GET_WAEHRUNG_VALUES: ACTIONS_PREFIX + 'GET_WAEHRUNG_VALUES',
    CAPITALBANK_DEPOTS_VORHANDEN:ACTIONS_PREFIX + 'CAPITALBANK_DEPOTS_VORHANDEN',
    GET_FREMDWAEHRUNG_VALUES: ACTIONS_PREFIX + 'GET_FREMDWAEHRUNG_VALUES',
    GET_ATTACHMENTS_GELDWAESCHE: ACTIONS_PREFIX + '  GET_ATTACHMENTS_GELDWAESCHE',
    SAVE_ATTACHMENTS: MUTATION_PREFIX + 'SAVE_ATTACHMENTS',
    START_VIDEO_INDENT: ACTIONS_PREFIX + 'START_VIDEO_INDENT',
    LOAD_TODO_VIDEO_INDENT: ACTIONS_PREFIX + 'LOAD_TODO_VIDEO_INDENT',
    LOAD_FFB_DOCS_DATA: ACTIONS_PREFIX + 'LOAD_FFB_DOCS_DATA',
    SAVE_FFB_SORGRECHTSNACHWEIS: ACTIONS_PREFIX + 'SAVE_FFB_SORGRECHTSNACHWEIS',
    IS_SPARPLAN_AVAILABLE: ACTIONS_PREFIX + 'IS_SPARPLAN_AVAILABLE',
    GET_VERMITTLERAUSWAHL: ACTIONS_PREFIX + 'GET_VERMITTLERAUSWAHL',
    GET_VERMITTLER_ADDRESS:ACTIONS_PREFIX + 'GET_VERMITTLER_ADDRESS',
    PRODUCE_MUSTERVORLAGE_PDF: ACTIONS_PREFIX + 'PRODUCE_MUSTERVORLAGE_PDF',
    TO_SIGNATURE_VORLEGEN: ACTIONS_PREFIX + 'TO_SIGNATURE_VORLEGEN',
    GET_PDF_LIST: ACTIONS_PREFIX + 'GET_PDF_LIST',
  },
  GETTERS: {
    ANTRAEGE: GETTERS_PREFIX + 'ANTRAEGE',
    ANTRAEGE_DATA: GETTERS_PREFIX + 'ANTRAEGE_DATA',
    POSITIONS: GETTERS_PREFIX + 'POSITIONS',
    POSITION_DATA: GETTERS_PREFIX + 'POSITION_DATA',
    REQUESTED_EBASE_DEPOTNUMMER: GETTERS_PREFIX + 'REQUESTED_EBASE_DEPOTNUMMER',
    REQUESTED_EBASE_AAB_ANLAGESTRATEGIEN: GETTERS_PREFIX + 'REQUESTED_EBASE_AAB_ANLAGESTRATEGIEN',
    ANTRAEGE_RELOAD_PAYLOAD: GETTERS_PREFIX + 'ANTRAEGE_RELOAD_PAYLOAD',
    SAVE_ANTRAG_STATE: GETTERS_PREFIX + 'SAVE_ANTRAG_STATE',
    ANTRAG_LIST: GETTERS_PREFIX + 'ANTRAG_LIST',
    ANTRAG_LIST_FORMULARE: GETTERS_PREFIX + 'ANTRAG_LIST_FORMULARE',
    GESELLSCHAFTS_FORMULARE: GETTERS_PREFIX + 'GESELLSCHAFTS_FORMULARE',
    GESELLSCHAFTS_FORMULARE_LINKS: GETTERS_PREFIX + 'GESELLSCHAFTS_FORMULARE_LINKS',
    GESELLSCHAFTS_MAINSTRUCTURE: GETTERS_PREFIX + 'GESELLSCHAFTS_MAINSTRUCTURE',
    POSITIONS_FREMDWAEHRUNG: GETTERS_PREFIX + 'POSITIONS_FREMDWAEHRUNG',
    CAPITALBANK_DEPOTS_VORHANDEN:GETTERS_PREFIX + 'CAPITALBANK_DEPOTS_VORHANDEN',
    GET_ATTACHMENTS: GETTERS_PREFIX + 'GET_ATTACHMENTS',
    GET_ATTACHMENTS_GELDWAESCHE: GETTERS_PREFIX + 'GET_ATTACHMENTS_GELDWAESCHE',
    GET_TODO_VIDEO_INDENT: GETTERS_PREFIX + 'GET_TODO_VIDEO_INDENT',
    IS_ANTRAG_ACTION_LOADING: GETTERS_PREFIX + 'IS_ANTRAG_ACTION_LOADING',
    IS_SOME_ANTRAG_ACTION_LOADING_ACTIVE: GETTERS_PREFIX + 'IS_SOME_ANTRAG_ACTION_LOADING_ACTIVE',
  }
}