var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseModal", {
    ref: "modal",
    attrs: { showDefaultButtons: false },
    on: { onCloseButton: _vm.close },
    scopedSlots: _vm._u([
      {
        key: "modalTitle",
        fn: function() {
          return [
            _c("InputField", {
              attrs: { label: "Bisheriges Passwort", type: "password" },
              model: {
                value: _vm.oldPassword,
                callback: function($$v) {
                  _vm.oldPassword = $$v
                },
                expression: "oldPassword"
              }
            }),
            _c("InputField", {
              attrs: {
                label: "Neues Passwort (Mindestens 6 Zeichen)",
                type: "password"
              },
              model: {
                value: _vm.newPassword,
                callback: function($$v) {
                  _vm.newPassword = $$v
                },
                expression: "newPassword"
              }
            }),
            _c("InputField", {
              attrs: { label: "Neues Passwort bestätigen", type: "password" },
              model: {
                value: _vm.newPasswordRetype,
                callback: function($$v) {
                  _vm.newPasswordRetype = $$v
                },
                expression: "newPasswordRetype"
              }
            })
          ]
        },
        proxy: true
      },
      {
        key: "footer",
        fn: function() {
          return [
            _c(
              "BaseButton",
              {
                attrs: { disabled: !_vm.isValid, isPrimary: "" },
                on: { click: _vm.changeLoginName }
              },
              [_vm._v("Passwort ändern")]
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }