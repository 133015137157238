var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-forms__container" }, [
    _c(
      "div",
      { staticClass: "input-forms__label-container" },
      [
        _vm.label
          ? _c("div", {
              staticClass: "input-forms__label-content",
              domProps: { innerHTML: _vm._s(_vm.sanitize(_vm.label)) }
            })
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "input-forms__input-container",
            class: {
              "forms__input--half-size": _vm.isComponentHalfSize,
              "autogrow-container": _vm.autoGrow
            }
          },
          [
            _vm.autoGrow
              ? _c("div", { staticClass: "autogrow-size-dummy fc-textarea" }, [
                  _vm._v(_vm._s(_vm.internalValue) + ".")
                ])
              : _vm._e(),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.internalValue,
                  expression: "internalValue"
                }
              ],
              ref: "innerTextarea",
              staticClass: "fc-textarea",
              class: _vm.getTextAreaClass,
              attrs: {
                placeholder: _vm.placeholder,
                disabled: _vm.disabled,
                rows: _vm.rows,
                maxlength: _vm.maxlength
              },
              domProps: { value: _vm.internalValue },
              on: {
                blur: _vm.onBlur,
                keyup: function($event) {
                  return _vm.$emit("keyup", $event)
                },
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.internalValue = $event.target.value
                }
              }
            })
          ]
        ),
        _vm.isValidationConfigured()
          ? [
              !_vm.suppressValidationMessage && _vm.validation
                ? _c(
                    "div",
                    { key: _vm.validation.updated },
                    _vm._l(
                      _vm.validation.getErrors(
                        this.validationPath,
                        this.validateUntouched
                      ),
                      function(error) {
                        return _c(
                          "div",
                          { key: error, staticClass: "fc-form-danger" },
                          [_vm._v(" " + _vm._s(error) + " ")]
                        )
                      }
                    ),
                    0
                  )
                : _vm._e()
            ]
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }