<template>
  <div>
    <div class="box__container" :tid="`2aea4560-8d7e-4815-93aa-1d0382a18f1c-${showAccounts}`">
      <div class="box__title">
        <AccountTypeIcon /> {{ title }}
      </div>
      <Table v-if="rows.length"
          rowId="index"
          :headers="headers"
          :rows="rows"
          :rowsPerPage="20"
          :mobileConfig="{title: 'Name', headers: ['Kontonummer', 'Kontostand']}"
          @action-TRANSACTIONS="openTransactions"
          @action-INFO="openInfo"
      />
      <GhostLoading v-else-if="isLoading" type="table" />
      <NoData v-else />
    </div>
  </div>
</template>

<script>
import ACCOUNT_TYPES from '@/store/account/types';
import CORE_TYPES from '@/store/account/types';
import { mapGetters } from 'vuex';
import AccountTypeIcon from '@/components/multiBanking/AccountTypeIcon.vue'
import GhostLoading from '@/components/core/loading/GhostLoading.vue'
import NoData from '@/components/core/NoData.vue'
import Table from "@/components/table2/Table.vue";
import {oldToNewColumns, ActionColumn, SimpleAction} from "@/components/table2/table_util.js";

export default {
  mixins: [],
  components: {
    Table,
    AccountTypeIcon,
    GhostLoading,
    NoData,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    showAccounts: {
      type: String,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    containerWidth: {
      type: Number,
      default: 0
    },
    containerHeight: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      showExpanded: false,
      errorMessage: null,
    }
  },
  computed: {
    ...mapGetters({
      accounts: ACCOUNT_TYPES.GETTERS.ACCOUNTS,
      isBrokerOrBypass: CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS,
    }),
    showErlaeuterung() {
      return this.parameters && !this.isBrokerOrBypass
    },
    rows() {
      const headersKeys = this.accounts.tableHeaders;
      const actions = [SimpleAction("TRANSACTIONS", "PhArrowsLeftRight", "Transaktionen"),
                        SimpleAction("INFO", "PhInfo", "Info")];
      let records = (this.accounts.records || [])
        .map(tuple => {
          const row = tuple.reduce((acc, curr, index) => ({ ...acc, ...({[headersKeys[index]?.key] : curr })}), {});
          row.actions = actions;
          return row;
      });

      records = records?.filter(a => 
        (a.Kontostand >= 0 && this.showAccounts === 'positive') ||
        (a.Kontostand < 0 && this.showAccounts === 'negative') ||
        (!this.showAccounts))
      return records || [];
    },
    headers() {
      if(this.accounts?.tableHeaders) {
        const headers = {
          lockedLeft: [],
            center: oldToNewColumns(this.accounts.tableHeaders.slice(1).filter(h => h.dataType !== 'Action'), {Kontostand: true}, false, {}),
            lockedRight: [
              ActionColumn("actions", "Aktionen"),
            ],
        };
        return headers;
      } else {
        return [];
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({to, from});
    next();
  },
  methods: {
    openTransactions(data) {
      if (data?.Kontonummer) {
        this.$router.push({path: `/home/berichte/transaktionen/bankkonten?params={"kontonummer":"${data.Kontonummer}"}`});
      }
    },
    openInfo(data) {
      const bgsNr = data?.Depotdaten ? JSON.parse(data?.Depotdaten?.bodyParams)?.bgsNr : '';
      if (bgsNr && data?.Kontonummer) {
        this.$router.push({path: `/home/account/depotstammdaten/${data.Kontonummer}/${bgsNr}`});
      }
    },
    handleTableAction(event) {
      var kontonummer = event.value.Kontonummer;
      let bodyParams = JSON.parse(event?.value?.actions?.bodyParams);
      let bgsNr = bodyParams?.bgsNr;
      switch(event.action.legend.key) {
        case 'TRANSACTIONS' :
          this.$router.push({path: `/home/berichte/transaktionen/bankkonten?params={"kontonummer":"${kontonummer}"}`});
          break;
        case 'INFO' :
          this.$router.push({path: `/home/account/depotstammdaten/${kontonummer}/${bgsNr}`});
          break;          
        default:
          break;
      }
    }
  }
}
</script>

<style src='@/components/steckbrief/steckbrief.scss' lang='scss' scoped></style>
<style scoped>
.space-between {
  justify-content: space-between;
}
.flex-item {
  flex: 1 1 auto;
}

.text-center {
  text-align: center;
}
</style>