var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.needsToChangePassword ? "login-container" : "" },
    [
      _vm.needsToChangePassword
        ? _c("HeaderLogin", {
            staticClass: "change-password-header",
            on: {
              backButtonPressed: function($event) {
                return _vm.logout()
              }
            }
          })
        : _vm._e(),
      _vm.isLoggedIn && !_vm.needsToChangePassword
        ? [
            _c("PageHeaderTitleNavigation", {
              attrs: { actions: _vm.headerActions(), title: _vm.pageTitle },
              on: {
                "action-ZUGANGSPASSWORT_ANDERN": function($event) {
                  return _vm.changePassword("ZUGANG")
                }
              }
            })
          ]
        : _vm._e(),
      _vm.needsToChangePassword
        ? _c(
            "div",
            {
              staticClass: "login-content",
              style: { backgroundImage: _vm.loginContentBackground },
              attrs: { "data-app-content": "" }
            },
            [
              _c(
                "div",
                { staticClass: "login-box" },
                [
                  _c("div", { staticClass: "login-logo-box" }, [
                    _c("img", { attrs: { src: _vm.logoLoginBoxBackground } })
                  ]),
                  _c("hr", { staticClass: "login-logo__bottom--spacer" }),
                  _c(
                    "div",
                    { staticClass: "rules-container" },
                    [
                      _c("PasswordPolicy", {
                        staticClass: "change-password--policy is-collapsable",
                        attrs: { isCollapsable: "" }
                      }),
                      _c(
                        "div",
                        { staticClass: "change-password--form" },
                        [
                          _c("div", { staticClass: "login-logo__box-title" }, [
                            _vm._v(" Passwort ändern ")
                          ]),
                          _c("InputField", {
                            attrs: {
                              id: "password",
                              label: "Aktuelles Passwort / Startpasswort",
                              type: "password",
                              allowPlainPassword: "",
                              validateUntouched: "",
                              autocomplete: "current-password"
                            },
                            model: {
                              value: _vm.form.password,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "password", $$v)
                              },
                              expression: "form.password"
                            }
                          }),
                          _c("InputField", {
                            attrs: {
                              id: "new-password",
                              label: "Neues Passwort",
                              type: "password",
                              allowPlainPassword: "",
                              allowPasswordRules: "",
                              validateUntouched: "",
                              autocomplete: "new-password"
                            },
                            model: {
                              value: _vm.form.newPassword,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "newPassword", $$v)
                              },
                              expression: "form.newPassword"
                            }
                          }),
                          _c("InputField", {
                            attrs: {
                              id: "new-password-again",
                              label: "Neues Passwort bestätigen",
                              type: "password",
                              allowPlainPassword: "",
                              allowPasswordRules: "",
                              autocomplete: "new-password"
                            },
                            model: {
                              value: _vm.form.newPasswordRetype,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "newPasswordRetype", $$v)
                              },
                              expression: "form.newPasswordRetype"
                            }
                          }),
                          !_vm.isCustomer
                            ? _c("InputField", {
                                attrs: {
                                  id: "communication-password",
                                  label:
                                    "Neues Kommunikations- und Versandpasswort",
                                  type: "password",
                                  allowPlainPassword: "",
                                  allowPasswordRules: "",
                                  validateUntouched: "",
                                  autocomplete: "new-password"
                                },
                                model: {
                                  value: _vm.versandForm.newPasswordVersand,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.versandForm,
                                      "newPasswordVersand",
                                      $$v
                                    )
                                  },
                                  expression: "versandForm.newPasswordVersand"
                                }
                              })
                            : _vm._e(),
                          !_vm.isCustomer
                            ? _c("InputField", {
                                attrs: {
                                  id: "communication-password-again",
                                  label:
                                    "Neues Kommunikations- und Versandpasswort bestätigen",
                                  type: "password",
                                  allowPlainPassword: "",
                                  allowPasswordRules: "",
                                  autocomplete: "new-password"
                                },
                                model: {
                                  value:
                                    _vm.versandForm.newPasswordVersandRetype,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.versandForm,
                                      "newPasswordVersandRetype",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "versandForm.newPasswordVersandRetype"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _c("PasswordPolicy", {
                        staticClass: "change-password--policy no-collapsable"
                      })
                    ],
                    1
                  ),
                  !_vm.isCustomer
                    ? _c("div", { staticClass: "text-small base-button" }, [
                        _vm._v(
                          "Das Kommunikations- und Versandpasswort dient zur Verschlüsselung aller per Mail verschickten PDFs."
                        )
                      ])
                    : _vm._e(),
                  _c(
                    "BaseButton",
                    {
                      staticClass: "base-button",
                      attrs: { isSecondary: "" },
                      on: {
                        click: function($event) {
                          return _vm.logout()
                        }
                      }
                    },
                    [_vm._v(" Zurück zum Login ")]
                  ),
                  _c(
                    "BaseButton",
                    {
                      staticClass: "base-button",
                      attrs: { isSecondary: "" },
                      on: { click: _vm.skipChangePassword }
                    },
                    [_vm._v(" Überspringen ")]
                  ),
                  _c(
                    "BaseButton",
                    {
                      staticClass: "base-button",
                      attrs: {
                        disabled:
                          _vm.isFormInvalid ||
                          _vm.zugangInvalid ||
                          _vm.isVersandFormInvalid ||
                            (_vm.versandInvalid && !_vm.isCustomer)
                      },
                      on: { click: _vm.changePassword }
                    },
                    [_vm._v(" Kennwort aktualisieren ")]
                  ),
                  _vm.errorMessage
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("span", { staticClass: "color-danger" }, [
                            _vm._v(_vm._s(_vm.errorMessage))
                          ])
                        ])
                      ])
                    : _vm._e(),
                  _vm.message
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("span", [_vm._v(_vm._s(_vm.message))])
                        ])
                      ])
                    : _vm._e()
                ],
                1
              )
            ]
          )
        : _vm._e(),
      !_vm.needsToChangePassword
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("div", { staticClass: "box__title" }, [
                _vm._v("Passwort ändern")
              ]),
              _c("InputField", {
                attrs: {
                  id: "password",
                  label: "Aktuelles Zugangspasswort",
                  type: "password",
                  allowPlainPassword: "",
                  isComponentHalfSize: "",
                  validateUntouched: "",
                  autocomplete: "current-password"
                },
                model: {
                  value: _vm.form.password,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "password", $$v)
                  },
                  expression: "form.password"
                }
              })
            ],
            1
          )
        : _vm._e(),
      !_vm.needsToChangePassword
        ? _c(
            "div",
            { staticClass: "box__container rules-container" },
            [
              _c(
                "div",
                { staticClass: "change-password--form" },
                [
                  _c("div", { staticClass: "box__title" }, [
                    _vm._v("Zugangspasswort ändern")
                  ]),
                  _c("InputField", {
                    attrs: {
                      id: "new-password",
                      label: "Neues Zugangspasswort",
                      type: "password",
                      allowPlainPassword: "",
                      allowPasswordRules: "",
                      autocomplete: "new-password"
                    },
                    model: {
                      value: _vm.form.newPassword,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "newPassword", $$v)
                      },
                      expression: "form.newPassword"
                    }
                  }),
                  _c("InputField", {
                    attrs: {
                      id: "new-password-again",
                      label: "Neues Zugangspasswort bestätigen",
                      type: "password",
                      allowPlainPassword: "",
                      allowPasswordRules: "",
                      autocomplete: "new-password"
                    },
                    model: {
                      value: _vm.form.newPasswordRetype,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "newPasswordRetype", $$v)
                      },
                      expression: "form.newPasswordRetype"
                    }
                  }),
                  !_vm.isMobileNativeContext
                    ? _c(
                        "BaseButton",
                        {
                          attrs: {
                            disabled: _vm.isFormInvalid || _vm.zugangInvalid
                          },
                          on: {
                            click: function($event) {
                              return _vm.changePassword("ZUGANG")
                            }
                          }
                        },
                        [_vm._v(" Zugangspasswort ändern ")]
                      )
                    : _vm._e(),
                  _vm.errorMessage
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("span", { staticClass: "color-danger" }, [
                            _vm._v(_vm._s(_vm.errorMessage))
                          ])
                        ])
                      ])
                    : _vm._e(),
                  _vm.message
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("span", [_vm._v(_vm._s(_vm.message))])
                        ])
                      ])
                    : _vm._e()
                ],
                1
              ),
              _c("PasswordPolicy", { staticClass: "change-password--policy" })
            ],
            1
          )
        : _vm._e(),
      !_vm.needsToChangePassword && !_vm.isCustomer
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("div", { staticClass: "box__title" }, [
                _vm._v("Kommunikations- und Versandpasswort ändern")
              ]),
              _c("div", { staticClass: "text-small" }, [
                _vm._v(
                  "Das Kommunikations- und Versandpasswort dient zur Verschlüsselung aller per Mail verschickten PDFs."
                )
              ]),
              _c("InputField", {
                attrs: {
                  id: "communication-password",
                  label: "Neues Kommunikations- und Versandpasswort",
                  type: "password",
                  allowPlainPassword: "",
                  allowPasswordRules: "",
                  isComponentHalfSize: "",
                  autocomplete: "new-password"
                },
                model: {
                  value: _vm.versandForm.newPasswordVersand,
                  callback: function($$v) {
                    _vm.$set(_vm.versandForm, "newPasswordVersand", $$v)
                  },
                  expression: "versandForm.newPasswordVersand"
                }
              }),
              _c("InputField", {
                attrs: {
                  id: "communication-password-again",
                  label: "Neues Kommunikations- und Versandpasswort bestätigen",
                  type: "password",
                  allowPlainPassword: "",
                  allowPasswordRules: "",
                  isComponentHalfSize: "",
                  autocomplete: "new-password"
                },
                model: {
                  value: _vm.versandForm.newPasswordVersandRetype,
                  callback: function($$v) {
                    _vm.$set(_vm.versandForm, "newPasswordVersandRetype", $$v)
                  },
                  expression: "versandForm.newPasswordVersandRetype"
                }
              }),
              _c(
                "BaseButton",
                {
                  attrs: {
                    disabled: _vm.isVersandFormInvalid || _vm.versandInvalid
                  },
                  on: {
                    click: function($event) {
                      return _vm.changePassword("VERSAND")
                    }
                  }
                },
                [_vm._v(" Kommunikations- und Versandpasswort ändern ")]
              ),
              _vm.errorMessageVersand
                ? _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c("span", { staticClass: "color-danger" }, [
                        _vm._v(_vm._s(_vm.errorMessageVersand))
                      ])
                    ])
                  ])
                : _vm._e(),
              _vm.messageVersand
                ? _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c("span", [_vm._v(_vm._s(_vm.messageVersand))])
                    ])
                  ])
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }