import { PrimaryMenu, GroupMenu, MenuItem, TabMenu, } from './../menu-utils';

import { PhHouse, } from 'phosphor-vue';

import UndrawDashboard from '@/components/icons/undraw/UndrawDashboard.vue';
import UndrawWallet from '@/components/icons/undraw/UndrawWallet.vue';
import UndrawInvesting from '@/components/icons/undraw/UndrawInvesting.vue';
import UndrawDataReport from '@/components/icons/undraw/UndrawDataReport.vue';
import UndrawCharts from '@/components/icons/undraw/UndrawCharts.vue';
import UndrawGrowing from '@/components/icons/undraw/UndrawGrowing.vue';
import UndrawEvents from '@/components/icons/undraw/UndrawEvents.vue';
import UndrawContainerShip from '@/components/icons/undraw/UndrawContainerShip.vue';
import UndrawSecureServer from '@/components/icons/undraw/UndrawSecureServer.vue';
import UndrawPreferences from '@/components/icons/undraw/UndrawPreferences.vue';
import UndrawReviewedDocs from '@/components/icons/undraw/UndrawReviewedDocs.vue';
import UndrawAllTheData from '@/components/icons/undraw/UndrawAllTheData.vue';
import UndrawInvestData from '@/components/icons/undraw/UndrawInvestData.vue';
import UndrawPersonalFinance from '@/components/icons/undraw/UndrawPersonalFinance.vue';
import UndrawInvest from '@/components/icons/undraw/UndrawInvest.vue';
import UndrawOnlineTransactions from '@/components/icons/undraw/UndrawOnlineTransactions.vue';
import UndrawSweetHome from '@/components/icons/undraw/UndrawSweetHome.vue';
import UndrawContentTeam from '@/components/icons/undraw/UndrawContentTeam.vue';
import UndrawPeopleSearch from '@/components/icons/undraw/UndrawPeopleSearch.vue';
import OpenSignatures from '@/components/icons/undraw/OpenSignatures.vue';
import UndrawCheckboxes from '@/components/icons/undraw/UndrawCheckboxes.vue';
import UndrawTransferFiles from '@/components/icons/undraw/UndrawTransferFiles.vue';
import UndrawEducator from '@/components/icons/undraw/UndrawEducator.vue';
import HomeButton from '@/components/icons/HomeButton.vue'
import UndrawSiteStats from '@/components/icons/undraw/UndrawSiteStats.vue';

export default PrimaryMenu('/home', 'Übersicht', PhHouse, [
  MenuItem('/home/steckbrief-view', 'Kunden-Überblick').withUnmodifiablePermission(),
  MenuItem('/home/customer-overview', 'Dashboard', UndrawDashboard),
  MenuItem('/home/freistellungsauftrag', 'Freistellungs&shy;aufträge', UndrawWallet),

  MenuItem('/home/vermogensubersicht', 'Vermögen', UndrawInvesting, [
    TabMenu('/home/vermogensubersicht/depot', 'Depotpositionen'),
    TabMenu('/home/vermogensubersicht/beteiligungen', 'Beteiligungen', UndrawContainerShip),
    TabMenu('/home/vermogensubersicht/versicherungsvermoegen', 'Versicherungs&shy;vermögen', UndrawSecureServer),
    TabMenu('/home/vermogensubersicht/wertpapiere-vorsorge', 'Wertpapiere Vorsorge'),
    TabMenu('/home/vermogensubersicht/weitere-vermoegen', 'Weiteres Vermögen'),
  ]),

  GroupMenu('/home/berichte', 'Berichte', UndrawDataReport, [
    MenuItem('/home/berichte/diagramme', 'Diagramme', UndrawCharts),
    MenuItem('/home/berichte/performance', 'Wertentwicklung', UndrawGrowing),
    MenuItem('/home/berichte/year-performance', 'Jahresperformance', UndrawInvestData),
    MenuItem('/home/berichte/profits', 'realisierte Gewinne', UndrawPersonalFinance),
    MenuItem('/home/berichte/spar_entnahmeplane', '<nobr>Spar-&</nobr> Entnahmepläne', UndrawInvest),
    MenuItem('/home/berichte/quartalsberichte', 'Quartalsberichte', UndrawEvents),
    MenuItem('/home/berichte/transaktionen', 'Transaktionen', UndrawOnlineTransactions),
    MenuItem('/home/berichte/ex-post-berechnung', 'ExPost Berechnung'),
    MenuItem('/home/courtagestatistik', 'Courtagestatistik', UndrawSiteStats),
  ]),

  MenuItem('/home/verbindlichkeiten', 'Verbindlichkeiten', null, [
    TabMenu('/home/verbindlichkeiten/credit', 'Kredite'),
    TabMenu('/home/verbindlichkeiten/weitere-verbindlichkeiten', 'Weitere Verbindlichkeiten'),
  ]),

  MenuItem('/home/versicherungen', 'Versicherungen', UndrawSecureServer),

  MenuItem('/home/unterdepots', 'Virtuelle Unterdepots', UndrawPreferences),
  MenuItem('/home/depotpositionenedit', 'Depotpositionen bearbeiten', UndrawReviewedDocs),
  MenuItem('/home/kennzahlen', 'Kennzahlen', UndrawAllTheData),

  MenuItem('/home/account', 'Konten', UndrawWallet),

  MenuItem('/home/multibanking-account', 'Multibanking', UndrawWallet, [
    TabMenu('/home/multibanking-account/overview', 'Übersicht'),
    TabMenu('/home/multibanking-account/kategorien', 'Kategorien'),
    TabMenu('/home/multibanking-account/balance-per-period', 'Kontoverlauf'),
    TabMenu('/home/multibanking-account/einnahmen-und-ausgaben', 'Einnahmen & Ausgaben'),
    TabMenu('/home/multibanking-account/monatliches-sparpotential', 'monatliches Sparpotential'),
  ]),

  MenuItem('/home/immobilien', 'Immobilien', UndrawSweetHome),

  MenuItem('/home/broker-overview', 'Vermittler Übersicht'),
  MenuItem('/intern/dashboard-view', 'Vermittler Dashboard', UndrawDashboard),
  MenuItem('/home/versicherungsfilter', 'Versicherungs&shy;suche', UndrawSecureServer),
  MenuItem('/intern/homepageselection', 'Homepage', UndrawContentTeam),
  MenuItem('/customer/customer-search', 'Kundensuche', UndrawPeopleSearch),
  MenuItem('/communication/todo-list', 'ToDo-Liste', OpenSignatures),
  MenuItem('/home/antragsliste-versicherungen', 'Antragsliste Versicherungen', UndrawCheckboxes),
  MenuItem('/home/maklerauftraege', 'Vermittlerwechsel Versicherungen', UndrawTransferFiles),

  MenuItem('/home/limit', 'Limits', null, [
    TabMenu('/home/limit/depotlimit', 'Depotlimit'),
    TabMenu('/home/limit/depotpositionlimits', 'Depotpositionslimits'),
    TabMenu('/home/limit/automatic-depotpositionlimits', 'Automatische Depotpositionslimits'),
    TabMenu('/home/limit/risikoklassenlimit', 'Risikoklassenlimit'),
    TabMenu('/home/limit/wertpapierkategorie-limits', 'Wertpapierkategorie Limits'),
  ]),
  MenuItem('/home/fk-events', 'Events', UndrawEducator),
  MenuItem('/home/gesellschaftsdaten', 'Gesellschaftsdaten'),
  MenuItem('/home/home', 'Home', HomeButton),

  MenuItem('/home/intern-overview', 'Intern Übersicht'),
  
]);
