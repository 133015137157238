<template>
    <BaseModal ref="modal"
        @onCloseButton="close"
        :showDefaultButtons="false">
        <template v-slot:modalTitle>
            <InputField label="Neue Telefonnummer für Sicherheitscodes" v-model="phoneNumber" @change="clearErrorStr()" />
            <!-- <InputField label="Passwort" v-model="password" type="password"/> -->
            <BaseButton @click="requestCodes" :disabled="!validatePhoneNumber(phoneNumber) || tokenButtonsDisabled" isSecondary>Sicherheitscode {{codesRequested ? 'erneut ' : ''}}senden</BaseButton>
            <InputField class="mt-4" label="SMS Code" v-model="smsCode" v-if="codesRequested"/>
            <!-- <InputField label="Sicherheitscode (per E-Mail versendet)" v-model="emailCode" v-if="codesRequested"/> -->
            <div v-if="errorStr" class="color-danger mt-2">{{errorStr}}</div>
        </template>
        <template v-slot:footer>
            <BaseButton @click="changePhone" :disabled="!validatePhoneNumber(phoneNumber) || smsCode.length <= 0" isPrimary>Telefonnummer ändern</BaseButton>
        </template>
    </BaseModal>
</template>
<script>
import SSO_TYPES from '@/store/sso/types';
import { mapGetters } from 'vuex';

import BaseModal from '@/components/core/BaseModal.vue';
import InputField from '@/components/core/forms/InputField.vue';
import BaseButton from '@/components/core/BaseButton.vue'
import validator from '@/mixins/validator/index.js';
import { formatPhone } from "@/mixins/validator/rules";

export default {
    mixins: [validator],
    validators: {
        phoneNumber: formatPhone('Bitte geben Sie eine gültige Telefonnummer ein.'),
    },
    components: {
        BaseModal,
        InputField,
        BaseButton,
    },
    emits: ['close'],
    computed: {
        ...mapGetters({
            oldPhoneNumber: SSO_TYPES.GETTERS.PHONE_NUMBER,
        }),
    },
    data() {
        return {
            phoneNumber: this.oldPhoneNumber || "",
            // password: "",
            smsCode: "",
            // emailCode: "",
            codesRequested: false,
            tokenButtonsDisabled: false,
            errorStr: '',
        };
    },
    mounted() {
        this.$refs.modal.open();
    },
    methods: {
        validatePhoneNumber(phoneNumber) {
            // var re = /^[\+]?[(]?[0-9]{1,4}[)]?[-\s\.]?[0-9]{1,6}[-\s\.]?[0-9]{5,12}$/;
            return this.oldPhoneNumber !== this.phoneNumber && this.validation?.phoneNumber?.valid; //re.test(phoneNumber);
        },
        requestCodes() {
            this.errorStr = null;
            // this.emailCode = "";
            this.smsCode = "";
            this.codesRequested = true;
            this.tokenButtonsDisabled = true;
            this.$store.dispatch(SSO_TYPES.ACTIONS.REQUEST_SMS_CODE, this.phoneNumber).then(response => {
                if (response?.data?.errorStr) {
                    if (response.data.phone) {
                         this.$pushErrors('phoneNumber', response.data.errorStr);
                    } else {
                         this.errorStr = response.data.errorStr;
                    }
                } 
            // this.$store.dispatch(SSO_TYPES.ACTIONS.REQUEST_EMAIL_CODE, null);
            }).catch(error => this.errorStr = "Die Telefonnummer konnte nicht geändert werden. Bitte überprüfen Sie ihre Eingaben.");
            
            setTimeout(() => {
                this.tokenButtonsDisabled = false;
            }, 2000);
        },
        changePhone() {
            this.$store.dispatch(SSO_TYPES.ACTIONS.CHANGE_PHONE_NUMBER, {
                newValue: this.phoneNumber,
                smsCode: this.smsCode,
            }).then(response => {
                 if (response?.data?.errorStr) {
                    if (response.data.sms) {
                        this.$pushErrors('smsCode', response.data.errorStr);
                    } else {
                         this.errorStr = response.data.errorStr;
                    }
                } else {
                    this.$store.dispatch(SSO_TYPES.ACTIONS.GET_SSO_ACCOUNT_INFO);
                    this.close();
                }
            }).catch(error => {
                this.errorStr = "Die Telefonnummer konnte nicht geändert werden. Bitte überprüfen Sie ihre Eingaben.";
            });
        },
        close() {
            this.errorStr = null;
            this.$refs.modal.close();
            this.$emit('close');
        },
         clearErrorStr() {
            if (this.errorStr) {
                this.errorStr = null;
            }
        }
    },
}
</script>
<style scoped>

</style>