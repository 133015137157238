<template>
  <div v-if="customerDataEdited">
    <FlexibleList
      v-if="rows && rows.length"
      :rows="rows"
      showThirdRow
      :linkInFirstRow="isEditable"
      @onRowClick="onRowClick"
      @onNavigate="navigateToCard"
      :pageSize="isCustomerOnly ? 0 : 5"
    >
      <template #title="row">
        {{ (row.firstName ? (row.firstName + ' ') : '') + row.lastName }}
      </template>

      <template #value="row">
        {{ row.personalDataBirth || "-" }}
      </template>

      <template #thirdRow="row">
        {{ row.relation }}
      </template>
    </FlexibleList>
    <NoData v-else noIcon />
  </div>
</template>


<script>
import { TextColumn } from "@/components/table2/table_util.js";
import { mapGetters } from "vuex";
import CUSTOMERDATA_TYPES from "@/store/customerData/types";
import CORE_TYPES from "@/store/core/types";
import persoenlicheDatenMixin from "../persoenliche-daten-mixin";
import stammdatenMixin from "@/components/persoenliche-daten/stammdaten/stammdaten-mixin";
import FlexibleList from "@/components/flexibleList/FlexibleList.vue";
import NoData from '@/components/core/NoData.vue';

export default {
  components: {
    FlexibleList,
    NoData,
  },
  mixins: [persoenlicheDatenMixin, stammdatenMixin],
  data: function () {
    return {
      title: "Zusätzliche Personen",
      personalDataBirth: {
        nameAtBirth: "",
        nationality: "",
        countryOfBirth: "",
        placeOfBirth: "",
        dayOfBirth: null,
      },
      modalTitle: null,
    };
  },
  computed: {
    ...mapGetters({
      customerDataEdited: CUSTOMERDATA_TYPES.GETTERS.GET_CUSTOMER_DATA_EDITED,
      isFA: CORE_TYPES.GETTERS.IS_FA,
      isFK: CORE_TYPES.GETTERS.IS_FK,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      personId: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_ID,
      customerDataConfig: CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA_CONFIG,
      customerData: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_DATA,
      loginData: CORE_TYPES.GETTERS.GET_LOGIN_DATA,
      persons: CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA_ADDITIONAL_PERSONS,
      isCustomerOnly: CORE_TYPES.GETTERS.IS_CUSTOMER_ONLY,
    }),
    isFA() {
      return !!this.loginData?.rights?.isFA;
    },
    vollmachtLabel() {
      return this.isFA ? "ZB" : "VM";
    },
    headers() {
      const result = {
        center: [],
      };

      result.center.push(TextColumn("lastName", "Name"));
      result.center.push(TextColumn("firstName", "Vorname"));
      result.center.push(TextColumn("relation", "Beziehung"));
      result.center.push(TextColumn("isLegalAgent", "Ges. Vertreter"));
      result.center.push(TextColumn("isExtraDepotOwner", "DI"));
      result.center.push(TextColumn("isMandatory", this.vollmachtLabel));
      result.center.push(TextColumn("zip", "Plz"));
      result.center.push(TextColumn("city", "Ort"));
      result.center.push(TextColumn("personalDataBirth", "Geburtsdatum"));

      return result;
    },
    rows() {
      return (this.persons || []).map((customer) => ({
        lastName: customer.personalDataAddress.lastName,
        firstName: customer.personalDataAddress.firstName,
        relationship: customer.relationship,
        relation: customer?.relationship?.relation
          ? customer.relationship.relation.display
          : "",
        isLegalAgent: customer?.relationship?.isLegalAgent ? "Ja" : "Nein",
        isExtraDepotOwner: customer?.relationship?.isExtraDepotOwner
          ? "Ja"
          : "Nein",
        isMandatory: customer?.relationship?.isMandatory ? "Ja" : "Nein",
        zip: customer.personalDataAddress.zip,
        city: customer.personalDataAddress.city,
        personalDataBirth: customer.personalDataBirth
          ? customer.personalDataBirth.dayOfBirth
          : "",
        personId: customer.personId,
      }));
    },
    tableId() {
      const uniqueUUID = "96aca340-be13-4d9c-81e4-e804c4fe37a0";
      return uniqueUUID;
    },
  },
  methods: {
    navigateToCard() {
      this.$router.push(`/persoenlichedaten/customer-data/steps/zusaetzliche-personen`);
    },    
    async onRowClick(whatRow) {
      this.$router.push({
        path: `/persoenlichedaten/person-data-overview/${whatRow?.personId}`,
        backAction: true,
      });
    },
  },
  mounted() {},
  validators: {},
};
</script>

<style scoped>
.align-end {
  flex: 1 1 auto;
  align-self: flex-end;
  padding: 0px 0px 16px 5px;
  color: var(--color-text);
}
.form-control {
  display: flex;
  align-content: stretch;
  align-items: center;
}
</style>