<template>
  <div>
    <MailComposer
      :custom="true"
      :customFunctions="customFunctions"
      :isUserSelect="!$route.meta.insuranceCancel"
      :allwaysManual="false"
      :showExtendedActionButtons="!$route.meta.insuranceCancel"
      :additionalSendMailParams="additionalSendMailParams"
      :isGetPlaceholdersOnInit="isGetPlaceholdersOnInit"
      :isContactRequired="!$route.meta.insurance && !$route.meta.insuranceCancel"
      :reciverDisable="$route.meta.insuranceCancel&&  $route.meta.modus=='MANY_MAIL'"
      @vollmacht="setVollmacht"
      @callbacks="setCallbacks"
    >
      <template v-slot:maklervollmacht>
        <BaseButton isSecondary @click="handleAddDocuments()"  :tid="_generateTidFromString('Vollmacht')" v-if="maklervollmacht && maklervollmacht.hasVollmacht" :disabled="!isByPass || maklervollmacht && maklervollmacht.disabled">
          Maklervollmacht anhängen
        </BaseButton>
      </template>
    </MailComposer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MailComposer from '../MailComposer.vue';
import MAILCOMPOSER_TYPES from '@/store/mailcomposer/types';
import BaseButton from '@/components/core/BaseButton.vue';
import {
  MAX_ITEMS_VISIBLE,
  KUNDE_TYPE,
  BROKER_TYPE,
  VERSICHERUNG_TAG_DESCRIPTION,
  GESELLSCHAFT_TAG_DESCRIPTION,
  KUNDE_TAG_DESCRIPTION,
  VERSICHERUNG_KUNDE_TAG_DESCRIPTION,
  VERSICHERUNG_GESELLSCHAFT_TAG_DESCRIPTION,
  VORLAGEN_TYPE_MAIL,
  VORLAGEN_TYPE_BRIEF, validateEmail, getFileName, getFileType
} from '../MailComposer.vue';
import validator from "@/mixins/validator";
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
// import MailMixin from "@/components/mailcomposer/mail-composer-mixin.js";
import CORE_TYPES from "@/store/core/types";

export default {
  mixins: [InteractiveHelpCommonsMixin, validator],
  name: 'InsuranceMailComposer',
  components: {
    MailComposer,
    BaseButton,
  },

  props: {},

  data() {
    return {
      callbacks: {},
      vollmacht: null,
      customFunctions: {
        templatesOptions: (templates) => {
          if (this.isAnInsuranceEmail) {
            let templateList = templates

            let neededTags = []
            if (this.linkedObjectSelected?.toCompany) {
              neededTags = [
                VERSICHERUNG_TAG_DESCRIPTION,
                GESELLSCHAFT_TAG_DESCRIPTION,
                VERSICHERUNG_GESELLSCHAFT_TAG_DESCRIPTION,
              ];
            } else {
              neededTags = [
                VERSICHERUNG_TAG_DESCRIPTION,
                KUNDE_TAG_DESCRIPTION,
                VERSICHERUNG_KUNDE_TAG_DESCRIPTION,
              ];
            }
            templateList = templateList.filter(template => template?.tags?.length && 
                template.tags.filter(tag => neededTags.includes(tag.description))?.length)
            
            return templateList
          }
          return []
        },
        initPlaceholders: (linkedObject) => {
          this.$store.dispatch(MAILCOMPOSER_TYPES.ACTIONS.GET_PLACEHOLDERS, this.isAnInsuranceEmail && linkedObject && { linkedObject: linkedObject } || {});
        },
        replaceParticipants: (participants) => {
          if (this.linkedObjectSelected?.participants && (this.$route.meta.insurance || this.$route.meta.insuranceBrief) && this.linkedObjectSelected && this.linkedObjectSelected.data && this.linkedObjectSelected.data.length) {
            return this.linkedObjectSelected?.participants || [];
          }
          return participants
        },
        getRoute: () => {
          return this.getRoute()
        },
        pageTitle: () => {
          if (this.$route.meta.insuranceRund) {
            return 'Rundmail an Gesellschaften';
          }
          if (this.$route.meta.insuranceCancel) {
            return 'Kündigung Versicherungen';
          }
          if (this.$route.meta.insurance) {
            return 'Versicherungen E-Mail';
          }
          switch (this.$route.meta.type) {
            case 'ONE_MAIL':
              return 'E-Mail an Kunde';
            case 'MANY_MAIL':
              return 'Rund E-Mail';
            case 'APP':
              return 'Kunden APP zusenden';
            case 'BRIEF':
              return 'Brief an Kunde';
            case 'BRIEF_MAIL':
              return 'Brief an Kunde';
            case 'FAX':
              return 'Fax an Kunde';
            default:
              return 'E-Mail schreiben';
          }
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      savedConfiguration: MAILCOMPOSER_TYPES.GETTERS.GET_MAILCOMPOSER_CONFIG,
      attachments: MAILCOMPOSER_TYPES.GETTERS.SET_ATTACHMENTS,
      linkedObjectSelected: MAILCOMPOSER_TYPES.GETTERS.GET_MAIL_LINKED_OBJECT,
      isByPass: CORE_TYPES.GETTERS.IS_BYPASS,
      cancelInsurance: MAILCOMPOSER_TYPES.GETTERS.INIT_CANCEL_INSURANCE,
    }),
    
    isAnInsuranceEmail() {
      return !!(this.$route.meta.insuranceBrief || this.$route.meta.insurance || this.$route.meta.insuranceCancel || this.$route.meta.insuranceRund);
    },
    
    additionalSendMailParams() {
     let bb = {
        ...(this.$route.meta.insuranceCancel ? { kuenMail: true } : {}),
        insurance: this.isAnInsuranceEmail,
        ...((this.isAnInsuranceEmail) && this.linkedObjectSelected?.data?.length && this.linkedObjectSelected.data[0] ? { linkedObject: 'VERTRAG' + this.linkedObjectSelected.data[0] } : {}),
      }

      if(this.$route.meta.insuranceCancel && this.$route.meta.modus=='MANY_MAIL'){
        bb.linkedObject=null;
      }
      return bb;
    },

    maklervollmacht() {
      if (this.vollmacht) {
        return this.vollmacht
      }
      return {
        hasVollmacht: this.linkedObjectSelected?.hasMaklerVollmacht,
        lastFile: this.linkedObjectSelected?.maklerVollmacht,
        disabled: false
      }
    },
    
    isGetPlaceholdersOnInit() {
      return !this.$route.meta.insurance;
    },
  },

  watch: {
    cancelInsurance(value) {
      if (!value) {
        return
      }
      if (!this.$route.meta.insuranceRund) {
        this.callbacks.setSubject(value.subject);
        this.callbacks.setHtmlText(value.html);
      }


      if (!this.savedConfiguration.receiver || !this.savedConfiguration.receiver.length) {
        let receiver = [];
        value.participants.map(p => {
          receiver.push({
            value: p.primaryObjectSerialized,
            label: `${p.name}`,
            name: p.name,
            email: p.email,
            primaryObjectSerialized: p.primaryObjectSerialized,
            tempFileId: p.tempFileId,
            data: p
          });
        });
        this.callbacks.setReceiver(receiver)
      }
    },
  },
  async mounted() {
    if (this.$route.meta.type !== 'FAX' && this.$route.meta.type !== 'APP'
      && !this.$route.meta.insurance && !this.$route.meta.insuranceCancel && !this.$route.meta.insuranceBrief 
      && !this.savedConfiguration?.receiver && this.$route.meta.mailMode !== 'AUFTRAGSDOKUMENTE') {
      await this.$store.dispatch(MAILCOMPOSER_TYPES.ACTIONS.GET_INIT, {});
    }
    if ((this.$route.meta.insurance || this.$route.meta.insuranceBrief)
     && !this.$route.meta.insuranceCancel && this.linkedObjectSelected && this.linkedObjectSelected.data && this.linkedObjectSelected.data.length) {
      this.$store.dispatch(MAILCOMPOSER_TYPES.ACTIONS.GET_INIT, {
        insurance: this.linkedObjectSelected.data[0],
        toCompany: this.linkedObjectSelected.toCompany
      });
    }
    
    const linkedObjectSelected = this.linkedObjectSelected?.data?.filter(v => !!v) || [];
    if(this.$route.meta.insuranceCancel) {
      const obj = this.$store.getters[MAILCOMPOSER_TYPES.GETTERS.INIT_CANCEL_INSURANCE];
      if(obj == null || obj.cancelList != linkedObjectSelected){
        this.$store.dispatch(MAILCOMPOSER_TYPES.ACTIONS.INIT_CANCEL_INSURANCE, linkedObjectSelected);
      }
    }
    if (this.$route.meta.insuranceRund && this.linkedObjectSelected?.data?.length) {
      if(obj == null || obj.cancelList != linkedObjectSelected){
        this.$store.dispatch(MAILCOMPOSER_TYPES.ACTIONS.INIT_CANCEL_INSURANCE, linkedObjectSelected);
      }
    }
  },
  beforeDestroy(){
  //  this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.INIT_CANCEL_INSURANCE,null);
    
   // this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, {action: 'clear',type: this.$route.name || this.$route.meta.type});
  },
  

  methods: {
    setCallbacks(value) {
      this.callbacks = value;
    },
    handleAddDocuments() {
      this.addVollmachtAttachment()
    },
    handleAddUsers(userType) {
      this.userType = userType;
      this.saveMailComposer();
      this.navigateTo('/communication/mailcomposer-customer-search/' + this.getRoute())
    },
    async addVollmachtAttachment() {
      this.$store.dispatch(MAILCOMPOSER_TYPES.ACTIONS.ADD_VOLLMACHT, {id: this.maklervollmacht?.lastFile?.id, name: this.maklervollmacht?.lastFile?.fileName});
    },
    setVollmacht(value) {
      this.vollmacht = value;
    },
    getRoute() {
      if (this.$route.meta.insuranceBrief && this.$route.meta.type == 'BRIEF') {
        return 'mailcomposer-brief-insurance';
      }
      if (this.$route.meta.insuranceBrief && this.$route.meta.type == 'BRIEF_MAIL') {
        return 'mailcomposer-brief-insurance-email'
      }
      if (this.$route.meta.insurance) {
        return 'mailcomposer-insurance';
      }
      if (this.$route.meta.insuranceCancel  && this.$route.meta.modus=='MANY_MAIL' ) {
        return 'mailcomposer-insurance-cancel';
      }
      if (this.$route.meta.insuranceCancel && this.$route.meta.modus=='ONE_MAIL') {
        return 'mailcomposer-insurance-cancel_one';
      }
      switch (this.$route.meta.type) {
        case 'ONE_MAIL':
          return 'mailcomposer';
        case 'MANY_MAIL':
          return 'mailcomposer-bulk';
        case 'APP':
          return 'mailcomposer-app';
        case 'BRIEF':
          if (this.$route.meta.serienbrief) {
            return 'mailcomposer-serienbrief';
          }
          return 'mailcomposer-brief';
        case 'BRIEF_MAIL':
          return 'mailcomposer-brief-email';
        case 'FAX':
          return 'mailcomposer-fax';
        default:
          return 'mailcomposer';
      }
    }
  },
  validators: {},
  beforeRouteLeave(to, from, next) {
    /*this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, {
      type: this.$route.name || this.$route.meta.type,
      action: 'clear',
    });*/
    this.callbacks.handleBeforeRouteLeave(to, from, next)
  }
};
</script>

<style scoped>
</style>
