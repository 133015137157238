<template>
  <div>
    <div v-if="getPositions && getPositions.length">
      <div class="small" v-if="config && config.comment">{{config.comment}}</div>
      <FormLabel v-if="isEditDisabled" class="mb-2"
        label='Diese Daten sind bei der Löschung nicht notwendig und werden daher nicht angedruckt.'
      />
     <Table
        v-if="tableData.records.length"
        :tableData="tableData"
        :cardViewEnabled="false"
        :filterEnabled="false"
        :exportEnabled="false"
        :paginationEnabled="true"
        :actions="columnActions"
        :rowsPerPage="tableData.records.length"
        :pageSize="tableData.records.length"
        @execute-action="handleTableAction($event)"
      >
        <template v-slot:betrag="props">
          <InputField :type="betragType" :value="props.data.row.betrag" :disabled="isEditDisabled" :placeholder="config && config.betrag && config.betrag.placeholder" :id="'betrag' + props.data.row.index" 
            @input="onChangeInputField($event, props.data.row, getBetragId)"/>
        </template>
        <template v-slot:aa="props">
          <InputField type="percent" :value="props.data.row.aa" :id="'aa' + props.data.row.index" 
            :disabled="disabled"
            :placeholder="props.data.row.defaultAA"
            @input="onChangeInputField($event, props.data.row, 'aa')"/>
        </template>

         <template v-slot:modus="props">
          <ComboBox  :value="props.data.row.modus" :id="'modus' + props.data.row.index" 
            :values="values('modus', props)"
            :firstEmpty="true"
            :disabled="isDisabled('modus', props)"
            @input="onChangeInputField($event, props.data.row, 'modus')"/>
        </template>
        <template v-slot:lagerstelle="props">
          <ComboBox  :value="props.data.row.lagerstelle" :id="'lagerstelle' + props.data.row.index" 
            :values="values('lagerstelle', props)"
            :firstEmpty="true"
            :disabled="isDisabled('lagerstelle', props)"
            @input="onChangeInputField($event, props.data.row, 'lagerstelle')"/>
        </template>
        <template v-slot:depotnummer="props">
          <InputField :value="props.data.row.depotnummer" :id="'depotnummer' + props.data.row.index" 
            :disabled="isDisabled('depotnummer', props)"
            @input="onChangeInputField($event, props.data.row, 'depotnummer')"/>
        </template>
        <template v-slot:frequenz="props">
          <ComboBox  :value="props.data.row.frequenz" :id="'frequenz' + props.data.row.index" 
            :values="values('frequenz', props)"
            :disabled="isDisabled('frequenz', props)"
            :firstEmpty="true"
            @input="onChangeInputField($event, props.data.row, 'frequenz')"/>
        </template>
         <template v-slot:dynamik="props">
          <InputField type="percent" :value="props.data.row.dynamik" :id="'dynamik' + props.data.row.index" 
            :disabled="isDisabled('dynamik', props)"
            @input="onChangeInputField($event, props.data.row, 'dynamik')"/>
        </template>
        <template v-slot:zielsumme="props">
          <InputField type="currency" :precision="2" :value="props.data.row.zielsumme" :id="'zielsumme' + props.data.row.index" 
            :disabled="isDisabled('zielsumme', props)"
            @input="onChangeInputField($event, props.data.row, 'zielsumme')"/>
        </template>
        <template v-slot:laufzeit="props">
          <InputField type="number" :value="props.data.row.laufzeit" :id="'laufzeit' + props.data.row.index" 
            :disabled="isDisabled('laufzeit', props)"
            @input="onChangeInputField($event, props.data.row, 'laufzeit')"/>
        </template>
        <template v-slot:bemerkung="props">
          <InputField  :value="props.data.row.bemerkung" :id="'bemerkung' + props.data.row.index" 
            @input="onChangeInputField($event, props.data.row, 'bemerkung')"/>
        </template>
         <template v-slot:provfrei="props">
          <input-toggle-switch  :value="props.data.row.provfrei" :id="'provfrei' + props.data.row.index" 
            :disabled="isDisabled('provfrei', props)"
            @input="onChangeInputField($event, props.data.row, 'provfrei')"/>
        </template>
         <template v-slot:depotuebertrag="props">
          <input-toggle-switch  :value="props.data.row.depotuebertrag" :id="'depotuebertrag' + props.data.row.index" 
            :disabled="isDisabled('depotuebertrag', props)"
            @input="onChangeInputField($event, props.data.row, 'depotuebertrag')"/>
        </template>
        <template v-slot:keineIndexierung="props">
          <InputToggleSwitch  :value="props.data.row.keineIndexierung" :id="'keineIndexierung' + props.data.row.index" 
            :disabled="isDisabled('keineIndexierung', props)"
            @input="onChangeInputField(!$event, props.data.row, 'indexierung')"/>
        </template>
        
      </Table>
      
    </div>

    <div v-if="config.mitSummenbildung && this.positions && this.positions.length">
      <Summenbildung :antragId="antragId" :summe="summe" />
    </div>

      <DeletePositionModal ref="deleteModal" 
        :position="positionToDelete"
        @delete="doDeletePosition()"
      ></DeletePositionModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FONDSFINDER_TYPES from '@/store/fondsfinder/types';
import InputField from "@/components/core/forms/InputField.vue";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import WertpapierAuswahlPositionsButtons from '@/components/core/forms/antraege/wertpapierAuswahl/WertpapierAuswahlPositionsButtons.vue'
import DeletePositionModal from '@/components/core/forms/antraege/wertpapierAuswahl/DeletePositionModal.vue'
import Table from '@/components/table/Table.vue';
import validator from "@/mixins/validator";
import antragMixin from '@/mixins/antrag/antrag-mixin.js';
import { componentValidatorToRule, isHiddenDisabled } from '@/components/antrag/antrag-utils.js';
import ANTRAG_TYPES from '@/store/antrag/types';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import FormLabel from '@/components/core/forms/FormLabel.vue';
import Summenbildung from '@/components/antrag/Summenbildung.vue';

export default {
  mixins: [validator, antragMixin],
  validators: {},
  components: {
    InputField,
    ComboBox,
    WertpapierAuswahlPositionsButtons,
    DeletePositionModal,
    Table,
    InputToggleSwitch,
    FormLabel,
    Summenbildung
  },
  props: {
    positions: {
    },
    config: {
    },
    antragId: {
    },
    categoryId: {
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      columnActions : [
        {
          legend: {
            icon: 'PhTrash',
            index: 1,
            key: 'DELETE',
            label: 'Löschen',
          }
        }
      ],
      positionToDelete : {
      },
      summe:{
        default :0,
      }
    }
  },
  watch: {
    tableData: {
      handler() {
        let sum = 0;
        
         if(this.tableData?.records){
           this.tableData.records.forEach(r=> sum+= +r.betrag)
         }
          this.summe = sum;
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters({
      positionInfo: FONDSFINDER_TYPES.GETTERS.POSITION_INFO,
      antraege: ANTRAG_TYPES.GETTERS.ANTRAEGE,
      antragData: ANTRAG_TYPES.GETTERS.ANTRAEGE_DATA
    }),
    headers() {
      return {
        isin: {
          label: 'ISIN',
          key: 'isin',
          dataType: 'String',
          sortable: false,
          filterable: false,
          sum: false,
          visible: true,
          fixed: true,
        },
        fondsname: {
          label: 'Fondsname',
          key: 'fondsname',
          dataType: 'String',
          sortable: false,
          filterable: false,
          sum: false,
          visible: true,
          fixed: true,
        },
        betrag: {
          label: this.betragHeader,
          key: 'betrag',
          dataType: 'Slot',
          sortable: false,
          filterable: false,
          sum: this.config?.hasSumme,
          visible: !this.config.noBetrag,
          fixed: true,
        },
        aa: {
          label: this.config?.individualAA && typeof(this.config?.individualAA) === 'string' ? this.config?.individualAA : 'Ausgabeaufschlag',
          key: 'aa',
          dataType: 'Slot',
          sortable: false,
          filterable: false,
          sum: false,
          visible: this.config?.individualAA,
          fixed: true,
        },
        modus: {
          label: this.config?.modus && this.config?.modus.label,
          key: 'modus',
          dataType: 'Slot',
          sortable: false,
          filterable: false,
          sum: false,
          visible: this.config?.modus,
          fixed: true,
          disabled: this.config?.modus && this.config?.modus.disabled,
          values: this.config?.modus && this.config?.modus.values
        },
        depotnummer: {
          label: this.config?.depotnummer && this.config?.depotnummer.label,
          key: 'depotnummer',
          dataType: 'Slot',
          sortable: false,
          filterable: false,
          sum: false,
          disabled: this.config?.depotnummer && this.config?.depotnummer.disabled,
          visible: this.config?.depotnummer,
          fixed: true,
        },
        provfrei: {
          label: this.config?.provfrei && this.config?.provfrei.label,
          key: 'provfrei',
          dataType: 'Slot',
          sortable: false,
          filterable: false,
          sum: false,
          disabled: this.config?.provfrei && this.config?.provfrei.disabled,
          visible: this.config?.provfrei,
          fixed: true,
        },
        depotuebertrag: {
          label: this.config?.depotuebertrag && this.config?.depotuebertrag.label,
          key: 'depotuebertrag',
          dataType: 'Slot',
          sortable: false,
          filterable: false,
          sum: false,
          visible: this.config?.depotuebertrag,
          disabled: this.config?.depotuebertrag && this.config?.depotuebertrag.disabled,
          fixed: true,
        },
        dynamik: {
          label: this.config?.dynamik && this.config?.dynamik.label,
          key: 'dynamik',
          dataType: 'Slot',
          sortable: false,
          filterable: false,
          sum: false,
          visible: this.config?.dynamik,
          disabled: this.config?.dynamik && this.config?.dynamik.disabled,
          fixed: true,
        },
        laufzeit: {
          label: this.config?.laufzeit && this.config?.laufzeit.label,
          key: 'laufzeit',
          dataType: 'Slot',
          sortable: false,
          filterable: false,
          sum: false,
          visible: this.config?.laufzeit,
          disabled: this.config?.laufzeit && this.config?.laufzeit.disabled,
          fixed: true,
        },
        zielsumme: {
          label: this.config?.zielsumme && this.config?.zielsumme.label,
          key: 'zielsumme',
          dataType: 'Slot',
          sortable: false,
          filterable: false,
          sum: false,
          visible: this.config?.zielsumme,
          disabled: this.config?.zielsumme && this.config?.zielsumme.disabled,
          fixed: true,
        },
        lagerstelle: {
          label: this.config?.lagerstelle && this.config?.lagerstelle.label,
          key: 'lagerstelle',
          dataType: 'Slot',
          sortable: false,
          filterable: false,
          sum: false,
          visible: this.config?.lagerstelle,
          fixed: true,
          values: this.config?.lagerstelle && this.config?.lagerstelle.values,
          disabled: this.config?.lagerstelle && this.config?.lagerstelle.disabled,
        },
        frequenz: {
          label: this.config?.frequenz && this.config?.frequenz.label,
          key: 'frequenz',
          dataType: 'Slot',
          sortable: false,
          filterable: false,
          sum: false,
          visible: this.config?.frequenz,
          fixed: true,
          values: this.config?.frequenz && this.config?.frequenz.values,
          disabled: this.config?.frequenz && this.config?.frequenz.disabled,
        },
        isPositionZielfondsSparplan: {
          label: '',
          key: 'isPositionZielfondsSparplan',
          dataType: 'Boolean',
          sortable: false,
          filterable: false,
          sum: false,
          visible: false,
          fixed: true,
        },
        bemerkung: {
          label: 'Bemerkung',
          key: 'bemerkung',
          dataType: 'Slot',
          sortable: false,
          filterable: false,
          sum: true,
          visible: this.config?.bemerkung,
          fixed: true,
        },
        keineIndexierung: {
          label: 'Keine Indexiereung',
          key: 'keineIndexierung',
          dataType: 'Slot',
          sortable: false,
          filterable: false,
          sum: false,
          visible: this.config?.hasIndexierung,
          fixed: true,
        },
        actions: {
          label: "",
          key: "actions",
          priority: 1,
          dataType: "tableAction",
          visible: true,
          sortable: false,
          filterable: false,
          sum: false,
          fixed: true,
        }
      }
    },
    tableData() {
      return {
        headers: this.headers,
        records: this.getPositions?.map((pos, index) => {
          const row = Object.assign(pos, { 
            fondsname: this.getFondsName(pos, this.positionInfo),
            betrag: pos[this.getBetragId] || 0,
            index: index,
            aa: this.config?.aaVariableName ? pos[this.config.aaVariableName] : pos.aa,
            defaultAA: this.positionInfo[pos.isin]?.defaultAusgabeaufschlag != null ? "" + this.positionInfo[pos.isin].defaultAusgabeaufschlag : "",
            isPositionZielfondsSparplan: this.config.isPositionZielfondsSparplan
          });
          if (this.config?.hasIndexierung) {
            if (!Object.keys(pos).includes('indexierung')) {
              row.indexierung = pos.art === 'SPARPLAN' ? true : false;
            }
            row.keineIndexierung = !row.indexierung;
          }
          return row;
        }),
      }
    },
    betragHeader() {
      return this.config && this.config.betrag && this.config.betrag.label || 'Betrag';
    },
    betragType() {
      let type = 'currency';
      if (this.config && this.config.betrag && this.config.betrag.type) {
        switch(this.config.betrag.type) {
          case 'TEXT_PROZENT': 
            type = 'percent';
            break;
          case 'TEXT': 
            type = 'text'
            break;
          case 'TEXT_NUMBER':
            type = 'number';
            break;
        }
      }
      return type;
    },
    antrag() {
      if (this.antraege && this.antraege[this.antragId]) {
        return this.antraege[this.antragId]
      }
      return null
    },
    getPositions() {
      if (this.positions && this.positions.length) {
        this.positions.filter(pos => !pos[this.getBetragId] && pos[this.getBetragId] !== 0).forEach(pos => 
        this.onChangeInputField(0, pos, this.getBetragId));
      }
      return this.positions;
    },
    positionWarnings() {
      if (this.antrag && this.antrag.warnings) {
        this.updateWarnings(this.antrag.warnings && this.antrag.warnings.positionWarnings);
        return this.antrag.warnings && this.antrag.warnings.positionWarnings;
      }
      return null
    },
    dynamicValidators() {
      if (this.config && this.config.betrag && this.config.betrag.validators) {
        const dynamicValidators = this.getPositions
          .map(pos => ({
            componentId: 'betrag' + pos.index,
            validators: this.config.betrag.validators.map(componentValidatorToRule)
          })
        )

        return dynamicValidators
      }
    },
    getBetragId() {
      return this.config && this.config.betrag && this.config.betrag.id
    },
    isEditDisabled() {
      return this.disabled || this.antragData?.["FFB-splittsparplan"]?.["disableEdit"];
    },
  },
  methods: {
    values(id, prop) {
      return prop.data.tableData.headers[id]?.values || [];
    },
    isDisabled(id, prop) {
      return this.disabled || (prop.data.tableData.headers[id]?.disabled && isHiddenDisabled(prop.data.tableData.headers[id]?.disabled, prop.data.row));
    },
    updateWarnings(warnings) {
      if (warnings && warnings.length) {
        for (const warn of warnings) {
          if (warn.posGrpId) {
            if (!warn.id || warn.id.includes(this.categoryId)) {
              const index = this.getPositions.findIndex(pos => pos.posGrpId === warn.posGrpId || pos.isin === warn.posGrpId);
              if (index >= 0) {
                this.$pushErrors('betrag' + index, warn.message || '');
              }
            }
          }
        }
      }
    },
    commitUpdatePositionsFromEvent(positionChange, isin) {
      const payload = {
        id: this.antragId,
        categoryId: this.categoryId,
        fonds: [
          { 
            ...positionChange,
            isin,
          }
        ]
      }
      this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_POSITIONS, payload)
    },
    onChangeInputField($event, position, field) { 
      if (field == 'aa' && this.config?.aaVariableName) {
        field = this.config.aaVariableName;
      }
      const positionChange = { [field]: $event }
      this.commitUpdatePositionsFromEvent(positionChange, position.isin)
    },
    handleTableAction(event) {
      if(event.action?.legend?.key === 'DELETE') {
        this.openModalDeleteFond(event.value);
      }
    },
    openModalDeleteFond(fond) {
      if (!this.disabled) {
        this.positionToDelete = fond;
        this.$refs.deleteModal.open()
      }
    },
    doDeletePosition() {
      if (this.positionToDelete) {
        this.deletePosition(this.antragId, this.categoryId, this.positionToDelete);
        this.positionToDelete = null;
      }
    }
  },
}
</script>

<style scoped>
  .small {
    font-size: small;
    color: var(--color-danger);
    margin-bottom: 1rem;
    padding-left: 2rem;
  }
</style>