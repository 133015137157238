import templateRoutes from '@/views/cms/templateRoutes'

export default [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "home-view" */ '@/components/cms/HomeView.vue'),
    children: [
      ...templateRoutes
    ],
  },
  {
    // if nothing matches, goes to HomeView.vue
    path: '*',
    redirect: '/page/:broker_id(\\d+)?'
  },
]