<template>
    <div>
        <PageHeaderActionCard :showHeaderCard="false" />

        <div class="box__container" v-if="pruefstatusChange && pruefstatusChange.newStatus && !pruefstatusChange.errorMessage">
            <div class="box__title">Prüfstatusänderung</div>

            <div>Kunde {{pruefstatusChange.kundennr}} {{pruefstatusChange.vorname}} {{pruefstatusChange.nachname}}</div>
            <div>Gesellschaft {{pruefstatusChange.gesellschaftName}}</div>
            <div v-if="pruefstatusChange.documents && pruefstatusChange.documents.length">
                <div v-for="(doc, index) of pruefstatusChange.documents" :key="index">
                    {{doc.name}}
                </div>
            </div>
            <div>Status ändern {{pruefstatusChange.pruefstatus.value}} -> {{pruefstatusChange.pruefstatusChangedStr}}</div>

            <InputField v-model="form.bemerkung" label="Bemerkung" v-if="pruefstatusChange.pruefstatusChanged === 'OK'" />
            
            <div v-if="pruefstatusChange.pruefstatusChanged === 'OK' || pruefstatusChange.pruefstatusChanged === 'IN_BEARBEITUNG'">
                <DatePickerField 
                    isValueAsString
                    :disabled="!pruefstatusChange.isVersBeginnEditable"
                    v-model="form.versBeginn" 
                    label="Versicherungsbeginn" 
                    style="width: 100%"/>  
                <DatePickerField 
                    v-if="pruefstatusChange.isAenderungsBeginnVisible"
                    isValueAsString
                    v-model="form.aenderungsBeginn" 
                    label="Änderungsbeginn" 
                    style="width: 100%"/>  
            </div>

            <div v-if="pruefstatusChange.pruefstatusChanged === 'IN_BEARBEITUNG'">
                <InputCheckBoxItem label="Makler informieren" v-model="form.maklerInformieren"/>
                <FormLabel :config="{'small': true}" label="E-Mail an den Makler, das der Vertrag an die Gesellschaft verschickt wurde" />
            </div>
            <div v-if="pruefstatusChange.pruefstatusChanged === 'IN_BEARBEITUNG' || pruefstatusChange.pruefstatusChanged === 'SELBST_GESENDET'">
                <InputCheckBoxItem label="Erinnerung senden" v-model="form.reklamationRemainder"/>
                <FormLabel :config="{'small': true}" label="Erinnerungsmails / Reklamationen an die Gesellschaft senden bis der Vertrag bearbeitet wurde" />
            </div>

            <div class="color-danger" v-if="pruefstatusChange.sendMaklerSelbst">
                Achtung: der Vermittler hat angegeben der Antrag selbst an die Gesellschaft zu senden bzw gesendet zu haben!
            </div>

            <div v-if="pruefstatusChange.emailData">
                <InputRadioBoxGroup  v-model="form.emailSenden" :values="comboboxValues" />
                <MailComposer ref="mailComposer" :hasPageHeader="false" v-if="form.emailSenden === 'RADIO_MAIL'"
                    :legitimation="{ hasLegitimation: true, disabled: pruefstatusChange.emailDataActions && pruefstatusChange.emailDataActions['LEGITIMATION']}" 
                    :vollmacht="{ hasVollmacht: true, disabled: pruefstatusChange.emailDataActions && pruefstatusChange.emailDataActions['VOLLMACHT']}" 
                    :senderDisabled="true"
                    :multipleReceivers="false"
                    :hasPasswort="true"
                    @fileUpload="fileUpload($event)"
                 />
            </div>

            <div class="mt-3">Wollen Sie die Änderung übernehmen?</div>

            <div class="layout__negative-margin--8">
                <div class="row">
                    <div class="col-12 col-sm-3 col-lg-2 mt-1">
                        <BaseButton @click="confirm()" isBlock isPrimary>Ja</BaseButton>
                    </div>
                    <div class="col-12 col-sm-3 col-lg-2 mt-1">
                        <BaseButton @click="resetData()" isBlock isSecondary>Nein</BaseButton>
                    </div>
                </div>
            </div>
        </div>

        <div class="box__container" v-else-if="pruefstatusChange && pruefstatusChange.errorMessage">
            <div class="box__title">Fehler</div>
            <div>{{pruefstatusChange.errorMessage}}</div>
            <div class="col-12 col-sm-3 col-lg-2 mt-1">
                <BaseButton @click="resetData()" isBlock isPrimary>Ok</BaseButton>
            </div>
        </div>

        <BaseModal ref="noAttachments" :showCancelButton="false" labelButtonConfirm="Ok">
            <template v-slot:modalTitle>
               <ph-warning :size="16" class="mr-2" style="color: orangered"/>Dokumente anhängen
            </template>
            <div>Achtung, es wurden keine Dokumente an die Mail angehängt.</div>
        </BaseModal>
        <BaseModal ref="errorModal" :showCancelButton="false" labelButtonConfirm="Ok" @onConfirmButton="errorMessage=null">
            <template v-slot:modalTitle>
               <ph-warning :size="16" class="mr-2" style="color: orangered"/>Fehler
            </template>
            <div>{{errorMessage}}</div>
        </BaseModal>
    </div>
</template>

<script>
import PageHeaderActionCard from '@/components/core/PageHeaderActionCard.vue'
import BaseButton from "@/components/core/BaseButton.vue";
import VERSICHERUNG_TYPES from '@/store/versicherungen/types';
import { mapGetters } from 'vuex';
import MailComposer from '@/components/mailcomposer/MailComposer.vue'
import MAILCOMPOSER_TYPES from "@/store/mailcomposer/types";
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import BaseModal from "@/components/core/BaseModal.vue";
import InputField from '@/components/core/forms/InputField.vue';
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue';
import InputCheckBoxItem from '@/components/core/forms/checkbox/InputCheckBoxItem.vue';
import FormLabel from '@/components/core/forms/FormLabel.vue';
import { PhWarning } from 'phosphor-vue';

export default {
    components: {
        PageHeaderActionCard,
        BaseButton,
        MailComposer,
        InputRadioBoxGroup,
        BaseModal,
        DatePickerField,
        InputField,
        InputCheckBoxItem,
        FormLabel,
        PhWarning,
    },
    data() {
        return {
            errorMessage: null,
            comboboxValues: [{ label: 'E-Mail senden', value: 'RADIO_MAIL'}, { label: 'Nicht senden', value: 'RADIO_NO_MAIL'}],
            form: {},
            formReseted: false,
        }
    },
    watch: {
        pruefstatusChange(value) {
            this.initForm(value)
        }
    },
    computed: {
        ...mapGetters({
            data: VERSICHERUNG_TYPES.GETTERS.PRUEFSTATUS_CHANGE,
        }),
       pruefstatusChange() {
           return this.data || {}
       },
    },
    mounted() {
        this.form = {
            emailSenden: 'RADIO_MAIL',
            aenderungsBeginn: '',
            versBeginn: '',
            bemerkung: '',
            maklerInformieren: false,
            reklamationRemainder: false,
        }
        if  (!this.$route.query.backAction) {
            this.getPruefChangeStatus();
        } else {
            this.setMailComposerConfig(this.pruefstatusChange.emailData)
            this.initForm(this.pruefstatusChange)
        }
    }, 
    methods: {
        initForm(pruefstatus) {
            this.form.aenderungsBeginn = pruefstatus?.aenderungsBeginn
            this.form.versBeginn = pruefstatus?.versBeginn
            this.form.maklerInformieren = pruefstatus?.maklerInformieren
            this.form.reklamationRemainder = pruefstatus?.reklamationRemainder
        },
        async getPruefChangeStatus() {
            if (this.pruefstatusChange?.checkVersicherungId) {
                const values = Object.assign({}, this.pruefstatusChange)
                this.$store.commit(VERSICHERUNG_TYPES.MUTATIONS.SET_PRUEFSTATUS_CHANGE, null)
                let emailData
                await this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.CAN_CHANGE_PRUEFSTATUS, { newStatus: values.pruefstatusChanged, versCheckId: values.checkVersicherungId }).then(response => {
                    if (response?.errorMessage) {
                        this.$store.commit(VERSICHERUNG_TYPES.MUTATIONS.SET_PRUEFSTATUS_CHANGE, Object.assign(values, { errorMessage:  response.errorMessage }))
                    } else {
                        emailData = response?.emailData
                        this.$store.commit(VERSICHERUNG_TYPES.MUTATIONS.SET_PRUEFSTATUS_CHANGE, Object.assign(values, response))
                    }
                })
                .catch(() => {
                    this.$store.commit(VERSICHERUNG_TYPES.MUTATIONS.SET_PRUEFSTATUS_CHANGE, Object.assign(values, { errorMessage:  'Es ist ein Fehler aufgetreten' }))
                })
                
                this.setMailComposerConfig(emailData)
            }
        },
        setMailComposerConfig(emailData) {
            if (emailData) {
                  this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAILCOMPOSER_CONFIG, {
                        sender: [{value: emailData.from, label:emailData.from}],
                        receiver: [{value: emailData.to, label:emailData.to}],
                        subject: emailData.subject,
                        attachments: emailData.attachments,
                        receiverCC: [{value: emailData.cc, label: emailData.cc }],
                        getComputedText: emailData.text,
                        passwort: emailData.passwort,
                    });
                    if (emailData.attachments?.length) {
                        this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, emailData.attachments);
                    }
            }
        },
        resetData() {
            this.formReseted = true;
            this.$store.commit(VERSICHERUNG_TYPES.MUTATIONS.SET_PRUEFSTATUS_CHANGE, null);
            this.$router.push({ path: '/home/antragsliste-versicherungen'})
        },
        confirm() {
            if (this.pruefstatusChange?.emailData && this.form.emailSenden === 'RADIO_MAIL' && !this.$refs.mailComposer?.allAttachments?.length) {
                this.$refs.noAttachments.open()
            } else {
                const to = this.$refs.mailComposer?.receiver
                const cc = this.$refs.mailComposer?.receiverCC
                let attachments = this.$refs.mailComposer?.allAttachments
                if (attachments?.length) {
                    attachments = attachments.map(att => ({ id: att.id, name: att.fileName }))
                }
                const emailData = this.pruefstatusChange?.emailData ? {
                    to: to?.length && to[0] ? (to[0].label || to[0]) : '',
                    cc: cc?.length && cc[0] ? (cc[0].label || cc[0]) : '',
                    subject: this.$refs.mailComposer?.subject || '',
                    passwort: this.$refs.mailComposer?.passwort || '',
                    text: this.$refs.mailComposer?.html || this.$refs.mailComposer?.getComputedText || '',
                    attachments: attachments,
                } : null;
                const payload = Object.assign(this.form, { newStatus: this.pruefstatusChange.pruefstatusChanged, checkVersicherungId: this.pruefstatusChange?.checkVersicherungId, emailData: emailData})
                this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.SAVE_PRUEFSTATUS, payload).then(response => {
                    if (response?.errorMessage) {
                        this.errorMessage = response.errorMessage
                        this.$refs.errorModal.open()
                    } else {
                        this.$router.push({ path: '/home/antragsliste-versicherungen', query: { previousRoute: 'pruefstatus-change' }})
                    }
                })
                .catch(() => {
                    this.errorMessage = 'Prüftatus kann nicht gespeichert werden.'
                    this.$refs.errorModal.open()
                })
            }
        },
        resetMailData() {
            this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAILCOMPOSER_CONFIG, {});
            this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_PARTICIPANTS, null);
            this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, null);
        },
        async fileUpload(type) {
            if (type) {
                await this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.ADD_ATTACHMENT, {checkVersicherungId: this.pruefstatusChange.checkVersicherungId, docType: type })
                    .then(response => {
                            if (response?.errorMessage) {
                                this.errorMessage = response.errorMessage
                                this.$refs.errorModal.open()
                            } else if (response?.attachment) {
                                this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, response.attachment)
                            }
                    })
                    .catch(() => {
                        this.errorMessage = 'Dokument kann nicht geöffnet werden.'
                        this.$refs.errorModal.open()
                    })
            }
        }
    },
    beforeRouteLeave(to,from,next) {
        if(!this.formReseted) {
            const emailData = Object.assign({}, this.pruefstatusChange?.emailData)
            emailData.attachments = this.$refs.mailComposer?.allAttachments
            emailData.subject = this.$refs.mailComposer?.subject
            emailData.text = this.$refs.mailComposer?.html
            emailData.passwort = this.$refs.mailComposer?.passwort
            emailData.to = (this.$refs.mailComposer?.receiver && this.$refs.mailComposer?.receiver[0] || {}).label
            emailData.cc = this.$refs.mailComposer?.receiverCC && this.$refs.mailComposer?.receiverCC[0] 
                && (this.$refs.mailComposer?.receiverCC[0].label || this.$refs.mailComposer?.receiverCC[0])
            const data = Object.assign({}, this.pruefstatusChange, this.form, { emailData: emailData })
            this.$store.commit(VERSICHERUNG_TYPES.MUTATIONS.SET_PRUEFSTATUS_CHANGE, data)
        }
        this.resetMailData()

        this.$addBreadcrumb({
          to,
          from,
          label: 'Zurück zur Prüfstatusänderung'
        });

        next()
    }
}
</script>

<style>

</style>