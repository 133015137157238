<template>
  <BaseModal
    ref="favoritenlisteModal"
    modalTitle="Favoritenlisten"
    labelButtonConfirm="Hinzufügen"
    :confirmDisabled="!selectedValidRows.length || !fondHasIsin"
    :showCancelButton="!$isSmallScreen"
    closeButtonLabel=""
    @onConfirmButton="addToFavoritenliste"
    @close="selectedRows = []"
  >
    <GhostLoading v-if="loading" type="table" />
    <Table
      v-else
      :headers="headers"
      :rows="rows"
      :mobileConfig="{title: 'label', headers: ['fondIncludedPill'], disableClickRow: true}"
      rowId="id"
      :rowsPerPage="15"
      :tableRowsPerPage="[]"
      v-model="selectedRows"
    />
  </BaseModal>
</template>

<script>
import FONDSFAVORITEN_TYPES from '@/store/fondsfavoriten/types';
import Table from "@/components/table2/Table.vue";
import { TextColumn, PillColumn, } from "@/components/table2/table_util.js";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import BaseModal from '@/components/core/BaseModal.vue';
import { mapGetters } from 'vuex';

export default {
  mixins: [],
  components: {
    Table,
    GhostLoading,
    BaseModal,
  },
  props: {
    fond: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      selectedRows: [],
    }
  },
  computed: {
    ...mapGetters({
      favoritenlists: FONDSFAVORITEN_TYPES.GETTERS.FAVORITENLISTS,
      options: FONDSFAVORITEN_TYPES.GETTERS.OPTIONS,
    }),
    headers() {
      return {
        lockedLeft: [
          TextColumn("label", "Bezeichnung"),
          PillColumn("fondIncludedPill", ""),
        ],
        center: [],
        lockedRight: []
      };
    },
    rows() {
      return this.favoritenlists.map(row => ({
        ...row,
        selectedDisabled: row.fondIncluded,
        fondIncludedPill: row.fondIncluded ? {label: "bereits enthalten", type: "success"} : null
      }))
    },
    fondHasIsin() {
      return !!this.fond?.isin
    },
    selectedValidRows() {
      return this.selectedRows.filter(fav => !fav.fondIncluded)
    },
  },
  methods: {
    loadFavoritenlists() {
      this.loading = true;

      this.$store.dispatch(FONDSFAVORITEN_TYPES.ACTIONS.LOAD_FAVORITENLISTS, { isin: this.fond.isin })
        .then(() => {
          this.selectedRows = this.rows.filter(fav => fav.fondIncluded)
        })
        .finally(() => this.loading = false);
    },
    addToFavoritenliste() {
      if (!this.fondHasIsin) {
        return
      }

      this.loading = true;
      const validRowsLabel = this.selectedValidRows.map(row => '<li>' + row.labelShort + '</li>').join('')
      const favoritenlistIds = this.selectedValidRows.map(favList => favList.id)

      this.$store.dispatch(FONDSFAVORITEN_TYPES.ACTIONS.ADD_FONDS, { isins: [this.fond.isin], favoritenlistIds })
        .then(() => {
          this.$confirmModal({
            title: 'Erfolg',
            message: `Der Fond "${this.fond.fondsname}" wurde erfolgreich den folgenden Favoritenlisten hinzugefügt:<br>
            <ul>
              ${validRowsLabel}
            </ul>`, 
            labelButtonConfirm: 'Ok',
            showCancelButton: false,
          })
        })
        .finally(() => {
          this.loading = false
        });

      this.$refs.favoritenlisteModal.close()
    },
    open() {
      this.$nextTick(() => {
        this.loadFavoritenlists()
        this.$refs.favoritenlisteModal.open()
      })
    }
  },
}
</script>