<template>
  <div>
    <AntragRender 
      :antrag="getAntrag"
      :antragData="getAntragData"
      :routeStep="getRouteStep">
    </AntragRender>

  </div>
</template>

<script>
import ANTRAG_TYPES from "@/store/antrag/types";
import INVESTMENT_ADVICE from "@/store/investmentAdvice/types";
import { calculateAntragID } from '@/components/antrag/antrag-utils';
import { mapGetters } from 'vuex';
import AntragRender from '@/components/antrag/AntragRender.vue';
import antragNavigationMixin from '@/mixins/antrag/antrag-navigation-mixin.js';
import antragMixin from '@/mixins/antrag/antrag-mixin.js';

export default {
  mixins: [antragNavigationMixin, antragMixin],
  computed: {
    ...mapGetters({
      antraege: ANTRAG_TYPES.GETTERS.ANTRAEGE,
      antraegeData: ANTRAG_TYPES.GETTERS.ANTRAEGE_DATA,
      depoteroeffnungInvestmentAdvice: INVESTMENT_ADVICE.GETTERS.INVESTMENT_ADVICE_DEPOTEROEFFNUNG,
    }),
    getRouteStep() {
      return this.$route.params.step
    },
    getRouteAntragId() {
      if (this.$route.params.antragsName && this.$route.params.lagerstelle) {
        return calculateAntragID({antragsName: this.$route.params.antragsName, lagerstelle: this.$route.params.lagerstelle})
      }
      return null;
    },
    getAntrag() {
      if (this.antraege && this.getRouteAntragId && this.antraege[this.getRouteAntragId]) {
        return this.antraege[this.getRouteAntragId];
      }
      return null;
    },
    getAntragData() {
      if (this.antraegeData && this.getRouteAntragId && this.antraegeData[this.getRouteAntragId]) {
        return this.antraegeData[this.getRouteAntragId]
      }
      return null
    },
    antragId() {
       return this.getAntrag?.id;
    },
    isAntragSaved() {
      return this.getAntragData && (this.getAntragData.antragsdatenId || this.getAntragData.antragsnrIntern || this.getAntragData.parameter_protokoll_id
      || this.getAntragData.antragsBezeichnung === "beratungsmapperisikopruefung" || this.antragId === 'VERSICHERUNG-beratungsdokumentationVersicherungen')
    },
    isWebserviceAktiv(){
      return this.getAntragData?.isWebserviceAktiv;
    },
    isWebserviceAntragSaved(){
      return this.isWebserviceAktiv && (this.getAntrag?.hatPDF || this.getAntragData?.hatPDF);   
    }
  },
  beforeDestroy() {
    this.removeAntragWarning();
  },
  watch: {
    '$route'(to, from) {
      if (!this.$route.query.backAction && !this.$route.query.avoidAntragLoading && this.isAnotherAntrag(to, from)) {
        this.doLoadAntrag();
      }
    },
  },
  data() {
    return {
      lagerstelle: null,
      antragsName: null,
    }
  },
  methods: {
    removeAntragWarning() {
      if (this.lagerstelle && this.antragsName) {
      const payload = {
        ...this.lagerstelle && { lagerstelle: this.lagerstelle},
        ...this.antragsName && { antragsName: this.antragsName}
      }
      payload.id = calculateAntragID(payload);

      this.$store.commit(ANTRAG_TYPES.MUTATIONS.REMOVE_ANTRAG_WARNING, payload)
      }
    },
    doLoadAntrag() {
      this.lagerstelle = this.$route.params.lagerstelle;
      this.antragsName = this.$route.params.antragsName;
      this.loadAntrag({ lagerstelle: this.lagerstelle, antragsName: this.antragsName, depoteroeffnungInvestmentAdvice: this.depoteroeffnungInvestmentAdvice, 
        dynamicFormId: this.$route.query?.dynamicFormId, ffbDepoteroeffnungId: this.$route.query?.ffbDepoteroeffnungId});
    },
    isAnotherAntrag(to, from) {
      return to.params.lagerstelle !== from.params.lagerstelle 
        || to.params.antragsName !== from.params.antragsName
        || to.query?.antragsdatenId !== from.query?.antragsdatenId
    },
    saveWebserviceAntrag(){
      if (this.isWebserviceAktiv && (!this.isWebserviceAntragSaved || this.getAntrag?.dataHasChanged)) {
          const payload = {
            id: this.antragId,
            data: {
              synchronisieren: true,
            }
          }

        this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_ANTRAG_DATA, payload)
        this.save()
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    if (from.path === '/beratung/antrag-beratungsmappe-selector') {
      next()
    } else if (to.query?.bmOptChosen || to.query?.beratungsMappeId || to.query?.avoidAntragLoading || (to.query?.hideBeratungsmappepopup === "true")) {
      next()
    } else {
      next({ path: '/beratung/antrag-beratungsmappe-selector', query: { formPath: to.fullPath, lagerstelleLabel: to.query?.lagerstelleLabel } })
    }
  },

  beforeRouteLeave(to, from, next) {
    if (this.getAntrag?.dataHasChanged && !this.isAntragSaved && !to.fullPath.includes('INPUT_DATA_POSITIONEN_KSC') && !to.fullPath.includes('/fondsfinder')) { //MSC-19303
        this.$confirmModal({
          title: 'Es bestehen Fehler im Antrag',
          message: `
            Wenn Sie die Seite jetzt verlassen wird der Antrag <b>nicht</b> gespeichert und Ihre Änderungen gehen verloren.
            <br>
            <br> Möchten Sie die Seite trotzdem verlassen?`,
          labelButtonCancel: 'Abbrechen',
          labelButtonConfirm: 'Seite verlassen',
        }).then(() => {
          this.$addBreadcrumb({
            label: 'zurück zum Formular', 
            to,
            from,
          });

          next()
        }).catch(() => {})
    }else  {
      if (!to.query.noSave && !this.isWebserviceAktiv) {
        this.save();
      } else if (this.isWebserviceAktiv && !to.fullPath.includes('/fondsfinder')) {
        this.saveWebserviceAntrag()
      }

      this.$addBreadcrumb({
        label: 'zurück zum Formular', 
        to,
        from,
      });

      next()
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (this.isAnotherAntrag(to, from)  && !this.isWebserviceAktiv) {
      this.save();
      
      this.$addBreadcrumb({
        label: 'zurück zum Formular', 
        to,
        from,
      });
    }

    if ((this.antragId === 'FK-rahmenvereinbarung' || this.antragId === 'FK-dialogvertragvermittlung') && this.getAntragData && this.getAntragData['INPUT_CHECK_SAVE_KUNDENDATEN'] === '1' && this.getAntragData.aenderungenHinweise){
      this.$confirmModal({
        title: 'Änderungen Kundeneinstellungen',
        message: this.getAntragData.aenderungenHinweise,
        labelButtonConfirm: 'Ok',
        showCancelButton: false,
      }).finally(() => {
        delete this.getAntragData.aenderungenHinweise
        next()
      })
    } else {
      next()
    }
  },
  mounted() {
    if (!this.$route.query.backAction && !this.$route.query.avoidAntragLoading) {
      this.doLoadAntrag();
    }
  },
  components: {
    AntragRender
}
}
</script>

<style scoped>

</style>