<template>
  <div v-if="searchingPositions">
    <div class="row">
      <div class="col-12">
        <h2>Wertpapiersuche</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <FondsFinderSuchMaske/>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <FondsFinderPositionsList @goBack="addFonds($event)" zurueckButtonText="Abbrechen" :addPositionsOnBack="false"/>
      </div>
    </div>
  </div>
  <div v-else class="antrag-components__container">
    <div class="box__container">

      <InputRadioBoxGroup v-model="positionData.allePositionen" :values="allValues" />

      <Table class="table-container" v-if="!positionData.allePositionen"
        tableId="f168f001-d40a-4a9a-ba2b-aa8d3ce74e30"
        :tableData="tableData"
        :cardViewEnabled="false"
        :filterEnabled="false"
        :exportEnabled="false"
        :paginationEnabled="false">
        <template v-slot:action="props">
          <InputField v-model="positionData['anteile' + props.data.row.index]" v-if="props.data.key == 'Anteile'"/>
        </template>
        <template v-slot:columnActions="props">
          <BaseButton @click="deleteFond(props.data.row.index - 1)" isSecondary>
            <ph-trash :size="16" />
          </BaseButton>
        </template>
      </Table>
      <BaseButton v-if="!positionData.all" @click="searchingPositions=true">Positionen hinzufügen</BaseButton>

      <FormDivider/>

      <InputToggleSwitch v-model="positionData.kontoAufloesen" label="Darüber hinaus erteile/n ich/wir Ihnen den Auftrag, das Konto abzuschließen und aufzulösen."/>
      
      <div class="row">
        <div class="col">
          <BaseButton @click="onBackButton">Zurück zum Formular</BaseButton>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex';
import { calculateAntragID } from '@/components/antrag/antrag-utils';
import antragNavigationMixin from '@/mixins/antrag/antrag-navigation-mixin.js';
import ANTRAG_TYPES from '@/store/antrag/types';
import CUSTOMERDATA_TYPES from '@/store/customerData/types'
import InputField from '@/components/core/forms/InputField.vue';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import FondsFinderSuchMaske from '@/components/antrag/fondsfinder/FondsFinderSuchMaske.vue';
import FondsFinderPositionsList from '@/components/antrag/fondsfinder/FondsFinderPositionsList.vue';
import Table from '@/components/table/Table.vue';
import FormDivider from '@/components/core/forms/FormDivider.vue';
import {PhTrash} from 'phosphor-vue';

export default {
  mixins: [antragNavigationMixin],
  components: {
    InputRadioBoxGroup,
    InputField,
    InputToggleSwitch,
    BaseButton,
    FondsFinderSuchMaske,
    FondsFinderPositionsList,
    Table,
    FormDivider,
    PhTrash,
  },
  props: {
    warnings: {
    },
  },
  computed: {
    ...mapGetters({
      antraege: ANTRAG_TYPES.GETTERS.ANTRAEGE,
      antraegeData: ANTRAG_TYPES.GETTERS.ANTRAEGE_DATA,
    }),
    antrag () {
      return this.antraege[this.antragId]
    },
    component() {
      return this.antrag.components[this.step].find(component => component.id == this.categoryId)
    },
    componentData() {
      return this.antraegeData[this.antragId][this.component.id]
    },
    /*bankverbindungSelection() {
      return this.antrag && this.antrag.comboboxSelections && this.antrag.comboboxSelections[this.bankverbindungId]
    },*/
    tableData() {
      const records = []
      if (this.antrag)
        for (let i = 1; i <= this.component.config.anzahlFonds; i++)
          if (this.positionData["isin" + i])
            records.push({
              Wertpapiername: this.positionData["fondsname" + i],
              ISIN: this.positionData["isin" + i],
              Anteile: this.positionData["anteile" + i],
              index: i,
            })
      return {
        headers: {
          Wertpapiername: {
            label: "Wertpapiername",
            key: "Wertpapiername",
            dataType: "String",
            visible: true,
            sortable: false,
            filterable: false,
            sum: false,
            fixed: true,
          },
          ISIN: {
            label: "ISIN",
            key: "ISIN",
            dataType: "String",
            visible: true,
            sortable: false,
            filterable: false,
            sum: false,
            fixed: true,
          },
          Anteile: {
            label: "Stückzahl / Nominale",
            key: "Anteile",
            dataType: "Action",
            visible: true,
            sortable: false,
            filterable: false,
            sum: false,
            fixed: true,
          },
          columnActions: {
            label: 'Aktionen',
            key: 'columnActions',
            dataType: 'Slot',
            visible: true,
          },
        },
        records,
      }
    }
  },
  data() {
    return {
      antragId: null,
      fondIndex: -1,
      categoryId: null,
      positionData: {
      },
      step: null,
      wertpapierConfig: {
        type: "CAPITALBANKUEBERTRAGUNG",
        buttonText: "Wertpapier hinzufügen",
      },
      allValues: [
        {value: true, label: "Übertrag des gesamten Depots (Bitte beachten Sie, dass bei Auswahl dieser Option die hier aufgeführten Wertpapiere nicht in das fertige Dokument eingetragen werden, da sie Teil des Gesamtdepots sind und daher in diesem Haken inbegriffen sind.)"},
        {value: false, label: "Übertrag der folgenden Wertpapiere:"},
      ],
      //bankverbindungId: "INPUT_KONTO",
      searchingPositions: false,
    }
  },
  mounted() {
    const antragsName = this.$route.params.antragsName
    this.antragId = calculateAntragID({antragsName: antragsName, lagerstelle: this.$route.params.lagerstelle})
    this.fondIndex = this.$route.params.fondIndex
    this.categoryId = this.$route.params.categoryId
    this.step = this.$route.params.step
    this.positionData = {
      ...this.componentData[this.fondIndex]
    }
  },
  methods: {
    onBackButton() {
      this.navigateBackToCurrentStep()
    },
    savePosition() {
      const newData = this.componentData.slice()
      newData[this.fondIndex] = this.positionData
      const payload = {
        id: this.antragId,
        data: {
          [this.component.id]: newData
        }
      }
      this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_ANTRAG_DATA, payload)
    },
    addFonds(fonds) {
      this.searchingPositions = false
      if (!fonds || fonds.length == 0)
        return
      // combine previously selected fonds with the new fonds
      const positionList = this.getPositions()
      fonds.forEach(fond => {
        positionList.push({
          isin: fond.isin,
          name: fond.wertpapiername,
          anteile: "alle",
        })
      })
      this.setPositions(positionList)
    },
    deleteFond(index) {
      const positionList = this.getPositions()
      positionList.splice(index, 1)
      this.setPositions(positionList)
    },
    getPositions() {
      const positionList = []
      for (let i = 1; i <= this.component.config.anzahlFonds; i++)
        if (this.positionData["isin" + i])
          positionList.push({
            isin: this.positionData["isin" + i],
            name: this.positionData["fondsname" + i],
            anteile: this.positionData["anteile" + i],
          })
      return positionList
    },
    setPositions(positionList) {
      for (let i = 1; i <= this.component.config.anzahlFonds; i++) {
        Vue.set(this.positionData, "isin" + i, positionList.length >= i ? positionList[i-1].isin : null)
        Vue.set(this.positionData, "fondsname" + i, positionList.length >= i ? positionList[i-1].name : null)
        Vue.set(this.positionData, "anteile" + i, positionList.length >= i ? positionList[i-1].anteile : null)
      }
    },
    editBankverbindung(event) {
      // todo: should be replaced to BANKVERBINDUNG new / edit form after it will be separated from the modal
      const personId = 'person1';
      this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.UPDATE_SELECTED_CUSTOMER_DATA, personId);
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GOTO_BANKVERBINDUNG, this.backButtonPayload);
    },
  },
  beforeDestroy() {
    this.savePosition()
  }
}
</script>
<style scoped>

</style>