<template>
    <div>
        <PageHeaderTitleNavigation 
            :title="title">
        </PageHeaderTitleNavigation>
        <div class="box__container">
            <InputField label="Login-Name" v-model="ssoName" isComponentHalfSize />
            <ComboBox label="Berechtigung" v-model="permission" :values="comboBerechtigung" isComponentHalfSize />
            <InputField label="temporäres Passwort (Mindestens 6 Zeichen)" v-model="password" type="password" isComponentHalfSize />
            <div class="mb-4">Verwenden Sie ein Passwort, dass nicht Ihrem Loginkennwort entspricht und teilen Sie dieses im Nachgang dem zu registrierenden Nutzer mit.</div>
            
            <div class="mb-0">Wenn Sie einen SSO-Login angeben, wird die Einladung an das entsprechenden SSO-Konto gesendet und nur dessen Besitzer kann sich damit anmelden.</div>
            <!-- <div>Wenn kein SSO-Login angegeben wird, ist es möglich, sich ohne SSO-Konto anzumelden.</div>
            <div>In beiden Fällen wird die Einladung nach der ersten damit erfolgten Anmeldung ungültig bzw. spätestens nach 7 Tagen.</div> -->
            <div class="my-0">Die Einladung sollte also sofort mit einem SSO-Konto verknüpft werden, um die Zugangsberechtigung dauerhaft zu behalten.</div>
            <div>Verwenden Sie nicht das Passwort, das Sie für ihre Anmeldung verwenden!</div>

            <div class="mt-4">
                <router-link :to="{name: 'sso'}">
                    <span @click="updateBreadcrumb()">zurück</span>
                </router-link>
                <BaseButton class="ml-3" @click="createInvite" :disabled="!ssoName || password.length < 6" isPrimary>Einladen</BaseButton>
            </div>

            <BaseModal ref="notExist" labelButtonCancel="Ok" :showConfirmButton="false" @onCancelButton="closeErrorModal">
                <template v-slot:modalTitle>
                    <PhInfo style="color: orange" />
                    Fehler
                </template>
                <div>
                    {{errorStr}}
                </div>
            </BaseModal>
        </div>
    </div>
</template>

<script>
import CORE_TYPES from '@/store/core/types';
import SSO_TYPES from '@/store/sso/types';
import { mapGetters } from 'vuex';
import { PhInfo } from 'phosphor-vue';

import InputField from '@/components/core/forms/InputField.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import BaseButton from '@/components/core/BaseButton.vue'
import BaseModal from '@/components/core/BaseModal.vue';
import { required, minLength } from '@/mixins/validator/rules';
import validator from '@/mixins/validator';
import { MIN_PASSWORD_LENGTH } from '@/views/core/ChangePassword.vue';
import mixin from '@/mixins/sso/sso-mixin.js';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'

const DEFAULT_TITLE = 'Registrierten Benutzer hinzufügen'

export default {
    mixins: [validator, mixin],
    validators: {
        ssoName: [required("Konto ist erforderlich!")],
        password: [minLength(MIN_PASSWORD_LENGTH, "Das Passwort muss mindestens eine Länge von 6 Zeichen haben.")],
    },
    components: {
        InputField,
        ComboBox,
        BaseButton,
        BaseModal,
        PhInfo,
        PageHeaderTitleNavigation,
    },
    data() {
        return {
            ssoName: "",
            permission: "",
            password: "",
            errorStr: '',
        };
    },
    mounted() {
        this.permission = this.comboBerechtigung[0].value;
    },
    computed: {
        ...mapGetters({
            fullname: CORE_TYPES.GETTERS.GET_USER_FULL_NAME,
        }),
        title() {
            return DEFAULT_TITLE
        },
    },
    methods: {
        createInvite() {
            this.updateBreadcrumb();
            const response = this.$store.dispatch(SSO_TYPES.ACTIONS.CREATE_INVITE, {
                ssoName: this.ssoName,
                permission: this.permission,
                password: this.password,
            }).then((errorStr) => {
                if (errorStr) {
                    this.errorStr = errorStr;
                    this.$refs.notExist.open();
                } else {
                    this.$router.push({name: 'sso'});
                }
            })
        },
        closeErrorModal() {
            this.errorStr = null;
        },
        updateBreadcrumb() {
            this.$store.commit(CORE_TYPES.MUTATIONS.POP_SAVE_BACK_TO_PREVIOUS_PAGE);
        },
    },
}
</script>

<style scoped>

</style>