<template>
    <div>
        <PageHeaderTitleNavigation title="Jahrescheckliste" />

        <BaseFilter filterId="cb88e24e-0838-440c-98b8-30ebe28ef86e" :metadata="searchMenu" @onFilter="search"
            :isCustomerSearch="false"  isCache immidiateSearch :defaultOptions="defaultOptionsData"
            :configFilter="configFilter" />
        <GhostLoading v-if="loadingList" type="table" :config="{ table: { rows: 10 } }" />
        <div v-else>
            <div class="box__container">
                <div class="box__title">Jahreschecklisten aller Makler</div>

                <div v-if="auswertungList && auswertungList.length">
                    <Table rowId="maklerNr" :headers="headers" :rows="auswertungList" :rowsPerPage="20"
                        @click-maklerNr="($event) => openBroker($event.maklerNr)" 
                        :headerActions="headerActions"
                        @headerAction-EXCEL="getExcelFile()">
                    </Table>
                </div>
                <NoData v-else />
            </div>
        </div>
    </div>
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from "@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue";
import { PageHeaderSimpleAction, PageHeaderDownloadLinkHrefAction } from "@/components/core/header-title-navigation/page-header-utils";
import { mapGetters } from 'vuex';
import NoData from '@/components/core/NoData.vue';
import Table from "@/components/table2/Table.vue";
import { TextColumn, PillColumn, DateColumn } from "@/components/table2/table_util.js";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import MAKLER_ANTRAG_TYPES from "@/store/maklerAntrag/types";
import CORE_TYPES from "@/store/core/types";
import BaseFilter from '@/components/core/BaseFilter.vue';

export default {
    components: {
        PageHeaderTitleNavigation,
        OptionMenu,
        NoData,
        Table,
        GhostLoading,
        BaseFilter,
        PageHeaderSimpleAction,
        PageHeaderDownloadLinkHrefAction,
    },
    data() {
        return {
            loading: false,
            loadingList: false,
            jahr: new Date().getFullYear(),
            headers: {
                lockedLeft: [,
                    TextColumn("jahr", "Jahr"),
                ],
                center: [
                    TextColumn("maklerNr", "Maklernummer").makeAlwaysVisible().makeLink(),
                    TextColumn("maklerName", "Makler").makeAlwaysVisible()
                ],
                lockedRight: [
                    PillColumn("statusOK", "Prüfungstatus"),
                    DateColumn("dateUpdated", "Geändert am"),
                    TextColumn("fehlerText", "Notizen")
                ],
            },
            configFilter: {
                hideFirstColumn: true,
            },
            defaultOptionsData: {
                jahr: {
                    value: new Date().getFullYear(),
                }
            }
        }
    },
    computed: {
        ...mapGetters({
            auswertung: MAKLER_ANTRAG_TYPES.GETTERS.GET_AUSWERTUNG,
        }),
        auswertungList() {
            return this.auswertung || [];
        },
        headerActions() {
            return [
                PageHeaderSimpleAction("EXCEL", "Excel herunterladen")
            ];
        },
        searchMenu() {
            return [
                {
                    type: 'group',
                    key: 'allgemein',
                    label: 'Allgemein',
                    menuItems: [
                        {
                            type: 'text',
                            label: 'Jahr',
                            key: 'jahr',
                            emptyDenied: true,
                        },
                        {
                            type: 'text',
                            label: 'Maklername / -nummer',
                            key: 'makler',
                        },
                        {
                            type: 'combobox',
                            key: 'pruefungsstatus',
                            label: 'Prüfungsstatus',
                            comboOptions: [
                                {
                                    label: 'Fehlerhaft',
                                    value: 'FEHLERHAFT',
                                },
                                {
                                    label: 'Fehlend',
                                    value: 'FEHLEND',
                                },
                                {
                                    label: 'Geprüft',
                                    value: 'GEPRUEFT',
                                },
                            ]
                        },
                    ]
                }
            ]
        },
    },
    methods: {
        async getExcelFile() {
            this.loading = true;
            await this.$store.dispatch(MAKLER_ANTRAG_TYPES.ACTIONS.GET_AUSWERTUNG_AS_EXCEL, { 'jahr': this.jahr });
            this.loading = false;
        },
        openBroker(brokerId) {
            this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_BROKER_NEW_TAB, { brokerId, path: "/intern/jahrescheckliste" })
        },
        search(filterParams) {
            const queryParams = {};
            this.jahr = filterParams.jahr;
            filterParams.forEach(param => {
                queryParams[param.key] = param.value;
                if(param.key === 'jahr') {
                    this.jahr = param.value;
                }
            });
            this.loadingList = true;
            this.$store.dispatch(MAKLER_ANTRAG_TYPES.ACTIONS.GET_AUSWERTUNG, queryParams).finally(() => { this.loadingList = false });
        }
    }
}
</script>

<style>
.combo-box__container {
    width: fit-content;
}

.fc-select {
    margin: 0 15px 0 0;
}
</style>