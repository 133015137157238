var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "Kundenzugang und Kunden-App versenden",
          actions: _vm.actions
        },
        on: {
          "action-CONFIRM": function($event) {
            return _vm.confirm()
          },
          "action-SEE_LATER": function($event) {
            return _vm.spaterAnzeigen()
          },
          "action-DECLINE": function($event) {
            return _vm.decline()
          }
        }
      }),
      _c("div", { staticClass: "box__container" }, [
        _c("p", [
          _vm._v(
            " Erleichtern Sie sich den Arbeitsaufwand beim Verschicken der Kundenzugänge an Ihre Kunden. Mit dieser Aktion ermittelt das System "
          ),
          _c("strong", [_vm._v("jeden")]),
          _vm._v(
            " Abend diejenigen Ihrer Kunden (mit eingetragener Mailadresse und Handynummer), die sich noch nie in den Kundenzugang bzw. Kunden-App eingeloggt haben und schickt diesen dann einmalig eine E-Mail mit einem Link zur Homepage "
          ),
          _c("strong", [_vm._v(_vm._s(_vm.appNameDefault))]),
          _vm._v(" und zum "),
          _c("strong", [_vm._v("KundenLogin")]),
          _vm._v(
            ". Legen Sie einen Neukunden an oder ergänzen Sie die persönlichen Daten des Kunden um die Mailadresse sowie die Handynummer, und Sie brauchen nicht mehr Ihren Kunden einzeln den Kundenzugang zuzuschicken. Dies geschieht dann automatisch über das System. "
          ),
          _c("strong", [_vm._v("Achtung:")]),
          _vm._v(
            " Wenn Sie über Ihre eigene Homepage den Kundenlogin bzw. auch die eigene Kundenapp in Ihrem Look-and-feel Ihren Kunden anbieten, bitten wir Sie den Text der E-Mail unter dem Reiter "
          ),
          _c("strong", [
            _vm._v("Daten/Einstellungen/Einstellungen E-Mailtexte")
          ]),
          _vm._v(" und der Vorlage "),
          _c("strong", [_vm._v("Mobiler Zugang")]),
          _vm._v(
            " bezüglich Ihre Homepage und Kundenzugang-URL anzupassen. Die App wird automatisch angepasst. "
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }