var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "Courtagestatistik der letzten 12 Monate",
          actions: _vm.headerActions
        }
      }),
      _c("BaseFilter", {
        attrs: {
          filterId: "10240c9b-ac0b-4e07-8cb5-04d6673b6c11",
          title: "Investmentabschlüsse zusätliche Spalten anzeigen",
          metadata: _vm.metadataFInvest,
          defaultOptions: _vm.defaultFInvest,
          configFilter: _vm.configFInvest,
          openFilterOnMount: false,
          showSaveButton: true
        },
        on: { onFilter: _vm.searchInvest }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.loadedI > 0 && _vm.rowsI.length
            ? _c(
                "div",
                [
                  _c("Table", {
                    attrs: {
                      title: "Investmentabschlüsse",
                      headers: _vm.headersI,
                      rows: _vm.rowsI,
                      rowsPerPage: 25,
                      exportConfig: { roottext: "Investmentabschlüsse" }
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.loadedI <= 0
            ? _c("GhostLoading", [_c("Block", { attrs: { height: "100" } })], 1)
            : _c("NoData", {
                attrs: { content: "Keine Informationen", noIcon: true }
              })
        ],
        1
      ),
      _c("BaseFilter", {
        attrs: {
          filterId: "b72914f7-e5f4-4bbc-9d73-cc97d1c1ed6d",
          title: "Versicherungscourtagen zusätzliche Spalten anzeigen",
          metadata: _vm.metadataFVers,
          defaultOptions: _vm.defaultFVers,
          configFilter: _vm.configFVers,
          openFilterOnMount: false,
          showSaveButton: true
        },
        on: { onFilter: _vm.searchVers }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.loadedV > 0 && _vm.rowsV.length
            ? _c(
                "div",
                [
                  _c("Table", {
                    attrs: {
                      title: "Versicherungscourtagen",
                      headers: _vm.headersV,
                      rows: _vm.rowsV,
                      rowsPerPage: 25,
                      exportConfig: { roottext: "Versicherungscourtagen" }
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.loadedV <= 0
            ? _c("GhostLoading", [_c("Block", { attrs: { height: "100" } })], 1)
            : _c("NoData", {
                attrs: { content: "Keine Informationen", noIcon: true }
              })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }