<template>
  <div>
    <DragnDropArea 
      @files="dropFiles"
      hoverText="Datei hier ablegen"
      :disabled="isDisabled || !hochladen"
    >
      <Table
        :title="title"
        :headers="headers"
        :rows="rows"
        hidePagination
        @action-DELETE="deleteAttachment"
      >
        <template #name="row">
          <DownloadLink
            :title="row.dateiname || row.filename"
            :filename="row.fileName || row.filename"
            downloadServicePath="/get_simple_file"
            :queryParams="{
              fileId: row.id,
            }"
          />
        </template>
      </Table>

      <div class="mt-3">
        <BaseFileSelect
          v-if="hochladen"
          :disabled="isDisabled"
          :accept="acceptFileTypes"
          @files="dropFiles"
        >
          Datei hochladen
        </BaseFileSelect>
        

        <BaseButton
          v-if="dokumentenarchiv"
          :disabled="isDisabled"
          label="Dokumentenarchiv"
          @click="handleAddDocuments"
        />
      </div>
    </DragnDropArea>
  </div>
</template>

<script>
import ANTRAG_TYPES from "@/store/antrag/types";
import BaseButton from "@/components/core/BaseButton.vue";
import Table from "@/components/table2/Table.vue";
import DownloadLink from "@/components/core/download/DownloadLink.vue";
import { mapGetters } from "vuex";
import {
  SlotColumn,
  ActionColumn,
  SimpleAction,
} from "@/components/table2/table_util";
import DragnDropArea from "@/components/fileUpload/DragnDropArea.vue";
import BaseFileSelect from "@/components/fileUpload/BaseFileSelect.vue";
import axios from 'axios';
import { uploadFileChunked } from '@/helpers/upload-helper'

export default {
  components: {
    BaseButton,
    Table,
    DownloadLink,
    DragnDropArea,
    BaseFileSelect,
  },
  props: {
    hochladen: {
      type: Boolean,
      default: false,
    },
    dokumentenarchiv: {
      type: Boolean,
      default: false,
    },
    componentId: {},
    antragId: "",
    antragData: {},
    disabled: {
      type: Boolean,
      default: false,
    },
    antrag: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: "Anhänge",
    },
    //"image/png, image/jpeg, .pdf"
    acceptFileTypes: {
      type: String,
      default: "*/*"
    },  
  },
  computed: {
    ...mapGetters({
      attachmentsTemp: ANTRAG_TYPES.GETTERS.GET_ATTACHMENTS,
    }),
    isDisabled() {
      return this.disabled || this.isDataReadOnly || this.isAlreadySigned
    },
    isAlreadySigned() {
      return this.antragData && this.antragData['isSigniert'] === '1';
    },
    isDataReadOnly() {
      return !!(this.antragData && this.antragData['READ_ONLY']);
    },
    attachments() {
      return this.antrag.attachments || []
    },
    rows() {
      const actions = [SimpleAction("DELETE", "PhTrash", "Löschen")];
      return this.attachments.map(row => ({
        ...row,
        actions,
      }));
    },
    headers() {
      return {
        lockedLeft: [SlotColumn("name", "Dateiname", 200, 1)],
        lockedRight: [ActionColumn("actions", "")],
      };
    },
  },
  methods: {
    async dropFiles(files) {
        const tempFileIds = []
        const result = files.map(file => {
            return new Promise((resolve, reject) => {
                uploadFileChunked(file).then(tempFileId => {
                  tempFileIds.push(tempFileId)
                  resolve()
                }).catch(() => reject())
            });
        });

        Promise.all(result).then(() => {
          this.addAttachments(tempFileIds)
        })
    },
    async addAttachments(tempFileIds) {
      if (tempFileIds && tempFileIds.length) {
        const response = await this.$store.dispatch(
          ANTRAG_TYPES.ACTIONS.SAVE_ATTACHMENTS, { antragsnrIntern: this.antragData.antragsnrIntern, tempFileIds, antragId: this.antragId}
        )

        /**
         * We need HIDDEN_DATA because we are calling the getAntragFile from the old MSC. If we don't change any data in the antrag
         * it does not update the PDF. I also had to add the 'INPUT_HIDDEN_DATA' in the ÄnderungsformularServlet. Forms using
         * our saving process should not have that problem.
         */
        this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_ANTRAG_DATA, {id: this.antragId, data: { HIDDEN_DATA: Date.now() }})

        return response
      }
    },
    deleteAttachment(row) {
      axios.delete(`${process.env.VUE_APP_API}/antraegeService/attachment`, { defaultSpinner: true, params: {antragsnrIntern: this.antragData.antragsnrIntern, fileId: row.id}})
        .then(response => {
          if (response && response.status === 200) {
            this.$store.commit(ANTRAG_TYPES.MUTATIONS.REMOVE_ATTACHMENT, { antragId: this.antragId, id: row.id});
            this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_ANTRAG_DATA, {id: this.antragId, data: { HIDDEN_DATA: Date.now() }})
          }
      })
    },
    handleAddDocuments() {
      this.navigateTo("antrag-dokumentenarchiv");
    },
    navigateTo(event) {
      this.$router.push({
        path: `/beratung/formulare/antrag/${this.$route.params.lagerstelle}/${this.$route.params.antragsName}/${this.$route.params.step}/${event}/`,
        query: {
          componentId: this.componentId,
          antragId: this.antragId,
          updateDataId: 'tempids',
        },
      });
    },
  },
  mounted() {
    // save attachments that were added from documentsarchive store
    if (this.attachmentsTemp && this.attachmentsTemp.length) {
        this.addAttachments(this.attachmentsTemp.map(a => a.id))
        .then(() => this.$store.commit(ANTRAG_TYPES.MUTATIONS.RESET_ATTACHMENTS))
    }
  },
};
</script>
