import actions from './actions';
import mutations from './mutations';
import getters from './getters';

/**
 * mutations: synchronous operation responsible for change the state
 * actions: asynchronous operation responsible for call external resources and commit mutations 
 */
export function getInitialState() {
  return {
    investmentAdviceOrder: {
      //  Mit Beratung
      beratungsmappe: undefined,
      posBesitzerId: undefined,      
      orderList: undefined,
      lagerstelle: '',
      depotnr: '',
    },
    investmentAdviceDepoteroeffnung: {
      beratungsmappe: undefined,
      targetAntrag: undefined,
      posBesitzerId: undefined,
      posBesitzerIdVerkauf: undefined,
    },
    investmentAdviceList: [],
    investmentAdvice: '-1',
    lastVisitStep: {},
    info: new Array(),
    order: {},
    parameters: { 
      MAIN: {},
      MYDEPOT: {},
      MYDEPOT_EINMALANLAGE: {},
      MYDEPOT_SPARPLAN: {},
      WK: {},
      FAVORITES: {},
      FONDSFINDER: {},
      OPTIMIZATION: {},
      BENCHMARK: {},
      OUTCOME: {},
      PDF: {},
      COURTAGE: {},
      ORDER: {}
    },
    ergebnisBacktestAdvice: {
      data: {},
      status: 'none',
      loaded: false,  },
    ergebnisBacktestDetails: {
      data: {},
      status: 'none',
      loaded: false,  },
    ergebnisBacktestActual: {
      data: {},
      status: 'none',
      loaded: false,  },
    ergebnisDiagramme: {},
    ergebnisDepotoptimierung: {
      data: {},
      status: 'none',
      loaded: false,  },
    expiredParameters: '',
    expiredPositions: '',
    listFilters: {},
    message: {
      show: false,
      title: '',
      text: '',
    }
  }
}

export default {
  state: {
    ...getInitialState()
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  },
  getters: {
    ...getters
  }
}