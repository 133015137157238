<template>
  <div>
    <PageHeaderTitleNavigation
      :title="$appNavigation.currentMenu.label"
      :subtitle="fvp.description + ' ' + fvp.create"
      :actions="actions"
      @action-PDF="fvpAction('pdf')"
      @action-XLS="fvpAction('xls')"
      @action-PDF_MAIL="fvpAction('mail')"
      @action-AN_EMP="fvpAction('an_emp')"
      @action-ORDER="fvpAction('order')"
      @action-EDIT_DESCRIPTION="editFVDesc">
    </PageHeaderTitleNavigation>

    <BoxContainer v-if="steps && isSmallOrMediumScreen">
      <Stepper3
        :stepperName="'fondsvergleich'"
        :selectedStepKey="selectedStepKey"
        :selectedSubstepKey="selectedSubstepKey"
        :stepperService="steps"
        @setStep="setStepByKeyOrObject($event)"
        @setSubstep="setStepByKeyOrObject($event)"
      ></Stepper3>
    </BoxContainer>
    
    <BoxContainer v-if="selected && !isSmallOrMediumScreen">
      <Table
        title="Ausgewählte Investmentfonds"
        :headers="selectedFundsHeaders"
        :mobileConfig="{title: 'Ausgewählte Investmentfonds', headers: ['fondsname', 'isin', 'actions'], disableClickRow: true}"
        :rows="fundsRows"
				:headerActions="tableActions"
        v-model="delSelection"
        rowId="isin"
        hidePagination
        @action-DELETE="delFunds($event)"
        @headerAction="handleHeaderAction"
      >
        <template #fondsname="{fondsname,isin}">
          <a @click="gotoFondsinfo(isin)">{{fondsname}}</a>
        </template>
      </Table>
    </BoxContainer>

    <ContentWithStepper :stepperCollapsed="stepperCollapsed"  v-if="!isSmallOrMediumScreen">
        <template #stepper>
            <BoxContainer>
                <VerticalStepper
                    :stepType="'tab'"
                    :visible="true"
                    :stepperName="'fondsvergleich'"
                    :selectedStepKey="selectedStepKey"
                    :selectedSubstepKey="selectedSubstepKey"
                    :stepperService="steps"
                    :showCollapse="true"
                    @setStep="setStep($event)"
                    @setSubstep="setStep($event)"
                    @collapsed="stepperCollapsed = $event"
                ></VerticalStepper>
            </BoxContainer>
        </template>
        <template #content>
          <CompareFunds ref="cmpFunds"
              :colorsOrder="colorsOrder"
              :setColorsOrder="setColorsOrder"
              :stepKey="selected"
              :settings="config" 
              @setStep="setStep($event)"
              @done="doneStep($event)"></CompareFunds>
        </template>
    </ContentWithStepper>

    <BaseModal
      ref="editBezeichnung"
      modalTitle="Fondsvergleich Bezeichnung"
      labelButtonConfirm="Übernehmen"
      labelButtonCancel="Abbrechen"
      size="sm"
      @onConfirmButton="onChangeDesc()">

      <InputField
        label=""
        v-model="fvp.description">
      </InputField>
    </BaseModal>
    <BaseModal
      ref="modalRef"
      modalTitle="Bitte einen Fondsvergleich starten" :showConfirmButton="false" 
      labelButtonCancel="Schließen"
      :isModalAsFullPage="false"
    >
        <div></div>
    </BaseModal>

    <BaseModal
        ref="editModal"
        :modalTitle="editModalTitle"
        size="lg"
        :showCancelButton="false"
        @onCloseButton="onCloseEditModal()"
        :actions="baseModalActions"
        :showConfirmButton="false"
        :autoCloseOnRouteNavigation="false"
        @action="handleModalActions">

        <CompareFunds ref="cmpFunds"
            :colorsOrder="colorsOrder"
            :setColorsOrder="setColorsOrder"
            :stepKey="selected"
            :settings="config" 
            @setStep="setStep($event)"
            @done="doneStep($event)"></CompareFunds>

    </BaseModal>

  </div>
</template>

<script>
import CompareFunds from '@/components/beratung/CompareFunds.vue';
import ContentWithStepper from '@/components/core/ContentWithStepper.vue';
import BoxContainer from '@/components/core/BoxContainer.vue';
import VerticalStepper from '@/components/vertical-stepper/VerticalStepper.vue';
import { StepperMediator } from '@/components/stepper/utils';
import { mapGetters } from 'vuex';
import FONDSVERGLEICH_TYPES from "@/store/fondsvergleich/types";
import CORE_TYPES from '@/store/core/types';
import InputField from '@/components/core/forms/InputField.vue';
import BaseModal from "@/components/core/BaseModal.vue";
import Stepper3 from '@/components/stepper/Stepper3.vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn, SlotColumn, ActionColumn, SimpleAction} from "@/components/table2/table_util.js";
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';
import ROLES, { VIEW_ROLES } from '@/router/roles';
import { BaseModalSimpleAction } from '@/components/core/base-modal-actions/base-modal-actions-utils';
import EVENT_BUS, { FONDSVERGLEICH_WEITER, FONDSVERGLEICH_RESET_VERGLEICH, FONDSVERGLEICH_GET_VERGLEICH, FONDSVERGLEICH_DO_ACTION } from '@/event-bus';

const FONDSVERGLEICH_PDF_ERZEUGEN = "pdf";
const FONDSVERGLEICH_XLS_ERZEUGEN = "xls";
const FONDSVERGLEICH_PDF_MAIL = "mail";


export default {
  components: {
    CompareFunds,
    ContentWithStepper,
    BoxContainer,
    VerticalStepper,
    InputField,
    BaseModal,
    Stepper3,
    Table,
    OptionMenu,
    PageHeaderTitleNavigation,
  },
  data() {
    return {
      currentSelectedTab: null,
      selectedSubstepKey: '',
      currentSubstep: null,
      stepperCollapsed: false,
      selectedFundsHeaders: {
        lockedLeft: [
            SlotColumn("fondsname", "Wertpapier", 200).makeAlwaysVisible(),
        ],
        center: [
            TextColumn("isin", "ISIN", 150),
        ],
        lockedRight: [
            ActionColumn("actions"),
        ],
      },
      colorsOrder: {},
      init: true,
      delSelection: [],
      loadingStep: false,
    }
  },
  computed: {
    ...mapGetters({
      session: FONDSVERGLEICH_TYPES.GETTERS.ACT,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      funds: FONDSVERGLEICH_TYPES.GETTERS.GET_FUNDS,
      isSmallScreen: CORE_TYPES.GETTERS.IS_SMALL_SCREEN,
      screenSize: CORE_TYPES.GETTERS.SCREEN_WIDTH,      
    }),
    indices() {
      return this.session?.settings?.indices || [];
    },
    ergebnisLoading() {
      return this.session?.ergebnisLoading || {};
    },
    ergebnisLoadingActive() {
      return Object.values(this.ergebnisLoading).some(v => v === true);
    },
    editModalTitle() {
      return this.currentSubstep?.title || this.currentSelectedTab?.label || '';
    },
    baseModalActions() {
      const actions =  [];

      if (this.currentSelectedTab?.stepKey =='FILTER') {
        actions.push(BaseModalSimpleAction(FONDSVERGLEICH_WEITER, 'Weiter').withPrimary(() => true));
      }

      if (this.currentSelectedTab?.stepKey =='EINSTELLUNG') {
        actions.push(BaseModalSimpleAction(FONDSVERGLEICH_GET_VERGLEICH, `Vergleich starten`)
          .withLoading(() => this.ergebnisLoadingActive)
          .withDisabled(() => this.ergebnisLoadingActive)
          .withPrimary(() => true));
      }

      if (this.currentSelectedTab?.stepKey =='EINSTELLUNG') {
        actions.push(BaseModalSimpleAction(FONDSVERGLEICH_RESET_VERGLEICH, 'Vergleich zurückstellen')
          .withPrimary(() => false));
      }

      actions.push(BaseModalSimpleAction(FONDSVERGLEICH_PDF_ERZEUGEN, 'PDF erzeugen'));
      actions.push(BaseModalSimpleAction(FONDSVERGLEICH_XLS_ERZEUGEN, 'XLS erzeugen'));
      actions.push(BaseModalSimpleAction(FONDSVERGLEICH_PDF_MAIL, 'PDF-Mail'));
        
      return actions;
    },       
    isSmallOrMediumScreen() {
      return this.isSmallScreen || this.isMediumScreen;
    },    
    isMediumScreen() {
      return this.screenSize === 'md';
    },  
    fundsRows() {
      return (this.funds || []).map(fund => ({
        ...fund,
        actions: [SimpleAction('DELETE', 'PhTrash', 'entfernen')],
      }));
    },
    config() {
      return this.session?.settings ;
    },
    hasVergleich() {
      return this.session?.hasVergleich ;
    },
    fvp() {
      return this.session?.fvp ;
    },
    steps() {
      const substeps = [];
      if (this.config?.showDaten) {
        substeps.push( {substepKey: 'STAMMDATEN', title:'Stammdaten'})
      }
      if (this.config?.showWertEntw) {
        substeps.push( {substepKey: 'WERTENTICK', title:'Wertentwicklung'})
      }
      if (this.config?.showRisiko) {
        substeps.push( {substepKey: 'RENDITERISIKO', title:'Rendite-Risiko'})
      }

      let result = [];

      if (this.isSmallOrMediumScreen) {
        result = [{stepKey: 'AUSGEWAEHLTE_INVESTMENTFONDS', label:'Ausgewählte Investmentfonds', current: false, totalProgress: 1}];
      }

      result = [ 
          ...result,
          {stepKey: 'FILTER', label:'Fondsauswahl', current: true, totalProgress: 1},
          {stepKey: 'EINSTELLUNG', label:'Einstellungen', current: false, totalProgress: 2},
          {stepKey: 'ERGEBNIS', label:'Ergebnis', current: false, totalProgress: 3, substeps: substeps },
      ]
      return new StepperMediator(result)
    },
    selectedStepKey() {
      return this.currentSelectedTab?.stepKey ;
    },
    selected() {
      return this.selectedSubstepKey || this.currentSelectedTab?.stepKey ;
    },
    colors() {
      return this.config && this.config.WertEntwColors || []
    },
    actions() {
      let result = [];
      if (!this.isSmallOrMediumScreen) {
        result.push(
          new PageHeaderSimpleAction('PDF', 'PDF erzeugen'),
          new PageHeaderSimpleAction('XLS', 'XLS erzeugen'),
          new PageHeaderSimpleAction('PDF_MAIL', 'PDF-Mail')
        );
      }

      result.push(
        new PageHeaderSimpleAction('AN_EMP', 'Anlageempfehlung').withVisible(() => !this.isCusmomerOnly),
        new PageHeaderSimpleAction('ORDER', 'Wertpapierorder').withVisible(() => !this.isBrokerView && !this.isCusmomerOnly),
        new PageHeaderSimpleAction('EDIT_DESCRIPTION', 'Beschreibung bearbeiten')
      );

      return result;
    },
    tableActions() {
      return [
        PageHeaderSimpleAction('DEPOT', 'Depotbestand übernehmen').withVisible(() => this.isCusmomer),
        PageHeaderSimpleAction('DEL_SELECTION', 'Auswahl entfernen'),
      ]

    },
    isBrokerView() {
      return this.hasRoles([
        VIEW_ROLES.VIEW_BROKER])
    },
    isCusmomerOnly() {
      return this.hasRoles([
        VIEW_ROLES.VIEW_CUSTOMER_ONLY ])
    },
    isCusmomer() {
      return this.hasRoles([
        VIEW_ROLES.VIEW_CUSTOMER_AS_BROKER, VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS, VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS  ])
    },
  },
  created() {
    let stepKey = 'FILTER';
    if (this.session) {
      if (this.session.stepKey) {
        stepKey = this.session.stepKey;
        this.selectedSubstepKey = this.session.substepKey;
      }
    }
    this.currentSelectedTab = this.steps.getCurrentStep(stepKey);
    
  },
  async mounted() {
    const fondsLength = await this.$store.dispatch(FONDSVERGLEICH_TYPES.ACTIONS.GET_FONDS, { id: this.fvp.id });
    if (fondsLength > 0) {
      this.setStep('EINSTELLUNG');
    }
  },
  methods: {
    onCloseEditModal() {
      this.showingEditModal = false;
    },    
    handleModalActions(actionData) {
      switch (actionData.key) {
        case FONDSVERGLEICH_WEITER:
          EVENT_BUS.$emit(FONDSVERGLEICH_WEITER);
          break;

        case FONDSVERGLEICH_RESET_VERGLEICH:
          EVENT_BUS.$emit(FONDSVERGLEICH_RESET_VERGLEICH);
          break;

        case FONDSVERGLEICH_GET_VERGLEICH:
          EVENT_BUS.$emit(FONDSVERGLEICH_GET_VERGLEICH);
          break;

        case FONDSVERGLEICH_PDF_ERZEUGEN:
        case FONDSVERGLEICH_XLS_ERZEUGEN:
        case FONDSVERGLEICH_PDF_MAIL:
          if (!this.hasVergleich && { pdf: true, mail: true, xls: true }[actionData.key]) {
            this.$refs.modalRef.open();
          } else {
            EVENT_BUS.$emit(FONDSVERGLEICH_DO_ACTION, {fvp: this.fvp, action: actionData.key});
          }
          break;
      }

    },   
    async setStepByKeyOrObject(step) {
      await this.setStep(step);

      if (this.isSmallOrMediumScreen) {
        this.$refs.editModal.open();
      }      
    },
    async setStep(step) {
      let stepKey = '', substepKey = '';
      if (typeof step === 'string' ) {
        stepKey = step;
        this.currentSelectedTab = this.steps.getCurrentStep(stepKey);
      } else {
        stepKey = step.stepKey;
        substepKey = step.substepKey;
        this.currentSelectedTab = this.steps.getCurrentStep(stepKey);
      }

      // select first substep if there is no substep selected and there are substeps available
      if (!substepKey && this.currentSelectedTab.substeps) {
        substepKey = this.currentSelectedTab.substeps[0].substepKey;
      }

      this.selectedSubstepKey = substepKey;
      this.currentSubstep = this.currentSelectedTab?.substeps?.find?.(substep => substep.substepKey === substepKey) || null;

      this.$store.commit(FONDSVERGLEICH_TYPES.MUTATIONS.SET_ACT, {
        stepKey: stepKey, 
        substepKey: substepKey
      } );
      const base = this.$route.path.substr(0, this.$route.path.indexOf('fondsvergleich'));
      this.$updateCurrentBreadcrumb({
        breadcrumb: this.currentSelectedTab.label,
       fullPath:  base + 'fondsvergleich'});
      
    },
    delFunds(row) {
      this.$refs.cmpFunds?.delFunds(row);
    },
    handleHeaderAction({ key, value }) {
      if (this.$refs.cmpFunds?.handleHeaderAction({ key, selection: this.delSelection }) ) {
        this.delSelection = [];
      }
    },
    doneStep(stepKey) {
      if ( this.selected === 'EINSTELLUNG') {
        this.setStep(stepKey) ;
      }
    },
    editFVDesc() {
      this.$refs.editBezeichnung.open();
    },
    onChangeDesc() {
      if ( !this.fvp.description) {
        this.fvp.description = 'Unbennant';
      }
      this.$store.commit(FONDSVERGLEICH_TYPES.MUTATIONS.SET_ACT, { fvp: this.fvp });
      if (this.fvp.id) {
        this.$store.dispatch(FONDSVERGLEICH_TYPES.ACTIONS.FVPSAVEDESC, this.fvp );
      }
    },
    fvpAction(key) {
      if (!this.hasVergleich && {pdf: true, mail: true, xls: true}[key]) {
        this.$refs.modalRef.open();
        return;
      }
      switch (key) {
        case 'pdf':
        case 'xls':
        case 'mail':
          // this.$refs.cmpFunds.doAktion(this.fvp, key);
          EVENT_BUS.$emit(FONDSVERGLEICH_DO_ACTION, {fvp: this.fvp, action: key});
          break;
        case 'an_emp':{
            if (!this.funds?.length) {
              return;
            }
            const query = '?adviceId=-1&addwp=' + this.funds.map(f => f.isin).join(',');
            this.$addBreadcrumb({
              label: 'zurück zur Fondsvergleich', 
              breadcrumb: 'Fondsauswahl | ' + this.fvp.description,
              fullPath: this.$route.fullPath,
            });
            this.$router.push({path: '/beratung/anlageempfehlung/details' + query })
          }
          break;
        case 'order':{
            let query = '/beratung/formulare/antrag/WP/wertpapierorder/main?initData=true' ;
            if (this.funds?.length) {
              query += '&kauf=' + this.funds.map(f => f.isin).join(',');
            }
            this.$addBreadcrumb({
              label: 'zurück zum Fondsvergleich', 
              breadcrumb: 'Fondsauswahl | ' + this.fvp.description,
              fullPath: this.$route.fullPath,
            });
            this.$router.push({path: '/beratung/antrag-beratungsmappe-selector?formPath=' + encodeURIComponent(query) })
          }
          break;
      }
    },
    gotoFondsinfo(isin) {
      this.$addBreadcrumb({
        breadcrumb: 'Fondsinfo',
        fullPath: this.$route.fullPath,
      });
      const path = `/shared/vermogensubersicht/fondsinfo/${isin}`;
      this.$router.push({ path });
    },
    setColorsOrder(name) {
      if (this.config && this.config.WertEntwColors) {
        const colors = JSON.parse(JSON.stringify(this.config.WertEntwColors));
        this.colorsOrder[name] = colors[Object.keys(this.colorsOrder).length];
      }
    },
  }
}
</script>

<style scoped>
.ampel-rot, .ampel-gelb, .ampel-gruen, .ampel-none{
 height: 16px;
 width: 16px;
 border-radius: 50%;
}
.ampel-rot{
 background-color: var(--color-danger);
}
.ampel-gelb{
 background-color: var(--color-warning);
}
.ampel-gruen{
 background-color: var(--color-success);
}
.ampel-none{
 background-color: transparent;
}
</style>
