var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { key: _vm.isin, staticClass: "fondsinfo__container" },
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "Fondsinfo",
          subtitle: _vm.fundsName,
          actions: _vm.pageHeaderTitleActions
        },
        on: {
          "action-ADD_FAVORITENLIST": function($event) {
            return _vm.$refs.addToFavoritenlistModal.open()
          }
        }
      }),
      _c("StepperForm", {
        key: _vm.selectedTabKey,
        ref: "stepperForm",
        attrs: {
          stepType: "tab",
          stepperName: "fondsinfo",
          stepperMediator: _vm.tabs,
          selectedStepKey: _vm.selectedTabKey,
          hasFertig: false,
          backNavigationOnClose: ""
        },
        on: {
          "set-step": function($event) {
            return _vm.setTab($event)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "contentTemplate",
            fn: function() {
              return [
                _c(
                  "keep-alive",
                  [
                    _c(_vm.loadTabComponent, {
                      tag: "component",
                      attrs: { selectedTabLabel: _vm.selectedTabLabel }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("AddToFavoritenlistModal", {
        ref: "addToFavoritenlistModal",
        attrs: { fond: { isin: _vm.isin, fondsname: _vm.fundsName } }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }