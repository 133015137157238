<template>
  <div class="broker-overview__container">
    <DashboardPanel
      id="3f6a2d8a-406b-4d4a-85f9-7204579b310a"
      :data="dashboardData"
      :externalConfig="externalWidgetsConfig"
      :isExternalLoading="externalLoading"
      @saved="saveBrokerOverviewConfiguration($event)"
      @restored="saveBrokerOverviewConfiguration($event)"
      @executeAction="handleExecuteAction($event)"
    >
      <template v-if="Object.keys(brokerParagraphsConfiguration).length" #topBox>
        <div class="paragraphs-container">
          <div class="paragraphs--item" v-for="(paragraph, index) in Object.keys(brokerParagraphsConfiguration)" :key="index">
            <span v-if="brokerParagraphsConfiguration[paragraph]" class="paragraphs--item--status color-success"><PhCheck :size="24" /></span>
            <span v-if="!brokerParagraphsConfiguration[paragraph]" class="paragraphs--item--status color-danger"><PhWarning :size="24" /></span>
            <span class="paragraphs--item--text">{{paragraph}}</span>
          </div>
        </div>
      </template>    
      <template #GeburtstageVermittler>
        <GeburtstageVermittler ref="geburtstageVermittler" />
      </template>
    </DashboardPanel>

    <BaseModal ref="fkEventsModal" labelButtonCancel="Schließen" :showConfirmButton="false">
      <template v-slot:modalTitle>
        {{fkEventsTitle}}
      </template>
      <div>
        <EventsFkOverview />
      </div>
    </BaseModal>
  </div>
</template>


<script>
import CORE_TYPES from '@/store/core/types'

import { mapGetters } from 'vuex';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import DASHBOARD_TYPES from '@/components/dashboard/store/types';
import MENU_TYPES from '@/store/menu/types'

import { BROKER_LEVEL_ROLES, EMPLOYEE_ROLES, ROLES, BROKER_STRUCTURE_ROLES, } from '@/router/roles'
import DashboardPanel from '@/components/dashboard/DashboardPanel.vue';
import GeburtstageVermittler from '@/components/broker/GeburtstageVermittler.vue';
import { PhCheck, PhWarning } from 'phosphor-vue';
import BaseModal from '@/components/core/BaseModal.vue';
import EventsFkOverview from '@/views/home/EventsFkOverview.vue';
import BROKERDATA_TYPES from '@/store/brokerData/types'

const MAP_WIDGET_NAME_TO_PARAMETER_NAME = {
  'StornoWarnungTableUebersicht': 'MAKLER_UEBERSICHT_WARNUNGEN',
  'MscNewsList': 'MAKLER_UEBERSICHT_NEWS',
  'TermineList': 'MAKLER_UEBERSICHT_KALENDER_HEUTE',
  'TicketsNachrichtenCard': 'MAKLER_UEBERSICHT_TICKET',
  'GeburtstageKunden': 'MAKLER_UEBERSICHT_GEB_TAGE',
  'GeburtstageVermittler': 'MAKLER_UEBERSICHT_GEB_TAGE_VERMITTLER',
  'TransaktionenVerlauf': 'MAKLER_UEBERSICHT_TRANSAKTIONEN',
  'UeberUnterschritteneLimits': 'MAKLER_UEBERSICHT_LIMITS',
  'ErfolgreicheVermittlerwechsel': 'MAKLER_UEBERSICHT_VERMITTLERWECHSEL',
  'FkEvents': 'FK_EVENTS',
};

const MAP_GEBURTSTAGEVERMITTLER_TITLE = {
  'Ja': 'Geburtstage der gesamten Vermittlerstruktur (innerhalb 7 Tagen)',
  'Nein': 'Geburtstage Ansprechpartner (in den nächsten 7 Tagen)',
  'Nur Hauptpersonen': 'Geburtstage der Vermittlerstruktur (innerhalb 7 Tagen)',
};


export default {
  mixins: [],
  data() {
    return {
      externalLoading: false,
    }
  },
  computed: {
    ...mapGetters({
      isTestBroker: CORE_TYPES.GETTERS.IS_TEST_BROKER,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      isFA: CORE_TYPES.GETTERS.IS_FA,
      hatVertrag: CORE_TYPES.GETTERS.HAT_VERTRAG,
      brokerOverviewConfiguration: DASHBOARD_TYPES.GETTERS.BROKER_OVERVIEW_CONFIGURATION,
      brokerParagraphsConfiguration: DASHBOARD_TYPES.GETTERS.BROKER_PARAGRAPHS_CONFIGURATION,
      isOptionMenuPathVisibile: MENU_TYPES.GETTERS.IS_OPTION_MENU_PATH_VISIBLE,
    }),
    externalWidgetsConfig() {
      const { brokerOverviewConfiguration, } = this;
      if(!brokerOverviewConfiguration) return {};

      const mapParameterNameToWidgetName = Object.keys(MAP_WIDGET_NAME_TO_PARAMETER_NAME)
        .reduce((acc, widgetName) => ({ ...acc, [MAP_WIDGET_NAME_TO_PARAMETER_NAME[widgetName]]: widgetName, }), {});

      return Object.keys(brokerOverviewConfiguration)
        .filter(paramName => paramName in mapParameterNameToWidgetName)
        .map(paramName => ({
          name: mapParameterNameToWidgetName[paramName],
          removed: brokerOverviewConfiguration[paramName] === 'Nein',
        }), []);
    },
    hasLastCustomersOpenedRoles() {
      return this.hasRoles([[BROKER_LEVEL_ROLES.ZUGRIFF_KUNDEN, EMPLOYEE_ROLES.ZUGRIFF_KUNDEN]]);
    },
    hasGeburtstageKundenRoles() {
      return this.hasRoles([[BROKER_LEVEL_ROLES.ZUGRIFF_KUNDEN, EMPLOYEE_ROLES.ZUGRIFF_KUNDEN]]);
    },
    hasStornoRoles() {
      return this.hasRoles([[EMPLOYEE_ROLES.STORNOS_WARNUNGEN_BESTAETIGEN, BROKER_LEVEL_ROLES.ZUGRIFF_KUNDEN, EMPLOYEE_ROLES.ZUGRIFF_KUNDEN]])
    },
    hasTermineAccess() {
      return this.hasRoles([[EMPLOYEE_ROLES.ZUGRIFF_TERMINE, BROKER_LEVEL_ROLES.ZUGRIFF_KUNDEN, EMPLOYEE_ROLES.ZUGRIFF_KUNDEN]])
    },
    hasStatisticsRole() {
      return this.hasRoles([[EMPLOYEE_ROLES.ZUGRIFF_STATISTIK, BROKER_LEVEL_ROLES.ZUGRIFF_STATISTIK]])
    },
    hasTransaktionenVerlaufRole() {
      return this.hasRoles([[BROKER_LEVEL_ROLES.ZUGRIFF_TRANSAKTIONEN_VERLAUF, BROKER_LEVEL_ROLES.ZUGRIFF_KUNDEN, EMPLOYEE_ROLES.ZUGRIFF_KUNDEN]]);
    },
    hasInvestmentFondsAccess() {
      return this.hasRoles([[BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS, BROKER_LEVEL_ROLES.ZUGRIFF_KUNDEN, EMPLOYEE_ROLES.ZUGRIFF_KUNDEN]]);
    },
    hasOpenSignatureRoles() {
      return this.hasRoles([[BROKER_LEVEL_ROLES.ZUGRIFF_KUNDEN, EMPLOYEE_ROLES.ZUGRIFF_KUNDEN]]);
    },
    isFKEventsVisible() {
      const fullPath = this.$router.match({ name: 'fk-events'})?.fullPath || ''
      const isVisible = this.isOptionMenuPathVisibile(fullPath)

      return isVisible && this.hatVertrag && this.hasRoles([ROLES.SHOW_EVENTS]);
    },
    dashboardData() {
      return {
        widgets: [
          {
            name: 'LastCustomersOpened',
            title: 'Zuletzt geöffnete Kunden',
            component: () => import('@/components/customerSearch/LastCustomersOpenedCarousel.vue'),
            props: {
              showTitle: false,
            },
            visible: () => this.hasLastCustomersOpenedRoles,
          },
          {
            name: 'StornoWarnungTableUebersicht',
            title: 'Wichtige Warnungen, Stornos, Informationen',
            component: () => import('@/components/stornoWarnungen/StornoWarnungTableUebersicht.vue'),
            props: {
              showTitle: false,
              maxCount: 3,
            },
            visible: () => this.hasStornoRoles,
            removable: () => false,
            overrideDefaultAction: true,
          },
          {
            name: 'MscNewsList',
            title: 'News',
            component: () => import('@/components/mscnews/MscNewsList.vue'),
            props: {
              lastNews: true,
            },
            overrideDefaultAction: true,
          },
          {
            name: 'TermineList',
            title: 'Termine / Aufgaben',
            component: () => import('@/components/calendar/TermineList.vue'),
            props: {
              showTitle: false,
            },
            visible: () => this.hasTermineAccess,
            overrideDefaultAction: true,
          },
          {
            name: 'TicketsNachrichtenCard',
            title: 'Nachrichten',
            component: () => import('@/components/communication/TicketsNachrichtenCard.vue'),
            props: {
              showTitle: false,
            },
            overrideDefaultAction: true,
          },
          {
            name: 'GeburtstageKunden',
            title: 'Geburtstage Kunden (in den nächsten 7 Tagen)',
            component: () => import('@/components/customer/GeburtstageKunden.vue'),
            isNavigable: false,
            visible: () => this.hasGeburtstageKundenRoles,
          },
          {
            name: 'GeburtstageVermittler',
            title: MAP_GEBURTSTAGEVERMITTLER_TITLE[this.brokerOverviewConfiguration.MAKLER_UEBERSICHT_GEB_TAGE_VERMITTLER_ART || 'Nein'],
            actions: 'MAKLER_UEBERSICHT_GEB_TAGE_VERMITTLER_ART' in this.brokerOverviewConfiguration ? [
              {
                legend: {
                  icon: 'PhGear',
                  key: 'GEBURTSTAGE_VERMITTLER_CONFIG',
                  label: 'Konfiguration',
                },
              }
            ] : [],
            isNavigable: false,
            visible: () => this.hasGeburtstageKundenRoles,
          },
          {
            name: 'TransaktionenVerlauf',
            title: 'Transaktionen der letzten 30 Tage',
            component: () => import('@/components/transaktionVerlauf/TransactionsCard.vue'),
            props: {
              limit: 5,
            },
            visible: () => this.hasTransaktionenVerlaufRole && this.hasInvestmentFondsAccess,
          },
          {
            name: 'UeberUnterschritteneLimits',
            title: 'Über-/Unterschrittene Limits der letzten 7 Tage',
            component: () => import('@/components/depotpositions/DepotpositionsOutOfLimit.vue'),
            visible: () => this.hasInvestmentFondsAccess,
            isNavigable: false,
          },
          {
            name: 'ErfolgreicheVermittlerwechsel',
            title: 'Erfolgreiche Vermittlerwechsel der letzten 30 Tage',
            component: () => import('@/components/depotpositions/DepotpositionsBrokerChanges.vue'),
            visible: () => this.hasInvestmentFondsAccess,
            isNavigable: false,
          },
          {
            name: 'OpenSignsOverview',
            title: 'Offene Unterschriften',
            component: () => import('@/components/openSigns/OpenSignsOverview.vue'),
            visible: () => this.hasOpenSignatureRoles,
          },
          {
            name: 'FkEvents',
            title: this.fkEventsTitle,
            props: {
              showTitle: false,
            },
            component: () => import('@/views/home/EventsFkOverview.vue'),
            visible: () => this.isFKEventsVisible,
          },
        ],
      };
    },
    fkEventsTitle() {
      return this.isFA ? 'FinanzAdmin Events' : 'FondsKonzept Events';
    }
  },
  mounted: function () {
    // disable preloading of calculation for now
    // if (this.hasStatisticsRole) {
    //   this.$store.dispatch(DASHBOARD_TYPES.ACTIONS.START_CALCULATION_DASHBOARD_CHARTS, null);
    // }
    this.$store.dispatch(DASHBOARD_TYPES.ACTIONS.GET_DASHBOARD_CONFIGURATION);

    this.externalLoading = true;
    this.$store.dispatch(DASHBOARD_TYPES.ACTIONS.GET_BROKER_OVERVIEW_CONFIGURATION).then(() => this.externalLoading = false);
    this.$store.dispatch(DASHBOARD_TYPES.ACTIONS.GET_BROKER_PARAGRAPHS_CONFIGURATION);
    if (this.hatVertrag) {
      this.$store.dispatch(DASHBOARD_TYPES.ACTIONS.SHOW_FK_EVENTS_MODAL).then(response => {
        if (response?.showFKEventsModal) {
          this.$refs.fkEventsModal.open();
        }
      });
    }
    this.$store.dispatch(BROKERDATA_TYPES.ACTIONS.GET_BROKER_DATA);
  },
  methods: {
    async saveBrokerOverviewConfiguration(widgets) {
      if(!widgets?.length) return;

      const parameters = widgets
        .filter(widget => !!MAP_WIDGET_NAME_TO_PARAMETER_NAME[widget.name])
        .reduce((acc, widget) => {
          acc[MAP_WIDGET_NAME_TO_PARAMETER_NAME[widget.name]] = widget.removed ? 'Nein' : 'Ja';
          return acc;
        }, {});
      
      try {
        this.externalLoading = true;
        await this.$store.dispatch(DASHBOARD_TYPES.ACTIONS.SAVE_BROKER_OVERVIEW_CONFIGURATION, { parameters });
      } finally {
        this.externalLoading = false;
      }
    },
    handleExecuteAction(event) {
      if(!event?.widget || !event?.action) {
        return ;
      }

      switch(event.action?.legend?.key) {
        case 'GEBURTSTAGE_VERMITTLER_CONFIG':
          this.$refs.geburtstageVermittler.openSettings();
          return;
      }

      if(event?.action?.action === 'BEARBEITEN_APP') {
        if(event?.action?.widget?.name === 'StornoWarnungTableUebersicht') {
          this.$router.push({ path: '/customer/stornos' });
        }
        if(event?.action?.widget?.name === 'MscNewsList') {
          this.$router.push({name: 'news-msc'});
        }
        if(event?.action?.widget?.name === 'TermineList') {
          this.$router.push({ path: '/communication/postfach/termine' });
        }
        if(event?.action?.widget?.name === 'TicketsNachrichtenCard') {
          this.$router.push({ path: '/communication/postfach/chats' });
        }
        if(event?.action?.widget?.name === 'TransaktionenVerlauf') {
          this.$router.push({ path: '/customer/eingegangene-transaktionen' });
        }
        if(event?.action?.widget?.name === 'OpenSignsOverview') {
          this.$router.push({ path: '/communication/offene-unterschriften' });
        }
        if(event?.action?.widget?.name === 'FkEvents') {
          this.$router.push({ name: 'fk-events' });
        }
      }

    },
  },
  beforeRouteLeave (to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zur Übersicht', 
      to,
      from,
    });

    next()
  },
  components: {
    OptionMenu,
    DashboardPanel,
    GeburtstageVermittler,
    PhCheck,
    PhWarning,
    BaseModal,
    EventsFkOverview,
  },

}
</script>

<style scoped lang="scss">
.grid-2 {
  flex: 1 1 100%;
  display: flex;
  align-items: stretch;
  align-content: stretch;
}
.grid-2 > div {
  width: 50%;
  flex: 1;
  flex-grow: 1;
  position: relative;
}
.grid-2 > div:first-child {
  margin-right: 24px;
}

::v-deep .dashboard-panel__header {
    justify-content: space-between;
    .dashboard-panel__title {
      margin: 0;
      align-items: center;
    }
}

.paragraphs-container {
  display: flex;
  flex-wrap: wrap;

  .paragraphs--item {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    margin: 0 0.5rem 0 0;

    &:last-child {
      margin-right: 0;
    }

    .paragraphs--item--status {
      margin: 0 0.25rem 0 0;
      svg {
        margin: 0;
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .grid-2 > div:first-child,
  .grid-2 > div {
    margin-right: 0;
    flex: 1;
    width: 100%;
  }
  .grid-2 {
    display: block;
  }
}
</style>