import html2canvas from "html2canvas";

const HASHED_PASSWORD_KEY = 'hashed-password';
const biometricLoginSettings = { 
  isAvailable: true, 
  isPermissionGranted: true, 
  authenticationType: 'AUTH-FACE', 
};

export function mockHandler(payload, next) {
  switch (payload?.action) {
    case 'load-login-data':
      loadLoginData(next)
      break;
    case 'save-hashed-password':
      saveHashedPassword(payload, next)
      break;
    case 'delete-hashed-password':
      deleteHashedPassword(payload, next)
      break;
    case 'load-hashed-password':
      loadHashedPassword(payload, next)
      break;
    case 'ready-to-screenshot-start-page':
      readyToScreenshotStartPage(next)
      break;
    case 'start-outgoing-call':
      startOutgoingCall(next)
      break;
    case 'view-pdf-in-signo':
    case 'open-extra-web-instance':
      openURL(payload, next)
      break;
    case 'view-document':
      payload.data = '[data truncated by bridge-mock]'
      callNext(next, payload)
      break;
    case 'close-extra-web-instance':
      closeExtraWebInstance(next)
      break;
    case 'switch-audio-output':
      switchAudioOutput(next)
      break;
    case 'load-biometric-login-settings':
      loadBiometricLoginSettings(next)
      break;
    case 'update-biometric-login-settings':
      updateBiometricLoginSettings(payload, next)
      break;
    default:
      callNext(next, payload)
      break;
  }
}

function loadBiometricLoginSettings(next) {
  return callNext(next, biometricLoginSettings);
}

function updateBiometricLoginSettings(payload, next) {
  biometricLoginSettings.isPermissionGranted = payload.isPermissionGranted;
  return callNext(next, { });
}

function switchAudioOutput(next) {
  return callNext(next, { });
}

function startOutgoingCall(next) {
  callNext(next, { 'allowed-to-proceed': true, 'show-button-switch-audio-output': true });
}

async function readyToScreenshotStartPage(next) { 
  // it takes a real screenshot and open the image on a new tab
  if (process.env.VUE_APP_VUE_JS_BRIDGE_MOCK_SCREENSHOT_START_PAGE === 'true') {
    html2canvas(document.body).then(function(canvas) {
      const base64image = canvas.toDataURL("image/png");
      window.open(base64image)
    });
  }
  callNext(next, {})
}

function saveHashedPassword(payload, next) {
  const { passwordToken, } = payload;
  localStorage.setItem(HASHED_PASSWORD_KEY, passwordToken);
  callNext(next, {})
}

function deleteHashedPassword(payload, next) {
  localStorage.removeItem(HASHED_PASSWORD_KEY);
  callNext(next, {})
}

function loadHashedPassword(payload, next) {
  const responseData = { 
    passwordToken: localStorage.getItem(HASHED_PASSWORD_KEY) || '',
  };
  callNext(next, responseData)
}

function loadLoginData(next) {
  const responseData = { 
    loginName: '0015537', 
    authenticationType: 'AUTH-FACE'
  }
  callNext(next, responseData)
}

function openURL(payload, next) {
  if(payload?.url) {
    window.open(payload?.url);
  }
  callNext(next, payload);
}

function closeExtraWebInstance(next) {
  window.close();
  callNext(next, {});
}

function callNext(next, payload) {
  next(JSON.stringify(Object.assign({ form: 'native' }, payload)))
}

function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}