<template>
  <div>
    <Table v-if="rows.length" tableId="dbbdcfe4-b5c1-4386-86d8-efac26962b2c" :headers="headers" :rows="rows"
      :rowsPerPage="10" :headerActions="tableHeaderActions"
      :mobileConfig="{ title: 'meldedatum', headers: ['schadennr', 'sparte', 'schadensursacheText', 'bearbstandLabel',] }"
      rowId="schadenId"
      v-model="selectedRows"
      @click-meldedatum="openSchaden" @headerAction-Schaden-VEREINIGEN="openVertragVereinigenModal()"
      @action-DELETE="deleteSchadensmeldung" />

    <div v-else>Keine Daten</div>
    <SchadenVereinigenModal ref="SchadenVereinigenModal" :vertragList="selectedRows" @save="mergeSchaden($event)" />
  </div>
</template>


<script>
import { mapGetters } from 'vuex';
import SCHADENSMELDUNG_TYPES from "@/store/schadensmeldung/types";
import VERSICHERUNG_TYPES from "@/store/versicherungen/types";
import Table from "@/components/table2/Table.vue";
import { TextColumn, DateColumn, ActionColumn, ConfirmedAction } from "@/components/table2/table_util.js";
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import CORE_TYPES from "@/store/core/types";
import { ROLES, VIEW_ROLES } from '@/router/roles'
import SchadenVereinigenModal from '@/components/versicherungen/SchadenVereinigenModal.vue';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import store from '@/store';
export default {

  mixins: [InteractiveHelpCommonsMixin],
  props: {
    schadenInfos: {
      type: Array,
      default: () => [],
    },
  },
  mounted(){
   
    if(!store.getters[CORE_TYPES.GETTERS.IS_ALLOWED_MERGE_SCHADEN]){
      this.selectedRows=null;
    } 
  },
  data() {
    return {
      selectedRows: []
    }

  },
  computed: {
    ...mapGetters({
      schadensmeldungen: SCHADENSMELDUNG_TYPES.GETTERS.SCHADENSMELDUNG,
      configData: SCHADENSMELDUNG_TYPES.GETTERS.CONFIG_DATA,
      isBypass: CORE_TYPES.GETTERS.IS_BYPASS,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      isOriginalUserBroker: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_BROKER,
      isOriginalUserIntern: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
      
    }),
    headers() {
      const headers = {
        center: [
          DateColumn("meldedatum", "Meldung vom").makeLink(),
          TextColumn("schadennr", "Schadensnummer"),
          TextColumn("sparte", "Sparte"),
          DateColumn("ereignisdatum", "Ereignisdatum"),
          TextColumn("schadensursacheText", "Ursache"),
          TextColumn("bearbstandLabel", "Bearbeitungsstand"),
        ],
        lockedRight: [],
      };
      if (this.isBypass)
        headers.lockedRight.push(ActionColumn("actions", "Aktionen"));
      return headers;
    },
    tableHeaderActions() {
      return [
        PageHeaderSimpleAction('Schaden-VEREINIGEN', 'Schaden vereinigen')
          .withDisabled(() => this.selectedRows && this.selectedRows.length != 2),

      ];
    },
    rows() {
      return this.schadenInfos.map(row => ({
        ...row,
        actions: [ConfirmedAction("DELETE", 'PhTrash', "Löschen", `Möchten Sie die Meldung vom ${row.meldedatum} wirklich löschen?`, "Eintrag löschen", "Löschen")],
      }));
    },
    
  },
  methods: {
    openSchaden(schaden) {
      this.$router.push(`/home/versicherungen/insurance-group/schadensmeldung/${this.$route.params.id}/${schaden.schadenId}`);
    },
   
    handleSelected(rows) {
      
      //const newlySelected = rows.map(r => r.label);
      //this.$set(this, 'selectedRows', [ ...newlySelected, ]);
    },
    deleteSchadensmeldung({ schadenId }) {
      this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.DELETE_SCHADENSMELDUNG, {
        schadenId,
      });
    },
    openVertragVereinigenModal() {
      console.log('rhhh', this.selectedRows);
      this.$refs.SchadenVereinigenModal.open();
    },
   async   mergeSchaden(row) {
      console.log("mergeSchaden",row);
      await this.$store.dispatch(SCHADENSMELDUNG_TYPES.ACTIONS.MERGE_SCHADEN,row);
      this.$router.push(`/home/versicherungen/insurance-group/schadensmeldung/${this.$route.params.id}/${row.toKeep.schadenId}`);
    }

  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zu Details',
      fullPath: from.fullPath,
      breadcrumb: 'Schadensmeldung',
    });
    next();
  },
  components: {
    Table, SchadenVereinigenModal
  },
}
</script>
