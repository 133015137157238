

export function searchMenu(showInaktiv, isMasterBroker, istDGFRP) {
  
  return [
    {
      type: 'group',
      key: 'allgemein',
      label: 'Allgemein',
      menuItems: [
       
        {
          type: 'text',
          label: 'Vermittlernummer',
          key: 'brokerId',
          emptyDenied: true,
          inputmode: 'numeric',
        },
        {
          type: 'text',
          label: 'Kundennummer',
          key: 'customerId',
          emptyDenied: true,
          inputmode: 'numeric',
        },
        {
          type: 'text',
          label: 'Name AP',
          key: 'surname',
          sortable: true,
          emptyDenied: true,
        },
        {
          type: 'text',
          label: 'Vorname AP',
          key: 'firstName',
          emptyDenied: true,
        },
        {
          type: 'text',
          label: 'Firma',
          key: 'firma',
          emptyDenied: true,
        },
        {
          type: 'text',
          label: 'Strukturleiter',
          key: 'strukturleiter',
          emptyDenied: true,
          inputmode: 'numeric',
        },
        {
          type: 'text',
          label: 'Fremdnummer',
          key: 'fremdnummer',
          emptyDenied: true,
        },
        {
          type: 'text',
          label: 'Referenznummer',
          key: 'referenznummer',
          emptyDenied: true,
        },    
        ...(showInaktiv ? [
          {
            type: 'default',
            label: 'nur aktive Vermittler',
            key: 'vermittlerAktiv',
          },
          {
            type: 'default',
            label: 'nur inaktive Vermittler',
            key: 'vermittlerInaktiv',
          },
        ] : []),
      ]
    },
    {
      type: 'group',
      key: 'adresse',
      label: 'Adresse',
      menuItems: [
        {
          type: 'text',
          key: 'strasse',
          label: 'Strasse',
          emptyDenied: true,
        },
        {
          type: 'text',
          key: 'ort',
          label: 'Ort',
          emptyDenied: true,
        },
        {
          type: 'text',
          key: 'plz',
          label: 'PLZ',
          emptyDenied: true,
        },
        {
          type: 'textRange',
          key: 'plzVonBis',
          label: 'PLZ Bereich',
          emptyDenied: true,
        },
        {
          type: 'default',
          key: 'fehlendeAngaben',
          label: 'fehlende Angaben',
          emptyDenied: true,
        },

      ]
    },
    {
      type: 'group',
      key: 'depot',
      label: 'Depot',
      menuItems: [
        {
          type: 'combobox',
          label: 'Gesellschaft',
          key: 'gesellschaftDepot',
          emptyDenied: true,
          comboOptions: []
        },
        {
          type: 'combobox',
          label: 'Lagerstelle',
          key: 'lagerstelleDepot',
          emptyDenied: true,
          comboOptions: []
        },
        {
          type: 'combobox',
          label: 'Wertpapier',
          key: 'wertpapier',
          emptyDenied: true,
          comboOptions: []
        },
        {
          type: 'text',
          label: 'ISIN',
          key: 'isin',
          emptyDenied: true,
          pattern: /\b([A-Z]{2})((?![A-Z]{10}\b)[A-Z0-9]{10})\b/i
        },
        {
          type: 'default',
          label: 'mit Bestand Investment',
          key: 'mitBestandInvestment',
          emptyDenied: true,
        },
        {
          type: 'default',
          label: 'mit Bestand Investment Struktr',
          key: 'mitBestandInvestmentStruktur',
          emptyDenied: true,
        },

      ]
    },
    {
      type: 'group',
      key: 'versicherung',
      label: 'Versicherung',
      menuItems: [
        {
          type: 'combobox',
          label: 'Gesellschaft',
          key: 'gesellschaftVers',
          emptyDenied: true,
          comboOptions: []
        },
        {
          type: 'combobox',
          label: 'Lagerstelle',
          key: 'lagerstelleVers',
          emptyDenied: true,
          comboOptions: []
        },

      ]
    },
    {
      type: 'group',
      key: 'sonstiges',
      label: 'Sonstiges',
      menuItems: [
        {
          type: 'text',
          label: 'E-Mail',
          key: 'email',
          emptyDenied: true,
        },
        {
          type: 'default',
          label: 'Geldwäschebelehrung lfd. Kalenderjahr',
          key: 'mitGeldwaesche',
          emptyDenied: true,
          deniable: true,
        },
        {
          type: 'combobox',
          label: 'Vermittlerkriterien',
          key: 'maklerkriterien',
          comboOptions: [],
          secondaryItem: {
            type: 'text',
            key: 'maklerkriterienText',
            parentValue: ['Sonstige'],
          }
        },
        {
          type: 'default',
          label: 'Zuverlässigkeitserklärung für Geschäftsführer/Berater akzeptiert',
          key: 'mitZuverlaessigkeit',
          emptyDenied: true,
          deniable: true,
        },
        {
          type: 'combobox',
          label: 'IHK Reg.',
          key: 'regIHK',
          emptyDenied: true,
          deniable: true,
          comboOptions: [
            {label: 'Passiv', value: 'p'}, 
            {label:'Unvollständig', value: 'u'}, 
            {label: 'Vollständig', value: 'v'}
          ]
        },
      ]
    },
    ...(isMasterBroker ? [
      {
        type: 'group',
        key: 'diverses1',
        label: 'Diverses 1',
        menuItems:[
          {
          type: 'combobox',
          label: 'Potential',
          key: 'potential',
          emptyDenied: true,
        },
          {
            type: 'default',
          label: 'Interessent',
          key: 'interessent',
          emptyDenied: true,
          },
  
          {
            type: 'default',
          label: 'Spezialsuche Servicegebühr',
          key: 'servicegebuerSpezial',
          emptyDenied: true,
          },
  
          {
          type: 'default',
          label: 'mit Stornorisiko',
          key: 'mitStornorisiko',
          emptyDenied: true,
          },
  
          {
            type: 'default',
          label: 'sonstige Sicherheiten',
          key: 'sonstigeSicherheiten',
          emptyDenied: true,
          },
        ],
      },
    ] : []),
    ...(istDGFRP ? [
      {
        type: 'group',
        key: 'diverses2',
        label: 'Diverses 2',
        menuItems:[
          {
            type: 'combobox',
            label:  'Betreuer',
            key: 'betreuer',
            emptyDenied: true,
          },
        ],
      },
    ] : [])
  ];
}
