<template>
  <div>
    <PageHeaderTitleNavigation 
      title="Aktivität" 
      :actions="headerActions"
      @action-CANCEL="abbrechen"
      @action-EMAIL="email"
    />

    <div class="box__container d-lg-none col-12">
      <Stepper2
        ref="refStepper2"
        stepType="step"
        :visible="true"
        stepperName="BegleitscheinData"
        :selectedStepKey="selectedStepKey"
        :stepperService="steps"
        @setStep="setStep($event)"
      ></Stepper2>
    </div>

    <ContentWithStepper :stepperCollapsed="stepperCollapsed">
        <template #stepper>
            <BoxContainer>
                <VerticalStepper
                    :stepType="'tab'"
                    :visible="true"
                    :stepperName="'BegleitscheinAktivit'"
                    :selectedStepKey="selectedStepKey"
                    :stepperService="steps"
                    :showCollapse="true"
                    @setStep="setStep($event)"
                    @collapsed="stepperCollapsed = $event"
                ></VerticalStepper>
            </BoxContainer>
        </template>
        <template #content>
          <Fields class="box__container" v-if="!transition"
            :fields="fields" :valuesProp="values" :combos="combos" 
            :tableData="tableData" :rowsCount="rowsCount"
            :validators="validators"
            @onBtClick="onBtClick"  @gotoEdit="gotoEdit" @doAction="doAction"
          >
            <template #beteiligte>
              <div>
                <ParticipantInput
                  label="Teilnehmer"
                  v-model="beteiligteValue"
                  :availableUsers="beteiligteValue"
                  :isUserSelect="false"
                  :isRole="true"
                  @deleteReceiveUsers="(bet) => doAction('delete_beteiligter', bet)"
                  @changeProp="(bet) => doAction('change_row', bet)"
                  @changeRole="(bet) => doAction('change_row', bet)"
                />
                <BaseButton class="mb-2" isSecondary @click="doAction('show_empf')">Empfänger</BaseButton>
              </div>
            </template>
          </Fields>

          <div class="box__container antrag-bottom-buttons-container row">
            <div class="col-12 my-1 col-sm-auto" v-if="selectedStepKey !== 'DATEN'">
              <BaseButton isBlock @click="setStep('DATEN')">Zurück</BaseButton>
            </div>
            <div class="col-12 my-1 col-sm-auto" v-if="selectedStepKey !== 'ANHANG'">
              <BaseButton isBlock @click="setStep('ANHANG')">Weiter</BaseButton>
            </div>
          </div>
        </template>
    </ContentWithStepper>

    <BaseModal 
      ref="modalMessage"
      :modalTitle="modalArg.title"
      :showConfirmButton="modalArg.showConfirmButton"
      :labelButtonConfirm="modalArg.labelButtonConfirm"
      :labelButtonCancel="modalArg.labelButtonCancel"
      @onConfirmButton="modalArg.onConfirm" > 
        <div v-html="sanitize(modalArg.body)"></div>
    </BaseModal>
    
    <BaseModal  v-if="!transition && selectedStepKey==='DATEN'"
      ref="modalEmpf"
      modalTitle="Empfänger"
      :showConfirmButton="true"
      labelButtonConfirm="Neue markierte hinzufügen"
      labelButtonCancel="Abbrechen"
      @onConfirmButton="addBeteiligter" > 
        <div>
          <div v-for="(empf,k) of empfValues" :key="k" class="row">
            <InputCheckBoxItem v-model="selectEmpf[empf.personId]" class="col-3" 
              :label="empf.maklernr + +(empf.unternr !== -1 ? ('-' + empf.unternr) : '')"/>
            <span class="col-4">{{empf.name}}</span>
            <span class="col-4">{{empf.email}}</span>
          </div>
        </div>
    </BaseModal>
    
    <DocumentsUploadModal v-if="!transition && selectedStepKey==='ANHANG'"
      ref='uploadForm'
      :files='uploadFiles' 
      :additionalInputs="uploadFields" 
      :uploadType="3" 
      :multipleFileUpload="true"
      :openOnMount="false"
      :customUpload="anhangUpload"
    />
  </div>
</template>

<script>
import DEPOTPOSITIONENEDIT_TYPES from "@/store/depotpositionenedit/types";
import Fields from '@/components/begleitscheine/Fields.vue';
import ContentWithStepper from '@/components/core/ContentWithStepper.vue';
import BoxContainer from '@/components/core/BoxContainer.vue';
import VerticalStepper from '@/components/vertical-stepper/VerticalStepper.vue';
import { StepperMediator } from '@/components/stepper/utils';
import InputCheckBoxItem from '@/components/core/forms/checkbox/InputCheckBoxItem.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import { required, regex } from "@/mixins/validator/rules";
import {tableMetaFromArrays} from '@/helpers/commonfunctions.js';
import { downloadFile } from '@/components/depotpositions/Utils';
import { base64ToArrayBuffer } from '@/helpers/download-helper.js';
import BaseModal from "@/components/core/BaseModal.vue";
import DocumentsUploadModal from '@/components/fileUpload/DocumentsUploadModal.vue';
import ParticipantInput from '@/components/mailcomposer/ParticipantInput'
import {sanitize} from '@/helpers/string-helper.js';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import CORE_TYPES from '@/store/core/types';
import Stepper2 from '@/components/stepper/Stepper2.vue';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

export default {
  name: 'BegleitscheinAktivitaet',
  components: {
    Fields, ContentWithStepper, BoxContainer, VerticalStepper, 
    BaseButton, InputCheckBoxItem, BaseModal, DocumentsUploadModal, ParticipantInput,
    OptionMenu, PageHeaderTitleNavigation, Stepper2,
  },
  props: {
  },
  data: function() {
    return {
      transition: false,
      bgsnr: '',
      newSid: '',
      // selectedStepKey: 'DATEN',
      currentSelectedTab: null,
      stepperCollapsed: false,
      seiten: {},
      values: {},
      dbValues: {},
      combos: {},
      // fields: [],
      enabled: {},
      empfaenger: [],
      selectEmpf: {},
      beteiligte: [],
      dbBeteiligte: [],
      sectedEmp: 0,
      rollen: [
        {label: 'Besitzer', value: 'BESITZER'},
        {label: 'Informiert', value: 'INFORMIERT'},
        {label: 'Teilnehmer', value: 'TEILNEHMER'},
        {label: 'Bearbeiter', value: 'BEARBEITER'},
        {label: 'Kontakt', value: 'KONTAKT'},
      ],
      tableData: {},
      modalArg: {
        title: '',
        showConfirmButton: false,
        labelButtonConfirm: '',
        labelButtonCancel: '',
        body: '',
        onConfirm: () => {}
      },
      uploadFields: [
        {key:'input_bemerkung', label:'Bemerkung', type:'TEXT',value: ''},
      ],
      uploadFiles: [],
      beteiligteValue: [],
      validators: {
        input_datum_beginn: [required('Bitte Fälligkeit eingeben', {forcetouch: true})], //, regex(/\d\d\.\d\d\.\d\d\d\d/, ' ')],
        input_betreff: [required('Bitte Betreff eingeben', {forcetouch: true})],
      },
      cancel: false,
    }
  },
 
  mounted() {
    this.bgsnr = this.$route.params.bgsnr;
    // this.sid = this.$route.params.sid || '';
    // if (this.sid === '0'){
    //   this.sid = '';
    // }
    this.getBgsAktivit();
  },
  computed: {
    sid() {
      return this.$route.params.sid === '0' ? this.newSid : this.$route.params.sid;
    },
    fields() {
      return this.seiten && this.seiten[this.selectedStepKey] || [];
    },
    selectedStepKey() {
      return this.$route.params.stepKey;
    },
    steps() {
      const result = [];
      let nr = 1;
      if (this.seiten?.DATEN ) {
        result.push({stepKey: 'DATEN', label:'Daten', current: this.selectedStepKey==='DATEN', totalProgress: nr++});
      }
      if (this.seiten?.ANHANG ) {
        result.push({stepKey: 'ANHANG', label:'Anhänge', current: this.selectedStepKey==='ANHANG', totalProgress: nr++});
      }
      return new StepperMediator(result)
    },
    empfValues() {
      if (this.empfaenger?.length) {
        let exclude = ',' 
        this.beteiligte.forEach( bet => {
          exclude += (bet[1] || -1) + ',';
        });
        const result = [];
        this.empfaenger.forEach( empf => {
          if ( -1 === exclude.indexOf( ',' + (empf.personId) + ',')) {
            result.push(empf);
          }
        });
        return result;
      }
      return [];
    },
    rowsCount() {
      let cnt = null;
      if ( this.fields ) {
        this.fields.forEach(field => {
          if (field.dataType === 'table') {
            this.tableName = field.data2;
            this.tableKey = field.key;
            if (field.data3?.records?.length) {
              this.tableData = tableMetaFromArrays(field.data3);
              cnt = field.data3.records.length;
            } else {
              cnt = 0;
            }
          }
        });
      }
      return cnt;
    },
    headerActions() {
      const actions = [];
      actions.push(PageHeaderSimpleAction('CANCEL', 'Änderungen verwerfen'));
      actions.push(PageHeaderSimpleAction('EMAIL', 'Versendet eine E-Mail mit den Termin- und Kontaktdaten an alle Teilnehmer.'));
      return actions;
    },
  },
  beforeRouteLeave(to, from, next) {
    const anyChanges = Object.keys(this.values).some(key => this.dbValues[key] != this.values[key]);
    if (this.cancel || !anyChanges) {
      this.$store.commit(CORE_TYPES.MUTATIONS.POP_SAVE_BACK_TO_PREVIOUS_PAGE)
      next();
    }
    if (this.isValid()) {
      this.$store.commit(CORE_TYPES.MUTATIONS.POP_SAVE_BACK_TO_PREVIOUS_PAGE)
      this.setBgsAktivit(2);
      next();
    } else {
      this.$confirmModal({
        title: 'Fehler', 
        message: 'Bitte alle Pflichtfelder ausfüllen.', 
        labelButtonConfirm: 'Ok',
        showCancelButton: false,
      })
    }
  },
  methods: {
    isValid() {
      return this.selectedStepKey === 'DATEN' ? this.values['input_datum_beginn'] && this.values['input_betreff'] : true;
    },
    sanitize(htmlString) {
        return sanitize(htmlString);
    },
    getBgsAktivit() {
      this.$store.dispatch(DEPOTPOSITIONENEDIT_TYPES.ACTIONS.JOB, {
        ask: 'get_aktivit',
        sid: this.sid, 
        refferer: 'bgsdaten',
        refferer_data: this.bgsnr,
        param_bereich: 'Begleitscheine',
        param_id: this.bgsnr,
      }).then(response => {
        if (response?.ok) {
          this.transition = true;
          this.maklernr = response.maklernr;
          this.values = response.values;
          delete this.values.refresh_table;
          delete this.values.beteiligte;
          this.combos = response.combos;
          if ( response.combos.rollen) {
            this.rollen = response.combos.rollen;
          }
          this.seiten = response.seiten;
          this.enabled = response.enabled;
          this.empfaenger = [];
          response.empfaenger.forEach( empf => {
            empf.personId = empf.personId || -1;
            empf.maklernr = response.maklernr;
            this.empfaenger.push(empf);
          });
          this.setBeteiligte(response.beteiligte);
          this.setForm(true);
          this.dbValues = JSON.parse(JSON.stringify(this.values));
          setTimeout(() => { this.transition = false; }, 0);
          this.$updateCurrentBreadcrumb({
            breadcrumb: 'Daten',
          });
        }
      });
    },
    setBgsAktivit(event, beteiligte) {
      if ( this.selectedStepKey !== 'DATEN' ) {
        return true;
      }
      // if (!this.values.input_datum_beginn || !this.values.input_betreff) {
      //   if (!this.values.input_datum_beginn ) {
      //     this.$pushErrors('input_datum_beginn', "Bitte Fälligkeit eingeben");
      //   }
      //   if (!this.values.input_betreff) {
      //     this.$pushErrors('input_betreff', "Bitte Betreff eingeben");
      //   }
      //   this.selectedStepKey = 'DATEN';
      //   return false;
      // }
      const params = Object.assign( {}, this.values, {
        ask: 'save_aktivit', 
        sid: this.sid,
        refferer: 'bgsdaten',
        refferer_data: this.bgsnr,
        param_bereich: 'Begleitscheine',
        param_id: this.bgsnr,
      });
      beteiligte = this.getModifiedBet(beteiligte);
      const besitzer = this.beteiligte.find( bet => bet[0] && bet[0][0] === 'M');
      if (besitzer) {
        beteiligte.push( {
          typid: 'M'+this.maklernr,
          rolle: 'BESITZER',
          aendern: true,
          msc_aktiv: true,
          mail_aktiv: true,
          tagesmail_aktiv: false,
        });
      }
      if (beteiligte.length) {
        params.beteiligte = beteiligte;
      } else {
        params.beteiligte = undefined;
      }
      const notModified = JSON.stringify(this.dbValues) === JSON.stringify(this.values);
      if (this.sid && beteiligte.length===0 && event !== 3 && notModified) {
        if (event === 2) {
          this.$store.dispatch(DEPOTPOSITIONENEDIT_TYPES.ACTIONS.GET_BGS, {bgsnr: this.bgsnr});
        }
        return true;
      } else if (event === 2) {
        params._refreshBgs = this.bgsnr;
      }
      if (event !== 3) {
        this.saveBgsAktivit(event, params);
        return true;
      } else {
        params.aktivitaetGeaendert = !this.sid || !notModified
        return params;
      }
    },
    async saveBgsAktivit(event, params) {
      if (!this.isValid()) {
        return;
      }
      let response = await this.$store.dispatch(DEPOTPOSITIONENEDIT_TYPES.ACTIONS.JOB, params );
      if (!this.sid && response.values?.param_sid) {
        // this.$router.push({path: `/home/vermogensubersicht/bgsaktivit/${this.bgsnr}/${response.values?.param_sid || '0'}/${this.selectedStepKey}` });
        this.newSid = response.values.param_sid;
      } 
      if (response.beteiligte) {
        this.setBeteiligte(response.beteiligte);
      }
      if (response.mailData) {
        response.mailData.node = false; 
        response.mailData.linkedObject = 'BEGLEITSCHEIN';
        response.mailData.anhang = JSON.parse(response.mailData.anhang.replace(/[']/g, '"'));
        this.$store.commit(DEPOTPOSITIONENEDIT_TYPES.MUTATIONS.PARAM, response.mailData );
        this.$router.push({path: `/home/vermogensubersicht/bgsmailaktivit` });
      }
      if (event === 1 && response.ok) {
        this.getBgsAktivit();
      }
    },
    setStep(step) {
      if ( this.selectedStepKey !== step ) {
        if (false === this.setBgsAktivit(1)) {
          return false;
        }
        if (!this.isValid()) {
        this.$confirmModal({
          title: 'Fehler', 
          message: 'Bitte alle Pflichtfelder ausfüllen.', 
          labelButtonConfirm: 'Ok',
          showCancelButton: false,
        })
        } else {
          this.transition = true;
          // this.selectedStepKey = step;
          // this.setForm(false);
          this.currentSelectedTab = this.steps.getCurrentStep(this.selectedStepKey);
          if (step === 'ANHANG') {
            this.tableData = {};
            this.fields.forEach(field => {
              if (field.dataType === 'table') {
                this.tableName = field.data2;
                this.tableKey = field.key;
                if (field.data3?.records?.length) {
                  this.tableData = tableMetaFromArrays(field.data3);
                }
              }
            });
          }
          this.$router.push({path: `/home/vermogensubersicht/bgsaktivit/${this.bgsnr}/${this.sid || '0'}/${step}` });
        }

        setTimeout(() => { this.transition = false; }, 0);
      }
      if ( this.currentSelectedTab?.label ) {
        this.$updateCurrentBreadcrumb({
          breadcrumb: this.currentSelectedTab.label,
        })
      }
    },
    setForm(checkValues) {
      const form = this.seiten[this.selectedStepKey] || [];
      form.forEach(field => {
        if ( this.enabled[field.key] !== undefined) {
          field.disabled = !this.enabled[field.key];
        }
        if (checkValues && this.values[field.key] === undefined) {
          if (field.dataType === 'check') {
            this.values[field.key] = '0';
          } else if (field.dataType === 'combo') {
            this.values[field.key] = this.combos?.length ? this.combos[0].value : '';
          } else if (field.dataType !== 'button' && field.dataType !== 'info' && field.dataType !== 'label'){
            this.values[field.key] = '';
          }
        }
      });
      // this.fields = form ;
    },
    setBeteiligte(beteiligte) {
      const beteiligteValue = [];
      beteiligte.forEach(bt => {
        if ( bt.length > 2 && (-1 !== bt[2].indexOf('[/'))) {
          bt[2] = bt[2].replace(/\[.*?\]/g, '');
        }
        beteiligteValue.push({
          value: bt[0],
          label: bt[2],
          rolle: bt[3],
          canChange: bt[4],
          alarmMail: bt[5],
          alarmMsc: bt[6],
          alarmDailyMail: bt[7],
        });
      });
      this.beteiligte = beteiligte;
      this.dbBeteiligte = JSON.parse(JSON.stringify(this.beteiligte));
      this.beteiligteValue = beteiligteValue;
    },
    onBtClick(field) {
      if (field.key === 'neue_datei') {
        this.$refs.uploadForm.open();
      } else if (field.key === 'mailanalle') {
        this.sendMail(null);
      }
    },
    gotoEdit(field, row) {
      if (field.key === 'dateianhaenge')  {
        if (typeof row.actions[0].data === 'string') {
          row.actions[0].data = JSON.parse( row.actions[0].data.replace(/'/g, '"') );
        }
        if (row.actions[0].parameter_file_id) {
          this.$store.dispatch(DEPOTPOSITIONENEDIT_TYPES.ACTIONS.JOB, {
            ask: 'get_anhang',
            fileid: row.actions[0].parameter_file_id,
          })
          .then(response => {
            if ( response.file) {
              const pdf = base64ToArrayBuffer(response.file);
              downloadFile(row.editierbar, pdf, 'application/pdf');
            } else if ( response.message) {
              this.modalArg.title = response.message;
              this.modalArg.showConfirmButton = false;
              this.modalArg.labelButtonConfirm = 'Ja';
              this.modalArg.labelButtonCancel = 'Schließen';
              this.modalArg.body = '';
              this.$refs.modalMessage.open();
            }
          });
        }
      }
    },
    doAction(key, row) {
      if (key === 'show_empf') {
        this.selectEmpf = {};
        this.empfValues.forEach( e => {
          this.selectEmpf[e.personId] = false;
        });
        this.$refs.modalEmpf.open();
        return;
      } else if (key === 'send_mail') {
        this.sendMail(row[0]);
        return;
      }
      const arg = {
        showConfirmButton: true,
        labelButtonConfirm: 'Ja',
        labelButtonCancel: 'Nein',
      };
      if (this.tableKey==='dateianhaenge' && key==='delete') {
        arg.message = 'Bestätigung';
        arg.body = 'Die Datei '+ row.editierbar +' löschen?';
        arg.params = {
          ask: 'del_anhang',
          fileid: row.actions[0].parameter_file_id,
          sid: this.sid,
        };
      } else if (key==='del_beteiligter' && row[0]) {
        if (row[0].charAt(0) === 'B') {
          arg.message = 'Bestätigung';
          arg.body = 'Beteiligter '+ row[2] +' entfernen?';
          arg.params = null;
        }
      } else if (key==='delete_beteiligter') {
        this.saveBeteiligte([{delete_id: row.value.substr(1)}]);
        return;
      } else if (key==='change_row' ) {
        row = row.row ? row.row : row;
        const zeile = this.beteiligte.find( r => r[0] === row.nummer);
        if ( zeile ) {
          zeile[3] = row.rolle;
          zeile[4] = row.canChange;
          zeile[5] = row.alarmMail;
          zeile[6] = row.alarmMsc;
          zeile[7] = row.alarmDailyMail;
        }
        return;
      }
      if (arg.message || arg.body) {
        this.modalArg.title = arg.message;
        this.modalArg.showConfirmButton = arg.showConfirmButton;
        this.modalArg.labelButtonConfirm = arg.labelButtonConfirm;
        this.modalArg.labelButtonCancel = arg.labelButtonCancel;
        this.modalArg.body = arg.body || '';
        this.modalArg.onConfirm = () => {
          if (key==='del_beteiligter') {
            this.saveBeteiligte([{delete_id: row[0].substr(1)}]);
            return;
          }
          this.$store.dispatch(DEPOTPOSITIONENEDIT_TYPES.ACTIONS.JOB, arg.params)
          .then(response => {
            if ( response.message) {
              this.modalArg.title = response.message;
              this.modalArg.showConfirmButton = false;
              this.modalArg.labelButtonConfirm = 'Ja';
              this.modalArg.labelButtonCancel = 'Schließen';
              this.modalArg.body = '';
              this.$refs.modalMessage.open();
            } else if (response.ok){
              this.getBgsAktivit()
            }
          });
        };
        this.$refs.modalMessage.open();
      }
    },
    addBeteiligter() {
      const beteiligte = [];
      Object.keys(this.selectEmpf).forEach(key => {
        if (this.selectEmpf[key]) {
          const personId = parseInt(key);
          const empf = this.empfaenger.find( e => e.personId === personId);
          if(empf) {
            beteiligte.push( {
              typid: personId === -1 ? 'M'+empf.maklernr : 'P'+personId,
              rolle: 'TEILNEHMER',
              aendern: false,
              msc_aktiv: true,
              mail_aktiv: true,
              tagesmail_aktiv: false,
            });
          }
        }
      });
      this.setBgsAktivit(1, beteiligte);
    },
    getModifiedBet(beteiligte) {
      if (!beteiligte) {
        beteiligte = [];
      }
      this.beteiligte.forEach(( bet, idx) => {
        if ( JSON.stringify(bet) !== JSON.stringify(this.dbBeteiligte[idx]) ) {
          beteiligte.push({
            beteiligterid: bet[0].substr(1),
            rolle: bet[3],
            aendern:  bet[4],
            msc_aktiv:  bet[5],
            mail_aktiv:  bet[6],
            tagesmail_aktiv:  bet[7],
          })
        }
      });
      return beteiligte;
    },
    saveBeteiligte(beteiligte) {
      beteiligte = this.getModifiedBet(beteiligte);
      if (beteiligte.length) {
        this.$store.dispatch(DEPOTPOSITIONENEDIT_TYPES.ACTIONS.JOB, {
          ask: 'save_beteiligte',
          sid: this.sid,
          refferer: 'bgsdaten',
          refferer_data: this.bgsnr,
          param_bereich: 'Begleitscheine',
          param_id: this.bgsnr,
          beteiligte: beteiligte,
        }).then(response => {
          if (response.beteiligte) {
            this.setBeteiligte(response.beteiligte);
          }
        });
      }
    },
    sendMail(bet) {
      const params = this.setBgsAktivit(3);
      params.ask = 'send_mail';
      params.mail_beteiligter = bet || '';
      this.saveBgsAktivit(3, params);
    },
    anhangUpload(data) {
      this.$store.dispatch(DEPOTPOSITIONENEDIT_TYPES.ACTIONS.JOB, {
        ask: 'add_anhang',
        sid: this.sid,
        fileid: '' + data.id,
        input_bemerkung: data.formData.input_bemerkung,
      }).then(response => {
        if (response.ok){
          this.getBgsAktivit();
        }
      });
    },
    email() {

    },
    abbrechen() {
      this.cancel = true;
      this.back();
    },
    back() {
        this.$router.push(`/home/depotpositionenedit/begleitscheinsteps/${this.$route.params.bgsnr}/AKTIVITAET?backAction=true`);
    },
  },
}
</script>

<style scoped>
.bet-row {
  font-size: 0.9em;
  border-bottom: 1px solid #C4C4C4;
}
.bet-row .row {
  align-items: flex-end;
}

</style>