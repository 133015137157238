<template>
  <div>
    <LineChart ref="lineChart"
      :chartSeries="chartSeries"
      :isArea="false"
      :customTooltipFormatter="customTooltipFormatter"
      :height="height" 
      :format="relativ ? '':format"
      :isLegend="false"
      :showDataZoom="showDataZoom"
      :chartOptions="chartOptions"
      :gridLeft="50"
    />
    <InputToggleSwitch v-if="hasShowAsTable && lineChart && chartSeries.length"
      label="Tabellarische Anzeige"
      inLineLabel
      v-model="showAsTable"
    />
    <div v-if="type=='Fondsinfo'" class="row">
      <div>Obere Schranke</div>
      <ComboBox :value="obereSchranke" :values="obereSchrankeOptions" @change="onChangeObereSchranke" />
      <div>Untere Schranke</div>
      <ComboBox :value="untereSchranke" :values="untereSchrankeOptions" @change="onChangeUntereSchranke" />
    </div>
    <slot name="beforLegend" />
    <ChartLegend v-if="lineChart && chartSeries.length"
      :showAsTable="showAsTable"
      :chartSeries="chartSeries"
      :lineChart="lineChart"
      :addOnHeaders="addOnHeaders"
      :addOnRows="addOnRows"
      :sortRows="sortLegend"
      @newOrder="$emit('newOrder', $event)"
    >
    </ChartLegend>
  </div>
</template>


<script>
import { mapGetters } from 'vuex';
import FONDSVERGLEICH_TYPES from "@/store/fondsvergleich/types";
import ComboBox from '@/components/core/forms/ComboBox.vue';
import dayjs from 'dayjs';
import {formatNumber} from '@/helpers/number-formatter.js';
import {utils} from './chartFondsUtil.js';
import LineChart from '@/components/charts/echarts/LineChart.vue';
import ChartLegend from "./ChartLegend.vue";
import {CurrencyColumn, PercentageColumn} from "@/components/table2/table_util.js";

import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue";

export default {
  // name: 'ChartFunds',
  components: {
    LineChart, ComboBox, ChartLegend, InputToggleSwitch
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    chartData: {
      type: Object,
      required: true,
    },
    sortLegend: {
      type: Boolean,
      default: false,
    },
    relativ: {
      type: Boolean,
      default: false,
    },
  },
  data: function() {
    return {
      chartSeries: [],
      height: '450px',
      format: 'percent', // 
      showDataZoom: false,
      obereSchranke: 5,
      untereSchranke: 5,
      obereSchrankeOptions: [],
      untereSchrankeOptions: [],
      hasShowAsTable: true,
      showAsTable: true,
      customColors: null,
      steuersatz: null,
      addOnHeaders: [],
      addOnRows: {},
      strData: '',
      lineChart: null,
      eChart: null,
      recalcOnZoom: false,
      chartOptions: {dataZoom: [
        {type: 'inside'},
        {left: 70, right: 80, labelFormatter: (value) => dayjs(new Date(value)).format('DD.MM.YYYY') }
      ]},
    }
  },
  mounted() {
    if ( this.$refs?.lineChart?.$refs?.chart) {
      this.lineChart = this.$refs.lineChart;
      this.initChart(this.$refs.lineChart.$refs.chart);
    }
  },
  updated() {
    if (!this.eChart && this.$refs?.lineChart?.$refs?.chart) {
      this.lineChart = this.$refs.lineChart;
      this.initChart(this.$refs.lineChart.$refs.chart);
    }
  },
  computed: {
    ...mapGetters({      
      zoom: FONDSVERGLEICH_TYPES.GETTERS.GET_START_END_ZOOM,
    }),
  },
  watch: {
    chartData: {
      handler: 'init',
      immediate: true,
    },
    relativ() {
      if (this.type == 'Anlageempf-advice' || this.type == 'Anlageempf-details'){
        this.initAnlageempfaelung();
      }
    }
  },
  methods: {
    initFondsinfo(init) {
      let series;
      if ( init ) {
        this.format = 'percent';
        this.showDataZoom = true;
        this.recalcOnZoom = true;
        this.hasShowAsTable = false;
        this.showAsTable = false;
        this.obereSchrankeOptions = [1,2,3,4,5,6,7,8,9,10].map(v => ({label: '+' + v, value: v}));
        this.untereSchrankeOptions = [1,2,3,4,5,6,7,8,9,10].map(v => ({label: '-' + v, value: v}));
        series = JSON.parse(this.strData);
        series.anlageData = utils.getAnlage(series.dTime, 100, 0, 0, 0);
        series.invest = [100, 0, 0, 0];
        utils.addKurs([series]);
      } else {
        series = this.chartSeries[0];
      }
      series = utils.addGD200Series(series, this.obereSchranke, this.untereSchranke);
      
      series.forEach((serie) => {
        serie.hidden = false;
        serie.highlight = false;
      })
      
      this.chartOptions = {dataZoom: [
        {type: 'inside', start: 0, end: 100},
        {left: 70, right: 80, start: 0, end: 100,
        labelFormatter: (value) => dayjs(new Date(value)).format('DD.MM.YYYY') }
      ]};
      this.chartSeries = series;
    },
    initFondsvergleich() {
      this.showDataZoom = true;
      this.recalcOnZoom = true;
      this.hasShowAsTable = false;
      this.showAsTable = true;
      const data = JSON.parse(this.strData);
      this.format = data.format;
      this.steuersatz = data.steuersatz;
      const fondsvergleich = utils.initFondsvergleich(this.strData);
      let series = fondsvergleich.series;
      this.addOnRows = fondsvergleich.addOnRows;
      this.addOnHeaders = (this.format === 'currency' ? [CurrencyColumn("saldo", "G&V Saldo")] : [])
      .concat([
        PercentageColumn("we", "Wertentwicklung"),
        PercentageColumn("wepa", "Wertentw. pro Jahr"),
        PercentageColumn("vola", "Volatilität"),
      ])
      if (data.sort === 'vola') {
        series = series.sort((a, b) => a[data.sort] - b[data.sort]); // desc
      } else {
        series = series.sort((a, b) => b[data.sort] - a[data.sort]);
      }
      
      if (this.format === 'currency') {
        const serie = {
          name: 'Anlagesumme',
          color: '#aaa',
          hidden: false,
          highlight: false,
          data: fondsvergleich.anlageData.map( (a, ix) => ([fondsvergleich.dTime[ix], a])),
          dTime: fondsvergleich.dTime,
          anlageData: null,
          invest: data.invest,
          key: 'ANLAGE'
        }
        const legend = {
            we: null,
          wepa: null,
          vola: null,
          key: 'ANLAGE',
        }
        if (this.format === 'currency') {
          legend.saldo = null;
        }
        this.addOnRows[serie.name] = legend;
        series.push(serie)
      }
      
      this.chartOptions = {dataZoom: [
        {type: 'inside', start: this.zoom?.start || 0, end: this.zoom?.end || 100},
        {left: 70, right: 80, start: 0, end: 100,
        labelFormatter: (value) => dayjs(new Date(value)).format('DD.MM.YYYY') }
      ]};
      if (data.legendWESort) {
        const legendWESort = data.legendWESort.split(',');
        const sortSeries = legendWESort.map(isin => series.find(s => s.key === isin)).filter(s => s);
        if (sortSeries.length === series.length) {
          series = sortSeries;
        }
      }
      this.chartSeries = series;
    },
    initAnlageempfaelung() {
      this.format = this.chartData.format;
      this.showDataZoom = true;
      this.recalcOnZoom = true;
      this.chartOptions = {dataZoom: [
        {type: 'inside', start: 0, end: 100},
        {left: 70, right: 80, start: 0, end: 100,
        labelFormatter: (value) => dayjs(new Date(value)).format('DD.MM.YYYY') }
      ]};
      let series = JSON.parse(this.strData);
      const initData = utils.initAnlageempfehlung(this.type, this.chartData?.anlage, series, this.format);
      series = initData.series;
      this.format = initData.format;
      utils.addKurs(series);
      this.chartSeries = !this.relativ 
        ? series.map(serie => ({...serie, relativ: null}) )
        : series.map(serie => ({...serie, data: serie.relativ||serie.data}) );
      if (process.env.VUE_APP_USER === 'hannes') console.log( {series: JSON.parse(JSON.stringify(series))}, {chartSeries: JSON.parse(JSON.stringify(this.chartSeries))}  );
      this.addOnRows = initData.addOnRows;
      this.addOnHeaders = (this.format === 'currency' ? [CurrencyColumn("saldo", "G&V Saldo")] : [])
      .concat([
        PercentageColumn("we", "Wertentwicklung"),
        PercentageColumn("wepa", "Wertentw. pro Jahr"),
        PercentageColumn("vola", "Volatilität"),
      ])
    },
    init() {
      if (!this.type || !this.chartData) {
        return;
      }
      // console.log('chartData', this.type, JSON.parse(JSON.stringify(this.chartData)))
      switch (this.type) {
        case 'Anlageempf-advice':
        case 'Anlageempf-details':
        case 'Anlageempf-actual': {
          if ( !this.chartData?.chartData?.series?.length) {
            this.chartSeries = [];
            this.strData = '';
            return;
          }
          const strData = JSON.stringify(this.chartData.chartData.series);
          if (this.strData === strData) {
            return;
          }
          this.strData = strData;
          this.initAnlageempfaelung();
        }
        break;
        case 'Fondsinfo': {
          if ( !this.chartData?.data) {
            this.chartSeries = [];
            this.strData = '';
            return;
          }
          const strData = JSON.stringify(this.chartData);
          if (this.strData === strData) {
            return;
          }
          this.strData = strData;
          this.initFondsinfo(true);
        }
        break;
        case 'Fondsvergleich': {
          if ( !this.chartData?.series) {
            this.chartSeries = [];
            this.strData = '';
            return;
          }
          const strData = JSON.stringify(this.chartData);
          if (this.strData === strData) {
            return;
          }
          this.strData = strData;
          this.initFondsvergleich();
        }
        break;
      }
    },
    onChangeObereSchranke(value) {
      this.obereSchranke = value;
      this.initFondsinfo(false);
    },
    onChangeUntereSchranke(value) {
      this.untereSchranke = value;
      this.initFondsinfo(false);
    },
    initChart(vChart) {
      if (vChart.chart && !this.eChart) {
        this.eChart = vChart.chart;
        this.eChart.on('datazoom', this.onDataZoom);
      }
    },
    onDataZoom() {
      if (!this.showDataZoom) {
        return;
      }
      const opt = this.eChart.getOption();
      setTimeout(() => {
        this.calcAfterZoom(opt.dataZoom[0].startValue, opt.dataZoom[0].endValue);
      }, 100);
    },
    calcAfterZoom(startValue, endValue) {
      const opt = this.eChart.getOption();
      if (opt.dataZoom?.length && startValue === opt.dataZoom[0].startValue && endValue === opt.dataZoom[0].endValue) {
        let series = JSON.parse(JSON.stringify(this.chartSeries));
        if (!series[0].dTime?.length) {
          return;
        }
        const ixVon = series[0].dTime.findIndex( t => t >= startValue);
        const ixBis = series[0].dTime.findIndex( t => t >= endValue) + 1;
        
        if ( this.type === 'Fondsinfo') {
          utils.recalcFromFactor(series[0], ixVon, ixBis, this.steuersatz);
          series = utils.addGD200Series(series[0], this.obereSchranke, this.untereSchranke);
        } else {
          const addOnRows = JSON.parse(JSON.stringify(this.addOnRows));
          let wes, vola, anlageData;

          series.forEach((serie) => {
            if (this.recalcOnZoom) {
              utils.recalcFromFactor(serie, ixVon, ixBis, this.steuersatz);
              anlageData = serie.zAnlageData;
            } else {
              anlageData = serie.anlageData;
            }
            if (anlageData){
              wes = utils.getKapitalgewichteteWertentwicklung(serie.data, anlageData, false, serie.irr, ixVon, ixBis ) 
              vola = utils.calcVola(serie.data, anlageData);
              const legend = addOnRows[serie.name];
              if (legend) {
                if (legend.we != undefined && wes){
                  legend.we = Math.round(wes[0] * 100) / 100;
                }
                if (legend.wepa != undefined && wes){
                  legend.wepa = Math.round(wes[1] * 100) / 100;
                }
                if (legend.saldo != undefined && wes){
                  legend.saldo = Math.round(wes[2] * 100) / 100;
                }
                if (legend.vola != undefined && legend.vola != null){
                  legend.vola = Math.round(vola * 100) / 100;
                }
                addOnRows[serie.name] = legend;
              }
            }
          });
          this.addOnRows = addOnRows;
        }
        if (this.recalcOnZoom) {
          if ((this.type == 'Anlageempf-advice' || this.type == 'Anlageempf-details') && this.relativ){
            series = series.map(serie => ({...serie, data: serie.relativ}) );
          }
          this.chartOptions = { dataZoom : [{type: 'inside'},{type: 'slider'}], series}
        } else {
          this.chartOptions = { dataZoom : [{type: 'inside'},{type: 'slider'}]}
        }
        
        this.$emit('newZeitIntervall', {ixVon, ixBis: ixBis-1, addOnRows: this.addOnRows,  start:opt.dataZoom[0].start,  end:opt.dataZoom[0].end});
      }
    },
    customTooltipFormatter(params) {
      const format = this.relativ ? '' : this.format;
      if(Array.isArray(params)) {
        const name = dayjs(params[0].value[0]).format('DD MMM YYYY') || '';
        const html = params.map(p => {
          const { marker, seriesName, value } = p;
          let seriesNameTool = seriesName;
          if (this.toolTipTruncName && -1 !== seriesNameTool.indexOf(this.toolTipTruncName)) {
            seriesNameTool = seriesNameTool.substr(0, seriesNameTool.indexOf(this.toolTipTruncName));
          }
          let percentValue = value
          if (Array.isArray(value)) {
            percentValue = value[1]
          }
          if (!percentValue) {
            return ''
          }
          const formattedValue = formatNumber(percentValue || 0, 2) + ( format === 'percent' ? ' %' : ( format === 'currency' ? ' €' : ''));
          return `
            <div>
              <span>${marker}</span>
              <span style="white-space: normal;">${seriesNameTool}:</span> 
              <b>${formattedValue}</b>
            </div>`;
        });
        return `
          <div class="tooltip-series">
            <div><b>${name}</b></div>
            <div class="tooltip-series-table">${html.join('')}</div>
          </div>
        `;
      } else {
        const { marker, seriesName, name, value } = params;
        let seriesNameTool = seriesName;
        if (this.toolTipTruncName && -1 !== seriesNameTool.indexOf(this.toolTipTruncName)) {
          seriesNameTool = seriesNameTool.substr(0, seriesNameTool.indexOf(this.toolTipTruncName));
        }
        const formattedValue = formatNumber(value, 0) + ( format === 'percent' ? ' %' : ( format === 'currency' ? ' €' : ''));
        return `
          <div>
            <div><b>${seriesNameTool} test</b></div>
            <div><span>${marker}</span> <span>${name}:</span> <b>${formattedValue}</b></div>
          </div>`;
      }
    },
  },
}
</script>

<style scoped>
</style>