<template>
  <div>
    <div v-if="rows.length">
        <div class="small" v-if="config && config.comment">{{config.comment}}</div>
        <Table
            :headers="headers"
            :rows="rows"
            rowId="fondsname"
            hidePagination
            @action-DELETE="openModalDeleteFond"
        />
    </div>

      <DeletePositionModal ref="deleteModal" 
        :position="positionToDelete"
        @delete="doDeletePosition()"
      ></DeletePositionModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FONDSFINDER_TYPES from '@/store/fondsfinder/types';
import DeletePositionModal from '@/components/core/forms/antraege/wertpapierAuswahl/DeletePositionModal.vue'
import Table from "@/components/table2/Table.vue";
import {TextColumn, ActionColumn, SimpleAction} from "@/components/table2/table_util.js";
import validator from "@/mixins/validator";
import antragMixin from '@/mixins/antrag/antrag-mixin.js';
import ANTRAG_TYPES from '@/store/antrag/types';

export default {
  mixins: [validator, antragMixin],
  validators: {},
  props: {
    positions: {
    },
    config: {
    },
    antragId: {
    },
    categoryId: {
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
        headers: {
            lockedLeft: [
                TextColumn("isin", "ISIN"),
                TextColumn("fondsname", "Fondsname"),
            ],
            lockedRight: [
                ActionColumn("actions", ""),
            ],
        },
        positionToDelete : {},
    }
  },
  computed: {
    ...mapGetters({
      positionInfo: FONDSFINDER_TYPES.GETTERS.POSITION_INFO,
      antraege: ANTRAG_TYPES.GETTERS.ANTRAEGE,
    }),
    rows() {
        const actions = [
            SimpleAction("DELETE", 'PhTrash', "Löschen"),
        ];
        return (this.positions || []).map(row => ({
            ...row,
            fondsname: this.getFondsName(row, this.positionInfo),
            actions,
        }));
    },
    antrag() {
      if (this.antraege && this.antraege[this.antragId]) {
        return this.antraege[this.antragId]
      }
      return null
    },
    positionWarnings() {
      if (this.antrag && this.antrag.warnings) {
        this.updateWarnings(this.antrag.warnings && this.antrag.warnings.positionWarnings);
        return this.antrag.warnings && this.antrag.warnings.positionWarnings;
      }
      return null
    },
  },
  methods: {
    updateWarnings(warnings) {
      if (warnings && warnings.length) {
        for (const warn of warnings) {
          if (warn.posGrpId) {
            if (!warn.id || warn.id.includes(this.categoryId)) {
              const index = this.positions.findIndex(pos => pos.posGrpId === warn.posGrpId || pos.isin === warn.posGrpId);
              if (index >= 0) {
                this.$pushErrors('betrag' + index, warn.message || '');
              }
            }
          }
        }
      }
    },
    commitUpdatePositionsFromEvent(positionChange, isin) {
      const payload = {
        id: this.antragId,
        categoryId: this.categoryId,
        fonds: [
          { 
            ...positionChange,
            isin,
          }
        ]
      }
      this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_POSITIONS, payload)
    },
    onChangeInputField($event, position, field) { 
      const positionChange = { [field]: $event }
      this.commitUpdatePositionsFromEvent(positionChange, position.isin)
    },
    openModalDeleteFond(fond) {
      this.positionToDelete = fond;
      this.$refs.deleteModal.open()
    },
    doDeletePosition() {
      this.deletePosition(this.antragId, this.categoryId, this.positionToDelete);
      this.positionToDelete = null;
    }
  },
  components: {
    DeletePositionModal,
    Table,
  }
}
</script>

<style scoped>
  .small {
    font-size: small;
    color: var(--color-danger);
    margin-bottom: 1rem;
    padding-left: 2rem;
  }
</style>