import MSC_MARKETING_TYPES from './types';
import Vue from 'vue'
import { getInitialState, getNewMarketingCampaign } from './index';

export default {
  [MSC_MARKETING_TYPES.MUTATIONS.GET_MARKETING_CAMPAIGN_SUCCESS](state, data) {
    Vue.set(state, 'marketingCampaigns', data);
  },
  [MSC_MARKETING_TYPES.MUTATIONS.GET_PENDING_MARKETING_CAMPAIGNS_SUCCESS](state, data) {
    Vue.set(state, 'pendingMarketingCampaigns', data);
  },
  [MSC_MARKETING_TYPES.MUTATIONS.POSTPONE_MARKETING_CAMPAIGN](state, data) {
    Vue.set(state, 'postponeMarketingCampaign', [ ...state.postponeMarketingCampaign, data ]);
  },
  [MSC_MARKETING_TYPES.MUTATIONS.SET_USER_INTO_TARGET_LIST](state, list) {
    Vue.set(state, 'targetList', list);
  },
  [MSC_MARKETING_TYPES.MUTATIONS.MARKETING_CAMPAIGN_CHANGED](state, data) {
    Vue.set(state, 'changed', data);
  },
  [MSC_MARKETING_TYPES.MUTATIONS.SET_EDITING_MARKETING_CAMPAIGN](state, campaign) {
    if (!campaign) {
      campaign = getNewMarketingCampaign();
    }
    Vue.set(state, 'editingMarketingCampaign', { ...campaign });
  },
  [MSC_MARKETING_TYPES.MUTATIONS.ADD_USER_INTO_TARGET_LIST](state, { values, targetType }) {
    if (!targetType || !values) {
      return;
    }

    const newValue = values.map(item => ({
      userId: item.id,
      userType: 'MAKLER',
      type: targetType,
      label: `${item.id} ${item.fullName}`,
    }))

    const targetList = [...state.targetList, ...newValue];

    // remove duplicated by userId
    const uniqueTargetList = targetList.filter((obj, index, arr) => {
      return arr.map(mapObj => mapObj.userId).indexOf(obj.userId) === index;
    });

    Vue.set(state, 'targetList', uniqueTargetList);
    Vue.set(state, 'changed', true);
  },
  [MSC_MARKETING_TYPES.MUTATIONS.REMOVE_USER_FROM_TARGET_LIST](state, data) {
    const result = state.targetList?.filter(item => !(item.userId === data.userId && item.userType === data.userType && item.type === data.type));

    Vue.set(state, 'targetList', result);
    Vue.set(state, 'changed', true);
  },
  [MSC_MARKETING_TYPES.MUTATIONS.RESET_USER_INTO_TARGET_LIST](state) {
    Vue.set(state, 'targetList', []);
  },
  [MSC_MARKETING_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState())
  },
}