<template>
  <div class="antrag-components__container">
    <div class="box__container">
      <FormLabel label="Bitte tragen Sie hier die Daten des Depots ein, von dem die Wertpapiere übertragen werden sollen"/>
      <FormHeader label="Daten der bisher depotführenden Gesellschaft"/>
      <InputField v-model="positionData.bisherManuellName" label="Name der Bank"/>
      <InputField v-model="positionData.bisherManuellStrasse" label="Straße"/>
      <InputField v-model="positionData.bisherManuellPLZ" label="PLZ"/>
      <InputField v-model="positionData.bisherManuellOrt" label="Ort"/>

      <FormHeader label="Daten des Depots bei der bisher depotführenden Gesellschaft"/>
      <InputField v-model="positionData.bisherDepotnr" label="Depotnummer"/>

      <div class="row">
        <div class="col">
          <BaseButton @click="$emit('next')">Weiter</BaseButton>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex';
import { calculateAntragID } from '@/components/antrag/antrag-utils';
import antragNavigationMixin from '@/mixins/antrag/antrag-navigation-mixin.js';
import ANTRAG_TYPES from '@/store/antrag/types';
import FormHeader from '@/components/core/forms/FormHeader.vue';
import FormLabel from '@/components/core/forms/FormLabel.vue';
import InputField from '@/components/core/forms/InputField.vue';
import BaseButton from '@/components/core/BaseButton.vue';


export default {
  mixins: [antragNavigationMixin],
  components: {
    FormHeader,
    FormLabel,
    InputField,
    BaseButton,
  },
  props: {
    warnings: {
    },
  },
  computed: {
    ...mapGetters({
      antraege: ANTRAG_TYPES.GETTERS.ANTRAEGE,
      antraegeData: ANTRAG_TYPES.GETTERS.ANTRAEGE_DATA,
    }),
    antrag () {
      return this.antraege[this.antragId]
    },
    component() {
      return this.antrag.components[this.step].find(component => component.id == this.categoryId)
    },
    componentData() {
      return this.antraegeData[this.antragId][this.component.id]
    },
  },
  data() {
    return {
      antragId: null,
      fondIndex: -1,
      categoryId: null,
      positionData: {
      },
      step: null,
      wertpapierConfig: {
        type: "FFBUEBERTRAGUNG",
        buttonText: "Wertpapier hinzufügen",
      },
      allValues: [
        {value: true, label: "Bitte übertragen Sie alle Positionen gemäß beigefügter aktueller Vermögensübersicht"},
        {value: false, label: "Bitte übertragen Sie die folgenden Positionen:"},
      ],
      searchingPositions: false,
    }
  },
  mounted() {
    const antragsName = this.$route.params.antragsName
    this.antragId = calculateAntragID({antragsName: antragsName, lagerstelle: this.$route.params.lagerstelle})
    this.fondIndex = this.$route.params.fondIndex
    this.categoryId = this.$route.params.categoryId
    this.step = this.$route.params.step
    this.positionData = {
      ...this.componentData[this.fondIndex]
    }
  },
  methods: {
    onBackButton() {
      this.navigateBackToCurrentStep()
    },
    savePosition() {
      const newData = this.componentData.slice()
      newData[this.fondIndex] = this.positionData
      const payload = {
        id: this.antragId,
        data: {
          [this.component.id]: newData
        }
      }
      this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_ANTRAG_DATA, payload)
    },
    addFonds(fonds) {
      this.searchingPositions = false
      if (!fonds || fonds.length == 0)
        return
      // combine previously selected fonds with the new fonds
      const positionList = this.getPositions()
      fonds.forEach(fond => {
        positionList.push({
          isin: fond.isin,
          name: fond.wertpapiername,
          anteile: "alle",
        })
      })
      this.setPositions(positionList)
    },
    deleteFond(index) {
      const positionList = this.getPositions()
      positionList.splice(index, 1)
      this.setPositions(positionList)
    },
    getPositions() {
      const positionList = []
      for (let i = 1; i <= this.component.config.anzahlFonds; i++)
        if (this.positionData["isin" + i])
          positionList.push({
            isin: this.positionData["isin" + i],
            name: this.positionData["fondsname" + i],
            anteile: this.positionData["anteile" + i],
          })
      return positionList
    },
    setPositions(positionList) {
      for (let i = 1; i <= this.component.config.anzahlFonds; i++) {
        Vue.set(this.positionData, "isin" + i, positionList.length >= i ? positionList[i-1].isin : null)
        Vue.set(this.positionData, "fondsname" + i, positionList.length >= i ? positionList[i-1].name : null)
        Vue.set(this.positionData, "anteile" + i, positionList.length >= i ? positionList[i-1].anteile : null)
      }
    },
  },
  beforeDestroy() {
    this.savePosition()
  }
}
</script>
<style scoped>

</style>