<template>
  <div v-if="isVisible" class="popup" ref="popup" :style="{ zIndex: zIndex, top: top + 'px', left: left + 'px' }" >
    <div class="header" data-draggable="true" @mousedown="startDrag" @touchstart="handleTouchStart">
      <span data-draggable="true" class="modal__title">{{modalTitle}}</span>
      <div class="hearder__buttons">
        <button type="button" class="base-modal__header--button clickable" @click="minimizePopup()">
          <PhArrowsInSimple size="20" />
        </button>  
        <button type="button" class="base-modal__header--button clickable"  v-if="!isMaximized" @click="maximizePopup()">
          <PhFrameCorners size="20" />
        </button>        
        <button type="button" 
          class="base-modal__header--button clickable" 
          @click="closePopup()">
              <PhXCircle size="20"/>
        </button>           
      </div>

    </div>
    <div class="content">
      <slot></slot> <!-- Slot for dynamic content -->
    </div>

    <div 
        v-if="isSafariOniOS"
        class="resizable-handle" 
        @touchstart="startResizing" 
        @touchmove="resize" 
        @touchend="stopResizing"
      >
    </div>


  </div>
</template>

<script>
import { PhXCircle, PhFrameCorners, PhArrowsInSimple, } from 'phosphor-vue';
import BrowserSupport from '@/browser-support';
const MOUSE_EVENT_OPTIONS = BrowserSupport.supportsPassive ? { passive: true, capture: false, } : false;
import FC_CONFIG_TYPES from '@/store/fcConfig/types';
import FC_CONFIG from '@/configs/fcConfig.js';
import { FC_CONFIG_USER_LEVEL } from '@/configs/fcConfig';
import CORE_TYPES from "@/store/core/types";
import { mapGetters } from 'vuex';

const MIN_POPUP_WIDTH = 395;
const MIN_POPUP_HEIGHT = 395;
const MIN_POPUP_HEIGHT_PERCENTAGE = 25;
const MIN_POPUP_WIDTH_PERCENTAGE = 25;
const POPUP_MARGIN_8 = 8;
const POPUP_MARGIN_12 = 12;
const POPUP_MARGIN_24 = 24;

export default {
  name: 'ResizablePopup',
  components: {
    PhXCircle,
    PhFrameCorners,
    PhArrowsInSimple,
  },  
  props: {
    // Change the modal title
    modalTitle: {
      default: 'Modal Title'
    },
    initialHeight: {
      type: Number,
      default: 400, 
    },
    initialWidth: {
      type: Number,
      default: 600, 
    },
    sizeIsPercentage: {
      type: Boolean,
      default: false, 
    },
    fullClientHeight: {
      type: Boolean,
      default: false, 
    },    
    startPosition: {
      type: String,
      default: 'TOP_LEFT',
      validator(value) {
        return ['TOP_RIGHT', 'TOP_LEFT', 'BOTTOM_RIGHT', 'BOTTOM_LEFT'].includes(value);
      }
    },
    id: {
      type: String,
      required: true,
      validator: (value) => {
        const notEmpty = !!value && !!value.trim();
        return notEmpty;
      },
    },            
  },  
  data() {
    return {
      isVisible: false,
      isMaximized: false,
      resizeObserver: null, // Declared but not reactive
      isDragging: false,
      isLongPress: false,
      initialTouch: null,
      initialMouseX: 0,
      initialMouseY: 0,
      initialPopupX: 0,
      initialPopupY: 0,
      zIndex: 9999,
      top: 0,
      left: 0,
      longPressTimeout: null,
      isResizing: false,
      startX: 0,
      startY: 0,
      startWidth: 0,
      startHeight: 0,
      currentWindowHeight: window.innerHeight,
    };
  },
  watch: {
    // Watch for changes on isVisible
    isVisible(newVal) {
      if (newVal) {
        this.setupResizeObserver();
      } else {
        this.teardownResizeObserver();
      }
    },
  },  
  computed: {
    ...mapGetters({
      fcConfigResizableModal: FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_RESIZABLE_MODAL,
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
      isKunde: CORE_TYPES.GETTERS.IS_CUSTOMER_ONLY,
      userLevelDefault: FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_USER_LEVEL_DEFAULT,

    }),
    savedModalPosition() {
      const contentToParse = this.fcConfigResizableModal[this.id]?.content;
      if (contentToParse) {
        return JSON.parse(contentToParse);
      }

      return null;
    },    
    computedInitialWidth() {
      return Math.max(this.initialWidth, this.sizeIsPercentage ? MIN_POPUP_WIDTH_PERCENTAGE : MIN_POPUP_WIDTH);
    },
    computedInitialHeight() {

      if (this.fullClientHeight) {
        const desiredHeight = this.currentWindowHeight - POPUP_MARGIN_12;
        return Math.max(desiredHeight, this.sizeIsPercentage ? MIN_POPUP_HEIGHT_PERCENTAGE :  MIN_POPUP_HEIGHT);
      }

      return Math.max(this.initialHeight, this.sizeIsPercentage ? MIN_POPUP_HEIGHT_PERCENTAGE :  MIN_POPUP_HEIGHT);
    },
    isSafariOniOS() {
      const isIOS = /iPad|iPhone|iPod/.test(navigator.platform) || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
      const isSafari = navigator.userAgent.includes('Safari') && !navigator.userAgent.includes('Chrome') && !navigator.userAgent.includes('CriOS') && !navigator.userAgent.includes('FxiOS');
      return isIOS && isSafari;
    },
    isIOS() {
      return /iPad|iPhone|iPod/.test(navigator.platform) || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
    }     
  },
  methods: {
    startResizing(event) {
      this.isResizing = true;
      this.startWidth = this.startWidth || this.computedInitialWidth;
      this.startHeight = this.startHeight || this.computedInitialHeight;
      this.startX = event.touches[0].clientX;
      this.startY = event.touches[0].clientY;
    },
    resize(event) {
      if (!this.isResizing) return;

      const currentX = event.touches[0].clientX;
      const currentY = event.touches[0].clientY;

      const popup = this.$refs.popup;

      popup.style.width = `${this.startWidth + (currentX - this.startX)}px`;
      popup.style.height = `${this.startHeight + (currentY - this.startY)}px`;

      // Prevent default behavior to avoid scrolling while resizing
      event.preventDefault();
    },
    stopResizing() {
      this.isResizing = false;

      const popup = this.$refs.popup;
      const computedStyle = window.getComputedStyle(popup);
      const popupWidth = parseInt(computedStyle.getPropertyValue('width'));
      const popupHeight = parseInt(computedStyle.getPropertyValue('height'));
      this.startWidth = popupWidth;
      this.startHeight = popupHeight;
    },        
    async showPopup() {
      await this.loadModalSettings();

      this.isVisible = true;
      this.$nextTick(() => {
        this.currentWindowHeight = window.innerHeight;

        const popup = this.$refs.popup;
        if (popup) {
          popup.style.width = `${this.computedInitialWidth}${this.sizeIsPercentage ? '%' : 'px'}`;
          popup.style.height = `${this.computedInitialHeight}${this.sizeIsPercentage ? '%' : 'px'}`;
          popup.style.minWidth =  `${MIN_POPUP_WIDTH}px`;
          popup.style.minHeight = `${MIN_POPUP_HEIGHT}px`;
        }

        this.setupResizeObserver();
        this.setInitialPosition();
        this.ensurePopupVisible();
      })      
    },
    setInitialPosition() {
      const {savedModalPosition} = this;

      const popup = this.$refs.popup;
      const computedStyle = window.getComputedStyle(popup);
      const popupWidth = parseInt(computedStyle.getPropertyValue('width'));
      const popupHeight = parseInt(computedStyle.getPropertyValue('height'));

      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;      

      let positionLeft = POPUP_MARGIN_8;
      let positionTop = POPUP_MARGIN_8;

      switch (this.startPosition) {
        case 'TOP_RIGHT':
          positionLeft = viewportWidth - popupWidth  - POPUP_MARGIN_24;
          break;

          case 'BOTTOM_LEFT':
          positionTop = viewportHeight - popupHeight  - POPUP_MARGIN_8;
          break;
          
          case 'BOTTOM_RIGHT':
          positionLeft = viewportWidth - popupWidth  - POPUP_MARGIN_24;
          positionTop = viewportHeight - popupHeight  - POPUP_MARGIN_8;
          break;           
      
        default:
          positionLeft = POPUP_MARGIN_8;
          break;
      }

      this.left = savedModalPosition?.left || positionLeft;
      this.top = savedModalPosition?.top ||positionTop;
    },
    async closePopup() {
      this.isVisible = false;
      this.isMaximized = false; // Reset maximized state when closing
      await this.saveModalSettings()
    },
    maximizePopup() {
      this.left = 8;
      this.top = 8;

      const popup = this.$refs.popup;
      if (popup) {
        popup.style.width = `calc(100vw - ${POPUP_MARGIN_24}px)`;
        popup.style.height = `calc(100vh -  ${POPUP_MARGIN_12}px)`;
        this.isMaximized = true; // Update maximized state

        const computedStyle = window.getComputedStyle(popup);
        const width = parseInt(computedStyle.getPropertyValue('width'));
        const height = parseInt(computedStyle.getPropertyValue('height'));

        this.$emit('onMaximize', { width, height });
      }
    },
    minimizePopup() {
      const popup = this.$refs.popup;
      if (popup) {
        popup.style.width = `${this.computedInitialWidth}${this.sizeIsPercentage ? '%' : 'px'}`;
        popup.style.height = `${this.computedInitialHeight}${this.sizeIsPercentage ? '%' : 'px'}`;
        this.isMaximized = false;
        this.$emit('onMinimize', { width: this.computedInitialWidth,  height: this.computedInitialHeight });
        this.ensurePopupVisible();
      }
    },   
    ensurePopupVisible() {
      if (!this.isVisible) return;

      const popup = this.$refs.popup;
      if (!popup) return;

      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;
      const rect = popup.getBoundingClientRect();

      // Adjust horizontal position
      if (rect.left < 0) {
        this.left = this.left + (rect.x * -1); 
      }  else if (rect.right > viewportWidth) {
        const difference = rect.right - viewportWidth;
        this.left = this.left - difference;
      }

      // Adjust vertical position
      if (rect.top < 0) {
        this.top = this.top + (rect.y * -1); 
      } else if (rect.bottom > viewportHeight) {
        const difference = rect.bottom - viewportHeight;
        this.top = this.top - difference;
      }
    },
    disableResizing() {
      const popup = this.$refs.popup;

      // temporarily disables resizing
      popup.style.resize = 'none';

      // Re-enable resizing after a short delay
      setTimeout(() => {
        popup.style.resize = 'both';
      }, 500); // 500 milliseconds delay  
    },    
    setupResizeObserver() {
      if (this.$refs.popup && !this.resizeObserver) {
        this.resizeObserver = new ResizeObserver(entries => {
          for (let entry of entries) {

            const popup = entry?.target;

            if (popup) {
              const blockSize = entry?.borderBoxSize?.[0]?.blockSize;
              const inlineSize = entry?.borderBoxSize?.[0]?.inlineSize;

              const rect = popup.getBoundingClientRect();
              const viewportWidth = window.innerWidth;
              const viewportHeight = window.innerHeight;

              const desiredWidth = viewportWidth;
              const desiredHeight = viewportHeight;

              // Adjust top position if the popup goes above the visible area
              if (rect.y < 0) {
                this.top = this.top + (rect.y * -1);
                this.initialPopupY = this.top;
              }

              // Adjust left position if the popup goes beyond the left boundary
              if (rect.x < 0) {
                this.left = this.left + (rect.x * -1);
                this.initialPopupX = this.left;
              }

              // Calculate the maximum allowed width and height based on current position
              const maxAllowedWidth = viewportWidth - rect.left - POPUP_MARGIN_24;
              const maxAllowedHeight = viewportHeight - rect.top - POPUP_MARGIN_12;

              // Restrict dimensions to not exceed the viewport dimensions
              const newWidth = Math.min(inlineSize, desiredWidth, maxAllowedWidth);
              const newHeight = Math.min(blockSize, desiredHeight, maxAllowedHeight);

              const popupWidth = entry?.contentRect?.width;
              const popupHeight = entry?.contentRect?.height;

              // Disable resizing temporarily if popup has reached maximum size
              if (popupWidth >= desiredWidth || popupHeight >= desiredHeight) {
                this.disableResizing();
              }      

              // Adjust style directly or update data properties that bind to style
              popup.style.width = `${newWidth}px`;
              popup.style.height = `${newHeight}px`;              

              this.$emit('onResize', { width: newWidth, height: newHeight });

              if (newWidth >= desiredWidth && newHeight >= desiredHeight) {
                this.isMaximized = true;
              } else {
                this.isMaximized = false;
              }

              this.initialMouseX = 0;
              this.initialMouseY = 0;

            }

          }
        });
        this.resizeObserver.observe(this.$refs.popup);
      }
    },
    teardownResizeObserver() {
      if (this.resizeObserver) {
        this.resizeObserver.disconnect();
        this.resizeObserver = null;
      }
    },
    handleTouchStart(event) {
      this.initialTouch = event.touches[0];
      this.longPressTimeout = setTimeout(() => {
        this.isLongPress = true;
        this.startDrag(event);
      }, 200); // Long press duration
      document.addEventListener('touchmove', this.handleTouchMove, { passive: false });
      document.addEventListener('touchend', this.handleTouchEnd);
    },
    handleTouchMove(event) {
      if (this.isLongPress) {
        this.handleDrag(event);
      } else {
        clearTimeout(this.longPressTimeout);
      }
    },
    handleTouchEnd() {
      clearTimeout(this.longPressTimeout);
      this.isLongPress = false;
      this.endDrag();
      document.removeEventListener('touchmove', this.handleTouchMove);
      document.removeEventListener('touchend', this.handleTouchEnd);
    },
    startDrag(event) {
      event.preventDefault();
      const initialEvent = event.type === 'touchstart' ? event.touches[0] : event;

      if (event.target.getAttribute('data-draggable') === 'true') {
        // Store initial mouse coordinates
        this.initialMouseX = initialEvent.clientX;
        this.initialMouseY = initialEvent.clientY;

        this.isDragging = true;
        // Get the initial top and left positions of the element
        const popup = this.$refs.popup;
        this.initialPopupX = popup.offsetLeft;
        this.initialPopupY = popup.offsetTop;        

        // passive: false: This indicates that preventDefault() might be called, and the browser will wait for the event to finish processing to see if preventDefault() is invoked
        document.addEventListener('mousemove', this.throttledHandleDrag, { passive: false });
        document.addEventListener('mouseup', this.endDrag, MOUSE_EVENT_OPTIONS);
      }
    },
    handleDrag(event) {
      event.preventDefault();
      const moveEvent = event.type === 'touchmove' ? event.touches[0] : event;
      
      if (this.isDragging) {
        const dx = moveEvent.clientX - this.initialMouseX;
        const dy = moveEvent.clientY - this.initialMouseY;

        this.top = this.initialPopupY + dy;
        this.left = this.initialPopupX + dx;

        const popup = this.$refs.popup;

        // Get viewport limits
        const viewportWidth = document.documentElement.clientWidth;
        const viewportHeight = document.documentElement.clientHeight;
        const dragElementWidth = popup.offsetWidth;
        const dragElementHeight = popup.offsetHeight;

        const initialX = 0;
        const initialY = 0;

        this.top = 
          Math.min(
            Math.max(initialY + window.scrollY, this.initialPopupY + dy), 
            viewportHeight - dragElementHeight  + window.scrollY
          );

        this.left = 
          Math.min(
            Math.max(initialX + window.scrollX, this.initialPopupX + dx), 
            viewportWidth - dragElementWidth  + window.scrollX
          );

      }
    },
    endDrag() {
      this.isDragging = false;
      document.removeEventListener('mousemove', this.throttledHandleDrag);
      document.removeEventListener('mouseup', this.endDrag);

    },     
    throttle(func, limit) {
      // console.log("throttle called")
      let lastFunc;
      let lastRan;
      return function() {
        const context = this;
        const args = arguments;
        if (!lastRan) {
          func.apply(context, args);
          lastRan = Date.now();
        } else {
          clearTimeout(lastFunc);
          lastFunc = setTimeout(function() {
            if ((Date.now() - lastRan) >= limit) {
              func.apply(context, args);
              lastRan = Date.now();
            }
          }, limit - (Date.now() - lastRan));
        }
      }
    },
    async saveModalSettings() {
      const { id, left, top, isIntern, isBroker, isKunde, $store } = this;
      
      const userLevel = isIntern ? FC_CONFIG_USER_LEVEL.INTERN :
                        isBroker ? FC_CONFIG_USER_LEVEL.MAKLER :
                        isKunde ? FC_CONFIG_USER_LEVEL.KUNDE :
                        this.userLevelDefault;

      const contentToSave = JSON.stringify({ left, top });
  
      const payload = {
        configId: id,
        configType: FC_CONFIG.RESIZABLE_MODAL_CONFIG,
        content: contentToSave,
        userLevel,
      };
  
      try {
        await $store.dispatch(FC_CONFIG_TYPES.ACTIONS.SAVE_FC_CONFIG, payload);
      } catch (error) {
        console.error('Error saving modal settings:', error);
      }
    },
    async loadModalSettings(forceReload = true) {
      if (!this.id) {
        return ;
      }

      const payload = {
        configId: this.id,
        configType: FC_CONFIG.RESIZABLE_MODAL_CONFIG,
        forceReload,
      };

      await this.$store.dispatch(FC_CONFIG_TYPES.ACTIONS.LOAD_FC_CONFIG, payload);
    },     
          
  },
  created() {
    //We'll throttle the handleDrag method to ensure it does not execute more often than every 10 milliseconds.
    this.throttledHandleDrag = this.throttle(this.handleDrag, 10);
  }, 
  mounted() {
    window.addEventListener('scroll', this.ensurePopupVisible);
    window.addEventListener('resize', this.ensurePopupVisible);
  }, 
  beforeUnmount() {
    this.teardownResizeObserver();
    document.removeEventListener('mousemove', this.throttledHandleDrag);
    document.removeEventListener('mouseup', this.endDrag);
    window.removeEventListener('scroll', this.ensurePopupVisible);
    window.removeEventListener('resize', this.ensurePopupVisible);
  },  
};
</script>

<style scoped>

.modal__title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.base-modal__header--button {
  background: none;
  border: none;
  display: flex;
  flex: 0 0 auto;
  margin: 0 0 0 6px;
  padding: 0;
  width: 20px;
  height: 20px;
}

.popup {
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 8px;
  border: 1px solid var(--color-background);
  background-color: var(--color-box);
  z-index: 9999;
  resize: both;
  overflow: auto;
}

.header {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: var(--color-box);
  cursor: grab;
}
.header > .hearder__buttons {
  display: flex;
  align-items: flex-end;
}
.content {
  padding: 20px;
}


.resizable-handle {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 16px;
  height: 16px;
  background: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 1px,
    #000 1px,
    #000 2px
  );
  cursor: nwse-resize;
}



</style>
