<template>
  <TeleportToBody>
    <ResizableModal 
      ref="resizablePopup" 
      :modalTitle="modalTitle" 
      :initialWidth="initialWidth"
      :initialHeight="initialHeight"
      :sizeIsPercentage="sizeIsPercentage"
      :startPosition="startPosition"
      :id="id"
      :fullClientHeight="fullClientHeight"
      @onMinimize="resizeObjectContainer" 
      @onMaximize="resizeObjectContainer" 
      @onResize="resizeObjectContainer">
      <GhostLoading v-if="loading">
            <Block type="paragraph" />
            <Block type="paragraph" />
            <Block type="paragraph" />
            <Block type="paragraph" />
            <Block type="paragraph" />
            <Block type="paragraph" />
            <Block type="paragraph" />
            <Block type="paragraph" />
            <Block type="paragraph" />
            <Block type="paragraph" />
            <Block type="paragraph" />
        </GhostLoading>
        <div class="object-container"  ref="objectContainer">
            <iframe  v-show="objectUrl && !loading" :src="objectUrl" width="100%" height="100%"  @load="onFileLoaded">
              Der Browser unterstützt das Format nicht. Bitte laden Sie die Datei herunter: <a :href="objectUrl">Herunterladen</a>.
            </iframe>
        </div>
    </ResizableModal>
  </TeleportToBody> 
</template>

<script>
import ResizableModal from '@/components/core/ResizableModal.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';
import TeleportToBody from '@/components/core/teleport/TeleportToBody.vue';

export default {
name: 'PreviewPDF',
components: {
  ResizableModal,
  GhostLoading,
  Block,
  TeleportToBody,
},  
props: {
  modalTitle: {
    default: 'Objekt-Vorschau'
  },
  initialHeight: {
    type: Number,
    default: 400, 
  },
  initialWidth: {
    type: Number,
    default: 600, 
  }, 
  objectUrl: {
    type: String,
  },
  sizeIsPercentage: {
    type: Boolean,
    default: false, 
  },
  startPosition: {
    type: String,
    default: 'TOP_LEFT',
    validator(value) {
      return ['TOP_RIGHT', 'TOP_LEFT', 'BOTTOM_RIGHT', 'BOTTOM_LEFT'].includes(value);
    }
  },
  fullClientHeight: {
    type: Boolean,
    default: false, 
  },
  id: {
    type: String,
    required: true,
    validator: (value) => {
      const notEmpty = !!value && !!value.trim();
      return notEmpty;
    },
  },                    
},  
data() {
  return {
    loading: true,
   };
},

methods: {
  onFileLoaded() {
    this.loading = false;
  },
  resizeObjectContainer(size) {
    let pdfContainer = document.querySelector('.object-container');
    pdfContainer.style.setProperty("min-height", `${size?.height - 80}px`, 'important');
  },
  showPopup() {
    this.loading = true;
    this.$refs.resizablePopup.showPopup();
  },
},
};
</script>

<style scoped>

.object-container {
  position: relative;
  min-width: 100%;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;  
}

.object-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}
</style>
