var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { title: "Änderungshistorie" }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.rows.length
            ? _c("Table", {
                attrs: {
                  title: _vm.historyTitle,
                  rows: _vm.rows,
                  headers: _vm.headers
                },
                on: {
                  "click-bezeichnung": _vm.openPDF,
                  action: function($event) {
                    return _vm.executeAction($event)
                  }
                }
              })
            : _vm.loading
            ? _c("GhostLoading", {
                attrs: { type: "table", config: { table: { rows: 10 } } }
              })
            : _c("NoData")
        ],
        1
      ),
      _vm.versionRows.length
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("Table", {
                attrs: {
                  title: _vm.versionTitle,
                  headers: _vm.versionHeaders,
                  rows: _vm.versionRows
                },
                on: {
                  "click-version": _vm.openPDF,
                  action: function($event) {
                    return _vm.executeAction($event)
                  }
                },
                model: {
                  value: _vm.selectedVersionDocs,
                  callback: function($$v) {
                    _vm.selectedVersionDocs = $$v
                  },
                  expression: "selectedVersionDocs"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.versionRows.length && _vm.isIntern
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _c(
                "BaseButton",
                {
                  attrs: {
                    disabled:
                      (_vm.versionRows && _vm.versionRows.length < 2) ||
                      _vm.loading
                  },
                  on: {
                    click: function($event) {
                      return _vm.executeVereinigung()
                    }
                  }
                },
                [_vm._v("Versionsvereinigung")]
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "BaseModal",
        {
          ref: "actionConfirmationModal",
          attrs: {
            modalTitle:
              (_vm.currentAction && _vm.currentAction.confirmationTitle) ||
              "Bestätigung",
            labelButtonConfirm: "Ok",
            showCancelButton: true
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.onConfirmActionModal()
            }
          }
        },
        [
          _c("label", [
            _vm._v(
              _vm._s(_vm.currentAction && _vm.currentAction.confirmationMessage)
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }