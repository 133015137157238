var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.filteredForm, function(field, fieldId, index) {
        return _c(
          "div",
          { key: index },
          [
            field.type === "LABEL"
              ? _c(
                  "div",
                  { staticClass: "col-12 font-bold" },
                  [_c("form-label", { attrs: { label: field.label } })],
                  1
                )
              : _vm._e(),
            field.type === "TEXT_CURRENCY"
              ? _c("InputField", {
                  staticClass: "mt-2",
                  attrs: {
                    label: field.label,
                    type: "currency",
                    precision: 2,
                    isComponentHalfSize: "",
                    disabled: _vm.disabled
                  },
                  on: {
                    change: function($event) {
                      return _vm.onChange({ value: $event, field: field })
                    }
                  },
                  model: {
                    value: field.value,
                    callback: function($$v) {
                      _vm.$set(field, "value", $$v)
                    },
                    expression: "field.value"
                  }
                })
              : _vm._e(),
            field.type === "TEXT_PROZENT"
              ? _c("InputField", {
                  staticClass: "mt-2",
                  attrs: {
                    label: field.label,
                    type: "percent",
                    precision: 4,
                    isComponentHalfSize: "",
                    disabled: _vm.disabled
                  },
                  on: {
                    change: function($event) {
                      return _vm.onChange({ value: $event, field: field })
                    }
                  },
                  model: {
                    value: field.value,
                    callback: function($$v) {
                      _vm.$set(field, "value", $$v)
                    },
                    expression: "field.value"
                  }
                })
              : _vm._e(),
            field.type === "TEXT_NUMBER"
              ? _c("InputField", {
                  staticClass: "mt-2",
                  attrs: {
                    label: field.label,
                    type: "number",
                    precision: 2,
                    isComponentHalfSize: "",
                    disabled: _vm.disabled
                  },
                  on: {
                    change: function($event) {
                      return _vm.onChange({ value: $event, field: field })
                    }
                  },
                  model: {
                    value: field.value,
                    callback: function($$v) {
                      _vm.$set(field, "value", $$v)
                    },
                    expression: "field.value"
                  }
                })
              : _vm._e(),
            field.type === "SINGLE_SELECTION"
              ? _c("InputRadioBoxGroup", {
                  staticClass: "mt-2",
                  attrs: {
                    label: field.label,
                    values: field.values,
                    isComponentHalfSize: "",
                    disabled: _vm.disabled,
                    vertical: false
                  },
                  on: {
                    input: function($event) {
                      return _vm.onChange({ value: $event, field: field })
                    }
                  },
                  model: {
                    value: field.value,
                    callback: function($$v) {
                      _vm.$set(field, "value", $$v)
                    },
                    expression: "field.value"
                  }
                })
              : _vm._e()
          ],
          1
        )
      }),
      _c("div", { staticClass: "cards__container" }, [
        _vm.rows && _vm.rows.length
          ? _c(
              "div",
              { staticClass: "cards__items" },
              _vm._l(_vm.rows, function(pos, index) {
                return _c(
                  "div",
                  {
                    key: pos.isin + index,
                    staticClass: "box__container cards__item"
                  },
                  [
                    _c("div", { staticClass: "cards__item--header" }, [
                      _c(
                        "div",
                        { staticClass: "box__title cards__item--header-title" },
                        [
                          _c("span", [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.navigateToFondsInfo(pos)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(pos && pos.wertpapier))]
                            )
                          ]),
                          _c("span", { staticClass: "text-divider" }, [
                            _vm._v("|")
                          ]),
                          _c("span", { class: _vm.getColorClass(pos) }, [
                            _vm._v(_vm._s(_vm.getOrderTyp(pos)))
                          ]),
                          _c("span", { staticClass: "text-divider" }, [
                            _vm._v("|")
                          ]),
                          _c("span", [_vm._v(_vm._s(pos && pos.betrag))])
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "cards__item--header-actions" },
                        [
                          _c(
                            "a",
                            {
                              attrs: { title: "Position löschen" },
                              on: {
                                click: function($event) {
                                  return _vm.deletePositionConfirmation(pos)
                                }
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "cards__item--header-action--item-icon"
                                },
                                [_c("PhTrash", { attrs: { size: 16 } })],
                                1
                              ),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "cards__item--header-action--item-text"
                                },
                                [_vm._v("Position löschen")]
                              )
                            ]
                          )
                        ]
                      )
                    ]),
                    pos
                      ? _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "font-bold col-lg-4 col-12" },
                            [_vm._v("ISIN: " + _vm._s(pos.isin))]
                          ),
                          _c(
                            "div",
                            { staticClass: "font-bold col-lg-4 col-12" },
                            [_vm._v("Antrag: " + _vm._s(pos.antrag))]
                          ),
                          _c(
                            "div",
                            { staticClass: "font-bold col-lg-4 col-12" },
                            [_vm._v("Perf. Fee: " + _vm._s(pos.perffee) + "%")]
                          )
                        ])
                      : _vm._e(),
                    pos
                      ? _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "font-bold col-lg-4 col-12" },
                            [
                              _vm._v(
                                "lauf. Kosten: " + _vm._s(pos.laufk) + "% p.a."
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "font-bold col-lg-4 col-12" },
                            [
                              _vm._v(
                                "davon Anteil WPD " +
                                  _vm._s(pos.anteilwpd) +
                                  "%"
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "font-bold col-lg-4 col-12" },
                            [_vm._v("Bepro: " + _vm._s(pos.bepro) + "%")]
                          )
                        ])
                      : _vm._e(),
                    _vm.form["input_wpa_entry_aa" + pos.id]
                      ? _c("InputField", {
                          attrs: {
                            type: "percent",
                            label: "Ausgabeaufschlag",
                            id: "input_wpa_entry_aa" + pos.id,
                            disabled:
                              _vm.disabled ||
                              _vm.form["input_wpa_entry_aa" + pos.id].disabled,
                            isComponentHalfSize: ""
                          },
                          on: { input: _vm.onChange },
                          model: {
                            value:
                              _vm.form["input_wpa_entry_aa" + pos.id].value,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.form["input_wpa_entry_aa" + pos.id],
                                "value",
                                $$v
                              )
                            },
                            expression:
                              "form['input_wpa_entry_aa'+pos.id].value"
                          }
                        })
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _vm.form[
                          "input_wpa_entry_transaktion_kauf_wert" + pos.id
                        ]
                          ? _c("InputField", {
                              staticClass:
                                "col-12 col-lg-auto forms__input--half-size",
                              attrs: {
                                type: "number",
                                label: _vm.transaktionskostenLabel(pos),
                                id:
                                  "input_wpa_entry_transaktion_kauf_wert" +
                                  pos.id,
                                disabled:
                                  _vm.disabled ||
                                  _vm.form[
                                    "input_wpa_entry_transaktion_kauf_wert" +
                                      pos.id
                                  ].disabled
                              },
                              on: { input: _vm.onChange },
                              model: {
                                value:
                                  _vm.form[
                                    "input_wpa_entry_transaktion_kauf_wert" +
                                      pos.id
                                  ].value,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form[
                                      "input_wpa_entry_transaktion_kauf_wert" +
                                        pos.id
                                    ],
                                    "value",
                                    $$v
                                  )
                                },
                                expression:
                                  "form['input_wpa_entry_transaktion_kauf_wert'+pos.id].value"
                              }
                            })
                          : _vm._e(),
                        _vm.form[
                          "input_wpa_entry_transaktion_kauf_rg_proz_eur" +
                            pos.id
                        ]
                          ? _c("InputRadioBoxGroup", {
                              staticClass: "col-auto radio-row",
                              attrs: {
                                values:
                                  _vm.form[
                                    "input_wpa_entry_transaktion_kauf_rg_proz_eur" +
                                      pos.id
                                  ].values,
                                disabled:
                                  _vm.disabled ||
                                  _vm.form[
                                    "input_wpa_entry_transaktion_kauf_rg_proz_eur" +
                                      pos.id
                                  ].disabled,
                                vertical: false
                              },
                              on: { input: _vm.onChange },
                              model: {
                                value:
                                  _vm.form[
                                    "input_wpa_entry_transaktion_kauf_rg_proz_eur" +
                                      pos.id
                                  ].value,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form[
                                      "input_wpa_entry_transaktion_kauf_rg_proz_eur" +
                                        pos.id
                                    ],
                                    "value",
                                    $$v
                                  )
                                },
                                expression:
                                  "form['input_wpa_entry_transaktion_kauf_rg_proz_eur'+pos.id].value"
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _vm.form[
                          "input_wpa_entry_transaktion_verkauf_wert" + pos.id
                        ]
                          ? _c("InputField", {
                              staticClass:
                                "col-12 col-lg-auto forms__input--half-size",
                              attrs: {
                                type: "number",
                                label: "Transaktionskosten Verkauf",
                                id:
                                  "input_wpa_entry_transaktion_verkauf_wert" +
                                  pos.id,
                                disabled:
                                  _vm.disabled ||
                                  _vm.form[
                                    "input_wpa_entry_transaktion_verkauf_wert" +
                                      pos.id
                                  ].disabled
                              },
                              on: { input: _vm.onChange },
                              model: {
                                value:
                                  _vm.form[
                                    "input_wpa_entry_transaktion_verkauf_wert" +
                                      pos.id
                                  ].value,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form[
                                      "input_wpa_entry_transaktion_verkauf_wert" +
                                        pos.id
                                    ],
                                    "value",
                                    $$v
                                  )
                                },
                                expression:
                                  "form['input_wpa_entry_transaktion_verkauf_wert'+pos.id].value"
                              }
                            })
                          : _vm._e(),
                        _vm.form[
                          "input_wpa_entry_transaktion_verkauf_rg_proz_eur" +
                            pos.id
                        ]
                          ? _c("InputRadioBoxGroup", {
                              staticClass: "col-auto radio-row",
                              attrs: {
                                values:
                                  _vm.form[
                                    "input_wpa_entry_transaktion_verkauf_rg_proz_eur" +
                                      pos.id
                                  ].values,
                                disabled:
                                  _vm.disabled ||
                                  _vm.form[
                                    "input_wpa_entry_transaktion_verkauf_rg_proz_eur" +
                                      pos.id
                                  ].disabled,
                                vertical: false
                              },
                              on: { input: _vm.onChange },
                              model: {
                                value:
                                  _vm.form[
                                    "input_wpa_entry_transaktion_verkauf_rg_proz_eur" +
                                      pos.id
                                  ].value,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form[
                                      "input_wpa_entry_transaktion_verkauf_rg_proz_eur" +
                                        pos.id
                                    ],
                                    "value",
                                    $$v
                                  )
                                },
                                expression:
                                  "form['input_wpa_entry_transaktion_verkauf_rg_proz_eur'+pos.id].value"
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm.form["input_wpa_entry_sparplan_raten" + pos.id]
                      ? _c("InputField", {
                          staticClass:
                            "col-12 col-lg-auto forms__input--half-size",
                          attrs: {
                            type: "number",
                            precision: 0,
                            label: "Anzahl Raten",
                            id: "input_wpa_entry_sparplan_raten" + pos.id,
                            disabled:
                              _vm.disabled ||
                              _vm.form[
                                "input_wpa_entry_sparplan_raten" + pos.id
                              ].disabled
                          },
                          on: { change: _vm.onChange },
                          model: {
                            value:
                              _vm.form[
                                "input_wpa_entry_sparplan_raten" + pos.id
                              ].value,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.form[
                                  "input_wpa_entry_sparplan_raten" + pos.id
                                ],
                                "value",
                                $$v
                              )
                            },
                            expression:
                              "form['input_wpa_entry_sparplan_raten'+pos.id].value"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              }),
              0
            )
          : _vm._e()
      ]),
      _c(
        "BaseModal",
        {
          ref: "deletePositionConfirmation",
          attrs: {
            modalTitle: "Position löschen",
            labelButtonConfirm: "Löschen"
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.deletePosition()
            }
          }
        },
        [_vm._v(" " + _vm._s(_vm.deletePositionMessage) + " ")]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }