var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-forms__container" }, [
    _c("label", { staticClass: "input-forms__label-container" }, [
      _vm.label
        ? _c("div", { staticClass: "input-forms__label-content" }, [
            _vm._v(_vm._s(_vm.label))
          ])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "layout__negative-margin--8 d-flex",
          class: { "forms__input--half-size": this.isComponentHalfSize }
        },
        [
          _c(
            "div",
            { staticClass: "entity-selector__combobox-container" },
            [
              _c("ComboBox", {
                attrs: {
                  renderDanger:
                    _vm.isValidationConfigured() &&
                    _vm.validation.isInvalid(this.validationPath) &&
                    _vm.validation.isDirty(this.validationPath),
                  isEntitySelector: "",
                  values: _vm.values,
                  value: _vm.internalValue,
                  disabled: _vm.disabled,
                  firstEmpty: _vm.firstEmpty,
                  sortComboboxValues: _vm.sortComboboxValues,
                  isEmbedded: ""
                },
                on: { input: _vm.onChange }
              })
            ],
            1
          ),
          !_vm.hideNewButton
            ? _c(
                "div",
                {
                  staticClass:
                    "entity-selector__button input-forms__buttons--line-height d-flex clickable"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.doClick("new")
                        }
                      }
                    },
                    [
                      _vm.iconNew === "default"
                        ? _c("ph-file-plus", { attrs: { size: 24 } })
                        : _vm._e(),
                      _vm.iconNew === "person"
                        ? _c("ph-user-plus", { attrs: { size: 24 } })
                        : _vm._e()
                    ],
                    1
                  )
                ]
              )
            : _vm._e(),
          _vm.isSomethingSelected && _vm.hasEditButton
            ? _c(
                "div",
                {
                  staticClass:
                    "entity-selector__button input-forms__buttons--line-height d-flex clickable"
                },
                [
                  _c("ph-pencil-line", {
                    attrs: { size: 24 },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.$emit("edit", _vm.internalValue)
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _vm.isValidationConfigured() &&
      !_vm.suppressValidationMessage &&
      _vm.validation.isInvalid(this.validationPath, this.validateUntouched)
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c(
                "div",
                { key: _vm.validation.updated },
                _vm._l(
                  _vm.validation.getErrors(
                    this.validationPath,
                    this.validateUntouched
                  ),
                  function(error) {
                    return _c(
                      "div",
                      { key: error, staticClass: "fc-form-danger" },
                      [_vm._v(" " + _vm._s(error) + " ")]
                    )
                  }
                ),
                0
              )
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }