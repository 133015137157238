import { getColorLimitedListBasedOnLightingDecreasing } from '@/helpers/colors-helper';

function compare(a, b) {
    if (a.startTime < b.startTime) {
        return -1;
    }
    if (a.startTime > b.startTime) {
        return 1;
    }
    return 0;
}

function getLighterColor(hex) {
    const colors = getColorLimitedListBasedOnLightingDecreasing(hex, {
        quantity: 1, 
        fromStepsHigher: 4, 
    });

    return colors?.[0] || hex;
}

export function isActivityTypeBereich(bereich) {
    return !(bereich === 'Termin')
}

function separateEvents(item) {
    let allDayEvents = [];
    let timedEvents = [];
    for (let sp = 0; sp < item.events.length; sp++) {
        if (item.events[sp]?.wholeDay && (item.events[sp].startTime === "00:00" && item.events[sp].endTime === "23:59"
            || item.events[sp].bereich !== 'Termin')) {
            allDayEvents.push(item.events[sp]);
        } else {
            timedEvents.push(item.events[sp]);
        }
    }
    return ({ date: item.date, allDayEvents: allDayEvents, timedEvents: timedEvents });
}

function getConcurrentEvents(timedEvents) {
    let endConcurrArray = [];
    if (timedEvents !== undefined) {
        for (let i = timedEvents.length - 1; i > 0; i--) {
            let tempnum = 0;
            for (let k = i - 1; k > -1; k--) {
                if (
                    dayjs(timedEvents[i].startTime, "HH:mm").isBefore(
                        dayjs(timedEvents[k].endTime, "HH:mm")
                    )
                ) {
                    tempnum = tempnum + 1;
                }
            }
            if (tempnum > 0) {
                endConcurrArray.push(tempnum);
            }
        }
        let finalnum = 0;
        for (let j = 0; j < endConcurrArray.length; j++) {
            if (endConcurrArray[j] > finalnum) finalnum = endConcurrArray[j];
        }
        return finalnum;
    } else return 0;
}

function checkIfAlreadyPlaced(state, index) {
    let cea = state.columnEventArray;

    for (let c = 0; c < cea.length; c++) {
        if (cea[c].eventIndex === index) return true;
        else continue;
    }
    return false;
}

function checkIfRightPlace(state, index, position) {
    let cea = state.columnEventArray;
    for (let d = 0; d < cea.length; d++) {
        if (
            cea[d].eventIndex === index &&
            cea[d].position === position
        )
            return true;
        else continue;
    }
    return false;
}

function getContent(state, commit, hour, timedEvents, maxconcurrent) {
    if (timedEvents.length > 0) {
        let columns = maxconcurrent + 1;
        let content = [];

        for (let i = 0; i < columns; i++) {

            let columnContent = [];
            let latestEndTime = 0;
            for (let j = 0; j < timedEvents.length; j++) {

                let calendarEvent = {
                    column: i,
                    eventID: "",
                    isStart: false,
                    isEnd: false,
                    event: null
                };

                let thisevent = timedEvents[j];
                let hourBeforeEnd = dayjs(thisevent.endTime, "HH:mm").subtract(1, "hour");

                let isalreadyplaced = checkIfAlreadyPlaced(state, j);
                if (isalreadyplaced) {
                    let inrightplace = checkIfRightPlace(state, j, i);
                    if (inrightplace) {
                        if (
                            dayjs(hour, "HH").isBetween(
                                dayjs(thisevent.startTime, "HH"),
                                dayjs(thisevent.endTime, "HH")
                            )
                        ) {
                            if (dayjs(hourBeforeEnd).isSame(dayjs(hour, "HH:mm"), "hour") && dayjs(hourBeforeEnd).isSame(dayjs(hour, "HH:mm"), "minute")) calendarEvent.isEnd = true;

                            calendarEvent.eventID = j;
                            calendarEvent.event = thisevent;
                            columnContent.push(calendarEvent);
                            break;
                        } else if (
                            dayjs(hour, "HH").isSame(dayjs(thisevent.endTime, "HH"))
                        ) {
                            if (dayjs(hour, "HH:mm").isBefore(dayjs(thisevent.endTime, "HH:mm"))) {
                                calendarEvent.eventID = j;
                                calendarEvent.isEnd = true;
                                calendarEvent.event = thisevent;
                                columnContent.push(calendarEvent);
                                latestEndTime = Number(thisevent.endTime.split(":")[1]);
                            }
                        }
                    } else continue;
                } else {
                    if (
                        dayjs(thisevent.startTime, "HH").isSame(
                            dayjs(hour, "HH"),
                            "hour"
                        )
                    ) {

                        if (Number(thisevent.startTime.split(":")[1]) < latestEndTime) continue;
                        if (dayjs(thisevent.endTime, "HH:mm").isSame(dayjs(hour, "HH:mm"), "hour") || (dayjs(hourBeforeEnd).isSame(dayjs(hour, "HH:mm"), "hour") && dayjs(hourBeforeEnd).isSame(dayjs(hour, "HH:mm"), "minute"))) {
                            calendarEvent.isEnd = true;
                            calendarEvent.isStart = true;
                            calendarEvent.eventID = j;
                            calendarEvent.event = thisevent;
                            columnContent.push(calendarEvent);
                            latestEndTime = Number(thisevent.endTime.split(":")[1]);
                            commit(
                                CALENDAR_TYPES.MUTATIONS.PUSH_COLUMN_EVENT_ARRAY,
                                {
                                    eventIndex: j,
                                    position: i
                                }
                            );
                            continue;
                        }

                        calendarEvent.isStart = true;
                        calendarEvent.eventID = j;
                        calendarEvent.event = thisevent;
                        columnContent.push(calendarEvent);
                        commit(
                            CALENDAR_TYPES.MUTATIONS.PUSH_COLUMN_EVENT_ARRAY,
                            {
                                eventIndex: j,
                                position: i
                            }
                        );
                        break;
                    }
                }
            }
            content.push(columnContent);
        }
        return content;
    }
}
function createWeeksContent(commit, refinedCalendarData) {
    let weeksContent = [];
    let week = [];
    let counter = 0
    for (let wdata = 0; wdata < refinedCalendarData.length; wdata++) {
        week.push(refinedCalendarData[wdata]);
        counter++;
        if (counter === 7) {
            weeksContent.push(week);
            week = [];
            counter = 0;
        }
    }
    commit(CALENDAR_TYPES.MUTATIONS.SET_WEEKS_CONTENT, weeksContent);

}

function createHoursContent(state, commit, refinedCalendarData) {
    let shownhours = state.shownHours;
    let hoursContent = [];

    for (let cdata = 0; cdata < refinedCalendarData.length; cdata++) {
        let timedEvents = refinedCalendarData[cdata].timedEvents;
        let date = refinedCalendarData[cdata].date;
        let maxconcurrent = getConcurrentEvents(timedEvents);

        for (let hourIdx = 0; hourIdx < shownhours.length; hourIdx++) {
            let content = getContent(state, commit, shownhours[hourIdx], timedEvents, maxconcurrent);
            hoursContent.push({
                date: date,
                hour: shownhours[hourIdx],
                content: content
            });
        }
        commit(CALENDAR_TYPES.MUTATIONS.RENEW_COLUMN_EVENT_ARRAY);
    }
    commit(CALENDAR_TYPES.MUTATIONS.SET_HOURS_CONTENT, hoursContent);
}

import CALENDAR_TYPES from './types';
import LOG_TYPES from '@/store/log/types';
import axios from 'axios';
import dayjs from 'dayjs';
import {buildMessage} from '@/helpers/log-message-helper';

export default {

    async [CALENDAR_TYPES.ACTIONS.RETRIEVE_CALENDAR_DATA]({ commit, state, rootState, dispatch }, payload) {
        const config = {
            defaultSpinner: true,
            params: payload.datesToSend
        };
        commit(CALENDAR_TYPES.MUTATIONS.RENEW_CALENDAR_DATA)
        // axios.get(rootState.core.apiAddress + '/calendarService/getcalendar', payload.datesToSend, config).then(response => {
        await axios.get(rootState.core.apiAddress + '/calendarService/getCalendar', config).then(response => {
            let propArray = [];
            if (response && response.data && response.data.data) {
                Object.keys(response.data.data).forEach(function (key, index) {
                    propArray.push(key);
                });
            }
            let calendarData = [];
            for (let prop = 0; prop < propArray.length; prop++) {
                let thiskey = propArray[prop];
                let eventsArray = [];
                for (let inob = 0; inob < response.data.data[thiskey].length; inob++) {
                    const eventItem = response.data.data[thiskey][inob];
                    eventItem.color = eventItem.color || document.documentElement.style.getPropertyValue('--color-primary');
                    eventItem.lighterColor = getLighterColor(eventItem.color);
                    eventsArray.push(eventItem);
                }
                eventsArray.sort(compare);
                calendarData.push({
                    date: dayjs(thiskey, "YYYYMMDD").format("D.MM.YYYY"),
                    events: eventsArray
                });
            }
            let separatedArray = calendarData.map(separateEvents);
            let startDate = dayjs(payload.datesToSend.fromDate, "DD.MM.YYYY");
            let endDate = dayjs(payload.datesToSend.toDate, "DD.MM.YYYY");
            let totaldays = endDate.diff(startDate, "day") + 1;
            let refinedArray = [];

            for (let dd = 0; dd < totaldays; dd++) {
                let thisday = dayjs(startDate).add(dd, "day");
                let allDayEvents = [];
                let timedEvents = [];
                for (let cld = 0; cld < separatedArray.length; cld++) {
                    if (dayjs(separatedArray[cld].date, "D.MM.YYYY").isSame(dayjs(thisday))) {
                        allDayEvents = separatedArray[cld].allDayEvents;
                        timedEvents = separatedArray[cld].timedEvents;
                        break;
                    }
                }
                refinedArray.push({
                    date: dayjs(thisday).format("D.MM.YYYY"),
                    allDayEvents: allDayEvents,
                    timedEvents: timedEvents
                })

            }
            commit(CALENDAR_TYPES.MUTATIONS.SET_CALENDAR_DATA, refinedArray);
            if (refinedArray.length === 7) commit(CALENDAR_TYPES.MUTATIONS.SET_WEEK_CALENDAR_DATA, refinedArray);
            if (payload.createHours) createHoursContent(state, commit, refinedArray);
            if (payload.createWeeks) createWeeksContent(commit, refinedArray);
            
            commit(CALENDAR_TYPES.MUTATIONS.SET_DATES, payload.datesToSend);

        })
    },

    [CALENDAR_TYPES.ACTIONS.CREATE_WEEK_CALENDAR_DATA]({ commit, state }, payload) {
        let lessDaysArray = [];
        for (let refdidx = 0; refdidx < state.refinedCalendarData.length; refdidx++) {
            if (dayjs(state.refinedCalendarData[refdidx].date, "D.MM.YYYY").isSameOrAfter(dayjs(payload.fromDate, "D.MM.YYYY"), "date") && dayjs(state.refinedCalendarData[refdidx].date, "D.MM.YYYY").isSameOrBefore(dayjs(payload.toDate, "D.MM.YYYY"), "date")) {
                lessDaysArray.push(state.refinedCalendarData[refdidx]);
            }
        }
        commit(CALENDAR_TYPES.MUTATIONS.SET_WEEK_CALENDAR_DATA, lessDaysArray);
        createHoursContent(state, commit, lessDaysArray);
    },

    [CALENDAR_TYPES.ACTIONS.RETRIEVE_SELECTED_APPOINTMENT]({ commit, rootState, state }, payload) {
        const config = {
            defaultSpinner: true
        };
        if (state.selectedAppointment?.appointment?.sid && state.selectedAppointment.appointment.sid != payload) {
            commit(CALENDAR_TYPES.MUTATIONS.LOAD_APPOINTMENT, payload);
            commit(CALENDAR_TYPES.MUTATIONS.SET_SELECTED_APPOINTMENT_DATA, { appointment: { appointment: state.emptyAppointment}, day: null });
        }
        axios.get(rootState.core.apiAddress + '/calendarService/getAppointment/' + payload, config).then(response => {
            commit(CALENDAR_TYPES.MUTATIONS.SET_SELECTED_APPOINTMENT_DATA, { appointment: response.data, day: null });
            commit(CALENDAR_TYPES.MUTATIONS.LOAD_APPOINTMENT, '');
        })
    },

    [CALENDAR_TYPES.ACTIONS.RETRIEVE_APPOINTMENT_COMBOS]({ commit, rootState, dispatch }) {
        const config = {
            defaultSpinner: true
        };
        axios.get(rootState.core.apiAddress + '/calendarService/getAppointmentCombos/', config).then(response => {
            commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_COMBOS, response.data);
        })
    },

    [CALENDAR_TYPES.ACTIONS.GET_APPOINTMENT_SETUP]({ commit, rootState, getters, }) {
        if(getters[CALENDAR_TYPES.GETTERS.GET_APPOINTMENT_SETUP]) return;

        const config = {
            defaultSpinner: true
        };
        axios.get(rootState.core.apiAddress + '/calendarService/getAppointmentSetup', config).then(response => {
            commit(CALENDAR_TYPES.MUTATIONS.GET_APPOINTMENT_SETUP, response.data);
        })
    },

    [CALENDAR_TYPES.ACTIONS.UPDATE_BETEILIGTE]({ rootState,state, commit, dispatch }, payload) {
        const config = {
            defaultSpinner: true,
        };
        if (state.selectedAppointment?.appointment?.sid) {
            axios.post(rootState.core.apiAddress + `/calendarService/setBeteiligte/${state.selectedAppointment.appointment.sid}`, payload, config).then(response => {
                commit(CALENDAR_TYPES.MUTATIONS.SET_SELECTED_APPOINTMENT_DATA, { appointment: response.data });
            })
        }
    },

    async [CALENDAR_TYPES.ACTIONS.GET_EMPFAENGER]({ rootState }, payload) {
        const config = {
            defaultSpinner: true
        };
        const result = axios.get(rootState.core.apiAddress + '/calendarService/getEmpfaenger?maklernr='+payload, config)
        return result;
    },

    [CALENDAR_TYPES.ACTIONS.SAVE_APPOINTMENT]({ rootState, state, commit, dispatch }, payload) {
        const config = {
            defaultSpinner: true
        };
        let beginTime = state.appointmentBeginTime == "" ? "00:00" : state.appointmentBeginTime;
        let endTime = state.appointmentEndTime == "" ? "23:59" : (state.appointmentEndTime == '24:00' ? '23:00' : state.appointmentEndTime);

        let beginDate = "";
        let endDate = "";
        if (dayjs(state.appointmentBeginDate, 'DD.MM.YYYY').isValid())
            beginDate = dayjs(state.appointmentBeginDate, 'DD.MM.YYYY').format("DD.MM.YYYY");//, 'DD.MM.YYYY'
        else beginDate = state.appointmentBeginDate;

        
        beginDate = beginDate instanceof Date ? 
          dayjs(beginDate).format('DD.MM.YYYY')
          : beginDate
        

        if (dayjs(state.appointmentEndDate, 'DD.MM.YYYY').isValid())
            endDate = dayjs(state.appointmentEndDate, 'DD.MM.YYYY').format("DD.MM.YYYY");//, 'DD.MM.YYYY'
        else endDate = state.appointmentEndDate;

        endDate = endDate instanceof Date ? 
          dayjs(endDate).format('DD.MM.YYYY')
          : endDate
        
        state.selectedAppointment.appointment.begin = beginDate + " " + beginTime;
        state.selectedAppointment.appointment.end = endDate + " " + endTime;

        if (state.selectedAppointment.appointment.fullDay) {
            beginTime = "00:00";
            endTime = "23:59";
        }
        if (beginTime == "00:00" && (endTime == "00:00" || endTime == "23:59")) state.selectedAppointment.appointment.fullDay = true;
        if (beginTime === endTime && beginDate === endDate) {
            alert("Beginzeitpunkt und Endezeitpunkt sind gleich. Der Termin wird als ganztägiger gespeichert.");
            beginTime = "00:00";
            endTime = "23:59";
            state.selectedAppointment.appointment.fullDay = true;
        }

        let isNew = false
        let url = '/calendarService/setAppointment/';
        if (!state.selectedAppointment.appointment.sid) {
            isNew = true;
            url = '/calendarService/createAppointment';
        }

        const appointment = {...state.selectedAppointment.appointment,
            ...((state.selectedAppointment.appointment.activity || state.selectedAppointment.appointment.fullDay) ? {
                begin: beginDate + ' ' + '00:00',
                end: endDate + ' ' + '23:59',
                fullDay: true,
            } : {}),
        }

        let updateBeteiligte = Promise.resolve();
        if (!isNew && (rootState.customerFilters.selectedCustomers.length || state.beteiligteForUpdate.length)){
            updateBeteiligte = dispatch(CALENDAR_TYPES.ACTIONS.SET_BETEILIGTE);
        }

        return updateBeteiligte.then(r => axios.post(rootState.core.apiAddress + url, {
            appointment,
            beteiligte: state.beteiligteForUpdate.map(bet => bet.beteiligte || bet).map(v => {
                const {value, label, id, ...data} = v
                return {
                    id: isNew ? null : id,
                    ...data
                }
            })
        }, config).then(response => {
            const appointment = response.data.appointment;
            const dateKey = dayjs(response.data.appointment.begin, 'DD.MM.YYYY HH:mm:ss');
            appointment.startTime = dateKey.format('HH:mm');
            appointment.endTime = dayjs(response.data.appointment.end, 'DD.MM.YYYY HH:mm:ss').format('HH:mm');
            const updatedPayload = (rootState.calendar.refinedCalendarData || []).map(day => {
                if (day.date === (dateKey.format('DD.MM.YYYY') || '0')) {
                    const allDayEvents = day.allDayEvents
                    const timedEvents = day.timedEvents
                    if (response.data.appointment.activity || response.data.appointment.fullDay) {
                        allDayEvents.push(response.data.appointment);
                    } else {
                        timedEvents.push(response.data.appointment);
                    }
                    return {
                        ...day,
                        allDayEvents,
                        timedEvents,
                    }
                }
                return day
            })
            commit(CALENDAR_TYPES.MUTATIONS.SET_CALENDAR_DATA, updatedPayload);

            commit(CALENDAR_TYPES.MUTATIONS.SET_SELECTED_APPOINTMENT_DATA, { appointment: response.data, day: null });
            const previousFileIds = state.selectedAppointment?.files.map(v => v.id) || []
            if (rootState.calendar.attachments && rootState.calendar.attachments.length > 0) {
                rootState.calendar.attachments
                    .filter(file => !previousFileIds.includes(file.id))
                    .map(attachment => dispatch(CALENDAR_TYPES.ACTIONS.SET_ATTACHMENT, attachment.id))
            }
            
            if (rootState.calendar.datesToSend && Object.keys(rootState.calendar.datesToSend).length) {
                dispatch(CALENDAR_TYPES.ACTIONS.RETRIEVE_CALENDAR_DATA, {
                    datesToSend: rootState.calendar.datesToSend,
                    createHours: true,
                    createWeeks: true,
                })
            }
            return response;
        }).catch((error) => {
            console.log('Error', error)
            return Promise.reject();
        }))
    },

    async [CALENDAR_TYPES.ACTIONS.DELETE_APPOINTMENT]({ rootState, state, dispatch }) {
        const config = {
            defaultSpinner: true
        };
        if (
            await axios.delete(rootState.core.apiAddress + '/calendarService/delAppointment/' + state.selectedAppointment.appointment.sid, config)
            .then(res => {
                if (rootState.calendar.datesToSend && Object.keys(rootState.calendar.datesToSend).length) {
                    dispatch(CALENDAR_TYPES.ACTIONS.RETRIEVE_CALENDAR_DATA, {
                        datesToSend: rootState.calendar.datesToSend,
                        createHours: true,
                        createWeeks: true,
                    })
                }
            })
        )
        return true;
        else return false;
    },

    [CALENDAR_TYPES.ACTIONS.SET_BETEILIGTE]({ rootState, state, dispatch }) {
        const config = {
            defaultSpinner: true
        };
        let beteiligteList = [];
        let besitzerCount = 0;

        let besitzer = null
        state.beteiligteForUpdate.forEach(bet => {
            if (bet.rolle==="BESITZER") {
                besitzerCount++;
                besitzer = bet
            } else {
                beteiligteList.push(bet.beteiligte || bet);
            }
        })
        if (besitzer) {
            beteiligteList.unshift(besitzer);
        }

        if (besitzerCount > 1) {
            dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Nur ein Besitzer darf bestimmt werden!', 'danger'));
            return Promise.reject();
        }
        
        // return Promise.all(beteiligteList.map(beteiligte => 
        //     axios.post(rootState.core.apiAddress + '/calendarService/setBeteiligte/' + state.selectedAppointment.appointment.sid, beteiligte, config)
        // ))
    },
    
    [CALENDAR_TYPES.ACTIONS.DELETE_BETEILIGTE]({ rootState, dispatch, commit },payload) {
        const config = {
            defaultSpinner: true
        };
        let beteiligteID =payload.beteiligte.id;
        axios.delete(rootState.core.apiAddress + '/calendarService/delBeteiligte/' + beteiligteID, config).then((response)=>{
            if (response && response.data){
                commit(CALENDAR_TYPES.MUTATIONS.DELETE_BETEILIGTE_SUCCESS, response.data);
                commit(CALENDAR_TYPES.MUTATIONS.DELETE_BETEILIGTE_BY_VALUE, beteiligteID);
            }
        })
    },
    async [CALENDAR_TYPES.ACTIONS.GET_CUSTOMER_APPOINTMENTS]({ rootState, dispatch, commit }) {
        const config = {
            defaultSpinner: true
        };
        await axios.get(rootState.core.apiAddress + '/calendarService/getCusomerAppointments', config).then((response)=>{
            commit(CALENDAR_TYPES.MUTATIONS.GET_CUSTOMER_APPOINTMENTS, response.data);
        })
    },
    [CALENDAR_TYPES.ACTIONS.SET_ATTACHMENT]({ rootState,state, commit, dispatch }, payload) {
        const config = {
            defaultSpinner: true,
        };
        axios.post(rootState.core.apiAddress + `/calendarService/addAttachment?sid=${state.selectedAppointment.appointment.sid}&tempFileId=${payload}`, {}, config).then((response)=>{
            commit(CALENDAR_TYPES.MUTATIONS.SET_ATTACHMENT, response.data);
        })
    },
    [CALENDAR_TYPES.ACTIONS.DELETE_ATTACHMENT]({ rootState,state, commit, dispatch }, payload) {
        if (!state.selectedAppointment.appointment.sid) {
            return
        }
        const config = {
            defaultSpinner: true,
            params: {
                sid: state.selectedAppointment.appointment.sid,
                fileId: payload
            }
        };
        axios.delete(rootState.core.apiAddress + '/calendarService/deleteAttachment', config).then((response)=>{
            commit(CALENDAR_TYPES.MUTATIONS.SET_ATTACHMENT, response.data);
        })
    },

};

