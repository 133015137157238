var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c(
            "BoxContainer",
            { attrs: { title: "" } },
            [
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "box__title",
                      staticStyle: { "flex-grow": "1" }
                    },
                    [_vm._v("Wertentwicklung")]
                  ),
                  _vm.zoom
                    ? _c("ComboBox", {
                        attrs: { value: _vm.years, values: _vm.zoom },
                        on: { change: _vm.onChangeYear }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c("ChartFunds", {
                attrs: { type: "Fondsinfo", chartData: _vm.chartPerf }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c(
            "BoxContainer",
            { attrs: { title: "Jährliche Rendite" } },
            [
              _c("BarChart", {
                attrs: {
                  format: "percent",
                  height: "400px",
                  chartOptions: _vm.chartRendite,
                  gridLeft: "0%"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c(
            "BoxContainer",
            { attrs: { title: "Rendite / Risiko" } },
            [
              _c("ChartYieldRisk", {
                attrs: { chartParam: _vm.riskChartParams }
              })
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }