<template>
  <div class="edge-detection-container">
    <canvas v-if="videoInput" id="videoDestination" ref="c0" v-bind:class="{ 'edge-detection-canvas-debug': debug }"/>
    <canvas v-else-if="imageInput" id="videoDestination" ref="c0" v-bind:class="{ 'edge-detection-canvas-debug': debug }"/>
    <img v-if="imgUrl" ref="src" v-bind:src="imgUrl"/>
  </div>
</template>

<script lang="ts">
import LOG_TYPES from '@/store/log/types';
import Vue from 'vue';

function rotate90(vector) {
  return {x: -vector.y, y: vector.x};
}
function toRadians(degrees) {
  return degrees * Math.PI / 180;
}
function pointDistance(pointA, pointB) {
  return Math.sqrt((pointA.x - pointB.x) * (pointA.x - pointB.x) + (pointA.y - pointB.y) * (pointA.y - pointB.y));
}
function normalizedPointDistance(pointA, pointB, normal) {
  return (pointB.x - pointA.x) * normal.x + (pointB.y - pointA.y) * normal.y;
}
function dotProduct(vectorA, vectorB) {
  return vectorA.x * vectorB.x + vectorA.y * vectorB.y;
}
function createLineObject(startPoint, endPoint, imgCenter) {
  const length = pointDistance(startPoint, endPoint);
  const normal = {x: (endPoint.y - startPoint.y) / length, y: (startPoint.x - endPoint.x) / length};
  let distance = normalizedPointDistance(imgCenter, startPoint, normal);
  if (distance < 0) {
    normal.x *= -1;
    normal.y *= -1;
    distance *= -1;
    const p = startPoint;
    startPoint = endPoint;
    endPoint = p;
  }
  const normalAngle = (720 + Math.atan2(normal.y, normal.x) * 180 / Math.PI) % 180;
  return{
    startPoint,
    endPoint,
    normal,
    length,
    distance,
    normalAngle,
  };
}

export default Vue.extend({
  props: {
    imgUrl: {
      default: null,
    },
    videoInput: {
      default: null,
    },
    imageInput: {
      default: null,
    },
    debugStage: {
      type: String,
      default: "-1",
    }
  },

  data: () => {
    return {
      interval: null,
      debug: false,
      angleQuality: 0.9,
      srcCtx: null,
      dstCtx: null,
      fps: 30,
      frameCounter: -1,
      cornerSearchPause: 6, // in frames
      cvData: [],
      startTime: null,
      gaussSize: 15,
      previousCorners: null,
      emittedCorners: null,
    };
  },

  computed: {
  },

  methods: {
    startRendering () {
      if (this.interval != null)
        return;
      this.interval = setInterval(() => requestAnimationFrame(() => this.render()), 1000 / this.fps);
    },
    render() {
      this.frameCounter = (this.frameCounter + 1) % this.cornerSearchPause;
      if (this.frameCounter == 0)
        this.searchCorners();
      const progress = (this.frameCounter + 1) / this.cornerSearchPause;
      this.renderRectangle(progress);
      if (progress == 1) {
        this.previousCorners = this.emittedCorners;
      }
    },
    renderRectangle(progress) {
      if (this.previousCorners || this.emittedCorners) {
        const inputElement = this.videoInput || this.imageInput || this.$refs.src;
        const canvas = this.$refs.overlay;
        if (!canvas) {
          return;
        }
        canvas.style.scale = 1;
        canvas.width = inputElement.clientWidth;
        canvas.height = inputElement.clientHeight;
        if (!this.dstCtx)
          this.dstCtx = canvas.getContext('2d');
        let corners = [];
        let intensity = 1;
        if (this.previousCorners && this.emittedCorners) {
          for (let i = 0; i < 4; i++) {
              corners.push({x: this.emittedCorners[i].x * progress + this.previousCorners[i].x * (1 - progress), y: this.emittedCorners[i].y * progress + this.previousCorners[i].y * (1 - progress)});
          }
        }
        else if (this.previousCorners) {
          intensity = 1 - progress;
          corners = this.previousCorners;
        }
        else {
          intensity = progress;
          corners = this.emittedCorners;
        }
        let intensityRange = '01234';
        this.dstCtx.fillStyle = '#0f0' + (intensityRange.charAt(Math.ceil(intensity * (intensityRange.length - 1))));
        this.dstCtx.beginPath();
        for (let i = 0; i < 4; i++) {
          if (i == 0)
            this.dstCtx.moveTo(corners[i].x * canvas.width, corners[i].y * canvas.height);
          else
            this.dstCtx.lineTo(corners[i].x * canvas.width, corners[i].y * canvas.height);
        }
        this.dstCtx.closePath();
        this.dstCtx.fill();
        intensityRange = '0123456789abcdef';
        this.dstCtx.strokeStyle = '#040' + (intensityRange.charAt(Math.ceil(intensity * (intensityRange.length - 1))));
        this.dstCtx.beginPath();
        for (let i = 0; i < 4; i++) {
          if (i == 0)
            this.dstCtx.moveTo(corners[i].x * canvas.width, corners[i].y * canvas.height);
          else
            this.dstCtx.lineTo(corners[i].x * canvas.width, corners[i].y * canvas.height);
        }
        this.dstCtx.closePath();
        this.dstCtx.stroke();
      }
      else if (this.dstCtx && this.debugStage < 0) {
        const canvas = this.$refs.overlay;
        if (!canvas) {
          return;
        }
        this.dstCtx.clearRect(0, 0, canvas.width, canvas.height);
      }
    },
    searchCorners() {
      //setup
      let cv = null;
      cv = this.$opencv;
      const inputElement = this.videoInput ||  this.imageInput || this.$refs.src;
      if (!cv || !cv.Mat || !inputElement || inputElement.clientWidth < 1) {
        requestAnimationFrame(() => this.render());
        return;
      }
      this.startTime = Date.now();
      const srcCanvas = this.$refs.c0;
      const scaleDown = Math.min(256 / inputElement.clientWidth, 256 / inputElement.clientHeight)
      srcCanvas.width = Math.round(inputElement.clientWidth * scaleDown);
      srcCanvas.height = Math.round(inputElement.clientHeight * scaleDown);
      if (!this.srcCtx)
        this.srcCtx = srcCanvas.getContext("2d");
      this.srcCtx.drawImage(inputElement, 0, 0, srcCanvas.width, srcCanvas.height);
      const src = this.addCv(cv.imread(srcCanvas));
      const center = {x: src.cols / 2, y: src.rows / 2};
      const overlay = this.addCv(cv.Mat.zeros(src.rows, src.cols, cv.CV_8UC4));
      const red = new cv.Scalar(255, 0, 0, 255);
      const orange = new cv.Scalar(255, 128, 0, 255);
      cv.GaussianBlur(src, src, new cv.Size(this.gaussSize, this.gaussSize), 0, 0, cv.BORDER_DEFAULT);
      let debugStep = 0; 
      if (this.debugStage == "" + (debugStep++)) {
        return this.showDebug(src);
      }
      const matGray = this.addCv(new cv.Mat());
      cv.cvtColor(src, matGray, cv.COLOR_RGB2GRAY, 0);
      if (this.debugStage == "" + (debugStep++)) {
        return this.showDebug(matGray);
      }
      const colorVector = this.addCv(new cv.MatVector());
      /*for (let i = 0; i < src.channels(); i++)
        matColors.push_back(this.addCv(new cv.Mat()));*/
      cv.split(src, colorVector);
      this.delCv(src);
      const matColors = [matGray];
      for (let i = 0; i < 3; i++) {
        if (this.debugStage == "" + (debugStep++))
          return this.showDebug(colorVector.get(i));
        matColors.push(colorVector.get(i));
      }
      const linesByColor = []
      const canny = this.addCv(new cv.Mat());
      let totalLines = 0
      for (let i = 0; i < matColors.length; i++) {
        cv.Canny(matColors[i], canny, 25, 70, 3, false);
        if (this.debugStage == "" + (debugStep++)) {
          return this.showDebug(canny);
        }


        const linesCV = new cv.Mat();
        const lines = []
        linesByColor.push(lines);
        const threshold = 10;
        const minLineLength = 30;
        const maxLineGap = 10;
        cv.HoughLinesP(canny, linesCV, 1, Math.PI / 180, threshold, minLineLength, maxLineGap);
        let debug = false;
        if (this.debugStage == "" + (debugStep++)) {
          debug = true;
        }
        for (let j = 0; j < linesCV.rows; ++j) {
          let startPoint = {x: linesCV.data32S[j * 4], y: linesCV.data32S[j * 4 + 1]};
          let endPoint = {x: linesCV.data32S[j * 4 + 2], y: linesCV.data32S[j * 4 + 3]};
          const line = createLineObject(startPoint, endPoint, center);
          lines.push(line);
          if (debug) {
            cv.line(overlay, line.startPoint, line.endPoint, red);
          }
        }
        linesCV.delete();
        totalLines += lines.length;
        if (debug) {
          return this.showDebug(overlay);
        }
      }
      this.delCv(canny);
      // combine lines (goal: have fewer lines to look at but don't remove important ones, merge linesByColor into one list)
      const redundantDistanceVariance = 10; // how far can line distances vary in the same redundancy group
      const redundantAngleVariance = 8; // how different can line-angles be before they can't be part of the same line
      const redundantPointDistance = 20;
      const redundantLines = [];
      linesByColor.forEach(lines => {
        lines.forEach(line => {
          redundantLines.push(line);
        });
      });
      redundantLines.sort((a, b) => a.normalAngle - b.normalAngle);
      const redundancyGroups = [];
      for (let i = 0; i < redundantLines.length; i++) {
        const line = redundantLines[i];
        const redundancies = line.redundancies || [line];
        for (let j = 1; j < redundantLines.length; j++) {
          const line2 = redundantLines[(i + j) % redundantLines.length];
          const angleDifference = (line2.normalAngle - line.normalAngle + 180) % 180;
          if (angleDifference > Math.max(redundantAngleVariance))
            break;
          if (Math.abs(line.distance - line2.distance) < redundantDistanceVariance && !redundancies.includes(line2) && // && angleDifference <= redundantAngleVariance
          (pointDistance(line.startPoint, line2.startPoint) < redundantPointDistance && pointDistance(line.endPoint, line2.endPoint) < redundantPointDistance ||
          pointDistance(line.startPoint, line2.endPoint) < redundantPointDistance && pointDistance(line.endPoint, line2.startPoint) < redundantPointDistance)) {
            if (line2.redundancies) { // && line2.redundancies != redundancies
              // need to merge the redundancy groups
              line2.redundancies.forEach(line3 => {
                redundancies.push(line3);
                line3.redundancies = redundancies;
              });
            }
            else {
              redundancies.push(line2);
              line2.redundancies = redundancies;
            }
          }
        }
        if (redundancies.length > 1 && redundancies[0] == line) {
          line.redundancies = redundancies;
          redundancyGroups.push(redundancies);
        }
      }
      const filteredLines = [];
      let debug = this.debugStage == "" + (debugStep++);
      redundancyGroups.forEach(lines => {
        const color = debug ? new cv.Scalar(Math.random() * 255, Math.random() * 255, Math.random() * 255, 255) : null;
        let startX = 0;
        let startY = 0;
        let endX = 0;
        let endY = 0;
        lines.forEach(line => {
          startX += line.startPoint.x;
          startY += line.startPoint.y;
          endX += line.endPoint.x;
          endY += line.endPoint.y;
        });
        const line = createLineObject({x: startX / lines.length, y: startY / lines.length}, {x: endX / lines.length, y: endY / lines.length}, center);
        line["redundancies"] = true;
        filteredLines.push(line);
        if (debug)
          cv.line(overlay, line.startPoint, line.endPoint, color);
      })
      redundantLines.forEach(line => {
        if (!line.redundancies) {
          filteredLines.push(line);
          if(debug)
            cv.line(overlay, line.startPoint, line.endPoint, red);
        }
      });
      if (debug) {
        this.$store.dispatch(LOG_TYPES.ACTIONS.LOG, "total lines reduced from " + redundantLines.length + " to: " + filteredLines.length);
        return this.showDebug(overlay);
      }
      // merge split lines that haven't been removed by the above redundancy check
      const mergableDistanceVariance = 5; // how far can line distances vary in the same redundancy group
      const mergableAngleVariance = 3; // how different can line-angles be before they can't be part of the same line
      const mergeGroups = [];
      filteredLines.sort((a, b) => a.normalAngle - b.normalAngle);
      for (let i = 0; i < filteredLines.length; i++) {
        const line = filteredLines[i];
        if (line.group)
          continue;
        const group = [line];
        for (let j = 1; j < filteredLines.length; j++) {
          const line2 = filteredLines[(i + j) % filteredLines.length];
          if (line2.group)
            continue;
          const angleDifference = (line2.normalAngle - line.normalAngle + 180) % 180;
          if (angleDifference > Math.max(mergableAngleVariance))
            break;
          if (pointDistance(line.normal, line2.normal) > 1) // make sure the lines aren't on opposite sides of the image
            continue;
          if (Math.abs(line.distance - line2.distance) < mergableDistanceVariance) { // && angleDifference <= mergableAngleVariance
            group.push(line2);
            line2.group = group;
          }
        }
        if (group.length > 1) {
          line.group = group;
          mergeGroups.push(group);
        }
      }
      debug = this.debugStage == "" + (debugStep++);
      const mergedLines = [];
      mergeGroups.forEach(lines => {
        let start = {x: 0, y: 0};
        let end = {x: 0, y: 0};
        const normal = lines[0].normal;
        lines.forEach(line => {
          let multiplier = pointDistance(normal, line.normal) > 1 ? -1 : 1;
          if (multiplier < 0)
            this.$store.dispatch(LOG_TYPES.ACTIONS.LOG, "bbbbb " + !line.redundancies);
          start.x += line.startPoint.x * multiplier;
          start.y += line.startPoint.y * multiplier;
          end.x += line.endPoint.x * multiplier;
          end.y += line.endPoint.y * multiplier;
        });
        const length = pointDistance(start, end);
        const direction = {x: (end.x - start.x) / length, y: (end.y - start.y) / length};
        let startExtent = null;
        let endExtent = null;
        const lineStarts = [];
        const lineEnds = [];
        lines.forEach(line => {
          const extent1 = normalizedPointDistance(center, line.startPoint, direction);
          const extent2 = normalizedPointDistance(center, line.endPoint, direction);
          if (extent1 > extent2)
            this.$store.dispatch(LOG_TYPES.ACTIONS.LOG, "aaaa");
          lineStarts.push(Math.min(extent1, extent2));
          lineEnds.push(Math.max(extent1, extent2));
          if (startExtent == null || extent1 < startExtent) {
            startExtent = extent1;
            start = line.startPoint;
          }
          if (endExtent == null || extent2 > endExtent) {
            endExtent = extent2;
            end = line.endPoint;
          }
        });
        lineStarts.sort((a, b) => a - b);
        lineEnds.sort((a, b) => a - b);
        let maxGap = 0;
        let activeLines = 0;
        let startIndex = 0;
        let endIndex = 0;
        while (endIndex < lineEnds.length - 1 && startIndex < lineStarts.length) {
          if (lineStarts[startIndex] < lineEnds[endIndex]) {
            activeLines += 1;
            startIndex += 1;
          }
          else {
            activeLines -= 1;
            if (activeLines <= 0) {
              // if there is a problem with lines being merged that are far apart from one another, the group should be split by gaps (over some threshold)
              const gap = lineStarts[startIndex] - lineEnds[endIndex];
              maxGap = Math.max(maxGap, gap);
            }
            endIndex += 1;
          }
        }
        if (maxGap > 0 && debug) {
          this.$store.dispatch(LOG_TYPES.ACTIONS.LOG, "found line with gap of " + maxGap);
        }
        const line = createLineObject(start, end, center);
        line["maxGap"] = maxGap;
        mergedLines.push(line);
        if (debug) {
          const color = new cv.Scalar(Math.random() * 255, Math.random() * 255, Math.random() * 255, 255);
          cv.line(overlay, line.startPoint, line.endPoint, color);
        }
      })
      filteredLines.forEach(line => {
        if (!line.group) {
          mergedLines.push(line);
          if(debug)
            cv.line(overlay, line.startPoint, line.endPoint, red);
        }
      });
      if (debug) {
        this.$store.dispatch(LOG_TYPES.ACTIONS.LOG, "total lines reduced from " + redundantLines.length + " to: " + mergedLines.length);
        return this.showDebug(overlay);
      }
      // look for corners
      debug = this.debugStage == "" + (debugStep++);
      const corners = [];
      mergedLines.forEach(line => {
        for (let i = 0; i < mergedLines.length; i++) {
          const line2 = mergedLines[i];
          if(line == line2) {
            break;
          }
          if (Math.abs(normalizedPointDistance({x: 0, y: 0}, line2.normal, line.normal)) < Math.sin(toRadians(30))) {
            // angle between lines is close enough to 90°
            const lineCenter = {
              x: center.x + line2.normal.x * line2.distance,
              y: center.y + line2.normal.y * line2.distance,
            }
            const distance = line.distance - normalizedPointDistance(center, lineCenter, line.normal);
            const direction = rotate90(line2.normal);
            // because the angle probably isn't exactly 90°
            const relativeLength = dotProduct(direction, line.normal);
            const corner = {
              x: lineCenter.x + direction.x * distance / relativeLength,
              y: lineCenter.y + direction.y * distance / relativeLength,
              lines: [line, line2],
              scores: null,
            }
            if (corner.x < 0 || corner.y < 0 || corner.x >= center.x * 2 || corner.y >= center.y * 2)
              continue;
            const score1 = -Math.min(Math.abs(normalizedPointDistance(center, line.startPoint, line2.normal) - line2.distance), Math.abs(normalizedPointDistance(center, line.endPoint, line2.normal) - line2.distance));
            const score2 = -Math.min(Math.abs(normalizedPointDistance(center, line2.startPoint, line.normal) - line.distance), Math.abs(normalizedPointDistance(center, line2.endPoint, line.normal) - line.distance));
            const score3 = 15 - 30 * Math.abs(normalizedPointDistance({x: 0, y: 0}, line2.normal, line.normal));
            corner.scores = [score1, score2, score3];
            corners.push(corner);
            if (debug) {
              if (pointDistance(lineCenter, corner) < pointDistance(lineCenter, {x: corner.x - direction.x, y: corner.y - direction.y})) {
                direction.x *= -1;
                direction.y *= -1;
              }
              const direction2 = rotate90(line.normal);
              const lineCenter2 = {
                x: center.x + line.normal.x * line.distance,
                y: center.y + line.normal.y * line.distance,
              }
              if (pointDistance(lineCenter2, corner) < pointDistance(lineCenter2, {x: corner.x - direction2.x, y: corner.y - direction2.y})) {
                direction2.x *= -1;
                direction2.y *= -1;
              }
              let score = Math.max(4, 30 + score2);
              cv.line(overlay, corner, {x: corner.x - direction.x * score, y: corner.y - direction.y * score}, red);
              score = Math.max(4, 30 + score1);
              cv.line(overlay, corner, {x: corner.x - direction2.x * score, y: corner.y - direction2.y * score}, red);
              //cv.line(dst4, new cv.Point(corner.x, corner.y), new cv.Point(lineCenter.x / 2 + corner.x / 2, lineCenter.y / 2 + corner.y / 2), red);
            }
          }
        }
      });
      if (debug) {
        this.$store.dispatch(LOG_TYPES.ACTIONS.LOG, "found " + corners.length + " corners with gauss " + this.gaussSize);
      }
      if (corners.length > 300 && this.gaussSize < 35) {
          // found too many corners, so restart with a bigger gauss size
          if (debug)
            this.$store.dispatch(LOG_TYPES.ACTIONS.LOG, "found too many corners, so restart with a bigger gauss size");
          this.gaussSize += 2;
          this.cleanupCV();
          this.handleNoRectangleFound();
          return;
      }
      if (debug) {
        return this.showDebug(overlay);
      }
      // build rectangles
      const rectangles = [];
      for (let i = 0; i < corners.length; i++) {
        const corner1 = corners[i];
        const cornersLeft = [];
        const cornersRight = [];
        const linesLeft = [];
        const linesRight = [];
        for (let j = i + 1; j < corners.length; j++) {
          const corner2 = corners[j];
          if (corner2.lines.indexOf(corner1.lines[0]) >= 0) {
            cornersLeft.push(corner2);
            linesLeft.push(corner2.lines[(corner2.lines.indexOf(corner1.lines[0]) + 1) % 2]);
          }
          else if (corner2.lines.indexOf(corner1.lines[1]) >= 0) {
            cornersRight.push(corner2);
            linesRight.push(corner2.lines[(corner2.lines.indexOf(corner1.lines[1]) + 1) % 2]);
          }
        }
        for (let j = i + 1; j < corners.length; j++) {
          const corner2 = corners[j];
          let index1 = linesLeft.indexOf(corner2.lines[0]);
          if (index1 < 0) {
            index1 = linesLeft.indexOf(corner2.lines[1]);
            if (index1 < 0)
              continue;
            let index2 = linesRight.indexOf(corner2.lines[0]);
            if (index2 >= 0)
              rectangles.push({corners: [corner1, cornersRight[index2], corner2, cornersLeft[index1]]});
          }
          else {
            let index2 = linesRight.indexOf(corner2.lines[1]);
            if (index2 >= 0)
              rectangles.push({corners: [corner1, cornersRight[index2], corner2, cornersLeft[index1]]});
          }
        }
      }
      rectangles.forEach(rect => {
        let score = 0;
        const sideLengths = [0, 0];
        let similarity = 100;
        for (let i = 0; i < rect.corners.length; i++) {
          rect.corners[i].scores.forEach(cornerScore => {
            score += cornerScore;
          });
          if (this.previousCorners) {
            let d = 1000;
            for (let j = 0; j < this.previousCorners.length; j++) {
              d = Math.min(d, pointDistance(rect.corners[i], {x: this.previousCorners[j].x * center.x * 2, y: this.previousCorners[j].y * center.y * 2}));
            }
            similarity -= d;
          }
          const distance = pointDistance(rect.corners[i], rect.corners[(i + 1) % rect.corners.length]);
          sideLengths[i % 2] += distance;
          score += distance;
          if (this.videoInput && this.previousCorners) { //TODO maybe add || this.imageInput here
            score += Math.max(0, similarity);
          }
        }
        const sideRatio = Math.min(sideLengths[0] / sideLengths[1], sideLengths[1] / sideLengths[0]);
        score -= 300 * (Math.abs(sideRatio - 210 / 297));
        rect.score = score;
      });
      if (rectangles.length > 1000 && this.gaussSize < 35) {
        // blur the image more
        this.gaussSize += 2;
      }
      if (rectangles.length < 5 && this.gaussSize > 3) {
        // try less blur next time
        this.gaussSize -= 2;
      }
      rectangles.sort((a, b) => b.score - a.score);
      if (this.debugStage == "" + (debugStep++)) {
        this.$store.dispatch(LOG_TYPES.ACTIONS.LOG, "found " + rectangles.length + " rectangles");
        rectangles.slice(0, 10).forEach(rect => {
          const color = new cv.Scalar(Math.random() * 255, Math.random() * 255, Math.random() * 255, 255);
          for (let i = 0; i < rect.corners.length; i++) {
            cv.line(overlay, rect.corners[i], rect.corners[(i + 1) % rect.corners.length], color);
          }
        });
        return this.showDebug(overlay);
      }

      this.cleanupCV()

      if (rectangles.length > 0) {
        rectangles[0].corners.forEach(corner => {
          corner.angle = Math.atan2(corner.y - center.y, corner.x - center.x);
          corner.x /= center.x * 2;
          corner.y /= center.y * 2;
        });
        rectangles[0].corners.sort((a, b) => a.angle - b.angle);
        this.handleRectangle(rectangles[0].corners, rectangles[0].score);
      }
      else {
        this.handleNoRectangleFound();
      }
    },
    addCv(cvElement) {
      this.cvData.push(cvElement);
      return cvElement;
    },
    delCv(cvElement) {
      this.cvData.splice(this.cvData.indexOf(cvElement), 1);
      cvElement.delete();
    },
    cleanupCV() {
      this.cvData.forEach(element => {
        element.delete();
      });
      this.cvData = [];
    },
    showDebug(matrix) {
      this.handleNoRectangleFound();
      let cv = null;
      cv = this.$opencv;
      cv.imshow('overlay', matrix);
      this.cleanupCV();
      if (this.videoInput) //TODO add case for imageInput
        this.$refs.overlay.style.scale = this.videoInput.clientWidth / this.$refs.overlay.clientWidth;
      else
        this.$refs.overlay.style.scale = 1;
    },
    handleRectangle(corners, score) {
      if (score > 300) {
        // good rectangle found
        const emittedCorners = [];
        for (let i = 0; i < 4; i++) {
          emittedCorners.push({id: i, x: corners[i].x, y: corners[i].y});
        }
        this.$emit('change', emittedCorners);
        this.emittedCorners = corners;
      }
      else {
        this.handleNoRectangleFound();
      }
    },
    handleNoRectangleFound() {
      this.emittedCorners = null;
      this.$emit('change', null);
    },
  },

  beforeDestroy () {
    if (this.interval)
      clearInterval(this.interval);
    this.interval = null;
  },

  mounted () {
    if(this.videoInput) {  
      this.startRendering();
    } else if (this.imageInput) {
      setTimeout(() => { //somehow this works but nextTick or requestAnimationframe dont, also strange timing issue regardless
        this.searchCorners();
        this.renderRectangle(1);
      }, 1);
    }
  },
})
</script>

<style scoped>
.edge-detection-container {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#videoDestination {
  display: none;
}
.edge-detection-canvas-debug {
  position: absolute;
  top: 0px;
  left: 0px;
}
.edge-detection-src-debug {
  max-width: 256px;
  max-height: 256px;
}
img {
  max-width: 256px;
  max-height: 256px;
  visibility: hidden;
  position: absolute;
}
video {
  position: absolute;
  visibility: hidden;
}
</style>
